import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CatalogFinal from '../../../../../assets/pictos/empty-picto/catalogue.svg';
import CheckboxAttributs from '../../../../ui/inputs/CheckboxAttributs';

export default function catalogEdit(allGroups){
    var obj = {
        titleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Gérer les attributs du catalogue' : "Modifier l'entrepôt",
        subTitleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Veuillez compléter les champs ci-dessous pour mettre à jour les attributs du catalogue' : "Veuillez compléter les champs ci-dessous pour mettre à jour votre entrepôt",
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations sur le catalogue',
            },
            finalStep: {
                picto: CatalogFinal,
                title: 'Et voilà !',
                subtitle: 'Vous pouvez confirmer votre modification',
                textButton: 'Enregistrer'
            },
            children:[
                {
                    labelName: 'Attributs',
                    isOptionnal: false,
                    component: CheckboxAttributs,
                    optionsInputs:{
                        stateName: 'attributesSelected',
                        required: true,
                        value: allGroups
                    }
                },
            ]
        }
    } 
    return obj;
}