import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import SearchTags from '../../../../ui/inputs/SearchTags';
import MediaImporter from '../components/MediaImporter'

export default function mediasImportStepperConfig(channels,categories,categorieSelected, isZip){
    let obj ={
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        titleForm: 'Importer des médias',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour importer des médias',
        textButtonValidate: 'Importer',
        formConfig: {
            type: STEPPER,

            finalStep: {
                title: 'Et voilà !',
                subtitle: 'Cliquez ci-dessous pour finaliser votre import',
                textButton: 'Importer mes médias'
            },
            children: []

        },

    }   
    if (!isZip) {
        obj.formConfig.children.push(
            {
                labelName: 'Selection du channel',
                isOptionnal: false,
                optionsInputs:[
                    {
                        type: 'radio',
                        isCard: true,
                        label: '',
                        helper:{
                            text: '',
                            link: false,
                        },
                        required: true,
                        stateName: 'channelSelected',
                        value: channels.map((values) => {
                            return ({
                                value: values.node.id,
                                label: values.node.libelle,
                                description: values.node.description,
                                image: values.node.logo,
                            });
                        })
                    }
                ]
            },
        )
    }
    if (isZip){
        obj.formConfig.children.push(
            {
                labelName : 'Selection de la catégorie',
                isOptional:false,
                optionsInputs:[
                    {
                        type: 'selectTree',
                        required: true,
                        multiselect : false,
                        label: 'Dossier',
                        translated: false,
                        helper: {
                            text: 'Indiquez le dossier de destination du média',
                            link: false,
                        },
                        
                        stateName: 'parent',
                        data: (() => {
                            let data = categories.filter(e => e.node.parent === null);
    
                            let populateChildren = (cats, parent) => {
                                
                                parent.value            = parent.node.id;
                                parent.label            = parent.node.libelle;
                                parent.children         = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);
                                parent.isDefaultValue   = parent.node.id === categorieSelected;  
                                parent.expanded         = true;

                                for (let child of parent.children)
                                    populateChildren(cats, child);
                            };

                            for (let parent of data)
                                populateChildren(categories, parent);

                            /* root.children = data;*/

                            return data;
                        })()
                    }
                ]
            },
            {
                // labelName : 'Importer les médias',
                // isOptional : false,
                // component : MediaImporter,
                isOptional: false,
                labelName: 'Importer un fichier ZIP',
                optionsInputs:[
                    {
                        type: 'uploadFile',
                        translated: false,
                        required: false,
                        typeUpload: "only-zip",
                        stateName: `upload_ZIP`,
                        multipleStates: true,
                        noMapping: true
                    }
                ]
            },
        )
    }else{
        obj.formConfig.children.push(
            {
                labelName : 'Importer les médias',
                isOptional : false,
                component : MediaImporter,
            },
            {
                labelName : 'Selection de la catégorie',
                isOptional:false,
                optionsInputs:[
                    {
                        type: 'selectTree',
                        multiselect : true,
                        label: 'Dossier',
                        translated: false,
                        helper: {
                            text: 'Indiquez le dossier de destination du média',
                            link: false,
                        },
                        
                        stateName: 'parent',
                        data: (() => {
                            /*let tree    = [];
                            let root    = 
                            {
                                children    : [],
                                checked     : categorieSelected.length>0 ? false : true,
                                expanded    : true,
                                label       : '/',
                                node        : 
                                {
                                    id: "root",
                                    libelle: "/",
                                    parent: null 
                                }
                            }*/
                            let data = categories.filter(e => e.node.parent === null);
                            
                            let populateChildren = (cats, parent) => {
                              
                                let catSelected         = categorieSelected.find(e => e.node.id === parent.node.id);
                                parent.value            = parent.node.id;
                                parent.label            = parent.node.libelle;
                                parent.children         = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);
                                parent.checked          = catSelected ? true : false;
                                parent.expanded         = true;
    
                                
                                for (let child of parent.children){
                                    populateChildren(cats, child);                                            
                                }
                            };
    
                            for (let parent of data){
                                populateChildren(categories, parent); 
                            }
                            /*root.children=data;
                            tree=tree.concat(root);*/
                                
    
                            return data;
    
    
                        })()
                    }
                ]
            },
            {
                labelName : 'Tags',
                isOptionnal : false,
                component : SearchTags,
            }
        )
    }
    return obj;
}
