import React from "react";
import Div from '../../../../shared/components/CustomDiv';
import H3 from '../../../../shared/components/CustomH3';

export default ({ data, preview, component, block, inputCallback, className }) => {
    var rules = data.blocks[block].config;

    return(
        <div className="regles_without_image">
            <div className="list_regles">
                {
                    rules.map((rule, index) =>{
                        return(
                            <div className="regle inline-flex" id={`rule-${index}`} key={`rule-${index}`} style={{maxWidth: rule.inputs?.maxWidth?.value ? rule.inputs.maxWidth.value : null, width: rule.inputs?.width?.value ?  `${rule.inputs.width.value}%` : null}}>
                                <Div data={rule} preview={preview} component={rule.blocks.number.id} block={'number'} nopadding={true} />
                                <div className="description">
                                    <H3 data={rule} preview={preview} component={rule.blocks.title.id} block={'title'} nopadding={true} />
                                    <Div data={rule} preview={preview} component={rule.blocks.descr.id} block={'descr'} nopadding={true} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
};