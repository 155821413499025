import { Box, Grid } from "@mui/material"
import CardCustom from "../../../../layouts/Card/CardCustom"
import { useEffect, useState } from "react"
import InputBuilder from "../../InputBuilder"
import { InputWrapper } from "./style/styled"
import OurTypography from "../../../typography/Typography"
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import colors from "../../../../../config/theme/colors"
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { v4 as uuidv4 } from "uuid";

const RowsNew = ({
  parentState,
  input,
  updateCallback,
  errorCallback,
  error
}) => {
  const { schema } = input
  const [errors, setErrors] = useState([])

  //* When there is an error in a row child input, we populate the errors state
  //* Once it's done, check if there is at least one so we pass it to the parent. This means that RowsNew has errors
  useEffect(() => {
    const hasErrors = Boolean(errors.find(r => Object.values(r.errors)?.find(e => e)))
    errorCallback(hasErrors)
  }, [errors])

  // Create
  const createRow = () => {
    const statesObj = schema.inputs.reduce((acc, input) => ({
      [input.stateName]: null
    }))

    const newRow = {
      id: uuidv4(),
      states: statesObj,
      create: true //* This will help to know if the row is new or not (for mutations requests)
    }

    updateCallback([...parentState, newRow])
    //* We don't need to add an error reference because the input checks for an error itself when rendering for the first time (mapRowsErrors is called automatically)
  }

  // Delete
  const deleteRow = (id) => {
    const newRows = parentState.map(row =>
      (row.id === id)
        ? ({
          ...row,
          delete: true
        })
        : row
    )

    // Delete the row and error reference
    updateCallback(newRows)
    setErrors(prev => prev.filter(error => error.id !== id))
  }

  const getInputState = (rowId, stateName) => {
    const rowToGet = parentState.find(row => row.id === rowId)
    return rowToGet.states?.[stateName]
  }

  // Update
  const updateState = (evt, rowId, stateName) => {
    const value = evt?.target?.value ?? evt;

    const copyOfRows = JSON.parse(JSON.stringify(parentState))
    const rowToUpdate = copyOfRows.find(row => row.id === rowId)

    if (rowToUpdate && rowToUpdate.states) {
      rowToUpdate.states[stateName] = value;
      rowToUpdate.update = true;
    }

    updateCallback(copyOfRows)
  }

  const mapRowsErrors = (inputError, itemStateName, rowId) => {
    const newErrors = (errorsState) => (
      parentState.filter(row => !row.delete).map(row => {
        if (row.id === rowId) {
          // The row that we are typing in
          return {
            id: row.id,
            errors: {
              ...errorsState?.find(r => r.id === rowId)?.errors,
              [itemStateName]: inputError
            }
          }
        } else {
          // The other rows
          return {
            id: row.id,
            errors: {
              ...errorsState?.find(r => r.id === row.id)?.errors,
            }
          }
        }
      })
    )

    setErrors(prev => newErrors(prev))
  };

  return (
    <Box>
      <Grid container gap={2} direction={'column'}>
        {/* Rows */}
        {parentState?.filter(row => !row.delete).map((row, index) => (
          <Grid item key={row.id}>
            <OurTypography fontsize={'16px'} fontweighttext={'bold'} style={{ marginBottom: 10 }}>{schema.title} n°{index + 1}</OurTypography>
            <CardCustom contentpadding={'16px'}>
              <Grid container style={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
              }}>
                {/* Row inputs */}
                <InputWrapper item>
                  <Grid container columnSpacing={2}>
                    {schema.inputs.map((input, index) => (
                      <InputBuilder
                        xs={input.xs}
                        value={!input.defaultValue
                          ? getInputState(row.id, input.stateName)
                          : null
                        }
                        input={input}
                        stateCallback={(evt) => updateState(evt, row.id, input.stateName)}
                        errorCallback={(error) => mapRowsErrors(error, input.stateName, row.id)}
                      />
                    ))}
                  </Grid>
                </InputWrapper>

                {/* Remove button */}
                <Grid container justifyContent={'center'} alignItems={'center'} style={{ height: '100%', marginLeft: 16, width: 'auto' }}>
                  <Grid container justifyContent={'center'} alignItems={'center'} style={{
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    border: `1px solid ${colors.grey.border}`,
                    cursor: parentState.filter(row => !row.delete).length > 1 && 'pointer',
                    backgroundColor: parentState.filter(row => !row.delete).length === 1 && colors.grey.lighter.hue900,
                  }} onClick={() => parentState.filter(row => !row.delete).length > 1 && deleteRow(row.id)}>
                    <CloseSharpIcon style={{ fontSize: 18 }} />
                  </Grid>
                </Grid>
              </Grid>
            </CardCustom>
          </Grid>
        ))}
      </Grid>

      {/* Add button */}
      <Grid container onClick={createRow} alignItems={'center'} style={{
        display: 'inline-flex',
        width: 'auto',
        cursor: 'pointer',
        marginTop: 20,
        marginBottom: 20,
      }}>
        <AddCircleSharpIcon style={{ fontSize: 16, marginRight: 5, color: colors.green.regular }} />
        <OurTypography fontsize={12} style={{ color: colors.green.regular }}>Ajouter une {schema.title.toLowerCase()}</OurTypography>
      </Grid>
    </Box>
  )
}

export default RowsNew