import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Grid, Box, CircularProgress } from '@mui/material';
import Typography from '../../../../ui/typography/Typography';
import { ROUTE_SALES_MARKETPLACES, ROUTE_COMMUNITIES_SOCIALS } from '../../../../../js/constants/route-names';
import { withApollo } from '@apollo/client/react/hoc';
import request from '../../../../../js/utils/fetch';
import TopPanel from '../../../../layouts/TopPanel/TopPanel';
import colors from '../../../../../config/theme/colors';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../../assets/selection.json";
import SliderDashboard from "../../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import Image2 from '../../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-marketplace.png';
import { v4 as uuidv4 } from 'uuid';
import { SNACK } from '../../../../../js/constants/action-types';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../../js/utils/checkRouting';
import moment from 'moment';
import styled from 'styled-components';
import AccordionCustom from '../../../../layouts/Accordion/AccordionCustom';


const WrapperStats = styled(Grid)`
    width: 100%; 
    padding: 0 36px;
    @media screen and (max-width: 1600px){
        padding: 0 16px;
    }
    @media screen and (max-width: 1450px){
        margin: 16px 0; 
    }
`;
const Title = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 16px;
    line-height: 23px;
    font-weight: bold;
    width: 100%;
`;
const TitleBis = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    width: 100%;
`;
const SubTitle = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    font-size: 35px;
    line-height: 46px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 30px;
        line-height: 41px;
    }
    @media screen and (max-width: 1450px){
        font-size: 23px;
        line-height: 30px;
    }
`;
const SubTitleBis = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    font-size: 31px;
    line-height: 39px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 23px;
        line-height: 24px;
    }
    @media screen and (max-width: 1450px){
        font-size: 18px;
        line-height: 19px;
    }
`;
const MoreInfos = styled(Typography)`
    margin-top: 4px;
    color: ${colors.black.regular};
    font-size: 20px;
    line-height: 23px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 16px;
        line-height: 19px;
    }
`;
const MoreInfosBis = styled(Typography)`
    margin-top: 4px;
    color: ${colors.black.regular};
    font-size: 12px;
    line-height: 14px;
    width: 100%;
`;
const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor};
    svg circle{
        stroke: ${props => props.statuscolor};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor};
    }
`;
class DashboardContents extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            contents: [],
            ready:false
        };
    }

    componentDidMount(){
        checkRouting(this.props);
        this.initDashboard();
    }

    initDashboard = () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/content`, 'get', false).then(
            (data) => {
                if(data.success){
                    this.setState({
                        contents: data.datas,
                        ready:true
                    }, () => {
                        let time = this.state.contents.nbContents - ((this.state.contents.percentCompleteContents * this.state.contents.nbContents) / 100);
                        this.setState({time:time??null});
                    });
                }
            }
        ).catch(e=>{            
            this.setState({
                contents: [],
                ready:true
            });
        })
    }


    render() {
        const sliderData = [
            {
                title: this.props.t("content.dashboard.whyCreateRS"),
                text: 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.',
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t("content.dashboard.createRS"),
                        link: `${ROUTE_COMMUNITIES_SOCIALS}?formOpen=true`
                    },
                ]
            },
            {
                title: this.props.t("content.dashboard.whyMarketPlace"),
                text: 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.',
                image: Image2,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t("content.dashboard.createMarketPlace"),
                        link: `${ROUTE_SALES_MARKETPLACES}?formOpen=true`
                    },
                ]
            }
        ];

        

        let lastDatas = [
            {
                'data': Math.round(this.state.contents.percentCompleteContents),
                'dataColor': colors.black.regular,
                'title': "Taux de complétude",
                'subtitle': this.props.t('content.dashboard.lastdatas.completedContents'),
                'moreInfos': `MAJ: ${moment().format('DD/MM/YYYY à HH:mm')}`
            },
            {
                'icon': 'picto-produit',
                'data': this.state.contents.nbCategories ? 20 : null,
                'dataColor': colors.blue.darker.hue300,
                // 'title': this.props.t('contents.dashboard.lastdatas.categoriesTotal'),
                'subtitle': `${this.state.contents.nbCategories?this.state.contents.nbCategories:0} ` + this.props.t('content.dashboard.lastdatas.categories'),
                'moreInfos': `Dernier ajout: ${this.state.contents.dateLastCategory ? moment(this.state.contents.dateLastCategory).format('DD/MM/YYYY à HH:mm') : 'N/A'}`

            },
            {
                'icon': 'picto-produit',
                'data': this.state.contents.nbContents ? 40 : null,
                'dataColor': colors.blue.darker.hue300,
                // 'title': this.props.t('contents.dashboard.lastdatas.productTotal'),
                'subtitle': `${this.state.contents.nbContents?this.state.contents.nbContents:0} ` + this.props.t(this.state.contents.nbContents > 1 ? 'content.dashboard.lastdatas.contents' : 'content.dashboard.lastdatas.content'),
                'moreInfos': `Dernier ajout: ${this.state.contents.dateLastContent ? moment(this.state.contents.dateLastContent).format('DD/MM/YYYY à HH:mm') : 'N/A'}`
            },
            {
                'data': Math.round((this.state.contents.nbContentsEnable / this.state.contents.nbContents) * 100),
                'dataColor': colors.blue.regularv2,
                // 'title': this.props.t('contents.dashboard.lastdatas.nbActiveProduct'),
                'subtitle': this.props.t('content.dashboard.lastdatas.activeContent'),
                'moreInfos': `Nombre total de contenus actif : `,
                'moreInfosData': `${this.state.contents.nbContentsEnable?this.state.contents.nbContentsEnable:0}/${this.state.contents.nbContents?this.state.contents.nbContents:0}`

            },
            {
                'data': Math.round((this.state.contents.countCatalogContent / this.state.contents.nbContents) * 100),
                'dataColor': colors.blue.darker.hue300,
                // 'title': this.props.t('contents.dashboard.lastdatas.nbCatalogProduct'),
                'subtitle': this.props.t('content.dashboard.lastdatas.catalogContent'),
                'moreInfos': `Nombre de catalogue contenu total : `,
                'moreInfosData': `${this.state.contents.countCatalogContent?this.state.contents.countCatalogContent:0}/${this.state.contents.nbContents?this.state.contents.nbContents:0}`

            },
        ]
        return (
            <div style={{width: "100%"}}>                
                <TopPanel 
                    icomoon="icon-dashboard" 
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("spread_builder.dashboard.welcomeOnDashboard") + ' ' + this.props.t("content.dashboard.content")} 
                    subtitle="Petite phrase de description OU heure de Mise à jour" 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    hasBorder={true}
                />
                <Grid container direction="column" justifyContent="center" spacing={0}>
                    <Grid item xs={12}>
                        <WrapperStats container alignItems='center' direction="row">
                            {/* Main stat */}
                            <Grid item xs={5} key={`LastDatas-first-item`}>
                                <Grid container justifyContent="center" alignItems="center" style={{
                                    height: "100%",
                                    flexDirection: this.props.windowWidth > 1200 ? 'row' : 'column'
                                }}>
                                    <Grid item style={{
                                        maxWidth: this.props.windowWidth > 1200 ? 142 : 120, 
                                        width: "100%",
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>                                                     
                                        {
                                            this.state.ready?
                                                <BoxCustom position="relative" display="flex" justifyContent={"center"} statuscolor={colors.green.regular} width="100%">
                                                    <CircularProgress variant="determinate" thickness={2.5} value={!Number.isNaN(lastDatas[0].data)? lastDatas[0].data: 0} style={{ width: "100%", height: "auto", position: "absolute", left: 0, top: 0, zIndex: 2 }} />
                                                    <CircularProgress
                                                        variant="determinate"
                                                        value={100}
                                                        disableShrink
                                                        style={{ width: "100%", height: "auto" }}
                                                        thickness={2.5}
                                                    />
                                                    <Box
                                                        top={0}
                                                        left={0}
                                                        bottom={0}
                                                        right={0}
                                                        position="absolute"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        {
                                                            lastDatas[0].icon ?
                                                                <IcomoonReact iconSet={iconSet} color="#0273A5" size={this.props.windowWidth > 1200 ? 25 : 20} icon={lastDatas[0].icon} />
                                                                :
                                                                <Typography variant="body1" color="textSecondary" style={{ 
                                                                    fontWeight: "bold", 
                                                                    fontSize: this.props.windowWidth > 1200 ? 45 : 40
                                                                }}>
                                                                    {!Number.isNaN(lastDatas[0].data)? lastDatas[0].data: 0}%
                                                                </Typography>
                                                        }
                                                    </Box>
                                                </BoxCustom>
                                                : <CircularProgress size={
                                                    this.props.windowWidth > 1200 ? "18px" : "20px"
                                                } color={"inherit"} />
                                        }
                                    </Grid>
                                    <Grid item style={{ 
                                        flex: 1, 
                                        paddingLeft: this.props.windowWidth > 1200 ? 32 : 0,
                                        paddingTop: this.props.windowWidth > 1200 ? 0 : "10px",
                                        textAlign: this.props.windowWidth > 1200 ? "start" : "center",
                                    }}>
                                        <Grid container direction="column" alignItems="center">
                                            <Title variant="body1">{this.props.t(lastDatas[0].title)}</Title>
                                            <SubTitle variant="h4">{this.props.t(lastDatas[0].subtitle)}</SubTitle>
                                            <MoreInfos variant="body2">{this.props.t(lastDatas[0].moreInfos)}</MoreInfos>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Secondary stats */}
                            <Grid item xs={7}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    {
                                        lastDatas.slice(1).map((data, index) => {                                            
                                            return (
                                                <Grid item xs={6} key={`LastDatas${index}`} style={{ padding: "24px 8px" }}>
                                                    {
                                                        this.state.ready?
                                                            <Grid container justifyContent="center" alignItems="center" style={{
                                                                height: "100%",
                                                                flexDirection: this.props.windowWidth > 1200 ? 'row' : 'column'
                                                            }}>
                                                                {/* Stats */}
                                                                <Grid xs={12} item style={{ maxWidth: 80, width: "100%" }}>
                                                                    {
                                                                        this.state.ready  ?
                                                                            <BoxCustom position="relative" display="flex" justifyContent={"center"} statuscolor={data.dataColor} width="100%">
                                                                                <CircularProgress variant="determinate" thickness={2.5} value={!Number.isNaN(data.data)?data.data:0} style={{ width: "100%", height: "auto", position: "absolute", left: 0, top: 0, zIndex: 2 }} />
                                                                                <CircularProgress
                                                                                    variant="determinate"
                                                                                    value={100}
                                                                                    disableShrink
                                                                                    style={{ width: "100%", height: "auto" }}
                                                                                    thickness={2.5}
                                                                                />
                                                                                <Box
                                                                                    top={0}
                                                                                    left={0}
                                                                                    bottom={0}
                                                                                    right={0}
                                                                                    position="absolute"
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center"
                                                                                >
                                                                                    {
                                                                                        data.icon ?
                                                                                            <IcomoonReact iconSet={iconSet} color={data.dataColor} size={this.props.windowWidth > 1200 ? 35 : 25} icon={data.icon} />
                                                                                            :
                                                                                            <Typography variant="body1" color="textSecondary" style={{ fontWeight: "bold", fontSize: 25 }}>
                                                                                                {!Number.isNaN(data.data)?data.data:0}%
                                                                                            </Typography>
                                                                                    }
                                                                                </Box>
                                                                            </BoxCustom>
                                                                            // <Typography variant="h2" colortext={colors.blue.lighter.hue150} style={{fontSize: this.props.windowWidth > 1200 ? 23 : 16}}>{lastDatas[0].data}</Typography>
                                                                            : <CircularProgress size={"18px"} color={"inherit"} />
                                                                    }
                                                                </Grid>
                                                                <Grid xs={12} item style={{
                                                                    flex: 1,
                                                                    paddingLeft: this.props.windowWidth > 1200 ? 22 : 0,
                                                                    marginTop: this.props.windowWidth <= 1200 && 6
                                                                }}>
                                                                    {/* Texts */}
                                                                    <Grid container direction="column" alignItems="center" style={{
                                                                        textAlign: this.props.windowWidth > 1200 ? 'start' : 'center'
                                                                    }}>
                                                                        <TitleBis variant="body1">{this.props.t(data.title)}</TitleBis>
                                                                        <SubTitleBis variant="h4">{this.props.t(data.subtitle)}</SubTitleBis>
                                                                        <MoreInfosBis variant="body2">{this.props.t(data.moreInfos)} <strong>{data.moreInfosData}</strong></MoreInfosBis>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            :
                                                            <Grid container alignItems='center' justifyContent="center">
                                                                <CircularProgress style={{ width: "100%", maxWidth: 80, height: "auto" }} color={"inherit"} />
                                                            </Grid>
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </WrapperStats>
                    </Grid>
                    {
                        sliderData.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xl={sliderData.length > 1 ? 12 : 6} lg={sliderData.length > 1 ? 12 : 8} md={sliderData.length > 1 ? 12 : 8} xs={12} style={{ overflow: 'hidden',maxWidth:"100%" }}>
                                <AccordionCustom forcedExpanded={true} title={<Typography variant={"h4"} style={{ fontSize: 14}}>{this.props.t('products.dashboard.actionslider.actionslider')}</Typography>}>
                                    <SliderDashboard sliderData={sliderData} windowWidth={this.props.windowWidth} />
                                </AccordionCustom>
                            </Grid>
                            : null
                    }
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
    }
}

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardContents))));