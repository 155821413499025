import { withApollo } from '@apollo/client/react/hoc';
import ImageFake from "@assets/images/image-fake.png";
import { SNACK, START_LOADING, STOP_LOADING } from "@constants/action-types";
import { ALERT_ERROR, ALERT_SUCCESS, ALERT_WARNING } from "@constants/alert-types";
import { ROUTE_CATALOGS_LIST, ROUTE_PRODUCTS_LIST_DETAIL } from "@constants/route-names";
import AccordionCustom from "@layouts/Accordion/AccordionCustom";
import OurDrawer from "@layouts/Drawer/OurDrawer";
import Listing from "@layouts/Listing/Listing";
import TopPanel from "@layouts/TopPanel/TopPanel";
import SyncIcon from '@mui/icons-material/Sync';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Divider from "@mui/material/Divider";
import { GET_MAGENTO_PAGES_BY_ASSETS_LIST } from "@queries/assetMagentoPage";
import { COUNT_ASSETS_BY_CATALOG_ID } from "@queries/assets";
import { UPDATE_ATTRIBUTE } from "@queries/attributes";
import { CREATE_CATALOG_FILTER, DELETE_CATALOG_FILTER, GET_CATALOG_FILTERS, UPDATE_CATALOG_FILTER } from "@queries/catalog_filter";
import { CREATE_CATEGORY_FILTER, DELETE_CATEGORY_FILTER, UPDATE_CATEGORY_FILTER } from "@queries/category_filter";
import { GET_CONTENT_CATEGORIES_ONLY } from "@queries/content_categories";
import { PRODUCTS_BY_CATALOGS_CAT_PAGINATION } from "@queries/products";
import OurButton from "@ui/button/Button";
import CatalogForm from "@ui/form/CatalogForm";
import ExportProducts from "@ui/form/ExportProductsForm";
import LayoutFormBuilder from "@ui/form/LayoutFormBuilder";
import ReturnLink from "@ui/link/ReturnLink";
import PageLoader from "@ui/loadings/page-loader/PageLoader";
import OurMenu from "@ui/menu/Menu";
import StatusInfo from "@ui/status-info/StatusInfo";
import OurTypography from "@ui/typography/Typography";
import { checkRouting } from '@utils/checkRouting';
import { CopyWithJSON } from "@utils/copy";
import request from '@utils/fetch';
import { getTraductionAttributs } from '@utils/functions';
import { goTo } from "@utils/goTo";
import * as Pagination from '@utils/pagination';
import parseHtml from "@utils/parseHtml";
import axios from 'axios';
import colors from "colors";
import { eventService } from "js/services/event.service";
import _, { groupBy } from 'lodash';
import moment from "moment";
import { CATALOG_DETAILS_FILTER_GET_CATALOGS_BY_ID, CATALOG_DETAILS_GET_CATALOGS_BY_ID, DELETE_CATALOG, GET_CATALOGS_BY_ID_ATTRS, GET_CATALOGS_BY_ID_CAT_FILTERS, GET_CATALOGS_CATEGORIES_BY_ID, GET_CATALOG_TYPES, UPDATE_CATALOG } from "queries/catalogs";
import { ADD_CATEGORY, ADD_CATEGORY_DATA, DELETE_CATEGORY, DELETE_CATEGORY_DATA, GET_CATEGORIES_BY_ID_LIST_LIGHT, GET_CATEGORIES_LIGHT_2, GET_CATEGORIES_ONLY, UPDATE_CATEGORY, UPDATE_CATEGORY_DATA, UPDATE_CATEGORY_POSITION } from "queries/categories";
import { createRef, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toggleExpandedForAll } from 'react-sortable-tree';
import { v4 as uuidv4 } from 'uuid';
import { filtersOptions, listMappers, listSettings, perPageOptions, viewOptions } from "../../products/config/listProducts.config";
import { dottedMenuConfig } from "../config/catalogDetails.config";
import catalogEditAttributes from "../config/catalogEditAttributs.config";
import catalogEditProductsCategories from "../config/catalogEditProductsCategories.config";
import formCategoriesAdd from "../../../products/config/formCategoriesAdd.config";
import formCategoriesEdit from "../../../products/config/formCategoriesEdit.config";

const CatalogDetail = props => {
    const [dataCount, setDataCount] = useState(0);
    const [reloadListing, setReloadListing] = useState(false);
    const [catalog, setCatalog] = useState(null);
    const containerRef = createRef(null);
    const [offsetListing, setOffsetListing] = useState(0);
    const [scrollListing, setScrollListing] = useState(false);
    const [openFormExport, setOpenFormExport] = useState(false);
    const [filtersOptionsValues, setFiltersOptionsValues] = useState(filtersOptions(JSON.parse(localStorage.getItem("ATTRIBUTES")).product.attributes.edges.filter(e=>e.node.isSearchable)));
    const [state, setState] = useState({
        activeCategorie: ["Home"],
        styleInnerContainer: {
            boxShadow: 'none',
            backgroundInner: colors.white,
            backgroundActions: colors.grey.lighter.hue980,
            padding: '0px',
            height: '630px'
        },
        openForm: false,
        exportType: "csv",
        exportLang: props.locales[0].node.code,
        exportStatus: null,
        openFormCategories: false,
        currentLang: props.locales[0].node.code,
        libelle: "",
        openFormAttributs: false,
        openSelectionProduct: false,
        openDialogCategory: false,
        openDialogTreeView: false,
        openMerchModal: false,
        catalog: null,
        catalogData: null,
        assetMagentoPages: null,
        contentCategories: null,
        startDate: '',
        endDate: '',
        nbProducts: 0,
        imageCatalog: null,
        categories: [],
        filteredCat: [],
        attributesSelectedExport:[],
        errors: {},
        seeErrors: false,
        ready: false,
        expanded: {},
        productByCategorie: [],
        selectedProductByCategorie: [],
        selectedCategorie: '',
        allCategories: [],
        typeCategorie: 'category',
        pagination: {
            page: 0,
            perPage: 6,
            count: 0,
        },
        paginationCheckboxItem: {
            page: 0,
            perPage: 8,
            count: 0,
        },
        idlist: [],
        arrayCatFilter: [],
        noResult: false,
        anchorEl: null,
        searchModal: '',
        listAsset: [],
        countAssets: 0,
        modalSynchro: false,
        reloadTreeCategories: uuidv4(),
        reloadProductList: false,
        reloadProductFormList: false,
        catFilters: [],
        catalogFilters: [],
        changeInMerch: false,
        categoryMaster: null,
        catalogSelected: null,
        allCategoriesReady: false,
        openEditForm: false,
        importTypeFile: "csv",
        importUrl: "",
        errors: {},
        customAttributes: [],
        categoriesData: [],
        metaAttributes: [],
        imageAttributes: [],
        allGroups:[],
        groupAttribut: "",
        seeErrors: false,
    })
    const [selectedItems, setSelectedItems] = useState([]);
    const [exportCatalogProducts, setExportCatalogProducts] = useState(false);
    const [catalogForm, setCatalogForm] = useState(null);

    const goToProductDetail = (id) => {
        props.history.push({
            pathname: ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', id.replace('/api/products/', '')),
            state: { productId: id }
        });
    };

    const handleClick = (event) => {
        setState(prev => ({ ...prev, anchorEl: event.currentTarget }));
    };

    const handleClose = () => {
        setState(prev => ({ ...prev, anchorEl: null }));
    };


    const handlerMutationExport = async () => {
        props.startLoading();
        if (hasErrors()) {
          props.stopLoading();
          props.snack(ALERT_ERROR, "Veuillez vérifier les champs invalides");
          return eventService.fire();
        }
        try {
          let data = new FormData();
          data.append("name", state.exportName);
          data.append("locale", state.exportLang);
          data.append("format", state.exportType);
          data.append("status", state.exportStatus);
          const arrayOfProductIds = selectedItems.map((e) =>e.replace("/api/products/", ""));
          arrayOfProductIds.forEach((e,i)=>{
            data.append(`productIds[${i}]`, e);  
          })
          state.attributesSelectedExport.forEach((e,i)=>{
            data.append(`attributeIdentifiers[${i}]`, e.identifier);  
          })
          if (exportCatalogProducts) {
            data.append("catalog", catalog.id?.replace("/api/catalogs/", ""));
          }
    
          let urlencoded = new URLSearchParams(data).toString();
    
          request(
            `${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`,
            "get",
            null,
            "application/x-www-form-urlencoded"
          )
            .then(async (data) => {
              if (data.success) {
                window.open(
                  `${process.env.REACT_APP_API_ROOT}/medias/export/${
                    data.mediaObject.filePath +
                    "." +
                    (state.exportType === "xls"
                      ? state.exportType + "x"
                      : state.exportType)
                  }`,
                  "_blank"
                );
                props.stopLoading();
                setOpenFormExport(!openFormExport)
                setExportCatalogProducts(false)
                props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
              } else {
                props.stopLoading();
                setExportCatalogProducts(false)
                setOpenFormExport(!openFormExport)
                props.snack(
                  ALERT_ERROR,
                  `Un problème est survenu lors de l'exportation !`
                );
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (e) {
          props.snack(ALERT_ERROR, `L'exportation a échoué !`);
          props.stopLoading();
        }
        setOpenFormExport(!openFormExport);
      };

    const handleToggleDrawer = (stateDrawer, changeWidth) => {
        setState(prev => ({
            ...prev,
            [stateDrawer]: !state[stateDrawer]
        }));

        if (changeWidth) {
            handleDrawerWidthChange(300);
        }
        else {
            handleDrawerWidthChange(-800);
        }
    };

    const handleDrawerWidthChange = (width) => {
        setState(prev => ({
            ...prev,
            drawerWidthModified: width
        }));
    };

    const handleToggleDialog = (stateName) => {
        if (stateName === "openMerchModal") {
            setState(prev => ({
                ...prev,
                changeInMerch: false,
            }))
        }
        setState(prev => ({ ...prev, [stateName]: !state[stateName] }));
    };

    const getAssetMagentoPages = () => {
        let assetList = []

        for (let asset of state.catalogData.assets.edges) {
            assetList.push(asset.node.id);
        }

        props.client.query({
            query: GET_MAGENTO_PAGES_BY_ASSETS_LIST,
            variables: { assetList, useInCategory: true },
            fetchPolicy: 'no-cache',
        }).then(result => {
            setState(prev => ({ ...prev, assetMagentoPages: result.data.assetMagentoPages.edges }));
        }).catch((err) => {
            console.log(err)
        });
    }

    const getContentCategories = () => {
        props.client.query({
            query: GET_CONTENT_CATEGORIES_ONLY,
            fetchPolicy: 'no-cache',
        }).then(result => {
            setState(prev => ({ ...prev, contentCategories: result.data.contentCategories }));
        }).catch((err) => {
            console.log(err)
        });
    }

    const getCatalog = async (avoidLoadingCats) => {
        const productEavType = JSON.parse(localStorage.getItem('GET_EAV_TYPES'))?.data?.eavTypes?.edges.find(e=>e.node.code === "product")?.node?.id
        const allGroups =
        props.attributeGroups?.filter(
          (e) => e.node.attributes.edges?.[0]?.node.eavType.id === productEavType
        )?? [];
        // let pagination = await paginationQueryData.resetPagination(state.pagination.perPage);
        // setState({ ...state,pagination });

        // GET CATALOGS
        /* TODO: Donovan -> Mettre l'info dans le local storage */
        let localStorageCatalog = localStorage.getItem('catalog_' + `/api/catalogs/${props.match.params.id}`);
        if (!localStorageCatalog) {
            props.client.query({
                query: CATALOG_DETAILS_GET_CATALOGS_BY_ID,
                variables: { id: `/api/catalogs/${props.match.params.id}` },
                fetchPolicy: 'no-cache',
            }).then(result => {
                let findCatalog = result.data.catalog;
                props.client.query({
                    query: GET_CATEGORIES_LIGHT_2,
                    variables: {
                        "catalog": findCatalog.id
                    },
                    fetchPolicy: 'no-cache'
                }).then((result) => {
                    setState(prev => ({
                        ...prev,
                        categories: result.data.categories,
                        allGroups
                    }))
                }).catch(err => {
                    console.log(err)
                });
                setCatalog({
                    ...findCatalog,
                    image: findCatalog.media,
                })
                setCatalogForm(findCatalog)
                getCatalogResult(findCatalog, avoidLoadingCats);
            }).catch((err) => {
                console.log(err)
            });
        } else {
            let result = JSON.parse(localStorageCatalog);
            setCatalog(result)
            getCatalogResult(result, avoidLoadingCats);
            setState(prev => ({
                ...prev,
                allGroups
            }))
        }
    };

    const getCatalogResult = (findCatalog, avoidLoadingCats) => {
        let local = moment.tz.guess();
        let toDateStart = moment.tz(findCatalog.startAt, local).format('L');
        let toDateStartNotConverted = moment(findCatalog.startAt).format('YYYY-MM-DD');
        let toDateEnd = moment.tz(findCatalog.endAt, local).format('L');
        let toDateEndNotConverted = moment(findCatalog.endAt).format('YYYY-MM-DD');
        const attrType = props.attributes.product.attributes?.edges.find((item) => item.node.id === 'type');
        setCatalog({
            ...findCatalog,
            image: findCatalog.media,
            catalogFilters: findCatalog.catalogFilters.edges.length !== 0
                // The catalog has filters
                ? findCatalog.catalogFilters.edges?.map((item) => {
                    return {
                        ...item.node,
                        changed: false,
                        checkbox: false,
                        showButton: false,
                        typingChips: null,
                        // new: true,
                        code: item.node.attribute?.id,
                        values: item.node.attributeOption?.id ?? item.node.value,
                        //* There are 2 types of input chips, one for text input using tags and the other for select using chips
                        //* At the moment the back reads them as the same type, so we will use the same type for both (no item.node.attributeOption and typeof value === string)
                        // TODO: When the input is a select with chips, the back needs to send attributeOption instead of value
                        attributeType: item.node.attributeOption && props.attributes.product.attributes?.edges.find((attr) => attr.node.id === item.node.attributeOption.attribute.id)
                        // id: uuidv4(),
                    }
                })
                // The catalog has no filters so we will create a new one with the default values in next steps
                : findCatalog.catalogFilters.edges,
        })
        setState(prev => ({
            ...prev,
            autosync: findCatalog.autosync,
            libelle: findCatalog.libelle,
            //categories: findCatalog.categories,
            description: findCatalog.description,
            catalogId: findCatalog.id,
            catalogIdentifier: findCatalog.identifier,
            status: findCatalog.status,
            imageCatalog: findCatalog.media,
            // media: findCatalog.media,
            catalogData: findCatalog,
            startDateNotConverted: toDateStartNotConverted,
            startDate: toDateStart,
            endDateNotConverted: toDateEndNotConverted,
            endDate: toDateEnd,
            ready: true,
        }));

        // GET PRODUCTS
        // let filteredProducts = [];
        // let idlist = [];
        // for (let category of state.categories) {
        //     console.log("category : ", category)
        //     for (let productCat of category.productCategories.edges) {
        //         // if (filteredProducts.find(value => value.node.id === productCat.node.id)) {
        //         // } else {
        //             idlist.push(productCat.node.product.id.replace('/api/products/', ''));
        //             filteredProducts.push(productCat);
        //         // }
        //     }
        // }

        // setState(prev => ({
        //     ...prev,
        //     idlist,
        //     filteredCat: filteredProducts,
        // }));

        getAssetByCatalogId();

        if (!avoidLoadingCats) {
            initCatalogFilters();
        }

        initAttributs();
        asyncGetCatalogAttributes();

        getContentCategories();
    }

    const errorMapper = () => {
        let string = "";
        let i = 0;
        for (let value of state.requiredValueMapper) {
          if (!value.checked) {
            if (i > 0) {
              string += ", ";
            } else {
              string += " ";
            }
            string += `"${value.label}"`;
            i++;
          }
        }
        if (i === 1) {
          return `Le champ ${string} est requis`;
        } else {
          return `Les champs${string} sont requis`;
        }
      };

    const handleInputChangeExport = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
    
        if (stateName === "importSep") {
          setState({
            ...state,
            upload_CSV: null,
            upload_ZIP: null,
          });
        }
        if (custom === "upload_CSV") {
          setState({
            ...state,
            [custom]: value,
          });
        } else {
          setValue(stateName, value, translated);
        }
      };


    const initCatalogFilters = (reload) => {
        if (reload) {
            props.startLoading()
        }
        props.client.query({
            query: CATALOG_DETAILS_FILTER_GET_CATALOGS_BY_ID,
            variables: { id: `/api/catalogs/${props.match.params.id}` },
            fetchPolicy: 'no-cache',
        }).then(result => {
            let catalogFilters = [];
            let values = null;

            let findCatalog = result.data.catalog;

            if (result.data.catalog.catalogFilters.edges.length > 0) {
                for (let filter of result.data.catalog.catalogFilters.edges) {
                    if (filter.node.attribute.attributeType.input === "select") {
                        if (filter.node.operator === "IN" || filter.node.operator === "NOT IN") {
                            let parsing = JSON.parse(filter.node.value)
                            values = parsing.map(e => `/api/attribute-options/${e}`)
                        } else {
                            values = filter.node.attributeOption.id
                        }
                    } else {
                        if (filter.node.operator === "IN" || filter.node.operator === "NOT IN") {
                            let parsing = JSON.parse(filter.node.value)
                            values = parsing
                        } else {
                            values = filter.node.value
                        }
                    }
                    catalogFilters.push({
                        new: false,
                        changed: false,
                        code: filter.node.attribute.id,
                        values,
                        operator: filter.node.operator,
                        attributeType: { node: filter.node.attribute },
                        id: filter.node.id,
                        checkbox: false,
                        typingChips: null,
                        showButton: false,
                    })
                }
            }

            let newCatalog = { ...catalog }
            newCatalog["catalogFilters"] = catalogFilters
            setCatalog(newCatalog)

            for (let category of findCatalog.categories) {
                category.productCategories = {
                    totalCount: 0,
                    edges: []
                };
            }
            let allCategories = state.allCategories.concat(findCatalog.categories);
            setState(prev => ({ ...prev, allCategories: allCategories ?? null, categories: findCatalog.categories, allCategoriesReady: true }))
            prepareSecondTree(state.categories, true);
            getAssetMagentoPages();
            if (reload) {
                props.stopLoading()
            }
        }).catch((err) => {
            console.log(err)
        });
    };

    const getGroupSelectedAttributes = (group, attr) => {
        return attr?.filter((attribute) => {
            return attribute.node.attributeGroup.edges.findIndex((attrGroup) => {
                return attrGroup.node.id === group.node.id
            })
        })
    }

    const groupWithAttributes = (gr, attr) => gr?.map((group) => {
        return ({
            ...group,
            selectedAttributes: getGroupSelectedAttributes(group, attr)?.map((attribute) => ({
                id: attribute.node.id,
                name: attribute.node.identifier,
                groupId: group.node.id,
            }))
        })
    })

    const asyncGetCatalogAttributes = () => {
        props.client.query({
            query: GET_CATALOGS_BY_ID_ATTRS,
            variables: { id: `/api/catalogs/${props.match.params.id}` },
            fetchPolicy: 'no-cache',
        }).then(result => {
            let findCatalog = result.data.catalog;

            setState(prev => {
                const allGroupsCopy = structuredClone(prev.allGroups);
                const selectedAttributes = findCatalog.attributes?.edges ?? [];

                const groupsWithSelectedAttr = groupWithAttributes(allGroupsCopy, selectedAttributes);

                return {
                    ...prev,
                    // attributes: findCatalog.attributes?.edges.map(e => e.node.id) ?? [],
                    selectedAttributes: findCatalog.attributes?.edges ?? [],
                    groupsWithSelectedAttr: groupsWithSelectedAttr,
                }
            });
        }).catch((err) => {
            console.log(err)
        });
    };

    const getAssetByCatalogId = () => {
        props.client.query({
            query: COUNT_ASSETS_BY_CATALOG_ID,
            variables: { catalog: `/api/catalogs/${props.match.params.id}` },
            //fetchPolicy: 'no-cache',
        }).then(result => {
            // let listAsset = [];
            // if(result.data.assets.edges.length>0){
            //     for(let asset of result.data.assets.edges){
            //         listAsset.push(asset)
            //     }
            // }
            let listAsset = [];
            let countAssets = result.data.assets.totalCount;
            setState(prev => ({ ...prev, listAsset }));
            setState(prev => ({ ...prev, countAssets }));
        }).catch((err) => {
            console.log(err)
        });
    };

    const getFiltersCatalogs = () => {
        props.client.query({
            query: GET_CATALOG_FILTERS,
            fetchPolicy: 'no-cache',
        }).then(result => {
            let filtersList = result.data.catalogFilters;
        }).catch((err) => {
            console.log(err)
        });
    };

    const handleGetCatalogsTypes = () => {

        let localStorageAllCatalogsTypes = localStorage.getItem('allCatalogsTypes');
        if (!localStorageAllCatalogsTypes) {
            props.client.query({
                query: GET_CATALOG_TYPES,
                fetchPolicy: 'no-cache'
            }).then(result => {
                let allCatalogsTypes = result.data.catalogTypes.edges;
                localStorage.setItem('allCatalogsTypes', JSON.stringify(allCatalogsTypes));

                setState(prev => ({ ...prev, allCatalogsTypes }))
            }).catch((err) => {
                console.log(err)
            });
        } else {
            let allCatalogsTypes = JSON.parse(localStorageAllCatalogsTypes);
            setState(prev => ({ ...prev, allCatalogsTypes }))
        }

    };

    const handleLang = (event) => {
        // setState({ ...state,currentLang: event.target.value }, () => {
        //     eventService.fire();
        // });
        setState(prev => ({ ...prev, currentLang: event.target.value }));
        eventService.fire();
        prepareSecondTree(state.categories)
    };

    const setValue = (stateName, value, translated) => {
        if (stateName === "catalogType") {
            let getNewType = state.allCatalogsTypes.find(e => e.node.id === value)
            setState(prev => ({
                ...prev,
                catalog: {
                    ...prev.catalog,
                    [stateName]: value,
                },
                type: getNewType.node ?? null
            }))
        }
        if (translated) {
            let values = state[props.locales[0].node.code];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            setState(prev => ({
                ...prev,
                [props.locales[0].node.code]: values,
            }));
        } else {
            setCatalog(prev => ({
                ...prev,
                [stateName]: value,
            }))
            setState(prev => ({
                ...prev,
                [stateName]: value,
            }));
        }
    };

    const handleInputChange = (stateName, evt, custom, translated) => {
        if (custom) {
            if (custom.props) {
                setState(prev => ({ ...prev, catalog: { ...prev.catalog, [stateName]: custom.props }.value }));
            } else {
                setState(prev => ({ ...prev, ...custom }));
            }
        }
        else {
            if (stateName === 'groupsWithSelectedAttr') {
                const { listingId, checkedValues } = evt;
                const copyOfGroupsWithSelectedAttr = structuredClone(state.groupsWithSelectedAttr);

                const group = copyOfGroupsWithSelectedAttr.find(group => group.node.id === listingId);

                if (group) {
                    group.selectedAttributes = checkedValues
                }

                setState(prev => ({
                    ...prev,
                    [stateName]: copyOfGroupsWithSelectedAttr
                }));
            } else {
                const value = evt?.target?.value ?? evt;
                setValue(stateName, value, translated);
            }
        }
    };

    const handleMediaPicker = (selected, stateName) => {
        if (stateName === 'image') {
            setCatalog({
                ...catalog,
                image: {
                    filePath: selected?.target?.value ?? selected
                }
            })
            handleInputChange(stateName, selected, null, false);
            // handleInputChange(stateName, selected, null, false);
        }
        else {
            handleInputChange(stateName, selected, null, true);
        }
    };

    const setExpanded = (all, node) => {
        if (node) {
            all[node.id] = node.expanded;

            for (let child of node.children) {
                setExpanded(all, child);
            }
        }
    };

    const storeDraggedCats = (categoriesToAdd, category, parent) => {
        let foundCat = state.categories.find(e => e.id === category.id);

        if (!foundCat) {
            // set data format
            category = CopyWithJSON(category);
            category.fromLeft = true;
            category.libelle = `${category.libelle}_${state.catalogIdentifier}`;

            let attributes = [];

            for (let { node } of category.categoryDatas.edges) {
                let found = false;

                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;

                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.value,
                            media: {
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id // category data id
                        });
                    }
                }

                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        locales: [{
                            value: node.value,
                            media: {
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id
                        }]
                    });
                }
            }

            category.attributes = attributes;

            categoriesToAdd.push(category);
        }

        // set correct parent
        category.parent = parent ? {
            id: parent
        } : null;

        for (let child of category.children) {
            storeDraggedCats(categoriesToAdd, child, category.id);
        }
    };

    const modifyCategoryAttributes = category => {
        for (let attribute of props.attributes.category.attributes.edges) {
            let isMedia = attribute.node.attributeType.input === 'image';

            for (let locale of props.locales) {
                let value = state[locale.node.code][attribute.node.identifier];
                let catAttribute = category.attributes?.find(e => e.id === attribute.node.id);

                if (catAttribute) {
                    let catAttributeLocale = catAttribute.locales.find(e => e.code === locale.node.code);

                    if (catAttributeLocale) {
                        catAttributeLocale.value = isMedia ? null : value;
                        catAttributeLocale.media = isMedia ? {
                            ...catAttributeLocale.media,
                            ...value
                        } : null;
                    } else if (value) {
                        catAttribute.locales.push({
                            value: isMedia ? null : value,
                            media: isMedia ? value : null,
                            id: locale.node.id,
                            code: locale.node.code
                        });
                    }
                } else if (value) {
                    category.attributes.push({
                        id: attribute.node.id,
                        identifier: attribute.node.identifier,
                        attributeType: attribute.node.attributeType,
                        locales: [{
                            value: isMedia ? null : value,
                            media: isMedia ? value : null,
                            id: locale.node.id,
                            code: locale.node.code
                        }]
                    });
                }
            }
        }
        return category;
    };

    const modifyCategoryFilters = category => {
        for (let filter of state.catFilters) {
            if (filter.new) {
                category.filters.push(filter)
            } else if (!filter.new && (filter.changed || filter.deleted)) {
                let getCatFilters = category.filters.find(e => e.id === filter.id)
                getCatFilters = filter
            }
        }
        return category
    };

    const handleMutationCategories = async () => {
        if (hasErrors()) {
            props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            setState(prev => ({ ...prev, seeErrors: true }));
            eventService.fire();
            return false;
        }
        props.startLoading();

        switch (state.editForm) {
            case 'edit':
                for (let editCat of state.categories) {
                    if (editCat.id === state.cat_id) {
                        editCat.status = state.catActivated
                        editCat.edited = true;
                        editCat.id = state.currentNode.id;
                        editCat.libelle = state.identifier;
                        editCat.parent = { id: state.parent };

                        if (state.typeCategorie === 'cms' && state.cmsPage) {
                            editCat.cmsPage = state.cmsPage;
                        }

                        if (state.typeCategorie === 'content' && state.categoryContent) {
                            editCat.contentCategory = state.categoryContent;
                        }

                        await modifyCategoryFilters(editCat);
                        await modifyCategoryAttributes(editCat);

                        setState(prev => ({
                            ...prev,
                            categories: prev.categories ?? null
                        }));
                        await prepareSecondTree(state.categories, false, true, true);
                    }
                }

                props.snack(ALERT_SUCCESS, "Modification effectuée avec succès")

                break;
            case 'add':
                let newCat = {
                    new: true,
                    attributes: [],
                    categoryDatas: { edges: [] },
                    libelle: state.identifier,
                    parent: { id: state.parent },
                    products: { edges: [] },
                    status: state.catActivated,
                    id: uuidv4(),
                };


                let variables = {
                    libelle: state.identifier,
                    parent: state.parent,
                    catalog: `/api/catalogs/${props.match.params.id}`,
                    master: state.categoryMaster,
                    status: state.catActivated,
                    type: state.typeCategorie,
                };

                if (state.typeCategorie === 'cms') {
                    variables.cmsPage = state.cmsPage;
                }

                if (state.typeCategorie === 'content') {
                    variables.contentCategory = state.categoryContent;
                }

                let MUTATION_RESULT = await props.client.mutate({
                    mutation: ADD_CATEGORY,
                    variables
                });

                newCat.id = MUTATION_RESULT.data.createCategory.category.id;
                newCat.new = false;
                newCat = await modifyCategoryAttributes(newCat);

                await handleSaveFilters(newCat)
                await saveAttributes(newCat, newCat);

                setState(prev => ({
                    // idCatSave: newCat.id,
                    ...prev,
                    categories: [...prev.categories, newCat],
                }));

                await prepareSecondTree([...state.categories, newCat], false, false);
                break;
            default: return;
        }
        prepareFirstTree(true);
        handleToggleDrawer('openFormCategories', false);
    };

    const processCategory = (category, parent = null, allCategories) => {
        return new Promise(async (resolve, reject) => {
            if (category.id === state.cat_id) {
                let query = null;
                let variables = null;

                if (category.new || category.fromLeft) {
                    query = ADD_CATEGORY;
                    variables = {
                        libelle: category.libelle,
                        parent,
                        catalog: `/api/catalogs/${props.match.params.id}`,
                        master: category.fromLeft ? category.id : state.categoryMaster,
                        status: category.status,
                    };

                    let products = state.selectedProductByCategorie[category.id];

                    if (products && products.length)
                        variables.products = products;
                } else {
                    query = UPDATE_CATEGORY;
                    variables = {
                        id: category.id,
                        libelle: category.libelle,
                        parent: state.parent,
                        status: category.status,
                        master: state.categoryMaster
                    };

                    if (state.typeCategorie) {
                        variables.type = state.typeCategorie;
                    }
                    if (state.cmsPage) {
                        variables.cmsPage = state.cmsPage;
                    }

                    if (state.typeCategorie === 'content') {
                        variables.contentCategory = state.categoryContent;
                    }

                    let products = state.selectedProductByCategorie[category.id];

                    if (products && products.length)
                        variables.products = products;
                }

                const MUTATION_RESULT = await props.client.mutate({
                    mutation: query,
                    variables
                });

                await handleSaveFilters(category)

                let uptodate = !category.new && !category.fromLeft
                    ? MUTATION_RESULT.data.updateCategory.category
                    : MUTATION_RESULT.data.createCategory.category;

                await saveAttributes(category, uptodate);

                allCategories.push(uptodate.id);

                for (let child of category.children) {
                    await processCategory(child, uptodate.id, allCategories);
                }
            } else {
                if (category.new) { }
                for (let child of category.children) {
                    await processCategory(child, category.id, allCategories);
                }
            }
            resolve();
        });
    };

    const handleSaveFilters = async (category) => {
        let queryFilter = null;
        let variablesFilter = null;
        if (state.catFilters[0].operator && state.catFilters[0].code && state.catFilters[0].values[0]) {
            for (let filter of state.catFilters) {
                let value = null;
                let attributeOption = null;
                if (filter.attributeType?.node.attributeType.input === "select") {
                    if (filter.operator === "IN" || filter.operator === "NOT IN") {
                        if (Array.isArray(filter.values)) {
                            let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                            value = JSON.stringify(arrayRegex)
                        } else {
                            attributeOption = filter.values
                        }
                    } else {
                        attributeOption = filter.values
                    }
                } else {
                    if (filter.operator === "IN" || filter.operator === "NOT IN") {
                        value = JSON.stringify(filter.values)
                    } else {
                        value = filter.values
                    }
                }
                if (filter.new) {
                    queryFilter = CREATE_CATEGORY_FILTER;
                    variablesFilter = {
                        operator: filter.operator,
                        createdAt: moment(),
                        category: category.id,
                        attribute: filter.code,
                        attributeOption,
                        value
                    }
                } else if (filter.changed && !filter.deleted) {
                    queryFilter = UPDATE_CATEGORY_FILTER;
                    variablesFilter = {
                        id: filter.id,
                        operator: filter.operator,
                        category: category.id,
                        attribute: filter.code,
                        attributeOption,
                        value
                    }
                } else if (filter.deleted) {
                    queryFilter = DELETE_CATEGORY_FILTER;
                    variablesFilter = {
                        id: filter.id,
                    }
                }
                if (queryFilter && variablesFilter) {
                    await props.client.mutate({
                        mutation: queryFilter,
                        variables: variablesFilter
                    });
                }
            }
            props.snack(ALERT_SUCCESS, 'La catégorie a bien été crée');
        } else {
            props.snack(ALERT_WARNING, 'Aucun filtre appliqué, la catégorie a bien été crée');
        }
    }

    const resetState = () => {
        setState(prev => ({
            ...prev,
            identifier: '',
            parent: '',
            errors: {},
            seeErrors: false,
            categoryMaster: null,
            catalogSelected: null,
            catFilters: [],
            typeCategorie: 'category',
            contentCategory: null
        }));
    }

    const handleCancel = () => {
        getCatalog();
    };

    const handleRecursiveCat = (breadcrumb) => {
        setState(prev => ({
            ...prev,
            activeCategorie: [...prev.activeCategorie, 'Home']
        }))
        for (let categorie of breadcrumb) {
            let translatedLibelle = categorie.categoryDatas.find(e => e.locale.code === props.locales[0].node.code)
            if (translatedLibelle) {
                setState(prev => ({
                    ...prev,
                    activeCategorie: [...prev.activeCategorie, ` > ${translatedLibelle.value}`]
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    activeCategorie: [...prev.activeCategorie, ` > ${categorie.libelle}`]
                }))
            }
        }
    };

    const getChildrenCat = (id) => {
        for (let categorie of state.categoriesShowed) {
            if (categorie.id === id) {
                for (let child of categorie?.childrens) {
                    setState(prev => ({
                        ...prev,
                        arrayCatFilter: [...prev.arrayCatFilter, child.id]
                    }))
                }
            }
        }
    };

    const handleButtonGroupChange = (stateName, value) => {
        // setState({ ...state,[stateName]: value });
        setCatalog({ ...catalog, [stateName]: value });
    };

    const deleteMutationCategory = () => {
        props.startLoading();
        if (!state.currentNode.new && !state.currentNode.fromLeft) {
            props.client.mutate({
                mutation: DELETE_CATEGORY,
                variables: { id: state.cat_id }
            }).then(() => {
                props.stopLoading();
                props.snack(ALERT_SUCCESS, 'Catégorie supprimée');

                removeNode(state.cat_id);
                handleToggleDrawer('openFormCategories', false);
                handleToggleDialog('openDialogCategory');
                resetState();
            }).catch(error => {
                props.stopLoading();
                props.snack(ALERT_ERROR, `Impossible de supprimer la catégorie, veuillez vérifier qu'elle n'est pas utilisée`);
                handleToggleDialog('openDialogCategory');
            });
        } else {
            props.stopLoading();
            props.snack(ALERT_SUCCESS, 'Catégorie supprimée');

            removeNode(state.cat_id);
            handleToggleDrawer('openFormCategories', false);
            handleToggleDialog('openDialogCategory');
            resetState();
        }
    };

    const removeNode = (id) => {
        let allCategories = state.categories;
        allCategories = state.categories.filter(e => e.id !== id);

        setState(prev => ({
            ...prev,
            categories: allCategories ?? null
        }))
        prepareSecondTree(allCategories);
    };

    const handleFormError = (stateName, error) => {
        let errors = state.errors;

        errors[stateName] = error;

        setState(prev => ({ ...prev, errors: errors }));
    };

    const hasErrors = () => {
        if (state.errors) {
            for (let error in state.errors) {
                if (state.errors[error])
                    return true;
            }
        }

        return false;
    };

    // SAVE TREE
    const copyCategories = (nodeInfo) => {
        setState(prev => ({ ...prev, openDialogTreeView: true, copyCategories: nodeInfo }));
    };

    const selectCategorie = (nodeInfo) => {
        setState(prev => ({ ...prev, selectCategorie: nodeInfo }));
    };

    const validateNewCategorie = () => {

        props.startLoading();
        let data = {};
        data.idCategory = parseInt(state.copyCategories.node.id.replace('/api/categories/', ''));
        data.idParent = parseInt(state.selectCategorie.node.id.replace('/api/categories/', ''));

        request(`${process.env.REACT_APP_API}/categories/copy`, 'post', data).then(response => {
            if (response.success) {
                props.client.query({
                    query: GET_CATALOGS_CATEGORIES_BY_ID,
                    variables: { id: `/api/catalogs/${props.match.params.id}` },
                    fetchPolicy: 'no-cache',
                }).then(result => {
                    let findCatalog = result.data.catalog;
                    let allCategories = state.categoriesSpread.concat(findCatalog.categories)
                    setState(prev => ({
                        ...prev,
                        categories: findCatalog.categories,
                        openDialogTreeView: false,
                        copyCategories: null,
                        selectCategorie: null,
                        allCategories: allCategories ?? null
                    }));
                    prepareSecondTree(findCatalog.categories, true);
                    props.stopLoading();
                }).catch((err) => {
                    console.log(err)
                });
            }
            else {
                props.stopLoading();
            }
        }).catch((err) => {
            console.log(err)
        });
    };

    const addSubcategory = (nodeInfo) => {
        resetState();

        for (let locale of props.locales) {
            setState(prev => ({
                ...prev,
                [locale.node.code]: {}
            }));
        }

        setState(prev => ({
            ...prev,
            openFormCategories: true,
            editForm: 'add',
            parent: nodeInfo.id,
            catActivated: true,
        }));
    };

    const deleteCat = (id) => {
        props.startLoading()
        props.client.mutate({
            mutation: DELETE_CATEGORY,
            variables: { id: id },
        }).then(() => {
            initCatalogFilters()
            // props.stopLoading()
            props.snack(ALERT_SUCCESS, 'La catégorie a été supprimé');
        }).catch((err) => {
            console.log(err)
            props.snack(ALERT_ERROR, 'Une erreur est survenue pendant la suppression');
        })
    }

    const editCat = async (nodeInfo) => {
        resetState();
        props.startLoading();
        let result = await props.client.query({
            query: GET_CATALOGS_BY_ID_CAT_FILTERS,
            variables: { id: `/api/catalogs/${props.match.params.id}`, categoryId: nodeInfo.id.replace('/api/categories/', '') },
            fetchPolicy: 'no-cache',
        });

        let catalogSelected = null;

        if (nodeInfo.master?.id) {
            let result2 = await props.client.query({
                query: GET_CATEGORIES_BY_ID_LIST_LIGHT,
                variables: { idList: [nodeInfo.master.id.replace('/api/categories/', '')] },
                fetchPolicy: 'no-cache',
            });
            catalogSelected = result2.data.categories[0].catalog.id
        }

        props.stopLoading();

        let findCategory = result.data.catalog?.categories?.[0];
        let categoryFilters = findCategory?.categoryFilters?.edges;

        let filters = [];

        if (categoryFilters && categoryFilters.length > 0) {
            for (let filter of categoryFilters) {
                if (filter) {
                    let getFilterData = {
                        ...filter.node,
                        attributeType: { node: filter.node.attribute }
                    }

                    filters.push(getFilterData);
                }
            }
        }

        nodeInfo.filters = filters;

        for (let locale of props.locales) {
            let values = {};

            for (let attribute of nodeInfo.attributes) {
                for (let attributeLocale of attribute.locales) {
                    if (attributeLocale.code === locale.node.code) {
                        switch (attribute.attributeType.input) {
                            case 'image':
                                values[attribute.identifier] = {
                                    data: attributeLocale.media ? attributeLocale.media.data : null,
                                    file: attributeLocale.media ? attributeLocale.media.file : null,
                                    changed: attributeLocale.media ? attributeLocale.media.changed : null
                                };
                                break;
                            default:
                                values[attribute.identifier] = attributeLocale.value;
                        }
                    }
                }
            }

            setState(prev => ({
                ...prev,
                [locale.node.code]: values
            }));
        }
        let catFilters = [];
        let values = null;
        if (nodeInfo.filters.length > 0) {
            for (let filter of nodeInfo.filters) {
                if (filter.attribute.attributeType.input === "select") {
                    if (filter.operator === "IN" || filter.operator === "NOT IN") {
                        if (Array.isArray(filter.values)) {
                            let parsing = JSON.parse(filter.value)
                            values = parsing.map(e => `/api/attribute-options/${e}`)
                        } else {
                            values = filter.attributeOption.id
                        }
                    } else {
                        values = filter.attributeOption.id
                    }
                } else {
                    if (filter.operator === "IN" || filter.operator === "NOT IN") {
                        let parsing = JSON.parse(filter.value)
                        values = parsing
                    } else {
                        values = filter.value
                    }
                }
                catFilters.push({
                    new: false,
                    changed: false,
                    code: filter.attribute.id,
                    values,
                    operator: filter.operator,
                    attributeType: { node: filter.attribute },
                    id: filter.id,
                    checkbox: false,
                    typingChips: null,
                    showButton: false,
                })
            }
        }

        setState(prev => ({
            ...prev,
            currentNode: nodeInfo,
            typeCategorie: nodeInfo.type,
            cmsPage: nodeInfo.cmsPage?.id,
            categoryContent: nodeInfo.contentCategory?.id,
            catFilters,
            openFormCategories: true,
            editForm: 'edit',
            cat_id: nodeInfo.id,
            identifier: nodeInfo.libelle,
            catActivated: nodeInfo.status,
            categoryMaster: nodeInfo.master?.id,
            catalogSelected: catalogSelected,
            parent: nodeInfo.parent === null ? nodeInfo.id : nodeInfo.parent.id,
        }));
    };

    const handleTreeChange = (stateName, value) => {
        setValue(stateName, value);
        let all = state.expanded;

        setExpanded(all, value[0]);

        setState(prev => ({ ...prev, expanded: all ?? null }));

    };

    const getAttributeTranslatedValue = (attributes, id, lang) => {
        let attribute = attributes?.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    const getTranslatedValueFromData = (datas, id, lang) => {
        let data = datas?.find(e => e.node.locale.id === lang && e.node.attribute.id === id);

        if (!data)
            return null;

        return data.node;
    };

    const saveAttributes = (category, uptodate) => {
        return new Promise(async (resolve, reject) => {
            for (let attribute of props.attributes.category.attributes.edges) {
                for (let locale of props.locales) {
                    let formValue = getAttributeTranslatedValue(category.attributes, attribute.node.id, locale.node.id);
                    let currentTranslation = getTranslatedValueFromData(category.categoryDatas?.edges, attribute.node.id, locale.node.id);

                    if (formValue) {
                        let resultMedia = null;
                        let isMedia = attribute.node.attributeType.input === 'image';

                        if (isMedia) {
                            if (formValue.media && formValue.media.changed) {
                                // means we have a new media
                                let formData = new FormData();

                                formData.append('file', formValue.media.file);
                                formData.append('type', formValue.media.type);

                                resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                            } else if (category.fromLeft && formValue.media.id) {
                                // means we need to duplicate media
                                let id = formValue.media.id.replace('/api/media-objects/', '');
                                let duplicate = await axios(`${process.env.REACT_APP_API}/media-objects/${id}/duplicate`, 'put');
                                resultMedia = { id: duplicate.response };
                            }
                        }

                        if (currentTranslation && !category.fromLeft && !category.new) {
                            // UPDATE STEP 

                            try {
                                if (isMedia && resultMedia) // remove the old media
                                    await axios(`${process.env.REACT_APP_API}/media-objects/${currentTranslation.media.id.replace('/api/media-objects/', '')}`, 'delete');
                            } catch (e) {
                                console.log(e);
                            }

                            if (!isMedia && currentTranslation.value === formValue.value)
                                continue; // no change

                            if (isMedia && currentTranslation.media.id === formValue.media.id)
                                continue; // no change

                            let variables = {
                                "id": currentTranslation.id,
                                "category": uptodate.id,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.media.id;
                            }
                            if (!isMedia)
                                variables.value = formValue.value;

                            await props.client.mutate({
                                mutation: UPDATE_CATEGORY_DATA,
                                variables
                            });
                        } else {
                            // CREATE STEP
                            let variables = {
                                "category": uptodate.id,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.media.id;
                            }
                            if (!isMedia)
                                variables.value = formValue.value;

                            let addDataResult = await props.client.mutate({
                                mutation: ADD_CATEGORY_DATA,
                                variables
                            });

                            if (!category?.categoryDatas?.edges) {
                                category = {
                                    categoryDatas: {
                                        edges: []
                                    }
                                }
                            }

                            category.categoryDatas.edges.push({
                                node: addDataResult?.data?.createCategoryData?.categoryData
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP
                        await props.client.mutate({
                            mutation: DELETE_CATEGORY_DATA,
                            variables: { "id": currentTranslation.id },
                            refetchQueries: [{
                                query: GET_CATEGORIES_ONLY,
                                variables: {
                                    "exists": [{
                                        "catalog": false
                                    }]
                                },
                            }]
                        });
                    }
                }
            }
            resolve();
        });
    };

    const handlerMutation = async (attr = false) => {
        props.startLoading();

        const filtered = state.groupsWithSelectedAttr?.map(group => group.selectedAttributes);
        const newArr = groupBy(filtered.flat(), 'id');

        const newObject = {};

        for (const key in newArr) {
            if (newArr.hasOwnProperty(key)) {
                newObject[key] = newArr[key].map(item => item.groupId);
            }
        }

        const attributesIds = Object.keys(newObject);

        // ! Error 500 when updating a lot of attributes at same time because of network overload
        // TODO: Instead of adding a list of groups IDs to every attribute, do it the other way around
        new Promise(async (resolve, reject) => {
            attributesIds.forEach(id => {
                props.client.mutate({
                    mutation: UPDATE_ATTRIBUTE,
                    variables: {
                        "id": id,
                        "attributeGroup": newObject[id],
                    }
                });
            });

            resolve();
        });

        let variables = {
            "id": `/api/catalogs/${props.match.params.id}`,
            "identifier": catalog.id,
            "libelle": catalog.libelle,
            "description": catalog.description,
            "status": catalog.status,
            "autosync": catalog.autosync,
            "startAt": catalog.startDateNotConverted,
            "endAt": catalog.endDateNotConverted,
        };

        if (attributesIds)
            variables.attributes = attributesIds;

        if (catalog.image?.id)
            variables.media = catalog.image?.id;

        props.client.mutate({
            mutation: UPDATE_CATALOG,
            variables
        }).then(async (result) => {
            for (let filter of catalog.catalogFilters) {
                let value = null;
                let attributeOption = null;

                if (filter.attributeType?.node.attributeType.input === "select") {
                    if (filter.operator === "IN" || filter.operator === "NOT IN") {
                        // let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                        // value = JSON.stringify(arrayRegex)
                        value = JSON.stringify(filter.values)
                    } else {
                        attributeOption = filter.values
                    }
                } else {
                    if (filter.operator === "IN" || filter.operator === "NOT IN") {
                        value = JSON.stringify(filter.values)
                    } else {
                        value = filter.values
                    }
                }

                let queryFilter = null;
                let variablesFilter = null;

                if (filter.new) {
                    queryFilter = CREATE_CATALOG_FILTER;
                    variablesFilter = {
                        operator: filter.operator,
                        createdAt: moment(),
                        catalog: catalog.id,
                        attribute: filter.code,
                        attributeOption,
                        value
                    }
                } else if (filter.changed && !filter.deleted) {
                    queryFilter = UPDATE_CATALOG_FILTER;
                    variablesFilter = {
                        id: filter.id,
                        operator: filter.operator,
                        catalog: catalog.id,
                        attribute: filter.code,
                        attributeOption,
                        value
                    }
                } else if (filter.deleted) {
                    queryFilter = DELETE_CATALOG_FILTER;
                    variablesFilter = {
                        id: filter.id,
                    }
                }

                if (queryFilter && variablesFilter) {
                    await props.client.mutate({
                        mutation: queryFilter,
                        variables: variablesFilter
                    });
                }
            }

            if (attr) {
                props.snack(ALERT_SUCCESS, 'Les attributs du catalogue ont été modifiés');
                handleToggleDrawer('openFormAttributs');
            } else {
                props.snack(ALERT_SUCCESS, 'Le catalogue a été modifié');
                setState(prev => ({ openEditForm: !prev.openEditForm }));
            }

            props.stopLoading();
            // resetStateCatalog();
            getCatalog();
        }).catch((err) => {
            console.log(err)
        });
    };

    const handlerMutationProducts = () => {

        props.startLoading();
        let idListState = [...state.idlist];
        let formatedDatas = {};
        formatedDatas.categories = [];

        for (const [key, value] of Object.entries(state.selectedProductByCategorie)) {
            let element = {};
            element.idCategory = parseInt(key.replace('/api/categories/', ''));
            let idList = _.clone(state.selectedProductByCategorie[key]);
            let i = 0;
            if (idList) {
                for (let i = 0; i < idList.length; i++) {
                    const element = idList[i];
                    idList[i] = parseInt(idList[i].replace('/api/products/', ''));
                    for (let j = 0; j < idListState.length; j++) {
                        if (parseInt(idListState[j]) === idList[i]) {
                            idListState.splice(j, 1);
                        }
                    }
                }
                element.products = idList;
            }
            if (idList.length > 0) {
                formatedDatas.categories.push(element);
            }
        }

        request(`${process.env.REACT_APP_API}/categories/remove-product`, 'post', formatedDatas)
            .then(async (response) => {
                setState(prev => ({ ...prev, openForm: false }));
                let pagination = await Pagination.resetPagination(state.pagination.perPage);
                setState(prev => ({
                    ...prev,
                    pagination,
                    selectedCategorie: '',
                    selectedProductByCategorie: [],
                    reloadProductList: true,
                }));
                getCatalog(true);
                props.stopLoading();
            }).catch((err) => {
                console.log(err)
            });
    };
    // -- END SAVE TREE

    const initProductByCategories = () => {
        let productByCategorie = state.productByCategorie || {};
        let selectedProductByCategorie = state.selectedProductByCategorie || [];
        for (let categorie of state.categories) {
            // let created = selectedProductByCategorie[categorie.id] === undefined;

            productByCategorie[categorie.id] = productByCategorie[categorie.id] || [];
            selectedProductByCategorie[categorie.id] = selectedProductByCategorie[categorie.id] || [];
            if (categorie.productCategories?.edges?.length > 0) {
                for (let product of categorie.productCategories.edges) {
                    let found = productByCategorie[categorie.id].find(e => e.node.id === product.node.product.id);
                    // let foundSelected   = selectedProductByCategorie[categorie.id].find(e => e === product.node.product.id);

                    if (!found)
                        productByCategorie[categorie.id].push(product);

                    // if (!foundSelected && created)
                    //     selectedProductByCategorie[categorie.id].push(product.node.product.id);
                }
            }
        }
        setState(prev => ({
            ...prev,
            productByCategorie,
            selectedProductByCategorie,
            selectedCategorie: '',
        }));
    }

    const resetStateCatalog = () => {
        initProductByCategories()
        setState(prev => ({
            ...prev,
            selectedCategorie: '',
            categoryMaster: null,
            catalogSelected: null,
            openEditForm: false,
        }));
    };

    const setSelectedProducts = values => {
        let productOfCategorie = state.selectedProductByCategorie;
        productOfCategorie[state.selectedCategorie] = values;

        setState(prev => ({
            ...prev,
            selectedProductByCategorie: productOfCategorie ?? null
        }));

    };

    const expandNodes = (expanded, stateName) => {
        setState(prev => ({
            ...prev,
            [stateName]: toggleExpandedForAll({
                treeData: prev[stateName],
                expanded
            })
        }));
    };

    const handleChangeCategorie = async (id) => {
        let pagination = await Pagination.resetPagination(state.paginationCheckboxItem.perPage);
        setState(prev => ({
            ...prev,
            paginationCheckboxItem: pagination,
            selectedCategorie: id,
            load: true,
        }));
        handleGetProductsPagination('no-cache');
    };

    const handleGetProductsPagination = async (policy = "cache-first") => {
        let paginationVars = await Pagination.paginationQueryData(state.paginationCheckboxItem);
        let variables = { ...paginationVars };
        variables.id = `/api/catalogs/${props.match.params.id}`;
        if (state.selectedCategorie) {
            variables.idlist = state.selectedCategorie.replace('/api/categories/', '');
        }
        props.client.query({
            query: PRODUCTS_BY_CATALOGS_CAT_PAGINATION,
            fetchPolicy: policy,
            variables
        }).then(async result => {
            if (result.data.catalog.categories.length > 0) {
                let pagination = await Pagination.updatePaginationData(state.paginationCheckboxItem, result.data.catalog.categories[0].productCategories);
                let products = state.productByCategorie;
                products[state.selectedCategorie] = result.data.catalog.categories[0].productCategories.edges;
                setState(prev => ({ ...prev, productByCategorie: products ?? null, paginationCheckboxItem: pagination ?? null, load: false }));
            }
        }).catch((err) => {
            console.log(err)
        });
    };

    const addProducts = (items) => {
        props.startLoading();
        request(`${process.env.REACT_APP_API}/categories/add-product`, 'post', items)
            .then(async (response) => {
                setState(prev => ({ ...prev, openProductSelector: false }));
                let pagination = await Pagination.resetPagination(state.paginationCheckboxItem.perPage);
                setState(prev => ({
                    ...prev,
                    paginationCheckboxItem: pagination,
                    reloadProductFormList: true,
                }));
                handleGetProductsPagination('no-cache');
                props.stopLoading();
            }).catch((err) => {
                console.log(err)
            });
    };

    const getCatalogTypeName = (identifier) => {
        switch (identifier) {
            case 'permanent':
                return 'Permanent';
            case 'ephemeral':
                return 'Éphémère';
            case 'thematic':
                return 'Thématique';
            default: return 'n/a';
        }
    };

    const initAttributs = () => {
        // * If the drawer has an empty list of attributes, logout, empty the localStorage and re login to fetch the attributes and store them well in the localStorage and redux
        setState(prev => ({ ...prev, allGroups: props.attributeGroups.filter(e => (!e.node.isSystem) || e.node.identifier === 'default') }));
    }

    const updatePositionCategoriesCatalog = async (elements, parent, node) => {
        let found = elements.find(e => e.id === node.id);

        if (!found) {
            for (let element of elements) {
                await updatePositionCategoriesCatalog(element.children, element.id, node);
            }
        } else {
            let position = 10;

            for (let element of elements) {
                await props.client.mutate({
                    mutation: UPDATE_CATEGORY_POSITION,
                    variables: { id: element.id, position, parent },
                });

                position = position + 10;
            }
        }
    };

    const handlerUpdatePositionCategoriesCatalog = async (treeData, node, nextTreeIndex) => {
        props.startLoading();

        await updatePositionCategoriesCatalog(treeData[0].children, treeData[0].id, node);

        props.stopLoading();
    };
    
    useEffect(() => {
        checkRouting(props)
        getCatalog(true);
    }, [reloadListing])
    
    useEffect(() => {
        let local = moment.tz.guess();
        let toDateStart = moment.tz(state.startDateNotConverted, local).format('L');
        setState(prev => ({
            ...prev,
            startDate: toDateStart ?? null,
        }));
    }, [state.startDateNotConverted])

    useEffect(() => {
        let local = moment.tz.guess();
        let toDateEnd = moment.tz(state.endDateNotConverted, local).format('L');
        setState(prev => ({
            ...prev,
            endDate: toDateEnd ?? null,
        }));
    }, [state.endDateNotConverted])
    
    useEffect(() => {
        handleGetCatalogsTypes();
        prepareFirstTree(true);
    }, [state.ready])
    
    useEffect(() => {
        setState(prev => ({ ...prev, reloadTreeCategories: uuidv4() }));
        prepareSecondTree(state.categories, true);
    }, [state.categories])
    
    const prepareFirstTree = (init) => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_CATEGORIES_LIGHT_2,
                variables: {
                    "exists": [{
                        "catalog": false
                    }]
                },
                fetchPolicy: 'no-cache'
            }).then(result => {
                setState(prev => ({
                    ...prev,
                    categoriesSpread: result.data.categories,
                }));
                processTree('treeData', result.data.categories, e => e.parent === null && e.libelle === 'Root', init);
                resolve();
            }).catch((err) => {
                console.log(err)
            });
        });
    }

    const prepareSecondTree = async (categories, init, exe = false, skipFetchCategories = false) => {
        categories = categories.sort(function (a, b) {
            return a.position - b.position;
        })

        await processTree('secondTreeData', categories, e => e.parent === null, init);

        if (exe) {
            let root = state.secondTreeData[0];
            let allCategories = [root.id];

            for (let child of root.children) {
                await processCategory(child, root.id, allCategories);
            }

            if (!skipFetchCategories) {
                props.client.query({
                    query: GET_CATALOGS_CATEGORIES_BY_ID,
                    variables: { id: `/api/catalogs/${props.match.params.id}` },
                    fetchPolicy: 'no-cache',
                }).then(result => {
                    let findCatalog = result.data.catalog;
                    setState(prev => ({
                        ...prev,
                        categories: findCatalog.categories,
                        openDialogTreeView: false,
                    }));
                    prepareSecondTree(state.categories, true);
                    props.stopLoading();
                }).catch((err) => {
                    console.log(err)
                });
            } else {
                props.stopLoading();
            }
        } else {
            props.stopLoading();
        }
    }

    const processTree = (stateName, categories, getRoot, init = false) => {
        return new Promise(async (resolve, reject) => {
            let data = categories.filter(getRoot);

            for (let parent of data) {
                convertToNode(parent, true, init);
                populateChildren(categories, parent, init);
            }

            if (stateName === 'secondTreeData') {
                initProductByCategories();
            }
            setState(prev => ({
                ...prev,
                [stateName]: copyArrayOfObjects(data)
            }));
            resolve()
        })
    };

    const convertToNode = (data, isRoot = false, init = false) => {
        let attributes = [];
        // let filters = [];
        if (init) {
            for (let { node } of data.categoryDatas.edges) {
                let found = false;

                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;

                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.value,
                            media: {
                                id: node.media ? node.media.id : null,
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id // category data id
                        });
                    }
                }

                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        locales: [{
                            value: node.value,
                            media: {
                                id: node.media ? node.media.id : null,
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id
                        }]
                    });
                }
            }
            // if (data.categoryFilters && data.categoryFilters.edges.length > 0){
            //     for (let filter of data.categoryFilters.edges){
            //         if (filter){
            //             let getFilterData = {
            //                 ...filter.node,
            //                 attributeType: {node: filter.node.attribute}
            //             }
            //             filters.push(getFilterData)
            //         }
            //     }
            // }
        }

        data.attributes = init ? attributes : data.attributes;
        // data.filters = init ? filters : data.filters;
        data.filters = []; // created when opening a cat in tree

        let nameAttribute = data.attributes.find(e => e.identifier === 'category_name');

        let getTraduction = nameAttribute ? nameAttribute.locales.find(
            translation => translation.code === props.locales[0].node.code
        ) : null;

        data.title = isRoot ? '/' : getTraduction?.value ?? nameAttribute?.locales[0]?.value ?? data.libelle;
        data.isDirectory = true;
        data.isRoot = isRoot;
        data.dragDisabled = isRoot;
        data.expanded = isRoot ? true : data.expanded;
    }

    const populateChildren = (cats, parent, init) => {
        parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);

        for (let child of parent.children) {
            convertToNode(child, false, init);
            populateChildren(cats, child, init);
        }
    }

    // be careful, only breaks references at objects level
    const copyArrayOfObjects = array => array.map(a => ({ ...a }));

    // goTo = route => props.history.push(route);
    // const goTo = (route, id) => {
    //     props.history.push({
    //         pathname: route,
    //         state: { productId: id }
    //     });
    // };

    const { anchorEl } = state;
    const catalogData = state.catalogData;
    const selectLang = {
        type: 'select',
        isContainerized: true,
        label: 'Langue',
        helper: 'Langue',
        required: false,
        stateName: 'currentLang',
        value:
            props.locales.map((locale) => {
                return ({
                    value: locale.node.code,
                    label: locale.node.libelle
                })
            })
    };

    let infoCatSelected = null;

    if (state.selectedCategorie) {
        infoCatSelected = state.allCategories.find(e => e.id === state.selectedCategorie)
        infoCatSelected = getTraductionAttributs('category_name', infoCatSelected?.categoryDatas?.edges, props.locales[0].node.code)
    }

    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop + 0.5 >= offsetListing + containerRef.current.offsetHeight) {
            setScrollListing(true)
        } else {
            setScrollListing(false)
        }
    };

    const resetStateExport = () => {
        setState({
          ...state,
          importTypeFile: "csv",
          importUrl: "",
          errors: {},
          customAttributes: [],
          allGroups: [],
          categoriesData: [],
          metaAttributes: [],
          imageAttributes: [],
          groupAttribut: "",
          seeErrors: false,
        });
      };

    const topPanelActions = {
        edit: {
            text: 'Modifier',
            icon: 'edit',
            handler: () => setState(prev => ({ ...prev, openEditForm: !prev.openEditForm })),
            style: {
                width: 235
            }
        }
    }

    const handlerMutationDuplicate = async (id)=>{
        props.startLoading()
        // for (let catalog of this.state.catalogSelected) {
        // }
        try {
            await axios.get(`${process.env.REACT_APP_API}/catalog/duplicate/${id.replace('/api/catalogs/', '')}/`);
            props.snack(ALERT_SUCCESS, props.t("catalogs.catalogs.catalogDuplicated"));
        } catch (error) {
            props.snack(ALERT_ERROR, props.t("catalogs.catalogs.catalogDuplicatedError"));
            console.log(error)
        }
        // this.handleToggleDrawer('openFormDuplicate', true);
        // this.resetStateCatalog();
        props.stopLoading();
    }

    const handleDelete = async(catalog) => {
        props.startLoading()
        props.client.mutate({
            mutation: DELETE_CATALOG,
            variables: { id: catalog },
            fetchPolicy: 'no-cache'
        }).then(async (result) => {
            props.snack(ALERT_SUCCESS, 'Catalogue supprimé avec succès');
            props.stopLoading();
            goTo(props.history, ROUTE_CATALOGS_LIST)
        }).catch((err)=>{
            console.log(err)
        });
    }

    return (
      <div style={{ overflow: "auto" }} onScroll={handleScroll}>
        <TopPanel
          icomoon="icon-catalogue"
          getRef
          setRef={setOffsetListing}
          colorIcomoon={colors.blue.lighter.hue300}
          title={props.t("drawer.catalogs_detail")}
          subtitle={props.t("catalogs.catalogs.manageProductCatalogs")}
          // dataCount={dataCount}
          // textAdd={props.t('catalog.add')}
          // handlerAdd={() => handleForm("add")}
          gradientColor1={colors.menu.regular}
          gradientColor2={colors.menu.darker}
          buttonAvailable={catalog}
          windowWidth={props.windowWidth}
          actions={topPanelActions}
          // textEdit={'Modifier'}
          // handlerEdit={()=>setState({...state,openEditForm:!state.openEditForm})}
        >
          <OurMenu
            menuOptions={dottedMenuConfig({
              deleteAction: handleDelete,
            })}
            handleClick={(e, item) => item.action(e)}
            id={catalog?.id}
            style={{
              position: "absolute",
              top: "13px",
              right: "-18px",
            }}
          />
        </TopPanel>
        <div
          ref={containerRef}
          style={{
            padding: "16px 16px 0px 16px",
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
          }}
        >
            <ReturnLink
                goTo={() => {
                    const referrer = document.referrer;
                    const currentPath = props.match.path;
                    const referrerHostname = referrer ? new URL(referrer).hostname : "";
                    
                    if (referrerHostname !== window.location.hostname) {
                        window.location.replace(currentPath.replace('detail/:id', ''));
                    } else if (referrer) {
                        props.history.goBack();
                    } else {
                        window.location.replace(currentPath.replace('detail/:id', ''));
                    }
                }}
                type={"button"}
            />
          <AccordionCustom
            title={"Informations liées"}
            custompadding={"16px !important"}
          >
            {catalog ? (
              <div
                style={{
                  display: "flex",
                  gap: "50px",
                }}
              >
                {catalog?.media ? (
                  <img
                    style={{ width: "35%" }}
                    src={`${process.env.REACT_APP_MEDIAS}/${catalog.media.filePath}`}
                  />
                ) : (
                  <img style={{ width: "35%" }} src={ImageFake} />
                )}
                <div
                  style={{
                    width: "65%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <OurTypography
                        style={{ fontSize: "25px", marginBottom: 5 }}
                        variant={"h2"}
                      >
                        {catalog.libelle}
                      </OurTypography>
                      <OurTypography
                        colortext={colors.grey.darker}
                        variant={"body1"}
                      >
                        {parseHtml(catalog.description)}
                      </OurTypography>
                    </div>
                    {/* <div onClick={()=>setState({...state,openEditForm:!state.openEditForm})} style={{display:"flex",gap:"5px",alignItems:"flex-end",cursor:"pointer"}}>
                                            <EditIcon fontSize="small" sx={{color:colors.blue.darker.hue300}}/>
                                            <OurTypography fontweighttext={"bold"} colortext={colors.blue.darker.hue300} variant={"body1"}>Modifier</OurTypography>
                                        </div> */}
                  </div>
                  <Divider sx={{ marginTop: 2, marginBottom: 3 }} flexItems />
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 3fr",
                        }}
                      >
                        <OurTypography>Statut :</OurTypography>
                        <StatusInfo status={catalog.status} width={"auto"} />
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 3fr",
                        }}
                      >
                        <OurTypography>Synchronisation :</OurTypography>
                        {catalog.autosync ? (
                          <div
                            style={{
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <SyncIcon
                              style={{
                                fill: colors.green.regular,
                                fontSize: "1.2rem",
                              }}
                            />
                            <OurTypography
                              variant="body1"
                              component="div"
                              style={{
                                color: colors.green.regular,
                                paddingLeft: 4,
                                fontSize: 14,
                                lineHeight: "19px",
                                fontWeight: "bold",
                              }}
                            >
                              {props.t("catalogs.catalogs.synchroProduct")}
                            </OurTypography>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <SyncProblemIcon
                              style={{
                                fill: colors.orange.regular,
                                fontSize: "1.2rem",
                              }}
                            />
                            <OurTypography
                              variant="body1"
                              component="div"
                              style={{
                                color: colors.orange.regular,
                                paddingLeft: 4,
                                fontSize: 14,
                                lineHeight: "19px",
                                fontWeight: "bold",
                              }}
                            >
                              {props.t("catalogs.catalogs.noSynchroProduct")}
                            </OurTypography>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 3fr",
                        }}
                      >
                        <OurTypography>Accès direct :</OurTypography>
                        <OurTypography>-</OurTypography>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 3fr",
                        }}
                      >
                        <OurTypography>Total de produits :</OurTypography>
                        <OurTypography style={{ fontWeight: "bold" }}>
                          {dataCount}
                        </OurTypography>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 3fr",
                        }}
                      >
                        <OurTypography>Total de catégories :</OurTypography>
                        <OurTypography style={{ fontWeight: "bold" }}>
                          {state.categories?.length ?? "-"}
                        </OurTypography>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 3fr",
                        }}
                      >
                        <OurTypography>Assignation assets :</OurTypography>
                        <OurTypography style={{ fontWeight: "bold" }}>
                          {catalog.assets?.edges.length}
                        </OurTypography>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", gap: "20px", marginTop: "30px" }}
                  >
                    <OurButton
                      style={{
                        minWidth: "200px",
                        height: "40px",
                        margin: "0px",
                      }}
                      text={props.t("catalogs.catalogs.organizeCatalog")}
                      color={colors.blue.darker.hue300}
                      colorHover={colors.white}
                      bgcolorhover={colors.blue.lighter.hue600}
                      bgcolor={colors.blue.lighter.hue900}
                      border={`1px solid ${colors.blue.darker.hue300}`}
                      stopPropagation={true}
                      onClick={() => {
                        handleToggleDrawer("openForm", true);
                      }}
                    />
                    <OurButton
                      style={{
                        minWidth: "200px",
                        height: "40px",
                        margin: "0px",
                      }}
                      text={props.t(
                        "spread_builder.asset_view.manageAttributes"
                      )}
                      color={colors.blue.darker.hue300}
                      colorHover={colors.white}
                      bgcolorhover={colors.blue.lighter.hue600}
                      bgcolor={colors.blue.lighter.hue900}
                      border={`1px solid ${colors.blue.darker.hue300}`}
                      stopPropagation={true}
                      onClick={() => {
                        handleToggleDrawer("openFormAttributs", true);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <PageLoader />
            )}
          </AccordionCustom>
        </div>
        <Listing
          padding={16}
          scroll={scrollListing}
          label="products"
          settings={listSettings}
          perPageOptions={perPageOptions}
          mappers={listMappers}
          typeResult={"itemsPerPage"}
          pagination={true}
          enablePagination={true}
          enableChangeView={true}
          enableChangeSort={true}
          enableSelectionItems={true}
          enableFilters={true}
          identifier="researchProducts"
          viewsOptions={viewOptions}
          tableProps={{
            textButtonSecondary: "red",
            redirectDetail: goToProductDetail,
          }}
          queryVariables={{
            localeId: "/api/locales/1",
            catalog: props.match.params.id,
          }}
          locales={props.locales}
          setDataCount={setDataCount}
          reload={reloadListing}
          setReload={setReloadListing}
          filtersOptions={filtersOptionsValues}
          itemSelection={{
            set: setSelectedItems,
            items: selectedItems,
            customOptionText: "Tout le catalogue",
          }}
          cardProps={{
            redirectDetail: goToProductDetail,
            showPercent: true,
            percent: "completude",
            image: "image",
            title: "libelle",
            imagesNb: "imagesNb",
            imagesMaxNb: "imagesMaxNb",
            sku: "sku",
            attributes: [
              {
                label: "Statut",
                value: "status",
                type: "status",
              },
              {
                label: "Variantes",
                value: "childrenNb",
              },
              {
                label: "Catalogues",
                value: "catalogsNb",
              },
              {
                label: "Catégories",
                value: "categoriesNb",
              },
            ],
          }}
          actions={{
            export: {
              toolTip: "Exporter la sélection",
              handler: (type) => {
                if (type === "all") {
                  setSelectedItems([]);
                  setExportCatalogProducts(true);
                }
                setOpenFormExport(!openFormExport);
              },
            },
            delete: {
              toolTip: "Supprimer la sélection",
              //   handler: () => setOpenDeleteModal(!openFormExport),
            },
          }}
        />
        <ExportProducts
          errorMapper={errorMapper}
          // setState={setExportState}
          stateCallback={handleInputChangeExport}
          state={state}
          productNb={selectedItems?.length}
          resetState={resetStateExport}
          handlerMutation={handlerMutationExport}
          allLangs={props.locales}
          t={props.t}
          allGroups={state.allGroups}
          setOpenFormExport={setOpenFormExport}
          openFormExport={openFormExport}
        />
        <LayoutFormBuilder
                isSublayout={false}
                // listProducts={listProducts}
                allCategories={state.allCategories}
                handleChangeCategorie={handleChangeCategorie}
                resetReloadListing={() => setState(prev => ({ ...prev, reloadProductFormList: false }))}
                selectedCategorie={state.selectedCategorie}
                productByCategorie={state.productByCategorie}
                selectedProductByCategorie={state.selectedProductByCategorie}
                treeAddCat={addSubcategory}
                treeEditCat={editCat}
                treeDeleteCat={deleteCat}
                expandNodes={expandNodes}
                copyCategories={copyCategories}
                handleCancel={resetStateCatalog}
                opened={state.openForm}
                handlerMutation={handlerMutationProducts}
                dataLayout={catalogEditProductsCategories(state.treeData, state.secondTreeData, state.filteredCat, handlerUpdatePositionCategoriesCatalog)}
                allState={state}
                stateCallback={handleInputChange}
                errorCallback={handleFormError}
                drawerWidth={'65vw'}
                forClose={() => handleToggleDrawer('openForm', true)}
                // handlerSetup={handlerSetup}
                validateButton={true}
                stepperButtonAction={[
                    () => setState(prev => ({
                        ...prev,
                        styleInnerContainer: {
                            boxShadow: 'none',
                            backgroundInner: colors.white,
                            backgroundActions: colors.white,
                            padding: '0px',
                            height: '450px'
                        }
                    })),
                    () => {
                        if (hasErrors()) {
                            props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                            setState(prev => ({ ...prev, seeErrors: true }));
                            eventService.fire();
                            return false;
                        }

                        handleDrawerWidthChange(300)

                        return true;
                    }
                ]}
                backStepperButtonAction={[
                    () => handleDrawerWidthChange(300),
                    () => setState(prev => ({
                        ...prev, 
                        backgroundDrawer: colors.grey.lighter.hue980,
                        styleInnerContainer: {
                            boxShadow: 'none',
                            backgroundInner: colors.white,
                            backgroundActions: colors.grey.lighter.hue980,
                            padding: '0px',
                            height: '630px'
                        }
                    })),
                    () => handleDrawerWidthChange(-800),
                ]}
                setSelectedProducts={setSelectedProducts}
                addProducts={() => handleToggleDrawer('openProductSelector')}
                merchModal={() => handleToggleDialog('openMerchModal')}
                handleTreeChange={handleTreeChange}
            />

        <OurDrawer
          isOpen={state.openEditForm}
          setIsOpen={() =>
            setState((prevState) => ({
              ...prevState,
              openEditForm: !prevState.openEditForm,
            }))
          }
        >
          <CatalogForm
            catalog={catalogForm}
            onSuccess={() => {
              setReloadListing(true);
              setState((prevState) => ({
                ...prevState,
                openEditForm: !prevState.openEditForm,
              }))
            }}
          />
        </OurDrawer>

        <LayoutFormBuilder
                isSublayout={false}
                styleInnerContainer={state.styleInnerContainer}
                backgroundColor={state.backgroundDrawer}
                handleCancel={resetStateCatalog}
                opened={state.openFormAttributs}
                handlerMutation={() => { handlerMutation(true) }}
                dataLayout={catalogEditAttributes(state.groupsWithSelectedAttr)}
                allState={state}
                stateCallback={handleInputChange}
                errorCallback={handleFormError}
                forClose={() => handleToggleDrawer('openFormAttributs', true)}
                // handlerSetup={handlerSetup}
                validateButton={true}
                stepperButtonAction={[
                    () => {
                        if (hasErrors()) {
                            props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                            setState(prev => ({ ...prev, seeErrors: true }));
                            eventService.fire();
                            return false;
                        }
                        return true;
                    }
                ]}
            />

        <LayoutFormBuilder
            isSublayout={false}
            styleInnerContainer={state.styleInnerContainer}
            opened={state.openFormCategories}
            forClose={() => handleToggleDrawer('openFormCategories', false)}
            handlerMutation={handleMutationCategories}
            dataLayout={state.editForm === 'edit' ?
                formCategoriesEdit(state.cat_id, copyArrayOfObjects(state.categories), state.parent, props.attributes?.category.attributes, state.currentLang, state.errors, state.seeErrors, handleMediaPicker, props.attributes?.product.attributes, true, state.typeCategorie, state.assetMagentoPages, state, state.contentCategories, state.categoryContent)
                : formCategoriesAdd(copyArrayOfObjects(state.categories), state.parent, props.attributes?.category.attributes, state.currentLang, state.errors, state.seeErrors, handleMediaPicker, true, props.t, state.typeCategorie, state.assetMagentoPages, state, props.attributes?.product.attributes, state.contentCategories, state.categoryContent)}
            icomoon={state.editForm === 'edit' ? 'ico-modifier-categorie' : 'ico-ajouter-categorie'}
            allState={state}
            stateCallback={handleInputChange}
            errorCallback={handleFormError}
            // deleteMutation={state.editForm === 'edit' ? () => { handleToggleDialog('openDialogCategory') } : null}
            // deleteText={state.editForm === 'edit' ? 'Supprimer la catégorie' : null}
            // deleteButton={state.editForm}
            validateButton={true}
            currentLang={state.currentLang}
            handleLang={handleLang}
            handleButtonGroupChange={handleButtonGroupChange}
        />
      </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
        attributeGroups: state.attributeGroups,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) =>
            dispatch({ type: SNACK, payload: { type, message } }),
    };
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogDetail))));