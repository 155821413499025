import React from 'react';

import {Grid, Box, Typography} from '@mui/material';
import styled from 'styled-components';
import videoLogo from '../../../../../assets/pictos/video.png';
import * as moment from 'moment';



const LastImageContainer=styled(Grid)`
    background-color:white;
    padding:20px;
`
const SmallImage=styled(Box)`
    border : 1px #EAECEE solid;
    cursor:pointer;
    &:first-child{
        margin-top: 35px;
    }
    &:hover{
        .AnnexTitle{
            background-size:100% 100%;
            color:white;         

        }
    }
`
const BigImage=styled(Box)`
    cursor:pointer;
    .bigImageContainer{
        border : 1px #EAECEE solid;
        text-align:center;
        height:350px;
        background-size:60%;
        background-position:center;
        background-repeat:no-repeat;

        
        
    }
    .bigImageTitle{
        border : 1px #EAECEE solid;
        text-align:center;
        padding:20px 20px;
        border-top:none;
        font-style:italic;
        background : transparent linear-gradient(135deg, #425E7C 0%, #15181C 100%) 0% 0% no-repeat padding-box;
        background-size:0 100%;
        transition: background-size .5s,color .5s;
        will-change:background-size;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
}
    }
    &:hover{
        .bigImageTitle{
            background-size:100% 100%;
            color:white;    
        }
        
    }
`
const AnnexImage=styled(Box)`
    height:100%;
    background-size:50%;
    background-position:center;
    background-repeat:no-repeat;
`
const AnnexTitle=styled(Typography)`
    padding:40px 20px 40px 20px;
    border-left:1px #EAECEE solid;
    background : transparent linear-gradient(135deg, #425E7C 0%, #15181C 100%) 0% 0% no-repeat padding-box;
    background-size:0 100%;
    transition: background-size .5s,color .5s;
    will-change:background-size;
    text-overflow:ellipsis;
    white-space : nowrap;
    overflow:hidden;
`
export default function LastImage(props){
    const medias=props.lastMedias;
    const firstMedia=medias[0];
    const otherMedias=medias.slice(1,5);   

    return(
        <LastImageContainer container> 
            <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                    Date: {moment(firstMedia.date).format('L')}
                </Typography>

                <BigImage style={{marginTop:20}} onClick={()=>props.modal(firstMedia, 0)}>
                    <Box className="bigImageContainer" style={{backgroundImage: `url(${['video/mpeg','video/webm','video/mp4'].includes(firstMedia.node.type) ? videoLogo : process.env.REACT_APP_API_ROOT+'/medias/'+firstMedia.node.filePath })`}}>
                    </Box>
                    <Typography className="bigImageTitle" variant="h3">
                        {firstMedia.node.name?firstMedia.node.name:firstMedia.node.filePath}
                    </Typography>
                </BigImage>


            </Grid>
            <Grid item xs={12} sm={6} style={{display:'flex', paddingLeft:20, flexDirection: 'column', justifyContent: 'space-between'}}>
            {otherMedias.map((media, index) =>
                <SmallImage key={`SmallImage${index}`} onClick={()=>props.modal(media, index)}>
                    <Grid container direction="row">
                        <Grid item xs={6}>
                            <AnnexImage style={{backgroundImage: `url(${['video/mpeg','video/webm','video/mp4'].includes(media.node.type) ? videoLogo : process.env.REACT_APP_API_ROOT+'/medias/'+media.node.filePath })`}}>
                            </AnnexImage>
                        </Grid>
                        <Grid item xs={6} >
                            <AnnexTitle variant="body2" className="AnnexTitle">
                                {media.node.name ? media.node.name : media.node.filePath}
                            </AnnexTitle>
                        </Grid>                        
                    </Grid>

                </SmallImage>
            )}
            </Grid>
        </LastImageContainer>
    )
}