import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import colors from '../../../config/theme/colors';
import request from '../../../js/utils/fetch';
import PageLoader from '../loadings/page-loader/PageLoader';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { SNACK, START_LOADING, STOP_LOADING } from '../../../js/constants/action-types';
import { Pagination } from '@mui/material';
import Button from '../../ui/button/Button';

const useStyles = makeStyles({
  list: {
    width: 500,
  },
  fullList: {
    width: 'auto',
  },
});

const GridItem = styled(Grid)`
    margin-bottom: 8px;
    padding: 8px;
    background: #f5f5f5;
    span, p{
        color: ${props => props.typeAction === "create" ? colors.black.regular : "#157EAB"};
    }
`
const UnderlineText = styled.span`
    text-decoration: underline;
    font-weight: bold;
`

const SelectCustom = styled(Select)`
    margin-top: 4px;
    .MuiSelect-outlined{
        padding: 10px;
    }
`

function LogsActions(props) {
    const classes = useStyles();
    const currentLang = props.locales[0].node.code;
    const moreInfos = null;
    const [selectedLocale, setSelectedLocale] =  useState('all');
    const [selectedAttribute, setSelectedAttribute] =  useState('all');
    const [listInfos, setListInfos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modaleOpen, setModaleOpen] = useState(false);
    const [pagination, setPagination] = useState(
        {
            perItem: 10,
            page: 1,
            totalItems: 0
        }
    );

    useEffect(() => {
        if (props.open && props.typeToLoad){
            setLoading(true)
            if (props.typeToLoad.includes('products')){
                let variables = {
                    "iri": props.typeToLoad,
                    "childrenType": "/api/product-datas",
                    "childrenName": "productDatas",
                    "childrenStaticProperties": ["attribute","locale"],
                    "page": pagination.page,
                    "itemsPerPage": pagination.perItem,
                }
                if (selectedLocale !== 'all' && selectedAttribute === 'all'){
                    variables.filters = {
                        "locale": [selectedLocale.replace('/api/locales/', '')],
                    }
                }else if(selectedAttribute !== 'all' && selectedLocale === 'all'){
                    variables.filters = {
                        "attribute":[selectedAttribute.replace('/api/attributes/', '')]
                    }
    
                } else if (selectedAttribute !== 'all' && selectedLocale !== 'all' ) {
                    variables.filters = {
                        "locale": [selectedLocale.replace('/api/locales/', '')],
                        "attribute":[selectedAttribute.replace('/api/attributes/', '')]
                    }
                }
                request(`${process.env.REACT_APP_API}/children/log-entries/array`, 'POST', variables).then(
                    (data) => {
                        if (data.success){
                            setLoading(false)
                            setListInfos(data.datas)
                            let getPagination = {...pagination}
                            getPagination.totalItems = data.itemsTotal
                            setPagination(getPagination)
                        }else{
                            setLoading(false)
                        }
                    }
                ).catch((error) => {
                    setLoading(false)
                });
            }else if(props.typeToLoad.includes('categories')){
                request(`${process.env.REACT_APP_API}/children/log-entries/array`, 'POST',{
                    "iri": props.typeToLoad,
                    "childrenType": "/api/category-datas",
                    "childrenName": "categoryDatas",
                    "childrenStaticProperties": ["attribute","locale"],
                    "page": pagination.page,
                    "itemsPerPage": pagination.perItem
                }).then(
                    (data) => {
                        if (data.success){
                            setLoading(false)
                            setListInfos(data.datas)
                        }else{
                            setLoading(false)
                        }
                    }
                ).catch((error) => {
                    setLoading(false)
                });
            }else{
                request(`${process.env.REACT_APP_API}/log-entries/array`, 'POST',{
                    "iri": props.typeToLoad,
                    "page": pagination.page,
                    "itemsPerPage": pagination.perItem
                }).then(
                    (data) => {
                        if (data.success){
                            setLoading(false)
                            setListInfos(data.datas)
                        }else{
                            setLoading(false)
                        }
                    }
                ).catch((error) => {
                    setLoading(false)
                });
            }
        }
    },[props.open, props.typeToLoad, pagination.page, selectedLocale, selectedAttribute]) 

    const changePage = (event, page) => {
        let getPagination = {...pagination}
        getPagination.page = page
        setPagination(getPagination)
    };

    const handleChangeLocale = (value) => {
        setSelectedLocale(value);
    };
    const handleChangeAttribute = (value) => {
        setSelectedAttribute(value);
    };

    const swiper = () => (
        <div
            className={clsx(classes.list)}
            role="presentation"
        >
            <Grid container justifyContent='space-between' alignItems='center' style={{padding: props.isProduct ? "10px 20px 0px 20px" : "10px 20px 20px 20px"}}>
                <Grid item>
                    <Typography variant='h4' style={{fontWeight: 'bold'}}>{props.t("spread.treeview.logs")}</Typography>
                </Grid>
                <Grid item>
                    <Grid container justifyContent='center' alignItems='center' onClick={props.toggleDrawer} style={{cursor: 'pointer', textDecoration:'underline'}}>
                        <Typography variant="body2" style={{marginBottom: 2, color: colors.blue.regular, fontWeight: 'bold'}}>X Fermer</Typography>
                    </Grid>
                </Grid>
            </Grid>

            {
                props.isProduct ? 
                    <Grid container justifyContent='space-between' style={{padding: "10px 20px"}}>
                        <Grid item xs={6} style={{paddingRight: 4}}>
                            <label>Langue :</label>
                            <SelectCustom 
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                value={selectedLocale}
                                onChange={(e) => handleChangeLocale(e.target.value)}
                            >
                                <MenuItem value={'all'}>{props.t('medias.medias.seeAll')}</MenuItem>
                                {
                                    props.locales.map((lang, index) => {
                                        return(
                                            <MenuItem key={index} value={lang.node.id}>{lang.node.libelle}</MenuItem>
                                        )
                                    })
                                }
                            </SelectCustom>
                        </Grid>
                        <Grid item xs={6} style={{paddingLeft: 4}}>
                            <label>Attribut:</label>
                            <SelectCustom 
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                value={selectedAttribute}
                                onChange={(e) => handleChangeAttribute(e.target.value)}
                            >
                                <MenuItem value={'all'}>{props.t('medias.medias.seeAll')}</MenuItem>
                                {
                                    props.attributes.product.attributes.edges.filter(e => e.node.attributeGroup.edges[0]?.node.id === props.groupAttribut).map((attr, index) => {
                                        let getNameAttribute = attr.node.translation.translationDatas.edges.find(e => e.node.locale.code === currentLang).node.value
                                        return(
                                            <MenuItem key={index} value={attr.node.id}>{getNameAttribute || attr.node.identifier}</MenuItem>
                                        )
                                    })
                                }
                            </SelectCustom>
                        </Grid>
                    </Grid>
                : null
            }
            
            <Grid container alignContent='flex-start' alignItems='flex-start' style={{padding: "0px 20px", height: "75vh", overflow:'auto'}}>
                {
                    loading ? 
                        <PageLoader />
                    :
                    listInfos.length > 0 ?
                        listInfos.map((info, index) => {
                            let getAttributeName = null;
                            if (props.typeToLoad){
                                if (props.typeToLoad.includes('products') ||  props.typeToLoad.includes('categories')){
                                    getAttributeName = props.attributes[props.typeToLoad.includes('products') ? 'product' : props.typeToLoad.includes('categories') ? 'category' : null].attributes.edges.find(e => e.node.id.replace('/api/attributes/', '') === info.attribute.id)?.node?.translation?.translationDatas.edges.find(trad => trad.node.locale.code === currentLang)?.node?.value
                                }
                            }
                            return(
                                <GridItem key={index} item xs={12} typeAction={info.action}>
                                    <Typography style={{fontWeight: 'bold'}}>{info.username || 'CRON DXP'}</Typography>
                                    <Typography>Version: {info.version}</Typography>
                                    {
                                        info.locale ? 
                                            <Typography>Langue : {info.locale.code}</Typography>
                                        : null
                                    }
                                    {
                                        info.attribute ? 
                                            <Typography style={{marginBottom: 8}}>Attribut: {getAttributeName || info.attribute.identifier}</Typography>
                                        : null
                                    }
                                    <Grid container style={{marginBottom: 8}}>
                                        {
                                            info.arrayUpdate.filter(e => (e.old || e.new) && e.var !== "product" && e.var !== "locale" && e.var !== "attribute" && e.var !== "category").map((arrayData, index) => {
                                                let getAttributeType = null
                                                let content;
                                                if (info.attribute && props.typeToLoad){
                                                    getAttributeType = props.attributes[props.typeToLoad.includes('products') ? 'product' : props.typeToLoad.includes('categories') ? 'category' : null].attributes.edges.find(e => e.node.attributeType.id === info.attribute.attributeType)
                                                }
                                                if (getAttributeType){
                                                    switch(getAttributeType.node.attributeType.input){
                                                        case "select" : {
                                                            let initOldValue = arrayData.old ? getAttributeType.node.attributeOptions.edges.find(attr => attr.node.id.replace('/api/attribute-options/', '') === (arrayData.var === "value" ? arrayData.old.replace('/api/attribute-options/', '') : arrayData.old?.id)) : null;
                                                            let getOldValue = null;
                                                            let getOldValueDefault = arrayData.old ? null : 'N/A';
                                                            if (initOldValue?.node){
                                                                getOldValue = initOldValue.node.translation.translationDatas.edges.find(trad => trad.node.locale.code === currentLang).node.value
                                                                getOldValueDefault = initOldValue.node.identifier
                                                            }
        
                                                            let initNewValue = arrayData.new ? getAttributeType.node.attributeOptions.edges.find(attr => attr.node.id.replace('/api/attribute-options/', '') === (arrayData.var === "value" ? arrayData.new.replace('/api/attribute-options/', '') : arrayData.new?.id)) : null;
                                                            let getNewValue = null;
                                                            let getNewValueDefault = arrayData.new ? null : 'N/A';
                                                            if (initNewValue?.node){
                                                                getNewValue = initNewValue.node.translation.translationDatas.edges.find(trad => trad.node.locale.code === currentLang).node.value
                                                                getNewValueDefault = initNewValue.node.identifier
                                                            }
                                                            content = <Grid key={index} item xs={12}>
                                                                        <Grid container>
                                                                            <Grid item>
                                                                                {
                                                                                    arrayData.var === 'isLocked' ?
                                                                                    <Typography>Verrouillage attribut: {arrayData.old ? typeof arrayData.old === "object" ? arrayData.old?.id : arrayData.old : 'false'} {"=>"} <strong>{arrayData.new ? typeof arrayData.new === "object" ? arrayData.new?.id : arrayData.new.toString() : 'N/A'}</strong></Typography>
                                                                                    : 
                                                                                    <Typography>Changement: {getOldValue || getOldValueDefault} {"=>"} <strong>{getNewValue || getNewValueDefault}</strong></Typography>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                            
                                                        }
                                                        break;
                                                        default:
                                                            content =<Grid key={index} item xs={12}>
                                                                        <Grid container>
                                                                            <Grid item>
                                                                                {
                                                                                    arrayData.var === 'isLocked' ?
                                                                                    <Typography>Verrouillage attribut: {arrayData.old ? typeof arrayData.old === "object" ? arrayData.old?.id : arrayData.old : 'false'} {"=>"} <strong>{arrayData.new ? typeof arrayData.new === "object" ? arrayData.new?.id : arrayData.new.toString() : 'N/A'}</strong></Typography>
                                                                                    : 
                                                                                    <Typography>Changement: {arrayData.old ? typeof arrayData.old === "object" ? arrayData.old?.id : arrayData.old : 'N/A'} {"=>"} <strong>{arrayData.new ? typeof arrayData.new === "object" ? arrayData.new?.id : arrayData.new.toString() : 'N/A'}</strong></Typography>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                    }
                                                }
                                                return content;
                                            })
                                        }
                                    </Grid>
                                    <Typography variant='body2'><UnderlineText>{info.action === "create" ? "Création" : "Modification"}</UnderlineText> le {moment(info.loggedAt).format("DD MMMM YYYY à HH:mm:ss")}</Typography>
                                </GridItem>
                            )
                        })
                    
                    : <Typography>Aucune actions pour le moment</Typography>
                }
            </Grid>
            {
                Math.ceil(pagination.totalItems / pagination.perItem) > 1 ?
                <Grid container justifyContent='center' alignItems='center' style={{marginTop: 8}}>
                    <Pagination onChange={(event, page) => {changePage(event, page)}} page={pagination.page} count={Math.ceil(pagination.totalItems / pagination.perItem)} color="primary" />
                </Grid>
                : null
            }
            {
                moreInfos ?
                    <Dialog
                    open={modaleOpen}
                    >
                        <DialogTitle id="alert-dialog-title">Informations sur "<strong><u>{moreInfos.attribute}</u></strong>"</DialogTitle>
                        <DialogContent>
                            <Grid container style={{marginBottom: 8}}>
                                <Typography style={{fontWeight: 'bold', marginBottom: 4, color: colors.black.regular, textDecoration: 'underline'}}>Avant:</Typography>
                                <Grid container style={{padding: 12, background: colors.red.lighter}}>
                                    {
                                        moreInfos.previous
                                    }
                                    {/* <TypographyCustom dangerouslySetInnerHTML={{ __html: moreInfos.previous }} /> */}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Typography style={{fontWeight: 'bold', marginBottom: 4, color: colors.black.regular, textDecoration: 'underline'}}>Après:</Typography>
                                <Grid container style={{padding: 12, background: colors.green.lighter}}>
                                    {
                                        moreInfos.new
                                    }
                                    {/* <TypographyCustom dangerouslySetInnerHTML={{ __html: moreInfos.new }} /> */}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button text="Fermer" color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`} onClick={() => setModaleOpen(false)}>
                            </Button>
                        </DialogActions>
                    </Dialog>
                : null
            }
        </div>
    );

    return (
        <div>
            <SwipeableDrawer
            anchor='right'
            open={props.open}
            onClose={() => props.toggleDrawer()}
            >
                {swiper()}
            </SwipeableDrawer>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LogsActions));