import { Grid, Typography, MenuItem, Tooltip, Box, Chip } from '@mui/material';
import { Suggestion, TextFieldCustomSmall, ChipInputCustom, ChipCustom } from "../../styles/inputStyled";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import HighlightOff from '@mui/icons-material/HighlightOff';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ChipInput from 'material-ui-chip-input-v5';
import CardCustom from '../../../../layouts/Card/CardCustom';
import colors from '../../../../../config/theme/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import TypographyCustom from '../../../typography/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { v4 as uuidv4 } from 'uuid';
import { RowsFieldCustom } from './styles/styled';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import OurChip from '../../../inputs/OurChip/OurChip';
import { MenuItemCustom, SelectFieldCustom } from '../OurSelect/styles/styled';
import { TextFieldCustom } from '../Text/styles/styled';
import OurTypography from '../../../typography/Typography';
import { useEffect, useRef } from 'react';

const Rows = ({ allProps: props, hasNew, setSeeError, checkError, seeError, errorMessage, classes }) => {
    const inputChipCustomref = useRef(null);
    const inputTagCustomref = useRef(null);

    const addNewRow = () => {
        props.value.push({
            new: true,
            code: props.input.dictionary ? [] : '',
            values: props.input.currentLang ? {} : props.input.filter ? [] : null,
            operator: props.input.filter ? '' : null,
            attributeType: null,
            id: uuidv4(),
            checkbox: false,
            typingChips: null,
            showButton: false,
        });
        setSeeError(true);
        checkError(props.input.stateName, props.value);
        props.stateCallback(props.value);
    }

    useEffect(() => {
        if (props.value.length === 0) {
            addNewRow();
        }
    }, [])


    useEffect(() => {
        if(inputChipCustomref.current){
            const flexWrapper = inputChipCustomref.current?.querySelector('.MuiInputBase-root');
            const textInput = inputChipCustomref.current?.querySelector('input');
            inputChipCustomref.current?.addEventListener("wheel", (evt) => {
                evt.preventDefault();
                flexWrapper.scrollLeft += evt.deltaY;
                flexWrapper.style.width = textInput.offsetWidth + 1000;
                // textInput.style.width = 1000;
            });
        }
    }, [inputChipCustomref.current])
    
    useEffect(() => {
        if(inputChipCustomref.current){
            const flexWrapper = inputChipCustomref.current?.querySelector('.MuiInputBase-root');
            const textInput = inputChipCustomref.current?.querySelector('input');
            inputChipCustomref.current?.addEventListener("wheel", (evt) => {
                evt.preventDefault();
                flexWrapper.scrollLeft += evt.deltaY;
                flexWrapper.style.width = textInput.offsetWidth + 1000;
                // textInput.style.width = 1000;
            });
        }
    }, [inputChipCustomref.current])

    return (
        <>
            {
                props.input.labelInput &&
                <Grid container style={{ marginBottom: 16 }}>
                    <Typography variant='body1' style={{ color: colors.black.regular }}>{props.input.labelInput}</Typography>
                </Grid>
            }
            <Grid container direction="row" spacing={0}>
                {
                    hasNew && props.input.dictionary &&
                    <Grid container alignItems="center">
                        <ReportProblemOutlinedIcon style={{ fill: colors.red.regular, fontSize: '1.5rem' }} /><Typography style={{ color: colors.red.regular, paddingLeft: 8 }}>Mettre un texte d'erreur lors de l'ajout d'une valeur</Typography>
                    </Grid>
                }
                {/* // TODO: Remove */}
                {/* {
                    props.value?.length > 0
                        ? !props.input.autoLabels?.show && (
                            <>
                                <Grid item xs={5} style={{ paddingBottom: 16, paddingRight: 15, textAlign: props.input.simpleField ? "left" : "center" }}>
                                    {
                                        props.input.labelsNames &&
                                        <label><b>{props.input.labelsNames.first}</b></label>
                                    }
                                </Grid>
                                <Grid item xs={props.input.filter ? 2 : 5} style={{ paddingBottom: 16, paddingRight: props.input.filter ? 20 : 0, textAlign: "center" }}>
                                    {
                                        props.input.labelsNames &&
                                        <label><b>{props.input.labelsNames.second}</b></label>
                                    }
                                </Grid>
                                {
                                    props.input.filter &&
                                    <Grid item xs={4} style={{ paddingBottom: 16, textAlign: "center", paddingRight: 15 }}>
                                        <label><b>{props.input.labelsNames.third}</b></label>
                                    </Grid>
                                }
                            </>
                        )
                        : <Grid item xs={12} style={{ marginBottom: 16 }}>Aucune valeur</Grid>
                } */}

                {
                    props.value?.length > 0
                        && props.value?.filter(row => !row.deleted).map((row, i) => (
                            <>
                                {
                                    props.input.autoLabels?.show &&
                                    <Grid item style={{ fontSize: 14, marginBottom: 8 }}>
                                        <label>{props.input.autoLabels?.textStart + (i + 1)}</label>
                                    </Grid>
                                }
                                <Grid key={i} item xs={12} style={{ marginBottom: props.input.autoLabels?.show ? 0 : 15 }}>
                                    <Box>
                                        <Grid container direction="row">
                                            {
                                                props.input.simpleField
                                                    ? (
                                                        <Grid item xs={props.value.length === 1 ? 12 : 11}>
                                                            <TextFieldCustom
                                                                variant="outlined"
                                                                color="secondary"
                                                                value={row.code}
                                                                fullWidth
                                                                disabled={props.input.disabled}
                                                                onChange={e => {
                                                                    if ((e.target.value !== props.allState?.[props.input.stateForCompare]?.['productSourceScraps']?.edges.find(e => e.node.id === row.id)?.node.name) && !row.new) {
                                                                        row.changed = true;
                                                                    } else {
                                                                        row.changed = false;
                                                                    }
                                                                    row.code = e.target.value;
                                                                    props.stateCallback(props.value);
                                                                    setSeeError(true);
                                                                    checkError(props.input.stateName, props.value);
                                                                }}
                                                                type='text'
                                                                placeholder={props.input.labelsNames ? null : 'Nom du site'}
                                                            />
                                                        </Grid>
                                                    )

                                                    : (
                                                        <>
                                                            {/* // * First input */}
                                                            <Grid item xs={5} style={{ paddingRight: 15 }}>
                                                                {
                                                                    props.input.dictionary ?
                                                                        <div>
                                                                            <div ref={inputChipCustomref} style={{ position: 'relative' }}>
                                                                                <ChipInputCustom
                                                                                    placeholder={props.input.labelsNames?.first}
                                                                                    color="secondary"
                                                                                    variant="outlined"
                                                                                    value={row.code}
                                                                                    fullWidth
                                                                                    fullWidthInput
                                                                                    disabled={props.input.disabled}
                                                                                    inputValue={row.typingChips}
                                                                                    onDelete={(chip, index) => {
                                                                                        row.changed = true;
                                                                                        row.code.splice(index, 1)
                                                                                        props.stateCallback(props.value)
                                                                                        setSeeError(true);
                                                                                        checkError(props.input.stateName, props.value);
                                                                                    }}
                                                                                    onAdd={(chip) => {
                                                                                        row.changed = true;
                                                                                        row.code.push(chip);
                                                                                        row.typingChips = "";
                                                                                        row.showButton = false;
                                                                                        props.stateCallback(props.value)
                                                                                        setSeeError(true);
                                                                                        checkError(props.input.stateName, props.value);
                                                                                    }}
                                                                                    onUpdateInput={(e) => {
                                                                                        row.typingChips = e.target.value;
                                                                                        if (e.target.value) {
                                                                                            row.showButton = true;
                                                                                        } else {
                                                                                            row.showButton = false;
                                                                                        }
                                                                                        props.stateCallback(props.value)
                                                                                    }}
                                                                                    classes={{ inputRoot: classes.inputRoot }}
                                                                                />

                                                                                {/* {
                                                                                    row.showButton && row.typingChips ?
                                                                                        <div onClick={() => {
                                                                                            if (!row.code.includes(row.typingChips)) {
                                                                                                // simulateKeyPress()
                                                                                                row.code.push(row.typingChips)
                                                                                                row.showButton = false;
                                                                                                row.typingChips = ""
                                                                                                props.stateCallback(props.value);
                                                                                            }
                                                                                        }}>
                                                                                            <AddBoxIcon
                                                                                                style={{ color: colors.blue.darker.hue300, fontSize: 35, cursor: "pointer", position: "absolute", top: "50%", right: 8, transform: 'translateY(-50%)' }}
                                                                                            />
                                                                                        </div>
                                                                                        : null
                                                                                } */}
                                                                                {/* {
                                                                                    row.code?.length > 0 && !row.showButton && !props.input.disabled ?
                                                                                        <div onClick={() => {
                                                                                            row.changed = true;
                                                                                            row.code = [];
                                                                                            props.stateCallback(props.value);
                                                                                            setSeeError(true);
                                                                                            checkError(props.input.stateName, props.value);
                                                                                        }}>
                                                                                            <HighlightOff
                                                                                                style={{ width: 25, cursor: "pointer", position: "absolute", top: "50%", right: 8, transform: 'translateY(-50%)' }}
                                                                                            />
                                                                                        </div>
                                                                                        : null
                                                                                } */}
                                                                            </div>
                                                                            <Suggestion>
                                                                                {
                                                                                    row.typingChips && !row.typingChips.includes('|%|') ?
                                                                                        <>
                                                                                            <span>Suggestion valeur joker : </span>
                                                                                            <span
                                                                                                className="tag"
                                                                                                onClick={() => {
                                                                                                    if (!row.code.includes(`|%|${row.typingChips}|%|`)) {
                                                                                                        // simulateKeyPress()
                                                                                                        row.code.push(`|%|${row.typingChips}|%|`)
                                                                                                    }
                                                                                                    row.showButton = false;
                                                                                                    row.typingChips = "";
                                                                                                    props.stateCallback(props.value);
                                                                                                }
                                                                                                }
                                                                                            >
                                                                                                |%|{row.typingChips}|%|
                                                                                            </span>
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                            </Suggestion>


                                                                        </div>
                                                                        :
                                                                        props.input.filter ?
                                                                            <SelectFieldCustom
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                value={row.code}
                                                                                fullWidth
                                                                                disabled={props.input.disabled}
                                                                                label={row.code
                                                                                    ? null
                                                                                    : props.input.labelsNames?.first}
                                                                                SelectProps={{
                                                                                    MenuProps: {
                                                                                        elevation: 0,
                                                                                        PaperProps: {
                                                                                            square: true,
                                                                                            style: {
                                                                                                border: `1px solid ${colors.grey.border}`,
                                                                                                marginTop: '4px',
                                                                                                boxShadow: 'none',
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                onChange={e => {
                                                                                    row.changed = true;
                                                                                    let getAttribute = props.input.attributes.edges.find(attr => attr.node.id === e.target.value)
                                                                                    row.code = e.target.value;
                                                                                    if (e.target.value === 'IN' || e.target.value === 'NOT IN') {
                                                                                        row.values = []
                                                                                    } else {
                                                                                        row.values = ''
                                                                                    }
                                                                                    row.attributeType = getAttribute;
                                                                                    props.stateCallback(props.value);
                                                                                    setSeeError(true);
                                                                                    checkError(props.input.stateName, props.value);
                                                                                }}
                                                                                select
                                                                                placeholder={'yo'}
                                                                            >
                                                                                {props.input.attributes.edges.filter(e => e.node.attributeType.input !== 'image' && e.node.attributeType.input !== 'file').map((option, index) => {
                                                                                    let getTranslation = option.node.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                                                    return (
                                                                                        <MenuItemCustom key={`OptionSelect${index}`} value={option?.node?.id}>
                                                                                            {getTranslation?.node?.value || option.node.translation.translationDatas.edges[0]?.node.value}
                                                                                        </MenuItemCustom>
                                                                                    )
                                                                                })}
                                                                            </SelectFieldCustom>
                                                                            :
                                                                            <RowsFieldCustom
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                value={row.code}
                                                                                fullWidth
                                                                                disabled={props.input.disabled ? props.input.disabled : !props.input.dictionary ? !row.new : null}
                                                                                onChange={e => {
                                                                                    if (props.input.dictionary) {
                                                                                        row.changed = true;
                                                                                    }
                                                                                    row.code = e.target.value;
                                                                                    props.stateCallback(props.value);
                                                                                    setSeeError(true);
                                                                                    checkError(props.input.stateName, props.value);
                                                                                }}
                                                                                type='text'
                                                                                placeholder={props.input.labelsNames ? null : 'Code'}
                                                                            />
                                                                }
                                                            </Grid>

                                                            {/* // * Second input */}
                                                            <Grid item xs={
                                                                props.input.filter 
                                                                    ? 2
                                                                    : props.value.length === 1
                                                                        ? 7
                                                                        : 6
                                                            } style={{ paddingRight: props.input.filter ? 15 : 0 }}>
                                                                {
                                                                    props.input.dictionarySelect ?
                                                                        <SelectFieldCustom
                                                                            id={props.input.stateName}
                                                                            variant="outlined"
                                                                            color="secondary"
                                                                            select
                                                                            fullWidth
                                                                            label={props.input.currentLang ? row.values[props.input.currentLang] || '' : row.values || ''
                                                                                ? null
                                                                                : props.input.labelsNames && props.input.labelsNames.second}
                                                                            value={props.input.currentLang ? row.values[props.input.currentLang] || '' : row.values || ''}
                                                                            onChange={e => {
                                                                                row.changed = true;
                                                                                if (props.input.currentLang) {
                                                                                    row.values[props.input.currentLang] = e.target.value;
                                                                                } else {
                                                                                    row.values = e.target.value;
                                                                                }
                                                                                props.stateCallback(props.value);
                                                                                setSeeError(true);
                                                                                checkError(props.input.stateName, props.value);
                                                                            }}
                                                                            disabled={props.input.disabled ? true : false}
                                                                            error={seeError && errorMessage ? true : false}
                                                                        >
                                                                            {props.allState.currentAttribute.attributeOptions.edges.map((option, index) => {
                                                                                let getTranslation = option.node.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                                                return (
                                                                                    <MenuItemCustom key={`OptionSelect${index}`} value={option.node.id}>
                                                                                        {getTranslation.node.value || option.node.translation.translationDatas.edges[0].node.value}
                                                                                    </MenuItemCustom>
                                                                                )
                                                                            })}
                                                                        </SelectFieldCustom>
                                                                        :
                                                                        props.input.filter ?
                                                                            <SelectFieldCustom
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                value={row.operator}
                                                                                label={row.operator
                                                                                    ? null
                                                                                    : props.input.labelsNames?.second}
                                                                                fullWidth
                                                                                SelectProps={{
                                                                                    MenuProps: {
                                                                                        elevation: 0,
                                                                                        PaperProps: {
                                                                                            square: true,
                                                                                            style: {
                                                                                                border: `1px solid ${colors.grey.border}`,
                                                                                                marginTop: '4px',
                                                                                                boxShadow: 'none',
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                disabled={props.input.disabled}
                                                                                onChange={e => {
                                                                                    row.changed = true;
                                                                                    row.operator = e.target.value;
                                                                                    if (e.target.value === 'IN' || e.target.value === 'NOT IN') {
                                                                                        row.values = []
                                                                                    } else {
                                                                                        row.values = ''
                                                                                    }
                                                                                    props.stateCallback(props.value);
                                                                                    setSeeError(true);
                                                                                    checkError(props.input.stateName, props.value);
                                                                                }}
                                                                                select
                                                                                placeholder={props.input.labelsNames ? null : 'Code'}
                                                                            >
                                                                                {props.input.operators.map((option, index) => {
                                                                                    return (
                                                                                        <MenuItemCustom key={`OptionSelect${index}`} value={option.id}>
                                                                                            {option.label}
                                                                                        </MenuItemCustom>
                                                                                    )
                                                                                })}
                                                                            </SelectFieldCustom>
                                                                            :
                                                                            <TextFieldCustom
                                                                                variant="outlined"
                                                                                placeholder={props.input.labelsNames.second}
                                                                                color="secondary"
                                                                                value={props.input.currentLang ? row.values[props.input.currentLang] || '' : row.values || ''}
                                                                                fullWidth
                                                                                SelectProps={{
                                                                                    MenuProps: {
                                                                                        elevation: 0,
                                                                                        PaperProps: {
                                                                                            square: true,
                                                                                            style: {
                                                                                                border: `1px solid ${colors.grey.border}`,
                                                                                                marginTop: '4px',
                                                                                                boxShadow: 'none',
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                label={row.code
                                                                                    ? null
                                                                                    : props.input.labelsNames && props.input.labelsNames.second}
                                                                                disabled={props.input.disabled}
                                                                                onChange={e => {
                                                                                    row.changed = true;
                                                                                    if (props.input.currentLang) {
                                                                                        row.values[props.input.currentLang] = e.target.value;
                                                                                    } else {
                                                                                        row.values = e.target.value;
                                                                                    }
                                                                                    props.stateCallback(props.value);
                                                                                    setSeeError(true);
                                                                                    checkError(props.input.stateName, props.value);
                                                                                }}
                                                                                type='text'
                                                                                // placeholder={props.input.labelsNames ? null : 'Valeur'}
                                                                            />
                                                                }
                                                            </Grid>

                                                            {/* // * Third input */}
                                                            {
                                                                props.input.filter &&
                                                                <Grid item xs={
                                                                    props.input.filter 
                                                                        ? props.input.lockFirst && i === 0 
                                                                            ? props.value.length === 1
                                                                                ? 7 
                                                                                : 6
                                                                            : props.value.length === 1
                                                                                ? 5
                                                                                : 4
                                                                        : 5
                                                                }>
                                                                    {/* {console.log( (row.operator === "IN" || row.operator === "NOT IN") && row.attributeType?.node.attributeType.input !== "select" && row.values.split('"').filter((e, i) => i % 2))} */}
                                                                    {/* {console.log(row, row.attributeType?.node.attributeType.input || 'text', 'test')} */}
                                                                    {
                                                                        (row.operator === "IN" || row.operator === "NOT IN") && row.attributeType?.node.attributeType.input !== "select" ?
                                                                            <ChipInputCustom
                                                                                color="secondary"
                                                                                variant="outlined"
                                                                                error={(props.input.lockFirst && i === 0) && seeError && row.values.length === 0 ? true : false}
                                                                                value={
                                                                                    row.values && typeof row.values === 'string'
                                                                                        ? row.values.split('"').filter((e, i) => i % 2)
                                                                                        : row.values
                                                                                }
                                                                                fullWidth
                                                                                fullWidthInput
                                                                                disabled={props.input.disabled}
                                                                                inputValue={row.typingChips}
                                                                                onDelete={(chip, index) => {
                                                                                    row.changed = true;
                                                                                    if (typeof row.values === 'object') {
                                                                                        row.values.splice(index, 1)
                                                                                    }
                                                                                    props.stateCallback(props.value)
                                                                                    setSeeError(true);
                                                                                    checkError(props.input.stateName, props.value);
                                                                                }}
                                                                                onAdd={(chip) => {
                                                                                    let getSplit = chip.split("|")
                                                                                    row.changed = true;
                                                                                    row.values.push(...getSplit);
                                                                                    row.typingChips = "";
                                                                                    row.showButton = false;
                                                                                    props.stateCallback(props.value)
                                                                                    setSeeError(true);
                                                                                    checkError(props.input.stateName, props.value);
                                                                                }}
                                                                                onUpdateInput={(e) => {
                                                                                    row.typingChips = e.target.value;
                                                                                    if (e.target.value) {
                                                                                        row.showButton = true;
                                                                                    } else {
                                                                                        row.showButton = false;
                                                                                    }
                                                                                    props.stateCallback(props.value)
                                                                                }}
                                                                            // classes={{ inputRoot: classes.inputRoot }}
                                                                            />
                                                                            :
                                                                            <SelectFieldCustom
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                placeholder={['text', undefined].includes(row.attributeType?.node.attributeType.input) && props.input.labelsNames?.third}
                                                                                value={
                                                                                    props.input.currentLang
                                                                                        ? row.values[props.input.currentLang]
                                                                                            ? row.values[props.input.currentLang]
                                                                                            : (row.operator === "IN" || row.operator === 'NOT IN') ? [] : ''
                                                                                        : row.values
                                                                                            ? row.values
                                                                                            : (row.operator === "IN" || row.operator === 'NOT IN') ? [] : ''
                                                                                }
                                                                                fullWidth
                                                                                disabled={props.input.disabled}
                                                                                onChange={e => {
                                                                                    row.changed = true;
                                                                                    let data = e.target.value;
                                                                                    if (props.input.currentLang) {
                                                                                        row.values[props.input.currentLang] = data;
                                                                                    } else {
                                                                                        row.values = data;
                                                                                    }
                                                                                    props.stateCallback(props.value);
                                                                                    setSeeError(true);
                                                                                    checkError(props.input.stateName, props.value);
                                                                                }}
                                                                                label={row.attributeType?.node.attributeType.input !== 'select'
                                                                                    ? null
                                                                                    : row.values.length === 0
                                                                                        ? props.input.labelsNames?.third
                                                                                        : null
                                                                                }
                                                                                SelectProps={{
                                                                                    multiple: row.attributeType?.node.attributeType.input === "select" ? row.operator === "IN" || row.operator === 'NOT IN' : false,
                                                                                    renderValue: (selected) => {
                                                                                        if (row.operator === "IN" || row.operator === 'NOT IN') {
                                                                                            return (
                                                                                                <div className={classes.chips}>
                                                                                                    {selected.map((value) => {
                                                                                                        let getName = row.attributeType?.node?.attributeOptions?.edges.find(e => e.node.id === value)
                                                                                                        let getTranslation = getName.node.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                                                                        return (
                                                                                                            <ChipCustom key={value} label={getTranslation?.node.value || getName?.node.translation.translationDatas.edges[0].node.value} className={classes.chip} />
                                                                                                        )
                                                                                                    }
                                                                                                    )}
                                                                                                </div>
                                                                                            )
                                                                                        } else {
                                                                                            let getName = row.attributeType?.node?.attributeOptions?.edges.find(e => e.node.id === selected)
                                                                                            let getTranslation = getName.node.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                                                            return getTranslation?.node.value || getName?.node.translation.translationDatas.edges[0].node.value
                                                                                        }
                                                                                    },
                                                                                    MenuProps: {
                                                                                        elevation: 0,
                                                                                        PaperProps: {
                                                                                            square: true,
                                                                                            style: {
                                                                                                border: `1px solid ${colors.grey.border}`,
                                                                                                marginTop: '4px',
                                                                                                boxShadow: 'none',
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                select={row.attributeType?.node.attributeType.input === "select" ? true : false}
                                                                                type={row.attributeType?.node.attributeType.input || 'text'}
                                                                                usechip={row.operator === "IN" || row.operator === 'NOT IN'}
                                                                            >
                                                                                {
                                                                                    row.attributeType?.node.attributeType.input === "select" && row.attributeType?.node?.attributeOptions?.edges.length > 0 ?
                                                                                        row.attributeType?.node.attributeOptions.edges.map((attribute, index) => {
                                                                                            let getTranslation = attribute.node.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                                                            return (
                                                                                                <MenuItemCustom key={`option-select-attribute${index}`} value={attribute?.node?.id}>
                                                                                                    {getTranslation?.node.value || attribute.node.translation.translationDatas.edges[0].node.value}
                                                                                                </MenuItemCustom>
                                                                                            )
                                                                                        })
                                                                                        : null
                                                                                }
                                                                            </SelectFieldCustom>
                                                                    }
                                                                </Grid>
                                                            }
                                                        </>
                                                    )
                                            }

                                            {/* //* Delete button */}
                                            {
                                                (props.input.lockFirst && i === 0) || props.value.length === 1 ?
                                                    null
                                                    :
                                                    <Grid item xs={1} style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                        maxHeight: 45 // The input height
                                                    }}>
                                                        {
                                                            !props.input.disabled ? (
                                                                <Tooltip title="Supprimer">
                                                                    <Grid container justifyContent={'center'} alignItems={'center'} style={{
                                                                        border: `1px solid ${colors.grey.border}`,
                                                                        backgroundColor: colors.white,
                                                                        borderRadius: '50%',
                                                                        height: 30,
                                                                        width: 30,
                                                                        cursor: 'pointer'
                                                                    }} onClick={() => {
                                                                        if (!row.new) {
                                                                            row.deleted = true;
                                                                            props.stateCallback(props.value);
                                                                        } else {
                                                                            props.stateCallback(props.value.filter(e => e.id !== row.id));
                                                                        }
                                                                    }}>
                                                                        <CloseSharpIcon style={{
                                                                            fontSize: 20,
                                                                        }} />
                                                                    </Grid>
                                                                </Tooltip>
                                                            ) : null
                                                        }
                                                    </Grid>
                                            }
                                        </Grid>

                                        {
                                            (row.operator === "IN" || row.operator === "NOT IN") && row.attributeType?.node.attributeType.input !== "select" ?
                                                <Grid container style={{ marginTop: 8 }}>
                                                    <Typography style={{ color: colors.grey.lighter.hue600 }}>Utilisez la touche Entrée pour valider les valeurs, vous pouvez utiliser "|" pour séparer si vous voulez en insérer plusieurs d'une seule fois. (Exemple: 123|1234|12345)</Typography>
                                                </Grid>
                                                : null
                                        }
                                    </Box>
                                </Grid>
                                {
                                    props.input.autoLabels?.show && props.input.helper ?
                                        <Grid item xs={12} style={{ marginBottom: 15 }}>
                                            <Typography variant={'body1'} style={{
                                                fontSize: 12,
                                                color: colors.grey.regular,
                                                marginTop: 3
                                            }}>{props.input.helper.text}</Typography>
                                        </Grid>

                                        : null
                                }
                            </>
                        ))
                }
                {
                    props.value?.length > 0 && props.input.dictionary ?
                        <>
                            <Grid container style={{ color: colors.grey.lighter.hue600 }}>Afin de définir une valeur clé pour rechercher tous les enregistrements utilisant cette valeur, entouré la de |%|.</Grid>
                            <Grid container style={{ color: colors.grey.lighter.hue600 }}><strong>Exemple : </strong> |%|Valeur|%|</Grid>
                        </>
                        : null
                }
                {!props.input.disabled && (
                    <Grid
                        container
                        alignItems={'center'}
                        justifyContent={'end'}
                        style={{
                            color: colors.green.regular,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            marginLeft: 'auto',
                            display: 'inline-flex',
                            width: 'auto'
                        }}
                        onClick={addNewRow}
                    >
                        <AddCircleIcon fontSize="small" style={{ marginRight: 5, marginTop: 2, fontSize: 16 }} />
                        <span>Ajouter une ligne</span>
                    </Grid>
                )
                }
                {
                    seeError && errorMessage ?
                        <TypographyCustom variant="body2" colortext={colors.red.regular} style={{ marginBottom: "18px", width: "100%" }}>{errorMessage}</TypographyCustom>
                        : null
                }
            </Grid>
        </>
    )
}

export default Rows;