import { Grid, Tooltip, Box } from "@mui/material";
import {
  GridCustom,
  HiddenUploadFile,
  LinkText,
} from "../../styles/inputStyled";
import CardCustom from "../../../../layouts/Card/CardCustom";
import DeleteIcon from "@mui/icons-material/Delete";
import TypographyCustom from "../../../typography/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuidv4 } from "uuid";
import LayoutBuilder from "../../LayoutFormBuilder";
import { RowsVariantFieldCustom } from "./styles/styled";
import { useEffect, useState } from "react";
import InputBuilder from "../../InputBuilder";

const RowsVariant = ({
  allProps: props,
  seeError,
  setSeeError,
  checkError,
  errorMessage,
  updateCallback,
  handleOpenMediaPicker,
  openMediaPicker,
  mediaPickerConfig,
}) => {
  const [rowsVariant, setRowsVariant] = useState([]);

  useEffect(() => {
    updateCallback(rowsVariant);
  }, [rowsVariant]);

  const handleChangeVariant = (event, row, variant) => {
    const newRowsVariant = [...rowsVariant];
    const rowVariantIndex = rowsVariant.findIndex((e) => {
      return e.id === row.id;
    });

    const handleVariantAttributes = () => {
      return {
        ...row,
        allAttr: [
          ...row.allAttr.map((e) => {
            if (e.id === variant.id) {
              e.values = event.target.value;
            }
            return e;
          }),
        ],
        values: event,
      };
    };
    const handleVariantImage = () => {
      return {
        ...row,
        image: {
          value: event,
        },
      };
    };
    const handleVariant = 'file' in event ? handleVariantImage : handleVariantAttributes;

    if (rowVariantIndex !== -1) {
      newRowsVariant[rowVariantIndex] = handleVariant();
    } else {
      newRowsVariant.push(handleVariant());
    }
    setRowsVariant(newRowsVariant);
  };

  return (
    <Grid container direction="row" spacing={0}>
      {props.value?.map((row, i) => {
        if (row.deleted) return null;

        return (
          <Grid
            key={i}
            item
            xs={12}
            style={
              seeError
                ? { border: "solid 1px red", marginBottom: 15 }
                : { border: "none", marginBottom: 15 }
            }
          >
            <CardCustom paddingbottom="16px" paddingtop="16px">
              <Grid container>
                <TypographyCustom
                  variant="body2"
                  colortext="#333333"
                  style={{ marginBottom: "18px" }}
                >
                  Variant {row.deleted ? i - 1 : i + 1}
                </TypographyCustom>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={0}
                style={{ position: "relative" }}
              >
                {row.allAttr.map((variant, index) => {
                  return (
                    <InputBuilder
                      key={`VariantProduct${index}`}
                      value={variant.values}
                      input={{
                        type: "select",
                        label: variant.label,
                        translated: false,
                        helper: {
                          text: "Choisir une image",
                          link: false,
                        },
                        required: false,
                        stateName: variant.identifier,
                        value: variant.valuesForSelect.map((option) => ({
                          value: option.node.id,
                          label:
                            option.node.translation.translationDatas.edges[0]
                              .node.value,
                        })),
                      }}
                      stateCallback={(event) =>
                        handleChangeVariant(event, row, variant)
                      }
                    />

                    // <Grid item xs={4} style={{ paddingRight: 20, marginTop: 8 }} key={`VariantProduct${index}`}>
                    //     <span>{variant.label}</span>
                    //     <RowsVariantFieldCustom
                    //         id={props.input.stateName}
                    //         variant="outlined"
                    //         color="secondary"
                    //         select={variant.type === "select" ? true : false}
                    //         fullWidth
                    //         type={variant.type}
                    //         value={variant.values ? variant.values : variant.valuesForSelect.length > 0 ? variant.valuesForSelect[0].node.id : ''}
                    //         onChange={e => {
                    //             variant.values = e.target.value;
                    //             if (!row.new) {
                    //                 row.updated = true;
                    //             }
                    //             props.stateCallback(props.value);
                    //             setSeeError(true);
                    //             checkError(props.value);
                    //         }}
                    //         // helperText={seeError ? errorMessage : null}
                    //         disabled={props.input.disabled ? true : false}
                    //         // error={seeError && errorMessage ? true : false}
                    //         style={{ marginTop: "8px" }}
                    //     >
                    //         {
                    //             variant.type === 'select' ?
                    //                 variant.valuesForSelect.map((option, index) => {
                    //                     return (
                    //                         <MenuItem key={`OptionSelect${index}`} value={option.node.id} disabled={option.disabled}>
                    //                             {option.node.translation.translationDatas.edges[0].node.value}
                    //                         </MenuItem>
                    //                     )
                    //                 })
                    //                 : null
                    //         }
                    //     </RowsVariantFieldCustom>
                    // </Grid>
                  );
                })}

                {props.input.hasPrice ? (
                  <Grid item xs={4} style={{ paddingRight: 20, marginTop: 8 }}>
                    <span>Prix</span>
                    <RowsVariantFieldCustom
                      variant="outlined"
                      color="secondary"
                      value={row.price.value || ""}
                      fullWidth
                      onChange={(e) => {
                        // row.price.changed = true;
                        row.price.value = e.target.value;
                        if (!row.new) {
                          row.updated = true;
                        }
                        props.stateCallback(props.value);
                        setSeeError(true);
                        checkError(props.value);
                      }}
                      type="text"
                      placeholder="Prix"
                      style={{ marginTop: "8px" }}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={4} style={{ paddingRight: 20, marginTop: 8 }}>
                  <span>Image</span>
                  <InputBuilder
                    input={{
                      ...props.input,
                      type: "oneImage",
                      label: "Image",
                      translated: false,
                      helper: {
                        text: "Choisir une image",
                        link: false,
                      },
                      stateName: "image" + i,
                    }}
                    value={row.image.value}
                    stateCallback={(event) => {
                      handleChangeVariant(event, row);
                    }}
                  />
                  <GridCustom item xs={props.xs ? props.xs : 12}>
                    <HiddenUploadFile
                      id={props.input.stateName + i}
                      type="file"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={
                        props.input.helper?.link ? (
                          <Box>
                            <LinkText variant="h5">
                              {props.input.helper?.text}
                            </LinkText>
                          </Box>
                        ) : (
                          props.input.helper?.text
                        )
                      }
                      hidden={true}
                    />
                    {/* <ContainerFileUpload
                      style={{
                        background: `url(${
                          row.image.value?.data
                            ? [
                                "video/mpeg",
                                "video/webm",
                                "video/mp4",
                              ].includes(row.image.value.types)
                              ? videoLogo
                              : row.image.value.filePath
                              ? process.env.REACT_APP_API_ROOT +
                                "/medias/" +
                                row.image.value.filePath
                              : row.image.value.data
                            : row.image.value?.filePath
                            ? process.env.REACT_APP_API_ROOT +
                              "/medias/" +
                              row.image.value.filePath
                            : `${colors.grey.lighter.hue900}`
                        }) no-repeat center`,
                      }}
                      fullwidth={props.input.fullwidth}
                    >
                      <ClickToUpload>
                        {row.image.value && row.image.value.filePath ? (
                          <>
                            <CustomFab
                              color="primary"
                              size="small"
                              disabled={props.input.disabled ? true : false}
                              onClick={() => {
                                handleOpenMediaPicker();
                                setRowVariantValue(row);
                              }}
                            >
                              <LabelFab>
                                <EditIcon />
                              </LabelFab>
                            </CustomFab>
                            <CustomFab
                              color="primary"
                              size="small"
                              style={{ marginLeft: 5 }}
                              onClick={() => {
                                row.image.value.id =
                                  props.input.defaultImage?.id || null;
                                row.image.value.data =
                                  props.input.defaultImage || null;
                                row.image.value.filePath =
                                  props.input.defaultImage?.filePath || null;
                                if (!row.new) {
                                  row.updated = true;
                                }
                                props.stateCallback(props.value);
                                setSeeError(true);
                                checkError(props.value);
                              }}
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </CustomFab>
                          </>
                        ) : (
                          <StyledFab
                            color="primary"
                            size="small"
                            disabled={props.input.disabled ? true : false}
                          >
                            <LabelFab
                              onClick={() => {
                                handleOpenMediaPicker();
                              }}
                            >
                              <AddIcon />
                            </LabelFab>
                          </StyledFab>
                        )}
                      </ClickToUpload>
                    </ContainerFileUpload> */}

                    <LayoutBuilder
                      opened={openMediaPicker}
                      dataLayout={mediaPickerConfig(
                        (value) => {
                          if (!row?.new) {
                            let getRow = props.value.find(
                              (e) => e.idVariant === row.idVariant
                            );
                            getRow.image.value = value;
                          } else {
                            let getRow = props.value.find(
                              (e) => e.id === row.id
                            );
                            getRow.image.value = value;
                          }
                          row.updated = true;
                          props.stateCallback(props.value);
                          setSeeError(true);
                          checkError(props.value);
                        },
                        props.input.stateName,
                        props.input.cardSize ? props.input.cardSize : 3,
                        handleOpenMediaPicker
                      )}
                      forClose={() => {
                        handleOpenMediaPicker();
                      }}
                    />
                  </GridCustom>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    right: "-20px",
                    top: "50%",
                    transform: "translate(-50%)",
                  }}
                >
                  <Tooltip title="Supprimer" style={{ marginTop: "8px" }}>
                    <DeleteIcon
                      style={{ color: "#FF6565", cursor: "pointer" }}
                      onClick={() => {
                        if (!row.new) {
                          row.deleted = true;
                          row.updated = true;
                          setRowsVariant(
                            props.value.filter((e) => e.id !== row.id)
                          );
                        } else {
                          setRowsVariant(
                            props.value.filter((e) => e.id !== row.id)
                          );
                        }
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              {!seeError ? null : (
                <span className="error-label">{errorMessage}</span>
              )}
            </CardCustom>
          </Grid>
        );
      })}

      <div
        style={{
          height: 21,
          color: "#59C870",
          textDecoration: "underline",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          // props.input.variantsValidated ? props.value.concat(props.input.variantsValidated.values) : props.value
          let allAttr = props.input.attributes.map((selectedAttr) => {
            return {
              label: selectedAttr.label,
              type: selectedAttr.node.attributeType.input,
              identifier: selectedAttr.node.identifier,
              id: selectedAttr.node.id,
              valuesForSelect: selectedAttr.node.attributeOptions.edges,
              // code: '',
              values:
                selectedAttr.node.attributeOptions.edges.length > 0
                  ? selectedAttr.node.attributeOptions.edges[0].node.id
                  : "",
            };
          });
          // let addVariantRow = props.input.variantsValidated ? props.value.concat(props.input.variantsValidated.values) : props.value;
          props.value.push({
            id: uuidv4(),
            new: true,
            updated: false,
            price: {
              value: props.input.defaultPrice.value || null,
            },
            image: {
              id: props.input.defaultImage?.id || null,
              value: {
                data: props.input.defaultImage,
                filePath: props.input.defaultImage?.filePath,
              },
              // id: props.input.defaultImage.id,
            },
            allAttr,
          });
          setSeeError(true);
          checkError(props.value);
          props.stateCallback(props.value);
        }}
      >
        <AddCircleIcon fontSize="small" style={{ marginRight: 10 }} />
        <span>Ajouter une variante</span>
      </div>
    </Grid>
  );
};

export default RowsVariant;
