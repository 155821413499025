import { withApollo } from '@apollo/client/react/hoc';
import colors from "@config/theme/colors";
import { SNACK, START_LOADING, STOP_LOADING } from "@constants/action-types";
import { ALERT_ERROR, ALERT_SUCCESS } from "@constants/alert-types";
import { ROUTE_CATALOGS_DETAIL } from "@constants/route-names";
import OurDrawer from "@layouts/Drawer/OurDrawer";
import Listing from "@layouts/Listing/Listing";
import TopPanel from "@layouts/TopPanel/TopPanel";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import { DELETE_CATALOG, GET_CATALOGS_BY_ID } from "@queries/catalogs";
import { eventService } from '@services/event.service';
import CatalogForm from "@ui/form/CatalogForm";
import request from '@utils/fetch';
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import slugify from 'slugify';
import { filtersOptions, listMappers, listSettings, perPageOptions, viewOptions } from "../config/listCatalogs.config";

const Catalogs = props => {
    const [dataCount,setDataCount] = useState(0);
    const [reloadListing,setReloadListing] = useState(false);
    const [openForm,setOpenForm] = useState(false);
    const [formType,setFormType] = useState("add");
    const [activeCheckIdentifier,setActiveCheckIdentifier] = useState(null);
    const [selectedItems,setSelectedItems] = useState([]);
    const [offsetListing,setOffsetListing] = useState(0);
    const [scrollListing,setScrollListing] = useState(false);
    const [catalogEdit, setCatalogEdit] = useState(null);

    const [state,setState] = useState({
        catalogFilters:[],
        exportType: "csv",
        exportLang: props.locales[0].node.code,
        exportStatus: null,
        typeTesting: {
            type: "catalog",
            testingState: ["catalogName", "catalogIdentifier"],
            testingTypingState: "catalogName",
            identifierState: "catalogIdentifier",
        },
    })

    const resetStateCatalog = () => {
        setState({
            ...state,
            imageCatalog: null,
            catalogFilters:[],
            catalogName: "",
            catalogIdentifier: "",
            catalogDescription: "",
            catalogStartAt: "",
            catalogEndAt: "",
            catalogIsActive: true,
            catalogAutosync: true,
            catalogSelected: [],
            secondTreeData: [],
            errors: {},
            seeErrors: false,
        });
    };

    const handleForm = (type) => {
        resetStateCatalog();
        setFormType(type)
        setOpenForm(!openForm);
    };

    const goToCatalog = (id) => {
        props.history.push({
            pathname: ROUTE_CATALOGS_DETAIL.replace(':id', id.replace('/api/catalogs/', '')),
            state: { catalogId: id }
        });
    };

    useEffect(() => {
      if (catalogEdit) {
        setOpenForm(true);
      } else {
        setOpenForm(false);
      }
    }, [catalogEdit]);

    const fetchCatalog = async (id) => {
      return props.client
        .query({
          query: GET_CATALOGS_BY_ID,
          variables: { id: id },
          fetchPolicy: "no-cache",
        })
        .then(async (result) => {
          const catalog = result.data.catalog;
          return catalog;
        })
        .catch(() => {
          props.snack(ALERT_ERROR, 'Une erreur est survenue lors de la récupération des données');
        });
    };
    

    const handleDelete = async(catalog) => {
        props.startLoading()
        props.client.mutate({
            mutation: DELETE_CATALOG,
            variables: { id: catalog },
            fetchPolicy: 'no-cache'
        }).then(async (result) => {
            props.snack(ALERT_SUCCESS, 'Catalogue supprimé avec succès');
            setReloadListing(true)
        }).catch((err)=>{
            props.snack(ALERT_ERROR, 'Une erreur est survenue lors de la suppression du catalogue');
        })
        .finally(()=>{
            props.stopLoading()
        })
    }

    const doneTyping = (stateName) => {
        let typeTesting = state.typeTesting;
        if (stateName === typeTesting.testingTypingState) {
            setState({
                ...state,
                [typeTesting.identifierState]: slugify(state[typeTesting.testingTypingState], { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            });
        }

        if (state[typeTesting.identifierState]) {
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${state[typeTesting.identifierState]}`, 'get')
            .then((data) => {
                if (data.success) {
                    eventService.fire({ stateName: typeTesting.identifierState, errorMessage: props.t("spread.active_assets.userAlreadyUsed") });
                }
            }).catch((err)=>{
                console.log(err)
            });
        }
        setActiveCheckIdentifier(null)
    };

    useEffect(() => {
        
      doneTyping(activeCheckIdentifier)
    }, [activeCheckIdentifier])

    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop + 0.5 >= offsetListing) {
            setScrollListing(true)
        }else{
            setScrollListing(false)
        }
      };

    return (
      <div style={{ overflow: "auto" }} onScroll={handleScroll}>
        <TopPanel
          icomoon="icon-catalogue"
          getRef
          setRef={setOffsetListing}
          colorIcomoon={colors.blue.lighter.hue300}
          title={
            process.env.REACT_APP_MODE_SPREAD !== "hub"
              ? props.t("drawer.catalogs")
              : props.t("catalogs.catalogs.manageWarehouses")
          }
          subtitle={
            process.env.REACT_APP_MODE_SPREAD !== "hub"
              ? props.t("catalogs.catalogs.manageProductCatalogs")
              : props.t("catalogs.catalogs.manageProductWarehouse")
          }
          dataCount={dataCount}
          textAdd={
            process.env.REACT_APP_MODE_SPREAD !== "hub"
              ? props.t("catalog.add")
              : null
          }
          handlerAdd={() => handleForm("add")}
          gradientColor1={colors.menu.regular}
          gradientColor2={colors.menu.darker}
          buttonAvailable={true}
          windowWidth={props.windowWidth}
        />

        <Listing
          padding={16}
          scroll={scrollListing}
          label="catalogues"
          settings={listSettings}
          perPageOptions={perPageOptions}
          mappers={listMappers}
          pagination={true}
          enablePagination={true}
          enableChangeView={true}
          enableChangeSort={true}
          enableSelectionItems={false}
          enableFilters={true}
          identifier="catalogs"
          viewsOptions={viewOptions}
          tableProps={{
            textButtonSecondary: "red",
            redirectDetail: goToCatalog,
          }}
          locales={props.locales}
          selectRadio={true}
          setDataCount={setDataCount}
          reload={reloadListing}
          setReload={setReloadListing}
          filtersOptions={filtersOptions}
          itemSelection={{
            set: setSelectedItems,
            items: selectedItems,
          }}
          cardProps={{
            // actionButtonDelete:handleDelete,
            redirectDetail: goToCatalog,
            image: "media",
            filePath: "filePath",
            title: "libelle",
            description: "description",
            attributes: [
              {
                label: "Statut",
                value: "status",
                type: "status",
              },
              {
                label: "Synchronisation",
                value: "autosync",
                type: "autoSync",
              },
            ],
            textButton: "Voir le catalogue",
            dottedMenuOptions: [
              {
                label: "Modifier",
                color: colors.blue.darker.hue300,
                isDisabled: false,
                icon: (
                  <FileCopySharpIcon
                    style={{ fill: colors.blue.darker.hue300 }}
                  />
                ),
                onClick: async (id) => {
                  props.startLoading();
                  const catalogSelected = await fetchCatalog(id);
                  props.stopLoading();
                  setCatalogEdit(catalogSelected);
                },
              },
              {
                label: "Supprimer",
                color: colors.red.regular,
                icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                modal: true,
                modalProps: {
                  identifier: "deleteCatalog",
                  type: "delete",
                  title: "Êtes-vous sûr de vouloir supprimer ce catalogue ?",
                  subtitle: "Cette action est irréversible",
                  actions: {
                    primaryAction: (id) => handleDelete(id),
                  },
                },
              },
            ],
          }}
        />

        <OurDrawer isOpen={openForm} setIsOpen={() => setOpenForm(!openForm)}>
          <CatalogForm
            catalog={catalogEdit}
            onSuccess={() => {
              setCatalogEdit(null);
              setReloadListing(true);
              setOpenForm(false);
            }}
          />
        </OurDrawer>
      </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) =>
        dispatch({ type: SNACK, payload: { type, message } }),
    };
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Catalogs))));