import React from "react";
import styled from "styled-components";
import { Drawer, Typography } from "@mui/material";
import colors from "@config/theme/colors.js";
import { ArrowBack } from "@mui/icons-material";
import { Box } from "@mui/system";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const DrawerCustom = styled(Drawer)`
  left: unset !important;
  right: 0 !important;

  & > .MuiDrawer-paper {
    z-index: 1300 !important;

    width: ${(props) =>
      props.drawerwidth
        ? typeof props.drawerwidth === "string"
          ? props.drawerwidth
          : `calc(50% - ${props.drawerwidth}px / 2)`
        : "calc(797px)"};
    @media screen and (max-width: 1500px) {
      width: ${(props) =>
        props.drawerwidth
          ? typeof props.drawerwidth === "string"
            ? props.drawerwidth
            : props.scrollable
            ? `calc(50% - ${props.drawerwidth}px / 2)`
            : `calc(60% - ${props.drawerwidth}px / 2)`
          : "calc(797px)"};
    }
    @media screen and (max-width: 1000px) {
      width: ${(props) =>
        props.drawerwidth
          ? typeof props.drawerwidth === "string"
            ? props.drawerwidth
            : `calc(100% - ${props.drawerwidth}px / 2)`
          : "calc(797px - 30% )"};
    }
    @media screen and (max-width: 765px) {
      width: 100vw;
    }
    @media screen and (max-width: 1280px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media screen and (max-width: 1000px) {
      padding-top: 20px;
    }
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    box-shadow: none;
    border: 0.5px solid ${colors.grey.lighter.hue700};
    margin-top: 0; // 80px to get as before
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
  }
`;

const BoxClose = styled(Box)`
  width: 80px;
  padding-bottom: 0;
  color: ${colors.black.regular};
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
  span {
    margin-left: 4px;
  }
`;

const OurDrawer = ({
  children,
  isOpen,
  setIsOpen,
  backdropProps,
  ...props
}) => {
  const drawerConfig = {
    variant: props.scrollable ? "persistent" : "temporary",
    anchor: "right",
    drawerwidth: props.drawerWidth,
    open: isOpen,
    ...props,
  };

  return (
    <DrawerCustom {...drawerConfig}>
      {!props.noCancel && (
        <Box>
          <Typography
            variant="h5"
            onClick={() => setIsOpen(false)}
            component="div"
          >
            <BoxClose pb={2} style={{ cursor: "pointer" }}>
              <ArrowBack />
              <span>{props.t("spread.active_assets.return")}</span>
            </BoxClose>
          </Typography>
        </Box>
      )}
      {children}
    </DrawerCustom>
  );
};

OurDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func,
  backdropProps: PropTypes.object,
  children: PropTypes.node,
};

export default withTranslation()(OurDrawer);
