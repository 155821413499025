import { GET_CATALOGS_ONLY } from "../catalogs";
import _request from "../_request";

export function fetchCatalogs(client) {
    return client.fetchQuery(
        "catalogs",
        async () => {
            return await _request(
                process.env.REACT_APP_API_GQL,
                GET_CATALOGS_ONLY,
            );
        }
    );
}