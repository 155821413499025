import {STEPPER} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';
import FinalPicto from '../../../../../../assets/pictos/empty-picto/empty_assets.png';
import ListProductsDD from '../../components/ListProductsDD';

export default function crosssellEdit(action = "add",inputAttributes, allStates) {
    if(inputAttributes){
        inputAttributes.edges = inputAttributes.edges.filter(e => e.node.internalFilter);
    }
    var obj = {
        titleForm: action === "add" ? `Créer un cross sell` : "Modifier un cross sell",
        subTitleForm: action === "add" ? 'Veuillez compléter les champs ci-dessous pour créer votre cross sell' : 'Veuillez compléter les champs ci-dessous pour modifier votre cross sell',
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: action === "add" ? 'Créer' : 'Modifier',
        formConfig: {
            type: STEPPER,
            finalStep: {
                picto: FinalPicto,
                title: 'Et voilà !',
                subtitle: action === "add" ? 'Votre nouveau cross sell est créé' : 'Votre cross sell est modifié',
                textButton: 'Terminer'
            },
            children:[
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type: 'text',
                            label: 'Nom',
                            helper: {
                                text: 'Indiquez le nom de votre cross sell',
                                link: false,
                            },
                            required: true,
                            stateName: 'crosssellName',
                        },
                        {
                            type: 'radio',
                            direction: 'row',
                            label: 'Actif',
                            helper: {
                                text: 'Indiquez l\'état',
                                link: false,
                            },
                            required: true,
                            stateName: 'crosssellStatus',
                            value: [
                                {
                                    value: true,
                                    label: 'Oui'
                                },
                                {
                                    value: false,
                                    label: 'Non'
                                },
                            ]
                        },
                        {
                            type: 'rows',
                            translated: false,
                            required: true,
                            stateName: 'crosssellListSku',
                            labelInput: 'Liste des produits: ',
                            labelsNames:{
                                first: 'Attribut',
                                second: 'Opérateur',
                                third: 'Valeurs',
                            },
                            lockFirst: true,
                            filter: true,
                            attributes: inputAttributes,
                            operators:[
                                {
                                    id: '=',
                                    label: '='
                                },
                                {
                                    id: '!=',
                                    label: '!='
                                },
                                {
                                    id: 'IN',
                                    label: 'IN'
                                },
                                {
                                    id: 'NOT IN',
                                    label: 'NOT IN'
                                },
                                {
                                    id: 'LIKE',
                                    label: 'LIKE'
                                },
                                {
                                    id: 'empty',
                                    label: 'empty'
                                }
                            ]
                        }
                    ]
                },
                {
                    labelName: 'Filtres',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type: 'rows',
                            translated: false,
                            required: true,
                            stateName: 'crossSellsFilters',
                            labelsNames:{
                                first: 'Attribut',
                                second: 'Opérateur',
                                third: 'Valeurs',
                            },
                            filter: true,
                            attributes: inputAttributes,
                            operators:[
                                {
                                    id: '=',
                                    label: '='
                                },
                                {
                                    id: '!=',
                                    label: '!='
                                },
                                {
                                    id: 'IN',
                                    label: 'IN'
                                },
                                {
                                    id: 'NOT IN',
                                    label: 'NOT IN'
                                },
                                {
                                    id: 'LIKE',
                                    label: 'LIKE'
                                },
                                {
                                    id: 'empty',
                                    label: 'empty'
                                }
                            ]
                        }
                    ]
                },
                {
                    labelName: 'Produits',
                    isOptionnal: false,
                    component: ListProductsDD,
                }
            ]
        }
    }
    return obj;
}