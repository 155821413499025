import { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-ordering';
import arrayMove from 'array-move';
import { Typography, Grid, Box } from '@mui/material';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import colors from '../../../../config/theme/colors';
import Button from '../../../ui/button/Button';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

const GridCustom = styled(Grid)`
  border: none;
  position:relative;
`;

const BtnContainerAbsolute = styled(Box)`
  position:absolute;
  bottom:0;
  left:50%;
  transform:translateX(-50%);
  display:flex;
  button{
    margin:0;
  }
`;

const TableRowCustom = styled.div`
  z-index: 1;
  display: flex;
  width: calc(100%/4 - 10px);
  margin:5px;
  height:450px;
  position: relative;
  img{
    width: 100%;
  }
  @media screen and (max-width: 1200px){
    width: calc(100%/3 - 20px);
  }
`;
const GridContainer = styled(Grid)`
  z-index: 1;
  background: white;
  display: flex;
  border: 1px solid #E3E3E3;
  flex-wrap: nowrap;
  padding: 12px;
  position: relative;
  img{
    width: 100%;
  }
`;
const BoxToAdd = styled(Box)`
  width: 40px;
  height: calc(100% - 2px);
  transition: all 0.5s ease;
  position: absolute;
  z-index: 10;
  right: 0;
  background: transparent;
  span{
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content:center;
    cursor: pointer;
    svg{
      fill: ${colors.sinfinNewMenu.normal};
      opacity: 0;
      transition: all 0.5s ease;
    }
  }
  &:hover{
    transition: all 0.5s ease;
    width:70px;
    background: white;
    span{
      border: 1px dashed #E3E3E3;
      svg{
        opacity: 1;
        transition: all 0.5s ease;
      }
    }
  }
`;

const Title = styled(Typography)`
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const DeleteText = styled(Typography)`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

export class SortableComponent extends Component {
  state = {
    items: this.props.listItems,
    boxToAdd : null
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }),async() => {
      await this.props.stateCallback('listItems', this.state.items)
      if (this.props.currentRule !== "custom"){
        this.props.stateCallback('rule', 'custom')
      }
    })
  }

  onHoverBox = (id) => {
    this.setState({
      boxToAdd : id
    })
  }
  componentDidUpdate(prevProps){
    if (prevProps.listItems !== this.props.listItems){
      this.setState({
        items: this.props.listItems??[],
      })
    }
  }

  render () {
    const SortableItem = SortableElement(
      ({value, index, id, totalElements}) =>{
        // let getName = getTraductionAttributs('product_name', value.product.productDatas.edges, this.props.currentLang);
        // let getImage = getTraductionAttributs('product_image', value.product.productDatas.edges, this.props.currentLang, 'image');
        let getName = value.name;
        let getImage = value.image;
        return (
          <TableRowCustom 
            item
            key={`row-product-${id}`} 
            className="sortable-item" 
            >
            <BoxToAdd onClick={() => this.props.handleButton(id, 'add')}>
              <span>
                <AddCircleOutlineIcon/>
              </span>
            </BoxToAdd>
            <GridContainer container direction='column'>
              {/* <IcomoonReact iconSet={iconSet} size={20} icon={"icon-drag_drop"} style={{position: "absolute", right: 8, top: 8,zIndex:1}}/> */}
              <GridCustom item align="center" style={{height:"85%"}}>
                  {
                      getImage && getImage !== '' ? 
                          (<img src={`${process.env.REACT_APP_MEDIAS}/${getImage}`} alt={`image-${getName}-${id}`} style={{maxWidth: '100%',height:"100%",objectFit:"cover", display: 'inline-block',verticalAlign: 'middle'}}/>) 
                      : 
                          (<img src={NoImage} alt={`image-${getName}-${id}`} style={{maxWidth: '100%',height:"100%",objectFit:"cover", display: 'inline-block',verticalAlign: 'middle'}}/>)
                  }
                  <BtnContainerAbsolute>
                    <Button  
                    text="Remplacer"
                      onClick={() => this.props.handleButton(id, 'replace')}                    
                      style={{
                        minWidth:28,
                        padding:"5px 20px",                        
                      }}
                    >
                      <AutorenewIcon style={{ fontSize: 20 }}/>
                   
                    </Button>
                    <Button
                    types="Delete"
                      onClick={() => this.props.handleDeleteItem(id)}                
                      style={{
                        minWidth:28,
                        padding:"0"
                      }}
                    >
                      <DeleteIcon style={{ fontSize: 20 }}/>
                    </Button>                    
                  </BtnContainerAbsolute>
              </GridCustom>
              <GridCustom style={{display: "flex",flexDirection: "column",height:"15%",paddingTop:10}}>
                  <Grid>
                    <Typography variant="body2" style={{fontSize:16}}>{value.sku}</Typography>
                    {/* <Title variant="h3">{getName || 'N/A'}</Title> */}
                    <ResponsiveEllipsis                                                
                        unsafeHTML={getName || 'N/A'}
                        maxLine={2}
                        ellipsis={'...'}
                        basedOn='words'                        
                        style={{
                          fontSize:19,
                          fontWeight:"bold"
                        }}                        
                    />
                  </Grid>
                  {/* <DeleteText variant="body2" onClick={() => this.props.handleDeleteItem(id)} style={{color: colors.red.regular}}>Supprimer</DeleteText>
                  <Button style={{margin: 0, minWidth: "auto"}} onClick={() => this.props.handleButton(id, 'replace')}><AutorenewIcon style={{marginRight: 4}}/> Remplacer</Button> */}
              </GridCustom>
            </GridContainer>
            {
              totalElements === (id + 1) ?
                <BoxToAdd onClick={() => this.props.handleButton(id + 1, 'add')}><span><AddCircleOutlineIcon/></span></BoxToAdd>
              : null
            }
          </TableRowCustom>
        )
      }
    )
 
    const SortableList = SortableContainer(({items}) => {
      return (
        <Grid container className="sortableHelper">
          {items.map((value, index) => {
            return(
              <SortableItem key={`item-${value.sku}`} totalElements={items.length} index={index} id={index} value={value} />
            )
          }
          )}
        </Grid>
      )
    })
    return (<SortableList items={this.state.items} onSortEnd={this.onSortEnd} axis={"xy"} distance={1}/>)
  }
}