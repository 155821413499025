import React from "react";
import { Typography } from "@mui/material";
import colors from "@/config/theme/colors";
import PropTypes from "prop-types";

const TextHelper = ({ children, ...rest }) => (
  <Typography
    variant="body2"
    sx={{
      color: colors.grey.regular,
      fontSize: "14px",
      lineHeight: "18px",
      fontStyle: "italic",
      fontWeight: "normal",
      textAlign: "left",
    }}
    {...rest}
  >
    {children}
  </Typography>
);

TextHelper.propTypes = {
    children: PropTypes.node,
};

export default TextHelper;

