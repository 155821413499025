import { ROUTE_CONTENU_ATTRIBUTES, ROUTE_CONTENU_CATEGORIES, ROUTE_MEDIAS_CATEGORIES, ROUTE_MEDIAS_TAGS, ROUTE_PRODUCTS_ATTRIBUTS, ROUTE_PRODUCTS_CATEGORIES, ROUTE_PRODUCTS_COMPLETUDE } from "../../../../../../../js/constants/route-names";
import { menuEntry } from "../../../../../../../js/utils/menuEntry";
import { SECTIONS } from "../constants/constants";

export const getSections = (countsData, itemFetchedDatas) => [
    (menuEntry("centralisation_products") || true) && {
        id: SECTIONS.PRODUCTS,
        title: "produits",
        icon: "picto-produit",
        count: countsData?.[SECTIONS.PRODUCTS]?.totalCount,
        items: {
            ...itemFetchedDatas[SECTIONS.PRODUCTS]
        }
    },
    (menuEntry("centralisation_catalogs") || true) && {
        id: SECTIONS.CATALOGS,
        title: "catalogues",
        icon: "ico_bookmarks",
        count: countsData?.[SECTIONS.CATALOGS]?.totalCount,
        items: {
            ...itemFetchedDatas[SECTIONS.CATALOGS]
        }
    },
    (menuEntry("centralisation_medias") || true) && {
        id: SECTIONS.MEDIAS,
        title: "médias",
        icon: "picto-media",
        count: countsData?.[SECTIONS.MEDIAS]?.totalCount,
        items: {
            ...itemFetchedDatas[SECTIONS.MEDIAS]
        }
    },
    (menuEntry("centralisation_contents") || true) && {
        id: SECTIONS.CONTENTS,
        title: "contenus",
        icon: "picto-connector-content",
        count: countsData?.[SECTIONS.CONTENTS]?.totalCount,
        items: {
            ...itemFetchedDatas[SECTIONS.CONTENTS]
        }
    },
]

export const formatFetchedDatas = ({
    product,
    content,
    media,
}) => ({
    [SECTIONS.PRODUCTS]: {
        categories: (menuEntry("centralisation_configurations_product_categories") || true) && {
            title: `Catégories (${product.categories.length})`,
            description: "Gestion des catégories de produits",
            icon: "picto-produit",
            count: product.categories.length,
            button: {
                text: 'Configurer',
                route: ROUTE_PRODUCTS_CATEGORIES
            }
        },
        completenessGroups: (menuEntry("centralisation_configurations_product_completudes") || true) && {
            title: `Complétudes (${product.completenessGroups.totalCount})`,
            description: "Gestion des complétudes de produits",
            icon: "picto-produit",
            count: product.completenessGroups.totalCount,
            button: {
                text: 'Configurer',
                route: ROUTE_PRODUCTS_COMPLETUDE
            }
        },
        attributes: (menuEntry("centralisation_configurations_product_attributes") || true) && {
            title: `Attributs (${JSON.parse(localStorage.getItem("ATTRIBUTE_GROUPS"))?.filter(attribute => !attribute.node.isSystem || attribute.node.identifier === "default").length})`,
            description: "Gestion des attributs de produits",
            icon: "picto-produit",
            count: JSON.parse(localStorage.getItem("ATTRIBUTE_GROUPS"))?.filter(attribute => !attribute.node.isSystem || attribute.node.identifier === "default").length,
            button: {
                text: 'Configurer',
                route: ROUTE_PRODUCTS_ATTRIBUTS
            }
        },
    },
    [SECTIONS.MEDIAS]: {
        categories: (menuEntry("centralisation_configurations_media_categories") || true) && {
            title: `Catégories (${media.mediaObjectCategories.totalCount})`,
            description: "Gestion des catégories de médias",
            icon: "picto-media",
            count: media.mediaObjectCategories.totalCount,
            button: {
                text: 'Configurer',
                route: ROUTE_MEDIAS_CATEGORIES
            }
        },
        tags: (menuEntry("centralisation_configurations_media_tags") || true) && {
            title: `Tags (${media.mediaObjectTags.totalCount})`,
            description: "Gestion des tags de médias",
            icon: "picto-media",
            count: media.mediaObjectTags.totalCount,
            button: {
                text: 'Configurer',
                route: ROUTE_MEDIAS_TAGS
            }
        },
    },
    [SECTIONS.CONTENTS]: {
        categories: (menuEntry("centralisation_configurations_content_categories") || true) && {
            title: `Catégories (${content.contentCategories.filter(category => category.parent).length})`,
            description: "Gestion des catégories de contenus",
            icon: "picto-connector-content",
            count: content.contentCategories.filter(category => category.parent).length,
            button: {
                text: 'Configurer',
                route: ROUTE_CONTENU_CATEGORIES
            }
        },
        models: (menuEntry("centralisation_configurations_content_templates") || true) && {
            title: `Modèles (${JSON.parse(localStorage.getItem("GET_COMPLETENESSGROUPS")).data.completenessGroups.edges?.length})`,
            description: "Gestion des modèles de contenus",
            icon: "picto-connector-content",
            count: JSON.parse(localStorage.getItem("GET_COMPLETENESSGROUPS")).data.completenessGroups.edges?.length || 0,
            button: {
                text: 'Configurer',
                route: ROUTE_CONTENU_ATTRIBUTES
            }
        },
    },
    // [SECTIONS.CATALOGS]: {},
})