import {useState} from 'react';

import styled from 'styled-components';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';

import HeadingCell from './cells/HeadingCell'
import CellText from './cells/CellText';
import CellImage from './cells/CellImage';
import CellLink from './cells/CellLink';
import CellAvatars from './cells/CellAvatars';
import CellProfilePicture from './cells/CellProfilePicture';
import colors from '../../../../../config/theme/colors';
import Button from '../../../../ui/button/Button';
import CellDate from './cells/CellDate';
import CellStatus from './cells/CellStatus';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

    
const CustomRow = styled(TableRow)`
    background : ${props => props.styling.rowBackground ? props.styling.rowBackground : 'white'};
    ${props => props.styling.type === 'cards' ? 
        `
        border-radius : 10px;
        &:hover{
            box-shadow :0px 1px 9px -1px rgba(0,0,0,0.15);
        }
        `
    : null}
    ${props => props.styling.type === 'striped' ? 
        `   
            background : #f2f2f2;
            &:nth-of-type(odd){
                background :  white;
            }
        `
    : null}
    ${props => props.styling.type === 'classic' ? 
        `   
            .MuiTableCell-root{
                border-bottom: 0.5px solid ${colors.grey.border};
            }
        `
    : null}
`
const CustomTable = styled(Table)`
    th{
        background: ${colors.blue.darker.hue300};
    }
    ${props => props.styling.type === 'cards' ? `
        border-spacing: 0px 10px;
        border-collapse:separate;
    `:null}
`

const HeadRow = styled(TableRow)`
    background : ${props=> props.styling?.headingBackground ? props.styling.headingBackground: '#003e4d' };

    th{
        color : ${props=> props.styling?.headingColor ? props.styling.headingColor : 'white' };
    }
`
const GridCustom = styled(Grid)`
    white-space: nowrap;
    cursor: pointer;
    span:hover{
        color: ${props => props.colorHover ? props.colorHover : colors.black.regular};
    }
`

const cells = {
    'text' : CellText,
    'image' : CellImage,
    'link'  : CellLink,
    'avatars' : CellAvatars,
    'profil_picture' : CellProfilePicture,
    'date' : CellDate,
    'switch': CellText,
    'select': CellText,
    'status': CellStatus,
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const Rows = (props) => {
    return (
        <CustomRow 
            key={`list-${capitalize(props.label)}-${props.index}`}
            styling = {props.styling}
        >
            {props.columns.map((column)=>{
                let Cell = cells[column.cellType];
                let value = props.item[column.field];
                let media = null;
                if (typeof value !== "boolean"){
                    if (!value || value === undefined){
                        if (props.item.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.attribute.attributeType.input === "image"){
                            media = props.item.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.media
                        }
                        value = props.item.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.value
                    }
                }
                return(
                    <Cell value={value} column={column} media={media} width={column.width} {...column.cellProps} attribute={props.item.shellDatas?.edges.find(e => e.node.attribute.identifier === column.field)?.node}/>
                )
            })}
            <TableCell align='center'>
                <GridCustom container alignItems='center' colorHover={colors.blue.darker.hue300}  onClick={() => props.tableProps.handlerButton(props.item)}>
                    <EditIcon style={{fill: colors.blue.darker.hue300, fontSize:14, marginRight: 4}}/>
                    <span><strong>{props.tableProps.textButton}</strong></span>
                </GridCustom>
            </TableCell>
                {
                    props.tableProps.textButtonDelete ?
                        <TableCell align='center'>
                            <GridCustom container alignItems='center' colorHover={colors.red.regular} onClick={() => props.tableProps.handlerButtonDelete(props.item)}>
                                <DeleteIcon style={{fill: colors.red.regular, fontSize:14, marginRight: 4}}/>
                                <span><strong>{props.tableProps.textButtonDelete}</strong></span>
                            </GridCustom>
                        </TableCell>
                    : null 
                }
        </CustomRow>
    )
}

export default function TableViewSimple(props){
    let {settings,items,label} = props;
    const columns = settings?.columns;
    return(
        <div className='listing' style={{maxHeight: 650, position:'relative', overflow:'auto'}}>
            <CustomTable aria-label="headerList" styling={settings.style} stickyHeader>
                <TableBody>
                    {
                        props.ready && items ? 
                            items.map((item,index)=>(
                                <Rows item={item} index={index} columns={columns} styling={props.settings.style} key={`row-${index}`} label={label} {...props} />
                            ))
                        : 
                        <tr>
                            <td colSpan={columns?.length ? columns.length : 1}><PageLoader/></td>                 
                        </tr>
                    }
                </TableBody>
            </CustomTable>
        </div>
    )
}

