import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@mui/material';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import * as moment from 'moment';
import OurTypography from '../../../ui/typography/Typography';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditSharpIcon from '@mui/icons-material/CreateSharp';

const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    width: 100%;
    padding: ${props => props.windowwidth > 1250 ? '25px 42px' : '12px 18px'};
    border-radius: 0px !important;
`

const CardLayout = styled(Grid)`
    & > div {
        padding-right: 10px;
        padding-left: 10px;
    }
`

export default function CardFooter(props) {

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.black.regular,
        },
        tooltip: {
            backgroundColor: colors.black.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }
    return (
        <ContainerCard windowwidth={props.windowWidth}>
            <CardLayout container alignItems='center'>
                <Grid item xs={1}><StatusInfo status={props.data.node.active} width={'auto'} /></Grid>

                <Grid item xs={2} style={{ paddingLeft: props.windowWidth > 1250 ? 0 : 20, paddingRight: props.windowWidth > 1250 ? 40 : 20 }}><OurTypography variant="h4" fontweighttext={'900'} style={{
                    fontSize: '16px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>{props.data.node.name}</OurTypography></Grid>                                
                <Grid item xs={2}><OurTypography variant="body1" style={{ fontSize: '13px', textAlign: 'center' }}><span style={{ color: colors.grey.regular, marginRight: 5 }}>{props.windowWidth > 1250 ? 'Date de début:' : 'Début:'}</span><span style={{ fontWeight: 'bold', color: colors.black.regular }}>{props.data.node.startedAt ? moment(props.data.node.startedAt).utc().format('DD/MM/YYYY') : 'N/A'}</span></OurTypography></Grid>
                <Grid item xs={2}><OurTypography variant="body1" style={{ fontSize: '13px', textAlign: 'center' }}><span style={{ color: colors.grey.regular, marginRight: 5 }}>{props.windowWidth > 1250 ? 'Date de fin:' : 'Fin:'}</span><span style={{ fontWeight: 'bold', color: colors.black.regular }}>{props.data.node.endedAt ? moment(props.data.node.endedAt).format('DD/MM/YYYY') : 'N/A'}</span></OurTypography></Grid>
                <Grid item xs={3}/>
                <Grid item xs={1}>
                    <OurTypography variant="body1" colortext={colors.black.regular} fontweighttext={'900'} style={{
                        fontSize: '12px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }} onClick={() => props.handlerButton(props.data.node)}>
                        <Grid container alignItems='center' justifyContent={props.windowWidth > 1250 ? 'center' : 'flex-end'}>
                            <EditSharpIcon style={{ fontSize: '16px', marginRight: 5, color: colors.blue.darker.hue300 }} /> {props.windowWidth > 1250 ? 'Modifier' : null}
                        </Grid>
                    </OurTypography>
                </Grid>
                <Grid item xs={1}>
                    <OurTypography variant="body1" colortext={colors.black.regular} fontweighttext={'900'} style={{
                        fontSize: '12px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }} onClick={() => props.handlerButtonSecondary(props.data.node)}>
                        <Grid container alignItems='center' justifyContent='flex-end'>
                            <DeleteSharpIcon style={{ fontSize: '16px', marginRight: 5, color: colors.red.regular }} /> {props.windowWidth > 1250 ? 'Supprimer' : null}
                        </Grid>
                    </OurTypography>
                </Grid>

                {/* <Button disabled={props.openForm} onClick={() => props.handlerButton(props.data.node)} text={props.textButton} style={{ margin: '0px' }} /> */}
                {/* <Button disabled={props.openForm} onClick={() => props.handlerButtonSecondary(props.data.node)} text={props.textButtonSecondary} style={{ margin: '0px', marginLeft: '8px' }} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} /> */}
            </CardLayout>
        </ContainerCard>
    );
}