import {FORM, STEPPER} from '../../../../../js/constants/form-types';
import { MEDIAPICKER_TYPES } from '../../../../../js/constants/mediapickerTypes.enum';
import Button from '../../../../ui/button/Button';
import SearchTags from '../../../../ui/inputs/SearchTags';
export default function mediasCategoriesConfig (categories,categorieSelected,allTags,newTags){
    
    var obj={
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        titleForm: 'Ajouter un média',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre média',
        textButtonValidate: 'Créer',
        formConfig:{
            type: FORM,
            header: {
                title: 'Informations generales',
            },
            children:[ 
                {
                    labelName: 'Média',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type:'oneImage',
                            label: 'Média (30 Mo max)',
                            translated: false,
                            helper: {
                                text: 'Média',
                                link: false,
                            },
                            fileType:"image",
                            required: true,
                            stateName: 'formImage',
                            fileType: MEDIAPICKER_TYPES.IMAGE,
                        },                            
                        {
                            type:'text',
                            label: 'Nom du média',
                            translated: false,
                            helper: {
                                text: 'Nom du média',
                                link: false,
                            },
                            required: true,
                            stateName: 'fileName',
                        },
                        {
                            type:'text',
                            label: 'Texte alternatif',
                            translated: false,
                            helper: {
                                text: 'Aide à la SEO et à l\'accessibilité',
                                link: false,
                            },
                            required: false,
                            stateName: 'fileAlt',
                        },
                        // {
                        //     type:'textarea',
                        //     label: 'Description',
                        //     translated: false,
                        //     helper: {
                        //         text: 'Description du média',
                        //         link: false,
                        //     },
                        //     required: false,
                        //     stateName: 'fileDescription',
                        // },
                        {
                            type: 'selectTree',
                            multiselect : true,
                            label: 'Dossier',
                            translated: false,
                            required:true,
                            bottom:true,
                            helper: {
                                text: 'Indiquez le dossier de destination du média',
                                link: false,
                            },
                            stateName: 'parent',
                            data: (() => {
                                let data = categories.filter(e => e.node.parent === null);
                                let populateChildren = (cats, parent) => {
                                    let catSelected         = categorieSelected.find(e => e.node?.id === parent.node.id) || '/api/media-object-categories/'+categorieSelected[0] === parent.node.id ? parent.node.id : null;
                                    parent.value            = parent.node.id;
                                    parent.label            = parent.node.libelle;
                                    parent.children         = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);
                                    parent.checked          = catSelected ? true : false;
                                    parent.expanded         = parent.value === "/api/media-object-categories/1"?true:false
                                    
                                    for (let child of parent.children){
                                        populateChildren(cats, child);                                            
                                    }
                                };

                                for (let parent of data){
                                    populateChildren(categories, parent); 
                                }
                                return data;
                            })()
                        },
                        {
                            type: 'selectTree',
                            multiselect : true,
                            label: 'Tags',
                            translated: false,
                            required:false,
                            bottom:true,
                            helper: {
                                text: 'Indiquez les tags à associer à votre média',
                                link: false,
                            },
                            stateName: 'newTags',
                            data: (() => {
                                let data = allTags
                                let populateChildren = (cats, parent) => {
                                    let tagSelected         = newTags.find(e => e.node?.id === parent.node.id) || '/api/media-object-tags/'+newTags[0] === parent.node.id ? parent.node.id : null;
                                    parent.value            = parent.node.id;
                                    parent.label            = parent.node.tag;
                                    parent.id               = parent.node.id;
                                    parent.checked          = tagSelected ? true : false;
                                };

                                for (let tag of data){
                                    populateChildren(allTags, tag); 
                                }
                                return data;
                            })()
                        }
                    ]   
                },
                // {
                //     labelName : 'Tags',
                //     isOptionnal : false,
                //     component : SearchTags,
                // }
            ]
        }
    }
    return obj;
}