import {STEPPER} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function headerEdit(action='add') {
    let generalInputs   = [
        // {
        //     required : true,
        //     type : 'text',
        //     label : 'Identifier',
        //     stateName : 'headerIdentifier',
        //     helper: {
        //         text: '',
        //         link: false,
        //     },
        // },
        {
            required : true,
            type : 'text',
            label : 'Message',
            stateName : 'headerMessage',
            helper: {
                text: 'Message du header',
                link: false,
            },
        },
        {
            required : true,
            type : 'text',
            label : 'Lien',
            stateName : 'headerLink',
            helper: {
                text: 'Lien de redirection du header',
                link: false,
            },
        },
        {
            type: 'date',
            label: 'Date de début',
            helper: {
                text: 'Date de début du header',
                link: false,
            },
            required: true,
            stateName: 'headerStartAt',
        },
        {
            type: 'date',
            label: 'Date de fin',
            helper: {
                text: 'Date de fin du header',
                link: false,
            },
            required: true,
            stateName: 'headerEndAt',
        },
        {
            type: 'radio',
            direction: 'row',
            label: 'Actif',
            helper: {
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'headerStatus',
            value: [
                {
                    value: true,
                    label: 'Oui'
                },
                {
                    value: false,
                    label: 'Non'
                },
            ]
        }
    ]
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? 'Créer un header':'Modifier un header',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre header`,
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de cet header' : 'Valider la modification de cet header',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}