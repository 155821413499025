import { withApollo } from '@apollo/client/react/hoc';
import colors from "@config/theme/colors";
import { SNACK, START_LOADING, STOP_LOADING } from "@constants/action-types";
import { ALERT_ERROR, ALERT_SUCCESS } from "@constants/alert-types";
import { ROUTE_PRODUCTS_BRANDS_DETAIL } from "@constants/route-names";
import OurDrawer from "@layouts/Drawer/OurDrawer";
import Listing from "@layouts/Listing/Listing";
import TopPanel from "@layouts/TopPanel/TopPanel";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import { DELETE_BRAND, DELETE_BRAND_SOURCE_ALERT, DELETE_BRAND_SOURCE_MAPPING, DELETE_BRAND_SOURCE_SCRAP } from "@queries/brands";
import { eventService } from '@services/event.service';
import BrandForm from "@ui/form/BrandForm/BrandForm.jsx";
import request from '@utils/fetch';
import { saveElement } from "@utils/functions";
import axios from "axios";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import slugify from 'slugify';
import { filtersOptions, listMappers, listSettings, perPageOptions, viewOptions } from "../config/listBrands.config";

const Brands = props => {
    const [dataCount,setDataCount] = useState(0);
    const [reloadListing,setReloadListing] = useState(false);
    const [openForm,setOpenForm] = useState(false);
    const [formType,setFormType] = useState("add");
    const [typingTimer,setTypingTimer] = useState(null);
    const [typingSearchTimer,setTypingSearchTimer] = useState(null);
    const [selectedItems,setSelectedItems] = useState([]);
    const [offsetListing,setOffsetListing] = useState(0);
    const [scrollListing,setScrollListing] = useState(false);
    const [items,setItems] = useState([]);
    const [state,setState] = useState({
        isEmpty: false,
        openForm: false,
        openFormAdd: false,
        openFormDuplicate: false,
        isActive:false,
        isActiveAlert:false,
        drawerWidthModified: props.drawerWidth,
        currentLang: props.locales[0].node.code,
        errors: {},
        seeErrors: false,
        ready: false,
        noResult: false,
        pagination: {
            page: 0,
            perPage: 6,
            count: 0,
        },
        skulist: [],
        searchValue: "",
        arrayCatFilter: [],
        typeTesting: {
            type: "catalog",
            testingState: ["catalogName", "catalogIdentifier"],
            testingTypingState: "catalogName",
            identifierState: "catalogIdentifier",
        },
        brandRows:[],
        mapOn:[],
    })
    const [brandEdit, setBrandEdit] = useState(null);

    useEffect(() => {
      if (brandEdit) {
        setOpenForm(true);
      } else {
        setOpenForm(false);
      }
    }, [brandEdit]);

    useEffect(() => {
      if (!openForm) {
        setBrandEdit(null);
      }
    }, [openForm]);

    const resetStateBrand = () => {
        setState({
            ...state,
            isActiveAlert:false,
            brandRows:[],
            mapOn:[],
            isActive:false,
            errors: {},
            seeErrors: false,
        });
    };

    const handleForm = (type) => {
        resetStateBrand();
        setFormType(type)
        setOpenForm(!openForm);
    };

    const goToBrand = (id) => {
        props.history.push({
            pathname: ROUTE_PRODUCTS_BRANDS_DETAIL.replace(':id', id.replace('/api/product-sources/', '')),
            state: { catalogId: id }
        });
    };

    const deleteSourceMap = async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          await props.client.mutate({
            mutation: DELETE_BRAND_SOURCE_MAPPING,
            variables: { id },
            fetchPolicy: "no-cache",
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };

    const deleteSourceScrap = async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          await props.client.mutate({
            mutation: DELETE_BRAND_SOURCE_SCRAP,
            variables: { id },
            fetchPolicy: "no-cache",
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };

    const deleteSourceAlert = async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          await props.client.mutate({
            mutation: DELETE_BRAND_SOURCE_ALERT,
            variables: { id },
            fetchPolicy: "no-cache",
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };
    

    const handleDelete = async (id) => {
      props.startLoading();
      const brand = items.find((e) => e.node.id === id).node;

      try {
        if (brand.productSourceMappings.totalCount > 0) {
            await Promise.all(
              brand.productSourceMappings.edges.map(async (item) => {
                await deleteSourceMap(item.node.id)
              })
            );
        }

        if (brand.productSourceScraps.totalCount > 0) {
          await Promise.all(
            brand.productSourceScraps.edges.map(async (item) => {
              await deleteSourceScrap(item.node.id)
            })
          )
        }

        if (brand.productSourceAlerts.totalCount > 0) {
          await Promise.all(
            brand.productSourceAlerts.edges.map(async (item) => {
              await deleteSourceAlert(item.node.id)
            })
          )
        }

        await props.client.mutate({
          mutation: DELETE_BRAND,
          variables: { id: brand.id },
          fetchPolicy: "no-cache",
        });
        props.stopLoading();
        props.snack(ALERT_SUCCESS, "Marque supprimée avec succès.");
        setReloadListing(true);
      } catch (error) {
        props.stopLoading();
        props.snack(ALERT_ERROR, "Une erreur est survenue lors de la suppression.");
        console.log(error);
      }
    };

    const doneTyping = (stateName) => {
        let typeTesting = state.typeTesting;
        if (stateName === typeTesting.testingTypingState) {
            setState({
                ...state,
                [typeTesting.identifierState]: slugify(state[typeTesting.testingTypingState], { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            });
        }

        if (state[typeTesting.identifierState]) {
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${state[typeTesting.identifierState]}`, 'get')
            .then((data) => {
                if (data.success) {
                    eventService.fire({ stateName: typeTesting.identifierState, errorMessage: props.t("spread.active_assets.userAlreadyUsed") });
                }
            }).catch((err)=>{
                console.log(err)
            });
        }
    };

    const handleFormError = (stateName, error) => {
        let errors = state.errors;
        errors[stateName] = error;
        setState({ ...state, errors:errors??{} });
    };

    const handleButtonGroupChange = (stateName, value) => {
        setState({ ...state,[stateName]: value });
    };

    const  handleDrawerWidthChange = (width) => {
        setState({ ...state, drawerWidthModified: width });
    };

    const hasErrors = () => {
        if (state.errors) {
            for (let error in state.errors) {
                if (state.errors[error]) return true;
            }
        }
        return false;
    };

    const checkIdentifier = (stateName) => {
        clearTimeout(typingTimer);
        setTypingTimer(setTimeout(() => { doneTyping(stateName) }, 500))
    };

    const setValue = (stateName, value, translated) => {
        if (translated) {
            let values = state[state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            setState({
                ...state,
                [state.currentLang]: {
                    ...state[state.currentLang],
                    [stateName]: value,
                },
            });
        } else {
            setState({
                ...state,
                [stateName]: value,
            });
        }
        if (state.typeTesting.testingState.includes(stateName))
            checkIdentifier(stateName);
    };

    const handleInputChange = (stateName, evt, custom, translated) => {
        if (custom && stateName !== 'catalogSelected') {
            setState({ ...state, ...custom });
        } else {
            const value = evt?.target?.value ?? evt;
            setValue(stateName, value, translated);
        }
    };

    const handleMediaPicker = (selected, stateName) => {
        if (stateName === 'imageCatalog') {
            handleInputChange(stateName, selected, null, false);
        }
        else {
            handleInputChange(stateName, selected, null, true);
        }
    }

    const handlerMutationDuplicate = async (id)=>{
        props.startLoading()
        // for (let catalog of this.state.catalogSelected) {
        // }
        try {
            await axios(`${process.env.REACT_APP_API}/catalog/duplicate/${id.replace('/api/catalogs/', '')}/`, 'GET');
            props.snack(ALERT_SUCCESS, props.t("catalogs.catalogs.catalogDuplicated"));
            setReloadListing(true)
        } catch (error) {
            props.snack(ALERT_ERROR, props.t("catalogs.catalogs.catalogDuplicatedError"));
            console.log(error)
        }
        // this.handleToggleDrawer('openFormDuplicate', true);
        // this.resetStateBrand();
        props.stopLoading();
    }

    const handlerMutation = async () => {
        props.startLoading();

        let variables={                
            libelle: state.brandName, 
            description: state.brandDesc,                
            media:state.brandImage?.id,
            status:state.isActive,
            isDisplay:true,
            isBrand:true,                
            activeAlert: state.isActiveAlert,
            link: state.brandLink,
            brandEcartPositive:state.brandEcartPositive,
            brandEcartNegative:state.brandEcartNegative
        }
        try {
            let createBrand = await saveElement('productSource', variables, {enableLoad:false, setDates:false})
            let createSource
                if (createBrand && state.brandRows.length > 0){                    
                    Promise.all(state.brandRows.map(async(row)=>{
                        let scrapVariables = {
                            name:row.code,
                            link:row.code,
                            codeScrap:row.code,
                            productSource:createBrand.id,
                        }
                        try {
                            createSource = await saveElement('productSourceScrap', scrapVariables, {enableLoad:false, setDates:false})                                                                                                                
                            Object.keys(state.mapOn).map(async(m)=>{
                                if (row.code === state.mapOn[m]) {
                                    let mapVariables = {
                                        productSource:createBrand.id,
                                        attribute:m,
                                        productSourceScrap:createSource.id,
                                    }
                                    try {
                                        await saveElement('productSourceMapping', mapVariables, {enableLoad:false, setDates:false})                                                                                    
                                    } catch (error) {
                                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création de la marque');
                                        console.log(error)
                                    }
                                }                                
                            })                        
                        } catch (error) {
                            props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création de la marque');
                            console.log(error)
                        }
                    }))
                }
                setReloadListing(true)
                props.snack(ALERT_SUCCESS, props.t("products.brands.brandAddedSuccess"));
                props.stopLoading();
                handleForm("add")
        } catch (error) {
            console.log(error)
        }
    };

    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop + 0.5 >= offsetListing) {
            setScrollListing(true)
        }else{
            setScrollListing(false)
        }
      };
    
    return (
      <div style={{ overflow: "scroll" }} onScroll={handleScroll}>
        <TopPanel
          icomoon="icon-cataogue"
          getRef
          setRef={setOffsetListing}
          colorIcomoon={colors.blue.lighter.hue300}
          title={props.t("drawer.brands")}
          subtitle={props.t("drawer.manageBrands")}
          dataCount={dataCount}
          textAdd={props.t("products.brands.addBrand")}
          handlerAdd={() => handleForm("add")}
          gradientColor1={colors.menu.regular}
          gradientColor2={colors.menu.darker}
          buttonAvailable={true}
          windowWidth={props.windowWidth}
        />
        <Listing
          padding={16}
          scroll={scrollListing}
          label="marques"
          settings={listSettings}
          perPageOptions={perPageOptions}
          mappers={listMappers}
          pagination={true}
          enablePagination={true}
          // enableChangeView={true}
          enableChangeSort={true}
          enableSelectionItems={false}
          enableFilters={true}
          identifier="productSources"
          viewsOptions={viewOptions}
          tableProps={{
            textButtonSecondary: "red",
            redirectDetail: goToBrand,
          }}
          locales={props.locales}
          setDataCount={setDataCount}
          reload={reloadListing}
          setReload={setReloadListing}
          filtersOptions={filtersOptions}
          listingCallback={(props) => setItems(props)}
          cardProps={{
            actionButtonDelete: handleDelete,
            redirectDetail: goToBrand,
            showPercent: true,
            percent: "percentCompletude",
            image: "media",
            filePath: "filePath",
            title: "libelle",
            description: "description",
            attributes: [
              {
                label: "Statut",
                value: "status",
                type: "status",
              },
              {
                label: "Produits total",
                value: "totalProductCount",
              },
            ],
            secondAttributes: [
              {
                label: "Sources",
                value: "scrapSources",
                type: "array",
              },
            ],
            textButton: "Voir la marque",
            dottedMenuOptions: [
              {
                label: "Modifier",
                color: colors.blue.darker.hue300,
                isDisabled: false,
                icon: (
                  <FileCopySharpIcon
                    style={{ fill: colors.blue.darker.hue300 }}
                  />
                ),
                onClick: (id) =>
                  setBrandEdit(items.find((e) => e.node.id === id)?.node),
              },
              {
                label: "Supprimer",
                color: colors.red.regular,
                icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                modal: true,
                modalProps: {
                  identifier: "deleteCatalog",
                  type: "delete",
                  title: "Êtes-vous sûr de vouloir supprimer ce catalogue ?",
                  subtiltle: "Cette action est irréversible",
                  actions: {
                    primaryAction: (id) => handleDelete(id),
                  },
                },
              },
            ],
            // windowWidth: this.props.windowWidth,
            // reload:()=>this.reload(),
            // openEditForm :(media) => this.editMedia(media)
          }}
        />

        <OurDrawer
          isOpen={openForm}
          drawerWidth={state.drawerWidthModified}
          setIsOpen={() => setOpenForm(!openForm)}
        >
          <BrandForm
            brand={brandEdit}
            onSuccess={() => {
              setReloadListing(true);
              setOpenForm(false);
            }}
          />
        </OurDrawer>
      </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) =>
        dispatch({ type: SNACK, payload: { type, message } }),
    };
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Brands))));