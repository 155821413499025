import { Grid, Box, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import LineCharts from "../../../../layouts/Stats/LineCharts";
import PageLoader from "../../../../ui/loadings/page-loader/PageLoader";
import { ArrowDropDown } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { connect } from "react-redux";
import { SNACK } from "../../../../../js/constants/action-types";
import { withTranslation } from "react-i18next";
import TopPanel from "../../../../layouts/TopPanel/TopPanel";
import TopItems from "./components/TopItems";
import {
  comparisonStatsBoxStyles,
  DatePickerCustom,
  inputStyles,
  SubTitleLeftGlobals,
  TitleGlobals,
  typographyStyles,
  WrapperDoubleStats,
  WrapperGlobalsLeft,
  WrapperGlobalsRight,
  FiltersContainer,
} from "./style/DashboardStats.styled";
import Typography from "../../../../ui/typography/Typography";
import StatsRealTime from "./components/StatsRealTime";
import request from "../../../../../js/utils/fetch";
import colors from "../../../../../config/theme/colors";
import { spaceOnNumbers } from "../../../../../js/utils/spaceOnNumbers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AccordionCustom from "../../../../layouts/Accordion/AccordionCustom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/fr";
import styled from "styled-components";
import InputBuilder from "@ui/form/InputBuilder";
import Button from "@/components/ui/button/OurButton";

const SelectCustom = styled(Select)`
  border: none;
  width: 100%;
  padding: 10px 15px;
  font-weight: bold;
  .MuiSelect-select {
    padding: 0;
  }
`;

const SelectWrapper = styled.div`
  & div,
  & input {
    /* height: 40px; */
    min-width: 200px;
  }
`;

const DashboardStats = (props) => {
  const [periode, setPeriode] = useState("week");
  const [isFirst, setIsFirst] = useState(true);
  const [isInputValues, setIsInputValues] = useState(false);
  const [stats, setStats] = useState({
    list: {
      date: [],
      totalAmount: {
        data: [],
        color: "#147FAB",
        title: "Chiffre d'affaires",
      },
      totalSubAmount: {
        data: [],
        color: "#147FAB",
        title: "Chiffre d'affaires HT",
      },
      orderNumber: {
        data: [],
        color: "#147FAB",
        title: "Nombre de commandes",
      },
      cartAverage: {
        data: [],
        color: "#147FAB",
        title: "Panier moyen",
      },
      allDatas: {
        totalAmount: {
          data: [],
          color: "#147FAB",
          title: "Chiffre d'affaires",
        },
        totalSubAmount: {
          data: [],
          color: "#147FAB",
          title: "Chiffre d'affaires HT",
        },
        orderNumber: {
          data: [],
          color: "#147FAB",
          title: "Nombre de commandes",
        },
        cartAverage: {
          data: [],
          color: "#147FAB",
          title: "Panier moyen",
        },
      },
      total: {
        totalAmount: 0,
        totalSubAmount: 0,
        orderNumber: 0,
        cartAverage: 0,
        totalAmountLastWeek: 0,
        orderNumberLastWeek: 0,
        cartAverageLastWeek: 0,
      },
      division: [],
    },
    areStatsLoaded: false,
    areStatsError: false,
  });
  const [statsDate, setStatsDate] = useState({
    startDate: moment().startOf("week").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [inputDate, setInputDate] = useState({
    startDate: moment().startOf("week"),
    endDate: moment().endOf("week"),
  });
  const [source, setSource] = useState("all");
  const [formattedDates, setFormattedDates] = useState([]);
  const [divisions, setDivisions] = useState({ list: [], isLoaded: false });
  const [sourceInput, setSourceInput] = useState(null);

  // useEffect(() => {
  //     checkRouting(props)
  // }, [])

  useEffect(() => {
    setPeriode("week");
    setIsInputValues(false);
    if (props.type !== undefined) {
      getDivisions();
    }
    setStatsDate({
      startDate: moment().startOf("week").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
    setInputDate({
      startDate: moment().startOf("week"),
      endDate: moment().endOf("week"),
    });
  }, [props.type]);

  useEffect(() => {
    if (isFirst) {
      getStats();
    } else {
      getStats({ startDate: statsDate.startDate, endDate: statsDate.endDate });
    }
    setIsInputValues(false);
  }, [statsDate, source]);

  const getStats = (postData = false) => {
    let formatedDatesList = [];
    setStats((prev) => ({
      ...prev,
      areStatsLoaded: false,
    }));
    const origin =
      props.type === "byMarketplace"
        ? ["MP"]
        : props.type === "bySite"
        ? ["B2C"]
        : null;
    const endPeriode = moment(statsDate.endDate)
      .endOf(periode)
      .format("YYYY-MM-DD");
    if (!postData) {
      const lastWeek = moment()
        .subtract(1, "weeks")
        .startOf("week")
        .format("YYYY-MM-DD");
      postData = {
        startDate: lastWeek,
        endDate: endPeriode,
        origin: origin,
        source: source === "all" ? null : source,
      };
    } else {
      postData = {
        startDate: postData.startDate,
        endDate: postData.endDate,
        origin: origin,
        source: source === "all" ? null : source,
      };
    }
    if (postData) {
      request(
        `${process.env.REACT_APP_API}/statistics/sales-order`,
        "POST",
        postData
      )
        .then((data) => {
          if (data.success) {
            const statsConfig = {
              date: [],
              totalAmount: {
                data: [],
                color: "#147FAB",
                title: "Chiffre d'affaires (TTC + FP)",
              },
              totalSubAmount: {
                data: [],
                color: "#147FAB",
                title: "Chiffre d'affaires (HT)",
              },
              orderNumber: {
                data: [],
                color: "#147FAB",
                title: "Nombre de commandes",
              },
              cartAverage: {
                data: [],
                color: "#147FAB",
                title: "Panier moyen (TTC)",
              },
              allDatas: {
                totalAmount: {
                  data: [],
                  color: "#147FAB",
                  title: "Chiffre d'affaires (TTC + FP)",
                },
                totalSubAmount: {
                  data: [],
                  color: "#147FAB",
                  title: "Chiffre d'affaires (HT)",
                },
                orderNumber: {
                  data: [],
                  color: "#147FAB",
                  title: "Nombre de commandes",
                },
                cartAverage: {
                  data: [],
                  color: "#147FAB",
                  title: "Panier moyen (TTC)",
                },
              },
              total: {
                totalAmount: 0,
                totalSubAmount: 0,
                orderNumber: 0,
                cartAverage: 0,
                totalAmountLastWeek: 0,
                orderNumberLastWeek: 0,
                cartAverageLastWeek: 0,
              },
              division: [],
            };

            let division = {};
            const sortedDataStats = Object.keys(data.stats).sort();
            sortedDataStats.forEach((k, i) => {
              if (k >= statsDate.startDate) {
                const formattedDate = moment(k).format("ddd DD.MM");
                formatedDatesList.push(formattedDate);
                statsConfig.date.push(k);
                statsConfig.total.totalAmount +=
                  data.stats[k]?.global.totalAmount;
                // statsConfig.total.totalSubAmount += data.stats[k]?.global.totalSubAmount
                statsConfig.total.cartAverage +=
                  data.stats[k]?.global.cartAverage;
                statsConfig.total.orderNumber +=
                  data.stats[k]?.global.orderNumber;

                statsConfig.totalAmount.data.push(
                  data.stats[k]?.global.totalAmount
                );
                statsConfig.totalSubAmount.data.push(
                  data.stats[k]?.global.totalSubAmount
                );
                statsConfig.cartAverage.data.push(
                  data.stats[k]?.global.cartAverage
                );
                statsConfig.orderNumber?.data.push(
                  data.stats[k]?.global.orderNumber
                );
              } else {
                statsConfig.total.totalAmountLastWeek +=
                  data.stats[k]?.global.totalAmount;
                statsConfig.total.cartAverageLastWeek +=
                  data.stats[k]?.global.cartAverage;
                statsConfig.total.orderNumberLastWeek +=
                  data.stats[k]?.global.orderNumber;
              }
            });

            statsConfig.total.totalSubAmount = Number(
              data.period?.totalSubAmount
            );

            for (const key in division) {
              statsConfig.division.push(division[key]);
            }

            statsConfig.division.sort((a, b) => {
              return b.orderNumber - a.orderNumber;
            });

            statsConfig.total.cartAverage =
              statsConfig.total.cartAverage / statsConfig.date.length;
            statsConfig.total.cartAverageLastWeek =
              statsConfig.total.cartAverageLastWeek / statsConfig.date.length;

            const totalAmountGloabalItem = {
              title: `Chiffre d'affaires global`,
              data: statsConfig.totalAmount.data,
              color: colors.blue.darker.hue300,
            };

            const totalSubAmountGlobalItem = {
              title: `Chiffre d'affaires global HT`,
              data: statsConfig.totalSubAmount.data,
              color: colors.blue.darker.hue300,
            };

            const orderNumberGloabalItem = {
              title: `Nombre de commandes global`,
              data: statsConfig.orderNumber?.data,
              color: colors.blue.regularv2,
            };

            const cartAverageGloabalItem = {
              title: `Panier moyen global`,
              data: statsConfig.cartAverage.data,
              color: colors.blue.regularv2,
            };

            statsConfig.allDatas.totalAmount.data.push(totalAmountGloabalItem);
            statsConfig.allDatas.totalSubAmount.data.push(
              totalSubAmountGlobalItem
            );
            statsConfig.allDatas.orderNumber?.data.push(orderNumberGloabalItem);
            statsConfig.allDatas.cartAverage.data.push(cartAverageGloabalItem);

            if (!isInputValues) {
              const diff = moment(endPeriode).diff(
                sortedDataStats[sortedDataStats.length - 1],
                "days"
              );
              for (let i = 0; i < diff; i++) {
                const date = moment(sortedDataStats[sortedDataStats.length - 1])
                  .add(i + 1, "days")
                  .format("ddd DD.MM");
                formatedDatesList.push(date);
              }
            }

            setFormattedDates(formatedDatesList);
            setIsFirst(false);
            setStats((prev) => ({
              ...prev,
              list: statsConfig,
              areStatsLoaded: true,
            }));
          } else {
            throw new Error("error");
          }
        })
        .catch((err) => {
          setStats((prev) => ({
            ...prev,
            areStatsLoaded: true,
            areStatsError: true,
          }));
        });
    }
  };

  const getDivisions = () => {
    setDivisions({ list: [], isLoaded: false });
    request(
      `${process.env.REACT_APP_API}/statistics/sales-order/source`,
      "POST",
      false
    )
      .then((data) => {
        if (data.success) {
          setDivisions({
            list: props.type === "bySite" ? data.infos.B2C : data.infos.MP,
            isLoaded: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeStatsPeriode = (e) => {
    let startDate, endDate;

    switch (e.target.value) {
      case "week":
      default:
        startDate = moment().startOf("week");
        endDate = moment();
        break;
      case "month":
        startDate = moment().startOf("months");
        endDate = moment();
        break;
      case "year":
        startDate = moment().startOf("years");
        endDate = moment();
        break;
    }

    setStatsDate({
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });
    setInputDate({
      startDate,
      endDate: moment(endDate).endOf(e.target.value),
    });
    setPeriode(e.target.value);
  };

  const inputClass = inputStyles();
  const typographyClass = typographyStyles();
  const comparisonStatsBoxClass = comparisonStatsBoxStyles();

  const checkDateOnInput = (date, value, ref) => {
    if (ref === "start") {
      setInputDate({
        startDate: date,
        endDate: inputDate.endDate,
      });
    } else if (ref === "end") {
      setInputDate({
        startDate: inputDate.startDate,
        endDate: date,
      });
    }
  };

  useEffect(() => {
    getStats();
  }, [props.type, source]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSource("all");
    if (props.type !== undefined) {
      getDivisions();
    }
  }, [props.type]);

  const getPeriodeName = (periode) => {
    switch (periode) {
      case "month":
        return "mois";
      case "year":
        return "année";
      case "week":
      default:
        return "semaine";
    }
  };

  const handleInputDate = () => {
    // setIsInputValues(true)
    setStatsDate({
      startDate: moment(inputDate.startDate).format("YYYY-MM-DD"),
      endDate: moment(inputDate.endDate).format("YYYY-MM-DD"),
    });
  };

  const getSalesRevenue = () => {
    return stats?.list?.total?.totalAmount <
      stats?.list?.total?.totalAmountLastWeek ? (
      <ArrowDropDown style={{ fill: colors.red.regular }} />
    ) : (
      <ArrowDropUpIcon style={{ fill: colors.green.regular }} />
    );

    // <Typography variant="body1" style={{ color: stats?.list?.total?.totalAmount < stats?.list?.total?.totalAmountLastWeek ? colors.red.regular : colors.green.regular }}>{spaceOnNumbers((stats?.list?.total?.totalAmount - stats?.list?.total?.totalAmountLastWeek).toFixed(2))}€ / {getPeriodeName(periode)}</Typography>
  };

  const periodeInputConfig = {
    isContainerized: true,
    type: "select",
    stateName: "periode",
    multiselect: false,
    disabled: false,
    value: [
      { value: "week", label: "Semaine" },
      { value: "month", label: "Mois" },
      { value: "year", label: "Année" },
    ],
    helper: {
      link: false,
      text: "",
    },
  };

  useEffect(() => {
    if (divisions.isLoaded) {
      const sourceInputConfig = {
        isContainerized: true,
        type: "select",
        stateName: "source",
        multiselect: false,
        disabled: false,
        value: divisions?.list?.map((e) => ({ value: e, label: e })),
        helper: {
          link: false,
          text: "",
        },
        custom: {
          padding: "14px 10px",
          // height: "40px",
        },
      };
      sourceInputConfig.value.unshift({
        value: "all",
        label:
          props.type === "bySite"
            ? "Tous les sites"
            : "Toutes les marketplaces",
      });
      setSourceInput(sourceInputConfig);
    }
  }, [divisions]);

  return (
    <div style={{ overflow: "auto" }}>
      <TopPanel
        title={props.t("drawer.dashboard_statisticsOrders")}
        subtitle={props.t("drawer.dashboard_statisticsOrdersText")}
        windowWidth={props.windowWidth}
        locales={props.locales}
        hasBorder={true}
      />
      <div>
        <StatsRealTime type={props.type} areStatsError={stats.areStatsError} />

        <AccordionCustom
          defaultExpanded={true}
          title={props.t("dashboard.globalstatistics")}
          style={{
            marginBottom: "16px",
          }}
        >
          {!stats?.areStatsLoaded && !divisions.isLoaded && !sourceInput ? (
            <Box
              style={{
                position: "relative",
                height: "300px",
                width: "100%",
                color: colors.grey.regular,
              }}
            >
              <PageLoader />
            </Box>
          ) : !stats?.areStatsError ? (
            <Grid
              container
              direction="column"
              justifyContent="center"
              spacing={0}
            >
              <Grid container direction="row">
                {/* Top */}
                <Grid
                  container
                  direction={{
                    xs: "column",
                    md: "row",
                  }}
                  alignItems="center"
                  gap={2}
                >
                  <WrapperGlobalsLeft
                    item
                    xs={12}
                    md={3}
                  >
                    <Box style={{ width: "100%" }}>
                      <TitleGlobals variant={"h2"}>
                        {props.t("dashboard.overview")}
                      </TitleGlobals>
                    </Box>
                  </WrapperGlobalsLeft>

                  <FiltersContainer item md>
                    {props.type !== undefined &&
                      divisions.isLoaded &&
                      sourceInput && (
                        <Grid item xs={3}>
                          <InputBuilder
                            margin={0}
                            value={source}
                            input={sourceInput}
                            stateCallback={(e) => {
                              setSource(e.target.value);
                            }}
                          />
                        </Grid>
                      )}
                    <Grid item xs={3}>
                      <InputBuilder
                        margin={0}
                        value={periode}
                        input={periodeInputConfig}
                        stateCallback={handleChangeStatsPeriode}
                      />
                    </Grid>
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <DatePickerCustom
                        view="day"
                        viewRenderers={{
                          hours: null,
                          minutes: null,
                          seconds: null,
                        }}
                        views={["year", "day"]}
                        value={inputDate.startDate}
                        maxDate={inputDate.endDate}
                        onChange={(date, value) =>
                          checkDateOnInput(date, value, "start")
                        }
                      />
                      <DatePickerCustom
                        view="day"
                        views={["year", "day"]}
                        value={inputDate.endDate}
                        minDate={inputDate.startDate}
                        viewRenderers={{
                          hours: null,
                          minutes: null,
                          seconds: null,
                        }}
                        sx={{
                          width: "100%",
                        }}
                        onChange={(date, value) =>
                          checkDateOnInput(date, value, "end")
                        }
                      />
                      <Button
                        handleClick={handleInputDate}
                        label={props.t("actions.validate")}
                        large
                      />
                    </LocalizationProvider>
                  </FiltersContainer>
                </Grid>
                {/* Bottom */}
                <WrapperGlobalsLeft item>
                  <Box mt={{ md: 10 }}>
                    <Grid
                      container
                      alignItems={"center"}
                      className={comparisonStatsBoxClass.root}
                    >
                      {/* One */}
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={4}
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h1"
                          component="div"
                          className={typographyClass.typography}
                        >
                          {spaceOnNumbers(stats?.list?.total.orderNumber)}
                        </Typography>
                        <SubTitleLeftGlobals variant="body1">
                          Commandes
                        </SubTitleLeftGlobals>
                        <Box
                          display={"flex"}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {stats?.list?.total?.orderNumber <
                          stats?.list?.total?.orderNumberLastWeek ? (
                            <ArrowDropDown
                              style={{ fill: colors.red.regular }}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              style={{ fill: colors.green.regular }}
                            />
                          )}
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                stats?.list?.total?.orderNumber <
                                stats?.list?.total?.orderNumberLastWeek
                                  ? colors.red.regular
                                  : colors.green.regular,
                            }}
                          >
                            {spaceOnNumbers(
                              stats?.list?.total?.orderNumber -
                                stats?.list?.total?.orderNumberLastWeek
                            )}{" "}
                            / {getPeriodeName(periode)}
                          </Typography>
                        </Box>
                      </Grid>
                      {/* Two */}
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={4}
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h1"
                          component="div"
                          className={typographyClass.typography}
                        >
                          {spaceOnNumbers(
                            stats?.list?.total?.cartAverage.toFixed(2)
                          )}
                          €
                        </Typography>
                        <SubTitleLeftGlobals variant="body1">
                          Panier moyen (TTC)
                        </SubTitleLeftGlobals>
                        <Box
                          display={"flex"}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {stats?.list?.total?.cartAverage <
                          stats?.list?.total?.cartAverageLastWeek ? (
                            <ArrowDropDown
                              style={{ fill: colors.red.regular }}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              style={{ fill: colors.green.regular }}
                            />
                          )}
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                stats?.list?.total?.cartAverage <
                                stats?.list?.total?.cartAverageLastWeek
                                  ? colors.red.regular
                                  : colors.green.regular,
                            }}
                          >
                            {spaceOnNumbers(
                              (
                                stats?.list?.total?.cartAverage -
                                stats?.list?.total?.cartAverageLastWeek
                              ).toFixed(2)
                            )}
                            € / {getPeriodeName(periode)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </WrapperGlobalsLeft>
                <WrapperGlobalsRight item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item lg={12} md={12} xs={12}>
                      <Box pt={1}>
                        <Box
                          display={"flex"}
                          style={{ alignItems: "flex-end" }}
                        >
                          <Typography variant={"h3"} style={{ fontSize: 20 }}>
                            {" "}
                            {props.t("dashboard.turnover")}{" "}
                            {spaceOnNumbers(
                              stats?.list?.total?.totalAmount.toFixed(2)
                            )}
                            €
                          </Typography>
                          {stats?.list?.total?.totalAmount <
                          stats?.list?.total?.totalAmountLastWeek ? (
                            <ArrowDropDown
                              style={{ fill: colors.red.regular, fontSize: 20 }}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              style={{
                                fill: colors.green.regular,
                                fontSize: 20,
                              }}
                            />
                          )}
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                stats?.list?.total?.totalAmount <
                                stats?.list?.total?.totalAmountLastWeek
                                  ? colors.red.regular
                                  : colors.green.regular,
                              fontSize: 10,
                            }}
                          >
                            {spaceOnNumbers(
                              (
                                stats?.list?.total?.totalAmount -
                                stats?.list?.total?.totalAmountLastWeek
                              ).toFixed(2)
                            )}
                            € / {getPeriodeName(periode)}
                          </Typography>
                        </Box>
                        <LineCharts
                          xAxisData={formattedDates}
                          yAxisData={stats?.list?.allDatas?.totalAmount?.data}
                          noLegend={true}
                          showArea={false}
                          fullHeight={true}
                          titleExport={"Chiffres d'affaires"}
                        />
                      </Box>
                    </Grid>
                    <WrapperDoubleStats item>
                      <Box pt={3}>
                        <LineCharts
                          xAxisData={formattedDates}
                          yAxisData={stats?.list?.allDatas?.orderNumber?.data}
                          noLegend={true}
                          showArea={false}
                          title={"Nombre de commandes"}
                          titleExport={"Nombre de commandes"}
                        />
                      </Box>
                    </WrapperDoubleStats>
                    <WrapperDoubleStats item>
                      <Box pt={3}>
                        <LineCharts
                          xAxisData={formattedDates}
                          yAxisData={stats?.list?.allDatas?.cartAverage?.data}
                          noLegend={true}
                          showArea={false}
                          title={"Panier moyen"}
                          titleExport={"Panier moyen"}
                        />
                      </Box>
                    </WrapperDoubleStats>
                  </Grid>
                </WrapperGlobalsRight>
              </Grid>
            </Grid>
          ) : (
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{ padding: "24px 0px", color: colors.grey.regular }}
              >
                Aucune donnée disponible
              </Typography>
            </Box>
          )}
        </AccordionCustom>
        {/* Repartition commandes */}
        {/* {
                    props.type ?
                        <StatsOrder type={props.type} checkDateOnInput={checkDateOnInput} areStatsError={stats.areStatsError} />
                        : null
                } */}
        {/* Top items */}
        <TopItems />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardStats))
);
