import colors from "../../../../config/theme/colors";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";

export const buttonTypes = (icon, hasIcon) => ({
  info: {
    primary: {
      icon: icon,
      color: colors.white,
      colorhover: colors.blue.lighter.hue300,
      bgcolor: colors.blue.darker.hue300,
      disabled: {
        icon: icon,
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue800,
        bgcolorhover: colors.grey.lighter.hue800,
      },
    },
    secondary: {
      icon: icon,
      color: colors.blue.darker.hue300,
      bgcolor: colors.blue.lighter.hue900,
      bgcolorhover: colors.blue.lighter.hue600,
      border: `1px solid ${colors.blue.darker.hue300}`,
      disabled: {
        icon: icon,
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue900,
        bgcolorhover: colors.grey.lighter.hue900,
        border: `1px solid ${colors.grey.lighter.hue600}`,
      },
    },
    terciary: {
      icon: icon,
      color: colors.blue.lighter.hue200,
      colorhover: colors.blue.darker.hue300,
      bgcolor: "transparent",
      bgcolorhover: "transparent",
      disabled: {
        icon: icon,
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue980,
        bgcolorhover: colors.grey.lighter.hue980,
      },
    },
  },
  create: {
    primary: {
      icon: icon ?? {
        icon: <AddSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
        position: "start",
      },
      color: colors.white,
      colorhover: colors.green.regularv2,
      bgcolor: colors.green.regular,
      bgcolorhover: colors.green.regular,
      disabled: {
        icon: icon ?? {
          icon: <AddSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
          position: "start",
        },
        color: colors.grey.lighter.hue600,
        // colorhover: colors.green.darker,
        bgcolor: colors.grey.lighter.hue900,
        bgcolorhover: colors.grey.lighter.hue900,
      },
    },
    secondary: {
      icon: icon ?? {
        icon: <AddSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
        position: "start",
      },
      color: colors.green.regular,
      bgcolor: colors.green.lighterv2,
      bgcolorhover: colors.green.lighterv2Hover,
      border: `1px solid ${colors.green.regular}`,
      disabled: {
        icon: icon ?? {
          icon: <AddSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
          position: "start",
        },
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue900,
        bgcolorhover: colors.grey.lighter.hue900,
        border: `1px solid ${colors.grey.lighter.hue600}`,
      },
    },
    terciary: {
      icon: icon ?? {
        icon: <AddSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
        position: "start",
      },
      color: colors.green.regular,
      colorhover: colors.green.darker,
      bgcolor: "transparent",
      bgcolorhover: "transparent",
      disabled: {
        icon: icon ?? {
          icon: <AddSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
          position: "start",
        },
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue980,
        bgcolorhover: colors.grey.lighter.hue980,
      },
    },
  },
  edit: {
    primary: {
      icon:
        icon ??
        (hasIcon
          ? {
              icon: <EditSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
              position: "start",
            }
          : null),
      color: colors.white,
      colorhover: colors.blue.lighter.hue300,
      bgcolor: colors.blue.darker.hue300,
      disabled: {
        icon:
          icon ??
          (hasIcon
            ? {
                icon: (
                  <EditSharpIcon style={{ fontSize: 20, marginRight: 5 }} />
                ),
                position: "start",
              }
            : null),
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue800,
        bgcolorhover: colors.grey.lighter.hue800,
      },
    },
    secondary: {
      icon:
        icon ??
        (hasIcon
          ? {
              icon: <EditSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
              position: "start",
            }
          : null),
      color: colors.blue.darker.hue300,
      bgcolor: colors.blue.lighter.hue900,
      bgcolorhover: colors.blue.lighter.hue600,
      border: `1px solid ${colors.blue.darker.hue300}`,
      disabled: {
        icon:
          icon ??
          (hasIcon
            ? {
                icon: (
                  <EditSharpIcon style={{ fontSize: 20, marginRight: 5 }} />
                ),
                position: "start",
              }
            : null),
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue900,
        bgcolorhover: colors.grey.lighter.hue900,
        border: `1px solid ${colors.grey.lighter.hue600}`,
      },
    },
    terciary: {
      icon:
        icon ??
        (hasIcon
          ? {
              icon: <EditSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
              position: "start",
            }
          : null),
      color: colors.blue.lighter.hue200,
      colorhover: colors.blue.darker.hue300,
      bgcolor: "transparent",
      bgcolorhover: "transparent",
      disabled: {
        icon:
          icon ??
          (hasIcon
            ? {
                icon: (
                  <EditSharpIcon style={{ fontSize: 20, marginRight: 5 }} />
                ),
                position: "start",
              }
            : null),
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue980,
        bgcolorhover: colors.grey.lighter.hue980,
      },
    },
  },
  delete: {
    primary: {
      icon: icon ?? {
        icon: <DeleteSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
        position: "start",
      },
      color: colors.white,
      colorhover: colors.red.lighterv2Hover,
      bgcolor: colors.red.regular,
      bgcolorhover: colors.red.regular,
      disabled: {
        icon: icon ?? {
          icon: <DeleteSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
          position: "start",
        },
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue900,
        bgcolorhover: colors.grey.lighter.hue900,
      },
    },
    secondary: {
      icon: icon ?? {
        icon: <DeleteSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
        position: "start",
      },
      color: colors.red.regular,
      bgcolor: colors.red.lighterv2,
      bgcolorhover: colors.red.lighterv2Hover,
      border: `1px solid ${colors.red.regular}`,
      disabled: {
        icon: icon ?? {
          icon: <DeleteSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
          position: "start",
        },
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue900,
        bgcolorhover: colors.grey.lighter.hue900,
        border: `1px solid ${colors.grey.lighter.hue600}`,
      },
    },
    terciary: {
      icon: icon ?? {
        icon: <DeleteSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
        position: "start",
      },
      color: colors.red.regular,
      colorhover: colors.red.darker,
      bgcolor: "transparent",
      bgcolorhover: "transparent",
      disabled: {
        icon: icon ?? {
          icon: <DeleteSharpIcon style={{ fontSize: 20, marginRight: 5 }} />,
          position: "start",
        },
        color: colors.grey.lighter.hue600,
        bgcolor: colors.grey.lighter.hue980,
        bgcolorhover: colors.grey.lighter.hue980,
      },
    },
  },
});

export const deleteBtn = {
  color: colors.red.regular,
  colorhover: colors.white,
  bgcolor: colors.white,
  bgcolorhover: colors.red.lighter,
};
