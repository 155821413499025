import styled from "styled-components";
import { Box } from "@mui/material";
import colors from "@/config/theme/colors.js";

export const BoxCustom = styled(Box)`
  color: ${(props) =>
    props.statuscolor === "neutral"
      ? colors.grey.regular
      : props.statuscolor === "positive"
      ? colors.green.regular
      : colors.red.regular};
  svg circle {
    stroke: ${(props) =>
      props.statuscolor === "neutral"
        ? colors.grey.regular
        : props.statuscolor === "positive"
        ? colors.green.regular
        : colors.red.regular};
  }
  div:nth-child(2) svg circle {
    stroke: #edeeee;
  }
  p {
    color: ${(props) =>
      props.statuscolor === "neutral"
        ? colors.grey.regular
        : props.statuscolor === "positive"
        ? colors.green.regular
        : colors.red.regular};
  }
`;
