import { GridCustom } from "../../styles/inputStyled";
import SearchBar from '../../../search/SearchBar';
import DialogModal from '../../../dialog/DialogModal';
import TreeViewUi from '../../../tree-view/TreeView';
import { useState } from "react";

const TreeView = ({ allProps: props }) => {
    const [state, setState] = useState({})
    const [idToDelete, setIdToDelete] = useState(null)
    const [openDeleteModal, setOpenDeleteModal] = useState(null)

    return (
        <>
            {props.input.name ? <h3>{props.input.name}</h3> : null}
            {
                props.input.stateNameQuery ?
                    <SearchBar style={{ marginBottom: 8 }} value={state[props.input.stateNameQuery] || ''} placeholder={props.input.placeholderSearch} onChange={(evt) => setState({
                        ...state,
                        [props.input.stateNameQuery]: evt.target?.value
                    })} />
                    : null
            }
            <GridCustom item xs={12}>
                {props.input.treeData
                    ? <TreeViewUi
                        reducedTree={true}
                        typeOfTree={props.input.typeOfTree}
                        preventItemClick={props.input.preventItemClick}
                        canDrop={props.input.canDrop}
                        canDrag={props.input.canDrag}
                        onMoveNode={props.input.onMoveNode}
                        dataTree={props.input.treeData}
                        expand={props.expandNodes}
                        editCat={props.treeEditCat}
                        deleteCat={(id) => { setIdToDelete(id); setOpenDeleteModal(!openDeleteModal) }}
                        copyCategories={props.copyCategories}
                        onChange={treeData => props.handleTreeChange(props.input.stateName, treeData)}
                        shouldCopy={props.input.shouldCopy}
                        addSubcategory={props.treeAddCat}
                        handleChangeCategorie={props.handleChangeCategorie}
                        selectedCategorie={props.selectedCategorie}
                        stateName={props.input.stateName}
                        canModify={true}
                        canAdd={true}
                        canDelete={true}
                        canLog={props.input.canLog}
                        searchQuery={state[props.input.stateNameQuery]}
                    />
                    : <p>Chargement...</p>
                }
            </GridCustom>
            <DialogModal
                icon={true}
                type='delete'
                open={openDeleteModal}
                title={`Êtes-vous sûr de vouloir supprimer cette catégorie ?`}
                secondaryAction={() => setOpenDeleteModal(!openDeleteModal)}
                primaryAction={() => { props.treeDeleteCat(idToDelete); setOpenDeleteModal(!openDeleteModal) }}
                windowWidth={props.windowWidth}
            >
                <p>Vous êtes sur le point de supprimer cette catégorie. Cette action est irréversible.</p>
            </DialogModal>
        </>
    )
}

export default TreeView;