import React, { useEffect, useState } from "react";
import InputBuilder from "@/components/ui/form/inputs/InputBuilder";
import styled from "styled-components";
import { IconButton, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import colors from "@/config/theme/colors";
import Icon from "@/components/ui/icons/Icon";
import Tooltip from "@/components/ui/tooltip/BootstrapTooltip";
import { MEDIAPICKER_TYPES } from "@/js/constants/mediapickerTypes.enum.js";
import { IMAGES } from "@/js/constants/medias-types";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const RowVariantContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid ${colors.grey.border};
  &:hover {
    border: solid 1px ${colors.blue.regular};
  }
  transition: all 0.1s ease-in-out;
`;

const RowVariantHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RowProductVariantForm = ({
  inputs,
  image,
  index,
  handleChangeVariant,
  handleChangeImage,
  handleDeleteVariant,
  ...props
}) => {
  const [form, setForm] = useState(inputs);
  const [mainImageValue, setMainImageValue] = useState(image);

  useEffect(() => {
    handleChangeVariant(form, index);
  }, [form]);

  const handleChange = (e, identifier) => {
    let value = null;

    if (e) value = e.target ? e.target.value : e;

    setForm((prevState) => {
      return prevState.map((input) => {
        if (input.identifier === identifier) {
          return {
            ...input,
            value,
          };
        }
        return input;
      });
    });
  };

  useEffect(() => {
    handleChangeImage(mainImageValue, index);
  }, [mainImageValue]);

  return (
    <RowVariantContainer onBlur={props.onBlur ? props.onBlur() : null}>
      <RowVariantHeader>
        <Typography variant="h3">
          {props.t("productVariants.form.defaultTitle")}
        </Typography>
        <Tooltip title={props.t("actions.delete")} placement="top">
          <IconButton onClick={() => handleDeleteVariant(index)}>
            <Icon icon="delete" color={colors.red.regular} />
          </IconButton>
        </Tooltip>
      </RowVariantHeader>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputBuilder
            type="mediaPicker"
            input={{
              fileType: MEDIAPICKER_TYPES.IMAGE,
              allowedTypes: IMAGES,
              allowedMedia: IMAGES,
              translated: false,
              required: false,
              stateName: "product_image",
              handleMediaPicker: (e, stateName) =>
                setMainImageValue({
                  ...e,
                  value: e,
                  identifier: "product_image",
                }),
            }}
            value={mainImageValue}
            label={props.t("inputs.mainImage")}
            usedAlone={true}
            hasMediaPicker={true}
            onDelete={() => setMainImageValue(null)}
            stateCallback={(e) => {
              const newForm = form.map((input) => {
                if (input.identifier === "product_image") {
                  return {
                    ...input,
                    value: e,
                  };
                }
                return input;
              });
              setForm(newForm);
            }}
          />
        </Grid>

        {form.map((input, index) => {
          return (
            <Grid key={`AttributeInput${index}`} item xs={12}>
              <InputBuilder
                key={`AttributeInput${index}`}
                type={input.inputType}
                sx={{ width: "100%" }}
                options={input.options}
                label={input.label}
                value={input.value}
                usedalone={true ? 1 : 0}
                delay={450}
                onChange={(e) => handleChange(e, input.identifier)}
              />
            </Grid>
          );
        })}
      </Grid>
    </RowVariantContainer>
  );
};

RowProductVariantForm.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string.isRequired,
      inputType: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ).isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  image: PropTypes.object,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChangeVariant: PropTypes.func.isRequired,
  handleChangeImage: PropTypes.func.isRequired,
  handleDeleteVariant: PropTypes.func.isRequired,
};

export default withTranslation()(RowProductVariantForm);
