import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import HeadingCell from './cells/HeadingCell'
import CellText from './cells/CellText';
import CellImage from './cells/CellImage';
import CellLink from './cells/CellLink';
import CellAvatars from './cells/CellAvatars';
import CellProfilePicture from './cells/CellProfilePicture';
import colors from '../../../../../config/theme/colors';
import Button from '../../../../ui/button/Button';
import CellStatus from './cells/CellStatus';
import StatusInfo from '../../../../ui/status-info/StatusInfo';
import OurTypography from '../../../../ui/typography/Typography';
import { useEffect, useState } from 'react';
import moment from 'moment';


const CustomRow = styled(TableRow)`
    background : ${props => props.styling.rowBackground ? props.styling.rowBackground : 'white'};
    td:first-child{
        border-left : 1px solid rgba(224, 224, 224, 1);
    }
    td:last-child{
        border-right : 1px solid rgba(224, 224, 224, 1);
    }
    &:nth-last-of-type(1){
        td{
            border-bottom:none;
        }
    }
    ${props => props.styling.type === 'cards' ? 
        `
        border-radius : 10px;
        &:hover{
            box-shadow :0px 1px 9px -1px rgba(0,0,0,0.15);
        }
        `
    : null}
    ${props => props.styling.type === 'striped' ? 
        `   
            background : #f2f2f2;
            &:nth-of-type(odd){
                background :  white;
            }
        `
    : null}
`
const CustomTable = styled(Table)`
    th{
        background: unset;
    }
    ${props => props.styling.type === 'cards' ? `
        border-spacing: 0px 10px;
        border-collapse:separate;
    `:null}
`

const HeadRow = styled(TableRow)`

    th{
        background : ${props=> props.styling?.headingBackground ? props.styling.headingBackground : colors.grey.lighter.hue980 };
        color : ${props=> props.styling?.headingColor ? props.styling.headingColor : colors.grey.lighter.hue600 };
    }
`
const ButtonCustom = styled(Button)`
    &.delete{
        background : ${colors.red.regular};
    }
    /* Change style */
    &.edit{
        background : ${colors.red.regular};
    }
`

const cells = {
    'text' : CellText,
    'image' : CellImage,
    'link'  : CellLink,
    'avatars' : CellAvatars,
    'profil_picture' : CellProfilePicture,
    'date' : CellText,
    'switch': CellText,
    'select': CellText,
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
//  DO NOT REMOVE THIS ==> ROWS FOR WORKFLOWS
const WorkflowRows = (props) => {
    return (
        <CustomRow 
            key={`list-${capitalize(props.label)}-${props.index}`}
            styling = {props.styling}
        >
            {props.columns.map((column,i)=>{
                if (column) {
                    let Cell = cells[column?.cellType];
                    let value = props.item.node[column?.field];
                    let media = null;
                    if (!value || value === undefined){
                        if (props.item.node.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.attribute.attributeType.input === "image"){
                            media = props.item.node.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.media
                        }
                        value = props.item.node.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.value
                    }
                    return(
                        <Cell key={i} value={value} media={media} width={column.width} column={column} {...column.cellProps} attribute={props.item.node.shellDatas?.edges.find(e => e.node.attribute.identifier === column.field  && e.node.locale?.code === props.currentLang)?.node}/>
                    )
                }
            })}
            <TableCell align='right'>
                {
                    props.tableProps.textButton ? (
                        <Button text={props.tableProps.textButton} onClick={() => props.tableProps.handlerButton(props.item.node.id)}/> 
                    ) : null
                }
                {
                    props.tableProps.textButtonSecondary ?
                    props.tableProps.typeButtonSecondary === 'edit' ? (
                        <Button
                            text={'Modifier'}
                            icon={"edit"}
                            color={colors.blue.darker.hue300}
                            colorHover={colors.white}
                            bgcolorhover={colors.blue.lighter.hue600}
                            bgcolor={colors.blue.lighter.hue900}
                            border={`1px solid ${colors.blue.darker.hue300}`}
                            stopPropagation={true}
                            onClick={() => props.tableProps.handlerButtonSecondary(props.item.node.id)}
                            disabled={false}
                            style={{
                                margin: 0,
                            }}
                        /> 
                    ) : (
                        <ButtonCustom className={props.tableProps.typeButtonSecondary} text={props.tableProps.textButtonSecondary} onClick={() => props.tableProps.handlerButtonSecondary(props.item.node.id)}/>
                    )
                    : null 
                }
            </TableCell>
        </CustomRow>
    )
}

const getElement = (field,value,column) =>{
    if (!value) {
        return "-"
    }
    switch (field) {
        case 'status':
            return <StatusInfo status={value} width={'auto'} />
        case 'default':
            return value
        case 'array':
            return value.length > 0? value.map(e=>e).join(","):"-"
        case 'date':
            return moment(value).format(column.cellProps?.formatDate??"DD/MM/YYYY");
        default:
            return<TableCell align='left'>
                {value}
            </TableCell>
            break;
    }
}

const Rows = props =>{
    const [checked,setChecked] =useState(false)

    useEffect(() => {
        setChecked(props?.itemSelection?.items.find(e=>e === props?.item?.id)?true : false)
    }, [props?.itemSelection?.items])

    return (
        <TableRow>
            {
                props.selectionItemsMode?
                    <TableCell padding="normal" sx={{borderBottom:props.last ?"none":`0.5px solid ${props.tableProps?.borderColor??colors.grey.border}`,background:props.tableProps?.backgroundColor,color:props.tableProps?.color}} align='left'>
                        <Checkbox onChange={(e)=>{props.handleSelectionItem(e.target.checked,props?.item?.id)}} checked={checked} sx={{padding:'0px !important'}} />
                    </TableCell>
                :null
            }
            {
                props.columns.map((column,i)=>{
                    if (column.field === 'status' && props.tableProps?.falseStatus) {
                        return(
                            <TableCell sx={{borderBottom:props.last ?"none":`0.5px solid ${props.tableProps?.borderColor?? colors.grey.border}`,background:props.tableProps?.backgroundColor,color:props.tableProps?.color}} key={i} align={column.cellProps.align ??"center"}>
                                <Tooltip title={column.label} placement='top'>
                                    <OurTypography colortext={props.tableProps?.color} fontweighttext={column.cellProps.fontWeight} style={{
                                        display:"flex",
                                        alignItems:"center",
                                        gap:8
                                    }}>
                                    <div style={{backgroundColor:props.tableProps?.falseStatusColor,height:"8px",width:"8px",borderRadius:"100%"}}/>{props.tableProps?.falseStatus}
                                    </OurTypography>
                                </Tooltip>
                            </TableCell>
                        )    
                    }
                    if (column.field === 'user' && props.tableProps?.falseUser) {
                        return(
                            <TableCell sx={{borderBottom:props.last ?"none":`0.5px solid ${props.tableProps?.borderColor?? colors.grey.border}`,background:props.tableProps?.backgroundColor,color:props.tableProps?.color}} key={i} align={column.cellProps.align ??"center"}>
                                <Tooltip title={column.label} placement='top'>
                                    <OurTypography colortext={props.tableProps?.color} fontweighttext={column.cellProps.fontWeight} style={{
                                        display:"flex",
                                        alignItems:"center",
                                        gap:8
                                    }}>
                                    <span style={{
                                        fontWeight:"normal"
                                    }}>Par </span>{props.tableProps?.falseUser}
                                    </OurTypography>
                                </Tooltip>
                            </TableCell>
                        )    
                    }
                    return (
                        <TableCell sx={{borderBottom:props.last ?"none":`0.5px solid ${props.tableProps?.borderColor?? colors.grey.border}`,background:props.tableProps?.backgroundColor,color:props.tableProps?.color}} key={i} align={column.cellProps.align ??"center"}>
                            <Tooltip title={column.label} placement='top'>
                                <OurTypography colortext={props.tableProps?.color} fontweighttext={column.cellProps.fontWeight}>{getElement(column.type,props.item[column.field],column)}</OurTypography>
                            </Tooltip>
                        </TableCell>
                    )
                })
            }
            {
                props.settings.action.show ?
                    <TableCell onClick={()=>props.tableProps.redirectDetail(props.item.id)} sx={{borderBottom:props.last ?"none":`0.5px solid ${props.tableProps?.borderColor??colors.grey.border}`,textDecoration:'underline',color:props.tableProps?.color??colors.blue.darker.hue300,cursor:'pointer',fontWeight:"bold",background:props.tableProps?.backgroundColor}} align='center'>
                        {props.settings.action.label}
                    </TableCell>
                :null
            }
        </TableRow>
    )
}

export default function TableView(props){
    let {settings,items,label,currentLang, selectionItemsMode} = props;
    const columns = settings?.columns;
    return(
        <div className='listing' style={{maxHeight: "100%", backgroundColor:"#FFF",border:`0.5px solid ${props.tableProps?.borderColor??colors.grey.border}`,overflow:"auto"}}>
            <CustomTable aria-label="headerList" styling={settings.style} stickyHeader>
                {
                    columns && columns.length > 0 && settings?.header?.show ? 
                        <TableHead>
                            <HeadRow styling={settings.style}>
                                {
                                    props.selectionItemsMode ?
                                        <HeadingCell padding='checkbox' label={""}  align={"left"}/>    
                                    :null
                                }
                                {columns.map((column,i)=>{
                                    return <HeadingCell align={column.cellProps.align ?? "center"} key={i} sortCallback={props.sortCallback}label={column?.label} filter={column?.filter} sortBy={props.sortBy}/>
                                })}
                                <HeadingCell padding='none' size='small' label={""}  align={"right"}/>
                            </HeadRow>
                        </TableHead>
                    : null
                }
                <TableBody>
                    {
                        props.ready && items ? 
                            items.map((item,index)=>{
                                return <Rows handleSelectionItem={props.handleSelectionItem} selectionItemsMode={selectionItemsMode} last={items.length === (index + 1)} item={item} index={index} columns={columns} styling={props.settings.style} key={`row-${index}`} label={label} {...props} />
                            })
                        : 
                        <tr>
                            <td colSpan={columns?.length ? columns.length : 1}><PageLoader/></td>                 
                        </tr>
                    }
                </TableBody>
            </CustomTable>
        </div>
    )
}

