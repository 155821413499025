import PropTypes from "prop-types";
import IcomoonReact from "icomoon-react"
import colors from "../../../../../../../config/theme/colors"
import OurTypography from "../../../../../../ui/typography/Typography"
import OurButton from "../../../../../../ui/button/Button"
import iconSet from "../../../../../../../assets/selection.json";

const CommonCard = ({
  data,
  button,
  isLast
}) => {

  return (
    <div style={{
      border: `0.5px solid ${colors.grey.border}`,
      borderRight: isLast ? `0.5px solid ${colors.grey.border}` : 'none',
      padding: 24,
    }}>
      <div style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        marginBottom: 10
      }}>
        <div style={{
          backgroundColor: colors.blue.darker.hue300,
          borderRadius: "20%",
          padding: 5
        }}>
          <IcomoonReact color={colors.white} iconSet={iconSet} size={20} icon={data.icon} />
        </div>
        <OurTypography style={{ lineHeight: "20px", fontSize: 24 }} fontweighttext={"bold"} fontsize={"20px"}>
          {data.title}
        </OurTypography>
      </div>
      <OurTypography style={{ marginBottom: 20 }}>{data.description}</OurTypography>
      <OurButton style={{
        minWidth: 200
      }} onClick={button.action}>{button.text}</OurButton>
    </div>
  )
}

CommonCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  }).isRequired,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
  }).isRequired
}

export default CommonCard