import React, { useState, useEffect, useRef } from "react";
import colors from '../../../config/theme/colors';
import styled from 'styled-components';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMore from "@mui/icons-material/ExpandMore";

// import sliderData from "../../data/hero-sliders/hero-slider-twelve.json";
// import '../../assets/scss/style.scss';
const AccordionDXP = styled(Accordion)`
    width: 100%;
    box-shadow: none !important;
    border:${props=> !props.noborder?`0.5px solid ${colors.grey.border}`:null};
    border-top:${props=> props.topborder?`0.5px solid ${colors.grey.border}`:null};
    .MuiAccordionSummary-content, .MuiAccordionSummary-root{
        cursor: ${props => props.forcedExpanded ? "default !important" : "pointer !important"};
    }
    &.Mui-expanded{
        margin: 0;
    }
    .MuiCollapse-root{
        overflow: ${props => props.overflow ? props.overflow: props.expandedFinished && props.isFilter?'visible': "clip"};
        /* height: ${props => props.overflow ?"calc(100% - 40px)": props.defaultExpanded === false ? 0:"auto"}; */
        height: ${props => props.overflow 
            ? props.detailsHeight ?? "100%"
            : props.defaultExpanded === false ? 0 :"auto"};
        & .MuiCollapse-wrapper, 
        & .MuiCollapse-wrapperInner, 
        & .MuiAccordion-region, 
        & .MuiAccordion-region > div{
            height: ${props => props.overflow ?"100%": "auto"};
        }
    }
    .MuiCollapse-wrapper,.MuiAccordion-region{
        height: ${props => props.overflow ?"100%": props.defaultExpanded === false ? "auto":"auto"};
    }
    height:${props => props.height ??"auto"};
`;
const AccordionSummaryDXP = styled(AccordionSummary)`    
    border-bottom:${props=> !props.noborder?props.borderBottomShow?`0.5px solid ${colors.grey.border} !important`:null:null};
    min-height: 40px !important;
    max-height: 40px !important;
    padding: 0 !important;
    background-color:#FFF;
    .MuiAccordionSummary-content{
        padding:${props=> props.noborder?0: "0 20px"};
        margin: 8px 0 !important;
        align-items: center;
        min-height: 40px;
        border-right:${props=> props.noborder?null: props.borderRight?`0.5px solid ${colors.grey.border}`:null};
    }
    .MuiAccordionSummary-expandIconWrapper{
        margin-right: 0;
        max-height: 40px;    
        padding: 0 10px;
    }
`;
const AccordionDetailsDXP = styled(AccordionDetails)`
    padding: ${props => props.custompadding ? props.custompadding : "22px !important"};
    height: ${props => props.scrollable ? props.height ? props.height : "550px" : "auto"};
    overflow: ${props => props.scrollable ? "auto" :props.overflow?props.overflow: props.expanded && props.isFilter? 'visible': "hidden"};
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    color: ${colors.black.regular};
    scroll-behavior: smooth;
    .MuiPaper-root{
        overflow: auto;
    }
    p{
        color: ${colors.black.regular};
    }
`;
export default function AccordionCustom(props) {
    let defaultExpanded = props.defaultExpanded
    const [expanded, setExpanded] = useState(typeof defaultExpanded === "boolean" ? defaultExpanded : true);
    const [expandedFinished, setExpandedFinished] = useState(typeof defaultExpanded === "boolean" ? defaultExpanded : true);
    const [borderBottomShow, setBorderBottomShow] = useState(typeof defaultExpanded === "boolean" ? defaultExpanded : true);
    const anchorContainerRef = useRef(null);

    useEffect(()=>{
        if (props?.currentAnchor && !props?.scrollChange) {
            let scrollBox = anchorContainerRef?.current
            scrollBox.scrollTo(0,props?.currentAnchor?.position)
        }
    },[props?.currentAnchor])

    useEffect(()=>{
        if (props.anchors) {
            let scrollBox = anchorContainerRef?.current
            scrollBox.onscroll = (e)=>{
                e.preventDefault()
                let anchor = props.anchors?.find((anchor,i)=>{
                    if (e?.target?.scrollTop >= anchor.position && e?.target?.scrollTop < props.anchors[i+1]?.position) {
                        return true
                    }
                })
                if (anchor) {
                    props.setCurrentAnchor(anchor)
                    props.setScrollChange(true)
                }
                if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
                    props.setCurrentAnchor(props.anchors[props.anchors.length-1])
                    props.setScrollChange(true)
                }
            }
        }
    },[props.anchors])


    const handle =()=>{
        if(props?.onChange){
            props?.onChange()
        }
        setExpanded(!expanded)
        if(expandedFinished){
            setExpandedFinished(!expandedFinished)
        }else{
            setTimeout(()=>{
                setExpandedFinished(!expandedFinished)
            },150)
        }
        if(borderBottomShow){
            setTimeout(()=>{
                setBorderBottomShow(!borderBottomShow)
            },150)
        }else{
            setBorderBottomShow(!borderBottomShow)
        }
    }

    useEffect(() => {
        props.handleExpanded && props.handleExpanded(expanded)
    }, [expanded]);

    return (
        <AccordionDXP
            defaultExpanded={props.defaultExpanded}
            expanded={props.forcedExpanded ? true : props.expanded?props.expanded : expanded}
            forcedExpanded={props.forcedExpanded}
            onChange={() => props.forcedExpanded?null:handle()}
            square={true}
            style={{ ...props.style }}
            noborder={props.noborder}
            overflow={props.overflow}
            expandedFinished={expandedFinished}
            isFilter={props.isFilter}
            height={props.height}
            topborder={props.topborder}
            detailsHeight={props.detailsHeight}
        >
            <AccordionSummaryDXP
                expandIcon={props.forcedExpanded && !props.hasIcon ? null : <ExpandMore style={{ fill: colors.black.regular }} />}
                borderRight={!props.forcedExpanded || props.hasIcon}
                noborder={props.noborder}
                borderBottomShow={props.forcedExpanded ? true : borderBottomShow}
                onClick={e=>e.preventDefault()}
            >
                {props.title}
            </AccordionSummaryDXP>
            <AccordionDetailsDXP ref={anchorContainerRef} id={props?.id} isFilter={props.isFilter} expanded={expanded} overflowChange={props.overflowChange} overflow={props.overflow} scrollable={props.scrollable} height={props.height} custompadding={props.custompadding} style={props.detailsStyles}>
                {props.children}
            </AccordionDetailsDXP>
        </AccordionDXP>
    );
}