import React from 'react';
import { ADD_LOCALE, UPDATE_LOCALE, GET_LOCALES } from '../../../../../queries/locales';
import { useMutation } from '@apollo/client';
import { SET_LOCALES } from '../../../../../js/constants/action-types';
import { Grid } from '@mui/material';
import Button from '../../../../ui/button/Button';
import colors from '../../../../../config/theme/colors';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { connect } from "react-redux";
import { withApollo } from '@apollo/client/react/hoc';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ValidateLanguages(props){
    const newLanguages = props.newLanguages;
    const changesLanguages = props.changesLanguages;

    const [open, setOpen] = React.useState(false);
    const [textSnack, setTextSnack] = React.useState('La mise à jour des langues s\'est déroulée avec succès');
    const [typeSnack, setTypeSnack] = React.useState('success');

    const [addLocale] = useMutation(ADD_LOCALE, {refetchQueries: [{query: GET_LOCALES}]});
    const [updateLocale] = useMutation(UPDATE_LOCALE, {refetchQueries: [{query: GET_LOCALES}]});
    
    const handleClick = () => {
      setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    
    const save = () => {
        setOpen(false);
        var validate = true;
        for (let language of newLanguages) {
            if(language.code && language.libelle && validate === true){
                addLocale({ variables: { "code": language.code, "libelle": language.libelle } }).then(result => {
                    let locale = result.data.createLocale.locale;
                    props.locales.push({ node: locale });
                    localStorage.setItem('LOCALES', JSON.stringify(props.locales));
                    props.setLocales(props.locales);
                }).catch(error => {
                    console.log(error)
                });
                setTextSnack('La mise à jour des langues s\'est déroulée avec succès');
                setTypeSnack('success');
            }else{
                setTextSnack('Tous les champs sont requis');
                setTypeSnack('error');
                validate = false;
            }
            handleClick();
        }
        for (let language of changesLanguages) {
            if(language.data.code && language.data.libelle && validate === true){
                updateLocale({ variables: { "id": language.id,  "code": language.data.code, "libelle": language.data.libelle }}).then(result => {
                    let locale = result.data.updateLocale.locale;

                    for (let loc of props.locales) {
                        if (loc.node.id === locale.id) {
                            loc.node.code = locale.code;
                            loc.node.libelle = locale.libelle;
                        }
                    }

                    localStorage.setItem('LOCALES', JSON.stringify(props.locales));
                    props.setLocales(props.locales);
                }).catch(error => {
                    console.log(error)
                });
                setTextSnack('La mise à jour des langues s\'est déroulée avec succès');
                setTypeSnack('success');
            }else{
                setTextSnack('Tous les champs sont requis');
                setTypeSnack('error');
                validate = false;
            }
            handleClick();
        }
        if(validate === true){
            props.handleResetData();
        }
    }

    return(
        <>
            <Grid container direction="column" style={{alignItems: "flex-end", paddingTop: 16}} spacing={0}>
                <Grid item>
                    <Button text="Save" bgcolor={colors.blue.darker.hue300} shadowcolor={colors.black.regular} onClick={save} disabled={props.validate ?  false : true}></Button>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
                <div>
                    <Alert onClose={handleClose} severity={typeSnack}>
                        {textSnack}
                    </Alert>
                </div>
            </Snackbar>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setLocales: (locales) => dispatch({ type: SET_LOCALES, payload: { locales }})
    }
}

const mapStateToProps = state => {
    return {
        locales: state.locales
    };
};

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(ValidateLanguages));