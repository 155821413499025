import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

const RetailersAdd =  {
    titleForm: "Ajouter un retailer",
    subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre retailer',
    langSelect: true,
    drawerType: 'drawer',
    noForm: true,
    component: Button,
    formConfig: {
        type: STEPPER,
        finalStep:{},
        children:[
            {
                labelName: 'Initialisation',
                isOptionnal: false,
                optionsInputs:[
                    {
                        type: 'text',
                        label: 'Libelle',
                        helper: {
                            text: 'Indiquez le nom',
                            link: false,
                        },
                        required: true,
                        stateName: 'libelle',
                    },
                    {
                        type: 'text',
                        label: 'Identifiant',
                        translated: false,
                        helper: {
                            text: "Identifiant de l'attribut",
                            link: false,
                        },
                        required: true,
                        stateName: 'attributeIdentifier',
                    },
                    {
                        type: 'textarea',
                        label: 'Description',
                        helper: {
                            text: 'Entrez ici une description',
                            link: false,
                        },
                        required: true,
                        stateName: 'description',
                    },
                    {
                        type: 'oneImage',
                        label: 'Logo du retailers',
                        translated: false,
                        helper:  {
                            text: 'Choisir une image',
                            link: false,
                        },
                        required: false,
                        stateName: 'image'
                    },
                ]
            },
            {
                labelName: 'Choix du fichier',
                isOptionnal: true,
                optionsInputs: []
            },
            {
                labelName: 'Mapping',
                isOptionnal: true,
                optionsInputs: [],
            }
        ]
    }
}

export default RetailersAdd;