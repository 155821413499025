import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    }
});

export default ({ config, mapper }) => {
    const classes = useStyles();

    if (!config) return null;

    return (
        <div className={classes.root}>
            { config?.elements?.map(mapper) }
        </div>
    );
};