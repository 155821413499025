import React from "react";
import { Box, Typography } from "@mui/material";
import colors from "@config/theme/colors";
import BootstrapTooltip from "@ui/tooltip"
import styled from "styled-components";
import PropTypes from "prop-types";

const Color = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  background-color: ${(props) => props.color};
`;

const ColorsList = ({ colorsList, limit = 4, style }) => {
  return (
    <Box style={style}>
      {colorsList.length > 0 ? (
        colorsList.map((color, index) => {
          if (index <= limit)
            return (
              <Box
                key={`guideline-color-${index}`}
                sx={{
                  display: "flex",
                  alignItems: "start",
                  marginRight: 2,
                }}
              >
                <Color color={color.codeHexa} />
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  {color.codeHexa},
                </span>
              </Box>
            );

          if (index === limit)
            return (
              <Box
                sx={{ display: "inline-block" }}
                key={`guideline-color-${index}`}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "start",
                    marginRight: 16,
                    marginTop: -20,
                    fontSize: 20,
                    fontWeight: "bold",
                    color: colors.grey.regular,
                    lineHeight: "16px",
                  }}
                >
                  <BootstrapTooltip
                    title={colorsList.map((color, index) =>
                      index >= 4 && index < 10 ? (
                        <Box
                          sx={{ display: "block" }}
                          key={`tooltip-item-color-${index}`}
                        >
                          <Box
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <Color color={color.codeHexa} />
                            <span
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "bold",
                              }}
                            >
                              {color.codeHexa},
                            </span>
                          </Box>
                        </Box>
                      ) : index === 10 ? (
                        <p key={`tooltip-item-last-${index}`}>
                          +{colorsList.length - 10} couleur
                          {colorsList.length - 10 > 1 ? "s" : null}
                        </p>
                      ) : null
                    )}
                  >
                    <p>...</p>
                  </BootstrapTooltip>
                </Box>
              </Box>
            );
        })
      ) : (
        <Box>
          <Typography
            sx={{
              color: colors.grey.lighter.hue600,
              fontWeight: "bold",
            }}
          >
            Aucune couleur associée
          </Typography>
        </Box>
      )}
    </Box>
  );
};

ColorsList.propTypes = {
  colorsList: PropTypes.arrayOf(
    PropTypes.shape({
      codeHexa: PropTypes.string,
    })
  ),
  limit: PropTypes.number,
  style: PropTypes.object,
};

export default ColorsList;
