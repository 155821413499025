import React from "react";
import styled from 'styled-components';

const CustomImg = styled.img`
    &.w100 {
        width: 100%;
        // min-height: 300px;
        height:${props => props.height? props.height + "px" : "auto" };
        object-fit:cover;
        object-position:50% ${props=>props.objectPosition}%;
    }
    &.absolute {
        position: absolute;
    }
`;

export const PictureTag = ({ media, alt, classes, spread, lazy, isOptimised = true,height,objectPosition }) => {
    let jpg = media?.filePath
        ? `${process.env.REACT_APP_MEDIAS}/${media?.filePath}`
        : media ? media : null;

    let webp = media?.filePath
        ? media?.filePath.replace(/\.[^/.]+$/, "") + '.webp'
        : media ? media : null;

    if (spread || !isOptimised) {
        return (
            <CustomImg
                className={classes}
                src={jpg}
                alt={alt}
                loading={lazy ? 'lazy' : 'eager'}
                height={height}
                objectPosition={objectPosition}
            />
        )
    }

    return (
        <picture>
            {/* <source srcSet={`${process.env.REACT_APP_MEDIAS}/${webp}`} type="image/webp" /> */}
            <source srcSet={jpg} type="image/jpeg" />
            <CustomImg
                className={classes}
                src={jpg}
                alt={alt}
                loading={lazy ? 'lazy' : 'eager'}
                height={height}
                objectPosition={objectPosition}
            />
        </picture>
    );
};