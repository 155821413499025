import { useEffect, useState } from "react";
import { GridCustom, SwitchCustom } from "../../styles/inputStyled";
import { FormControlLabel } from "@mui/material";
import { IOSSwitch } from "./styles/styled";

const Switch = ({ allProps: props }) => {
    const [switchValue, setSwitchValue] = useState(props.input.type === 'switch' ? props.value : null);

    useEffect(() => {
        props.handleButtonGroupChange(props.input.stateName, switchValue ?? props.value);
    }, [switchValue]);

    const handleSwitchChange = (event) => setSwitchValue(!switchValue);

    return (
        <GridCustom item xs={12}>
            {
                props.input.isRadio ? (
                    null
                ) : (
                    <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked={switchValue ?? false} />}
                        onChange={event => handleSwitchChange(event)}
                        disabled={props.input.disabled}
                        // label="iOS style"
                    />
                )
            }
        </GridCustom>
    )
}

export default Switch;