import styled from 'styled-components';
import colors from '@config/theme/colors';

export const FormContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${colors.background.white.primary};
  width: 100%;
`;

export const MappingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${colors.background.white.primary};
  width: 100%;
  padding: 30px;
`;