import React from "react";
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { withRouter } from 'react-router';
function CellAvatars(props){
    const goTo = (route) => {
        props.history.push(route);

    };

    return(
        <TableCell 
            style={{
                width : props.width ? props.width :'auto',
                fontWeight : props.fontWeight ? props.fontWeight : 'normal',
                padding : '4px 16px'
            }}
        >
            <Grid container>
            {props.value.map((item, index)=>      
                <Tooltip key={index} title={item.tooltip} arrow>
                    <Avatar 
                        style={{cursor: item.link ? 'pointer' : 'auto',marginRight:10}} 
                        onClick={item.link ? ()=>goTo(item.link) : ()=>{}}
                        src={item.image ? `${process.env.REACT_APP_MEDIAS}/${item.image}`:null}
                    >
                            {item.letter}
                        </Avatar>
                </Tooltip>                    
            )}
            </Grid>
        </TableCell>
    )
}
export default withRouter(CellAvatars);