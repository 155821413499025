import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Grid, Box } from '@mui/material';
import Typography from 'ui/typography/Typography';
import CardCustom from 'layouts/Card/CardCustom';
import Button from 'ui/button/Button';
import colors from 'colors';
import CardHomepageStats from 'layouts/Card/cardContent/CardHomepageStats';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import request from 'js/utils/fetch';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { withApollo } from '@apollo/client/react/hoc';
import Image1 from '../../../../../src/assets/images/ecran.png';
import { eventService } from 'js/services/event.service';
import { withTranslation } from 'react-i18next';
import logoSinfin from '../../../../../src/assets/images/logotype-home.svg';
import {
    ROUTE_PRODUCTS_LIST,
    ROUTE_STATS_ORDERS,
    ROUTE_CATALOGS_LIST,
    ROUTE_MEDIAS_LIST,
    ROUTE_CHANNELS,
    ROUTE_SYSTEM_LANGUAGES,
    ROUTE_CONTENU_LIST,
    ROUTE_SETTINGS_GUIDELINE,
    ROUTE_SALES_ESHOP,
    ROUTE_PRODUCTS_BRANDS_LIST,
} from 'js/constants/route-names';
import LoyaltySharpIcon from '@mui/icons-material/LoyaltySharp';
import StyleSharpIcon from '@mui/icons-material/StyleSharp';
import InsertPhotoSharpIcon from '@mui/icons-material/InsertPhotoSharp';
import ColorLensSharpIcon from '@mui/icons-material/ColorLensSharp';
import SettingsInputComponentSharpIcon from '@mui/icons-material/SettingsInputComponentSharp';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import DevicesSharpIcon from '@mui/icons-material/DevicesSharp';
import Newscard from './components/Newscard';
import OurTypography from 'ui/typography/Typography';
import InputBuilder from 'ui/form/InputBuilder';
import AccordionCustom from 'layouts/Accordion/AccordionCustom';
import SinfinLogo from '../../../../../src/assets/images/sinfin-logo-white.svg';
import StatsCard from './components/StatsCard';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { menuEntry } from 'js/utils/menuEntry';
import { GET_TOTAL_COUNTS, GET_TOTAL_COUNT_PRODUCTS_CONFIGURATION, GET_TOTAL_COUNT_DIFFUSION } from 'queries/total';
import PageLoader from 'ui/loadings/page-loader/PageLoader';
import moment from 'moment';
import { ROUTE_DIFFUSION_NEW_ASSETS, ROUTE_DIFFUSION_NEW_COVERRATE } from '../../../../js/constants/route-names';
import { ASSET_APPLICATION, ASSET_ESHOP, ASSET_MINISITE, ASSET_SALES } from '../../../../js/constants/assets-types';

const notifs = [
    {
        title: 'Sinfin™ se refait une beauté !',
        text: 'homepage.notification.lasttext',
        date: moment('2023-07-17'),
        // isHighlighted: true,
        id: uuidv4()
    },
    {
        title: 'Flux de travail',
        text: 'homepage.notification.secondtext',
        date: moment('2023-09-16'),
        // isHighlighted: true,
        id: uuidv4()
    },
    {
        title: 'Flux de travail',
        text: 'homepage.notification.secondtext',
        date: moment('2023-07-15'),
        // isHighlighted: false,
        id: uuidv4()
    },
    {
        title: 'Flux de travail',
        text: 'homepage.notification.secondtext',
        date: moment('2023-06-14'),
        // isHighlighted: false,
        id: uuidv4()
    },
    {
        title: 'Flux de travail',
        text: 'homepage.notification.secondtext',
        date: moment('2023-07-13'),
        // isHighlighted: false,
        id: uuidv4()
    },
    {
        title: 'Flux de travail',
        text: 'homepage.notification.secondtext',
        date: moment('2023-07-12'),
        // isHighlighted: false,
        id: uuidv4()
    },
];

const ContainerBlocNotification = styled(Box)`
    position: relative;
    padding-bottom: 16px;
    & > div:nth-child(even){
        background-color: ${colors.white.regular};
    }
`;
const RemoveNotification = styled(Button)`
    background: transparent;
    border-radius: 10px;
`;

const VerticalSpaceBetweenLayout = styled(Grid)`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
`;

const DataLayout = styled(Box)`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto 1fr;
    row-gap: 16px;
`;

const InputWrapper = styled(Box)`
width: 100%;
    & > div {
        margin-bottom: 0px;
        width: 100%;
    }
`;

const HomepagNew = ({ history, windowWidth, user, t, client }) => {
    const [notifications, setNotifications] = useState(notifs);
    const [filteredNotifications, setFilteredNotifications] = useState(notifications);
    const [data, setData] = useState(null);
    const [totalOrder, setTotalOrder] = useState(null);
    const [date, setDate] = useState(null);
    const [allDatas, setAllDatas] = useState(null);
    const [selectDataPie, setSelectDataPie] = useState(null);
    const [selectedDataPie, setSelectedDataPie] = useState(null);
    const [actions, setActions] = useState([]);
    const [statsHomepage, setStatsHomepage] = useState(null);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [leftSideRefHeight, setLeftSideRefHeight] = useState(null);
    const [notificationsDate, setNotificationsDate] = useState('week');
    const leftSideRef = useRef(null);

    useEffect(() => {
        initStats();
    }, []);

    const sortedNotifications = useMemo(() => {
        const sorted = filteredNotifications.sort((a, b) => {
            const moment1 = moment(a.date, 'YYYY-MM-DD');
            const moment2 = moment(b.date, 'YYYY-MM-DD');
        
            return moment2.diff(moment(), 'days') - moment1.diff(moment(), 'days');
        });

        return filteredNotifications;
    }, [filteredNotifications]);

    const initStats = async () => {
        let array = [];
        let eshopCount = 0;
        let assetCount = 0;
        try {
            if (menuEntry("centralisation") || true) {
                const { data } = await client.query({
                    query: GET_TOTAL_COUNTS
                })
                let obj = {
                    title: "Centralisation",
                    data: [
                        (menuEntry("centralisation_products") || true) &&
                        {
                            title: "Produits",
                            icone: "picto-produit",
                            total: data.products.totalCount,
                            // totalin: 23,
                            route: ROUTE_PRODUCTS_LIST
                        },
                        (menuEntry("centralisation_medias") || true) &&
                        {
                            title: "Médias",
                            icone: "picto-media",
                            total: data.mediaObjects.totalCount,
                            // totalin: 23,
                            route: ROUTE_MEDIAS_LIST
                        },
                        (menuEntry("centralisation_contents") || true) &&
                        {
                            title: "Contenus",
                            icone: "picto-connector-content",
                            total: data.contents.totalCount,
                            // totalin: 23,
                            route: ROUTE_CONTENU_LIST
                        },
                        (menuEntry("centralisation_catalogs") || true) &&
                        {
                            title: "Catalogues",
                            icone: "ico_bookmarks",
                            total: data.catalogs.totalCount,
                            // totalin: 23,
                            route: ROUTE_CATALOGS_LIST
                        },
                        (menuEntry("centralisation_brands") || true )&&
                        {
                            title: "Marques",
                            icone: "ico_hanger",
                            total: data.productSources.totalCount,
                            // totalin: 23,
                            route: ROUTE_PRODUCTS_BRANDS_LIST
                        },
                    ]
                }
                array.push(obj)
            }
            // if (menuEntry("optimisation") || true) {
            //     // const {data} = await client.query({
            //     //     query: GET_TOTAL_COUNTS
            //     // })
            //     let obj = {
            //         title: "Optimisation",
            //         data: [
            //         ]
            //     }
            //     array.push(obj)
            // }
            if (menuEntry("diffusion") || true) {
                const {data} = await client.query({
                    query: GET_TOTAL_COUNT_DIFFUSION
                })

                data.assets.edges.forEach((edge) => {
                    const assetTypeIdentifier = edge.node.assetType?.identifier;
                    if (assetTypeIdentifier && ASSET_ESHOP.includes(assetTypeIdentifier)) {
                        eshopCount += 1;
                    }
                    if (assetTypeIdentifier && ASSET_APPLICATION.concat(ASSET_SALES,ASSET_MINISITE).includes(assetTypeIdentifier)) {
                        assetCount += 1;
                    }
                })

                let obj = {
                    title: "Diffusion",
                    data: [
                        {
                            title: "E-shop",
                            icone: "picto-produit",
                            total: eshopCount,
                            // totalin: 23,
                            route: ROUTE_SALES_ESHOP
                        },
                        {
                            title: "Assets de sortie",
                            icone: "picto-categorie",
                            total: assetCount,
                            // totalin: 23,
                            route: ROUTE_DIFFUSION_NEW_ASSETS
                        },
                        {
                            title: "Taux de couverture",
                            icone: "ico-cover-rate",
                            total: "75%",
                            // totalin: 23,
                            route: ROUTE_DIFFUSION_NEW_COVERRATE,
                            border:true
                        },
                    ]
                }
                array.push(obj)
            }
            setStatsHomepage(array)
        } catch (error) {
            console.log(error)
        }
        // request(`${process.env.REACT_APP_API}/dashboards/stats/homepage`, 'get', false).then(
        //     (data) => {
        //         if (data.success) {
        //             let getStats = statsHomepage
        //             if (getStats[0]) {
        //                 getStats[0].data = data.datas.nbCategories;
        //             }
        //             if (getStats[1]) {
        //                 getStats[1].data = data.datas.nbAssetEnable;
        //             }
        //             if (getStats[2]) {
        //                 getStats[2].data = data.datas.nbProducts;
        //             }
        //             if (getStats[3]) {
        //                 getStats[3].data = data.datas.nbCampaignEnable;
        //             }
        //             if (data.datas.nbCategories > 1 && getStats[0]) {
        //                 getStats[0].subtitle = 'homepage.stats.category';
        //             }
        //             if (data.datas.nbAssetEnable > 1 && getStats[1]) {
        //                 getStats[1].subtitle = 'homepage.stats.asset';
        //             }
        //             if (data.datas.nbProducts > 1 && getStats[2]) {
        //                 getStats[2].subtitle = 'homepage.stats.product';
        //             }
        //             // if (data.datas.nbCampaignEnable > 1 && getStats[3]) {
        //             //     getStats[3].subtitle = 'homepage.stats.campagne';
        //             // }

        //             setStatsHomepage([...getStats]);
        //         }
        //     }
        // ).catch((err) => {
        //     console.log(err)
        // })

        let actions = [];

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': 'ico-charts',
                'title': 'homepage.cards.statisticTitle',
                'subtitle': 'homepage.cards.statisticSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.white,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_STATS_ORDERS,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            })
        }

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': 'ico_eshop',
                'title': 'homepage.cards.eshopTitle',
                'subtitle': 'homepage.cards.eshopSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.white,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_SALES_ESHOP,
                // 'routeState': {openForm: true, buttonAvailable: true}
            })
        }

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': <LoyaltySharpIcon style={{ fontSize: windowWidth <= 1500 ? "16px" : "25px" }}></LoyaltySharpIcon>,
                'title': 'homepage.cards.engineTitle',
                'subtitle': 'homepage.cards.engineSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.black.regular,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_CATALOGS_LIST,
                // 'routeState': {openForm: true, buttonAvailable: true}
            });
        }

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': <StyleSharpIcon style={{ fontSize: windowWidth <= 1500 ? "16px" : "25px" }}></StyleSharpIcon>,
                'title': 'homepage.cards.produitTitle',
                'subtitle': 'homepage.cards.produitSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.black.regular,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_PRODUCTS_LIST,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            });
        }

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': <InsertPhotoSharpIcon style={{ fontSize: windowWidth <= 1500 ? "16px" : "25px" }}></InsertPhotoSharpIcon>,
                'title': 'homepage.cards.mediaTitle',
                'subtitle': 'homepage.cards.mediaSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.black.regular,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_MEDIAS_LIST,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            });
        }
        actions.push({
            'icon': <DescriptionSharpIcon style={{ fontSize: '2vh' }}></DescriptionSharpIcon>,
            'title': 'homepage.cards.contentTitle',
            'subtitle': 'homepage.cards.contentSubtitle',
            'gradientColor1': colors.white,
            'gradientColor2': colors.white,
            'colorPicto': colors.black.regular,
            'color': colors.black.regular,
            'colorHover': colors.black.regular,
            'route': ROUTE_CONTENU_LIST,
            // 'routeState': {openForm: true, buttonAvailable: true}
        });

        actions.push({
            'icon': <DevicesSharpIcon style={{ fontSize: '2vh' }}></DevicesSharpIcon>,
            'title': 'homepage.cards.canalTitle',
            'subtitle': 'homepage.cards.canalSubtitle',
            'gradientColor1': colors.white,
            'gradientColor2': colors.white,
            'colorPicto': colors.black.regular,
            'color': colors.black.regular,
            'colorHover': colors.black.regular,
            'route': ROUTE_CHANNELS,
            // 'routeState': {openMediaForm: true, buttonAvailable: true}
        });

        actions.push({
            'icon': <ColorLensSharpIcon style={{ fontSize: windowWidth <= 1500 ? "16px" : "25px" }}></ColorLensSharpIcon>,
            'title': 'homepage.cards.guideTitle',
            'subtitle': 'homepage.cards.guideSubtitle',
            'gradientColor1': colors.white,
            'gradientColor2': colors.white,
            'colorPicto': colors.black.regular,
            'color': colors.black.regular,
            'colorHover': colors.black.regular,
            'route': ROUTE_SETTINGS_GUIDELINE,
            // 'routeState': {openForm: true, buttonAvailable: true}
        });

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': <SettingsInputComponentSharpIcon style={{ fontSize: windowWidth <= 1500 ? "16px" : "25px" }}></SettingsInputComponentSharpIcon>,
                'title': 'homepage.cards.settingTitle',
                'subtitle': 'homepage.cards.settingSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.black.regular,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_SYSTEM_LANGUAGES,
                // 'routeState': {openForm: true, buttonAvailable: true}
            });
        }

        if (process.env.REACT_APP_MODE_SPREAD === 'hub') {
            await request(`${process.env.REACT_APP_API}/hubStats`, 'post').then(
                (data) => {
                    if (data.success) {
                        let date = [];
                        let orderByDate = [];
                        let ordersByStatus = [];
                        let totalOrder = 0;

                        for (let item in data.ordersByDate) {
                            date.push(item)
                            orderByDate.push(data.ordersByDate[item]);
                            totalOrder += data.ordersByDate[item];
                        }

                        for (let item in data.ordersByStatus) {
                            let name = '';
                            switch (item) {
                                case 'delayed':
                                    name = 'En retard';
                                    break;
                                case 'pending':
                                    name = 'En attente';
                                    break;
                                case 'preorder':
                                    name = 'Pré-commande';
                                    break;
                                case 'preparation':
                                    name = 'En cours de préparation';
                                    break;
                                case 'returned':
                                    name = 'Retour';
                                    break;
                                case 'shipped':
                                    name = 'Expédiée'
                                    break;
                                default: return null;
                            }

                            ordersByStatus.push({ name: name, value: data.ordersByStatus[item] })
                        }

                        setData(data);
                        setTotalOrder(totalOrder);
                        setDate(date);

                        setAllDatas([{
                            data: orderByDate,
                            color: '#147FAB',
                            title: 'Commande/jour',
                        }]);

                        setSelectDataPie({
                            type: 'select',
                            isContainerized: true,
                            required: false,
                            stateName: 'selectedDataPie',
                            label: '',
                            helper: '',
                            value:
                                [
                                    {
                                        value: ordersByStatus,
                                        label: 'Commandes'
                                    },
                                    {
                                        value: [
                                            { value: data.fullStock, name: 'Stock actuel' },
                                            { value: data.fullReassort, name: 'Stock à venir' },
                                        ],
                                        label: 'Stock'
                                    }
                                ]
                        });

                        setSelectedDataPie({
                            value: ordersByStatus,
                            label: 'Commandes'
                        });
                    }
                }
            ).catch((err) => {
                console.log(err)
            });
        }

        setActions(actions);
    };

    const removeNotification = (id) => {
        const newState = notifications;
        const index = newState.findIndex(a => a.id === id);

        if (index === -1) return;
        newState.splice(index, 1);

        setNotifications([...newState]);
    };

    const handleSelectPie = (event) => {
        setSelectedDataPie(event.target.value);
        eventService.fire();
    };

    const goToAction = (route) => {
        history.push(route);
    };

    const searchInputConfig = {
        type: 'text',
        label: 'Rechercher dans la plateforme Sinfin™ …',
        helper: 'Rechercher dans la plateforme Sinfin™ …',
        iconPosition: 'start',
        required: false,
        stateName: 'libelle',
        disabled: false,
        value: '',
        custom: {
            prefix: <SearchSharpIcon />,
            backgroundColor: colors.grey.lighter.hue980,
        }
    };

    const statConfig = {
        icon: 'ico_produit',
        title: 'Produits',
        value: {
            main: 567,
            secondary: '/6788'
        },
        link: {
            label: 'Voir',
            route: ROUTE_CHANNELS
        },
    };

    const getHeight = () => {
        if (leftSideRef?.current) {
            setLeftSideRefHeight(leftSideRef.current.clientHeight)
        }
    };

    useEffect(() => {
        getHeight();
    }, [leftSideRef?.current]);

    useEffect(() => {
        const today = moment().startOf(notificationsDate);

        const filterOptions = {
            'day': notifications.filter(n => {
                const date = moment(n.date);

                return date.isSame(today, 'day');
            }),
            'week': notifications.filter(n => {
                const date = moment(n.date);

                return date.isSame(today, 'week');
            }),
            'month': notifications.filter(n => {
                const date = moment(n.date);

                return date.isSame(today, 'month');
            }),
        };

        setFilteredNotifications(filterOptions[notificationsDate]);
    }, [notificationsDate]);

    const notificationsDateConfig = {
        // xs: 4,
        type: 'select',
        isContainerized: true,
        required: false,
        value: [
            {
                value: 'day',
                label: 'Aujourd\'hui'
            },
            {
                value: 'week',
                label: 'Cette semaine'
            },
            {
                value: 'month',
                label: 'Ce mois-ci'
            },
        ],
        custom: {
            height: '30px',
            padding: '5px 5px 5px 10px'
        }
    }

    return (
        statsHomepage ?
            <div> {/* This div is used to make the homepage different to the others so the 32px padding is not applied */}
                <Grid container style={{
                    padding: 16
                }}>
                    <Grid container direction="row">
                        <VerticalSpaceBetweenLayout item xs={12} md={8} ref={leftSideRef} style={{ paddingRight: windowWidth > 1250 ? 7 : 0 }}>
                            <DataLayout>
                                {/* User Menu */}
                                <CardCustom paddingbottom={windowWidth > 1100 ? "0px" : 16} contentpadding={'0px'} style={{ minHeight: 111 }}>
                                    <CardHomepageStats statsHomepage={statsHomepage} user={user} windowWidth={windowWidth} />
                                </CardCustom>
                                {/* Search Bar */}
                                <CardCustom contentpadding={'16px 32px 14px 32px'} style={{ width: '100%' }}>
                                    <Grid container style={{ width: '100%' }}>
                                        <Grid item style={{ width: '100%' }}>
                                            <OurTypography fontweighttext={'bold'} style={{ fontSize: 18, marginBottom: 12 }}>Recherche rapide</OurTypography>
                                            <InputWrapper>
                                                <InputBuilder value={searchInputValue} input={searchInputConfig} stateCallback={(evt) => {
                                                    setSearchInputValue(evt.target.value);
                                                }} />
                                            </InputWrapper>
                                        </Grid>
                                    </Grid>
                                </CardCustom>

                                <Grid container direction={'column'}>
                                    <OurTypography fontsize={'29px'} fontweighttext={'bold'} style={{ marginBottom: 16 }}>Dashboard</OurTypography>
                                    <Box style={{
                                        display: 'grid',
                                        // gridTemplateRows: 'repeat(4, 1fr)',
                                        gridTemplateRows: 'repeat(3, 1fr)',
                                        rowGap: 16
                                    }}>
                                        {
                                            statsHomepage.map((stat, i) => {
                                                return (
                                                    <AccordionCustom key={i} title={stat.title} style={{
                                                        minHeight: '100px'
                                                    }} height={'auto'} custompadding={'0px !important'} forcedExpanded={true}>
                                                        <Grid container style={{
                                                            display: 'grid',
                                                            gridTemplateColumns: `repeat(${stat.data.length === 3? 4 :stat.data.length}, 1fr)`,
                                                            height: '100%'
                                                        }}>
                                                            {
                                                                stat?.data &&
                                                                stat.data.map((item, j) => {
                                                                    return (
                                                                        <Grid key={j} item style={{ height: '100%' }}>
                                                                            <StatsCard stat={item} index={j} isLast={item?.border ?false: j === stat.data.length - 1} />
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </AccordionCustom>
                                                )
                                            })
                                        }
                                    </Box>
                                </Grid>
                            </DataLayout>

                            {/* Banner */}
                            <Grid container justifyContent={'space-between'} style={{ backgroundColor: colors.black.lighter, padding: '18px 25px', marginTop: 16 }}>
                                <img src={SinfinLogo} alt="logo" style={{ width: 100 }} />
                                <a href='https://support.sinfin.fr/' target='_blank' style={{ textDecoration: 'none' }}>
                                    <Box style={{ color: colors.black.regular, backgroundColor: colors.white, padding: '10px 30px', borderRadius: '100px', fontWeight: 'bold', cursor: 'pointer' }}>
                                        OUVRIR UN TICKET +
                                    </Box>
                                </a>
                            </Grid>

                            {/* <Box mt={windowWidth <= 1500 ? 1.5 : '14px'} style={{ flex: 1 }}>
                                <Grid container direction="row" spacing={1} style={{ height: "100%" }}>
                                    {
                                        actions ? (actions.map((action, index) =>
                                            action ?
                                                (
                                                    <Grid item md={4} xs={6} key={`CardActions${index}`} style={{ marginRight: 0 }}>
                                                        <CardCustom style={{ height: '100%' }} contentpadding={'0px 0px 0px 0px'} hoverbordercolor={colors.grey.border}>
                                                            <CardActions action={action} goTo={goToAction} t={t} windowWidth={windowWidth}></CardActions>
                                                        </CardCustom>
                                                    </Grid>
                                                ) : null
                                        )) : null
                                    }
                                </Grid>
                                {
                                    (date && allDatas) || selectedDataPie && selectDataPie ?
                                        <Grid container direction="row" spacing={2}>
                                            {
                                                date && allDatas ? (
                                                    <Grid item lg={6} xs={12} >
                                                        <Box mt={1} mb={2}>
                                                            <CardCustom paddingtop={'48px'}>
                                                                <LineCharts xAxisData={date} yAxisData={allDatas} handleSelectPie={handleSelectPie} />
                                                            </CardCustom>
                                                        </Box>
                                                    </Grid>
                                                ) : null
                                            }
                                            {
                                                selectedDataPie && selectDataPie ? (
                                                    <Grid item lg={6} xs={12}>
                                                        <Box mt={1} mb={2}>
                                                            <CardCustom paddingtop={'48px'} style={{ position: 'relative' }}>
                                                                <PieCharts selectedDataPie={selectedDataPie} selectDataPie={selectDataPie} handleSelectPie={handleSelectPie} />
                                                            </CardCustom>
                                                        </Box>
                                                    </Grid>
                                                ) : null
                                            }
                                        </Grid>
                                        : null
                                }
            
                                {data && totalOrder ? (
                                    <Grid container direction="row" justifyContent="center" spacing={4}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justifyContent="center" spacing={0}>
                                                <Grid item xs={4}>
                                                    <CardCustom style={{ background: colors.black.regular, borderRadius: '5px 0 0 5px', borderRight: '1px solid white' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px' }}>
                                                        <Typography variant="h3" style={{ color: 'white', fontSize: 14, lineHeight: '20px' }}>Total commande</Typography>
                                                        <Typography variant="body1" style={{ color: 'white', fontSize: 36, lineHeight: '36px' }}>
                                                            <ShoppingCartOutlinedIcon /> {totalOrder}
                                                        </Typography>
                                                    </CardCustom>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <CardCustom style={{ background: colors.black.regular, borderRadius: '0', borderRight: '1px solid white' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px' }}>
                                                        <Typography variant="h3" style={{ color: 'white', fontSize: 14, lineHeight: '20px' }}>Total stock</Typography>
                                                        <Typography variant="body1" style={{ color: 'white', fontSize: 36, lineHeight: '36px' }}>
                                                            <EqualizerIcon accentHeight={300} additive /> {data?.fullStock ? data.fullStock : null}
                                                        </Typography>
                                                    </CardCustom>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <CardCustom style={{ background: colors.black.regular, borderRadius: '0 5px 5px 0' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px' }}>
                                                        <Typography variant="h3" style={{ color: 'white', fontSize: 14, lineHeight: '20px' }}>Total réassort</Typography>
                                                        <Typography variant="body1" style={{ color: 'white', fontSize: 36, lineHeight: '36px' }}>
                                                            <PlayForWorkIcon /> {data?.fullReassort ? data.fullReassort : null}
                                                        </Typography>
                                                    </CardCustom>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Box> */}
                        </VerticalSpaceBetweenLayout>

                        {/* Right */}
                        <Grid item xs={12} md={4} style={{ paddingLeft: windowWidth > 1250 ? 7 : 0 }}>
                            <CardCustom cardContentStyle={{ display: 'flex', flexDirection: 'column',overflow:"auto" }} style={{
                                minHeight: windowWidth <= 1500 ? null : 'calc(100vh - 32px)', overflow: 'auto',
                                height: leftSideRefHeight ?? 'calc(100vh - 32px)'
                            }}>
                                <Box m={'32px'} display="flex" justifyContent="center">
                                    <img src={logoSinfin} height={"150px"} width={'285px'} alt="logo-dxp" />
                                </Box>
                                <Box mb={'10px'}>
                                    <Grid container justifyContent='space-between' alignItems='end'>
                                        <Grid item xs={3}>
                                            <Typography variant="h3" colortext={colors.black.regular}>Notifications</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <InputWrapper>
                                                <InputBuilder value={notificationsDate} input={notificationsDateConfig} stateCallback={(evt) => {
                                                    setNotificationsDate(evt.target.value);
                                                }} />
                                            </InputWrapper>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <ContainerBlocNotification>
                                    {
                                        sortedNotifications.map((notification, index) => {
                                            return (
                                                <Newscard 
                                                    key={`notification${index}`} 
                                                    notification={notification} 
                                                    isHighlighted={
                                                        moment().diff(notification.date, 'days') === 0
                                                    } 
                                                />
                                            )
                                        })
                                    }
                                </ContainerBlocNotification>
                            </CardCustom>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            : <PageLoader />
    );
};

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps)(HomepagNew))));
