import React, { useEffect }  from 'react';

import { Grid, InputLabel } from '@mui/material';
import Button from '../../../ui/button/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';

import InputBuilder from '../../../ui/form/InputBuilder';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import DialogModal from '../../../ui/dialog/DialogModal';

import { withApollo } from '@apollo/client/react/hoc';
import { DELETE_LOCALE } from '../../../../queries/locales';

import { SET_LOCALES } from '../../../../js/constants/action-types';
import { connect } from "react-redux";

import _ from 'lodash';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {withTranslation} from 'react-i18next'


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    margin-top: ${props => props.margintop || 0};
    margin-bottom: 24px;
`;
const GridFlexCenter = styled(Grid)`
    display: flex;
    align-items: center;
`;

function CardLanguage(props){
    const language = props.language;
    const [open, setOpen] = React.useState(false);
    const [libelle, setLibelle] = React.useState(language.libelle);
    const [code, setCode] = React.useState(language.code);

    const [openSnack, setOpenSnack] = React.useState(false);
    const textSnack = React.useState('Impossible de supprimer cette langue.');
    const typeSnack = React.useState('error');

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const deleteLanguage = (id) => {
        props.client.mutate({
            mutation: DELETE_LOCALE,
            variables: {
                'id': id
            }
        })
        .then((res) =>{
            if (!res.errors) {
                props.handleRemove();
                handleClose();
            } 
            
        }).catch(e => {
            if (e.graphQLErrors) {
                handleClose();
                setOpenSnack(true)
                // reduce to get message
                _.reduce(
                    e.graphQLErrors,
                    (res, err) => [...res, err.message],
                    []
                );
            }
        })
        
    }

    useEffect(() => {
        if(language.code !== code || language.libelle !== libelle){
            props.handleInputChange({
                id: language.id,
                data: {
                    libelle,
                    code
                }
            })
        }
    }, [code, libelle]);

    const selectLanguage = {
        type: 'text',
        label: 'Nom de la langue',
        helper: 'Nom de la langue',
        required: true,
        stateName: 'libelle',
        disabled: props.disabled,
        value: ''
    };
    const codeLang = {
        type: 'text',
        label: 'Code de la langue',
        helper: 'Code de la langue',
        required: true,
        stateName: 'code',
        disabled: props.disabled,
        value: ''
    };

    return(
        <Grid container direction="row" spacing={0}>
            <Grid item lg={4} sm={6} xs={12}>
                <GridFlexCenter container direction="column" justifyContent="center" spacing={0}>
                    <Grid container direction="row" spacing={1}>
                        <GridFlexCenter item xs={2}>
                            <InputLabelCustom>{ props.t('settings.langues.choice')}</InputLabelCustom>
                        </GridFlexCenter>
                        <InputBuilder xs={9} value={libelle} input={selectLanguage} stateCallback={(evt) => {
                            setLibelle(evt.target.value);
                        }}/> 
                    </Grid>
                </GridFlexCenter>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <GridFlexCenter container direction="column" justifyContent="center" spacing={0}>
                    <Grid container direction="row" spacing={1}>
                        <GridFlexCenter item xs={3}>
                            <InputLabelCustom>{ props.t('settings.langues.codelangue')}</InputLabelCustom>
                        </GridFlexCenter>
                        <InputBuilder xs={9} value={code} input={codeLang} stateCallback={(evt) => {
                            setCode(evt.target.value);
                        }} disabled={props.disabled} /> 
                    </Grid>
                </GridFlexCenter>
            </Grid>
            {
                props.handleRemove !== null ?
                    <Grid item lg={4} xs={12} style={{justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-start'}}>
                        <Button types="Delete" style={{margin: props.windowWidth < 1400 ? 0 : null}} onClick={handleClickOpen}><DeleteSharpIcon/> { props.t('settings.langues.delete')}</Button>                    
                    </Grid>
                : null
            }
            <DialogModal
                icon={true}
                type='delete'
                open={open}
                title={'Êtes-vous sûr de vouloir supprimer cette langue ?'}
                primaryAction={props.type === 'existing' ? () => {deleteLanguage(language.id)} : () => {props.handleRemove(); handleClose()}}
                secondaryAction={handleClose}
                windowWidth={props.windowWidth}
            >
                Si vous supprimez cette langue celle-ci ne sera plus accessible. Si vous ne l'utilisez plus mais que vous ne souhaitez pas la supprimer, annulez la suppression et déclarer cette langue comme inactive.
            </DialogModal>

            <Snackbar open={openSnack} autoHideDuration={10000} onClose={handleCloseSnack}>
                <div>
                    <Alert onClose={handleCloseSnack} severity={typeSnack}>
                        {textSnack}
                    </Alert>
                </div>
            </Snackbar>
        </Grid>
    );
}

const mapDispatchToProps = dispatch => {
    return { setLocales: (locales) => dispatch({ type: SET_LOCALES, payload: { locales }}) }
}

const mapStateToProps = state => {
    return { locales: state.locales };
};

export default withTranslation()(withApollo(connect(mapStateToProps, mapDispatchToProps)(CardLanguage)));