import { Box, CircularProgress, colors } from "@mui/material";
import styled from "styled-components";

const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor};
    svg circle{
        stroke: ${props => props.statuscolor};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor};
    }
`;

const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`

const CircularDataProgress = props => {
    const { count, content, color } = props
    return (
        <BoxCustom position="relative" display="flex" justifyContent={"center"} statuscolor={color} >
            <CircularProgressCustom variant="determinate" thickness={2.5} value={100} style={{ position: "absolute", left: 0, top: 0, zIndex: 2}} size={50}/>
            <CircularProgress
                variant="determinate"
                value={100}
                disableShrink
                thickness={2.5}
                size={50}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {content}
            </Box>
        </BoxCustom>
    )
}

export default CircularDataProgress