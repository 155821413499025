import {MEDIAPICKER} from '../../../../../../js/constants/form-types';
import Button from '../../../../button/Button';
import MediaPicker from '../../../../../../builder/core/components/builder/MediaSelector';

export default function mediaPickerConfig({
    onSelect = () => console.log('bug media picker'), 
    stateName, 
    xs = 3, 
    toggleMediaPickerDrawer = () => {},
    translated = false, 
    allowedTypes,
}) {
    return {
        titleForm:'Sélectionner un média',
        subTitleForm: 'Sélectionner un média',
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: 'Valider',
        formConfig: {
            type                    : MEDIAPICKER,
            component               : MediaPicker,
            onSelect,
            stateName,
            xs,
            toggleMediaPickerDrawer,
            translated,
            allowedTypes,
        }
    };
}