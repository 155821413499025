import styled from "styled-components";
import { InputFieldCustom } from "../../../styles/styled";
import colors from "../../../../../../config/theme/colors";

export const TextAreaSimpleFieldCustom = styled(InputFieldCustom)`
    background-color: white;
    .MuiOutlinedInput-root {
        fieldset {
            border: 1px solid ${colors.grey.border};
        }
        &:hover fieldset {
            border: 1px solid ${colors.grey.border};
        }
        &.Mui-focused fieldset {
            border: 1px solid ${colors.grey.border};
        }
    }

    .Mui-error .MuiOutlinedInput-notchedOutline{
        border-color: ${colors.red.regular} !important;
    }

`;