import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from "js/constants/action-types";
import TopPanel from "layouts/TopPanel/TopPanel";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { withApollo } from '@apollo/client/react/hoc';
import { filtersOptions, listMappers, listSettings, perPageOptions, viewOptions } from "./config/listProducts.config";
import colors from "colors";
import Listing from "layouts/Listing/Listing";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import FileCopySharpIcon from "@mui/icons-material/FileCopySharp";
import { useState } from "react";

const Bundles = props => {
    const [filtersOptionsValues, setFiltersOptionsValues] = useState(filtersOptions(JSON.parse(localStorage.getItem("ATTRIBUTES")).product.attributes.edges.filter(e=>e.node.isSearchable)));
    return (
        <div style={{
            position:"relatiive",
        }}>
            <TopPanel
                title="Bundles"
                subtitle="Liste de vos bundles (création / modification/ suppression)"
            />
            <Listing
                padding={16}
                // scroll={scrollListing}
                label="products"
                settings={listSettings}
                perPageOptions={perPageOptions}
                mappers={listMappers}
                typeResult={"itemsPerPage"}
                pagination={true}
                enablePagination={true}
                enableChangeView={true}
                enableChangeSort={true}
                // enableSelectionItems={true}
                enableFilters={true}
                identifier="researchProducts"
                viewsOptions={viewOptions}
                // callbackFilters={callbackFilters}
                // previousFilters={getItemsLocalStorage("PIM_FILTERS")}
                tableProps={{
                textButtonSecondary: "red",
                //   redirectDetail: goToProductDetail,
                }}
                queryVariables={{
                localeId: "/api/locales/1",
                // productBundleOptions:true
                }}
                locales={props.locales}
                // setDataCount={setDataCount}
                // reload={reloadListing}
                // setReload={setReloadListing}
                filtersOptions={filtersOptionsValues}
                // itemSelection={{
                //   set: setSelectedItems,
                //   items: selectedItems,
                // }}
                cardProps={{
                //   actionButtonDelete: handleDelete,
                //   redirectDetail: goToProductDetail,
                showPercent: true,
                percent: "completude",
                image: "image",
                title: "libelle",
                imagesNb: "imagesNb",
                imagesMaxNb: "imagesMaxNb",
                sku: "sku",
                attributes: [
                    {
                    label: "Statut",
                    value: "status",
                    type: "status",
                    },
                    {
                    label: "Variantes",
                    value: "childrenNb",
                    },
                    {
                    label: "Catalogues",
                    value: "catalogsNb",
                    },
                    {
                    label: "Catégories",
                    value: "categoriesNb",
                    },
                ],
                dottedMenuOptions: [
                    {
                    label: "Dupliquer",
                    color: colors.blue.darker.hue300,
                    isDisabled: false,
                    icon: (
                        <FileCopySharpIcon
                        style={{ fill: colors.blue.darker.hue300 }}
                        />
                    ),
                    //   action: (id) => handlerMutationDuplicate(id),
                    },
                    {
                    label: "Supprimer",
                    color: colors.red.regular,
                    icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                    modal: true,
                    modalProps: {
                        identifier: "deleteCatalog",
                        type: "delete",
                        title: "Êtes-vous sûr de vouloir supprimer ce catalogue ?",
                        subtiltle: "Cette action est irréversible",
                        actions: {
                        //   primaryAction: (id) => handleDelete(id),
                        },
                    },
                    },
                ],
                // windowWidth: props.windowWidth,
                // reload:()=>reload(),
                // openEditForm :(media) => editMedia(media)
                }}
                actions={{
                export: {
                    toolTip: "Exporter la séléction",
                    // handler: () => setOpenFormExport(!openFormExport),
                },
                delete: {
                    toolTip: "Supprimer la séléction",
                    // handler: () => setOpenDeleteModal(!openFormExport),
                },
                }}
            />
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Bundles))));