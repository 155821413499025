const guidelineCardMapper = async (flows) => {
  try {
    let results = flows.map((flow) => {
      return {
        attributeGroup: flow.node.attributeGroup,
        colors: flow.node.colors.edges.map((color) => color.node),
        documents: flow.node.documents.edges.map((document) => document.node),
        guidelineData: flow.node.guidelineData.edges.map(
          (guidelineData) => guidelineData.node
        ),
        typographies: flow.node.typographies.edges.map(
          (typography) => typography.node
        ),
        id: flow.node.id,
        identifier: flow.node.identifier,
        label: flow.node.libelle,
        guideline_url: flow.node.guideline_url ?? "test",
      };
    });
    return await results;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Mapper for GET_GUIDELINE_BY_ID route
 * @param {object} guideline
 * @returns {object}
 */
const guidelineMapper = async (guideline) => {
  try {
    let results = {
      attributeGroup: guideline.attributeGroup,
      colors: guideline.colors.edges.map((color) => color.node),
      documents: guideline.documents.edges.map((document) => document.node),
      guidelineData: guideline.guidelineData.edges.map(
        (guidelineData) => guidelineData.node
      ),
      typographies: guideline.typographies.edges.map((typography) => ({
        ...typography.node,
        typographyDatas: typography.node.typographyDatas.edges.sort((a, b) => {
          const markupIdA = a.node.markup.id.split("/").pop();
          const markupIdB = b.node.markup.id.split("/").pop();
          return markupIdA - markupIdB;
        }),
        idTypo: typography.node.id,
        id: typography.node.typographySystem.id,
        markup: typography.node.typographyDatas.edges.map((typographyData) =>
          parseInt(typographyData.node.px)
        ),
      })),
      id: guideline.id,
      identifier: guideline.identifier,
      label: guideline.libelle,
      guideline_url: guideline.guideline_url ?? "test",
    };
    return results;
  } catch (error) {
    console.log(error);
  }
};

const markupsMapper = async (markups) => {
  try {
    let results = markups.map((markup) => {
      return {
        id: markup.node.id,
        libelle: markup.node.libelle,
        value: markup.node.value,
      };
    });
    return await results;
  } catch (error) {
    console.log(error);
  }
};

export { guidelineCardMapper, guidelineMapper, markupsMapper };
