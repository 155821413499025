import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getTranslationLabel } from "@/js/utils/translation.utils.js";

import SelectInput from "@/components/ui/form/inputs/SelectInput";
import Icon from "@/components/ui/icons/Icon";
import Tooltip from "@/components/ui/tooltip/BootstrapTooltip";
import InputBuilder from "@/components/ui/form/inputs/InputBuilder";

const operators = [
  {
    value: "=",
    label: "=",
  },
  {
    value: "!=",
    label: "!=",
  },
  {
    value: "IN",
    label: "IN",
  },
  {
    value: "NOT IN",
    label: "NOT IN",
  },
  {
    value: "LIKE",
    label: "LIKE",
  },
  {
    value: "empty",
    label: "empty",
  },
];

const RowFilter = ({ row, index, onChange, onDelete, ...props }) => {
  const [inputs, setInputs] = useState(row);

  useEffect(() => {
    onChange(inputs);
  }, [inputs]);

  return (
    <Grid container spacing={1} wrap="nowrap" alignItems="center">
      <Grid item xs={5}>
        <SelectInput
          options={props.attributes?.product?.attributes.edges
            .filter((attribute) => attribute.node.internalFilter)
            .map((attribute) => ({
              value: attribute.node.id,
              label: getTranslationLabel(attribute.node.translation),
            }))}
          value={row.attribute}
          onChange={(e) =>
            setInputs((prevState) => ({
              ...prevState,
              attribute: e,
              value: "",
            }))
          }
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={2}>
        <SelectInput
          options={operators}
          value={row.operator}
          onChange={(e) =>
            setInputs((prevState) => ({
              ...prevState,
              operator: e,
            }))
          }
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={5}>
        <InputBuilder
          type={
            props.attributes?.product?.attributes?.edges?.find(
              (attribute) => attribute.node.id === row.attribute
            )?.node?.attributeType.input ?? "text"
          }
          value={row.value}
          options={props.attributes?.product?.attributes.edges
            .find((attribute) => attribute.node.id === row.attribute)
            ?.node.attributeOptions.edges.map((option) => ({
              value: option.node.id,
              label: getTranslationLabel(option.node.translation),
            }))}
          onChange={(e) =>
            setInputs((prevState) => ({
              ...prevState,
              value: e.target ? e.target.value : e,
            }))
          }
          sx={{ width: "100%" }}
        />
      </Grid>

      {onDelete && (
        <Grid item xs={12} md={1}>
          <Tooltip title="Supprimer">
            <IconButton onClick={() => onDelete(row.id ?? row.tempoId)}>
              <Icon icon="cancel" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

RowFilter.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tempoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // required if no id
    attribute: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps)(RowFilter))
);
