import React, { useEffect, useState } from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
  SET_GUIDELINE
} from "@constants/action-types";
import {
  GET_SYSTEM_TYPOS,
  GET_MARKUPS,
  UPDATE_GUIDELINE,
  EDIT_COLOR,
  ADD_COLOR,
  EDIT_TYPO,
  ADD_TYPO,
  EDIT_TYPO_DATA,
  ADD_TYPO_DATA,
  ADD_GUIDELINE_DATA,
  UPDATE_GUIDELINE_DATA,
  ADD_GUIDELINE,
} from "@queries/brand_guideline";
import slugify from "slugify";
import { Box } from "@mui/material";
import TabPanel from "@ui/TabPanel";
import colors from "@config/theme/colors";
import TopPanel from "@components/layouts/TopPanel/TopPanel";
import TextInput from "@ui/form/inputs/TextInput";
import OurTabs from "@ui/OurTabs/OurTabs";
import InputBuilder from "@ui/form/InputBuilder";
import ColorSelector from "./components/colors/ColorSelector";
import TypographySelector from "./components/typographies/TypographySelector";
import {
  DOCUMENTS,
  VIDEOS,
  IMAGES,
} from "@constants/medias-types";
import PropTypes from "prop-types";
import OurButton from "@ui/button/Button";
import {
  validateBtn
} from "@ui/button/config/themes.config";
import { MEDIAPICKER_TYPES } from "@constants/mediapickerTypes.enum";

const tabs = ["Identité", "Couleurs", "Typographies", "Documents"];

const GuidelineForm = ({
  guideline = null,
  onSuccess = () => {},
  onFail = () => {},
  ...props
}) => {
  const [form, setForm] = useState(null);
  const [errors, setErrors] = useState({});
  const [tabActive, setTabActive] = useState(props.tabActive ?? 0);
  const [typographiesSystems, setTypographiesSystems] = useState([]);
  const [markups, setMarkups] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);

  useEffect(() => {
    setTabActive(props.tabActive);
  }, [props.tabActive]);

  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      id: guideline?.id ?? null,
      libelle: guideline?.libelle ?? "",
      logo: {
        media:
          guideline?.guidelineData?.edges?.find(
            (data) => data.node?.attribute?.identifier === "guideline_logo"
          )?.node?.media ?? "",
        attribute:
          guideline?.guidelineData?.edges?.find(
            (data) => data.node?.attribute?.identifier === "guideline_logo"
          ) ?? "",
      },
      description:
        guideline?.guidelineData?.edges?.find(
          (data) => data.node?.attribute?.identifier === "guideline_description"
        )?.node?.value ?? "",
      colors: guideline?.colors?.edges ?? [],
      typographies: guideline?.typographies?.edges ?? [],
      documents: guideline?.documents?.edges?.map((doc) => doc.node) ?? [],
    }));
  }, [guideline]);

  const fetchTypoSystem = async () => {
    const res = await props.client.query({
      query: GET_SYSTEM_TYPOS,
      fetchPolicy: "no-cache",
    });

    setTypographiesSystems(res.data.typographySystems.edges);
  };

  const fetchMarkups = async () => {
    const res = await props.client.query({
      query: GET_MARKUPS,
      fetchPolicy: "no-cache",
    });
    const markups = res.data.markups.edges;

    setMarkups(markups);
  };

  useEffect(() => {
    fetchTypoSystem();
    fetchMarkups();
  }, []);

  const handleChangeDocuments = (newDoc, stateName, prevDoc) => {
    let newListDocuments = [];

    if (!newDoc) {
      //Delete
      newListDocuments = form.documents.filter((doc) => doc.id !== prevDoc.id);
    } else if (newDoc && prevDoc) {
      //Edit
      newListDocuments = form.documents.map((doc, i) => {
        if (doc.id === prevDoc.id) {
          return newDoc;
        }
        return doc;
      });
    } else if (newDoc) {
      //Add
      newListDocuments = [...form.documents, newDoc];
    }

    setForm((prevState) => ({
      ...prevState,
      documents: newListDocuments,
    }));
  };

  const handleChangeLogo = (media) => {
    setForm((prevState) => ({
      ...prevState,
      logo: {
        ...prevState.logo,
        media,
      },
    }));
  };

  const validateForm = () => {
    setErrors({});
    return new Promise((resolve, reject) => {
      if (!form.libelle) {
        setErrors((prevState) => ({
          ...prevState,
          libelle: "Le nom de la marque est obligatoire",
        }));
        resolve(false);
      }
      resolve(true);
    });
  };

  const handleSubmit = async () => {
    const isValid = await validateForm();
    if (!isValid) return;

    props.startLoading();

    let variables = {
      libelle: form.libelle,
      documents: form.documents.map((doc) => doc.id),
      attributeGroup: props.attributeGroups.find(
        (group) => group.node.identifier === "guideline"
      ).node.id,
      identifier: slugify(form.libelle, {
        replacement: "_",
        lower: true,
        remove: /[^\w\-\s]+/g,
      }),
      status: true,
    };

    if (guideline) variables.id = guideline.id;

    props.client
      .mutate({
        mutation: guideline?.id ? UPDATE_GUIDELINE : ADD_GUIDELINE,
        variables,
      })
      .then(async (result) => {
        const guidelineId =
          guideline?.id ?? result.data.createGuideline.guideline.id;
        await saveColors(guidelineId).catch((err) => {
          props.snack(
            "error",
            "Une erreur est survenue lors de l'enregistrement des couleurs"
          );
        });
        await saveTypographies(guidelineId).catch((err) => {
          props.snack(
            "error",
            "Une erreur est survenue lors de l'enregistrement des typographies"
          );
        });
        await saveLogo(guidelineId).catch((err) => {
          props.snack(
            "error",
            "Une erreur est survenue lors de l'enregistrement du logo"
          );
        });
        await saveDescription(guidelineId).catch((err) => {
          props.snack(
            "error",
            "Une erreur est survenue lors de l'enregistrement de la description"
          );
        });
      })
      .then(() => {
        props.stopLoading();
        props.snack(
          "success",
          "La charte graphique a bien été " +
            (guideline?.id ? "modifiée" : "ajoutée")
        );
        onSuccess();
      })
      .catch((err) => {
        props.stopLoading();
        props.snack("error", "Une erreur est survenue");
        onFail();
      });
  };

  const saveColors = (guidelineId) => {
    return new Promise(async (resolve, reject) => {
      const colorsFiltered = form.colors.filter((color) => color.changed);
      for (let color of colorsFiltered) {
        let variables = {
          codeHexa: color.hex,
          codeR: color.rgb.r,
          codeG: color.rgb.g,
          codeB: color.rgb.b,
          guideline: guidelineId,
          markups: color.markups?.map((markup) => markup.id),
        };

        if (color.id) variables.id = color.id;

        props.client.mutate({
          mutation: color.id ? EDIT_COLOR : ADD_COLOR,
          variables,
        });
      }
      resolve();
    });
  };

  const saveLogo = (guidelineId) => {
    return new Promise(async (resolve, reject) => {
      const guidelineData = guideline?.guidelineData.edges.find(
        (data) => data.node.attribute.identifier === "guideline_logo"
      );
      const attribute = props.attributeGroups
        .find((group) => group.node.identifier === "guideline")
        .node.attributes.edges.find(
          (attr) => attr.node.identifier === "guideline_logo"
        ).node;
      let variables = {
        guideline: guidelineId,
        attribute: attribute.id,
        locale: props.locales[0].node.id,
        media: form.logo.media.id,
      };

      if (guidelineData) {
        variables.id = guidelineData.node.id;
      }

      props.client
        .mutate({
          mutation: guidelineData ? UPDATE_GUIDELINE_DATA : ADD_GUIDELINE_DATA,
          variables,
        })
        .then((result) => {
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  };

  const saveDescription = (guidelineId) => {
    return new Promise(async (resolve, reject) => {
      const guidelineData = guideline?.guidelineData.edges.find(
        (data) => data.node.attribute.identifier === "guideline_description"
      );
      const attribute = props.attributeGroups
        .find((group) => group.node.identifier === "guideline")
        .node.attributes.edges.find(
          (attr) => attr.node.identifier === "guideline_description"
        ).node;

      let variables = {
        guideline: guidelineId,
        attribute: attribute.id,
        locale: props.locales[0].node.id,
        value: form.description,
      };

      if (guidelineData) {
        variables.id = guidelineData.node.id;
      }

      props.client
        .mutate({
          mutation: guidelineData ? UPDATE_GUIDELINE_DATA : ADD_GUIDELINE_DATA,
          variables,
        })
        .then((result) => {
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  };

  /**
   * Modifie la police de la typographie
   */
  const saveTypographies = async (guidelineId) => {
    const typographiesFiltered = form.typographies.filter(
      (typo) => typo.changed
    );

    for (let typo of typographiesFiltered) {
      let mutationType = null;
      let variables = {
        identifier: typo.identifier,
        guideline: guidelineId,
        typographySystem: typo.typographySystem.id,
      };

      if (typo.id) {
        mutationType = "EDIT";
        variables.id = typo.id;
      } else if (typo.tempoId) {
        const identifierTypo = `${typo.identifier}_${slugify(form.libelle, {
          replacement: "_",
          lower: true,
          remove: /[^\w\-\s]+/g,
        })}`;
        mutationType = "ADD";
        variables.identifier = identifierTypo;
      }

      props.client
        .mutate({
          mutation: mutationType === "EDIT" ? EDIT_TYPO : ADD_TYPO,
          variables,
        })
        .then((result) => {
          const typographyId =
            mutationType === "EDIT"
              ? result.data.updateTypography?.typography?.id
              : result.data.createTypography?.typography?.id;

          saveTypographiesData(typographyId, typo, mutationType);
        });
    }
  };

  /**
   * Modifie les données pour chaque markup de la typographie
   */
  const saveTypographiesData = async (
    typographyId,
    typography,
    mutationType
  ) => {
    for (let markup of markups) {
      const typographyMarkup = typography.typographyDatas.find(
        (typographyData) => typographyData.markup.id === markup.node.id
      );

      let variables = {
        typography: typographyId,
        markup: markup.node.id,
        px: typographyMarkup.px,
      };

      if (mutationType === "ADD") {
        props.client.mutate({
          mutation: ADD_TYPO_DATA,
          variables,
        });
      } else {
        const typographyData = typography.typographyDatas.find(
          (typographyData) => typographyData.markup.id === markup.node.id
        );
        variables.id = typographyData.id;

        props.client.mutate({
          mutation: EDIT_TYPO_DATA,
          variables,
        });
      }
    }
  };

  return (
    form && (
      <Box sx={{ width: "100%" }}>
        <TopPanel
          inForm={true}
          windowWidth={props.windowWidth}
          title={
            guideline
              ? "Modifier la charte graphique"
              : "Ajouter une charte graphique"
          }
          subtitle={
            "Veuillez compléter les champs ci-dessous pour configurer votre image de marque"
          }
        />

        <OurTabs
          tabs={tabs}
          tabActive={tabActive}
          handleGetCurrentTab={(tab) => setTabActive(tab)}
        >
          <TabPanel value={tabActive} index={0}>
            <Box
              sx={{
                backgroundColor: colors.background.white.primary,
                padding: "32px",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <InputBuilder
                input={{
                  type: "mediaPicker",
                  label: "Logotype",
                  translated: false,
                  fileType: MEDIAPICKER_TYPES.IMAGE,
                  helper: {
                    text: "",
                    link: false,
                  },
                  required: true,
                  stateName: "logo",
                  handleMediaPicker: handleChangeLogo,
                }}
                value={form.logo.media}
              />
              <TextInput
                label="Nom de la marque"
                value={form.libelle}
                placeholder="Nom de la marque"
                required={true}
                helper="Indiquer le nom de la marque ici"
                sx={{ width: "100%" }}
                error={errors.libelle}
                showError={errors.libelle}
                onChange={(e) =>
                  setForm((prevState) => ({
                    ...prevState,
                    libelle: e.target.value,
                  }))
                }
              />
              <InputBuilder
                input={{
                  type: "textarea",
                  label: "Description",
                  isContent: false,
                  translated: false,
                  translation: true,
                  checkSpelling: true,
                  helper: {
                    text: "Tapez votre description ici",
                    link: false,
                  },
                  required: false,
                  stateName: "description",
                  modules: {
                    toolbar: [["bold", "italic", "underline", "strike"]],
                  },
                }}
                value={form.description}
                stateCallback={(e, stateName) =>
                  setForm({ ...form, description: e })
                }
              />
            </Box>
          </TabPanel>
          <TabPanel value={tabActive} index={1}>
            <ColorSelector
              colors={form.colors.map((color) =>
                color.node?.id && !color.node?.changed
                  ? {
                      id: color.node.id,
                      hex: color.node.codeHexa,
                      rgb: {
                        r: color.node.codeR,
                        g: color.node.codeG,
                        b: color.node.codeB,
                        a: 100,
                      },
                      markups: color.node.markups.edges.map(
                        (markup) => markup.node ?? markup
                      ),
                    }
                  : color
              )}
              markups={markups.map((markup) => markup.node ?? markup)}
              onChange={(e) =>
                setForm((prevState) => ({ ...prevState, colors: e }))
              }
            />
          </TabPanel>
          <TabPanel value={tabActive} index={2}>
            <TypographySelector
              listTypographies={form.typographies.map((typography) =>
                typography.node?.id
                  ? {
                      id: typography.node?.id,
                      identifier: typography.node?.identifier,
                      typographyDatas:
                        typography.node?.typographyDatas.edges.map(
                          (typographyData) => ({
                            id: typographyData.node?.id,
                            px: typographyData.node?.px,
                            markup: typographyData.node?.markup,
                          })
                        ),
                      typographyMarkups:
                        typography.node?.typographyDatas.edges.map(
                          (typographyData) => typographyData.node?.markup
                        ),
                      typographySystem: typography.node?.typographySystem,
                    }
                  : typography
              )}
              listMarkups={markups.map((markup) => markup.node)}
              listTypographiesSystems={typographiesSystems.map(
                (typographySystem) => typographySystem.node
              )}
              maxLength={4}
              onChange={(newTypographies) => {
                setForm((prevState) => ({
                  ...prevState,
                  typographies: newTypographies,
                }));
              }}
            />
          </TabPanel>
          <TabPanel value={tabActive} index={3}>
            <Box
              sx={{
                backgroundColor: colors.background.white.primary,
                padding: "32px",
              }}
            >
              {form.documents.map((doc, index) => {
                return (
                  <InputBuilder
                    key={index}
                    input={{
                      type: "mediaPicker",
                      fileType: MEDIAPICKER_TYPES.FILE,
                      allowedTypes: [...IMAGES, ...VIDEOS, ...DOCUMENTS],
                      allowedMedia: [...IMAGES, ...VIDEOS, ...DOCUMENTS],
                      label: "Importez votre fichier",
                      translated: false,
                      helper: {
                        text: "",
                        link: false,
                      },
                      required: false,
                      stateName: "listDocument",
                      handleMediaPicker: (e, stateName) =>
                        handleChangeDocuments(e, stateName, doc),
                    }}
                    value={doc}
                  />
                );
              })}
              <InputBuilder
                input={{
                  type: "mediaPicker",
                  fileType: MEDIAPICKER_TYPES.FILE,
                  allowedTypes: [...IMAGES, ...VIDEOS, ...DOCUMENTS],
                  allowedMedia: [...IMAGES, ...VIDEOS, ...DOCUMENTS],
                  label: "Importez votre fichier",
                  translated: false,
                  helper: {
                    text: "",
                    link: false,
                  },
                  required: false,
                  stateName: "listDocument",
                  handleMediaPicker: (e, stateName) =>
                    handleChangeDocuments(e, stateName),
                }}
                value={""}
              />
            </Box>
          </TabPanel>
        </OurTabs>
        <Box
          pt={3}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          {/* <OurButton
            deleteIcon={true}
            text={"Supprimer la charte graphique"}
            {...deleteBtn.primary}
          /> */}
          <OurButton
            {...validateBtn.primary}
            onClick={handleSubmit}
            text={"Valider la modification"}
          />
        </Box>
      </Box>
    )
  );
};

GuidelineForm.propTypes = {
  guideline: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  tabActive: PropTypes.number,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    users: state.users,
    locales: state.locales,
    attributeGroups: state.attributeGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGuideline: (guideline) =>
      dispatch({ type: SET_GUIDELINE, payload: guideline }),
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(GuidelineForm)
);
