import DropdownTreeSelect from "react-dropdown-tree-select";
import styled from "styled-components";

export const DropdownTreeSelectCustom = styled(DropdownTreeSelect)`
  font-weight: bold;
  background-color: white;

  & .node {
    height: 35px;
  }

  & .node-label {
    font-weight: 400;
  }
`