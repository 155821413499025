import * as React from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";

const CircularProgressWithLabel = ({
  label = "",
  textColor = "",
  children,
  ...props
}) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        {label && (
          <Typography variant="caption" component="div" color={textColor}>
            {`${Math.round(label)}%`}
          </Typography>
        )}
        {children && <Box>{children}</Box>}
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  label: PropTypes.string,
  textColor: PropTypes.string,
};

export default CircularProgressWithLabel;
