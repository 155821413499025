import { useState } from "react";
import styled from "styled-components";
import { Box, Grid } from "@mui/material";
import Typography from "../../../ui/typography/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import GetAppSharpIcon from "@mui/icons-material/GetAppSharp";
import { makeStyles } from "@mui/styles";
import colors from "../../../../config/theme/colors";
import pngBackground from "../../../../assets/images/medias/pixelBackground.jpg";
import pdfPlaceholder from "../../../../assets/pictos/file-types/picto_pdf.png";
import DialogModal from "../../../ui/dialog/DialogModal";
import MediaModal from "../../../screens/dashboard/medias/components/MediaModal";
import {
  ALERT_ERROR,
  ALERT_SUCCESS,
} from "../../../../js/constants/alert-types";
import {
  START_LOADING,
  STOP_LOADING,
  SNACK,
} from "../../../../js/constants/action-types";
import { getFileConfig } from "../../../../js/helpers/files";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { connect } from "react-redux";
import OurMenu from "../../../ui/menu/Menu";
import { DELETE_MEDIA } from "../../../../queries/medias";
import { withApollo } from "@apollo/client/react/hoc";
import { downloadFile } from "../../../../../src/js/utils/downloadFile";

const BigImage = styled(Box)`
  cursor: pointer;
  height: ${(props) => (props.windowWidth > 1500 ? "380px" : "230px")};
  position: relative !important;
  .bigImageContainer {
    overflow: hidden;
    text-align: center;
    height: 70%;
    width: 100%;
    position: relative;
    /* background-size:100%;
        background-position:center;
        background-repeat:no-repeat; 
        background-image: url(${(props) => props.imagebackground}), url(${(
      props
    ) => (props.typeimage === "PNG" ? pngBackground : "")}); */

    img {
      max-width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .bigImageInfo {
    margin-top: 10px;
    width: 100%;
    border-top: none;
    font-style: italic;
    height: 30%;
  }
  .editicon {
    z-index: 99;
    @media screen and (max-width: 850px) {
      z-index: 99;
    }
  }
  &:hover {
    .bigImageInfo {
      background-size: 100% 100%;
    }
    .editicon {
      display: flex;
    }
  }
`;
const Title = styled(Typography)`
  margin-top: ${(props) => (props.windowWidth > 1500 ? "23px" : "0px")};
  transition: color 0.5s;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(props) => (props.windowWidth > 1500 ? "20px" : "13px")};
  text-align: left;
`;

const useStyles = makeStyles((theme) => ({
  editIcon: {
    position: "absolute",
    color: colors.black.regular,
    // display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    fontSize: 25,
    top: -10,
    right: 10,
    transition: "all .5s",
    display: "none",
  },
  menu: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  paper: {
    border: "1px solid #d3d4d5",
  },
}));

function getMediaType(type) {
  var res = type
    ?.match(/\.[0-9a-z]+$/i)?.[0]
    .replaceAll(".", "")
    .toUpperCase();
  return res;
}

function CardMedia({ onDelete, ...props }) {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [toDelete, setToDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadConfirmation, setDownloadConfirmation] = useState(false);
  const open = Boolean(anchorEl);

  // PDF TO IMAGE
  const [pdfImage, setPdfImage] = useState(null);
  const [pdfImageLoading, setPdfImageLoading] = useState(false);

  const classes = useStyles();

  let media = props.data;

  if (media?.node) {
    media = media?.node;
  }
  let type = {};
  type = {
    value: getMediaType(media?.filePath),
    style: {
      background: getFileConfig(media?.type).color,
      color: "white",
      fontWeight: "bold",
    },
  };
  // if(['video/mpeg','video/webm','video/mp4'].includes(media.type)){
  //     isVideo=true;
  // }

  //#region MODALS
  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleMediaModal = (media, index, isNav = false) => {
    let setupModalData = {
      ...media,
      index: index,
    };

    if (!isNav) {
      setOpenModal(!openModal);
    }
    setModalData(setupModalData);
  };

  // handleNextMedia = (step,index) => {
  //     let next;

  //     switch(step){
  //         case 'next':
  //             next = index +1;
  //             break;

  //         case 'prev':
  //             next = index-1;
  //             break;

  //         default :
  //             next = next;
  //             break;
  //     }

  //     let media = this.state.medias[next];
  //     this.handleMediaModal(media,next,true);
  // }

  const handleDeleteModal = (id) => {
    setToDelete(id);
    setOpenDeleteModal(!openDeleteModal);
  };

  const closeAllModal = () => {
    setOpenModal(false);
    setOpenDeleteModal(false);
  };
  //#endregion

  const deleteMedia = async (action) => {
    switch (action) {
      case "delete":
        props.startLoading();
        props.client
          .mutate({
            mutation: DELETE_MEDIA,
            variables: {
              id: toDelete,
            },
          })
          .then((res) => {
            if (onDelete) onDelete();
            if (props.reload) props.reload();
            props.stopLoading();
            props.snack(ALERT_SUCCESS, "Média supprimé");
            closeAllModal();
          })
          .catch((err) => {
            console.log(err)
            props.stopLoading();
            closeAllModal();
            props.snack(ALERT_ERROR, "Erreur lors de la suppression du média, il est probablement utilisé ailleurs sur la plateforme");
          });
        break;
      case "cancel":
        setOpenDeleteModal(!openDeleteModal);
      default:
        return null;
    }
    setToDelete(null);
  };

  const getFileSize = (size) => {
    const ko = size / 1024;
    const mo = ko / 1024;
    return mo < 1 ? ko.toFixed(0) + " ko" : mo.toFixed(0) + " mo";
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const getActionButtons = () => {
    if (props.actionButtons) {
      const additionalButtons = props.actionButtons.map(
        (actionBtn) => actionBtn.label
      );

      const actionButtons = defaultActionButtons.map((actionBtn) => {
        if (additionalButtons.includes(actionBtn.label)) {
          return props.actionButtons.find(
            (action) => action.label === actionBtn.label
          );
        }
        return actionBtn;
      });

      return actionButtons;
    }

    return defaultActionButtons;
  };

  const defaultActionButtons = [
    {
      label: "Modifier",
      color: colors.blue.darker.hue300,
      icon: <EditIcon style={{ fill: colors.black.regular }} />,
      action: (e, media) => {
        props.openEditForm(media);
        handleClose(e);
      },
    },
    {
      label: "Télécharger",
      color: colors.blue.darker.hue300,
      icon: <GetAppSharpIcon style={{ fill: colors.black.regular }} />,
      action: (e, media) => {
        downloadFile(
          `${process.env.REACT_APP_API_ROOT}/medias/${media?.filePath}`,
          media?.name || media?.filePath
        );
        handleClose(e);
      },
    },
    {
      label: "Supprimer",
      color: colors.red.regular,
      icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
      action: (e, media) => {
        handleDeleteModal(media.id);
        handleClose(e);
      },
    },
  ];

  return (
    <Box
      style={{
        padding: "16px",
        paddingBottom: "0px",
      }}
    >
      <BigImage
        onClick={() => handleMediaModal(media, props.currentIndex)}
        typeimage={type.value}
        windowWidth={props.windowWidth}
        style={{
          position: "relative",
        }}
        // imagebackground={isVideo==true ? videoLogo : process.env.REACT_APP_API_ROOT+'/medias/'+media.filePath }
      >
        <Box>
          <OurMenu
            menuOptions={getActionButtons()}
            handleClick={(e, item) => item.action(e, media)}
            hasSquare={true}
          />
        </Box>
        {/* Image */}
        <Box className="bigImageContainer">
          {media?.type === "video/mp4" || media?.type === "video/webm" ? (
            <>
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              >
                <source
                  src={`${process.env.REACT_APP_API_ROOT}/medias/${media.filePath}#t=1`}
                  type={media?.type}
                />
              </video>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PlayCircleOutlineIcon style={{ fill: "#FFF", fontSize: 50 }} />
              </div>
            </>
          ) : //pdf
          media?.type === "application/pdf" ? (
            <img src={pdfPlaceholder} style={{ height: "100%" }} />
          ) : (
            <img
              src={
                getFileConfig(media?.type, false).image
                  ? getFileConfig(media?.type, false).image
                  : process.env.REACT_APP_API_ROOT +
                    "/medias/" +
                    media?.filePath
              }
              style={{ height: getFileConfig(media?.type).image ? "100%" : "" }}
            />
          )}
        </Box>
        {/* Title */}
        <Box className="bigImageInfo" style={{ fontStyle: "normal" }}>
          <Title
            windowWidth={props.windowWidth}
            variant="h3"
            title={media?.name ? media?.name : media?.filePath}
          >
            {media?.name ? media?.name : media?.filePath}
          </Title>
          {/* Type */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              marginTop: props.windowWidth > 1500 ? "10px" : "2px",
              fontSize: "12px",
            }}
          >
            <Grid item>
              <Grid container alignItems="center" style={{ gap: "2px" }}>
                <Grid item style={{ marginRight: "5px" }}>
                  <img
                    src={getFileConfig(media?.type, true).icon}
                    alt="test"
                    style={{
                      width: "20px",
                      marginTop: "7px",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: props.windowWidth > 1500 ? "17px" : "13px",
                      color: colors.grey.regular,
                      fontWeight: "200",
                    }}
                  >
                    Type de fichier: .{type.value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {media?.size ? (
              <Grid
                item
                style={{
                  color: colors.grey.regular,
                  fontWeight: "200",
                  fontSize: props.windowWidth > 1500 ? "17px" : "13px",
                }}
              >
                {getFileSize(media?.size)}
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </BigImage>

      {/* Info modal */}
      {modalData ? (
        <MediaModal
          open={openModal}
          onClose={handleCloseModal}
          modalData={modalData}
          hasData={true}
          // handleNav           = {handleNextMedia}
          // lastMedia           = {this.state.medias?.length-1}
          actions={{
            delete: () => {
              handleDeleteModal(modalData.id);
              handleCloseModal();
            },
            download: () => {
              window.open(
                `${process.env.REACT_APP_API_ROOT}/medias/${modalData?.filePath}`
              );
              handleCloseModal();
            },
          }}
          readOnly={props.readOnly}
          windowWidth={props.windowWidth}
        />
      ) : null}

      {/* Delete modal */}
      <DialogModal
        icon={true}
        type="delete"
        open={openDeleteModal}
        title={"Êtes-vous sûr de vouloir supprimer ce média ?"}
        primaryAction={() => {
          deleteMedia("delete");
        }}
        secondaryAction={() => {
          deleteMedia("cancel");
        }}
        windowWidth={props.windowWidth}
      >
        <Typography variant="body2">
          Êtes-vous sûr de vouloir supprimer ce média ?{" "}
          <strong>Cette action est irréversible</strong>
        </Typography>
      </DialogModal>
    </Box>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(CardMedia)
);
