import { FormatStrikethroughOutlined } from '@mui/icons-material';
import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import SelectCompanies from '../components/SelectCompanies'

export default function contactAdd(currentLang,customAttributes,handleMediaPicker,handleSelectCompany,currentCompanies,formSettings, edit, goTo) {
    let typeInput = '';
    let attributeSetup = attribute => {

        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = 'textarea';
                break;
            case 'number': // create an input
            case 'decimal': // create an input
            case 'date' : 
                typeInput = 'date';
                break;        
            case 'text':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case 'image':
                typeInput = 'mediaPicker';
                break;
            case 'file':
                typeInput = 'file';
                break;
            default: typeInput = 'text';
        }

        return ({
            type: typeInput,
            label: langSelected?.node.value ?? defaultLang.node.value,
            handleMediaPicker:handleMediaPicker,
            translated: true,
            helper: {
                text: '',
                link: false,
            },
            isSystem: true,
            currentLang,
            required: attribute.node.isRequired,
            stateName: attribute.node.identifier,
            value: attribute.node.attributeOptions.edges.map((values) => {
                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );

                return ({
                    value: values.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault.node.value
                });
            })
        });
    };
    let systemInputs    = customAttributes.map(attributeSetup);

    let generalInputs=[
        {
            type : 'select',
            label: 'Statut',
            stateName : 'customerStatus',
            translated: false,
            helper: {
                text: '',
                link: false,
            },
            value: [
                { value: 'contact', label: 'Contact' },
                { value: 'lead', label: 'Lead' },
                { value: 'client', label: 'Client' },
            ]
        },
        {
            type : 'text',
            label: 'Email',
            stateName : 'customerEmail',
            translated: false,
            helper: {
                text: '',
                link: false,
            },
        }
    ]
    
    generalInputs =  generalInputs.concat(systemInputs);
    
    var form = {
        titleForm: edit ? 'Modifier le contact' : 'Créer un contact',
        subTitleForm: edit ? 'Veuillez compléter ou éditer les champs ci-dessous pour modifier votre contact' : 'Veuillez compléter les champs ci-dessous pour créer un contact',
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: edit ? 'Valider la modification de ce contact' : 'Valider la création de ce contact',
                textButton: edit ? 'Modifier' : 'Créer'
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs 
                    
                },
                {
                    labelName: 'Sociétés',
                    isOptionnal: false,
                    component : SelectCompanies,
                    componentProps :{
                        currentLang :currentLang,
                        handleSelectCompany : handleSelectCompany,
                        currentCompanies : currentCompanies,
                        handleSearch : formSettings.handleSearch,
                        queryVariables : formSettings.queryVariables,
                        goTo : goTo
                    }
                    
                },
            ]
        }
    }
    
    return form;
}