import { TextField } from "@mui/material";
import styled from "styled-components";
import colors from "../../../../../../config/theme/colors";
import { InputFieldCustom } from "../../../styles/styled";
import { Typography } from "@mui/material";
import WarningIcon from "../../../../../../assets/pictos/warning-icon.svg"

export const TextFieldCustom = styled(InputFieldCustom)`
  input {
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 0;
    font-weight: bold;
    height: 45px;
    @media screen and (max-width: 1450px) {
      font-size: 14px;
      padding: 14px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 13px;
      padding: 13px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 13px;
      padding: 13px;
    }
  }
  & > p {
    margin: 0;
  }
  fieldset {
    border: 1px solid ${colors.grey.border} !important;
  }
  .MuiOutlinedInput-root {
        gap: 14px;

        & > svg {
            color: ${colors.grey.regular};
        }
    &:hover fieldset {
      border: 1px solid ${colors.grey.border};
    }
    &.Mui-focused fieldset {
      border: 1px solid ${colors.grey.border};
    }
  }
  .MuiInputBase-root {
    background-color: ${(props) =>
      props.custom?.backgroundColor ?? colors.white};
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.red.regular} !important;
    background-color: ${colors.red.lighterv2} !important;

    &::after {
      content: '';
      background-image: url(${WarningIcon}) !important;
      background-repeat: no-repeat  !important;
      background-position: center  !important;
      background-size: 20px  !important;
      width: 20px  !important;
      height: 20px  !important;
      position: absolute  !important;
      top: 46%  !important;
      right: 10px  !important;
      transform: translateY(-50%)  !important;
    }
  }
`;

export const HelperText = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: soleil, sans-serif;
  font-weight: 400;
  line-height: 1.66;
`;

export const SpanAddCustom = styled.span`
  color: ${colors.green.regular};
  text-decoration: underline;
  font-weight: bold;
`;