import React from "react";
import styled from "styled-components";
import PageLoader from "@ui/loadings/page-loader/PageLoader";
import GridView from "./components/GridView/GridView";
import TableView from "./components/TableView/TableView";
import { itemMapper } from "../../../js/mappers/mapper";
import { CursorPagination } from "@utils/pagination";
import * as listingHelper from "@utils/listing";
import { getElements } from "@utils/functions";
import NoResult from "./components/NoResult";
import TableViewSimple from "./components/TableView/TableViewSimple";
import CarouselListing from "../Carousel/CarouselListing";
import { withTranslation } from "react-i18next";
import OurButton from "@ui/button/Button";
import Filters from "@ui/Filters/Filters";
import Grid from "@mui/material/Grid";
import { FormInputTypes } from "../../../builder/shareable/types";
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from "@constants/action-types";
import ChipMedias from "@screens/dashboard/medias/components/ChipMedias";
import { LIST_MEDIAS } from "@constants/medias-types";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { withApollo } from "@apollo/client/react/hoc";

const ListingContainer = styled(Grid)`
  height: ${(props) => props.height ?? "100%"};
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: 10px;
  overflow: hidden;
  padding: ${(props) => props.padding ?? 0}px !important;
`;

const ButtonSort = styled(OurButton)``;

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

class Listing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mappingReady: false,
            items: null,
            pagination: this.props.pagination ?
                {
                    page: 1,
                    perPage: this.props.perPageOptions[this.props.viewsOptions.current][0],                    
                }
                : false,
            views: this.props.viewsOptions,
            sortBy: [{filter : "asc", orderBy : "asc"},{filter : "desc", orderBy : "desc"}],
            currentSort: {filter : "desc", orderBy : "desc"},
            noResult: false,
            filters:{
              dynamicFilterName : "product_name",
            },
            dynamicFilters:[],
            activeFilter:false,
            activeChip: true,
            selectionItemsMode:false,
            dynamicFilterName : "sku",
            filtersOptions : this.props.filtersOptions,
            selectionItemsMode:false,
            additionalFilterValue:props.history.location.state?.types
             ? props.history.location.state.types 
             : LIST_MEDIAS,
        }
        this.ref=React.createRef(null)
    }
    
  handleChangeFilters = (evt, item, type, input) => {
    const value = evt?.target?.value ?? evt;
    const stateName = item.stateName;
    const filters = {
      ...this.state.filters,
    };
    let dynamicFilters = [
      ...this.state.dynamicFilters,
    ];
    filters[stateName] = value === "all" ? null : value;
    if (stateName === "dynamicFiltersName") {
      this.addDynamicFilter(value);
      this.setState({ dynamicFilterName: value, filters });
    }
    else if (input.dynamicFilter && stateName !== "sku") {
      let array = filters["productDatas"] ?? [];
      array = array.map(e=>JSON.parse(e))
      let exist = array.find(e=>e.identifier === stateName)
      if (exist) {
        array = array.filter(e=>e.identifier !== stateName)
      }
      array.push({
        identifier : stateName,
        value : input.type === "select" ? null : value,
        label:input.type === "select" ? input.value.find(e=>e.value === value).label : value,
        option:input.type === "select" ? value : null,
      })
      dynamicFilters = array;
      array = array.map(e=>JSON.stringify(e))
      filters["productDatas"] = array;
      if (type === "select") {
        this.setState(
          {
            filters,
            dynamicFilters,
            activeFilter: true,
          },
          () => {
            this.handleGetItems();
          }
        );  
      } else {
        this.setState({ filters });
      }
    }
    else if (type === "select") {
      this.setState(
        {
          filters,
          activeFilter: true,
        },
        () => {
          this.handleGetItems();
        }
      );
    } else {
      this.setState({ filters });
    }

    // Auto-Debounce input filter to not spam requests
    clearTimeout(this.filterTimeout); 
    let array = filters["productDatas"] ?? [];
    array = array.map(e => JSON.parse(e));

    this.filterTimeout = setTimeout(() => {
      const inputValue = evt.target.value;
  
      if (inputValue.length > 0) {
        this.setState(
          {
            activeFilter: true,
            dynamicFilters,
          },
          () => {
            this.handleGetItems();
          }
        );
      } else {
        this.setState(
          {
            activeFilter: false,
            dynamicFilters,
          },
          () => {
            this.handleGetItems();
          }
        );
      }
    }, 500);
  };

  handleSelectionMode = (value) => {
    this.setState({
      selectionItemsMode: value,
    });
  };

  handleSelectionItems = (value) => {
    if (value === "all") {
      this.props.itemSelection.set(this.state.listItems.map((e) => e.node.id));
    }
    if (value === "none") {
      this.props.itemSelection.set([]);
    }
  };

  handleSelectionItem = (value, id) => {
    if (value) {
      this.props.itemSelection.set([...this.props.itemSelection.items, id]);
    } else {
      let newArray = [...this.props.itemSelection.items].filter(
        (e) => e !== id
      );
      this.props.itemSelection.set(newArray);
    }
  };

  handleRemoveDynamicFilter = (value, id) => {
    const filters = this.state.filters;
    let newDynamics = this.state.dynamicFilters.filter(e=>e.identifier !== value.identifier);
    let array = filters["productDatas"] ?? [];
    array = array.map(e=>JSON.parse(e));
    array = array.filter(e=>e.identifier !== value.identifier);
    array = array.map(e=>JSON.stringify(e));
    filters["productDatas"] = array;
    delete filters[value.identifier];
    this.setState({ filters,dynamicFilters:newDynamics },() => {
      this.handleGetItems();
    });
  };

  mapItems = async (items) => {
    this.setState({ mappingReady: false });
    let result = await itemMapper(
      this.props.mappers,
      items,
      this.props.currentLang
    );
    if (this.props.label === "marques") {
      result.card = result.card.filter((e) => e.libelle !== "GoogleShopping");
    }
    this.setState({ items: result });
  };

  async handleGetItems() {
    this.setState({ ready: false });
    let variables;

    if (this.state.activeFilter) {
      variables = {
        ...this.props.queryVariables,
        order: this.state.currentSort.orderBy,
        ...this.state.filters,
      };
    } else {
      variables = {
        ...this.props.queryVariables,
        order: this.state.currentSort.orderBy,
      };
    }
    if (this.props.additionalFilters){
      variables[this.props.additionalFilters.filterName] = this.state.additionalFilterValue
    }
    variables = await listingHelper.initQuery(
      this.state,
      variables,
      "queryData",
      this.props.typeResult
    );
    let queryStates = await listingHelper.initQuery(
      this.state,
      variables,
      "states",
      this.props.typeResult
    );

    this.setState({ ...queryStates });

    let result = await getElements(this.props.identifier, variables);
    let handleResults = await listingHelper.handleResult(
      result.data[this.props.identifier],
      "listItems",
      this.state,
      {},
      this.props.typeResult,
      this.props.identifier
    );
    await this.mapItems(handleResults.listItems);

    this.setState({
      ...handleResults,
    });

    if (this.props.setDataCount && handleResults?.totalCount){
      this.props.setDataCount(handleResults.totalCount);
    } else if (this.props.setDataCount && !handleResults?.totalCount) {
      this.props.setDataCount(0);
    }

    if (this.props.listingCallback)
      this.props.listingCallback(handleResults.listItems);
  }

  changeViews = async (mode) => {
    let views = await listingHelper.changeViews(this.state, mode);
    this.setState(
      {
        views,
      },
      () => {
        if (
          this.props.perPageOptions[mode][0] !== this.state.pagination.perPage
        ) {
          this.changePerPage(this.props.perPageOptions[mode][0]);
        }
      }
    );
  };

  changePage = async (newPage) => {
    let pagination = await listingHelper.pagination.changePage(
      this.state.pagination,
      newPage,
      this.props.typeResult
    );
    this.setState(
      {
        pagination,
      },
      () => this.handleGetItems()
    );
  };

  changePerPage = async (perPage) => {
    let reload = null;
    if (this.props.typeResult === "collection") {
      reload = this.state.pagination.itemsPerPage !== perPage;
    } else {
      reload = this.state.pagination.perPage !== perPage;
    }
    if (reload) {
      let pagination = await listingHelper.pagination.updatePerPage(
        this.state.pagination,
        perPage
      );
      this.setState(
        {
          pagination,
        },
        () => this.handleGetItems()
      );
    }
  };

  handleSort = async (sortBy) => {
    let newSortBy = await listingHelper.sortBy.handleSort(
      this.state.sortBy,
      sortBy
    );
    let pagination = await listingHelper.pagination.resetPagination(
      this.state.pagination.perPage
    );
    this.setState(
      {
        currentSort: newSortBy,
        pagination,
      },
      () => this.handleGetItems()
    );
  };

  resetPagination = async () => {
    let pagination = await listingHelper.pagination.resetPagination(
      this.state.pagination.perPage
    );
    this.setState({ pagination, noResult: false }, () => this.handleGetItems());
    if (this.props?.setReload) {
      this.props.enablePagination && this.props.setReload(false);
    }
  };

  componentDidMount() {
    this.handleGetItems();
  }

  filterByType = (cat) => {
    let filter;
    if (cat === 'all') {
        filter = LIST_MEDIAS;
    }
    else {
        filter = cat;
    }
    this.setState({
      additionalFilterValue: filter
    },
    () => {
      this.handleGetItems();
    })
  }

  componentDidUpdate(prevProps,prevState) {
      if (JSON.stringify(this.props.queryVariables) !== JSON.stringify(prevProps.queryVariables)) {
          this.resetPagination();
      }
      if (this.props.reload !== prevProps.reload && this.props.reload === true) {
          this.resetPagination();
      }
      if (this.props.currentLang !== prevProps.currentLang) {
          this.mapItems(this.state.listItems)
      }
      if (this.state.selectionItemsMode !== prevState.selectionItemsMode) {
          if (this.state.selectionItemsMode) {
              
          }
      }
  }

  addDynamicFilter = (stateName) => {
    let attribute = this.props.attributes.product.attributes.edges.find(e=>e.node.identifier === stateName)?.node
    let filters = {...this.state.filtersOptions}
    filters.inputs.splice(-1)
    filters.inputs.push({
      xs: 8,
      type : attribute?.attributeType?.input === "select" ? FormInputTypes.SELECT : FormInputTypes.TEXT,
      dynamicFilter:true,
      required: false,
      disabled: false,
      topLabel:"Taper votre recherche",
      label:'Recherche',
      stateName: stateName,
      helper: {
          position: 'top',
          text: null,
          link: false,
      },
      value: attribute?.attributeType?.input === "select" ? attribute.attributeOptions.edges.map((value)=>{
        return {
          label:value.node.translation.translationDatas.edges[0].node.value,
          value:value.node.id.replace("/api/attribute-options/",""),
        }
      }):null
    })
    this.setState({filtersOptions:filters})
  };

  render() {
    let { settings, label, perPageOptions, propsToPass } = this.props;
    let pagination = this.props.pagination ? this.state.pagination : null;
    let currentLang = this.props.currentLang;
    let noResult = this.state.noResult;
    let views = this.state.views;

    return (
      <ListingContainer
        padding={this.props.padding}
        ref={this.ref}
        height={this.props.height}
        className="listing-wrapper"
      >
                {
                    this.props.enableFilters ?
                        <Filters
                            options={this.state.filtersOptions}
                            state={this.state.filters}
                            handleChange={this.handleChangeFilters}
                            // onKeyCallback={this.updateFilters}
                            dynamicFilters={this.state.dynamicFilters}
                            handleRemoveDynamicFilter={this.handleRemoveDynamicFilter}
                            activeFilter={this.state.activeChip}
                        />
                    :null
                }
                <div className="pagination" style={{
                  display:'flex',
                  justifyContent: 'space-between',
                }}>
                  {
                      this.state.ready ? (
                          <CursorPagination
                              itemSelection={this.props.itemSelection}
                              handleSelectionMode={this.handleSelectionMode}
                              handleSelectionItems={this.handleSelectionItems}
                              rowLabel={`${capitalize(label)} par page`}
                              pagination={pagination}
                              type="table"
                              changeSort={this.handleSort}
                              currentSort={this.state.currentSort}
                              changePageCallback={this.changePage}
                              changePerPageCallback={this.changePerPage}
                              showPerPage={perPageOptions[views.current]?.length > 0}
                              perPageOptions={perPageOptions[views.current]}
                              typeResult={this.props.typeResult}
                              views={views} 
                              handleViews={this.changeViews}
                              currentLang={currentLang}
                              handleLang={this.props.handleLang}
                              locales={this.props.locales}
                              enableChangeSort={this.props.enableChangeSort}
                              enableChangeView={this.props.enableChangeView}
                              enablePagination={this.props.enablePagination}
                              enableSelectionItems={this.props.enableSelectionItems}
                              actions={this.props.actions}
                          />
                      ) : null
                  }
                  {
                      this.props.additionalFilters && this.state.ready &&
                        <ChipMedias
                            categories={this.props.additionalFilters?.categories} 
                            handleChangeTab={this.filterByType} 
                            defaultTab={this.state.additionalFilterValue}
                            currentCategory={this.props.additionalFilters?.currentCategory}
                            dataCount={this.props.dataCount}
                        />
                  }
                </div>
                {
                    this.state.ready?
                        !noResult ? (
                            <>
                                {
                                    views.current === 'card' ?
                                        this.state.ready ? (
                                            <GridView 
                                              scroll={this.props.scroll} 
                                              settings={settings.grid} 
                                              propsToPass={propsToPass} 
                                              items={this.state.items? this.state.items[views.current]:null} 
                                              cardProps={this.props.cardProps} 
                                              label={label} 
                                              currentLang={currentLang} 
                                              cardContainerProps={this.props.cardContainerProps} 
                                            />
                                        ) : <PageLoader />
                                        :
                                        views.current === "table" ?
                                            this.state.ready ? (
                                                <TableView 
                                                  handleSelectionItem={this.handleSelectionItem} 
                                                  itemSelection={this.props.itemSelection} 
                                                  selectionItemsMode={this.state.selectionItemsMode}  
                                                  settings={settings.table} propsToPass={propsToPass} 
                                                  items={this.state.items?this.state.items[views.current]:null} 
                                                  tableProps={this.props.tableProps} 
                                                  label={label} 
                                                  ready={this.state.ready} 
                                                  sortCallback={this.handleSort} 
                                                  sortBy={this.state.sortBy} 
                                                  currentLang={currentLang} 
                                                />
                                            ) : <PageLoader />
                                            :
                                            views.current === "carousel" ?
                                                this.state.ready ? (
                                                    <CarouselListing 
                                                      items={this.state.items} 
                                                      currentLang={currentLang} 
                                                      propsToPass={propsToPass} 
                                                      cardProps={this.props.cardProps}
                                                    />
                                                ) : <PageLoader />                                            
                                                :this.state.ready ? (
                                                    <TableViewSimple 
                                                      settings={settings.table} 
                                                      propsToPass={propsToPass} 
                                                      items={this.state.items?this.state.items[views.current]:null} 
                                                      tableProps={this.props.cardProps} 
                                                      label={label} 
                                                      ready={this.state.ready} 
                                                      sortCallback={this.handleSort} 
                                                      sortBy={this.state.sortBy} 
                                                    />
                                                    ) : <PageLoader />
                                }
                            </>
                        ) : <NoResult component={this.props.noResultComponent} />
                        :<PageLoader/>
                }
            </ListingContainer>
        )
    }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
    attributeGroups: state.attributeGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Listing)))
);
