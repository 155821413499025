import PropTypes from "prop-types";
import React, { useState } from "react";
import FormLogin from '../blocks/login/FormLogin';

const LoginGame = (props) => {
    const [hover, setHover] = useState(false);
    // const [collectedProps, drop] = useDrop({
    //     accept: ['media'],
    //     drop: (item) => {
    //         data.inputs['backgroundImage'].value = item.media.node;
    //         update();
    //     }
    // });

    let { 
        preview = false,  
        id = null, 
        data,
        inputCallback = null,
        update = null
    } = props;

    let image = data.inputs['backgroundImage'].value;

    return (
        <div 
            className="login-game"
            // ref={drop}
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: 'relative'
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && props.toolbarSection }
            <section class="first">
                <div onMouseEnter={() => setHover(false)} onMouseLeave={() => setHover(true)}>
                    <FormLogin data={data.blocks.middleBox} inputCallback={inputCallback} component={id} update={update} toolbar={props.toolbarBlock} />
                </div>
            </section>
        </div>
    );
};

LoginGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default LoginGame;
