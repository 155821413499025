import { gql } from '@apollo/client';

//----- Récupération des catégories
export const GET_COMPANIES = gql`
{
  companies (first: 10000){
    edges {
      node {
        id
        libelle
        parent {
            id
        }
        children{
          totalCount
        }
        customers{
          totalCount
        }
        companyDatas{
          edges{
            node{
              id
              value
              attribute{
                id
                identifier
                attributeType {
                  id
                  input
                }
                translation{
                  translationDatas{
                    edges{
                      node{
                        locale{
                          code
                        }
                        value
                      }
                    }
                  }
                }
              }
              media{
                id
              	filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
              locale{
                id
                code
              }
            }
          }
        }
      }
    }
  }  
}
`;
export const GET_COMPANIES_CURSOR = gql`
{
  companies(first: 10000){
    edges{
      cursor
    }
  }
}
`;
export const GET_COMPANIES_PAGINATION = gql`
query companies ($nbperpage: Int, $cursor: String, $cursorLast: String, $parent : Boolean,$id_list:[Int]){
  companies (first: $nbperpage, after: $cursor, before: $cursorLast, exists:{parent:$parent},id_list:$id_list){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges {
      node {
        id
        libelle
        createdAt
        parent {
            id
        }
        children{
          totalCount
        }
        customers{
          totalCount
        }
        companyDatas{
          edges{
            node{
              id
              value
              attribute{
                id
                identifier
                attributeType {
                  id
                  input
                }
              }
              media{
                id
              	filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
              locale{
                id
                code
              }
            }
          }
        }
      }
    }
  }  
}
`;

export const SEARCH_COMPANIES = gql`
query companyDataSearch($attribute: String, $needle: String) {
  companyDatas(attribute: $attribute, value: $needle) {
    edges {
      node {
        id
        value
        company {
          id
          libelle
          createdAt
          parent {
            id
          }
          companyDatas {
            edges {
              node {
                id
                value
                attribute {
                  id
                  identifier
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                }
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const SEARCH_COMPANIES_CUSTOMERS = gql`
query companyDataSearch($attribute: String, $needle: String) {
  companyDatas(attribute: $attribute, value: $needle) {
    edges {
      node {
        id
        value
        company {
          id
          libelle
          createdAt
          parent {
            id
          }
          customers{
            edges{
              node{
                id
                sku
                status
                companies {
                  edges {
                    node {
                      id
                      libelle
                      companyDatas {
                        edges {
                          node {
                            value
                            attribute {
                              identifier
                            }
                            media {
                              filePath
                            }
                            locale {
                              code
                            }
                          }
                        }
                      }
                    }
                  }
                }
                customerDatas {
                  edges {
                    node {
                      value
                      id
                      attributeOption {
                        id
                        identifier
                        translation {
                          translationDatas {
                            edges {
                              node {
                                locale {
                                  id
                                  code
                                }
                                value
                              }
                            }
                          }
                        }
                      }
                      attribute {
                        id
                        identifier
                        isSystem
                        translation {
                          id
                          translationDatas {
                            edges {
                              node {
                                id
                                value
                                locale {
                                  id
                                  code
                                }
                              }
                            }
                          }
                        }
                        attributeType {
                          id
                          input
                        }
                      }
                      media {
                        id
                        filePath
                        type
                        size
                        category {
                          edges {
                            node {
                              id
                              libelle
                            }
                          }
                        }
                      }
                      locale {
                        id
                        code
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des catégories sans les produits
export const GET_COMPANIES_ONLY = gql`
{
  companies(first: 50) {
    edges {
      node {
        id
        libelle
        parent {
            id
        }
        companyDatas{
          edges{
            node{
              id
              value
              attribute{
                id
                identifier
                attributeType {
                  id
                  input
                }
              }
              media{
                id
                filePath
                type
                size
                company{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
              locale{
                id
                code
              }
            }
          }
        }
      }
    }
  }  
}
`;


//----- Récupération des données d'une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/companies/5"
  }
*/
export const GET_COMPANY_DATA = gql`
query companyDatas($id: ID!) {
  company(id: $id) {
    id
    libelle
    createdAt
    parent{
      id
      libelle
    }
    children{
      totalCount
      edges{
        node{
          id
          libelle
          createdAt
        }
      }
    }
    customers{
      totalCount
    }
    companyDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
            translation{
              translationDatas{
                edges{
                  node{
                    locale{
                      code
                    }
                    value
                  }
                }
              }
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;

//----- Ajouter une catégorie
/*
 Exemple de variables
  {
    "libelle" : "Cat de démo",
    "parent" : "/api/companies/1"
  }
*/
export const ADD_COMPANY = gql`
mutation AddCompany($libelle: String!, $parent: String,$createdAt : String!,$updatedAt:String!){
	createCompany(input: {libelle:$libelle, parent:$parent,createdAt :$createdAt,updatedAt:$updatedAt}){
    company{
      id
      libelle
      parent {
        id
      }
    }
  }  
}`;

//----- Modifier une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/companies/5",
    "libelle" : "libelle edited",
    "parent" : "/api/companies/1"
  }
*/
export const UPDATE_COMPANY = gql`
mutation UpdateCompany($id: ID!, $libelle: String, $parent: String){
	updateCompany(input: {id:$id, libelle:$libelle, parent:$parent}){
    company{
      id
      libelle
      parent {
        id
      }
    }
  }  
}`;

//----- Supprimer une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/companies/1"
  }
*/
export const DELETE_COMPANY = gql`
mutation DeleteCompany($id: ID!){
	deleteCompany(input: {id:$id}){
    company{
      id
    }
  }  
}`;



//--------------------
//----- DONNEES COMPANIES 
//--------------------

//----- Ajouter une valeur d'attribut à une catégorie

/*
 Exemple de variables
 {
  "value": "Catégorie 1 de test",
	"company": "/api/companies/5",
  "attribute": "/api/attributes/21", 
	"locale": "/api/locales/2"
}
*/
export const ADD_COMPANY_DATA = gql`
mutation AddCompanyData($value: String, $company: String!, $attribute: String!, $locale: String!, $media: String){
	createCompanyData(input: {value:$value, company:$company, attribute:$attribute, locale:$locale, media: $media}){
    companyData{
      id
      value
      company{
        id
      }
      attribute{
        id
      }
      media{
        id
      }
      locale{
        id
      }
    }
  }  
}`;



//----- Modifier la valeur d'attribut d'une catégorie

/*
 Exemple de variables
 {
  "id": "/api/company-datas/1",
  "value": "Catégorie 1 de test edited",
	"company": "/api/companies/5",
  "attribute": "/api/attributes/21", 
	"locale": "/api/locales/2"
}
*/
export const UPDATE_COMPANY_DATA = gql`
mutation UpdateCompanyData($id: ID!, $value: String, $company: String!, $attribute: String!, $locale: String!, $media: String){
	updateCompanyData(input: {id:$id, value:$value, company:$company, attribute:$attribute, locale:$locale, media:$media}){
    companyData{
      id
      value
      company{
        id
      }
      attribute{
        id
      }
      media{
        id
      }
      locale{
        id
      }
    }
  }  
}`;


//----- Supprimer une valeur d'attribut d'une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/company-datas/68"
  }
*/
export const DELETE_COMPANY_DATA = gql`
mutation DeleteCompanyData($id: ID!){
	deleteCompanyData(input: {id:$id}){
    companyData{
      id
    }
  }  
}`;


/*
 Exemple de variables
  {
    "id" : 8
  }
  ou
  {
    "parent" : "/api/companies/7"
  }
  */
export const GET_COMPANY = gql`
query getCompanyByIdorParent($id:Int, $parent:String) {
  companies(id:$id, parent:$parent){
    edges{
      node{
        id
        parent{id}
        libelle
        children{
          totalCount
        }
        customers{
          totalCount
        }
      }
    }
  }
}`;