import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';
import colors from '../../../config/theme/colors';
import styled from 'styled-components';

const InputCustom = styled(Input)`
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: 20
  },
  label: {
    color: '#000'
  },
  input: {
    width: '100%',
    backgroundColor: colors.blue.lighter.hue600,
    color: colors.blue.lighter.hue150,
  }
});

export default function SliderInput(props) {
  const classes = useStyles();

  const handleSliderChange = (event, newValue) => {
    props.onChange(newValue);
  };

  const handleInputChange = event => {
    props.onChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (props.value < props.params?.min) {
      props.onChange(props.params?.min);
    } else if (props.value > props.params?.max) {
      props.onChange(props.params?.max);
    }
  };

  return (
    <div className={classes.root}>
      <Typography id="input-slider" style={{fontSize: 14}} gutterBottom>
        { props.label }
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof props.value === 'number' ? props.value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            step={props.params?.step}
            min={props.params?.min}
            max={props.params?.max}
          />
        </Grid>
        <Grid item>
          <InputCustom
            className={classes.input}
            value={props.value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: props.params?.step,
              min: props.params?.min,
              max: props.params?.max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
