import React from 'react';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import CustomCard from './CustomCard';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    cardHeader: {
        color: '#000'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    slidePanel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    actionBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10
    }
}));

export default ({ data, buildFormInput, addSlider }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                data.slides.map((slide, i) => {
                    let inputs = [];
        
                    for (let blockName in slide.blocks) {
                        let block       = slide.blocks[blockName];
                        let cardInputs  = [];
            
                        for (let inputName in block.inputs) {
                            let input = block.inputs[inputName];
            
                            cardInputs.push(buildFormInput(input, block.id));
                        }

                        inputs.push(<CustomCard block={block} cardInputs={cardInputs} />);
                    }

                    return (
                        <Accordion key={i}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Slide {i + 1}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.slidePanel}>
                                { inputs }
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }

            <div className={classes.actionBar}>
                <Chip
                    label="Ajouter"
                    onClick={() => addSlider(data)}
                    icon={<AddIcon />}
                />
            </div>
        </div>
    )
};