import React from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import H2 from '../../../../shared/components/CustomH2';
import H3 from '../../../../shared/components/CustomH3';

export default ({ data, preview, component, block, inputCallback, className }) => {
    var dotations = data.blocks[block].config;
    return( 
        <div className="dotations more_details">
            <div>
                <H2 data={data} preview={preview} component={component} block={'dotationsTitle'} inputCallback={inputCallback} />
                {
                    data.blocks.dotationsSubtitle ?
                    (<H3 className="center" data={data} preview={preview} component={component} block={'dotationsSubtitle'} inputCallback={inputCallback} />) : null
                }
            </div>
            <div className="list">
                {
                    dotations.map((dotation, index) =>{
                        let image = dotation.blocks.blockCustom.inputs.backgroundImage.value;
                        let border = dotation.blocks.blockCustom.inputs.border;
                        return(
                            <div 
                                key={index}
                                style={{
                                    backgroundColor: dotation.blocks.blockCustom.inputs['backgroundColor'].value,
                                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                                    backgroundSize: "contain",
                                    backgroundPosition: "center top",
                                    backgroundRepeat: "no-repeat",
                                    position: 'relative',
                                    borderStyle: 'solid',
                                    borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                                    borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                                    borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                                    height: 460,
                                }}
                                className="dotation" onClick={() => inputCallback(component, block)}
                            >
                                <div>
                                    <H2 data={dotation} preview={preview} component={dotation.blocks.text.id} block={'title'} inputCallback={() => inputCallback(component, block)} nopadding={true} />
                                    <H3 data={dotation} preview={preview} component={dotation.blocks.text.id} block={'subtitle'} inputCallback={() => inputCallback(component, block)} nopadding={true} />
                                    <Pg data={dotation} preview={preview} component={dotation.blocks.text.id} block={'text'} inputCallback={() => inputCallback(component, block)} nopadding={true} />
                                    {
                                        (typeof dotation.blocks.image.inputs.value.value === 'string' && dotation.blocks.image.inputs.value.value !== '') || dotation.blocks.image.inputs.value?.value?.filePath ? 
                                        (
                                            <img
                                                className="img_center"
                                                src={
                                                    typeof dotation.blocks.image.inputs.value.value === 'string' && dotation.blocks.image.inputs.value.value !== '' ? 
                                                        dotation.blocks.image.inputs.value.value : 
                                                        dotation.blocks.image.inputs.value?.value?.filePath ? 
                                                            ` ${process.env.REACT_APP_MEDIAS}/${dotation.blocks.image.inputs.value.value.filePath}` 
                                                            : null
                                                }
                                                alt={`Dotation ${index+1}`}
                                            />
                                        ) : null
                                    }
                                </div>
                            </div>
                        ) 
                    })
                }
            </div>
        </div>
    )
};