import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import colors from "../../../../config/theme/colors";

export const useStyles = makeStyles({
    inputRootExplorer: {
        minHeight: 40,
        padding: "0 4px !important",
        "& .MuiInputBase-input": {
            minWidth: "100%",
            padding: 4,
            margin: 0
        },
        "& .MuiChip-clickable": {
            maxWidth: '100%',
            margin: "4px",
        }
    },
});

export const SimpleImage = styled.img`
    max-width:80%;
    margin:0 auto;
    display : block;
    margin-bottom:50px;
`;

export const InputFieldCustom = styled(TextField)`
    /* input, .MuiSelect-select{
        font-size: 16px;
        padding: 16px;
        border-radius: 0;
        border-color: ${colors.grey.lighter.hue700};
        @media screen and (max-width: 1450px){
            font-size: 14px;
            padding: 14px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            padding: 13px;
        }
        @media screen and (max-width: 1000px){
            font-size: 13px;
            padding: 13px;
        }
    }
    & > p{
        margin: 0;
    } */
`;