import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import colors from '../../../../../config/theme/colors'
import styled from 'styled-components';
import pngBackground from '../../../../../assets/images/medias/pixelBackground.jpg'
import { getFileConfig } from '../../../../../js/helpers/files';
import { withTranslation } from 'react-i18next';
import { Box, Divider, Tooltip } from '@mui/material';
import OurTypography from '../../../../ui/typography/Typography';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import moment from 'moment';
import OurButton from '../../../../ui/button/Button';
import { downloadFile } from '../../../../../js/utils/downloadFile';

const ImageContainer = styled(Grid)`
    /* width: 100%; */
    /* height: 100%; */
    max-width: 90%;
    max-height: 90%;
    overflow:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1000px){
        max-height: 400px !important;
        img,video{
            max-height: 400px !important;
        }
    }
    img,video{
        background-image: ${props => props.imagetype === 'PNG' ? 'url(' + pngBackground + ')' : 'none'};
        background-size: cover;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        box-shadow: 1px 1px 10px -3px ${colors.grey.lighter.hue800};
        object-fit: contain;
    }
`;

const CloseModal = styled(CloseIcon)`
    position : absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transform: scale(1);
    transition: all .5s;

    &:hover{
        transform: scale(1.1);
    }
`;

const ModalWrapperCustom = styled(Box)`
    display: flex;
    flex-direction: column;
    border: none;
    outline: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    max-width: 1150px;
    max-height: 700px;
    background-color: white;
    z-index: 999;
    overflow: auto;
`;

const MediaOption = styled(Grid)``;

function getMediaType(type) {
    if (type) {
        var res = type.match(/\.[0-9a-z]+$/i)?.[0].replaceAll('.', '').toUpperCase();
        return res;
    }
}

function MediaModal(props) {

    const [openConfirmation, setOpenConfirmation] = useState(false);

    let modalData = props.modalData;
    let size = modalData.size;
    // let currentIndex    = modalData.index;
    let round = 0;
    let isVideo = ['video/mpeg', 'video/webm', 'video/mp4'].includes(modalData.type) ? true : false;
    let isPDF = ['application/pdf'].includes(modalData.type) ? true : false;
    let mimeType = modalData.type;
    let type = {
        value: getMediaType(modalData.filePath),
        style: {
            background: getFileConfig(modalData.type).color,
            color: 'white',
            fontWeight: 'bold',
        }
    }
    let convertSize;
    let unit;

    if (size >= 1048576) {
        convertSize = size / 1048576;
        unit = 'Mo';
        round = 1;
    }
    else {
        convertSize = size / 1024;
        unit = 'Ko';
    }

    const useStyles = makeStyles((theme) => ({
        asset: {
            // margin: 'auto',
            // maxHeight: '90%',
            // maxWidth: '80%',
            // maxHeight: '100%',
            // maxWidth: '100%',
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            outline: 'none',
        },
    }));

    convertSize = convertSize.toFixed(round);
    const classes = useStyles();

    const rowInfos = [
        {
            label: 'Importé :',
            value: moment(modalData?.created_at).format('DD/MM/YYYY [à] HH:mm')
        },
        {
            label: 'Dossier :',
            value: modalData?.category?.edges.map(cat => cat.node.libelle).join(', ') || '-'
        },
        {
            label: 'Tag :',
            value: modalData?.mediaObjectTags?.edges.map(tag => tag.node.tag).join(', ') || '-'
        },
        {
            label: 'Validité :',
            value: moment(modalData?.expirationAt).format('DD/MM/YYYY [à] HH:mm')
        },
    ]

    const getFileSize = (size) => {
        const ko = size / 1024
        const mo = ko / 1024
        return mo < 1 ? ko.toFixed(0) + ' ko' : (mo).toFixed(0) + ' mo'
    }

    const handleDownloadFile = () => {
        downloadFile(
            `${process.env.REACT_APP_API_ROOT}/medias/${modalData?.filePath}`, 
            modalData?.name || modalData?.filePath
        );
    };

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{ border: '0px', outline: 'none', display: 'flex', zIndex: '99999' }}
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                {props.hasData
                    ? (
                        <Fade in={props.open}>
                            <ModalWrapperCustom>
                                <Grid container justifyContent={'space-between'} style={{
                                    borderBottom: '1px solid #e0e0e0',
                                    flex: '0 0 auto'
                                }}>
                                    <OurTypography style={{ padding: '10px 0px 10px 25px' }}>Informations liées au média</OurTypography>
                                    <Grid container justifyContent={'center'} alignItems={'center'} style={{
                                        borderLeft: '1px solid #e0e0e0',
                                        width: 40,
                                        cursor: 'pointer'
                                    }} onClick={props.onClose}>
                                        <CloseSharpIcon />
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={'32px'} style={{
                                    padding: 32,
                                    flex: '1 1 auto',
                                    height: 'calc(100% - 40px)',
                                }}>
                                    <Grid item md={7} style={{
                                        width: props.windowWidth < 1250 ? '50%' : '100%',
                                        height: props.windowWidth < 1250 ? '50%' : '100%',
                                        margin: 'auto',
                                    }}>
                                        {isVideo ?
                                            <video className={classes.asset} controls autoPlay>
                                                <source src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath}`} type={mimeType} />
                                                Sorry, your browser doesn't support embedded videos.
                                            </video>
                                            : isPDF ?
                                                <iframe className={classes.asset}
                                                    src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath}`}
                                                    type={mimeType}
                                                    title={modalData.name}
                                                    width="100%"
                                                    height="100%"
                                                    allowFullScreen
                                                    loading='lazy'
                                                    seamless
                                                    allowTransparency
                                                />
                                                :
                                                // null
                                                <img className={classes.asset} src={getFileConfig(modalData.type).image ? getFileConfig(modalData.type).image : `${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath}`} />
                                        }
                                    </Grid>
                                    <Grid item md={5}>
                                        <Grid container direction={'column'} style={{height: '100%'}}>
                                            <Grid flex={'1 1 auto'}>
                                                {true ? (
                                                    // {'TEst'.length > 80 ? (
                                                    <Tooltip title={'TEst'}>
                                                        <OurTypography fontweighttext={'bold'} style={{
                                                            fontSize: 30,
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: 2,
                                                            WebkitBoxOrient: 'vertical',
                                                            overflow: 'hidden',
                                                            lineHeight: '1',
                                                            paddingBottom: '0.15em'
                                                        }}>{modalData.name}</OurTypography>
                                                    </Tooltip>
                                                ) : (
                                                    <OurTypography fontweighttext={'bold'} style={{
                                                        fontSize: 30,
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        lineHeight: '1',
                                                        paddingBottom: '0.15em'
                                                    }}>{modalData.name}</OurTypography>
                                                )}
                                                <Grid container justifyContent={'space-between'} style={{ marginTop: 20 }}>
                                                    <Grid item>
                                                        <Grid container alignItems='center' style={{ gap: '2px' }}>
                                                            <Grid item style={{ marginRight: '5px' }}>
                                                                <img src={getFileConfig(modalData?.type, true).icon} alt='test' style={{
                                                                    width: '20px',
                                                                    marginTop: '7px',
                                                                }} />
                                                            </Grid>
                                                            <Grid item>
                                                                <OurTypography style={{ fontSize: props.windowWidth > 1500 ? '17px' : '13px', color: colors.grey.regular, fontWeight: '200' }}>Type de fichier: .{type.value}</OurTypography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {modalData?.size ? (
                                                        <Grid item style={{ color: colors.black.regular, fontWeight: 'bold', fontSize: props.windowWidth > 1500 ? '17px' : '13px' }}>
                                                            {getFileSize(modalData?.size)}
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                                <Divider style={{ margin: '20px 0px' }} />
                                                <Grid container style={{gap: 5}}>
                                                    {rowInfos.map((row, index) => (
                                                        <Grid container key={index} wrap='nowrap' style={{ fontSize: 16 }}>
                                                            <Grid item style={{ minWidth: 150 }}>{row.label}</Grid>
                                                            <Grid item style={{
                                                                ...row.style,
                                                                fontWeight: 'bold',
                                                            }}>{row.value}</Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                <Divider style={{ margin: '20px 0px' }} />
                                                <OurTypography style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 20 }}>Texte alternative</OurTypography>
                                                <OurTypography style={{
                                                    fontSize: 14,
                                                    color: colors.grey.regular,
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 4,
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    lineHeight: '1.4',
                                                    paddingBottom: '0.15em'
                                                }}>
                                                    {modalData?.alt ?? 'Aucun texte alternatif n\'a été renseigné'}
                                                </OurTypography>
                                            </Grid>
                                            <Grid container flex={'0 0 auto'} justifyContent={'space-between'}>
                                                <Grid container style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '1fr 1fr',
                                                    gap: '10px',
                                                    margin: props.windowWidth < 1250 ? '50px 0px 32px 0px' : '50px 0px 0px 0px',
                                                }}>
                                                    <OurButton style={{height: 48}} downloadIcon={true} onClick={handleDownloadFile}>Télécharger</OurButton>
                                                    <OurButton style={{ margin: 0, padding: "14px 32px", boxSizing: 'border-box', height: 48 }} text={'Supprimer'} deleteIcon color={colors.red.regular} border={`1px solid ${colors.red.regular}`} bgcolor={colors.red.lighterv2} bgcolorhover={colors.red.lighterv2Hover} onClick={props.actions.delete} disabled={false} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ModalWrapperCustom>
                        </Fade>
                    )
                    : (
                        <Fade in={props.open}>
                            {isVideo ?
                                <video className={classes.asset} controls autoPlay>
                                    <source src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath}`} type={mimeType} />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                                : isPDF ?
                                    <iframe className={classes.asset}
                                        src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath}`}
                                        type={mimeType}
                                        title={modalData.name}
                                        width="100%"
                                        height="100%"
                                        allowFullScreen
                                        loading='lazy'
                                        seamless
                                        allowTransparency
                                    />
                                    :
                                    <img className={classes.asset} src={getFileConfig(modalData.type).image ? getFileConfig(modalData.type).image : `${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath}`} />
                            }
                        </Fade>
                    )
                }
            </Modal>
        </>

    );
}

export default withTranslation()(MediaModal);
