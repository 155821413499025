import * as sinfinConfig from "./config/sinfinWorkflow.config";
import * as pimConfig from "./config/pimWorkflow.config";
import * as gaiaConfig from "./config/gaiaWorkflow.config";
import * as tpConfig from "./config/tpWorkflow.config";
import * as contributorTasksConfig from "./config/contributorTasksWorkflow.config";
import * as adminTasksConfig from "./config/adminTasksWorkflow.config";
import * as activityConfig from "./config/activityWorkflow.config";
import { getTeamByUserId, getTradingPartnersList } from "../../../../queries/product_workflow";


const currentUser = JSON.parse(localStorage.getItem('AUTH_USER'))


export const getTopPanel = props=>{
    let title
    let subtitle
    switch (props.identifier) {
        case "sinfin":
            title = props.t("workflow.workflow_sinfin")
            subtitle= "Liste de vos produit a assigner"
            break;
        case "pim":
            title = props.t("workflow.workflow_pim")
            subtitle= "Liste de vos produit a assigner"
            break;
        case "gaia":
            title = props.t("workflow.workflow_gaia")
            subtitle= "Liste de vos produit a assigner"
            break;
        case "tp":
            title = props.t("workflow.workflow_tp")
            subtitle= "Liste de vos produit a assigner"
            break;
        case "tasks":
            title = props.t("workflow.workflow_tasks")
            subtitle= "Liste de vos produit a assigner"
        case "tasks_contributor":
            title = props.t("workflow.workflow_tasks")
            subtitle= "Liste de vos produit a assigner"
            break;
        case "activity":
            title = props.t("workflow.workflow_activity")
            subtitle= "Liste de vos produit a assigner"
            break;
    
        default:
            break;
    }
    return {title,subtitle}
}


export const getListingProps = props=>{
    const id = props.identifier
    let label
    let settings
    let perPageOptions
    let mappers
    let identifier
    switch (id) {
        case "sinfin":
            label = "sinfin workflow"
            settings= sinfinConfig.listSettings({users:props?.users,currentUser:props?.currentUser})
            perPageOptions=sinfinConfig.perPageOptions
            mappers=sinfinConfig.listMappers
            identifier='productWorkflows'
            break;
        case "pim":
            label = "pim workflow"
            settings= pimConfig.listSettings({users:props?.users,currentUser:props?.currentUser})
            perPageOptions=pimConfig.perPageOptions
            mappers=pimConfig.listMappers
            identifier='productWorkflows'
            break;
        case "gaia":
            label = "gaia workflow"
            settings= gaiaConfig.listSettings({users:props?.users,currentUser:props?.currentUser})
            perPageOptions=gaiaConfig.perPageOptions
            mappers=gaiaConfig.listMappers
            identifier='productWorkflows'
            break;
        case "tp":
            label = "tp workflow"
            settings= tpConfig.listSettings({users:props?.users,currentUser:props?.currentUser})
            perPageOptions=tpConfig.perPageOptions
            mappers=tpConfig.listMappers
            identifier='productWorkflows'
            break;
        case "tasks":
            label = "tasks workflow"
            settings= adminTasksConfig.listSettings({users:props?.users,currentUser:props?.currentUser})
            perPageOptions= adminTasksConfig.perPageOptions
            mappers= adminTasksConfig.listMappers
            identifier='productWorkflows'
            break;
        case "tasks_contributor":
            label = "tasks workflow"
            settings= contributorTasksConfig.listSettings({users:props?.users,currentUser:props?.currentUser})
            perPageOptions= contributorTasksConfig.perPageOptions
            mappers= contributorTasksConfig.listMappers
            identifier='productWorkflows'
            break;
        case "activity":
            label = "activity workflow"
            settings= activityConfig.listSettings({users:props?.users,currentUser:props?.currentUser})
            perPageOptions=activityConfig.perPageOptions
            mappers=activityConfig.listMappers
            identifier='productWorkflows'
            break;
    
        default:
            break;
    }
    return {
        label,
        settings,
        perPageOptions,
        mappers,
        identifier,
    }
}

export const getFilters = props=>{
    let filters
    switch (props.props.identifier) {
        case "sinfin":
        case "pim":
            filters = pimConfig.filtersSettings(props,props.users,props.currentUser,props.tradingPartners)
            break;
        case "gaia":
            filters = gaiaConfig.filtersSettings(props,props.users,props.currentUser,props.tradingPartners)
            break;
        case "tp":
            filters = tpConfig.filtersSettings(props,props.users,props.currentUser,props.tradingPartners)
            break;
        case "tasks":
            filters =  adminTasksConfig.filtersSettings(props,props.users,props.currentUser,props.tradingPartners)
        case "tasks_contributor":
            filters = contributorTasksConfig.filtersSettings(props,props.users,props.currentUser,props.tradingPartners)
            break;
        case "activity":
            filters = activityConfig.filtersSettings(props,props.users,props.currentUser,props.tradingPartners)
            break;
        default:
            break;
    }
    return filters
}


export const getUsers = async props=>{
    const users = await props.client.query({
        query: getTeamByUserId,
        variables: {
            id: props.id
        }
      }).then(result => {
        return result.data.user.subordinate.edges
      }).catch((err)=>{
        console.log(err)
      });
      return users
}

export const getTradingPartners = async props=>{
    const users = await props.client.query({
        query: getTradingPartnersList,
      }).then(result => {
        return result.data.channels.edges
      }).catch((err)=>{
        console.log(err)
      });
      return users
}
