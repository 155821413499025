import {Grid} from '@mui/material';
import Pagination from '@mui/material/Pagination';

import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import SearchBar from '../../../../ui/search/SearchBar';
import Button from '../../../../ui/button/Button';
import Typography from '../../../../ui/typography/Typography';
import colors from '../../../../../config/theme/colors';
import CardCompany from '../../../../layouts/Card/cardContent/CardCompany';
import {ROUTE_CRM_CONTACTS_SOCIETIES} from '../../../../../js/constants/route-names';
import Listing from '../../../../layouts/Listing/Listing'
import CardCustom from '../../../../layouts/Card/CardCustom';       
import React from 'react';

export const listSettings = {
    grid : {
        components : CardCompany,
        columns : {
            xs : 1,
            sm : 1,
            laptop : 2,
        }
    },

}

export const listMappers = [
    {
        view : 'card',
        mapper : null
    },
]

export const perPageOptions = {
    'card' : ['4'],
}

export const viewsOptions = {
    current : 'card',
}

const styles = makeStyles((theme)=>({
    root : {
        padding : '30px 20px'

    },
    customersContainer:{
        paddingTop : 30
    },
}));

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;


export default function SelectCompanies(props){
    props = props.customProps;
    const classes       = styles();
    
    return(
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end" style={{paddingBottom : 10}}>
                    <Grid item xs={6} >
                        <SearchBar onChange={props.handleSearch} placeholder={props.isOffer ? "Rechercher une offre" : "Rechercher une société"}></SearchBar>
                    </Grid>
                </Grid>
            </Grid>

            <Listing
                label = 'sociétés'
                settings = {listSettings}
                cardProps = {{
                    currentLang : props.currentLang,
                    isOffer : props.isOffer, 
                    select : true, 
                    inputCard : true, 
                    hasCursor: true,
                    handleSelectCompany : props.handleSelectCompany, 
                    uniqueSelect:props.uniqueSelect,
                    selectedCompanies : props.selectedCompanies,
                    currentCompanies : props.currentCompanies,
                }}
                cardContainerProps ={{
                    style:{width: "100%", height: "100%", padding: 0, paddingBottom: 16},
                    cardContentStyle:{padding: 0}
                }}
                perPageOptions = {perPageOptions}
                mappers = {listMappers}
                currentLang = {props.currentLang}
                identifier = 'companies'
                viewsOptions = {viewsOptions}
                queryVariables ={props.queryVariables}
            />
        </Grid>
    )
}