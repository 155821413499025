import React from 'react';

import { Box, Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import styled from 'styled-components';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import Button from '../../../ui/button/Button';
import { ROUTE_COMMUNITIES_MINISITES_DETAIL, ROUTE_SALES_ESHOP_DETAIL, ROUTE_COMMUNITIES_PERMANENTS_POSTS, ROUTE_COMMUNITIES_GAMES_DETAIL, ROUTE_SALES_SHOPS_DETAILS, ROUTE_SALES_SOCIAL_COMMERCE_DETAILS, ROUTE_SALES_MARKETPLACES_DETAILS, ROUTE_COMMUNITIES_SOCIALS_DETAIL, ROUTE_SALES_APPLICATIONS_DETAILS, ROUTE_COMMUNITIES_CMS_DETAIL } from '../../../../js/constants/route-names';
import { ASSET_APPLICATION, ASSET_GAME, ASSET_MINISITE, EPHEMERAL_ASSET } from '../../../../js/constants/assets-types';
import { withRouter } from 'react-router';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import { withTranslation } from 'react-i18next';
import ImgNotFound from '../../../../assets/images/image-fake-card.png';
import OurButton from '../../../ui/button/Button';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import TextEllipsis from './TextEllipsis';

const AssetDescr = styled(Typography)`
    font-size:13px;
    line-height:17px;
    margin-top:4px;
    margin-bottom:16px;  
    min-height:34px;
    width:100%;
    p{
        margin: 0;
    }
`

const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    width: 100%;
`
const ContainerBackground = styled(Box)`
    overflow: hidden;
    background: ${props => props.backgroundcolorcard};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`
const LineColor = styled(Box)`
    background: ${props => props.typeasset === 'permanent' ? colors.blue.darker.hue300 : props.typeasset === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    width: 100%;
`

const TypeAsset = styled(Typography)`
    font-weight: bold;
`
const AssetType = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
`

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.black.regular,
    },
    tooltip: {
        backgroundColor: colors.black.regular,
        fontSize: 14,
    },
}));


function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

function CardAssets(props) {
    const asset = props.data.asset;
    let image = '';
    let name = '';
    let description = '';
    if (!props.isDashboard) {
        const attributes = asset.node.assetDatas.edges;
        let allNames = attributes.filter(e => e.node.attribute.identifier === 'asset_store_name');
        name = allNames.find(e => e.node.locale.code === props.currentLang);
        let nameEN = allNames.find(e => e.node.locale.code === 'en_US');
        let nameFR = allNames.find(e => e.node.locale.code === 'fr_FR');

        let allDescription = attributes.filter(e => e.node.attribute.identifier === 'asset_store_description');
        description = allDescription.find(e => e.node.locale.code === props.currentLang);
        let descriptionEN = allDescription.find(e => e.node.locale.code === 'en_US');
        let descriptionFR = allDescription.find(e => e.node.locale.code === 'fr_FR');

        // let allImages   = attributes.filter(e => e.node.attribute.identifier === 'product_image');
        // let imageDB       = allImages.find(e => e.node.locale.code === props.currentLang);
        let allImages = asset.node.assetDatas.edges.filter(e => e.node.attribute.identifier === 'asset_store_image');
        let imageByLang = allImages.find(e => e.node.locale.code === props.currentLang);
        let defaultImage = allImages[0];

        image = imageByLang
            ? `${process.env.REACT_APP_MEDIAS}/${imageByLang.node.media?.filePath}` ?? ''
            : defaultImage
                ? `${process.env.REACT_APP_MEDIAS}/${defaultImage.node.media?.filePath}` ?? ''
                : null;

        name = name ? name.node.value : nameEN ? nameEN.node.value : nameFR ? nameFR.node.value : (asset.node.identifier);
        // description = description ? description.node.value : 'Pas encore de description';
        description = description ? description.node.value : descriptionEN ? descriptionEN.node.value : descriptionFR ? descriptionFR.node.value : props.t("spread_builder.dashboard.noDescription");
    } else {
        name = asset.node.asset[props.currentLang]?.assetName || props.t("spread_builder.dashboard.noNameInThisLanguage");
        description = asset.node.asset[props.currentLang]?.assetDescription || props.t("spread_builder.dashboard.noDescriptionInThisLanguage");
        let imageByLang = asset.node.asset[props.currentLang]?.assetImage?.filePath;

        image = imageByLang
            ? `${process.env.REACT_APP_MEDIAS}/${imageByLang}`
            : null

    }


    const goTo = (route, id) => {
        props.history.push({
            pathname: route,
            state: { assetId: id }
        });
    };

    function renderCardImage(image) {
        let content = '';        
        if (asset.node.assetGameType) {
            content = <TypeAsset variant="h1" colortext={colors.white}>{asset.node.assetType.libelle} - {asset.node.assetGameType.libelle}</TypeAsset>
        } else {
            content = <TypeAsset variant="h1" colortext={colors.white}>{asset.node.assetType.libelle}</TypeAsset>
        }
        if (image) {
            return (
                <ContainerImage height={210} width={'100%'} backgroundimage={image ? image : null}>
                </ContainerImage>
            )
        } else {
            return (
                <ContainerImage height={210} width={'100%'} backgroundimage={ImgNotFound}>
                </ContainerImage>
            )
        }
    }


    const classes = useRowStyles();

    let routeByAsset = null
    // if (ASSET_MINISITE.includes(props.data.asset.node.assetType.identifier)) {
    //     routeByAsset = ROUTE_COMMUNITIES_MINISITES_DETAIL
    // } else if (ASSET_GAME.includes(props.data.asset.node.assetType.identifier)) {
    //     routeByAsset = ROUTE_COMMUNITIES_GAMES_DETAIL
    // } else if (props.data.asset.node.assetType.assetCategory.identifier === "instore") {
    //     routeByAsset = ROUTE_SALES_SHOPS_DETAILS
    // } else if (props.data.asset.node.assetType.assetCategory.identifier === "connector") {
    //     routeByAsset = ROUTE_SALES_SOCIAL_COMMERCE_DETAILS
    // } else if (props.data.asset.node.assetType.assetCategory.identifier === "marketplace") {
    //     routeByAsset = ROUTE_SALES_MARKETPLACES_DETAILS
    // } else if (props.data.asset.node.assetType.assetCategory.identifier === "social") {
    //     routeByAsset = ROUTE_COMMUNITIES_SOCIALS_DETAIL
    // } else if (props.data.asset.node.assetType.assetCategory.identifier === "cms") {
    //     routeByAsset = ROUTE_COMMUNITIES_CMS_DETAIL
    // } else if (ASSET_APPLICATION.includes(props.data.asset.node.assetType.identifier)) {
    //     routeByAsset = ROUTE_SALES_APPLICATIONS_DETAILS
    // }else {
    // }
    
    if (props.data.asset.node.assetType.identifier === "application") {
        routeByAsset = ROUTE_SALES_APPLICATIONS_DETAILS
    }else{
        routeByAsset = ROUTE_SALES_ESHOP_DETAIL
    }

    return (
        <Box width={'100%'} style={{paddingBottom: '0px'}}>
            {renderCardImage(image)}
            <Grid container p={2}>
                <Grid item xs={12} style={{paddingTop: '10px'}}>
                    <Grid container style={{ borderBottom: `0.5px solid ${colors.grey.lighter.hue700}` }}>
                        {/* Name and asset type */}
                        <Grid container justifyContent='space-between' sm={12}>
                            <Grid item>
                                <Typography variant="h3" style={{ fontSize: '22px' }}>{name}</Typography>
                            </Grid>
                        </Grid>
                        {/* Description */}
                        <Grid item sm={12}>
                            <AssetDescr variant="body2" component="div">
                                <TextEllipsis
                                    text={description}
                                    maxLine={2}
                                    ellipsis='...'
                                    hasModal={false}
                                />
                            </AssetDescr>
                        </Grid>
                    </Grid>
                    <Grid container style={{ padding: '20px 0px' }}>
                        {/* Titles */}
                        <Grid item sm={5} direction='column' style={{ display: 'flex', gap: '5px' }}>
                            <Typography variant='div'>Statut :</Typography>
                            <Typography variant='div'>Langue :</Typography>
                            <Typography variant='div'>Catalogue :</Typography>
                        </Grid>
                        {/* Data */}
                        <Grid item sm={7} direction='column' style={{ display: 'flex', gap: '5px', fontWeight: 'bold' }}>
                            <StatusInfo status={props.isDashboard ? asset.node.asset.status : asset.node.status} />
                            <Typography variant='div'>{asset?.node?.locales?.edges.length > 0 ? asset?.node?.locales?.edges?.["0"].node?.libelle : 'N/A'}</Typography>
                            <Typography variant='div'>{asset?.node?.catalog?.id ? 'Oui' : 'Non'}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop: '15px'}}>
                    <OurButton types="Info" text="Voir le  détail" variant="contained" color="secondary" size="small" style={{ marginTop: "1%", padding: '12px', width: '100%', color: colors.white, marginBottom: '0px' }} onClick={(e) => {
                        goTo(routeByAsset.replace(':id', asset.node.id.replace('/api/assets/', '')))
                    }}>
                    </OurButton>
                </Grid>
                {/* <Grid container justifyContent="space-between" style={{paddingBottom: 16}} alignItems="center">
                    <AssetType 
                        variant="h5" 
                        component="div" 
                        typeasset={EPHEMERAL_ASSET.indexOf(asset.node.assetType.identifier) > -1 ? 'ephemeral' : 'permanent'} 
                        marginright={"1rem"}
                    >
                        {EPHEMERAL_ASSET.indexOf(asset.node.assetType.identifier) > -1 
                            ? props.t("spread_builder.dashboard.ephemeralAsset") 
                            : props.t("spread_builder.dashboard.permanentAsset")
                        }
                    </AssetType>
                    <Box>
                        <StatusInfo status={props.isDashboard ? asset.node.asset.status : asset.node.status}/>
                    </Box>
                </Grid> */}
                {
                    props.isDashboard && asset.node.stats.success ?
                        <Grid container>
                            {
                                asset.node.stats.nbWishlist || asset.node.stats.nbWishlist >= 0 ?
                                    <Grid>
                                        <BootstrapTooltip title={'Total Wishlist'} className={clsx(classes.tooltip)} arrow>
                                            <Box style={{ display: 'flex' }}>
                                                <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"picto-profil"} />
                                                <Typography variant="h4" style={{ marginLeft: 8 }}>{asset.node.stats.nbWishlist}</Typography>
                                            </Box>
                                        </BootstrapTooltip>
                                    </Grid>
                                    : null
                            }
                            {
                                asset.node.assetGameType ?
                                    <>
                                        <Grid container direction="row" style={{ marginTop: 8 }}>
                                            <BootstrapTooltip title={'Total Participations'} className={clsx(classes.tooltip)} arrow>
                                                <Box style={{ display: 'flex' }}>
                                                    <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"picto-profil"} />
                                                    <Typography variant="h4" style={{ marginLeft: 8 }}>{asset.node.stats.datas.participations}</Typography>
                                                </Box>
                                            </BootstrapTooltip>
                                            <BootstrapTooltip title={'Total Participations uniques'} className={clsx(classes.tooltip)} arrow>
                                                <Box style={{ display: 'flex' }} ml={2}>
                                                    <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"account"} />
                                                    <Typography variant="h4" style={{ marginLeft: 8 }}>{asset.node.stats.datas.singleParticipations}</Typography>
                                                </Box>
                                            </BootstrapTooltip>
                                            <BootstrapTooltip title={'Total Prix remportés'} className={clsx(classes.tooltip)} arrow>
                                                <Box style={{ display: 'flex' }} ml={2}>
                                                    <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"icon-win"} />
                                                    <Typography variant="h4" style={{ marginLeft: 8 }}>{asset.node.stats.datas.giftsWon}</Typography>
                                                </Box>
                                            </BootstrapTooltip>
                                        </Grid>
                                    </>
                                    : null
                            }
                        </Grid>
                        : null
                }
                {/* {!props.configCard ?
                    <Grid container direction="row" justifyContent="flex-end" style={{alignSelf:'flex-end'}}>
                        <Box mt={2}>
                            {
                                props.canBroadcast ?
                                    !props.isDashboard ?
                                        !props.isDiffusion ?
                                            asset.node.assetType.hasFeed || asset.node.assetType.hasPage || asset.node.assetType.identifier === 'jeu' ?
                                                <Button 
                                                    text={props.t("spread_builder.asset_view.broadcast")}
                                                    bgcolor={colors.blue.darker.hue300} 
                                                    color={colors.white} 
                                                    bgcolorhover={colors.blue.darker.hue300}  
                                                    colorhover={colors.white}  
                                                    border={`1px solid ${colors.blue.darker.hue300}`}
                                                    onClick={() => goTo(ROUTE_COMMUNITIES_PERMANENTS_POSTS.replace(':id', asset.node.id.replace('/api/assets/', '')))} 
                                                    arrow="right"
                                                    style={{marginRight: 8}}
                                                /> 
                                            : null
                                        : null
                                    : null
                                : null
                            }
                            
                            <Button 
                            text={props.isDiffusion ? props.t("spread_builder.dashboard.broadcast") : props.t("spread_builder.dashboard.seeDetails")}
                            bgcolor={colors.white} 
                            color={colors.blue.darker.hue300} 
                            bgcolorhover={colors.blue.darker.hue300}  
                            colorhover={colors.white}  
                            border={`1px solid ${colors.blue.darker.hue300}`}
                            onClick={
                                props.isDiffusion ? 
                                    () => goTo(ROUTE_COMMUNITIES_PERMANENTS_POSTS.replace(':id', asset.node.id.replace('/api/assets/', '')))
                                :() => goTo(routeByAsset.replace(':id', props.isDashboard ? `/api/assets/${asset.node.asset.id}` : asset.node.id.replace('/api/assets/', '')))} 
                            arrow="right"
                            />
                        </Box>
                    </Grid>  
                : null}   
                 */}
            </Grid>
        </Box>
    )
}
export default withTranslation()(withRouter(CardAssets));