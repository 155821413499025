import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '../../../ui/button/Button';
import { Grid, Typography, Box, Tooltip } from '@mui/material';
import FormBuilder from '../FormBuilder';
import StepperCustom from '../../stepper/StepperCustom';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Title = styled(Typography)`
  color: ${colors.black.regular};
  font-size: 34px;
`;

const Subtitle = styled(Typography)`
  color: ${colors.black.regular};
  max-width: 380px;
  margin: auto;
  font-weight: lighter;
`;

const RequiredMessage = styled(Typography)`
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-10px);
    color: ${colors.grey.lighter.hue800};
    font-weight: lighter;
    font-style: italic;
    /* line-height: 6px; */
`;

const FormScrollableLayout = styled(Grid)`
  overflow: auto; 
  border: 1px solid ${colors.grey.lighter.hue700};
  border-top: 0;
  background-color: ${colors.grey.lighter.hue980};

  & > div {
    /* //* This is important to make the form scrollable and flexible without taking all the height */
    height: 0px !important;
    flex: 1 1 auto;
  }
`

const StepLabelCustom = styled(StepLabel)`
  .MuiStepIcon-text, .MuiStepLabel-iconContainer {
    display: none;
  }
  & > span:first-child {
    scale: 1.1;
  }
  & > span:last-child {
    transform: translate(-7px, 4px);
    
    /* max-width: ${props => props.nbSteps ? 100 / props.nbSteps : 100}%; */
    /* & > .Mui-completed > span > span {
      display: none;
    } */
    & > .Mui-completed > span {
      opacity: 0.5;
    }
    & > span {
      /* Ellipsis */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; 
       /*Create max width depending of nbSteps  */
    }
}
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actions_section: {
    /* padding: "24px 32px", */
    /* padding: '15px 0px 15px 0px', */
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));

function getSteps(getData) {
  return getData;
}

function HorizontalLinearStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps(props.navFormConfig.children);
  const isStepOptional = step => {
    return (
      step.isOptionnal === true
    )
  };
  //* The steps are set with dynamic widths so it's needed to get the width of the stepper header to set a static width to the step titles and add ellipsis if needed
  const stepperHeaderRef = React.useRef(null);

  // useEffect(() => {

  // }, [window.outerWidth])

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let goNext = () => {
      let newSkipped = skipped;

      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(newSkipped);
    }

    if (props.stepperButtonAction[activeStep] !== null) {
      let canGoNext = props.stepperButtonAction[activeStep]();
      if (typeof canGoNext === "boolean") {
        if (canGoNext) {
          goNext();
        }
      } else {
        goNext();
      }
    } else {
      goNext();
    }
  };

  const handleBack = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setSkipped(newSkipped);

    if (props.backStepperButtonAction) {
      if (props.backStepperButtonAction[activeStep] !== null) {
        props.backStepperButtonAction[activeStep]();
      }
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(steps[activeStep])) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    if (props.stepperSkip) {
      setActiveStep(prevActiveStep => props.stepperSkip);
      setSkipped(prevSkipped => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(prevSkipped => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    }
  };

  const CustomComponent = activeStep !== steps.length && steps[activeStep].component ? steps[activeStep].component : null;
  let CustomComponentProps;
  if (CustomComponent) {
    CustomComponentProps = steps[activeStep].componentProps;
  }
  return (
    <div className={classes.root}>
      <StepperCustom className={steps.length <= 1 ? "onlyStep" : activeStep === steps.length ? "endSteps" : null} activeStep={activeStep} nbSteps={steps?.length} ref={stepperHeaderRef} currentWidth={stepperHeaderRef?.current?.offsetWidth}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(step)) {
            if ((props.windowWidth > 1000 && steps.length <= 3) || (props.windowWidth > 1400 && steps.length > 3) || activeStep === index) {
              labelProps.optional = <Typography variant="caption">&nbsp;(Optionnel)</Typography>;
            }
          }
          if (isStepSkipped(index)) {
            stepProps.completed = activeStep === index ? false : true;
          }
          return (
            <Step className={activeStep === index ? 'active' : null} key={step.labelName} {...stepProps}>
              <Tooltip title={props.t(step.labelName)}>
                <StepLabelCustom {...labelProps} nbSteps={steps.length}>
                  <span>
                    <span style={{marginRight: 4, fontSize: 17}}>{index + 1}.</span>
                    {props.t(step.labelName)}
                  </span>
                </StepLabelCustom>
              </Tooltip>
            </Step>
          );
        })}
      </StepperCustom>
      {/* FINAL STEP */}
      {activeStep === steps.length ? (
        <Grid item xs={12}>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item xs={3}>
              <Box textAlign={"center"}>
                <CheckCircleOutlineIcon style={{ fill: colors.green.regular, width: "100%", height: "100%", maxHeight: "54px" }} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={"center"} mt={2}>
                <Title variant={"h2"}>{props.navFormConfig.finalStep.title}</Title>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={"center"} mt={1}>
                <Subtitle variant={"h4"}>{props.navFormConfig.finalStep.subtitle}</Subtitle>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={"center"} mt={4}>
                <Button bgcolor={colors.green.regular} shadowcolor={colors.green.darker} text={props.navFormConfig.finalStep.textButton} onClick={props.handlerMutation} className="button" />
              </Box>
            </Grid>
            {/* <Grid item xs={12}>
                  <Box textAlign={"center"} mt={1}>
                      <Button 
                        onClick={handleBack} 
                        className={classes.button} 
                        text="Revenir"
                        bgcolor="transparent"
                        bgcolorhover="transparent"
                        color={colors.grey.regular}
                        disableElevation={true}
                        arrow="left"
                      />
                  </Box>
                </Grid> */}
          </Grid>
        </Grid>

      ) : (
        <div style={{ height: '100%', display: 'grid', gridTemplateRows: '1fr auto' }}>
          <FormScrollableLayout container direction={'column'}>
            {
              CustomComponent
                ?
                <CustomComponent
                  stateCallback={props.stateCallback}
                  errorCallback={props.errorCallback}
                  allState={props.allState}
                  optionsInputs={steps[activeStep].optionsInputs}
                  paginationInfo={props.paginationInfo}
                  searchHandler={props.searchHandler}
                  isFormComponent={true}
                  customProps={CustomComponentProps}
                  stepsInfo={steps[activeStep]}
                />
                : <FormBuilder
                  isCategory={false}
                  styleInnerContainer={props.styleInnerContainer}
                  backgroundColor={props.backgroundColor}
                  optionsInputs={steps[activeStep].optionsInputs}
                  stateCallback={props.stateCallback}
                  errorCallback={props.errorCallback}
                  allState={props.allState}
                  handleButtonGroupChange={props.handleButtonGroupChange ? props.handleButtonGroupChange : null}
                  handleTreeChange={props.handleTreeChange}
                  expandNodes={props.expandNodes}
                  treeEditCat={props.treeEditCat}
                  treeDeleteCat={props.treeDeleteCat}
                  treeAddCat={props.treeAddCat}
                  handleChangeCategorie={props.handleChangeCategorie}
                  selectedCategorie={props.selectedCategorie}
                  productByCategorie={props.productByCategorie}
                  selectedProductByCategorie={props.selectedProductByCategorie}
                  setSelectedProducts={props.setSelectedProducts}
                  copyCategories={props.copyCategories}
                  allCategories={props.allCategories}
                  gridItems={props.gridItems}
                  loadGrid={props.loadGrid}
                  addProducts={props.addProducts}
                  merchModal={props.merchModal}
                  resetReloadListing={props.resetReloadListing}
                  hideInput={props.hideInput !== null ? props.hideInput : null}
                  paginationInfo={props.paginationInfo}
                  searchHandler={props.searchHandler}
                  useLocking={props.useLocking}
                />
            }
          </FormScrollableLayout>
          {/* Buttons */}
          <Box className={classes.actions_section}>
            <Grid container direction="column" style={{
              marginBottom: 16,
              marginTop: 16,
              position: 'relative',
            }}>
              <RequiredMessage variant="h4">*{props.t('products.obligate')}</RequiredMessage>
              <Box marginLeft={'auto'}>
                {activeStep !== 0 ?
                  <Button
                    disabled={activeStep === 0}
                    bgcolor="transparent"
                    bgcolorhover="transparent"
                    color={colors.blue.darker.hue300}
                    disableElevation={true}
                    onClick={handleBack}
                    className={classes.button}
                    text="Étape précédente"
                    arrow="left"
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                  : ''}

                {isStepOptional(steps[activeStep]) && (
                  <Button
                    variant="contained"
                    color="white"
                    bgcolor={colors.blue.darker.hue300}
                    onClick={handleSkip}
                    className={classes.button}
                    text="Passer"
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                )}

                <Button
                  variant="contained"
                  color="white"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={props.stepperButtonDisabled ? (props.stepperButtonDisabled[activeStep] !== null && props.stepperButtonDisabled[activeStep]()) : null}
                  text={'Étape suivante'}
                  arrow="right"
                  style={{
                    marginRight: "0px",
                    margin: "0px",
                  }}
                />
              </Box>
            </Grid>

          </Box>
        </div>
      )}
    </div>
  );
}
export default withTranslation()(HorizontalLinearStepper)