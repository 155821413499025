import React from 'react';

import { Box, Grid } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components'
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import { withTranslation } from 'react-i18next';

const SeeMore = styled(Box)`
    width: 100%;
    // border: 0.5px solid ${colors.grey.lighter.hue700};

    svg {
        width: 3vh;
        height: auto;
    }
    &:hover{
        // border-color: ${colors.grey.darker};
        .MuiTypography-body1{
            color: ${props => props.colortexthover ? props.colortexthover : ''};
        }
        .MuiTypography-h5{
            color: ${props => props.colortexthover ? props.colortexthover : ''};
        }
    }
`

function CardActions(props) {
    const action = props.action;

    return (
        <SeeMore style={{
            cursor: 'pointer',
            padding: props.windowWidth <= 1500 ? '16px' : '22px 24px',
            height: '100%'
        }} onClick={() => { props.goTo(action.route, action.routeState ? action.routeState : null) }} colortexthover={action.colorHover ? action.colorHover : action.color ? action.color : colors.white}>
            <Box p={"8px"} style={{
                color: colors.white,
                background: colors.black.regular,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '7px',
            }}>
                {typeof action.icon === 'string'
                    ? <IcomoonReact iconSet={iconSet} color={action.colorPicto ? action.colorPicto : colors.white} size={props.windowWidth <= 1500 ? '16px' : '39px'} icon={action.icon} className="actionIcone" />
                    : action.icon
                }
            </Box>
            <Typography component="div" variant="body1" colortext={action.color ? action.color : colors.white} style={{ fontWeight: 'bold', fontSize: "16px" }}>
                <Box pt={props.windowWidth <= 1500 ? 1 : '10px'}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12}>
                            <Grid item style={{
                                marginBottom: 'px',
                                fontWeight: 'bold'
                            }}>
                                {props.t(action.title)}
                            </Grid>
                            <Grid item>
                                <Typography component="div" colortext={action.color ? action.color : colors.white} variant="h5" style={{
                                    fontSize: "12px",
                                    lineHeight: '14px',
                                    fontWeight: '300'
                                }}>
                                    {props.t(action.subtitle)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Typography>

        </SeeMore>
    );
}

export default withTranslation()(CardActions);