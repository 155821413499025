import { gql } from '@apollo/client';

export const GET_TOTAL_COUNTS = gql`
{
  products{
    totalCount
  }
  catalogs{
    totalCount
  }
  mediaObjects{
    totalCount
  }
  contents{
    totalCount
  }
  productSources{
    totalCount
  }  
}`;

export const GET_TOTAL_COUNT_PRODUCTS_CONFIGURATION = gql`
{
  categories(exists: [{catalog: false}]){
    id
  }
  completenessGroups {
    totalCount
  }
}`;

export const GET_TOTAL_COUNT_CONTENTS_CONFIGURATION = gql`
{
  contentCategories {
    id
    parent {
        id
    }
  }


}`;

export const GET_TOTAL_COUNT_MEDIAS_CONFIGURATION = gql`
{
    mediaObjectCategories{
        totalCount
    }
    mediaObjectTags {
      totalCount
    }
}`;

export const GET_TOTAL_COUNT_MEDIAS = gql`
query getTotalCountMedias($type_list: [String],$categories: [String]){
  mediaObjects(type_list: $type_list,category_list: $categories){
      totalCount
  }
}`;

export const GET_TOTAL_COUNT_DIFFUSION = gql`
{
  assets {
    edges {
      node {
        id
        libelle
        identifier
        assetType {
          id
          identifier
          libelle
        }
        assetDatas {
          edges {
            node {
              id
              value
              attribute {
                id
                identifier
              }
            }
          }
        }
      }
    }
  }
}
`
