import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { SocialNetworks } from '../../shareable/types';
import { Instagram, Facebook, Twitter, Pinterest, LinkedIn, YouTube, Menu } from '@mui/icons-material';

const SocialTextField = withStyles({
  root: {
    '& input': {
      padding: 10
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 15,
    '&>div': {
      '&>div': {
        margin: '8px 0',
        boxShadow: 'inherit',
        '&:first-child': {
          marginTop: 0,
        },
        '&:before': {
          content: 'inherit',
        }
      }
    }
  },
  dragIndicator: {
    marginRight: 10,
    cursor: 'pointer'
  },
  input: {
    marginBottom: theme.spacing(1),
    padding: 2,
    borderRadius: 0,
    '& > div': {
      borderRadius: 0,
      '& > div': {
        borderRadius: 0,
      },
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const DragHandle = sortableHandle(() => {
  const classes = useStyles();
  return <Menu className={classes.dragIndicator} />;
});

const SortableItem = SortableElement(({ items, onChange, item, id }) => {
  const classes = useStyles();

  const getIcon = (icon) => {
    switch (icon) {
      case SocialNetworks.FACEBOOK:
        return <Facebook />;
      case SocialNetworks.INSTAGRAM:
        return <Instagram />;
      case SocialNetworks.YOUTUBE:
        return <YouTube />;
      case SocialNetworks.PINTEREST:
        return <Pinterest />;
      case SocialNetworks.TWITTER:
        return <Twitter />;
      case SocialNetworks.LINKEDIN:
        return <LinkedIn />;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={1} key={`item-child-${id}`} alignItems="center">
      <Grid item xs={2}>
        <DragHandle />
      </Grid>
      <Grid item xs={10}>
        <SocialTextField
          variant="outlined"
          value={item.value}
          className={classes.input}
          style={{ borderRadius: 0, width: '100%', height: 30 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {getIcon(item.icon)}
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            item.value = event.target.value;
            onChange(items);
          }}
        />
      </Grid>
    </Grid>
  );
});

const SortableList = SortableContainer(({ items, onChange }) => {
  return <div>
    {items.map((value, index) =>
      <SortableItem
        items={items}
        onChange={onChange}
        key={`item-${index}`}
        item={value}
        index={index}
        id={index}
      />
    )}
  </div>
});

export default function SocialSelector({ value, onChange }) {
  const classes = useStyles();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange(arrayMove(value, oldIndex, newIndex));
  };

  return (
    <div className={classes.root}>
      <SortableList
        items={value}
        onChange={onChange}
        onSortEnd={onSortEnd}
        helperClass='sortable-helper'
        useDragHandle
      />
    </div>
  );
}
