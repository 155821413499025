import { product } from '../../../../../../builder/shareable/tools/GA';
import {STEPPER} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';
import ListingProducts from '../../../products/components/ListingProducts';
import ProductsSelector from '../../../products/components/ProductsSelector';

export default function infoManagementsEdit(action='add', handleMediaPicker, allState) {
    let generalInputs   = [
        {
            required : true,
            type : 'text',
            label : 'Libelle',
            stateName : 'infoManagementLibelle',
            helper: {
                text: 'Libelle de la publicité',
                link: false,
            },
        },
        {
            required : true,
            type : 'colorPicker',
            label : 'Couleur Libelle',
            stateName : 'infoManagementLibelleColor',
            translated: false,
            helper: {
                text: '',
                link: false,
            },
        },
        {
            type: 'radio',
            direction: 'row',
            label: 'Actif',
            helper: {
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'infoManagementStatus',
            value: [
                {
                    value: true,
                    label: 'Oui'
                },
                {
                    value: false,
                    label: 'Non'
                },
            ]
        },
        {
            type: 'mediaPicker',
            label: 'Media',
            helper: {
                text: 'Indiquez une image',
                link: false,
            },
            handleMediaPicker: handleMediaPicker,
            required: true,
            stateName: 'infoManagementMedia',
        },
        {
            required : true,
            type : 'text',
            label : 'Texte Bouton',
            stateName : 'infoManagementTextButton',
            helper: {
                text: 'Indiquez le texte du bouton',
                link: false,
            },
        },
        {
            required : true,
            type : 'colorPicker',
            label : 'Couleur Texte Bouton',
            stateName : 'infoManagementTextColorButton',
            translated: false,
            helper: {
                text: '',
                link: false,
            },
        },
        {
            required : true,
            type : 'colorPicker',
            label : 'Couleur Bouton',
            stateName : 'infoManagementColorButton',
            translated: false,
            helper: {
                text: '',
                link: false,
            },
        },
        {
            required : true,
            type : 'text',
            label : 'Lien Bouton',
            stateName : 'infoManagementLinkButton',
            helper: {
                text: 'Indiquez le lien du bouton',
                link: false,
            },
        },
        {
            type: 'select',
            isContainerized: true,
            label: 'Type',
            helper: 'Type de publicité',
            required: true,
            stateName: 'infoManagementType',
            disabled: action === "add" ? false : true,
            value: [
                    {
                        value : 'product',
                        label : 'Fiche produit',
                        // disabled : node.node.id === attributeType,
                    },
                    {
                        value : 'category',
                        label : 'Landing Catégorie',
                    }
                ]
        }
    ]

    let categorieSelect = [
        {
            type: 'selectTree',
            label: 'Catégories',
            translated: false,
            helper: {
                text: 'Sélectionnez les catégories pour la publicité',
                link: false,
            },
            required: true,
            stateName: 'categoriesSelected',
            multiselect: true,
            data: (() => {
                let data = allState.assetInfo?.catalog.categories.filter(e => e.parent === null);
                let populateChildren = (cats, parent) => {
                    let catSelected = allState.categoriesSelected.find(e => e === parent.id);
    
                    let allNames    = parent.categoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');
                    const defaultValue  = allNames.find(e => e.node.locale.code === allState.currentLang);
                    const value         = allNames.find(e => e.node.locale.code === allState.currentLang);
                    let finalValue = value?.node.value ?? defaultValue?.node.value ?? parent.libelle;
    
                    parent.value    = parent.id;
                    parent.label    = finalValue;
                    parent.checked  = catSelected ? true : false;
                    parent.expanded = true;
                    parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);
    
                    for (let child of parent.children)
                        populateChildren(cats, child);
                };
    
                for (let parent of data)
                    populateChildren(allState.assetInfo?.catalog.categories, parent);
                return data;
            })()
        },
    ]

    let steps = []
    steps.push({
        labelName: 'Informations',
        isOptionnal: false,
        optionsInputs:generalInputs
    })

    if (allState.infoManagementType === "product"){
        steps.push({
            labelName: 'Sélections',
            isOptionnal: false,
            component: allState.infoManagementType === "product" ? ProductsSelector : null,
            optionsInputs: {
                catalogId : allState.assetInfo?.catalog.id,
                noCategories: true,
            }
        })
    }else{
        steps.push({
            labelName: 'Sélections',
            isOptionnal: false,
            optionsInputs:categorieSelect
        })
    }

    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? 'Créer une publicité':'Modifier la publicité',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre publicité`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de cette publicité' : 'Valider la modification de cette publicité',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children: steps
        }
    }
    return form;
}