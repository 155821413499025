import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import OurTypography from "../../../ui/typography/Typography";
import OurButton from "../../../ui/button/Button";
import OurMenu from "../../../ui/menu/Menu";
import colors from "../../../../config/theme/colors";

const CardMinimal = ({data, currentLang, ...customProps}) => {
  return (
    <Box p={2} onClick={() => console.log('deier')}>
      <Grid container justifyContent={'space-between'} style={{
        marginBottom: 32
      }}>
        <Grid item>
          <Grid container gap={'12px'}>
            <OurTypography fontsize={'19px'} fontweighttext={'bold'}>{data.title}</OurTypography>
            {data.helper && <OurTypography style={{marginTop: 2}} fontsize={'12px'} colortext={colors.grey.lighter.hue600}>{data.helper}</OurTypography>}
          </Grid>
        </Grid>
        <Grid item>
          <OurMenu 
            obj={data.obj}
            id={data.id} 
            menuOptions={customProps.dottedMenu.options} 
            toggleCallback={customProps.dottedMenu.toggleCallback} 
            handleClick={(e, item) => item.action(e)} 
          />
        </Grid>
      </Grid>

      <Grid container style={{
        marginBottom: 20
      }}>
        {data.summary.map((item, index) => (
          <Grid container>
            <Grid item xs={5}>{item.label}</Grid>
            <Grid item xs={7}>{item.value}</Grid>
          </Grid>
        ))}
      </Grid>

      <OurButton types={customProps.button.type} importance={customProps.button.importance} disabled={customProps.button.isDisabled} style={{
        width: '100%',
      }} onClick={() => customProps.button.action(data.obj)}>{customProps.button.text}</OurButton>
    </Box>
  )
};

CardMinimal.propTypes = {
  data: PropTypes.object,
  currentLang: PropTypes.string,
};

CardMinimal.defaultProps = {
  data: {},
  currentLang: '',
};

export default CardMinimal;