import React, { useState, useCallback } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { InputLabel } from "@mui/material";
import { withTranslation } from "react-i18next";
import colors from "../../../../config/theme/colors";
import { HelperText } from "../components/Text/styles/styled";
import PropTypes from "prop-types";
import { debounce } from "lodash";

const NumberInputCustom = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid ${colors.grey.border};
    }
    &:hover fieldset {
      border: 1px solid ${colors.grey.border};
    }
    &.Mui-focused fieldset {
      border: 1px solid ${colors.grey.border};
    }
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.red.regular} !important;
  }
  /* input, .MuiSelect-select{
    font-size: 16px;
    padding: 16px;
    border-radius: 0;
    border-color: ${colors.grey.lighter.hue700};
    @media screen and (max-width: 1450px){
        font-size: 14px;
        padding: 14px;
    }
    @media screen and (max-width: 1280px){
        font-size: 13px;
        padding: 13px;
    }
    @media screen and (max-width: 1000px){
        font-size: 13px;
        padding: 13px;
    }
}
& > p{
    margin: 0;
} */
`;

const NumberInput = ({
  label,
  value,
  icon = null,
  error = null,
  required = false,
  helper = null,
  onChange = () => {},
  delay = 0,
  ...props
}) => {
  const [val, setVal] = useState(value);

  const handleChange = (e) => {
    setVal(e.target.value);
    emitValue(e);
  };

  const emitValue = useCallback(
    debounce((val) => {
      onChange(val);
    }, delay),
    []
  );

  return (
    <>
      <Grid item xs={props.xs ? props.xs : 12} style={{ position: "relative" }}>
        {label && (
          <InputLabel sx={{ fontSize: "14px", marginBottom: "5px" }}>
            {label}
            {required && "*"}
          </InputLabel>
        )}
        <Box sx={{ display: "flex", gap: 10, alignItexms: "center" }}>
          <NumberInputCustom
            variant="outlined"
            size="small"
            value={val}
            InputProps={{
              startAdornment: icon && icon.position === "start" && (
                <InputAdornment position="start">{icon.value}</InputAdornment>
              ),
              endAdornment: icon && icon.position === "end" && (
                <InputAdornment position="end">{icon.value}</InputAdornment>
              ),
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            error={error ? true : false}
            helperText={error ? error : null}
            onKeyDown={(evt) => {
              if (props.onKeyCallback) {
                props.onKeyCallback(evt);
              }
            }}
            type="number"
            inputProps={{
              maxLength: 255,
              spellCheck: true,
              lang: "fr",
            }}
            spellCheck={true}
            lang="fr"
            onChange={handleChange}
            {...props}
          />
          {/* {props.input.translation && props.allState.currentLang !== "fr_FR" ? (
            <Translation
              value={value}
              stateCallback={props.stateCallback}
              currentLang={props.allState.currentLang}
            />
          ) : null} */}
        </Box>

        {/* {showError && error ? null : props.input.helper.link ? (
          <LinkText
            variant="body2"
            onClick={() => goTo(props.input.helper.route)}
          >
            {props.t(props.input.helper.text)}
          </LinkText>
        ) : (
        )} */}
        {helper && (
          <HelperText variant="body2">{props.t(helper.text)}</HelperText>
        )}
      </Grid>
    </>
  );
};

NumberInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  icon: PropTypes.shape({
    value: PropTypes.node,
    position: PropTypes.oneOf(["start", "end"]),
  }),
  error: PropTypes.string,
  required: PropTypes.bool,
  helper: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.bool,
    route: PropTypes.string,
  }),
};

export default withTranslation()(NumberInput);
