import { withTranslation } from "react-i18next";
import colors from "../../../../../../config/theme/colors";
import TopPanel from "../../../../../layouts/TopPanel/TopPanel";
import { withApollo } from "@apollo/client/react/hoc";
import { useNavigate, withRouter } from "react-router";
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../../../js/constants/action-types";
import AccordionCustom from "../../../../../layouts/Accordion/AccordionCustom";
import { useState } from "react";
import moment from "moment";
import InfoCard from "./components/InfoCard";
import { formatFetchedDatas, getSections } from "./config/sections";
import OurTypography from "../../../../../ui/typography/Typography";
import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_TOTAL_COUNTS, GET_TOTAL_COUNT_CONTENTS_CONFIGURATION, GET_TOTAL_COUNT_MEDIAS_CONFIGURATION, GET_TOTAL_COUNT_PRODUCTS_CONFIGURATION } from "../../../../../../queries/total";
import PageLoader from "../../../../../ui/loadings/page-loader/PageLoader";
import { SECTIONS } from "./constants/constants";
import { menuEntry } from "../../../../../../js/utils/menuEntry";
import CommonCard from "./components/CommonCard";
import { goTo } from "../../../../../../js/utils/goTo";
import { ROUTE_CONTENU_ATTRIBUTES, ROUTE_CONTENU_CATEGORIES, ROUTE_MEDIAS_CATEGORIES, ROUTE_MEDIAS_TAGS, ROUTE_PRODUCTS_ATTRIBUTS, ROUTE_PRODUCTS_CATEGORIES, ROUTE_PRODUCTS_COMPLETUDE } from "../../../../../../js/constants/route-names";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Configuration = props => {
    // Router
    const history = useHistory()

    // State
    const [sections, setSections] = useState(null)

    // Data fetching
    const { data: totalCount, loading: isTotalCountLoading } = useQuery(GET_TOTAL_COUNTS)
    const { data: productsConfig, loading: isProductsConfigLoading } = useQuery(GET_TOTAL_COUNT_PRODUCTS_CONFIGURATION, {
        skip: !(menuEntry("centralisation_products") || true)
    })
    const { data: mediasConfig, loading: isMediasConfigLoading } = useQuery(GET_TOTAL_COUNT_MEDIAS_CONFIGURATION, {
        skip: !(menuEntry("centralisation_medias") || true)
    })
    const { data: contentsConfig, loading: isContentsConfigLoading } = useQuery(GET_TOTAL_COUNT_CONTENTS_CONFIGURATION, {
        skip: !(menuEntry("centralisation_contents") || true)
    })

    useEffect(() => {
        if (!isProductsConfigLoading && !isMediasConfigLoading && !isContentsConfigLoading && !isTotalCountLoading) {
            getData()
        }
    }, [totalCount, productsConfig, mediasConfig, contentsConfig])

    const getData = () => {
        const fetchedDatas = formatFetchedDatas({
            product: productsConfig,
            media: mediasConfig,
            content: contentsConfig
        })

        setSections(getSections(totalCount, fetchedDatas))
    }

    return (
        <div>
            <TopPanel
                title={props.t("drawer.configurations")}
                subtitle={"Gestion de vos configuration (attributs / catégories / complétudes / … )"}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                buttonAvailable={true}
                windowWidth={props.windowWidth}
            />
            <Grid container gap={2}>

                {/* Main stats */}
                <AccordionCustom
                    title={<OurTypography>Dernières datas : <span style={{ fontWeight: 'bold' }}>{moment().format("dddd DD MMMM YYYY [à] HH:mm:ss")}</span></OurTypography>}
                    custompadding={'0px !important'}
                    detailsStyles={{ display: 'flex' }}
                >
                    {isTotalCountLoading
                        ? <PageLoader />
                        : (
                            sections?.map((section, index) => (
                                <InfoCard
                                    title={`Nombre de ${section.title}`}
                                    icon={section.icon}
                                    value={section.count}
                                    isLast={index === sections.length - 1}
                                />)
                            ))
                    }
                </AccordionCustom>

                {/* Listing */}
                {(!sections)
                    ? <PageLoader />
                    : (
                        // Section is the accordion
                        sections.filter(section => section.id !== SECTIONS.CATALOGS).map((section, index) => (
                            <AccordionCustom
                                title={<OurTypography>Configuration {section.title}</OurTypography>}
                                detailsStyles={{ display: 'grid', gridTemplateColumns: `repeat(${Object.keys(section.items).length}, 1fr)` }}
                            >
                                {/* Columns by row */}
                                {Object.keys(section.items).map((key, index) => {
                                    const item = section.items[key]
                                    return (
                                        <CommonCard
                                            data={item}
                                            button={{
                                                action: () => history.push(item.button.route),
                                                text: item.button.text
                                            }}
                                            isLast={index === Object.keys(section.items).length - 1}
                                        />
                                    )
                                }
                                )}
                            </AccordionCustom>
                        ))
                    )}
            </Grid>
        </div>
    )
}

export default withTranslation()(Configuration);