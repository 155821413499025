import React from "react";
import PG from "../../../../../shared/components/CustomDiv";
import request from "../../../../../../tools/Fetch";

function validateParticipation(href, callBackValidated){
    if (href){
        window.open(href.replace('%token%', localStorage.getItem('userToken')),'sharer','toolbar=0,status=0,width=580,height=325');
    }
    let data = new FormData();
    data.append('username', localStorage.getItem('username'));
    data.append('token', process.env.REACT_APP_ASSET_TOKEN);
    request(`${process.env.REACT_APP_API}/public/asset/registration/play`, 'post', data, 'multipart/form-data')
        .then(result => {})
        .catch(error => {
            console.err(error)
        })
    callBackValidated()
}

export default ({ data, preview, component, block, inputCallback, update, actionClick, id, callBackValidated }) => {
    var socials = data.blocks[block].config;
    return(
        socials.map((social, index) =>{
            return(
                <div key={index} className="social complete" onClick={() => inputCallback(component, block)}>
                    <a 
                        class="share_button"
                        // href={social.blocks.link.inputs.value.value && social.blocks.type.inputs.value.value !== "email" ? social.blocks.link.inputs.value.value : null }
                        href="javascript:void(0)"
                        // target="_blank" 
                        rel="noopener noreferrer" 
                        aria-label=""
                        style={{backgroundColor: social.blocks.colors.inputs.backgroundColor2.value}}
                        onClick={social.blocks.type.inputs.value.value === "email" ? () => actionClick() : () => validateParticipation(social.blocks.link.inputs.value.value, callBackValidated)}
                    >
                        
                        <div className="container-picto" style={{backgroundColor: social.blocks.colors.inputs.backgroundColor1.value}}>
                            <img  
                                src={
                                    typeof social.blocks.image?.inputs.value.value === 'string' ? 
                                        social.blocks.image.inputs.value.value : 
                                        social.blocks.image.inputs.value?.value?.filePath ? 
                                            ` ${process.env.REACT_APP_MEDIAS}/${social.blocks.image.inputs.value.value.filePath}` 
                                            : `http://via.placeholder.com/50x50/FFFFFF/53839D?text=${index+1}`
                                }
                            />
                        </div>
                        {
                            social.blocks.text?.inputs.value.value ?
                                <PG className={"text-button"} data={social} preview={preview} component={id} block={'text'} inputCallback={inputCallback} nopadding={true} style={{color: social.blocks.colors.inputs.colorText.value}}/>
                            : null
                        }
                    </a>
                </div>
            )
        })
    )
};