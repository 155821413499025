import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { withApollo } from '@apollo/client/react/hoc';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { getTraductionAttributs } from '../../../js/utils/functions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 15
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
}));

function CategorySelector ({ catalog, value, onChange, locales }) {
    const classes = useStyles();
    const [ options, setOptions ] = useState([]);

    useEffect(() => {
        const convertToOption = (e, level) => ({
            id: e.id,
            value: e.id,
            label: level + (getTraductionAttributs('category_name', e.categoryDatas, locales[0].node.code) || e.libelle),
            parent: e.parent
        });
        
        const populate = (parent) => {
            parent.children = categories.filter(e => e.parent && e.parent.id === parent.id);
        
            for (let child of parent.children) {
                populate(child);
            }
        };
        
        const addCat = (cat, level = '_') => {
            options.push(convertToOption(cat, level));
        
            for (let sub of cat.children) {
                addCat(sub, level + '_');
            }
        };
        
        let categories  = catalog.categories;
        let tree        = categories.filter(e => e.parent === null);
    
        for (let root of tree) {
            populate(root);
        }
    
        let options = [];
    
        for (let root of tree) {
            addCat(root);
        }

        setOptions(options);
    }, []);

    const onSelect = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className={classes.root}>
            <FormControl variant="outlined" className={classes.formControl}>
                <Typography gutterBottom style={{fontSize: 14}}>
                    Catégorie
                </Typography>

                <Select
                    id="category-select"
                    onChange={onSelect}
                    disabled={false}
                    value={value}
                    placeholder="Choisir..."
                    style={{borderRadius: 0, width: '100%'}}
                >
                    { options.map((option, i) => (
                    <MenuItem key={i} value={option.value}>{ option.label }</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default withApollo(CategorySelector);