import OurDrawer from "@/components/layouts/Drawer/OurDrawer";
import { withApollo } from "@apollo/client/react/hoc";
import {
  SET_ATTRIBUTES,
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import { ALERT_ERROR, ALERT_SUCCESS } from "@constants/alert-types";
import {
  ROUTE_PRODUCTS_LIST,
  ROUTE_PRODUCTS_LIST_DETAIL,
} from "@constants/route-names";
import { getItemsLocalStorage, setItemsLocalStorage } from "@helpers/filters";
import Listing from "@layouts/Listing/Listing";
import TopPanel from "@layouts/TopPanel/TopPanel";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import FileCopySharpIcon from "@mui/icons-material/FileCopySharp";
import { GET_CATEGORIES_LIGHT_2 } from "@queries/categories";
import {
  ADD_PRODUCT,
  ADD_PRODUCT_DATA,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
} from "@queries/products";
import { eventService } from "@services/event.service";
import Modal from "@ui/dialog/DialogModal";
import ExportProducts from "@ui/form/ExportProductsForm";
import ImportProductsForm from "@ui/form/ImportProductsForm";
import ProductForm from "@ui/form/ProductForm";
import request from "@utils/fetch";
import axios from "axios";
import colors from "colors";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import slugify from "slugify";
import importProductsConfig from "../config/formImportProducts.config";
import {
  filtersOptions,
  listMappers,
  listSettings,
  perPageOptions,
  viewOptions,
} from "../config/listProducts.config";

const Products = (props) => {
  const [dataCount, setDataCount] = useState(0);
  const [reloadListing, setReloadListing] = useState(false);
  const [openFormAdd, setOpenFormAdd] = useState(false);
  const [openFormImport, setOpenFormImport] = useState(false);
  const [openFormExport, setOpenFormExport] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [formType, setFormType] = useState("add");
  const [typingTimer, setTypingTimer] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [offsetListing, setOffsetListing] = useState(0);
  const [scrollListing, setScrollListing] = useState(false);
  const [state, setState] = useState({
    formReady: false,
    dataImportLayout: importProductsConfig,
    importTypeFile: "csv",
    importUrl: "",
    errors: {},
    exportType: "csv",
    exportLang: props.locales[0].node.code,
    exportStatus: null,
    isEmpty: false,
    openForm: false,
    openFormAdd: false,
    drawerWidthModified: props.drawerWidth,
    currentLang: props.locales[0].node.code,
    seeErrors: false,
    ready: false,
    noResult: false,
    pagination: {
      page: 1,
      nbperPage: 6,
      count: 0,
    },
    selectedItems: [],
    attributesSelected: [],
    attributesSelectedExport: [],
    categoriesSelected: [],
    typeTesting: {
      type: "products",
      testingState: ["libelle", "attributeIdentifier", "sku"],
      testingTypingState: "libelle",
      identifierState: "attributeIdentifier",
    },
    skulist: [],
    searchValue: "",
    customAttributes: [],
    categoriesData: [],
  });
  const [exportState, setExportState] = useState({});
  const [filtersOptionsValues, setFiltersOptionsValues] = useState(
    filtersOptions(
      JSON.parse(localStorage.getItem("ATTRIBUTES"))
        .product.attributes.edges.filter((e) => e.node.isSearchable)
        .sort((a, b) =>
          a.node.translation.translationDatas.edges[0].node.value.toLowerCase() <
          b.node.translation.translationDatas.edges[0].node.value.toLowerCase()
            ? -1
            : 1
        )
    )
  );

  useEffect(() => {
    initProduct();
    initForm();
  }, []);

  const initForm = useCallback(async () => {
    const res = await props.client.query({
      query: GET_CATEGORIES_LIGHT_2,
      variables: {
        exists: [
          {
            catalog: false,
          },
        ],
      },
      fetchPolicy: "no-cache",
    });

    handleToggleDrawer("form");

    setState((prevState) => {
      return {
        ...prevState,
        categoriesData: res.data.categories,
        ready: true,
      };
    });
  }, []);

  const resetStateProducts = () => {
    setState({
      ...state,
      importTypeFile: "csv",
      importUrl: "",
      errors: {},
      dataImportLayout: importProductsConfig,
      customAttributes: [],
      allGroups: [],
      categoriesData: [],
      metaAttributes: [],
      imageAttributes: [],
      groupAttribut: "",
      seeErrors: false,
    });
  };

  const resetStateExport = () => {
    setState({
      ...state,
      importTypeFile: "csv",
      importUrl: "",
      errors: {},
      dataImportLayout: importProductsConfig,
      customAttributes: [],
      allGroups: [],
      categoriesData: [],
      metaAttributes: [],
      imageAttributes: [],
      groupAttribut: "",
      seeErrors: false,
    });
  };

  // const handleFormImport = (type) => {
  //     resetStateProducts();
  //     setFormType(type)
  //     setOpenFormImport(!openFormImport);
  // };

  const goToProductDetail = (id) => {
    props.history.push({
      pathname: ROUTE_PRODUCTS_LIST_DETAIL.replace(
        ":id",
        id.replace("/api/products/", "")
      ),
      state: { productId: id },
    });
  };

  const handleDelete = async (productId, multiple = false) => {
    props.startLoading();
    props.client
      .mutate({
        mutation: DELETE_PRODUCT,
        variables: { id: productId },
        fetchPolicy: "no-cache",
      })
      .then(async (result) => {
        if (!multiple) {
          props.snack(ALERT_SUCCESS, "Produit supprimé avec succès");
          props.stopLoading();
          setReloadListing(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetState = () => {
    setState({
      ...state,
      imageSrc: [],
      categories: [],
      errors: {},
    });
  };

  const handleDeleteMultiple = async () => {
    props.startLoading();
    try {
      await Promise.all(
        selectedItems.map(async (item) => await handleDelete(item))
      );
      props.snack(ALERT_SUCCESS, "Produit supprimés avec succès");
      setReloadListing(true);
    } catch (error) {
      props.snack(ALERT_ERROR, "Une erreur est survenue");
    } finally {
      props.stopLoading();
      setOpenDeleteModal(!openDeleteModal);
    }
  };

  const prepareAttributeValues = () => {
    for (let locale of props.locales) {
      let values = {};

      for (let attribute of state.customAttributes) {
        if (attribute.node.attributeType.input === "select") {
          if (attribute.node.attributeOptions.edges.length) {
            values[attribute.node.identifier] = {
              value: attribute.node.isRequired
                ? attribute.node.attributeOptions.edges[0].node.id
                : null,
            };
          }
        }
      }

      setState({
        ...state,
        [locale.node.code]: values,
      });
    }
  };

  const prepareAttributes = () => {
    return new Promise(async (resolve, reject) => {
      let group = state.allGroups.find(
        (e) => e.node.id === state.groupAttribut
      );

      let isSystemAttributes = props.attributes.product.attributes.edges.filter(
        (e) => e.node.isSystem
      );
      let metaAttributes = props.attributes.product.attributes.edges.filter(
        (e) => e.node.isSystem && e.node.identifier.indexOf("meta") > -1
      );
      let customAttributes = group.node.attributes.edges.filter(
        (e) => !e.node.isSystem
      );
      let hasPrice = group.node.attributes.edges.find(
        (e) => e.node.identifier === "product_price"
      )
        ? true
        : false;
      let imageAttributes = isSystemAttributes
        .filter((e) => e.node.attributeType.input === "image")
        .concat(
          customAttributes.filter((e) => e.node.attributeType.input === "image")
        );
      setState((prevState) => ({
        ...prevState,
        isSystemAttributes: isSystemAttributes ?? null,
        metaAttributes: metaAttributes ?? null,
        customAttributes: customAttributes ?? null,
        imageAttributes: imageAttributes ?? null,
        hasPrice: hasPrice ?? null,
        maxImageNumber: imageAttributes.length ?? null,
      }));

      resolve();
    });
  };

  const goTo = (route) => props.history.push(route);

  const initProduct = async () => {
    const groupAttribut =
      props.attributeGroups?.find((e) => e.node.identifier === "default")?.node
        .id ?? "";
    const productEavType = JSON.parse(
      localStorage.getItem("GET_EAV_TYPES")
    )?.data?.eavTypes?.edges.find((e) => e.node.code === "product")?.node?.id;

    const allGroups =
      props.attributeGroups?.filter(
        (e) => e.node.attributes.edges?.[0]?.node.eavType.id === productEavType
      ) ?? [];

    const group = allGroups.find((e) => e.node.id === groupAttribut);

    // await prepareAttributes();

    const customAttributes =
      group?.node.attributes.edges.filter((e) => !e.node.isSystem) ?? [];

    const isSystemAttributes = props.attributes.product.attributes.edges.filter(
      (e) => e.node.isSystem
    );

    const metaAttributes = props.attributes.product.attributes.edges.filter(
      (e) => e.node.isSystem && e.node.identifier.indexOf("meta") > -1
    );

    const hasPrice = group?.node.attributes.edges.find(
      (e) => e.node.identifier === "product_price"
    )
      ? true
      : false;

    const imageAttributes = isSystemAttributes
      ?.filter((e) => e.node.attributeType.input === "image")
      .concat(
        customAttributes?.filter((e) => e.node.attributeType.input === "image")
      );

    prepareAttributeValues();

    for (let locale of props.locales) {
      let values = {};
      for (let attribute of customAttributes) {
        if (
          attribute.node.attributeType.input === "select" &&
          attribute.node.attributeOptions.edges.length
        ) {
          values[attribute.node.identifier] = {
            value: attribute.node.isRequired
              ? attribute.node.attributeOptions.edges[0].node.id
              : null,
          };
        }
      }

      setState((prevState) => {
        return {
          ...prevState,
          sku: null,
          groupAttribut,
          allGroups,
          attributes: [],
          status: true,
          isSystemAttributes: isSystemAttributes ?? null,
          metaAttributes: metaAttributes ?? null,
          customAttributes: customAttributes ?? null,
          imageAttributes: imageAttributes ?? null,
          hasPrice: hasPrice ?? null,
          maxImageNumber: imageAttributes.length ?? null,
          [locale.node.code]: values,
          formReady: true,
        };
      });
    }
  };

  const handleSuccess = async () => {
    initProduct();

    props.snack(ALERT_SUCCESS, "Produit ajouté !");

    handleToggleDrawer("form");
    resetState();

    props.stopLoading();

    goTo(ROUTE_PRODUCTS_LIST);
  };

  const saveAttributes = (product, variants) => {
    return new Promise(async (resolve, reject) => {
      let attributes = state.isSystemAttributes.concat(state.customAttributes);
      let getProductData = [];
      for (let attribute of attributes) {
        for (let locale of props.locales) {
          let formValue = state[locale.node.code][attribute.node.identifier];
          let isMedia =
            attribute.node.attributeType.input === "image" ||
            attribute.node.attributeType.input === "file";
          if (formValue && isMedia) {
            isMedia = true;
          }

          if (formValue && formValue.value) {
            let variables = {
              attributeOption:
                attribute.node.attributeType.input === "select"
                  ? formValue.value
                  : null,
              product: product,
              attribute: attribute.node.id,
              locale: locale.node.id,
              isLocked: formValue.isLocked,
              updatedAt: moment().format("YYYY-MM-DD"),
            };

            if (isMedia) {
              if (variants) {
                variants.image.data
                  ? (variables.media = formValue.value.id)
                  : (variables.media = variants.image.id);
              } else {
                variables.media = formValue.value.id;
              }
            }

            if (!isMedia)
              if (variants) {
                for (let newAttr of variants.allAttr) {
                  if (attribute.node.id === newAttr.id) {
                    variables.attributeOption = newAttr.values;
                  } else {
                    if (attribute.node.attributeType.input !== "select") {
                      variables.value = formValue.value;
                    }
                  }
                }
                if (attribute.node.identifier === "product_price") {
                  variables.value = variants.price.value
                    ? variants.price.value
                    : variants.price;
                }
              } else {
                if (attribute.node.attributeType.input !== "select") {
                  variables.value = formValue.value;
                }
              }
            let resultMutation = await props.client.mutate({
              mutation: ADD_PRODUCT_DATA,
              variables,
            });
            getProductData.push(
              resultMutation.data.createProductData.productData
            );
          }
        }
      }

      resolve(getProductData);
    });
  };

  const createVariant = () => {
    return new Promise(async (resolve, reject) => {
      let getChildren = await getVariantForChildren();
      resolve(getChildren);
    });
  };

  const getVariantForChildren = () => {
    return new Promise(async (resolve, reject) => {
      let values = state.variantsValidated.values;
      let getChildren = [];
      for (let i = 0; i < values.length; ++i) {
        let skuVariant = state.sku + "-variant" + (i + 1);
        let variables = {
          sku: skuVariant,
          attributeGroup: state.groupAttribut,
          categories: state.categories.map((e) =>
            parseInt(e.id.replace("/api/categories/", ""))
          ),
          createdAt: moment().format("YYYY-MM-DD"),
          updatedAt: moment().format("YYYY-MM-DD"),
          status: state.status,
        };
        if (state.selectedProducts) {
          variables.suggestions = state.selectedProducts;
        }
        const ADD_PRODUCT_VARIANT_RESULT = await props.client.mutate({
          mutation: ADD_PRODUCT,
          variables,
        });
        await saveAttributes(
          ADD_PRODUCT_VARIANT_RESULT.data.createProduct.product.id,
          values[i]
        );
        let variantCreate =
          ADD_PRODUCT_VARIANT_RESULT.data.createProduct.product.id;
        getChildren.push(variantCreate);
      }
      resolve(getChildren);
    });
  };

  const handlerMutation = async () => {
    try {
      if (hasErrors("firstForm")) {
        props.snack(ALERT_ERROR, "Veuillez vérifier les champs invalides");
        setState({ ...state, seeErrors: true });
        return eventService.fire();
      }
      props.startLoading();
      let variables = {
        sku: state.sku,
        attributeGroup: state.groupAttribut,
        categories: state.categories.map((e) =>
          parseInt(e.id.replace("/api/categories/", ""))
        ),
        createdAt: moment().format("YYYY-MM-DD"),
        updatedAt: moment().format("YYYY-MM-DD"),
        status: state.status,
        superAttribute: state.isVariant
          ? state.variantsValidated.attributes.map((e) => e.node.id)
          : [],
      };
      if (state.selectedProducts) {
        variables.suggestions = state.selectedProducts;
      }
      const ADD_PRODUCT_RESULT = await props.client.mutate({
        mutation: ADD_PRODUCT,
        variables,
      });

      await saveAttributes(ADD_PRODUCT_RESULT.data.createProduct.product.id);
      if (state.isVariant) {
        const newVariants = await createVariant();
        await props.client.mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            id: ADD_PRODUCT_RESULT.data.createProduct.product.id,
            children: newVariants,
          },
        });
        handleSuccess();
      }

      handleSuccess();
    } catch (e) {
      handleError(e);
    }
  };

  const handleError = (e) => {
    props.snack(ALERT_ERROR, "Une erreur est survenue");
    props.stopLoading();

    if (e.graphQLErrors) {
      for (let error of e.graphQLErrors) {
        console.error("ERROR", `${error.message} =>`, error.debugMessage);
      }
    }
  };

  const doneTyping = (stateName) => {
    let typeTesting = state.typeTesting;
    if (stateName === typeTesting.testingTypingState) {
      setState({
        ...state,
        [typeTesting.identifierState]: slugify(
          state[typeTesting.testingTypingState],
          { replacement: "_", lower: true, remove: /[^\w\-\s]+/g }
        ),
      });
    }

    if (state[typeTesting.identifierState]) {
      request(
        `${process.env.REACT_APP_API}/unique/${typeTesting.type}/${
          state[typeTesting.identifierState]
        }`,
        "get"
      )
        .then((data) => {
          if (data.success) {
            eventService.fire({
              stateName: typeTesting.identifierState,
              errorMessage: props.t("spread.active_assets.userAlreadyUsed"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleFormError = (stateName, error) => {
    let errors = state.errors;
    errors[stateName] = error;
    setState({ ...state, errors: errors ?? {} });
  };

  const handleButtonGroupChange = (stateName, value) => {
    setState({ ...state, [stateName]: value });
  };

  const handleDrawerWidthChange = (width) => {
    setState({ ...state, drawerWidthModified: width });
  };

  const hasErrors = () => {
    if (state.errors) {
      for (let error in state.errors) {
        if (state.errors[error]) return true;
      }
    }
    return false;
  };

  const checkIdentifier = (stateName) => {
    clearTimeout(typingTimer);
    setTypingTimer(
      setTimeout(() => {
        doneTyping(stateName);
      }, 500)
    );
  };

  const setValue = (stateName, value, translated) => {
    if (translated) {
      let values = state[state.currentLang];

      if (!values) {
        values = {};
      }

      values[stateName] = value;

      setState((prevState) => ({
        ...prevState,
        [state.currentLang]: values,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [stateName]: value,
      }));
    }
    if (state.typeTesting.testingState.includes(stateName))
      checkIdentifier(stateName);
  };

  const handleInputChange = (stateName, evt, custom, translated) => {
    const value = evt?.target?.value ?? evt;

    if (stateName === "importSep") {
      setState({
        ...state,
        upload_CSV: null,
        upload_ZIP: null,
      });
    }
    if (custom === "upload_CSV") {
      setState({
        ...state,
        [custom]: value,
      });
    } else {
      setValue(stateName, value, translated);
    }
  };

  const handleMediaPicker = (selected, stateName) => {
    handleInputChange(
      stateName,
      selected,
      null,
      stateName === "imageCatalog" ? false : true
    );
  };

  const handlerMutationDuplicate = async (id) => {
    props.startLoading();
    try {
      await axios(
        `${process.env.REACT_APP_API}/catalog/duplicate/${id.replace(
          "/api/catalogs/",
          ""
        )}/`,
        "GET"
      );
      props.snack(
        ALERT_SUCCESS,
        props.t("catalogs.catalogs.catalogDuplicated")
      );
      setReloadListing(true);
    } catch (error) {
      props.snack(
        ALERT_ERROR,
        props.t("catalogs.catalogs.catalogDuplicatedError")
      );
      console.log(error);
    }
    props.stopLoading();
  };

  const handlerMutationImport = async () => {
    props.startLoading();
    let importConfig = {
      url: `${
        state.importType === "excel"
          ? `${process.env.REACT_APP_API_ROOT}/medias/${state.upload_CSV?.medias?.mediaObject.filePath}`
          : state.upload_CSV?.medias?.contentUrl ?? null
      }`,
      mapper: state.mapper,
      eavType: props.attributes.eavTypes.find((e) => e.node.code === "product")
        .node.id,
      locale: state.importLang,
      delimiter: state.importSep,
      media: state.upload_ZIP
        ? `/api/media-objects/${state.upload_ZIP.medias.id}`
        : null,
      fileType: state.importSep === "xml" ? "simplexml" : null,
    };

    try {
      await request(
        `${process.env.REACT_APP_API_ROOT}/api/file-imports`,
        "post",
        importConfig,
        undefined,
        true
      );
      props.snack(
        ALERT_SUCCESS,
        `Votre fichier a été importé avec succès, son intégration dans Sinfin DXP sera exécutée lors du passage du CRON (Tâche automatique).`,
        6000
      );
      setOpenFormImport(!openFormImport);
      props.stopLoading();
    } catch (e) {
      props.snack(ALERT_ERROR, `L'import a échoué !`);
      props.stopLoading();
    }
  };

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + 0.5 >= offsetListing) {
      setScrollListing(true);
    } else {
      setScrollListing(false);
    }
  };

  const errorMapper = () => {
    let string = "";
    let i = 0;
    for (let value of state.requiredValueMapper) {
      if (!value.checked) {
        if (i > 0) {
          string += ", ";
        } else {
          string += " ";
        }
        string += `"${value.label}"`;
        i++;
      }
    }
    if (i === 1) {
      return `Le champ ${string} est requis`;
    } else {
      return `Les champs${string} sont requis`;
    }
  };

  const handleFormImport = async (type, title) => {
    let separator = {
      type: "select",
      isContainerized: true,
      label: props.t("products.categories.importation.separator"),
      translated: false,
      helper: {
        text: props.t("products.categories.importation.separatorsub"),
        link: false,
      },
      required: false,
      stateName: "importSep",
      value: [
        { value: ",", label: "," },
        { value: ";", label: ";" },
        { value: "|", label: "|" },
        { value: "xml", label: "SimpleXML ( temp )" },
      ],
    };
    let setupUploadFile;
    let setupUploadFileExcel;
    let setupUploadFileZip;
    let setupMapping;

    setupUploadFile = {
      type: "uploadFile",
      label: props.t("products.list.importation.csv"),
      translated: false,
      required: false,
      stateName: `upload_CSV`,
      downloadType: "product",
    };

    setupUploadFileZip = {
      type: "uploadFile",
      label: props.t("products.list.importation.zip"),
      translated: false,
      required: false,
      typeUpload: title === "zip" ? "only-zip" : "zip",
      stateName: `upload_ZIP`,
      multipleStates: title === "zip" ? true : false,
    };

    setupUploadFileExcel = {
      type: "uploadFile",
      label: props.t("products.list.importation.excel"),
      translated: false,
      required: false,
      typeUpload: "xls",
      stateName: `upload_CSV`,
      downloadType: "product",
    };

    setupMapping = {
      type: "mapper",
      label: "",
      translated: false,
      required: false,
      mapOn: `upload_CSV`,
      stateName: `mapper`,
      staticOptions: [],
    };

    let configFormImport = { ...state.dataImportLayout };
    configFormImport.formConfig.children[0].optionsInputs = [];
    configFormImport.formConfig.children[1].optionsInputs = [];

    if (title === "excel") {
      configFormImport.formConfig.children[0].optionsInputs.push(
        setupUploadFileExcel
      );
    } else if (title === "zip") {
      configFormImport.formConfig.children[0].optionsInputs.push(
        setupUploadFileZip
      );
    } else {
      configFormImport.formConfig.children[0].optionsInputs.push(separator);
      configFormImport.formConfig.children[0].optionsInputs.push(
        setupUploadFile
      );
      configFormImport.formConfig.children[0].optionsInputs.push(
        setupUploadFileZip
      );
    }
    configFormImport.formConfig.children[1].optionsInputs.push(setupMapping);

    try {
      setState({
        ...state,
        dataImportLayout: configFormImport,
        catalogFormData: {
          type,
          title,
        },
        mapper: [],
        headers: title === "api" ? "" : null,
        upload_CSV: null,
        upload_ZIP: null,
        importSep: title === "zip" ? ";" : ",",
        importValues: {},
        importLang: props.locales[0].node.id ?? null,
        importType: title,
        importUrl: title === "api" ? "" : null,
      });
      setFormType(type);
      setOpenFormImport(!openFormImport);
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggleDrawer = (drawer) => {
    if (drawer === "form") {
      setState((prevState) => ({
        ...prevState,
        openForm: !state.openForm ?? false,
      }));
    }
    if (drawer === "addVariant") {
      setState((prevState) => {
        if (prevState.variantsValidated?.values?.length > 0) {
          let copyVariantsValidated = [...prevState.variantsValidated.values];

          return {
            ...prevState,
            openFormVariant: !prevState.openFormVariant,
            variantsProduct: copyVariantsValidated,
          };
        }

        return {
          ...prevState,
          openFormVariant: !state.openFormVariant,
          variantsProduct: [],
        };
      });
    }
    setState((prevState) => ({
      ...prevState,
      seeErrors: false,
    }));
  };

  const copy = (array) => {
    let newArray = [];

    for (let elem of array) newArray.push(Object.assign({}, elem));

    return newArray;
  };

  const saveVariant = (drawer) => {
    setState((prevState) => {
      if (prevState.variantsValidated?.values?.length > 0) {
        return {
          ...prevState,
          openFormVariant: !prevState.openFormVariant,
          variantsProduct: [],
          variantsValidated:
            {
              attributes: prevState.attributesSelected,
              values: prevState.variantsValidated.values,
            } ?? null,
        };
      }

      return {
        ...prevState,
        openFormVariant: !state.openFormVariant,
        variantsProduct: [],
      };
    });
  };

  const callbackFilters = (filters, pagination) => {
    let itemToStore = {
      filters,
      pagination,
    };
    setItemsLocalStorage("PIM_FILTERS", itemToStore);
  };

  const handlerMutationExport = async () => {
    props.startLoading();
    if (hasErrors()) {
      props.stopLoading();
      props.snack(ALERT_ERROR, "Veuillez vérifier les champs invalides");
      return eventService.fire();
    }
    try {
      let data = new FormData();
      data.append("name", state.exportName);
      data.append("locale", state.exportLang);
      data.append("format", state.exportType);
      data.append("status", state.exportStatus);
      const arrayOfProductIds = selectedItems.map((e) =>
        e.replace("/api/products/", "")
      );
      arrayOfProductIds.forEach((e, i) => {
        data.append(`productIds[${i}]`, e);
      });
      state.attributesSelectedExport.forEach((e, i) => {
        data.append(`attributeIdentifiers[${i}]`, e.identifier);
      });

      let urlencoded = new URLSearchParams(data).toString();

      request(
        `${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`,
        "get",
        null,
        "application/x-www-form-urlencoded"
      )
        .then(async (data) => {
          if (data.success) {
            window.open(
              `${process.env.REACT_APP_API_ROOT}/medias/export/${
                data.mediaObject.filePath +
                "." +
                (state.exportType === "xls"
                  ? state.exportType + "x"
                  : state.exportType)
              }`,
              "_blank"
            );
            props.stopLoading();
            setOpenFormExport(!openFormExport);
            props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
          } else {
            props.stopLoading();
            setOpenFormExport(!openFormExport);
            props.snack(
              ALERT_ERROR,
              `Un problème est survenu lors de l'exportation !`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      props.snack(ALERT_ERROR, `L'exportation a échoué !`);
      props.stopLoading();
    }
    handleToggleDrawer("openFormExport");
  };

  return (
    <div style={{ overflow: "auto" }} onScroll={handleScroll}>
      <TopPanel
        icomoon="icon-catalogue"
        colorIcomoon={colors.blue.lighter.hue300}
        title={props.t("drawer.products")}
        subtitle={props.t("products.list.topbar.subtitle")}
        dataCount={dataCount}
        getRef
        setRef={setOffsetListing}
        textAdd={props.t("products.list.topbar.add")}
        handlerAdd={() => {
          setOpenFormAdd(!openFormAdd);
        }}
        textImport={"Importer des produits"}
        handlerImport={() => handleFormImport(null, "api")}
        gradientColor1={colors.menu.regular}
        gradientColor2={colors.menu.darker}
        buttonAvailable={true}
        windowWidth={props.windowWidth}
      />
      <Listing
        padding={16}
        scroll={scrollListing}
        label="products"
        settings={listSettings}
        perPageOptions={perPageOptions}
        mappers={listMappers}
        typeResult={"itemsPerPage"}
        pagination={true}
        enablePagination={true}
        enableChangeView={true}
        enableChangeSort={true}
        enableSelectionItems={true}
        enableFilters={true}
        identifier="researchProducts"
        viewsOptions={viewOptions}
        callbackFilters={callbackFilters}
        previousFilters={getItemsLocalStorage("PIM_FILTERS")}
        tableProps={{
          textButtonSecondary: "red",
          redirectDetail: goToProductDetail,
        }}
        queryVariables={{
          localeId: "/api/locales/1",
        }}
        locales={props.locales}
        setDataCount={setDataCount}
        reload={reloadListing}
        setReload={setReloadListing}
        filtersOptions={filtersOptionsValues}
        itemSelection={{
          set: setSelectedItems,
          items: selectedItems,
          customOptionText: "Tous les produits",
        }}
        cardProps={{
          actionButtonDelete: handleDelete,
          redirectDetail: goToProductDetail,
          showPercent: true,
          percent: "completude",
          image: "image",
          title: "libelle",
          imagesNb: "imagesNb",
          imagesMaxNb: "imagesMaxNb",
          sku: "sku",
          attributes: [
            {
              label: "Statut",
              value: "status",
              type: "status",
            },
            {
              label: "Variantes",
              value: "childrenNb",
            },
            {
              label: "Catalogues",
              value: "catalogsNb",
            },
            {
              label: "Catégories",
              value: "categoriesNb",
            },
          ],
          dottedMenuOptions: [
            {
              label: "Dupliquer",
              color: colors.blue.darker.hue300,
              isDisabled: false,
              icon: (
                <FileCopySharpIcon
                  style={{ fill: colors.blue.darker.hue300 }}
                />
              ),
              action: (id) => handlerMutationDuplicate(id),
            },
            {
              label: "Supprimer",
              color: colors.red.regular,
              icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
              modal: true,
              modalProps: {
                identifier: "deleteCatalog",
                type: "delete",
                title: "Êtes-vous sûr de vouloir supprimer ce catalogue ? here",
                subtiltle: "Cette action est irréversible",
                actions: {
                  primaryAction: (id) => handleDelete(id),
                },
              },
            },
          ],
        }}
        actions={{
          export: {
            toolTip: "Exporter la séléction",
            handler: (type) => {
              if (type === "all") {
                setSelectedItems([]);
              }
              setOpenFormExport(!openFormExport);
            },
          },
          delete: {
            toolTip: "Supprimer la séléction",
            handler: () => setOpenDeleteModal(!openFormExport),
          },
        }}
      />
      
      <OurDrawer
        isOpen={openFormAdd}
        setIsOpen={() => setOpenFormAdd(!openFormAdd)}
      >
        <ProductForm />
      </OurDrawer>

      <ExportProducts
        errorMapper={errorMapper}
        setState={setExportState}
        stateCallback={handleInputChange}
        state={state}
        resetState={resetStateExport}
        handlerMutation={handlerMutationExport}
        allLangs={props.locales}
        t={props.t}
        allGroups={state.allGroups}
        setOpenFormExport={setOpenFormExport}
        openFormExport={openFormExport}
        productNb={selectedItems.length}
      />
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(!openDeleteModal)}
        title={`Êtes-vous sur de vouloir supprimer ${selectedItems?.length} produits ?`}
        subtitle={"Cette action est irréversible"}
        primaryAction={() => handleDeleteMultiple(selectedItems)}
        secondaryAction={() => setOpenDeleteModal(!openDeleteModal)}
      />

      {
        // Form import product
      }
      <OurDrawer
        isOpen={openFormImport}
        setIsOpen={() => setOpenFormImport(!openFormImport)}
      >
        <ImportProductsForm
          onSuccess={() => setOpenFormImport(!openFormImport)}
        />
      </OurDrawer>

      {/* {
        // Form add variant
      }
      <LayoutFormBuilder
        scrollable={true}
        isSublayout={false}
        validateButton={true}
        // handleCancel={resetVariant}
        opened={state.openFormVariant}
        forClose={() => handleToggleDrawer("addVariant")}
        handlerMutation={() => saveVariant("addVariant")}
        icomoon={"ico-modifier-produit"}
        //noCancel={true}
        dataLayout={formVariantProductAdd(
          state.currentLang,
          state.customAttributes,
          state.attributesSelected,
          state[state.currentLang]?.product_price,
          state.variantsValidated,
          state.imagesSelected ? state.imagesSelected[0]?.original : "",
          handleMediaPicker,
          state.hasPrice
        )}
        backStepperButtonAction={[
          () => {
            setState({ errors: {} });
          },
          () => {
            setState({ errors: {} });
          },
          () => {
            setState({ errors: {} });
          },
          null,
        ]}
        stepperButtonDisabled={[
          null,
          () => state.variantsProduct?.length === 0,
        ]}
        stepperButtonAction={[
          () => {
            if (hasErrors()) {
              props.snack(
                ALERT_ERROR,
                "Veuillez vérifier les champs invalides"
              );
              setState({ seeErrors: true });
              eventService.fire();
              return false;
            }
            return true;
          },
          () => {
            if (hasErrors()) {
              props.snack(
                ALERT_ERROR,
                "Veuillez vérifier les champs invalides"
              );
              setState({ seeErrors: true });
              eventService.fire();
              return false;
            }
            return true;
          },
          null,
        ]}
        handleButtonGroupChange={handleButtonGroupChange}
        allState={state}
        stateCallback={handleInputChange}
        // errorCallback={handleSecondFormError}
        currentLang={state.currentLang}
        // handleLang={handleLang}
        drawerWidth={props.drawerWidth}
        deleteButton={false}
      /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
    attributeGroups: state.attributeGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
    setAttributes: (attributes) =>
      dispatch({ type: SET_ATTRIBUTES, payload: { attributes } }),
  };
};

export default withTranslation()(
  withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Products)))
);
