import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function merchandisingEdit(action='add', categories, currentLang) {
    let generalInputs = []
    if (action === 'add'){
        generalInputs.push(
            {
                type: 'select',
                isContainerized: true,
                label: `Choix de la catégorie`,
                helper: {
                    text: `Indiquez la catégorie pour le merchandising`,
                    link: false,
                },
                translated: false,
                required: true,
                stateName: 'merchandisingCategory',
                value: categories.map(category => {
                    const getCatNames = category.categoryDatas.edges.filter(e => e.node.attribute.identifier === "category_name")
                    const langSelectedValuesDefault = getCatNames?.[0];
                    const langSelectedValues = getCatNames?.find(
                        lang  => lang.node.locale.code === currentLang
                    );
    
                    return ({
                        value: category.id,
                        label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node.value ?? category.libelle
                    });
                })
            },
        )
    }
    generalInputs.push(
        {
            required : true,
            type : 'text',
            label : 'Libellé de la version',
            stateName : 'merchandisingLibelle',
            helper: {
                text: 'Nom de la version du merchandising',
                link: false,
            },
        }, 
        {
            type: 'date',
            label: 'Date de début',
            helper: {
                text: 'Date de début',
                link: false,
            },
            required: true,
            stateName: 'merchandisingStartAt',
        },
        {
            type: 'date',
            label: 'Date de fin',
            helper: {
                text: 'Date de fin',
                link: false,
            },
            required: true,
            stateName: 'merchandisingEndAt',
        },
    )
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? 'Créer un merchandising':'Créer une version',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer votre merchandising':'créer votre version'}`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: action === 'add'? 'Créer ce merchandising' : 'Créer cette version',
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations générales',
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}