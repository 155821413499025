import React from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import H2 from '../../../../shared/components/CustomH2';
import H3 from '../../../../shared/components/CustomH3';

export default ({ data, preview, component, block, inputCallback, className }) => {
    var dotations = data.blocks[block].config;
    return( 
        <div className="dotations">
            <div>
                {
                    data.blocks.dotationsTitle ? (
                        <H2 data={data} preview={preview} component={component} block={'dotationsTitle'} inputCallback={inputCallback} />
                    ) : null
                }
                {
                    data.blocks.dotationsSubtitle ? (
                        <H3 className="center" data={data} preview={preview} component={component} block={'dotationsSubtitle'} inputCallback={inputCallback} />
                    ) : null
                }
            </div>
            {
                dotations.length > 0 ?
                    <div className="list">
                        {
                            dotations.map((dotation, index) =>{                                          
                                return(
                                    <div className="dotation" onClick={() => inputCallback(component, block)} id={`dotation-${index}`} key={`dotation-${index}`}>
                                        <div>
                                            {
                                                dotation.blocks.link
                                                ?<a href={`${dotation.blocks.link.inputs.value.value}`}>
                                                    <img 
                                                        className="img_center"
                                                        src={
                                                            typeof dotation.blocks.image.inputs.value.value === 'string' && dotation.blocks.image.inputs.value.value !== '' ? 
                                                                dotation.blocks.image.inputs.value.value : 
                                                                dotation.blocks.image.inputs.value?.value?.filePath ? 
                                                                    ` ${process.env.REACT_APP_MEDIAS}/${dotation.blocks.image.inputs.value.value.filePath}` 
                                                                    : `http://via.placeholder.com/280x270/FFFFFF/53839D?text=${index+1}`
                                                        }
                                                        alt={`Dotation ${index+1}`}
                                                    />
                                                </a>
                                                :<img 
                                                    className="img_center"
                                                    src={
                                                        typeof dotation.blocks.image.inputs.value.value === 'string' && dotation.blocks.image.inputs.value.value !== '' ? 
                                                            dotation.blocks.image.inputs.value.value : 
                                                            dotation.blocks.image.inputs.value?.value?.filePath ? 
                                                                ` ${process.env.REACT_APP_MEDIAS}/${dotation.blocks.image.inputs.value.value.filePath}` 
                                                                : `http://via.placeholder.com/280x270/FFFFFF/53839D?text=${index+1}`
                                                    }
                                                    alt={`Dotation ${index+1}`}
                                                />
                                            }                    
                                        </div>
                                        <Pg data={dotation} preview={preview} component={dotation.blocks.text.id} block={'text'} inputCallback={() => inputCallback(component, block)} nopadding={true} />
                                    </div>
                                ) 
                            })
                        }
                    </div>
                : null
            }
            {
               data.blocks.moreDotations ?
                    <div>
                        <Pg data={data} preview={preview} component={component} block={'moreDotations'} inputCallback={inputCallback} nopadding={true} />
                    </div>
               : null
            }
        </div>
    )
};