import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";

import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
  SET_DRAWER_STATE,
  SET_GUIDELINE,
} from "@constants/action-types";
import {
  ROUTE_SETTINGS_GUIDELINE_ADD,
  ROUTE_SETTINGS_GUIDELINE_UPDATE,
} from "@constants/route-names";
import {
  GET_BRAND_GUIDELINE,
  DELETE_GUIDELINE,
} from "@queries/brand_guideline";

import colors from "@config/theme/colors";

import { Box, Grid } from "@mui/material";
import PageLoader from "@ui/loadings/page-loader/PageLoader";

import EmptyCard from "@ui/empty-card/EmptyCard";
import Empty from "@assets/pictos/empty-picto/empty_guidelines.png";

import CardCustom from "@components/layouts/Card/CardCustom";
import CardGuideline from "./components/card/CardGuideline";
import TopPanel from "@components/layouts/TopPanel/TopPanel";
import { checkRouting } from "@utils/checkRouting";
import styled from "styled-components";
import { goTo } from "@utils/goTo";
import GuidelineForm from "@ui/form/GuidelineForm";
import CustomDrawer from "@layouts/Drawer";

import DialogContentText from "@mui/material/DialogContentText";
import DialogModal from "@ui/dialog/DialogModal";
import ColorLensSharpIcon from "@mui/icons-material/ColorLensSharp";
import TitleIcon from "@mui/icons-material/Title";

const PageWrapper = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  overflow: scroll;
  position: relative;
`;

const GuidelineBrand = (props) => {
  const currentLang = props.locales[0].node.code;
  const content = {
    emptyTitle: "Vous n’avez pas encore paramétré votre guideline",
    emptySubtitle: "Cliquez ci-dessous commencer la configuration",
    emptyTxtBtn: "Créer ma guideline",
    emptyPicto: Empty,
  };

  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [guidelines, setGuidelines] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [guidelineEdit, setGuidelineEdit] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);

  useEffect(() => {
    if (isEmpty) goTo(props.history, ROUTE_SETTINGS_GUIDELINE_ADD);
  }, [isEmpty]);

  useEffect(() => {
    checkRouting(props);
    fetchGuidelines();
  }, []);

  useEffect(() => {
    if (!openForm) {
      setGuidelineEdit(null);
    }
  }, [openForm]);

  const fetchGuidelines = async () => {
    setLoading(true);
    props.client
      .query({
        query: GET_BRAND_GUIDELINE,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        result.data.guidelines.edges.length === 0
          ? setIsEmpty(true)
          : setGuidelines(result.data.guidelines.edges);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        props.snack(
          "error",
          "Une erreur est survenue lors du chargement des données."
        );
      });
  };

  const handleDelete = () => {
    props.startLoading();
    props.client
      .mutate({
        mutation: DELETE_GUIDELINE,
        variables: { id: guidelineEdit.id },
      })
      .then(async (result) => {
        props.snack("success", "La charte graphique a bien été supprimée.");
        setModalDelete(false);
        fetchGuidelines();
        props.stopLoading();
      })
      .catch((err) => {
        props.stopLoading();
      });
  };

  return (
    <PageWrapper>
      <TopPanel
        icomoon="ico_guideline"
        colorIcomoon={colors.blue.darker.hue300}
        title="Guideline"
        subtitle="Gestion de votre image de marque"
        handlerAdd={() => {
          setOpenForm(true);
        }}
        buttonAvailable={true}
        textAdd="Ajouter une charte graphique"
        gradientColor1={colors.menu.regular}
        gradientColor2={colors.menu.darker}
        windowWidth={props.windowWidth}
        currentLang={currentLang}
        handleLang={null}
        locales={props.locales}
        hasBorder={true}
      />
      <Box
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        {loading && <PageLoader />}

        {!loading && !isEmpty && guidelines.length > 0 && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={0}
            style={{ width: "100%" }}
          >
            <Grid container direction="row" spacing={4}>
              {guidelines.map((guideline, index) => (
                <Grid
                  item
                  xl={4}
                  sm={4}
                  xs={12}
                  style={{ display: "flex", flexWrap: "wrap" }}
                  key={`card-guideline-${index}`}
                >
                  <CardCustom
                    cardContentStyle={{ padding: 0, width: "100%" }}
                    style={{ width: "100%" }}
                  >
                    <CardGuideline
                      guideline={guideline.node}
                      onClick={() => {
                        goTo(
                          props.history,
                          ROUTE_SETTINGS_GUIDELINE_UPDATE.replace(
                            ":id",
                            guideline.node.id.split("/").pop()
                          )
                        );
                      }}
                      actionButtons={[
                        {
                          label: "Modifier",
                          icon: <ColorLensSharpIcon />,
                          onClick: () => {
                            setGuidelineEdit(guideline.node);
                            setOpenForm(true);
                          },
                        },
                        {
                          label: "Supprimer",
                          icon: <TitleIcon />,
                          onClick: () => {
                            setModalDelete(true);
                            setGuidelineEdit(guideline.node);
                          },
                        },
                      ]}
                    />
                  </CardCustom>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {!loading && isEmpty && guidelines.length === 0 && (
          <EmptyCard
            title={content.emptyTitle}
            subtitle={content.emptySubtitle}
            textButton={content.emptyTxtBtn}
            onClick={() => {
              goTo(props.history, ROUTE_SETTINGS_GUIDELINE_ADD);
            }}
            picto={content.emptyPicto}
            openForm={true}
            xsImg={4}
          />
        )}
      </Box>

      <CustomDrawer
        isOpen={openForm}
        setIsOpen={setOpenForm}
        hideBackdrop={false}
      >
        <GuidelineForm
          guideline={guidelineEdit}
          onSuccess={() => {
            fetchGuidelines();
            setOpenForm(false);
          }}
        />
      </CustomDrawer>

      <DialogModal
        icon={true}
        type="delete"
        open={modalDelete}
        onClose={() => setModalDelete(false)}
        title={"Êtes-vous sûr de vouloir supprimer cette charte graphique ?"}
        primaryAction={handleDelete}
        secondaryAction={() => setModalDelete(false)}
        // windowWidth={props.windowWidth}
      >
        <DialogContentText id="alert-dialog-description">
          Si vous supprimez cette charte graphique celle-ci ne sera plus
          accessible. Si vous ne souhaitez pas la supprimer, annulez la
          suppression en cliquant sur annuler.
        </DialogContentText>
      </DialogModal>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    users: state.users,
    locales: state.locales,
    drawerState: state.drawerState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGuideline: (guideline) =>
      dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
    setDrawerState: (state) =>
      dispatch({ type: SET_DRAWER_STATE, payload: state }),
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(GuidelineBrand))
);
