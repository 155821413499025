import PropTypes from "prop-types";
import React, { useState } from "react";
import H2 from '../../../../../shared/components/CustomH2';
import Button from '../../../../../shared/components/CustomLink';


const RedirectOn = (props) => {
    let {
        preview = false,
        id = null, 
        data,
        inputCallback = null,
    } = props;
    
    let image = data.inputs['backgroundImage'].value;

    return (
        <div className="results">
            <section 
                className="bandeau" 
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: 'cover!important',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                }}
            >
                { !preview && props.children }
                <div className="block">
                    <img onClick={() => inputCallback(id, 'image')} src={data.blocks.image.inputs.value?.value?.filePath  ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` : typeof(data.blocks.image.inputs.value?.value) === "string" ? data.blocks.image.inputs.value.value : "http://via.placeholder.com/500x300/FFFFFF/53839D?text=Image"} />
                </div>
                <div className="block">
                    <H2 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                    <Button data={data} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="as_button" nopadding={true} />
                </div>
            </section>
        </div>
    );
};

RedirectOn.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default RedirectOn;
