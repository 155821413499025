import React from "react";

const TabPanel = ({ value, index, ...props }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...props}
    >
      {value === index && <div>{props.children}</div>}
    </div>
  );
};

export default TabPanel;
