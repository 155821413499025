import moment from 'moment';
import { FormInputTypes } from '../../../../../builder/shareable/types';

const currentUser = JSON.parse(localStorage.getItem('AUTH_USER'))


export const listSettings =(props)=> {
    return {
    table : {
        style : {
            type : 'striped',
        },
        columns : [
            {
                label   : 'Statut GAIA',
                field   :  'importState',
                cellType : 'status',
                cellProps : {
                    width : '7%',
                }
            },
            {
                label   : 'Date',
                field   :  'created_at',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'Distributeur',
                field   :  'channels',
                cellType : 'avatar',
                cellProps : {
                    width : '10%',
                    withText:true
                }
            },
            {
                label   : 'N°SKU',
                field   :  'sku',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'Statut TP',
                field   :  'retailerState',
                cellType : 'status',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'Assignation',
                field   :  'user',
                cellType : 'select',
                cellProps : {
                    width : '15%',
                },
                input:{
                    type: 'select',
                    isContainerized: true,
                    width:"80%",
                    helper: {
                        text: '',
                        link: false,
                    },
                    stateName: 'user',
                    translated: false,
                    disabled:false,
                    defaultValue:" ",
                    value: (()=>{
                        let allUsers = !props.users ? []: props?.users?.map((user,i)=>{
                            return {
                                value: user.node.id,
                                label: `${user.node.firstname} ${user.node.lastname}` 
                            }
                        })
                        return allUsers
                    })() 
                },
            },
            {
                label   : "Description de l'erreur",
                field   :  'errors',
                cellType : 'cta_drawer',
                cellProps : {
                    width : '10%',
                }
            },
        ]   
    }
}}

export const listMappers = [
    {
        view : 'table',
        mapper : 'workflowMapper'
    }
]

export const perPageOptions = {
    'table_workflow' : [12]
}

export const filtersSettings =(props)=>{
    const {users,state,currentUser,tradingPartners} = props
    return [
    {
        xs: 2,
        required: false,
        disabled: false,
        label:'Rechercher par SKU...',
        type: FormInputTypes.TEXT,
        stateName: 'sku',
        helper: {
            position: 'top',
            text: 'SKU number',
            link: false,
        }
    },
    {
        xs: 2,
        required: false,
        disabled: false,
        defaultValue:" ",
        type: FormInputTypes.SELECT,
        stateName: 'channel',
        helper: {
            position: 'top',
            text: 'Trading Partner',
            link: false,
        },
        value: (()=>{
            let allTP = tradingPartners?.map((tp,i)=>{
                return {
                    value: tp.node.id,
                    label: tp.node.libelle 
                }
            })
            allTP.unshift({
                value: " ",
                label: 'Tous les TP'
            })
            return allTP
        })() 
    },
    {
        xs: 2,
        required: false,
        disabled: false,
        type: FormInputTypes.SELECT,
        stateName: 'user',
        defaultValue:" ",
        helper: {
            position: 'top',
            text: 'Assignation',
            link: false,
        },
        value: (()=>{
            let allUsers = users?.map((user,i)=>{
                return {
                    value: user.node.id,
                    label: `${user.node.firstname} ${user.node.lastname}` 
                }
            })
            allUsers.unshift({
                value: " ",
                label: 'Toutes les assignations'
            })
            return allUsers
        })() 
    },
    {
        xs: 1,
        required: false,
        disabled: false,
        type: FormInputTypes.DATE_FROM_TO,
        label: 'date',
        stateName: 'dateFrom',
        format:"yyyy-MM-DD 00:00:00",
        startValue:moment().startOf('month').format('yyyy-MM-DD'),
        maxDate:moment().format('yyyy-MM-DD'),
        helper: {
            position: 'top',
            text: 'Date de début',
            link: false,
        }
    },
    {
        xs: 1,
        required: false,
        disabled: false,
        type: FormInputTypes.DATE_FROM_TO,
        label: 'date',
        stateName: 'dateTo',
        format:"yyyy-MM-DD 23:00:00",
        startValue:moment(),
        maxDate:moment().format('yyyy-MM-DD'),
        helper: {
            position: 'top',
            text: 'Date de fin',
            link: false,
        }
    },
    {
        xs: 2,
        type: 'select',
        isContainerized: true,
        // multiselect : true,
        label: 'Type',
        defaultValue:" ",
        translated: false,
        helper: {
            position: 'top',
            text: 'Statut GAIA',
            link: false,
        },
        required: false,
        stateName: 'importState',
        value: [
            {
                value: " ",
                label: 'Tous',
            },
            {
                value: 2,
                label: 'Rejeté',
            },
            {
                value: 1,
                label: 'Accepté',
            },
        ]
    },
    {
        xs: 2,
        type: 'select',
        isContainerized: true,
        // multiselect : true,
        label: 'Type',
        defaultValue:" ",
        translated: false,
        helper: {
            position: 'top',
            text: 'Statut Trading Partner',
            link: false,
        },
        required: false,
        stateName: 'retailerState',
        value: [
            {
                value: " ",
                label: 'Tous',
            },
            {
                value: 3,
                label: 'Synchronized',
            },
            {
                value: 1,
                label: "Reviewed",
            },
            {
                value: 2,
                label: 'Envoyé',
            },
        ]
    }
]
}