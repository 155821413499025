import { Box, Grid } from "@mui/material";
import IcomoonReact from "icomoon-react";
import colors from "../../../../../config/theme/colors";
import iconSet from "../../../../../assets/selection.json";
import OurTypography from "../../../../ui/typography/Typography";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { goTo } from "../../../../../js/utils/goTo";

// Create a styled component for a Grid which has a grey border on right side unless it is the last item
const StyledGrid = styled(Grid)`
  height: 100%;
  padding: 16px;
  position: relative;
  /* border-right: ${(props) => (props.isLast ? "none" : "1px solid #E0E0E0")}; */
  &::after {
    content: "";
    display: ${(props) => (props.isLast ? "none" : "block")};
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #e0e0e0;

  }
`;

const StatsCard = (props) => {
  return (
    <StyledGrid container direction={"column"} justifyContent={'space-between'} isLast={props.isLast} isFirst={props.index === 0}>
      <Grid item>
        <Grid container>
          {typeof props.stat.icone !== 'string'
            ? props.stat.icone
            : <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={'20px'} icon={props.stat.icone} />
          }
          <OurTypography style={{ marginLeft: '10px' }} fontweighttext={'bold'}>{props.stat.title}</OurTypography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Box style={{ fontSize: 16, fontWeight: 'bold' }}>
              <span style={{ fontSize: props.stat.total ? 30 : 40 }}>{props.stat.totalin}</span>
              {props.stat.totalin && props.stat.total ? '/' : ''}
              {props.stat.total}
            </Box>
          </Grid>
          <Grid item style={{alignSelf: 'flex-end', fontWeight: 'bold'}}>
            <Box onClick={() => goTo(props.history,props.stat.route)} style={{
              color: colors.blue.darker.hue300,
              cursor: 'pointer',
              textDecoration: 'underline',
            }}>Voir</Box>
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  )
}

export default withRouter(StatsCard);