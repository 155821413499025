import moment from "moment"
import { FormInputTypes } from "../../../../../builder/shareable/types"


export const listSettings =(props)=> {
    return {
    table : {
        style : {
            type : 'striped',
        },
        columns : [
            {
                label   : 'Date',
                field   :  'created_at',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'Distributeur',
                field   :  'channels',
                cellType : 'avatar',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'N°SKU',
                field   :  'sku',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'Status',
                field   :  'state',
                cellType : 'stateStatus',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'Assignation',
                field   :  'user',
                cellType : 'select',
                cellProps : {
                    width : '15%',
                },
                input:{
                    type: 'select',
                    isContainerized: true,
                    width:"80%",
                    helper: {
                        text: '',
                        link: false,
                    },
                    stateName: 'user',
                    translated: false,
                    disabled:  false,
                    defaultValue:" ",
                    value: (()=>{
                        let allUsers = !props.users ? []: props?.users?.map((user,i)=>{
                            return {
                                value: user.node.id,
                                label: `${user.node.firstname} ${user.node.lastname}` 
                            }
                        })
                        return allUsers
                    })() 
                },
            },
            {
                label   : 'Priorité',
                field   :  'priority',
                cellType : 'select',
                cellProps : {
                    width : '10%',
                },
                input:{
                    type: 'select',
                    isContainerized: true,
                    label: 'products.categories.exporter.filename',
                    helper: {
                        text: '',
                        link: false,
                    },
                    width:"80%",
                    stateName: 'priority',
                    translated: false,
                    disabled:false,
                    value:[
                        {
                            label:"Moyenne",
                            value:1,
                        },
                        {
                            label:"Haute",
                            value:2,
                        },
                    ]
                },
            },
            {
                label   : "Description de l'erreur",
                field   :  'errors',
                cellType : 'cta_drawer',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : "Action",
                field   :  'ok',
                cellType : 'link',
                cellProps : {
                    width : '10%',
                }
            },
        ]   
    }
}}

export const listMappers = [
    {
        view : 'table',
        mapper : 'workflowMapper'
    }
]

export const perPageOptions = {
    'table_workflow' : ['12']
}


export const filtersSettings =(props)=>{
    const {users,tradingPartners} = props
    return [
        {
            xs: 2,
            required: false,
            disabled: false,
            defaultValue:" ",
            type: FormInputTypes.SELECT,
            stateName: 'channel',
            helper: {
                position: 'top',
                text: 'Trading Partner',
                link: false,
            },
            value: (()=>{
                let allTP = tradingPartners?.map((tp,i)=>{
                    return {
                        value: tp.node.id,
                        label: tp.node.libelle 
                    }
                })
                allTP.unshift({
                    value: " ",
                    label: 'Tous les TP'
                })
                return allTP
            })() 
        },
    {
        xs: 3,
        required: false,
        disabled: false,
        label:'Rechercher par SKU...',
        type: FormInputTypes.TEXT,
        stateName: 'sku',
        helper: {
            position: 'top',
            text: 'SKU number',
            link: false,
        }
    },
    {
        xs: 2,
        required: false,
        disabled: false,
        defaultValue:" ",
        type: FormInputTypes.SELECT,
        stateName: 'state',
        helper: {
            position: 'top',
            text: 'Statut',
            link: false,
        },
        value:[
            {
                value: " ",
                label: 'Tous les statuts'
            },
            {
                value: 2,
                label: 'Contribution'
            },
            {
                value: 1,
                label: 'Correction'
            },
        ]
    },
    {
        xs: 1,
        required: false,
        disabled: false,
        type: FormInputTypes.DATE_FROM_TO,
        label: 'date',
        stateName: 'dateFrom',
        format:"yyyy-MM-DD 00:00:00",
        startValue:moment().startOf('month').format('yyyy-MM-DD'),
        maxDate:moment().format('yyyy-MM-DD'),
        helper: {
            position: 'top',
            text: 'Date de début',
            link: false,
        }
    },
    {
        xs: 1,
        required: false,
        disabled: false,
        type: FormInputTypes.DATE_FROM_TO,
        label: 'date',
        stateName: 'dateTo',
        format:"yyyy-MM-DD 23:00:00",
        startValue:moment(),
        maxDate:moment().format('yyyy-MM-DD'),
        helper: {
            position: 'top',
            text: 'Date de fin',
            link: false,
        }
    },
    {
        xs: 2,
        required: false,
        disabled: false,
        type: FormInputTypes.SELECT,
        stateName: 'user',
        defaultValue:" ",
        helper: {
            position: 'top',
            text: 'Assignation',
            link: false,
        },
        value: (()=>{
            let allUsers = users?.map((user,i)=>{
                return {
                    value: user.node.id,
                    label: `${user.node.firstname} ${user.node.lastname}` 
                }
            })
            allUsers.unshift({
                value: " ",
                label: 'Toutes les assignations'
            })
            return allUsers
        })() 
    },
    {
        xs: 2,
        required: false,
        disabled: false,
        defaultValue:" ",
        type: FormInputTypes.SELECT,
        stateName: 'priority',
        helper: {
            position: 'top',
            text: 'Priorité',
            link: false,
        },
        value:[
            {
                value: " ",
                label: 'Toutes les priorités'
            },
            {
                value: 1,
                label: 'Moyenne'
            },
            {
                value: 2,
                label: 'Haute'
            }
        ]
    }
]
}