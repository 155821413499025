import Swiper from "react-id-swiper"
import CardMerchandisingVersions from "../Card/cardContent/CardMerchandisingVersions";
import CardCustom from "../Card/CardCustom";
import { Box } from "@mui/material";
import colors from "../../../config/theme/colors";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from 'styled-components';

// const Arrow = styled.div`
//     position:absolute;
//     top:50%;
//     transform:translateY(-50%);
//     z-index:1;
//     color:black;
//     border:1px solid black;
//     border-radius:100%;
//     cursor:pointer;
//     padding:5px;    
//     background-color:white;
// `

const CarouselListing = (props)=>{
    const {currentLang,propsToPass}= props
    const items= props.items.card

    const params = {
        slidesPerView: 3.5,
        spaceBetween: 10,
        pagination: false,
        // navigation: {
        //     nextEl: '.swiper-button-next-catalog',
        //     prevEl: '.swiper-button-prev-catalog'
        //   },
        // renderPrevButton: () => <Arrow style={{left:0}}><ArrowBackIosIcon style={{fontSize: 20}}/></Arrow>,
        // renderNextButton: () => <Arrow style={{right:0}}><ArrowForwardIosIcon style={{fontSize: 20}}/></Arrow>,
    }

    return (
        <Box style={{
            width:"100%"
        }} className='listing'>
            <Swiper {...params}>
                {
                    items.map((item,index)=>{
                        return <div style={{backgroundColor:"white",width:"auto",border:"0.5px solid #D0D0D1",minWidth:"362px"}}>
                            <CardMerchandisingVersions data={item} key={index} {...props.cardProps} {...propsToPass} currentLang={currentLang}/>
                        </div> 
                    })
                }                      
            </Swiper>
        </Box>
    )
}

export default CarouselListing