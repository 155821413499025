import { goTo } from "../../../../../js/utils/goTo";
import { GridCustom, LinkText, HelperText } from "../../styles/inputStyled";
import { TextAreaSimpleFieldCustom } from "./styles/styled";


const TextAreaSimple = ({ allProps: props, seeError, errorMessage, setSeeError, checkError }) => {
    return (
        <GridCustom item xs={12}>
            <TextAreaSimpleFieldCustom
                id={props.input.stateName}
                variant="outlined"
                value={props.value ? props.value : ''}
                helperText={seeError ? errorMessage : null}
                fullWidth
                multiline
                rows={4}
                onChange={evt => {
                    setSeeError(true);
                    checkError(evt.target.value);
                    props.stateCallback(evt);
                }}
                name={props.input.stateName}
                placeholder={props.input.label}
                disabled={props.input.disabled ? true : false}
                error={seeError && errorMessage ? true : false}
                spellcheck={true}
                lang="fr"
            />

            {
                seeError && errorMessage ? null :
                    props.input.helper.link
                        ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText>
                        :
                        <>
                            <HelperText variant="body2">
                                {props.input.helper.text}
                            </HelperText>
                            {props.input.helper.charCount ?
                                <HelperText variant="body2">
                                    {`${props.value ? props.value.length : 0} / ${props.input.helper.maxChar}`}
                                </HelperText>
                                : null}
                        </>
            }
        </GridCustom>
    )
}

export default TextAreaSimple;