import colors from "../../../../../../config/theme/colors";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';

export const dottedMenuConfig = ({deleteAction,duplicateAction}) => {
  const array = []
  if (duplicateAction) {
    array.push({
      label: 'Dupliquer',
      color: colors.blue.darker.hue300,
      isDisabled: false,
      icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
      modal: true,
      modalProps: {
        type: "info",
        title: 'Êtes-vous sûr de vouloir dupliquer ce catalogue ?',
        subtitle: "Vous allez dupliquer ce catalogue",
        actions: {
          primaryAction: (id) => duplicateAction(id)
        }
      },
    })
  }
  if (deleteAction) {
    array.push({
      label: 'Supprimer',
      color: colors.red.regular,
      icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
      modal: true,
      modalProps: {
        identifier: 'deleteCatalog',
        type: "delete",
        title: 'Êtes-vous sûr de vouloir supprimer ce catalogue ?',
        subtitle: "Cette action est irréversible",
        actions: {
          primaryAction: (id) => deleteAction(id),
        }
      },
    })
  }
  return array
}