import React from "react";
import colors from "@config/theme/colors";
import styled from "styled-components";
import PropTypes from "prop-types";

const TypographyCard = ({ typographySystem, typographyDatas, listMarkups }) => {
  const DivMarkups = styled.div`
    border: 1px solid lightgrey !important;
    border-top: 0 !important;
    background: white;
    width: 100%;
    padding: 6px 16px;
    & > div:first-child {
      display: flex;
      align-items: center;
      padding: 6px 0px;
    }
  `;

  const DivColor = styled.div`
    border: 1px solid lightgrey !important;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    position: relative;
  `;

  return (
    <div style={{ width: "100%" }}>
      <DivColor style={{ cursor: "pointer" }}>
        <p>{typographySystem?.label}</p>
        <p
          style={{
            color: colors.blue.darker.hue300,
            textDecoration: "underline",
            position: "absolute",
            right: 20,
          }}
        >
          Modifier
        </p>
      </DivColor>
      <DivMarkups>
        {listMarkups ? (
          <>
            {listMarkups.map((markup, index) => {
              const typographyData = typographyDatas.find(
                (data) => data?.markup?.id === markup.id
              );

              return (
                <p
                  style={{
                    fontFamily: typographySystem?.value,
                    margin: 0,
                    lineHeight: "1.3",
                    fontSize: 20,
                  }}
                  key={`typopicker-markup-${index}`}
                >
                  {markup.libelle} : {typographyData.px}px
                </p>
              );
            })}
          </>
        ) : (
          "Aucune valeur"
        )}
      </DivMarkups>
    </div>
  );
};

TypographyCard.propTypes = {
  typographySystem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  typographyDatas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      px: PropTypes.string.isRequired,
      markup: PropTypes.shape({
        id: PropTypes.string.isRequired,
        libelle: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ),
  listMarkups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      libelle: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default TypographyCard;
