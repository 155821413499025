import styled from "styled-components";
import { InputFieldCustom } from "../../../styles/styled";
import { DatePicker } from "@mui/x-date-pickers";
import colors from "../../../../../../config/theme/colors";
import { Box, Grid } from "@mui/material";

export const DateFieldCustom = styled(InputFieldCustom)`
    /* Ready to custom */
`;

export const DatePickerFieldCustom = styled(Grid)`
    /* Ready to custom */
    /* width: 100%; */

    & > .MuiFormControl-root {
        width: 100%;
    }
`;

export const DatePickerCustom = styled(DatePicker)`
    margin-bottom: 16px;

    & .MuiOutlinedInput-root {
        fieldset {
            border: 1px solid ${colors.grey.border};
        }
        &:hover fieldset {
            border: 1px solid ${colors.grey.border};
        }
        &.Mui-focused fieldset {
            border: 1px solid ${colors.grey.border};
        }
    }

    & .MuiFormHelperText-root {
        margin-left: 0px;
    }

    & .MuiInputBase-root {
        height: 45px;
        background-color: white !important;
        font-weight: bold;

        svg {
            fill: black;
        }
    }

    & .MuiInputAdornment-root {
        margin-right: 35px;
    }
`;