import { Box, Grid, Typography } from "@mui/material"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"
import styled from "styled-components"
import colors from "../../../config/theme/colors"
import IcomoonReact from 'icomoon-react';
import iconSet from "../../../assets/selection.json";
import OurTypography from "../typography/Typography"

const ReturnLink = styled(Box)`
  cursor: pointer;
  display: flex;
  display: inline-block;
  width:max-content;
  background-color:${props=>props.type === 'button' ? "white":"auto"};
  border:${props=>props.type === 'button' ? `1px solid ${colors.grey.lighter.hue800}`:"none"};
  padding:${props=>props.type === 'button' ? `5px`:"auto"};
  height:${props=>props.type === 'button' ? `100%`:"auto"};
  display:${props=>props.type === 'button' ? `flex !important`:"auto"};
  align-items:${props=>props.type === 'button' ? `center`:"auto"};
`

const ReturnLayout = styled(Grid)`
  gap: 5px;
`

const OurReturnLink = (props) => {
  const type = props?.type ? props?.type : 'text'

  return (
    type === 'button'?
      <ReturnLink type={"button"} variant={'body2'} onClick={props.goTo}>
        <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"ico_go_back"} />
      </ReturnLink>
    :<ReturnLink variant={'body2'} onClick={props.goTo}>
      <ReturnLayout container alignItems="center">
        <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"ico_go_back"} />
        <OurTypography text={props.t("spread.active_assets.return")} style={{ fontSize: '16px' }} colortext={colors.black.regular} fontweighttext={900} />
      </ReturnLayout>
    </ReturnLink>
  )
}

export default withTranslation()(withRouter(OurReturnLink))
