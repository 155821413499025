import { Box, Divider, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import OurMenu from "../../../ui/menu/Menu";
import OurTypography from "../../../ui/typography/Typography";
import { getIdFromPath } from "../../../../js/utils/string.utils";
import PropTypes from "prop-types";

const CardWrapper = styled(Box)`
  position: relative;
  width: 100%;
  padding: 16px;
`;

const CardTypographies = ({ typography, title, actionButtons, ...props }) => {
  const getHeadlines = () => {
    const headlines = typography.typographyDatas?.edges
      ?.filter((item) => item.node?.markup.value[0].toLowerCase() === "h")
      .sort(
        (a, b) =>
          getIdFromPath(a.node.markup.id) - getIdFromPath(b.node.markup.id)
      );
    return headlines;
  };

  return (
    <CardWrapper>
      <Box>
        <Grid container justifyContent={"space-between"}>
          <OurTypography
            fontweighttext={700}
            style={{
              fontSize: "25px",
            }}
          >
            {title}
          </OurTypography>

          <OurMenu
            menuOptions={actionButtons}
            handleClick={(e, item) => item.action(e)}
          />
        </Grid>
        <Box mt={"20px"}>
          {/* TODO: Add ratio */}
          {/* <Grid container>
            <Grid item xs={6}>
              <OurTypography style={{ fontSize: "15px" }}>
                Ratio :
              </OurTypography>
            </Grid>
            <Grid item xs={6}>
              <OurTypography fontweighttext={700} style={{ fontSize: "15px" }}>
                -
              </OurTypography>
            </Grid>
          </Grid> */}

          <Divider style={{ margin: "20px 0px" }} />

          <Grid
            container
            style={{
              marginBottom: "10px",
            }}
          >
            <Grid item xs={6}>
              <Typography style={{ fontSize: "15px" }}>Headline :</Typography>
            </Grid>
            <Grid item xs={6}>
              <OurTypography fontweighttext={700} style={{ fontSize: "15px" }}>
                {getHeadlines().map((item, index) => (
                  <span key={`typography-${index}`}> {`${item.node.px}px`} </span>
                ))}
              </OurTypography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              marginBottom: "10px",
            }}
          >
            <Grid item xs={6}>
              <OurTypography style={{ fontSize: "15px" }}>
                Paragraphe :
              </OurTypography>
            </Grid>
            <Grid item xs={6}>
              <OurTypography fontweighttext={700} style={{ fontSize: "15px" }}>
                {typography.typographyDatas.edges.map(
                  (item) =>
                    item.node.markup.value === "p" && item.node.px + "px"
                )}
              </OurTypography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <OurTypography style={{ fontSize: "15px" }}>Note :</OurTypography>
            </Grid>
            <Grid item xs={6}>
              <OurTypography fontweighttext={700} style={{ fontSize: "15px" }}>
                {typography.typographyDatas.edges.map(
                  (item) =>
                    item.node.markup.value === "span" && item.node.px + "px"
                )}
              </OurTypography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </CardWrapper>
  );
};

CardTypographies.propsTypes = {
  title: PropTypes.string,
  typography: PropTypes.shape({
    typographyDatas: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        px: PropTypes.string.isRequired,
        markup: PropTypes.shape({
          id: PropTypes.string,
          value: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      color: PropTypes.string,
    })
  ),
};

export default CardTypographies;
