import styled from "styled-components";
import OurTypography from "../../../typography/Typography";
import { LinearProgress } from "@mui/material";
import colors from "../../../../../config/theme/colors";

export const LinearProgressCustom = styled(LinearProgress)`
    .MuiLinearProgress-bar{
        background-color: ${colors.blue.darker.hue300};
    }
`;

export const OverlayUpload = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
`;
export const SectionCustom = styled.section`
    border: dashed 2px ${colors.grey.border};
    background-color: ${props => props.hover ? '#eee' : '#fff'};
    border-radius: 4px;
    height: 210px;
    cursor: pointer;
    width: 100%;
    img{
        /* height: 75px; */
        width: 25px;
        margin-bottom: 15px;
    }
    div{
        font-size: 14px;
    }
    @media screen and (max-width: 1450px){
        height: 250px;
        img{
            height: 70px;
        }
        div{
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1280px){
        height: 200px;
        img{
            height: 55px;
        }
        div{
            font-size: 12px;
        }
    }
    @media screen and (max-width: 1000px){
        height: 150px;
        img{
            height: 40px;
        }
    }
`;

export const FileName = styled(OurTypography)`
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
`;

export const ChangeFileText = styled(OurTypography)`
  font-size: 12px;
  text-decoration: underline;
  font-weight: bold;
`;

export const DownloadFileText = styled(OurTypography)`

`;