import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const AContainer = styled.a`
    img{
        width: 100%;
        display: block;
        @media screen and (max-width: 1200px){
            &.onlyDesktop{
                display: none!important;
            }
        }
        @media screen and (min-width: 1201px){
            &.onlyTablet{
                display: none!important;
            }
        }
        @media screen and (max-width: 600px){
            &.onlyTablet{
                display: none!important;
            }
        }
        @media screen and (min-width: 601px){
            &.onlyMobile{
                display: none!important;
            }
        }
    }
`;


const BannerImage = (props) => {
    let {
        preview = false,
        id = null, 
        data,
        inputCallback = null,
    } = props;

    return (
        <div className="results">
            <section className="bandeau">
                { !preview && props.children }
                <AContainer href={data.inputs.link.value} target="_blank" rel="noopener noreferrer">
                    <img 
                        style={{margin: 'auto', display: 'block'}}
                        src={
                            typeof data.blocks.imageDesktop.inputs.value.value === 'string' ? 
                                data.blocks.imageDesktop.inputs.value.value : 
                                data.blocks.imageDesktop.inputs.value?.value?.filePath ? 
                                    ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.imageDesktop.inputs.value.value.filePath}` 
                                    : "http://via.placeholder.com/1920x500/FFFFFF/53839D?text=Logo"
                        }
                        className="onlyDesktop"
                    />
                    <img 
                        style={{margin: 'auto', display: 'block'}}
                        src={
                            typeof data.blocks.imageTablet.inputs.value.value === 'string' ? 
                                data.blocks.imageTablet.inputs.value.value : 
                                data.blocks.imageTablet.inputs.value?.value?.filePath ? 
                                    ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.imageTablet.inputs.value.value.filePath}` 
                                    : "http://via.placeholder.com/1200x2000/FFFFFF/53839D?text=Logo"
                        } 
                        className="onlyTablet"
                    />
                    <img 
                        style={{margin: 'auto', display: 'block'}}
                        src={
                            typeof data.blocks.imageMobile.inputs.value.value === 'string' ? 
                                data.blocks.imageMobile.inputs.value.value : 
                                data.blocks.imageMobile.inputs.value?.value?.filePath ? 
                                    ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.imageMobile.inputs.value.value.filePath}` 
                                    : "http://via.placeholder.com/600x1000/FFFFFF/53839D?text=Logo"
                        } 
                        className="onlyMobile"
                    />
                </AContainer>
            </section>
        </div>
    );
};

BannerImage.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default BannerImage;
