import { AppBar } from "@mui/material";
import styled from "styled-components";
import colors from "../../../../config/theme/colors";

export const AppBarCustom = styled(AppBar)`
    background: transparent;
    box-shadow: inherit;
    flex: 0 0 auto;
    transition: all 0.5s ease-in-out;

    svg{
        fill: ${colors.black.regular};
    }

    .Mui{
      /* Normal tab */
      &Tab-root{
        flex-basis: ${props => props.tabsNb <= props.columnsNb
          ? `calc((100% / ${props.tabsNb})) !important`
          : `calc((100% / ${props.columnsNb}) - (50px / ${props.columnsNb})) !important`
        };
        color: ${colors.black.regular};
        position: relative;
        z-index: 9;
        text-transform: inherit;
        font-weight: bold;
        font-size: 14px;
        opacity: 1;
        transition: all 0.5s ease-in-out;
        min-width: 130px; 
        max-width: unset;
        border-bottom: 1px solid ${colors.grey.border};
        border-left: 0.5px solid ${colors.grey.border};
        border-right: 0.5px solid ${colors.grey.border};
        &:last-child{
          border-right: none;
        }
      }
      /* Selected tab */
      &-selected{
        flex-basis: ${props => props.tabsNb <= props.columnsNb
          ? `calc((100% / ${props.tabsNb})) !important`
          : `calc((100% / ${props.columnsNb}) - (50px / ${props.columnsNb})) !important`
        };
        background-color: ${colors.grey.lighter.hue980};
        font-weight: bold;

        border-left: 0.5px solid ${colors.grey.border};
        border-right: 0.5px solid ${colors.grey.border};
        border-bottom: none;
      }
    }

    .MuiTabs{
      &-root{
        overflow-x: auto;
        /* div:first-child{
            left: 10px;
        }
        div:last-child{
            right: 10px;
        } */
        .MuiTabs-scrollButtons{
            position: absolute;
            height: 48px;
        }
      }

      &-scroller{
        transition: all 0.5s ease-in-out;
        overflow: auto !important;
        &::-webkit-scrollbar{
          display: "none";
        }
        &::-webkit-scrollbar-track{
          display: "none";
        }
        &::-webkit-scrollbar-thumb{
          display: "none";
        }
      }

      &-indicator{
        display: none;
        /* top: 0;
        height: 100%;
        border-top: 2px solid ${colors.blue.darker.hue300};
        background-color: ${colors.grey.lighter.hue980} */
      }
    }
`;

export const SwitchTabCustom = styled.span`
  border: 1px solid ${props => props.isDisabled ? colors.grey.lighter.hue600 : colors.black.regular};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};

  & > svg{
    fill: ${props => props.isDisabled ? colors.grey.lighter.hue600 : colors.black.regular};
    font-size: 16px;
    padding: 2px
  }
`