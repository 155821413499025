export const EPHEMERAL_ASSET = [
    // "wordpress",
    // "minisite",
    "jeu",
    // "facebook",
    // "twitter",
    // "instagram",
    // "pinterest",
];

export const ASSET_GAME = [
    "jeu",
    "flipbook",
    "flipbook_with_cart"
]
export const ASSET_ESHOP = [
    "magento",
    "pages",
    "shopify",
]
export const ASSET_MINISITE = [
    "minisite",
]
export const ASSET_APPLICATION = [
    "application",
    "minisite",
    "magento",
    "pages",
    "shopify",
    "jeu",
    "flipbook",
    "flipbook_with_cart",
    "marketplace",
    "connector",
    "instore",
]
export const ASSET_SALES = [
    "webshop",
    "connector",
    "instore",
    "marketplace"
]