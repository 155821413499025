import { capitalize } from 'lodash';
import {FORM, TABS} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CheckboxAttributs from '../../../../ui/inputs/CheckboxAttributs';

export default function formCompletenessGroup(allGroups, edit, currentLang,t){
    const attributesList = allGroups?.map((group, index) => ({
        type: 'checkboxList',
        required: false,
        stateName: 'groupsWithAllSelectedAttr',
        listing: {
            id: group.node.id,
            title: capitalize(group.node.identifier),
            values: group?.node.attributes.edges?.map((attr) => {
                return ({
                    id: attr.node.id,
                    name: t(attr.node.identifier) ?? '-',
                    groupId: group.node.id,
                })
            }),
            checkedValuesByDefault: group?.selectedAttributes,
        },
        custom: {
            isExpandable: true,
            hasSearch: true,
            hasSelectAll: true,
            style: {
                marginBottom: index === allGroups.length - 1 ? 32 : 20,
            }
        }
    }))

    return ({
        titleForm: edit === 'edit' ?  t('products.completeness.edittitle') : t('products.completeness.createtitle'),
        subTitleForm: edit === 'edit' ? t('products.completeness.editsub') : t('products.completeness.createsub'),
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: edit === 'edit' ? t('products.completeness.edit') : t('products.completeness.create'),
        formConfig: {
            type: FORM,
            header: {
                title: 'Information sur le groupe',
            },
            children: [
                {
                    labelName: t('products.completeness.info'),
                    isOptionnal: false,
                    optionsInputs: [
                        {
                            type: 'text',
                            label: t('products.completeness.wording'),
                            helper: {
                                text: t('products.completeness.wordinghere'),
                                link: false,
                            },
                            required: true,
                            stateName: 'libelle',
                        },
                        ...attributesList
                    ]
                },
            ]
            // children:[
            //     {
            //         labelName: t('products.completeness.info'),
            //         isOptionnal: false,
            //         optionsInputs: [
            //             {
            //                 type: 'text',
            //                 label: t('products.completeness.wording'),
            //                 helper: {
            //                     text: t('products.completeness.wordinghere'),
            //                     link: false,
            //                 },
            //                 required: true,
            //                 stateName: 'libelle',
            //             },
            //         ]
            //     },
            //     {
            //         labelName:  t('products.completeness.selectattribut'),
            //         isOptionnal: false,
            //         component: CheckboxAttributs,
            //         optionsInputs:{
            //             stateName: 'attributesSelected',
            //             required: true,
            //             value: allGroups
            //         }
            //     }
            // ]
        }
    })
}