import { gql } from '@apollo/client';

//----- Récupération des MEDIAS
export const GET_MEDIAS = gql`
{
  mediaObjects(first:1000){
    edges {
      node{
        id
        filePath
        type
        size
        name
        alt
        isInternal
        expirationAt
        mediaObjectTags{
          edges{
            node{
            	tag
            }
          }
        }
        category{
          edges{
            node{
              id
              libelle
            }
          }
        }
      }
    }
  }
}`;

export const GET_MEDIAS_CURSOR = gql`
query mediaObjects($types: [String], $filePath: String, $categories: [String],$id_list:[Int]) {
  mediaObjects(first:500, type_list: $types, filePath: $filePath,id_list:$id_list, category_list: $categories, order:{created_at:"DESC"}) {
    totalCount
    edges {
      cursor
    }
  }
}`;

//----- Récupération des MEDIAS
export const GET_MEDIAS_IMAGES = gql`
query mediaObjects($types: [String], $filePath: String, $name: String, $categories: [String], $nbperpage: Int, $cursor: String,$id_list:[Int]) {
  mediaObjects(type_list: $types, filePath: $filePath, name: $name, first: $nbperpage, after: $cursor,id_list:$id_list, isInternal: false, category_list: $categories, order:{created_at:"DESC"}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges {
      cursor
      node{
        id
        filePath
        type
        size
        isInternal
        expirationAt
        created_at
        mediaObjectTags{
          edges{
            node{
              id
              tag
              mediaObjects{
                edges{
                  node{
                    id
                  }
                }
              }
            }
          }
        }
        name
        alt
        description
        category{
          edges{
            node{
              id
              libelle
            }
          }
        }
      }
    }
  }
}`;

export const GET_MEDIAS_COUNT = gql`
query mediaObjects($types: [String], $filePath: String, $categories: [String], $first : Int=1000) {
  mediaObjects(type_list: $types, filePath: $filePath, first: $first, category_list: $categories, order:{created_at:"DESC"}) {
    totalCount
  }
}`;

export const ADD_TAG = gql`
mutation AddMediaObjectTag($tag: String!, $mediaObjects: [String]){
  createMediaObjectTag(input: {tag:$tag, mediaObjects:$mediaObjects}){
    mediaObjectTag{
      id
      tag
      mediaObjects{
        edges{
          node{
            id
          }
        }
      }
    }
  }  
}`;

export const UPDATE_TAG = gql`
mutation UpdateMediaObjectTag($id: ID!, $tag: String, $mediaObjects: [String]){
  updateMediaObjectTag(input: {id:$id, tag:$tag, mediaObjects:$mediaObjects}){
    mediaObjectTag{
      id
      tag
      mediaObjects{
        edges{
          node{
            id
          }
        }
      }
    }
  }  
}`;

export const DELETE_TAG = gql`
mutation DeleteMediaObjectTag($id: ID!){
  deleteMediaObjectTag(input: {id:$id}){
    mediaObjectTag{
      id
    }
  }
}`;

export const GET_TAGS = gql`
{
  mediaObjectTags {
    edges {
      node {
        id
        tag
        mediaObjects {
          totalCount
          edges{
            node{
              id
            }
          }
        }
      }
    }
  }
}`;

/*
Exemple de variables
  {
    "tag": "test"
  }
*/
export const SEARCH_TAGS = gql`
query searchMediaObjectTags($tag: String,$first : Int){
  mediaObjectTags(tag:$tag,first:$first) {
    edges {
      node {
        id
        tag
        mediaObjects {
          totalCount
          edges{
            node{
              id
            }
          }
        }
      }
    }
  }
}`;

export const DELETE_MEDIA = gql`
mutation DeleteMediaObject($id: ID!){
  deleteMediaObject(input: {id:$id}){
    mediaObject{
      id
    }
  }
}`;