import React, {useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as moment from 'moment';
import Badge from '@mui/material/Badge';
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';

const BadgeCustom = styled(Badge)`
    display: block;
    padding: 0;
    &>span{
        background-color: ${props => props.bgcolor ? props.bgcolor : colors.white};
        color: ${props => props.bgcolor ? colors.white : colors.black.regular};
        left: -50px;
        position: absolute;
        right: initial;
        width: 100px;
        text-transform: uppercase;
    }
`;

const columns = [
    { id: 'status', label: 'Statut', minWidth: 100 },
    { id: 'name', label: 'Nom', minWidth: 170 },
    { id: 'date', label: "Date", minWidth: 90},
    { id: 'typeCron', label: 'Type', minWidth: 90 },
    { id: 'message', label: "Message", minWidth: 210 },
];

function createData(status, name, date, typeCron, message) {
    return { status, name, date, typeCron, message};
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 'calc(100vh - 300px)',
    },
});

export default function HistoryCrons(props) {
    const classes = useStyles();
    const [rows, setRows] = React.useState([]);

    useEffect(() => {
        if(props.rows){
            let temporaryRows= [];
            for(let row of props.rows){
                temporaryRows.push(
                    createData(
                        row.node.status, 
                        row.node.cronShedule.jobCode,
                        moment(row.node.date).utc().format('DD/MM/YYYY HH:mm:ss'),
                        row.node.cronType,
                        row.node.message
                    )
                );
            }
            setRows(temporaryRows);
        }
    }, [props.rows]);

    return (
        <Paper>
            <TableContainer className={classes.container} style={{overflowX: 'unset'}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth}}
                            >
                                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                    {column.label}
                                </div>
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows ? (
                                rows.map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${row.code} ${index}`}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} style={{padding: "6px 16px"}} >
                                                    {
                                                        column.id === 'status' ?
                                                            (
                                                                <BadgeCustom
                                                                bgcolor={
                                                                    row.status === "success" || row.status === "enable" ? 
                                                                        colors.green.regular 
                                                                    : row.status === "disable" ? 
                                                                        colors.orange.regular 
                                                                    : row.status === "start" || row.status === "end" ? 
                                                                        colors.grey.regular
                                                                    :
                                                                        colors.red.regular
                                                                } 
                                                                badgeContent={
                                                                    row.status
                                                                } 
                                                            />
                                                            )
                                                        : column.id === 'name' ?
                                                            (
                                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                                    <span>{value}</span>
                                                                </div>
                                                            )
                                                        : column.id === 'date' ? 
                                                            (
                                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                                    <span>{value}</span>
                                                                </div>
                                                            )
                                                        : column.id === 'typeCron' ? 
                                                            (
                                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                                    <span>{value}</span>
                                                                </div>
                                                            )
                                                        : value
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                        </TableRow>
                                    );
                                })
                            ) : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
