import React from 'react';
import { Grid, Typography, Box, Tooltip } from '@mui/material';
import colors from '../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { booleanTypes, statusTypes } from './config/types.config';
import iconSet from '../../../assets/selection.json';
import IcomoonReact from 'icomoon-react';

const TypographyCustom = styled(Typography)`
    font-weight: bold;
    padding-left: 6px;

    color: ${props => props.color ?? colors.grey.regular} !important;

`;

const GridCustom = styled(Grid)`
    & svg {
        color: ${props => typeof props.statusTypeValue?.icon !== 'string' ? props.statusTypeValue?.color : colors.grey.regular} !important;
    }
`;

const CircleStatus = styled.div`
    background-color: ${props => props.isActive ? colors.green.regular : colors.grey.lighter.hue600};
    border-radius: 50%;
`;

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: props => props.status ? colors.green.regular : colors.grey.lighter.hue600,
    },
    tooltip: {
        backgroundColor: props => props.status ? colors.green.regular : colors.grey.lighter.hue600,
        fontSize: 14,
        padding: 10,
        textAlign: 'left',
    },
}));

// Example of use:
// <Status type="orders" value={'en cours'} />
// <Status type="isActive" value={false} />
// <Status icon={'ico_produit'} text={'Produits'} color={'red} />

function Status({
    // Config for custom status (//! IMPORTANT: Dont' mix with setted status config)
    icon,
    text,
    color,

    // Config for setted status (//! IMPORTANT: Dont' mix with custom status config)
    type,
    value,

    // All other props
    hasText = true,
    size,
    ...props
}) {
    const isBoolean = typeof value === 'boolean';
    const isConfigured = typeof value === 'string';
    const isCustom = Boolean(text);

    return (
        <Grid container justifyContent={props.justify ? props.justify : 'flex-start'} alignItems="center" direction="row" style={{
            width: props.width,
            flexWrap: 'nowrap',
            width: 'auto',
            ...props.style
        }}>
            <Box>
                <GridCustom container alignItems="center" statusTypeValue={
                    (value && !isBoolean) && statusTypes[type][value]
                }>
                    {isBoolean // It is a setted boolean status
                        ? booleanTypes[type][value ? 'active' : 'inactive']?.icon ?? <CircleStatus isActive={value} style={{ width: size ?? 16, height: size ?? 16 }} />
                        : typeof value === 'string' // It is a setted configured status
                            ? typeof statusTypes[type][value]?.icon === 'string' // It is React Icomoon icon
                                ? <IcomoonReact iconSet={iconSet} color={statusTypes[type][value].color ?? colors.grey.regular} size={props.size ?? 16} icon={statusTypes[type][value]?.icon} />
                                : statusTypes[type][value]?.icon // It is a React Material icon
                            : typeof icon === 'string' // It is a custom status
                                ? <IcomoonReact iconSet={iconSet} color={color ?? colors.grey.regular} size={props.size ?? 16} icon={icon} />
                                : icon // It is a React Material icon
                    }
                </GridCustom>
            </Box>
            {hasText && (
                <Box>
                    <Grid container alignItems="center">
                        <TypographyCustom variant={"body2"} color={
                            isBoolean // It is a setted boolean status
                                ? value
                                    ? booleanTypes[type].active.color // Normally it is always green
                                    : booleanTypes[type].inactive.color // Normally it is always gray
                                : typeof value === 'string' // It is a setted configured status
                                    ? statusTypes[type][value].color
                                    : color // It is a custom status

                        } className="active-value" style={{
                            fontSize: props.fontSize ?? 14,
                            lineHeight: props.fontSize
                                ? `${props.fontSize + 2}px`
                                : '16px',
                        }}
                        >
                            {isBoolean // It is a setted boolean status
                                ? value
                                    ? booleanTypes[type].active.text
                                    : booleanTypes[type].inactive.text
                                : typeof value === 'string' // It is a setted configured status
                                    ? statusTypes[type][value].text
                                    : text // It is a custom status
                            }
                        </TypographyCustom>
                    </Grid>
                </Box>
            )}
        </Grid>
    );
}

export default withTranslation()(Status);