import React from 'react';

import { withRouter } from 'react-router';
import { connect } from "react-redux";

import colors from '../../../../config/theme/colors';

import TopPanel from '../../../layouts/TopPanel/TopPanel';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import EmptySocials from '../../../../assets/pictos/empty-picto/empty_rs.png';
import { ROUTE_SALES_ESHOP} from '../../../../js/constants/route-names';
import { withTranslation } from 'react-i18next';

class DiffusionSocials extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            filtered: [],
            ready: true,
        };
    }

    componentDidMount() {
        this.setState({
            filtered: this.props.products??[]
        });
    }
    

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="picto-rs"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("spread.campaigns.manageSocial")} 
                    subtitle={this.props.t("spread.campaigns.manageSocialPosts")} 
                    // handlerAdd={() => this.goTo(ROUTE_PRODUCTS_ADD)} 
                    textAdd={this.props.t("spread.campaigns.addSocial")} 
                    handlerImport={() => this.goTo(ROUTE_SALES_ESHOP)} 
                    textImport={this.props.t("spread.campaigns.seeSocial")} 
                    // searchHandler={this.handleChange} 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    // openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    locales={this.props.locales}
                />
                <EmptyCard 
                    title={this.props.t("spread.campaigns.notConfigureSocial")} 
                    subtitle={this.props.t("spread.active_assets.clickToAdd")} 
                    textButton={this.props.t("spread.active_assets.createSocial")} 
                    picto={EmptySocials} 
                    onClick={() => this.handleToggleDrawer('openForm')}
                    openForm={this.state.openForm}
                />
            </div>
        );
    }
    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, null)(DiffusionSocials)));
