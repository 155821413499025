import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import styled from 'styled-components';
import CardCustom from '../../../layouts/Card/CardCustom';
import {Grid, Paper, Box} from '@mui/material';
import colors from '../../../../config/theme/colors';
import Typography from '../../../ui/typography/Typography';
import SearchBar from '../../../ui/search/SearchBar';
import Button from '../../../ui/button/Button';
import EmptyHelp from '../../../../assets/pictos/empty-picto/empty_rs.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";

const BoxCustom = styled(Box)`
    width: auto;
`;

const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    margin-top: 35px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;

const DivQuestion = styled(Grid)`
    padding: 20px;
    // margin: 20px;    
    &:hover{
        background-color: ${colors.blue.darker.hue300} !important;
        color: ${colors.white} !important;
        cursor: pointer;
    }
`;

class SettingsHelps extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            activeQuestion: null,
            searchBar : '',
            mock_data_french :[
                {
                    id: 1,
                    question: "Lorem ipsum dolor sit amet",
                    text: "Voici le texte sur 'Lorem ipsum dolor sit amet'"                      
                },
                  {
                    id: 2,
                    question: "Donec quis nisl ultrices",
                    text: "C'est le texte sur 'Donec quis nisl ultrices'"
                  },
                  {
                    id: 3,
                    question: "Vivamus vestibulum diam id arcu pellentesque tincidunt ?",
                    text: "C'est le texte sur 'Vivamus vestibulum diam id arcu pellentesque tincidunt'"
                  },
                  {
                    id: 4,
                    question: "Duis varius nisl sed ?",
                    text: "C'est le texte sur 'Duis varius nisl sed'"
                  },
                  {
                    id: 5,
                    question: "Aliquam eget tempus augue ?",
                    text: "C'est le texte sur 'Aliquam eget tempus augue'" 
                  },
                  {
                    id: 6,
                    question: "Cursus Elit Nullam Quam Sem ?",
                    text: "C'est le texte sur 'Cursus Elit Nullam Quam Sem'"
                  },
                  {
                    id: 7,
                    question: "Justo Vulputate Consectetur Amet Adipiscing ?",
                    text: "C'est le texte sur 'Justo Vulputate Consectetur Amet Adipiscing'"
                  },
                  {
                    id: 8,
                    question: "Inceptos Elit Magna Lorem Dolor ?",
                    text: "C'est le texte sur 'Inceptos Elit Magna Lorem Dolor'"
                  },
                  {
                    id: 9,
                    question: "Cras Inceptos Fusce Dolor Tristique ?",
                    text: "C'est le texte sur 'Cras Inceptos Fusce Dolor Tristique'"
                  },
                  {
                    id: 10,
                    question: "Ornare Bibendum Sollicitudin Pellentesque Etiam ?",
                    text: "C'est le texte sur 'Ornare Bibendum Sollicitudin Pellentesque Etiam'"
                  },
            
            ],
            mock_data_english : [ 
            {
                id: 1,
                question: "Lorem ipsum dolor sit amet",
                text: "This is the 'Lorem ipsum dolor sit amet"
            },
            {
                id: 2,
                question: "What are the KPIs i can see on the platform ?",
                text: "This is the 'What are the KPIs i can see on the platform' text"
            },
            {
                id: 3,
                question: "What are the emotions you detect ?",
                text: "This is the 'What are the emotions you detect' text"
            },
            {
                id: 4,
                question: "What is the emotional intensity and how is it calculated ?",
                text: "This is the 'What is the emotional intensity and how is it calculated' text"
            },
            {
                id: 5,
                question: "What is the speech engagement and how is it calculated ?",
                text: "This is the 'What is the speech engagement and how is it calculated' text"
            },
            {
                id: 6,
                question: "Cursus Elit Nullam Quam Sem ?",
                text: "This is the 'Cursus Elit Nullam Quam Sem' text"
            },
            {
                id: 7,
                question: "Justo Vulputate Consectetur Amet Adipiscing ?",
                text: "This is the 'Justo Vulputate Consectetur Amet Adipiscing' text"
            },
            {
                id: 8,
                question: "Inceptos Elit Magna Lorem Dolor ?",
                text: "This is the 'Inceptos Elit Magna Lorem Dolor' text"
            },
            {
                id: 9,
                question: "Cras Inceptos Fusce Dolor Tristique ?",
                text: "This is the 'Cras Inceptos Fusce Dolor Tristique' text"
            },
            {
                id: 10,
                question: "Ornare Bibendum Sollicitudin Pellentesque Etiam ?",
                text: "This is the 'Ornare Bibendum Sollicitudin Pellentesque Etiam' text"
            },

            ] 
        };
    }

    displayQuestion(id){
        if (this.state.activeQuestion === id ) {
            this.setState({
                activeQuestion: null, 
            })       
        }
        else {
            this.setState({
                activeQuestion: id, 
            });        
        }
    }
        
    handleInput = (event) => {
        this.setState({
            searchBar: event.target.value
        })
    }

    render() {


        let data = this.state.mock_data_french.find(data => data.id === this.state.activeQuestion);
        let filterquestion = this.state.mock_data_french.filter(
            (data) => {
                return data.question.toLowerCase().includes(this.state.searchBar)
            }
        );

        return (
            <div>
                <TopPanel 
                    icomoon="help"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={"Aides"}
                    subtitle={"Liste d'aide qui peut répondre à vos questions"}
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}     
                    hasBorder={true}               
                />

                <BoxCustom onClick={this.props.history.goBack} style={{marginBottom: 16}}>
                    <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                </BoxCustom>

                <Grid container direction="column" justifyContent="center" spacing={0} style={{width:  this.state.openForm && this.props.windowWidth > 1200 ? '100%' : "100%", marginTop: 35, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    <CardCustom style={{width: this.state.openForm && this.props.windowWidth > 1500 ? '100%' : "calc(100% - 32px)", marginLeft: 16, marginTop: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 8 : 24, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                        <Grid container spacing={4}>
                            <Grid item xs={4}>
                                <Paper style={{ boxShadow: "none"}}>
                                    <Grid item xs={12} style={{marginTop: '10px', marginBottom: '10px'}}>
                                        <SearchBar 
                                        type="text" 
                                        placeholder= "En quoi peut-on vous aider ?"
                                        onChange={this.handleInput}/>  
                                    </Grid>
                                    <div style={{overflowY: "auto", height: "40vh"}}>
                                        {filterquestion.length > 0 ? 
                                            (
                                                filterquestion.map((data, index)=> (
                                                    
                                                    <DivQuestion key={`div-data${index}`} onClick={() => this.displayQuestion(data.id)} style={{background: this.state.activeQuestion === data.id ? "#0273A5" : "none", color: this.state.activeQuestion === data.id ? "white" : "black"}}>
                                                        <a>{data.question}</a>
                                                    </DivQuestion>

                                                ))
                                            ):
                                            
                                            <EmptyCard title={"Pas de résultat pour cette recherche"} picto={EmptyHelp} xsImg={this.state.openForm ? 4 : 2} mtImg={2} />
                                        }
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={8}>
                                <Paper style={{ boxShadow: "none"}}>  
                                    {!this.state.activeQuestion ? (
                                        
                                        <Typography variant="h1" style={{marginTop: 8}}>Sélectionner une question</Typography>

                                    ) : ( 

                                        <div>
                                            <Typography variant="h1">{data.question}</Typography>

                                            <div style={{marginTop: "30px", overflowY: "auto", height: "40vh"}}>{data.text}</div>
                                            
                                            <Button 
                                            text="Fermer"
                                            onClick={() => this.displayQuestion()} 
                                            color={colors.white} 
                                            colorhover={colors.white}
                                            bgcolor={colors.grey.lighter.hue600}
                                            bgcolorhover={colors.blue.darker.hue300}
                                            ></Button>
                                        </div>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </CardCustom>
                </Grid>
            </div>
        );
    }
    
    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withRouter(connect(mapStateToProps, null)(SettingsHelps));
