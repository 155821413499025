import Button from '../../../../ui/button/Button';

import {CARD} from '../../../../../js/constants/form-types';


const MediasImport = 
    {
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: CARD,
        },

    }

export default MediasImport;