import { Box, Grid } from "@mui/material"
import PropTypes from "prop-types"
import CardCustom from "../CardCustom"
import OurTypography from "../../../ui/typography/Typography"
import OurButton from "../../../ui/button/Button"
import OurMenu from "../../../ui/menu/Menu"
import colors from "../../../../config/theme/colors"

const CardSchema = ({
  menu,
  schema
}) => {

  return (
    <Box>
      <CardCustom>
        <Grid container justifyContent={'space-between'}>
          <OurTypography fontsize={'25px'} fontweighttext={'bold'} style={{ marginBottom: 24 }}>{schema.title}</OurTypography>
          {menu && (
            <OurMenu menuOptions={menu} handleClick={(e, item) => item.action(e)} />
          )}
        </Grid>
        <Grid container gap={'2px'} direction={'column'}>
          {Object.keys(schema.summary).map((summaryKey, index) => (
            <Grid item>
              <Grid container>
                <Grid item style={{ minWidth: 150 }}>
                  <OurTypography>{summaryKey} :</OurTypography>
                </Grid>
                <Grid item>
                  <OurTypography fontweighttext={'bold'}>{schema.summary[summaryKey]}</OurTypography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <OurButton
          types={'edit'}
          importance={'secondary'}
          onClick={() => schema.action(schema.id)}
          style={{
            width: '100%',
            marginTop: 47
          }}
        >
          Modifier
        </OurButton>
      </CardCustom>
    </Box>
  )
}

CardSchema.propTypes = {
  menu: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    id: PropTypes.string.isRequired || PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    action: PropTypes.func.isRequired,
  })
}

export default CardSchema