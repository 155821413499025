import React from 'react';
import { Box, Grid, Typography,Tooltip } from '@mui/material';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import Button from '../../../ui/button/Button';
import NoImage from '../../../../assets/images/not-found.png';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS} from '../../../../js/constants/route-names';
import MailIcon from '@mui/icons-material/Mail';
import WorkIcon from '@mui/icons-material/Work';
import LanguageIcon from '@mui/icons-material/Language';
import iconSet from '../../../../assets/selection.json';
import IcomoonReact from "icomoon-react";
import { withRouter } from 'react-router';
import * as moment from "moment";
import OurReturnLink from '../../../ui/link/ReturnLink'

const ContainerCardCRM = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    height: 100%;
    cursor: ${props => props.selectable ? `pointer` : `initial`};
`
const ContactStatus = styled(Box)`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #6eaed2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:10px;
`
const ProfilePicture = styled(Box)`
    border-radius: 50%;
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    background-repeat : no-repeat;
    height: 220px;
    width: 220px;
`
const CompanyPicture = styled(Box)`
    border-radius: 50%;
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    background-repeat : no-repeat;
    height: 35px;
    width: 35px;
    margin-right :15px;
`
const CompanyBox = styled(Grid)`
    color : ${colors.grey.regular};
`
const CompanyItem = styled(Grid)`
    border-bottom : 1px solid ${colors.grey.lighter.hue900};
    padding : 15px 0px;
    cursor:pointer;
    padding-left:0px;
    transition : all .2s;
    ${props => !props.selectable ? 
    `
        &:hover{
            background-color : ${colors.grey.lighter.hue980};
            padding-left:2px;
        }        
    ` 
    :``}
`
const QuoteBox = styled(Box)`
    color : ${colors.grey.regular};
    background-color:${colors.grey.lighter.hue980};
`
const ContainerButton = styled(Box)`
    display: flex;
`
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`
const BoxCustom = styled(Box)`
    width: auto;
`
const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

function CardContactDetails(props){
    const icons = {
        client: {
            icon:"icon-clients",
            libelle : "Client"
        },
        lead:{
            icon :"icon-leads" ,
            libelle : 'Lead'
        },
        contact:{
            icon:"icon-contacts",
            libelle : "Contact"
        } 
    };

    const getTranslation = (currentLang,attribute)=>{
        let allStates = props.allStates;
        let translatedValue = allStates[currentLang]?.[attribute]
        if (translatedValue){
            return translatedValue;
        }
    
        else{
            for(let locale of props.langs){
                translatedValue = allStates[locale.node.code]?.[attribute];
                if(translatedValue){
                    return translatedValue;
                }
            }
            return null;
        }
    }

    const getTypeAttribut = (node) => {
        switch(node.attribute.attributeType.input){
            case 'date':
                return moment(node.value).format('LL');
            case 'mail':
                return <a href={`mailto:${node.value}`}>{node.value}</a>;
            case 'tel':
                return <a href={`tel:${node.value}`}>{node.value}</a>;
            default:
                return node.value;
        }
    }

    let firstname   = getTranslation(props.currentLang,'customer_firstname');
    let lastname    = getTranslation(props.currentLang,'customer_lastname');
    let phone       = getTranslation(props.currentLang,'customer_phone');
    let image       = getTranslation(props.currentLang,'customer_image');
    let email       = props.allStates.customerEmail;
    let job         = getTranslation(props.currentLang,'customer_job');
    let link        = getTranslation(props.currentLang,'customer_link');
    let comment     = getTranslation(props.currentLang,'customer_comment');
    
    let status      = props.allStates.customerStatus;
    const company   = props.allStates;

    const attributesSecondary = props.allStates.currentCustomer?.node.customerDatas.edges.filter(
        e => e.node.attribute.identifier !== 'customer_firstname' 
            && e.node.attribute.identifier !=='customer_lastname' 
            && e.node.attribute.identifier !=='customer_phone' 
            && e.node.attribute.identifier !=='customer_image' 
            && e.node.attribute.identifier !=='customer_job' 
            && e.node.attribute.identifier !=='customer_link' 
            && e.node.attribute.identifier !== 'customer_comment'
            && e.node.attribute.identifier !== 'customer_mail'
            && e.node.attribute.identifier !== 'customer_status'
    )

    const goTo = (route, id, target) => {
        let variables = {};
        variables[target] = id;
        props.history.push({
            pathname : route,
            state: variables
        });
    };
    
    return(
        <ContainerCardCRM pt={2} pb={1} >
            <OurReturnLink goTo={props.history.goBack} />
            {/* <BoxCustom onClick={props.history.goBack} style={{marginBottom: 16}}>
                <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
            </BoxCustom> */}
            <Grid container alignItems={"center"}>
                <Grid item xs={props.type === "dashboard" ? 5 : 6}>

                    <Box pr={4}>
                        <Grid container alignItems="center" justifyContent="center">
                            <ProfilePicture style={{margin:'auto'}} backgroundimage={image ? `${process.env.REACT_APP_MEDIAS}/${image.filePath}` : NoImage } />
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={props.type === "dashboard" ? 7 : 6}>
                    <Grid container alignItems="center" style={{marginBottom : 20}}>
                        <Tooltip title={icons[status].libelle} arrow>
                            <ContactStatus>
                                <IcomoonReact iconSet={iconSet} size={30} icon={icons[status].icon} />
                            </ContactStatus>                        
                        </Tooltip>
                        <Typography variant="h3" >{firstname || lastname ? `${firstname ? firstname :''} ${lastname?lastname:''}`:'-'}</Typography>
                    </Grid>

                    <Grid container alignItems="center" style={{marginBottom:8}}>
                        <PhoneIphoneIcon/> 
                        <Typography style={{paddingLeft:12}}>
                            {phone ? 
                                (<a href={`tel:${phone}`} style={{color:"#808F98"}}>{phone}</a>) 
                                : '-'
                            }
                        </Typography>
                    </Grid>
                    <Grid container alignItems="center" style={{marginBottom:8}}>
                        <MailIcon/> 
                        <Typography style={{paddingLeft:12}}>
                            {email ? 
                                (<a href={`mailto:${email}`} style={{color:"#808F98"}}>{email}</a>) 
                                : '-'
                            }
                        </Typography>
                    </Grid>
                    <Grid container alignItems="center" style={{marginBottom:8}}>
                        <LanguageIcon/> 
                        <Typography style={{paddingLeft:12}}>
                            { link ? (<a href={link} target="_blank" rel="noopener noreferrer" style={{color:"#808F98"}}>{link}</a>) : '-'}
                        </Typography>
                    </Grid>

                    <Grid container alignItems="center" style={{marginBottom:8}}>
                        <WorkIcon/> <Typography style={{paddingLeft:12}}>{job ? job : '-'}</Typography>
                    </Grid>
                    {
                        attributesSecondary?.length > 0 ? 
                            attributesSecondary.map((attribute, index) => {
                                let typeAttribut = attribute.node.attribute.translation.translationDatas.edges.find(e => e.node.locale.code === props.currentLang);
                                let defaultTypeAttribut = attribute.node.attribute.translation.translationDatas.edges[0].node.value;
                                return(
                                    <Grid key={index} container alignItems="center" style={{marginBottom:8}}>
                                        <Typography>
                                            <strong>{typeAttribut ? typeAttribut.node.value : defaultTypeAttribut}  : </strong> {getTypeAttribut(attribute.node)}
                                        </Typography> 
                                    </Grid>
                                )
                            })
                        : null
                    }
                    <QuoteBox style={{padding:20,marginTop:20}}>
                        <Typography variant="h4">Commentaire</Typography>
                        <Typography variant="body2" style={{fontStyle : 'italic'}} dangerouslySetInnerHTML={{__html:comment ? comment : '-'}}></Typography>
                    </QuoteBox>
                </Grid>

                <CompanyBox lg={12} container style={{marginTop:16}}>
                    <Typography variant="h3" style={{paddingLeft:12,marginBottom:12,width:"100%"}}>{company.currentCustomer?.node?.companies.edges ? 'Sociétés' : null }</Typography>

                    {company.currentCustomer ? company.currentCustomer.node.companies.edges.map((company, index)=>{

                        const companyName = getTraductionAttributs('company_name', company.node.companyDatas.edges, props.currentLang);
                        const imageCompany = getTraductionAttributs('company_image', company.node.companyDatas.edges, props.currentLang,'image');
                        return(
                                <Box key={index} pl={4} style={{display: 'flex', width:"50%"}}>
                                    <CompanyItem container alignItems="center" onClick={()=>!props.select ? goTo(ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS.replace(':id', company.node.identifier), company.node.id,'idCompany'):null} selectable={props.select} >
                                        <CompanyPicture backgroundimage={imageCompany ? `${process.env.REACT_APP_MEDIAS}/${imageCompany.filePath}` : NoImage}/>
                                            <Typography style={{fontWeight: 'bold'}}>{companyName}</Typography>
                                    </CompanyItem>
                                </Box>
                            )
                    }) :''}
                </CompanyBox>
                    {
                        props.isEdit ?
                        <ContainerButton pt={3} style={{justifyContent: 'flex-end', width:'100%'}}>
                            <ButtonCustom 
                                disableElevation={true} 
                                types="Edit"
                                arrow="right" 
                                bgColor={colors.green.regular} 
                                text="Modifier le contact" 
                                onClick={() => props.handleToggleDrawer('contactAddForm')} 
                            />
                        </ContainerButton>
                        : null
                    }
                
            </Grid>
        </ContainerCardCRM>
    );
}

export default withRouter(CardContactDetails)