import { useState } from "react";
import { getFileConfig } from "../../../js/helpers/files";
import { ModalWrapperCustom, useStyles } from "./styles/styled";
import { Backdrop, Box, Fade, Grid, Modal } from "@mui/material";
import { withTranslation } from "react-i18next";
import OurTypography from "../typography/Typography";
import { DOCUMENTS } from "../../../js/constants/medias-types";
import colors from "../../../config/theme/colors";


function getMediaType(type) {
    if (type) {
        var res = type.match(/\.[0-9a-z]+$/i)?.[0].replace('.', '').toUpperCase();
        return res;
    }
}

function OurModal(props) {

    const [openConfirmation, setOpenConfirmation] = useState(false);

    let modalData = props.modalData?.file;

    let isVideo = ['video/mpeg', 'video/webm', 'video/mp4'].includes(modalData?.type) ? true : false;
    let isPDF = ['application/pdf'].includes(modalData?.type) ? true : false;
    let mimeType = modalData?.type;

    const classes = useStyles();

    return (
        <Modal
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={props.isOpen}>
                <ModalWrapperCustom>
                    {modalData?.id ?
                        isVideo
                            ? (
                                <video className={classes.asset} controls autoPlay>
                                    <source src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData?.filePath}`} type={mimeType} />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            )
                            : isPDF
                                ? (
                                    <iframe className={classes.asset}
                                        src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData?.filePath}`}
                                        type={mimeType}
                                        title={modalData?.name}
                                        width="100%"
                                        height="100%"
                                        allowFullScreen
                                        loading='lazy'
                                        seamless
                                        allowTransparency
                                    />
                                )
                                : (
                                    <img className={classes.asset} src={
                                        getFileConfig(modalData?.type).image 
                                            ? getFileConfig(modalData?.type).image 
                                            : `${process.env.REACT_APP_API_ROOT}/medias/${modalData?.filePath}`} 
                                    />
                                )
                    : (
                        <img src={props.modalData?.data} alt="" style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'contain'
                        }} />
                    )}
                </ModalWrapperCustom>
            </Fade>
        </Modal>
    );
}

export default withTranslation()(OurModal);
