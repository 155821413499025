import LayoutFormBuilder from "@ui/form/LayoutFormBuilder";
import exportProducts from "./exportProduct.config";

const ExportProducts = props => {
    return (
        <LayoutFormBuilder
                isSublayout={true}
                opened={props.openFormExport}
                icomoon={'ico-import-fichier'}
                forClose={() => props.setOpenFormExport(!props.openFormExport)}
                dataLayout={exportProducts(props.allLangs, 'products', props.t,props.allGroups,props.productNb)}
                drawerWidth={props.drawerWidth}
                handleCancel={props.resetState}
                handlerMutation={props.handlerMutation}
                allState={props.state}
                stateCallback={props.stateCallback}
                stepperButtonDisabled={[() => (
                    props.state.exportName &&props.state.exportLang &&props.state.exportType ? false:true
                    ), null
                ]}
                stepperButtonAction={[
                    null,
                    // () => {
                    //     let error = 0;
                    //     let requiredValueMapper;
                    //     console.log(props.state.requiredValueMapper)
                    //     if (props.state.requiredValueMapper) {
                    //         let i = 0;
                    //         requiredValueMapper = props.state.requiredValueMapper;
                    //         for (let item of props.state.requiredValueMapper) {
                    //             for (let value of props.state.mapper) {
                    //                 if (value && value.length > 0) {
                    //                     for (let val of value) {
                    //                         if (val === item.id) {
                    //                             error++;
                    //                             requiredValueMapper[i].checked = true;
                    //                         }
                    //                     }
                    //                 }
                    //             }
                    //             i++;
                    //         }
                    //     }
                    //     if (error === props.state.requiredValueMapper?.length) {
                    //         return true;
                    //     } else {    
                    //         props.setState({ ...props.state,requiredValueMapper:requiredValueMapper })
                    //         props.snack(ALERT_ERROR, props.errorMapper());
                    //         return false;
                    //     }
                    // },
                    null
                ]}
                backStepperButtonAction={[null, null, null]}
            />
    )
}

export default ExportProducts