import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import CardCustom from '../../../Card/CardCustom';
import { capitalize } from 'lodash';

export default function GridView(props) {
    let { settings, items, currentLang, propsToPass } = props;
    let columns = settings.columns;
    let layout = settings?.layout; 

    return (
        <Grid
            container
            columnSpacing={2}
            rowGap={2}
            className='listing'
            sx={{
                marginTop:0,
                overflow:props.scroll ?"auto":"hidden",
                "&::-webkit-scrollbar":{
                    display: "none"
                },
                "&::-webkit-scrollbar-track":{
                    display: "none"
                },
                "&::-webkit-scrollbar-thumb" :{
                    display: "none",
                }
            }}
        >
            {items?.length > 0 ?
                items.map((item, index) => (
                    <Grid
                        item
                        key={`list${capitalize(props.label)}-${index}`}
                        xs={12 / (columns.xs ? columns.xs : 1)}
                        sm={12 / (columns.sm ? columns.sm : 2)}
                        lg={12 / (columns.laptop ? columns.laptop : 3)}  
                        sx={{
                            // paddingTop:"0px !important",
                            // paddingBottom:"16px !important"
                        }}                      
                    >
                        <CardCustom
                            style={{ width: "100%", height: "100%", padding: 0, ...props.cardContainerProps?.style }}
                            cardContentStyle={{ padding: 0 }}
                            contentpadding={props.cardContainerProps?.contentpadding}
                        >
                            <settings.components data={item} {...props.cardProps} {...propsToPass} currentLang={currentLang} />
                        </CardCustom>
                    </Grid>
                ))
                : null}
        
        </Grid>
    )
}


