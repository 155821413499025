import React, { useEffect }  from 'react';

import { Box, Grid,Checkbox } from '@mui/material';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { withRouter } from 'react-router';
import { ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS } from '../../../../js/constants/route-names';
import styled from 'styled-components';
import * as moment from 'moment';
import 'moment-timezone';

import emptyCompany from '../../../../assets/images/emptyCompany.jpg';
import { getTraductionAttributs } from '../../../../js/utils/functions';


const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    background-repeat : no-repeat;
`;

const ContainerStyled = styled(Grid)`
    padding-bottom: 0.5rem;
`;

const LineColor = styled(Box)`
    background: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
`;

const CatalogType = styled(Typography)`
    color: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    font-weight: bold;
    text-transform: uppercase;
    margin-right: ${props => props.marginright};
`;

const CatalogDate = styled(Typography)`
    color: ${colors.grey.regular};
    font-weight: bold;
    text-transform: uppercase;
`;

const CatalogNumbers = styled(Typography)`
    color: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    font-weight: bold;
`;

const CatalogNbType = styled(Typography)`
    margin-top: -0.5rem;
    font-weight: bold;
    color: ${colors.black.regular};
`;

const CheckboxCustom = styled(Checkbox)`
    margin-top: -10px;
    margin-left: -9px;
    padding : 9px;
    width: calc(100% + 32px);
    height: calc(100% + 40px);
    left: -6px;
    top: -6px;
    position: absolute;
    border-radius: 0;
    svg{
        position: absolute;
        bottom: 20px;
        path{
            color: rgb(110, 174, 209);
        }
    }
`;

const ContainerInfos = styled(Box)`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem 0.5rem 1.5rem;
`;

const NameCompany = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;


function CardCompany(props){
    let libelle;
    let image;
    let type;
    let description;
    let companyId   = !props.isDetail ? props.data.node.id : props.data.id;

    const [companyData, setCompanyData] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [checked,setChecked] = React.useState(false);

    const handleCheckbox=(checked)=>{
        setChecked(!checked);
        props.handleSelectCompany(!checked,companyId)
    }

    const checkIsChecked = (id) => {
        if (props.uniqueSelect){
            if (props.selectedCompanies.toAdd === id)
                return true
            else{
                return false
            }
        }else{
            return checked
        } 
    }

    const goTo = (route, id) => {
        props.history.push({
            pathname : route,
            state: {idCompany : id}
        });
    };

    useEffect(() => {
        if (!props.isDetail){
            if (companyData !== props.data.node){
                let toDateStart = moment(props.data.node.createdAt).format('L');
                // let toDateEnd = moment(props.data.node.endAt).format('L');
                setCompanyData(props.data.node)
                setStartDate(toDateStart)
                // setEndDate(toDateEnd)
                if(props.inputCard){
                    if(props.currentCompanies?.includes(props.data.node.id)){
                        setChecked(true)
                    }      
                }  
            }            
        }else{
            if (companyData !== props.data){
                let toDateStart = moment(props.data.createdAt).format('L');
                // let toDateEnd = moment(props.data.endAt).format('L');
                setCompanyData(props.data)
                setStartDate(toDateStart)
                // setEndDate(toDateEnd)
                if(props.inputCard){
                    if(props.currentCompanies.includes(props.data.id)){
                        setChecked(true)
                    } 
                }
            }               
        }
    });
    let attributes = null;
    let attributesSecondary = null;
    if (props.isOffer){
        attributes = props.data.node;
        libelle = attributes.title;
        description = attributes.description;
        // type = getTraductionAttributs('company_type', attributes, props.currentLang);
        // attributesSecondary = attributes.filter(e => e.node.attribute.identifier !== 'company_name' && e.node.attribute.identifier !=='company_type' && e.node.attribute.identifier !=='company_image' && e.node.attribute.identifier !=='company_since')
    }else{
        attributes = !props.isDetail ? props.data.node?.companyDatas?.edges : props.data.companyDatas?.edges;
        libelle = getTraductionAttributs('company_name', attributes, props.currentLang);
        type = getTraductionAttributs('company_type', attributes, props.currentLang);
        image = getTraductionAttributs('company_image', attributes, props.currentLang,'image')?.filePath;
        attributesSecondary = attributes.filter(e => e.node.attribute.identifier !== 'company_name' && e.node.attribute.identifier !=='company_type' && e.node.attribute.identifier !=='company_image' && e.node.attribute.identifier !=='company_since')
    }   
    
    return(

        companyData ? (
            <Box 
                width={'100%'} 
                style={{cursor: props.hasCursor ? 'pointer' : 'auto', height: '100%', display: 'flex', flexDirection:'column', position: 'relative'}} 
                onClick={props.inputCard ? ()=>handleCheckbox(checked) : null}
            >
                {
                    props.isOffer ?
                        <>
                            {
                                props.select ? <CheckboxCustom checked={checkIsChecked(companyId)} onClick={()=>handleCheckbox(checked)} disableRipple={true} /> : null
                            }
                            <Grid container lg={12} direction={"column"} >
                                <ContainerInfos style={{padding: '16px 24px'}}>
                                    <ContainerStyled 
                                        container 
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs={props.openForm ? 6 : 8}> 
                                            <Grid container alignItems="flex-start">
                                                <CatalogDate variant="h4" colortext={colors.grey.regular} component="div">
                                                    {startDate} 
                                                </CatalogDate> 
                                            </Grid>
                                        </Grid>
                                    </ContainerStyled>
                                    <NameCompany variant="h2" colortext={colors.black.regular}>
                                        {libelle}
                                    </NameCompany>
                                    <Typography variant="h5" colortext={colors.grey.regular} dangerouslySetInnerHTML={{ __html: description }} />
                                </ContainerInfos>
                            </Grid>
                        </>
                    :
                    <>
                        {
                            props.select ? <CheckboxCustom checked={checkIsChecked(companyId)} onClick={()=>handleCheckbox(checked)} disableRipple={true} /> : null
                        }
                        <ContainerImage height={160} backgroundimage={image ? `${process.env.REACT_APP_MEDIAS}/${image}` : emptyCompany} />
                        {
                            companyData.type?.identifier ?
                                <LineColor height={8} typecatalog={companyData.type?.identifier}/>
                            : null
                        }
                        <ContainerInfos style={{padding: '16px 24px'}}>
                            <ContainerStyled 
                                container 
                                direction="row"
                                justifyContent="space-between"
                                alignItems={props.openForm ? "flex-start" : "center"}
                            >
                                <Grid item xs={props.openForm ? 6 : 8}> 
                                    <Grid container alignItems="center">
                                        <CatalogType variant="h5" component="div" typecatalog={companyData.type?.identifier} marginright={"1rem"}>
                                            {type ? type : 'n/a'}
                                        </CatalogType>
                                        {
                                            companyData.type?.identifier !== 'permanent' ? 
                                                <CatalogDate variant="h5" colortext={colors.grey.regular} component="div">
                                                    {startDate} 
                                                </CatalogDate> 
                                            : null
                                        }
                                    </Grid>
                                </Grid>
                            </ContainerStyled>
                            <Grid container lg={12} direction={"column"} >
                                {libelle ? 
                                    <NameCompany variant="h2" colortext={colors.black.regular}>
                                        {libelle}
                                    </NameCompany>
                                : <NameCompany variant="h2" colortext={colors.black.regular}></NameCompany>}
                                {props.isDetail ?
                                    <Box mt={2} mb={1}>
                                        <Grid container>
                                            {
                                                attributesSecondary.length > 0 ? 
                                                    attributesSecondary.map((attribute, index) => {
                                                        let typeAttribut = attribute.node.attribute.translation.translationDatas.edges.find(e => e.node.locale.code === props.currentLang);
                                                        let defaultTypeAttribut = attribute.node.attribute.translation.translationDatas.edges[0].node.value;
                                                        return(
                                                            <Grid key={index} item lg={props.openForm ? 12 : 4} xs={props.openForm ? 12 : 6}>
                                                                <Typography variant="h4" colortext={colors.black.regular} style={{paddingTop:8}}>
                                                                    <strong>{typeAttribut ? typeAttribut.node.value : defaultTypeAttribut}  : </strong> {attribute.node.value}
                                                                </Typography> 
                                                            </Grid>
                                                        )
                                                    })
                                                : null
                                            }
                                        </Grid>
                                    </Box>
                                    : null
                                }
                            </Grid>
                            {!props.isDetail ? 
                                <Grid
                                    container 
                                    direction="row"
                                    justifyContent="space-between"
                                    style={{paddingTop: 8, paddingBottom: props.select ? 16 : null}}
                                >
                                    <Grid item xs={props.select ? 12 : 6}>
                                        <Grid container>
                                            <Grid item>
                                                <CatalogNumbers variant="h1" style={{textAlign:'center'}} typecatalog={companyData.type?.identifier}>
                                                    {props.data.node.customers?.totalCount}
                                                </CatalogNumbers>
                                                <CatalogNbType variant="h4">
                                                    {props.data.node.customers?.totalCount > 1 ? 'contacts' : 'contact'}
                                                </CatalogNbType>
                                            </Grid>
                                            {props.isChildren !== false ?
                                                <Grid item style={{marginLeft: 32}}>
                                                    <CatalogNumbers variant="h1" style={{textAlign:'center'}} typecatalog={companyData.type?.identifier}>
                                                        {props.data.node.children?.totalCount ? props.data.node.children?.totalCount : 0}
                                                    </CatalogNumbers>
                                                    <CatalogNbType variant="h4">
                                                        {props.data.node.children?.totalCount > 1 ? 'filiales' : 'filiale'}
                                                    </CatalogNbType>
                                                </Grid>                                
                                            :null}
        
                                        </Grid>
                                    </Grid>
                                    {
                                        !props.select ? (
                                            <Grid item xs={props.select ? 12 : 6}>
                                                <Grid container justifyContent="flex-end">
                                                    <Box mt={props.openForm ? 1 : 0}>
                                                        <Button 
                                                        types="Edit"
                                                        text={props.textButton} 
                                                        color={colors.blue.darker.hue300} 
                                                        bgcolor={colors.white} 
                                                        bgcolorHover={colors.blue.darker.hue300}
                                                        disableElevation={true} 
                                                        arrow="right" 
                                                        onClick={props.edit ? ()=> props.data.action() :() => goTo(ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS.replace(':id', companyData.libelle), companyData.id)}/>
                                                    </Box>
                                                </Grid>
                                            </Grid> 
                                        ) : null
                                    }
                                </Grid>
                            : 
                                <Grid
                                    container 
                                    direction="row"
                                    justifyContent="space-between"
                                    style={{paddingTop:8}}
                                >
                                    <Grid item lg={props.openForm ? 12 : 7} xs={6}>
                                        <Grid container>
                                            <Grid item>
                                                <CatalogNumbers variant="h1" style={{textAlign:'center'}} typecatalog={companyData.type?.identifier}>
                                                    {props.data.customers?.totalCount}
                                                </CatalogNumbers>
                                                <CatalogNbType variant="h4">
                                                    {props.data.customers?.totalCount > 1 ? 'contacts' : 'contact'}
                                                </CatalogNbType>
                                            </Grid>
                                            {props.isChildren !== false ?
                                                <Grid item style={{marginLeft: 32}}>
                                                    <CatalogNumbers variant="h1" style={{textAlign:'center'}} typecatalog={companyData.type?.identifier}>
                                                        {props.data.children?.totalCount ? props.data.children?.totalCount : 0}
                                                    </CatalogNumbers>
                                                    <CatalogNbType variant="h4">
                                                        {props.data.children?.totalCount > 1 ? 'filiales' : 'filiale'}
                                                    </CatalogNbType>
                                                </Grid>                                
                                            :null}
                                        </Grid>
                                    </Grid>   
                                </Grid>
                            }
                            
                            {!props.select && !props.isList ? 
                                <Grid item lg={12} xs={6}>
                                    <Grid container justifyContent="flex-end">
                                        <Box mt={props.openForm ? 1 : 0}>
                                            <Button 
                                            text={props.textButton} 
                                            color={colors.blue.darker.hue300} 
                                            border={`1px solid ${colors.blue.darker.hue300}`}
                                            colorhover={colors.white}
                                            bgcolor={colors.white} 
                                            bgcolorHover={colors.blue.darker.hue300}
                                            disableElevation={true} 
                                            arrow="right" 
                                            onClick={props.edit ? ()=> props.data.action() :() => goTo(ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS.replace(':id', companyData.libelle), companyData.id)}/>
                                        </Box>
                                    </Grid>
                                </Grid> 
                            : 
                                null
                            }
                        </ContainerInfos>
                    </>
                }
            </Box>
        )
        : null
    )
}

export default withRouter(CardCompany);
