import { FormInputTypes } from '../../../../../builder/shareable/types';
import CardUserGroup from '../../../../layouts/Card/cardContent/CardUserGroup';

export const listSettings = {
    grid : {
        components : CardUserGroup,
        columns : {
            xs : 2,
            sm : 3,
            laptop : 3,
        }
    },
}

export const listMappers = [
    {
        view : 'card',
        mapper : null
    },
    // {
    //     view : 'table',
    //     mapper : 'companiesListMapper'
    // }
]

export const perPageOptions = {
    'card' : ['6'],
}

export const viewOptions = {
    current : 'card',
    settings: [
        {
            label: "Card",
            value:"card"
        }
    ]
}


export const filtersOptions = {
    title: 'Filtres et Recherche',
    inputs: [
        {
            xs:3,
            required: false,
            disabled: false,
            topLabel:"Par statut",
            label:'Recherche par SKU, nom, ect …',
            type: FormInputTypes.SELECT,
            stateName: 'status',
            helper: {
                position: 'top',
                text: null,
                link: false,
            },
            value:[
                {
                    label:"Tous les statuts",
                    value:'all',
                },
                {
                    label:"Actif",
                    value:"true",
                },
                {
                    label:"Inactif",
                    value:"false",
                },
            ]
        },
        {
            xs: 9,
            required: false,
            disabled: false,
            topLabel:"Taper votre recherche",
            label:'Recherche par nom',
            type: FormInputTypes.TEXT,
            stateName: 'libelle',
            helper: {
                position: 'top',
                text: null,
                link: false,
            }
        },
    ]
}
