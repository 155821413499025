import { Box, Grid } from "@mui/material"
import LayoutFormBuilder from "../../../ui/form/LayoutFormBuilder"
import { Suspense, useEffect, useState } from "react"
import { eventService } from "../../../../js/services/event.service"
import { ALERT_ERROR } from "../../../../js/constants/alert-types"
import { useToggle } from "@uidotdev/usehooks"
import SystemInfosConfig from "./config/systemInfos.config"
import TopPanel from "../../../layouts/TopPanel/TopPanel"
import colors from "../../../../config/theme/colors"
import CardSchema from "../../../layouts/Card/cardContent/CardSchema"
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { ADD_CURRENCY, DELETE_CURRENCY, GET_CURRENCIES, UPDATE_CURRENCY } from "../../../../queries/currencies"
import { ADD_LOCALE, DELETE_LOCALE, GET_LOCALES, UPDATE_LOCALE } from "../../../../queries/locales"
import { GET_TAXRATES } from "../../../../queries/tax_rates"
import { withApollo } from "@apollo/client/react/hoc"
import { connect } from "react-redux"
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types"
import PageLoader from "../../../ui/loadings/page-loader/PageLoader"
import { v4 as uuidv4 } from "uuid";
import { CURRENCIES, LOCALES, SYSTEMS } from "./constants/settings"

const Settings = (props) => {
  const [isDrawerOpen, toggleDrawer] = useToggle(false)
  const [drawerType, setDrawerType] = useState(null)
  const [clickedItem, setClickedItem] = useState(null)
  const [inputsOptions, setInputsOptions] = useState(null)
  const [state, setState] = useState({
    edit: {
      errors: {},
      rows: []
    }
  })
  const settings = [
    {
      id: 1,
      slug: SYSTEMS.CURRENCY,
      title: 'Devises',
      summary: {
        'Nombre': inputsOptions?.currencies?.edges.length,
        'Devise(s)': inputsOptions?.currencies?.edges.map(({ node }) => node.libelle).join(', ') || '-'
      },
      action: (id) => {
        const item = settings.find(item => item.id === id)
        setClickedItem(item)
        handleToggleDrawer('edit')
      }
    },
    {
      id: 2,
      slug: SYSTEMS.LANG,
      title: 'Langues',
      summary: {
        'Nombre': inputsOptions?.locales?.edges.length,
        'Langue(s)': inputsOptions?.locales?.edges.map(({ node }) => node.libelle).join(', ') || '-'
      },
      action: (id) => {
        const item = settings.find(item => item.id === id)
        setClickedItem(item)
        handleToggleDrawer('edit')
      }
    },
    {
      id: 3,
      slug: SYSTEMS.TAX,
      title: 'Taxes',
      summary: {
        'Nombre': inputsOptions?.taxRates?.edges.length,
        'Taxe(s)': inputsOptions?.taxRates?.edges.map(({ node }) => node.libelle).join(', ') || '-'
      },
      action: (id) => {
        const item = settings.find(item => item.id === id)
        setClickedItem(item)
        handleToggleDrawer('edit')
      }
    },
  ]

  useEffect(() => {
    getInputsData()
  }, [])

  // Whenever the type changes, update the code to the same value
  // IMPORTANT: All sort of drawer inputs states (locales, currencies, taxes, etc from systemInfos.config.js) must follow the same structure (type - code)
  useEffect(() => {
    state[drawerType]?.rows.forEach((row, index) => {
      const listToPick = {
        currency: CURRENCIES,
        lang: LOCALES,
        tax: null //TODO: Add tax rates
      }

      const codeToAdd = listToPick[clickedItem.slug]?.find(locale => locale.code === row.states.type)?.code;

      if (codeToAdd && row.states.code !== codeToAdd) {
        const updatedRow = {
          ...row,
          states: {
            ...row.states,
            code: codeToAdd
          }
        };

        setState(prevState => ({
          ...prevState,
          [drawerType]: {
            ...prevState[drawerType],
            rows: [
              ...prevState[drawerType].rows.slice(0, index), // Keep the rows before the updated row
              updatedRow,
              ...prevState[drawerType].rows.slice(index + 1) // Keep the rows after the updated row
            ]
          }
        }));
      }
    });
  }, [state[drawerType]?.rows]);

  useEffect(() => {
    if(clickedItem){
      const currencies = inputsOptions?.currencies?.edges.map(({node}) => ({
        id: node.id,
        states: {
          'type': node.code,
          'code': node.code
        }
      }))

      const locales = inputsOptions?.locales?.edges.map(({node}) => ({
        id: node.id,
        states: {
          'type': node.code,
          'code': node.code
        }
      }))
      
      // TODO: Change the states to match the tax rates
      const taxRates = inputsOptions?.taxRates?.edges.map(({node}) => ({
        id: node.id,
        states: {
          'type': node.code,
          'code': node.code
        }
      }))

      const allRows = {
        currency: currencies,
        lang: locales,
        tax: taxRates
      }

      setState(prevState => ({
        ...prevState,
        edit: {
          ...prevState.edit,
          // If there are no rows, add one empty row
          rows: allRows[clickedItem.slug].length > 0 
            ? allRows[clickedItem.slug]
            : [{
              id: uuidv4(),
              states: {}
            }]
        }
      }));
    }
  }, [clickedItem])

  const getInputsData = async () => {
    const currencies = props.client.query({
      query: GET_CURRENCIES,
      fetchPolicy: 'no-cache'
    })

    const languages = props.client.query({
      query: GET_LOCALES,
      fetchPolicy: 'no-cache'
    })

    const taxRates = props.client.query({
      query: GET_TAXRATES,
      fetchPolicy: 'no-cache'
    })

    const allPromises = [currencies, languages, taxRates]

    const results = await Promise.allSettled(allPromises)

    const finalData = results.map(result => {
      if (result.status === 'fulfilled') {
        return result.value.data
      }
    })

    // Merge all data into one object
    const finalDataFormatted = {};
    finalData.forEach((item, index) => {
      const key = Object.keys(item)[0];
      finalDataFormatted[key] = item[key];
    });

    setInputsOptions(finalDataFormatted)
  }

  // Drawer
  const handleToggleDrawer = (type) => {
    if(isDrawerOpen){
      // As we are changing the drawer type to null, the form state will be undefined "state[drawerType]" so the inputs will dissapear during the transition to close
      // To avoid this, we toggle the drawer but we wait 500ms to change the drawer type to null so the data is still there during the transition
      setTimeout(() => {
        setDrawerType(null)
      }, 500)
    } else {
      setDrawerType(type)
    }
    toggleDrawer()
  }

  const hasErrors = () => {
    if (state[drawerType].errors) {
      for (let error in state[drawerType].errors) {
        if (state[drawerType].errors[error])
          return true;
      }
    }

    return false;
  };

  const handleFormError = (stateName, error) => {
    let errors = state[drawerType].errors;

    errors[stateName] = error;

    setState(prev => ({
      ...prev,
      [drawerType]: {
        ...prev[drawerType],
        errors: errors
      }
    }));
  };

  const handleInputChange = (stateName, evt, custom, translated) => {
    const value = evt?.target?.value ?? evt;

    //* Set the state in the corresponding drawer
    setState(prev => ({
      ...prev,
      [drawerType]: {
        ...prev[drawerType],
        [stateName]: value,
      }
    }));
  };

  const handlerMutation = async () => {
    if (hasErrors()) {
      props.snack('error', 'Veuillez corriger les erreurs avant de continuer')
    } else {
      updateSystem(clickedItem.slug)
    }
  }

  const updateSystem = async (systemOption) => {
    const queries = {
      [SYSTEMS.CURRENCY]: {
        create: (row) => props.client.mutate({
          mutation: ADD_CURRENCY,
          variables: {
            'code': row.states.code,
            'libelle': CURRENCIES.find(currency => currency.code === row.states.code)?.libelle
          }
        }),
        update: (row) => props.client.mutate({
          mutation: UPDATE_CURRENCY,
          variables: {
            'id': row.id,
            'code': row.states.code,
            'libelle': CURRENCIES.find(currency => currency.code === row.states.code)?.libelle
          }
        }),
        delete: (row) => props.client.mutate({
          mutation: DELETE_CURRENCY,
          variables: {
            'id': row.id
          }
        }),
      },
      [SYSTEMS.LANG]: {
        create: (row) => props.client.mutate({
          mutation: ADD_LOCALE,
          variables: {
            'code': row.states.code,
            'libelle': LOCALES.find(locale => locale.code === row.states.code)?.libelle
          }
        }),
        update: (row) => props.client.mutate({
          mutation: UPDATE_LOCALE,
          variables: {
            'id': row.id,
            'code': row.states.code,
            'libelle': LOCALES.find(locale => locale.code === row.states.code)?.libelle
          }
        }),
        delete: (row) => props.client.mutate({
          mutation: DELETE_LOCALE,
          variables: {
            'id': row.id
          }
        })
      },
      // TODO: Add variables (I need the tax rates list options to add the list into the constants)
      [SYSTEMS.TAX]: {
        create: (row) => props.client.mutate({
          mutation: ADD_CURRENCY,
          variables: {
            'test': 'test'
          }
        }),
        update: (row) => props.client.mutate({
          mutation: ADD_CURRENCY,
          variables: {
            'test': 'test'
          }
        }),
        delete: (row) => props.client.mutate({
          mutation: ADD_CURRENCY,
          variables: {
            'test': 'test'
          }
        }),
      }
    }

    props.startLoading()

    const allRows = state[drawerType].rows
    const rowsToMutate = {
      create: allRows.filter(row => row.create && !row.delete),
      update: allRows.filter(row => row.update && !row.create && !row.delete),
      delete: allRows.filter(row => row.delete && !row.create),
    }
    const actions = Object.keys(rowsToMutate)

    actions.forEach(action => {
      rowsToMutate[action].forEach(async (row) => {
        const mutation = queries[systemOption][action]
        await mutation(row)
      })
    })

    // Refresh data
    await getInputsData()
    props.stopLoading()
    handleToggleDrawer('edit')
    props.snack('success', 'Les modifications ont bien été enregistrées')
  }

  return (
    <Box>
      <TopPanel
        title={'Gestion système'}
        subtitle={'Gestion de vos groupe d’utilisateurs et gestion d’accès'}
        buttonAvailable={true}
        windowWidth={props.windowWidth}
        hasBorder={true}
      />

      {inputsOptions
        ? (
          <Grid container columnSpacing={2} rowGap={2}>
            {settings.map((setting, index) => (
              <Grid item md={4} xs={6}>
                <CardSchema schema={setting} />
              </Grid>
            ))}
          </Grid>
        )
        : <PageLoader />
      }

      <LayoutFormBuilder
        isSublayout={false}
        opened={isDrawerOpen}
        handlerMutation={handlerMutation}
        dataLayout={SystemInfosConfig(
          clickedItem,
          props.locales,
          state?.edit?.rows,
        )}
        allState={state[drawerType]}
        stateCallback={handleInputChange}
        errorCallback={handleFormError}
        forClose={() => handleToggleDrawer('edit')}
        validateButton={true}
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    locales: state.locales,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
  }
}

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(Settings))