export const handleSort = (sortBy,newSort) =>{
    let newSortBy = sortBy;
    let newValue;
    return new Promise((resolve,reject)=>{
        let foundFilter = sortBy.find(e=>e.filter === newSort.filter);
        if(foundFilter){
            // let index = sortBy.findIndex(e=>e.filter === newSort.filter);
            // if(newSort.orderBy !== null){
            //     newSortBy[index] = newSort;
            // } else {
            //     newSortBy.splice(index,1)
            // }
            newValue= foundFilter
        } else {
            newSortBy.push(newSort);
            newValue = newSortBy
        }        
        resolve(newValue);
    })
}