import React from 'react';
import {Typography, Grid,Box,Tooltip} from '@mui/material';
import ReactDOM from 'react-dom';
import TabCalendar from './components/TabCalendar'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import styled,{css} from 'styled-components';
import imgNotFound from '../../../../assets/images/not-found.png'
import PageLoader from '../../../ui/loadings/page-loader/PageLoader'
import * as moment from 'moment';


import { withApollo } from '@apollo/client/react/hoc';
import { 
    GET_ASSETS_FOR_CALENDAR,
} from '../../../../queries/assets';
import { 
    GET_CATALOGS,
} from '../../../../queries/catalogs';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { BROADCAST, BROADCAST_CALENDAR, VIEW } from '../../../../js/constants/constant-rights';
import { withTranslation } from 'react-i18next';

const calendarButton=css`
        background-color:white;
        color:#AAB4BA;
        font-style:italic;
        border-color:#EAECEE;
        transform:translate(110px,-70px);
        &:not(:disabled){
            &.fc-button-active{
                background-color:#0273A5;
                border-color:#0273A5;
                color:white;
                &:focus{
                    box-shadow:none;
                }   
                            
            }
        }
        &:focus{
            box-shadow:0 0 0 0.2rem rgba(110, 174, 209, 0.5)
        } 
        &:active:focus{
            box-shadow:0 0 0 0.2rem rgba(110, 174, 209, 0.5);
            background-color:#0273A5;
            border-color:transparent;
        } 
        
`
const calendarButtonArrow=css`
        background-color:transparent;
        color:#0273A5;
        border:transparent;
        &:not(:disabled):active{
            background-color:transparent;
            color:#0273A5;
            box-shadow:0 0 0 0.2rem rgba(110, 174, 209, 0.5);

        }
`
const CalendarContainer=styled(Grid)`
    background-color:white;
    .fc-day-grid-event{
        border-radius:15px;
        padding:2px 20px;
        font-style:italic;
    }
    .fc-day-header{
        padding:20px 0px;
    }
    .fc-head-container thead{
        border-top:transparent;
    }
    .fc-day-top{
        padding:3px 10px 2px 10px;
        .fc-day-number{
            float:left!important;
            font-weight:bold;
            padding:5px;
        }
    }
    color:#0273A5;
    font-style:italic;
    .fc-unthemed td.fc-today{
        background-color:transparent;
        .fc-day-number{
            background-color:#FF6565;
            color:white;
            border-radius:50%;  
            width:15px;
            height:15px;
            line-height:15px;
            text-align:center;
            
        }
    }
    .fc-center{
        transform:translateX(110px);
    }
    .fc-dayGridMonth-button{
        ${calendarButton};
    }
    .fc-timeGridDay-button{
        ${calendarButton};
    }
    .fc-timeGridWeek-button{
        ${calendarButton};
    }
    .fc-next-button{
        ${calendarButtonArrow}
    }
    .fc-prev-button{
        ${calendarButtonArrow}
    }
    .fc-event{
        cursor:pointer;
        margin-bottom:5px;
        img{
            vertical-align : middle;
            margin : 0 5px;
        }
        span{
            vertical-align : middle;
        }

      
    }
`
const ToolTipCalendar= styled.div`
    font-size:14px;
`
const tooltipTitle = (title) => {
    return (
        <ToolTipCalendar>
            {title}
        </ToolTipCalendar>
    );
};
class Calendar extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            events:[],
            allEvents:[],
            allColors:{    
                retailers:['#ff5e5e','#d13b3b','#b0533e','#e34927'],
                campaigns:['#71d134','#478f66','#5ca34e']
            },
            availableColors:[],
            usedColors:[],
            
        };

        this.calendarCategories = [
            {
                libelle:'Assets',
                type:'campaigns'
            },
            {
                libelle:'Catalogues',
                type:'retailers'
            }
        ];
        
        this.calendarOptions={
            buttonText:{     
                today:    'Aujourd\'hui',
                month:    'Mois',
                week:     'Semaine',
                day:      'Jour',
                list:     'Liste'        
            },
            header: {
                left:   'prev',
                center: 'title',
                right:  'dayGridMonth,timeGridWeek,timeGridDay next',
            },
            height: 750,
            columnHeaderFormat: {
                weekday: 'long'
            }
        }
    }
    
    pickColor = (config, type) => {
        let availables  = this.state.allColors[type];

        if (config.used.length === availables.length) {
            config.used = [];
        }

        for (let available of availables) {
            if (!config.used.includes(available)) {
                config.used.push(available);
                return (available);
            }
        }
    }

    getImageType (asset) {
        const images = require.context('../../../../assets/images/', true);
        let image;
        try{
            image=images('./' + asset);
        } catch(e){
            image=imgNotFound;
        }
        return image;
    }

    getAssetsFeed(){
        return new Promise((resolve) => {
            //---- get feed
            const getAllAssets  = GET_ASSETS_FOR_CALENDAR;
            let config = {
                used: []
            };
            this.props.client.query({
                query:getAllAssets,
                fetchPolicy:'no-cache'
            }).then(result =>{
                let events = [];
                for(let asset of result.data.assets.edges){
                    let img = this.getImageType(asset.node.assetType.logo);

                    events.push(
                    {
                        title:asset.node.identifier,
                        start: moment(asset.node.startAt).format('YYYY-MM-DD'),
                        end: moment(asset.node.endAt).format('YYYY-MM-DD'),
                        backgroundColor:this.pickColor(config, 'campaigns'),
                        borderColor:'transparent',
                        image : typeof img === "string" ? img : img?.default
                    });
                }
                this.setState({isLoading: false});
               
                resolve(events);
            }).catch((err)=>{
                console.log(err)
            });
        })
    }

    getCatalogsFeed(){
        return new Promise((resolve) => {
            //---- get feed
            const getAllCatalogs  = GET_CATALOGS;
            let config = {
                used: []
            };
            this.props.client.query({
                query:getAllCatalogs,
                fetchPolicy:'no-cache'
            }).then(result =>{
                let events = [];
                
                for(let catalog of result.data.catalogs.edges){
                    events.push(
                    {
                        title:catalog.node.libelle,
                        start: moment(catalog.node.startAt).format('YYYY-MM-DD'),
                        end:moment(catalog.node.endAt).format('YYYY-MM-DD'),
                        backgroundColor:this.pickColor(config, 'retailers'),
                        borderColor:'transparent'
                    });
                }
                this.setState({isLoading: false});
                resolve(events);
            }).catch((err)=>{
                console.log(err)
            });
        })
    }

    handleEvents = async (type, callback) => {
        let events = [];
        this.setState({isLoading:true});
        // let config = {
        //     used: []
        // };
    
        switch(type){
            case 'all':
                return this.handleGetAllEvents();
                
            case 'campaigns':
                events = await this.getAssetsFeed();

                break;

            case 'retailers':
                events = await this.getCatalogsFeed();

                break;
            default:
                return null;
        }

        if (callback)
            return callback(events);
        this.setState({ events:events??[] });
        // this.setState({isLoading:!this.state.isLoading});

    };

    handleGetAllEvents = () => {
        let categories  = this.calendarCategories;
        let allEvents   = [];

        for (let category of categories){
            this.handleEvents(category.type, (events) => {
                let newArray = allEvents.concat(events);
                this.setState({
                    events: newArray??[],
                });
            });
        }
        // this.setState({isLoading:false});
    };

    handleEventsTooltip = (info) => {
        ReactDOM.render(
            <Tooltip title={tooltipTitle(info.event.title)} arrow={true} style={{fontSize:14}}>
            <div className="fc-content" style={{padding:'3px 0px 3px 0px'}}>
                <img src={info.event.extendedProps.image} width={15}/>
                <span className="fc-title">{info.event.title}</span>
            </div> 
            </Tooltip>
            ,
            info.el,
        );
        return info.el
        
    };

    componentDidMount() {
        this.handleEvents('campaigns');
    }
    
    render() {
        return (
            <div>
                <Typography variant="h1">{this.props.t('spread.spread_calendar.calendar')}</Typography>
                <TabCalendar categories={this.calendarCategories} handleChangeTab={this.handleEvents} />
                <CalendarContainer container justifyContent="center" width={'100%'}>
                    
                    <Grid item xs={10}>
                        <Box pt={2}>
                            {this.state.isLoading ?
                                <PageLoader />
                            :
                                <FullCalendar
                                defaultView="dayGridMonth"
                                plugins={[ dayGridPlugin,timeGridPlugin ]}
                                buttonText={this.calendarOptions.buttonText }
                                header={this.calendarOptions.header}
                                columnHeaderFormat={this.calendarOptions.columnHeaderFormat}
                                height={this.calendarOptions.height}
                                weekends={true}
                                events={this.state.events}
                                eventRender={this.handleEventsTooltip}
                                eventLimit={2}
                                eventLimitText="Voir plus"
                                locale="fr"
                                firstDay={1}
                                />  
                            }
                        </Box>
                       
                    </Grid>
                

                </CalendarContainer>
               
            </div>

        );
    }

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withTranslation()(withRouter(withApollo(connect(null, mapDispatchToProps)(Calendar))));
