import { Box, Grid, Radio, RadioGroup } from "@mui/material";
import { GridCustom } from "../../styles/inputStyled";
import { ClickableLabel, DescriptionRadio, FormControlLabelCustom, GridRadio } from "./styles/styled";
import CardCustom from "../../../../layouts/Card/CardCustom";
import OurTypography from "../../../typography/Typography";
import imgNotFound from '../../../../../assets/images/not-found.png';

/**
 * Radio button component. It can be a simple text radio or a card radio button.
 *
 * @component
 * @param {Object} allProps - Props for the component.
 * @param {Object} allProps.input - Configuration for the radio button.
 * @param {boolean} allProps.input.isCard - Determines if the radio button uses a card layout.
 * @param {string} allProps.input.stateName - The ID for the radio button group.
 * @param {Array} allProps.input.value - The options for the radio button group.
 * @param {string} allProps.input.value[].value - The value of the option.
 * @param {string} allProps.input.value[].label - The label for the option.
 * @param {string} allProps.input.value[].description - The description for the option.
 * @param {string} allProps.input.value[].image - The image path for the option (optional).
 * @param {string} allProps.input.direction - The direction of the radio button group (optional).
 * @param {boolean} error - Error object containing error details.
 * @param {boolean} error.seeError - Indicates if the error is visible.
 * @param {string} error.errorMessage - The error message to display.
 * @param {Function} updateCallback - Callback function triggered on radio button change.
 * @returns {JSX.Element} OurRadio component.
 * 
 * ! PLEASE: Update the example below with all the props that can be used in the future
 * @example //* In case is a regular simple text radio:
 * {
    isCard: false,
    stateName: 'radioGroup',
    disabled: false,
    direction: 'row',
    value: [
      {
        value: 'option1',
        label: 'Option 1',
      },
      {
        value: 'option2',
        label: 'Option 2',
      },
    ],
  },
  * @example //* In case is a card radio:
  {
    isCard: true,
    stateName: 'radioGroup',
    disabled: false,
    value: [
      {
        value: 'option1',
        label: 'Option 1',
        description: 'Description for Option 1',
      },
      {
        value: 'option2',
        label: 'Option 2',
        description: 'Description for Option 2',
      },
    ],
  },
 * 
 */
const OurRadio = ({ allProps: props, error, updateCallback }) => {

  return (
    <GridCustom item xs={12}>
      <RadioGroup
        id={props.input.stateName}
        aria-label={props.input.stateName}
        variant="outlined"
        color="secondary"
        value={props.value}
        onChange={updateCallback}
      >
        {props.input.isCard
          ? (
            // * The card is non-customizable since it has to be the same every time.
            // * In case of needing a customizable card in the future, create a children field in the input config object.
            <Grid container direction="column">
              {props.input.value.map((option, index) => {
                let image = "";
                const imagesFolder = require.context('../../../../../assets/images', true);
                try {
                  image = imagesFolder('./' + option.image);
                } catch (e) {
                  image = imgNotFound;
                }
                return (
                  <GridRadio key={`Radio${option.value}${index}`} item xs={12}>
                    <ClickableLabel htmlFor={`Radio${option.value}-${index}`} className={!error.seeError ? props.value === option.value ? 'active' : null : 'error'}>
                      <CardCustom paddingtop={'18px'} paddingbottom={'18px'} contentpadding={'16px !important'}>
                        <Grid container alignItems={'center'} justifyContent={'space-between'} wrap={'nowrap'} style={{ gap: '20px' }}>
                          <Grid item style={{ width: '100%' }}>
                            <Grid container direction={'row'} wrap={'nowrap'} alignItems={'center'} style={{ gap: '24px', width: '100%' }}>
                              {/* <img src={typeof image === "string" ? image : image?.default} width={75} height={75} /> */}
                              <Box pl={2} maxWidth={'25%'}>
                                <OurTypography variant={'h4'} style={{
                                  fontWeight: 'bold',
                                  fontSize: '18px',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  hyphens: 'auto',
                                }}>{option.label}</OurTypography>
                                {/* // TODO: Add channel type */}
                                {/* <Typography variant={'h4'} style={{
                                    fontSize: '12px',
                                    color: colors.grey.darker,
                                    lineHeight: '16px',
                                    fontWeight: 'bold',
                                }}>{option.label} - {option.label}</Typography> */}
                              </Box>
                              <Box pl={2} width={'auto'}>
                                <DescriptionRadio variant={'body2'} dangerouslySetInnerHTML={{ __html: option.description }} style={{
                                  fontSize: '16px',
                                }} />
                              </Box>
                            </Grid>
                          </Grid>
                          {
                            !props.input.disabled ?
                              <Box mr={2}>
                                <Radio color="primary" value={option.value} id={`Radio${option.value}-${index}`} disabled={props.input.disabled ? true : false} />
                              </Box> : ''
                          }
                        </Grid>
                      </CardCustom>
                    </ClickableLabel>
                  </GridRadio>
                )
              })}
            </Grid>
          )
          : (
            <Grid container direction={props.input.direction || "column"}>
              {props.input.value.filter(e => e).map((option, index) => {
                return (
                  <FormControlLabelCustom key={index} className={props.value === option.value ? 'active' : null} value={option.value} control={<Radio />} label={option.label} style={{ marginRight: props.input.direction && props.input.direction === "row" ? 32 : 0 }} />
                )
              })}
            </Grid>
          )
        }
      </RadioGroup>
      {
        !error.seeError ? null :
          <span className="error-label">{error.errorMessage}</span>
      }
    </GridCustom>
  )
}

export default OurRadio;