import React from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import 'moment/locale/fr';
import { withRouter } from "react-router";
import { matchPath } from "react-router";
import { withStyles } from '@mui/styles';

import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import SettingsIcon from '@mui/icons-material/Settings';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';

import iconSet from "../../assets/selection.json";
import IcomoonReact from "icomoon-react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import styled from 'styled-components';
import colors from '../../config/theme/colors';
import './DrawerLeft.scss';
import { SET_NAVIGATION } from '../../js/constants/action-types';
import setUpDrawer from './drawer.config.js';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import { getTraductionAttributs } from '../../js/utils/functions';
import i18n from '../../i18n';
import { Box, Grid } from '@mui/material';
import { ROUTE_HOME } from '../../js/constants/route-names';
import OurTypography from '../ui/typography/Typography';
import { menuEntry } from '../../js/utils/menuEntry';

const isCRM = process.env.REACT_APP_MODE_CRM === 'true';

var drawerWidth = 300;

if (window.innerWidth <= 1600 && window.innerWidth > 1400) {
    drawerWidth = 240;
}
else if (window.innerWidth <= 1400 && window.innerWidth > 1000) {
    drawerWidth = 210;
}
else {
    drawerWidth = 240;
}

const styles = theme => ({
    root: {
        display: 'flex',
        '&>main': {
        },
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: `${drawerWidth}px`,
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    drawerOpen: {
        width: `${drawerWidth}px`,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: 'none !important',
        border: `0.5px solid ${colors.grey.lighter.hue700}`,
        overflowX: `hidden`,
        height: '100%',
        paddingTop: isCRM ? 120 : 0,
        ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
            boxShadow: 'initial',
        },
        "&::-webkit-scrollbar":{
            display: "none"
        },
        "&::-webkit-scrollbar-track":{
            display: "none",
        },
        "&::-webkit-scrollbar-thumb":{
            display: "none"
        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        border: `0.5px solid ${colors.grey.lighter.hue700}`,
        overflowX: 'hidden',
        background: isCRM ? colors.crmNavBackground : colors.white,
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
        },
        height: '100%',
        paddingTop: isCRM ? 120 : 0,
        '& .MuiAccordionSummary-expandIcon': {
            display: 'none',
        },
        '& .MuiListItemText-root': {
            display: 'none',
        },
        '& p': {
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            width: '100%',
            fontSize: 13
        },
        '& .activePanel': {
            borderLeft: 'none!important'
        },
        ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
            background: 'rgba(0,0,0,0)',
            boxShadow: 'initial',
        }
    },
    itemOpen: {
        // padding: `0.5rem 1.5rem 0.5rem 2.5rem`,
    },
    itemClose: {
        // padding: `0.5rem 1rem 0.5rem 2.2rem`,
        width: `auto`,
    },
    itemTextOpen: {
        display: `block`,
    },
    itemTextClose: {
        display: `none`,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
});

const AccordionCustom = styled(Accordion)`
    box-shadow: none !important;
    margin-bottom: 0 !important;
    background: transparent;
    &:last-of-type{
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    &:before{
        display: none;
    }
    &.Mui-expanded{
        margin:0 !important;
    }
    border-bottom: 0.5px solid ${colors.grey.lighter.hue700};
    
`;

const AccordionCustomSub = styled(AccordionCustom)`
    border-bottom: 0;
`;

const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 4px 4px 4px 16px !important;
    min-height: 40px !important;
    .MuiSvgIcon-root {
        font-size: 1rem !important;   
    }
    .MuiIconButton-root {
        padding: 4px;
    }
    &> .MuiAccordionSummary-content{
        margin: 7px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &.Mui-expanded{
        min-height: 40px !important;
        .MuiAccordionSummary-content {
            margin: 7px 0 !important;
        }
        p{
            color: ${colors.blue.darker.hue300};
        }
    }
    &:hover{
        p{
            color: ${colors.blue.darker.hue300} !important;
        }
        .MuiAccordionSummary-expandIconWrapper svg{
            background: ${colors.blue.lighter.hue900} !important;
        }
        svg{
            color: ${colors.blue.darker.hue300};
        }
    }
    svg{
        color: ${isCRM ? colors.white : colors.black.regular};
    }
    p{
        font-weight: bold;
        font-size: 12px;
    }
    @media screen and (max-width: 1400px){
        padding: 0 10px;
        &> .MuiAccordionSummary-content{
            margin: 10px 0;
        }
    }
`;

const AccordionSummaryCustomSub = styled(AccordionSummaryCustom)`
    background: ${colors.blue.lighter.hue900};
    padding-left: 40px !important;
    border: 0;
    min-height: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    @media screen and (max-width: 1400px){
        padding-left: 24px!important;
    }
    p{
        color: ${colors.blue.darker.hue300};
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;
    }
    &:hover{
        background:${colors.blue.lighter.hue600} !important;
    }
    svg{
        path{
            fill: ${colors.blue.lighter.hue200} !important;
        }
    }
    span{
        color: ${colors.blue.darker.hue300} !important;
    }
    &.activeNav{
        background: ${colors.blue.lighter.hue600} !important;
        svg{
            path{
                fill: ${colors.blue.darker.hue300} !important;
            }
        }
        p{
            color: ${colors.blue.darker.hue300} !important;
            font-weight: bold;
        }
    }
    &> .MuiAccordionSummary-content{
        margin: 0;
    }
`

const AccordionDetailsCustom = styled(AccordionDetails)`
    padding: 0;
`;
const AccordionDetailsSubCustom = styled(AccordionDetailsCustom)`
    padding: 16px 0px !important;
    background: ${colors.blue.lighter.hue900};
`;

const ListCustom = styled(List)`
    width: 100%;
    padding: 0;
    padding-top:0 !important;
    padding-bottom:0 !important;
`;

const ListItemCustom = styled(ListItem)`
    &:hover{
        background: ${colors.blue.lighter.hue900};
        &.activeNav {
        }
        svg{
            path{
                fill: ${colors.blue.darker.hue300};
            }
        }
        span{
            color: ${colors.blue.darker.hue300};
        }
    }
    @media screen and (max-width: 1400px){
        padding-left: 10px!important;
        padding-right: 10px !important;
        max-width: 100%;
    }
`;
const ListItemSubTitleCustom = styled(ListItemCustom)`
    background: ${colors.blue.regularv2} !important;
    &:hover{
        background:${colors.blue.regularv2} !important;
    }
`;

const ListItemSubCustom = styled(ListItemCustom)`
    background: ${colors.blue.lighter.hue900};
    padding-left:40px ;
    @media screen and (max-width: 1400px){
        padding-left: 24px!important;
    }
    &:hover{
        background: ${colors.blue.lighter.hue600} ;
    }
    svg{
        path{
            fill: ${colors.blue.lighter.hue200} !important;
        }
    }
    span{
        color: ${colors.blue.darker.hue300} !important;
    }
    &.activeNav{
        background: ${colors.blue.lighter.hue600} !important;
        svg{
            path{
                fill: ${colors.blue.darker.hue300} !important;
            }
        }
        span{
            color: ${colors.blue.darker.hue300} !important;
        }
    }
`;

const ListItemSubSubCustom = styled(ListItemSubCustom)`
    padding-left: 85px !important;
    min-height: 44px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    @media screen and (max-width: 1400px){
        padding-left: 70px!important;
    }
    span{
        color: ${colors.blue.regularv2}
    }
    &.activeNav, &:hover{
        background: none !important;
        svg{
            path{
                fill: ${colors.blue.darker.hue300} !important;
            }
        }
        span{
            color: ${colors.blue.darker.hue300} !important;
            font-weight: bold;
        }
    }
`;

const TypographyCustom = styled(Typography)`
    &:first-letter{
        text-transform: capitalize;
    }
`;

const TypographyCustomSettings = styled(Typography)`
    &:first-letter{
        text-transform: capitalize;
    }
`;

const ListItemTextCustom = styled(ListItemText)`
    span{
        font-size: 14px;
        @media screen and (max-width: 1400px){
            line-height: 18px;
        }
    }
`;

const ListItemSubTextCustom = styled(ListItemTextCustom)`
    position: relative;
    margin: 0;
    &:before{
        content:"";
        background: ${colors.blue.lighter.hue200}; 
        width: 1.5px;
        height: 100%;
        min-height: 44px;
        position: absolute;
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
    }
`

const ListItemIconCustom = styled(ListItemIcon)`
    svg{
        border-radius: 5px;
    }
    min-width: auto !important;
`;

const DrawerCustom = styled(Drawer)`
    width: ${props => props.drawerwidth ? props.drawerwidth : null}px;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    box-shadow: 3px 0px 22px #d4d9dc;
    &#closed{
        @media screen and (max-width: 1400px){
            .MuiListItem-gutters{
                justify-content: center;
            }
        }
    }
    .MuiDrawer-paper{
        width: ${props => props.drawerwidth ? `${props.drawerwidth}px` : null};
    }
    .MuiListItem-root{
        width: ${props => props.drawerwidth ? `${props.drawerwidth}px` : null};
    }
`;

const SwipeableDrawerCustom = styled(SwipeableDrawer)`
    .MuiPaper-root{
        position: relative;
        width: calc(100% - 2px);
    }
`;

const EncartTopLeft = styled(Box)`
    left: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    overflow:hidden;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    img{
        display: inline-block;
        padding-right: 10px;
        padding-left: 10px;
        width: 120px;
        height: auto;
        max-width: 100%;
    }
    .MuiTypography-h2{
        color: ${colors.black.regular};
        display: inline-block;
        padding-right: 20px;
    }
`;

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.black.regular,
    },
    tooltip: {
        backgroundColor: colors.black.regular,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class DrawerLeft extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLang: this.props.locales?.[0]?.node?.code,
            expanded: null,
            expandedSub: null,
            menuItems: null,
            subMenuItems: null,
            childActive: null,
            detailActive: null,
            settingPosition: '100%',
            assetName: '...',
            asset: JSON.parse(localStorage.getItem('ASSET_CURRENT')) || null,
        };
        window.setAssetNameMenu = this.setAssetNameMenu
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleChangeSub = panel => (event, expandedSub) => {
        this.setState({
            expandedSub: expandedSub ? panel : false,
        });
    };

    goTo = (route, customState) => {
        this.props.history.push({
            pathname: route,
            state: { customState: customState }
        });
    };

    setAssetNameMenu = (asset) => {
        let getName = getTraductionAttributs('asset_store_name', asset.assetDatas?.edges, i18n.language)
        localStorage.setItem('ASSET_CURRENT', JSON.stringify(asset))
        this.setState({
            asset: asset,
            assetName: getName
        })
    }

    checkPath = (path) => {
        let pathFound = false;
        for (let item of this.state.menuItems) {
            // Check the browser path is inside the allRoutes of first level item
            if (item.allRoutes.indexOf(path) > -1 || (!item.isHome && path.includes(item.route))) {
                pathFound = true
                //* The first level item is expanded
                this.setState({
                    expanded: item.route,
                })
                let childrenItemsWithChildren = item.childrens?.filter(e => e?.childrensStructure);
                let { parent: parentRoute, ...grandParentItem } = { ...this.getParent(childrenItemsWithChildren, path) };
                let { parent: parentRouteSub, ...parentItem } = { ...this.getParent(childrenItemsWithChildren, path, true) };
                let isDetail = path.includes('/detail/');
                let isPriceDetail = path.includes('prices/list/detail');

                // 2 levels, 3 levels or URL with /detail/
                if (parentRoute || item.childrens.find(e => e?.route === path) || isDetail) {
                    if (isDetail && !isPriceDetail) {
                        let parentSetup = item.childrens.find(e => e?.route === parentRoute)
                        this.setState({
                            childActive: parentSetup,
                            detailActive: item.childrens.find(e => path?.includes(e?.route)),
                            expandedSub: parentRouteSub
                        })
                    }
                    else if (isPriceDetail) {
                        localStorage.removeItem('ASSET_CURRENT')
                        this.setState({
                            assetName: '...',
                            childActive: item.childrens.find(e => e?.route === '/products/prices'),
                            expandedSub: parentRouteSub,
                            detailActive: null
                        })
                    } else if (item.childrens.find(e => e?.route === path)) {
                        // Path -> Browser URL
                        // Item -> Menu Item URL
                        localStorage.removeItem('ASSET_CURRENT')
                        this.setState({
                            assetName: '...',
                            childActive: item.childrens.find(e => e?.route === path),
                            expandedSub: parentRouteSub,
                            detailActive: null
                        })
                        // } else if(test()){
                        // this.setState({
                        //     assetName: '...',
                        //     childActive: item.childrens.find(e => e?.route === path),
                        //     expandedSub: parentRouteSub,
                        //     detailActive: null
                        // })
                        // Check that the current URL is a grand-child of the current menu item
                    } else {
                        let parentSetup = item.childrens.find(e => e?.route === parentRoute)
                        localStorage.removeItem('ASSET_CURRENT')
                        this.setState({
                            assetName: '...',
                            childActive: parentSetup,
                            detailActive: null,
                        })
                    }
                } else if (parentRouteSub) {
                    // 4 Levels

                    const childParentStart = childrenItemsWithChildren?.find(e => e?.allRoutes?.includes(path))

                    localStorage.removeItem('ASSET_CURRENT')
                    this.setState({
                        assetName: '...',
                        childActive: this.state.subChildActive ?? childParentStart,
                        detailActive: this.getParent(childrenItemsWithChildren, path, true),
                        expandedSub: parentRouteSub
                    })
                } else {
                    localStorage.removeItem('ASSET_CURRENT')
                    this.setState({
                        assetName: '...',
                        childActive: null,
                        detailActive: null,
                    })
                }
            }
        }

        // You need to add the path to the allRoutes of the 1 level
        if (!pathFound) {

            localStorage.removeItem('ASSET_CURRENT')
            this.setState({
                expanded: null,
                childActive: null,
                detailActive: null
            })
        }
    };

    isPathChildren = (path, childrens) => {
        for (let children of childrens) {
            const match = matchPath(path, {
                path: children,
                exact: true,
                strict: false
            });
            if (match) {
                return true;
            }
        }
        return false;
    };

    getParent = (children, path, isSub = false) => {
        // 3 Level
        // Get the 4 level routes and get its parent route
        if (isSub) {
            for (let child of children) {
                let match = child.childrensStructure.filter(e => e.childrensStructure);
                if (match) {
                    for (let child2 of match) {
                        for (let sub of child2.childrens) {
                            const getMatch = matchPath(path, {
                                path: sub,
                                exact: true,
                                strict: false
                            });
                            if (getMatch) {
                                const valueParent = child2.childrensStructure.find(e => e.route === getMatch.path || getMatch.path.includes(e.route))
                                return valueParent;
                            }
                        }
                    }
                }
            }

            return false;
        } else {
            // 2 Level
            // Get the 3 level routes and get its parent route
            for (let child of children) {
                const match = child.childrensStructure.find(e => e.route === path)
                if (match) {
                    return match;
                }
            }
        }

        return false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.setNavigation(this.props.location.pathname)
            if (JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
                this.setAssetNameMenu(JSON.parse(localStorage.getItem('ASSET_CURRENT')))
            }
            this.checkPath(this.props.location.pathname)
            this.setState({
                menuItems: setUpDrawer(isCRM)
            })
        }
        if (prevProps.attributes !== this.props.attributes) {
            this.setState({
                menuItems: setUpDrawer(isCRM)
            })
        }
    }

    componentDidMount() {
        this.setState({
            menuItems: setUpDrawer(isCRM)
        }, () => {
            if (JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
                this.setAssetNameMenu(JSON.parse(localStorage.getItem('ASSET_CURRENT')))
            }
            this.props.setNavigation(this.props.location.pathname)
            this.checkPath(this.props.location.pathname)
        })
    }

    getGuidelineName = () => {
        if (this.props.guideline) {
            if (this.props.guideline.libelle) {
                return this.props.guideline.libelle;
            }
            return null;
        }
        else {
            return null;
        }
    };

    getLinkBrand = () => {
        if (this.props.guideline) {
            for (let guidelineData of this.props.guideline.guidelineData.edges) {
                if (guidelineData.node.locale.code === this.state.currentLang && guidelineData.node.attribute.identifier === "guideline_url") {
                    return (guidelineData.node.value)
                }
            }
            return null;
        }
        else {
            return null;
        }
    };

    getGuidelineImg = () => {
        if (this.props.guideline) {
            for (let guidelineData of this.props.guideline.guidelineData.edges) {
                if (guidelineData.node.locale.code === this.state.currentLang && guidelineData.node.attribute.identifier === "guideline_logo") {
                    return process.env.REACT_APP_API_ROOT + '/medias/' + guidelineData.node.media?.filePath;
                }
            }
            return null;
        }
        else {
            return null;
        }
    };

    handleOpenSettings = () => {
        this.setState({
            settingPosition: this.state.settingPosition === "100%" ? "0%" : "100%",
            showMenu: !this.state.showMenu,
        })
    };

    getCurrentTab = (root) => {
        const allRoutes = root?.allRoutes;

    }

    render() {
        const { props } = this;
        const { expanded, menuItems, expandedSub } = this.state;
        const { classes, t } = this.props;
        const isCRM = process.env.REACT_APP_MODE_CRM === 'true';
        const settingsItem = menuItems?.find(e => e.route === '/settings');
        if (props.windowWidth <= 1000) {
            return (
                <SwipeableDrawerCustom
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: props.opened,
                        [classes.drawerClose]: !props.opened,
                    })}
                    open={props.opened}
                    style={{ zIndex: 100 }}
                    onClose={() => { return null }}
                    onOpen={() => { return null }}
                >
                    <Grid container alignItems='center' style={{ zIndex: 1, minHeight: "none" }}>
                        {
                            isCRM ?
                                null
                                :
                                <EncartTopLeft className={props.opened ? 'open' : 'close'} imagefound={(this.getGuidelineImg() !== null && isCRM === false) ? "true" : "false"} drawerwidth={props.opened ? props.drawerWidth : drawerWidth} onClick={() => this.goTo(ROUTE_HOME)} style={{ cursor: 'pointer' }}>
                                    {
                                        this.getGuidelineImg() !== null && isCRM === false ? (
                                            <img src={this.getGuidelineImg()} alt={this.getGuidelineName()} />
                                        ) : this.getGuidelineName() !== null && isCRM === false ? (
                                            <Typography variant="h2">{this.getGuidelineName()}</Typography>
                                        ) : null
                                    }
                                </EncartTopLeft>
                        }
                    </Grid>
                    {menuItems ?
                        menuItems.map((item, index) => {
                            if (!item.childrens.every(_.isNull)) {
                                if (!item.notAccordion) {
                                    return (
                                        <AccordionCustom
                                            expanded={expanded === item.route}
                                            onChange={this.handleChange(item.route)}
                                            TransitionProps={{
                                                unmountOnExit: false,
                                                unmountOnEnter: false,
                                                enter: false,
                                                exit: false
                                            }}
                                            TransitionComponent={Collapse} //* This is the part that solve the issue of the accordion error on responsive (it was using the comopnent itself "<Collapse in={false} />") 
                                            key={`MenuItem${index}`}
                                            elevation={0}
                                            square
                                            disableGutters
                                        >
                                            <AccordionSummaryCustom
                                                expandIcon={expanded === item.route ? <p>ok</p> : <AddIcon />}
                                                className={item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{t(item.name, item.nameArgs)}</TypographyCustom>
                                            </AccordionSummaryCustom>
                                            <AccordionDetailsCustom>
                                                <ListCustom disablePadding>
                                                    {
                                                        item.childrens.map((children, index) => {
                                                            if (children !== null) {
                                                                if (children.hasSubmenu || this.state.detailActive) {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <BootstrapTooltip
                                                                                title={t(children.name, children.nameArgs)}
                                                                                placement="right"
                                                                                disableHoverListener={props.opened ? true : false}
                                                                                className={clsx(classes.tooltip)}
                                                                                key={`ChildItem${index}`}
                                                                            >
                                                                                <ListItemCustom button onClick={() => { this.goTo(children.route, children.customState); this.setState({ subMenuItems: children.childrensStructure }) }} className={
                                                                                    clsx(classes.drawer, {
                                                                                        [classes.itemOpen]: props.opened,
                                                                                        [classes.itemClose]: !props.opened,
                                                                                    },
                                                                                        props.location.pathname === children.route || (children.childrens ? this.isPathChildren(props.location.pathname, children.childrens) : false) ? 'activeNav' : '')
                                                                                }>
                                                                                    <ListItemIconCustom>
                                                                                        <IcomoonReact iconSet={iconSet} size={26} icon={children.icone} />
                                                                                    </ListItemIconCustom>
                                                                                    <ListItemTextCustom primary={t(children.name, children.nameArgs)} />
                                                                                </ListItemCustom>
                                                                            </BootstrapTooltip>
                                                                            {
                                                                                this.state.detailActive && this.state.detailActive.route === children.route && this.state.detailActive.childrensStructure ?
                                                                                    <>
                                                                                        <BootstrapTooltip
                                                                                            title={this.state.assetName}
                                                                                            placement="right"
                                                                                            disableHoverListener={props.opened ? true : false}
                                                                                            className={clsx(classes.tooltip)}
                                                                                        // key={`ChildItemSub${indexSub}`}
                                                                                        >
                                                                                            <ListItemSubTitleCustom className={
                                                                                                clsx(classes.drawer, {
                                                                                                    [classes.itemOpen]: props.opened,
                                                                                                    [classes.itemClose]: !props.opened,
                                                                                                },
                                                                                                    this.state.detailActive.route === children.route ? 'activeNav' : '')
                                                                                            }>
                                                                                                {/* <ListItemIconCustom>
                                                                                            <IcomoonReact iconSet={iconSet} size={28} icon={'ico_retour_fonce'} />
                                                                                        </ListItemIconCustom> */}
                                                                                                <ListItemTextCustom primary={this.state.assetName} />
                                                                                            </ListItemSubTitleCustom>
                                                                                        </BootstrapTooltip>
                                                                                        {
                                                                                            this.state.assetName !== '...' ?
                                                                                                this.state.detailActive.childrensStructure?.map((item, indexAsset) => {
                                                                                                    var numbAssetId = props.location.pathname.match(/\d+/)?.[0]
                                                                                                    return (
                                                                                                        <BootstrapTooltip
                                                                                                            title={t(item.name, item.nameArgs)}
                                                                                                            placement="right"
                                                                                                            disableHoverListener={props.opened ? true : false}
                                                                                                            className={clsx(classes.tooltip)}
                                                                                                            key={`ChildItemSubAsset${indexAsset}`}
                                                                                                        >
                                                                                                            <ListItemSubCustom button onClick={() => { this.goTo(item.route?.replace(':id', numbAssetId || this.state.asset.id?.replace('/api/assets/', ''))?.replace(':typeAsset', this.state.asset.assetType.identifier), item.customState); }} className={
                                                                                                                clsx(classes.drawer, {
                                                                                                                    [classes.itemOpen]: props.opened,
                                                                                                                    [classes.itemClose]: !props.opened,
                                                                                                                },
                                                                                                                    props.location.pathname === item.route?.replace(':id', numbAssetId || this.state.asset.id?.replace('/api/assets/', ''))?.replace(':typeAsset', this.state.asset.assetType.identifier) || (item.childrens ? this.isPathChildren(props.location.pathname, item.childrens) : false) ? 'activeNav' : '')
                                                                                                            }>
                                                                                                                <ListItemIconCustom>
                                                                                                                    <IcomoonReact iconSet={iconSet} size={26} icon={'ico_retour_fonce'} />
                                                                                                                </ListItemIconCustom>
                                                                                                                <ListItemTextCustom primary={t(item.name, item.nameArgs)} />
                                                                                                            </ListItemSubCustom>
                                                                                                        </BootstrapTooltip>
                                                                                                    )
                                                                                                })
                                                                                                : null
                                                                                        }
                                                                                    </>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                this.state.childActive && this.state.childActive.hasSubmenu && (this.state.childActive.parent === children.route || this.state.childActive.route === children.route) ?
                                                                                    this.state.childActive.childrensStructure.map((childActive, indexSub) => {
                                                                                        return (
                                                                                            <BootstrapTooltip
                                                                                                title={t(childActive.name, childActive.nameArgs)}
                                                                                                placement="right"
                                                                                                disableHoverListener={props.opened ? true : false}
                                                                                                className={clsx(classes.tooltip)}
                                                                                                key={`ChildItemSub${indexSub}`}
                                                                                            >
                                                                                                <ListItemSubCustom button onClick={() => { this.goTo(childActive.route, childActive.customState); }} className={
                                                                                                    clsx(classes.drawer, {
                                                                                                        [classes.itemOpen]: props.opened,
                                                                                                        [classes.itemClose]: !props.opened,
                                                                                                    },
                                                                                                        props.location.pathname === childActive.route || (childActive.childrens ? this.isPathChildren(props.location.pathname, childActive.childrens) : false) ? 'activeNav' : '')
                                                                                                }>
                                                                                                    <ListItemIconCustom>
                                                                                                        <IcomoonReact iconSet={iconSet} size={26} icon={'ico_retour_fonce'} />
                                                                                                    </ListItemIconCustom>
                                                                                                    <ListItemTextCustom primary={t(childActive.name, childActive.nameArgs)} />
                                                                                                </ListItemSubCustom>
                                                                                            </BootstrapTooltip>
                                                                                        )
                                                                                    })
                                                                                    : null
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <BootstrapTooltip
                                                                            title={t(children.name, children.nameArgs)}
                                                                            placement="right"
                                                                            disableHoverListener={props.opened ? true : false}
                                                                            className={clsx(classes.tooltip)}
                                                                            key={`ChildItem${index}`}
                                                                        >
                                                                            <ListItemCustom button onClick={() => { this.goTo(children.route, children.customState); this.setState({ subMenuItems: null }) }} className={
                                                                                clsx(classes.drawer, {
                                                                                    [classes.itemOpen]: props.opened,
                                                                                    [classes.itemClose]: !props.opened,
                                                                                },
                                                                                    props.location.pathname === children.route || (children.childrens ? this.isPathChildren(props.location.pathname, children.childrens) : false) ? 'activeNav' : '')
                                                                            }>
                                                                                <ListItemIconCustom>
                                                                                    <IcomoonReact iconSet={iconSet} size={26} icon={children.icone} />
                                                                                </ListItemIconCustom>
                                                                                <ListItemTextCustom primary={t(children.name, children.nameArgs)} />
                                                                            </ListItemCustom>
                                                                        </BootstrapTooltip>
                                                                    )
                                                                }

                                                            } else {
                                                                return null
                                                            }
                                                        })
                                                    }
                                                </ListCustom>
                                            </AccordionDetailsCustom>
                                        </AccordionCustom>
                                        // Version
                                    )
                                } else {
                                    return (
                                        <AccordionCustom
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                            onClick={() => { this.goTo(item.route) }}
                                        >
                                            <AccordionSummaryCustom
                                                className={item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{t(item.name, item.nameArgs)}</TypographyCustom>
                                            </AccordionSummaryCustom>
                                        </AccordionCustom>
                                    )
                                }
                            } else {
                                return null
                            }
                        })
                        : null}
                </SwipeableDrawerCustom>
            )
        }
        else {
            return (
                <DrawerCustom
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: props.opened,
                        [classes.drawerClose]: !props.opened,
                    })}
                    id={!props.opened ? 'closed' : ''}
                    variant={'permanent'}
                    drawerwidth={props.drawerWidth}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: props.opened,
                            [classes.drawerClose]: !props.opened,
                        }),
                    }}
                    style={{ width: props.drawerWidth }}
                >
                    <Grid container alignItems='center' justifyContent="center" style={{ zIndex: 1, minHeight: "none", padding: "20px 0px 40px 10px" }}>
                        {
                            isCRM ?
                                null
                                :
                                <EncartTopLeft className={props.opened ? 'open' : 'close'} imagefound={(this.getGuidelineImg() !== null && isCRM === false) ? "true" : "false"} drawerwidth={props.opened ? props.drawerWidth : drawerWidth} onClick={() => this.goTo(ROUTE_HOME)} style={{ cursor: 'pointer' }}>
                                    {
                                        this.getGuidelineImg() !== null && isCRM === false ? (
                                            <img src={this.getGuidelineImg()} alt={this.getGuidelineName()} />
                                        ) : this.getGuidelineName() !== null && isCRM === false ? (
                                            <Typography variant="h2">{this.getGuidelineName()}</Typography>
                                        ) : null
                                    }
                                </EncartTopLeft>
                        }
                    </Grid>
                    {menuItems ?
                        // 1 Level (Ex: Centralisation)
                        menuItems.filter(i => i.route !== '/settings').map((item, index) => {
                            if (!item.childrens.every(_.isNull)) {
                                if (!item.notAccordion) {
                                    return (
                                        <AccordionCustom
                                            expanded={expanded === item.route}
                                            onChange={this.handleChange(item.route)}
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                        >
                                            <AccordionSummaryCustom
                                                expandIcon={expanded === item.route ? <RemoveIcon /> : <AddIcon />}
                                                className={item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2" style={{fontSize: 15, minHeight: 40, display: 'flex', alignItems: 'center'}}>{t(item.name, item.nameArgs)}</TypographyCustom>
                                            </AccordionSummaryCustom>
                                            <AccordionDetailsCustom>
                                                <ListCustom>
                                                    {
                                                        // 2 Level (Ex: Configuration)
                                                        item.childrens.map((children, index) => {
                                                            if (children !== null) {
                                                                if (children.hasSubmenu || this.state.detailActive || children.staticItem) {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <BootstrapTooltip
                                                                                title={t(children.name, children.nameArgs)}
                                                                                placement="right"
                                                                                disableHoverListener={props.opened ? true : false}
                                                                                className={clsx(classes.tooltip)}
                                                                                key={`ChildItem${index}`}
                                                                            >
                                                                                <ListItemCustom button onClick={() => {
                                                                                    this.goTo(children.route, children.customState);
                                                                                    this.setState({
                                                                                        subMenuItems: children.childrensStructure,
                                                                                        subChildActive: children
                                                                                    })
                                                                                }}
                                                                                    className={
                                                                                        clsx(classes.drawer, {
                                                                                            [classes.itemOpen]: props.opened,
                                                                                            [classes.itemClose]: !props.opened,
                                                                                        },
                                                                                            props.location.pathname === children.route || (children.childrens ? this.isPathChildren(props.location.pathname, children.childrens) : false) ? 'activeNav' : '')
                                                                                    }>
                                                                                    <ListItemIconCustom>
                                                                                        { typeof children.icone === 'string'
                                                                                            ? <IcomoonReact iconSet={iconSet} size={26} icon={children.icone} />
                                                                                            : children.icone
                                                                                        }
                                                                                    </ListItemIconCustom>
                                                                                    <ListItemTextCustom primary={t(children.name, children.nameArgs)} />
                                                                                </ListItemCustom>
                                                                            </BootstrapTooltip>

                                                                            {/* Header */}
                                                                            {
                                                                                children.staticItem && this.state.childActive?.route === children.route ?
                                                                                    <BootstrapTooltip
                                                                                        title={t(children.staticItem.title)}
                                                                                        placement="right"
                                                                                        disableHoverListener={props.opened ? true : false}
                                                                                        className={clsx(classes.tooltip)}
                                                                                    // key={`ChildItemSub${indexSub}`}
                                                                                    >
                                                                                        <ListItemSubTitleCustom className={
                                                                                            clsx(classes.drawer, {
                                                                                                [classes.itemOpen]: props.opened,
                                                                                                [classes.itemClose]: !props.opened,
                                                                                            },
                                                                                                this.state.childActive?.route === children.route ? 'activeNav' : '')
                                                                                        }>
                                                                                            {/* <ListItemIconCustom>
                                                                                            <IcomoonReact iconSet={iconSet} size={28} icon={'ico_retour_fonce'} />
                                                                                        </ListItemIconCustom> */}
                                                                                            <ListItemTextCustom primary={t(children.staticItem.title)} />
                                                                                        </ListItemSubTitleCustom>
                                                                                    </BootstrapTooltip>
                                                                                    : null
                                                                            }

                                                                            {/* level 3 and 4 - ASSETS */}
                                                                            {
                                                                                this.state.detailActive && this.state.detailActive.route === children.route && this.state.detailActive.childrensStructure ?
                                                                                    <>
                                                                                        {/* Header */}
                                                                                        <BootstrapTooltip
                                                                                            title={this.state.assetName}
                                                                                            placement="right"
                                                                                            disableHoverListener={props.opened ? true : false}
                                                                                            className={clsx(classes.tooltip)}
                                                                                        // key={`ChildItemSub${indexSub}`}
                                                                                        >
                                                                                            <ListItemSubTitleCustom className={
                                                                                                clsx(classes.drawer, {
                                                                                                    [classes.itemOpen]: props.opened,
                                                                                                    [classes.itemClose]: !props.opened,
                                                                                                },
                                                                                                    this.state.detailActive.route === children.route ? 'activeNav' : '')
                                                                                            }>
                                                                                                {/* <ListItemIconCustom>
                                                                                                    <IcomoonReact iconSet={iconSet} size={28} icon={'ico_retour_fonce'} />
                                                                                                </ListItemIconCustom> */}
                                                                                                <ListItemTextCustom primary={this.state.assetName} />
                                                                                            </ListItemSubTitleCustom>
                                                                                        </BootstrapTooltip>
                                                                                        {/* Level 3 */}
                                                                                        {
                                                                                            this.state.assetName !== '...' ?
                                                                                                this.state.detailActive.childrensStructure?.map((item, indexAsset) => {
                                                                                                    var numbAssetId = props.location.pathname.match(/\d+/)?.[0]
                                                                                                    if (item.noRoute) {
                                                                                                        return (
                                                                                                            <AccordionCustomSub
                                                                                                                expanded={expandedSub === item.route}
                                                                                                                onChange={this.handleChangeSub(item.route)}
                                                                                                                TransitionProps={{ unmountOnExit: true }}
                                                                                                                key={`MenuItem${index}`}
                                                                                                            >
                                                                                                                <AccordionSummaryCustomSub
                                                                                                                    className={expandedSub === item.route ? 'activeNav' : ''}
                                                                                                                >
                                                                                                                    <ListItemIconCustom>
                                                                                                                        {
                                                                                                                            expandedSub === item.route ? <RemoveIcon style={{ width: 28, height: 28 }} /> : <AddIcon style={{ width: 28, height: 28 }} />
                                                                                                                        }
                                                                                                                    </ListItemIconCustom>
                                                                                                                    <TypographyCustom variant="body2">{t(item.name, item.nameArgs)}</TypographyCustom>
                                                                                                                </AccordionSummaryCustomSub>
                                                                                                                <AccordionDetailsSubCustom>
                                                                                                                    <ListCustom>
                                                                                                                        {
                                                                                                                            item.childrensStructure?.map((item2, indexAsset) => {
                                                                                                                                var numbAssetId = props.location.pathname.match(/\d+/)?.[0]
                                                                                                                                return (
                                                                                                                                    <BootstrapTooltip
                                                                                                                                        title={t(item2.name, item2.nameArgs)}
                                                                                                                                        placement="right"
                                                                                                                                        disableHoverListener={props.opened ? true : false}
                                                                                                                                        className={clsx(classes.tooltip)}
                                                                                                                                        key={`ChildItemSubSubAsset${indexAsset}`}
                                                                                                                                    >
                                                                                                                                        <ListItemSubSubCustom button onClick={() => { this.goTo(item2.route?.replace(':id', numbAssetId || this.state.asset.id?.replace('/api/assets/', ''))?.replace(':typeAsset', this.state.asset.assetType.identifier), item2.customState); }} className={
                                                                                                                                            clsx(classes.drawer, {
                                                                                                                                                [classes.itemOpen]: props.opened,
                                                                                                                                                [classes.itemClose]: !props.opened,
                                                                                                                                            },
                                                                                                                                                props.location.pathname === item2.route?.replace(':id', numbAssetId || this.state.asset.id?.replace('/api/assets/', ''))?.replace(':typeAsset', this.state.asset.assetType.identifier) || (item2.childrens ? this.isPathChildren(props.location.pathname, item2.childrens) : false) ? 'activeNav' : '')
                                                                                                                                        }>
                                                                                                                                            <ListItemSubTextCustom primary={t(item2.name, item2.nameArgs)} />
                                                                                                                                        </ListItemSubSubCustom>
                                                                                                                                    </BootstrapTooltip>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </ListCustom>
                                                                                                                </AccordionDetailsSubCustom>
                                                                                                            </AccordionCustomSub>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <BootstrapTooltip
                                                                                                                title={t(item.name, item.nameArgs)}
                                                                                                                placement="right"
                                                                                                                disableHoverListener={props.opened ? true : false}
                                                                                                                className={clsx(classes.tooltip)}
                                                                                                                key={`ChildItemSubAsset${indexAsset}`}
                                                                                                            >
                                                                                                                <ListItemSubCustom button onClick={() => { this.goTo(item.route?.replace(':id', numbAssetId || this.state.asset.id?.replace('/api/assets/', ''))?.replace(':typeAsset', this.state.asset.assetType.identifier), item.customState); }} className={
                                                                                                                    clsx(classes.drawer, {
                                                                                                                        [classes.itemOpen]: props.opened,
                                                                                                                        [classes.itemClose]: !props.opened,
                                                                                                                    },
                                                                                                                        props.location.pathname === item.route?.replace(':id', numbAssetId || this.state.asset.id?.replace('/api/assets/', ''))?.replace(':typeAsset', this.state.asset.assetType.identifier) || (item.childrens ? this.isPathChildren(props.location.pathname, item.childrens) : false) ? 'activeNav' : '')
                                                                                                                }>
                                                                                                                    <ListItemIconCustom>
                                                                                                                        <IcomoonReact className='ico_force_return' iconSet={iconSet} size={26} icon={'ico_retour_fonce'} />
                                                                                                                    </ListItemIconCustom>
                                                                                                                    <ListItemTextCustom primary={t(item.name, item.nameArgs)} />
                                                                                                                </ListItemSubCustom>
                                                                                                            </BootstrapTooltip>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                                : null
                                                                                        }
                                                                                    </>
                                                                                    : null
                                                                            }
                                                                            {/* Level 3 */}
                                                                            {/* // TODO: It is here the condition why the menu disappears */}
                                                                            {
                                                                                this.state.childActive
                                                                                    && this.state.childActive.hasSubmenu
                                                                                    && (this.state.childActive.parent === children.route || this.state.childActive.route === children.route)
                                                                                    ? this.state.childActive.childrensStructure.map((childActive, indexSub) => {
                                                                                        if (childActive.childrensStructure && childActive.childrensStructure.length > 0) {
                                                                                            return (
                                                                                                <AccordionCustomSub
                                                                                                    expanded={expandedSub === childActive.route}
                                                                                                    // expanded={expandedSub === childActive.route}
                                                                                                    onChange={this.handleChangeSub(childActive.route)}
                                                                                                    TransitionProps={{ unmountOnExit: true }}
                                                                                                    key={`MenuItem${index}`}
                                                                                                >
                                                                                                    <AccordionSummaryCustomSub
                                                                                                        className={expandedSub === childActive.route ? 'activeNav' : ''}
                                                                                                    >
                                                                                                        <ListItemIconCustom>
                                                                                                            {
                                                                                                                expandedSub === childActive.route ? <RemoveIcon style={{ width: 28, height: 28 }} /> : <AddIcon style={{ width: 28, height: 28 }} />
                                                                                                            }
                                                                                                        </ListItemIconCustom>
                                                                                                        <TypographyCustom variant="body2">{t(childActive.name, childActive.nameArgs)}</TypographyCustom>
                                                                                                    </AccordionSummaryCustomSub>
                                                                                                    <AccordionDetailsSubCustom>
                                                                                                        {/* Level 4 */}
                                                                                                        <ListCustom>
                                                                                                            {
                                                                                                                childActive.childrensStructure?.map((subChild, indexAsset) => {
                                                                                                                    return (
                                                                                                                        <BootstrapTooltip
                                                                                                                            title={t(subChild.name, subChild.nameArgs)}
                                                                                                                            placement="right"
                                                                                                                            disableHoverListener={props.opened ? true : false}
                                                                                                                            className={clsx(classes.tooltip)}
                                                                                                                            key={`ChildItemSubSubAsset${indexAsset}`}
                                                                                                                        >
                                                                                                                            <ListItemSubSubCustom button onClick={() => {
                                                                                                                                this.goTo(subChild.route)
                                                                                                                            }} className={
                                                                                                                                clsx(classes.drawer, {
                                                                                                                                    [classes.itemOpen]: props.opened,
                                                                                                                                    [classes.itemClose]: !props.opened,
                                                                                                                                }),
                                                                                                                                props.location.pathname === subChild.route ? 'activeNav' : ''
                                                                                                                            }>
                                                                                                                                <ListItemSubTextCustom primary={t(subChild.name, subChild.nameArgs)} />
                                                                                                                            </ListItemSubSubCustom>
                                                                                                                        </BootstrapTooltip>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </ListCustom>
                                                                                                    </AccordionDetailsSubCustom>
                                                                                                </AccordionCustomSub>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <BootstrapTooltip
                                                                                                    title={t(childActive.name, childActive.nameArgs)}
                                                                                                    placement="right"
                                                                                                    disableHoverListener={props.opened ? true : false}
                                                                                                    className={clsx(classes.tooltip)}
                                                                                                    key={`ChildItemSub${indexSub}`}
                                                                                                >
                                                                                                    <ListItemSubCustom button onClick={() => { this.goTo(childActive.route, childActive.customState); }} className={
                                                                                                        clsx(classes.drawer, {
                                                                                                            [classes.itemOpen]: props.opened,
                                                                                                            [classes.itemClose]: !props.opened,
                                                                                                        },
                                                                                                            props.location.pathname === childActive.route || (childActive.childrens ? this.isPathChildren(props.location.pathname, childActive.childrens) : false) ? 'activeNav' : '')
                                                                                                    }>
                                                                                                        <ListItemIconCustom>
                                                                                                            <IcomoonReact iconSet={iconSet} size={26} icon={'ico_retour_fonce'} />
                                                                                                        </ListItemIconCustom>
                                                                                                        <ListItemTextCustom primary={t(childActive.name, childActive.nameArgs)} />
                                                                                                    </ListItemSubCustom>
                                                                                                </BootstrapTooltip>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                    : null
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <BootstrapTooltip
                                                                            title={t(children.name, children.nameArgs)}
                                                                            placement="right"
                                                                            disableHoverListener={props.opened ? true : false}
                                                                            className={clsx(classes.tooltip)}
                                                                            key={`ChildItem${index}`}
                                                                        >
                                                                            <ListItemCustom button onClick={() => { this.goTo(children.route, children.customState); this.setState({ subMenuItems: null }) }} className={
                                                                                clsx(classes.drawer, {
                                                                                    [classes.itemOpen]: props.opened,
                                                                                    [classes.itemClose]: !props.opened,
                                                                                },
                                                                                    props.location.pathname === children.route || (children.childrens ? this.isPathChildren(props.location.pathname, children.childrens) : false) ? 'activeNav' : '')
                                                                            }>
                                                                                <ListItemIconCustom>
                                                                                    { typeof children.icone === 'string'
                                                                                        ? <IcomoonReact iconSet={iconSet} size={26} icon={children.icone} />
                                                                                        : children.icone
                                                                                    }
                                                                                </ListItemIconCustom>
                                                                                <ListItemTextCustom primary={t(children.name, children.nameArgs)} />
                                                                            </ListItemCustom>
                                                                        </BootstrapTooltip>
                                                                    )
                                                                }
                                                            } else {
                                                                return null
                                                            }
                                                        })
                                                    }
                                                </ListCustom>
                                            </AccordionDetailsCustom>
                                        </AccordionCustom>
                                    )
                                } else {
                                    return (
                                        <AccordionCustom
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                            onClick={() => { this.goTo(item.route) }}
                                        >
                                            <AccordionSummaryCustom
                                                className={item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{t(item.name, item.nameArgs)}</TypographyCustom>
                                            </AccordionSummaryCustom>
                                        </AccordionCustom>
                                    )
                                }
                            } else {
                                return null
                            }
                        })
                        : null}
                    {/* <div style={{
                        height:"100%",
                        display:"flex",
                        alignItems:"flex-end"
                    }}>
                        <div style={{
                            display:"flex",
                            justifyContent: "space-between",
                            alignItems:"center",
                            borderTop:`0.5px solid ${colors.grey.border}`,
                            width:"100%",
                            padding:"15px 10px",
                            cursor:"pointer",
                            gap:"20px",
                            "&:hover":{
                                backgroundColor: colors.grey.border
                            }
                        }}>
                            <OurTypography fontWeight={'bold'} sx={{display:"flex",alignItelms:"center",gap:"5px"}}><SettingsIcon fontSize="small"/>Paramètres</OurTypography> 
                            <OurTypography fontSize={14} color={colors.grey.lighter.hue800}>Sinfin™ v1.9.0</OurTypography> 
                        </div>
                    </div> */}

                    {
                        settingsItem ?
                            <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                                <div style={{ position: "relative" }}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: "calc(100% + 0.5px)",
                                            left: 0,
                                            width: "100%",
                                            overflow: "hidden",
                                            transition: "all 0.3s ease-in-out",
                                            transform: `translateY(${this.state.showMenu ? 0 : "calc(100% + 0.5px)"})`,
                                            height: 'auto',
                                            backgroundColor: "#fff",
                                            zIndex: 1,
                                            maxHeight: this.state.showMenu ? "200px" : 0,
                                        }}>
                                        {
                                            settingsItem?.childrens?.map((children, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <BootstrapTooltip
                                                            title={t(children?.name, children?.nameArgs)}
                                                            placement="right"
                                                            disableHoverListener={props.opened ? true : false}
                                                            className={clsx(classes.tooltip)}
                                                            key={`ChildItem${index}`}
                                                        >
                                                            <ListItemCustom button onClick={() => { this.goTo(children?.route, children?.customState); this.setState({ subMenuItems: children?.childrensStructure }); this.handleOpenSettings() }} className={
                                                                clsx(classes.drawer, {
                                                                    [classes.itemOpen]: props.opened,
                                                                    [classes.itemClose]: !props.opened,
                                                                },
                                                                    props.location.pathname === children?.route || (children?.childrens ? this.isPathChildren(props.location.pathname, children?.childrens) : false) ? 'activeNav' : '')
                                                            }>
                                                                <ListItemIconCustom>
                                                                    <IcomoonReact iconSet={iconSet} size={26} icon={children?.icone} />
                                                                </ListItemIconCustom>
                                                                <ListItemTextCustom primary={t(children?.name, children?.nameArgs)} />
                                                            </ListItemCustom>
                                                        </BootstrapTooltip>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                    <div style={{
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "flex-end",
                                        zIndex: 2,
                                        position: 'relative',
                                        backgroundColor: '#fff'
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            borderTop: `0.5px solid ${colors.grey.border}`,
                                            width: "100%",
                                            padding: "15px 10px",
                                            cursor: "pointer",
                                            gap: "20px",
                                            "&:hover": {
                                                backgroundColor: colors.grey.border
                                            }
                                        }}
                                            onClick={this.handleOpenSettings}
                                        >
                                            <OurTypography fontWeight={'bold'} sx={{ display: "flex", alignItelms: "center", gap: "5px" }}><SettingsIcon fontSize="small" />Paramètres</OurTypography>
                                            <OurTypography fontSize={14} color={colors.grey.lighter.hue800}>Sinfin™ v1.9.0</OurTypography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </DrawerCustom>
            );
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNavigation: (navigation) => dispatch({ type: SET_NAVIGATION, payload: { navigation } }),
    }
}

const mapStateToProps = state => {
    return {
        navigation: state.navigation,
        attributes: state.attributes,
        guideline: state.guideline,
        locales: state.locales,
        theme: state.theme
    };
};

export default withTranslation()(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DrawerLeft))));