import { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import { withTranslation } from 'react-i18next';
import { withApollo } from "@apollo/client/react/hoc";
import { Box } from '@mui/material';
import { GET_TOTAL_COUNT_MEDIAS } from '../../../../../queries/total';

const Div = styled.div`
    display: flex;
    align-items: center;
    p {
        margin: 0;
        margin-right: 16px;
        color: ${colors.black.regular};
    }
    & > div {
        margin-right: 10px;
        &:last-of-type {
            margin-right: 0px;
        }
    }
`;

function ChipMedias(props) {
    const categories = props.categories;
    const [dataCount, setDataCount] = useState({});
    const [dataCountlLoaded, setDataCountLoaded] = useState(false);
    const [isDataCountValid, setDataCountValid] = useState(true);
    const [value, setValue] = useState(props.defaultTab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
      let obj = {};
      setDataCountLoaded(false);

      const promises = categories.map((cat) => {
        return props.client
          .query({
            query: GET_TOTAL_COUNT_MEDIAS,
            variables: {
              type_list: cat.type,
              categories: props.currentCategory,
            },
          })
          .then(({ data }) => {
            obj[cat.libelle] = data.mediaObjects.totalCount;
          })
          .catch((error) => {
            console.log("Impossible de récupérer le nombre de media", error);
          });
      });

      Promise.all(promises)
        .then(() => {
            if (props.dataCount !== obj["Tous les fichiers"]) {
                setDataCount({ ...obj, "Tous les fichiers": props.dataCount });
                setDataCountValid(false);
            } else {
                setDataCount(obj);
                setDataCountValid(true);
            }              
          setDataCountLoaded(true);
        })
        .catch((error) => {
          console.error("Erreur lors de la requête :", error);
        });
    }, [props.dataCount]);

    console.log(props.dataCount);
      
    return (
        <Box>
            <Div>
                {dataCountlLoaded &&
                    categories.map((categorie, index) =>
                    categorie && (
                        <Chip
                            style={{
                                fontSize: '14px',
                                height: '29px',
                                backgroundColor:
                                    value === categorie.type ? colors.blue.darker.hue300 : colors.grey.lighter.hue980,
                                border:
                                    value !== categorie.type && `1px solid ${colors.grey.border}`,
                                fontWeight:
                                    value !== categorie.type && `100`,
                                alignItems: 'right'
                            }}
                            key={`ChipMedia${index}`}
                            color="primary"
                            variant={value === categorie.type ? 'default' : 'outlined'}
                            label={
                                isDataCountValid
                                    ? `${categorie.libelle} (${dataCount[categorie.libelle] || 0})`
                                    : categorie.libelle === "Tous les fichiers"
                                    ? `${categorie.libelle} (${props.dataCount})`
                                    : categorie.libelle
                                }
                            onClick={(event) => {
                                handleChange(event, categorie.type);
                                props.handleChangeTab(categorie.type);
                            }}
                        />
                    )
                )}
            </Div>
        </Box>
    );
}

export default withTranslation()(withApollo(ChipMedias));
