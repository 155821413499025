import { Grid } from '@mui/material';
import CardCustom from '../../../../layouts/Card/CardCustom';
import TypographyCustom from '../../../typography/Typography';
import * as moment from "moment";
import AddCircleIcon from '@mui/icons-material/AddCircle'

const VariantAdd = ({ allProps: props, seeError, errorMessage, setSeeError, checkError }) => {
    return (
        <Grid item xs={12}>
            {
                props.value?.values?.length > 0
                    ? props.value.values.map((attr, index) => {
                        let variantIndex = index + 1
                        let imagePath = process.env.REACT_APP_API_ROOT + '/medias/' + attr.image.value?.filePath
                        return (
                            <Grid container direction="column" style={{ marginBottom: 32 }} key={`VariantProductElement${index}`}>
                                <Grid container>
                                    Variant {variantIndex}
                                </Grid>
                                <Grid container style={{ marginTop: 10 }}>
                                    <CardCustom style={{ width: '100%' }}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <img src={attr.image?.value?.filePath ? imagePath : props.allState?.imagesSelected[0]?.original} width={'100%'} />
                                            </Grid>
                                            <Grid item xs={3} style={{ marginLeft: '12px' }}>
                                                <TypographyCustom variant="body2" style={{ fontWeight: 'bold' }} colortext="#333333">Attributs</TypographyCustom>
                                                {attr.allAttr.map((attribut, index) => {

                                                    if (attribut.type === "select") {
                                                        let getValueAttr = attribut.valuesForSelect.find(e => e.node.id === attribut.values)
                                                        let translatedAttr = getValueAttr.node?.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                        return (
                                                            <TypographyCustom key={`AttributVariant${index}`} variant="body2" style={{ marginLeft: 10, marginTop: 6 }}>{translatedAttr ? translatedAttr.node.value : getValueAttr.node?.translation.translationDatas.edges[0].node.value}</TypographyCustom>
                                                        )
                                                    } else {
                                                        return (
                                                            <TypographyCustom key={`AttributVariant${index}`} variant="body2" style={{ marginLeft: 10, marginTop: 6 }}>{attribut.type === "date" ? moment(attribut.values).format('DD/MM/YYYY') : attribut.values}</TypographyCustom>
                                                        )
                                                    }
                                                })}
                                            </Grid>
                                            {
                                                props.input.hasPrice ?
                                                    <Grid item xs={3}>
                                                        <TypographyCustom variant="body2" style={{ fontWeight: 'bold' }} colortext="#333333">Prix</TypographyCustom>
                                                        <TypographyCustom variant="body2" style={{ marginLeft: 10, marginTop: 6 }}>{attr.price ? attr.price.value : null} €</TypographyCustom>
                                                    </Grid>
                                                    : null
                                            }
                                        </Grid>
                                    </CardCustom>
                                </Grid>
                            </Grid>
                        )
                    }) : <TypographyCustom variant="body2">Vous n’avez pas encore de variante produit</TypographyCustom>
            }
            <div style={{ marginTop: 16, color: '#59C870', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => { setSeeError(true); checkError(props.value); props.input.handleClick('addVariant') }} >
                <AddCircleIcon fontSize="large" style={{ marginRight: 10 }} />
                <span style={{ color: '#2B4553', fontSize: '16px', fontWeight: 'bold' }}>{props.value?.values?.length > 0 ? 'Ajouter/Modifier une variante produit' : 'Ajouter une variante produit'}</span>
            </div>
            {!seeError ? null : <span className="error-label">{errorMessage}</span>}
        </Grid>
    )
}

export default VariantAdd;