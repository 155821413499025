import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from '../../../../shared/components/CustomButton';
import H1 from '../../../../shared/components/CustomH1';
import Pg from '../../../../shared/components/CustomParagraph';

const LoginGame = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);

    let { 
        preview = false,
        id = null, 
        data,
        inputCallback = null,
    } = props;

    let image = data.inputs['backgroundImage'].value;
    let image2 = data.blocks.middleBox.inputs['backgroundImage'].value;

    let border;
    if (data.blocks.middleBox.inputs.border) {
        border = data.blocks.middleBox.inputs.border;
    }

    return (
        <div className="game_index">
            <section 
                class="game"
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: "cover!important",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative'
                }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                { !preview && props.toolbarSection }
                <div className="popup play game">
                    {
                        typeof data.blocks.logo.inputs.value?.value === 'string' || data.blocks.logo.inputs.value?.value?.filePath ? 
                        (
                            <img className="logo"  
                                onClick={() => inputCallback(id, 'logo')} 
                                src={
                                    typeof data.blocks.logo.inputs.value?.value === 'string' ? 
                                        data.blocks.logo.inputs.value.value : 
                                        data.blocks.logo.inputs.value?.value?.filePath ? 
                                            ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` 
                                            : "http://via.placeholder.com/300x115/CCCCCC/AAAAAA?text=Image"
                                }
                            />
                        ) : null
                    }
                    <div 
                        className="popup_content" 
                        onMouseEnter={() => {setHoverBlock(true); setHover(false);}} 
                        onMouseLeave={() => {setHoverBlock(false); setHover(true)}}
                        style={{
                            backgroundColor: data.blocks.middleBox.inputs['backgroundColor'].value,
                            backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                            borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                            borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                        }}
                    >
                        { !preview && hoverBlock && props.toolbarBlock }
                        <div>
                            <H1 data={data.blocks.middleBox} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                            <Pg data={data.blocks.middleBox} preview={preview} component={id} block={'desc'} inputCallback={inputCallback} />
                            <Button data={data.blocks.middleBox} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="button goToGame" nopadding={true} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

LoginGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default LoginGame;
