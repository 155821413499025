import moment from "moment";
import { DatePickerCustom, DatePickerFieldCustom } from "./styles/styled";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box } from "@mui/material";
import { LinkText } from "../../styles/inputStyled";
import OurLock from "../../../OurLock/OurLock";

const OurDatePicker = ({ allProps: props, seeError, updateCallback, errorMessage, lock }) => {

  return (
    <>
      <DatePickerFieldCustom item xs={props.input.xs ?? 12}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePickerCustom
            disableToolbar
            autoOk
            invalidDateMessage={'La date est invalide'}
            variant="inline"
            format="DD/MM/YYYY"
            value={moment(props.allState[props.input.stateName])}
            minDate={props.input.minDate}
            onChange={updateCallback}
            onKeyDown={(e) => e.preventDefault()}
            slotProps={{
              textField: {
                helperText: props.input.helper.link ? <Box><LinkText variant="h5">{props.input.helper.text}</LinkText></Box> : props.input.helper.text,
              },
            }}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </LocalizationProvider>
        <OurLock lock={lock} style={{
            position: 'absolute',
            top: '30px',
            right: 0,
        }} />
      </DatePickerFieldCustom>

      {
        !seeError ? null :
          <span className="error-label">{errorMessage}</span>
      }
    </>
  )
}

export default OurDatePicker;