import React, { useState } from "react";

import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";

import { MEDIAPICKER_TYPES } from "@constants/mediapickerTypes.enum.js";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import { ADD_CATALOG, UPDATE_CATALOG } from "@queries/catalogs.js";
import {
  CREATE_CATALOG_FILTER,
  UPDATE_CATALOG_FILTER,
  DELETE_CATALOG_FILTER,
} from "@queries/catalog_filter.js";
import { ALERT_ERROR, ALERT_SUCCESS } from "@constants/alert-types";

import { generateTempoId } from "@utils/string.utils";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";

import moment from "moment";
import styled from "styled-components";
import colors from "@config/theme/colors";

import InputBuilder from "@ui/form/InputBuilder";
import TopPanel from "@layouts/TopPanel/TopPanel";
import OurStepper from "@ui/stepper/OurStepper";
import StepPanel from "@ui/stepper/components/StepPanel";
import TextInput from "@ui/form/inputs/TextInput";
import TextareaInput from "@ui/form/inputs/TextareaInput";
import TextAddElement from "@ui/typography/TextAddElement";
import FinalStep from "@ui/stepper/components/FinalStep.jsx";
import RowFilter from "./components/RowFilter";
import RadioGroupInput from "@ui/form/inputs/RadioGroupInput";

const FormContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${colors.background.white.primary};
  width: 100%;
  min-height: calc(100vh - 275px);
`;

const stepPanelStyle = {
  height: "calc(100vh - 275px)",
  overflowY: "auto",
  borderBottom: `solid 1px ${colors.grey.lighter.hue800}`,
  borderLeft: `solid 1px ${colors.grey.lighter.hue800}`,
};

const CatalogForm = ({ catalog = null, onSuccess, onFail, ...props }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState({
    image: catalog ? catalog.media : null,
    name: catalog ? catalog.libelle : "",
    identifier: catalog ? catalog.identifier : "",
    description: catalog ? catalog.description : "",
    active: catalog ? catalog.status : false,
    autoSync: catalog ? catalog.autosync : false,
    filters: catalog
      ? catalog.catalogFilters.edges.map((filter) => ({
          id: filter.node.id,
          attribute: filter.node.attribute.id,
          operator: filter.node.operator,
          value: filter.node.value,
        }))
      : [],
  });
  const [identifierSwitch, setIdentifierSwitch] = useState(true);

  const stepOneCompleted = () => {
    return form.name && form.identifier;
  };

  const handleDeleteFilter = async (id) => {
    const filter = form.filters.find(
      (filter) => filter.id === id || filter.tempoId === id
    );
    const refreshFilters = () => {
      const newFilters = form.filters.filter(
        (filter) => filter.id !== id && filter.tempoId !== id
      );
      setForm((prevState) => ({
        ...prevState,
        filters: newFilters,
      }));
    };

    if (filter.id) {
      props.startLoading();
      props.client
        .mutate({
          mutation: DELETE_CATALOG_FILTER,
          variables: {
            id: filter.id,
          },
        })
        .then((res) => {
          refreshFilters();
        })
        .catch((err) => {
          props.snack(ALERT_ERROR, "Une erreur est survenue lors de la suppression du filtre");
        })
        .finally(() => {
          props.stopLoading();
        });
    } else {
      refreshFilters();
    }
  };

  const handleSubmit = async () => {
    props.startLoading();

    try {
      const variables = {
        id: catalog ? catalog.id : null,
        type: "/api/catalog-types/1",
        identifier: form.identifier,
        libelle: form.name,
        description: form.description,
        status: form.active,
        media: form.image ? form.image.id : null,
        autosync: form.autoSync,
        startAt: moment().format("YYYY-MM-DD"),
        endAt: moment().format("YYYY-MM-DD"),
      };
      const res = await props.client.mutate({
        mutation: catalog ? UPDATE_CATALOG : ADD_CATALOG,
        variables,
      });

      const filtersFormatted = formatFilters();

      await Promise.all(
        filtersFormatted.map(async (filter) => {
          const variables = {
            id: filter.id ?? null,
            operator: filter.operator,
            createdAt: filter.id ? null : moment().format("YYYY-MM-DD"),
            catalog: catalog ? catalog.id : res.data.createCatalog.catalog.id,
            attribute: filter.attribute,
            attributeOption: filter.attributeOption,
            value: filter.value,
          };
          await props.client.mutate({
            mutation: filter.id ? UPDATE_CATALOG_FILTER : CREATE_CATALOG_FILTER,
            variables,
          });
        })
      );

      if (onSuccess) onSuccess();
    } catch (err) {
      props.snack(ALERT_ERROR, "Une erreur est survenue lors de la soumission du formulaire");
      if (onFail) onFail();
    } finally {
      props.stopLoading();
      props.snack(
        ALERT_SUCCESS,
        catalog ? "Catalogue modifié" : "Catalogue ajouté"
      );
    }
  };

  const formatFilters = () => {
    let filters = [...form.filters];

    for (let filter of filters) {
      let value = null;
      let attributeOption = null;

      if (filter.attributeType?.node?.attributeType?.input === "select") {
        if (filter.operator === "IN" || filter.operator === "NOT IN") {
          const arrayRegex = filter.values.map((e) =>
            e.replace("/api/attribute-options/", "")
          );
          value = JSON.stringify(arrayRegex);
        } else {
          attributeOption = filter.values;
        }
      } else {
        if (filter.operator === "IN" || filter.operator === "NOT IN") {
          value = JSON.stringify(filter.values);
        } else {
          value = filter.values;
        }
      }
    }

    return filters;
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "maxContent",
        overflowY: "hidden",
      }}
    >
      <TopPanel
        inForm={true}
        windowWidth={props.windowWidth}
        title={catalog ? "Modifier un catalogue" : "Ajouter un catalogue"}
        subtitle={
          catalog
            ? "Veuillez compléter les champs ci-dessous pour modifier votre catalogue"
            : "Veuillez compléter les champs ci-dessous pour créer votre catalogue"
        }
      />

      <OurStepper
        steps={[
          {
            label: "Principal",
            required: true,
            completed: stepOneCompleted(),
            show: true,
          },
          {
            label: "Filtres",
            required: true,
            completed: true,
            show: true,
          },
          {
            label: "Finalisation",
            required: true,
            completed: false,
          },
        ]}
        activeStep={activeStep}
        helper="* Champs obligatoires"
        onChange={(e) => setActiveStep(e)}
      >
        <StepPanel value={activeStep} index={0} style={stepPanelStyle}>
          <FormContainer>
            <InputBuilder
              input={{
                type: "mediaPicker",
                label: "Entête de catalogue",
                usedAlone: true,
                translated: false,
                fileType: MEDIAPICKER_TYPES.IMAGE,
                helper: {
                  text: "",
                  link: false,
                },
                stateName: "image",
                value: form.image,
                handleMediaPicker: (media) =>
                  setForm((prevState) => ({
                    ...prevState,
                    image: media,
                  })),
              }}
              value={form.image}
            />
            <TextInput
              label="Nom"
              value={form.name}
              required={true}
              helper={<span>Indiquez le nom du catalogue</span>}
              sx={{ width: "100%" }}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                  identifier: identifierSwitch
                    ? e.target.value
                    : form.identifier,
                }))
              }
            />
            <Box sx={{ width: "100%" }}>
              <RadioGroupInput
                label="Utiliser le nom du catalogue comme identifiant"
                radioGroupProps={{
                  row: true,
                }}
                options={[
                  {
                    value: true,
                    label: "Oui",
                  },
                  {
                    value: false,
                    label: "Non",
                  },
                ]}
                value={identifierSwitch}
                onChange={(e) => setIdentifierSwitch((prevState) => !prevState)}
              />
            </Box>
            <TextInput
              label="Identifiant"
              value={form.identifier}
              required={true}
              helper={<span>Indiquez l'identifiant du catalogue</span>}
              sx={{ width: "100%" }}
              disabled={identifierSwitch}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  identifier: e.target.value,
                }))
              }
            />
            <TextareaInput
              label="Description"
              value={form.description}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
              helper={<span>Indiquez la description du catalogue</span>}
              sx={{ width: "100%" }}
            />

            <RadioGroupInput
              radioGroupProps={{
                row: true,
              }}
              options={[
                {
                  value: true,
                  label: "Oui",
                },
                {
                  value: false,
                  label: "Non",
                },
              ]}
              label="Actif"
              value={form.active}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  active: !prevState.active,
                }))
              }
            />
            <RadioGroupInput
              radioGroupProps={{
                row: true,
              }}
              options={[
                {
                  value: true,
                  label: "Oui",
                },
                {
                  value: false,
                  label: "Non",
                },
              ]}
              value={form.autoSync}
              label="Synchronisation auto."
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  autoSync: !prevState.autoSync,
                }))
              }
            />
          </FormContainer>
        </StepPanel>
        <StepPanel value={activeStep} index={1} style={stepPanelStyle}>
          <FormContainer>
            {form.filters.length > 0 ? (
              form.filters.map((filter, index) => (
                <RowFilter
                  key={index}
                  row={filter}
                  onChange={(row) => {
                    const newFilters = form.filters.map((filter) => {
                      const filterId = filter.id ?? filter.tempoId;
                      if (filterId === row.id || filterId === row.tempoId) {
                        return row;
                      }
                      return filter;
                    });
                    setForm((prevState) => ({
                      ...prevState,
                      filters: newFilters,
                    }));
                  }}
                  onDelete={(id) => handleDeleteFilter(id)}
                />
              ))
            ) : (
              <Box sx={{ width: "100%" }}>
                <Typography
                  variant="body1"
                  sx={{ color: colors.grey.lighter.hue600 }}
                >
                  Aucun filtre
                </Typography>
              </Box>
            )}

            <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
              <TextAddElement
                label="Ajouter un filtre"
                onClick={async () => {
                  const tempoId = await generateTempoId();
                  setForm((prevState) => ({
                    ...prevState,
                    filters: [
                      ...prevState.filters,
                      {
                        tempoId: tempoId,
                        attribute: "category",
                        operator: "",
                        value: "",
                      },
                    ],
                  }));
                }}
              />
            </Box>
          </FormContainer>
        </StepPanel>
        <StepPanel value={activeStep} index={2}>
          <FinalStep
            text="Votre catalogue est prêt à être enregistrer ! :)"
            action={{
              text: "Enregistrer le catalogue",
              onClick: () => handleSubmit(),
            }}
          />
        </StepPanel>
      </OurStepper>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(CatalogForm)
);
