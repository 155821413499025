import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Grid, Box, CircularProgress } from '@mui/material';

import Typography from '../../../ui/typography/Typography';
import { ROUTE_CATALOGS_LIST } from '../../../../js/constants/route-names';
import { withApollo } from '@apollo/client/react/hoc';
import { GET_CATALOG_TYPES } from '../../../../queries/catalogs';
import styled from 'styled-components';
import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../assets/selection.json";
import SliderDashboard from "../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import { v4 as uuidv4 } from 'uuid';
import { SNACK } from '../../../../js/constants/action-types';
import { withTranslation } from 'react-i18next';
import { checkRouting } from '../../../../js/utils/checkRouting';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

const WrapperStats = styled(Grid)`
    width: 100%; 
    min-height: 105px;
    @media screen and (max-width: 1450px){
        margin: 16px 0; 
    }
`;

const Title = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 16px;
    line-height: 23px;
    font-weight: bold;
    width: 100%;
`;
const TitleBis = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    width: 100%;
`;
const SubTitle = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    font-size: 35px;
    line-height: 46px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 30px;
        line-height: 41px;
    }
    @media screen and (max-width: 1450px){
        font-size: 25px;
        line-height: 36px;
    }
`;
const SubTitleBis = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    font-size: 35px;
    line-height: 39px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 28px;
        line-height: 30px;
    }
    @media screen and (max-width: 1450px){
        font-size: 18px;
        line-height: 19px;
    }
`;
const MoreInfos = styled(Typography)`
    margin-top: 4px;
    color: ${colors.black.regular};
    font-size: 20px;
    line-height: 23px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 16px;
        line-height: 19px;
    }
`;
const MoreInfosBis = styled(Typography)`
    margin-top: 4px;
    color: ${colors.black.regular};
    font-size: 12px;
    line-height: 14px;
    width: 100%;
`;
const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor};
    svg circle{
        stroke: ${props => props.statuscolor};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor};
    }
`;

class DashboardCatalogs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            catalogs: [],
            loadingDashboard: true
        };
    }

    componentDidMount() {
        checkRouting(this.props)
        this.initDashboard();
    }

    initDashboard = () => {
        this.props.client.query({
            query: GET_CATALOG_TYPES,
            fetchPolicy: 'no-cache',
        }).then(result => {
            request(`${process.env.REACT_APP_API}/dashboards/stats/catalog`, 'get', false)
            .then((data) => {
                if (data.success) {
                    this.setState({
                        catalogs: data.datas
                    }, () => {
                        let i = 0;
                        let copyCatalogs = [...this.state.catalogs];
                        for (let catalog of copyCatalogs.activeCatalogs) {
                            for (let catalogType of result.data.catalogTypes.edges) {
                                if (catalogType.node.id === catalog.type) {
                                    copyCatalogs.activeCatalogs[i].type = { id: catalogType.node.id, identifier: catalogType.node.identifier };
                                }
                            }
                            i++;
                        }
                        this.setState({ 
                            copyCatalogs,
                            loadingDashboard: false
                        });
                    });
                }
            }).catch((err)=>{
                console.log(err)
            });
        }).catch((err)=>{
            console.log(err)
        });
    }

    render() {
        let sliderData = [
            {
                title: this.props.t("catalogs.dashboard.whyCreateCatalog"),
                text: this.props.t("catalogs.dashboard.catalogSubtitle"),
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t("catalogs.dashboard.createCatalog"),
                        link: `${ROUTE_CATALOGS_LIST}?formOpen=true`
                    },
                ]
            }
        ];
        sliderData = sliderData.filter(function (el) {
            return el !== null;
        });

        let stats = [
            {
                'icon': 'ico_bookmarks',
                'data': this.state.catalogs.nbPermanentCatalogs,
                'dataColor': colors.black.regular,
                'subtitle': this.state.catalogs.nbPermanentCatalogs > 1 ? this.props.t("catalogs.dashboard.permanentCatalogs") : this.props.t("catalogs.dashboard.permanentCatalog"),
            },
            {
                'icon': 'ico_alarm',
                'data': this.state.catalogs.nbEphemeralCatalogs,
                'dataColor': colors.black.regular,
                'subtitle': this.state.catalogs.nbEphemeralCatalogs > 1 ? this.props.t("catalogs.dashboard.ephemeralCatalogs") : this.props.t("catalogs.dashboard.ephemeralCatalog"),
            },
            {
                'icon': 'ico_megaphone',
                'data': this.state.catalogs.nbThematicCatalogs,
                'dataColor': colors.black.regular,
                'subtitle': this.state.catalogs.nbThematicCatalogs > 1 ? this.props.t("catalogs.dashboard.thematicCatalogs") : this.props.t("catalogs.dashboard.thematicCatalog"),
            },
        ];

        let lastDatas = [
            {
                'subtitle': this.props.t("catalogs.dashboard.activeCatalog"),
                'data': Math.round(this.state.catalogs.nbCatalogEnable / (this.state.catalogs.nbCatalogEnable + this.state.catalogs.nbCatalogDisable) * 100),
                'dataColor': colors.blue.regularv2,
                'moreInfos': `Nombre total de catalogue actif : `,
                'suptitle' : 'Nombre de catalogue actif',
                'moreInfosData': `${this.state.catalogs.nbCatalogEnable}/${this.state.catalogs.nbCatalogEnable + this.state.catalogs.nbCatalogDisable}`
            },
            {
                'subtitle': this.props.t("catalogs.dashboard.catalogLinkedToAsset"),
                'data': Math.round(this.state.catalogs.nbCatalogWithAsset / (this.state.catalogs.nbCatalogWithAsset + this.state.catalogs.nbCatalogWithoutAsset) * 100),
                'dataColor': colors.blue.darker.hue300,
                'moreInfos': `Nombre total de catalogue lié : `,
                'suptitle' : 'Nombre de produits dans un catalogue',
                'moreInfosData': `${this.state.catalogs.nbCatalogWithAsset}/${this.state.catalogs.nbCatalogWithAsset + this.state.catalogs.nbCatalogWithoutAsset}`,
            },
        ];

        return (
            <div style={{ width: "100%" }}>
                <TopPanel
                    icomoon="icon-dashboard"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("spread_builder.dashboard.welcomeOnDashboardCatalogs")}
                    subtitle="Gestion de vos catalogues (création / modification / suppression)"
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    hasBorder={true}
                    windowWidth={this.props.windowWidth}
                />
                <Grid container direction="row" justifyContent="center" spacing={0}>    
                    <Grid item xs={12}>
                        <WrapperStats container direction="row" alignItems="center" justifyContent='space-between'>
                            {/* Circle stats */}
                            <Grid item md={7}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    {
                                        // this.state.loadingDashboard ?
                                        // Fix this
                                            // <CircularProgress color={"inherit"} />
                                            // :
                                            lastDatas.map((data, index) => {
                                                return (
                                                    <Grid item xs={6} key={`LastDatas${index}`} style={{ 
                                                        padding: "0px 10px 32px 10px",
                                                        display: this.state.loadingDashboard ? "flex" : null,
                                                        justifyContent: this.state.loadingDashboard ? "center" : null,
                                                    }}>
                                                        {
                                                            // data.data || data.data === 0 && data.data !== "NaN" ?
                                                            !this.state.loadingDashboard ?
                                                                <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                                                                    <Grid item style={{ maxWidth: 80, width: "100%" }}>
                                                                        {
                                                                                <BoxCustom position="relative" display="flex" justifyContent={"center"} statuscolor={data.dataColor} width="100%">
                                                                                    <CircularProgress variant="determinate" thickness={2.5} value={data.data === 'NaN' ? 0 : data.data} style={{ width: "100%", height: "auto", position: "absolute", left: 0, top: 0, zIndex: 2 }} />
                                                                                    <CircularProgress
                                                                                        variant="determinate"
                                                                                        value={100}
                                                                                        disableShrink
                                                                                        style={{ width: "100%", height: "auto" }} 
                                                                                        thickness={2.5}
                                                                                    />
                                                                                    <Box
                                                                                        top={0}
                                                                                        left={0}
                                                                                        bottom={0}
                                                                                        right={0}
                                                                                        position="absolute"
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                        justifyContent="center"
                                                                                    >
                                                                                        {
                                                                                            data.icon ?
                                                                                                <IcomoonReact iconSet={iconSet} color={data.dataColor} size={this.props.windowWidth > 1200 ? 35 : 25} icon={data.icon} />
                                                                                                :
                                                                                                <Typography variant="body1" color="textSecondary" style={{ fontWeight: "bold", fontSize: 25 }}>
                                                                                                    {data.data || 0}%
                                                                                                </Typography>
                                                                                        }
                                                                                    </Box>
                                                                                </BoxCustom>
                                                                                // <Typography variant="h2" colortext={colors.blue.lighter.hue150} style={{fontSize: this.props.windowWidth > 1200 ? 23 : 16}}>{lastDatas[0].data}</Typography>
                                                                            }
                                                                    </Grid>
                                                                    <Grid item style={{ flex: 1, paddingLeft: 22 }}>
                                                                        <Grid container direction="column" alignItems="center">
                                                                            <TitleBis variant="body1">{this.props.t(data.suptitle)}</TitleBis>
                                                                            <TitleBis variant="body1">{this.props.t(data.title)}</TitleBis>
                                                                            <SubTitleBis variant="h5">{this.props.t(data.subtitle)}</SubTitleBis>
                                                                            <MoreInfosBis variant="body2">{this.props.t(data.moreInfos)} <strong>{data.moreInfosData !== "undefined/NaN" ? data.moreInfosData : '0/0'}</strong></MoreInfosBis>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            : <CircularProgress color={"inherit"} />
                                                                // :
                                                                // null
                                                                // <Grid container alignItems='center' justifyContent="center">
                                                                //     {/* // Fix this */}
                                                                //     {/* <CircularProgress color={"inherit"} /> */}
                                                                //     <p>Pas de data</p>
                                                                // </Grid>
                                                        }
                                                    </Grid>
                                                )
                                            })
                                    }
                                </Grid>
                            </Grid>
                            {/* Mini texts */}
                            {/* <Grid item md={5} sm={4}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item style={{ display: 'flex', alignItems: 'center', width: "100%" }}>
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography variant="h4" style={{ lineHeight: '18px' }}><strong>Nombre de catalogue par statut</strong></Typography>
                                                <Typography colortext={colors.black.regular} variant="body2">Dernière mise à jour : {moment().format('DD/MM/YYYY à HH:mm')}</Typography>
                                            </Grid>
                                            <Grid item style={{ marginTop: 16 }}>
                                                <Grid container direction="row" style={{paddingLeft: '15px'}}>
                                                    {stats.map((stat, index) =>
                                                    (
                                                        <Grid item md={4} xs={12} key={`Stat${index}`}>
                                                            <Grid container justifyContent={{md: 'center', sm: 'flex-end'}}>
                                                                <Grid item justifyContent='center'>
                                                                    <Stats windowWidth={this.props.windowWidth} stat={stat} subtitlebold={true} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                        </WrapperStats>
                    </Grid>
                    {
                        sliderData.length > 0 ?
                            <Grid item xl={sliderData.length >= 1 ? 12 : 6} lg={sliderData.length >= 1 ? 12 : 8} md={sliderData.length >= 1 ? 12 : 8} xs={12} style={{ overflow: 'hidden' }}>
                                <AccordionCustom forcedExpanded={true} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{this.props.t('products.dashboard.actionslider.actionslider')}</Typography>}>
                                    <SliderDashboard sliderData={sliderData} windowWidth={this.props.windowWidth} />
                                </AccordionCustom>
                            </Grid>
                            : null
                    }
                    {/* {
                        this.state.catalogs.activeCatalogs?.length > 0 ?
                            <Grid item xs={12} style={{ marginTop: 20 }}>
                                <Typography variant="h4" style={{ fontWeight: 'bold' }}>{this.props.t("catalogs.dashboard.activeCatalog")}</Typography>
                                <Grid container direction="row" style={{ marginTop: 8 }} spacing={2}>
                                    {
                                        this.state.catalogs.activeCatalogs.map((catalog, index) => {
                                            let type = {
                                                node: {
                                                    ...catalog
                                                }
                                            }
                                            return (
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListFlux${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{ width: "100%" }} contentpadding={'0 0px 10px 0px'} >
                                                        <CardCatalog
                                                            data={type}
                                                            textButton={this.props.t("catalogs.dashboard.seeCatalog")}
                                                            openForm={this.state.openForm} windowWidth={this.props.windowWidth}
                                                            nbProducts={this.state.catalogs.activeProductsByCatalog[catalog.id]}
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }

                                </Grid>
                            </Grid>
                            : null
                    } */}
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
    }
}

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardCatalogs))));
