import React, { useState, useCallback, useEffect } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { InputLabel } from "@mui/material";
import { withTranslation } from "react-i18next";
import colors from "@config/theme/colors";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import Icon from "@components/ui/icons/Icon";
import { HelperText } from "../styles/inputStyled";
import { Controller } from "react-hook-form";

const TextFieldCustom = styled(TextField)`
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${colors.blue.regular};
    }
    & > p {
      margin: 0;
    }
  }
  .MuiOutlinedInput-root {
    background-color: white;
    fieldset {
      border: 1px solid ${colors.grey.border};
    }
    &:hover fieldset {
      border-color: ${colors.blue.regular};
    }
    &.Mui-error {
      background-color: ${colors.red.hue100} !important;
    }
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.red.regular} !important;
  }
  & > p {
    margin-left: 0;
  }
`;

/**
 * This component can be used with or without react hook form, controlled or not
 * please, use it with react hook form, it's better
 * If you use this component in form with react hook form, use control and name props
 */
const TextInput = ({
  label = "",
  value = "",
  onChange = null,
  error = null,
  required = false,
  helper = null,
  type = "text",
  delay = 0,
  startIcon = null,
  endIcon = null,
  inHookForm = false,
  name, // for react hook form
  rules = {}, // for react hook form
  control, // for react hook form
  register, // for react hook form
  ...props
}) => {
  const isPassword = type === "password";
  const defaultInputProps = {
    startAdornment: startIcon ? (
      <InputAdornment position="start">
        <Icon icon={startIcon} size={"1.3rem"} />
      </InputAdornment>
    ) : null,
    endAdornment: endIcon ? (
      <InputAdornment position="end">
        <Icon icon={endIcon} size={"1.3rem"} />
      </InputAdornment>
    ) : null,
  };
  const [val, setVal] = useState(value);
  const [hideValue, setHideValue] = useState(isPassword);
  const [InputProps, setInputProps] = useState(
    props.InputProps ?? defaultInputProps
  ); // Beware, InputProps and inputProps are different, see MUI doc
  const commonDefaultProps = {
    variant: "outlined",
    InputProps,
    error: error ? true : false,
    helperText: error,
    type: !hideValue && isPassword ? "text" : type,
    inputProps: {
      maxLength: 255,
      spellCheck: true,
      lang: "fr",
      ...props.inputProps,
    },
    spellCheck: true,
    lang: "fr",
    size: "medium",
    ...props,
  };

  useEffect(() => {
    if (error || isPassword) {
      setInputProps((prevState) => ({
        ...prevState,
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              gap: "0.5rem",
            }}
            onClick={() => isPassword && setHideValue(!hideValue)}
          >
            {isPassword &&
              (hideValue ? (
                <Icon icon="visibility" size="1.3rem" />
              ) : (
                <Icon icon="visibilityOff" size="1.3rem" />
              ))}
            {error && (
              <Icon icon="warning" size="1.3rem" color={colors.red.regular} />
            )}
          </InputAdornment>
        ),
      }));
    } else {
      setInputProps(defaultInputProps);
    }
  }, [error, isPassword, hideValue]);

  useEffect(() => {
    if (value !== val) setVal(value);
  }, [value]);

  const handleChange = (e, hookFormCallback) => {
    if (onChange) setVal(e.target.value);
    emitValue(e, hookFormCallback);
  };

  const emitValue = useCallback(
    debounce((val, hookFormCallback) => {
      if (onChange) onChange(val);
      if (hookFormCallback) hookFormCallback(val);
    }, delay),
    []
  );

  return (
    <>
      <Grid item style={{ position: "relative" }}>
        {label && (
          <InputLabel sx={{ fontSize: "14px", marginBottom: "5px" }}>
            {label}
            {required && "*"}
          </InputLabel>
        )}
        <Box>
          {control && name && inHookForm ? (
            <Controller
              control={control}
              name={name}
              rules={rules}
              render={({ field, fieldState }) => (
                <TextFieldCustom
                  {...commonDefaultProps}
                  {...field}
                  onChange={
                    onChange
                      ? (e) => handleChange(e, field.onChange)
                      : (e) => field.onChange(e)
                  }
                />
              )}
            />
          ) : (
            <TextFieldCustom
              {...(register && name && inHookForm ? register(name, rules) : {})}
              {...commonDefaultProps}
              value={inHookForm ? undefined : val}
              onChange={inHookForm ? undefined : handleChange}
            />
          )}
          {helper && <HelperText>{helper}</HelperText>}
        </Box>
      </Grid>
    </>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  required: PropTypes.bool,
  helper: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  delay: PropTypes.number,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  inHookForm: PropTypes.bool,
  name: PropTypes.string,
  rules: PropTypes.object,
  register: PropTypes.func,
  control: PropTypes.object,
};

export default withTranslation()(TextInput);
