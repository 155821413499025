import colors from "../../../../../../../config/theme/colors"
import iconSet from "../../../../../../../assets/selection.json";
import OurTypography from "../../../../../../ui/typography/Typography";
import CircularDataProgress from "../../../../../../ui/data-progress/CircularDataProgress";
import IcomoonReact from "icomoon-react";

const InfoCard = ({
  title,
  value,
  icon,
  isLast
}) => {
  return (
    <div style={{
      width: "25%",
      borderRight: isLast ? "none" : `0.5px solid ${colors.grey.border}`,
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }}>
      <div>
        <OurTypography mb={1} fontweighttext={"bold"}>{title}</OurTypography>
        <OurTypography style={{ lineHeight: "31px" }} fontweighttext={"bold"} fontsize={"31px"}>{value}</OurTypography>
      </div>
      <div>
        <CircularDataProgress
          count={value}
          content={
            <IcomoonReact color={colors.blue.darker.hue300} iconSet={iconSet} size={20} icon={icon} />
          }
          color={colors.blue.darker.hue300}
        />
      </div>
    </div>
  )
}

export default InfoCard