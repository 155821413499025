import { GridCustomCheckbox } from "../../styles/inputStyled";
import CheckboxItems from '../../../inputs/CheckboxItems';

const CheckBoxItem = ({ allProps: props }) => {
    return (
        <GridCustomCheckbox item xs={12}>
            <CheckboxItems
                currentLang={props.allState.currentLang}
                selectedCategorie={props.selectedCategorie}
                productByCategorie={props.productByCategorie}
                selectedProductByCategorie={props.selectedProductByCategorie}
                setSelectedProducts={props.setSelectedProducts}
                resetReloadListing={props.resetReloadListing}
                gridItems={props.gridItems}
                loadGrid={props.loadGrid}
                addProducts={props.addProducts}
                merchModal={props.merchModal}
                pagination={props.allState.paginationCheckboxItem}
                allState={props.allState}
                stateCallback={props.stateCallback}
            />
        </GridCustomCheckbox>
    )
}

export default CheckBoxItem;