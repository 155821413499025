import React from "react";
import { Grid } from "@mui/material"
import PropTypes from 'prop-types';
import colors from "../../../config/theme/colors"
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import OurTypography from "../typography/Typography";
import "./SeeMore.scss"

const SeeMore = ({
    isOpen,
    handleClick,
    openedIcon,
    closedIcon,
    lineStyle,
    lineStyleOpen,
}) => {

    return (
        <Grid container className={isOpen ? 'seeMore lineStyleOpen' : 'seeMore lineStyle'}>
            <Grid container justifyContent='center' alignItems='center' onClick={() => {handleClick()}}>
                <OurTypography className={isOpen ? 'seeMoreText' : 'seeMoreText close'} colortext={colors.blue.darker.hue300}>{isOpen ? '- Voir moins de catégories' : '+ Voir plus de catégories'}</OurTypography>
            </Grid>
        </Grid>
    )
}

SeeMore.propTypes = {
    isOpen: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
    openedIcon: PropTypes.node,
    closedIcon: PropTypes.node,
    lineStyle: PropTypes.object,
}

SeeMore.defaultProps = {
    isOpen: false, 
    handleClick: () => {},
    openedIcon: <AddCircleSharpIcon fontSize='large' style={{fill: colors.grey.border}}></AddCircleSharpIcon>,
    closedIcon: <RemoveCircleSharpIcon fontSize='large' style={{fill: colors.grey.border}}></RemoveCircleSharpIcon>,
    // lineStyle: {
    //     background: 'linear-gradient(rgba(255, 255, 255, 0.53) 0%, rgb(255, 255, 255) 60%)',
    //     width: '100%',
    //     height: '40px',
    //     top: '-30px',
    //     position: 'relative',
    //     transition: 'all 0.5s ease-in-out'
    // },
    // lineStyleOpen: {
    //     background: 'linear-gradient(rgba(255, 255, 255, 0.53) 0%, rgb(255, 255, 255) 60%)',
    //     width: '100%',
    //     height: '40px',
    //     position: 'relative',
    //     transition: 'all 0.5s ease-in-out',
    //     top: 0
    // },
}

export default SeeMore