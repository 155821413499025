import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import SelectSort from "../../components/ui/pagination/SelectSort";
import SelectOptions from "../../components/ui/pagination/SelectOptions";
import SelectCkeckBox from "../../components/ui/pagination/SelectCkeckBox";
import TraductionSelect from "../../components/layouts/TopPanel/TraductionSelect";
import colors from "../../config/theme/colors";
import SelectLang from "../../components/ui/pagination/SelectLang";

const TablePaginationCustom = styled(Pagination)`
  .MuiToolbar-root {
    padding: 0;
  }
  .MuiTablePagination-spacer {
    display: none;
  }
  .Mui-selected {
    background: #fff;
    border: 0.5px solid ${colors.grey.border};
    font-weight: bold;
  }
`;

/**
 * Change la page en cours
 * @param {Object} pagination - Paramètre de pagination actuelle
 * @param {number} newPage - Page demandée
 * @returns {Object} - Nouveaux paramètres de pagination
 */
export const changePage = (pagination, newPage, typeResult = "edges") => {
  return new Promise((resolve, reject) => {
    let { page, startCursor, endCursor } = pagination;
    let newPagination = null;
    if (typeResult === "edges" || typeResult === "itemsPerPage") {
      newPagination = {
        ...pagination,
        startCursor: newPage > page ? endCursor : null,
        endCursor: newPage > page ? null : startCursor,
        page: newPage,
      };
    } else {
      newPagination = {
        ...pagination,
        page: newPage,
      };
    }
    resolve(newPagination);
  });
};

/**
 * Transforme les paramètres de paginations en variables pour une query
 * @param {Object} pagination - Paramètres de pagination
 * @returns {Object} - Variables de query
 */
export const paginationQueryData = (pagination, typeResult = "edges") => {
  return new Promise((resolve, reject) => {
    let variables = null;
    if (typeResult === "edges") {
      variables = {
        nbperpage: pagination.perPage,
      };
    } else {
      variables = {
        itemsPerPage: pagination.perPage,
      };
    }
    if (pagination.page !== null) {
      if (pagination.firstRender) {
        variables.page = 1;
      } else {
        variables.page = pagination.page;
      }
    }
    if (pagination.endCursor) {
      variables.cursorLast =
        pagination.page === 1 ? null : pagination.endCursor;
    }
    if (pagination.startCursor) {
      variables.cursor = pagination.page === 1 ? null : pagination.startCursor;
    }
    resolve(variables);
  });
};

/**
 *  Mets à jour les paramètres de pagination après une query
 * @param {Object} pagination - Paramètres de pagination
 * @param {Object} result - Résultats de la query
 * @returns {Object} - Nouveaux paramètres de pagination
 */
export const updatePaginationData = (
  pagination,
  result,
  type = "edges",
  identifier,
  firstRender
) => {
  return new Promise((resolve, reject) => {
    let newPagination = {};
    if (type === "edges" || type === "itemsPerPage") {
      newPagination = {
        ...pagination,
        page: firstRender ? 1 : pagination.page,
        startCursor: result?.pageInfo?.startCursor,
        endCursor: result?.pageInfo?.endCursor,
        count: result?.totalCount,
        totalPages: Math.ceil(result?.totalCount / pagination.perPage),
      };
    } else {
      newPagination = {
        ...pagination,
        itemsPerPage: pagination.perPage,
        count: result.paginationInfo?.totalCount,
        paginationInfo: result.paginationInfo,
        totalPages: Math.ceil(result?.totalCount / pagination.perPage),
      };
    }
    resolve(newPagination);
  });
};

/**
 * Change le nombre d'élèments par page
 * @param {Object} pagination - Paramètres de la pagination
 * @param {number} value - Nouveau nombre d'élèments par page
 * @returns {Object} - Paramètres de pagination
 */
export const updatePerPage = (pagination, value) => {
  return new Promise((resolve, reject) => {
    let newPagination = {
      ...pagination,
      perPage: value,
      page: 1,
      startCursor: null,
      endCursor: null,
    };
    resolve(newPagination);
  });
};

export const resetPagination = (perPage = 6) => {
  return new Promise((resolve, reject) => {
    let newPagination = {
      perPage: perPage,
      page: 1,
      startCursor: null,
      endCursor: null,
    };
    resolve(newPagination);
  });
};

export const CursorPagination = (props) => {
  let { type, pagination, rowLabel, typeResult = "edges", inFormType } = props;
  const [page, setPage] = useState(pagination.page ?? 0);
  const [perPage, setPerPage] = useState(
    props.pagination.perPage || props.pagination.itemsPerPage
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    props.changePageCallback(newPage, inFormType);
  };

  const handleRowText = () => {
    let pagination = props.pagination;
    let perPage =
      typeResult === "edges" || typeResult === "itemsPerPage"
        ? pagination.perPage
        : pagination.itemsPerPage;
    let from = pagination.page * perPage;
    let to = (pagination.page + 1) * perPage;

    from = from === 0 ? 1 : from;
    to = to > pagination.count ? pagination.count : to;
    let text = `${from}-${to} sur ${pagination.count} `;
    return text;
  };

  const handleChangeRowsPerPage = async (event) => {
    let value = event.target.value;
    setPerPage(value);
    props.changePerPageCallback(value);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {type === "table" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
          }}
        >
          {props.enableChangeView ? (
            <SelectOptions
              changePerPageCallback={handleChangeRowsPerPage}
              currentPerPage={perPage}
              perPageOptions={props.perPageOptions}
              views={props.views}
              handleViews={props.handleViews}
            />
          ) : null}
          {props.enableChangeSort ? (
            <SelectSort
              changeSort={props.changeSort}
              currentSort={props.currentSort}
            />
          ) : null}
          { props.enablePagination && pagination.totalPages > 1 ? (
            <TablePaginationCustom
              page={page}
              component="div"
              onChange={handleChangePage}
              rowsPerPage={perPage}
              onChangeRowsPerPage={
                props.perPageOptions ? handleChangeRowsPerPage : () => {}
              }
              count={pagination.totalPages}
              labelDisplayedRows={(pagination) => handleRowText()}
              labelRowsPerPage={rowLabel}
              rowsPerPageOptions={
                props.perPageOptions ? props.perPageOptions : []
              }
              style={{ borderBottom: 0 }}
              showFirstButton
              showLastButton
              defaultPage={1}
            />
          ) : null}
          {props.enableSelectionItems ? (
            <>
              <Divider orientation="vertical" flexItem />
              <SelectCkeckBox
                itemSelection={props.itemSelection}
                views={props.views}
                handleViews={props.handleViews}
                handleSelectionMode={props.handleSelectionMode}
                handleSelectionItems={props.handleSelectionItems}
                actions={props.actions}
              />
            </>
          ) : null}
        </div>
      ) : null}
      {/* <SelectLang
                currentLang={props.currentLang}
                handleLang={props.handleLang}
                locales={props.locales}
            /> */}
      {/* <TraductionSelect
                currentLang={props.currentLang}
                handleLang={props.handleLang}
                locales={props.locales}
            /> */}
    </div>
  );
};
