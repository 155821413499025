import React from 'react';
import { Grid } from '@mui/material';
import Typography from '../../../../ui/typography/Typography';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader'
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../../CardCustom';
import * as moment from 'moment';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LanguageIcon from '@mui/icons-material/Language';
import imgNotFound from '../../../../../assets/images/not-found.png';
import CircularProgress from '@mui/material/CircularProgress';

//ICONES FB
import likeReaction         from '../../../../../assets/pictos/reaction-01.svg'
import loveReaction         from '../../../../../assets/pictos/reaction-02.svg'
import laughReaction        from '../../../../../assets/pictos/reaction-03.svg'
import surprisedReaction    from '../../../../../assets/pictos/reaction-04.svg'
import sadReaction          from '../../../../../assets/pictos/reaction-05.svg'
import angryReaction        from '../../../../../assets/pictos/reaction-06.svg'
import careReaction        from '../../../../../assets/pictos/reaction-07.svg'


const FeedItem = styled(Grid)`
    margin-bottom:30px;
    img{
        width:100%;
    }
`

const SocialCounter = styled(Grid)`
    color:${colors.blue.lighter.hue150}
`

const PostDate = styled(Typography)`
    float:right;
    color:#0273A5;
`

const MessagePost = styled(Typography)`
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`

const SeeMore = styled(Typography)`
    color:${colors.blue.darker.hue300};
    text-align: center;
    cursor: pointer;
`

const SocialsIcon=styled.div`
    width:25px;
    height:25px;
    border-radius:50%;
    background:red;
    display:flex;
    border:3px solid white;
    transform : scale(1);
    transition:all .5s;
    &:hover{
        transform : scale(1.1); 
    }
`
export default class CardFacebook extends React.Component{

    handleClick() {
        this.props.handleLoad(this.props.pagination.cursors.after)
    }

    renderReactions(post){
        let arrayPosts = {};
        let getLike = post.filter(e => e.type === 'LIKE')
        let getLove = post.filter(e => e.type === 'LOVE')
        let getCare = post.filter(e => e.type === 'CARE')
        let getHaha = post.filter(e => e.type === 'HAHA')
        let getWow = post.filter(e => e.type === 'WOW')
        let getSad = post.filter(e => e.type === 'SAD')
        let getGrr = post.filter(e => e.type === 'ANGRY')
        arrayPosts = {
            like: getLike.length,
            love: getLove.length,
            care: getCare.length,
            haha: getHaha.length,
            wow: getWow.length,
            sad: getSad.length,
            grr: getGrr.length,
        }
        return(
            Object.keys(arrayPosts).map((keyName,index) => {
                if (arrayPosts[keyName] > 0){
                    return(
                        <Tooltip arrow title={<span style={{fontSize: '0.8rem', fontWeight: 'bold'}}>{keyName.toUpperCase()} : {arrayPosts[keyName]}</span>} key={`Reaction-${keyName}`}>
                            <SocialsIcon style={{backgroundImage:`url(${this.facebookIcons[keyName]})`,marginLeft:index === 0 ? 0 : -8, zIndex: 10 - index}}/>   
                        </Tooltip> 
                    ) 
                }
            })
        )
    }

    render() {
        
        let getImageType = (asset) => {
            const images = require.context('../../../../../assets/images', true);
            let image;
            try{
                image=images('./' + asset);
            } catch(e){
                image=imgNotFound;
            }
            return image;
        }

        this.facebookIcons={
            like        : likeReaction,
            love        : loveReaction,
            haha        : laughReaction,
            wow         : surprisedReaction,
            sad         : sadReaction,
            grr      : angryReaction,
            care      : careReaction,
        }  

        let props = this.props;
        let posts=this.props.data;
        let pagination=this.props.pagination;
        let img = getImageType(props.type.logo);
        return (
            <>
                {posts?.length > 0 ? 
                    posts.map((post,index)=>
                    <FeedItem item xs={4} key={`post-${index}`}>
                        <CardCustom contentpadding={"0px 0px 20px 0px"} style={{height: "100%"}} cardContentStyle={{display: "flex", flexDirection:'column'}}>
                            <CardHeader
                                action={
                                <IconButton aria-label="settings" onClick={(event)=>{this.props.handleSettings(event,post)}}>
                                    <MoreVertIcon style={{color:'#0273A5'}}/>
                                </IconButton>
                                }

                            />
                            <Grid container style={{height: "100%"}}>
                                <Grid item xs={12} style={{maxHeight: 160,minHeight: 160, overflow: 'hidden', position: 'relative'}}>
                                    {
                                        post.full_picture ?
                                            <img src={post.full_picture} style={{position: "absolute", top: "50%", transform: "translateY(-50%)"}}/>
                                            : <img src={typeof img === "string" ? img : img?.default} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                                    }
                                </Grid >                                   

                                <Grid item xs={12} style={{padding:20,backgroundColor:colors.grey.lighter.hue980}}>
                                    <MessagePost variant="body2">
                                        {post.message}
                                    </MessagePost>
                                </Grid>
                                {post.url ?
                                    <SocialCounter item xs={12} style={{padding:'20px  20px'}}>
                                        <Grid container alignItems="center" direction="row">
                                            <LanguageIcon/> 
                                            <a href={post.url} style={{marginLeft:10,color:colors.blue.lighter.hue150}} target="_blank" rel="noreferrer">Lien intégré à la publication</a>
                                        </Grid>

                                    </SocialCounter>
                                    :<div style={{height: 64, width: "100%"}}></div>
                                }
                                <Grid item xs={12} style={{padding:'0  20px', display: 'flex', alignItems: "flex-end"}}>
                                    <Grid container space={1} alignItems="center" style={{height: 25}}>
                                        {
                                            post.reactions?.data.length > 0 ? 
                                            <SocialCounter item xs={10}>
                                                <Grid container alignItems="center" direction="row">
                                                    {/* TODO: Regrouper les réactions par type -> Display avec l'image */}
                                                    {this.renderReactions(post.reactions.data)}
                                                    {/* {post.reactions?.data.map((reaction,index)=>{
                                                        return(
                                                            <Tooltip arrow title={`${reaction.type}: `} key={`Reaction-${reaction.type}`}>
                                                                <SocialsIcon style={{backgroundImage:`url(${this.facebookIcons[reaction.type]})`,marginLeft:index === 0 ? 0 : -8, zIndex: 10 - index}}/>   
                                                            </Tooltip> 
                                                        )                                               
                                                    })} */}
                                                    <Typography variant="body2" style={{marginLeft:10}}>{post.reactions.data.length}</Typography>
                                                </Grid>                                                             
                                            </SocialCounter>                                                            
                                            : 
                                            <Grid item xs={10}><Typography variant="body2">Aucune réaction sur ce post</Typography></Grid>
                                        }

                                        <Grid item xs={2} style={{flexGrow:'1'}}>
                                            <PostDate variant="body2">{moment(post.created_time).format('L')}</PostDate>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardCustom>
                    </FeedItem>                                    
                )
                : <PageLoader/>}
                {pagination?.cursors?.after !== pagination?.cursors?.before ?
                    <Grid container justifyContent="center" style={{paddingBottom: 24}}>
                        {
                            this.props.loader ?
                                <CircularProgress color="inherit" size={'3rem'} 
                                style={{padding: 0}}
                                />
                            :
                                <SeeMore variant="body2" onClick={()=> this.handleClick()}>Voir plus <br/> ...</SeeMore>
                        }
                    </Grid>
                    : null 
                }
            </>
        );
    }
}