import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultAccordion } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bloc accordeon',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        className:{
            label: 'Classe(s)',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        value: {
            type: FormInputTypes.TEXT,
            label: "Titre de la section",
            value: "Accordeons"
        },
        fontSize: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Taille du texte (en px)",
            params: {max: 100, min: 2, step: 2},
        },
        color: {
            label: 'Couleur du texte',
            type: FormInputTypes.COLOR,
            value: 'FAFAFB',
        },
        textAlign: {
            type: FormInputTypes.SELECT,
            label: "Alignement du texte",
            value: "initial",
            params: [
              { label: "Initial", value: "initial" },
              { label: "Centré", value: "center" },
              { label: "Droite", value: "right" },
            ],
          },
        paddingTop: {
            type: FormInputTypes.SLIDER,
            value: 60,
            label: "Padding top",
            params: {max: 200, min: 0, step: 2},
        },
        paddingBottom: {
            type: FormInputTypes.SLIDER,
            value: 60,
            label: "Padding bottom",
            params: {max: 200, min: 0, step: 2},
        },
        noPaddingHorizontal: {
            label: "Désactiver l'espace sur les côtés",
            type: FormInputTypes.SWITCH,
            value: false,
        },
    },
    blocks:{
        accordions:{
            title: "Accordeons",
            name: "Accordeons",
            type: EditableTypes.MULTIPLE_BLOCKS,            
            subtype: ItemTypes.CMS_ACCORDION,
            buttonAdd: 'un accordeon',
            config: [ 
                getDefaultAccordion(),
            ]
        }
    }
};
