import { FORM } from "../../../../../js/constants/form-types";
import Button from "../../../../ui/button/Button";

export default function assetsAdd(listGroups,generate){
    return ({
        titleForm: 'Créer un nouvel utilisateur',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer un utilisateur',
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        textButtonValidate: 'Créer',
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations générales',
            },
            children:[
                {
                    optionsInputs: [
                        {
                            type: 'oneImage',
                            label: 'Photo de profil',
                            translated: false,
                            helper:  {
                                text: 'Choisir une image de profil',
                                link: false,
                            },
                            required: false,
                            stateName: 'image',
                        },
                        {
                            type: 'text',
                            label: 'Nom',
                            helper: {
                                text: 'Indiquez le nom ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'lastname',
                        },
                        {
                            type: 'text',
                            label: 'Prénom',
                            helper: {
                                text: 'Indiquez le prénom ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'firstname',
                        },
                        {
                            type: 'text',
                            email: true,
                            label: 'Adresse email',
                            helper: {
                                text: 'Indiquez l\'adresse email',
                                link: false,
                            },
                            required: true,
                            stateName: 'email',
                        },
                        {
                            type: 'text',
                            label: 'Téléphone',
                            helper: {
                                text: 'Indiquez le numéro de téléphone ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'phone',
                        },
                        {
                            type: 'text',
                            label: 'Poste occupé',
                            helper: {
                                text: 'Indiquez le poste ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'job',
                        },
                        {
                            type: 'select',
                            disabled: false,
                            label: 'Groupe',
                            helper: {
                                text: 'Choississez un groupe',
                                link: false,
                            },
                            required: true,
                            stateName: 'userGroup',
                            value: listGroups ? 
                                listGroups.map((values) => {
                                    return values.node ? ({ label: values.node?.name ?? values.node.code, value: values.node.id }) : ({ label: values?.name ?? values.code, value: values.id })
                                })
                                : null
                        },
                        {
                            type: 'switch',
                            label: 'Actif',
                            helper: {
                                label: 'État',
                                text: 'Indiquez l\'état',
                                link: false,
                            },
                            required: true,
                            stateName: 'isActive',
                            value: [
                                {
                                    value: true,
                                    label: 'Actif',
                                },
                                {
                                    value: false,
                                    label: 'Inactif',
                                },
                            ]
                        },
                        {
                            type: 'switch',
                            label: 'Notifier le mot de passe par email',
                            helper: {
                                text: 'Notification',
                                link: false,
                            },
                            required: true,
                            stateName: 'notif',
                            value: [
                                {
                                    value: true,
                                    label: 'Par mail',
                                },
                                {
                                    value: false,
                                    label: 'Afficher à l\'écran',
                                },
                            ]
                        },
                        {
                            type: 'switch',
                            label: 'Générer le mot de passe',
                            helper: {
                                text: 'Génération',
                                link: false,
                            },
                            required: true,
                            stateName: 'generate',
                            value: [
                                {
                                    value: true,
                                    label: 'Par Sinfin DXP',
                                },
                                {
                                    value: false,
                                    label: 'Par moi',
                                },
                            ]
                        },
                        !generate&&{
                            type: 'hidden',
                            label: 'Nouveau mot de passe',
                            helper: {
                                text: 'Le mot de passe doit contenir au moins 8 caractères, dont un chiffre, une minuscule, une majuscule et un caractère spécial parmis : !@#$%^&*',
                                link: false,
                            },
                            required: true,
                            stateName: 'plainPassword',
                        },
                        !generate&&{
                            type: 'hidden',
                            confirm: true,
                            label: 'Confirmer le mot de passe',
                            helper: {
                                text: 'Répétez le mot de passe',
                                link: false,
                            },
                            required: true,
                            stateName: 'plainPasswordRepeat',
                        },
                    ]
                },
            ]
        }
    })
}
