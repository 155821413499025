import { gql } from '@apollo/client';

//----- Récupération des crossSells
// TODO: Create filters to get cross sells
export const GET_CROSS_SELLS = gql`
query crossSell($name: String, $status: Boolean){
    crossSells(name: $name, status: $status){
        edges{
            node{
                id
                name
                status
                asset{
                  id
                }
                crossSellProducts{
                  edges{
                    node{
                      id
                    }
                  }
                }
                crossSellProductFilters{
                  edges{
                    node{
                      id
                      operator
                      value
                      attribute{
                        id
                        identifier
                        attributeType {
                          id
                          input
                          translation {
                            id
                            translationDatas {
                              edges {
                                node {
                                  id
                                  value
                                  locale {
                                    code
                                    libelle
                                  }
                                }
                              }
                            }
                          }
                        }
                        attributeOptions {
                          edges {
                            node {
                              id
                              identifier
                              translation {
                                id
                                translationDatas {
                                  edges {
                                    node {
                                      id
                                      value
                                      locale {
                                        id
                                        code
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      attributeOption{
                        id
                        identifier
                      }
                      step
                    }
                  }
                }
            }
        }
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
        }
        totalCount
        }
    }
`;

//----- Ajouter un crossSell
/*
    Exemple de variables
        {
            "asset" : "/api/assets/2",
            " name": "Test 3.0",
            "status": "true"
        }
*/
export const CREATE_CROSS_SELL = gql`
mutation createCrossSell($asset: String!, $name: String!, $status: Boolean!){
    createCrossSell(input: {asset: $asset, name: $name, status: $status}){
      crossSell{
        id
        asset{
          id
        }
        name
        status
      }
    }
  }
`;

//----- Modifier un crossSell
/*
 Exemple de variables
  {
    "id": "/api/cross-sells/2",
    "asset" : "/api/assets/1",
    " name": "Cross Sell 2.0",
    "status": "false"
  }
*/
export const UPDATE_CROSS_SELL = gql`
mutation updateCrossSell($id: ID!, $asset: String!, $name: String!, $status: Boolean!) {
  updateCrossSell(input: {id: $id, asset: $asset, name: $name, status: $status}) {
    crossSell{
      id
      asset{
        id
      }
      name
      status
    }
  }
}
`;

//----- Supprimer un crossSell
/*
 Exemple de variables
  {
    "id" : "/api/cross-sells/4"
  }
*/
export const DELETE_CROSS_SELL = gql`
mutation deleteCrossSell($id: ID!){
  deleteCrossSell(input: {id: $id}){
    crossSell{
      id
    }
  }
}
`;

//----- Récupération d'un seul crossSell
/*
Exemple de variables
{
  "id" : "/api/cross-sells/2"
}
*/
export const GET_CROSS_SELL = gql`
query crossSell($id: ID!){
  crossSell(id: $id){
    id
    asset{
      id
    }
    name
    status
    crossSellProducts{
      edges{
        node{
          id
        }
      }
    }
    crossSellProductFilters{
      edges{
        node{
          id
        }
      }
    }
  }
}
`;

//----- Récupération des crossSellProducts
export const GET_CROSS_SELL_PRODUCTS = gql`
{
  crossSellProducts{
   edges{
    	node{
        id
        crossSell{
          id
          name
          status
          asset{
            id
          }
          crossSellProductFilters{
            edges{
              node{
                id
              }
            }
          }
        }
        product{
          id
        }
        position
      }
   }
   pageInfo{
   		endCursor
    	startCursor
    	hasNextPage
    	hasPreviousPage
   }
  totalCount
  }
}
`;

//----- Ajouter un crossSellProduct
/*
  Exemple de variables
    {
      "crossSell" : "/api/cross-sells/2",
      "product": "/api/products/2",
      "position": 2
    }
*/
export const CREATE_CROSS_SELL_PRODUCT = gql`
mutation createCrossSellProduct($crossSell: String!, $product: String!, $position: Int) {
  createCrossSellProduct(input: {crossSell: $crossSell, product: $product, position: $position}){
    crossSellProduct{
      id
      crossSell{
        id
      }
      product{
        id
      }
      position
    }
  }
}
`;

//----- Modifier un crossSellProduct
/*
  Exemple de variables
    {
      "id" : "/api/cross-sell-products/1",
      "crossSell" : "/api/cross-sells/3",
      "product": "/api/products/2",
      "position": 4
    }
*/
export const UPDATE_CROSS_SELL_PRODUCT = gql`
mutation updateCrossSellProduct($id: ID!, $crossSell: String!, $product: String!, $position: Int) {
  updateCrossSellProduct(input: {id: $id, crossSell: $crossSell, product: $product, position: $position}) {
    crossSellProduct{
      id
      crossSell{
        id
      }
      product{
        id
      }
      position
    }
  }
}
`;

//----- Supprimer un crossSellProduct
/*
 Exemple de variables
  {
    "id" : "/api/cross-sell-products/3"
  }
*/
export const DELETE_CROSS_SELL_PRODUCT = gql`
mutation deleteCrossSellProduct($id: ID!){
  deleteCrossSellProduct(input: {id:$id}){
    crossSellProduct{
      id
    }
  }
}
`;

//----- Récupération d'un seul crossSellProduct
/*
Exemple de variables
{
  "id" : "/api/cross-sell-products/1"
}
*/
export const GET_CROSS_SELL_PRODUCT = gql`
query crossSellProduct($id: ID!){
  crossSellProduct(id: $id){
    id
    crossSell{
      id
      name
      status
      asset{
        id
      }
      crossSellProducts{
        edges{
          node{
            id
          }
        }
      }
      crossSellProductFilters{
        edges{
          node{
            id
          }
        }
      }
    }
  }
}
`;

//----- Récupération des crossSellProductFilters
export const GET_CROSS_SELL_PRODUCT_FILTERS = gql`
{
	crossSellProductFilters{
    edges{
      node{
        id
        crossSell{
          id
          name
          status
          asset{
            id
          }
          crossSellProducts{
            edges{
              node{
                id
              }
            }
          }
        }
        operator
        value
        attribute{
          id
        }
        attributeOption{
          id
        }
        step
      }
    }
  }
}
`;

//----- Ajouter un crossSellProductFilter
/*
  Exemple de variables
    {
      "crossSell" : "/api/cross-sells/2",
      "operator": "Test operator",
      "value": "Test value",
      "attribute": "/api/attributes/2",
      "attributeOption": "/api/attribute-options/40",
      "step": "Testing step"
    }
*/
export const CREATE_CROSS_SELL_PRODUCT_FILTER = gql`
mutation createCrossSellProductFilter($crossSell: String!, $operator: String!, $value: String, $attribute: String!, $attributeOption: String, $step: String!){
  createCrossSellProductFilter(input: {crossSell: $crossSell, operator: $operator, value: $value, attribute: $attribute, attributeOption: $attributeOption, step: $step}){
    crossSellProductFilter{
      id
      crossSell{
        id
      }
      operator
      value
      attribute{
        id
      }
      attributeOption{
        id
      }
      step
    }
  }
}
`;

//----- Modifier un crossSellProductFilter
/*
  Exemple de variables
    {
      "id" : "/api/cross-sell-product-filters/3",
      "crossSell" : "/api/cross-sells/1",
      "operator": "new operator",
      "value": "new value",
      "attribute": "/api/attributes/5",
      "attributeOption": "/api/attribute-options/40",
      "step": "new step"
    }
*/
export const UPDATE_CROSS_SELL_PRODUCT_FILTER = gql`
mutation updateCrossSellProductFilter($id: ID!, $crossSell: String, $operator: String, $value: String, $attribute: String, $attributeOption: String, $step: String){
  updateCrossSellProductFilter(input: {id: $id, crossSell: $crossSell, operator: $operator, value: $value, attribute: $attribute, attributeOption: $attributeOption, step: $step}){
    crossSellProductFilter{
      id
      crossSell{
        id
      }
      operator
      value
      attribute{
        id
      }
      attributeOption{
        id
      }
      step
    }
  }
}
`;

//----- Supprimer un crossSellProductFilter
/*
 Exemple de variables
  {
    "id" : "/api/cross-sell-product-filters/3"
  }
*/
export const DELETE_CROSS_SELL_PRODUCT_FILTER = gql`
mutation deleteCrossSellProductFilter($id: ID!){
  deleteCrossSellProductFilter(input: {id:$id}){
    crossSellProductFilter{
      id
    }
  }
}
`;

//----- Récupération d'un seul crossSellProductFilter
/*
Exemple de variables
{
  "id" : "/api/cross-sell-product-filters/1"
}
*/
export const GET_CROSS_SELL_PRODUCT_FILTER = gql`
query crossSellProductFilter($id: ID!){
  crossSellProductFilter(id: $id){
    id
    crossSell{
      id
      name
      status
      asset{
        id
      }
      crossSellProducts{
        edges{
          node{
            id
          }
        }
      }
    }
    operator
    value
    attribute{
      id
    }
    attributeOption{
      id
    }
    step
  }
}
`;