import React, { useEffect, useState } from "react";
import Icon from "@ui/icons/Icon";
import colors from "@config/theme/colors";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { lighterAndDarker } from "@utils/color.utils";
import CircularProgress from "@mui/material/CircularProgress";

const OurButton = ({
  type,
  handleClick,
  label,
  color,
  hoverColor,
  icon,
  loading = false,
  submit = false,
  large,
  sx = {},
  ...props
}) => {
  const setButtonType = () => {
    switch (type) {
      case "delete":
        return {
          icon: icon === false ? null : "delete",
          color: colors.red.regular,
          hoverColor: colors.red.hue900,
          label: label ?? props.t("actions.delete"),
        };
      case "edit":
        return {
          icon: icon === false ? null : "edit",
          color: colors.blue.regular,
          hoverColor: colors.blue.hue400,
          label: label ?? props.t("actions.edit"),
        };
      case "add":
        return {
          icon: icon === false ? null : "add",
          color: colors.green.hue500,
          hoverColor: colors.green.hue400,
          label: label ?? props.t("actions.add"),
        };
      case "validate":
        return {
          icon: icon === false ? null : "check",
          color: colors.green.hue500,
          hoverColor: colors.green.hue400,
          label: label ?? props.t("actions.validate"),
        };
      default:
        return {
          icon: icon ?? null,
          color: color ?? colors.blue.regular,
          hoverColor:
            hoverColor ?? lighterAndDarker(color ?? colors.blue.regular, 30),
          label: label ?? "",
        };
    }
  };
  const config = setButtonType();

  return (
    <Button
      variant="contained"
      startIcon={
        config.icon ? (
          <Icon icon={config.icon} color={sx.color ?? "white"} size="20" />
        ) : null
      }
      onClick={handleClick}
      sx={{
        backgroundColor: config.color,
        "&:hover": {
          backgroundColor: config.hoverColor,
        },
        borderRadius: "0",
        textTransform: "none",
        fontWeight: "bold",
        padding: large ? "8px 48px" : "8px 24px",
        ...sx,
      }}
      disabled={loading}
      {...props}
    >
      {loading ? <CircularProgress size={"1.3rem"} /> : config.label}
      {/* {props.children} */}
      {submit && <input type="submit" style={{ display: "none" }} />}
    </Button>
  );
};

OurButton.propsTypes = {
  type: PropTypes.string,
  handleClick: PropTypes.func,
  label: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
};

export default withTranslation()(OurButton);

// TODO : finish this component
