import { Divider, Grid } from "@mui/material";
import CarouselProductDetail from "../../../../../layouts/Carousel/CarouselProductDetail";
import OurTypography from "../../../../../ui/typography/Typography";
import StatusInfo from "../../../../../ui/status-info/StatusInfo";
import styled from "styled-components";
import colors from "../../../../../../config/theme/colors";
import OurTooltip from "../../../../../ui/tooltip/Tooltip";

const GridCustom = styled(Grid)`
    display: grid;
    grid-auto-rows: max-content;
    row-gap:50px;
`

const ProductDetailGeneral = props =>{
    const {images,product} = props
    const name = product.flatProducts.edges[0]?.node.name
    const status = product.status
    const price = product.productDatas.edges.find(e=>e.node.attribute.identifier === "product_price")?.node?.value
    const variants = product.children.edges
    const description = product.productDatas.edges.find(e=>e.node.attribute.identifier === "product_description")?.node?.value
    const attributes = [
        {
            label : "Code article",
            value: product.flatProducts.edges[0]?.node.sku
        },
        {
            label : "EAN13",
            value: product.productDatas.edges.find(e=>e.node.attribute.identifier === "product_ean")?.node?.value
        },
        {
            label : "Catégories",
            value: product.productCategories.edges.length > 0 ? <OurTooltip title={product.productCategories.edges.map(e=>e.node.category.libelle).join(' / ')}>{product.productCategories.edges.length}</OurTooltip> : null
        },
        {
            label : "Complétude",
            value: Math.round(Number(product.flatProducts.edges[0]?.node.completude)) + "%",
            color:colors.green.regular
        },
        {
            label : "Catalogue(s)",
            value: product.flatProducts.edges[0]?.node.catalogsNb
        },
        {
            label : "Variante(s)",
            value: variants.length
        },
    ]

    // Condition for LCS multiple prices
    if (process.env.REACT_APP_MULTIPLE_PRICE === "true") {
        attributes.unshift(
            {
                label: "Prix navision",
                value: product.navisionPrice !== "Aucun" ? `${product.navisionPrice} €` : product.navisionPrice,
            },
            {
                label: "Prix du marché",
                value: `${product.productDatas.edges.find(elem => elem.node.attribute.identifier === "PrixMarche").node.value} €`,
            },
        )
    }

    const nbrItems = attributes.length / 2;

    return (
        <Grid container id={props?.id} columnSpacing={4} style={{
            padding: "15px"
        }}>
            <Grid item xs={"auto"}>
                <CarouselProductDetail images={images} imagesWithoutPlaceholder={images} />
            </Grid>
            <GridCustom item xs container>
                <Grid item xs={12} container>
                    <Grid item xs>
                        <OurTypography style={{lineHeight: "34px"}} fontsize={"33px"} fontweighttext={"bold"}>{name}</OurTypography>
                    </Grid>
                    <Grid item>
                        <StatusInfo status={status} width={'auto'} />
                    </Grid>
                </Grid>
                {
                    price &&
                    <Grid item xs={12}>
                        <OurTypography fontsize={"25px"} fontweighttext={"bold"}>{price} €</OurTypography>
                    </Grid>
                }
                {
                    process.env.REACT_APP_MULTIPLE_PRICE === "true" ?
                    <Grid item xs={12}>
                        <OurTypography fontsize={"25px"} fontweighttext={"bold"}>
                            {
                                product.navisionPrice !== "Aucun" ? 
                                `${product.navisionPrice} €` : 
                                `${product.productDatas.edges.find(elem => elem.node.attribute.identifier === "PrixMarche").node.value} €`
                            }
                        </OurTypography>
                    </Grid>
                    : null
                }
                <Grid item xs={12} container columnSpacing={2}>
                    <Grid item xs>
                        {
                            attributes.filter((e,i)=>i<nbrItems).map((e,i)=>{
                                return (
                                    <div key={i} style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        marginBottom: 7
                                    }}>
                                        <OurTypography>{e.label} : </OurTypography>
                                        <OurTypography colortext={e?.color} fontweighttext={'bold'}>{e.value ?? "-"}</OurTypography>
                                    </div>
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs>
                        {
                            attributes.filter((e,i)=>i>=nbrItems).map((e,i)=>{
                                return (
                                    <div key={i} style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        marginBottom: 7
                                    }}>
                                        <OurTypography>{e.label} : </OurTypography>
                                        <OurTypography colortext={e?.color} fontweighttext={'bold'}>{e.value ?? "-"}</OurTypography>
                                    </div>
                                )
                            })
                        }
                    </Grid>
                </Grid>
                {
                    description &&
                    <Grid item xs={12}>
                        <OurTypography style={{marginBottom: 15}} fontweighttext={'bold'}>Description</OurTypography>
                        <OurTypography dangerouslySetInnerHTML={{__html : description}}/>
                    </Grid>
                }
                {
                    variants.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <OurTypography style={{ marginBottom: 15 }} fontweighttext={'bold'}>Variantes</OurTypography>
                            </Grid>
                        </>
                    )
                }
            </GridCustom>
        </Grid>
    )
}

export default ProductDetailGeneral;