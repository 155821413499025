import React, { useEffect, useState, useContext } from 'react';
import { BuilderContext } from "../builder";
import { ArrowBackIos, RotateLeft, Search, Settings, Visibility, VisibilityOff } from '@mui/icons-material';
import { getCurrentDraggables, getDraggablesConfig } from "../core/config";
import { Draggable } from '../core/components/builder';
import { getTypeName } from '../core/components/builder/dnd/getTypeName';
import { AssetTypes } from '../shareable/types';
import './Panel.scss';
import { translatePage } from '../utils/translation';

const Tabs = {
    general: [
        { id: 'components', label: 'Liste de composants' },
        { id: 'order', label: 'Ordre des composants' }
    ],
    edition: [
        { id: 'content', label: 'Contenu' },
        { id: 'design', label: 'Apparence' },
        { id: 'settings', label: 'Paramètres' }
    ]
};

const PanelState = {
    GENERAL: 'general',
    EDITION: 'edition',
    SETTINGS: 'settings'
};

const FooterHeight = 53;

export const BuilderPanel = (props) => {
    const { 
        ready,
        save, reinit,
        page,
        onlyContent,
        noPanel, setNoPanel,
        preview, setPreview,
        height,
        presentation,
        assetType,
        localConfig,
        currentComponent,
        setComponent,
        buildForm,
        buildGeneralInputs
    } = useContext(BuilderContext);

    const [currentState, setCurrentState] = useState(PanelState.GENERAL);
    const [selectedTab, setSelectedTab] = useState('components');
    const [draggables, setDragables] = useState([]);
    const [activatedCategories, setActivatedCategories] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [translating, setTranslating] = useState(false);

    useEffect(() => {
        let draggables = getDraggablesConfig(assetType, presentation);
        setDragables(draggables);
    }, [assetType]);

    useEffect(() => {
        if (currentComponent) {
            setCurrentState(PanelState.EDITION);
            setSelectedTab('components');
            setSearchFilter('');
        } else if (currentState !== PanelState.SETTINGS) {
            setCurrentState(PanelState.GENERAL);
            setSelectedTab('components');
            setSearchFilter('');
        }
    }, [currentComponent]);

    // { Components categories

    const toggleCategory = id => {
        if (activatedCategories.indexOf(id) > -1) {
            setActivatedCategories(activatedCategories.filter(e => e !== id));
        } else {
            let newArray = [...activatedCategories];
            newArray.push(id);
            setActivatedCategories(newArray);
        }
    };

    const categoryIsActive = id => activatedCategories.indexOf(id) > -1;

    // Components categories }

    // { Search 

    const onChange = (event) => {
        setSearchFilter(event.target.value);

        !event.target.value
            ? setActivatedCategories([])
            : setActivatedCategories(draggables?.map(e => e.label));
    };

    // Search }

    // { Edition 

    const goBack = () => {
        setCurrentState(PanelState.GENERAL);
        setSelectedTab('components');
        setSearchFilter('');
        setComponent(null);
    };

    const openSettings = () => {
        setCurrentState(PanelState.SETTINGS);
        setSelectedTab('components');
        setComponent(null);
    };

    // Edition }

    const AllTabs = currentState === PanelState.GENERAL
        ? Tabs.general
        : Tabs.edition;

    let searchLowered = searchFilter?.toLowerCase();

    const SettingsEnabled = (
        assetType === AssetTypes.MINISITE || 
        assetType === AssetTypes.NEWSLETTER || 
        assetType === AssetTypes.FLIPBOOK || 
        assetType === AssetTypes.GAME
    ) && ready;

    const OrderEnabled = (
        assetType === AssetTypes.MINISITE || 
        assetType === AssetTypes.GAME || 
        assetType === AssetTypes.CMS ||
        assetType === AssetTypes.MAGENTO ||
        assetType === AssetTypes.SHOPIFY ||
        assetType === AssetTypes.WORDPRESS
    ) && ready;
    
    return (
        <div className="builder-panel" style={{height}}>
            <div className="panel-content" style={{height: height - FooterHeight}}>
                <div className="panel-header">
                    {
                        currentState === PanelState.GENERAL
                            ? <span className="panel-header-label">SINFIN™ BUILDER</span> 
                            : (
                                <span className="panel-header-edition" onClick={goBack}>
                                    <span className="panel-header-back"><ArrowBackIos /></span> 
                                    &nbsp;{ currentState === PanelState.EDITION
                                        ? 'Modifier le bloc'
                                        : 'Modifier les paramètres' }
                                </span> 
                            ) 
                    }
                    <span className="panel-header-cross" onClick={() => setNoPanel(true)}>&times;</span>
                </div>

                {
                    currentState === PanelState.GENERAL
                        ? (
                            <>
                                <div className={`panel-tabs panel-tabs-columns-${AllTabs.length}`}>
                                    {
                                        AllTabs.map((tab, index) => {
                                            if (tab.label === 'order' && !OrderEnabled)
                                                return null;

                                            return (
                                                <div 
                                                    key={index} 
                                                    className={`panel-tabs-item ${selectedTab === tab.id ? 'panel-tabs-item--selected' : ''}`}
                                                    onClick={() => {
                                                        setSelectedTab(tab.id);
                                                        setSearchFilter('');
                                                    }}
                                                >
                                                    <span>{tab.label}</span>
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                                {
                                    selectedTab === 'components' && (
                                        <>
                                            <div className="panel-search">
                                                <Search />
                                                <input 
                                                    type="text" 
                                                    className="panel-search-input"
                                                    placeholder="Rechercher un composant..."
                                                    onChange={onChange}
                                                /> 
                                            </div>

                                            {
                                                process.env.REACT_APP_ENABLE_TRANSLATION && process.env.REACT_APP_TRANSLATION_KEY && localConfig && page ?
                                                    <div className="translate-btn" title="Traduire en anglais" onClick={async () => {
                                                        setTranslating(true);

                                                        await translatePage(
                                                            props.startLoading,
                                                            props.stopLoading, 
                                                            props.updateConfig,
                                                            localConfig[page]
                                                        );

                                                        setTranslating(false);
                                                    }}>
                                                        <img src="/img/flag-en.png" className={`${translating ? 'rotate' : ''}`} />
                                                    </div>
                                                : null
                                            }

                                            <div className='panel-components'>
                                                { 
                                                    draggables?.map((draggable, i) => {
                                                        let isActive = categoryIsActive(draggable.label);
                                                        let filteredDraggables = draggable.items;

                                                        if (searchFilter) {
                                                            filteredDraggables = draggable.items.filter(e => {
                                                                let name = getTypeName(e.type)?.toLowerCase();

                                                                if (name.indexOf(searchLowered) > -1)
                                                                    return true;
                                                            })
                                                        }

                                                        return (
                                                            <div key={`draggable-${i}`}>
                                                                <div>
                                                                    <div 
                                                                        className={`panel-category ${isActive ? 'panel-category--selected' : ''}`} 
                                                                        onClick={() => toggleCategory(draggable.label)}
                                                                    >
                                                                        <span>{draggable.label}</span>
                                                                        <span className='panel-category-toggle'>{isActive ? '-' : '+'}</span>
                                                                    </div>

                                                                    {
                                                                        isActive && (
                                                                            <div className="panel-components-list">
                                                                                {
                                                                                    filteredDraggables.map((item, j) => (
                                                                                        <Draggable 
                                                                                            key={`draggable-item-${j}`} 
                                                                                            type={item.type} 
                                                                                            icon={item.icon} 
                                                                                            block={true} 
                                                                                        />
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </>
                                    )   
                                }

                                {
                                    selectedTab === 'order' && OrderEnabled && localConfig && page && (
                                        <div className="panel-order">
                                            { getCurrentDraggables(localConfig[page], props.extractAcceptsFromType, props.addComponent) }
                                        </div>
                                    )
                                }
                            </>
                        ) : null
                }

                {
                    currentState === PanelState.EDITION
                        ? currentComponent
                            ? buildForm()
                            : null 
                        : null
                }

                {
                    currentState === PanelState.SETTINGS
                        ? buildGeneralInputs()
                        : null
                }
            </div>

            <div className="panel-footer">
                <div className="panel-footer-actions">
                    <span title="Prévisualiser">
                        {
                            preview 
                                ? <Visibility onClick={() => setPreview(!preview)} /> 
                                : <VisibilityOff onClick={() => setPreview(!preview)} />
                        }
                    </span>
                    <span title="Mise à zéro"><RotateLeft onClick={reinit} /></span> 
                    { SettingsEnabled && <span title="Paramètres"><Settings onClick={openSettings} /></span> }
                </div>
                <div className="panel-footer-button" onClick={save}>
                    <span>Sauvegarder</span>
                </div>
            </div>
        </div>
    );
}