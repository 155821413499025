import React from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import colors from '../../../config/theme/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const CustomSelect = styled(Select)`
    cursor:pointer;
    background-color:#FFF;
    padding:5px 10px;
    fieldset{
        border: 1px solid ${colors.grey.border} !important;
    }
    .MuiSelect-select {
        padding:0;

    }
    .MuiSvgIcon-root {
        right: 5px;
    }
    .MuiModal-root .MuiPaper-root{
        margin-top : 5px;
        .MuiList-root{
            padding:0;
        }
    }
`;

const CustomDivider = styled(Divider)`
    margin-top:0px !important;
    margin-bottom:0px !important;
`;

const CustomMenuItemActive = styled(MenuItem)`
    background-color: ${props=> props.view ?"transparent":colors.blue.lighter.hue900} !important;
    color:${colors.blue.darker.hue300};
    font-weight:bold;
    .MuiTypography-root{
        font-weight:bold;
        color:${colors.blue.darker.hue300};
    }
    &:hover{
        background-color: ${colors.blue.lighter.hue900};
        color:${colors.blue.darker.hue300};
    }
`;

const CustomMenuItem = styled(MenuItem)`
    &:hover{
        background-color: ${colors.blue.lighter.hue900};
        color:${colors.blue.darker.hue300};        
    }
`;

const CustomListSubheader = styled(ListSubheader)`
    line-height:26px;
`;

export default function SelectOptions(props){
    const {perPageOptions,changePerPageCallback,currentPerPage,handleViews,views } = props;

    const handleChange = (e,child)=>{
        if (child.props.name == 'view') {
            handleViews(e.target.value)
        }else {
            changePerPageCallback(e)
        }
    }
    return(
        <FormControl>
            <CustomSelect
                autoWidth
                value={1}
                renderValue={()=><div style={{fontWeight:"bold"}}>Vue</div>}
                onChange={handleChange}
                IconComponent={(props)=>{return <ExpandMoreIcon className={props.className} fontSize="small" />}}
                MenuProps={{
                    sx:{
                        marginTop:"5px"
                    },
                    PaperProps:{
                        sx:{
                            boxShadow:"none",
                            border:`0.5px solid ${colors.grey.border} !important`
                        },  
                    },
                    MenuListProps: { 
                        disablePadding: true ,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: 0,
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
            >
                <CustomListSubheader value={1}>Par Vue</CustomListSubheader>
                {
                    views.settings ?
                    views?.settings?.map((view,i)=>{
                        if (views.current === view.value) {
                            return (
                                <CustomMenuItemActive view key={i}  value={view.value} name={'view'}>
                                    <FormControlLabel control={<Radio disableRipple checked={views.current === view.value} sx={{
                                        fontWeight:"bold",
                                        '&.Mui-checked': {
                                            color: colors.blue.darker.hue300,
                                        },
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 18,
                                        fill: colors.blue.darker.hue300,
                                    },
                                    "&.MuiRadio-root":{
                                        padding: "0px 9px"
                                    }
                                    }} />} label={view.label} />
                                </CustomMenuItemActive>
                            )
                        }else{
                            return (
                                <CustomMenuItem view key={i}  value={view.value} name={'view'}>
                                    <FormControlLabel control={<Radio disableRipple checked={views.current === view.value} sx={{
                                        color: colors.blue.darker.hue300,
                                        '&.Mui-checked': {
                                            color: colors.blue.darker.hue300,
                                          },
                                      '& .MuiSvgIcon-root': {
                                        fontSize: 18,
                                      },
                                      "&.MuiRadio-root":{
                                        padding: "0px 9px"
                                      }
                                    }} />} label={view.label} />
                                </CustomMenuItem>
                            )
                        }
                    })
                    :null
                }
                {(perPageOptions && perPageOptions.length > 0)?<CustomDivider/>:null}
                {
                    perPageOptions && perPageOptions.length > 0?
                    perPageOptions.map((option, i)=>{
                        if (currentPerPage === option) {
                            return <CustomMenuItemActive key={i} value={option} name={'perPageOptions'}>{option}</CustomMenuItemActive>    
                        }
                        return <CustomMenuItem key={i} value={option} name={'perPageOptions'}>{option}</CustomMenuItem>
                    })
                    :null
                }
            </CustomSelect>
        </FormControl>
    );
}