import React from 'react';
import { Grid } from '@mui/material';
import Typography from '../../../../ui/typography/Typography';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader'
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../../CardCustom';

import * as moment from 'moment';

import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';


import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//ICONES Twitter
import CachedIcon from '@mui/icons-material/Cached';
import FavoriteIcon from '@mui/icons-material/Favorite';


const FeedItem = styled(Grid)`
    margin-bottom:30px;
    img{
        width:100%;
    }
`

const SeeMore = styled(Typography)`
    color:${colors.blue.darker.hue300};
    text-align: center;
    cursor: pointer;
`

const SocialCounter = styled(Grid)`
    color:${colors.blue.lighter.hue150}
`

const PostDate = styled(Typography)`
    float:right;
    color:#0273A5;
`
export default class CardFacebook extends React.Component{
    constructor(props){
        super(props)
        this.state={
            numberOfItems : 3
        }         

        this.twitterIcons={
            Retweet : <CachedIcon/>,
            Fav     : <FavoriteIcon/>
    
    
        }   
    }

    handleClick() {
        this.setState({numberOfItems: this.state.numberOfItems + 3??0})
    }


    render() {
        /*const asset   = this.props.asset;
        const goTo      = this.props.routeAsset;
        let values = [];
        let image= '';
        if(this.props.locales){

            for (let locale of this.props.locales) { 
                values.push({
                    code: locale.node.code,
                    name: this.props.allState[locale.node.code]?.asset_store_name ?? null,
                    description: this.props.allState[locale.node.code]?.asset_store_description ?? null,
                    images: this.props.allState[locale.node.code]?.asset_store_image ?? null,
                    ean: this.props.allState[locale.node.code]?.asset_ean ?? null,
                });
            }
        }*/

        let posts=this.props.data;
  
        return (
            <>
                {posts ? posts.slice(0, this.state.numberOfItems).map((post,index)=>
                    <FeedItem item xs={4} key={`post-${index}`}>
                        <CardCustom contentpadding={"0px 0px 20px 0px"}>
                                    <CardHeader
                                    action={
                                    <IconButton aria-label="settings" onClick={(event)=>{this.props.handleSettings(event,post)}}>
                                        <MoreVertIcon style={{color:'#0273A5'}}/>
                                    </IconButton>
                                    }

                                />
                                <Grid container>
                                     {post.entities.media ?
                                        <Grid item xs={12}>
                                            <img src={post.entities.media[0].media_url}/>
                                        </Grid>                                     
                                    :null
                                    }

                                    <Grid item xs={12} style={{padding:20,backgroundColor:colors.grey.lighter.hue980}}>
                                        <Typography variant="body2">
                                            {post.full_text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{padding:'0  20px'}}>
                                        <Grid container space={1} style={{marginTop:30}} alignItems="center">
                                           
                                            <SocialCounter item xs={10}>
                                                <Grid container alignItems="center" direction="row">    
                                                    {post.favorite_count>0 ?
                                                    <Grid item xs={1}>
                                                        <Tooltip arrow title={post.favorite_count} >
                                                            {this.twitterIcons.Fav}
                                                        </Tooltip>  
                                                    </Grid>                                                   
                                                    :null
                                                    }      
                                                    {post.retweet_count>0 ?
                                                    <Grid item xs={1}>
                                                        <Tooltip arrow title={post.retweet_count} >
                                                            {this.twitterIcons.Retweet}
                                                        </Tooltip>   
                                                    </Grid>                                                  
                                                    :null
                                                    }      
                                                    {post.retweet_count === 0 && post.favorite_count === 0 ?
                                                    <Grid item xs={12}>
                                                        <Typography variant="body2">Aucune réaction sur ce post</Typography>
                                                    </Grid>
                                                    :null}                 

                
                                                </Grid>

                                                
                                                                                                                
                                            </SocialCounter>                                                            
                                            
                                            


                                            <Grid item style={{flexGrow:'1'}}>
                                                <PostDate variant="body2">{moment(post.created_at).format('L')}</PostDate>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    


                                    
                                </Grid>
                        </CardCustom>
                    </FeedItem>                                    
                )
                : <PageLoader/>}
                {posts?.length > this.state.numberOfItems ?
                    <Grid container justifyContent="center" style={{paddingBottom: 24}}>
                        <SeeMore variant="body2" onClick={()=> this.handleClick()}>Voir plus <br/> ...</SeeMore>
                    </Grid>
                    : null 
                }
            </>
        );
    }
}