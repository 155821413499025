import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import StyleIcon from '@mui/icons-material/Style';
import { orange, red, blue } from '@mui/material/colors';
import styled from 'styled-components';

const Div1 = styled.div`
  width: 100%;
  height: 1px;
  position: absolute;
  display: block;
  background: #53839D;
  top: 0;
  left:0;
  z-index: 10;
`;
const Div2 = styled.div`
  width: 100%;
  height: 1px;
  position: absolute;
  display: block;
  background: #53839D;
  bottom: 0;
  left:0;
  z-index: 10;
`;
const Div3 = styled.div`
  width: 1px;
  height: 100%;
  position: absolute;
  display: block;
  background: #53839D;
  top: 0;
  left:0;
  z-index: 10;
`;
const Div4 = styled.div`
  width: 1px;
  height: 100%;
  right:0;
  position: absolute;
  display: block;
  background: #53839D;
  top: 0;
  z-index: 10;
`;
const Name = styled.p`
  color: white!important;
  padding-right: 10px!important;
  font-size: 14px!important;
  z-index: 10!important;
  margin: 0!important;
`;

const useStyles = makeStyles(theme => ({
  root: {
    '&': {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10000,
      height: 30,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#53839D',
      padding: '0px',
      paddingLeft: '10px',
      boxSizing: 'border-box',
      display: 'flex !important',
      verticalAlign: 'inherit!important',
    },
  },
  button: {
    marginRight: '10px'
  }
}));

const EditButton = withStyles(theme => ({
  root: {
    color: '#fff !important',
    borderRadius: '0!important',
    boxShadow: '0',
    backgroundColor: "#447691",
    border: '0',
    width: '30px',
    height: '30px',
    minHeight: '30px',
    padding: '0',
    margin: '0px!important',
    fontSize: '30px',
    lineHeight: '30px',
    '&:hover': {
      backgroundColor: "#3b6982",
    },
  },
}))(Fab);

const DeleteButton = withStyles(theme => ({
  root: {
    color: '#fff !important',
    borderRadius: '0!important',
    boxShadow: '0',
    backgroundColor: `${red[500]}`,
    border: '0',
    width: '30px',
    height: '30px',
    minHeight: '30px',
    padding: '0',
    margin: '0px!important',
    fontSize: '30px',
    lineHeight: '30px',
    '&:hover': {
      backgroundColor: `${red[700]}`,
    }
  },
}))(Fab);

const SnapButton = withStyles(theme => ({
  root: {
    color: '#fff !important',
    borderRadius: '0!important',
    boxShadow: '0',
    backgroundColor: `${blue[500]}`,
    border: '0',
    width: '30px',
    height: '30px',
    minHeight: '30px',
    padding: '0',
    margin: '0px!important',
    fontSize: '30px',
    lineHeight: '30px',
    '&:hover': {
      backgroundColor: `${blue[700]}`,
    }
  },
}))(Fab);

export default function Toolbar({ name = null, onEdit, onEdit2, onEdit3, data, onDelete, onSnap, onStyle, noBorder = false, snapable = false }) {
  const classes = useStyles();

  return (
    <>
      {
        !noBorder ?
          (
            <>
              <Div1 />
              <Div2 />
              <Div3 />
              <Div4 />
            </>
          ) : null
      }
      <div className={classes.root}>
        <Name>{name}</Name>
        {
          onEdit ? (
            <EditButton color="secondary" size="small" aria-label="edit" className={classes.button} onClick={onEdit}>
              <EditIcon />
            </EditButton>
          ) : null
        }
        {
          onEdit2 ? (
            <EditButton color="secondary" size="small" aria-label="edit" className={classes.button} onClick={onEdit2}>
              <EditIcon />
            </EditButton>
          ) : null
        }
        {
          onEdit3 ? (
            <EditButton color="secondary" size="small" aria-label="edit" className={classes.button} onClick={onEdit3}>
              <EditIcon />
            </EditButton>
          ) : null
        }
        {
          onDelete ? (
            <DeleteButton color="secondary" size="small" aria-label="delete" className={classes.button} onClick={onDelete}>
              <DeleteIcon />
            </DeleteButton>
          ) : null
        }

        {
          snapable ? (
            <>
              <SnapButton color="secondary" size="small" aria-label="snap" className={classes.button} onClick={onSnap}>
                <CameraEnhanceIcon />
              </SnapButton>
              <SnapButton color="grey" size="small" aria-label="snap" className={classes.button} onClick={onStyle}>
                <StyleIcon />
              </SnapButton>
            </>
          ) : null
        }
      </div>
    </>

  );
}