import { useState, Fragment, useEffect } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import notFound from '../../../assets/images/not-found.png';
import colors from '../../../config/theme/colors';
import { GET_PRODUCTS_SEARCH_PAGINATION } from '../../../queries/products';
import { SEARCH_CONTENTS } from '../../../queries/contents';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from "react-redux";

const animatedComponents = makeAnimated();

const ColorButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: colors.green.regular,
        '&:hover': {
            backgroundColor: colors.green.darker,
        }
    }
}))(Button);


function SearchBarProductDynamic(props) {
    const [options, setOptions]     = useState([]);
    const [values, setValues]       = useState(props.isMulti ? [] : props.value);
    const [timer, setTimer]         = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            ...props.style,
            width: '100%'
        })
    };

    useEffect(() => {
        if (props.products){
            let options = props.products.map(e => {
                let allNames    = e.attributes.product_name;
                let name        = allNames?.['fr_FR'] ?? 'n/a';
    
                let allImages   = e.attributes.product_image;
                let image       = allImages?.['fr_FR'] ?? null;

                let price = +(e?.attributes?.special_price?.['fr_FR'] || e?.attributes?.product_price?.['fr_FR'] || 0);
                
                return  {
                    item: e,
                    value: e.id,
                    label: name ? `${name} (${e.sku})` : `${e.sku}`,
                    image,
                    price,
                    isProduct: true
                };
    
            });

            setOptions(options)

            if (props.selected)
                setValues(options.find(e => e.item.id === props.selected.id))
        }
    }, [props.products, props.selected])

    const reset = () => {
        setValues(props.isMulti ? [] : null);
    };

    const handleClick = () => {
        props.onSelect(props.isMulti ? values.map(e => e.item) : values.item);
        reset();
    };

    const onChange = (values, action) => {
        setValues(values);

        if (!props.isMulti)
            props.onSelect(values);
    };

    const promiseOptions = (inputValue, callback) => {
        if (timer)
            clearTimeout(timer);

        setTimer(setTimeout(() => {
            setIsLoading(true);

            let productDatas = [];

            if (inputValue) {
                productDatas.push(JSON.stringify({
                    'identifier': 'product_name',
                    'value': inputValue,
                    'locale': props.currentLang,
                    'option': null
                }));
            }

            let variables = {
                itemsPerPage: 30, 
                page: 1
            };

            if (props.catalog) 
                variables.catalog = +props.catalog?.id?.replace('/api/catalogs/', '');
            
            variables.sku = inputValue;

            // if (productDatas.length) 
            //     variables.productDatas = productDatas;

            let query = props.client.query({
                query: GET_PRODUCTS_SEARCH_PAGINATION,
                variables,
                fetchPolicy:'no-cache'
            });

            query.then(result => {
                let products = result.data.researchProducts.edges.map(e => e.node);

                let listProductsFinal = products.map(product => {
                    const datas     = product.productDatas.edges;
                    let allNames    = datas.filter(e => e.node.attribute.identifier === 'product_name');
                    let name        = allNames.find(e => e.node.locale.code === props.currentLang);
    
                    let allImages    = datas.filter(e => e.node.attribute.identifier === 'product_image');
                    let image        = allImages.find(e => e.node.locale.code === props.currentLang);
                    return  {
                        item: product,
                        value: product.id,
                        label: name ? name.node.value : (allNames[0]?.node.value || product.identifier),
                        image: image ? (image.node.media?.filePath ?? '') : allImages[0]?.node.media.filePath ?? null,
                        isProduct: true
                    };
                });

                setIsLoading(false);
                callback(listProductsFinal);
            }).catch(error => {
                setIsLoading(false);
                callback([]);
                console.log(error);
            });
        }, 1000));
    };

    const promiseOptionsContent = inputValue => {
        let nameAttribute = props.attributes.content?.attributes.edges.find(e => e.node.identifier === 'content_title');

        setIsLoading(true);

        return new Promise(resolve => {
            props.client.query({
                query: SEARCH_CONTENTS,
                variables: { 
                    attribute: nameAttribute.node.id,
                    needle: inputValue
                },
                fetchPolicy:'no-cache'
            }).then(result => {
                let contents    = result.data.contentDatas.edges.map(e => e.node.content);
                let listContents = [];
                let listContentsFinal = contents.filter(e => {
                    if(listContents.indexOf(e.sku) === -1){
                        return e
                    }
                    return(listContentsFinal)
                });

                listContentsFinal = listContentsFinal.map(e => {
                    const datas = e.contentDatas.edges;
                    let allNames    = datas.filter(e => e.node.attribute.identifier === 'content_title');
                    let name        = allNames.find(e => e.node.locale.code === props.currentLang);
    
                    let allImages    = datas.filter(e => e.node.attribute.identifier === 'content_image');
                    let image        = allImages.find(e => e.node.locale.code === props.currentLang);
                    return  {
                        item: e,
                        value: e.id,
                        label: name ? name.node.value : (allNames[0].node.value || e.identifier),
                        image: image ? (image.node.media?.filePath ?? '') : allImages[0]?.node.media.filePath ?? null,
                        isProduct: true
                    };
                });

                setIsLoading(false);

                resolve(listContentsFinal);
            }).catch(error => {
                setIsLoading(false);
                resolve([]);
                console.log(error);
            });
        });
    };

    const Option = ({ innerProps, isDisabled, isSelected, data }) => {
        return (
            <div {...innerProps} style={{
                padding: 10,
                backgroundColor: isSelected ? 'rgb(236, 255, 220)' : '#fff',
                cursor: isSelected ? 'default' : 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginBottom: 2
            }}>
                <div style={{
                    width: 40,
                    marginLeft: 10,
                    marginRight: 10,
                    display: 'inline-flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img 
                        src={data.image ? `${process.env.REACT_APP_MEDIAS}/${data.image}` : notFound} alt={data.label}
                        style={{
                            height: 20,
                            maxWidth: '100%'
                        }}
                    />
                </div>
                <div style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <span>{ data.label } {  data.price && props.displayPrices && <strong> / { data.price } €</strong> }</span>
                </div>
                {
                    props.isMulti ? (
                        <div style={{
                            display: 'inline-block',
                            backgroundColor: 'rgb(41, 53, 130)',
                            borderRadius: 10,
                            color: '#fff',
                            marginLeft: 5,
                            fontSize: 10,
                            padding: '0 5px'
                        }}>
                            { !props.static && <span>{ data.isProduct ? 'produit' : `catégorie (${data.item.node.products.edges.length})` }</span> }
                        </div>
                    ) : null
                }
            </div>
        );
    };

    const Menu = props => {
        return (
            <Fragment>
                <components.Menu {...props} style={{position: 'relative'}}>
                    { props.children }

                    {
                        (props.isMulti ? (values?.length ?? 0) > 0 : false) ? (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                padding: 10,
                                position: 'absolute',
                                top: 0,
                                right: 0
                            }}>
                                <ColorButton
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    startIcon={<DoneIcon />}
                                    onClick={handleClick}
                                >
                                    Valider la sélection
                                </ColorButton>
                            </div>
                        ) 
                        : null
                    }
                </components.Menu>
            </Fragment>
        );
    };

    if (props.static) {
        return (
            <Select 
                options={options} 
                components={{ ...animatedComponents, Option, Menu }}
                closeMenuOnSelect={props.isMulti ? false : true}
                isMulti={props.isMulti}
                hideSelectedOptions={props.isMulti ? false : true}
                onChange={onChange}
                styles={customStyles}
                isDisabled={isLoading}
                placeholder={props.isMulti ? "Ajouter..." : "Choisir..."}
                noOptionsMessage={() => "Aucun résultat"}
                loadingMessage={() => "Chargement..."}
                value={values}
            />
        );
    } else {
        return (
            <AsyncSelect
                closeMenuOnSelect={props.isMulti ? false : true}
                hideSelectedOptions={props.isMulti ? false : true}
                value={values}
                isMulti={props.isMulti}
                onChange={onChange}
                placeholder={props.isMulti ? "Ajouter..." : "Choisir..."}
                noOptionsMessage={() => "Aucun résultat"}
                loadingMessage={() => "Chargement..."}
                components={{ ...animatedComponents, Option, Menu }}
                styles={customStyles}
                // isDisabled={isLoading}
                defaultOptions
                loadOptions={props.isContent ? promiseOptionsContent : promiseOptions}
            />
        );
    }
}


const mapStateToProps = state => {
    return {
        attributes: state.attributes
    };
};

export default withApollo(connect(mapStateToProps)(SearchBarProductDynamic));