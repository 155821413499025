import PropTypes from "prop-types";
import React from "react";
import Link from 'next/link';
import { useRouter } from 'next/router';

const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const NavMenu = ({ menuWhiteClass, sidebarMenu, menu, categories, spread = false }) => {
    const router = useRouter();

    const handleClick = (e, href) => {
        e.preventDefault();

        if (href.indexOf('http') > -1)
            return window.open(href, '_blank');

        if (router)
            router.push(href);
    };

    return (
        <div
        className={` ${
            sidebarMenu
            ? "sidebar-menu"
            : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
        } `}
        >
        <nav>
            <ul>
            { menu.map((item, i) => {
                if (item.type === 'link') {
                    return item.value === '/' || item.value === '/contact' ? (
                        <li key={i}>
                            <LinkCustom spread={spread} href={item.value}>
                                <a href={item.value} onClick={(e) => {
                                    if (spread)
                                        e.preventDefault();
                                }}>
                                    { item.name }
                                </a>
                            </LinkCustom>
                        </li>
                    ) : (
                        <li key={i}>
                            <a href={item.value} target="_blank" rel="noreferrer">
                                { item.name }
                            </a>
                        </li>
                    );
                } else {
                    if (!item.value) 
                        return null;

                    const getName = (category) => {
                        return category.attributes['category_name']?.['fr_FR'] ?? category.libelle;
                    };

                    let id = item.value.replace('/api/categories/', '');
                    let category = categories.find(e => e.id.toString() === id);

                    if (!category)
                        return null;

                    let children = categories
                        .filter(e => e.parent && e.parent === category.id)
                        .map(e => ({
                            ...e,
                            name: getName(e)
                        }));

                    for (let child of children) {
                        child.children = categories
                        .filter(e => e.parent && e.parent === child.id)
                        .map(e => ({
                            ...e,
                            name: getName(e)
                        }));
                    }
                    
                    return (
                        <li key={i}>
                            <LinkCustom spread={spread} href="/category/[slug]" as={`/category/${item.value.replace('/api/categories/', '')}`}>
                                <a>
                                    { item.name }
                                    { children && children.length ? <i className="fa fa-angle-down" /> : null }
                                </a>
                            </LinkCustom>
                            
                            {
                                children && children.length ? (
                                    <ul className="mega-menu mega-menu-padding">
                                        { 
                                            children.map((e, i) => {
                                                return (
                                                    <li key={i}>
                                                        <ul>
                                                        {
                                                            e.children.length > 0 ? (
                                                            <>
                                                                <li className="mega-menu-title">
                                                                    <LinkCustom spread={spread} href="/category/[slug]" as={`/category/${e.id}`}>
                                                                        <a>
                                                                            { e.name.length > 15 ? e.name.substring(0, 15) + '...' : e.name }
                                                                        </a>
                                                                    </LinkCustom>
                                                                </li>
                                                                {
                                                                e.children.map((f, j) => { 
                                                                    return (
                                                                        <li key={j}>
                                                                            <LinkCustom spread={spread} href="/category/[slug]" as={`/category/${f.id}`}>
                                                                                <a>
                                                                                    { f.name.length > 15 ? f.name.substring(0, 15) + '...' : f.name }
                                                                                </a>
                                                                            </LinkCustom>
                                                                        </li>
                                                                    );
                                                                })
                                                                }
                                                            </>
                                                            ) : (
                                                                <li>
                                                                    <LinkCustom spread={spread} href="/category/[slug]" as={`/category/${e.id}`}>
                                                                        <a>
                                                                            { e.name.length > 20 ? e.name.substring(0, 20) + '...' : e.name }
                                                                        </a>
                                                                    </LinkCustom>
                                                                </li>
                                                            )
                                                        }
                                                        </ul>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                            
                        </li>
                    );
                }
            })}
            {/* <li>
                <a href={process.env.PUBLIC_URL + "/"}>
                Home
                {sidebarMenu ? (
                    <span>
                    <i className="fa fa-angle-right"></i>
                    </span>
                ) : (
                    <i className="fa fa-angle-down" />
                )}
                </a>
                <ul className="mega-menu mega-menu-padding">
                <li>
                    <ul>
                    <li className="mega-menu-title">
                        <a href={process.env.PUBLIC_URL + "/"}>
                        Home 1
                        </a>
                    </li>
                    <li>
                        <a href={process.env.PUBLIC_URL + "/home-fashion"}>
                        Home 1
                        </a>
                    </li>
                    </ul>
                </li>
                <li>
                    <ul>
                    <li className="mega-menu-title">
                        <a href={process.env.PUBLIC_URL + "/"}>
                        Home 2
                        </a>
                    </li>
                    <li>
                        <a href={process.env.PUBLIC_URL + "/home-furniture-two"}>
                        Home 2
                        </a>
                    </li>
                    </ul>
                </li>
                <li>
                    <ul>
                    <li className="mega-menu-title">
                        <a href={process.env.PUBLIC_URL + "/"}>
                        Home 3
                        </a>
                    </li>
                    <li>
                        <a href={process.env.PUBLIC_URL + "/home-grid-banner"}>
                        Home 3
                        </a>
                    </li>
                    </ul>
                </li>
                </ul>
            </li>
            <li>
                <a href={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                {" "}
                Shop
                {sidebarMenu ? (
                    <span>
                    <i className="fa fa-angle-right"></i>
                    </span>
                ) : (
                    <i className="fa fa-angle-down" />
                )}
                </a>
                <ul className="mega-menu">
                <li>
                    <ul>
                    <li className="mega-menu-title">
                        <a href={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                        Shop 1
                        </a>
                    </li>
                    <li>
                        <a href={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                        Shop 1
                        </a>
                    </li>
                    </ul>
                </li>
                <li>
                    <ul>
                    <li className="mega-menu-title">
                        <a href={process.env.PUBLIC_URL + "/product/1"}>
                        Shop 2
                        </a>
                    </li>
                    <li>
                        <a href={process.env.PUBLIC_URL + "/product/1"}>
                        Shop 2
                        </a>
                    </li>
                    </ul>
                </li>
                <li>
                    <ul>
                    <li className="mega-menu-img">
                        <a href={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                        <img
                            src={"http://via.placeholder.com/201x366/DDDDDD/BBBBBB?text=201x366"}
                            alt=""
                        />
                        </a>
                    </li>
                    </ul>
                </li>
                </ul>
            </li>
            <li>
                <a href={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                Collection
                </a>
            </li>
            <li>
                <a href={process.env.PUBLIC_URL + "/"}>
                Divers
                {sidebarMenu ? (
                    <span>
                    <i className="fa fa-angle-right"></i>
                    </span>
                ) : (
                    <i className="fa fa-angle-down" />
                )}
                </a>
                <ul className="submenu">
                <li>
                    <a href={process.env.PUBLIC_URL + "/cart"}>
                    Divers 1
                    </a>
                </li>
                <li>
                    <a href={process.env.PUBLIC_URL + "/checkout"}>
                    Divers 2
                    </a>
                </li>
                <li>
                    <a href={process.env.PUBLIC_URL + "/wishlist"}>
                    Divers 3
                    </a>
                </li>
                </ul>
            </li>
            <li>
                <a href={process.env.PUBLIC_URL + "/contact"}>
                Contact us
                </a>
            </li> */}
            </ul>
        </nav>
        </div>
    );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool
};

export default NavMenu;
