import { FORM } from '../../../../js/constants/form-types';
import Button from '../../button/Button';

export default function mediaPicker(value) {
    return {
        titleForm: "Ajouter un post Twitter",
        subTitleForm: "Veuillez compléter le champ ci-dessous pour insérer votre post Twitter",
        langSelect: true,
        drawerType: 'drawer',
        textButtonValidate: 'Ajouter',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations générales',
            },
            children: [
                {
                    optionsInputs: [
                        {
                            type: 'textareaSimple',
                            label: 'URL',
                            helper: {
                                text: 'Url du post Twitter',
                                link: false,
                            },
                            required: false,
                            stateName: 'twitterURL',
                            value,
                            translated: false
                        },
                    ]
                },
            ]
        }
    };
}