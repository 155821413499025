import { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import CardHomepageStats from '../../../layouts/Card/cardContent/CardHomepageStats';
import CardActions from '../../../layouts/Card/cardContent/CardActions';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import request from '../../../../js/utils/fetch';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import SliderActu from './components/SliderActu';
import { withApollo } from '@apollo/client/react/hoc';
import Image1 from '../../../../../src/assets/images/ecran.png';
import BannerVideo from '../../../../../src/assets/images/image-home-dxp.png';
import BannerVideoXL from '../../../../../src/assets/images/image-home-xl.png';
import LineCharts from '../../../layouts/Stats/LineCharts';
import PieCharts from '../../../layouts/Stats/PieCharts';
import { eventService } from '../../../../js/services/event.service';
import { withTranslation } from 'react-i18next';
import logoSinfin from '../../../../../src/assets/images/logotype-home.svg';
import {
    ROUTE_PRODUCTS_LIST,
    ROUTE_STATS_ORDERS,
    ROUTE_CATALOGS_LIST,
    ROUTE_MEDIAS_LIST,
    ROUTE_CHANNELS,
    ROUTE_SYSTEM_LANGUAGES,
    ROUTE_CONTENU_LIST,
    ROUTE_SETTINGS_GUIDELINE,
    ROUTE_SALES_ESHOP,
} from '../../../../js/constants/route-names';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LoyaltySharpIcon from '@mui/icons-material/LoyaltySharp';
import StyleSharpIcon from '@mui/icons-material/StyleSharp';
import InsertPhotoSharpIcon from '@mui/icons-material/InsertPhotoSharp';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ColorLensSharpIcon from '@mui/icons-material/ColorLensSharp';
import SettingsInputComponentSharpIcon from '@mui/icons-material/SettingsInputComponentSharp';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import DevicesSharpIcon from '@mui/icons-material/DevicesSharp';

const notifs = [
    {
        title: 'Sinfin™ se refait une beauté !',
        text: 'homepage.notification.lasttext',
        id: uuidv4()
    },
    {
        title: 'Flux de travail',
        text: 'homepage.notification.secondtext',
        id: uuidv4()
    },
];

const sliderData = [
    {
        title: 'homepage.notification.slide-un.title',
        text: <><p>Analysez les données commerciales propre à vos points de vente dans des espaces dédiés et modulables.</p><p>Rendez-vous sur votre dashboard pour retrouver l’ensemble de vos données en temps réel</p></>,
        image: Image1,
        id: uuidv4()
    },
];

const ContainerBlocNotification = styled(Box)`
    border-radius: 10px;
    position: relative;
    margin-bottom: 14px;
`;
const ContainerNotification = styled(Box)`
    background: #F5F5F580;
    border-radius: 2px;
    position: relative;
`;
const RemoveNotification = styled(Button)`
    background: transparent;
    border-radius: 10px;
`;

const Homepage = ({ history, windowWidth, user, t }) => {
    const [notifications, setNotifications] = useState(notifs);
    const [data, setData] = useState(null);
    const [totalOrder, setTotalOrder] = useState(null);
    const [date, setDate] = useState(null);
    const [allDatas, setAllDatas] = useState(null);
    const [selectDataPie, setSelectDataPie] = useState(null);
    const [selectedDataPie, setSelectedDataPie] = useState(null);
    const [actions, setActions] = useState([]);
    const [statsHomepage, setStatsHomepage] = useState([
        {
            'icon': <WidgetsIcon style={{ fontSize: windowWidth <= 1500 ? 19 : 19 }}></WidgetsIcon>,
            'data': '-',
            'dataColor': colors.black.regular,
            'dataCustomFont': '16px',
            'subtitle': 'homepage.stats.category',
            'subtitleCustomFont': '16px',
            'subtitleColor': colors.black.regular,
            'gradientColor1': colors.blue.darker.hue300,
            'gradientColor2': colors.black.regular,
        },
        process.env.REACT_APP_MODE_SPREAD !== "hub" ?
            {
                'icon': <StyleSharpIcon style={{ fontSize: windowWidth <= 1500 ? 19 : 19 }}></StyleSharpIcon>,
                'data': '-',
                'dataColor': colors.black.regular,
                'dataCustomFont': '16px',
                'subtitle': 'homepage.stats.asset',
                'subtitleCustomFont': '16px',
                'subtitleColor': colors.black.regular,
                'gradientColor1': colors.blue.darker.hue300,
                'gradientColor2': colors.black.regular,
            } : null,
        {
            'icon': <LoyaltySharpIcon style={{ fontSize: windowWidth <= 1500 ? 19 : 19 }}></LoyaltySharpIcon>,
            'data': '-',
            'dataColor': colors.black.regular,
            'dataCustomFont': '16px',
            'subtitle': 'homepage.stats.product',
            'subtitleColor': colors.black.regular,
            'subtitleCustomFont': '16px',
            'gradientColor1': colors.blue.darker.hue300,
            'gradientColor2': colors.black.regular,
        },
        // process.env.REACT_APP_MODE_SPREAD !== "hub" ?
        //     {
        //         'icon': 'icon-campagne',
        //         'data': '-',
        //         'dataColor': colors.black.regular,
        //         'dataCustomFont': '16px',
        //         'subtitle': 'homepage.stats.campagne',
        //         'subtitleColor': colors.black.regular,
        //         'subtitleCustomFont': '16px',
        //         'gradientColor1': colors.blue.darker.hue300,
        //         'gradientColor2': colors.black.regular,
        //     } : null,
    ]);

    useEffect(() => {
        initStats();
    }, []);

    const initStats = async () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/homepage`, 'get', false).then(
            (data) => {
                if (data.success) {
                    let getStats = statsHomepage
                    if (getStats[0]) {
                        getStats[0].data = data.datas.nbCategories;
                    }
                    if (getStats[1]) {
                        getStats[1].data = data.datas.nbAssetEnable;
                    }
                    if (getStats[2]) {
                        getStats[2].data = data.datas.nbProducts;
                    }
                    if (getStats[3]) {
                        getStats[3].data = data.datas.nbCampaignEnable;
                    }
                    if (data.datas.nbCategories > 1 && getStats[0]) {
                        getStats[0].subtitle = 'homepage.stats.category';
                    }
                    if (data.datas.nbAssetEnable > 1 && getStats[1]) {
                        getStats[1].subtitle = 'homepage.stats.asset';
                    }
                    if (data.datas.nbProducts > 1 && getStats[2]) {
                        getStats[2].subtitle = 'homepage.stats.product';
                    }
                    // if (data.datas.nbCampaignEnable > 1 && getStats[3]) {
                    //     getStats[3].subtitle = 'homepage.stats.campagne';
                    // }

                    setStatsHomepage([...getStats]);
                }
            }
        ).catch((err)=>{
            console.log(err)
        })

        let actions = [];

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': 'ico-charts',
                'title': 'homepage.cards.statisticTitle',
                'subtitle': 'homepage.cards.statisticSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.white,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_STATS_ORDERS,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            })
        }

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': 'ico_eshop',
                'title': 'homepage.cards.eshopTitle',
                'subtitle': 'homepage.cards.eshopSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.white,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_SALES_ESHOP,
                // 'routeState': {openForm: true, buttonAvailable: true}
            })
        }

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': <LoyaltySharpIcon style={{ fontSize: windowWidth <= 1500 ? "16px" : "25px" }}></LoyaltySharpIcon>,
                'title': 'homepage.cards.engineTitle',
                'subtitle': 'homepage.cards.engineSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.black.regular,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_CATALOGS_LIST,
                // 'routeState': {openForm: true, buttonAvailable: true}
            });
        }

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': <StyleSharpIcon style={{ fontSize: windowWidth <= 1500 ? "16px" : "25px" }}></StyleSharpIcon>,
                'title': 'homepage.cards.produitTitle',
                'subtitle': 'homepage.cards.produitSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.black.regular,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_PRODUCTS_LIST,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            });
        }

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': <InsertPhotoSharpIcon style={{ fontSize: windowWidth <= 1500 ? "16px" : "25px" }}></InsertPhotoSharpIcon>,
                'title': 'homepage.cards.mediaTitle',
                'subtitle': 'homepage.cards.mediaSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.black.regular,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_MEDIAS_LIST,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            });
        }
        actions.push({
            'icon': <DescriptionSharpIcon style={{ fontSize: '2vh' }}></DescriptionSharpIcon>,
            'title': 'homepage.cards.contentTitle',
            'subtitle': 'homepage.cards.contentSubtitle',
            'gradientColor1': colors.white,
            'gradientColor2': colors.white,
            'colorPicto': colors.black.regular,
            'color': colors.black.regular,
            'colorHover': colors.black.regular,
            'route': ROUTE_CONTENU_LIST,
            // 'routeState': {openForm: true, buttonAvailable: true}
        });

        actions.push({
            'icon': <DevicesSharpIcon style={{ fontSize: '2vh' }}></DevicesSharpIcon>,
            'title': 'homepage.cards.canalTitle',
            'subtitle': 'homepage.cards.canalSubtitle',
            'gradientColor1': colors.white,
            'gradientColor2': colors.white,
            'colorPicto': colors.black.regular,
            'color': colors.black.regular,
            'colorHover': colors.black.regular,
            'route': ROUTE_CHANNELS,
            // 'routeState': {openMediaForm: true, buttonAvailable: true}
        });

        actions.push({
            'icon': <ColorLensSharpIcon style={{ fontSize: windowWidth <= 1500 ? "16px" : "25px" }}></ColorLensSharpIcon>,
            'title': 'homepage.cards.guideTitle',
            'subtitle': 'homepage.cards.guideSubtitle',
            'gradientColor1': colors.white,
            'gradientColor2': colors.white,
            'colorPicto': colors.black.regular,
            'color': colors.black.regular,
            'colorHover': colors.black.regular,
            'route': ROUTE_SETTINGS_GUIDELINE,
            // 'routeState': {openForm: true, buttonAvailable: true}
        });

        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            actions.push({
                'icon': <SettingsInputComponentSharpIcon style={{ fontSize: windowWidth <= 1500 ? "16px" : "25px" }}></SettingsInputComponentSharpIcon>,
                'title': 'homepage.cards.settingTitle',
                'subtitle': 'homepage.cards.settingSubtitle',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.black.regular,
                'color': colors.black.regular,
                'colorHover': colors.black.regular,
                'route': ROUTE_SYSTEM_LANGUAGES,
                // 'routeState': {openForm: true, buttonAvailable: true}
            });
        }

        if (process.env.REACT_APP_MODE_SPREAD === 'hub') {
            await request(`${process.env.REACT_APP_API}/hubStats`, 'post').then(
                (data) => {
                    if (data.success) {
                        let date = [];
                        let orderByDate = [];
                        let ordersByStatus = [];
                        let totalOrder = 0;

                        for (let item in data.ordersByDate) {
                            date.push(item)
                            orderByDate.push(data.ordersByDate[item]);
                            totalOrder += data.ordersByDate[item];
                        }

                        for (let item in data.ordersByStatus) {
                            let name = '';
                            switch (item) {
                                case 'delayed':
                                    name = 'En retard';
                                    break;
                                case 'pending':
                                    name = 'En attente';
                                    break;
                                case 'preorder':
                                    name = 'Pré-commande';
                                    break;
                                case 'preparation':
                                    name = 'En cours de préparation';
                                    break;
                                case 'returned':
                                    name = 'Retour';
                                    break;
                                case 'shipped':
                                    name = 'Expédiée'
                                    break;
                                default: return null;
                            }

                            ordersByStatus.push({ name: name, value: data.ordersByStatus[item] })
                        }

                        setData(data);
                        setTotalOrder(totalOrder);
                        setDate(date);

                        setAllDatas([{
                            data: orderByDate,
                            color: '#147FAB',
                            title: 'Commande/jour',
                        }]);

                        setSelectDataPie({
                            type: 'select',
                            isContainerized: true,
                            required: false,
                            stateName: 'selectedDataPie',
                            label: '',
                            helper: '',
                            value:
                                [
                                    {
                                        value: ordersByStatus,
                                        label: 'Commandes'
                                    },
                                    {
                                        value: [
                                            { value: data.fullStock, name: 'Stock actuel' },
                                            { value: data.fullReassort, name: 'Stock à venir' },
                                        ],
                                        label: 'Stock'
                                    }
                                ]
                        });

                        setSelectedDataPie({
                            value: ordersByStatus,
                            label: 'Commandes'
                        });
                    }
                }
            ).catch((err)=>{
                console.log(err)
            });
        }

        setActions(actions);
    };

    const removeNotification = (id) => {
        const newState = notifications;
        const index = newState.findIndex(a => a.id === id);

        if (index === -1) return;
        newState.splice(index, 1);

        setNotifications([...newState]);
    };

    const handleSelectPie = (event) => {
        setSelectedDataPie(event.target.value);
        eventService.fire();
    };

    const goToAction = (route) => {
        history.push(route);
    };

    return (
        <div>
            <Grid container direction="column" justifyContent="center" spacing={0}>
                <Grid container direction="row">
                    <Grid item xs={12} md={8} style={{ maxHeight: windowWidth <= 1500 ? null /* 'calc(100vh - 48px)' */ : 'calc(100vh - 64px)', overflow: 'auto', paddingBottom: windowWidth > 1250 ? 0 : 14, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingRight: windowWidth > 1250 ? 7 : 0 }}>
                        <CardCustom paddingbottom={windowWidth > 1100 ? "0px" : 16} contentpadding={'0px'} style={{ border: `0.5px solid ${colors.grey.lighter.hue700}`, minHeight: 111 }}>
                            <CardHomepageStats statsHomepage={statsHomepage} user={user} windowWidth={windowWidth} />
                        </CardCustom>
                        <Box mt={windowWidth <= 1500 ? 1.5 : '14px'} style={{ flex: 1 }}>
                            <Grid container direction="row" spacing={1} style={{ height: "100%" }}>
                                {
                                    actions ? (actions.map((action, index) =>
                                        action ?
                                            (
                                                <Grid item md={4} xs={6} key={`CardActions${index}`} style={{ marginRight: 0 }}>
                                                    <CardCustom style={{ height: '100%' }} contentpadding={'0px 0px 0px 0px'} hoverbordercolor={colors.grey.border}>
                                                        <CardActions action={action} goTo={goToAction} t={t} windowWidth={windowWidth}></CardActions>
                                                    </CardCustom>
                                                </Grid>
                                            ) : null
                                    )) : null
                                }
                            </Grid>
                            {
                                (date && allDatas) || selectedDataPie && selectDataPie ?
                                    <Grid container direction="row" spacing={2}>
                                        {
                                            date && allDatas ? (
                                                <Grid item lg={6} xs={12} >
                                                    <Box mt={1} mb={2}>
                                                        <CardCustom paddingtop={'48px'}>
                                                            <LineCharts xAxisData={date} yAxisData={allDatas} handleSelectPie={handleSelectPie} />
                                                        </CardCustom>
                                                    </Box>
                                                </Grid>
                                            ) : null
                                        }
                                        {
                                            selectedDataPie && selectDataPie ? (
                                                <Grid item lg={6} xs={12}>
                                                    <Box mt={1} mb={2}>
                                                        <CardCustom paddingtop={'48px'} style={{ position: 'relative' }}>
                                                            <PieCharts selectedDataPie={selectedDataPie} selectDataPie={selectDataPie} handleSelectPie={handleSelectPie} />
                                                        </CardCustom>
                                                    </Box>
                                                </Grid>
                                            ) : null
                                        }
                                    </Grid>
                                    : null
                            }

                            {data && totalOrder ? (
                                <Grid container direction="row" justifyContent="center" spacing={4}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justifyContent="center" spacing={0}>
                                            <Grid item xs={4}>
                                                <CardCustom style={{ background: colors.black.regular, borderRadius: '5px 0 0 5px', borderRight: '1px solid white' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px' }}>
                                                    <Typography variant="h3" style={{ color: 'white', fontSize: 14, lineHeight: '20px' }}>Total commande</Typography>
                                                    <Typography variant="body1" style={{ color: 'white', fontSize: 36, lineHeight: '36px' }}>
                                                        <ShoppingCartOutlinedIcon /> {totalOrder}
                                                    </Typography>
                                                </CardCustom>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <CardCustom style={{ background: colors.black.regular, borderRadius: '0', borderRight: '1px solid white' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px' }}>
                                                    <Typography variant="h3" style={{ color: 'white', fontSize: 14, lineHeight: '20px' }}>Total stock</Typography>
                                                    <Typography variant="body1" style={{ color: 'white', fontSize: 36, lineHeight: '36px' }}>
                                                        <EqualizerIcon accentHeight={300} additive /> {data?.fullStock ? data.fullStock : null}
                                                    </Typography>
                                                </CardCustom>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <CardCustom style={{ background: colors.black.regular, borderRadius: '0 5px 5px 0' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px' }}>
                                                    <Typography variant="h3" style={{ color: 'white', fontSize: 14, lineHeight: '20px' }}>Total réassort</Typography>
                                                    <Typography variant="body1" style={{ color: 'white', fontSize: 36, lineHeight: '36px' }}>
                                                        <PlayForWorkIcon /> {data?.fullReassort ? data.fullReassort : null}
                                                    </Typography>
                                                </CardCustom>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </Box>
                        <Box mt={windowWidth <= 1500 ? 1.5 : '14px'}>
                            <a target={'_blank'} href='https://support.sinfin.fr/' style={{ display: 'flex' }} rel="noreferrer">
                                {
                                    process.env.REACT_APP_MODE_SPREAD !== "hub" ? (
                                        <img src={windowWidth > 1700 ? BannerVideoXL : BannerVideo} style={{ width: "100%" }} />
                                    ) : null
                                }
                            </a>
                        </Box>
                    </Grid>
                    {/* Right */}
                    <Grid item xs={12} md={4} style={{ paddingLeft: windowWidth > 1250 ? 7 : 0 }}>
                        <CardCustom cardContentStyle={{ display: 'flex', flexDirection: 'column' }} style={{ maxHeight: windowWidth <= 1500 ? null : 'calc(100vh - 63px)', overflow: 'auto', height: "100%" }}>
                            <Box m={'32px'} display="flex" justifyContent="center" flex={1}>
                                <img src={logoSinfin} height={"auto"} width={'285px'} alt="logo-dxp" />
                            </Box>
                            <Box mb={'5px'}>
                                <Grid container justifyContent='space-between' alignItems='baseline'>
                                    <Typography variant="h3" colortext={colors.black.regular}>Notifications</Typography>
                                </Grid>
                            </Box>
                            <ContainerBlocNotification>
                                {
                                    notifications.map((notification, index) => {
                                        return (
                                            <ContainerNotification p={2} mt={'8px'} pb={3} key={`Notification${index}`}>
                                                <Typography
                                                    style={{ fontWeight: 'bold', fontSize: 12, lineHeight: 'inherit' }}
                                                    colortext={colors.black.regular}
                                                >{notification.title}</Typography>
                                                <Box mt={1}>
                                                    <Typography variant={"body2"} style={{ fontWeight: 'normal', fontSize: windowWidth < 1400 ? 12 : 13, color: "#AAB4BA" }}>{t(notification.text)}
                                                    </Typography>
                                                </Box>
                                                {/* <RemoveNotification
                                                    bgcolor={"transparent"}
                                                    bgcolorhover={"transparent"}
                                                    color={colors.black.regular}
                                                    fontsize={16}
                                                    style={{
                                                        padding: '0px 10px',
                                                        position: 'absolute',
                                                        right: '1rem',
                                                        minWidth: 'auto',
                                                    }}
                                                    onClick={() => removeNotification(notification.id)}
                                                >x</RemoveNotification> */}
                                            </ContainerNotification>
                                        )
                                    })
                                }
                            </ContainerBlocNotification>
                            <Box mt={1} mb={'5px'}>
                                <Typography variant="h3" colortext={colors.black.regular}>{t('homepage.notification.news')}</Typography>
                            </Box>
                            <Box mt={1}>
                                <SliderActu sliderData={sliderData} />
                            </Box>
                        </CardCustom>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps)(Homepage))));
