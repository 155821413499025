import React from 'react';
import { makeStyles } from '@mui/styles';
import '../../../shareable/components/assets/book/scss/style.scss';
import HeaderBook from '../../../shareable/components/assets/book/components/HeaderBook/HeaderBook';
import FooterBook from '../../../shareable/components/assets/book/components/FooterBook/FooterBook';
import PostCardBook from '../../../shareable/components/assets/book/components/PostCardBook/PostCardBookOld';
import GiftsList from '../../../shareable/components/assets/book/components/GiftsList/GiftsList';

const useStyles = makeStyles({
    root: {
        width: '100%'
    }
});
export default ({ config, params, getComponent, preview }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} id="builder-template-scope-book">
            <HeaderBook/>
            <GiftsList />
            <PostCardBook fullWidth={false} noButton={true}/>
            <FooterBook />
        </div>
    );
};