import { Grid } from "@mui/material";
import styled from "styled-components";
import colors from "../../../../../config/theme/colors";
import AccordionCustom from "../../../../layouts/Accordion/AccordionCustom";
import InputBuilder from "../../InputBuilder";
import OurTypography from "../../../typography/Typography";
import EditSharpIcon from '@mui/icons-material/EditSharp';

const UserAccessWrapper = styled(Grid)`
    margin-bottom: 0px !important;
`;

const UserAccessGridCustom = styled(Grid)`
    position: relative;

    & > div {
        margin: 0px;
    }

    /* ${props => props.index === 0
        ? `
            &::after{
                content: '';
                background: ${colors.blue.lighter.hue600};
                width: 70%;
                height: 2px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-31%, -50%);
            }
        `
        : null
    } */
`;

const TemporalAccessLayout = styled(Grid)`
    & > div {
        display: flex;
        justify-content: center;
        padding-left: 8px;
    }
`;

const UserAccess = ({allProps: props}) => {

  return (
    <UserAccessWrapper item xs={12} style={{
      marginBottom: 20
    }}>
      <AccordionCustom style={{
        marginBottom: 10
      }} defaultExpanded={false} title={
        <UserAccessGridCustom onClick={evt => evt.stopPropagation} style={{ width: 'max-content' }}>
          <InputBuilder value={
            props?.allState?.userAccess?.access.find((item) => item?.stateName === props.input?.title.stateName)
          } input={props.input.title} stateCallback={(evt, value) => {
            // Event, clicked input, isParent
            props.stateCallback(evt, {
              clickedAccessInput: props.input,
              isParent: true
            })
          }} />
        </UserAccessGridCustom>
      }>
        <Grid container>
          <Grid item xs={12} style={{
            marginBottom: 15
          }}>
            <UserAccessGridCustom container>
              <Grid item xs={8}>
                <OurTypography colortext={colors.blue.darker.hue300} fontsize={14} fontstyle={'italic'} style={{
                  textDecoration: 'underline'
                }}>Sélectionner vos droits</OurTypography>
              </Grid>
              <Grid item xs={4} style={{ paddingLeft: 2 }}>
                <Grid container justifyContent={'center'}>
                  <EditSharpIcon style={{ fontSize: '20px', color: colors.blue.darker.hue300 }} />
                </Grid>
              </Grid>
              {/* <Grid item xs={2} style={{ paddingLeft: 2 }}>
                          <VisibilitySharpIcon style={{ fontSize: '20px', color: colors.blue.darker.hue300 }} />
                      </Grid> */}
            </UserAccessGridCustom>
          </Grid>
          {props.input.children.map((item, index) => {
            return (
              <Grid item xs={12} key={index}>
                <UserAccessGridCustom container>
                  <Grid item xs={8}>
                    <OurTypography colortext={colors.black.lighter} fontsize={14}>{item.title}</OurTypography>
                  </Grid>
                  {item.options.map((checkbox, index) => {
                    const rights = checkbox?.value.map((item) => {
                      return {
                        [item?.value]: item?.isChecked,
                      }
                    })

                    const mergedRights = Object.assign({}, ...rights);

                    return (
                      <UserAccessGridCustom item xs={4} key={index} index={index}>
                        {/* <UserAccessGridCustom item xs={2} key={index} index={index}> */}
                        <TemporalAccessLayout container justifyContent={'center'}>
                          <InputBuilder value={mergedRights} input={checkbox} stateCallback={(evt) => {
                            // Event, clicked input, isParent
                            props.stateCallback(evt, {
                              clickedAccessInput: checkbox,
                              isParent: false
                            })
                          }} />
                        </TemporalAccessLayout>
                      </UserAccessGridCustom>
                    )
                  })}
                </UserAccessGridCustom>
              </Grid>
            )
          })}
        </Grid>
      </AccordionCustom>
    </UserAccessWrapper>
  )
}

export default UserAccess;