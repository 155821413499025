import colors from "../../../../config/theme/colors";

export const deleteBtn = {
  primary: {
    color: colors.red.regular,
    colorhover: colors.white,
    bgcolor: colors.white,
    bgcolorhover: colors.red.lighter,
  },
};

export const validateBtn = {
  primary: {
    color: colors.white,
    colorhover: colors.white,
    bgcolor: colors.green.regular,
    bgcolorhover: colors.green.darker,
  },
};

export const infoBtn = {
  primary: {
    color: colors.white,
    colorhover: colors.blue.lighter.hue300,
    bgcolor: colors.blue.darker.hue300,
  },
};

