import { Grid, Box, CircularProgress } from "@mui/material";
import { BoxCustom } from "./style/StatsRealTime.styled";
import { ArrowDropDown } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CardCustom from "@components/layouts/Card/CardCustom";
import PageLoader from "@components/ui/loadings/page-loader/PageLoader";
import Typography from "@components/ui/typography/Typography";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { SNACK } from "@constants/action-types";
import { spaceOnNumbers } from "@utils/spaceOnNumbers";
import { useEffect, useState } from "react";
import request from "@utils/fetch";
import colors from "@config/theme/colors";
import AccordionCustom from "@components/layouts/Accordion/AccordionCustom";

const realTimeBoxStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      flexWrap: "noWrap",
      flexDirection: "column-reverse",
      gap: "20px",
    },
  },
}));

const RealTime = (props) => {
  const { type } = props;
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getRealTime = () => {
    const date = `${moment().format("dddd DD MMM YYYY [-] HH:mm:ss")}`;
    const formattedDate = date[0].toUpperCase() + date.substring(1);
    return formattedDate;
  };

  const getStats = () => {
    setIsLoading(true);
    const origin =
      type === "byMarketplace" ? ["MP"] : type === "bySite" ? ["B2C"] : null;
    const date = moment().subtract(1, "days").format("yyyy-MM-DD");
    const postData = {
      startDate: date,
      origin: origin,
    };
    if (postData) {
      request(
        `${process.env.REACT_APP_API}/statistics/sales-order`,
        "POST",
        postData
      )
        .then((data) => {
          if (data.success) {
            const today = moment().format("yyyy-MM-DD");
            setStats({
              today: data.stats[today].global,
              yesterday: data.stats[date].global,
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  };

  const getPercentage = (value1, value2) => {
    const percentage = Math.round((value1 * 100) / value2 - 100);
    if (Number.isNaN(percentage)) {
      return "0%";
    }
    return percentage + " %";
  };

  useEffect(() => {
    getStats();
  }, [type]);

  const realTimeBoxClass = realTimeBoxStyles();

  return (
    <Box pb={2}>
      <AccordionCustom
        defaultExpanded={true}
        square={true}
        title={
          <Typography variant={"h4"} style={{ fontSize: 14 }}>
            {props.t("dashboard.realtime")} : <strong>{getRealTime()}</strong>
          </Typography>
        }
      >
        {isLoading ? (
          <Box
            style={{
              position: "relative",
              height: "300px",
              width: "100%",
              color: colors.grey.regular,
            }}
          >
            <PageLoader />
          </Box>
        ) : !error ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            gap={{
              xs: 2,
              md: 0,
            }}
          >
            {/* Chiffres d'affaires */}
            <Grid item lg={4} md={4} xs={12}>
              <Box pr={1} height={"100%"}>
                <CardCustom
                  style={{ width: "100%", height: "100%" }}
                  cardContentStyle={{ height: "100%" }}
                  contentpadding={"16px 24px"}
                >
                  <Grid container className={realTimeBoxClass.root}>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body1">
                        <strong>Chiffre d'affaires (TTC + FP)</strong>
                      </Typography>
                      <Box mt={1} mb={1}>
                        <Typography variant="body2">
                          {" "}
                          <strong>
                            <span style={{ fontSize: 35, lineHeight: "25px" }}>
                              {!props.areStatsError
                                ? spaceOnNumbers(stats?.today.totalAmount) + "€"
                                : "0€"}
                            </span>
                          </strong>
                        </Typography>
                        <Typography variant="body2">
                          {" "}
                          <strong>
                            <span style={{ fontSize: 12, lineHeight: "20px" }}>
                              {spaceOnNumbers(stats?.today.totalSubAmount)}€ HT
                              sans FP
                            </span>
                          </strong>
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems="center">
                        {stats?.today.totalAmount <
                        stats?.yesterday?.totalAmount ? (
                          <ArrowDropDown style={{ fill: colors.red.regular }} />
                        ) : (
                          <ArrowDropUpIcon
                            style={{ fill: colors.green.regular }}
                          />
                        )}
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              stats?.today.totalAmount <
                              stats?.yesterday?.totalAmount
                                ? colors.red.regular
                                : colors.green.regular,
                          }}
                        >
                          J -1{" "}
                          <span>
                            ({spaceOnNumbers(stats?.yesterday?.totalAmount)}€)
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <BoxCustom
                        position="relative"
                        maxHeight={88}
                        display="flex"
                        justifyContent={"center"}
                        statuscolor={
                          stats?.today.totalAmount <
                          stats?.yesterday?.totalAmount
                            ? "negative"
                            : "positive"
                        }
                      >
                        <CircularProgress
                          variant="determinate"
                          thickness={2.5}
                          value={
                            (stats?.today.totalAmount * 100) /
                              stats?.yesterday?.totalAmount -
                            100
                          }
                          style={{
                            maxHeight: 88,
                            maxWidth: 88,
                            width: "100%",
                            height: "auto",
                            position: "absolute",
                            zIndex: 2,
                          }}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={100}
                          style={{
                            maxHeight: 88,
                            maxWidth: 88,
                            width: "100%",
                            height: "auto",
                          }}
                          thickness={2.5}
                        />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{ fontWeight: "bold" }}
                          >
                            {stats?.today.totalAmount === 0
                              ? null
                              : stats?.today.totalAmount <
                                stats?.yesterday?.totalAmount
                              ? ""
                              : "+"}
                            {getPercentage(
                              stats?.today.totalAmount,
                              stats?.yesterday?.totalAmount
                            )}
                          </Typography>
                        </Box>
                      </BoxCustom>
                    </Grid>
                  </Grid>
                </CardCustom>
              </Box>
            </Grid>
            {/* Nombre de commande */}
            <Grid item lg={4} md={4} xs={12}>
              <Box pl={0.5} pr={0.5} height={"100%"}>
                <CardCustom
                  style={{ width: "100%", height: "100%" }}
                  cardContentStyle={{ height: "100%" }}
                  paddingtop={"48px"}
                >
                  <Grid container className={realTimeBoxClass.root}>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body1">
                        <strong>Nombre de commande</strong>
                      </Typography>
                      <Box mt={1} mb={1}>
                        <Typography variant="body2">
                          <strong>
                            <span style={{ fontSize: 35, lineHeight: "25px" }}>
                              {!props.areStatsError
                                ? spaceOnNumbers(stats?.today.orderNumber)
                                : "0"}
                            </span>
                          </strong>
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems="center">
                        {stats?.today.orderNumber <
                        stats?.yesterday.orderNumber ? (
                          <ArrowDropDown style={{ fill: colors.red.regular }} />
                        ) : (
                          <ArrowDropUpIcon
                            style={{ fill: colors.green.regular }}
                          />
                        )}
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              stats?.today.orderNumber <
                              stats?.yesterday.orderNumber
                                ? colors.red.regular
                                : colors.green.regular,
                          }}
                        >
                          J -1{" "}
                          <span>
                            ({spaceOnNumbers(stats?.yesterday.orderNumber)}{" "}
                            commande)
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <BoxCustom
                        position="relative"
                        maxHeight={88}
                        display="flex"
                        justifyContent={"center"}
                        statuscolor={
                          stats?.today.orderNumber <
                          stats?.yesterday.orderNumber
                            ? "negative"
                            : "positive"
                        }
                      >
                        <CircularProgress
                          variant="determinate"
                          thickness={2.5}
                          value={
                            (stats?.today.orderNumber * 100) /
                              stats?.yesterday.orderNumber -
                            100
                          }
                          size={"100%"}
                          style={{
                            maxHeight: 88,
                            maxWidth: 88,
                            width: "100%",
                            height: "auto",
                            position: "absolute",
                            zIndex: 2,
                          }}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={100}
                          style={{
                            maxHeight: 88,
                            maxWidth: 88,
                            width: "100%",
                            height: "auto",
                          }}
                          thickness={2.5}
                        />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{ fontWeight: "bold" }}
                          >
                            {stats?.today.orderNumber === 0
                              ? null
                              : stats?.today.orderNumber <
                                stats?.yesterday.orderNumber
                              ? ""
                              : "+"}
                            {getPercentage(
                              stats?.today.orderNumber,
                              stats?.yesterday.orderNumber
                            )}
                          </Typography>
                        </Box>
                      </BoxCustom>
                    </Grid>
                  </Grid>
                </CardCustom>
              </Box>
            </Grid>
            {/* Panier moyen */}
            <Grid item lg={4} md={4} xs={12}>
              <Box pl={1} height={"100%"}>
                <CardCustom
                  style={{ width: "100%", height: "100%" }}
                  cardContentStyle={{ height: "100%" }}
                  paddingtop={"48px"}
                >
                  <Grid container className={realTimeBoxClass.root}>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body1">
                        <strong>Panier moyen (TTC)</strong>
                      </Typography>
                      <Box mt={1} mb={1}>
                        <Typography variant="body2">
                          <strong>
                            <span style={{ fontSize: 35, lineHeight: "25px" }}>
                              {!props.areStatsError
                                ? spaceOnNumbers(stats?.today.cartAverage) + "€"
                                : "0€"}
                            </span>
                          </strong>
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems="center">
                        {stats?.today.cartAverage <
                        stats?.yesterday.cartAverage ? (
                          <ArrowDropDown style={{ fill: colors.red.regular }} />
                        ) : (
                          <ArrowDropUpIcon
                            style={{ fill: colors.green.regular }}
                          />
                        )}
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              stats?.today.cartAverage <
                              stats?.yesterday.cartAverage
                                ? colors.red.regular
                                : colors.green.regular,
                          }}
                        >
                          J -1{" "}
                          <span>
                            ({spaceOnNumbers(stats?.yesterday.cartAverage)}€)
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <BoxCustom
                        position="relative"
                        maxHeight={88}
                        display="flex"
                        justifyContent={"center"}
                        statuscolor={
                          stats?.today.cartAverage <
                          stats?.yesterday.cartAverage
                            ? "negative"
                            : "positive"
                        }
                      >
                        <CircularProgress
                          variant="determinate"
                          thickness={3}
                          value={
                            (stats?.today.cartAverage * 100) /
                              stats?.yesterday.cartAverage -
                            100
                          }
                          size={"100%"}
                          style={{
                            maxHeight: 88,
                            maxWidth: 88,
                            width: "100%",
                            height: "auto",
                            position: "absolute",
                            zIndex: 2,
                          }}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={100}
                          style={{
                            maxHeight: 88,
                            maxWidth: 88,
                            width: "100%",
                            height: "auto",
                          }}
                          thickness={2.5}
                        />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{ fontWeight: "bold" }}
                          >
                            {stats?.today.cartAverage === 0
                              ? null
                              : stats?.today.cartAverage <
                                stats?.yesterday.cartAverage
                              ? ""
                              : "+"}
                            {getPercentage(
                              stats?.today.cartAverage,
                              stats?.yesterday.cartAverage
                            )}
                          </Typography>
                        </Box>
                      </BoxCustom>
                    </Grid>
                  </Grid>
                </CardCustom>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{ padding: "24px 0px", color: colors.grey.regular }}
            >
              Aucune donnée disponible
            </Typography>
          </Box>
        )}
      </AccordionCustom>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RealTime))
);
