import { gql } from "@apollo/client";

//----- Récupération des groupes d'utilisateur
export const GET_USERGROUPS = gql`
  {
    userGroups {
      edges {
        node {
          id
          code
          createdAt
          updatedAt
          users {
            edges {
              node {
                id
              }
            }
          }
          menuEntryRelations {
            edges {
              node {
                id
                writeRight
                readRight
                menuEntry {
                  id
                  code
                  parent {
                    id
                  }
                  createdAt
                  updatedAt
                  position
                }
              }
            }
          }
        }
      }
    }
  }
`;

//----- Récupération des groupes d'utilisateur
export const GET_USERGROUPS_PAGINATION = gql`
  query getUserGroupsPagination(
    $nbperpage: Int
    $cursor: String
    $cursorLast: String
    $usersPerPage: Int
    $usersCursor: String
    $usersCursorLast: String
  ) {
    userGroups(first: $nbperpage, after: $cursor, before: $cursorLast) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          code
          createdAt
          updatedAt
          libelle
          users(first: $usersPerPage, after: $usersCursor, before: $usersCursorLast) {
            pageInfo {
              startCursor
              endCursor
            }
            edges {
              node {
                id
                email
                firstname
                lastname
                phone
                job
                image
                preferredLangcode
                isActive
                isBlocked
                lastLogin
              }
            }
          }
          menuEntryRelations {
            edges {
              node {
                id
                writeRight
                readRight
                menuEntry {
                  id
                  code
                  parent {
                    id
                  }
                  createdAt
                  updatedAt
                  position
                }
              }
            }
          }
        }
      }
    }
  }
`;

//----- Ajouter un groupe d'utilisateur
/*
 Exemple de variables
  {
  "code": "test usergroup",
  "createdAt": "2022-03-02",
}
*/
export const CREATE_USERGROUP = gql`
  mutation createUserGroup(
    $code: String!
    $createdAt: String!
    $updatedAt: String
  ) {
    createUserGroup(
      input: { code: $code, createdAt: $createdAt, updatedAt: $updatedAt }
    ) {
      userGroup {
        id
        code
        createdAt
        updatedAt
        menuEntryRelations {
          edges {
            node {
              id
              menuEntry {
                id
                code
              }
              readRight
              writeRight
            }
          }
        }
      }
    }
  }
`;

//----- Modifier un groupe d'utilisateur
/*
 Exemple de variables
  {
  "id": "/api/user-groups/3",
  "code": "Another usergroup",
  "createdAt": "2022-01-02",
  "updatedAt": "2022-03-16",
}
*/
export const UPDATE_USERGROUP = gql`
  mutation updateUserGroup(
    $id: ID!
    $code: String
    $createdAt: String
    $updatedAt: String
  ) {
    updateUserGroup(
      input: {
        id: $id
        code: $code
        createdAt: $createdAt
        updatedAt: $updatedAt
      }
    ) {
      userGroup {
        id
        code
        createdAt
        updatedAt
        menuEntryRelations {
          edges {
            node {
              id
              menuEntry {
                id
                code
              }
              readRight
              writeRight
            }
          }
        }
      }
    }
  }
`;

//----- Supprimer un groupe d'utilisateur
/*
 Exemple de variables
  {
    "id" : "/api/user-groups/4"
  }
*/
export const DELETE_USERGROUP = gql`
  mutation deleteUserGroup($id: ID!) {
    deleteUserGroup(input: { id: $id }) {
      userGroup {
        id
        menuEntryRelations {
          edges {
            node {
              id
              menuEntry {
                id
                code
              }
              readRight
              writeRight
            }
          }
        }
      }
    }
  }
`;

//----- Récupération d'un seul groupe d'utilisateur
/*
 Exemple de variables
  {
    "id" : "/api/user-groups/3"
  }
*/
export const GET_USERGROUP = gql`
  query userGroup($id: ID!) {
    userGroup(id: $id) {
      id
      code
      createdAt
      updatedAt
      users {
        edges {
          node {
            id
          }
        }
      }
      menuEntryRelations {
        edges {
          node {
            id
            menuEntry {
              id
              code
            }
            readRight
            writeRight
          }
        }
      }
    }
  }
`;

//----- Ajouter un menu entry à un groupe d'utilisateur
/*
 Exemple de variables
  {
  "userGroup": "/api/user-groups/1",
  "menuEntry": "/api/menu-entries/1",
  "readRight": true,
  "writeRight": false
}
*/
export const createUserGroupMenuEntry = gql`
  mutation createUserGroupMenuEntry(
    $userGroup: String!
    $menuEntry: String!
    $readRight: Boolean
    $writeRight: Boolean
  ) {
    createUserGroupMenuEntry(
      input: {
        userGroup: $userGroup
        menuEntry: $menuEntry
        readRight: $readRight
        writeRight: $writeRight
      }
    ) {
      userGroupMenuEntry {
        id
      }
    }
  }
`;

//----- Editer un menu entry lié à un groupe d'utilisateur
/*
 Exemple de variables
  {
  "id": "/api/user-group-menu-entries/userGroup=3;menuEntry=1",
  "userGroup": "/api/user-groups/1",
  "menuEntry": "/api/menu-entries/1",
  "readRight": true,
  "writeRight": false
}
*/
export const updateUserGroupMenuEntry = gql`
  mutation updateUserGroupMenuEntry(
    $id: ID!
    $userGroup: String
    $menuEntry: String
    $readRight: Boolean
    $writeRight: Boolean
  ) {
    updateUserGroupMenuEntry(
      input: {
        id: $id
        userGroup: $userGroup
        menuEntry: $menuEntry
        readRight: $readRight
        writeRight: $writeRight
      }
    ) {
      userGroupMenuEntry {
        id
      }
    }
  }
`;

//----- Supprimer un menu entry lié à un groupe d'utilisateur
/*
 Exemple de variables
  {
  "id": "/api/user-group-menu-entries/userGroup=3;menuEntry=1"
}
*/
export const deleteUserGroupMenuEntry = gql`
  mutation deleteUserGroupMenuEntry($id: ID!) {
    deleteUserGroupMenuEntry(input: { id: $id }) {
      userGroupMenuEntry {
        id
      }
    }
  }
`;
