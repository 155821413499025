import colors from "@config/theme/colors.js";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import { ALERT_ERROR, ALERT_SUCCESS } from "@constants/alert-types";
import { CSV } from "@constants/medias-types.js";
import TopPanel from "@layouts/TopPanel/TopPanel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@ui/button/Button.js";
import InputBuilder from "@ui/form/InputBuilder";
import SelectInput from "@ui/form/inputs/SelectInput";
import OurStepper from "@ui/stepper/OurStepper";
import StepPanel from "@ui/stepper/components/StepPanel";
import request from "@utils/fetch";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RowMatching from "@ui/form/components/RowMatching";
import { FormContainer, MappingContainer } from "./style/ImportProductsForm.styled";

const ImportProductsForm = ({ onSuccess, onFail, ...props }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState({
    separator: ",",
    file: null,
    lang: props.locales[0].node.id,
  });
  const [mapping, setMapping] = useState([]);
  const [headers, setHeaders] = useState([]);

  const requiredFields = ["sku"];

  const onSubmit = async () => {
    props.startLoading();

    const mapper = mapping.map((item) => {
      if (item.label === "sku") {
        return ["sku"];
      }
      if (item.value.length > 0) {
        return [item.id];
      }
      return null;
    });

    let importConfig = {
      url: `${process.env.REACT_APP_API_ROOT}/medias/${form.file.medias.filePath}`,
      mapper,
      eavType: props.attributes.eavTypes.find((e) => e.node.code === "product")
        .node.id,
      locale: form.lang,
      delimiter: form.separator,
      media: null,
      fileType: null,
    };

    try {
      await request(
        `${process.env.REACT_APP_API_ROOT}/api/file-imports`,
        "post",
        importConfig,
        undefined,
        true
      );
      props.snack(
        ALERT_SUCCESS,
        `Votre fichier a été importé avec succès, son intégration dans Sinfin DXP sera exécutée lors du passage du CRON (Tâche automatique).`,
        6000
      );
      props.stopLoading();
      if (onSuccess) onSuccess();
    } catch (e) {
      props.snack(ALERT_ERROR, `L'import a échoué !`);
      props.stopLoading();
      if (onFail) onFail();
    }
  };

  const getOptionsMapping = () => {
    const options = [
      {
        id: "category",
        label: "Catégorie",
        identifier: "category",
      },
      {
        id: "attributeGroup",
        label: `Groupe d'attributs`,
        identifier: "attributeGroup",
      },
      {
        id: "status",
        label: "Statut",
        identifier: "status",
      },
    ];

    const currentLang = props.locales[0].node.code;
    const attributes = props.attributes.product.attributes.edges;

    for (let attribute of attributes) {
      const defaultLang = attribute.node.translation.translationDatas.edges[0];

      const langSelected =
        attribute.node.translation.translationDatas.edges.find(
          (lang) => lang.node.locale.code === currentLang
        );

      if (attribute.node.status) {
        options.push({
          id: attribute.node.id,
          identifier: attribute.node.identifier,
          label: langSelected
            ? langSelected?.node?.value
            : defaultLang
            ? defaultLang.node?.value
            : attribute.node.identifier,
        });
      }
    }
    return options;
  };

  const handleImportValues = () => {
    const options = getOptionsMapping();

    const initMapping = headers.map((header) => {
      const optionMatch = options.find(
        (option) => option.label === header || option.identifier === header
      );
      return {
        id: optionMatch ? optionMatch.id : header,
        label: header,
        value: optionMatch ? [optionMatch.identifier] : [],
      };
    });

    setMapping(initMapping);

    // for (let header of newMapping) {
    //   for (let option of options) {
    //     if (
    //       option.label?.toLowerCase() === header ||
    //       option.identifier?.toLowerCase() === header
    //     ) {
    //       header.value = option.identifier;
    //     }
    //     if (
    //       !hasSku &&
    //       option.identifier === "product_ean" &&
    //       (option.label === header || option.label === header)
    //     ) {
    //       if (values[header]) {
    //         values[header].push("sku");
    //       }
    //     }
    //   }
    // }
  };

  useEffect(() => {
    handleImportValues();
  }, [headers]);

  const fetchHeaders = async (file) => {
    props.startLoading();

    setForm({ ...form, file });

    let headersFormData = new FormData();

    headersFormData.append(
      "csv",
      `${process.env.REACT_APP_API_ROOT}${file.medias.contentUrl}`
    );

    headersFormData.append("separator", form.separator);

    axios
      .post(
        `${process.env.REACT_APP_API_ROOT}/api/export/csv/structure`,
        headersFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.data.success) setHeaders(res.data.message);
      })
      .catch(() => {
        props.snack(
          ALERT_ERROR,
          "Une erreur est survenue lors du chargement des headers"
        );
      })
      .finally(() => {
        props.stopLoading();
      });
  };

  const handleChangeMapping = (val, index, label) => {
    const newMapping = mapping.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          value: val,
        };
      }
      return item;
    });

    setMapping(newMapping);
  };

  const validateMapping = () => {
    return new Promise((resolve, reject) => {
      requiredFields.forEach((field) => {
        const hasField = mapping.find((item) => item.value.includes(field));
        if (!hasField) {
          props.snack(
            ALERT_ERROR,
            `Vous devez obligatoirement avoir un ${field}`
          );
          resolve(false);
        }
      });
      resolve(true);
    });
  };

  const handleChangeStep = async (newStep) => {
    if (activeStep === 1 && newStep > activeStep) {
      const mappingIsValid = await validateMapping();
      if (mappingIsValid) setActiveStep(newStep);
    } else if (newStep !== activeStep) {
      setActiveStep(newStep);
    }
  };

  return (
    <Box sx={{ width: "100%", height: "maxContent", overflowY: "hidden" }}>
      <TopPanel
        inForm={true}
        windowWidth={props.windowWidth}
        title="Importer des produits"
        subtitle={
          "Veuillez compléter les champs ci-dessous pour importer votre produit"
        }
      />

      <OurStepper
        steps={[
          {
            label: "Choix des fichiers",
            required: true,
            completed: form.file ? true : false,
            show: true,
          },
          {
            label: "Mapping",
            required: true,
            completed: mapping.length > 0 ? true : false,
            show: true,
          },
          {
            label: "Finalisation",
            required: true,
            completed: false,
          },
        ]}
        activeStep={activeStep}
        onChange={(e) => handleChangeStep(e)}
      >
        <StepPanel
          value={activeStep}
          index={0}
          style={{ height: "calc(100vh - 275px)", overflowY: "auto" }}
        >
          <FormContainer>
            <SelectInput
              label="Séparateur"
              helper="Choisir le séparateur"
              options={[
                {
                  label: ",",
                  value: ",",
                },
                {
                  label: ";",
                  value: ";",
                },
                {
                  label: "|",
                  value: "|",
                },
                {
                  label: "SimpleXML (temp)",
                  value: "xml",
                },
              ]}
              value={form.separator}
              onChange={(e) => setForm({ ...form, separator: e })}
              sx={{ width: "100%" }}
            />
            <InputBuilder
              input={{
                type: "uploadFile",
                allowedTypes: CSV,
                typeUpload: null,
                label: "Importer votre fichier",
                required: true,
                translated: false,
                stateName: "file",
                downloadType: "product",
                topLabel: "Importer votre fichier",
              }}
              allState={form}
              value={form.file}
              stateCallback={(e) => fetchHeaders(e)}
            />
          </FormContainer>
        </StepPanel>
        <StepPanel
          value={activeStep}
          index={1}
          style={{ height: "calc(100vh - 275px)", overflowY: "auto" }}
        >
          <MappingContainer>
            <SelectInput
              label="Langue"
              options={props.locales.map((locale) => ({
                value: locale.node.id,
                label: locale.node.libelle,
              }))}
              sx={{ width: "100%" }}
              value={form.lang}
              helper="Sélectionner la langue de l'import"
              rules={{ required: true }}
              onChange={(e) => {
                setForm({ ...form, lang: e });
              }}
            />

            <Grid container rowSpacing={1} padding={1}>
              {mapping.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <RowMatching
                    label={item.label}
                    value={item.value}
                    options={getOptionsMapping()}
                    sx={{
                      width: "100%",
                      backgroundColor: colors.blue.lighter.hue600,
                      alignItems: "center",
                      padding: "5px",
                    }}
                    onChange={(val) =>
                      handleChangeMapping(val, index, item.identifier)
                    }
                    onDelete={() =>
                      setMapping((prevState) =>
                        prevState.filter((_, i) => i !== index)
                      )
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </MappingContainer>
        </StepPanel>
        <StepPanel value={activeStep} index={2}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ padding: "30px" }}
          >
            <Grid item xs={3}>
              <Box textAlign={"center"}>
                <CheckCircleOutlineIcon
                  style={{
                    fill: colors.green.regular,
                    width: "100%",
                    height: "100%",
                    maxHeight: "54px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={"center"} mt={2}>
                <Typography variant={"h2"}>Bientôt prêt !</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={"center"} mt={1}>
                <Typography variant={"h4"}>
                  Votre fichier est prêt à être importé :)
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={"center"} mt={4}>
                <Button
                  bgcolor={colors.green.regular}
                  shadowcolor={colors.green.darker}
                  text="Importer le fichier"
                  onClick={onSubmit}
                  className="button"
                />
              </Box>
            </Grid>
          </Grid>
        </StepPanel>
      </OurStepper>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
    attributeGroups: state.attributeGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportProductsForm);
