import { FORM } from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CatalogFinal from '../../../../../assets/pictos/empty-picto/catalogue.svg';
import CheckboxAttributs from '../../../../ui/inputs/CheckboxAttributs';
import { capitalize } from 'lodash';

export default function formAttributsRights(groupWithSelectedAttributes, currentLang) {
    var obj = {
        titleForm: 'Gérer les droits',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour configurer votre asset',
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            header: {
                title: 'Information sur le groupe',
            },
            finalStep: {
                picto: CatalogFinal,
                title: 'Et voilà !',
                subtitle: 'Vous pouvez confirmer votre modification',
                textButton: 'Enregistrer'
            },
            children: [
                {
                    // labelName: 'Attributs',
                    // isOptionnal: false,
                    optionsInputs: [
                        {
                            type: 'checkboxList',
                            required: false,
                            stateName: 'groupWithAttributes',
                            listing: {
                                id: groupWithSelectedAttributes?.node.id,
                                title: capitalize(groupWithSelectedAttributes?.node.identifier),
                                values: groupWithSelectedAttributes?.node.attributes.edges?.map((attr) => {
                                    return ({
                                        id: attr.node.id,
                                        name: attr.node.translation.translationDatas.edges.find(i => i.node.locale.code === currentLang).node.value ?? '-',
                                        groupId: attr.node.id,
                                    })
                                }),
                                checkedValuesByDefault: [],
                            },
                            custom: {
                                isExpandable: false,
                                isExpanded: true,
                                hasSearch: true,
                                hasSelectAll: true,
                            }
                        }
                    ]
                },
            ]
        }
    }
    return obj;
}