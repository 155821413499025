import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Box, Grid } from '@mui/material';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardSetup from '../../../layouts/Card/cardContent/CardSetup';
import Typography from '../../../ui/typography/Typography';
import { ROUTE_SALES_MARKETPLACES, ROUTE_COMMUNITIES_SOCIALS, ROUTE_CHANNELS_CMS, ROUTE_CHANNELS_MEDIAS, ROUTE_COMMUNITIES_NEWSLETTERS, ROUTE_HOME, ROUTE_CHANNELS_SOCIALS, ROUTE_CHANNELS_MARKETPLACES } from '../../../../js/constants/route-names';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import SliderDashboard from "../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import Image2 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-marketplace.png';
import { v4 as uuidv4 } from 'uuid';
import { CONNECTORS, CONNECTORS_DASHBOARD, CONNECTORS_CMS, CONNECTORS_EMAILS, CONNECTORS_MARKETPLACES, CONNECTORS_MEDIAS, CONNECTORS_SOCIAL_NETWORK, CREATE, VIEW } from '../../../../js/constants/constant-rights';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { SNACK } from '../../../../js/constants/action-types';
import ImportHistory from '../settings/ImportHistory';
import CronNotifications from './components/CronNotifications';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;
const Wrapper = styled(Box)`
    width: 100%; 
    display: grid; 
    grid-template-rows: auto 1fr; 
    height: 100%;
`;
const SublayoutWrapper = styled(Grid)`
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
`;

class DashboardChannels extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            channels: [],
            listCrons: null,
        };
    }

    componentDidMount() {
        checkRouting(this.props);
        this.initDashboard();
    }

    initDashboard = () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/connector`, 'get', false).then(
            (data) => {
                if (data.success) {
                    this.setState({
                        channels: data.datas
                    });
                }
            }
        ).catch((e) => {
            this.props.dispatch({ type: SNACK, payload: { type: ALERT_ERROR, message: e.message } });
        });
        if (process.env.REACT_APP_MODE_SPREAD === "hub") {
            request(`${process.env.REACT_APP_API}/warehousePreference`, 'get', false).then(
                (data) => {
                    if (data.success) {
                        this.setState({
                            preferences: data.preferences
                        });
                    }
                }
            ).catch((e) => {
                this.props.dispatch({ type: SNACK, payload: { type: ALERT_ERROR, message: e.message } });
            });
        }
    }

    getListCronByRetailer = (id, retailer) => {
        this.setState({
            listCrons: null,
            drawerCron: true,
            retailerCron: retailer
        });

        try {
            request(`${process.env.REACT_APP_API}/scheduler/retailer/${id}`, 'get', false).then(
                (data) => {
                    if (data.success) {
                        this.setState({ listCrons: data.crons })
                    }
                }
            ).catch((e) => {
                this.props.dispatch({ type: SNACK, payload: { type: ALERT_ERROR, message: e.message } });
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        let sliderData = [
            {
                title: this.props.t("connectors.dashboard.whyMarketPlace"),
                text: this.props.t("connectors.dashboard.marketPlaceInfo"),
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t("connectors.dashboard.addConnector"),
                        link: `${ROUTE_CHANNELS_MARKETPLACES}?formOpen=true`
                    },
                ]
            },
            {
                title: this.props.t("connectors.dashboard.whySocialConnector"),
                text: this.props.t("connectors.dashboard.socialInfo"),
                image: Image2,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t("connectors.dashboard.addSocialConnector"),
                        link: `${ROUTE_CHANNELS_SOCIALS}?formOpen=true`
                    },
                ]
            },
            {
                title: this.props.t("connectors.dashboard.whyCMSConnector"),
                text: this.props.t("connectors.dashboard.cmsConnectorInfo"),
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t("connectors.dashboard.addCmsConnector"),
                        link: `${ROUTE_CHANNELS_CMS}?formOpen=true`
                    },
                ]
            },
            // 
            // {
            //     title: this.props.t("connectors.dashboard.whyMailConnector"),
            //     text: this.props.t("connectors.dashboard.mailConnectorInfo"),
            //     image: Image2,
            //     id: uuidv4(),
            //     buttons: [
            //         {
            //             text: this.props.t("connectors.dashboard.addMailConnector"),
            //             action: () => this.goTo(ROUTE_COMMUNITIES_NEWSLETTERS, true),
            //         },
            //     ]
            // }
            {
                title: this.props.t("connectors.dashboard.whyMediaConnector"),
                text: this.props.t("connectors.dashboard.mediaConnectorInfo"),
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t("connectors.dashboard.addMediaConnector"),
                        link: `${ROUTE_CHANNELS_MEDIAS}?formOpen=true`
                    },
                ]
            },
        ];
        sliderData = sliderData.filter(function (el) {
            return el !== null;
        });

        let stats =
            [
                {
                    'icon': 'picto-stock',
                    'data': this.state.channels.channelActifs,
                    'dataColor': colors.black.regular,
                    'subtitle': process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.dashboard.activeConnectors") : this.props.t("connectors.dashboard.activeChannels"),
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                },
                {
                    'icon': 'picto-produit',
                    'data': this.state.channels.channelInactifs,
                    'dataColor': colors.black.regular,
                    'subtitle': process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.dashboard.inactiveConnector") : this.props.t("connectors.dashboard.inactiveChannels"),
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                },
            ];
        // let lastDatas = [
        //     {
        //         'icon': 'picto-stock',
        //         'data': this.state.channels.channelActifs,
        //         'dataColor': colors.black.regular,
        //         'subtitle': 'Channels Actifs',
        //     },
        //     {
        //         'icon': 'picto-produit',
        //         'data': this.state.channels.channelInactifs,
        //         'dataColor': colors.black.regular,
        //         'subtitle': 'Channels Inactifs',
        //     },
        // ]
        return (
            <Wrapper>
                <TopPanel
                    title={this.props.t("spread_builder.dashboard.welcomeOnDashboard") + ' ' + (process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.dashboard.connectors") : this.props.t("connectors.dashboard.channels"))}
                    subtitle="Vue d'ensemble des connecteurs dans la plateforme"
                    openForm={this.state.openForm}
                    hasBorder={true}
                />

                <SublayoutWrapper container direction="column" justifyContent="center" spacing={0}>
                    {
                        sliderData.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xl={sliderData.length >= 1 ? 12 : 6} lg={sliderData.length >= 1 ? 12 : 8} md={sliderData.length >= 1 ? 12 : 8} xs={12} style={{ overflow: 'hidden' }}>
                                <AccordionCustom forcedExpanded={true} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{this.props.t('products.dashboard.actionslider.actionslider')}</Typography>}>
                                    <SliderDashboard sliderData={sliderData} windowWidth={this.props.windowWidth} />
                                </AccordionCustom>
                            </Grid>
                            : null
                    }
                    {/* {
                        this.state.channels.marketplaces?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{this.props.t("connectors.dashboard.marketConnectors")}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.marketplaces.map((marketplace, index) => {
                                            let type = {
                                                node: {
                                                    ...marketplace,
                                                    preferences: this.state.preferences?.[marketplace.id]
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListMarketplaces${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={this.props.t("catalogs.catalogs.modify")}
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.socials?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{this.props.t("connectors.dashboard.socialConnectors")}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.socials.map((social, index) => {
                                            let type = {
                                                node: {
                                                    ...social
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListFlux${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={this.props.t("catalogs.catalogs.modify")}
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.cms?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.dashboard.cmsConnectors") : this.props.t("connectors.dashboard.shops")}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.cms.map((cms, index) => {
                                            let type = {
                                                node: {
                                                    ...cms,
                                                    preferences: this.state.preferences?.[cms.id]
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListCMS${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={this.props.t("catalogs.catalogs.modify")}
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(cms.id, cms.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.contents?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{this.props.t("connectors.dashboard.contentConnectors")}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.contents.map((content, index) => {
                                            let type = {
                                                node: {
                                                    ...content
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListContents${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={this.props.t("catalogs.catalogs.modify")}
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.medias?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{this.props.t("connectors.dashboard.mediaConnectors")}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.medias.map((media, index) => {
                                            let type = {
                                                node: {
                                                    ...media
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListMedias${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={this.props.t("catalogs.catalogs.modify")}
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.connectors?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.dashboard.fluxConnectors") : this.props.t("connectors.dashboard.logisticians")}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.connectors.map((connector, index) => {
                                            let type = {
                                                node: {
                                                    ...connector
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListFlux${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={this.props.t("catalogs.catalogs.modify")}
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    } */}
                    {/* {
                        this.state.channels.newsletters?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{this.props.t("connectors.dashboard.emailConnectors")}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.newsletters.map((newsletter, index) => {
                                            let type = {
                                                node: {
                                                    ...newsletter
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListFlux${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={this.props.t("catalogs.catalogs.modify")}
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    } */}
                    {/* {
                        this.state.channels.retailers?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.dashboard.retailersConnectors") : this.props.t("connectors.dashboard.retailersConnectors")}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.retailers.map((retailer, index) => {
                                            let type = {
                                                node: {
                                                    ...retailer
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListFlux${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={this.props.t("catalogs.catalogs.modify")}
                                                            isDashboard={true} 
                                                            isRetailer={true}
                                                            cronListOpening={this.getListCronByRetailer}
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    } */}
                    <CronNotifications closeDrawer={() => this.setState({ drawerCron: false })} drawerCron={this.state.drawerCron} retailerCron={this.state.retailerCron} listCrons={this.state.listCrons} />
                    <Grid item xs={12} style={{ marginTop: 8 }}>
                        <Grid container direction="row" style={{ marginTop: 0, height: '100%' }}>
                            <ImportHistory used="dashboard" />
                        </Grid>
                    </Grid>
                </SublayoutWrapper>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // startLoading: () => dispatch({ type: START_LOADING }),
        // stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardChannels))));
