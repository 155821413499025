import React, { useState } from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import colors from '../../../config/theme/colors';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { useEffect } from 'react';
import { Dialog } from '@mui/material';
import DialogModal from '../dialog/DialogModal';

const CustomSelect = styled(Select)`
    cursor:pointer;
    padding:0;
    fieldset{
        border-width:0 !important;
    }
    .MuiSelect-select {
        padding:0;
        padding-right: 15px !important;
        color:black;
    }
    .MuiSvgIcon-root {
        right: 0px;
    }
    .MuiModal-root .MuiPaper-root{
        margin-top : 5px;
        .MuiList-root{
            padding:0;
        }
    }
`;

const CustomIconButton = styled(IconButton)`
    padding:5px;
`;

const CustomMenuItemActive = styled(MenuItem)`
    background-color: transparent !important;
    font-weight:bold;
    .MuiTypography-root{
        font-weight:bold;
    }
    &:hover{

    }
`;

const CustomMenuItem = styled(MenuItem)`
    &:hover{
        background-color: ${colors.blue.lighter.hue900};
        color:${colors.blue.darker.hue300};        
    }
`;

export default function SelectCkeckBox(props){
    const {handleViews,views,itemSelection,handleSelectionMode,handleSelectionItems } = props;
    const [value,setValue]=useState("none")
    const [isModalOpen,setIsModalOpen]=useState(false)

    const handleChange = (e,child)=>{
        if (views.current !== 'table') {
            handleViews('table')
        }
        handleSelectionMode(true)
        setValue(e.target.value)
        handleSelectionItems(e.target.value)
    }

    const handleCheckBox = (e)=>{
        e.stopPropagation()
        const value = e.target.checked
        if (views.current !== 'table') {
            handleViews('table')
        }
        handleSelectionMode(true)
        if (value) {
            handleSelectionItems("all")
            setValue("all")
        }else{
            handleSelectionItems("none")
            setValue("none")
        }
    }

    const handleToggleDialogModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    useEffect(() => {
      if (itemSelection.items.length === 0) {
        setValue('none')
      }
    }, [itemSelection.items])

    useEffect(() => {
        if(value === 'null'){
            props.actions?.export.handler("all")
        }
      }, [value])


    return(
        <FormControl sx={{display:"flex",alignItems:"center",flexDirection:"row"}}>
            <Checkbox 
                // icon={<CheckBoxOutlineBlankSharpIcon fontSize='small' />} 
                // checkedIcon={<CheckIcon fontSize='small' sx={{border:"2px solid black"}}/>} 
                sx={{color:"black",'&.Mui-checked':{color:"black"},padding:"3px 5px"}} 
                onClick={handleCheckBox} 
                disableRipple 
                checked={itemSelection.items.length > 0}
                // size="small"
            />
            <CustomSelect
                autoWidth
                renderValue={()=>null}
                value={value}
                onChange={handleChange}
                // onClick={handleCheckBox}
                IconComponent={(props)=>{return <ArrowDropDownIcon className={props.className} fontSize="small" />}}
                MenuProps={{
                    sx:{
                        marginTop:"5px"
                    },
                    PaperProps:{
                        sx:{
                            boxShadow:"none",
                            border:`0.5px solid ${colors.grey.border} !important`
                        },  
                    },
                    MenuListProps: { 
                        disablePadding: true ,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    },
                    transformOrigin: {
                      vertical: 0,
                      horizontal: "center"
                    },
                    getContentAnchorEl: null
                  }}
            >
                {
                    value === "null"?
                    <CustomMenuItemActive value={"null"}>{props.itemSelection?.customOptionText ?? "Tous les produits"}</CustomMenuItemActive>
                    :<CustomMenuItem value={"null"}>{props.itemSelection?.customOptionText ?? "Tous les produits"}</CustomMenuItem>
                }

                {
                    value === "all"?
                    <CustomMenuItemActive value={"all"}>Toute la page</CustomMenuItemActive>
                    :<CustomMenuItem value={"all"}>Toute la page</CustomMenuItem>
                }
                {
                    value === "none"?
                    <CustomMenuItemActive value={"none"}>Aucun</CustomMenuItemActive>
                    :<CustomMenuItem value={"none"}>Aucun</CustomMenuItem>
                }
            </CustomSelect>    
            <div
                style={{
                    display:"flex",
                    width:itemSelection.items.length > 0 && views.current === 'table' ?"100%":"0%",
                    transition: "width 0.3s linear",
                    overflow :"hidden"
                }}
            >
                <Divider variant="middle" orientation='vertical' flexItem/>
                <Paper sx={{
                    background:'transparent',
                    padding:"0 10px",
                    display:"flex",
                    gap:"5px"
                }}>
                    <Tooltip placement='top' title={''}>
                        <CustomIconButton>
                            <RemoveRedEyeIcon fontSize='small' sx={{color:colors.blue.darker.hue300}} />
                        </CustomIconButton>
                    </Tooltip>
                    <Tooltip placement='top' title={''}>
                        <CustomIconButton>
                            <VisibilityOffIcon fontSize='small' sx={{color:colors.blue.darker.hue300}} />
                        </CustomIconButton>
                    </Tooltip>
                    <Tooltip placement='top' title={props.actions?.delete.toolTip??'Supprimer la séléction'}>
                        <CustomIconButton onClick={props.actions?.delete.handler ?? null}>
                            <DeleteSharpIcon fontSize='small' sx={{color:colors.blue.darker.hue300}} />
                        </CustomIconButton>
                    </Tooltip>
                    <Tooltip placement='top' title={props.actions?.export.toolTip??'Exporter la séléction'}>
                        <CustomIconButton onClick={props.actions?.export.handler ?? null}>
                            <DownloadSharpIcon fontSize='small' sx={{color:colors.blue.darker.hue300}} />
                        </CustomIconButton>
                    </Tooltip>
                </Paper>
            </div> 

            <DialogModal
                icon={true}
                type={props.customProps?.modals.delete.type}
                open={isModalOpen}
                title={props.customProps?.modals.delete.title}
                primaryAction={props.customProps?.modals.delete.actions.main}
                secondaryAction={handleToggleDialogModal}
                windowWidth={props.windowWidth}
            >
                {props.customProps?.modals.delete.text}
            </DialogModal>
        </FormControl>
    );
}