import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { toggleExpandedForAll } from "react-sortable-tree";

import { withApollo } from '@apollo/client/react/hoc';
import {
  GET_CATEGORIES_ONLY,
  UPDATE_CATEGORY,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY_DATA,
  ADD_CATEGORY_DATA,
  DELETE_CATEGORY_DATA,
} from "../../../../queries/categories";
import formCategoriesAdd from "./config/formCategoriesAdd.config";
import formCategoriesEdit from "./config/formCategoriesEdit.config";

import fetch from "../../../../js/utils/fetch";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import TreeView from "../../../ui/tree-view/TreeView";
import TopPanel from "../../../layouts/TopPanel/TopPanel";
import LayoutBuilder from "../../../ui/form/LayoutFormBuilder";
import SearchBar from "../../../ui/search/SearchBar";
import Button from "../../../ui/button/Button";

import importCategoriesTypesConfig from "./config/importCategoriesTypes.config";
import importCategoriesConfig from "./config/importCategories.config";
import importFichier from "../../../../assets/pictos/icon-import-fichier.svg";
import importAPI from "../../../../assets/pictos/icon-import-api.svg";
import importFlux from "../../../../assets/pictos/icon-import-flux.svg";
import exportProductsConfig from "./config/exportProducts.config";

import colors from "../../../../config/theme/colors";
import {
  START_LOADING,
  STOP_LOADING,
  SNACK,
} from "../../../../js/constants/action-types";
import {
  ALERT_SUCCESS,
  ALERT_ERROR,
} from "../../../../js/constants/alert-types";
import { eventService } from "../../../../js/services/event.service";
import slugify from "slugify";
import request from "../../../../js/utils/fetch";

import { withTranslation } from "react-i18next";
import { checkRouting } from "../../../../js/utils/checkRouting";
import { getParams } from "../../../../js/utils/getParams";

import styled from "styled-components";
import AccordionCustom from "../../../layouts/Accordion/AccordionCustom";
import { GetApp } from "@mui/icons-material";
import DialogModal from "../../../ui/dialog/DialogModal";

const styles = (theme) => ({});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: colors.blue.darker.hue300,
  },
  tooltip: {
    backgroundColor: colors.blue.darker.hue300,
    fontSize: 14,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const PageWrapper = styled(Box)`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;

  & > .layout-wrapper{
    display: none;
  }
`;

class ProductsCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      openFormExport: false,
      openDialog: false,
      editForm: '',
      openFormImports: false,
      openFormImport: false,
      cat_id: "",
      parent: "",
      libelle: "",
      categoriesData: [],
      treeData: null,
      inputAttributes: [],
      currentLang: this.props.locales[0].node.code,
      errors: {},
      seeErrors: false,
      disableExport: false,
      exportName: "",
      exportLang: this.props.locales[0].node.code,
      exportType: "csv",
      exportStatus: 'all',
      exportImage: 'all',
      searchString: null,
    };

    this.stats = [
      {
        icon: "picto-stock",
        data: "24/06/20 à 09:00",
        dataColor: colors.pink.regular,
        subtitle: "Mise à jour des Stocks",
        gradientColor1: colors.pink.regular,
        gradientColor2: colors.pink.lighter,
      },
      {
        icon: "picto-produit",
        data: "24/06/20 à 09:00",
        dataColor: colors.pink.regular,
        subtitle: "Mise à jour des Produits",
        gradientColor1: colors.pink.regular,
        gradientColor2: colors.pink.lighter,
      },
      {
        icon: "picto-prix",
        data: "24/06/20 à 09:00",
        dataColor: colors.pink.regular,
        subtitle: "Mise à jour des Prix",
        gradientColor1: colors.pink.regular,
        gradientColor2: colors.pink.lighter,
      },
    ];
    this.typingTimer = null;
    this.typeTesting = "category";
  }

  componentDidMount() {
    checkRouting(this.props);
    this.prepareTree();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.identifier !== this.state.identifier && this.state.editForm === 'add') {
      this.setState({
        [this.state.currentLang]: {
          category_name: this.state.identifier,
        }
      })
    }
  }

  getAttributeTranslatedValue = (id, lang) => {
    if (!this.state.currentNode) return null;

    let attribute = this.state.currentNode.attributes.find((e) => e.id === id);

    if (!attribute) return null;

    let translation = attribute.locales.find((e) => e.id === lang);

    if (!translation) return null;

    return translation;
  };

  saveAttributes = (cat) => {
    return new Promise(async (resolve, reject) => {
      let category = cat || this.state.currentNode.node;

      for (let attribute of this.props.attributes.category.attributes.edges) {
        for (let locale of this.props.locales) {
          let formValue =
            this.state[locale.node.code][attribute.node.identifier];
          let currentTranslation = this.getAttributeTranslatedValue(
            attribute.node.id,
            locale.node.id
          );
          let isMedia = false;

          if (formValue && attribute.node.attributeType.input === "image") {
            /*if (!formValue.changed)
                            continue;*/

            isMedia = true;
            /*formValue   = formValue.file;*/
          }

          if (formValue) {
            /*let resultMedia = null;
    
                        if (attribute.node.attributeType.input === 'image') { 
                            let formData = new FormData();
    
                            formData.append('file', formValue);
                            formData.append('type', formValue.type);
        
                            resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                        }*/

            if (currentTranslation) {
              // UPDATE STEP

              let variables = {
                id: currentTranslation.categoryDataId,
                category: category.id,
                attribute: attribute.node.id,
                locale: locale.node.id,
              };

              if (isMedia) variables.media = formValue.id;

              if (!isMedia) variables.value = formValue;

              await this.props.client.mutate({
                mutation: UPDATE_CATEGORY_DATA,
                variables,
              });

              /* try {
                                 if (isMedia)
                                     await axios(`${process.env.REACT_APP_API}/media-objects/${currentTranslation.media.id.replace('/api/media-objects/', '')}`, 'delete');
                             } catch(e) {
                             }*/
            } else {
              // CREATE STEP
              let variables = {
                category: category.id,
                attribute: attribute.node.id,
                locale: locale.node.id,
              };

              if (isMedia) variables.media = formValue.id;

              if (!isMedia) variables.value = formValue;

              await this.props.client.mutate({
                mutation: ADD_CATEGORY_DATA,
                variables,
              });
            }
          } else if (currentTranslation) {
            // DELETE STEP

            await this.props.client.mutate({
              mutation: DELETE_CATEGORY_DATA,
              variables: { id: currentTranslation.categoryDataId },
              refetchQueries: [
                {
                  query: GET_CATEGORIES_ONLY,
                  variables: {
                    exists: [
                      {
                        catalog: false,
                      },
                    ],
                  },
                },
              ],
            });
          }
        }
      }
      resolve();
    });
  };

  handleError = (e) => {
    this.props.snack(ALERT_ERROR, "Une erreur est survenue");

    this.props.stopLoading();

    if (e.graphQLErrors) {
      for (let error of e.graphQLErrors) {
        console.error("ERROR", `${error.message} =>`, error.debugMessage);
      }
    }
  };

  handleSuccess = async () => {
    await this.prepareTree();

    this.props.snack(
      ALERT_SUCCESS,
      this.state.editForm === "edit"
        ? "Catégorie modifiée !"
        : "Catégorie ajoutée !"
    );

    this.handleToggleDrawer();
    this.resetState();
    this.props.stopLoading();
  };

  handleFormError = (stateName, error) => {
    let errors = this.state.errors;

    errors[stateName] = error;

    this.setState({ errors: errors ?? null });
  };

  hasErrors = () => {
    if (this.state.errors) {
      for (let error in this.state.errors) {
        if (this.state.errors[error]) return true;
      }
    }

    return false;
  };

  handlerMutation = async () => {
    try {
      if (this.hasErrors()) {
        this.props.snack(ALERT_ERROR, "Veuillez vérifier les champs invalides");
        this.setState({ seeErrors: true });
        return eventService.fire();
      }

      let query = null;
      let variables = null;

      this.props.startLoading();

      switch (this.state.editForm) {
        case "edit":
          query = UPDATE_CATEGORY;
          variables = {
            id: this.state.cat_id,
            libelle: this.state.identifier,
            parent: this.state.parent,
          };
          break;
        case "add":
          query = ADD_CATEGORY;
          variables = {
            libelle: this.state.identifier,
            parent: this.state.parent,
            master: null,
            status: true,
          };
          break;
        default:
          return this.props.stopLoading();
      }

      const GET_CATEGORIES_RESULT = await this.props.client.mutate({
        mutation: query,
        variables,
        // refetchQueries: [
        //   {
        //     query: GET_CATEGORIES_ONLY,
        //     variables: {
        //       exists: [
        //         {
        //           catalog: false,
        //         },
        //       ],
        //     },
        //   },
        // ],
      });

      await this.saveAttributes(
        this.state.editForm === "edit"
          ? GET_CATEGORIES_RESULT.data.updateCategory.category
          : GET_CATEGORIES_RESULT.data.createCategory.category
      );

      this.handleSuccess();
    } catch (e) {
      console.log('error', e);
      this.handleError(e);
    }
  };

  deleteMutation = () => {
    let query = null;
    let variables = null;

    this.props.startLoading();

    query = DELETE_CATEGORY;
    variables = { id: this.state.cat_id };
    this.props.client
      .mutate({
        mutation: query,
        variables,
      })
      .then(async () => {
        // this.removeNode(this.state.cat_id);
        await this.prepareTree()

        this.props.stopLoading();
        this.props.snack(ALERT_SUCCESS, "Catégorie supprimée");

        this.handleToggleDialog();
        this.resetState();
      })
      .catch((error) => {
        this.props.stopLoading();
        this.props.snack(
          ALERT_ERROR,
          `Impossible de supprimer la catégorie, veuillez vérifier qu'elle n'est pas utilisée`
        );
        this.handleToggleDialog();
      });
  };

  removeNode = (id) => {
    let removeRecursive = (data) => {
      if (data.children) {
        data.children = this.copyArrayOfObjects(
          data.children.filter((e) => e.id !== id)
        );

        for (let child of data.children) removeRecursive(child);
      }
    };

    let tree = this.state.treeData;

    for (let data of tree) removeRecursive(data);

    this.setState({
      treeData: this.copyArrayOfObjects(tree) ?? null
    });
  };

  recursiveTraductionCheck = (cat, isRoot) => {
    this.convertToNode(cat, isRoot);

    for (let child of cat.children) this.recursiveTraductionCheck(child, false);
  };

  handleLang = (event) => {
    this.setState({ currentLang: event.target.value }, () => {
      eventService.fire();

      for (let cat of this.state.treeData) {
        this.recursiveTraductionCheck(cat, true);
      }

      this.forceUpdate();
    });
  };

  handleToggleDrawer = () => {
    this.setState({
      openForm: !this.state.openForm ?? false,
      seeErrors: false,
      errors: {},
    });
  };

  handleToggleDrawerImport = (stateDrawer) => {
    this.setState({
      [stateDrawer]: !this.state[stateDrawer],
    });
  };

  handleToggleDialog = (node = null) => {
    if (node) {
      this.setState({
        cat_id: node.id ?? node,
      })
    }

    this.setState({
      openDialog: !this.state.openDialog ?? false,
    });
  };

  resetState() {
    this.setState({
      cat_id: "",
      identifier: "",
      parent: "",
      errors: {},
      currentNode: null,
    });
  }

  editCat = (nodeInfo) => {
    this.resetState();

    for (let locale of this.props.locales) {
      let values = {};

      for (let attribute of nodeInfo.attributes) {
        for (let attributeLocale of attribute.locales) {
          if (attributeLocale.code === locale.node.code) {
            switch (attribute.attributeType.input) {
              case "image":
                values[attribute.identifier] = {
                  data: attributeLocale.media
                    ? attributeLocale.media.filePath
                    : null,
                  file: null,
                  changed: false,
                };
                break;
              default:
                values[attribute.identifier] = attributeLocale.value;
            }
          }
        }
      }

      this.setState({
        [locale.node.code]: values,
      });
    }

    this.setState({
      currentNode: nodeInfo,
      openForm: true,
      editForm: "edit",
      cat_id: nodeInfo.id,
      identifier: nodeInfo.libelle,
      parent: nodeInfo.parent === null ? nodeInfo.id : nodeInfo.parent.id,
    });
  };

  handleMediaPicker = (selected, stateName) => {
    this.handleInputChange(stateName, selected, null, this.state.currentLang);
  };

  addCategory = () => {
    this.resetState();

    for (let locale of this.props.locales) {
      this.setState({
        [locale.node.code]: {},
      });
    }

    let defaultCat = this.state.categoriesData.find((e) => e.parent === null);

    this.setState({
      openForm: true,
      editForm: "add",
      parent: defaultCat.id ?? null,
    });
  };

  addSubcategory = (nodeInfo) => {
    this.resetState();

    for (let locale of this.props.locales) {
      this.setState({
        [locale.node.code]: {},
      });
    }

    this.setState({
      openForm: true,
      editForm: "add",
      parent: nodeInfo.id,
    });
  };

  doneTyping = (stateName) => {
    if (stateName === "identifier") {
      this.setState({
        catalogIdentifier: slugify(this.state.identifier, {
          replacement: "_",
          lower: true,
          remove: /[^\w\-\s]+/g,
        }) ?? null,
      });
    }
    if (this.state.identifier) {
      request(
        `${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.identifier}`,
        "get"
      ).then((data) => {
        if (data.success) {
          eventService.fire({
            stateName: "identifier",
            errorMessage:
              "Cet identifiant est déjà utilisé et n'est donc pas valide.",
          });
        }
      }).catch((err) => {
        console.log(err)
      });
    }
    this.forceUpdate();
  };

  checkIdentifier = (stateName) => {
    if (stateName === "identifier") {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.doneTyping(stateName);
      }, 500);
    }
  };

  setValue = (stateName, value, translated) => {
    if (translated) {
      let values = this.state[this.state.currentLang];
      if (!values) {
        values = {};
      }
      values[stateName] = value;
      this.setState({
        [this.state.currentLang]: values,
      });
    } else {
      this.setState({
        [stateName]: value,
      });
    }
    if (stateName === "identifier") this.checkIdentifier(stateName);
  };

  handleButtonGroupChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };

  handleInputChange = (stateName, evt, custom, translated) => {
    const value = evt?.target?.value ?? evt;
    this.setValue(stateName, value, translated);
  };

  expand = (expanded) => {
    this.setState({
      treeData: toggleExpandedForAll({
        treeData: this.state.treeData,
        expanded,
      }) ?? null,
    });
  };

  handleFormImport = (type, title) => {
    this.setState({
      typeImport: type,
      mapper: [],
      media: null,
      headers: null,
      importFile: null,
      importSep: ";",
      importValues: {},
      importLang: this.props.locales[0].node.id ?? null,
    });

    this.handleToggleDrawerImport("openFormImport");
  };

  handlerMutationExport = async () => {
    this.setState({
      disableExport: true,
    });
    try {
      if (this.hasErrors()) {
        this.props.snack(ALERT_ERROR, "Veuillez vérifier les champs invalides");
        return eventService.fire();
      }
      this.props.startLoading();
      let data = new FormData();
      data.append("name", this.state.exportName);
      data.append("local", this.state.exportLang);
      data.append("format", this.state.exportType);

      let urlencoded = new URLSearchParams(data).toString();

      request(
        `${process.env.REACT_APP_API}/export/excel/categories?${urlencoded}`,
        "get",
        null,
        "application/x-www-form-urlencoded"
      ).then(async (data) => {
        if (data.success) {
          window.open(
            `${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath +
            "." +
            (this.state.exportType === "xls"
              ? this.state.exportType + "x"
              : this.state.exportType)
            }`,
            "_blank"
          );
        }
        this.props.stopLoading();
        this.props.snack(ALERT_SUCCESS, `L'exportation a réussi !`, 8000);
      }).catch((err) => {
        console.log(err)
      });
    } catch (e) {
      this.props.snack(ALERT_ERROR, `L'exportation a échoué !`);
      this.props.stopLoading();
    }
    this.setState({
      disableExport: false,
      exportName: "",
      exportLang: this.props.locales[0].node.code ?? null,
      exportType: "csv",
    });
    this.handleToggleDrawerImport("openFormExport");
  };

  stateCallback = (stateName, value, custom, translated, callback) => {
    if (stateName === "importSep") {
      this.setState({
        importFile: null,
        importValues: {},
        importLang: this.props.locales[0].node.id ?? null,
      });
    }
    this.setState(
      {
        [stateName]: value?.target?.value ?? value,
      },
      callback
    );
  };

  handlerMutationImport = async () => {
    this.props.startLoading();

    let importConfig = {
      url: `${process.env.REACT_APP_API_ROOT}${this.state.media?.contentUrl ?? null
        }`,
      mapper: this.state.mapper,
      eavType: this.props.attributes.eavTypes.find(
        (e) => e.node.code === "category"
      ).node.id,
      locale: this.state.importLang,
      delimiter: this.state.importSep,
    };

    try {
      // todo thomas
      await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/file-imports`,
        "post",
        importConfig,
        undefined,
        true
      );
      await this.prepareTree();
      this.props.snack(
        ALERT_SUCCESS,
        `Votre fichier a été importé avec succès, son intégration dans Sinfin DXP sera exécutée lors du passage du CRON (Tâche automatique).`,
        6000
      );
      this.handleToggleDrawerImport("openFormImports");
      this.handleToggleDrawerImport("openFormImport");
      this.props.stopLoading();
    } catch (e) {
      this.props.snack(ALERT_ERROR, `L'import a échoué !`);
      this.props.stopLoading();
    }
  };

  firstTab = () => (
    <p>TESTING 1</p>
  )

  render() {
    const { treeData } = this.state;
    const { classes } = this.props;
    return (
      <PageWrapper>
        <TopPanel
          icomoon="picto-categorie"
          colorIcomoon={colors.blue.darker.hue300}
          title={this.props.t("products.categories.gerer")}
          subtitle={this.props.t("products.categories.completer")}
          handlerAdd={this.addCategory}
          textAdd={'+ ' + this.props.t("products.categories.create")}
          handlerImport={() => this.handleToggleDrawerImport("openFormImports")}
          textImport={this.props.t("products.categories.import")}
          searchHandler={false}
          // stats={this.stats}
          gradientColor1={colors.menu.regular}
          gradientColor2={colors.menu.darker}
          openForm={this.state.openForm}
          buttonAvailable={treeData ? true : false}
          windowWidth={this.props.windowWidth}
          currentLang={this.state.currentLang}
          handleLang={this.handleLang}
          locales={this.props.locales}
          hasBorder={true}
        />

        <Grid
          container
          direction="column"
          spacing={0}
          style={{
            // width:
            //   this.state.openForm && this.props.windowWidth > 1200
            //     ? '100%'
            //     : "100%",
            width: "100%",
            position: "relative",
            transition: "all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms",
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            flexWrap: 'nowrap',
            height: '100%',
          }}
        >
          <AccordionCustom title={'Filtres et Recherche'} forcedExpanded={true} detailsStyles={{
            padding: 16,
          }}>
            <SearchBar
              noIcon={true}
              value={this.state.searchString}
              placeholder={'Recherche'}
              onChange={(e) => this.handleInputChange("searchString", e)}
            />
          </AccordionCustom>
          {treeData ? (
            <TreeView
              typeOfTree={"categorie"}
              dataTree={treeData}
              duplicateCat={this.duplicateCat}
              editCat={this.editCat}
              addSubcategory={this.addSubcategory}
              expand={this.expand}
              onChange={(treeData) => this.setState({ treeData })}
              searchQuery={this.state.searchString}
              searchMethod={this.customSearchMethod}
              canAdd={true}
              canModify={true}
              canLog={true}
              canDelete={true}
              deleteCat={this.handleToggleDialog}
              handleClickNode={this.handleClickNode}
              secondaryAction={
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <BootstrapTooltip
                    title="Cliquez pour exporter les produits"
                    className={clsx(classes.tooltip)}
                  >
                    <Grid
                      onClick={
                        this.state.disableExport
                          ? null
                          : () =>
                            this.handleToggleDrawerImport("openFormExport")
                      }
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: this.state.disableExport
                          ? "inherit"
                          : "pointer",
                        alignItems: "center",
                        fontWeight: 'bold'
                      }}
                    >
                      <Typography style={{ color: colors.blue.darker.hue300, marginRight: 5, fontWeight: 'bold' }}>
                        {this.props.t("products.list.export.title")}
                      </Typography>
                      <GetApp
                        style={{
                          fontSize: 18,
                          marginTop: 2,
                          fill: this.state.disableExport
                            ? colors.grey.regular
                            : colors.blue.darker.hue300,
                        }}
                      />
                    </Grid>
                  </BootstrapTooltip>
                </Grid>
              }
            />
          ) : (
            <PageLoader />
          )}
        </Grid>


        {this.state.openForm ? (
          <LayoutBuilder
            isSublayout={false}
            opened={this.state.openForm}
            forClose={this.handleToggleDrawer}
            handlerMutation={this.handlerMutation}
            dataLayout={
              this.state.editForm === "edit"
                ? formCategoriesEdit(
                  this.state.cat_id,
                  this.state.categoriesData,
                  this.state.parent,
                  this.state.inputAttributes,
                  this.state.currentLang,
                  this.state.errors,
                  this.state.seeErrors,
                  this.handleMediaPicker
                )
                : formCategoriesAdd(
                  this.state.categoriesData,
                  this.state.parent,
                  this.state.inputAttributes,
                  this.state.currentLang,
                  this.state.errors,
                  this.state.seeErrors,
                  this.handleMediaPicker,
                  false,
                  this.props.t
                )
            }
            icomoon={
              this.state.editForm === "edit"
                ? "ico-modifier-categorie"
                : "ico-ajouter-categorie"
            }
            allState={this.state}
            stateCallback={this.handleInputChange}
            errorCallback={this.handleFormError}
            // deleteMutation={
            //   this.state.editForm === "edit" ? this.handleToggleDialog : null
            // }
            // deleteText={
            //   this.state.editForm === "edit" ? "Supprimer la catégorie" : null
            // }
            // deleteButton={this.state.editForm}
            validateButton={true}
            currentLang={this.state.currentLang}
            handleLang={this.handleLang}
            handleButtonGroupChange={this.handleButtonGroupChange}
          />
        ) : null}

        <LayoutBuilder
          isSublayout={false}
          opened={this.state.openFormImports}
          forClose={() => this.handleToggleDrawerImport("openFormImports")}
          dataLayout={importCategoriesTypesConfig}
          drawerWidth={this.props.drawerWidth}
          dataCard={[
            {
              libelle: this.props.t("products.categories.importation.flux"),
              bicoloreText: "Flux",
              colorhover: "#0273A5",
              picto: importFlux,
              disabled: true,
              textButton: this.props.t("products.categories.import"),
              description: this.props.t(
                "products.categories.importation.fluxText"
              ),
              catalogDescription: "Veuillez compléter les champs ci-dessous",
              onClick: () => this.handleFormImport("flux"),
            },
            {
              libelle: this.props.t("products.categories.importation.api"),
              bicoloreText: "Api",
              colorhover: "#0273A5",
              picto: importAPI,
              disabled: true,
              textButton: this.props.t("products.categories.import"),
              description: this.props.t(
                "products.categories.importation.apiText"
              ),
              catalogDescription: "Veuillez compléter les champs ci-dessous",
              onClick: () => this.handleFormImport("api"),
            },
            {
              libelle: this.props.t("products.categories.importation.file"),
              bicoloreText: this.props.t("products.list.cardproduct.file"),
              colorhover: "#0273A5",
              picto: importFichier,
              disabled: false,
              textButton: this.props.t("products.categories.import"),
              description: this.props.t(
                "products.categories.importation.fileText"
              ),
              catalogDescription: "Veuillez compléter les champs ci-dessous",
              onClick: () => this.handleFormImport("fichier"),
            },
          ]}
        />

        <LayoutBuilder
          isSublayout={true}
          opened={this.state.openFormImport}
          icomoon={"ico-import-fichier"}
          forClose={() => this.handleToggleDrawerImport("openFormImport")}
          dataLayout={importCategoriesConfig}
          drawerWidth={this.state.drawerWidth}
          handleCancel={this.handleCancel}
          handlerMutation={this.handlerMutationImport}
          allState={this.state}
          stateCallback={this.stateCallback}
          stepperButtonDisabled={[() => this.state.headers === null, null]}
          stepperButtonAction={[null, null]}
          backStepperButtonAction={[null, null, null]}
        />
        <LayoutBuilder
          isSublayout={true}
          opened={this.state.openFormExport}
          icomoon={"ico-import-fichier"}
          forClose={() => this.handleToggleDrawerImport("openFormExport")}
          dataLayout={exportProductsConfig(
            this.props.locales,
            "categories",
            this.props.t
          )}
          drawerWidth={this.state.drawerWidth}
          handleCancel={this.handleCancel}
          handlerMutation={this.handlerMutationExport}
          allState={this.state}
          stateCallback={this.stateCallback}
          validateButton={true}
          errorCallback={this.handleFormError}
          checkError={() => { }}
        // stepperButtonDisabled={[() => this.state.headers === null, null]}
        // stepperButtonAction={[null, null]}
        // backStepperButtonAction={[null, null, null]}
        />

        <DialogModal
          icon={true}
          type='delete'
          open={this.state.openDialog}
          title={'Êtes-vous sûr de vouloir supprimer cette catégorie ?'}
          primaryAction={this.deleteMutation}
          secondaryAction={this.handleToggleDialog}
          windowWidth={this.props.windowWidth}
        >
          <Typography variant="body2">
            Si vous supprimez cette catégorie celle-ci ne sera plus
            accessible. Si vous ne souhaitez pas la supprimer, annulez la
            suppression en cliquant sur annuler. <strong>Cette action est irréversible</strong></Typography>
        </DialogModal>
      </PageWrapper>
    );
  }

  prepareTree() {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          query: GET_CATEGORIES_ONLY,
          variables: {
            exists: [
              {
                catalog: false,
              },
            ],
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          let cats = result.data.categories;
          const copyOfCats = JSON.parse(JSON.stringify(cats)); //* Please, create a deep copy of an array or object before modifying it (extending, etc)

          let data = copyOfCats.filter(
            (e) => e.parent === null && e.libelle === "Root"
          );

          this.setState({ categoriesData: copyOfCats });
          this.setState({
            inputAttributes: this.props.attributes.category.attributes ?? [],
          });

          for (let parent of data) {
            this.convertToNode(parent, true);
            this.populateChildren(copyOfCats, parent);
          }
          this.setState({ treeData: this.copyArrayOfObjects(data)[0].children });
          if (
            getParams(this.props).includes("formOpen") ||
            this.props.history.location?.state?.formOpen
          ) {
            this.addCategory();
          }
          resolve();
        }).catch((err) => {
          console.log(err)
        });
    });
  }

  convertToNode(data, isRoot = false) {
    let allNames = data.categoryDatas.edges.filter(
      (e) => e.node.attribute.identifier === "category_name"
    );

    let getTraduction = allNames.find(
      (translation) => translation.node.locale.code === this.state.currentLang
    );

    data.title =
      data.libelle === "Root"
        ? "/"
        : getTraduction?.node?.value ?? allNames[0]?.node.value ?? data.libelle;
    data.isDirectory = true;
    data.isRoot = isRoot;
    data.expanded = isRoot ? true : data.expanded;
    data.attributes = [];

    for (let { node } of data.categoryDatas.edges) {
      let found = false;

      for (let attribute of data.attributes) {
        if (attribute.id === node.attribute.id) {
          found = true;

          // add locale to existing attribute
          attribute.locales.push({
            value: node.value,
            media: node.media,
            id: node.locale.id,
            code: node.locale.code,
            categoryDataId: node.id, // category data id
          });
        }
      }

      if (!found) {
        // create attribute and add locale
        data.attributes.push({
          id: node.attribute.id,
          identifier: node.attribute.identifier,
          attributeType: node.attribute.attributeType,
          locales: [
            {
              value: node.value,
              media: node.media,
              id: node.locale.id,
              code: node.locale.code,
              categoryDataId: node.id,
            },
          ],
        });
      }
    }
  }

  populateChildren(cats, parent) {
    parent.children = cats.filter(
      (e) => e.parent !== null && e.parent.id === parent.id
    );

    for (let child of parent.children) {
      this.convertToNode(child);
      this.populateChildren(cats, child);
    }
  }

  copyArrayOfObjects = (array) => array.map((a) => ({ ...a })); // be careful, only breaks references at objects level

  goTo = (route) => this.props.history.push(route);
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message, duration) =>
      dispatch({ type: SNACK, payload: { type, message, duration } }),
  };
};

export default withTranslation()(
  withRouter(
    withApollo(
      withStyles(styles)(
        connect(mapStateToProps, mapDispatchToProps)(ProductsCategories)
      )
    )
  )
);
