import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Link from 'next/link';
import RibbonBottomRight from './image/ruban-3.png';
import StarBlue from './image/asterix-bleu.svg';
import StarRed from './image/asterix-rouge.svg';

const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const CopyrightBook = (props) => {
    let {
        id = null, 
        data,
        noSticky,
        inputCallback = null,
        fullWidth = false,
        noButton = false,
        spread = false,
        preview = false,
        emailTemplate = '',
    } = props; 
    return (
        <>
            <div className="copyrightWrapper">
                { !preview && props.children}
                <div className="container">
                    <div className="starsSection">
                        <img src={StarBlue} alt="Etoile bleu" />
                        <img src={StarRed} alt="Etoile rouge" />
                        <img src={StarBlue} alt="Etoile bleu" />
                    </div>
                    {
                        process.env.REACT_APP_RESTRICTED !== "true" && data.blocks.copyrightLonger.inputs.value?.value ? (
                            <div className="copyright longer" dangerouslySetInnerHTML={{__html: data.blocks.copyrightLonger.inputs.value?.value}} />
                        ) : null
                    }
                    <div className="copyright">{data.blocks.copyright?.inputs.value?.value}</div>
                </div>
                <div className="ribbon_bottom_right">
                    <img src={RibbonBottomRight} alt="Ruban décoratif en bas à droite"/>
                </div>
            </div>
        </>
    );
};

CopyrightBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default CopyrightBook;
