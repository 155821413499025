import moment from 'moment';

export const catalogTableMapper = async (flows,lang) =>{
    try {
        let results = flows.map((flow,index)=>{
            return {
                id:flow.node.id,
                libelle: flow.node.libelle,
                description: flow.node.description,
                status: flow.node.status,
                autosync: flow.node.autosync,
                media: flow.node.media?.filePath,
                startAt: moment(flow.node.startAt).format('DD/MM/YYYY à HH:MM'),
                updatedAt: moment(flow.node.updatedAt).format('DD/MM/YYYY à HH:MM'),
                endAt: moment(flow.node.endAt).format('DD/MM/YYYY à HH:MM'),
            }
        })
        return await results
    } catch (error) {
        console.log(error)   
    }
}

export const catalogCardMapper = async (flows,lang) =>{
    try {
        let results = flows.map((flow,index)=>{
            return {
                id:flow.node.id,
                libelle: flow.node.libelle,
                description: flow.node.description,
                status: flow.node.status,
                autosync: flow.node.autosync,
                media: flow.node.media?.filePath,
                startAt: moment(flow.node.startAt).format('DD/MM/YYYY à HH:MM'),
                updatedAt: moment(flow.node.updatedAt).format('DD/MM/YYYY à HH:MM'),
                endAt: moment(flow.node.endAt).format('DD/MM/YYYY à HH:MM'),
            }
        })
        return await results
    } catch (error) {
        console.log(error)   
    }
}