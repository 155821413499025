import React,{ useState } from "react";
import styled from "styled-components";
import Div from "../shared/div";
import H2 from "../shared/h2";

const DivGrid = styled(Div)`
    display:grid;
    grid-template-columns:repeat(${props=>props.grid? props.grid :3},max-content);
    gap:${props=>props.gap}px;
    justify-content:space-between;    
`

const DivText = styled(Div)`    
    width:max-content;
    p{
        margin:0;
    }
`;


const BlocMultipleText = props=>{
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        data,
    } = props;


    return (
        <Div style={{position: 'relative'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} id={data.inputs.identifiant?.value} className={data.inputs.className?.value}>
            { hover && !preview && props.children }
            <Div
            style={{
                margin: data.inputs.noPaddingHorizontal.value? 0:"auto",
                width:data.inputs.noPaddingHorizontal.value?"100%":"90%",
                paddingBottom:`${data.inputs.paddingBottom.value}px`,
                paddingTop:`${data.inputs.paddingTop.value}px`,                
            }} 
            >
                {
                    data.blocks.title.inputs.value.value ?                
                    <H2 
                        color={data.blocks.title.inputs.color?.value}
                        fontSize={data.blocks.title.inputs.fontSize?.value}                        
                        style={{
                            textAlign: data.blocks.title.inputs.textAlign?.value,
                            marginLeft: data.blocks.title.inputs.textAlign?.value === 'center' ? 'auto' : null,
                            marginRight: data.blocks.title.inputs.textAlign?.value === 'center' ? 'auto' : null,
                            paddingBottom:30
                        }}
                    >
                        {data.blocks.title.inputs.value.value}
                    </H2>
                    :null
                }
                <DivGrid grid={data.inputs.gridNumber.value} gap={data.inputs.gridGap.value}>
                    {
                        data.blocks.texts.config.length > 0?
                        data.blocks.texts.config.map((text,i)=>{                            
                            return  (
                            <DivText
                            key={i}
                            style={{
                                color:text.inputs.color?.value,
                                fontSize:text.inputs.fontSize?.value,
                                textAlign:text.inputs.textAlign?.value                                
                            }}                                 
                            >
                                <div dangerouslySetInnerHTML={{__html:text.inputs.value.value}}/>
                            </DivText>)                            
                        })
                        :null
                    }
                </DivGrid>
            </Div>
        </Div>
    )
}

export default BlocMultipleText