import { Box,  Grid, Tab, Tabs, Typography } from "@mui/material"
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../../../js/constants/action-types"
import colors from "../../../../../../config/theme/colors"
import { withStyles } from "@mui/styles"
import InputBuilder from "../../../../../ui/form/InputBuilder"
import { useState } from "react"
import { useEffect } from "react"
import PageLoader from "../../../../../ui/loadings/page-loader/PageLoader"

const CustomTabs = withStyles({
  root: {
    borderBottom: `0.5px solid ${colors.grey.border}`,
    minHeight:"40px",
  },  
  flexContainer:{
    height:"100%"
  }
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',    
    fontWeight: "bold",    
    color:"#000",
    backgroundColor:"#FAFAFA",
    fontSize:14,
    minWidth:"unset",
    minHeight:"unset",
    height:"auto !important",
  },
  selected: {
    backgroundColor:"#FFF",
  },
}))((props) => <Tab indicatorColor={'transparent'} disableRipple {...props} />);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
      style={{
        padding:15,
        backgroundColor:"#FFF",
        overflow:"auto"
      }}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

const ContentAddSidebar=props=>{
  const [tabValue,setTabValue] =useState(0)
  const [categories,setCategories] =useState([])

  const handleChange = (event, newValue) => {
    setTabValue(newValue)    
  };

  useEffect(()=>{    
    setCategories(props.dataLayout.formConfig.children.filter(e=>e.showInSidebar))
  },[props])
    return (
      <Box
        style={{
          border: `0.5px solid ${colors.grey.border}`,
          height:"100%",
          display:"grid",
          gridTemplateRows:"auto 1fr",
          overflow:"hidden"
        }}
      >
        {
          !props.allState.ready ?
          <PageLoader/>
          :<>
          <CustomTabs
            value={tabValue}
            variant="fullWidth"
            indicatorColor=""          
            onChange={handleChange}                   
          >
            {
              categories.map((c,i)=>{                                          
                return <CustomTab label={c.labelName} key={i} />                                        
              })
            }          
          </CustomTabs>
          {
              categories.map((c,i)=>{              
                  return (
                    <TabPanel value={tabValue} index={i} key={i}>   
                      {
                        c.optionsInputs.filter(e=>!e.showInCard).map((option,i)=>{     
                            return (
                              <Grid key={i} container>
                                <Grid item xs={12}>
                                    <Typography style={{marginBottom:5}}>{option.label}{option.required ? '*' : null}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <InputBuilder
                                      value={
                                        option.translated
                                            ? option.valueWithFlags
                                                ? props.allState[props.allState.currentLang][option.stateName]?.value
                                                : props.allState[props.allState.currentLang][option.stateName]
                                            : option.valueWithFlags
                                                ? props.allState[option.stateName]?.value
                                                : props.allState[option.stateName]
                                    }
                                      allState={props.allState}
                                      input={option}
                                      handleTreeChange={props.handleTreeChange}
                                      expandNodes={props.expandNodes}
                                      treeEditCat={props.treeEditCat}
                                      copyCategories={props.copyCategories}
                                      treeAddCat={props.treeAddCat}
                                      errorCallback={props.errorCallback ? (error) => props.errorCallback(option.stateName, error) : () => { }}
                                      stateCallback={(evt, custom) => props.stateCallback(option.stateName, evt, custom, option.translated)}
                                      stateCallbackOriginal={props.stateCallback}
                                      handleButtonGroupChange={props.handleButtonGroupChange
                                          ? props.handleButtonGroupChange
                                          : null}
                                      gridItems={props.gridItems}
                                      loadGrid={props.loadGrid}
                                      addProducts={props.addProducts}
                                      merchModal={props.merchModal}
                                      resetReloadListing={props.resetReloadListing}
                                      catalogDatas={props.catalogDatas}
                                  />
                                </Grid>
                              </Grid>
                            )
                        })
                      }               
                    </TabPanel>
                  )
              })
            } 
          
          </>
        }

      </Box>
    )
  }


const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
        attributeGroups: state.attributeGroups
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ContentAddSidebar)));