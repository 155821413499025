import { brandsCardMapper, brandsTableMapper } from "./brands";
import { catalogCardMapper, catalogTableMapper } from "./catalogs";
import { companiesGridMapper, companiesListMapper } from "./companies";
import { customersListMapper } from "./customers";
import { flowListMapper } from "./flow";
import { importsTableMapper } from "./imports";
import { productsCardMapper, productsTableMapper } from "./products";
import { topMessagesMapper } from "./topMessages";
import { userCardsMapper } from "./users";
import { userGroupCardsMapper } from "./usergroups";
import { guidelineCardMapper } from "./guideline";
import { assetCardMapper } from "./assets";
import { attributesConfigMapper, completenessGroupsMapper } from "./settings";

const mapperList = {
  catalogsTable: catalogTableMapper,
  catalogsCard: catalogCardMapper,
  brandsTable: brandsCardMapper,
  brandsCard: brandsTableMapper,
  productsCard: productsCardMapper,
  productsTable: productsTableMapper,
  importsTable: importsTableMapper,
  usersCard: userCardsMapper,
  userGroupsCard: userGroupCardsMapper,
  CardGuideline: guidelineCardMapper,
  assetCard:assetCardMapper,
  attributesConfigMapper:attributesConfigMapper,
  completenessGroupsMapper:completenessGroupsMapper,

  companiesGridMapper: companiesGridMapper,
  companiesListMapper: companiesListMapper,
  customersListMapper: customersListMapper,
  flowListMapper: flowListMapper,
  topMessagesMapper: topMessagesMapper,
};

export const itemMapper = async (formats, items, lang, customDatas) => {
  let result = {};
  for (let format of formats) {
    if (format.mapper) {
      let mapper = mapperList[format.mapper];
      let formatItems = await mapper(items, lang, customDatas);
      result[format.view] = formatItems;
    } else {
      result[format.view] = items;
    }
  }
  return result;
};
