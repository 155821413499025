import moment from "moment"
import { FormInputTypes } from "../../../../../builder/shareable/types"

export const listSettings =(props)=> {
    return {
    table : {
        style : {
            type : 'striped',
        },
        columns : [
            {
                label   : 'Status',
                field   :  'state',
                cellType : 'stateStatus',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'Date',
                field   :  'created_at',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'Distributeur',
                field   :  'channels',
                cellType : 'avatar',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'N°SKU',
                field   :  'sku',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : 'Priorité',
                field   :  'priority',
                cellType : 'select',
                cellProps : {
                    width : '10%',
                },
                input:{
                    type: 'select',
                    isContainerized: true,
                    label: 'products.categories.exporter.filename',
                    helper: {
                        text: '',
                        link: false,
                    },
                    width:"80%",
                    stateName: 'priority',
                    translated: false,
                    disabled:true,
                    value:[
                        {
                            label:"Moyenne",
                            value:1,
                        },
                        {
                            label:"Haute",
                            value:2,
                        },
                    ]
                },
            },
            {
                label   : 'Tâche',
                field   :  'taskState',
                cellType : 'task',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : "Description de l'erreur",
                field   :  'errors',
                cellType : 'cta_drawer',
                cellProps : {
                    width : '10%',
                }
            },
            {
                label   : "",
                field   :  'ok',
                cellType : 'link',
                cellProps : {
                    width : '10%',
                }
            },
        ]   
    }
}}

export const listMappers = [
    {
        view : 'table',
        mapper : 'workflowMapper'
    }
]

export const perPageOptions = {
    'table_workflow' : ['12']
}


export const filtersSettings =(props)=>{
    return [
    {
        xs: 3,
        required: false,
        disabled: false,
        label:'Rechercher par SKU...',
        type: FormInputTypes.TEXT,
        stateName: 'sku',
        helper: {
            position: 'top',
            text: 'SKU number',
            link: false,
        }
    },
    {
        xs: 2,
        required: false,
        disabled: false,
        defaultValue:" ",
        type: FormInputTypes.SELECT,
        stateName: 'state',
        helper: {
            position: 'top',
            text: 'Statut',
            link: false,
        },
        value:[
            {
                value: " ",
                label: 'Tous les statuts'
            },
            {
                value: 2,
                label: 'Contribution'
            },
            {
                value: 1,
                label: 'Correction'
            },
        ]
    },
    {
        xs: 2,
        required: false,
        disabled: false,
        defaultValue:" ",
        type: FormInputTypes.SELECT,
        stateName: 'taskState',
        helper: {
            position: 'top',
            text: 'Statut de la tâche',
            link: false,
        },
        value:[
            {
                value: " ",
                label: 'Tous les statuts'
            },
            {
                value: 4,
                label: 'À faire'
            },
            {
                value: 1,
                label: 'En cours'
            },
            {
                value: 2,
                label: 'En recette'
            },
            {
                value: 3,
                label: 'Résolu'
            }
        ]
    },
    {
        xs: 2,
        required: false,
        disabled: false,
        defaultValue:" ",
        type: FormInputTypes.SELECT,
        stateName: 'priority',
        helper: {
            position: 'top',
            text: 'Priorité',
            link: false,
        },
        value:[
            {
                value: " ",
                label: 'Toutes les priorités'
            },
            {
                value: 1,
                label: 'Moyenne'
            },
            {
                value: 2,
                label: 'Haute'
            }
        ]
    },
    {
        xs: 1,
        required: false,
        disabled: false,
        type: FormInputTypes.DATE_FROM_TO,
        label: 'date',
        stateName: 'dateFrom',
        format:"yyyy-MM-DD 00:00:00",
        startValue:moment().startOf('month').format('yyyy-MM-DD'),
        maxDate:moment().format('yyyy-MM-DD'),
        helper: {
            position: 'top',
            text: 'Date de début',
            link: false,
        }
    },
    {
        xs: 1,
        required: false,
        disabled: false,
        type: FormInputTypes.DATE_FROM_TO,
        label: 'date',
        stateName: 'dateTo',
        format:"yyyy-MM-DD 23:00:00",
        startValue:moment(),
        maxDate:moment().format('yyyy-MM-DD'),
        helper: {
            position: 'top',
            text: 'Date de fin',
            link: false,
        }
    },
]
}