import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from '@apollo/client/react/hoc';

import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { SET_GUIDELINE } from '../../../../js/constants/action-types';
import { ROUTE_SHELLS_CUSTOM_LIST } from '../../../../js/constants/route-names';
import { UNIQUE_EAV_CODE, GET_ATTRIBUTES_BY_TYPE } from "../../../../queries/attributes";
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';

import colors from '../../../../config/theme/colors';

import { Grid, Box } from '@mui/material';
import styled from 'styled-components';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import Empty from '../../../../assets/pictos/empty-picto/empty_guidelines.png';

import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import formShellCustom from './config/formShellCustom.config';
import Typography from '../../../ui/typography/Typography';
import Button from '../../../ui/button/Button';
import slugify from 'slugify';
import { eventService } from '../../../../js/services/event.service';
import DialogModal from '../../../ui/dialog/DialogModal';
import { CREATE_SHELL, UPDATE_SHELL, DELETE_SHELL, CREATE_SHELL_DATA, DELETE_SHELL_DATA, GET_DATAS_OF_A_SHELL, UPDATE_SHELL_DATA } from '../../../../queries/shells';
import * as moment from "moment";
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import { checkRouting } from '../../../../js/utils/checkRouting';


const BoxCustom = styled(Box)`
    width: auto;
`;
const BoxCustomAttributs = styled(Box)`
    background: ${colors.grey.lighter.hue980};
`;
const TitleAttributs = styled(Typography)`
    font-weight: bold;
    color: ${colors.black.regular};
`;
const TypographyAttributs = styled(Typography)`
    color: ${colors.grey.regular};
    p{
        margin: 0;
    }
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
const SpanColor = styled.span`
    color: ${colors.blue.darker.hue300};
    font-weight: bold;
`;
const Title = styled(Typography)`
    font-weight: bold;
`
const Subtitle = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
`;
const AttributContainer = styled(Grid)`
    margin-bottom: 16px;
`;

class Shells extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            ready: false,
            content: {
                emptyTitle: "Vous n’avez pas encore paramétré vos enveloppes",
                emptySubtitle: "Cliquez ci-dessous commencer la configuration",
                emptyTxtBtn: "Créer mon enveloppe",
                emptyPicto: Empty,
            },
            editForm: '',
            shellName: null,
            errors: {},
            seeErrors: false,
            openForm: false,
            openDialog: false,
            currentShell: null,
            customAttributes: [],
            sku: null,
        }
        this.typingTimer = null;
    }

    resetState() {
        this.setState({
            sku: null,
            shellName: null,
            seeErrors: false,
        });
    }

    deleteShell = () => {
        this.props.startLoading()
        this.props.client.query({
            query: DELETE_SHELL,
            fetchPolicy: 'no-cache',
            variables: {
                "id": `/api/shells/${this.props.match.params.idShell}`
            }
        }).then(result => {
            this.props.stopLoading();
            if (result.data.deleteShell) {
                this.props.snack(ALERT_SUCCESS, 'Enveloppe supprimée !');
                this.goTo(ROUTE_SHELLS_CUSTOM_LIST.replace(':alias', this.props.match.params.alias).replace(':id', this.props.match.params.id))
            }
        }).catch((err)=>{
            console.log(err)
        });
    }

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    setValue = (stateName, value, translated) => {
        // if(stateName === 'product_price' && value.includes(',')){
        //     value = value.replace(',', '.');
        // }
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            });
        }
        if (stateName === "sku")
            this.checkIdentifier(stateName);
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
    };

    doneTyping = (stateName) => {
        if (stateName === 'shellName') {
            this.setState({
                attributeIdentifier: slugify(this.state.shellName, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g })??null,
            })
        }

        if (this.state.shellName) {
            this.props.client.query({
                query: UNIQUE_EAV_CODE,
                fetchPolicy: 'no-cache',
                variables: {
                    "code": this.state[stateName]
                }
            }).then(result => {
                if (result.data.eavTypes.edges.length !== 0) {
                    eventService.fire({ stateName: 'shellName', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.' });
                    let errors = this.state.errors;
                    errors[stateName] = true;
                    this.setState({
                        errors:errors??{}
                    })
                }
            }).catch((err)=>{
                console.log(err)
            });
        }
        this.forceUpdate();
    };

    handleToggleDrawer = () => {
        this.setState({
            openForm: !this.state.openForm??false
        });
    };

    prepareAttributes() {
        return new Promise(async (resolve, reject) => {
            let group = this.state.allGroups;
            let customAttributes = group.filter(e => !e.node.isSystem);
            let imageAttributes = customAttributes.filter(e => e.node.attributeType.input === 'image');

            this.setState({
                customAttributes:customAttributes??null,
                imageAttributes:imageAttributes??null,
            });

            resolve();
        });
    }

    prepareAttributeValues() {
        return new Promise((resolve, reject) => {
            for (let locale of this.props.locales) {
                let values = {};

                for (let attribute of this.state.attributes) {
                    let attributeLocale = attribute.locales.find(e => e.code === locale.node.code);
                    let defaultLocale = attribute.locales.find(e => e.code === this.state.currentLang);

                    if (!attributeLocale) {
                        if (attribute.attributeType.input === 'select') {
                            values[attribute.identifier] = defaultLocale?.value ?? attribute.locales[0];
                        }
                    } else {
                        switch (attribute.attributeType.input) {
                            case 'file':
                            case 'image':
                                values[attribute.identifier] = {
                                    // data: attributeLocale.media ? attributeLocale.media.filePath : null,
                                    filePath: attributeLocale.media ? attributeLocale.media.filePath : null,
                                    file: null,
                                    changed: false,
                                    type: attributeLocale.media?.type,
                                    name: attributeLocale.media?.name,
                                };
                                break;
                            default:
                                values[attribute.identifier] = attributeLocale.value;
                        }
                    }
                }

                this.setState({
                    [locale.node.code]: values,
                });
            }
            resolve()
        })
    }

    prepareAttributesLocales(result) {
        return new Promise((resolve, reject) => {
            let attributes = [];

            for (let { node } of result.data.shell.shellDatas.edges) {
                let found = false;

                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;

                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        });
                    }
                }

                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        isSystem: node.attribute.isSystem,
                        attributeOptions: node.attributeOptions,
                        translationLabel: node.attribute.translation.translationDatas.edges,
                        locales: [{
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        }]
                    });
                }
            }

            this.setState({ attributes }, resolve);
        });
    }


    initShell = () => {
        return new Promise(async (resolve, reject) => {
            this.props.client.query({
                query: GET_ATTRIBUTES_BY_TYPE,
                variables: { "id": `/api/eav-types/${this.props.match.params.id}` },
                fetchPolicy: "no-cache",
            }).then(GET_ATTRIBUTES_BY_TYPE_RESULT => {
                this.setState({
                    sku: null,
                    // groupAttribut : GET_ATTRIBUTES_BY_TYPE_RESULT.data.attributeGroups.edges.find(e => e.node.identifier === 'default').node.id,
                    categories: [], // product categories
                    allGroups: GET_ATTRIBUTES_BY_TYPE_RESULT.data.eavType.attributes.edges.filter(e => (!e.node.isSystem && !e.node.isForContent) || e.node.identifier === 'default'),
                    attributes: [],
                    // status: true
                }, async () => {
                    await this.prepareAttributes();
                    if (this.props.match.params.idShell === "new") {
                        this.setState({
                            editForm: 'add',
                        })
                        await this.prepareAttributeValues();
                        this.handleToggleDrawer();
                        this.setState({
                            ready: true
                        });
                    } else {
                        this.props.client.query({
                            query: GET_DATAS_OF_A_SHELL,
                            fetchPolicy: 'no-cache',
                            variables: { "id": `/api/shells/${this.props.match.params.idShell}` }
                        }).then(async result => {
                            if (result.data) {
                                this.prepareAttributesLocales(result);
                                await this.prepareAttributeValues();
                                this.setState({
                                    editForm: 'edit',
                                    sku: result.data.shell.sku,
                                    ready: true,
                                })
                                // this.handleToggleDrawer();
                            }
                        }).catch((err)=>{
                            console.log(err)
                        });
                    }

                });
            }).catch((err)=>{
                console.log(err)
            });
            resolve();
        })
    }

    componentDidMount = () => {
        checkRouting(this.props);
        this.initShell();
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors:errors??{} });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.attributes)
            return null;

        let attribute = this.state.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    saveAttributes = shell => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.customAttributes;

            for (let attribute of attributes) {
                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code][attribute.node.identifier];
                    let currentTranslation = this.getAttributeTranslatedValue(attribute.node.id, locale.node.id);
                    let isMedia = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';
                    if (formValue && isMedia) {

                        /*if (!formValue.changed)
                            continue;*/
                        isMedia = true;
                        /*formValue   = formValue.file;*/
                    }

                    if (formValue) {
                        // let resultMedia = null;

                        /* if (isMedia) { 
                             let formData = new FormData();
     
                             formData.append('file', formValue);
                             formData.append('type', formValue.type);
         
                             resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                         }*/
                        if (currentTranslation) {
                            // UPDATE STEP

                            let variables = {
                                "id": currentTranslation.productDataId,
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "shell": shell,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.id;
                            }

                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select') {
                                    variables.value = formValue;
                                }

                            await this.props.client.mutate({
                                mutation: UPDATE_SHELL_DATA,
                                variables
                            });
                        } else {
                            // CREATE STEP
                            let ADD_SHELL_DATA_RESULT = ''
                            let variables = {
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "shell": shell,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.id;
                            }

                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select') {
                                    variables.value = formValue;
                                }
                            ADD_SHELL_DATA_RESULT = await this.props.client.mutate({
                                mutation: CREATE_SHELL_DATA,
                                variables
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP

                        await this.props.client.mutate({
                            mutation: DELETE_SHELL_DATA,
                            variables: { "id": currentTranslation.productDataId }
                        });
                    }
                }
            }

            resolve();
        });
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
            this.props.startLoading();
            let variables = {
                'id': `/api/shells/${this.props.match.params.idShell}`,
                'sku': this.state.sku,
                'eavType': `/api/eav-types/${this.props.match.params.id}`,
                'createdAt': moment().format('YYYY-MM-DD'),
                'updatedAt': moment().format('YYYY-MM-DD'),
                'status': true,
            };
            if (this.state.editForm === "add") {
                const SHELL_RESULT_CREATE_RESULT = await this.props.client.mutate({
                    mutation: CREATE_SHELL,
                    variables
                });
                await this.saveAttributes(SHELL_RESULT_CREATE_RESULT.data.createShell.shell.id);
            } else {
                await this.props.client.mutate({
                    mutation: UPDATE_SHELL,
                    variables
                });
                await this.saveAttributes(`/api/shells/${this.props.match.params.idShell}`);
            }

            this.handleSuccess();
        } catch (e) {
            this.handleError(e);
        }
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        if (this.state.editForm === "add") {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Enveloppe ajoutée !');
            this.goTo(ROUTE_SHELLS_CUSTOM_LIST.replace(':alias', this.props.match.params.alias).replace(':id', this.props.match.params.id))
        }
        else {
            this.setState({
                ready: false,
            })
            this.handleToggleDrawer();
            this.props.snack(ALERT_SUCCESS, 'Enveloppe modifiée !');
            this.initShell();
        }
        // await setRedux(false,true,false,false);
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog??false
        });
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    };
    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
    };

    handleClose = async () => {
        await this.initShell();
        this.handleToggleDrawer()
    }

    render() {
        let map = attribute => {
            const defaultLabel = attribute.node.translation.translationDatas.edges[0];

            const label = attribute.node.translation.translationDatas.edges.find(
                lang => lang.node.locale.code === this.state.currentLang
            );

            let values = [];

            for (let locale of this.props.locales) {
                values.push({
                    code: locale.node.code,
                    value: this.state[locale.node.code]?.[attribute.node.identifier] ?? null
                });
            }

            const defaultValue = values.find(e => attribute.node.attributeType.input === 'file' ? e.value?.data ? e.value?.data ?? null : e.value : e.value);
            const value = values.find(e => e.code === this.state.currentLang);
            let local = moment.tz.guess();
            const finalAttributeValue = attribute.node.attributeType.input === 'file'
                ? (value?.value?.file ?? null)
                    ? value.value.file.name
                    : (value?.value?.data ?? null)
                        ? value.value.data
                        : (defaultValue?.value?.file ?? null)
                            ? defaultValue.value.file.name
                            : (defaultValue?.value?.data ?? null)
                                ? defaultValue?.value?.data
                                : defaultValue?.value?.filePath
                : attribute.node.attributeType.input === 'date'
                    ? value?.value
                        ? moment.tz(value?.value, local).format('L')
                        : moment.tz(defaultValue?.value, local).format('L') ?? null
                    : value?.value ?? (defaultValue?.value ?? null);

            let finalAttributeOptionValue = null;

            if (attribute.node.attributeType.input === 'select') {
                if (finalAttributeValue) {
                    let option = attribute.node.attributeOptions.edges.find(e => e.node.id === finalAttributeValue);

                    if (option) {
                        const optionDefaultLabel = option.node.translation.translationDatas.edges[0];

                        const optionLabel = option.node.translation.translationDatas.edges.find(
                            lang => lang.node.locale.code === this.state.currentLang
                        );

                        finalAttributeOptionValue = optionLabel?.node.value ?? optionDefaultLabel?.node.value ?? option.node.identifier;
                    }
                }
            }

            return (
                (finalAttributeValue || finalAttributeOptionValue) !== null ?
                    <AttributContainer container direction="row" key={attribute.node.identifier}>
                        <Grid item xs={8} >
                            <TitleAttributs variant="body2">
                                {
                                    label?.node.value
                                    ?? defaultLabel?.node.value
                                    ?? attribute.node.identifier
                                }
                            </TitleAttributs>
                        </Grid>
                        <Grid item xs={4}>
                            {
                                attribute.node.attributeType.input === 'textarea' ?
                                    <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: finalAttributeValue }} />
                                    :
                                    attribute.node.attributeType.input === 'file' ?
                                        <a target="__blank" href={`${process.env.REACT_APP_MEDIAS}/${defaultValue.value.file?.name || finalAttributeValue}`}>
                                            <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }}>
                                                {defaultValue.value.name}
                                            </TypographyAttributs>
                                        </a>
                                        :
                                        attribute.node.attributeType.input === 'image' ?
                                            <>
                                                <p>{!finalAttributeValue?.filePath ? (<strong style={{ maxWidth: '50%', paddingLeft: 8 }}>Aucune image</strong>) : null}</p>
                                                {finalAttributeValue?.filePath ? (<img style={{ maxWidth: '50%', paddingLeft: 8 }} src={`${process.env.REACT_APP_MEDIAS}/${finalAttributeValue?.filePath}`} />) : null}
                                            </>

                                            :
                                            <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }}>
                                                {attribute.node.attributeType.input === 'select' ? finalAttributeOptionValue : finalAttributeValue}
                                            </TypographyAttributs>
                            }
                        </Grid>
                    </AttributContainer>
                    : null
            );
        };
        return (
            <div style={{ width: "100%" }}>
                <TopPanel
                    icomoon="icon-dashboard"
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Enveloppes"
                    subtitle="Gérer vos enveloppes Sinfin DXP"
                    // handlerAdd={() => {this.addShell()}} 
                    buttonAvailable={true}
                    // textAdd="Ajouter une enveloppe"
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                    locales={this.props.locales}
                    hasBorder={true}
                />
                {
                    (
                        <div style={{ width: this.state.openForm && this.props.windowWidth > 1500 ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "calc(100% - 32px)", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                            <Grid container justifyContent={'flex-end'}>
                                <TraductionSelect
                                    currentLang={this.state.currentLang}
                                    handleLang={this.handleLang}
                                    locales={this.props.locales}
                                />
                            </Grid>
                        </div>
                    )
                }
                {
                    !this.state.ready ?
                        (
                            <PageLoader />
                        )
                        :
                        <>
                            <BoxCustom onClick={this.props.history.goBack} style={{ marginBottom: 16 }}>
                                <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                            </BoxCustom>
                            <CardCustom style={{ width: this.state.openForm && this.props.windowWidth > 1500 ? '100%' : "calc(50% - 32px)", marginTop: 8, marginLeft: 16, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                                <Subtitle variant="body1">Code article : {this.state.sku}</Subtitle>
                                {
                                    this.state.customAttributes && this.state.customAttributes?.length && process.env.REACT_APP_MODE_SPREAD !== "hub"
                                        ? (
                                            <Box pt={4}>
                                                <Subtitle variant="body2">Attributs</Subtitle>
                                                <BoxCustomAttributs mt={2} py={2} px={1}>
                                                    <Grid container>
                                                        {
                                                            this.state.customAttributes
                                                                ? this.state.customAttributes.filter(e => e.node.status).map(
                                                                    map)
                                                                : null
                                                        }
                                                    </Grid>
                                                </BoxCustomAttributs>
                                            </Box>
                                        ) : null
                                }
                                {
                                    this.state.editForm === "edit" ?
                                        <Grid container justifyContent="flex-end">
                                            <Button types="Edit" disableElevation={true} bgColor={colors.green.regular} onClick={this.state.openForm ? this.handleClose : this.handleToggleDrawer} fontsize={12} />
                                        </Grid>
                                        : null
                                }

                            </CardCustom>
                        </>
                }
                <LayoutBuilder
                    isSublayout={false}
                    opened={this.state.openForm}
                    forClose={this.handleClose}
                    handlerMutation={() => (this.handlerMutation())}
                    dataLayout={formShellCustom(this.state.editForm, this.state.currentLang, this.state.customAttributes, this.state.imageAttributes, this.state.errors, this.state.seeErrors, this.handleMediaPicker)}
                    allState={this.state}
                    icomoon={(this.state.editForm === 'edit') ? 'ico-modifier-attribut' : 'ico-ajouter-attribut'}
                    stateCallback={this.handleInputChange}
                    errorCallback={this.handleFormError}
                    checkError={() => { }}
                    deleteMutation={
                        (this.state.editForm === 'edit') ?
                            this.handleToggleDialog
                            : null
                    }
                    deleteText={this.state.editForm === 'edit' ? "Supprimer l'enveloppe" : null}
                    deleteButton={this.state.editForm}
                    validateButton={true}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                />
                <DialogModal
                    icon={true}
                    type='delete'
                    open={this.state.openDialog}
                    title={`Êtes-vous sûr de vouloir supprimer cette enveloppe ?`}
                    secondaryAction={this.handleToggleDialog}
                    primaryAction={() => { this.deleteShell(); this.handleToggleDialog() }}
                    windowWidth={this.props.windowWidth}
                >
                    Si vous supprimez cette enveloppe celle-ci ne sera plus accessible. Si vous ne l'utilisez plus mais que vous ne souhaitez pas la supprimer, annulez la suppression.
                </DialogModal>
            </div>
        );
    }

    goTo = (route, id = null) => {
        this.props.history.push({
            pathname: route,
            state: { id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        users: state.users,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Shells)));