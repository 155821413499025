import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { START_LOADING } from "../../../../js/constants/action-types";

import colors from "../../../../config/theme/colors";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

import { Grid, Box } from "@mui/material";
import Button from "../../../ui/button/Button";
import Typography from "../../../ui/typography/Typography";

import imgNotFound from "../../../../assets/images/not-found.png";
import pictoExternalLink from "../../../../assets/pictos/out.svg";
import ColorLensSharpIcon from "@mui/icons-material/ColorLensSharp";
import TitleIcon from "@mui/icons-material/Title";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { withStyles, makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const Link = styled.a`
  color: ${colors.blue.darker.hue300};
  &:hover {
    color: ${colors.black.regular};
  }
`;
const ContainerImage = styled(Box)`
  overflow: hidden;
  background-color: ${(props) =>
    props.backgroundimage === imgNotFound ? "#F8FAFB" : "white"};
  background-image: url(${(props) => props.backgroundimage});
  background-size: ${(props) =>
    props.backgroundimage === imgNotFound ? "contain" : "80%"};
  background-repeat: no-repeat;
  background-position: center;

  margin: ${(props) =>
    props.backgroundimage === imgNotFound ? "0" : "0px 16px"};
`;
const LineColor = styled(Box)`
  background: ${colors.blue.darker.hue300};
`;
const CatalogDescription = styled(Typography)`
  max-height: 40px;
  min-height: 40px;
  overflow: hidden;
  p {
    margin: 0;
  }
`;
const BoxTitle = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 6px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${colors.grey.lighter.hue900};
  p {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    margin-left: 6px;
  }
`;
const Color = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  background-color: ${(props) => props.color};
`;

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

function CardGuideline(props) {
  const logo = (guideline) => {
    for (let attribut of guideline.guidelineData.edges) {
      if (attribut.node.attribute.identifier.includes("logo")) {
        return `${process.env.REACT_APP_MEDIAS}/${attribut.node.media.filePath}`;
      }
    }
    return imgNotFound;
  };

  if (props.guideline) {
    let guideline = props.guideline.node;
    return (
      <Box width={"100%"} style={{ padding: 0 }}>
        <ContainerImage height={160} backgroundimage={logo(guideline)} />
        <LineColor height={8} />
        <Box padding={"1rem 1.5rem 0.5rem 1.5rem"}>
          <Typography
            variant="h4"
            colortext={colors.black.regular}
            component="div"
          >
            <Box fontWeight="bold">{guideline.libelle}</Box>
          </Typography>
          <Typography
            variant="body1"
            component="div"
            style={{ lineHeight: "32px" }}
          >
            {guideline.guideline_url ? (
              <Link href={guideline.guideline_url} target="_blank">
                {guideline.guideline_url}
                <img
                  src={pictoExternalLink}
                  alt="Voir le site"
                  style={{
                    display: "inline-block",
                    height: 13,
                    width: 13,
                    marginLeft: 13,
                  }}
                />
              </Link>
            ) : null}
          </Typography>
          <CatalogDescription variant="body1" component="div">
            {guideline.guidelineData.edges.map((attribut, index) =>
              attribut.node.attribute.identifier.includes("description") ? (
                <ResponsiveEllipsis
                  style={{ lineHeight: "32px" }}
                  unsafeHTML={attribut.node.value}
                  maxLine="2"
                  ellipsis="..."
                  basedOn="letters"
                  key={`guideline-description-${index}`}
                />
              ) : null
            )}
          </CatalogDescription>

          <BoxTitle>
            <ColorLensSharpIcon style={{ color: colors.blue.darker.hue300 }} />
            <p style={{ color: colors.blue.darker.hue300 }}>
              {guideline.colors.edges.length === 1 ||
              guideline.colors.edges.length === 0
                ? "Couleur"
                : "Couleurs"}
            </p>
          </BoxTitle>
          <Box style={{ display: "flex", flexWrap: "wrap" }}>
            {guideline.colors.edges.length > 0 ? (
              guideline.colors.edges.map((color, index) =>
                index <= 3 ? (
                  <Box
                    style={{ marginBottom: 30, display: "inline-block" }}
                    key={`guideline-colors-${index}`}
                  >
                    <Box
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        marginRight: 16,
                      }}
                    >
                      <Color color={color.node.codeHexa} />
                      <span style={{ textTransform: "uppercase" }}>
                        {color.node.codeHexa}
                      </span>
                    </Box>
                  </Box>
                ) : index === 4 ? (
                  <Box
                    style={{ marginBottom: 30, display: "inline-block" }}
                    key={`guideline-color-${index}`}
                  >
                    <Box
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        marginRight: 16,
                        marginTop: -20,
                        fontSize: 20,
                        fontWeight: "bold",
                        color: colors.grey.regular,
                        lineHeight: "16px",
                      }}
                    >
                      <BootstrapTooltip
                        title={guideline.colors.edges.map((color, index) =>
                          index >= 4 && index < 10 ? (
                            <Box
                              style={{ display: "block" }}
                              key={`tooltip-item-color-${index}`}
                            >
                              <Box
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                <Color color={color.node.codeHexa} />
                                <span style={{ textTransform: "uppercase" }}>
                                  {color.node.codeHexa}
                                </span>
                              </Box>
                            </Box>
                          ) : index === 10 ? (
                            <p key={`tooltip-item-last-${index}`}>
                              +{guideline.colors.edges.length - 10} couleur
                              {guideline.colors.edges.length - 10 > 1
                                ? "s"
                                : null}
                            </p>
                          ) : null
                        )}
                      >
                        <p>...</p>
                      </BootstrapTooltip>
                    </Box>
                  </Box>
                ) : null
              )
            ) : (
              <p
                style={{
                  marginBottom: 30,
                  marginTop: 0,
                  color: colors.grey.lighter.hue600,
                }}
              >
                Aucune couleur associée
              </p>
            )}
          </Box>

          <BoxTitle>
            <TitleIcon style={{ color: colors.blue.darker.hue300 }} />
            <p style={{ color: colors.blue.darker.hue300 }}>
              {guideline.typographies.edges.length === 1 ||
              guideline.typographies.edges.length === 0
                ? "Typographie"
                : "Typographies"}
            </p>
          </BoxTitle>
          <Box>
            {guideline.typographies.edges.length > 0 ? (
              guideline.typographies.edges.map((typo, index) => (
                <Box
                  style={{ marginBottom: 30, display: "inline-block" }}
                  key={`guideline-typo-${index}`}
                >
                  <Box
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      marginRight: 16,
                    }}
                  >
                    <span>{typo.node.typographySystem.libelle}</span>
                  </Box>
                </Box>
              ))
            ) : (
              <p
                style={{
                  marginBottom: 30,
                  marginTop: 0,
                  color: colors.grey.lighter.hue600,
                }}
              >
                Aucune typographie associée
              </p>
            )}
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {!props.configCard && props.onClick ? (
              <Grid item lg={12} xs={12}>
                <Grid container justifyContent="flex-end">
                  <Box mt={props.openForm ? 1 : 0}>
                    <Button
                      style={{ marginBottom: 8, marginRight: 16 }}
                      onClick={props.onClick}
                      disabled={guideline.openForm}
                      bgcolor={colors.white}
                      bgcolorhover={colors.blue.darker.hue300}
                      color={colors.blue.darker.hue300}
                      colorhover={colors.white}
                      border={`1px solid ${colors.blue.darker.hue300}`}
                      fontsize={14}
                    >
                      Voir la charte
                      <ArrowForwardIosIcon
                        style={{ fontSize: "0.9rem", marginLeft: 7 }}
                      />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardGuideline)
);
