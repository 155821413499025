import { connect } from "react-redux";
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../../../js/constants/action-types";
import AccordionCustom from "../../../../../layouts/Accordion/AccordionCustom";
import InputBuilder from "../../../../../ui/form/InputBuilder";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import PageLoader from "../../../../../ui/loadings/page-loader/PageLoader";
import { FormInputTypes } from "../../../../../../builder/shareable/types";

const Filters = props => {
    const [state, setState] = useState(null);

    
    const getState = ()=>{
        setState({
            "source": " ",
            ...props.filters.reduce((acc, item) => {
                acc[item.stateName] = item.type === 'dateFromTo' ? item?.startValue : item?.defaultValue ??null;
                return acc;
            }, {})
        })
    }

    const handleChange = (evt, item,type) => {
        const value = evt?.target?.value ?? evt;

        if(item.type === 'multiselect'){
            state[item.stateName] = value
            setState({
                [item.stateName]: value
            })
        } else {
            setState({
                ...state,
                [item.stateName]: value
            })
        }
    }

    useEffect(()=>{
        setState(null)
    },[props.location.pathname])
    
    useEffect(()=>{
        getState()
    },[])
    
    useEffect(()=>{
        props.stateCallback(state)
        if (!state) {
            getState()    
        }
    },[state])

    return (
        <AccordionCustom
            // overflow={'visible'}
            forcedExpanded
            title={
                <InputBuilder 
                    xs={2} 
                    value={state?state["source"]:" "} 
                    padding={"0px"} 
                    style={{margin:0}} 
                    input={
                        {
                            xs: 2,
                            required: false,
                            disabled: false,
                            defaultValue:" ",
                            noBorder:true,
                            type: FormInputTypes.SELECT_WITHOUT_BORDER,
                            stateName: 'source',
                            special: 'noborder',
                            helper: {
                                position: 'top',
                                text:'Visualiser par :',
                                link: false,
                            },
                            value: (()=>{
                                let allBrands = props.brands?.map((brand,i)=>{
                                    return {
                                        value: brand.node.id,
                                        label: brand.node.libelle 
                                    }
                                })
                                allBrands.unshift({
                                    value: " ",
                                    label: 'Toutes les maisons'
                                })
                                return allBrands
                            })() 
                        }
                    } 
                    stateCallback={(evt,type) => {
                        evt.stopPropagation()
                        handleChange(evt, {stateName: 'source'},type)
                    }} 
                />
            }
        >
            {
                state?
                    <Box
                        style={{
                            display:"flex",
                            flexWrap:'nowrap',
                            justifyContent:"space-bteween",
                            width:"100%",
                            gap:20
                        }}
                    >
                        {
                            props.filters.map((item, index) => {
                                return (
                                    <InputBuilder key={index} xs={item.xs} value={state[item.stateName]} padding={"12px"} style={{margin:0}} input={item} stateCallback={(evt,type) => {
                                        handleChange(evt, item,type)
                                    }} />
                                )
                            })
                        }
                    </Box>
                :<PageLoader/>
            }
        </AccordionCustom>
    )
}


const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Filters))));