import { Grid, Switch, Typography, TextField, MenuItem } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import {connect} from 'react-redux';
import ButtonCustom from '../../../../ui/button/Button';
import { withApollo } from '@apollo/client/react/hoc';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import CardCustom from '../../../../layouts/Card/CardCustom';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import * as listingHelper from '../../../../../js/utils/listing';
import {getElements} from '../../../../../js/utils/functions';
import {CursorPagination} from '../../../../../js/utils/pagination';
import { getTraductionAttributs } from '../../../../../js/utils/functions';
import NoImage from '../../../../../assets/images/not-found.png';
import { GET_SELECTIONS } from '../../../../../queries/selections';
import { UPDATE_CATALOG_PRODUCT } from '../../../../../queries/catalogs';
import * as moment from 'moment';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../../js/constants/alert-types';
import { ROUTE_PRODUCTS_LIST_DETAIL } from '../../../../../js/constants/route-names';
import MasterSearch from '../../../../ui/search/MasterSearch';
import AccordionCustom from '../../../../layouts/Accordion/AccordionCustom';

const TableCellCustom = styled(TableCell)`
    border: none;
    // display: inline-block;
    // width: calc(100% / 9);
    padding: 3px 16px;
`;

const SwitchCustom = styled(Switch)`
    .Mui-checked{
        color: ${colors.blue.lighter.hue150};
        & + .MuiSwitch-track{
            background-color: ${colors.blue.darker.hue300};
        }
    }
`;

const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 13px;
        padding: 10px;
    }
    & > p{
        margin: 0;
    }
`;

class ProductSelectorCatalog extends React.Component { 
    constructor(props){
        super(props)
        this.state = {
            ready: false,
            switchValue: false,
            pagination :  {
                page : 0,
                perPage : 30
            },
            perPageOptions:{
                'table' : [30,50,100],
            },
            listCollections: [],
            searchValue: '',
            searchValueSku: '',
            searchStatus: 'all',
            attributesInternalFilter: [],
            customSearchAttributes: {
                "product_image": 'all'
            },
            categoriesSelected: this.props.activeCategorie ? [this.props.activeCategorie] : [],
            catalogId: this.props.catalogId || null,
            readyFilters: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchStatus = this.handleSearchStatus.bind(this);
        this.handleSearchSku = this.handleSearchSku.bind(this);
    }

    handleSearchStatus(e, value) {
        this.setState({
            searchStatus: e.target.value
        });
    }

    handleSearchSku(e, value) {
        this.setState({
            searchValueSku: e.target.value
        });
    }

    handleChange(e, value) {
        this.setState({searchValue: e.target.value});
    }

    validateFilters = () => {
        this.setState({
            ready: false,
            page: 0,
        }, () => this.handleGetItems())
    }

    changeViews = async(mode) =>{
        let views = await listingHelper.changeViews(this.state,'list');
        this.setState({
            views
        })
        if(this.state.perPageOptions['list'][0]!=this.state.pagination.perPage){
            this.changePerPage(this.state.perPageOptions['list'][0])
        }
    }

    changePage = async(newPage) =>{
        let pagination = await listingHelper.pagination.changePage(this.state.pagination,newPage);
        this.setState({
            pagination
        },()=>this.handleGetItems())
    }

    changePerPage = async(perPage) => {
        let reload = this.state.pagination.perPage !== perPage;
        if(reload){
            let pagination = await listingHelper.pagination.updatePerPage(this.state.pagination,perPage);
            this.setState({
                pagination
            },()=>{
                this.handleGetItems()
            })    
        }
    }

    initCollections() {
        this.props.client.query({
            query: GET_SELECTIONS,
            fetchPolicy: "no-cache",
        }).then(result => {
            this.setState({
                listCollections: result.data.selections.edges
            })
        }).catch((err)=>{
            console.log(err)
        });
    }

    async componentDidMount(){
        this.initCollections();
        await this.handleGetItems();
    }

    async componentDidUpdate(prevProps){
        if(prevProps.listCategories !== this.props.listCategories){
            if (this.props.listCategories?.length > 0){
                this.setState({
                    categories: [...this.props.listCategories]
                })
            }    
        }
    }

    handleGetItems() {
        return new Promise((resolve, reject) => {
            this.setState({ready:false})
            let productsDataFilters = [];
            let variables = {
                "catalog": this.props.allState.catalogId.replace('/api/catalogs/', ''),
                "itemsPerPage": this.state.pagination.perPage, 
                "page": this.state.pagination.page + 1,
            }
            if (this.state.searchValueSku){
                variables.sku = this.state.searchValueSku
            }

            if (this.state.searchValue !== "" && this.state.searchValue){
                productsDataFilters.push(JSON.stringify({
                    'identifier': 'product_name',
                    'value': this.state.searchValue,
                    'locale': this.props.allState.currentLang,
                    'option': null
                }))
            }

            if (this.state.searchStatus && this.state.searchStatus !== 'all'){
                if (this.state.searchStatus === 'active'){
                    variables.status = true
                }else{
                    variables.status = false
                }
            }

            if (this.state.customSearchAttributes){
                for (const [key, value] of Object.entries(this.state.customSearchAttributes)) {
                    if (value !== ''){
                        let option = null
                        let newValue = null
                        if (key !== 'product_image'){
                            option = value?.includes('/api/attribute-options')
                        }else{
                            if (value === "active"){
                                newValue = true
                            }else if (value === "inactive"){
                                newValue = false
                            }
                        }
                        if (value !== 'all'){
                            productsDataFilters.push(JSON.stringify({
                                'identifier': key,
                                'value': option ? null : newValue !== null ? newValue : value,
                                'locale': option ? null : this.props.allState.currentLang,
                                'option': option ? value.replace(`/api/attribute-options/`, '') : null
                            }))
                        }
                    }
                }
            }

            if (productsDataFilters.length > 0){
                variables.productDatas = productsDataFilters;
            }

            if (this.state.categoriesSelected.length > 0){
                variables.categories = this.state.categoriesSelected.map(e => parseInt(e.id.replace('/api/categories/', '')))
            }

            variables = listingHelper.initQuery(this.state,variables,'queryData');
            let queryStates = listingHelper.initQuery(this.state,variables,'states');

            this.setState({...queryStates});
            let result = getElements('researchProducts',variables);
            let handleResults = listingHelper.handleResult(result.data['researchProducts'],'listItems',this.state);
            
            this.setState({
                readyFilters: true,
                ...handleResults,
            })
            // if(this.props.listingCallback)
            //     this.props.listingCallback(handleResults.listItems)
            resolve();
        
        });
    }

    stateCallback = (stateName, value, custom, translated, callback) => {
        if (custom === true){
            let customSearch = this.state.customSearchAttributes;
            if (!customSearch) {
                customSearch = {};
            }
            customSearch[stateName] = value?.target?.value ?? value;
            this.setState({
                customSearchAttributes: customSearch ??{}
            }, callback);
        }else{
            this.setState({
                [stateName]: value?.target?.value ?? value
            }, callback);
        }
    };

    handleSwitchChange  = (id, status, idProduct) => {
        let getData = [...this.state.listItems]
        let statusValue = getData.find(e => e.node.id === idProduct)
        statusValue = statusValue.node.productCatalogs.edges.find(e => e.node.catalog.id === this.props.allState.catalogId);
        statusValue.node.status = !status
        this.setState({
            listItems : getData
        },() => {
            this.props.client.mutate({
                mutation: UPDATE_CATALOG_PRODUCT,
                variables:{
                    "id": id,
                    "status": !status,
                    "updatedAt": moment().format('YYYY-MM-DD')
                }
            }).then(result =>{
                if (result){
                    this.props.snack(ALERT_SUCCESS, 'Modification du status effectuée');
                }else{
                    this.props.snack(ALERT_ERROR, 'Une erreur est survenue');
                }
            }).catch((err)=>{
                console.log(err)
            });
        })
    };

    handleSelectionChange  = (id, selection, idProduct) => {
        let getData = [...this.state.listItems]
        let selectionValue = getData.find(e => e.node.id === idProduct)
        selectionValue = selectionValue.node.productCatalogs.edges.find(e => e.node.catalog.id === this.props.allState.catalogId);
        if (selectionValue?.node.selection && selectionValue.node.selection?.id !== selection.target.value){
            selectionValue.node.selection.id = selection.target.value;
        }else if (!selectionValue?.node.selection){
            selectionValue = {
                node: {
                    selection: {
                        id: selection.target.value
                    }
                }
            };
        }
        this.setState({
            listItems : getData
        },() => {
            this.props.client.mutate({
                mutation: UPDATE_CATALOG_PRODUCT,
                variables:{
                    "id": id,
                    "selection": selection.target.value === "none" ? null : selection.target.value,
                    "updatedAt": moment().format('YYYY-MM-DD')
                }
            }).then(result =>{
                if (result){
                    this.props.snack(ALERT_SUCCESS, 'Modification de la collection effectuée');
                }else{
                    this.props.snack(ALERT_ERROR, 'Une erreur est survenue');
                }
            }).catch((err)=>{
                console.log(err)
            });
        })
    };

    handleBlankGoTo = (productId) => {
        window.open(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', productId.replace('/api/products/', '')))
    }

    resetFilters = () => {
        this.setState({
            ready: false,
            page: 0,
            searchValue: '',
            searchValueSku: '',
            searchStatus: 'all',
            customSearchAttributes: {
                "product_image": 'all'
            },
            categoriesSelected: this.props.activeCategorie ? [this.props.activeCategorie] : [],
        }, () => this.handleGetItems())
    }

    render(){
        let headCells = [
            { id: 'image', align: "center", style: null, disablePadding: true, label: 'Image' },
            { id: 'sku', style: null, disablePadding: false, label: 'Code article' },
            { id: 'nom', style: null, disablePadding: false, label: 'Nom' },
            { id: 'collection', style: null, disablePadding: false, label: 'Collection' },
            { id: 'status', align: "center", style: null, disablePadding: false, label: 'Actif' },
            { id: 'button', style: null, disablePadding: false, label: '' }
        ]


        return(
            <Grid container spacing={4} style={{background: colors.grey.lighter.hue980, marginTop: 10, padding:"0 16px"}}>
                <Grid container style={{marginTop: 16, marginBottom: 16}}>
                    {
                        this.state.readyFilters ?
                            <AccordionCustom defaultExpanded={false} title={'Filtres et Recherche'}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <MasterSearch 
                                        currentStatus={this.state.searchStatus} 
                                        searchStatusHandler={this.handleSearchStatus} 
                                        handleNameDesc={this.handleChange} 
                                        handleSearchSku={this.handleSearchSku} 
                                        validateSearch={this.validateFilters} 
                                        resetFilters={this.resetFilters}
                                        categories={this.props.allState.categories}
                                        attributesInternalFilter={this.props.attributes.product.attributes.edges.filter(e => e.node.internalFilter)}
                                        stateCallback={this.stateCallback}
                                        currentLang={this.props.allState.currentLang} 
                                        allState={this.state}
                                        categorieActivated={this.props.activeCategorie ? false : true}
                                        // noCategories={this.props.noCategories}
                                    />
                                </Grid>
                            </AccordionCustom>
                        : null
                    }
                </Grid>
                {
                    this.state.ready ?
                        this.state.listItems.length > 0 ?
                            <CardCustom style={{width: "100%", height: "auto",  padding: "0"}} cardContentStyle={{height: "auto"}} contentpadding={'0'} hovercard={false} collapse={true}>
                                <TableContainer component={Paper} style={{maxHeight: 500}}>
                                    <Table aria-label="headerList" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {headCells.filter(e => e!== null).map((headCell) => (
                                                    <TableCellCustom
                                                        key={headCell.id}
                                                        align={headCell.align ? headCell.align : 'left'}
                                                        style={{width: `${headCell.style?.width}`, background: "white"}}
                                                    >
                                                        {headCell.label}
                                                    </TableCellCustom>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.listItems.map((product, index) => {
                                                    let getName = getTraductionAttributs('product_name', product.node.productDatas.edges, this.props.allState.currentLang);
                                                    let getImage = getTraductionAttributs('product_image', product.node.productDatas.edges, this.props.allState.currentLang, 'image');
                                                    let getProductCatalog = product.node.productCatalogs.edges.find(e => e.node.catalog.id === this.props.allState.catalogId);
                                                    return(
                                                        <TableRow key={`row-product-${index}`}>
                                                            <TableCellCustom component="th" scope="row" align="center">
                                                                {
                                                                    getImage && getImage !== '' ? 
                                                                        (<img src={`${process.env.REACT_APP_MEDIAS}/${getImage.filePath}`} alt={`image-${getName}-${index}`} style={{maxWidth: '100%', maxHeight: 40}}/>) 
                                                                    : 
                                                                        (<img src={NoImage} style={{maxWidth: '100%', maxHeight: 40}} alt={`image-${getName}-${index}`}/>)
                                                                }
                                                            </TableCellCustom>
                                                            <TableCellCustom >
                                                                <Typography variant="body2">{product.node.sku}</Typography>
                                                            </TableCellCustom>
                                                            <TableCellCustom style={{minWidth: 150}}>
                                                                <Title variant="body1">{getName || 'N/A'}</Title>
                                                            </TableCellCustom>
                                                            <TableCellCustom>
                                                                { 
                                                                    getProductCatalog ? (
                                                                        <TextFieldCustom
                                                                            id={`Selection-${index}`}
                                                                            name={`Selection-${index}`}
                                                                            variant="outlined"
                                                                            color="secondary"
                                                                            select
                                                                            fullWidth
                                                                            displayEmpty
                                                                            value={getProductCatalog && getProductCatalog?.node.selection !== null ? getProductCatalog.node.selection.id : 'none'}
                                                                            onChange={evt => {
                                                                                this.handleSelectionChange(getProductCatalog?.node.id, evt, product.node.id);
                                                                            }}
                                                                            style={{minWidth: 150}}
                                                                            >
                                                                                <MenuItem
                                                                                    value={'none'}
                                                                                    disabled={getProductCatalog && getProductCatalog?.node.selection === '' ? true : false}
                                                                                    >
                                                                                    <em>Aucune collection</em>
                                                                                </MenuItem>
                                                                            {this.state.listCollections.map((option,index) => {
                                                                                return(
                                                                                    <MenuItem key={`OptionSelect${index}`} value={option?.node.id} disabled={getProductCatalog?.node.selection?.id === option.node.id ? true : false}>
                                                                                        {option.node.identifier}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                        </TextFieldCustom>
                                                                    ) : '-'
                                                                }
                                                            </TableCellCustom>
                                                            <TableCellCustom align={"center"}>
                                                                {
                                                                    getProductCatalog ? (
                                                                        <SwitchCustom
                                                                            checked={getProductCatalog?.node.status}
                                                                            className={getProductCatalog?.node.status ? 'checked' : ''}
                                                                            onChange={() => this.handleSwitchChange(getProductCatalog?.node.id, getProductCatalog?.node.status, product.node.id)}
                                                                            color="primary"
                                                                            name="checkedB"
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    ) : '-'
                                                                }
                                                            </TableCellCustom>
                                                            <TableCellCustom>
                                                                <ButtonCustom disableElevation={true} text={"Voir"} arrow="right" bgColor={colors.green.regular} onClick={() => this.handleBlankGoTo(product.node.id)} />
                                                            </TableCellCustom>
                                                        </TableRow>
                                                    )
                                                })   
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardCustom>
                        : <Typography style={{padding: 20}}>Aucun produit</Typography>
                    : <PageLoader />
                }
                {
                    this.state.ready && this.state.listItems.length > 0 ?
                        <Grid container style={{padding: "8px 20px"}}>
                            <CursorPagination
                                rowLabel = {`Produits par page`}
                                pagination = {this.state.pagination}
                                type = "table"
                                changePageCallback = {this.changePage}
                                changePerPageCallback = {this.changePerPage}
                                showPerPage = {this.state.perPageOptions['table']?.length > 0}
                                perPageOptions = {this.state.perPageOptions['table']}
                            />
                        </Grid>
                    : null
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo((connect(mapStateToProps,mapDispatchToProps)(ProductSelectorCatalog)));