import ReactQuill from "react-quill";
import styled from "styled-components";
import colors from "../../../../../../config/theme/colors";

export const ReactQuillCustom = styled(ReactQuill)`
  .ql-toolbar {
    border: 1px solid ${colors.grey.border};
  }

  .ql-container {
    border: 1px solid ${colors.grey.border};
    border-top: none;
  }
`;