import { Box, Grid } from "@mui/material";
import colors from "../../../../../config/theme/colors";
import { goTo } from "../../../../../js/utils/goTo";
import { GridCustom, HelperText, LinkText } from "../../styles/inputStyled";
import './styles/styles.scss';
import { MenuItemCustom, MenuItemInline, NotContainerizedSelectWrapper, SelectFieldCustom, SelectInline, useStyles } from "./styles/styled";
import OurLock from "../../../OurLock/OurLock";
import InputLabel from '@mui/material/InputLabel';
import OurTypography from "../../../typography/Typography";
/**
 * Custom select input component.
 * Renders different UI based on the value of the `isContainerized` property.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.updateCallback - The callback function to be called when the select value changes.
 * @param {Object} props.error - The error object containing error information.
 * @param {boolean} props.input.isContainerized - Flag indicating whether the input is containerized.
 * @param {string} props.input.stateName - The identifier for the select input state.
 * @param {string} props.input.label - The placeholder text for the select input.
 * @param {boolean} props.input.multiselect - Flag indicating whether the select input allows multiple selections.
 * @param {boolean} props.input.disabled - Flag indicating whether the select input is disabled.
 * @param {Object[]} props.input.value - The options for the select input.
 * @param {string} props.input.value[].value - The value of the option.
 * @param {string} props.input.value[].label - The label of the option.
 * @param {Object} props.input.helper - The helper object for additional helper text or link.
 * @param {boolean} props.input.helper? - Flag indicating whether the helper text is a link.
 * @param {string} props.input.helper?.text - The text for the helper.
 * @param {Function} props.t - The translation function for internationalization.
 * @returns {JSX.Element} The rendered JSX element.
 * 
 * ! PLEASE: Update the example below with all the props that can be used in the future
 * @example
 * Example of props.input
 * const inputProps = {
 *   type: "select",
 *   isContainerized: true,
 *   stateName: "exampleState",
 *   label: "Example Select",
 *   multiselect: false,
 *   disabled: false,
 *   value: [
 *     { value: "option1", label: "Option 1" },
 *     { value: "option2", label: "Option 2" },
 *     { value: "option3", label: "Option 3" }
 *   ],
 *   helper: {
 *     link: false,
 *     text: "This is a helper text."
 *   }
 *   custom: {
 *     padding: "0 16px", 
 *   }
 * };
 */
const OurSelect = ({
  updateCallback,
  error,
  lock,
  // Other props
  allProps: props
}) => {
  const isContainerized = props.input?.isContainerized ?? true; // By default, the select input is containerized.
  const classes = useStyles();  
  const value = !props.value && props.value !== false ?props.input.defaultValue?props.input.defaultValue:'all': props.value
  return (
    <>
      {
        props.input.inlineMode?
        <Box display={"flex"} alignItems={"center"} gap={"5px"}>
          <OurTypography margin={"0 !important"} variant="body2" sx={{marginBottom: "5px"}}>Visualiser par : </OurTypography>
          <SelectInline
            variant="standard"
            helperText={error?.seeError && error?.errorMessage}
            disabled={props.input.disabled}
            error={error?.seeError && error?.errorMessage}
            value={value}
            onChange={updateCallback}
            MenuProps={{
              elevation: 0,
              PaperProps: {
                square: true,
                style: {
                  border: `1px solid ${colors.grey.border}`,
                  marginTop: '4px',
                  boxShadow: 'none',
                  color: colors.blue.darker.hue300,
                  "&:hover": {
                    backgroundColor: colors.blue.darker.hue900,
                  }
                }
              }
            }}
          >
            {props.input.value?.map((option, index) => {
                return (
                  <MenuItemInline key={`OptionSelect${index}`} value={option.value} disabled={option.disabled} total={props.input.value?.length}>
                    {option.label}
                  </MenuItemInline>
                )
              })}
          </SelectInline>
        </Box>
        :
        isContainerized ? (
          <GridCustom margin={props?.margin} item xs={props.input?.xs ?? 12} style={{position: 'relative',paddingLeft : props.input?.dynamicFilter ? 0: "auto"}}>
            {
                props.input.topLabel ?
                    <InputLabel sx={{ fontSize: "12px", marginBottom: "5px" }}>
                        {props.input.topLabel}
                    </InputLabel>
                  : null
            }
            <SelectFieldCustom
              custom={props.input.custom}
              // defaultValue={props.input.value[0].value}
              id={props.input.stateName}
              variant="outlined"
              color="secondary"
              select
              fullWidth
              SelectProps={{
                multiple: props.input.multiselect,
              }}
              MenuProps={{
                elevation: 0,
                PaperProps: {
                  square: true,
                  style: {
                    border: `1px solid ${colors.grey.border}`,
                    marginTop: '4px',
                    boxShadow: '0px',
                  }
                }
              }}
              placeholder={props.input.label}
              value={value}
              onChange={updateCallback}
              helperText={error?.seeError && error?.errorMessage}
              disabled={props.input.disabled}
              error={error?.seeError && error?.errorMessage}
            >
              {props.input.value?.map((option, index) => {
                return (
                  <MenuItemCustom key={`OptionSelect${index}`} value={option.value} disabled={option.disabled} total={props.input.value?.length}>
                    {option.label}
                  </MenuItemCustom>
                )
              })}
            </SelectFieldCustom>
            {
              error?.seeError && error?.errorMessage ? null :
                props.input.helper?.link
                  ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.t(props.input.helper?.text)}</LinkText>
                  : props.input.helper?.text && <HelperText variant="body2">{props.t(props.input.helper?.text)}</HelperText>
            }
            <OurLock lock={lock} isDisabled={props.input.disabled} style={{
              position: 'absolute',
              right: '20px',
              bottom: '20%',
            }} />
          </GridCustom>
        ) : (
          <Grid item xs={props.input.xs ?? 12} style={{ display: 'flex', alignItems: 'center' }}>
            <NotContainerizedSelectWrapper
              id={props.input.stateName}
              variant="outlined"
              color="secondary"
              select
              value={value}
              onChange={updateCallback}
              helperText={props.input.helper?.text}
              disabled={props.input.disabled}
              SelectProps={{
                MenuProps: {
                  classes: {
                    paper: classes.select
                  },
                  PaperProps: {
                    style: {
                      border: `1px solid ${colors.black.regular}`,
                    }
                  },
                  elevation: 0,
                }
              }}
            >
              {props?.input?.value.map(option => (
                <MenuItemCustom key={option?.value} value={option?.value}>
                  {option?.label}
                </MenuItemCustom>
              ))}
            </NotContainerizedSelectWrapper>
          </Grid>
        )
      }
    </>
  )
}

export default OurSelect;