import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
import { withApollo } from '@apollo/client/react/hoc';
import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import Typography from '../../../ui/typography/Typography';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import { ADD_COMPANY_DATA,ADD_COMPANY,UPDATE_COMPANY,UPDATE_COMPANY_DATA,DELETE_COMPANY, GET_COMPANY_DATA} from '../../../../queries/companies';
import { GET_CUSTOMERS,UPDATE_CUSTOMER,GET_CUSTOMERS_CURSOR,SEARCH_CUSTOMERS} from '../../../../queries/customers';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import * as moment from 'moment';
import 'moment-timezone';
import ContactList from './components/ContactList';
import companyAdd from './config/companyAdd.config';
import { eventService } from '../../../../js/services/event.service';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader'
import {getTraductionAttributs, prepareAttributeValues} from '../../../../js/utils/functions';
import CardCompany from '../../../layouts/Card/cardContent/CardCompany';
import DialogModal from '../../../ui/dialog/DialogModal'
import { ROUTE_CRM_CONTACTS_SOCIETIES, ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_CONTACT, CRM_CONTACT_SOCIETIES, VIEW, UPDATE, DELETE } from '../../../../js/constants/constant-rights';

const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

class ContactCompaniesDetails extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            //General States
            searchValue: '',
            isReady : false,

            //FORM
            companyEditForm : false,
            companyAddChildrenForm : false,
            isChildren : false,
            errors: {},
            customersFormSettings:[],


            //Current company
            companyParent:null,
            companyChildrens:[],
            companyChildrenDraft:{
                companyChildrens:[],
                companyCustomers : {
                    toAdd : [],

                }
            },


            //CUSTOMERS
            companyCustomers : {
                toAdd : [],
                toRemove : [],
            },

            

            //Pagination
            nbperpageCustomers : 6,
            currentPage : null,
            cursorCustomers : null,
            cursorCustomersForm : null,

            //Contacts
            categories:[
                {
                    libelle : 'Tout voir',
                    value : null
                },
                {
                    libelle : 'Clients',
                    value : 'client'
                },
                {
                    libelle : 'Leads',
                    value : 'lead'
                },
                {
                    libelle : 'Contacts',
                    value : 'contact'
                },
            ],
            openDeleteModal : false,
        }
    }

    handleLang = (event) => {
        let companyChildrenDraft = this.state.companyChildrenDraft;
        companyChildrenDraft['currentLang'] = event.target.value;
        this.setState({ 
            currentLang: event.target.value,
            companyChildrenDraft : companyChildrenDraft ??null
        }, () => {
            eventService.fire();
        });
    };

    handleSearch = (isForm) => {
        
        let value = this.state.searchValue;
        let filters = [
            {
                target : 'status',
                value:  isForm ? this.state.currentCategoryForm : this.state.currentCategory
            }
        ]
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_CUSTOMERS,
                variables: {"needle": value,"attribute":null},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let customers = result.data.customerDatas.edges.map(e=>parseInt(e.node.customer.id.replace('/api/customers/',''),10));
                let listID=[];
             
                let uniqueCustomers = customers.filter(customer=>{
                    if(!listID.includes(customer)){
                        listID.push(customer);
                        return customer;
                    }else{
                        return customer;
                    }
                })
                
                this.setState({
                    activeSearch :uniqueCustomers
                },()=>this.handleGetCustomers(filters,isForm))
                
            }).catch((err)=>{
                console.log(err)
            });
        }
    }
    handleChangeCustomer=(e,isForm) =>{
        let formSettings = this.state.customersFormSettings;
        let customersReady = this.state.customersReady;

        if(isForm)
            formSettings.isReady = false;
        else
            customersReady = false;

        this.setState({
            currentPage : 1,
            formSettings : formSettings??null,
            customersReady:customersReady??null,
        });

        clearTimeout(this.typingSearchTimer);

        if(e?.target?.value){
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch(isForm);
            }, 500);
        }

        else{
            this.setState({activeSearch:false},()=>this.handleGetCustomers(null,isForm))
        }

    }

    setValue = (stateName, value, translated) => {
        let isChildren = this.state.isChildren
        let companyChildrenDraft=this.state.companyChildrenDraft;

        if (translated) {
            let values = isChildren===true ? this.state.companyChildrenDraft[this.props.locales[0].node.code] : this.state[this.props.locales[0].node.code];

            if (!values) {
                values = {};
            }
            values[stateName] = value;

            if(isChildren===true){
                companyChildrenDraft[this.props.locales[0].node.code]=values;
                this.setState({
                    companyChildrenDraft: companyChildrenDraft??null                    
                })
            }
            else{
                this.setState({
                    [this.props.locales[0].node.code]: values,
                });               
            }
        } else {
            if(isChildren===true){           
                companyChildrenDraft[stateName]=value;
                this.setState({
                    companyChildrenDraft:companyChildrenDraft??null
                })
            }
            else{
                this.setState({
                    [stateName]: value,
                });
            }
        }
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors:errors??null });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    handleNextStep = () =>{
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }
        this.handleDrawerWidthChange(-350)
        return true;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }

    handleToggleDrawer = (stateDrawer,reset=false) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer]
        });
        if(reset){
            this.resetState()
        }
    };

    handleToggleModal = (stateModal)=>{
        this.setState({ 
            [stateModal]: !this.state[stateModal]
        });
    }
    handleCompanyChildren = (action='add',index=null)=>{
        
        this.setState({
            isChildren:true,
            companyChildrenAction : action
        },()=>{
            switch(action){
                case 'add' :
                    this.handleToggleDrawer('companyAddChildrenForm');
                break;
                case 'edit':
                    let companyChildrenDraft = this.state.companyChildrens[index];
                    companyChildrenDraft['currentLang'] = this.props.locales[0].node.code
                    this.setState({
                        companyChildrenDraft : {...companyChildrenDraft},
                        childrenToEdit : index,
                    })
                    this.handleToggleDrawer('companyAddChildrenForm');
                break;
                case 'delete':
                    this.handlerChildrenMutation('delete');
                    this.setState({
                        childrenToEdit : index,
                    })
                break;
                default:
                    return null;
            }
        })
    }

    handleMediaPicker=(selected,stateName,translated)=>{
        this.handleInputChange(stateName,selected,null,translated);  
    }
    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value,translated);
    }

    handlerChildrenMutation = () =>{
        let action =this.state.companyChildrenAction;
        const companyChildrens=[...this.state.companyChildrens];
        let children = {...this.state.companyChildrenDraft}
        switch(action){
            case 'add':
                companyChildrens.push(children);
                this.handleToggleDrawer('companyAddChildrenForm',true);
            break;
            case 'edit':
                companyChildrens[this.state.childrenToEdit]=children;
                this.handleToggleDrawer('companyAddChildrenForm',true);
            break;
            case 'delete':
                companyChildrens.splice(this.state.childrenToEdit,1);
                break;
            default:
                return null;
        }

        this.setState({
            companyChildrens : companyChildrens??null
        },()=>{
            this.resetCompanyDraft();
            this.resetState();
        })
        this.resetCompanyDraft();
    }

    resetCompanyDraft(){
        let companyChildrenDraft = {...this.state.companyChildrenDraft};
        companyChildrenDraft.companyChildrens = [];
        companyChildrenDraft.companyCustomers={
            toAdd:[]
        };
        companyChildrenDraft['companyIdentifier']=null;
        for (let locale of this.props.locales){
            companyChildrenDraft[locale.node.code]={};
        }
        this.setState({ companyChildrenDraft: companyChildrenDraft });
    }

    resetState = () =>{
        this.setState({
            editChildren:false,
            childrenToEdit:null,
            isChildren:false
        })
    }

    setUpAttributes = async()=>{
        let attributes = await prepareAttributeValues('company',true,this.state.currentCompany);
        this.setState({
            ...attributes,
        })
        if(this.state.companyChildrens.length >0){
            let childrens=[];
            let index = 0;
            for(let company of this.state.companyChildrens){
                let attributes = await prepareAttributeValues(this.props,'company',company);
                childrens[index] = {...attributes};
                index++;
            }
            this.setState({companyChildrens : childrens})          
        }
        this.props.stopLoading();
    }

    prepareAttributeValues(company,isChildren,index) {
        let companyChildrenDraft = [...this.state.companyChildrenDraft];
        const companyDatas = company.companyDatas.edges;
        for (let locale of this.props.locales) {
            let values = {};
            for (let attribute of this.state.customAttributes) {
                if (attribute.node.attributeType.input === 'select') {
                    if (attribute.node.attributeOptions.edges.length){
                        values[attribute.node.identifier] = attribute.node.attributeOptions.edges[0].node.id
                    }
                }
                let attributeTranslation = getTraductionAttributs(attribute.node.identifier,companyDatas,locale.node.code,attribute.node.attributeType.input)
                if(attributeTranslation){
                    values[attribute.node.identifier]=attributeTranslation;

                }
            }
            companyChildrenDraft[locale.node.code] = {}
            
            if(isChildren){
                let childrens = this.state.currentCompanyChildrens;
                childrens[index][locale.node.code]={...values};
                this.setState({
                    currentCompanyChildrens :childrens??null,
                })
            }
            else{
                this.setState({
                    [locale.node.code]: {...values},
                    companyChildrenDraft:companyChildrenDraft??null,
                });
            }
        }
    }


    prepareAttributes = ()=>{
        return new Promise(async (resolve, reject) => {
            let companyChildrenDraft=this.state.companyChildrenDraft;
            let isSystemAttributes  = this.props.attributes.company.attributes.edges.filter(e => e.node.isSystem);
            companyChildrenDraft['currentLang']=this.props.locales[0].node.code;
            companyChildrenDraft['isSystemAttributes']=isSystemAttributes;
            this.setState({
                isSystemAttributes:isSystemAttributes??null,
                customAttributes : this.props.attributes.company.attributes.edges??null,
                companyChildrenDraft :  companyChildrenDraft??null,
            },()=>{
                resolve()
            })
        })
    }

    handlerCompanyMutation= async(action='edit',allStates,parent,attributes)=>{
        this.props.startLoading();
        let variables;
        switch(action){
            case 'add':
                variables={
                    libelle : allStates.companyIdentifier.replaceAll(' ','_').toLowerCase(),
                    parent : parent,
                    createdAt: moment().format('YYYY-MM-DD'),
                    updatedAt: moment().format('YYYY-MM-DD'),
                }
                const ADD_COMPANY_RESULT = await this.props.client.mutate({
                    mutation : ADD_COMPANY,
                    variables : variables
                })
                await this.saveAttributes(ADD_COMPANY_RESULT.data.createCompany.company,allStates,attributes);
                if(allStates.companyCustomers.toAdd.length >0)
                    for(let customer of allStates.companyCustomers.toAdd)
                        this.handleAddCustomer(this.props.location.state.idCompany,customer)

                if(allStates.companyChildrens.length > 0)
                    for(let company of allStates.companyChildrens)
                        this.handlerCompanyMutation('add',company,ADD_COMPANY_RESULT.data.createCompany.company.id);

            break;
            case 'edit':
                variables={
                    libelle : allStates.companyIdentifier,
                    parent : parent,
                    updatedAt: moment().format('YYYY-MM-DD'),
                    id : allStates.currentCompany.id
                }
                const UPDATE_COMPANY_RESULT = await this.props.client.mutate({
                    mutation : UPDATE_COMPANY,
                    variables : variables
                })

                await this.saveAttributes(UPDATE_COMPANY_RESULT.data.updateCompany.company,allStates,allStates.currentCompany.companyDatas.edges);
                if(allStates.companyCustomers.toAdd.length >0)
                    for(let customer of allStates.companyCustomers.toAdd)
                        this.handleAddCustomer( this.props.location.state.idCompany,customer)

                if(allStates.companyCustomers.toRemove.length >0)
                    for(let customer of allStates.companyCustomers.toRemove)
                        this.handleRemoveCustomer(this.props.location.state.idCompany,customer)

                if(allStates.companyChildrens.length > 0)
                    for(let company of allStates.companyChildrens)
                        this.handlerCompanyMutation('add',company,this.props.location.state.idCompany);
            break;
            default:
                return null;
        }
        
        this.handleGetCompany();
        this.handleToggleDrawer('companyEditForm')
        this.props.stopLoading();
        this.props.snack(ALERT_SUCCESS, 'Société modifiée avec succès');
    }

    handleAddCustomer = async(company,customer)=>{
        let companies   = customer.node.companies.edges.map((company)=>company.node.id);
        let id          = customer.node.id;
        companies.push(company);
        await this.props.client.mutate({
            mutation : UPDATE_CUSTOMER,
            variables : {
                companies : companies,
                id : id
            }
        })
    }


    handleRemoveCustomer = async(company,customer)=>{
        let companies   = customer.node.companies.edges.map((company)=>company.node.id);
        let id          = customer.node.id;
        let index       = companies.indexOf(company);
        companies.splice(index,1);
        await this.props.client.mutate({
            mutation : UPDATE_CUSTOMER,
            variables : {
                companies : companies,
                id : id
            }
        })
    }

    saveAttributes(company,allStates,currentData){
            return new Promise(async (resolve, reject) => {
                let attributes = allStates.isSystemAttributes;
                let getProductData = [];
                for (let attribute of attributes) {
                    for (let locale of this.props.locales) { 
                        let formValue       = allStates[locale.node.code][attribute.node.identifier];
                        let isMedia         = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';

                        if (formValue) {
                            let variables = {
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "company": company.id, 
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia){
                                variables.media = formValue.id;
                            }
                                
                            
                            if (!isMedia){
                                if (attribute.node.attributeType.input !== 'select'){
                                        variables.value = formValue;                                    
                                }

                            let isTranslated = false;
                            if(currentData){
                                let translation = currentData.find((e)=>{
                                    if(e.node.attribute.id === attribute.node.id && e.node.locale.id === locale.node.id){
                                        return true;
                                    }else{
                                        return false
                                    }
                                });
                                if(translation){
                                    isTranslated = true;
                                    variables.id = translation.node.id;
                                }
                            }

                            let resultMutation = await this.props.client.mutate({
                                mutation: isTranslated ? UPDATE_COMPANY_DATA : ADD_COMPANY_DATA,
                                variables
                            })
                            getProductData.push(resultMutation)
                        }
                    }
                    }
                }
    
                resolve(getProductData);
            });
    }
    
    handleDrawerWidthChange = (width) => {
        this.setState({ 
            drawerWidthModified: width
        });
    };


    getCustomersCursors= () =>{
        return new Promise((resolve,reject)=>{
            this.props.client.query({
                query : GET_CUSTOMERS_CURSOR,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    listCursorsCustomers : result.data.customers.edges
                })
                resolve();
            }).catch((err)=>{
                console.log(err)
            });
        })
    }
    handleGetCustomers= async(filters,initForm = false)=>{
        let currentCompany      = this.state.currentCompany;
        let variables = 
            {
                'nbperpage': this.state.nbperpageCustomers, 
            };

        if(!initForm){
            variables.companies=currentCompany.id
            if(this.state.cursorCustomers && this.state.listCursorsCustomers !== this.state.listCursorsCustomers[0].cursor){
                variables.cursor = this.state.cursorCustomers;
            }
        }
        else{
            if(this.state.cursorCustomersForm && this.state.listCursorsCustomers !== this.state.listCursorsCustomers[0].cursor){
                variables.cursor = this.state.cursorCustomersForm;
            }
        }
        if(this.state.activeSearch){
            variables.id_list = this.state.activeSearch;
        }
        if(filters?.length>0){
            for(let filter of filters){
                variables[filter.target] = filter.value;
            }
        }

        this.props.client.mutate({
            mutation : GET_CUSTOMERS,
            variables : variables
        }).then(result =>{
            if(!initForm){
                this.setState({
                    currentCompany : currentCompany ??null,
                    currentCustomers : result.data.customers.edges,
                    customersReady : true,
                    isReady : true,
                    countPageCustomers: Math.ceil(result.data.customers.totalCount / this.state.nbperpageCustomers) ??null,
                })                
            }else{
                let customersFormSettings = this.state.customersFormSettings;
                customersFormSettings.customers = result.data.customers.edges;
                customersFormSettings.count = Math.ceil(result.data.customers.totalCount / this.state.nbperpageCustomers);
                customersFormSettings.isReady = true;
                customersFormSettings.changePage = this.changePage
                
                this.setState({
                    customersFormSettings : customersFormSettings ??null ,
                })
            }
        }).catch((err)=>{
            console.log(err)
        });
    }

    handleSelect=(checked,customer,isCurrent)=>{
        
        let companyChildrenDraft    = {...this.state.companyChildrenDraft}; 
        let companyCustomers        =  {...this.state.companyCustomers};
        let isChildren              = this.state.isChildren;
        let index;

        if(checked){

            if (isChildren){
                companyChildrenDraft['companyCustomers'].toAdd.push(customer)                
            }
            else{
                if(isCurrent){
                    index =companyCustomers.toRemove.indexOf(customer);
                    companyCustomers.toRemove.splice(index,1); 
                }
                else{
                    companyCustomers.toAdd.push(customer)                
                }
            }
        }
        else{
            if(isChildren){
                index =companyChildrenDraft['companyCustomers'].toAdd.indexOf(customer);
                companyChildrenDraft['companyCustomers'].toAdd.splice(index,1);
            }
            else{
                if(isCurrent){
                    companyCustomers.toRemove.push(customer)
                }
                else{
                    index =companyCustomers.toAdd.indexOf(customer);
                    companyCustomers.toAdd.splice(index,1);                    
                }

            }
        }
        this.setState({
            companyCustomers : companyCustomers,
            companyChildrenDraft : companyChildrenDraft
        })
    }

    handleGetCompany = async()=>{
        await this.getCustomersCursors();
        let variables = 
            {
                'id': this.props.location.state.idCompany, 
            };
        this.props.client.query({
            query: GET_COMPANY_DATA,
            variables: variables,
            fetchPolicy: 'no-cache'
        }).then((result) => {
            let currentCompany = result.data.company;
            let currentCompanyChildrens = result.data.company.children.edges;
            currentCompany={
                ...currentCompany,
                // childrensCount : currentCompanyChildrens.length,
                action : this.initCompanyEditForm
            }
            
            this.setState({
                currentCompany : currentCompany,
                companyIdentifier : currentCompany.libelle,
                currentCompanyChildrens: currentCompanyChildrens
            },()=>{
                this.setUpAttributes();
                this.handleGetCustomers();
            })
        }).catch((err)=>{
            console.log(err)
        });
    }

    initCompanyEditForm = async() =>{
        await this.initCompanyEditFormDatas();
        let formSettings = this.state.customersFormSettings;
        formSettings.handleCategory = this.handleCategory;
        formSettings.currentCompany = this.state.currentCompany;
        formSettings.handleSelect   =this.handleSelect;
        formSettings.handleSearch   = this.handleChangeCustomer;
        this.setState({
            customersFormSettings : formSettings??null
        })
        this.handleToggleDrawer('companyEditForm',false,true);
    }

    initCompanyEditFormDatas= () =>{
        return new Promise((resolve,reject)=>{
            this.handleGetCustomers(null,true)
            resolve();
        })
    }

    changePage =(event,page,isForm)=>{
        let index           = 0;
        let cursor          = this.state.cursorCustomers; 
        let cursorForm      = this.state.cursorCustomersForm;
        let currentPage     = this.state.currentPage;
        let formSettings    = this.state.customersFormSettings
        let customersReady  = this.state.customersReady

        let filters = {target : 'status',value:null}
        filters.value = isForm ? this.state.currentCategoryForm : this.state.currentCategory;

        if(page > 1){
            index = ( page * this.state.nbperpageCustomers ) - this.state.nbperpageCustomers - 1
        }
        if(isForm){
            cursorForm = this.state.listCursorsCustomers[index].cursor;
            formSettings.page = page;
            formSettings.isReady = false;
        }
        else{
            cursor = this.state.listCursorsCustomers[index].cursor;
            currentPage = page;
            customersReady =false;
        }
        this.setState({
            cursorCustomers: cursor??null,
            cursorCustomersForm : cursorForm??null,
            currentPage : currentPage??null,
            customersFormSettings : formSettings??null,
            customersReady : customersReady??null,
        },()=>{
            
            this.handleGetCustomers(filters,isForm);
        });
    }

    handleDeleteCompany = ()=>{
        let company = this.state.currentCompany.id;
        this.props.startLoading();
        this.props.client.mutate({
            mutation : DELETE_COMPANY,
            variables : {id : company}
        }).then(result=>{
            this.props.stopLoading();
            this.props.history.push({
                pathname : ROUTE_CRM_CONTACTS_SOCIETIES,
                state: {hasMessage:true,message : 'Société supprimée'}
            });
        }).catch((err)=>{
            console.log(err)
        });
    }
    
    handleCategory = (category,isForm)=>{
        let formSettings = this.state.customersFormSettings;
        let customersReady = this.state.customersReady;
        let currentCategory = this.state.currentCategory;
        let currentCategoryForm = this.state.currentCategoryForm;
        let filter = [{
            target : 'status', 
            value : category
        }]
        if(isForm){
            formSettings.isReady = false;
            currentCategoryForm = category;
        }
        else{
            customersReady = false;
            currentCategory = category;
        }
        this.setState({
            currentCategory :currentCategory??null,
            currentCategoryForm : currentCategoryForm??null,
            customersReady : customersReady??null,
            customersFormSettings : formSettings??null
        })
        this.handleGetCustomers(filter,isForm);
    }

    componentDidMount(){
        const getRights = hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, VIEW);
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.handleGetCompany();
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.location.pathname !== this.props.location.pathname){
            this.props.startLoading();
            this.setState({
                idCompany : this.props.location.state.idCompany??null
            },()=>{
                this.handleGetCompany();
            })
        }
    }
    
    render(){

        return(
            <div style={{width: this.state.companyEditForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <TopPanel 
                    icomoon="icon-business" 
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Gérer cette société" 
                    subtitle="Voir vos contacts" 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    buttonAvailable={!this.state.companyEditForm}
                    currentLang={this.props.locales[0].node.code} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                    hasBorder={true}
                />
                <BoxCustom onClick={this.props.history.goBack} mb={2} mt={2}>
                    <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                </BoxCustom>
                {this.state.isReady ? 
                    <Grid container>
                    <Grid item xs={12}>
                        <CardCustom>
                            {this.state.currentCompany 
                                ? 
                                <CardCompany data={this.state.currentCompany} openForm={this.state.companyEditForm} textButton={'Modifier la société'}  windowWidth={this.props.windowWidth} currentLang={this.props.locales[0].node.code} edit={true} isDetail={true} isList={!hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, UPDATE)}/>
                                :
                                null
                            }
                            {this.state.currentCompanyChildrens?.length >0
                                ? 
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3" style={{padding:'20px 0px'}}>Filiale</Typography>  
                                    </Grid>
                                    {this.state.currentCompanyChildrens.map((company,index)=>
                                    <Grid item xs={this.state.companyEditForm ? 6 : 4} key={index}>
                                        <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={0} >
                                            <CardCompany data={company} textButton={'Voir la filiale'}  windowWidth={this.props.windowWidth} currentLang={this.props.locales[0].node.code} isChildren={true} />                                             
                                        </CardCustom>
                                    </Grid>
                                    )}
                                </Grid>
                                
                                :null
                            }
                            <ContactList 
                                categories          = {this.state.categories} 
                                handleCategory      = {this.handleCategory} 
                                items               = {this.state.currentCustomers} 
                                handleSearch        = {this.handleChangeCustomer} 
                                select              = {false} 
                                handleSelect        = {this.handleSelect} 
                                smallScreen         = {this.state.companyEditForm}
                                pagination          = {true}
                                paginationSettings  = {{'page':this.state.currentPage ? this.state.currentPage:1,'count':this.state.countPageCustomers,'changePage' : this.changePage}}
                                isReady             = {this.state.customersReady}
                                showTab             = {true}
                                isCard              = {true}
                            />
                        </CardCustom>                       
                    </Grid>                   
                </Grid>
                :  
                    <PageLoader/>
                }

                {this.state.isReady ? 
                    <div>
                        <LayoutBuilder
                            opened              = {this.state.companyEditForm} 
                            forClose            = {()=>this.handleToggleDrawer('companyEditForm')} 
                            handlerSetup        = {()=>{}} 
                            dataLayout          = {companyAdd(this.props.locales[0].node.code,this.state.customAttributes,'add',this.handleMediaPicker,this.handleCompanyChildren,this.state.customersFormSettings,'edit')} 
                            drawerWidth         = {this.props.drawerWidth}     
                            allState            = {this.state}    
                            stateCallback       = {this.handleInputChange} 
                            errorCallback       = {this.handleFormError}    
                            stepperButtonAction = {[
                                this.handleNextStep,
                                this.handleNextStep,
                                this.handleNextStep,
                            ]}
                            handlerMutation     = {()=>this.handlerCompanyMutation('edit',this.state,this.state.currentCompany.parent? this.state.currentCompany.parent.id:null,this.state.isSystemAttributes)}
                            currentLang         = {this.props.locales[0].node.code}
                            handleLang          = {this.handleLang}
                            deleteMutation      = {hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, DELETE) ? ()=>this.handleToggleModal('openDeleteModal') : null}
                            deleteText          = {'Supprimer cette société'}
                            validateButton      = {true}
                        />
                        <LayoutBuilder
                            opened={this.state.companyAddChildrenForm} 
                            forClose={()=>this.handleToggleDrawer('companyAddChildrenForm',true)} 
                            handlerSetup={()=>{}} 
                            dataLayout={companyAdd(this.props.locales[0].node.code,this.state.customAttributes,'addChildren',this.handleMediaPicker,null,this.state.customersFormSettings)} 
                            drawerWidth={this.props.drawerWidth}     
                            allState={this.state.companyChildrenDraft}    
                            stateCallback={this.handleInputChange} 
                            stepperButtonAction={[
                                this.handleNextStep,
                                this.handleNextStep
                            ]}
                            handlerMutation={this.handlerChildrenMutation}
                            currentLang={this.props.locales[0].node.code}
                            handleLang={this.handleLang}
                            errorCallback={this.handleFormError}
                        />
                        <DialogModal
                            open            = {this.state.openDeleteModal}
                            secondaryAction = {()=>this.handleToggleModal('openDeleteModal')}
                            primaryAction   = {()=>this.handleDeleteCompany()}
                            title           = 'Supprimer cette société' 
                        >
                            <Typography variant="body2">Êtes-vous sûr de vouloir supprimer cette société ?<strong>Cette action est irréversible</strong></Typography>
                        </DialogModal>
                    </div>
                :null}
            </div>
        )
    }
    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => ({...a})); 

    // goTo = route => this.props.history.push(route);
    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { productId : id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactCompaniesDetails)));

