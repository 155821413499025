import { GridCustom, LinkText, HelperText } from "../../styles/inputStyled";
import { Box, Grid, IconButton, InputAdornment, InputLabel } from '@mui/material';
import colors from "../../../../../config/theme/colors";
import Tooltip from '@mui/material/Tooltip';
import IcomoonReact from "icomoon-react";
import Translation from '../../../../layouts/Translation/Translation';
import { FRENCH } from "../../../../../js/constants/languages";
import { TextFieldCustom } from "./styles/styled";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import iconSet from '../../../../../assets/selection.json'
import { goTo } from "../../../../../js/utils/goTo";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import OurTooltip from "../../../tooltip/Tooltip";
import OurLock from "../../../OurLock/OurLock";

/**
 * Text Component
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.error - Error object.
 * @param {boolean} props.error.seeError - Flag indicating whether to display error message.
 * @param {string} props.error.errorMessage - Error message to display.
 * @param {Function} props.updateCallback - Callback to update the state.
 * @param {Object} props.isHidden - Flag indicating whether to display the text or not.
 * @param {Object} props.allProps - All other props passed to the component.
 * @returns {JSX.Element} Text component.
 *
 * ! PLEASE: Update the example below with all the props that can be used in the future
 * * Plaintext example:
 * @example //* This is an object example that can be passed as config props for the input builder type Text: (props.input)
 * {
        xs: 12,
        type: 'text',
        label: 'Rechercher dans la plateforme Sinfin™ …',
        helper: 'Rechercher dans la plateforme Sinfin™ …',
        iconPosition: 'start',
        required: true,
        stateName: 'libelle',
        disabled: false,
        defaultValue: '',
        value: '',
        hidden={false},
        rightButton: {
            icon: 'search',
            textTooltip: 'Rechercher',
            textTooltipDisabled: 'Veuillez saisir un texte pour lancer la recherche',
            callback: () => console.log('search')
        },
        custom: {
            prefix: <SearchSharpIcon />,
            suffix: <SearchSharpIcon />,
            backgroundColor: colors.grey.lighter.hue980,
        }
    };
    * * Hidden text example:
    * @example //* This is an object example that can be passed as config props for the input builder type Text: (props.input)
    * {
        xs: 12,
        type: 'text',
        label: 'Rechercher dans la plateforme Sinfin™ …',
        helper: 'Rechercher dans la plateforme Sinfin™ …',
        iconPosition: 'start',
        required: true,
        stateName: 'libelle',
        disabled: false,
        defaultValue: '',
        value: '',
        hidden={true},
        rightButton: {
            icon: 'search',
            textTooltip: 'Rechercher',
            textTooltipDisabled: 'Veuillez saisir un texte pour lancer la recherche',
            callback: () => console.log('search')
        },
        custom: {
            prefix: <SearchSharpIcon />,
            backgroundColor: colors.grey.lighter.hue980,
        }
    };
**/
const OurText = ({
    error, updateCallback, isHidden, lock, keyCallback,
    allProps: props,
}) => {
    const [isTextHidden, setIsTextHidden] = useState(isHidden);

    const handleClickShowText = () => setIsTextHidden(!isTextHidden);
    const handleMouseDownText = event => event.preventDefault();

    const getType = () => {
        return props.input.type === 'hidden'
            ? isTextHidden
                ? 'password'
                : 'text'
            : props.input.type
    }

    return (
        <GridCustom item className="input-with-border" xs={props.xs ?? 12} style={{paddingLeft : props.input?.dynamicFilter ? 0: "auto"}}>
            <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <Box position={'relative'} width={'100%'}>
                    {
                        props.input.topLabel ?
                            <InputLabel sx={{ fontSize: "12px", marginBottom: "5px" }}>
                                {props.input.topLabel}
                            </InputLabel>
                        : null
                    }
                    <TextFieldCustom
                        custom={props.input.custom}
                        id={props.t(props.input.stateName)}
                        variant="outlined"
                        value={props.value ?? props.input.defaultValue ?? ''}
                        helperText={error.seeError && error.errorMessage}
                        fullWidth={!props.input.rightButton}
                        onChange={updateCallback}
                        onKeyDown={evt=>{
                            if (keyCallback) {
                                keyCallback(evt)
                            }
                        }}
                        InputProps={{
                            inputProps: { min: props.input.min, max: props.input.max },
                            startAdornment: props.input.custom?.prefix,
                            endAdornment: (props.input.custom?.suffix) ?? (isHidden && (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle text visibility"
                                        onClick={handleClickShowText}
                                        onMouseDown={handleMouseDownText}
                                        edge="end"
                                    >
                                        {isTextHidden ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ))
                        }}
                        name={props.t(props.input.stateName)}
                        type={getType()}
                        placeholder={props.t(props.input.label)}
                        disabled={props.input.disabled}
                        error={error.seeError && error.errorMessage}
                        style={{
                            width: props.input.rightButton
                                ? '90%'
                                : props.input.translation && props.allState.currentLang !== FRENCH
                                    ? '90%'
                                    : '100%'
                        }}
                        inputProps={{
                            maxLength: 255,
                            spellCheck: true,
                            lang: 'fr'
                        }}
                        spellCheck={true}
                        lang="fr"
                    />
                    <OurLock lock={lock} isDisabled={props.input.disabled} style={{
                        position: 'absolute',
                        right: 0,
                        bottom: error.seeError && error.errorMessage ? '33%' : '3%',
                    }} />
                </Box>
                {
                    props.input.rightButton ?
                        <Tooltip
                            title={props.value ? props.input.rightButton.textTooltip : props.input.rightButton.textTooltipDisabled}
                        >
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="center"
                                style={{ width: '10%', height: 51, minWidth: 'auto', padding: 0, position: 'absolute', top: 0, right: 0, cursor: props.value ? 'pointer' : 'inherit' }}
                                onClick={props.value ? props.input.rightButton.callback : null}
                            >
                                <IcomoonReact
                                    iconSet={iconSet}
                                    color={props.value ? colors.blue.darker.hue300 : colors.grey.lighter.hue600}
                                    size={35}
                                    icon={props.input.rightButton.icon}
                                />
                            </Grid>
                        </Tooltip>
                        : null
                }
                {
                    props.input.translation && props.allState.currentLang !== FRENCH ?
                        <Translation
                            value={props.value}
                            stateCallback={props.stateCallback}
                            currentLang={props.allState.currentLang}
                        />
                        : null
                }
            </Box>

            {
                error.seeError && error.errorMessage ? null :
                    props.input.helper?.link
                        ? <LinkText variant="body2" onClick={() => goTo(props.history,props.input.helper?.route)}>{props.t(props.input.helper?.text)}</LinkText>
                        : props.input.helper?.text && <HelperText variant="body2">{props.t(props.input.helper?.text)}</HelperText>
            }
        </ GridCustom>
    )
}

export default OurText;