
/**
 * 
 * @param {string} color a hex color. Exemple : #ffffff
 * @param {number} magnitude the amount to lighten or darken the color. Exemple : 20
 * @returns a hex color lighter or darker than the original color
 */
export const lighterAndDarker = (color, magnitude) => {
    color = color.replace(`#`, ``);
    if (color.length === 6) {
        const decimalColor = parseInt(color, 16);
        let r = (decimalColor >> 16) + magnitude;
        r > 255 && (r = 255);
        r < 0 && (r = 0);
        let g = (decimalColor & 0x0000ff) + magnitude;
        g > 255 && (g = 255);
        g < 0 && (g = 0);
        let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
        b > 255 && (b = 255);
        b < 0 && (b = 0);
        return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
        return color;
    }
};

export const convertToRgb = (hex) => {
    const hexString = hex.replace("#", "");
    const r = parseInt(hexString.substring(0, 2), 16);
    const g = parseInt(hexString.substring(2, 4), 16);
    const b = parseInt(hexString.substring(4, 6), 16);
    return {
      r,
      g,
      b,
    };
  };
  
  export const convertToRgba = (hex, opacity) => {
    const hexString = hex.replace("#", "");
    const r = parseInt(hexString.substring(0, 2), 16);
    const g = parseInt(hexString.substring(2, 4), 16);
    const b = parseInt(hexString.substring(4, 6), 16);
  
    return {
      r,
      g,
      b,
      a: opacity,
    };
  };

  export const convertToHex = (rgb) => {
    const { r, g, b } = rgb;
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  }