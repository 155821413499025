import { gql } from '@apollo/client';

//----- Récupération des commandes

export const GET_ORDERS_CURSOR = gql`
{
  orders (first: 10000){
    edges{
      cursor
    }
  }
}
`;

export const GET_ORDERS_PAGINATION = gql`
query orders ($nbperpage: Int, $cursor: String, $number: String, $source_number: String, $shipping_name: String, $shipping_firstname: String, $billing_name: String, $billing_firstname: String, $status: String, $source: String){
  orders (first: $nbperpage, after: $cursor, number: $number, source_number: $source_number, shipping_name:$shipping_name, shipping_firstname:$shipping_firstname, billing_name:$billing_name, billing_firstname:$billing_firstname, status:$status, source:$source){
    edges{
      node{
        id
        number
        billingName
        billingFirstname
        billingCompany
        billingStreet
        billingZipcode
        billingCity
        billingCountry
        billingEmail
        billingPhone
        createdAt
        shippingName
        shippingFirstname
        shippingCompany
        shippingStreet
        shippingZipcode
        shippingCity
        shippingCountry
        shippingEmail
        shippingPhone
        status
        paymentMethod
        shippingMethod
        shippingAmount
        discountAmount
        totalAmount
        status
        source
        sourceNumber
        orderTrackings{
          edges{
            node{
              url
            }
          }
        }
        orderItems{
          edges{
            node{
              id
              product{
                id
                sku
              }
              qty
              qtyShipped
              qtyInvoiced
            }
          }
        }
      }
    }
    totalCount
  }
}
`;

export const GET_ORDERS = gql`
{
  orders{
    edges{
      node{
        id
        number
        billingName
        billingFirstname
        billingCompany
        billingStreet
        billingZipcode
        billingCity
        billingCountry
        billingEmail
        billingPhone
        shippingName
        shippingFirstname
        shippingCompany
        shippingStreet
        shippingZipcode
        shippingCity
        shippingCountry
        shippingEmail
        shippingPhone
        status
        paymentMethod
        shippingMethod
        shippingAmount
        discountAmount
        totalAmount
        source
        sourceNumber
        orderTrackings{
          edges{
            node{
              url
            }
          }
        }
        orderItems{
          edges{
            node{
              id
              product{
                id
                sku
              }
              qty
              qtyShipped
              qtyInvoiced
            }
          }
        }
      }
    }
  } 
}
`;


export const GET_ORDER_BY_ID = gql`
query order($id: ID!) {
  order(id: $id) {
    id
    number
    billingName
    billingFirstname
    billingCompany
    billingStreet
    billingZipcode
    billingCity
    billingCountry
    billingEmail
    billingPhone
    shippingName
    shippingFirstname
    shippingCompany
    shippingStreet
    shippingZipcode
    shippingCity
    shippingCountry
    shippingEmail
    shippingPhone
    status
    paymentMethod
    shippingMethod
    shippingAmount
    discountAmount
    totalAmount
    source
    sourceNumber
    createdAt
    orderTrackings{
      edges{
        node{
          url
        }
      }
    }
    orderItems{
      edges{
        node{
          id
          product{
            id
            sku
            productDatas(first: 200){
              edges{
                node{
                  id
                  value
                  selection{
                    id
                    identifier
                  }
                  attribute{
                    identifier
                    isSystem
                  }
                  locale{
                    code
                  }
                  media{
                    id
                    filePath
                    type
                    size
                  }
                }
              }
            }
          }
          qty
          qtyShipped
          qtyInvoiced
          price
          totalPrice
        }
      }
    }
  }
}
`;

export const SEARCH_ORDERS = gql`
query orders ($nbperpage: Int, $cursor: String, $number: String, $source_number: String, $shipping_name: String, $shipping_firstname: String, $billing_name: String, $billing_firstname: String, $status: String, $source: String){
  orders (first: $nbperpage, after: $cursor, number: $number, source_number: $source_number, shipping_name:$shipping_name, shipping_firstname:$shipping_firstname, billing_name:$billing_name, billing_firstname:$billing_firstname, status:$status, source:$source){
    edges{
      node{
        id
        number
        billingName
        billingFirstname
        billingCompany
        billingStreet
        billingZipcode
        billingCity
        billingCountry
        billingEmail
        billingPhone
        shippingName
        shippingFirstname
        shippingCompany
        shippingStreet
        shippingZipcode
        shippingCity
        shippingCountry
        shippingEmail
        shippingPhone
        status
        paymentMethod
        shippingMethod
        shippingAmount
        discountAmount
        totalAmount
        source
        sourceNumber
        orderTrackings{
          edges{
            node{
              url
            }
          }
        }
        orderItems{
          edges{
            node{
              id
              product{
                id
                sku
              }
              qty
              qtyShipped
              qtyInvoiced
            }
          }
        }
      }
    }
  } 
}
`;