import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideImage, getDefaultBackgroundCms, getDefaultTextCms, getDefaultLinkCms} from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider image',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        formatSlider: {
            label: 'Format du slider',
            type: FormInputTypes.BUTTON_GROUP,
            params:[
                {value: 'carre', label: 'Vignette carré'},
                {value: 'horizontal', label: 'Image horizontale'},
                {value: 'fullscreen', label: 'Plein écran'},
            ],
            value: 'carre'
        },
        noPaddingHorizontal: {
            label: "Désactiver l'espace sur les côtés",
            type: FormInputTypes.SWITCH,
            value: false,
        },
    },
    blocks:{
        background: getDefaultBackgroundCms('rgba(255,255,255,0)'),
        title: getDefaultTextCms("Titre", "Découvrir", null),
        link: getDefaultLinkCms("Lien", "Tout afficher", "https://www.lecoqsportif.com/", null, null,true,null,true),
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_IMAGE,
            buttonAdd: 'une slide',
            limit: 10,
            config: [ 
                getDefaultSlideImage(),
                getDefaultSlideImage(),
                getDefaultSlideImage(),
            ]
        }
    }
};