import React from 'react';
import FormBuilder from '../FormBuilder';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import { withTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material';
import OurTabs from '../../OurTabs/OurTabs';


const AppBarCustom = styled(AppBar)`
    background: transparent;
    box-shadow: inherit;
    svg{
        fill: ${colors.black.regular};
    }
    .MuiTabs-root{
        div:first-child{
            left: 10px;
        }
        div:last-child{
            right: 10px;
        }
        .MuiTabs-scrollButtons{
            position: absolute;
            height: 48px;
        }
    }
    button{
        color: ${colors.black.regular};
        position: relative;
        z-index: 9;
        text-transform: inherit;
        font-weight: bold;
        font-size: 14px;
        opacity: 1;
    }
    .MuiTabs-scroller{
        overflow: auto !important;
        &::-webkit-scrollbar{
            width: 0px;
            height: 3px;
        }
    }
    .MuiTabs-indicator{
        top: 0;
        height: 100%;
        border-top: 2px solid ${colors.blue.darker.hue300};
        background-color: ${colors.grey.lighter.hue980}
    }
`;

class TabsFormBuilder extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabState: 0,
            alreadyOpened: {}
        }
    }
    handleChange = (event, value) => {
        let alreadyOpened = this.state.alreadyOpened;

        alreadyOpened[value] = true;

        this.setState({
            tabState: value,
            alreadyOpened: { ...alreadyOpened }
        });
    };

    componentDidMount() {
        this.setState({
            tabState: this.props.allState.tabState ?? 0,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.allState.tabState !== this.props.allState.tabState) {
            this.setState({
                tabState: this.props.allState.tabState ?? 0,
            });
        }
    }

    render() {
        const tabsColumns = 5
        const tabs = this.props.navFormConfig.children
        const switchCanAppear = tabs.length > 1 && tabs.filter(t => t.labelName)?.length > (tabsColumns ?? 5)
        // const switchCanAppear = tabs.length > 1

        // 68%

        return (
            <Grid container style={{ width: '100%' }} direction={'column'} wrap='nowrap'>
                <OurTabs style={{
                    height: '100%',
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                }} hasSwitch={switchCanAppear} columnsNb={5} tabs={tabs} handleGetCurrentTab={(state) => {
                    this.setState({
                        tabState: state
                    })
                }}>
                    {tabs.filter(e => e).map((child, index) =>
                    (
                        child ?
                            (
                                child.component ? (
                                    <Box value={this.state.tabState} hidden={this.state.tabState !== index} index={index} key={`FormBuilder${index}`} style={{ 
                                        overflow: "auto", 
                                        //* The height and flex are important to maje the form scrollable and flexible with every type of screen
                                        height: '0px',
                                        flex: '1 1 auto'
                                    }}>
                                        {
                                            this.state.tabState !== index && !this.state.alreadyOpened[index] ? null : (
                                                <child.component
                                                    stateCallback={this.props.stateCallback}
                                                    errorCallback={this.props.errorCallback}
                                                    allState={this.props.allState}
                                                    isFormComponent={true}
                                                    optionsInputs={child.optionsInputs}
                                                />
                                            )
                                        }
                                    </Box>
                                ) : (
                                    <Box value={this.state.tabState} hidden={this.state.tabState !== index} index={index} key={`FormBuilder${index}`} style={{
                                        overflow: "auto",
                                        backgroundColor: colors.grey.lighter.hue980,
                                        //* The height and flex are important to maje the form scrollable and flexible with every type of screen
                                        height: '0px',
                                        flex: '1 1 auto'
                                    }}>
                                        <FormBuilder
                                            useLocking={this.props.useLocking}
                                            optionsInputs={child.optionsInputs}
                                            stateCallback={this.props.stateCallback}
                                            errorCallback={this.props.errorCallback}
                                            allState={this.props.allState}
                                            handleButtonGroupChange={this.props.handleButtonGroupChange ? this.props.handleButtonGroupChange : null}
                                        />
                                    </Box>
                                )
                            )
                            : null
                    )
                    )}
                </OurTabs>
            </Grid>
        )
    }
}
export default withTranslation()(TabsFormBuilder);