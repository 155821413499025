import { Box, Grid, Divider } from '@mui/material';
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import OurTypography from '../../../ui/typography/Typography';
import { useState } from 'react';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import OurMenu from '../../../ui/menu/Menu';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';

const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    padding: 16px;
`

function CardUserGroup(props) {

    const [rootAccessList, setRootAccessList] = useState([])

    const editMenuOptions = [
        // {
        //     label: 'Dupliquer',
        //     color: colors.blue.darker.hue300,
        //     isDisabled: true,
        //     icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
        //     action: (e) => {
        //         e.stopPropagation();
        //     }
        // },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                props.handlerMenu.toggleDeleteDialog(props.data.node)
            }
        }
    ]

    useEffect(() => {
        const rawAccessList = props.data.node.menuEntryRelations?.edges
            ?.filter(access => access.node.readRight || access.node.writeRight)
            ?.map(access => access.node.menuEntry)
            .filter(e=>!e.parent)
        setRootAccessList(rawAccessList)
    }, [])

    const capitalize = (text) => {
        return (text).charAt(0).toUpperCase() + (text).slice(1)
    }

    // Menu

    return (
        <ContainerCard>
            <Grid container style={{ position: 'relative', height: '100%' }} direction={'column'} justifyContent={'space-between'}>
                <Box mb={'36.5px'}>
                    <Grid container direction="column">
                        <Grid container justifyContent='space-between' style={{marginBottom: '12px'}}>
                            <OurTypography variant="h4" fontweighttext={700} style={{ fontSize: '25px' }}>{capitalize(props.data.node.code)}</OurTypography>
                            <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />
                        </Grid>
                        {/* <OurTypography variant="body" colortext={colors.grey.lighter.hue600} style={{ fontSize: '13px', marginTop: '8px', marginBottom: '12px' }}>{uppercaseFirstChar(props.data.node.code)}</OurTypography> */}
                        <Divider />
                    </Grid>
                    <Grid container direction="column" style={{ marginTop: '12px' }}>
                        <OurTypography variant="body" colortext={colors.black.regular} style={{ fontSize: '13px' }}>Accès du groupe :</OurTypography>
                        <Box mt={'10px'}>
                            {rootAccessList.length ? (
                                rootAccessList.map((i, index) => (
                                    <OurTypography key={index} colortext={colors.black.regular} fontweighttext={700} style={{
                                        marginRight: index === (rootAccessList.length - 1) ? null : '5px',
                                        display: 'inline-block'
                                    }}>
                                        {index === (rootAccessList.length - 1)
                                            ? props.t(`drawer.${i.code}`) + '.'
                                            : props.t(`drawer.${i.code}`) + ','}
                                    </OurTypography>
                                ))) : (
                                <OurTypography colortext={colors.black.regular} fontweighttext={700}>Aucun accès.</OurTypography>
                            )}
                        </Box>
                    </Grid>
                </Box>
                <Grid container justifyContent="flex-end">
                    <Button style={{
                        width: '100%'
                    }} onClick={() => props.handlerButton(props.data.node)} text={props.textButton}></Button>
                </Grid>
            </Grid>
        </ContainerCard>
    );
}

export default withTranslation()(CardUserGroup)
