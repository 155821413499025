import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Box } from '@mui/material';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardAssetsPage from '../../../layouts/Card/cardContent/CardAssetsPage';
import imgNotFound from '../../../../assets/images/not-found.png';
import { SNACK } from '../../../../js/constants/action-types';
import { withTranslation } from 'react-i18next';
import { checkRouting } from '../../../../js/utils/checkRouting';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { GET_ASSET_BY_ID_NAVIGATION } from '../../../../queries/assets';

class EngineAssetsDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            asset: this.props.location.state?.asset ? this.props.location.state.asset : null,
            minisite: this.props.location.state?.minisite ? this.props.location.state.minisite : null,
            minisitePageTypes: this.props.location.state?.minisitePageTypes ? this.props.location.state.minisitePageTypes : null
        };

    }

    SettingDatas = (data, type) => {
        let imageAsset = null;
        const requireImages = require.context('../../../../assets/images', true);
        try{
            if (data.node.assetGamePageType){
                imageAsset    = requireImages('./' + data.node.assetGamePageType.image);
            }else{
                if(type === 'flipbook' || type === 'flipbook_with_cart'){
                    if(data.node.assetMinisitePageType.identifier === 'home'){
                        let image = data.node.assetMinisitePageType.image;
                        image = image.replace('minisite', 'flipbook');
                        imageAsset    = requireImages('./' + image);
                    }
                    else{
                        imageAsset    = requireImages('./' + data.node.assetMinisitePageType.image);
                    }
                }else{
                    imageAsset    = requireImages('./' + data.node.assetMinisitePageType.image);
                }
            }
        } catch(e){
            imageAsset=imgNotFound;
        }
        this.setState({
            content:{
                title: data.node.libelle,
                status: data.node.status === '0' ? 'disabled' : 'completed',
                image: typeof imageAsset === "string" ? imageAsset : imageAsset?.default,
                page: data.node.identifier,
                updatedAt: data.node.updatedAt
            }
        })
    }

    initNavAsset = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query:GET_ASSET_BY_ID_NAVIGATION,
                variables:{id: `/api/assets/${this.props.match.params.id}`},
                fetchPolicy:'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            }).catch((err)=>{
                console.log(err)
            });
        })
    }

    componentDidMount(){
        checkRouting(this.props)
        if(this.state.minisite){
            this.SettingDatas(this.state.minisite,this.state.asset? this.state.asset.assetType.identifier:null)
        }
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))){
            this.initNavAsset()
        }
    }

    render() {
        return (
            <div>
                <TopPanel
                    title={this.props.t("spread.active_assets.pageDetails")}
                    // subtitle="Petite phrase de description OU heure de Mise à jour"
                    hasBorder={true}
                    windowWidth={this.props.windowWidth}
                />
                <Box pt={3}>
                {
                    this.state.content ? 
                    (
                        <CardCustom>
                            <CardAssetsPage content={this.state.content} asset={this.state.asset} page={this.state.minisite} goTo={this.goToId} canUpdate={true}/>
                        </CardCustom>
                    ) : null
                }
                </Box>
            </div>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };
    goToId = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: {assetId : id}
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsPage)));
