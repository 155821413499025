import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';
import { ROUTE_BRAND_FLOW_DETAILS } from '../../../../js/constants/route-names';
import { BRAND, BRAND_FLOW, CONNECTORS, CONNECTORS_RETAILERS } from '../../../../js/constants/constant-rights';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import fetch from '../../../../js/utils/fetch';
import axios from '../../../../js/utils/axios';
import { withApollo } from '@apollo/client/react/hoc';
import { GET_RETAILERS, ADD_RETAILER, UPDATE_RETAILER, DELETE_RETAILER, ADD_RETAILER_DATA, UPDATE_RETAILER_DATA, DELETE_RETAILER_DATA } from '../../../../queries/retailers';
import { GET_ATTR_GROUPE_WITH_ATTRIBUTES, GET_ATTR_GROUPE_LIGHT } from '../../../../queries/attributes';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';

import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import DialogModal from '../../../ui/dialog/DialogModal';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardSetup from '../../../layouts/Card/cardContent/CardSetup';
import TopPanel from '../../../layouts/TopPanel/TopPanel';

import EmptyRetailers from '../../../../assets/pictos/empty-picto/empty_retailers.png';
import EmptyFlux from '../../../../assets/pictos/empty-picto/empty_flux.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import retailersAddConfig from './config/retailersAdd.config';
import CronNotifications from './components/CronNotifications';
import { setRedux } from '../../../../js/utils/functions';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import { getRetailers } from './queries/ListRetailers.queries';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

const PageWrapper = styled(Box)`
    position: relative;
    height: calc(100% - 64px);
    display: grid;
    grid-template-rows: auto 1fr;

    & > .layout-wrapper{
        display: none;
    }
`;

const ListRetailers = props =>{
    const [state,setState]= useState({
        attrTypeFile :[],
        filtered: [],
        attrGroup: false,
        openForm: false,
        editForm: 'add',
        isEmpty: false,
        emptyPicto: null,
        openDialogRemove: false,
        isInternal: props.isInternal,
        content:
            props.type === "retailer" ?
                {
                    title: props.t("connectors.retailers.manageRetailers"),
                    subtitle: props.t("connectors.retailers.manageRetailerInfo"),
                    picto: "picto-retailer",
                    txtBtn: props.t("spread.campaigns.addRetailers"),
                    titleForm: props.t("spread.campaigns.addRetailers"),
                    emptyTitle: props.t("spread.campaigns.notConfigureRetailer"),
                    emptySubtitle: props.t("spread.active_assets.clickToAdd"),
                    emptyTxtBtn: props.t("spread.campaigns.addRetailers"),
                    emptyPicto: EmptyRetailers,
                }
                :
                props.type === "flow" ?
                    {
                        title: props.t("connectors.retailers.manageFlow"),
                        subtitle: props.t("connectors.retailers.manageFlowInfo"),
                        picto: "icon-flux",
                        txtBtn: props.t("connectors.retailers.addFeed"),
                        titleForm: props.t("connectors.retailers.addFeed"),
                        emptyTitle: props.t("connectors.retailers.notConfiguredFeed"),
                        emptySubtitle: props.t("spread.active_assets.clickToAdd"),
                        emptyTxtBtn: props.t("connectors.retailers.addNewFeed"),
                        emptyPicto: EmptyFlux,
                    }
                    :
                    {
                        title: props.t("connectors.retailers.manageBrand"),
                        subtitle: props.t("connectors.retailers.manageBrandInfo"),
                        picto: "icon-business",
                        txtBtn: props.t("connectors.retailers.addBrand"),
                        titleForm: props.t("connectors.retailers.addBrand"),
                        emptyTitle: props.t("connectors.retailers.notConfiguredBrand"),
                        emptySubtitle: props.t("connectors.retailers.clickToAddBrand"),
                        emptyTxtBtn: props.t("connectors.retailers.addNewBrand"),
                        emptyPicto: EmptyRetailers,
                    },
        dataAddLayout: retailersAddConfig,
        importLang: props.locales[0].node.id,
        currentLang: props.locales[0].node.code,
        errors: {},
        seeErrors: false,
        inputHidden: [],
        importFile: null,
        attrTypeUrl: [],
        attrTypeFtp: [],
        ready: false,
        drawerCron: false,
        typeTesting: {
            type: 'retailer',
            testingState: ['libelle', 'attributeIdentifier'],
            testingTypingState: 'libelle',
            identifierState: 'attributeIdentifier'
        }
    })
    const [openForm,setOpenForm]= useState(false)
    const [openFormType,setOpenFormType]= useState('edit')

    const [typingTimer,setTypingTimer]= useState(null)

    const handleLang = (event) => {
        setState({ ...state, currentLang: event.target.value });
        // forceUpdate();
    };

    const handleChange = (e) => {
        let currentList = [];
        let newList = [];
        if (e.target.value !== "") {
            currentList = state.retailers;
            newList = currentList.filter(item => {
                const lc = item.node.libelle.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
        } else {
            newList = state.retailers;
        }
        setState({
            ...state,
            filtered: newList
        });
    }

    const doneTyping = (stateName) => {
        let typeTesting = state.typeTesting;
        if (stateName === typeTesting.testingTypingState) {
            setState({
                ...state,
                [typeTesting.identifierState]: slugify(state[typeTesting.testingTypingState], { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            });
        }

        if (state[typeTesting.identifierState]) {
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${state[typeTesting.identifierState]}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: typeTesting.identifierState, errorMessage: props.t("spread.active_assets.userAlreadyUsed") });
                    }
                }
            ).catch((err)=>{
                console.log(err)
            });
        }
        // forceUpdate();
    };

    const checkIdentifier = (stateName) => {
        clearTimeout(typingTimer);
        setTypingTimer(setTimeout(() => { doneTyping(stateName) }, 500))
    };

    const stateCallback = (stateName, value, custom, translated, callback) => {
        if (translated) {
            let values = state[state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value?.target?.value ?? value;
            setState({ ...state, [state.currentLang]: values });
        }
        else {
            setState({
                ...state,
                [stateName]: value?.target?.value ?? value
            });
            // callback()
        }
        if (state.editForm === 'add' && state.typeTesting.testingState.includes(stateName))
            checkIdentifier(stateName)
    };

    const handleButtonGroupChange = (stateName, value) => {
        setState({
            ...state,
            [stateName]: value
        });
    };

    const handlerSetup = () => {
        /* // TODO: Refactor. The StartLoading was there because there are many for loops so it is slow to load */
        if (state.editForm === 'edit' && props.type === "brand") {
            props.startLoading();
        }
        props.client.query({
            query: GET_ATTR_GROUPE_WITH_ATTRIBUTES,
            fetchPolicy: 'no-cache',
        }).then(result => {
            //RECUPERATION MAPPER
            let mapper = [];
            setState({ ...state, importSep: "," });

            if (state.editForm === 'edit' && props.type === "brand") {
                request(`${process.env.REACT_APP_API}/retailers/${state.editIdentifier}/mapper`, 'get', false).then(
                    (data) => {
                        setState({ existentMapper: data.mapper, importSep: data.delimiter });
                        props.stopLoading();
                    }
                ).catch((err)=>{
                    console.log(err)
                });
            }
            else {
                setState({ existentMapper: null });
            }

            //CONFIG DES COMPOSANTS STATICS
            const switchType = {
                type: 'select',
                isContainerized: true,
                label: props.t("connectors.retailers.importBy"),
                translated: false,
                helper: {
                    text: props.t("connectors.retailers.chooseImportType"),
                    link: false,
                },
                required: true,
                disabled: false,
                stateName: 'importFile',
                value: [
                    {
                        value: 'file',
                        label: props.t("connectors.retailers.file"),
                        disabled: props.type === "brand" ? true : false,
                    },
                    {
                        value: 'url',
                        label: 'Url',
                        disabled: props.type === "brand" ? true : false,
                    },
                    {
                        value: 'ftp',
                        label: 'FTP',
                    },
                    {
                        value: 'sftp',
                        label: 'SFTP',
                    }
                ]
            };

            const separator = {
                type: 'select',
                isContainerized: true,
                label: props.t("connectors.retailers.separator"),
                translated: false,
                helper: {
                    text: props.t("connectors.retailers.chooseSeparator"),
                    link: false,
                },
                required: false,
                stateName: 'importSep',
                value: [
                    { value: ',', label: ',' },
                    { value: ';', label: ';' },
                    { value: '|', label: '|' },
                    { value: 'xml', label: 'SimpleXML ( temp )' },
                ]
            };

            const setupUploadFile = {
                type: 'uploadFile',
                translated: true,
                required: false
            };

            const staticOptions = [
                {
                    id: 'sku',
                    label: 'Code article'
                },
                {
                    id: 'selection',
                    label: 'Selection'
                },
                {
                    id: 'price',
                    label: 'Price'
                },
                {
                    id: 'specialPrice',
                    label: 'Special Price'
                },
                {
                    id: 'specialPriceFrom',
                    label: 'Special Price From Date'
                },
                {
                    id: 'specialPriceTo',
                    label: 'Special Price To Date'
                },
                {
                    id: 'currency',
                    label: 'Currency'
                },
                {
                    id: 'rateGroup',
                    label: 'Rate Group'
                },
                {
                    id: 'stock',
                    label: 'Stock'
                },
                {
                    id: 'ecopart',
                    label: 'Eco-part'
                },
                {
                    id: 'url',
                    label: 'URL'
                },
                {
                    id: 'version',
                    label: 'Version'
                },
                {
                    id: 'stockType',
                    label: 'StockType'
                }
            ];

            const setupMapping = {
                type: props.type === 'brand' ? 'mapper-multiple' : 'mapper',
                translated: true,
                required: false,
            };

            const setupInputText = {
                type: 'text',
                translated: true,
                helper: { link: false },
                required: false,
            };

            setState({ importFile: "" })

            /* // TODO: Refactor */
            for (let attrGroup of result.data.attributeGroups.edges) {
                let testType = props.type === 'flow' ? 'retailer' : props.type;
                if (attrGroup.node.identifier === testType) {
                    let getLayoutAdd = state.dataAddLayout;
                    setState({...state, attrGroupId: attrGroup.node.id, attr: attrGroup.node.attributes });

                    //INIT STEP 2 & 3 
                    getLayoutAdd.formConfig.children[1].optionsInputs = [];
                    getLayoutAdd.formConfig.children[2].optionsInputs = [];

                    //PUSH switch type d'import de fichier 
                    getLayoutAdd.formConfig.children[1].optionsInputs.push(switchType);

                    //Initialisation des mapper par locale
                    for (let locale of props.locales) {
                        setState({ ...state,[locale.node.code]: {} });
                        let localeState = state[locale.node.code];
                        for (let attr of attrGroup.node.attributes.edges) {
                            if (attr.node.attributeType.input === 'file') {
                                localeState[`upload_${attr.node.identifier}`] = null;
                                localeState[`mapper_${attr.node.identifier}`] = mapper;
                            }
                            else if (attr.node.attributeType.input === 'text') {
                                localeState[attr.node.identifier] = null;
                                localeState[`mapper_text_${attr.node.identifier}`] = mapper;
                            }
                            setState({
                                ...state,
                                [locale.node.code]: localeState
                            });
                        }
                    }

                    setState({
                        ...state,
                        dataAddLayout: getLayoutAdd
                    });

                    // SELECT Type File
                    for (let attr of attrGroup.node.attributes.edges) {
                        if (attr.node.attributeType?.input === "select") {
                            let retailer_file_type = {
                                type: 'select',
                                isContainerized: true,
                                label: props.t("connectors.retailers.fileType"),
                                translated: false,
                                helper: {
                                    text: props.t("connectors.retailers.chooseFileType"),
                                    link: false,
                                },
                                required: false,
                                stateName: 'retailer_file_type',
                                value: []
                            }

                            for (let item of attr.node.attributeOptions.edges) {
                                if (retailer_file_type.value.length === 0) {
                                    setState({...state,retailer_file_type: item.node.identifier });
                                }
                                retailer_file_type.value.push({ value: item.node.identifier, label: item.node.translation.translationDatas.edges[0].node.value })
                            }

                            let configFormAdd = state.dataAddLayout;
                            configFormAdd.formConfig.children[1].optionsInputs.push(retailer_file_type);

                            setState({
                                ...state,
                                dataAddLayout: configFormAdd
                            });
                        }
                    }
                    state.dataAddLayout.formConfig.children[1].optionsInputs.push(separator);

                    for (let attr of attrGroup.node.attributes.edges) {
                        //LABEL ATTRIBUTION
                        let label = '';
                        if (attr.node.translation.translationDatas) {
                            label = attr.node.translation.translationDatas.edges[0].node.value;
                        }
                        else {
                            label = attr.node.identifier
                        }

                        if (attr.node.attributeType.input === "file") {
                            if (state.editForm === "add") {
                                let uploadFile = _.clone(setupUploadFile);
                                uploadFile.stateName = `upload_${attr.node.identifier}`;
                                uploadFile.label = label;

                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `upload_${attr.node.identifier}`;
                                mapping.stateName = `mapper_${attr.node.identifier}`;
                                if (props.type !== 'brand') {
                                    mapping.staticOptions = staticOptions;
                                }
                                setState({
                                    ...state,
                                    attrTypeFile:[...state.attrTypeFile,`upload_${attr.node.identifier}`,`mapper_${attr.node.identifier}`]
                                });

                                let configFormAdd = state.dataAddLayout;
                                configFormAdd.formConfig.children[1].optionsInputs.push(uploadFile);
                                configFormAdd.formConfig.children[2].optionsInputs.push(mapping);

                                setState({
                                    ...state,
                                    dataAddLayout: configFormAdd
                                });
                            }

                            else if (state.editForm === "edit") {
                                for (let retailer of state.retailers) {
                                    if (retailer.node.id === state.editId) {
                                        for (let attribute of retailer.node.retailerData.edges) {
                                            if (attribute.node.attribute.id === attr.node.id && attribute.node.media.filePath) {
                                                state[attribute.node.locale.code][`upload_${attr.node.identifier}`] = { file: { name: attribute.node.media.filePath } };
                                            }
                                        }
                                    }
                                }
                                let uploadFile = _.clone(setupUploadFile);
                                uploadFile.stateName = `upload_${attr.node.identifier}`;
                                uploadFile.label = label;

                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `upload_${attr.node.identifier}`;
                                mapping.stateName = `mapper_${attr.node.identifier}`;

                                if (props.type !== 'brand') {
                                    mapping.staticOptions = staticOptions;
                                }

                                let configFormAdd = state.dataAddLayout;
                                configFormAdd.formConfig.children[1].optionsInputs.push(uploadFile);
                                configFormAdd.formConfig.children[2].optionsInputs.push(mapping);

                                state.attrTypeFile.push(`upload_${attr.node.identifier}`);
                                state.attrTypeFile.push(`mapper_${attr.node.identifier}`);

                                setState({
                                    ...state,
                                    dataAddLayout: configFormAdd,
                                });
                            }
                        }

                        else if (attr.node.attributeType.input === "text") {
                            if (state.editForm === "add") {
                                let inputText = _.clone(setupInputText);
                                inputText.helper.text = label;
                                inputText.label = label;
                                inputText.stateName = attr.node.identifier;

                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `${attr.node.identifier}`;
                                mapping.stateName = `mapper_text_${attr.node.identifier}`;
                                if (props.type !== 'brand') {
                                    mapping.staticOptions = staticOptions;
                                }

                                let configFormAdd = state.dataAddLayout;

                                if (attr.node.identifier.match(/url/g)) {
                                    state.attrTypeUrl.push(attr.node.identifier);
                                    state.attrTypeUrl.push(`mapper_text_${attr.node.identifier}`);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);
                                    configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                }

                                else if (attr.node.identifier.match(/ftp/g)) {
                                    state.attrTypeFtp.push(attr.node.identifier);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);

                                    if (attr.node.identifier.match(/filename/g)) {
                                        state.attrTypeFtp.push(`mapper_text_${attr.node.identifier}`);
                                        configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                    }
                                }
                                setState({ ...state,dataAddLayout: configFormAdd });
                            }
                            else if (state.editForm === 'edit') {
                                for (let retailer of state.retailers) {
                                    if (retailer.node.id === state.editId) {
                                        for (let attribute of retailer.node.retailerData.edges) {
                                            if (attribute.node.value !== null) {
                                                state[attribute.node.locale.code][attribute.node.attribute.identifier] = attribute.node.value;
                                            }
                                        }
                                    }
                                }

                                let inputText = _.clone(setupInputText);
                                inputText.helper.text = label;
                                inputText.label = label;
                                inputText.stateName = attr.node.identifier;

                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `${attr.node.identifier}`;
                                mapping.stateName = `mapper_text_${attr.node.identifier}`;
                                if (props.type !== 'brand') {
                                    mapping.staticOptions = staticOptions;
                                }

                                let configFormAdd = state.dataAddLayout;
                                if (attr.node.identifier.match(/url/g)) {
                                    state.attrTypeUrl.push(attr.node.identifier);
                                    state.attrTypeUrl.push(`mapper_text_${attr.node.identifier}`);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);
                                    configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                }

                                else if (attr.node.identifier.match(/ftp/g)) {
                                    state.attrTypeFtp.push(attr.node.identifier);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);
                                    if (attr.node.identifier.match(/filename/g)) {
                                        state.attrTypeFtp.push(`mapper_text_${attr.node.identifier}`);
                                        configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                    }
                                }

                                setState({
                                    ...state,
                                    dataAddLayout: configFormAdd,
                                });
                            }
                        }
                    }
                }
            }

            setState({ ...state, importFile: props.type === "brand" ? 'ftp' : 'file' });
        }).catch((err)=>{
            console.log(err)
        });
    }

    const handlerSaveRetailerData = () => {
        return new Promise(async (resolve, reject) => {
            if (state.editForm === "add") {
                for (let locale of props.locales) {
                    for (let item of state.attr.edges) {
                        if (state.importFile === 'file') {
                            if (state[locale.node.code][`upload_${item.node.identifier}`] && state[locale.node.code][`upload_${item.node.identifier}`].medias) {
                                let value = state[locale.node.code][`upload_${item.node.identifier}`].medias.id;

                                let variables = {
                                    "retailer": state.idNewRetailer,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id
                                };

                                variables.media = `/api/media-objects/${value}`;

                                await props.client.mutate({
                                    mutation: ADD_RETAILER_DATA,
                                    variables
                                });
                            }
                        }
                        else {
                            if (state[locale.node.code][item.node.identifier]) {
                                let value = state[locale.node.code][item.node.identifier];

                                let variables = {
                                    "retailer": state.idNewRetailer,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id,
                                    "value": value,
                                };

                                await props.client.mutate({
                                    mutation: ADD_RETAILER_DATA,
                                    variables
                                });
                            }
                        }
                    }
                }
            }

            else if (state.editForm === "edit") {
                let editRetailerData = {};
                for (let retailer of state.retailers) {
                    if (retailer.node.id === state.editId) {
                        editRetailerData = retailer.node.retailerData.edges;
                    }
                }

                for (let locale of props.locales) {
                    for (let item of state.attr.edges) {
                        if (state.importFile === 'file') {
                            if (state[locale.node.code][`upload_${item.node.identifier}`] && state[locale.node.code][`upload_${item.node.identifier}`].medias) {
                                let value = state[locale.node.code][`upload_${item.node.identifier}`].medias.id;
                                let variables = {
                                    "retailer": state.editId,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id,
                                    "media": `/api/media-objects/${value}`
                                };

                                let update = false;
                                for (let attribute of editRetailerData) {
                                    if (attribute.node.attribute.identifier === item.node.identifier && attribute.node.locale.code === locale.node.code) {
                                        update = attribute.node.id;
                                    }
                                }

                                if (update) {
                                    variables.id = update;
                                    await props.client.mutate({
                                        mutation: UPDATE_RETAILER_DATA,
                                        variables
                                    });
                                }

                                else {
                                    await props.client.mutate({
                                        mutation: ADD_RETAILER_DATA,
                                        variables
                                    });
                                }
                            }
                        }
                        else {
                            if (state[locale.node.code][item.node.identifier]) {
                                let value = state[locale.node.code][item.node.identifier];
                                let variables = {
                                    "retailer": state.editId,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id,
                                    "value": value
                                };
                                let update = false;
                                for (let attribute of editRetailerData) {
                                    if (attribute.node.attribute.identifier === item.node.identifier && attribute.node.locale.code === locale.node.code) {
                                        update = attribute.node.id;
                                    }
                                }

                                if (update) {
                                    variables.id = update;
                                    await props.client.mutate({
                                        mutation: UPDATE_RETAILER_DATA,
                                        variables
                                    });
                                }

                                else {
                                    await props.client.mutate({
                                        mutation: ADD_RETAILER_DATA,
                                        variables
                                    });
                                }
                            }
                        }
                    }
                }
            }
            setState({
                ...state,
                openForm: !state.openForm
            })
            props.stopLoading();
            handleGetRetailers();
            resolve();
        });
    }

    const handlerMutation = () => {
        props.startLoading();
        let query = null;
        let variables = null;
        switch (state.editForm) {
            case 'edit':
                query = UPDATE_RETAILER;
                variables =
                {
                    "id": state.editId,
                    "identifier": state.attributeIdentifier,
                    "description": state.description,
                    "libelle": state.libelle,
                    "attributeGroup": state.attrGroupId,
                    "isInternal": state.isInternal,
                }

                break;
            case 'add':
                query = ADD_RETAILER;
                variables =
                {
                    "identifier": state.attributeIdentifier,
                    "description": state.description,
                    "libelle": state.libelle,
                    "attributeGroup": state.attrGroupId,
                    "isInternal": state.isInternal,
                }
                break;
        }
        if (state.image) {
            variables.logo = state.image.data
        }
        props.client.mutate({
            mutation: query,
            variables,
        }).then(result => {
            if (state.editForm === 'add') {
                setState({
                    ...state,
                    idNewRetailer: result.data.createRetailer.retailer.id
                });
                handlerSaveRetailerData();
                handlerImportFiles();
            }
            else if (state.editForm === "edit") {
                setState({
                    ...state,
                    idNewRetailer: state.editId
                });
                handlerSaveRetailerData();
                handlerImportFiles();
            }
            setRedux(false, true, false, false)
        }).catch((err)=>{
            console.log(err)
        });
    }

    const handlerFtpFile = async () => {
        for (let locale of props.locales) {
            if (state[locale.node.code].retailer_ftp_host && state[locale.node.code].retailer_ftp_port && state[locale.node.code].retailer_ftp_username && state[locale.node.code].retailer_ftp_password && state[locale.node.code].retailer_ftp_path && state[locale.node.code].retailer_ftp_filename) {
                let formData = new FormData();
                formData.append('retailer_ftp_host', state[locale.node.code].retailer_ftp_host)
                formData.append('retailer_ftp_port', state[locale.node.code].retailer_ftp_port)
                formData.append('retailer_ftp_username', state[locale.node.code].retailer_ftp_username)
                formData.append('retailer_ftp_password', state[locale.node.code].retailer_ftp_password)
                formData.append('retailer_ftp_path', state[locale.node.code].retailer_ftp_path)
                formData.append('retailer_ftp_filename', state[locale.node.code].retailer_ftp_filename)
                formData.append('retailer_file_type', state.retailer_file_type)

                try {
                    request(state.importFile === 'ftp' ? `${process.env.REACT_APP_API_ROOT}/api/retailer/ftp/` : `${process.env.REACT_APP_API_ROOT}/api/retailer/sftp/`, 'post', formData, 'multipart/form-data').then(
                        (data) => {
                            let getState = state[locale.node.code];
                            getState.retailer_ftp_filename_import = data.fileUrl
                            setState({
                                ...state,
                                [locale.node.code]: getState
                            });
                        }
                    ).catch((err)=>{
                        console.log(err)
                    });
                    props.snack(ALERT_SUCCESS, `Succès !`);
                } catch (e) {
                    props.snack(ALERT_ERROR, `Echec !`);
                }
            }
        }
    }

    const getHeaderFtp = (item, locale) => {
        return new Promise(async (resolve, reject) => {
            let mapOn = item.node.identifier + "_import";

            let headersFormData = new FormData();
            headersFormData.append(state.importSep === 'xml' ? 'xml' : 'csv', state[locale.node.code][mapOn]);
            headersFormData.append('separator', state.importSep);
            let resultHeaders = await axios(state.importSep !== "xml" ? `${process.env.REACT_APP_API_ROOT}/api/export/csv/structure` : `${process.env.REACT_APP_API_ROOT}/api/export/simplexml/structure`, 'post', headersFormData);

            let mapper = [];
            let mapping = state[locale.node.code][`mapper_text_${item.node.identifier}`];
            for (let header of resultHeaders.message) {
                mapper.push(mapping[header])
            }
            let importConfig = {
                "url": state[locale.node.code][mapOn],
                "mapper": mapper,
                "eavType": props.attributes.eavTypes.find(e => e.node.code === 'retailer').node.id,
                "locale": locale.node.id,
                "retailer": state.idNewRetailer,
                "delimiter": state.retailer_file_type === "retailer_file_type_csv" || state.retailer_file_type === "retailer_file_type_simplexml" ? state.importSep : null,
                "fileType": state.importSep === "xml" ? "simplexml" : null,
            };

            try {
                // todo thomas
                await fetch(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
                props.snack(ALERT_SUCCESS, props.t("connectors.retailers.importSuccessful"));
                props.stopLoading();
            } catch (e) {
                props.snack(ALERT_ERROR, props.t("content.content.importMediaFailed"));
                props.stopLoading();
            }
            resolve();
        });
    }

    const getHeaderUrl = (item, locale) => {
        return new Promise(async (resolve, reject) => {
            let headersFormData = new FormData();
            headersFormData.append(state.retailer_file_type === "retailer_file_type_simplexml" ? 'xml' : 'csv', state[locale.node.code][item.node.identifier]);
            headersFormData.append('separator', state.importSep);

            let resultHeaders = await axios(state.importSep !== "xml" ? `${process.env.REACT_APP_API_ROOT}/api/export/csv/structure` : `${process.env.REACT_APP_API_ROOT}/api/export/simplexml/structure`, 'post', headersFormData);

            let mapper = [];
            let mapping = state[locale.node.code][`mapper_text_${item.node.identifier}`];
            for (let header of resultHeaders.message) {
                mapper.push(mapping[header])
            }

            let importConfig = {
                "url": state[locale.node.code][item.node.identifier],
                "mapper": mapper,
                "eavType": props.attributes.eavTypes.find(e => e.node.code === 'retailer').node.id,
                "locale": locale.node.id,
                "retailer": state.idNewRetailer,
                "delimiter": state.importSep,
                "fileType": state.importSep === "xml" ? "simplexml" : null,
            };

            try {
                // todo thomas
                await fetch(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
                props.snack(ALERT_SUCCESS, props.t("connectors.retailers.importSuccessful"));
                props.stopLoading();
            } catch (e) {
                props.snack(ALERT_ERROR, props.t("content.content.importMediaFailed"));
                props.stopLoading();
            }
            resolve();
        });
    }

    const handlerImportFiles = async () => {
        for (let locale of props.locales) {
            for (let item of state.attr.edges) {
                if (state.importFile === 'file') {
                    if (item.node.identifier && state[locale.node.code][`upload_${item.node.identifier}`]) {
                        if (state[locale.node.code][`mapper_${item.node.identifier}`]?.length !== [] && state[locale.node.code][`upload_${item.node.identifier}`] !== null && state[locale.node.code][`upload_${item.node.identifier}`].headers) {
                            let mapper = [];
                            let mapping = state[locale.node.code][`mapper_${item.node.identifier}`];
                            for (let header of state[locale.node.code][`upload_${item.node.identifier}`].headers) {
                                mapper.push(mapping[header])
                            }
                            let importConfig = {
                                "url": `${process.env.REACT_APP_API_ROOT}${state[locale.node.code][`upload_${item.node.identifier}`].medias?.contentUrl ?? null}`,
                                "mapper": mapper,
                                "eavType": props.attributes.eavTypes.find(e => e.node.code === 'retailer').node.id,
                                "locale": locale.node.id,
                                "retailer": state.idNewRetailer,
                                "delimiter": state.importSep,
                                "fileType": state.importSep === "xml" ? "simplexml" : null,
                            };

                            try {
                                // todo thomas
                                await fetch(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
                                props.snack(ALERT_SUCCESS, props.t("connectors.retailers.importSuccessful"));
                                props.stopLoading();
                            } catch (e) {
                                props.snack(ALERT_ERROR, props.t("content.content.importMediaFailed"));
                                props.stopLoading();
                            }
                        }
                    }
                }
                else if (state.importFile === 'ftp' || state.importFile === 'sftp') {
                    if (item.node.identifier.match(/ftp_filename/g) && state[locale.node.code][item.node.identifier]) {
                        getHeaderFtp(item, locale);
                    }
                }
                else if (state.importFile === "url") {
                    if (item.node.identifier.match(/url/g) && state[locale.node.code][item.node.identifier]) {
                        getHeaderUrl(item, locale);
                    }
                }
            }
        }
    }

    const handleGetAttributGroup = async () => {
        try {
            let data = await props.client.query({query: GET_ATTR_GROUPE_LIGHT,variables: { "is_internal": state.isInternal },fetchPolicy: "no-cache"})
            for (let item of data.data.attributeGroups.edges) {
                if (item.node.identifier === props.type) {
                    setState({ ...state,idAttrGroup: item.node.id, attrGroup: true });
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleGetRetailers = async () => {
        if (!state.idAttrGroup || !state.attrGroup) {
            await handleGetAttributGroup();
        }
        try {
            let data = await props.client.query({query: GET_RETAILERS,variables: { "is_internal": state.isInternal, "attributeGroup": props.type === "flow" ? null : state.idAttrGroup },fetchPolicy: "no-cache"})    
            setState({
                ...state,
                retailers: data.data.retailers.edges,
                filtered: data.data.retailers.edges,
                categories: [],
                ready: true,
                isEmpty:data.data.retailers.edges.length === 0 ?true :false
            });
        } catch (error) {
            console.log(error)
        }
    }

    const deleteRetailerDatas = async () => {
        try {
            for (let retailer of state.retailers) {
                if (retailer.node.id === state.editId) {
                    for (let attr of retailer.node.retailerData.edges) {
                        await props.client.mutate({
                            mutation: DELETE_RETAILER_DATA,
                            variables: { "id": attr.node.id }
                        })
                    }
                }
            }
        } catch (error) {
            
        }
    }
    
    const deleteChannel = async () => {
        try {
            await props.client.mutate({
                mutation: DELETE_RETAILER,
                variables: { "id": state.editId },
            }) ;   
            handleGetRetailers();
            setState({
                ...state,
                openDialogRemove: false
            });
        } catch (error) {
            console.log(error)
        }
    }

    const deleteMutation = async () => {
        props.startLoading();
        await deleteRetailerDatas();
        await deleteChannel();
        await props.stopLoading();
    }

    const editChannel = async (id, identifier) => {
        await setState({
            ...state,
            editForm: "edit",
            editId: id,
            editIdentifier: identifier,
        });
        props.type === "retailer" ?
                state.dataAddLayout.titleForm = props.t("connectors.retailers.editRetailer")
                : props.type === "flow" ?
                    state.dataAddLayout.titleForm = props.t("connectors.retailers.editFeed")
                    :
                    state.dataAddLayout.titleForm = props.t("connectors.retailers.editBrand")
        handlerSetup();
        handleToggleDrawer();
    }

    const handleToggleDialog = (id = null) => {
        if(id){
            setState({
                ...state,
                openDialogRemove: !state.openDialogRemove,
                editId: id
            })
        }
        setState({ 
            ...state,
            openDialogRemove: !state.openDialogRemove 
        });
    }


    const getListCronByRetailer = (id, retailer) => {
        setState({
            ...state,
            listCrons: null,
            drawerCron: true,
            retailerCron: retailer
        });

        try {
            request(`${process.env.REACT_APP_API}/scheduler/retailer/${id}`, 'get', false)
            .then(
                (data) => {
                    if (data.success) {
                        setState({ ...state, listCrons: data.crons })
                    }
                }
            ).catch((err)=>{
                console.log(err)
            });
        } catch (e) {
            console.log(e);
        }
    }

    const handleToggleDrawer = () => {
        if (state.editForm === "edit" && state.openForm ) {
            for (let retailer of state.retailers) {
                if (retailer.node.id === state.editId) {
                    setState({
                        ...state,
                        openForm: !state.openForm,
                        libelle: retailer.node.libelle,
                        description: retailer.node.description,
                        image: { changed: false, data: retailer.node.logo },
                        attributeIdentifier: retailer.node.identifier
                    });
                }
            }
            let children = 0;
            for (let item of state.dataAddLayout.formConfig.children) {
                let optionsInputs = 0;
                for (let input of item.optionsInputs) {
                    if (input.stateName === "attributeIdentifier") {
                        let getLayoutAdd = state.dataAddLayout
                        getLayoutAdd.formConfig.children[children].optionsInputs[optionsInputs].disabled = true
                        setState({
                            ...state,
                            dataAddLayout: getLayoutAdd
                        });
                    }
                    optionsInputs++;
                }
                children++;
            }
        }
        else if (state.editForm === "add" && state.openForm) {
            setState({
                ...state,
                openForm: !state.openForm,
                libelle: undefined,
                description: undefined,
                image: undefined,
                attributeIdentifier: undefined,
            });
            handlerSetup();
            let children = 0;
            for (let item of state.dataAddLayout.formConfig.children) {
                let optionsInputs = 0;
                for (let input of item.optionsInputs) {
                    if (input.stateName === "attributeIdentifier") {
                        let getLayoutAdd = state.dataAddLayout
                        getLayoutAdd.formConfig.children[children].optionsInputs[optionsInputs].disabled = false
                        setState({
                            ...state,
                            dataAddLayout: getLayoutAdd
                        });
                    }
                    optionsInputs++;
                }
                children++;
            }
        }

        if (!state.openForm) {
            setState({
                ...state,
                editForm: "add",
                openForm: !openForm
            });
            state.dataAddLayout.titleForm = props.type === "retailer" ? props.t("spread.campaigns.addRetailers") : props.type === "flow" ? "Ajouter un flux" : "Ajouter une marque";
        }
    }

    const hasErrors = () => {
        if (state.errors) {
            for (let error in state.errors) {
                if (state.errors[error])
                    return true;
            }
        }
        return false;
    };

    const handleFormError = (stateName, error) => {
        let errors = state.errors;
        errors[stateName] = error;
        setState({ ...state,errors });
    };

    useEffect(() => {
        checkRouting(props);
        let mainRight = null;
        let secondaryRight = null;

        if (props.isInternal) {
            mainRight = BRAND;
            secondaryRight = BRAND_FLOW;
            setState({
                ...state,
                mainRight,
                secondaryRight,
            });
        } else {
            mainRight = CONNECTORS;
            secondaryRight = CONNECTORS_RETAILERS;
            setState({
                ...state,
                mainRight,
                secondaryRight,
            });
        }
        handleGetRetailers();
        let finalStep =
        {
            picto: EmptyRetailers,
            title: props.t("connectors.retailers.andThereYouGo"),
            subtitle: props.t("connectors.retailers.spreadInfo"),
            textButton: props.t("connectors.retailers.connect")
        }
        let getLayoutAdd = state.dataAddLayout;
        getLayoutAdd.formConfig.finalStep = finalStep;
        getLayoutAdd.titleForm = state.content.titleForm;
        setState({
            ...state,
            dataAddLayout: getLayoutAdd
        });
    }, [])

    useEffect(() => {
        let mainRight = null;
        let secondaryRight = null;
        if (props.isInternal) {
            mainRight = BRAND;
                secondaryRight = BRAND_FLOW;
                setState({
                    ...state,
                    mainRight,
                    secondaryRight,
                });
        }else {
            mainRight = CONNECTORS;
            secondaryRight = CONNECTORS_RETAILERS;
            setState({
                ...state,
                mainRight,
                secondaryRight,
            });
        }
        setState({
            ...state,
            filtered: [],
            retailer: [],
            openForm: false,
            editForm: 'add',
            isInternal: props.isInternal,
            attrGroup: false,
            ready: false,
            content: props.type === "retailer" ?
                {
                    title: process.env.REACT_APP_MODE_SPREAD !== "hub" ? props.t("connectors.retailers.manageRetailers") : props.t("connectors.retailers.manageWarehouses"),
                    subtitle: process.env.REACT_APP_MODE_SPREAD !== "hub" ? props.t("connectors.retailers.manageRetailerInfo") : props.t("connectors.retailers.manageWarehousesinfo"),
                    picto: "picto-retailer",
                    txtBtn: process.env.REACT_APP_MODE_SPREAD !== "hub" ? props.t("spread.campaigns.addRetailers") : null,
                    titleForm: props.t("spread.campaigns.addRetailers"),
                    emptyTitle: props.t("connectors.retailers.notConfiguredRetailers"),
                    emptySubtitle: props.t("spread.active_assets.clickToAdd"),
                    emptyTxtBtn: props.t("spread.campaigns.addRetailers"),
                    emptyPicto: EmptyRetailers,
                }
                : props.type === "flow" ?
                    {
                        title: props.t("connectors.retailers.manageFlow"),
                        subtitle: props.t("connectors.retailers.manageFlowInfo"),
                        picto: "icon-flux",
                        txtBtn: props.t("connectors.retailers.addFeed"),
                        titleForm: props.t("connectors.retailers.addFeed"),
                        emptyTitle: props.t("connectors.retailers.notConfiguredFeed"),
                        emptySubtitle: props.t("spread.active_assets.clickToAdd"),
                        emptyTxtBtn: props.t("connectors.retailers.addNewFeed"),
                        emptyPicto: EmptyFlux,
                    }
                    :
                    {
                        title: process.env.REACT_APP_MODE_SPREAD !== "hub" ? props.t("connectors.retailers.manageBrand") : "ERP",
                        subtitle: process.env.REACT_APP_MODE_SPREAD !== "hub" ? props.t("connectors.retailers.manageBrandInfo") : props.t("connectors.retailers.manageErp"),
                        picto: "icon-business",
                        txtBtn: process.env.REACT_APP_MODE_SPREAD !== "hub" ? props.t("connectors.retailers.addBrand") : null,
                        titleForm: props.t("connectors.retailers.addBrand"),
                        emptyTitle: props.t("connectors.retailers.notConfiguredBrand"),
                        emptySubtitle: props.t("connectors.retailers.clickToAddBrand"),
                        emptyTxtBtn: props.t("connectors.retailers.addNewBrand"),
                        emptyPicto: EmptyRetailers,
                    },
        });
        handleGetRetailers();
        let finalStep =
        {
            picto: EmptyRetailers,
            title: props.t("connectors.retailers.andThereYouGo"),
            subtitle: props.t("connectors.retailers.spreadInfo"),
            textButton: props.t("connectors.retailers.connect")
        }
        let getLayoutAdd = state.dataAddLayout;
        getLayoutAdd.formConfig.finalStep = finalStep;
        getLayoutAdd.titleForm = state.content.titleForm;
        setState({
            ...state,
            dataAddLayout: getLayoutAdd
        });
    }, [props.isInternal,props.type])

    useEffect(() => {
        let inputHidden = [];
        if (state.importFile === 'file') {
            setState({
                ...state,
                inputHidden: []
            });
            for (let item of state.attrTypeUrl) {
                inputHidden.push(item)
            }
            for (let item of state.attrTypeFtp) {
                inputHidden.push(item);
            }
            inputHidden.push('retailer_file_type');
        }
        else if (state.importFile === 'url') {
            setState({
                ...state,
                inputHidden: []
            })
            for (let item of state.attrTypeFile) {
                inputHidden.push(item)
            }
            for (let item of state.attrTypeFtp) {
                inputHidden.push(item);
            }
            inputHidden.push('retailer_file_type');
        }
        else if (state.importFile === 'ftp' || state.importFile === 'sftp') {
            setState({
                ...state,
                inputHidden: []
            });
            for (let item of state.attrTypeUrl) {
                inputHidden.push(item)
            }
            for (let item of state.attrTypeFile) {
                inputHidden.push(item);
            }
            if (state.retailer_file_type !== "retailer_file_type_csv" && state.retailer_file_type !== "retailer_file_type_simplexml") {
                setState({...state, importSep: ',' });
                inputHidden.push("importSep");
            }
        }
        setState({...state, inputHidden })
    }, [state.importFile])
    
    
    useEffect(() => {
        let inputHidden = [];
        if (state.retailer_file_type === "retailer_file_type_csv") {
            setState({
                ...state,
                inputHidden: []
            });
            for (let item of state.attrTypeUrl) {
                inputHidden.push(item)
            }
            for (let item of state.attrTypeFile) {
                inputHidden.push(item);
            }
        } else if (state.retailer_file_type === "retailer_file_type_simplexml") {
            setState({
                ...state,
                inputHidden: []
            });
            for (let item of state.attrTypeUrl) {
                inputHidden.push(item)
            }
            for (let item of state.attrTypeFile) {
                inputHidden.push(item);
            }
            setState({ ...state,importSep: 'xml' });
            inputHidden.push("importSep");
        }
        else {
            setState({
                ...state,
                inputHidden: [],
                importSep: ',',
            });
            for (let item of state.attrTypeUrl) {
                inputHidden.push(item)
            }
            for (let item of state.attrTypeFile) {
                inputHidden.push(item);
            }
            inputHidden.push("importSep");
        }
        setState({ ...state,inputHidden })
    }, [state.retailer_file_type])

    const goTo = route => {
        props.history.push(route);
    };

    const goToDetails = (route, id) => {
        props.history.push({
            pathname: route,
            state: { retailerId: id }
        });
    };
    return (
        <PageWrapper>
                {state.content ?
                    (
                        <TopPanel
                            icomoon={state.content.picto}
                            colorIcomoon={colors.blue.darker.hue300}
                            title={state.content.title}
                            subtitle={state.content.subtitle}
                            gradientColor1={colors.menu.regular}
                            gradientColor2={colors.menu.darker}
                            handlerAdd={() => { handleToggleDrawer() }}
                            // textAdd={state.isEmpty ? null : '+ ' + state.retailers ? '+ ' + state.content.txtBtn : null}
                            textAdd={state.content.txtBtn ? '+ ' + state.content.txtBtn : null}
                            searchHandler={(state.isEmpty || !state.retailers) ? null : handleChange}
                            searchStyle={state.isEmpty ? { paddingTop: 16 } : ''}
                            openForm={state.openForm}
                            buttonAvailable={state.openForm ? false : true}
                            windowWidth={props.windowWidth}
                            noResult={state.filtered.length > 0?false:true}
                            hasBorder={true}
                        />
                    ) : null
                }
                <Grid container direction="column" spacing={0} style={{ width: state.openForm && state.isEmpty ? `calc(100% - ((50% - ${props.drawerWidth}px / 2) + (${props.drawerWidth}px / 2) + 32px))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', position: 'relative' }}>                    
                    <Grid container direction="row" spacing={2} style={{
                        // position: 'relative',
                        width: '100%',
                        // height: '100%',
                    }}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            display: 'grid',
                            gridTemplateColumns:state.ready?"repeat(3,1fr)":"1fr",
                            gap: '16px',
                        }}>
                            {state.isEmpty ?
                                <EmptyCard title={state.content.emptyTitle} subtitle={state.content.emptySubtitle} textButton={state.content.emptyTxtBtn} onClick={() => { handleToggleDrawer() }} picto={state.content.emptyPicto} openForm={state.openForm} xsImg={state.openForm ? 4 : 2} />
                                :
                                state.ready ?
                                    <>
                                        {
                                            state.filtered.length > 0 ? (
                                                state.filtered.map((type, index) =>
                                                    <GridCustom item xs={12} key={`ListRetailers${index}`} >
                                                        <CardCustom paddingbottom={0} paddingtop={0} style={{ width: "100%" }} contentpadding={'0px'}>
                                                            <CardSetup 
                                                                type={type} 
                                                                textButton={'Set up'} 
                                                                handlerEdit={process.env.REACT_APP_MODE_SPREAD !== "hub" ? () => editChannel(type.node.id, type.node.identifier) : null} cronListOpening={getListCronByRetailer} isRetailer={true} showDetails={props.type === 'flow' ? () => goToDetails(ROUTE_BRAND_FLOW_DETAILS.replace(':id', type.node.id.replace('/api/retailers/', '')), type.node.id) : false} 
                                                                handlerMenu={{
                                                                    toggleDeleteDialog: handleToggleDialog,
                                                                    // toggleEditDialog: handleToggleDialog,
                                                                }}
                                                            />
                                                        </CardCustom>
                                                    </GridCustom>
                                                )
                                            )
                                                : (<EmptyCard title={props.t("catalogs.catalogs.noResults")} textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? state.content.emptyTxtBtn : null} onClick={() => { handleToggleDrawer() }} picto={state.content.emptyPicto} openForm={state.openForm} xsImg={state.openForm ? 4 : 2} />)
                                        }
                                    </>
                                    : (
                                        <Box style={{
                                            width: '100%',
                                            display:"flex",
                                            justifyContent:"center",
                                            alignItems:"center"
                                        }}>
                                            <PageLoader />
                                        </Box>
                                    )
                            }
                        </div>
                    </Grid>
                </Grid>
                {
                    state.dataAddLayout ?
                        (
                            <LayoutBuilder
                                isSublayout={false}
                                opened={state.openForm}
                                icomoon={props.type === "retailer" ? 'picto-retailer' : props.type === "flow" ? 'icon-flux' : 'icon-business'} gradientColor1={colors.menu.regular} gradientColor2={colors.menu.darker}
                                forClose={() => { setState({openForm:false}) }}
                                dataLayout={state.dataAddLayout}
                                drawerWidth={props.drawerWidth}
                                stateCallback={stateCallback}
                                handleButtonGroupChange={handleButtonGroupChange}
                                stepperButtonAction={[
                                    () => {
                                        if (hasErrors()) {
                                            props.snack(ALERT_ERROR, props.t("spread.active_assets.toastCheckField"));
                                            setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
                                        return true;
                                    },
                                    () => {
                                        handlerFtpFile();
                                        if (hasErrors()) {
                                            props.snack(ALERT_ERROR, props.t("spread.active_assets.toastCheckField"));
                                            setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
                                        return true;
                                    }, null
                                ]}
                                errorCallback={handleFormError}
                                stepperSkip={3}
                                validateButton={true}
                                // deleteMutation={state.editForm === 'edit' ? handleToggleDialog : null}
                                stepperButtonDisabled={[null, null, null]}
                                // deleteText={state.editForm === 'edit' ? props.t("spread.active_assets.delete") : null}
                                handlerMutation={() => { handlerMutation() }}
                                allState={state}
                                currentLang={state.currentLang}
                                handleLang={handleLang}
                                hideInput={state.inputHidden}
                                windowWidth={props.windowWidth}
                            />
                        ) : null
                }
                <DialogModal
                    icon={true}
                    type='delete'
                    open={state.openDialogRemove}
                    title={
                        props.type === "retailer"
                            ? props.t("connectors.retailers.sureToDeleteRetailer")
                            : props.type === "flow"
                                ? props.t("connectors.retailers.sureToDeleteFeed")
                                : props.t("connectors.retailers.sureToDeleteBrand")
                    }
                    secondaryAction={handleToggleDialog}
                    primaryAction={deleteMutation}
                    windowWidth={props.windowWidth}
                >
                    {props.type === "retailer"
                        ? props.t("connectors.retailers.verySureToDeleteRetailer")
                        : props.type === "flow"
                            ? props.t("connectors.retailers.verySureToDeleteFeed")
                            : props.t("connectors.retailers.verySureToDeleteBrand")
                    }
                </DialogModal>
                <CronNotifications closeDrawer={() => setState({ drawerCron: false })} drawerCron={state.drawerCron} retailerCron={state.retailerCron} listCrons={state.listCrons} />
            </PageWrapper>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        typeOf: state.typeOf,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListRetailers))));