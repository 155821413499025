import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { AppBarCustom, SwitchTabCustom } from "./styles/styled";
import colors from "../../../config/theme/colors";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import { DIRECTIONS } from "./constants/constants";

/**
 * OurTabs Component
 *
 * @component
 * @param {Object[]} tabs - Array of tabs configuration.
 * @param {ReactNode} children - Tab contents as children.
 * @param {Function} handleGetCurrentTab - Callback function to get the current active tab index.
 * @param {Object} props - Other props to be passed to the component.
 * @returns {JSX.Element} OurTabs component.
 * @example
 ** Example 1: Using configured components
 * const tabsConfig = [
 *   {
 *     labelName: 'Tab 1',
 *     component: () => <p>Content 1</p>
 *   },
 *   {
 *     labelName: 'Tab 2',
 *     component: () => <p>Content 2</p>
 *   },
 * ];
 *
 * <OurTabs tabs={tabsConfig} />
 *
 * @example
 ** Example 2: Using custom components/children
 * const customTabs = [
 *   'Tab 1',
 *   'Tab 2',
 * ];
 *
 * <OurTabs tabs={customTabs} handleGetCurrentTab={(state) => {
    this.setState({
            tabState: state
        })
    }}>
 *   <div value={this.state.tabState} hidden={true}>Content 1</div>
 *   <div value={this.state.tabState} hidden={false}>Content 2</div>
 * </OurTabs>
 */
const OurTabs = ({ tabs, children, hasSwitch, handleGetCurrentTab, ...props }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isLeftSwitchActive, setIsLeftSwitchActive] = useState(false);
  const [isRightSwitchActive, setIsRightSwitchActive] = useState(true);
  const appBar = useRef(null)

  useEffect(() => {
    const TAB_BAR_SCROLL_BASE = 107;

    if (typeof handleGetCurrentTab === "function") {
      handleGetCurrentTab(currentTab);

      setTimeout(() => {
        const scrollableChild = appBar.current.querySelector('.MuiTabs-scroller');
        if (scrollableChild.scrollLeft > 0) {
          checkScrollSwitches(DIRECTIONS.RIGHT, {
            scrollableNode: scrollableChild,
            tabBarScrollbase: TAB_BAR_SCROLL_BASE
          });
        } else {
          checkScrollSwitches(DIRECTIONS.LEFT, {
            scrollableNode: scrollableChild,
            tabBarScrollbase: TAB_BAR_SCROLL_BASE
          });
        }
      }, 500)
    }
  }, [currentTab]);

  const isLastTab = (currentTab + 1) === tabs.filter(t => t).length

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const checkScrollSwitches = (direction, {
    scrollableNode,
    tabBarScrollbase
  }) => {
    if (direction === DIRECTIONS.RIGHT) {
      if (scrollableNode.scrollLeft % tabBarScrollbase === 0) {
        setIsRightSwitchActive(false);
      }
      setIsLeftSwitchActive(true);
    } else {
      if (scrollableNode.scrollLeft === 0) {
        setIsLeftSwitchActive(false);
      }
      setIsRightSwitchActive(true);
    }
  }

  const scroll = (direction) => {
    const TAB_BAR_SCROLL_BASE = 107;
    const tabsContainer = document.querySelector('.MuiTabs-flexContainer');
    const TABS_CONTAINER_WIDTH = tabsContainer.offsetWidth;

    if (direction === DIRECTIONS.RIGHT) {
      const scrollableChild = appBar.current.querySelector('.MuiTabs-scroller');

      if (scrollableChild.scrollLeft + TAB_BAR_SCROLL_BASE === TABS_CONTAINER_WIDTH) {
        scrollableChild.scrollLeft = TABS_CONTAINER_WIDTH;
      } else {
        scrollableChild.scrollLeft += TAB_BAR_SCROLL_BASE;
      }

      checkScrollSwitches(DIRECTIONS.RIGHT, {
        scrollableNode: scrollableChild,
        tabBarScrollbase: TAB_BAR_SCROLL_BASE
      });
    } else if (direction === DIRECTIONS.LEFT) {
      const scrollableChild = appBar.current.querySelector('.MuiTabs-scroller');

      if (scrollableChild.scrollLeft - TAB_BAR_SCROLL_BASE === 0) {
        scrollableChild.scrollLeft = 0;
      } else {
        scrollableChild.scrollLeft -= TAB_BAR_SCROLL_BASE;
      }

      checkScrollSwitches(DIRECTIONS.LEFT, {
        scrollableNode: scrollableChild,
        tabBarScrollbase: TAB_BAR_SCROLL_BASE
      });
    }
  }

  return (
    <>
      {/* Switch */}
      {hasSwitch && (
        <Grid container justifyContent={'flex-end'} style={{
          flex: '0 0 auto'
        }}>
          <Box display={'inline-block'}>
            <SwitchTabCustom isDisabled={!isLeftSwitchActive} onClick={() => scroll(DIRECTIONS.LEFT)}>
              <ArrowBackIosSharpIcon />
            </SwitchTabCustom>
            <SwitchTabCustom isDisabled={!isRightSwitchActive} onClick={() => scroll(DIRECTIONS.RIGHT)}>
              <ArrowForwardIosSharpIcon />
            </SwitchTabCustom>
          </Box>
        </Grid>
      )}

      {/* Tabs */}
      <Box style={{ border: "1px solid " + colors.grey.border, ...props.style }}>
        {/* Tabs */}
        <AppBarCustom position="static" columnsNb={props.columnsNb ?? 5} tabsNb={tabs.length} ref={appBar}>
          <Tabs value={currentTab} onChange={handleChangeTab} aria-label="Tabs">
            {tabs.map((tab, index) => {
              return (
                <Tab
                  textColor="primary"
                  label={tab.labelName ?? tab}
                  wrapped
                  key={`Tab${index}`}
                  style={{
                    color: tab?.error ? colors.red.darker : tab.isDisabled ? colors.grey.disabled : colors.grey.dark,
                    borderLeft:
                      index === 0 ? "none" : "1px solid " + colors.grey.border,
                    borderRight: 'none',
                  }}
                  disabled={tab.isDisabled}
                />
              );
            })}
          </Tabs>
        </AppBarCustom>

        {/* Tab contents */}
        {children ??
          tabs
            .filter((t) => t)
            .map((tab, index) => {
              return (
                <div
                  key={`TabContent${index}`}
                  value={currentTab}
                  hidden={currentTab !== index}
                >
                  {tab.component()}
                </div>
              );
            })}
      </Box>
    </>
  );
};

export default OurTabs;
