import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from '@apollo/client/react/hoc';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import CardCustom from '../../../layouts/Card/CardCustom';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import { Grid, Box } from '@mui/material';
import NoImage from '../../../../assets/images/not-found.png';
import { ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT, ROUTE_HOME } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import EmptyProduct from '../../../../assets/pictos/empty-picto/empty_products.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { GET_MODELES} from '../../../../queries/crm_modeles';
import styled from 'styled-components';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_OFFERS, CRM_OFFERS_MODELES, CREATE, VIEW} from '../../../../js/constants/constant-rights';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px){
        padding: 16px 8px 8px!important;
    }
`;

const LineColor = styled(Box)`
    background: ${colors.blue.darker.hue300};
`;

class ListModelsDevis extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            modeles: [],
        }
    }

    componentDidMount() {
        const getRights = hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.initModeles();
        }
    }

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="picto-produit"
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Gérer les modèles de devis" 
                    subtitle="Gestion de vos modèles de devis (création / modification / suppression)" 
                    handlerAdd={() => this.goTo(ROUTE_CRM_OFFERS_MODELES_ADD)} 
                    textAdd={hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, CREATE) ? "Ajouter un modèle de devis" : null}
                    // handlerImport={() => this.handleToggleDrawer('openForm')} 
                    // textImport="Importer des produits" 
                    searchHandler={this.handleChange} 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    hasBorder="true"
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    // locales={this.props.locales}
                />
                <Grid container direction="column" justifyContent="center" style={{paddingTop: 8}} spacing={0}>
                    <Grid container direction="row" spacing={2} style={{marginTop: 0, marginBottom: 0}}>
                        {
                            this.state.ready ?
                                this.state.modeles.length > 0 ?
                                    this.state.modeles.map((modele, index) => {
                                        return(
                                            <GridCustom item lg={4} md={6} xs={12} key={`Project${index}`}>
                                                <CardCustom style={{width: "100%", height: "100%",  padding: 0}} cardContentStyle={{height: "100%", padding: 0, display: 'flex', flexDirection: 'column'}} hovercard={true}>
                                                    <div style={{maxHeight: 160, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        <img style={{maxHeight: 160, height: '100%'}} src={modele.node.media ? `${process.env.REACT_APP_MEDIAS}/${modele.node.media.filePath}` : NoImage}/>
                                                    </div>
                                                    <LineColor height={8}/>
                                                    <div style={{padding: 16, height: "100%", display: 'flex', flexDirection: "column"}}>
                                                        <Typography variant="h4" colortext={colors.black.regular} style={{display: 'flex', justifyContent: 'space-between'}} component="div">
                                                            <Box fontWeight="bold">
                                                                {modele.node.name}
                                                            </Box>
                                                        </Typography>
                                                        <Typography dangerouslySetInnerHTML={{__html: modele.node.description}} />
                                                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: "flex-end", marginTop: 16, flex: 1}}>
                                                            <Button types="Info" text="Voir le modèle" onClick={() => this.goTo(ROUTE_CRM_OFFERS_MODELES_EDIT.replace(':id', modele.node.name),modele.node.id)}></Button>
                                                        </div>
                                                    </div>
                                                </CardCustom>
                                            </GridCustom>
                                        )
                                    })
                                : <EmptyCard title={hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, CREATE) ? "Vous n'avez pas encore configuré de modèle" :  "Vous n'avez aucun droit de création sur cette page"} subtitle={hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, CREATE) ? "Cliquez sur le bouton ci-dessous pour en ajouter un" : "Faite une demande auprès d'un administrateur"} textButton={hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, CREATE) ? "Ajouter un modèle d'offre" : null} onClick={() => this.goTo(ROUTE_CRM_OFFERS_MODELES_ADD)} picto={EmptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                            : <PageLoader />
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }

    initModeles() {
        this.props.client.query({
            query: GET_MODELES,
            fetchPolicy: "no-cache",
            // variables : { id: this.state.productId }
        }).then(GET_MODELES_DATA_RESULT => {
            this.setState({
                modeles: GET_MODELES_DATA_RESULT.data.models.edges,
            })
            if (GET_MODELES_DATA_RESULT.data.models.edges.length > 0){
                this.setState({
                    isEmpty: false,
                })
            }else{
                this.setState({
                    isEmpty: true
                })
            }
            this.setState({
                ready: true
            })
        }).catch(error => {
            console.log(error)
            this.props.snack(ALERT_ERROR, `Une erreur est survenue lors de la récupération des modèles de devis`);
        });
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { modeleId : id }
        });
    };

}

const mapDispatchToProps = dispatch => {
    return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ListModelsDevis)));
