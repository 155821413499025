
import { FormInputTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getDefaultLink } from '../../helpers';
import { v4 as uuidv4 } from "uuid";

export default (props) => {
    let block = {
        name: "Email de jeu",
        key: "mail_game",
        type: "mail_game",
        inputs: {
            backgroundColor: {
                label: 'Couleur de fond',
                type: FormInputTypes.COLOR,
                value: '#F0F7FA'
            },
            backgroundImage: {
                label: 'Image de fond',
                type: FormInputTypes.IMAGE,
                value: null
            },
            isWin: {
                label: 'Afficher dotation',
                type: FormInputTypes.SWITCH,
                value: false
            },
            isVirtual: {
                label: 'Dotation virtuelle',
                type: FormInputTypes.SWITCH,
                value: false
            },
            isCode: {
                label: 'Dotation code',
                type: FormInputTypes.SWITCH,
                value: false
            },
        },
        blocks: {
            header: getDefaultText("Header", "GRAND JEU CONCOURS !", 16, "#53839D", "#FFF", 'initial', 'center'),
            imageTop: getImageDefault(null, "Image top"),
            title: getDefaultText("Titre", "BRAVO !", 40, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
            subtitle: getDefaultText("Sous-titre", "Votre inscription au tirage au sort a bien été enregistrée !",  24, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
            link: getDefaultLink("Lien", "Lien", "%link%", 20, '#000', '#53839D', null, 0, '#000', 0, false),
            description: getDefaultText("Description", "Rendez-vous le JJ/MM/AAAA pour le résultat final*",  20, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
            imageBottom: getImageDefault(null, "Image bottom #1"),
            linkBottom: getDefaultLink("Lien image bottom #1", "Lien", "#", 20, '#000', '#53839D', null, 0, '#000', 0, false),
            imageBottom2: getImageDefault(null, "Image bottom #2"),
            linkBottom2: getDefaultLink("Lien image bottom #2 ", "Lien", "#", 20, '#000', '#53839D', null, 0, '#000', 0, false),
            footer: {
                id: uuidv4(),
                name: "Paramétrage du footer",
                inputs: {
                    backgroundColor: {
                        label: 'Couleur de fond',
                        type: FormInputTypes.COLOR,
                        value: "#53839D",
                    },
                    backgroundImage: {
                        label: 'Image de fond',
                        type: FormInputTypes.IMAGE,
                        value: null
                    },
                },
            },
            footerText: getDefaultTextarea("Texte du footer", "Jeu sans obligation d’achat. Valable en France métropolitaine (Corse comprise).  X a chargé la société SINFIN (1 avenue Louis François Armand – 10340 Rosières-près-Troyes) de collecter votre adresse mail pour vous permettre de participer au jeu concours à l’occasion de l’activation promotionnelle. L’adresse email que vous avez renseignée dans le formulaire sera utilisée par X pour confirmer votre participation. Ces informations seront supprimées dans un délai de 4 mois à compter de la fin du jeu-concours. Elles seront accessibles aux équipes X et SINFIN. L’utilisation de vos données personnelles est nécessaire à l’exécution du contrat que  vous avez conclu avec X en acceptant le règlement du jeu-concours, consultable ici. Si vous ne souhaitez pas fournir ces informations, vous ne pourrez malheureusement pas participer au jeu-concours.", true, "#FFF", null, 'initial', 'initial', 12),
        }
    };

    if (false) {
        block.blocks = {
            ...block.blocks,

        }
    }

    return block;
};
