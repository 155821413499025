import { withTranslation } from 'react-i18next';
import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';


export default function exportProducts(allLangs, typeExport, t){
    let allLanguages =[
        {
            value: 'all',
            label: 'Toutes les langues',
            disabled: true
        }
    ]
    for (let lang of allLangs){
        allLanguages.push(
            {
                value: lang.node.code,
                label: lang.node.libelle
            }
        )
    }
    // allLanguages.concat(getLanguages)
    var obj = {
        titleForm: typeExport === "products" ? t('products.list.export.title') : t('products.categories.exporter.exporter'),
        subTitleForm: typeExport === "products" ? t('products.list.export.subTitle') : t('products.categories.exporter.completer'),
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations générales'
            },
            children: [
                {
                    optionsInputs: [
                        {
                            type: 'text',
                            label: t('products.categories.exporter.filename'),
                            helper: {
                                text: 'Nom du fichier',
                                link: false,
                            },
                            required: true,
                            stateName: 'exportName',
                            translated: false,
                        },
                        {
                            type: 'select',
                            isContainerized: true,
                            label: typeExport === "products" ? t('products.list.export.productLanguage') : t('products.categories.exporter.langue'),
                            translated: false,
                            helper: {
                                text: typeExport === "products" ? 'Indiquez la langue des produits exportés' : 'Indiquez la langue des catégories exportées',
                                link: false,
                            },
                            required: true,
                            stateName: 'exportLang',
                            value: allLanguages
                        },
                        {
                            type: 'select',
                            isContainerized: true,
                            label: t('products.categories.exporter.exporttype'),
                            translated: false,
                            helper: {
                                text: t('products.categories.exporter.exporttype'),
                                link: false,
                            },
                            required: true,
                            stateName: 'exportType',
                            value: 
                                [
                                    {
                                        value : 'csv',
                                        label : '.csv', 
                                    },
                                    {
                                        value : 'xls',
                                        label : '.xls', 
                                    }
                                ]
                        },
                        {
                            type: 'select',
                            isContainerized: true,
                            label: t('products.categories.exporter.status'),
                            translated: false,
                            helper: {
                                text: 'Sélectionnez le status produit',
                                link: false,
                            },
                            required: true,
                            stateName: 'exportStatus',
                            value: 
                                [
                                    {
                                        value : 'all',
                                        label : 'Tout', 
                                    },
                                    {
                                        value : 'active',
                                        label : 'Actif', 
                                    },
                                    {
                                        value : 'inactive',
                                        label : 'Inactif', 
                                    }
                                ]
                        },
                        {
                            type: 'select',
                            isContainerized: true,
                            label: t('products.categories.exporter.image'),
                            translated: false,
                            helper: {
                                // text: 'Sélectionnez le status produit',
                                link: false,
                            },
                            required: true,
                            stateName: 'exportImage',
                            value: 
                                [
                                    {
                                        value : 'all',
                                        label : 'Tout', 
                                    },
                                    {
                                        value : 'active',
                                        label : 'Oui', 
                                    },
                                    {
                                        value : 'inactive',
                                        label : 'Non', 
                                    }
                                ]
                        },
                    ]
                },
            ]
        }
    }
    return obj;
}