import React, { useEffect, useState, useRef } from "react";
import Div from '../shared/div';
import H2 from '../shared/h2';
import H4 from '../shared/h4';
import P from '../shared/p';
import Button from '../shared/a';
import Img from '../shared/img';
import styled from 'styled-components';

const DivBlocTextMedia = styled(Div)`
    padding: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    &>div{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        &>div{
            padding: 24px;
            dipslay: flex;
            justify-content: center;
            flex-direction: row;
            width: 100%;
            max-width: 50%;
        }
        .blocMedia img{
            display: block;
        }
        &.tier{
            .blocText{
                width: 30%;
            } 
            .blocMedia{
                width: 70%;
                max-width: 70%;
                video{
                    width: 100%;
                }
            }
        }
        &.untier{
            .blocText{
                width: 70%;
                max-width: 70%;
            } 
            .blocMedia{
                width: 30%;
                max-width: 30%;
                video{
                    width: 100%;
                }
            }
        }
    }
    .container-button{
        display: flex;
        justify-content: center;
        flex-direction: row;
        a{
            margin: 20px;
        }
    }
    h2{
        max-width: 100%;
        width: 100%;
        font-family: 'DINOffc-bold', sans-serif;
        margin-bottom: 16px;
        line-height: 1.2;
    }
    h4{
        max-width: 100%;
        width: 100%;
        line-height: 1.2;
        font-family: 'DinRegular', sans-serif;
        margin-bottom: 25px;
    }
    div{
        font-family: 'Univers',sans-serif;
        font-size: 1rem;
        line-height: 1.2;
        a{
            font-family: 'DINOffc-bold', sans-serif;
        }
        &.center{
            max-width: 100%;
            width: 600px;
            p{
                text-align: center;
                width: 100%;
            }
        }
    }
    p{
        max-width: 100%;
        width: 100%;
        font-size: 1.4375rem;
        line-height: 1.2;
        font-family: 'Univers', sans-serif;
    }
    @media screen and (max-width: 1250px){
        h2{
            margin-bottom: 8px;
        }
        h4{
            font-size: 1.875rem!important;
            margin-bottom: 12px;
        }
        .container-button{
            a{
                margin: 10px;
                font-size: 1rem;
                line-height: 20px;
                padding: 8px 16px;
            }
        }
    }
    @media screen and (max-width: 1023px){
        padding: 20px 5%;
    }
    @media screen and (max-width: 1000px){
        &>div{
            display: flex;
            flex-direction: column;
            &>div{
                max-width: calc(100% - 12px);
            }
            &.tier, &.untier{
                .blocText{
                    width: 100%;
                    max-width: calc(100% - 12px);
                } 
                .blocMedia{
                    width: 100%;
                    max-width: calc(100% - 12px);
                }
            }
            .blocText{
                order: ${props => `${props.positionMedia !== 'top' ? 0 : 2}!important`};
            }
        }
    }
    @media screen and (max-width: 800px){
        .container-button{
            width: 100%;
            flex-wrap: wrap;
            a{
                width: 50%;
                text-align: center;
                flex: calc(100% / 2 - 60px);
            }
        }
    }
    @media screen and (max-width: 650px){
        padding: 8px 5%;
        &>div{
            &>div{
                max-width: 100%;
                padding: 8px 0;
            }
            &.tier, &.untier{
                .blocText{
                    width: 100%;
                    max-width: 100%;
                } 
                .blocMedia{
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
    @media screen and (max-width: 500px){
        .container-button{
            a{
                width: 100%;
                flex: 100%;
            }
        }
    }
    &.fullWidth{
        padding: 0;
        .blocMedia{
            padding: 0;
            video{
                width: 100%;
            }
        }
        .blocText{
            padding: 36px 50px;
        }
    }
`;

const DivText = styled(Div)`
    @media screen and (max-width: 1400px){
        padding-left: ${props => `${props.paddingHorizontal ? props.paddingHorizontal / 16 : 0}vw` || 0}!important;
        padding-right: ${props => `${props.paddingHorizontal ? props.paddingHorizontal / 16 : 0}vw` || 0}!important;
        padding-top: ${props => `${props.paddingVertical ? props.paddingVertical / 16 : 0}vw` || 0}!important;
        padding-bottom: ${props => `${props.paddingVertical ? props.paddingVertical / 16 : 0}vw` || 0}!important;
        font-size: ${props => `${props.fontSize ? props.fontSize / 16 : 0}vw`}!important;
        left: -${props => `${props.left && props.paddingHorizontal ? (props.paddingHorizontal / 16) * 2 : 0}`}vw!important;
        width: ${props => props.widthAuto && props.paddingHorizontal ? `calc(100% - ${props.widthMedia === 'tier' ? '100%' : (props.paddingHorizontal / 16) * 2}vw)` : '100%'}!important;
    }
    @media screen and (max-width: 1000px){
        left: 0!important;
        width: ${props => props.paddingHorizontal ? `calc(100% - ${(props.paddingHorizontal / 16) * 2}vw)` : '100%'}!important;
    }
    @media screen and (max-width: 991px){
        font-size: ${props => `${props.fontSize ? (props.fontSize / 8 > 2.3) ?  props.fontSize / 8 : 2.3 : 2.3}vw`}!important;
    }
    @media screen and (max-width: 767px){
        font-size: ${props => `${props.fontSize ? (props.fontSize / 8 > 3) ?  props.fontSize / 8 : 3 : 3}vw`}!important;
    }
    @media screen and (max-width: 450px){
        font-size: ${props => `${props.fontSize ? (props.fontSize / 8 > 4) ?  props.fontSize / 8 : 4 : 4}vw`}!important;
    }
`;

const BlocTextMedia = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        data = null??{},
    } = props;

    const vidRef = useRef(null);

    const handlePlayVideo = () => {
        if(vidRef.current){
            vidRef.current.play();
        }
    }

    useEffect(() => {
        handlePlayVideo();
    }, []);
    useEffect(() => {
        handlePlayVideo();
    }, [props]);

    const VIDEOS = ["video/mpeg","video/webm","video/mp4"];

    let image = data.inputs['backgroundImage'].value;
    let media = data.blocks?.media?.inputs?.value?.value;
    let isVideo = VIDEOS.includes(media?.type) ? true : false;

    return (
        <Div 
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: 'relative',
                maxWidth: `${data.inputs['maxWidth']?.value}px`,
                margin: 'auto',
            }} 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}
            id={data.inputs.identifiant?.value}
        >
            { hover && !preview && props.children }
            <DivBlocTextMedia className={data.inputs.imageFull?.value ? 'fullWidth' : null } positionMedia={data.inputs['positionMedia'].value}>
                <Div className={data.inputs.widthMedia?.value ? data.inputs.widthMedia.value : null} style={{flexDirection: data.inputs['positionMedia']?.value === 'top' || data.inputs['positionMedia']?.value === 'bottom' ? 'column' : null, maxWidth: data.inputs['positionMedia']?.value === 'top' || data.inputs['positionMedia']?.value === 'bottom' ? 1040 : null}}>
                    <Div className="blocText" style={{order: data.inputs['positionMedia'].value === 'right' || data.inputs['positionMedia'].value === 'bottom' ? '0' : '2', maxWidth: data.inputs['positionMedia'].value === 'top' || data.inputs['positionMedia'].value === 'bottom' ? '100%' : null, width: data.inputs['positionMedia'].value === 'top' || data.inputs['positionMedia'].value === 'bottom' ? '100%' : null}}>
                        {
                            data.blocks.title.inputs?.value?.value ? (
                                <H2 
                                    color={data.blocks.title.inputs.color?.value}
                                    fontSize={data.blocks.title.inputs.fontSize?.value}
                                    textTransform={data.blocks.title.inputs.textTransform?.value}
                                    style={{
                                        textAlign: data.blocks.title.inputs.textAlign?.value,
                                        marginLeft: data.blocks.title.inputs.textAlign?.value === 'center' ? 'auto' : null,
                                        marginRight: data.blocks.title.inputs.textAlign?.value === 'center' ? 'auto' : null
                                    }}
                                >
                                    {data.blocks.title.inputs.value?.value}
                                </H2>
                            ) : null
                        }
                        {data.blocks.subtitle.inputs?.value?.value ? (
                            <H4 
                                color={data.blocks.subtitle.inputs.color?.value}
                                fontSize={data.blocks.subtitle.inputs.fontSize?.value}
                                textTransform={data.blocks.subtitle.inputs.textTransform?.value ? data.blocks.subtitle.inputs.textTransform?.value : null}
                                style={{
                                    textAlign: data.blocks.subtitle.inputs?.textAlign?.value,
                                    marginLeft: data.blocks.subtitle.inputs?.textAlign?.value === 'center' ? 'auto' : null, 
                                    marginRight: data.blocks.subtitle.inputs?.textAlign?.value === 'center' ? 'auto' : null
                                }}
                            >
                                {data.blocks.subtitle.inputs?.value?.value}
                            </H4>
                        ) : null}
                        {
                            data.blocks.text?.inputs?.value?.value && data.blocks.text?.inputs?.value?.value !== "<p><br></p>" ? (
                                <DivText
                                    className={`containerText ${data.blocks.text.inputs?.textAlign?.value ? data.blocks.text.inputs?.textAlign?.value : ''}`}
                                    color={data.blocks.text.inputs?.color?.value}
                                    paddingVertical={data.blocks.text?.inputs?.paddingVertical?.value}
                                    paddingHorizontal={data.blocks.text?.inputs?.paddingHorizontal?.value}
                                    fontSize={data.blocks.text.inputs.fontSize?.value}
                                    left={data.inputs['positionMedia'].value === 'left' ? true : false}
                                    widthAuto={data.blocks.text?.inputs?.widthAuto?.value}
                                    widthMedia={data.inputs.widthMedia?.value}
                                    style={{
                                        textAlign: data.blocks.text.inputs?.textAlign?.value ? data.blocks.text.inputs?.textAlign?.value : null, 
                                        marginLeft: data.blocks.text.inputs?.textAlign?.value === 'center' ? 'auto' : null, 
                                        marginRight: data.blocks.text.inputs?.textAlign?.value === 'center' ? 'auto' : null, 
                                        fontSize: data.blocks.text.inputs.fontSize?.value ? data.blocks.text.inputs.fontSize?.value : null,    
                                        color: data.blocks.text.inputs.color?.value ? data.blocks.text.inputs.color?.value : null,    
                                        backgroundColor: data.blocks.text.inputs.backgroundColor?.value ? data.blocks.text.inputs.backgroundColor?.value : null,
                                        textTransform: data.blocks.text.inputs.textTransform?.value ? data.blocks.text.inputs.textTransform?.value : null,
                                        padding: `${data.blocks.text?.inputs?.paddingVertical?.value ? data.blocks.text?.inputs.paddingVertical?.value : 0 }px ${data.blocks.text?.inputs?.paddingHorizontal?.value ? data.blocks.text?.inputs.paddingHorizontal?.value : 0 }px`,
                                        width: `${data.blocks.text?.inputs?.widthAuto?.value ? `calc(100% - ${data.inputs.widthMedia?.value === 'tier' ? 0 : data.blocks.text?.inputs?.paddingHorizontal?.value ? data.blocks.text?.inputs.paddingHorizontal?.value * 2 : 0}px)` : '100%'}`,
                                        position: 'relative',
                                        left: data.inputs['positionMedia'].value === 'left' && !data.blocks.text?.inputs?.widthAuto?.value ? `-${data.blocks.text?.inputs?.paddingHorizontal?.value ? data.blocks.text?.inputs?.paddingHorizontal?.value * 2 : 0}px` : null,
                                    }}
                                    dangerouslySetInnerHTML={{__html: data.blocks.text.inputs.value?.value}}
                                />
                            ) : null
                        }
                        {
                            data.inputs.positionButton?.value === 'texte' ? (
                                <Div className="container-button" style={{justifyContent: data.inputs['centerButton']?.value ? 'center' : 'flex-start'}}>
                                    {
                                        data.blocks.link?.config?.map((link, index) => (
                                            <Button 
                                                key={index}
                                                href={link.inputs.link?.value}
                                                aria-label={`${data.blocks.title.inputs.value?.value} ${link.inputs.value?.value}`} title={`${data.blocks.title.inputs.value?.value} ${link.inputs.value?.value}`}
                                                backgroundColor={link.inputs.backgroundColor?.value} 
                                                color={link.inputs.color?.value}
                                                borderWidth={link.inputs.border?.inputs.borderWidth?.value}
                                                borderColor={link.inputs.border?.inputs.borderColor?.value}
                                                borderRadius={link.inputs.border?.inputs.borderRadius?.value}
                                                className="little"
                                                style={{marginLeft: !data.inputs['centerButton']?.value ? 0 : null}}
                                            >
                                                {link.inputs.value?.value}
                                            </Button>
                                        ))
                                    }
                                </Div>
                            ) : null
                        }
                    </Div>
                    {
                        data.inputs['showMedia']?.value ? (
                            <Div className="blocMedia" style={{order: '1', maxWidth: data.inputs['positionMedia']?.value === 'top' || data.inputs['positionMedia']?.value === 'bottom' ? '100%' : null, width: data.inputs['positionMedia']?.value === 'top' || data.inputs['positionMedia']?.value === 'bottom' ? '100%' : null}}>
                                {
                                    isVideo ? (
                                        <video autoPlay={true} muted={true} loop={true} ref={vidRef}>
                                            <source src={`${process.env.REACT_APP_MEDIAS}/${media?.filePath}`} type="video/mp4"/>
                                        </video>
                                    ) : (
                                        <Img 
                                            className="w100"  
                                            style={{
                                                height:data.blocks.media.inputs.height?.value? `${data.blocks.media.inputs.height?.value}px`:"auto"
                                            }}                                           
                                            src={
                                                typeof media === 'string' 
                                                    ? media 
                                                    : media?.filePath 
                                                        ? `${process.env.REACT_APP_MEDIAS}/${media?.filePath}` 
                                                        : null
                                            } 
                                            alt={data.blocks.media.inputs.alt?.value ?? "Illustration"}
                                        />
                                    )
                                }
                                {
                                    data.inputs.positionButton?.value === 'image' ? (
                                        <Div className="container-button" style={{justifyContent: data.inputs['centerButton']?.value ? 'center' : 'flex-start'}}>
                                            {
                                                data.blocks.link?.config?.map((link, index) => (
                                                    <Button 
                                                        key={index}
                                                        href={link.inputs.link?.value}
                                                        aria-label={`${data.blocks.title.inputs.value?.value} ${link.inputs.value?.value}`} title={`${data.blocks.title.inputs.value?.value} ${link.inputs.value?.value}`}
                                                        backgroundColor={link.inputs.backgroundColor?.value} 
                                                        color={link.inputs.color?.value}
                                                        borderWidth={link.inputs.border?.inputs.borderWidth?.value}
                                                        borderColor={link.inputs.border?.inputs.borderColor?.value}
                                                        borderRadius={link.inputs.border?.inputs.borderRadius?.value}
                                                        className="little"
                                                        style={{marginLeft: !data.inputs['centerButton']?.value ? 0 : null}}
                                                    >
                                                        {link.inputs.value?.value}
                                                    </Button>
                                                ))
                                            }
                                        </Div>
                                    ) : null
                                }
                            </Div>
                        ) : null
                    }
                </Div>
            </DivBlocTextMedia>
        </Div>
    );
};

export default BlocTextMedia;