import CardUsers from '../../../../layouts/Card/cardContent/CardUsers';

export const listSettings = {
    grid: {
        components: CardUsers,
        columns: {
            xs: 1,
            sm: 2,
            laptop: 3,
        },
    },
};

export const listMappers = [
    {
        view: 'card',
        mapper: "usersCard"
    },
];

export const perPageOptions = {
    'card': [12, 24],
};

export const viewOptions = {
    current : 'card',
    settings: [
        {
            label: "Card",
            value:"card"
        }
    ]
}