import { TABS } from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function formContentCategoriesAdd(categories, parentId, inputAttributes, currentLang, errors, seeErrors,handleMediaPicker, isCatalog = false, mediaCategories, selectedMediaCategories) {
    let typeInput = '';

    let attributeSetup = attribute => {
        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = 'textarea';
                break;
            case 'number': // create an input
            case 'decimal': // create an input
            case 'text':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case 'image':
                typeInput = 'mediaPicker';
                break;
            default: typeInput = null;
        }

        return ({
            type: typeInput,
            label: langSelected?.node.value ?? defaultLang.node.value,
            translated: true,
            helper: {
                text: '',
                link: false,
            },
            isSystem: false,
            currentLang,
            required: attribute.node.isRequired,
            stateName: attribute.node.identifier,
            handleMediaPicker : typeInput === 'mediaPicker'? handleMediaPicker :null,

            value: attribute.node.attributeOptions.edges.map((values) => {
                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );

                return ({
                    value: values.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault.node.value
                });
            })
        });
    };

    let generalInputs = [
        {
            type: 'text',
            label: 'Identifiant',
            translated: false,
            helper: {
                text: 'Identifiant de la catégorie',
                link: false,
            },
            required: true,
            stateName: 'identifier',
        },
        {
            type: 'selectTree',
            label: 'Parent',
            translated: false,
            helper: {
                text: 'Indiquez le parent',
                link: false,
            },
            required: true,
            stateName: 'parent',
            data: (() => {
                const copyofData = JSON.parse(JSON.stringify(categories));
                let data = copyofData.filter(isCatalog ? e => e.parent === null : e => e.parent === null && e.libelle === 'Root');

                let populateChildren = (cats, parent) => {
                    let nameAttribute = parent.attributes?.find(e => e.identifier === 'category_name') ?? null;

                    let currentTranslation = nameAttribute?.locales.find(
                        translation => translation.code === currentLang
                    ) ?? null;

                    parent.value  = parent.id;
                    parent.label  = isCatalog && parent.parent === null ? '/' : parent.libelle === 'Root' ? '/' : currentTranslation?.value ?? nameAttribute?.locales?.[0].value ?? parent.libelle;
                    parent.children         = cats.filter(e => e.parent !== null && e.parent.id === parent.id);
                    parent.isDefaultValue   = parent.id === parentId;
                    parent.expanded         = true;

                    for (let child of parent.children)
                        populateChildren(cats, child);
                };

                for (let parent of data)
                    populateChildren(copyofData, parent);

                return data;
            })()
        },
        {
            type: 'selectTree',
            label: 'Catégories médias',
            translated: false,
            required: false,
            helper: {
                text: 'Catégories médias',
                link: false,
            },
            multiselect: true,
            stateName: 'selectedMediaCategories',
            data: (() => {
                let data = [];
                if (mediaCategories.length > 0){}
                    data = mediaCategories.filter(e => e.node.parent === null && e.node.libelle === 'Root').map(e => e.node);

                let populateChildren = (cats, parent) => {
                    let catSelected = selectedMediaCategories?.find(e => e.id === parent.id);
                    parent.value  = parent.id;
                    parent.label  = parent.libelle === 'Root' ? '/' : parent.libelle;
                    parent.disabled = false;
                    parent.children         = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.id).map(e => e.node);
                    parent.expanded         = true;
                    parent.checked   = catSelected ? true : false;

                    for (let child of parent.children)
                        populateChildren(cats, child);
                };

                for (let parent of data)
                    populateChildren(mediaCategories, parent);

                return data;
            })()
        }
    ];

    let attributesInputs = inputAttributes.edges.map(attributeSetup);

    let hasErrors = (inputs) => {
        for (let input of inputs) {
            if (errors[input.stateName])
                return true;
        }

        return false;
    };

    var obj = {
        titleForm: 'Ajouter une catégorie',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre catégorie',
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: TABS,
            seeErrors,
            children: [
                {
                    labelName: 'Général',
                    error: hasErrors(generalInputs),
                    optionsInputs: generalInputs
                },
                {
                    labelName: 'Attributs',
                    error: hasErrors(attributesInputs),
                    isOptionnal: false,
                    optionsInputs: attributesInputs
                }
            ]
        }
    };

    return obj;
}