import { GridCustom, ChipInputCustom } from "../../styles/inputStyled";
import { Grid, Typography } from '@mui/material';

const ProductExplorer = ({ allProps: props, seeError, setSeeError, errorMessage, checkError, classes }) => {
    return (
        <GridCustom container direction="row" alignItems="center">
            <Grid item style={{ flex: 1 }}>
                <ChipInputCustom
                    color="secondary"
                    variant="outlined"
                    error={seeError && props.value.length === 0 ? true : false}
                    value={props.value || []}
                    disabled={props.input.disabled}
                    fullWidth
                    fullWidthInput
                    // inputValue={row.typingChips}
                    onDelete={(chip, index) => {
                        props.value.splice(index, 1)
                        props.stateCallback(props.value)
                        setSeeError(true);
                        checkError(props.value);
                    }}
                    onAdd={(chip) => {
                        let getSplit = chip.split("|")
                        props.value.push(...getSplit);
                        props.stateCallback(props.value)
                        setSeeError(true);
                        checkError(props.value);
                    }}
                    // onUpdateInput={(e) => {
                    //     row.typingChips = e.target.value;
                    //     if (e.target.value) {
                    //         row.showButton = true;
                    //     } else {
                    //         row.showButton = false;
                    //     }
                    //     props.stateCallback(props.value)
                    // }}
                    classes={{ inputRoot: classes.inputRootExplorer }}
                />
            </Grid>
            {/* <Grid item>
                        <ButtonCustom border={`1px solid ${colors.grey.border}`} bgcolor={"#EDEEEE"} bgcolorhover={"#EDEEEE"} color={colors.black.regular} text={'Parcourir'} onClick={() => console.log('HERE')} className="button" style={{margin:0,padding: "12px 32px"}}/>
                    </Grid> */}
            {
                !seeError ? null :
                    <Grid container>
                        <span className="error-label">{errorMessage}</span>
                    </Grid>
            }
            {
                <Grid container style={{ marginTop: 8 }}>
                    <Typography>Utilisez la touche Entrée pour valider les valeurs, vous pouvez utiliser "|" pour séparer si vous voulez en insérer plusieurs d'une seule fois. (Exemple: 123|1234|12345)</Typography>
                </Grid>
            }
        </GridCustom>
    )
}

export default ProductExplorer;