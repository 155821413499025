import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import {Tooltip} from '@mui/material';
import { withApollo } from '@apollo/client/react/hoc';

import TopPanel from '../../../layouts/TopPanel/TopPanel';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import companyAdd from './config/companyAdd.config';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import colors from '../../../../config/theme/colors';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importAPI from '../../../../assets/pictos/icon-import-api.svg';
import importFlux from '../../../../assets/pictos/icon-import-flux.svg';
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';
import importCompaniesConfig from './config/importCompanies.config'
import importCompaniesTypesConfig from './config/importCompaniesTypes.config'
import { makeStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';

import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_CONTACT, CRM_CONTACT_SOCIETIES, VIEW, CREATE, IMPORT} from '../../../../js/constants/constant-rights';

import {prepareAttributeValues,saveElement,updateElement,getElements} from '../../../../js/utils/functions';
import Listing from '../../../layouts/Listing/Listing';
import * as listingHelper from '../../../../js/utils/listing';
import {listSettings,listMappers,perPageOptions} from './config/companyListing.config';
import * as formHelper from '../../../../js/helpers/form'
import {makeUnique,searchItem} from '../../../../js/helpers/search'



const styles = theme => ({
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.darker.hue300,
    },
    tooltip: {
        backgroundColor: colors.blue.darker.hue300,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}


class ContactCompanies extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openForm : false,
            openFormAdd : false,
            openFormCategories : false,
            catalogFormData : {},
            filteredCatalogs : [],
            filtered : [],
            allCatalogs : [],
            catalogId : '',
            isChildren:false,
            companyChildrens:[],
            companyAddForm:false,
            companyAddChildrenForm:false,
            companyParent:null,
            companyCustomers:[],
            companyChildrenDraft:{
                companyChildrens:[],
                companyCustomers:[]
            },
            editForm: false,
            cat_id: '',
            parent: '',
            libelle:'',
            currentLang: this.props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            ready: false,
            expanded: {},
            noResult: false,
            searchValue: '',
            arrayCatFilter: [],
            formSettings:{},
            nbperpageCustomers: 6,
            customersToAdd:[],
            openImportForm : false,
            openImportTypesForm : false,

        };
        this.typingTimer = null;
        this.typeTesting = 'catalog';
        this.typingSearchTimer = null;
    }

    handleLang = (event) => {
        let companyChildrenDraft = this.state.companyChildrenDraft;
        companyChildrenDraft['currentLang'] = event.target.value;
        this.setState({ 
            currentLang: event.target.value,
            companyChildrenDraft : companyChildrenDraft??{companyChildrens:[],companyCustomers:[]}
        }, () => {
            eventService.fire();
        });
    };

    handleSearch = async(e,value) => {
        let idList = await searchItem(e,'customer','/api/customers/',{attributes:null},{delay:100})
        this.setState({
            formSettings : {
                ...this.state.formSettings,
                activeSearchCustomers : idList,
            }
        })
    }

    handleChangeCompanies = async(e)=>{
        let idList = await searchItem(e,'company','/api/companies/',{attributes:null},{delay:100})
        this.setState({
            activeSearchCompanies : idList,
        })
    }

    handleToggleDrawer = (stateDrawer,reset=false) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer]
        });

        if(reset){
            this.resetState()
        }
    };
    

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors:errors??{} });
    };

    handleNextStep = () =>{
        let result = formHelper.handleStep('next',this.state.errors);
        this.setState({
            seeErrors : !result??false
        })
        return result;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }


    handleCompanyChildren = (action='add',index=null)=>{
        
        this.setState({
            isChildren:true,
            companyChildrenAction : action
        },()=>{
            switch(action){
                case 'add' :
                    this.handleToggleDrawer('companyAddChildrenForm');
                    break;
                case 'edit':
                    this.setState({
                        companyChildrenDraft : this.state.companyChildrens[index]??{companyChildrens:[],companyCustomers:[]},
                        childrenToEdit : index,
                    })
                    this.handleToggleDrawer('companyAddChildrenForm');
                    break;

    
                case 'delete':
                    this.handlerChildrenMutation('delete');
                    this.setState({
                        childrenToEdit : index,
                    })
                    break;
                default:
                    return null;
            }
        })
    }

    handleMediaPicker=(selected,stateName,translated)=>{
        this.handleInput(stateName,selected,null,translated);  
    }

    handleInput = (stateName,evt,custom,translated) =>{
        let isChildren = this.state.isChildren;
        let value;
        if(isChildren){
            value = formHelper.setValue(this.state,stateName, evt, custom, translated,{parent:'companyChildrenDraft'});
        } else {
            value = formHelper.setValue(this.state,stateName, evt, custom, translated);
        }
        this.setState({...value})
    }

    handlerChildrenMutation = () =>{
        let action =this.state.companyChildrenAction;
        const companyChildrens=[...this.state.companyChildrens];
        let children = {...this.state.companyChildrenDraft};
        
        switch(action){
            case 'add':
                companyChildrens.push(children);
                this.handleToggleDrawer('companyAddChildrenForm',true);
                break;

            case 'edit':
                companyChildrens[this.state.childrenToEdit]=children;
                this.handleToggleDrawer('companyAddChildrenForm',true);
                
                break;

            case 'delete':
                companyChildrens.splice(this.state.childrenToEdit,1);
                this.resetState();
                break;
            default:
                return null;
        }
       
        this.setState({
            companyChildrens : companyChildrens??[]
        },()=>{
            this.resetCompanyDraft();
        })
    }

    resetCompanyDraft(){
        let companyChildrenDraft = {...this.state.companyChildrenDraft};
        companyChildrenDraft.companyChildrens = [];
        companyChildrenDraft.companyCustomers = [];
        companyChildrenDraft['companyIdentifier']=null;

        for (let locale of this.props.locales){
            companyChildrenDraft[locale.node.code]={};
        }
        this.setState({ companyChildrenDraft:companyChildrenDraft??{companyChildrens:[],companyCustomers:[]}, })
    }

    resetState = () =>{
        this.setState({
            editChildren:false,
            childrenToEdit:null,
            isChildren:false
        })
    }

    setUpAttributes = async()=>{
        let attributes = await prepareAttributeValues('company');
        let draftAttributes = await prepareAttributeValues('company');
        let companyChildrenDraft = {
            ...this.state.companyChildrenDraft,
            ...draftAttributes
        }
        companyChildrenDraft['currentLang'] = this.state.currentLang;
        
        this.setState({
            ...attributes,
            companyChildrenDraft:companyChildrenDraft,
            isReady : true,
        });
    }

    handlerCompanyMutation= async(action='add',allStates=this.state,parent=null)=>{
        this.props.startLoading();
        switch(action){
            case 'add':
                
                let variables={
                    libelle : this.state.companyIdentifier.replaceAll(' ','_').toLowerCase(),
                    parent : parent,
                }

                let newCompany = await saveElement('company',variables,{setAttributes:true,enableLoad:false,states:this.state})

                if(allStates.companyCustomers.length >0){
                    for(let customer of allStates.companyCustomers){
                        let companies   = customer.node.companies.edges.map((company)=>company.node.id);
                        let id          = customer.node.id;

                        companies.push(newCompany.id);

                        await updateElement(this.state,'customer',{id : id,companies : companies},null,{enableLoad:false})
                    }
                }
                if(allStates.companyChildrens.length > 0)
                    for(let company of allStates.companyChildrens)
                        this.handlerCompanyMutation('add',company,newCompany.id);
                
                
                this.props.snack(ALERT_SUCCESS, 'Sociétée ajouté avec succès');
                this.props.stopLoading();
                this.handleToggleDrawer('companyAddForm');
                this.reloadCompanies();
                

                break;
            default:
                return null;
        }
    }
    

    handleCustomersCategory = async(category)=>{
        let formSettings = this.state.formSettings;
        formSettings.status = category;
        this.setState({
            formSettings:formSettings??null,
            currentStatus: category,
        })
    }

    handleSelect = (checked,contact)=>{
        let companyChildrenDraft    = {...this.state.companyChildrenDraft}; 
        let companyCustomers        =  [...this.state.companyCustomers];
        let isChildren              = this.state.isChildren;
        let index;

        if(checked){
            if (isChildren){
                companyChildrenDraft['companyCustomers'].push(contact)                
            }
            else{
                companyCustomers.push(contact)                
            }
        }
        else{
            if(isChildren){
                index =companyChildrenDraft['companyCustomers'].indexOf(contact);
                companyChildrenDraft['companyCustomers'].splice(index,1);
            }
            else{
                index =companyCustomers.indexOf(contact);
                companyCustomers.splice(index,1);
            }
        }

        
        this.setState({
            companyCustomers : companyCustomers??[],
            companyChildrenDraft : companyChildrenDraft
        })
    }

    handleFormImport = (type, title) => {
        this.setState({
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            media: null,
            headers: null,
            importFile: null,
            importSep: ';',
            importValues: {},
            importLang: this.props.locales[0].node.id??null
        });

        this.handleToggleDrawer('openImportForm');
    };

    handlerImportMutation= async () => {
        this.props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.media?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'company').node.id,
            "locale": this.state.importLang,
            "delimiter":this.state.importSep
        };

        try {
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-import-crms`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès, son intégration dans Sinfin DXP sera exécutée lors du passage du CRON (Tâche automatique).`,6000);
            this.setUpAttributes(); 
            this.handleToggleDrawer('openImportTypesForm');
            this.handleToggleDrawer('openImportForm');
            this.props.stopLoading();
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'import a échoué !`);
            this.props.stopLoading();
        }
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    initForm=()=>{
        let formSettings = this.state.formSettings;
        formSettings.handleCategory = this.handleCustomersCategory;
        formSettings.handleSearch = this.handleSearch;
        formSettings.handleSelect= this.handleSelect;
        this.setState({
            formSettings : formSettings??null
        })
        this.handleToggleDrawer('companyAddForm');
    }

    getCompaniesCallback = (companies) =>{
        this.setState({reloadCompanies:false})
    }
    
    reloadCompanies = () =>{
        this.setState({ reloadCompanies : true})
    }

    componentDidMount() {
        const getRights = hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, VIEW);
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            
            this.setUpAttributes();
            if(this.props.location.state?.hasMessage){
                this.props.snack(ALERT_SUCCESS,this.props.location.state.message)
            }
        }
    }

    render() {
        let companiesByPage = [];
        this.state.viewMode === 'list' ? companiesByPage = [10,20,50] : companiesByPage = [6,18,30];
        return (
            <div style={{width: this.state.openForm ? '100%' : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <TopPanel 
                    icomoon="icon-business" 
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Sociétés" 
                    subtitle="Gestion de vos sociétés (création / modification / suppression)" 
                    handlerAdd={()=>this.initForm()} 
                    textAdd={hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, CREATE) ? "Ajouter une société" : null}
                    searchHandler={
                        this.handleChangeCompanies
                    } 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    buttonAvailable={!this.state.companyAddForm}
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    // locales={this.props.locales}
                    handlerImport={() => this.handleToggleDrawer('openImportTypesForm')} 
                    textImport={hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, IMPORT) ? "Importer des sociétés" : null}
                    hasBorder={true}
                />
                <TraductionSelect 
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                />
                
                <Listing
                    label = 'sociétés'
                    settings = {listSettings}
                    cardProps = {{
                        openForm : this.state.openForm,
                        currentLang : this.state.currentLang,
                        textButton:'Voir la société',
                        windowWidth : this.props.windowWidth,
                        isList : true,
                    }}
                    perPageOptions = {perPageOptions}
                    mappers = {listMappers}
                    currentLang = {this.state.currentLang}
                    identifier = 'companies'
                    queryVariables={{
                        parent : false,
                        id_list : this.state.activeSearchCompanies
                    }}
                    viewsOptions = {{
                        current : 'card',
                        settings : ['card','table'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                    }}
                    reload={this.state.reloadCompanies}
                    listingCallback = {this.getCompaniesCallback}
                />
            
                {this.state.isReady ? (
                    <div>
                            <LayoutBuilder
                                icomoon="icon-business"
                                opened={this.state.companyAddForm} 
                                forClose={()=>this.handleToggleDrawer('companyAddForm')} 
                                handlerSetup={()=>{}} 
                                dataLayout={companyAdd(this.state.currentLang,this.state.customAttributes,'add',this.handleMediaPicker,this.handleCompanyChildren,this.state.formSettings)} 
                                drawerWidth={this.props.drawerWidth}     
                                allState={this.state}    
                                stateCallback={this.handleInput} 
                                errorCallback={this.handleFormError}    
                                stepperButtonAction={[
                                    this.handleNextStep,
                                    this.handleNextStep,
                                    this.handleNextStep,
                                ]}
                                handlerMutation={this.handlerCompanyMutation}
                                currentLang={this.state.currentLang}
                                handleLang={this.handleLang}
                            />

                            <LayoutBuilder
                                icomoon="icon-business"
                                colorIcomoon={colors.blue.darker.hue300}
                                opened={this.state.companyAddChildrenForm} 
                                forClose={()=>this.handleToggleDrawer('companyAddChildrenForm',true)} 
                                handlerSetup={()=>{}} 
                                dataLayout={companyAdd(this.state.currentLang,this.state.customAttributes,'addChildren',this.handleMediaPicker,null,this.state.formSettings)} 
                                drawerWidth={this.props.drawerWidth}     
                                allState={this.state.companyChildrenDraft}    
                                stateCallback={this.handleInput} 
                                stepperButtonAction={[
                                    this.handleNextStep,
                                    this.handleNextStep
                                ]}
                                handlerMutation={this.handlerChildrenMutation}
                                currentLang={this.state.currentLang}
                                handleLang={this.handleLang}
                            />

                            <LayoutBuilder 
                                opened={this.state.openImportTypesForm} 
                                forClose={() => this.handleToggleDrawer('openImportTypesForm', true)} 
                                dataLayout={importCompaniesTypesConfig} 
                                drawerWidth={this.props.drawerWidth} 
                                dataCard={[
                                    {
                                        'libelle': 'Importer via un',
                                        'bicoloreText': 'flux',
                                        'colorhover': '#0273A5',
                                        'picto': importFlux,
                                        'disabled': true,
                                        'textButton': 'Importer',
                                        'description': 'Votre import contacts facilité en renseignant simplement votre flux', 
                                        'catalogDescription' : 'Veuillez compléter les champs ci-dessous',
                                        'onClick': () => this.handleFormImport(null, 'flux')
                                    },
                                    {
                                        'libelle': 'Importer via une',
                                        'bicoloreText': 'API',
                                        'colorhover': '#0273A5',
                                        'picto': importAPI,
                                        'disabled': true,
                                        'textButton': 'Importer',
                                        'description': 'Votre import contacts facilité en renseignant simplement votre API', 
                                        'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                                        'onClick': () => this.handleFormImport(null, 'api')
                                    },
                                    {
                                        'libelle': 'Importer un',
                                        'bicoloreText': 'fichier',
                                        'colorhover': '#0273A5',
                                        'picto': importFichier,
                                        'disabled': false,
                                        'textButton': 'Importer',
                                        'description': 'Votre import contacts facilité en important simplement votre fichier', 
                                        'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                                        'onClick': () => this.handleFormImport(null, 'fichier')
                                    }
                                ]}
                            />

                            <LayoutBuilder 
                                icomoon={'ico-import-fichier'}
                                opened={this.state.openImportForm} 
                                forClose={() => this.handleToggleDrawer('openImportForm', true)}  
                                dataLayout={importCompaniesConfig} 
                                drawerWidth={this.state.drawerWidth}
                                handleCancel={this.handleCancel}
                                handlerMutation={this.handlerImportMutation} 
                                allState={this.state} 
                                stateCallback={this.stateCallback}
                                stepperButtonDisabled={[() => this.state.headers === null, null]}
                                stepperButtonAction={[null, null]}
                                backStepperButtonAction={[null, null, null]}
                            />  
                        </div>
                    )
                :null}

            </div>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => a.node ? ({...a, node: {...a.node}}) : ({...a})); 

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContactCompanies))));