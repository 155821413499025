import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary,AccordionDetails as MuiAccordionDetails,InputBase,InputLabel,Select,MenuItem,Input,FormControl,Grid,OutlinedInput,InputAdornment} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import { v4 as uuidv4 } from "uuid";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {withStyles} from '@mui/styles';
import { alpha } from '@mui/material/styles';
import './OfferManager.scss';
import { Calculator } from '../../../shareable/tools';

// Styles 

const Accordion = withStyles({
    root: {
        padding: '0 !important',
        margin: '0 0 16px 0 !important',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#333333',
        minHeight: 56,
        borderRadius: 4,
        '&$expanded': {
            minHeight: 56,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionSummaryPink = withStyles({
    root: {
        backgroundColor: '#CF3657',
    },
    expanded: {},
})(AccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        flexDirection: 'column'
    },
}))(MuiAccordionDetails);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: props => props.mini ? '4px 4px' : '8px 8px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

const BootstrapReadonlyInput = withStyles((theme) => ({
    input: {
        backgroundColor: '#ccc',
        border: 'none',
        '&:focus': {
            boxShadow: 'none'
        },
    }
}))(BootstrapInput);

const PhaseInput = withStyles((theme) => ({
    input: {
        color: '#fff',
        borderColor: '#fff',
        '&:focus': {
            borderColor: '#fff',
        },
    },
}))(Input);

const OutlinedInputCustom = withStyles((theme) => ({
    root: {
        width: '100%'
    },
    input: {
        padding: '10px 8px',
        width: '100%'
    },
}))(OutlinedInput);

const FullWidthSelect = withStyles((theme) => ({
    root: {
        width: '100%'
    },
}))(Select);

// Sortable

const DragHandle = sortableHandle(() => {
    return <MenuIcon style={{ color: '#000', marginRight: 10, cursor: 'pointer' }} />;
});

const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
});

const SortableSubtask = sortableElement(({ subtask, onChange, listTaxes, poles }) => (
    <div className="subtask">
        <div className="subtask-name">
            <DragHandle />

            <Input value={subtask.name} onChange={(e) => {
                subtask.name = e.target.value;
                subtask.updated = true;
                onChange();
            }} />

            <Input value={subtask.ref} style={{ marginLeft: 10 }} onChange={(e) => {
                subtask.ref = e.target.value;
                subtask.updated = true;
                onChange();
            }} />

            <DeleteIcon style={{ color: 'red', marginLeft: 10 }} className="tache-delete" onClick={() => {
                subtask.deleted = true;
                onChange();
            }} />
        </div>

        <OfferManagerTaskLine tache={subtask} mini={true} onChange={onChange} listTaxes={listTaxes} poles={poles} />
    </div>
));

// Components

const OfferManagerTaskLine = props =>{
    const { tache, onChange, mini, listTaxes, poles } = props;

    const _calculate = (task) => {
        if (Number.isNaN(task.qty))
            task.qty = 0;

        if (Number.isNaN(task.price))
            task.price = 0;

        if (Number.isNaN(task.remise))
            task.remise = 0;

        let HT = task.qty * task.price;
        let total = HT;

        if (task.typeRemise === '€') {
            total = total - task.remise;
        } else if (task.typeRemise === '%') {
            const DISCOUNT_coef = 1 - task.remise / 100;
            total = total * DISCOUNT_coef;
        }

        return total;
    };
    return (
        <div className="tache-line">
            <FormControl className="input">
                <InputLabel shrink htmlFor={`${tache.id}-qty`}>
                    QTÉ
                </InputLabel>
                <BootstrapInput id={`${tache.id}-qty`} style={{ width: 50, padding: 0 }} mini={mini} value={tache.qty} onChange={(e) => {
                    tache.qty = e.target.value;
                    tache.total = _calculate(tache);
                    tache.updated = true;
                    onChange();
                }} />
            </FormControl>

            <FormControl className="input">
                <InputLabel shrink htmlFor={`${tache.id}-price`}>
                    PU HT
                </InputLabel>
                <BootstrapInput id={`${tache.id}-price`} style={{ width: 60 }} mini={mini} value={tache.price} onChange={(e) => {
                    tache.price = e.target.value;
                    tache.total = _calculate(tache);
                    tache.updated = true;
                    onChange();
                }} />
            </FormControl>

            <FormControl className="input">
                <InputLabel id={`${tache.id}-tva`}>TVA</InputLabel>
                <Select
                    labelId={`${tache.id}-tva`}
                    value={tache.tva}
                    onChange={(e) => {
                        tache.tva = e.target.value;
                        tache.total = _calculate(tache);
                        tache.updated = true;
                        onChange();
                    }}
                    input={<BootstrapInput mini={mini} />}
                >
                    {listTaxes.map((tax, i) => <MenuItem value={tax.node.id} key={i}>{tax.node.name}</MenuItem>)}
                </Select>
            </FormControl>

            <FormControl className="input small-margin">
                <InputLabel shrink htmlFor={`${tache.id}-remise`}>
                    REMISE
                </InputLabel>
                <BootstrapInput id={`${tache.id}-remise`} style={{ width: 60 }} mini={mini} value={tache.remise} onChange={(e) => {
                    tache.remise = e.target.value;
                    tache.total = _calculate(tache);
                    tache.updated = true;
                    onChange();
                }} />
            </FormControl>

            <FormControl className="input">
                <InputLabel htmlFor={`${tache.id}-type-remise`}></InputLabel>
                <Select
                    labelId={`${tache.id}-type-remise`}
                    value={tache.typeRemise}
                    onChange={(e) => {
                        tache.typeRemise = e.target.value;
                        tache.total = _calculate(tache);
                        tache.updated = true;
                        onChange();
                    }}
                    input={<BootstrapInput mini={mini} />}
                >
                    <MenuItem value={"%"}>%</MenuItem>
                    <MenuItem value={"€"}>€</MenuItem>
                </Select>
            </FormControl>

            <FormControl className="input">
                <InputLabel shrink htmlFor={`${tache.id}-total`}>
                    T. HT
                </InputLabel>
                <BootstrapReadonlyInput
                    id={`${tache.id}-total`}
                    readOnly={true}
                    mini={mini}
                    value={tache.total}
                    style={{ width: 60 }} />
            </FormControl>

            <FormControl className="input">
                <InputLabel id={`${tache.id}-poles`} shrink={true}>PÔLES</InputLabel>
                <Select
                    labelId={`${tache.id}-poles`}
                    multiple
                    value={tache.poles}
                    onChange={(event) => {
                        const { value } = event.target;
                        tache.poles = value;
                        tache.updated = true;
                        onChange();
                    }}
                    input={<BootstrapInput mini={mini} />}
                    displayEmpty={true}
                    renderValue={(selected) => {
                        if (selected.length === 0)
                            return <span>Choisir...</span>;
                        
                        return selected.map((value, index) => `${poles.find(e => e.node.id === value)?.node.name}${index === selected.length - 1 ? '': ', '}`) 
                    }}
                >
                    { poles.map((pole) => (
                        <MenuItem key={pole.node.id} value={pole.node.id} style={{
                            fontWeight: tache.poles.find(e => e === pole.node.id) ? 'bold' : 'normal'
                        }}>
                            { pole.node.name }
                        </MenuItem>
                    )) }
                </Select>
            </FormControl>
        </div>
    );
}

const OfferManagerTask = props =>{
    const { tache, onChange, defaultPrice, listTaxes, poles } = props;

    let allSubTaches = tache.taches.filter(e => !e.deleted);

    const  _onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;

        let { tache, onChange } = props;

        tache.taches = arrayMove(tache.taches, oldIndex, newIndex);

        // for (let tache of tache.tache)
        //     tache.ordered = true;

        onChange();
    };
    return (
        <div className="tache">
            <div className="tache-name">
                <Input value={tache.name} onChange={(e) => {
                    tache.name = e.target.value;
                    tache.updated = true;
                    onChange();
                }} />

                <Input value={tache.ref} style={{ marginLeft: 10 }} onChange={(e) => {
                    tache.ref = e.target.value;
                    tache.updated = true;
                    onChange();
                }} />

                <DeleteIcon style={{ color: 'red', marginLeft: 10 }} className="tache-delete" onClick={() => {
                    tache.deleted = true;
                    onChange();
                }} />
            </div>

            <OfferManagerTaskLine tache={tache} onChange={onChange} listTaxes={listTaxes} poles={poles} />

            <SortableContainer onSortEnd={_onSortEnd} helperClass='sortable-helper' useDragHandle>
                {allSubTaches.map((subtask, i) => (
                    <SortableSubtask
                        index={i}
                        key={`item-${subtask.id}`}
                        subtask={subtask}
                        listTaxes={listTaxes}
                        poles={poles}
                        onChange={onChange}
                    />
                ))}
            </SortableContainer>

            <div className="add-sub-task" onClick={() => {
                tache.taches.push({
                    id: uuidv4(),
                    new: true,
                    qty: 0,
                    price: defaultPrice,
                    tva: listTaxes?.[0]?.node.id,
                    remise: 0,
                    typeRemise: '%',
                    total: 0,
                    name: "Nouvelle sous-tâche",
                    ref: 'Référence',
                    taches: [],
                    poles: []
                });

                onChange();
            }}>
                <AddIcon style={{ fontSize: 15 }} />
                Ajouter une sous-tâche
            </div>
        </div>
    );
}

const OfferManagerPhase = props =>{
    const { phase, onChange, defaultPrice, listTaxes, poles } = props;

    let allTaches = phase.taches.filter(e => !e.deleted);
    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon style={{ color: '#fff', fontSize: 30 }} />}
            >
                <div className="phase-name">
                    <PhaseInput
                        value={phase.name}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onChange={(e) => {
                            phase.name = e.target.value;
                            phase.updated = true;
                            onChange();
                        }}
                    />

                    <span className="delete-phase" onClick={(e) => {
                        e.stopPropagation();
                        phase.deleted = true;
                        onChange();
                    }}>Supprimer</span>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="all-taches">
                    {allTaches.map((tache, i) => <OfferManagerTask tache={tache} key={i} onChange={onChange} defaultPrice={defaultPrice} listTaxes={listTaxes} poles={poles} />)}
                </div>

                <div className="add-something-to add-tache" onClick={() => {
                    phase.taches.push({
                        id: uuidv4(),
                        qty: 0,
                        new: true,
                        price: defaultPrice,
                        tva: listTaxes?.[0]?.node.id,
                        remise: 0,
                        typeRemise: '%',
                        total: 0,
                        name: "Nouvelle tâche",
                        ref: 'Référence',
                        taches: [],
                        poles: []
                    });

                    onChange();
                }}>
                    <AddIcon style={{ fontSize: 15 }} />
                    Ajouter une tâche
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

const OfferManager = props => {
    const [defaultPrice,setDefaultPrice] = useState(null)

    useEffect(()=>{
        setDefaultPrice(props.listTarifs?.[0]?.node.id)
    },[])

    const _save = () => {
        props.update();
    };
        const { data, update, isModel, listDeadlines, listTaxes, listAgencePoles, listPayments, listTarifs } = props;
        const { footer, phases } = data;  

        let allPhases = phases.filter(e => !e.deleted);

        let { ht, ttc, totals, remises } = Calculator.getTotals(allPhases, listTaxes);

        if (!phases || (!isModel && !footer)) return null;

        if (footer) {
            if (!footer.paymentTerm)
                footer.paymentTerm = listPayments[0]?.node.id;

            if (!footer.paymentDeadline)
                footer.paymentDeadline = listDeadlines[0]?.node.id;
        }

        const price = listTarifs?.find(e => e.node.id === defaultPrice);

    return (
        <div className="offer-manager">
            <Grid container spacing={2} justifyContent="center" alignItems="center" className="offer-default-price">
                <Grid item xs={6}>
                    Tarif à la journée
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSelect
                        labelId="reg"
                        value={defaultPrice}
                        onChange={(e) => {
                            setDefaultPrice(e.target.value)
                        }}
                        input={<BootstrapInput style={{ width: '100%' }} />}
                    >
                        {listTarifs.map((reg, i) => <MenuItem value={reg.node.id} key={i}>{reg.node.name}</MenuItem>)}
                    </FullWidthSelect>
                </Grid>
            </Grid>

            {allPhases.map((phase, i) => <OfferManagerPhase
                phase={phase}
                key={i}
                onChange={_save}
                defaultPrice={price?.node.price}
                listTaxes={listTaxes}
                poles={listAgencePoles}
            />)}

            <div className="add-something-to add-phase" onClick={() => {
                phases.push({
                    id: uuidv4(),
                    new: true,
                    name: "Nouvelle phase",
                    taches: []
                });

                _save();
            }}>
                <AddIcon style={{ fontSize: 15 }} />
                Ajouter une phase
            </div>

            {
                !isModel && footer && (
                    <>
                        <hr />

                        <Accordion defaultExpanded={true}>
                            <AccordionSummaryPink
                                expandIcon={<ArrowDropDownIcon style={{ color: '#fff', fontSize: 30 }} />}
                            >
                                <div className="phase-name">
                                    Pied de page
                                </div>
                            </AccordionSummaryPink>
                            <AccordionDetails>
                                <Grid container spacing={2} justifyContent="center" alignItems="center">
                                    <Grid item xs={6}>
                                        Remise fixe
                                    </Grid>
                                    <Grid item xs={6}>
                                        <OutlinedInputCustom
                                            value={footer.discountFixed}
                                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                            onChange={(e) => {
                                                footer.discountFixed = e.target.value;
                                                _save();
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        Remise pourcentage
                                    </Grid>
                                    <Grid item xs={6}>
                                        <OutlinedInputCustom
                                            value={footer.discountPercent}
                                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                            onChange={(e) => {
                                                footer.discountPercent = e.target.value;
                                                _save();
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        Accompte
                                    </Grid>
                                    <Grid item xs={6}>
                                        <OutlinedInputCustom
                                            value={footer.advancePayment}
                                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                            onChange={(e) => {
                                                footer.advancePayment = e.target.value;
                                                _save();
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        Condition de règlement
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FullWidthSelect
                                            labelId="reg"
                                            value={footer.paymentTerm}
                                            onChange={(e) => {
                                                footer.paymentTerm = e.target.value;
                                                _save();
                                            }}
                                            input={<BootstrapInput style={{ width: '100%' }} />}
                                        >
                                            {listPayments.map((reg, i) => <MenuItem value={reg.node.id} key={i}>{reg.node.name}</MenuItem>)}
                                        </FullWidthSelect>
                                    </Grid>
                                    <Grid item xs={6}>
                                        Délais de paiement
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FullWidthSelect
                                            labelId="deadline"
                                            value={footer.paymentDeadline}
                                            onChange={(e) => {
                                                footer.paymentDeadline = e.target.value;
                                                _save();
                                            }}
                                            input={<BootstrapInput style={{ width: '100%' }} />}
                                        >
                                            {listDeadlines.map((deadline, i) => <MenuItem value={deadline.node.id} key={i}>{deadline.node.name}</MenuItem>)}
                                        </FullWidthSelect>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </>
                )
            }

            <div className="total-line">
                <span className="label">Total HT</span>
                <span className="amount">{ht.toFixed(2)} €</span>
            </div>

            {
                totals.map((total, i) => (
                    <div className="total-line" key={i}>
                        <span className="label">{total.tax.name}</span>
                        <span className="amount">{total.total.toFixed(2)} €</span>
                    </div>
                ))
            }

            <div className="total-line">
                <span className="label">Remises</span>
                <span className="amount">{remises.toFixed(2)} €</span>
            </div>

            <div className="total-line">
                <span className="label">Total TTC</span>
                <span className="amount">{ttc.toFixed(2)} €</span>
            </div>
        </div>
    );
}


export default OfferManager;