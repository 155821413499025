import {CARD} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

const ImportCategoriesTypes =  {
    langSelect: false,
    drawerType: 'drawer',
    noForm: true,
    component: Button,
    formConfig: {
        type: CARD,
    }
};

export default ImportCategoriesTypes