import React from 'react';

import { Grid } from '@mui/material';
import Button from '../../../../ui/button/Button';
import colors from '../../../../../config/theme/colors';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { withApollo } from '@apollo/client/react/hoc';
import { useMutation } from '@apollo/client';
import { GET_CURRENCIES, ADD_CURRENCY, UPDATE_CURRENCY } from '../../../../../queries/currencies';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ValidateCurrencies(props){
    const newCurrencies = props.newCurrencies;
    const changesCurrencies = props.changesCurrencies;

    const [open, setOpen] = React.useState(false);
    const [textSnack, setTextSnack] = React.useState('La mise à jour des langues s\'est déroulée avec succès');
    const [typeSnack, setTypeSnack] = React.useState('success');

    const [addCurrency] = useMutation(ADD_CURRENCY, {refetchQueries: [{query: GET_CURRENCIES}]});
    const [updateCurrency] = useMutation(UPDATE_CURRENCY);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    
    const save = () => {
        try {
            setOpen(false);
            let validate = true;
            for (let currency of newCurrencies) {
                if(currency.code && currency.libelle && validate === true){
                    addCurrency({ variables: { 
                        "code": currency.code, 
                        "libelle": currency.libelle 
                    }}).then(result => {
                        props.handleResetData();
                    }).catch(error => {
                        console.log(error)
                    });
                    setTextSnack('La mise à jour des devises s\'est déroulée avec succès');
                    setTypeSnack('success');
                }else{
                    setTextSnack('Tous les champs sont requis');
                    setTypeSnack('error');
                    validate = false;
                }
                handleClick();
            }
            for (let currency of changesCurrencies) {
                if(currency.data.code && currency.data.libelle && validate === true){
                    updateCurrency({ variables: { 
                        "id": currency.id,  
                        "code": currency.data.code, 
                        "libelle": currency.data.libelle 
                    }}).then(result => {
                        props.handleResetData();
                    }).catch(error => {
                        console.log(error)
                    });
                    setTextSnack('La mise à jour des devises s\'est déroulée avec succès');
                    setTypeSnack('success');
                }else{
                    setTextSnack('Tous les champs sont requis');
                    setTypeSnack('error');
                    validate = false;
                }
                handleClick();
            }
        } catch (error) {
            console.log(error)
        }

        //--- RESET LOCALE STORAGE
        localStorage.removeItem('GET_CURRENCIES');
        let localStorage_GET_CURRENCIES = localStorage.getItem('GET_CURRENCIES');
        if(!localStorage_GET_CURRENCIES){
            props.client.query({
                query: GET_CURRENCIES,
                fetchPolicy: 'no-cache'
            }).then(result => {
                localStorage.setItem('GET_CURRENCIES', JSON.stringify(result));
            }).catch(error => {
                console.log(error)
            });
        } 

    }

    return(
        <>
            <Grid container direction="column" style={{alignItems: "flex-end", paddingTop: 16}} spacing={0}>
                <Grid item>
                    <Button text="Save" bgcolor={colors.blue.darker.hue300} shadowcolor={colors.black.regular} onClick={save} disabled={props.validate ?  false : true}></Button>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
                <div>
                    <Alert onClose={handleClose} severity={typeSnack}>
                        {textSnack}
                    </Alert>
                </div>
            </Snackbar>
        </>
    );
}

export default withApollo(ValidateCurrencies);