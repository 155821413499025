export const completenessGroupsMapper = (groups, lang) => {
    return new Promise((resolve, reject) => {
        let results = groups.map((group, index) => {
            return {
                obj: group,
                id: group.node.id,
                title: group.node.libelle,
                summary: [
                    {
                        label: 'Nb d\'attributs',
                        value: group.node.completenessGroupAttributes.edges.length,
                    }
                ],
            }
        })
        resolve(results);
    })
}

export const attributesConfigMapper = (groups, lang) => {
    return new Promise((resolve, reject) => {
        let results = groups.map((group, index) => {
            return {
                obj: group,
                id: group.node.id,
                title: group.node.libelle,
                summary: [
                    {
                        label: 'Nb d\'attributs',
                        value: group.node.completenessGroupAttributes?.edges.length,
                    }
                ],
            }
        })
        resolve(results);
    })
}