import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import H1 from '../../../../shared/components/CustomH1';
import H2 from '../../../../shared/components/CustomH2';
import Link from '../../../../shared/components/CustomLink';
import Label from '../../../../shared/components/CustomLabel';
import Button from '../../../../shared/components/CustomButton';
import colors from '../../../../../colors';
import request from "../../../../../tools/Fetch";

const HomeCompleteFormGame = (props) => {
    const [hover, setHover] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [errorConsent, setErrorConsent] = useState(false);
    const [errorMail, setErrorMail] = useState(false);
    const [consentInput, setConsentInput] = useState(false);
    const [optionnalInput, setOptionnalInput] = useState(false);
    const [states, setStates] = useState({});

    function handleChange(value, type=false) {
        let getState = {...states};
        getState[type] =  value?.target?.value ?? value;
        setStates(getState);
        setErrorMail(false)
    }

    let { 
        preview = false,  
        id = null, 
        data,
        inputCallback = null,
    } = props;

    const handleClickButton = (e) => {
        setLoading(true)
        let data = new FormData();
		let currentSource = localStorage.getItem('GAME_SOURCE');
        data.append('username', states.labelInput3);
        data.append('token', process.env.REACT_APP_ASSET_TOKEN);
		data.append('source', currentSource);
        if (optionnalInput){
            data.append('subscribe', optionnalInput);
        }
        let infos = {
            lastname: states.labelInput2,
            firstname: states.labelInput1,
            birthdate: states.labelInput4
        }
        data.append('customData2', JSON.stringify(infos));
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(states.labelInput3)) {
            e.preventDefault();
            
            if (consentInput){
                try {
                    request(`${process.env.REACT_APP_API}/public/asset/games/${props.assetGameType}/registration/can_play`, 'post', data, 'multipart/form-data').then(response => {
                        if (response.code === "no_registration_for_asset" || response.code === "email_not_validated"){
                            setShowPopup(true);
                        }else if(response.code === "can_play"){
                            if (props.assetGameType === "random_draw" && props.isMultiplePlayGame){
                                localStorage.setItem('username', states.labelInput3);
                                window.location = '/game';
                            }else{
                                localStorage.setItem('wishlist_validated', false)
                                localStorage.setItem('username', states.labelInput3);
                                if(response.data?.userToken){
                                    localStorage.setItem('userToken', response.data.userToken);
                                }
                                window.location = '/game';
                            }
                        }else if (response.code === "already_played_today"){
                            window.location = '/already-played';
                        }else if (response.code === "already_win"){
                            window.location = '/already-won';
                        }
                        setLoading(false);
                    }).catch(error => {
                        console.log(error);
                        setLoading(false);
                    });
                } catch(e) {
                    console.log(e);
                    setLoading(false);
                }
            }else{
                setLoading(false);
                setErrorConsent(true);
            }
        }else{
            setErrorMail(true);
            if (!consentInput){
                setErrorConsent(true);
            }
            setLoading(false);
        }
    }

    let image = data.inputs['backgroundImage'].value;
    
    
    useEffect(() => {
        let element = {};
        for(const [key, value] of Object.entries(data.blocks)){
            element[key] = '';
        }
        setStates(element);
    }, []);

    return (
        <div 
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "100%",
                backgroundPosition: "bottom center",
                backgroundRepeat: "no-repeat",
                position: 'relative'
            }}
            className={'typeSection'}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && props.children }

            <Link className="reglement home_reglement" href="#reglement_link" data={data} preview={preview} component={id} block={'linkReglementText'} inputCallback={inputCallback} />
            <section 
                className="first overflow_hidden" 
                style={{
                    minHeight: ((data.blocks['title'].inputs.value?.value && data.blocks['title'].inputs.value?.value !== "<p><br></p>") || (data.blocks['subtitle'].inputs.value?.value && data.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? '500px' : 'auto',
                    padding: '50px 0',
                    display: 'flex',
                    flexDirection: 'column', 
                    justifyContent: 'center', 
                    alignItems: 'center'
                }}
            >
                {
                    data.inputs.displayLogo.value === 'show' ? (
                        <img className="logo" onClick={() => inputCallback(id, 'logo')}
                            style={{
                                width: 'auto',
                                maxWidth: '45%', 
                                minWidth: 330,
                                maxHeight: ((data.blocks['title'].inputs.value?.value && data.blocks['title'].inputs.value?.value !== "<p><br></p>") && (data.blocks['subtitle'].inputs.value?.value && data.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? null : '300px',
                                border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null
                            }}
                            src={
                                typeof data.blocks.logo.inputs.value.value === 'string' ? 
                                    data.blocks.logo.inputs.value.value : 
                                    data.blocks.logo.inputs.value?.value?.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` 
                                        : "http://via.placeholder.com/400x250/FFFFFF/53839D?text=Logo"
                                }
                            alt="Logo jeu"
                        />
                    ) : null
                }
                {
                    data.blocks['title'].inputs.value?.value && data.blocks['title'].inputs.value?.value !== "<p><br></p>" ? (
                        <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
                    ) : null
                }
                {
                    data.blocks['subtitle'].inputs.value?.value && data.blocks['subtitle'].inputs.value?.value !== '<p><br></p>' ? (
                        <H2 data={data} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true} />
                    ) : null
                }

                <form id="form_login" className="complete_form" method="POST">
                    <input style={{
                            fontSize: data.blocks['labelInput1']?.inputs?.size?.value,
                            lineHeight: '2',
                            borderStyle: 'solid',
                            borderWidth: data.blocks.button.inputs?.border?.inputs?.borderWidth?.value ? data.blocks.button.inputs.border.inputs.borderWidth.value : '0px',
                            borderColor: data.blocks.button.inputs?.border?.inputs?.borderColor?.value ? data.blocks.button.inputs.border.inputs.borderColor.value : null,
                            borderRadius: data.blocks.button.inputs?.border?.inputs?.borderRadius?.value ? data.blocks.button.inputs.border.inputs.borderRadius.value : null,
                    }} type="text" value={states.labelInput1} name="text" id="labelInput1" placeholder={data.blocks['labelInput1']?.inputs?.placeholder?.value} required autofocus onChange={(e) => {handleChange(e.target?.value, 'labelInput1')}}/>
                    <input style={{
                            fontSize: data.blocks['labelInput2']?.inputs?.size?.value,
                            lineHeight: '2',
                            borderStyle: 'solid',
                            borderWidth: data.blocks.button.inputs?.border?.inputs?.borderWidth?.value ? data.blocks.button.inputs.border.inputs.borderWidth.value : '0px',
                            borderColor: data.blocks.button.inputs?.border?.inputs?.borderColor?.value ? data.blocks.button.inputs.border.inputs.borderColor.value : null,
                            borderRadius: data.blocks.button.inputs?.border?.inputs?.borderRadius?.value ? data.blocks.button.inputs.border.inputs.borderRadius.value : null,
                    }} type="text" value={states.labelInput2} name="text" id="labelInput2" placeholder={data.blocks['labelInput2']?.inputs?.placeholder?.value} required autofocus onChange={(e) => {handleChange(e.target?.value, 'labelInput2')}}/>
                    <input style={{
                            fontSize: data.blocks['labelInput3']?.inputs?.size?.value,
                            lineHeight: '2',
                            borderStyle: 'solid',
                            borderWidth: data.blocks.button.inputs?.border?.inputs?.borderWidth?.value ? data.blocks.button.inputs.border.inputs.borderWidth.value : '0px',
                            borderColor: data.blocks.button.inputs?.border?.inputs?.borderColor?.value ? data.blocks.button.inputs.border.inputs.borderColor.value : null,
                            borderRadius: data.blocks.button.inputs?.border?.inputs?.borderRadius?.value ? data.blocks.button.inputs.border.inputs.borderRadius.value : null,
                    }} type="text" value={states.labelInput3} name="text" id="labelInput3" placeholder={data.blocks['labelInput3']?.inputs?.placeholder?.value} required autofocus onChange={(e) => {handleChange(e.target?.value, 'labelInput3')}}/>
                    <label for="labelInput4" >
                        {data.blocks['labelInput4']?.inputs?.placeholder?.value}
                    </label>
                    <input style={{
                            fontSize: data.blocks['labelInput4']?.inputs?.size?.value,
                            lineHeight: '2',
                            borderStyle: 'solid',
                            borderWidth: data.blocks.button.inputs?.border?.inputs?.borderWidth?.value ? data.blocks.button.inputs.border.inputs.borderWidth.value : '0px',
                            borderColor: data.blocks.button.inputs?.border?.inputs?.borderColor?.value ? data.blocks.button.inputs.border.inputs.borderColor.value : null,
                            borderRadius: data.blocks.button.inputs?.border?.inputs?.borderRadius?.value ? data.blocks.button.inputs.border.inputs.borderRadius.value : null,
                    }} type="date" value={states.labelInput4} name="date" id="labelInput4" placeholder={data.blocks['labelInput4']?.inputs?.placeholder?.value} required autofocus onChange={(e) => {handleChange(e.target?.value, 'labelInput4')}}/>
                    <div className="consentContainer">
                        <div className={errorConsent ? "consent error" : "consent"}>
                            <input required id="consentement" type="checkbox" value={consentInput} onClick={() => { setConsentInput(!consentInput); setErrorConsent(false);}}/>
                            <Label data={data} preview={preview} component={id} block={'labelConsent'} inputCallback={inputCallback} nopadding={true} forInput="consentement" />    
                        </div>
                        <div className="consent consentOptionnal">
                            <input id="consentementOptionnal" type="checkbox" value={optionnalInput} onClick={() => setOptionnalInput(!optionnalInput)}/>
                            <Label data={data} preview={preview} component={id} block={'labelConsentOptionnal'} inputCallback={inputCallback} nopadding={true} forInput="consentementOptionnal" />    
                        </div>
                    </div>
                    <Button data={data} preview={preview} component={id} block={'button'} className="button" inputCallback={inputCallback} nopadding={true} type="submit" onClick={(e) => handleClickButton(e)}/>
                </form>
            </section>
        </div>
    );
};

HomeCompleteFormGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default HomeCompleteFormGame;
