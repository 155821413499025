import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@mui/material';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import * as moment from 'moment';

const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    padding: 16px;
`

export default function CardHeaders(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.black.regular,
        },
        tooltip: {
            backgroundColor: colors.black.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }
    return(
        <ContainerCard>
            <Grid container style={{position: 'relative', height: '100%'}}>
                <Grid container direction="column">
                    <Grid container justifyContent="flex-end">
                        <StatusInfo status={props.data.node.status} width={'auto'}/>
                    </Grid>
                    <Typography variant="h4">{props.data.node.message}</Typography>
                    <Typography variant="body1">Lien: <a href={props.data.node.link} target="__blank">{props.data.node.link}</a></Typography>
                    <Typography variant="body1">Date de début: {moment(props.data.node.startDate).format('L')}</Typography>
                    <Typography variant="body1">Date de fin: {moment(props.data.node.endDate).format('L')}</Typography>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Button onClick={() => props.handlerButton(props.data.node)} text={props.textButton}></Button>
                </Grid>
            </Grid>
        </ContainerCard>
    );
}