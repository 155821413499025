import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import './LoadingOverlay.scss';

const useStyles = makeStyles(theme => ({
    root: {
      zIndex: 2000,
      color: '#fff'
    }
}));

const LoadingOverlay = (props) => {
    const classes = useStyles();

    return (
        <Backdrop className={classes.root} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
export default LoadingOverlay;