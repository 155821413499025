import { FORM } from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';
import CatalogFinal from '../../../../../../assets/pictos/empty-picto/catalogue.svg';
import CheckboxAttributs from '../../../../../ui/inputs/CheckboxAttributs';
import { capitalize } from 'lodash';

export default function catalogEditAttributes(groupsWithSelectedAttr) {
    var obj = {
        titleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Gérer les attributs du catalogue' : "Modifier l'entrepôt",
        subTitleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Veuillez compléter les champs ci-dessous pour mettre à jour les attributs du catalogue' : "Veuillez compléter les champs ci-dessous pour mettre à jour votre entrepôt",
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            header: {
                title: 'Modifier les attributs du catalogue',
            },
            // finalStep: {
            //     picto: CatalogFinal,
            //     title: 'Et voilà !',
            //     subtitle: 'Vous pouvez confirmer votre modification',
            //     textButton: 'Enregistrer'
            // },
            children: [
                {
                    labelName: 'Attributs',
                    isOptionnal: false,
                    optionsInputs: groupsWithSelectedAttr?.map((group, index) => ({
                        type: 'checkboxList',
                        required: false,
                        stateName: 'groupsWithSelectedAttr',
                        listing: {
                            id: group.node.id,
                            title: capitalize(group.node.identifier),
                            values: group?.node.attributes.edges?.map((attr) => {
                                return ({
                                    id: attr.node.id,
                                    name: attr.node.identifier ?? '-',
                                    groupId: group.node.id,
                                })
                            }),
                            checkedValuesByDefault: group?.selectedAttributes,
                        },
                        custom: {
                            isExpandable: true,
                            isExpanded: false,
                            hasSearch: true,
                            hasSelectAll: true,
                            style: {
                                marginBottom: index === groupsWithSelectedAttr.length - 1 ? 32 : 20,
                            }
                        }
                    }))
                },
            ]
        }
    }
    return obj;
}