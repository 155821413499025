import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CustomCard from './CustomCard';

import { MultipleBlockConfig } from '.';
import colors from "../../../../config/theme/colors";
import _ from 'lodash';
import styled from 'styled-components';


const AccordionSummaryCustom = styled(AccordionSummary)`
    background: ${colors.blue.lighter.hue900};
    &>div{
        justify-content: space-between;
        align-items: center;
        p{
            color: ${colors.blue.lighter.hue300};
        }
        svg{
            fill: ${colors.blue.lighter.hue300};
        }
    }
`;


const DivContainer = styled.div`
    &>div.MuiPaper-root{
        margin-left: 0!important;
        margin-right: 0!important;
        width: 100%!important;
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    cardHeader: {
        color: '#000'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rulePanel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    actionBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10
    }
}));

export default ({ datas, buildFormInput, addBlock, deleteBlock, updateConfig }) => {
    const classes = useStyles();

    let inputs = [];
    let inputsConfig = [];
    let inputsBlock = [];
    
    for (let inputName in datas.inputs) {
        let input = datas.inputs[inputName];
        inputsConfig.push(buildFormInput(input, datas.id, true));
    }
    
    inputs.push(
        <Accordion style={{ borderRadius: 0, boxShadow: 'inherit', width: '100%', marginBottom: 16 }}>
            <AccordionSummaryCustom
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{datas.name} - Configuration du bloc</Typography>
            </AccordionSummaryCustom>
            <AccordionDetails>
                <div style={{padding: '8px', width: 'calc(100% - 16px)', background: '#F0F7FA' }}>
                    <div style={{display: 'flex', flexDirection: 'column', background: 'white', padding: 8, width: 'calc(100% - 16px)'}}>
                        {inputsConfig}
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );

    if(Array.isArray(datas.blocks)){
        for (let block of datas.blocks) {
            let cardInputs  = [];
            
            if(block.inputs){
                for (let inputName in block.inputs) {
                    let input = block.inputs[inputName];
                    cardInputs.push(buildFormInput(input, block.id));
                }
                inputsBlock.push(<CustomCard block={block} cardInputs={cardInputs} propExpanded={false} noPadding={true} />);
            }

            if(block.blocks){
                for(let item in block.blocks){
                    if (block.blocks[item].type === 'multiple-block') {
                        inputsBlock.push(
                            <Typography className={classes.heading} style={{color: colors.black.regular, backgroundColor: 'white', paddingBottom: 12, fontSize: 16,  padding: 16}}>
                                { block.blocks[item].name }
                            </Typography>
                        );
                        inputsBlock.push(<div style={{backgroundColor: 'white', padding: 8, marginBottom: 8}}><MultipleBlockConfig datas={block.blocks[item]} buildFormInput={buildFormInput} addBlock={addBlock} deleteBlock={deleteBlock} /></div>)
                    } else if(block.blocks[item].inputs) {
                        for (let inputName of block.blocks[item].inputs) {
                            let input = block.blocks[item].inputs[inputName];
                            cardInputs.push(buildFormInput(input, item.id));
                            inputsBlock.push(<CustomCard block={block} cardInputs={cardInputs} propExpanded={false} noPadding={true} />);
                        }
                    }
                }
            }
        }
    }else{
        for (let blockName in datas.blocks) {
            let block       = datas.blocks[blockName];
            let cardInputs  = [];
    
            for (let inputName in block.inputs) {
                let input = block.inputs[inputName];
                cardInputs.push(buildFormInput(input, block.id));
            }
    
            inputsBlock.push(
                <div style={{padding: "8px", background: 'white', margin: "16px 0"}}>
                    <Typography variant="h2" className={classes.heading} style={{ color: colors.black.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16, fontWeight: '700' }}>
                        { block.name }
                    </Typography>
                    { block.type === 'multiple-block' ? (<MultipleBlockConfig datas={block} buildFormInput={buildFormInput} addBlock={addBlock} deleteBlock={deleteBlock} />) : cardInputs}
                </div>
            );
    
        }
    }

    inputs.push(
        <div style={{width: 'calc(100% - 16px)', padding: 8, background: colors.blue.lighter.hue900}}>
            <DivContainer>
                {inputsBlock}
            </DivContainer>
        </div>
    );

    return (
        <div className={classes.root}>
            { inputs }
        </div>
    )
};