import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { START_LOADING } from "@constants/action-types";
import colors from "@config/theme/colors";
import { Box } from "@mui/material";
import Typography from "@ui/typography/Typography";
import imgNotFound from "@assets/pictos/file-types/not-found.png";
import styled from "styled-components";
import OurMenu from "@ui/menu/Menu";
import PropsType from "prop-types";
import Tooltip from "@ui/tooltip/Tooltip";
import OurButton from "@ui/button/OurButton";
import { infoBtn } from "@ui/button/config/themes.config";

const Link = styled.a`
  color: ${colors.blue.darker.hue300};
  font-weight: bold;
  &:hover {
    color: ${colors.black.regular};
  }
`;

const ContainerImage = styled(Box)`
  overflow: hidden;
  background-color: ${(props) =>
    props.backgroundimage === imgNotFound ? "#F8FAFB" : "white"};
  background-image: url(${(props) => props.backgroundimage});
  background-size: ${(props) =>
    props.backgroundimage === imgNotFound ? "contain" : "80%"};
  background-repeat: no-repeat;
  background-position: center;
  border: solid 1px ${colors.grey.lighter.hue900};
`;

const Color = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  background-color: ${(props) => props.color};
`;

const CardGuideline = ({ guideline, actionButtons, ...props }) => {
  const logo = (guideline) => {
    for (let data of guideline.guidelineData.edges) {
      if (data.node.attribute.identifier.includes("logo")) {
        return `${process.env.REACT_APP_MEDIAS}/${data.node.media?.filePath}`;
      }
    }
    return imgNotFound;
  };

  return (
    <Box
      width={"100%"}
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignContent: "space-between",
        gap: "1rem",
        height: "100%",
      }}
    >
      <ContainerImage height={160} backgroundimage={logo(guideline)} />

      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.6rem",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box sx={{ position: "absolute", right: 0, top: "10px" }}>
            <OurMenu menuOptions={actionButtons} />
          </Box>
          <Typography
            variant="h1"
            colortext={colors.black.regular}
            component="div"
            fontWeight="bold"
          >
            {guideline.libelle}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "start" }}>
          <Box sx={{ width: "50%" }}>
            <Typography variant="body1" sx={{ lineHeight: "32px" }}>
              URL de production :
            </Typography>
          </Box>
          <Box>
            <Link href={guideline.guideline_url} target="_blank">
              {guideline.guideline_url}
            </Link>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "start" }}>
          <Box sx={{ width: "50%" }}>
            <Typography>
              {guideline.colors.length === 1 || guideline.colors.length === 0
                ? "Couleur :"
                : "Couleurs :"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", maxWidth: "50%" }}>
            {guideline.colors.edges.length > 0 ? (
              guideline.colors.edges.map((color, index) => {
                if (index <= 3)
                  return (
                    <Box
                      key={`guideline-color-${index}`}
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        marginRight: 2,
                      }}
                    >
                      <Color color={color.node.codeHexa} />
                      <span
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        {color.node.codeHexa},
                      </span>
                    </Box>
                  );

                if (index === 4)
                  return (
                    <Box
                      sx={{ display: "inline-block" }}
                      key={`guideline-color-${index}`}
                    >
                      <Box
                        sx={{
                          display: "inline-flex",
                          alignItems: "start",
                          marginRight: 16,
                          marginTop: -20,
                          fontSize: 20,
                          fontWeight: "bold",
                          color: colors.grey.regular,
                          lineHeight: "16px",
                        }}
                      >
                        <Tooltip
                          title={guideline.colors?.edges.map((color, index) =>
                            index >= 4 && index < 10 ? (
                              <Box
                                sx={{ display: "block" }}
                                key={`tooltip-item-color-${index}`}
                              >
                                <Box
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Color color={color.node.codeHexa} />
                                  <span
                                    style={{
                                      textTransform: "uppercase",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {color.node.codeHexa},
                                  </span>
                                </Box>
                              </Box>
                            ) : index === 10 ? (
                              <p key={`tooltip-item-last-${index}`}>
                                +{guideline.colors.edges.length - 10} couleur
                                {guideline.colors.edge.length - 10 > 1
                                  ? "s"
                                  : null}
                              </p>
                            ) : null
                          )}
                        >
                          <p>...</p>
                        </Tooltip>
                      </Box>
                    </Box>
                  );
              })
            ) : (
              <Box>
                <Typography
                  sx={{
                    color: colors.grey.lighter.hue600,
                  }}
                >
                  Aucune couleur associée
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "start" }}>
          <Box sx={{ width: "50%" }}>
            <Typography>
              {guideline.typographies.length === 1 ||
              guideline.typographies.length === 0
                ? "Typographie :"
                : "Typographies :"}
            </Typography>
          </Box>
          <Box>
            {guideline.typographies.edges.length > 0 ? (
              guideline.typographies.edges.map((typo, index) => (
                <Box
                  style={{ display: "inline-block" }}
                  key={`guideline-typo-${index}`}
                >
                  <Box
                    style={{
                      display: "inline-flex",
                      alignItems: "start",
                      marginRight: 16,
                      fontWeight: "bold",
                    }}
                  >
                    <span>{typo.node.typographySystem.libelle}</span>
                  </Box>
                </Box>
              ))
            ) : (
              <Box>
                <Typography
                  style={{
                    color: colors.grey.lighter.hue600,
                  }}
                >
                  Aucune typographie associée
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {!props.configCard && props.onClick && (
        <Box sx={{ width: "100%"}}>
          <OurButton
            fullWidth
            label="Voir la guideline"
            onClick={() => props.onClick()}
          />
        </Box>
      )}
    </Box>
  );
};

CardGuideline.propTypes = {
  guideline: PropsType.object.isRequired,
  actionButtons: PropsType.arrayOf(
    PropsType.shape({
      label: PropsType.string,
      onClick: PropsType.func,
      icon: PropsType.element,
    })
  ),
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardGuideline)
);
