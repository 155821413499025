import { FormControlLabel, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import colors from "../../../../../../config/theme/colors";

export const GridRadio = styled(Grid)`
    &:not(:last-child){
        margin-bottom: 0.5rem;
    }
`;

export const ClickableLabel = styled.label`
    > div {
        border: 1px solid transparent;
        &:hover{
            border: 1px solid #0273A5;
            cursor: pointer;
        }
    }
    &.active{
        > div {
            border: 1px solid #0273A5;
        }
    }
    &.error{
        > div {
            border: 1px solid #f44336;
        }
    }
`;

export const DescriptionRadio = styled(Typography)`
p{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
}
`;

export const FormControlLabelCustom = styled(FormControlLabel)`
    margin: 0;
    > span.MuiRadio-root, > span.MuiCheckbox-root{
        padding: 3px 0;
    }
    span{
        color: ${colors.black.regular};
    }
    span.MuiTypography-root{
        margin-left: 8px;
    }
    &.active{
        span{
            color: ${colors.blue.darker.hue300};
            font-weight: bold;
        }
    }
    
`;