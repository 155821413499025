export const modules = {
  toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'blockquote'],
      ['link', 'video'],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ],
};

export const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'link',
  'image',
  'video',
  'list',
  'color',
  'align',
  'background',
  'blockquote',
  'twitter',
  'header'
];