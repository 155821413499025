import { gql } from '@apollo/client';
import Axios from './js/utils/axios';
import { UPDATE_CATALOG } from './queries/catalogs';
import { ADD_CATEGORY, ADD_CATEGORY_DATA } from './queries/categories';

const GET_CATEGORIES_LIGHT = gql`
{
  categories{
    id
    libelle
    status
    parent {
        id
    }
    master {
      id
      libelle
    }
    products(first: 10000){
      totalCount
      edges {
          node {
              id
          }
      }
    }
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  } 
}
`;

const GET_CATALOGS_BY_ID = gql`
query catalogs($id: ID!){
  catalog(id: $id){
    id
    identifier
    libelle
    categories{
      id
    }
  }
}
`;

const patch =(client) => {

    let saveAttributes = async (category, uptodate) => {
        console.log(`Duplicating category attributes (${category.libelle})...`);

        for (let { node } of category.categoryDatas.edges) {
            let variables = {
                "category": uptodate.id, 
                "attribute": node.attribute.id,
                "locale": node.locale.id
            };

            let isMedia = node.attribute.attributeType.input === 'image';

            if (isMedia) {
                let id = node.media.id.replace('/api/media-objects/', '');
                let duplicate   = await Axios(`${process.env.REACT_APP_API}/media-objects/${id}/duplicate`, 'put');

                variables.media = "/api/media-objects/" + duplicate.response;
            } 

            if (!isMedia)
                variables.value = node.value;

            await client.mutate({
                mutation: ADD_CATEGORY_DATA,
                variables
            });
        }
    };

    let processChildren = async (categories, parent, catalog, attach, allIdentifiers) => {
        // console.log(parent.products.edges.map(e => e.node.id));

        console.log(`Duplicating category (${parent.libelle})...`);

        const MUTATION_RESULT = await client.mutate({
            mutation: ADD_CATEGORY,
            variables: {
                libelle: `${parent.libelle}_${catalog.libelle}_${Math.round(Math.random() * 1000000)}`, 
                parent: attach,
                master: parent.id,
                status: parent.status,
                products: parent.products.edges.map(e => e.node.id)
            }
        });

        let uptodate = MUTATION_RESULT.data.createCategory.category;

        allIdentifiers.push(uptodate.id);

        await saveAttributes(parent, uptodate);

        parent.children = categories.filter(e => e.parent?.id === parent?.id);

        for (let child of parent.children) {
            await processChildren(categories, child, catalog, uptodate.id, allIdentifiers);
        }
    };

    window.copySpread = (category, catalog, parent) => {
        console.log('Fetching all categories...');

        client.query({
            query: GET_CATEGORIES_LIGHT,
            fetchPolicy: 'no-cache'
        }).then(resultCats => {
            console.log('Fetching catalog...');

            client.query({
                query: GET_CATALOGS_BY_ID,
                variables: { id: catalog },
                fetchPolicy: 'no-cache'
            }).then(async resultCata => {
                let findCatalog = resultCata.data.catalog;
                let allCategories = resultCats.data.categories;

                console.log(findCatalog)

                console.log('Building tree recursively...');

                let root = allCategories.find(e => e.id === category);

                let allIdentifiers = [];
            
                await processChildren(allCategories, root, findCatalog, parent, allIdentifiers);

                await client.mutate({
                    mutation: UPDATE_CATALOG,
                    variables: {
                        id: catalog,
                        categories: allIdentifiers.concat(findCatalog.categories.map(e => e.id))
                    }
                });

                console.log('Finished!');
            }).catch((err)=>{
              console.log(err)
            });
        }).catch((err)=>{
          console.log(err)
        });
    };
};

export default patch