import React, { Suspense, useEffect } from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {Switch,Route} from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Appbar from '../../navigation/Appbar';
import DrawerLeft from '../../navigation/DrawerLeft';
import './Dashboard.scss';
import { START_LOADING, STOP_LOADING, SET_USER, SET_GUIDELINE, SNACK } from 'js/constants/action-types';
import { GET_SYSTEM_TYPOS } from "queries/brand_guideline";
import GoogleFontLoader from 'react-google-font-loader';
import { withApollo } from '@apollo/client/react/hoc';

import {
  ROUTE_HOME,
  ROUTE_STATS_ORDERS,
  ROUTE_STATS_RETAILERS,
  ROUTE_STATS_ASSETS,
  ROUTE_STATS_CAMPAIGNS,
  ROUTE_STATS_GOOGLE_ANALYTICS,
  ROUTE_DIFFUSION_CALENDAR,
  ROUTE_DIFFUSION_PERMANENT,
  ROUTE_COMMUNITIES_PERMANENTS_POSTS,
  ROUTE_COMMUNITIES_PERMANENTS_ADD_POST,
  ROUTE_DIFFUSION_CAMPAIGNS,
  ROUTE_DIFFUSION_CAMPAIGNS_ADD,
  ROUTE_DIFFUSION_CAMPAIGNS_DETAIL,
  ROUTE_DIFFUSION_RETAILERS,
  ROUTE_DIFFUSION_SOCIALS,
  ROUTE_ENGINE,
  ROUTE_SALES_ESHOP,
  ROUTE_ENGINE_ASSETS_DETAIL,
  ROUTE_DIFFUSION_PERMANENTS_PAGE,
  ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET,
  ROUTE_ENGINE_ASSETS_DETAIL_STATS,
  ROUTE_CATALOGS,
  ROUTE_CATALOGS_LIST,
  ROUTE_CATALOGS_DETAIL,
  ROUTE_MEDIAS,
  ROUTE_MEDIAS_CATEGORIES,
  ROUTE_MEDIAS_LIST,
  ROUTE_MEDIAS_TAGS,
  ROUTE_CHANNELS_MEDIAS,
  ROUTE_ORDERS_LIST,
  ROUTE_ORDERS_LIST_PREORDER,
  ROUTE_ORDERS_LIST_RETURN,
  ROUTE_ORDERS_LIST_DELAY,
  ROUTE_ORDERS_DETAIL,
  ROUTE_ATTRIBUTS,
  ROUTE_PRODUCTS_ATTRIBUTS,
  ROUTE_ATTRIBUTS_CATEGORIES,
  ROUTE_ATTRIBUTS_POINT,
  ROUTE_PRODUCTS,
  ROUTE_PRODUCTS_CATEGORIES,
  ROUTE_PRODUCTS_LIST,
  ROUTE_PRODUCTS_ADD,
  ROUTE_PRODUCTS_LIST_DETAIL,
  ROUTE_SALES_SHOPS,
  ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK,
  ROUTE_CHANNELS,
  ROUTE_SALES_RETAILERS,
  ROUTE_CHANNELS_BRANDS,
  ROUTE_SALES_MARKETPLACES,
  ROUTE_CHANNELS_CONNECTORS,
  ROUTE_COMMUNITIES_SOCIALS,
  ROUTE_CHANNELS_CMS,
  ROUTE_COMMUNITIES_NEWSLETTERS,
  ROUTE_CHANNELS_CONTENTS,
  ROUTE_SETTINGS,
  ROUTE_SYSTEM_LANGUAGES,
  ROUTE_SYSTEM_CURRENCIES,
  ROUTE_SETTINGS_USERS,
  ROUTE_SETTINGS_SUBSCRIPTIONS,
  ROUTE_PRODUCTS_COMPLETUDE,
  ROUTE_SETTINGS_ACCESS,
  ROUTE_PREFERENCES,
  ROUTE_HELPS,
  ROUTE_SETTINGS_GUIDELINE,
  ROUTE_SETTINGS_GUIDELINE_ADD,
  ROUTE_SETTINGS_GUIDELINE_UPDATE,
  ROUTE_BRAND_FLOW,
  ROUTE_CONTENU,
  ROUTE_CONTENU_CATEGORIES,
  ROUTE_CONTENU_LIST,
  ROUTE_CONTENU_ADD,
  ROUTE_CONTENU_LIST_DETAIL,
  ROUTE_CONTENU_ATTRIBUTES,
  ROUTE_HISTORY_IMPORT,
  ROUTE_SHELLS,
  ROUTE_SHELLS_LIST,
  ROUTE_SHELLS_CUSTOM_ATTRIBUTES,
  ROUTE_SHELLS_CUSTOM_LIST,
  ROUTE_SHELLS_CUSTOM_DETAIL,
  ROUTE_RESTAURANTS,
  ROUTE_RESTAURANTS_DETAILS,
  ROUTE_RESTAURANTS_DEMANDS,
  ROUTE_SALES_SHOPS_DETAILS,
  ROUTE_PRODUCTS_COLLECTIONS,
  ROUTE_SYSTEM_TAXRATES,
  ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS,
  ROUTE_SALES_ESHOP_FOOTER,
  ROUTE_SALES_APPLICATION_FOOTER,
  ROUTE_SYSTEM_GROUPRATES,
  ROUTE_BRAND_FLOW_DETAILS,
  ROUTE_SALES_ESHOP_DETAIL,
  ROUTE_SALES_ESHOP_DETAIL_STATS,
  ROUTE_COMMUNITIES_GAMES,
  ROUTE_COMMUNITIES_GAMES_DETAIL,
  ROUTE_COMMUNITIES_GAMES_DETAIL_STATS,
  ROUTE_COMMUNITIES_GAMES_DIFFUSION,
  ROUTE_COMMUNITIES_GAMES_DIFFUSION_PAGE,
  ROUTE_COMMUNITIES_MINISITES,
  ROUTE_COMMUNITIES_MINISITES_DETAIL,
  ROUTE_COMMUNITIES_MINISITES_DETAIL_STATS,
  ROUTE_COMMUNITIES_MINISITES_DIFFUSION,
  ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE,
  ROUTE_PRODUCTS_SETTINGS,
  ROUTE_HISTORY_DASHBOARD,
  ROUTE_SALES_SOCIAL_COMMERCE,
  ROUTE_SALES_GLOBAL,
  ROUTE_MEDIAS_SETTINGS,
  ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING,
  ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS,
  ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_LANDING,
  ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_LANDING_CHILDS,
  ROUTE_SALES_SOCIAL_COMMERCE_DETAILS,
  ROUTE_CHANNELS_MARKETPLACES,
  ROUTE_SALES_MARKETPLACES_DETAILS,
  ROUTE_CHANNELS_SOCIALS,
  ROUTE_COMMUNITIES_SOCIALS_DETAIL,
  ROUTE_SALES_APPLICATIONS,
  ROUTE_SALES_APPLICATIONS_DETAILS,
  ROUTE_SALES_APPLICATIONS_NOTIFICATIONS,
  ROUTE_SETTINGS_USERS_GROUPS,
  ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING,
  ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH,
  ROUTE_SALES_ESHOP_DETAIL_CACHE_CATEGORIES,
  ROUTE_PRODUCTS_COVER_RATE,
  ROUTE_PRODUCTS_COVER_RATE_DETAIL,
  ROUTE_SALES_ESHOP_DETAIL_CROSSSELL,
  ROUTE_SALES_APPLICATION_DETAIL_CROSSSELL,
  ROUTE_CONTENU_SETTINGS,
  ROUTE_STATS_ORDERS_BY_SITE,
  ROUTE_STATS_ORDERS_BY_MARKETPLACES,
  ROUTE_HISTORY_IMPORT_PRODUCT,
  ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME,
  ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS,
  ROUTE_COMMUNITIES_CMS,
  ROUTE_COMMUNITIES_CMS_DETAIL,
  ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING,
  ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS,
  ROUTE_PRODUCTS_PRICE_COMPARE,
  ROUTE_PRODUCTS_PRICE_COMPARE_DASHBOARD,
  ROUTE_SALES_ESHOP_DETAIL_TOP_MESSAGES,
  ROUTE_PRODUCTS_PRICE_COMPARE_LIST,
  ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL,
  ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS,
  ROUTE_SALES_APPLICATION_DETAIL_DISCOUNTS,
  ROUTE_PRODUCTS_BRANDS_LIST,
  ROUTE_PRODUCTS_BRANDS_DETAIL,
  ROUTE_PRODUCTS_WORKFLOW_NEW,
  ROUTE_PRODUCTS_WORKFLOW_DELTA,
  ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE,
  ROUTE_PRODUCTS_WORKFLOW_DELAYED,
  ROUTE_PRODUCTS_WORKFLOW_ERRORS,
  ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER,
  ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER_CHILDS,
  ROUTE_PRODUCTS_IMPORTS,
  ROUTE_PRODUCTS_WORKFLOW_MANUALLY,
  ROUTE_PRODUCTS_WORKFLOW_TO_VERIFY,
  ROUTE_PRODUCTS_WORKFLOW_TO_VALIDATE,
  ROUTE_PRODUCTS_WORKFLOW_LISTING,
  ROUTE_PRODUCTS_WORKFLOW,
  ROUTE_PRODUCTS_WORKFLOW_SINFIN,
  ROUTE_PRODUCTS_WORKFLOW_PIM,
  ROUTE_PRODUCTS_WORKFLOW_GAIA,
  ROUTE_PRODUCTS_WORKFLOW_TP,
  ROUTE_PRODUCTS_WORKFLOW_TASKS,
  ROUTE_PRODUCTS_WORKFLOW_TASKS_CONTRIBUTOR,
  ROUTE_PRODUCTS_WORKFLOW_ACTIVITY,
  ROUTE_CENTRALISATION_CONFIGURATION,
  ROUTE_PRODUCTS_ATTRIBUT,
  ROUTE_CONTENT_ATTRIBUT,
  ROUTE_SYSTEM,
  ROUTE_OPTIMISATION_DASHBOARD,
} from 'js/constants/route-names';

import Homepage from './homepage/Homepage';
import Calendar from './homepage/Calendar';

import DashboardStats from './centralisation/stats/DashboardStats';
import StatsAssets from './centralisation/stats/StatsAssets';
import StatsRetailers from './centralisation/stats/StatsRetailers';
import StatsCampaigns from './centralisation/stats/StatsCampaigns';
import StatsGoogleAnalytics from './centralisation/stats/StatsGoogleAnalytics';

import DiffusionPermanent from './diffusion/DiffusionPermanent';
import DiffusionPermanentPosts from './diffusion/DiffusionPermanentPosts';
import DiffusionPermanentAddPost from './diffusion/DiffusionPermanentAddPost';
import DiffusionCampaigns from './diffusion/DiffusionCampaigns';
import DiffusionCampaignAdd from './diffusion/DiffusionCampaignAdd';
import DiffusionCampaignDetail from './diffusion/DiffusionCampaignDetail';
import DiffusionRetailers from './diffusion/DiffusionRetailers';
import DiffusionSocials from './diffusion/DiffusionSocials';

import DashboardEngine from './engine/DashboardEngine';
import EngineAssets from './engine/EngineAssets';
import EngineAssetsDetails from './engine/EngineAssetsDetails';
import EngineAssetsDetailsPageStats from './engine/EngineAssetsDetailsPageStats';
import EngineAssetsDetailsPage from './engine/EngineAssetsDetailsPage';
import EngineAssetsDetailsHeaders from './engine/EngineAssetsDetailsHeaders';

import DashboardCatalogs from './catalogs/DashboardCatalogs';
import ListCatalogs from './catalogs/ListCatalogs';
import CatalogsDetails from './catalogs/CatalogsDetails';

import DashboardMedias from './medias/DashboardMedias';
import MediasCategories from './medias/MediasCategories';
import ListMedias from './medias/ListMedias';
import MediasTags from './medias/MediasTags';

import DashboardContents from './centralisation/contents/DashboardContents';
import ListContents from './centralisation/contents/ContentsPage/ListContents';
import ContentDetail from './centralisation/contents/ContentDetails/ContentDetail';
import ContentAddNew from './centralisation/contents/ContentAddNew';
import ContentsCategories from './centralisation/contents/ContentsCategories';
import ContentsAttributes from './centralisation/contents/ContentsAttributes';

import AttributesProducts from './attributs/AttributesProducts';

import DashboardProducts from './products/DashboardProducts';
import ProductsCategories from './products/ProductsCategories';
import ProductAdd from './products/ProductAdd';
import ListProducts from './products/ListProducts';
import ShopTheLook from './products/ShopTheLook';

import DashboardChannels from './channels/DashboardChannels';
import ListChannels from './channels/ListChannels';
import ListRetailers from './channels/ListRetailers';
import RetailersDetails from './channels/RetailersDetails';

import GuidelineBrand from './brand/GuidelinesPage/GuidelineBrand';

import SettingsLanguages from './settings/SettingsLanguages';
import SettingsCurrencies from './settings/SettingsCurrencies';
import SettingsUsers from './settings/config/SettingsUsers';
import SettingsSubscriptions from './settings/SettingsSubscriptions';
import SettingsPreferences from './settings/SettingsPreferences';
import SettingsAccess from './settings/SettingsAccess';
import ProductsCompletude from './products/ProductsCompletude';
import ImportHistory from './settings/ImportHistory';
import SettingsHelps from './settings/SettingsHelps';
import ListOrders from './orders/ListOrders';
import OrderDetails from './orders/OrderDetails';
import ListShells from './shells/ListShells';
import ListShellsCustom from './shells/ListShellsCustom';
import ShellCustomDetail from './shells/ShellCustomDetail';
import ListCollections from './products/ListCollections';
import SettingsTaxRates from './settings/SettingsTaxRates';
import DiffusionListPages from './diffusion/DiffusionListPages';
import SettingsGroupRates from './settings/SettingsGroupRates';
import EngineAssetsInfoManagements from './engine/EngineAssetsInfoManagements';
import EngineAssetsFooterManagements from './engine/EngineAssetsFooterManagements';

import ListRestaurants from './restaurant/restaurants/ListRestaurants';
import RestaurantDetails from './restaurant/restaurants/RestaurantDetails';
import ListDemands from './restaurant/demands/ListDemands';
import SettingsTemplate from './settings/SettingsTemplate';
import EngineAssetsDetailsNotifications from './engine/EngineAssetsDetailsNotifications';
import SettingsUsersGroups from './settings/SettingsUsersGroups';
import EngineAssetsDetailsMerchandising from './engine/EngineAssetsDetailsMerchandising';
import EngineAssetsDetailsMerchandisingDetail from './engine/EngineAssetsDetailsMerchandisingDetail';
import EngineAssetsCacheCategories from './engine/EngineAssetsCacheCategories';
import ProductsCoverRate from './products/ProductsCoverRate';
import ProductsCoverRateDetail from './products/ProductsCoverRateDetail';
import EngineAssetsDetailsCrosssell from './engine/EngineAssetsDetailsCrosssell';
import { checkPath } from 'js/utils/resetPaginationLocalStorage';
import ImportHistoryProduct from './settings/ImportHistoryProduct';
import HistoryDashboard from './settings/HistoryDashboard';
import LayerBreadCrumb from '../../navigation/LayerBreadCrumb';
import PriceCompare from './products/PriceCompare';
import EngineAssetsDetailsTopMessages from './engine/EngineAssetsDetailsTopMessages';
import EngineAssetsDetailsDiscounts from './engine/EngineAssetsDetailsDiscounts';
import BrandsDetail from './centralisation/brands/BrandDetails/BrandsDetail';
import ListNewProducts from './products/ListNewProducts';
import ListProductsDelta from './products/ListProductsDelta';
import ListProductsFilter from './products/ListProductsFilter';

import ListProductsImport from './products/ListProductsImport';
import ListProductWorkflow from './products/ListProductWorkflow';
import ListImportsProducts from './products/ListImportsProducts';
import WorkflowDashboard from './workflow/WorkflowDashboard';
import WorkflowLayout from './workflow/WorkflowLayout';
import Catalogs from './centralisation/catalogs/CatalogsPage/Catalogs';
import Brands from './centralisation/brands/BrandsPage/Brands';
import Products from './centralisation/products/ProductsPage/Products';
import CatalogDetail from './centralisation/catalogs/CatalogDetails/CatalogDetail';
import HomepageNew from './homepage/HomepageNew';
import Imports from './centralisation/imports/Imports';
import NotFound from './notFound/NotFound';
import PlaceholderView from '../../layouts/PlaceholderView/PlaceholderView';
import Configuration from './centralisation/configurations/Configuration/Configuration';
import GuidelineDetails from './brand/GuidelineDetails/GuidelineDetails';
import ProductDetailNew from './centralisation/products/ProductDetails/ProductDetailNew';
import ProductsCompletudeNew from './products/ProductsCompletudeNew';
import AttributesProductsNew from './attributs/AttributesProductsNew';
import AttributesDetails from './attributs/AttributsDetails';
import Settings from './settings/Settings';
import Eshop from './diffusion/Eshop';
import { ROUTE_DIFFUSION_NEW_ASSETS, ROUTE_DIFFUSION_NEW_COVERRATE, ROUTE_PRODUCTS_BUNDLES, ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_HOME, ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_HOME_CHILDS, ROUTE_SALES_APPLICATION_DETAIL_MERCHANDISING, ROUTE_SALES_APPLICATION_DETAIL_MERCHANDISING_BY_MERCH, ROUTE_SALES_APPLICATION_DETAIL_SHOP_THE_LOOK, ROUTE_SALES_APPLICATION_DETAIL_TOP_MESSAGES } from '../../../js/constants/route-names';
import Bundles from './centralisation/products/Bundles';

function Dashboard(props) {
  const [open, setOpen] = React.useState(window.innerWidth <= 1000 ? false : true);
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  const [typoSystem, setTypoSystem] = React.useState([]);
  const [appBarHeight, setAppBarHeight] = React.useState(0);
  const handleDrawerToggle = () => setOpen(!open);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (window.innerWidth > 1000) {
        setOpen(true)
      }
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  var drawerWidth = 300;

  if (windowSize.width > 1600) {
    open ? drawerWidth = 300 : drawerWidth = 110;
  }
  if (windowSize.width <= 1600 && windowSize.width > 1400) {
    open ? drawerWidth = 240 : drawerWidth = 110;
  }
  else if (windowSize.width <= 1400 && windowSize.width > 1000) {
    open ? drawerWidth = 210 : drawerWidth = 110;
  }
  else if (windowSize.width <= 1000) {
    open ? drawerWidth = 240 : drawerWidth = 0;
  }

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    hide: {
      display: 'none',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      height:appBarHeight + 2
    },
    content: {
      display: 'grid',
      gridTemplateRows: appBarHeight ? `${appBarHeight}px calc(100vh - ${appBarHeight}px)` : null,
      gridTemplateColumns:`100%`,
      flexDirection: 'column',
      // width: `calc(100% - ${drawerWidth}px)`,
      width: `100%`,
      flexGrow: 1,
      height: "100vh",
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      "& > div > div:not(.hidden)": {
        // padding: props.windowWidth <= 1500 ? theme.spacing(3) : theme.spacing(4),
        padding: 16,
      },
      "& > div > h1": {
        paddingLeft: props.windowWidth <= 1500 ? theme.spacing(3) : theme.spacing(4),
        paddingRight: props.windowWidth <= 1500 ? theme.spacing(1) : theme.spacing(2),
        paddingTop: props.windowWidth <= 1500 ? theme.spacing(1) : theme.spacing(2)
      },
      "& > div > div.MuiBox-root": {
        // padding: props.windowWidth <= 1500 ? theme.spacing(3) : theme.spacing(4),
        padding: 16
      },
      "& > div > div.MuiGrid-root": {
        // padding: props.windowWidth <= 1500 ? theme.spacing(3) : theme.spacing(4),
        padding: 16,
      }
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }));
  const classes = useStyles();

  const makeArrayFonts = () => {
    props.client.query({
      query: GET_SYSTEM_TYPOS,
      fetchPolicy: 'no-cache'
    }).then(result => {
      let array = [];
      for (let font of result.data.typographySystems.edges) {
        array.push({ font: font.node.value });
      }
      setTypoSystem(array)
      return (array);
    }).catch((err)=>{
      console.log(err)
    });
  }
  let pathname = props.location.pathname
  let customState = props.location?.state?.customState
  let startBreadcrumb = { url: ROUTE_HOME, identifier: 'drawer.home' }
  checkPath(props)

  function isLoggedIn() {
    let localStorageTokenExpiration = localStorage.getItem('AUTH_TOKEN_EXPIRY');
    let now = new Date();
    let tokenExpiration = new Date(localStorageTokenExpiration);
    if (now < tokenExpiration) {
      return true;
    } else {
      localStorage.removeItem('AUTH_TOKEN');
      localStorage.removeItem('AUTH_TOKEN_EXPIRY');
      localStorage.removeItem('AUTH_USER');
      return "<Redirect to='/login' />"
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {
        pathname !== '/' ?
          <Appbar setAppBarHeight={setAppBarHeight} openCallback={handleDrawerToggle} opened={open} user={props.user} windowWidth={props.windowWidth} drawerWidth={drawerWidth} />
          : null
      }

      <DrawerLeft openCallback={handleDrawerToggle} pathname={pathname} opened={open} windowWidth={props.windowWidth} drawerWidth={drawerWidth} />

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        style={{position:"relative"}}
      >
        {
          pathname !== '/' ?
            <section className={classes.drawerHeader} />
            : null
        }
       
        <Switch render={isLoggedIn}>
          <Route path={`${ROUTE_HOME}`} exact >
            <LayerBreadCrumb bc={[startBreadcrumb]}>
              <HomepageNew user={props.user} windowWidth={props.windowWidth} />
              {/* <Homepage user={props.user} windowWidth={props.windowWidth} /> */}
            </LayerBreadCrumb>
          </Route>

          {/* DASHBOARD */}
          <Route path={`${ROUTE_STATS_ORDERS}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_STATS_ORDERS, identifier: 'drawer.dashboard_statisticsOrders' }]}>
              <DashboardStats windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_STATS_ORDERS_BY_SITE}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_STATS_ORDERS, identifier: 'drawer.dashboard_statisticsOrders' }, { url: ROUTE_STATS_ORDERS_BY_SITE, identifier: 'drawer.dashboard_statisticsOrdersSiteClient' }]}>
              <DashboardStats windowWidth={props.windowWidth} type={"bySite"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_STATS_ORDERS_BY_MARKETPLACES}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_STATS_ORDERS, identifier: 'drawer.dashboard_statisticsOrders' }, { url: ROUTE_STATS_ORDERS_BY_MARKETPLACES, identifier: 'drawer.dashboard_statisticsOrdersMarketplaces' }]}>
              <DashboardStats windowWidth={props.windowWidth} type={"byMarketplace"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_STATS_RETAILERS}`} exact>
            <LayerBreadCrumb bc={[]}>
              <StatsRetailers />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_STATS_ASSETS}`} exact>
            <LayerBreadCrumb bc={[]}>
              <StatsAssets />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_STATS_CAMPAIGNS}`} exact>
            <LayerBreadCrumb bc={[]}>
              <StatsCampaigns />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_STATS_GOOGLE_ANALYTICS}`} exact>
            <LayerBreadCrumb bc={[]}>
              <StatsGoogleAnalytics />
            </LayerBreadCrumb>
          </Route>

          {/* HISTORY */}
          <Route path={`${ROUTE_HISTORY_DASHBOARD}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_HISTORY_DASHBOARD, identifier: 'drawer.dashboard' }]}>
              <HistoryDashboard windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_HISTORY_IMPORT}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_HISTORY_IMPORT, identifier: 'drawer.history_imports' }]}>
              <ImportHistory used="page" />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_HISTORY_IMPORT_PRODUCT}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_HISTORY_IMPORT_PRODUCT, identifier: 'drawer.history_imports_product' }]}>
              <ImportHistoryProduct used="history-product" />
            </LayerBreadCrumb>
          </Route>

          {/* SALES */}
          <Route path={`${ROUTE_SALES_GLOBAL}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_GLOBAL, identifier: 'drawer.sells_global' }]}>
              <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'sales'} title={'sells_global'} icon={"ico_global"} filterName={"global"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_SALES_ESHOP, identifier: 'drawer.diffusion' }, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }]}>
              {/* <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'webshop'} title={'sells_eshop'} icon={"ico_eshop"} filterName={"eshop"} /> */}
              <Eshop drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'webshop'} title={'sells_eshop'} icon={"ico_eshop"} filterName={"eshop"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_SALES_ESHOP, identifier: 'drawer.diffusion' }, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.dashboard' }]}>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATIONS_DETAILS}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_SALES_ESHOP, identifier: 'drawer.diffusion' }, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.dashboard' }]}>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_STATS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_STATS, identifier: 'drawer.statistics' }]}>
              <EngineAssetsDetailsPageStats drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET, identifier: 'drawer.headers' }]}>
              <EngineAssetsDetailsHeaders drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING, identifier: 'drawer.landing' }]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={false} type={"landing"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING, identifier: 'drawer.landing' }, { url: ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS, identifier: 'drawer.contents' }]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={true} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_LANDING}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop'}, {url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop'}, {url: ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_LANDING, identifier: 'drawer.landing' }]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={false} type={"landing"}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_LANDING_CHILDS}`}>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier:'drawer.sells_eshop'}, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_LANDING, identifier: 'drawer.landing'}, {url: ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_LANDING_CHILDS, identifier:'drawer.contents'}]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={true}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING, identifier: 'drawer.home' }]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={false} type={"home"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING, identifier: 'drawer.landing' }, { url: ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS, identifier: 'drawer.contents' }]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={true} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_HOME}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop'}, {url: ROUTE_SALES_APPLICATIONS_DETAILS, identifier:'drawer.detail_eshop'}, {url: ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_HOME, identifier: 'drawer.home'}]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={false} type={"home"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_HOME_CHILDS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop'},{ url: ROUTE_SALES_APPLICATIONS_DETAILS, identifier:'drawer.detail_eshop'},{ url: ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_HOME, identifier:'drawer.landing'}, {url: ROUTE_SALES_APPLICATION_DETAIL_LANDINGS_ASSET_HOME_CHILDS, identifier: 'drawer.contents' }]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={true} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_FOOTER}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_FOOTER, identifier: 'drawer.eshop_footer' }]}>
              <EngineAssetsFooterManagements drawerWidth={drawerWidth} windowWidth={props.windowWidth} /> 
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_FOOTER}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier:'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier:'drawer.detail_eshop' }, {url: ROUTE_SALES_APPLICATION_FOOTER, identifier:'drawer.eshop_footer'}]}>
              <EngineAssetsFooterManagements drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK, identifier: 'drawer.shop_the_look' }]}>
              <ShopTheLook drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_DETAIL_SHOP_THE_LOOK}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_APPLICATION_DETAIL_SHOP_THE_LOOK, identifier: 'drawer.shop_the_look' }]}>
              <ShopTheLook drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING, identifier: 'drawer.merchandising' }]}>
              <EngineAssetsDetailsMerchandising drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_DETAIL_MERCHANDISING}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_APPLICATIONS, identifier: 'drawer.sells_eshop'}, {url: ROUTE_SALES_APPLICATIONS_DETAILS, identifier: 'drawer.detail_eshop'}, {url: ROUTE_SALES_APPLICATION_DETAIL_MERCHANDISING, identifier:'drawer.merchandising'}]}>
              <EngineAssetsDetailsMerchandising type={'application'} drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_DETAIL_MERCHANDISING_BY_MERCH}`}>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_APPLICATIONS, identifier:'drawer.sells_application' }, { url: ROUTE_SALES_APPLICATIONS_DETAILS, identifier:'drawer.detail_eshop' }, {url: ROUTE_SALES_APPLICATION_DETAIL_MERCHANDISING, identifier:'drawer.merchandising'}, { url: ROUTE_SALES_APPLICATION_DETAIL_MERCHANDISING_BY_MERCH, identifier: 'drawer.versions' }]}>
              <EngineAssetsDetailsMerchandisingDetail drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING, identifier: 'drawer.merchandising' }, { url: ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH, identifier: 'drawer.versions' }]}>
              <EngineAssetsDetailsMerchandisingDetail drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_CROSSSELL}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_CROSSSELL, identifier: 'drawer.crossSell' }]}>
              <EngineAssetsDetailsCrosssell drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_DETAIL_CROSSSELL}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_APPLICATIONS, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_APPLICATIONS_DETAILS, identifier:'drawer.detail_eshop'}, {url: ROUTE_SALES_APPLICATION_DETAIL_CROSSSELL, identifier:'drawer.crossSell'}]}>
              <EngineAssetsDetailsCrosssell drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS, identifier: 'drawer.infoManagement' }]}>
              <EngineAssetsInfoManagements drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS, identifier: 'drawer.discounts' }]}>
              <EngineAssetsDetailsDiscounts drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_DETAIL_DISCOUNTS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, {url: ROUTE_SALES_APPLICATIONS, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_APPLICATIONS_DETAILS, identifier:'drawer.details_eshop' }, { url: ROUTE_SALES_APPLICATION_DETAIL_DISCOUNTS, identifier:'drawer.discounts' }]}>
              <EngineAssetsDetailsDiscounts drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_TOP_MESSAGES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_TOP_MESSAGES, identifier: 'drawer.messagesPromo' }]}>
              <EngineAssetsDetailsTopMessages drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_APPLICATION_DETAIL_TOP_MESSAGES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, {url: ROUTE_SALES_APPLICATIONS, identifier: 'drawer.sells_eshop'}, {url: ROUTE_SALES_APPLICATIONS_DETAILS, identifier: 'drawer.detail_eshop'}, {url: ROUTE_SALES_APPLICATION_DETAIL_TOP_MESSAGES, identifier: 'drawer.messagesPromo'}]}>
              <EngineAssetsDetailsTopMessages drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_ESHOP_DETAIL_CACHE_CATEGORIES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_ESHOP, identifier: 'drawer.sells_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL, identifier: 'drawer.detail_eshop' }, { url: ROUTE_SALES_ESHOP_DETAIL_CACHE_CATEGORIES, identifier: 'drawer.clear_cache_categories' }]}>
              <EngineAssetsCacheCategories drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_DIFFUSION_NEW_ASSETS}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_SALES_ESHOP, identifier: 'drawer.diffusion' }, { url: ROUTE_SALES_APPLICATIONS, identifier: 'drawer.applications' }]}>
              <Eshop drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'application'} filterBy={'application'} title={'outputAsset'} icon={"ico_eshop"} filterName={"applications"} />
              {/* <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'online'} filterBy={'application'} title={'applications'} icon={"ico_eshop"} filterName={"applications"} /> */}
            </LayerBreadCrumb>
          </Route>
          {/* <Route path={`${ROUTE_SALES_APPLICATIONS_DETAILS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_APPLICATIONS, identifier: 'drawer.applications' }, { url: ROUTE_SALES_APPLICATIONS_DETAILS, identifier: 'drawer.dashboard' }]}>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route> */}
          <Route path={`${ROUTE_SALES_APPLICATIONS_NOTIFICATIONS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_APPLICATIONS, identifier: 'drawer.applications' }, { url: ROUTE_SALES_APPLICATIONS_NOTIFICATIONS, identifier: 'drawer.notifications' }]}>
              <EngineAssetsDetailsNotifications drawerWidth={drawerWidth} windowWidth={props.windowWidth} icon={"ico-notifications"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_SOCIAL_COMMERCE}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_SOCIAL_COMMERCE, identifier: 'drawer.sells_social_commerce' }]}>
              <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'connector'} title={'sells_social_commerce'} icon={"ico_social-commerce"} filterName={"social-commerce"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_SOCIAL_COMMERCE_DETAILS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_SOCIAL_COMMERCE, identifier: 'drawer.sells_social_commerce' }, { url: ROUTE_SALES_SOCIAL_COMMERCE_DETAILS, identifier: 'drawer.dashboard' }]}>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_MARKETPLACES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_MARKETPLACES, identifier: 'drawer.marketplaces' }]}>
              <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'marketplace'} title={'marketplaces'} icon={"ico_marketplace"} filterName={"marketplace"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_MARKETPLACES_DETAILS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_MARKETPLACES, identifier: 'drawer.marketplaces' }, { url: ROUTE_SALES_MARKETPLACES_DETAILS, identifier: 'drawer.dashboard' }]}>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_SHOPS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_SHOPS, identifier: 'drawer.shops' }]}>
              <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'instore'} title={'shops'} icon={"ico_magasin"} filterName={"shop"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_SHOPS_DETAILS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_SHOPS, identifier: 'drawer.shops' }, { url: ROUTE_SALES_SHOPS_DETAILS, identifier: 'drawer.dashboard' }]}>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SALES_RETAILERS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SALES_RETAILERS, identifier: 'drawer.retailers' }]}>
              <ListRetailers windowWidth={props.windowWidth} drawerWidth={drawerWidth} isInternal={false} type="retailer" />
            </LayerBreadCrumb>
          </Route>

          {/* COMMUNITIES */}
          <Route path={`${ROUTE_COMMUNITIES_GAMES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_GAMES, identifier: 'drawer.community_contests' }]}>
              <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'online'} filterBy={'games'} title={'community_contests'} icon={"ico_jeux-concours"} filterName={"game"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_GAMES_DETAIL}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_GAMES, identifier: 'drawer.community_contests' }, { url: ROUTE_COMMUNITIES_GAMES_DETAIL, identifier: 'drawer.dashboard' }]}>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_GAMES_DIFFUSION}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_GAMES, identifier: 'drawer.community_contests' }, { url: ROUTE_COMMUNITIES_GAMES_DIFFUSION, identifier: 'drawer.manage_games' }]}>
              <DiffusionPermanentPosts />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_GAMES_DIFFUSION_PAGE}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_GAMES, identifier: 'drawer.community_contests' }, { url: ROUTE_COMMUNITIES_GAMES_DIFFUSION, identifier: 'drawer.manage_games' }, { url: ROUTE_COMMUNITIES_GAMES_DIFFUSION_PAGE, identifier: 'spread.active_assets.pageDetails' }]}>
              <EngineAssetsDetailsPage windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_GAMES_DETAIL_STATS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_GAMES, identifier: 'drawer.community_contests' }, { url: ROUTE_COMMUNITIES_GAMES_DETAIL_STATS, identifier: 'drawer.statistics' }]}>
              <EngineAssetsDetailsPageStats drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_MINISITES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_MINISITES, identifier: 'drawer.community_minisites' }]}>
              <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'online'} title={'community_minisites'} icon={"ico_web"} filterName={"minisite"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_MINISITES_DETAIL}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_MINISITES, identifier: 'drawer.community_minisites' }, { url: ROUTE_COMMUNITIES_MINISITES_DETAIL, identifier: 'drawer.dashboard' }]}>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_MINISITES_DIFFUSION}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_MINISITES, identifier: 'drawer.community_minisites' }, { url: ROUTE_COMMUNITIES_MINISITES_DIFFUSION, identifier: 'drawer.manage_pages' }]}>
              <DiffusionPermanentPosts />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_MINISITES, identifier: 'drawer.community_minisites' }, { url: ROUTE_COMMUNITIES_MINISITES_DIFFUSION, identifier: 'drawer.manage_pages' }, { url: ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE, identifier: 'spread.active_assets.pageDetails' }]}>
              <EngineAssetsDetailsPage windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_MINISITES_DETAIL_STATS}`} exact>
            <LayerBreadCrumb bc={[]}>
              <EngineAssetsDetailsPageStats drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_NEWSLETTERS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_NEWSLETTERS, identifier: 'drawer.community_newsletters' }]}>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="newsletter" />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_SOCIALS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_SOCIALS, identifier: 'drawer.social_networks' }]}>
              <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'social'} title={'social_networks'} icon={"ico_rs"} filterName={"social"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_SOCIALS_DETAIL}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_SOCIALS, identifier: 'drawer.social_networks' }, { url: ROUTE_COMMUNITIES_SOCIALS_DETAIL, identifier: 'drawer.dashboard' }]}>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_PERMANENTS_POSTS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_SOCIALS, identifier: 'drawer.social_networks' }, { url: ROUTE_COMMUNITIES_PERMANENTS_POSTS, identifier: 'drawer.spread' }]}>
              <DiffusionPermanentPosts />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_PERMANENTS_ADD_POST}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_SOCIALS, identifier: 'drawer.social_networks' }, { url: ROUTE_COMMUNITIES_PERMANENTS_POSTS, identifier: 'drawer.spread' }, { url: ROUTE_COMMUNITIES_PERMANENTS_ADD_POST, identifier: 'spread.campaigns.addPost' }]}>
              <DiffusionPermanentAddPost drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_CMS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_CMS, identifier: 'drawer.cms' }]}>
              <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeList={'cms'} title={'cms'} icon={"ico_bookmarks"} filterName={"cms"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_CMS_DETAIL}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_CMS, identifier: 'drawer.cms' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL, identifier: 'drawer.dashboard' }]}>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_CMS, identifier: 'drawer.cms' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL, identifier: 'drawer.detail_cms' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING, identifier: 'drawer.landing' }]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={false} route={ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS} type={"landing"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_CMS, identifier: 'drawer.cms' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL, identifier: 'drawer.detail_cms' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING, identifier: 'drawer.landing' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS, identifier: 'drawer.contents' }]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={true} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_CMS, identifier: 'drawer.cms' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL, identifier: 'drawer.detail_cms' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER, identifier: 'drawer.footer' }]}>              
              <EngineAssetsFooterManagements drawerWidth={drawerWidth} windowWidth={props.windowWidth} /> 
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER_CHILDS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_COMMUNITIES_CMS, identifier: 'drawer.cms' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL, identifier: 'drawer.detail_cms' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER, identifier: 'drawer.footer' }, { url: ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER_CHILDS, identifier: 'drawer.contents' }]}>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={true} />
            </LayerBreadCrumb>
          </Route>

          {/* CATALOGS */}
          <Route path={`${ROUTE_CATALOGS}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_CATALOGS, identifier: 'drawer.centralisation' }, { url: ROUTE_CATALOGS, identifier: 'drawer.catalogs' }]}>
              <Catalogs drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
              {/* <DashboardCatalogs drawerWidth={drawerWidth} windowWidth={props.windowWidth} /> */}
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CATALOGS_LIST}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_CATALOGS, identifier: 'drawer.centralisation' }, { url: ROUTE_CATALOGS_LIST, identifier: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.catalogs' : 'drawer.warehouses' }]}>
              {/* <ListCatalogs drawerWidth={drawerWidth} windowWidth={props.windowWidth} /> */}
              <Catalogs drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CATALOGS_DETAIL}`} exact>
            <LayerBreadCrumb attributesCheck={false} bc={[{ url: ROUTE_CATALOGS_LIST, identifier: 'drawer.centralisation' }, { url: ROUTE_CATALOGS_LIST, identifier: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.catalogs' : 'drawer.warehouses' }, { url: ROUTE_CATALOGS_DETAIL, identifier: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'catalogs.detail' : 'catalogs.detailWarehouse' }]}>
              {/* <CatalogsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} /> */}
              <CatalogDetail drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CENTRALISATION_CONFIGURATION}`} exact>
            <LayerBreadCrumb attributesCheck={false} bc={[{ url: ROUTE_CATALOGS_LIST, identifier: 'drawer.centralisation' }, { url: ROUTE_CENTRALISATION_CONFIGURATION, identifier: "Configurations" }]}>
              <Configuration drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>

          {/* PRODUCTS */}
          <Route path={`${ROUTE_PRODUCTS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_CATALOGS, identifier: 'drawer.dashboard' }]}>
              {/* <DashboardProducts windowWidth={props.windowWidth} /> */}
              <DashboardStats windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_ADD}`} exact>
            <LayerBreadCrumb attributesCheck={false} bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_LIST, identifier: 'drawer.products' }, { url: ROUTE_PRODUCTS_ADD, identifier: 'products.list.topbar.add' }]}>
              <ProductAdd drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_LIST}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_PRODUCTS_LIST, identifier: 'drawer.centralisation' }, { url: ROUTE_PRODUCTS_LIST, identifier: 'drawer.products' }]}>
              {/* <ListProducts windowWidth={props.windowWidth} /> */}
              <Products windowWidth={props.windowWidth}/>
            </LayerBreadCrumb>
          </Route>
          {/* <Route path={`${ROUTE_PRODUCTS_BUNDLES}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_PRODUCTS_LIST, identifier: 'drawer.centralisation' }, { url: ROUTE_PRODUCTS_BUNDLES, identifier: 'drawer.bundles' }]}>
              <Bundles windowWidth={props.windowWidth}/>
            </LayerBreadCrumb>
          </Route> */}
          <Route path={`${ROUTE_PRODUCTS_LIST_DETAIL}`} exact>
            <LayerBreadCrumb attributesCheck={false} bc={[{ url: ROUTE_PRODUCTS_LIST, identifier: 'drawer.centralisation' }, { url: ROUTE_PRODUCTS_LIST, identifier: 'drawer.products' }, { url: ROUTE_PRODUCTS_LIST_DETAIL, identifier: 'drawer.detail_product' }]}>
              <ProductDetailNew drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_DIFFUSION_NEW_COVERRATE}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_SALES_ESHOP, identifier: 'drawer.diffusion' }, { url: ROUTE_PRODUCTS_COVER_RATE, identifier: 'drawer.products_cover_rate' }]}>
              <ProductsCoverRate drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_COVER_RATE_DETAIL}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_COVER_RATE, identifier: 'drawer.products_cover_rate' }, { url: ROUTE_PRODUCTS_COVER_RATE_DETAIL, identifier: 'drawer.detail_product' }]}>
              <ProductsCoverRateDetail drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_BRANDS_LIST}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_PRODUCTS_BRANDS_LIST, identifier: 'drawer.centralisation' }, { url: ROUTE_PRODUCTS_BRANDS_LIST, identifier: 'drawer.brands' }]}>
              <Brands drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_BRANDS_DETAIL}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_BRANDS_LIST, identifier: 'drawer.brands' }, { url: ROUTE_PRODUCTS_BRANDS_DETAIL, identifier: 'products.brands.detail_brand' }]}>
              <BrandsDetail drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
              {/* <BrandsDetail drawerWidth={drawerWidth} windowWidth={props.windowWidth} /> */}
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_IMPORTS}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_PRODUCTS_IMPORTS, identifier: 'products.imports.imports' }]}>
              <Imports drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_NEW}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_NEW, identifier: 'drawer.new' }]}>
              <ListNewProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_DELTA}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_DELTA, identifier: 'drawer.products_delta' }]}>
              <ListProductsDelta drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_LISTING}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_NEW, identifier: 'drawer.new' }]}>
              <ListImportsProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_MANUALLY}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_DELTA, identifier: 'drawer.products_delta' }]}>
              <ListProductWorkflow type={"manually"} drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_TO_VERIFY}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_DELTA, identifier: 'drawer.products_delta' }]}>
              <ListProductWorkflow type={"to_verify"} drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_TO_VALIDATE}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_DELTA, identifier: 'drawer.products_delta' }]}>
              <ListProductWorkflow type={"to_validate"} drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>


          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE, identifier: 'drawer.workflow_products_to_complete' }]}>
              <ListProductsFilter drawerWidth={drawerWidth} windowWidth={props.windowWidth} productTarget="workflow_products_to_complete" />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_DELAYED}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_DELAYED, identifier: 'drawer.workflow_products_delayed' }]}>
              <ListProductsFilter drawerWidth={drawerWidth} windowWidth={props.windowWidth} productTarget="workflow_products_delayed" />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_ERRORS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_ERRORS, identifier: 'drawer.workflow_products_errors' }]}>
              <ListProductsFilter drawerWidth={drawerWidth} windowWidth={props.windowWidth} productTarget="workflow_products_errors" />
            </LayerBreadCrumb>
          </Route>

          <Route path={`${ROUTE_PRODUCTS_PRICE_COMPARE}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_PRICE_COMPARE_DASHBOARD, identifier: 'drawer.dashboard' }]}>
              <PriceCompare drawerWidth={drawerWidth} windowWidth={props.windowWidth} type={"dashboard"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_PRICE_COMPARE_LIST}`} exact>
            <LayerBreadCrumb attributesCheck={true} bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_PRICE_COMPARE_LIST, identifier: 'drawer.products_price_compare' }]}>
              <PriceCompare drawerWidth={drawerWidth} windowWidth={props.windowWidth} type={"list"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL}`} exact>
            <LayerBreadCrumb attributesCheck={true} bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL, identifier: 'drawer.products_price_compare_detail' }]}>
              <PriceCompare drawerWidth={drawerWidth} windowWidth={props.windowWidth} type={"detail"} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_SETTINGS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_SETTINGS, identifier: 'drawer.configuration' }]}>
              <SettingsTemplate drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeSettings={'products'} />
            </LayerBreadCrumb>
          </Route>
          {/* <Route path={`${ROUTE_PRODUCTS_ATTRIBUTS}`} exact>
                <AttributesProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route> */}
          {/* <Route path={`${ROUTE_PRODUCTS_CONNECTORS}`} exact>
              <ProductsConnectors windowWidth={props.windowWidth}/>
            </Route> */}
          <Route path={`${ROUTE_PRODUCTS_COLLECTIONS}`} exact>
            <LayerBreadCrumb bc={[]}>
              <ListCollections windowWidth={props.windowWidth} drawerWidth={drawerWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_CATEGORIES}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_PRODUCTS_SETTINGS, identifier: 'Configuration' }, { url: ROUTE_PRODUCTS_CATEGORIES, identifier: 'drawer.categories' }]}>
              <ProductsCategories drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_ATTRIBUTS}`} exact>
            <LayerBreadCrumb attributesCheck={true} bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_PRODUCTS_SETTINGS, identifier: 'Configuration' }, { url: ROUTE_PRODUCTS_ATTRIBUTS, identifier: 'drawer.attributes' }]}>
              {/* <AttributesProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeAttributs={'product'} /> */}
              <AttributesProductsNew drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeAttributs={'product'} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_ATTRIBUT}`} exact>
            <LayerBreadCrumb attributesCheck={true} bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_PRODUCTS_SETTINGS, identifier: 'Configuration' }, { url: ROUTE_PRODUCTS_ATTRIBUTS, identifier: 'Groupes d\'attributs' }, { url: ROUTE_PRODUCTS_ATTRIBUT, identifier: 'drawer.attributes' }]}>
              <AttributesDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeAttributs={'product'} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_COMPLETUDE}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_PRODUCTS_SETTINGS, identifier: 'Configuration' }, { url: ROUTE_PRODUCTS_COMPLETUDE, identifier: 'drawer.completeness' }]}>
              {/* <ProductsCompletude windowWidth={props.windowWidth} drawerWidth={drawerWidth} /> */}
              <ProductsCompletudeNew windowWidth={props.windowWidth} drawerWidth={drawerWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW}`} exact >
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW, identifier: 'drawer.workflow' },{ url: ROUTE_PRODUCTS_WORKFLOW, identifier: 'drawer.workflow_dashboard' }]}>
              <WorkflowDashboard windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_SINFIN}`} exact >
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW, identifier: 'drawer.workflow' },{ url: ROUTE_PRODUCTS_WORKFLOW, identifier: 'workflow.workflow_sinfin' }]}>
              <WorkflowLayout windowWidth={props.windowWidth} identifier={"sinfin"}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_PIM}`} exact >
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW, identifier: 'drawer.workflow' },{ url: ROUTE_PRODUCTS_WORKFLOW, identifier: 'workflow.workflow_pim' }]}>
              <WorkflowLayout windowWidth={props.windowWidth} identifier={"pim"}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_GAIA}`} exact >
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW, identifier: 'drawer.workflow' },{ url: ROUTE_PRODUCTS_WORKFLOW, identifier: 'workflow.workflow_gaia' }]}>
              <WorkflowLayout windowWidth={props.windowWidth} identifier={"gaia"}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_TP}`} exact >
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW, identifier: 'drawer.workflow' },{ url: ROUTE_PRODUCTS_WORKFLOW, identifier: 'workflow.workflow_tp' }]}>
              <WorkflowLayout windowWidth={props.windowWidth} identifier={"tp"}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_TASKS}`} exact >
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_ACTIVITY, identifier: 'drawer.configuration' }]}>
              <WorkflowLayout windowWidth={props.windowWidth} identifier={"tasks"}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_TASKS_CONTRIBUTOR}`} exact >
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_ACTIVITY, identifier: 'drawer.configuration' }]}>
              <WorkflowLayout windowWidth={props.windowWidth} identifier={"tasks_contributor"}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_PRODUCTS_WORKFLOW_ACTIVITY}`} exact >
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_WORKFLOW_ACTIVITY, identifier: 'drawer.configuration' }]}>
              <WorkflowLayout windowWidth={props.windowWidth} identifier={"activity"}/>
            </LayerBreadCrumb>
          </Route>


          {/* MEDIAS */}
          <Route path={`${ROUTE_MEDIAS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_MEDIAS, identifier: 'drawer.dashboard' }]}>
              <DashboardMedias windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_MEDIAS_LIST}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_MEDIAS_LIST, identifier: 'drawer.medias' }]}>
              <ListMedias windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_MEDIAS_SETTINGS}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_MEDIAS, identifier: 'Configuration' }]}>
              <SettingsTemplate drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeSettings={'medias'} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_MEDIAS_CATEGORIES}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_MEDIAS_SETTINGS, identifier: 'Configuration' }, { url: ROUTE_MEDIAS_CATEGORIES, identifier: 'drawer.categories' }]}>
              <MediasCategories />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_MEDIAS_TAGS}`} exact >
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_MEDIAS_SETTINGS, identifier: 'Configuration' }, { url: ROUTE_MEDIAS_TAGS, identifier: 'drawer.tags' }]}>
              <MediasTags windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>

          {/* CONTENU */}
          <Route path={`${ROUTE_CONTENU}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_CONTENU, identifier: 'drawer.dashboard' }]}>
              <DashboardContents windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CONTENU_LIST}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_CONTENU_LIST, identifier: 'drawer.content' }]}>              
              <ListContents windowWidth={props.windowWidth}/>
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CONTENU_LIST_DETAIL}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_CONTENU_LIST, identifier: 'drawer.content' }, { url: ROUTE_CONTENU_LIST_DETAIL, identifier: 'drawer.detail_content' }]}>
              <ContentDetail drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CONTENU_ADD}`} exact>
            <LayerBreadCrumb bc={[]}>
              <ContentAddNew drawerWidth={drawerWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CONTENU_SETTINGS}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_CONTENU_SETTINGS, identifier: 'Configuration' }]}>
              <SettingsTemplate drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeSettings={'contents'} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CONTENU_ATTRIBUTES}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_CONTENU_SETTINGS, identifier: 'Configuration' }, { url: ROUTE_CONTENU_ATTRIBUTES, identifier: 'drawer.models' }]}>
              {/* <ContentsAttributes drawerWidth={drawerWidth} windowWidth={props.windowWidth} /> */}
              <AttributesProductsNew drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeAttributs={'content'} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CONTENT_ATTRIBUT}`} exact>
            <LayerBreadCrumb attributesCheck={true} bc={[startBreadcrumb, { url: ROUTE_PRODUCTS_SETTINGS, identifier: 'drawer.configuration' }, { url: ROUTE_PRODUCTS_ATTRIBUTS, identifier: 'Groupes d\'attributs' }, { url: ROUTE_PRODUCTS_ATTRIBUT, identifier: 'drawer.attributes' }]}>
              <AttributesDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeAttributs={'content'} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CONTENU_CATEGORIES}`} exact>
            <LayerBreadCrumb bc={[{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' }, { url: ROUTE_CONTENU_SETTINGS, identifier: 'Configuration' }, { url: ROUTE_CONTENU_CATEGORIES, identifier: 'drawer.categories' }]}>
              <ContentsCategories drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>

          {/* SHELLS */}
          <Route path={`${ROUTE_SHELLS}`} exact>
          </Route>
          <Route path={`${ROUTE_SHELLS_LIST}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SHELLS_LIST, identifier: 'drawer.manage_shells' }]}>
              <ListShells drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SHELLS_CUSTOM_LIST}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SHELLS_CUSTOM_LIST, identifier: 'drawer.manage_shell' }]}>
              <ListShellsCustom drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SHELLS_CUSTOM_ATTRIBUTES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SHELLS_CUSTOM_ATTRIBUTES, identifier: 'spread_builder.asset_view.manageAttributes' }]}>
              <AttributesProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth} customAttributes={true} typeAttributs={customState?.alias} noGroup={true} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SHELLS_CUSTOM_DETAIL}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SHELLS_LIST, identifier: 'drawer.manage_shells' }, { url: ROUTE_SHELLS_CUSTOM_DETAIL, identifier: 'drawer.manage_shell_code' }]}>
              <ShellCustomDetail drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>

          {/* CHANNELS */}
          <Route path={`${ROUTE_CHANNELS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_CHANNELS, identifier: 'drawer.dashboard' }]}>
              <DashboardChannels drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CHANNELS_CONNECTORS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_CHANNELS_CONNECTORS, identifier: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.connectors_custom' : 'drawer.coordinators' }]}>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="connector" />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CHANNELS_BRANDS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_CHANNELS_BRANDS, identifier: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.brands' : "drawer.erp" }]}>
              <ListRetailers windowWidth={props.windowWidth} drawerWidth={drawerWidth} isInternal={false} type="brand" />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CHANNELS_MARKETPLACES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_CHANNELS_MARKETPLACES, identifier: 'drawer.marketplaces' }]}>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="marketplace" />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CHANNELS_CMS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_CHANNELS_CMS, identifier: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.cms' : "drawer.sells" }]}>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="cms" />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CHANNELS_SOCIALS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_CHANNELS_SOCIALS, identifier: 'drawer.social_networks' }]}>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="social" />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CHANNELS_CONTENTS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_CHANNELS_CONTENTS, identifier: 'drawer.contents' }]}>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="content" />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_CHANNELS_MEDIAS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_CHANNELS_MEDIAS, identifier: 'drawer.medias' }]}>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="media" />
            </LayerBreadCrumb>
          </Route>

          {/* SETTINGS */}
          <Route path={`${ROUTE_SETTINGS_GUIDELINE}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SETTINGS_GUIDELINE, identifier: 'drawer.guideline' }]}>
              <GuidelineBrand drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
              {
                typoSystem.length > 0 ? (
                  <GoogleFontLoader fonts={makeArrayFonts()} />
                ) : null
              }
            </LayerBreadCrumb>
          </Route>
          {/* <Route path={`${ROUTE_SETTINGS_GUIDELINE_ADD}`} exact>
            <LayerBreadCrumb bc={[]}>
              <GuidelineAdd drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
              {
                typoSystem.length > 0 ? (
                  <GoogleFontLoader fonts={makeArrayFonts()} />
                ) : null
              }
            </LayerBreadCrumb>
          </Route> */}
          <Route path={`${ROUTE_SETTINGS_GUIDELINE_UPDATE}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SETTINGS_GUIDELINE, identifier: 'drawer.guideline' }]}>
              <GuidelineDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
              {
                typoSystem.length > 0 ? (
                  <GoogleFontLoader fonts={makeArrayFonts()} />
                ) : null
              }
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SETTINGS_USERS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SETTINGS_USERS, identifier: 'drawer.manage_users' }]}>
              <SettingsUsers drawerWidth={drawerWidth} windowWidth={props.windowWidth} user={props.user} />
            </LayerBreadCrumb>
          </Route>
          {/* <Route path={`${ROUTE_SETTINGS_DETAIL_USER}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SETTINGS_USERS, identifier: 'drawer.manage_users' }, { url: ROUTE_SETTINGS_DETAIL_USER, identifier: 'drawer.detail_user' }]}>
              <UserDetail drawerWidth={drawerWidth} user={props.user} />
            </LayerBreadCrumb>
          </Route> */}
          <Route path={`${ROUTE_SETTINGS_USERS_GROUPS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SETTINGS_USERS_GROUPS, identifier: 'drawer.settings_manage_users_groups' }]}>
              <SettingsUsersGroups drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SETTINGS_ACCESS}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SETTINGS_ACCESS, identifier: 'drawer.settings_manage_accesses' }]}>
              <SettingsAccess />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SETTINGS_SUBSCRIPTIONS}`} exact>
            <SettingsSubscriptions />
          </Route>

          {/* SYSTEM */}
          <Route path={`${ROUTE_SYSTEM}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SYSTEM, identifier: 'Système' }]}>
              <Settings windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          {/* <Route path={`${ROUTE_SYSTEM_CURRENCIES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SYSTEM_CURRENCIES, identifier: 'drawer.system_currencies' }]}>
              <SettingsCurrencies windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SYSTEM_LANGUAGES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SYSTEM_LANGUAGES, identifier: 'drawer.system_locales' }]}>
              <SettingsLanguages windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SYSTEM_TAXRATES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SYSTEM_TAXRATES, identifier: 'drawer.system_taxrates' }]}>
              <SettingsTaxRates windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route>
          <Route path={`${ROUTE_SYSTEM_GROUPRATES}`} exact>
            <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_SYSTEM_TAXRATES, identifier: 'drawer.system_taxrates' }, { url: ROUTE_SYSTEM_GROUPRATES, identifier: 'settings.taxGroup.title' }]}>
              <SettingsGroupRates windowWidth={props.windowWidth} />
            </LayerBreadCrumb>
          </Route> */}


          <Route path={`${ROUTE_DIFFUSION_CALENDAR}`} exact>
            <Calendar />
          </Route>
          <Route path={`${ROUTE_DIFFUSION_PERMANENT}`} exact>
            <DiffusionPermanent windowWidth={props.windowWidth} />
          </Route>
          <Route path={`${ROUTE_DIFFUSION_CAMPAIGNS}`} exact>
            <DiffusionCampaigns />
          </Route>
          <Route path={`${ROUTE_DIFFUSION_CAMPAIGNS_ADD}`} exact>
            <DiffusionCampaignAdd drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_DIFFUSION_CAMPAIGNS_DETAIL}`} exact>
            <DiffusionCampaignDetail drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_DIFFUSION_RETAILERS}`} exact>
            <DiffusionRetailers />
          </Route>
          <Route path={`${ROUTE_DIFFUSION_SOCIALS}`} exact>
            <DiffusionSocials />
          </Route>

          <Route path={`${ROUTE_ENGINE}`} exact>
            <DashboardEngine drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
          </Route>
          <Route path={`${ROUTE_ENGINE_ASSETS_DETAIL}`} exact>
            <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
          </Route>
          <Route path={`${ROUTE_ENGINE_ASSETS_DETAIL_STATS}`} exact>
            <EngineAssetsDetailsPageStats windowWidth={props.windowWidth} />
          </Route>
          <Route path={`${ROUTE_DIFFUSION_PERMANENTS_PAGE}`} exact>
            <EngineAssetsDetailsPage windowWidth={props.windowWidth} />
          </Route>

          <Route path={`${ROUTE_ORDERS_LIST}`} exact>
            <ListOrders windowWidth={props.windowWidth} />
          </Route>
          <Route path={`${ROUTE_ORDERS_LIST_PREORDER}`} exact>
            <ListOrders windowWidth={props.windowWidth} noStatus={'preorder'} />
          </Route>
          <Route path={`${ROUTE_ORDERS_LIST_DELAY}`} exact>
            <ListOrders windowWidth={props.windowWidth} noStatus={'delayed'} />
          </Route>
          <Route path={`${ROUTE_ORDERS_LIST_RETURN}`} exact>
            <ListOrders windowWidth={props.windowWidth} noStatus={'returned'} />
          </Route>
          <Route path={`${ROUTE_ORDERS_DETAIL}`} exact>
            <OrderDetails drawerWidth={drawerWidth} />
          </Route>

          <Route path={`${ROUTE_ATTRIBUTS}`} exact>

          </Route>

          <Route path={`${ROUTE_ATTRIBUTS_CATEGORIES}`} exact>
            <AttributesProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeAttributs={'category'} noGroup={true} />
          </Route>

          <Route path={`${ROUTE_ATTRIBUTS_POINT}`} exact>
            <AttributesProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeAttributs={'Magasins'} noGroup={true} butInGroup={true} />
          </Route>

          <Route path={`${ROUTE_BRAND_FLOW}`} exact>
            <ListRetailers windowWidth={props.windowWidth} drawerWidth={drawerWidth} isInternal={true} type="flow" />
          </Route>
          <Route path={`${ROUTE_BRAND_FLOW_DETAILS}`} exact>
            <RetailersDetails windowWidth={props.windowWidth} drawerWidth={drawerWidth} isInternal={true} type="flow" />
          </Route>

          <Route path={`${ROUTE_PREFERENCES}`} exact>
            <SettingsPreferences />
          </Route>
          <Route path={`${ROUTE_HELPS}`} exact>
            <SettingsHelps />
          </Route>

          <Route path={`${ROUTE_SETTINGS}`}>
            <Typography variant="body1">
              Paramètres
            </Typography>
          </Route>

          <Route path={`${ROUTE_RESTAURANTS}`} exact>
            <ListRestaurants drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
          </Route>
          <Route path={`${ROUTE_RESTAURANTS_DETAILS}`} exact>
            <RestaurantDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
          </Route>
          <Route path={`${ROUTE_RESTAURANTS_DEMANDS}`} exact>
            <ListDemands drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
          </Route>
          <Route component={PlaceholderView} />
          <Route path={`${ROUTE_OPTIMISATION_DASHBOARD}`} exact>
              <PlaceholderView/>
          </Route>
        </Switch>
      </main>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    setUser: user => dispatch({ type: SET_USER, payload: { user } }),
    setGuideline: guideline => dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),

  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading,
    user: state.user,
    guideline: state.guideline,
  };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));