import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import colors from '../../../config/theme/colors';
import styled from 'styled-components';

const SwitchCustom = styled(Switch)`
    .Mui-checked{
        color: ${colors.blue.lighter.hue150}!important;
        & + .MuiSwitch-track{
            background-color: ${colors.blue.lighter.hue300}!important;
        }
    }
`;

export default function SwitchInput(props) {
    const [checked, setChecked] = useState(props.value);

    const handleInputChange = () => {
        setChecked(!checked);
        props.onChange(!checked);
    };

    return (
        <>
            <Typography gutterBottom style={{fontSize: 14}}>  
                { props.label }
            </Typography>
            <SwitchCustom
                checked={checked}
                onChange={handleInputChange}
                label={props.label}
            />
        </>
    );
}