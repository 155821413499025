import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function merchandisingAddCat(categorie, currentLang) {
    let generalInputs = []
    generalInputs.push(
        {
            type: 'select',
            isContainerized: true,
            label: `Choix de la catégorie`,
            helper: {
                text: `Indiquez la catégorie pour le merchandising`,
                link: false,
            },
            translated: false,
            required: true,
            disabled:true,
            stateName: 'merchandisingCategory',
            value: [
                {
                    value : categorie.id,
                    label : categorie.libelle
                }
            ]
        },
        {
            required : true,
            type : 'text',
            label : 'Libellé de la catégorie',
            stateName : 'merchandisingCatLibelle',
            helper: {
                text: 'Nom de la catégorie',
                link: false,
            },
        }, 
        {
            type: 'number',
            label: 'Position',
            helper: {
                text: 'Position de la catégorie',
                link: false,
            },
            required: true,
            stateName: 'merchandisingCatPosition',
        },
    )
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm:'Créer une catégorie',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour créer votre catégorie`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: 'Créer cette catégorie',
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations générales',
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}