import { withApollo } from "@apollo/client/react/hoc";
import fakeImage from "@assets/images/image-fake.png";
import colors from "@config/theme/colors";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import {
  ALERT_ERROR,
  ALERT_SUCCESS,
} from "@constants/alert-types";
import { ROUTE_PRODUCTS_LIST } from "@constants/route-names";
import AccordionCustom from "@layouts/Accordion/AccordionCustom";
import OurDrawer from "@layouts/Drawer/OurDrawer";
import TopPanel from "@layouts/TopPanel/TopPanel";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import { Grid } from "@mui/material";
import { GET_CATEGORIES_LIGHT_2 } from "@queries/categories";
import {
  ADD_PRODUCT,
  ADD_PRODUCT_DATA,
  DELETE_PRODUCT,
  DELETE_PRODUCT_DATA,
  GET_PRODUCT_DATA_LIMITED,
  GET_PRODUCT_DATA_LIMITED_WITHOUT_CATEGORIES,
  GET_PRODUCT_SOURCES,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_DATA,
  GET_RETAILER_PRODUCT_DATA,
} from "@queries/products";
import { eventService } from "@services/event.service";
import ProductForm from "@ui/form/ProductForm/ProductForm";
import ReturnLink from "@ui/link/ReturnLink";
import PageLoader from "@ui/loadings/page-loader/PageLoader";
import LogsActions from "@ui/logs-actions/LogsActions";
import { goTo } from "@utils/goTo";
import { isImgUrl } from "@utils/isValidImage";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styled from "styled-components";
import ProductDetailAssociatedProducts from "../components/ProductDetailAssociatedProducts";
import ProductDetailAttributs from "../components/ProductDetailAttributs";
import ProductDetailGeneral from "../components/ProductDetailGeneral";
import ProductDetailSummary from "../components/ProductDetailSummary";
import ProductDetailTaxonomie from "../components/ProductDetailTaxonomie";
import { initCategories } from "../utils/initCategories";
import { initCollections } from "../utils/initCollections";

const ProductsDetailsLayout = styled.div`
  margin-top: 8px;

  height: 0px;
  flex: 1 1 auto;

  & .MuiCollapse-root {
    height: calc(100% - 40px);
  }
`;

const ProductDetailNew = (props) => {
  const [panelHeight, setPanelHeight] = useState(null);
  const [taxos, setTaxos] = useState(false);
  const [anchors, setAnchors] = useState(null);
  const [currentAnchor, setCurrentAnchor] = useState(null);
  const [product, setProduct] = useState(null);
  const [headHeight, setHeadHeight] = useState(null);
  const [groupAttribut, setGroupAttribut] = useState(null);
  const [infoLogs, setInfoLogs] = useState(false);
  const [scrollChange, setScrollChange] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [ready, setReady] = useState(false);
  const productId = `/api/products/${props.match.params.id}`;
  const ref = useRef(null);
  const [images, setImages] = useState([]);
  const [state, setState] = useState({
    allGroups: [],
    categoriesData: [],
    imageAttributes: [],
    currentLang: props.locales[0].node.code,
    metaAttributes: [],
    customAttributes: [],
    imagesSelected: [],
    variantProduct: [],
    variantsValidated: [],
    errors: {},
    categories: [],
    attributes: [],
    currentCollection: null,
    ready: false,
  });

  useEffect(() => {
    initCollections(props.client, setState, state);
    initProduct();
    initCategories(props.client, setState, state);
  }, []);

  useEffect(() => {
    getAnchors();
  }, [taxos, product]);

  const deleteProduct = async (id) => {
    props.startLoading();
    try {
      await props.client.mutate({
        mutation: DELETE_PRODUCT,
        variables: {
          id: productId,
        },
      });
      props.stopLoading();
      props.snack(ALERT_SUCCESS, "Le produit a bien été supprimé");
      goTo(props.history, ROUTE_PRODUCTS_LIST);
    } catch (error) {
      console.log(error);
      props.snack(ALERT_ERROR, "Une erreur est survenue");
    }
  };

  const handleButtonGroupChange = (stateName, value) => {
    setState((prevState) => ({
      ...prevState,
      [stateName]: value,
    }));
  };

  useEffect(() => {
    if (state.ready) {
      initAttributs();
    }
  }, [state.ready]);

  const initAttributs = async () => {
    setReady(false);
    try {
      const { data } = await props.client.query({
        query: GET_PRODUCT_SOURCES,
        variables: {
          product: productId,
        },
      });
      // for (let source of data.data.productSources.edges) {
      //     props.client.query({
      //         query: GET_PRODUCT_SOURCES_PRICES,
      //         variables: {
      //             "product": productId,
      //             "source": source.node.id
      //         }
      //     }).then((result2) => {
      //         source.node.productSourceDatas = source.node.productSourceDatas || {};
      //         source.node.productSourceDatas.edges = source.node.productSourceDatas.edges || [];
      //         source.node.productSourceDatas.edges = source.node.productSourceDatas.edges.concat(result2.data.productSourceDatas.edges);
      //         setState({
      //             ...state,
      //             listSources: data.productSources.edges
      //         });
      //     }).catch((err)=>{
      //         console.log(err)
      //     });
      // }

      const categoriesData = await props.client.query({
        query: GET_CATEGORIES_LIGHT_2,
        variables: {
          exists: [
            {
              catalog: false,
            },
          ],
        },
      });

      let productGroup = props.attributeGroups?.find(
        (e) => e.node.id === groupAttribut.node.id
      )?.node;

      let isSystemAttributes =
        props.attributes.product?.attributes?.edges.filter(
          (e) => e.node.isSystem
        );
      let metaAttributes = props.attributes.product?.attributes?.edges.filter(
        (e) => e.node.isSystem && e.node.identifier.indexOf("meta") > -1
      );
      let customAttributes =
        productGroup?.attributes?.edges?.filter((e) => !e.node.isSystem) ?? [];
      let hasPrice = productGroup?.attributes?.edges?.find(
        (e) => e.node.identifier === "product_price"
      )
        ? true
        : false;
      let imageAttributes = isSystemAttributes
        .filter((e) => e.node.attributeType.input === "image")
        .concat(
          customAttributes.filter((e) => e.node.attributeType.input === "image")
        );

      let attributes = [];

      for (let { node } of state.data.product.productDatas.edges) {
        let found = false;

        for (let attribute of attributes) {
          if (attribute.id === node.attribute.id) {
            found = true;
            // add locale to existing attribute
            attribute.locales.push({
              value:
                node.attribute.attributeType.input === "select"
                  ? node.attributeOption.id
                  : node.value,
              media: node.media,
              id: node.locale.id,
              code: node.locale.code,
              productDataId: node.id,
              type: node.attribute.attributeType.input,
              isLocked: node.isLocked,
            });
          }
        }

        if (!found) {
          // create attribute and add locale
          attributes.push({
            id: node.attribute.id,
            identifier: node.attribute.identifier,
            attributeType: node.attribute.attributeType,
            isSystem: node.attribute.isSystem,
            attributeOptions: node.attributeOptions,
            isLocked: node.isLocked,
            translationLabel: node.attribute.translation.translationDatas.edges,
            locales: [
              {
                value:
                  node.attribute.attributeType.input === "select"
                    ? node.attributeOption?.id
                    : node.value,
                media: node.media,
                id: node.locale.id,
                code: node.locale.code,
                productDataId: node.id,
                type: node.attribute.attributeType.input,
              },
            ],
          });
        }
      }

      let obj = {};

      for (let locale of props.locales) {
        let values = {};
        for (let attribute of attributes) {
          let attributeLocale = attribute.locales.find(
            (e) => e.code === locale.node.code
          );
          // let defaultLocale   = attribute.locales.find(e => e.code === props.locales[0].node.code);
          let defaultLocale = attribute.locales.find((e) => e.code === "fr_FR");
          if (!attributeLocale && defaultLocale) {
            if (attribute.attributeType.input === "select") {
              values[attribute.identifier] = {
                value: defaultLocale?.value ?? attribute.locales[0],
                new: false,
                updated: false,
                isLocked: defaultLocale.isLocked
                  ? defaultLocale.isLocked
                  : false,
              };
            }
            switch (attribute.attributeType.input) {
              case "file":
              case "image":
                values[attribute.identifier] = {
                  value: {
                    id: defaultLocale?.media ? defaultLocale.media.id : null,
                    data: defaultLocale?.media
                      ? defaultLocale.media.filePath
                      : null,
                    file: null,
                    changed: false,
                    type: defaultLocale.media?.type,
                    name: defaultLocale.media?.name,
                  },
                  new: true,
                  updated: false,
                  isLocked: defaultLocale.isLocked
                    ? defaultLocale.isLocked
                    : false,
                };
                break;
              default:
                values[attribute.identifier] = {
                  value: defaultLocale.value,
                  new: true,
                  updated: false,
                  isLocked: defaultLocale.isLocked
                    ? defaultLocale.isLocked
                    : false,
                };
            }
          } else if (attributeLocale) {
            switch (attribute.attributeType.input) {
              case "file":
              case "image":
                values[attribute.identifier] = {
                  value: {
                    id: attributeLocale.media?.id,
                    data: attributeLocale.media
                      ? attributeLocale.media.filePath
                      : null,
                    file: null,
                    changed: false,
                    type: attributeLocale.media?.type,
                    name: attributeLocale.media?.name,
                  },
                  new: false,
                  updated: false,
                  isLocked: attribute.isLocked ? attribute.isLocked : false,
                };
                break;
              default:
                values[attribute.identifier] = {
                  value: attributeLocale.value,
                  new: false,
                  updated: false,
                  isLocked: attributeLocale.isLocked
                    ? attributeLocale.isLocked
                    : false,
                };
            }
          }
        }
        obj[locale.node.code] = values;
      }

      setState((prevState) => ({
        ...prevState,
        isSystemAttributes: isSystemAttributes ?? null,
        metaAttributes: metaAttributes ?? null,
        customAttributes: customAttributes ?? null,
        imageAttributes: imageAttributes ?? null,
        hasPrice: hasPrice ?? null,
        maxImageNumber: imageAttributes.length ?? null,
        readyForCat: true,
        listSources: data.productSources.edges,
        currentSource: data.productSources.edges[0]?.node.id,
        attributes,
        initialAttributes: attributes,
        scrapAttributes: attributes,
        categoriesData: categoriesData.data.categories,
        ...obj,
      }));
      setReady(true);
    } catch (error) {
      console.log(error);
    }
  };

  const initProduct = async () => {
    try {
      const { data } = await props.client.query({
        query: GET_PRODUCT_DATA_LIMITED,
        fetchPolicy: "no-cache",
        variables: {
          id: productId,
          selection:
            state.currentCollection !== "-1" ? state.currentCollection : null,
        },
      });
      let resultWithCategories = await props.client.query({
        query: GET_PRODUCT_DATA_LIMITED_WITHOUT_CATEGORIES,
        fetchPolicy: "no-cache",
        variables: {
          id: productId,
          selection:
            state.currentCollection !== "-1" ? state.currentCollection : null,
        },
      });

      let filterCategoriesCatalog =
        resultWithCategories?.data?.product?.productCategories?.edges
          .filter((e) => e.node.category.catalog === null)
          .map((e) => e.node.category) || [];
      let getCategoriesCatalog =
        resultWithCategories?.data?.product?.productCategories?.edges
          .filter((e) => e.node.category.catalog !== null)
          .map((e) => e.node.category) || [];
      let getimageProduct = data.product?.productDatas.edges.find(
        (e) => e.node.attribute.identifier === "product_image"
      );
      let array = [];

      array.push({ node: data.product?.attributeGroup });

      let children = data.product?.children?.edges ?? [];
      setGroupAttribut(
        props.attributeGroups.find(
          (e) => e.node.id === data.product.attributeGroup.id
        )
      );

      let navisionPrice = 'Aucun';

      if (process.env.REACT_APP_MULTIPLE_PRICE === "true") {
        props.client.query({
          query: GET_RETAILER_PRODUCT_DATA,
          fetchPolicy: "no-cache",
          variables: {
            sku: data.product.sku,
          },
        }).then((skuResult) => {
          const skuPriceResult = skuResult.data.retailerProductDatas.edges[0]?.node.price;
          if (skuPriceResult) {
            navisionPrice = skuPriceResult;
          }
        }).catch((err) => {
          console.log("skuResultError", err);
        })
      }

      setProduct({...data.product, navisionPrice});

      setState((prevState) => ({
        ...prevState,
        sku: data.product?.sku,
        groupAttribut: data.product?.attributeGroup.id,
        categories: filterCategoriesCatalog, // product categories
        allGroups: array,
        status: data.product?.status,
        isVariant:
          children.length || data.product?.superAttribute?.edges?.length > 0
            ? true
            : false,
        defaultImageProduct: getimageProduct?.node.media,
        categoriesCatalog: getCategoriesCatalog,
        fetchedProduct: data.product,
        scrapStatus: data.product.scrapStatus,
        ready: true,
        data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (product) {
      getImages().then((result) => setImages(result));
    }
  }, [product]);

  const handleInputChange = (stateName, evt, custom, translated) => {
    const value = evt?.target?.value ?? evt;
    setValue(stateName, value, custom, translated);
  };

  const setValue = (stateName, value, custom, translated) => {
    if (custom) {
      if (translated) {
        let values = state[props.locales[0].node.code];
        if (!values) {
          values = {};
        }
        if (values[stateName]) {
          if (!values[stateName]?.new) {
            values[stateName][custom] = value;
            values[stateName].updated = true;
          } else {
            values[stateName] = {
              [custom]: value,
              updated: true,
            };
          }
        } else {
          values[stateName] = {
            [custom]: value,
            new: true,
            updated: true,
          };
        }

        setState((prevState) => ({
          ...prevState,
          [props.locales[0].node.code]: values,
        }));
      }
    } else {
      if (stateName === "product_price" && value.includes(",")) {
        value = value.replaceAll(",", ".");
      }
      if (translated) {
        let values = state[props.locales[0].node.code];
        if (!values) {
          values = {};
        }
        if (values[stateName]) {
          if (!values[stateName]?.new) {
            values[stateName].value = value;
            values[stateName].updated = true;
          } else {
            values[stateName] = {
              value,
              updated: true,
              new: true,
            };
          }
        } else {
          values[stateName] = {
            value: value,
            new: true,
            updated: true,
          };
        }

        setState((prevState) => ({
          ...prevState,
          [prevState.currentLang]: values,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [stateName]: value,
        }));
      }
    }
  };

  const getAnchors = () => {
    let anchors = [
      {
        identifier: "product-detail-general",
        label: "Général",
        position:
          document.getElementById("product-detail-general")?.offsetTop - 50 < 0
            ? 0
            : document.getElementById("product-detail-general")?.offsetTop - 50,
      },
      {
        identifier: "product-detail-attributs",
        label: "Attributs",
        position:
          document.getElementById("product-detail-attributs")?.offsetTop - 50 <
          0
            ? 0
            : document.getElementById("product-detail-attributs")?.offsetTop -
              50,
      },
      {
        identifier: "product-detail-seo",
        label: "Google preview",
        position:
          document.getElementById("product-detail-seo")?.offsetTop - 50 < 0
            ? 0
            : document.getElementById("product-detail-seo")?.offsetTop - 50,
      },
    ];
    if (taxos) {
      anchors.push({
        identifier: "product-detail-taxonomie",
        label: "Taxonomies",
        position:
          document.getElementById("product-detail-taxonomie")?.offsetTop - 50 <
          0
            ? 0
            : document.getElementById("product-detail-taxonomie")?.offsetTop -
              50,
      });
    }
    if (product?.suggestions.edges.length > 0) {
      anchors.push({
        identifier: "product-detail-associated-products",
        label: "Produits associés",
        position:
          document.getElementById("product-detail-associated-products")
            ?.offsetTop -
            50 <
          0
            ? 0
            : document.getElementById("product-detail-associated-products")
                ?.offsetTop - 50,
      });
    }
    // anchors.forEach(element => {
    //     let position = document.getElementById(element.identifier)?.offsetTop
    //     element["position"] = position - 50
    // });
    setAnchors(anchors);
    setCurrentAnchor(anchors[0]);
  };

  const getImages = () => {
    let array = product?.productDatas.edges.filter(
      (e) => e.node.attribute.attributeType.input === "image"
    );
    try {
      return Promise.all(
        array.map(async (e) => {
          const isValid = await isImgUrl(
            `${process.env.REACT_APP_MEDIAS}/${e.node?.media?.filePath}`
          );
          if (isValid)
            return `${process.env.REACT_APP_MEDIAS}/${e.node?.media?.filePath}`;
          else return fakeImage;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setHeadHeight(ref?.current.clientHeight);
  }, [ref]);

  const hasErrors = (formError) => {
    if (formError === "firstForm") {
      if (state.errors) {
        for (let error in state.errors) {
          if (state.errors[error]) return true;
        }
      }
    } else {
      if (state.secondErrors) {
        for (let error in state.secondErrors) {
          if (state.secondErrors[error]) return true;
        }
      }
    }
    return false;
  };

  const createVariant = () => {
    return new Promise(async (resolve, reject) => {
      let getChildren = await getVariantForChildren();
      resolve(getChildren);
    });
  };

  const getVariantForChildren = () => {
    return new Promise(async (resolve, reject) => {
      let values = state.variantsValidated.values;
      let getChildren = [];
      for (let i = 0; i < values.length; ++i) {
        let skuVariant = state.sku + "-variant" + (i + 1);
        if (values[i]?.new === true) {
          const ADD_PRODUCT_VARIANT_RESULT = await props.client.mutate({
            mutation: ADD_PRODUCT,
            variables: {
              sku: skuVariant,
              attributeGroup: state.groupAttribut,
              categories: state.categories.map((e) =>
                parseInt(e.id.replace("/api/categories/", ""))
              ),
              createdAt: moment().format("YYYY-MM-DD"),
              updatedAt: moment().format("YYYY-MM-DD"),
              status: state.status,
            },
          });
          await saveAttributesVariants(
            ADD_PRODUCT_VARIANT_RESULT.data.createProduct.product.id,
            values[i]
          );
          let variantCreate =
            ADD_PRODUCT_VARIANT_RESULT.data.createProduct.product.id;
          getChildren.push(variantCreate);
        } else {
          await saveAttributesVariants(values[i].idVariant, values[i], true);
          getChildren.push(values[i].idVariant);
        }
      }
      resolve(getChildren);
    });
  };

  const saveAttributesVariants = (product, variants, update) => {
    return new Promise(async (resolve, reject) => {
      let attributes = state.isSystemAttributes.concat(state.customAttributes);
      let getProductData = [];
      for (let attribute of attributes) {
        // for (let locale of props.locales) {
        let locale = props.locales.find(
          (e) => e.node.code === props.locales[0].node.code
        );
        let formValue = state[locale.node.code][attribute.node.identifier];
        let isMedia =
          attribute.node.attributeType.input === "image" ||
          attribute.node.attributeType.input === "file";
        let isForVariant =
          attribute.node.identifier === "product_price" ||
          attribute.node.identifier === "product_image";
        // let currentTranslation  = getAttributeTranslatedValue(attribute.node.id, locale.node.id);
        if (formValue && isMedia) {
          /*if (!formValue.changed)
                        continue;*/
          isMedia = true;
          /*formValue   = formValue.file;*/
        }
        if (
          variants.allAttr.find((e) => e.id === attribute.node.id) ||
          formValue ||
          isForVariant
        ) {
          if (variants && variants.new) {
            let variables = {
              attributeOption:
                attribute.node.attributeType.input === "select"
                  ? formValue
                  : null,
              product: product,
              attribute: attribute.node.id,
              locale: locale.node.id,
              updatedAt: moment().format("YYYY-MM-DD"),
            };

            if (isMedia) {
              if (variants) {
                variables.media = variants.new
                  ? variants.image.value.id
                  : variants.image.value.data.id;
              } else {
                variables.media = formValue.id;
              }
            }

            if (!isMedia)
              if (variants) {
                for (let newAttr of variants.allAttr) {
                  if (attribute.node.id === newAttr.id) {
                    if (newAttr.type === "select") {
                      variables.attributeOption = newAttr.values;
                    } else {
                      variables.value = newAttr.values;
                    }
                  }
                }
                if (attribute.node.identifier === "product_price") {
                  variables.value = variants.price.value;
                }
              } else {
                variables.value = formValue;
              }
            await props.client.mutate({
              mutation: ADD_PRODUCT_DATA,
              variables,
            });
          } else if (variants.updated) {
            for (let newAttr of variants.allAttr) {
              if (attribute.node.id === newAttr.id) {
                let variables = {
                  id: newAttr.productDataId,
                  attributeOption:
                    attribute.node.attributeType.input === "select"
                      ? newAttr.values
                      : null,
                  product: product,
                  attribute: attribute.node.id,
                  locale: locale.node.id,
                  updatedAt: moment().format("YYYY-MM-DD"),
                };

                if (isMedia) variables.media = formValue.id;

                if (!isMedia) variables.value = newAttr.values;

                await props.client.mutate({
                  mutation: UPDATE_PRODUCT_DATA,
                  variables,
                });
              }
            }
            if (
              attribute.node.identifier === "product_price" &&
              variants.price.id
            ) {
              let variables = {
                id: variants.price.id,
                attributeOption: null,
                product: product,
                attribute: attribute.node.id,
                locale: locale.node.id,
                updatedAt: moment().format("YYYY-MM-DD"),
              };
              variables.value = variants.price.value;
              await props.client.mutate({
                mutation: UPDATE_PRODUCT_DATA,
                variables,
              });
            }
            if (
              attribute.node.identifier === "product_image" &&
              variants.image.id
            ) {
              let variables = {
                id: variants.image.id,
                attributeOption: null,
                product: product,
                attribute: attribute.node.id,
                locale: locale.node.id,
                updatedAt: moment().format("YYYY-MM-DD"),
              };
              variables.media = variants.image.value.id;
              await props.client.mutate({
                mutation: UPDATE_PRODUCT_DATA,
                variables,
              });
            }
          }
        }
        // }
      }

      resolve(getProductData);
    });
  };

  const handlerMutation = async () => {
    try {
      if (hasErrors("firstForm")) {
        props.snack(ALERT_ERROR, "Veuillez vérifier les champs invalides");
        setState((prevState) => ({ ...prevState, seeErrors: true }));
        return eventService.fire();
      }

      props.startLoading();

      let catalogCat = state.categoriesCatalog?.map((e) => e.id);
      let allCategories = state.categories?.map((e) => e.id).concat(catalogCat);

      if (state.isVariant) {
        const newVariants = await createVariant();
        if (state.variantToDelete.length > 0) {
          for (let variantId of state.variantToDelete) {
            await props.client.mutate({
              mutation: DELETE_PRODUCT,
              variables: {
                id: variantId,
              },
            });
          }
        }
        let variables = {
          id: productId,
          attributeGroup: state.groupAttribut,
          categories: allCategories.map((e) =>
            e.replace("/api/categories/", "")
          ),
          status: state.status,
          children: newVariants,
        };
        if (state.selectedProducts) {
          variables.suggestions = state.selectedProducts;
        }
        await props.client.mutate({
          mutation: UPDATE_PRODUCT,
          variables,
        });
      } else {
        let variables = {
          id: productId,
          attributeGroup: state.groupAttribut,
          categories: allCategories.map((e) =>
            e.replace("/api/categories/", "")
          ),
          status: state.status,
        };
        if (state.selectedProducts) {
          variables.suggestions = state.selectedProducts;
        }
        await props.client.mutate({
          mutation: UPDATE_PRODUCT,
          variables,
        });
      }

      await saveAttributes(productId);

      handleSuccess();
    } catch (e) {
      handleError(e);
    }
  };

  // const resetStateProducts = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     importTypeFile: "csv",
  //     importUrl: "",
  //     errors: {},
  //     dataImportLayout: importProductsConfig,
  //     customAttributes: [],
  //     allGroups: [],
  //     categoriesData: [],
  //     metaAttributes: [],
  //     imageAttributes: [],
  //     groupAttribut: "",
  //     seeErrors: false,
  //   }));
  // };

  const saveAttributes = (product) => {
    return new Promise(async (resolve, reject) => {
      let attributes = state.isSystemAttributes.concat(state.customAttributes);
      for (let locale of props.locales) {
        for (let attribute of attributes) {
          let formValue = state[locale.node.code][attribute.node.identifier];
          let currentTranslation = getAttributeTranslatedValue(
            attribute.node.id,
            locale.node.id
          );
          let isMedia =
            attribute.node.attributeType.input === "image" ||
            attribute.node.attributeType.input === "file";
          if (formValue && isMedia) {
            isMedia = true;
          }
          if (formValue && formValue.value) {
            let toCreate = formValue.new && formValue.updated;
            let toUpdate =
              !formValue.new && formValue.updated && currentTranslation;
            if (toUpdate) {
              // UPDATE STEP
              let variables = {
                id: currentTranslation.productDataId,
                attributeOption:
                  attribute.node.attributeType.input === "select"
                    ? formValue.value
                    : null,
                product: product,
                attribute: attribute.node.id,
                isLocked: formValue.isLocked,
                selection:
                  state.currentCollection !== "-1"
                    ? state.currentCollection
                    : null,
                locale: locale.node.id,
              };

              if (isMedia) {
                variables.media = formValue.value.id;
              }

              if (!isMedia)
                if (attribute.node.attributeType.input !== "select") {
                  variables.value = formValue.value;
                }

              await props.client.mutate({
                mutation: UPDATE_PRODUCT_DATA,
                variables,
              });
            } else if (toCreate) {
              // CREATE STEP

              let ADD_PRODUCT_DATA_RESULT = "";
              let variables = {
                attributeOption:
                  attribute.node.attributeType.input === "select"
                    ? formValue.value
                    : null,
                product: product,
                attribute: attribute.node.id,
                locale: locale.node.id,
                isLocked: formValue.isLocked,
                selection:
                  state.currentCollection !== "-1"
                    ? state.currentCollection
                    : null,
                updatedAt: moment().format("YYYY-MM-DD"),
              };

              if (isMedia) {
                variables.media = formValue.value.id;
              }

              if (!isMedia)
                if (attribute.node.attributeType.input !== "select") {
                  variables.value = formValue.value;
                }
              ADD_PRODUCT_DATA_RESULT = await props.client.mutate({
                mutation: ADD_PRODUCT_DATA,
                variables,
              });
            }
          } else if (currentTranslation) {
            // DELETE STEP

            await props.client.mutate({
              mutation: DELETE_PRODUCT_DATA,
              variables: { id: currentTranslation.productDataId },
            });
          }
        }
      }

      resolve();
    });
  };

  const getAttributeTranslatedValue = (id, lang, isScrap = false) => {
    if (isScrap) {
      if (!state.initialAttributes) return null;

      let attribute = state.initialAttributes.find((e) => e.id === id);

      if (!attribute) return null;

      let translation = attribute.locales.find((e) => e.id === lang);

      if (!translation) return null;

      return translation;
    } else {
      if (!state.attributes) return null;

      let attribute = state.attributes.find((e) => e.id === id);

      if (!attribute) return null;

      let translation = attribute.locales?.find((e) => e.id === lang);
      if (!translation) return null;

      return translation;
    }
  };

  const handleSuccess = async () => {
    setOpenForm(!openForm);
    resetState();
    await initProduct();
    props.snack(ALERT_SUCCESS, "Produit modifié !");
    props.stopLoading();
  };

  const resetState = () => {
    setState((prevState) => ({
      ...prevState,
      allGroups: [],
      categoriesData: [],
      imageAttributes: [],
      currentLang: props.locales[0].node.code,
      metaAttributes: [],
      customAttributes: [],
      imagesSelected: [],
      variantProduct: [],
      variantsValidated: [],
      errors: {},
      categories: [],
      attributes: [],
      currentCollection: null,
      ready: false,
      variantToDelete: [],
      variantsProduct: [],
    }));
    setReady(false);
  };

  const handleError = (e) => {
    props.snack(ALERT_ERROR, "Une erreur est survenue");

    props.stopLoading();

    if (e.graphQLErrors) {
      for (let error of e.graphQLErrors) {
        console.error("ERROR", `${error.message} =>`, error.debugMessage);
      }
    }
  };

  const handleToggleDrawer = (drawer) => {
    if (drawer === "form") {
      setState((prevState) => ({
        ...prevState,
        openForm: !state.openForm ?? false,
      }));
    }
    if (drawer === "addVariant") {
      setState((prevState) => {
        if (prevState.variantsValidated?.values?.length > 0) {
          let copyVariantsValidated = [...prevState.variantsValidated.values];

          return {
            ...prevState,
            openFormVariant: !prevState.openFormVariant,
            variantsProduct: copyVariantsValidated,
          };
        }

        return {
          ...prevState,
          openFormVariant: !state.openFormVariant,
          variantsProduct: [],
        };
      });
    }
    setState((prevState) => ({
      ...prevState,
      seeErrors: false,
    }));
  };

  const saveVariant = (drawer) => {
    setState((prevState) => {
      if (prevState.variantsValidated?.values?.length > 0) {
        return {
          ...prevState,
          openFormVariant: !prevState.openFormVariant,
          variantsProduct: [],
          variantsValidated:
            {
              attributes: prevState.attributesSelected,
              values: prevState.variantsValidated.values,
            } ?? null,
        };
      }

      return {
        ...prevState,
        openFormVariant: !state.openFormVariant,
        variantsProduct: [],
      };
    });
  };

  const handleMediaPicker = (selected, stateName) => {
    handleInputChange(
      stateName,
      selected,
      null,
      stateName === "imageCatalog" ? false : true
    );
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        height: `calc(100% + ${panelHeight}px)`,
      }}
    >
      <TopPanel
        getRef
        setRef={setPanelHeight}
        title={props.t("drawer.detail_product")}
        subtitle={"Vue d’ensemble pour gerer le produit dans la plateforme"}
        gradientColor1={colors.menu.regular}
        gradientColor2={colors.menu.darker}
        windowWidth={props.windowWidth}
        locales={props.locales}
        hasBorder={true}
        actions={{
          edit: {
            text: "Modifier",
            handler: () => setOpenForm(!openForm),
          },
          dottedMenu: [
            {
              label: "Supprimer",
              color: colors.red.regular,
              icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
              modal: true,
              modalProps: {
                identifier: "deleteProduct",
                type: "delete",
                title: "Êtes-vous sûr de vouloir supprimer ce produit ?",
                subtitle: "Cette action est irréversible",
                actions: {
                  primaryAction: () => deleteProduct(),
                },
              },
            },
          ],
        }}
        buttonAvailable={ready}
        alignItems={true}
      />
      <Grid
        container
        direction={"column"}
        style={{
          padding: 10,
          height: `100%`,
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            display: "flex",
            flex: "0 0 auto",
          }}
        >
          <ReturnLink goTo={props.history.goBack} type={"button"} />
        </div>
        <ProductsDetailsLayout>
          <AccordionCustom
            id={"product-detail-container"}
            overflow
            forcedExpanded
            height={"100%"}
            scrollable
            title={
              <ProductDetailSummary
                setOpenLogs={setInfoLogs}
                openLogs={infoLogs}
                locales={props.locales}
                anchors={anchors}
                currentAnchor={currentAnchor}
                setCurrentAnchor={setCurrentAnchor}
                setScrollChange={setScrollChange}
              />
            }
            custompadding={"0 !important"}
            currentAnchor={currentAnchor}
            setCurrentAnchor={setCurrentAnchor}
            anchors={anchors}
            setScrollChange={setScrollChange}
            scrollChange={scrollChange}
          >
            <div
              style={{
                display: "grid",
                rowGap: 50,
              }}
            >
              {product ? (
                <>
                  <ProductDetailGeneral
                    id={"product-detail-general"}
                    images={images}
                    product={product}
                  />
                  <ProductDetailAttributs
                    id={"product-detail-attributs"}
                    product={product}
                  />
                  {/* <ProductDetailSEO
                    id={"product-detail-seo"}
                    product={product}
                  /> */}
                  <ProductDetailTaxonomie
                    id={"product-detail-taxonomie"}
                    product={product}
                    setTaxos={setTaxos}
                  />
                  <ProductDetailAssociatedProducts
                    id={"product-detail-associated-products"}
                    product={product}
                  />
                </>
              ) : (
                <PageLoader />
              )}
            </div>
          </AccordionCustom>
        </ProductsDetailsLayout>
        <LogsActions
          typeToLoad={productId}
          open={infoLogs}
          toggleDrawer={() => setInfoLogs(!infoLogs)}
          isProduct={true}
          groupAttribut={groupAttribut}
        />
      </Grid>
      {/* {ready ? (
        // <Edit
        //     open={openForm}
        //     onClose={()=>setOpenForm(!openForm)}
        //     locales={props.locales}
        //     state={state}
        //     handleButtonGroupChange={handleButtonGroupChange}
        //     handleInputChange={handleInputChange}
        //     handlerMutation={handlerMutation}
        // />

        // FORM VARIANT
        <LayoutFormBuilder
          scrollable={true}
          isSublayout={false}
          validateButton={true}
          // handleCancel={resetVariant}
          opened={state.openFormVariant}
          forClose={() => handleToggleDrawer("addVariant")}
          handlerMutation={() => saveVariant("addVariant")}
          icomoon={"ico-modifier-produit"}
          //noCancel={true}
          dataLayout={formVariantProductAdd(
            state.currentLang,
            // this.state.categoriesData,
            state.customAttributes,
            state.attributesSelected,
            state[state.currentLang]?.product_price,
            state.variantsValidated,
            state.imagesSelected ? state.imagesSelected[0]?.original : "",
            //   handleMediaPicker,
            state.hasPrice
          )}
          backStepperButtonAction={[
            () => {
              setState((prevState) => ({ ...prevState, errors: {} }));
            },
            () => {
              setState((prevState) => ({ ...prevState, errors: {} }));
            },
            () => {
              setState((prevState) => ({ ...prevState, errors: {} }));
            },
            null,
          ]}
          stepperButtonDisabled={[
            null,
            () => state.variantsProduct?.length === 0,
          ]}
          stepperButtonAction={[
            () => {
              if (hasErrors()) {
                props.snack(
                  ALERT_ERROR,
                  "Veuillez vérifier les champs invalides"
                );
                setState((prevState) => ({ ...prevState, seeErrors: true }));
                eventService.fire();
                return false;
              }
              return true;
            },
            () => {
              if (hasErrors()) {
                props.snack(
                  ALERT_ERROR,
                  "Veuillez vérifier les champs invalides"
                );
                setState({ seeErrors: true });
                eventService.fire();
                return false;
              }
              return true;
            },
            null,
          ]}
          handleButtonGroupChange={handleButtonGroupChange}
          allState={state}
          stateCallback={handleInputChange}
          // errorCallback={handleSecondFormError}
          currentLang={state.currentLang}
          // handleLang={handleLang}
          drawerWidth={props.drawerWidth}
          deleteButton={false}
        />
      ) : null} */}

      <OurDrawer
        isOpen={openForm}
        setIsOpen={() => setOpenForm(!openForm)}
      >
        <ProductForm product={product} setIsOpen={() => setOpenForm(!openForm)} />
      </OurDrawer>

      {
        // TODO : delete if not used
      }
      {/* <LayoutFormBuilder
        isSublayout={false}
        validateButton={true}
        handleCancel={resetStateProducts}
        opened={false}
        forClose={() => setOpenForm(!openForm)}
        handlerMutation={handlerMutation}
        icomoon={"ico-modifier-produit"}
        useLocking={true}
        dico={true}
        dataLayout={formEditProduct(
          "update",
          state.currentLang,
          state.categoriesData,
          state.customAttributes,
          state.metaAttributes,
          state.imageAttributes,
          state.categoriesSelected ?? [],
          state.allGroups, //all groups
          state.errors, //errors
          state.seeErrors, //seeErrors
          handleMediaPicker, //handleMediaPicker
          [],
          handleToggleDrawer, // handletoggleDrawer
          state.variantsValidated,
          state.isVariant, //isVariant
          null, //RightButtonCallback,
          ALLOWED_IN_PRODUCT, //Allowed types
          state.hasPrice,
          state.imagesSelected ? state.imagesSelected[0]?.original : "",
          "",
          true
        )}
        backStepperButtonAction={[
          () => {
            setState({ errors: {} });
          },
          () => {
            setState({ errors: {} });
          },
          () => {
            setState({ errors: {} });
          },
          null,
        ]}
        stepperButtonDisabled={[null, () => state.variantsProduct.length === 0]}
        stepperButtonAction={[
          () => {
            if (hasErrors()) {
              props.snack(
                ALERT_ERROR,
                "Veuillez vérifier les champs invalides"
              );
              setState({ seeErrors: true });
              eventService.fire();
              return false;
            }
            return true;
          },
          () => {
            if (hasErrors()) {
              props.snack(
                ALERT_ERROR,
                "Veuillez vérifier les champs invalides"
              );
              setState({ seeErrors: true });
              eventService.fire();
              return false;
            }
            return true;
          },
          null,
        ]}
        handleButtonGroupChange={handleButtonGroupChange}
        allState={state}
        stateCallback={handleInputChange}
        // errorCallback={handleSecondFormError}
        currentLang={state.currentLang}
        // handleLang={handleLang}
        deleteButton={false}
        drawerWidth={"60vw"}
      /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    attributeGroups: state.attributeGroups,
    locales: state.locales,
    currentLang: state.currentLang,
    categories: state.categories,
  };
};

export default withTranslation()(
  withRouter(
    withApollo(connect(mapStateToProps, mapDispatchToProps)(ProductDetailNew))
  )
);
