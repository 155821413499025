import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '../../../../builder/shareable/components/assets/cms/components/shared/button';
import OurTypography from '../../../ui/typography/Typography';
import CardCustom from '../CardCustom';
import EditIcon from '@mui/icons-material/Edit';
import colors from '../../../../config/theme/colors';
import OurButton from '../../../ui/button/Button';

const CardInventory = ({
  title,
  img,
  type,
  data,
  hasModifyButton,
  handleModifyButton,
  isButtonsAvailable
}) => {
  return (
    <CardCustom style={{
      width: '100%',
      height: '100%',
    }}>
      <Box style={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
      }}>
        {/* First line */}
        <Grid container justifyContent='space-between'>
          <Grid item>
            <OurTypography text={title} fontweighttext={900} style={{ fontSize: '25px' }} />
          </Grid>
          {type === 'tags' ? (
            <Grid item>
              <OurTypography text={title.charAt(0).toUpperCase()} fontweighttext={900} colortext={colors.blue.darker.hue300} style={{ fontSize: '32px' }} />
            </Grid>
          ) : null}
        </Grid>

        {/* Second line */}
        <Box mt={3}>
          {data.map((item, index) => (
            <Grid container xs={12}>
              <Grid item xs={12} sm={6} md={4}>
                <OurTypography text={item.label + ' :'} />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <OurTypography fontweighttext={900}>
                  {item.value}
                </OurTypography>
              </Grid>
            </Grid>
          ))}
        </Box>

        {/* Third line */}
        <Grid container>
          {hasModifyButton ? (
            <OurButton
              types="edit"
              importance={'secondary'}
              style={{
                width: '100%',
                height: '40px',
                margin: '0px',
                marginTop: '28px',
                display: 'flex',
                alignSelf: 'end'
              }}
              stopPropagation={true}
              onClick={handleModifyButton}
            >Modifier</OurButton>
          ) : null}
        </Grid>
      </Box>
    </CardCustom>
  )
}

// PropTypes
CardInventory.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  hasModifyButton: PropTypes.bool.isRequired,
  handleModifyButton: PropTypes.func.isRequired,
  isButtonsAvailable: PropTypes.bool.isRequired
}

// Default props
CardInventory.defaultProps = {
  title: 'Test',
  img: 'https://picsum.photos/200/300',
  type: 'tags',
  data: [
    {
      label: 'Name',
      value: 'Test'
    },
    {
      label: 'Description',
      value: 'Test'
    },
    {
      label: 'Price',
      value: 'Test'
    },
  ],
  hasModifyButton: true,
  handleModifyButton: () => console.log('Modify button clicked'),
  isButtonsAvailable: true
}

export default CardInventory