import { gql } from '@apollo/client';



//----- Récupération des campagnes
export const GET_CAMPAIGN = gql`
{
  campaigns {
    edges{
      node{
        id
        identifier
        libelle
        description
        gtm
        startAt
        endAt
        status
        media{
          id
          filePath
          alt
          type
        }
        catalogs{
          edges{
            node{
              id
            }
          }
        }
        retailers{
          edges{
            node{
              id
              libelle
              identifier
            }
          }
        }
        assets{
          edges{
            node{
              id
              assetType{
                identifier
                libelle
              }
              assetGameType{
                identifier
                libelle
              }
              assetDatas{
                edges{
                  node{
                    id
                    value
                    attribute{
                      identifier
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//TODO Dono
export const GET_CAMPAIGN_BY_ID = gql`
query campaigns($id: ID!){
  campaign(id: $id) {
    id
    identifier
    libelle
    description
    gtm
    startAt
    endAt
    status
    media{
      id
      filePath
      alt
      type
    }
    catalogs{
      edges{
        node{
          id
          id
          identifier
          libelle
          description
          type{
            id
            identifier
          }
          status
          media{
            id
            filePath
            type
          }
          startAt
          endAt
          categories{
            productCategories{
              totalCount
            }
          }
        }
      }
    }
    retailers{
      edges{
        node{
          id
          libelle
          identifier
          logo
          description
          isInternal
          attributeGroup{
            id
            identifier
          }
          retailerData{
            edges{
              node{
                value
                id
                media{
                  id
                  filePath
                }
                attributeOption{
                  identifier
                  translation{
                    translationDatas{
                      edges{
                        node{
                          locale{
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute{
                  id
                  identifier
                  isSystem
                  attributeType {
                    id
                    input
                  }
                }
                locale{
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
    assets{
      edges{
        node{
          id
          identifier
          status
          startAt
          endAt
          assetType{
            id
            logo
            libelle
            description
            channelRequired
            hasFeed
            isActive
            identifier
            attributeGroup{
              id
            }
            assetCategory{
              id
              logo
              identifier
            }
          }
          assetDatas{
            edges{
              node{
                value
                id
                attributeOption{
                  id
                  identifier
                  translation{
                    translationDatas{
                      edges{
                        node{
                          locale{
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute{
                  id
                  identifier
                  isSystem
                  translation{
                    id
                    translationDatas{
                      edges{
                        node{
                          id
                          value
                          locale{
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                  attributeType {
                    id
                    input
                  }
                }
                media{
                  id
                  filePath
                  type
                  size
                  category{
                    edges{
                      node{
                        id
                        libelle
                      }
                    }
                  }
                }
                locale{
                  id
                  code
                }          
              }
            }
          }
          assetCrons{
            edges{
              node{
                id
                rate
                eavType{
                  id
                  code
                }
              }
            }
          }
          assetMinisitePages{
            edges{
              node{
                id
                identifier
                libelle
                status
                content
                createdAt
                assetMinisitePageType{
                  id
                }
              }
            }
          }
          assetGameType{
            id
            libelle
            identifier
            logo
            description
          }
        }
      }
    }
  }
}
`;

//----- Ajouter une campagne
/*
Exemple de variables
  {
    "identifier": "test",
    "libelle": "Test",
    "description": "Ma description de test",
    "gtm": "UA-1234",
    "startAt": "2020-09-01",
    "endAt": "2020-09-30",
    "catalogs": ["/api/catalogs/3"],
    "assets": ["/api/assets/2","/api/assets/3"],
    "retailers": ["/api/retailers/1"]
  }
*/
export const ADD_CAMPAIGN = gql`
mutation AddCampaign($identifier: String!, $libelle: String!, $description: String, $gtm: String, $startAt: String!, $endAt: String!, $catalogs: [String], $retailers: [String], $assets: [String], $status: Boolean!,$media: String){
	createCampaign(input: {identifier:$identifier, libelle:$libelle, description:$description, gtm:$gtm, startAt:$startAt, endAt:$endAt, catalogs:$catalogs, retailers:$retailers, assets:$assets, status:$status, media:$media}){
    campaign{
      id
    }
  }  
}`;

//----- modifier une campagne
/*
Exemple de variables
  {
    "id": "/api/campaigns/4",
    "libelle": "Test update",
    "description": "Ma description de test",
    "gtm": "UA-1234",
    "startAt": "2020-09-01",
    "endAt": "2020-09-30",
    "catalogs": ["/api/catalogs/3"],
    "assets": ["/api/assets/2","/api/assets/3","/api/assets/4"],
    "retailers": ["/api/retailers/1"]
  }
*/
export const UPDATE_CAMPAIGN = gql`
mutation UpdateCampaign($id: ID!, $identifier: String, $libelle: String, $description: String, $gtm: String, $startAt: String, $endAt: String, $catalogs: [String], $retailers: [String], $assets: [String], $status: Boolean, $media: String){
	updateCampaign(input: {id: $id, identifier:$identifier, libelle:$libelle, description:$description, gtm:$gtm, startAt:$startAt, endAt:$endAt, catalogs:$catalogs, retailers:$retailers, assets:$assets, status:$status, media:$media}){
    campaign{
      id
    }
  }  
}`;


//----- Supprimer une campagne
/*
Exemple de variables
  {
    "id" : "/api/campaigns/4"
  }
*/
export const DELETE_CAMPAIGN = gql`
mutation DeleteCampaign($id: ID!){
	deleteCampaign(input: {id:$id}){
    campaign{
      id
    }
  }  
}`;