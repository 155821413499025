import { withApollo } from "@apollo/client/react/hoc";
import colors from "@config/theme/colors";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import { ALERT_ERROR, ALERT_SUCCESS } from "@constants/alert-types.js";
import { MEDIAPICKER_TYPES } from "@constants/mediapickerTypes.enum.js";
import { IMAGES } from "@constants/medias-types";
import CardImage from "@layouts/Card/CardImage";
import OurDrawer from "@layouts/Drawer/OurDrawer";
import TopPanel from "@layouts/TopPanel/TopPanel";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GET_CATEGORIES_FORM_SELECT } from "@queries/categories";
import {
  ADD_PRODUCT,
  ADD_PRODUCT_DATA,
  GET_PRODUCT_VARIANTS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_DATA,
} from "@queries/products";
import ProductSelector from "@screens/dashboard/products/components/ProductsSelector";
import OurTabs from "@ui/OurTabs/OurTabs";
import OurButton from "@ui/button/Button";
import ProductVariantForm from "@ui/form/ProductForm/ProductVariantForm";
import InputBuilder from "@ui/form/inputs/InputBuilder";
import RadioGroupInput from "@ui/form/inputs/RadioGroupInput";
import SelectInput from "@ui/form/inputs/SelectInput";
import SelectTree from "@ui/form/inputs/SelectTree";
import TextInput from "@ui/form/inputs/TextInput";
import PageLoader from "@ui/loadings/page-loader/PageLoader";
import TextAddElement from "@ui/typography/TextAddElement";
import TextHelper from "@ui/typography/TextHelper";
import {
  getMainProductImage,
  getProductAttributes,
  getProductData,
  getProductImages,
  getCustomAttributes,
  getProductsAttributeGroups,
} from "@utils/products/product.utils";
import { getIdFromPath } from "@utils/string.utils.js";
import { getTranslationLabel } from "@utils/translation.utils.js";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styled from "styled-components";
import RowVariantProduct from "./components/RowProductVariant";
import RowProductVariantForm from "./components/RowProductVariantForm";
import { Container, CustomTabPanel, FormContainer } from "./style/ProductForm.styled.js";

const ProductForm = ({ product, setProduct, setIsOpen, ...props }) => {
  const [tabActive, setTabActive] = useState(0);
  const [form, setForm] = useState({
    attributeGroup: product
      ? product.attributeGroup.id
      : getProductsAttributeGroups(props.attributeGroups).find(
          (e) => e.node.identifier === "default"
        ).node.id,
    sku: product ? product.sku : "",
    ean:
      getProductData(product, "product_ean", props.locales[0].node.code)?.node
        .value ?? "",
    title:
      getProductData(product, "product_name", props.locales[0].node.code)?.node
        .value ?? "",
    categories: product
      ? product.productCategories.edges.map(
          (category) => category.node.category
        )
      : [],
    description:
      getProductData(product, "product_description", props.locales[0].node.code)
        ?.node.value ?? "",
    active: product ? product.status : true,
    images:
      getProductImages(product)
        ?.filter((e) => e.node.media)
        .map((e) => e.node.media) ?? [],
    metaTitle:
      getProductData(product, "product_metatitle", props.locales[0].node.code)
        ?.node.value ?? "",
    metaDescription:
      getProductData(product, "product_metadesc", props.locales[0].node.code)
        ?.node.value ?? "",
    productVariants: [],
    attributes: product
      ? product.productDatas.edges
          .map((productData) => ({
            [productData.node.attribute.identifier]: productData.node.value,
          }))
          .reduce((a, b) => ({ ...a, ...b }), {})
      : {},
    productVariantsAttributes: [],
    selectedProducts: product
      ? product.suggestions.edges.map((suggestion) => suggestion.node.id)
      : [],
  });
  const [lang, setLang] = useState(props.locales[0].node);
  const [showFormProductVariant, setShowFormProductVariant] = useState(false);
  const [productVariantEdit, setProductVariantEdit] = useState(null);
  const [productImageEdit, setProductImageEdit] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [switchs, setSwitchs] = useState({
    attributeGroupConfigurable: true,
  });
  const [loading, setLoading] = useState(false);
  const [attributesInputs, setAttributesInputs] = useState([]);

  useEffect(() => {
    fetchCategories();
    if (product?.children?.edges.length > 0) fetchProductVariants();
    getAttributesInputs();
  }, []);

  useEffect(() => {
    if (product) {
      setForm((prevState) => ({
        ...prevState,
        attributeGroup: product
          ? product.attributeGroup.id
          : getProductsAttributeGroups(props.attributeGroups).find(
              (e) => e.node.identifier === "default"
            ).node.id,
        sku: product ? product.sku : "",
        ean:
          getProductData(product, "product_ean", lang.code)?.node.value ?? "",
        title:
          getProductData(product, "product_name", lang.code)?.node.value ?? "",
        categories: product
          ? product.productCategories.edges.map(
              (category) => category.node.category
            )
          : [],
        description:
          getProductData(product, "product_description", lang.code)?.node
            .value ?? "",
        active: product ? product.status : true,
        images:
          getProductImages(product, lang.code)
            ?.filter((e) => e.node.media)
            .map((e) => e.node.media) ?? [],
        metaTitle:
          getProductData(product, "product_metatitle", lang.code)?.node.value ??
          "",
        metaDescription:
          getProductData(product, "product_metadesc", lang.code)?.node.value ??
          "",
        attributes: product ? getProductAttributes(product, lang.code) : {},
      }));
    }
  }, [lang]);
  const [errors, setErrors] = useState({
    sku: null,
    title: null,
  });

  useEffect(() => {
    if (productImageEdit?.image && productImageEdit.action === "delete") {
      const buttonDelete = document.querySelector(
        "#delete_image_button_fakeMediaPicker"
      );
      if (buttonDelete) {
        buttonDelete.click();
      }
    } else if (productImageEdit?.image && productImageEdit?.action === "edit") {
      const buttonEdit = document.querySelector(
        "#edit_image_button_fakeMediaPicker"
      );
      if (buttonEdit) {
        buttonEdit.click();
      }
    }
  }, [productImageEdit]);

  const fetchCategories = async () => {
    props.client
      .query({
        query: GET_CATEGORIES_FORM_SELECT,
        variables: {
          exists: [
            {
              catalog: false,
            },
          ],
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchProductVariants = () => {
    setLoading({
      ...loading,
      productVariants: true,
    });

    props.client
      .query({
        query: GET_PRODUCT_VARIANTS,
        variables: {
          id: product.id,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        const productVariants = res.data.product.children.edges.map(
          (productVariant) => {
            const inputs = productVariant.node.productDatas.edges
              .filter((productData) => productData.node.locale.id === lang.id)
              .filter(
                (productData) =>
                  !["image", "file"].includes(
                    productData.node.attribute.attributeType.input
                  )
              )
              .map((productData) => ({
                id: productData.node.id,
                identifier: productData.node.attribute.identifier,
                label: getTranslationLabel(
                  productData.node.attribute.translation
                ),
                inputType: productData.node.attribute.attributeType.input,
                options: productData.node.attribute.attributeOptions.edges.map(
                  (option) => ({
                    label: getTranslationLabel(option.node.translation),
                    value: option.node.id,
                  })
                ),
                value: productData.node.value,
              }));

            return {
              id: productVariant.node.id,
              sku: productVariant.node.sku,
              image: getMainProductImage(productVariant.node)?.node.media ?? {},
              inputs,
            };
          }
        );

        setForm((prevState) => ({
          ...prevState,
          productVariants,
        }));
      })
      .catch((err) => {
        console.log(err);
        setForm((prevState) => ({
          ...prevState,
          productVariants: [],
        }));
        props.snack(
          ALERT_ERROR,
          props.t("productVariants.errors.fetchProductVariants")
        );
      })
      .finally(() => {
        setLoading({
          ...loading,
          productVariants: false,
        });
      });
  };

  useEffect(() => {
    let parents = categories.filter(
      (e) => e.parent === null && e.catalog === null
    );

    const setChildrens = (parent) => {
      if (parent) {
        parent.children = [];
        for (let category of categories) {
          if (category.parent?.id === parent.id) {
            parent.children.push(category);
            setChildrens(category);
          }
        }
      }
    };
    const setTree = (parent) => {
      parent.label = parent.libelle;
      parent.value = parent.id;
      parent.checked = form.categories.some((e) => e.id === parent.id);
      if (parent.children.length > 0) {
        parent.expanded = true;
        for (let child of parent.children) {
          setTree(child);
        }
      }
    };

    for (let parent of parents) {
      setChildrens(parent);
      setTree(parent);
    }

    setCategoriesTree(parents);
  }, [categories]);

  const handleChangeCategories = (val, selectedNodes) => {
    const handleTree = (parent) => {
      for (let category of parent) {
        if (category.id === val.id) {
          category.checked = !category.checked;
        } else if (category.children.length > 0) {
          handleTree(category.children);
        }
      }
    };
    const newTree = [...categoriesTree];
    handleTree(newTree);
    setCategoriesTree(newTree);

    const isChecked = form.categories.some((e) => e.id === val.id);
    if (isChecked) {
      setForm((prevState) => ({
        ...prevState,
        categories: form.categories.filter((e) => e.id !== val.id),
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        categories: [...form.categories, val],
      }));
    }
  };

  const getAttributesInputs = useCallback(() => {
    const customAttributes = getCustomAttributes(
      props.attributes.product.attributes
    );
    const defaultAttributeGroup = props.attributeGroups?.find(
      (e) => e.node.identifier === "default"
    )?.node;
    let inputs = [];

    if (!product) {
      inputs = defaultAttributeGroup.attributes.edges
        .filter((e) => e.node.attributeType.input !== "image" && e.node.status)
        .concat(
          customAttributes.filter(
            (e) => e.node.status && e.node.attributeType.input !== "image"
          )
        );
    } else {
      // if (currentCollection === "/api/selections/3") {
      //   customInputs = customAttributes
      //     .filter(
      //       (e) =>
      //         e.node.attributeType.input !== "image" &&
      //         e.node.status &&
      //         e.node.identifier !== "ecoresponsable"
      //     )
      //     .map(attributeSetup);
      // } else if (currentCollection === "/api/selections/2") {
      //   customInputs = customAttributes
      //     .filter(
      //       (e) =>
      //         e.node.attributeType.input !== "image" &&
      //         e.node.status &&
      //         e.node.identifier !== "personnal_shopper"
      //     )
      //     .map(attributeSetup);
      // }
      // else {
      inputs = customAttributes.filter(
        (e) =>
          e.node.attributeType.input !== "image" &&
          e.node.status &&
          e.node.identifier !== "ecoresponsable" &&
          e.node.identifier !== "personnal_shopper"
      );
      // }
    }
    inputs.forEach((input) => {
      if (input.node.attributeType.input === "select") {
        input.node.attributeOptions.edges.forEach((option) => {
          option.node.label = getTranslationLabel(option.node.translation);
          option.node.value = option.node.id;
        });
      }
    });

    setAttributesInputs(inputs);
  }, [product]);

  const handleChangeAttribute = (e, inputType, formKey) => {
    const newForm = { ...form };

    switch (inputType) {
      case "text":
      case "textarea":
      case "number":
      case "decimal":
      case "radio":
        newForm.attributes[formKey] = e.target.value;
        break;
      case "select":
        newForm.attributes[formKey] = e;
        break;
      case "date":
        newForm.attributes[formKey] = moment(e).format("YYYY-MM-DD");
        break;
    }

    setForm(newForm);
  };

  const handleSaveProductVariants = (productVariants, attributesSelected) => {
    setForm((prevState) => ({
      ...prevState,
      productVariants: [...prevState.productVariants, ...productVariants],
      productVariantsAttributes: [
        ...prevState.productVariantsAttributes,
        ...attributesSelected,
      ],
    }));
    setShowFormProductVariant(false);
  };

  const handleDeleteProductVariant = (id) => {
    setForm((prevState) => ({
      ...prevState,
      productVariants: prevState.productVariants.filter(
        (productVariant) =>
          productVariant.tempoId !== id && productVariant.tempoId
      ),
    }));
  };

  useEffect(() => {
    if (!showFormProductVariant) {
      setProductVariantEdit(null);
    }
  }, [showFormProductVariant]);

  const checkForm = () => {
    return new Promise((resolve, reject) => {
      setErrors({
        sku: null,
        title: null,
      });

      const errorMessage = props.t("errors.requiredField");
      if (!form.sku) {
        setErrors((prevState) => ({
          ...prevState,
          sku: errorMessage,
        }));
        reject("sku");
      }
      if (!form.title) {
        setErrors((prevState) => ({
          ...prevState,
          title: errorMessage,
        }));
        reject("title");
      }
      resolve(true);
    });
  };

  const handlerMutation = async () => {
    checkForm().then(async () => {
      try {
        props.startLoading();

        const hasNewVariants =
          switchs.attributeGroupConfigurable && form.productVariants.length > 0;
        const query = product ? UPDATE_PRODUCT : ADD_PRODUCT;
        const variables = {
          id: product ? product.id : null,
          sku: form.sku,
          attributeGroup: form.attributeGroup ?? "",
          categories: form.categories.map((category) =>
            parseInt(getIdFromPath(category.id))
          ),
          createdAt: moment().format("YYYY-MM-DD"),
          updatedAt: moment().format("YYYY-MM-DD"),
          status: form.active === "true" ? true : false,
          superAttribute: hasNewVariants ? form.productVariantsAttributes : [],
        };

        if (form.selectedProducts) {
          variables.suggestions = form.selectedProducts;
        }

        const res = await props.client.mutate({
          mutation: query,
          variables,
        });

        await saveAttributes(
          product ? product.id : res.data.createProduct.product.id
        );

        if (hasNewVariants) {
          const newVariants = await createVariant();
          const productVariants = form.productVariants
            .map((productVariant) => productVariant.id)
            .concat(newVariants)
            .filter((e) => e);

          await props.client.mutate({
            mutation: UPDATE_PRODUCT,
            variables: {
              id: res.data.createProduct
                ? res.data.createProduct.product.id
                : res.data.updateProduct.product.id,
              children: productVariants,
            },
          });
        }

        props.snack(ALERT_SUCCESS, props.t("products.form.saveSuccess"));
      } catch (error) {
        console.log(error);
        props.snack(ALERT_ERROR, props.t("products.form.saveError"));
      } finally {
        props.stopLoading();
        setIsOpen();
      }
    }).catch((err) => {
      setTabActive(0);
    })
  };

  const createVariant = () => {
    return new Promise(async (resolve, reject) => {
      let getChildren = await getVariantForChildren();
      resolve(getChildren);
    });
  };

  const getFormFormatted = () => {
    let images = {};
    form.images.forEach((img, index) => {
      if (index > 0) images[`product_image_${index + 1}`] = img;
      else images[`product_image`] = img;
    });

    return {
      ...form.attributes,
      ...images,
      product_name: form.title,
      product_description: form.description,
      product_ean: form.ean,
      product_metatitle: form.metaTitle,
      product_metadesc: form.metaDescription,
    };
  };

  const saveAttributes = (productId, productVariant) => {
    return new Promise(async (resolve, reject) => {
      let productData = [];

      const allProductsAttributes = props.attributes.product.attributes.edges;
      if (productVariant) {
        Promise.all(
          [...productVariant.inputs, productVariant.image].map(
            async (input) => {
              const attribute = allProductsAttributes.find(
                (e) => e.node?.identifier === input.identifier
              );

              if (attribute) {
                const isMedia =
                  attribute.node.attributeType.input === "image" ||
                  attribute.node.attributeType.input === "file";

                let variables = {
                  attributeOption:
                    attribute.node.attributeType.input === "select"
                      ? input.value
                      : null,
                  product: productId,
                  attribute: attribute.node.id,
                  locale: lang.id,
                  // isLocked: formValue.isLocked,
                  updatedAt: moment().format("YYYY-MM-DD"),
                };

                if (isMedia) {
                  variables.media = input.id ?? input.value.id;
                  variables.id = input.productDataId ?? null;
                } else {
                  variables.value = input.value;
                }

                const res = await props.client.mutate({
                  mutation: ADD_PRODUCT_DATA,
                  variables,
                });

                productData.push(res.data.createProductData.productData);
              }
            }
          )
        );
      } else {
        let requests = [];
        for (const [key, productAttribute] of Object.entries(
          getFormFormatted()
        )) {
          const attribute = allProductsAttributes.find(
            (e) => e.node.identifier === key
          );

          if (
            attribute &&
            productAttribute !==
              getProductData(product, key, lang.code)?.node.value
          ) {
            const query = getProductData(product, key, lang.code)
              ? UPDATE_PRODUCT_DATA
              : ADD_PRODUCT_DATA;
            const isMedia =
              attribute.node.attributeType.input === "image" ||
              attribute.node.attributeType.input === "file";

            let variables = {
              attributeOption:
                attribute.node.attributeType.input === "select"
                  ? productAttribute
                  : null,
              product: productId,
              attribute: attribute.node.id,
              locale: lang.id,
              // isLocked: formValue.isLocked,
              updatedAt: moment().format("YYYY-MM-DD"),
            };

            if (isMedia) {
              variables.media = productAttribute.id;
            } else {
              variables.value = productAttribute;
            }

            const id = getProductData(product, key, lang.code)?.node.id ?? null;

            if (id) {
              variables.id = id;
            }
            requests.push({
              mutation: query,
              variables,
            });
          }
        }
        Promise.all(requests.map(async (e) => await props.client.mutate(e)));
      }
      resolve(productData);
    });
  };

  const getVariantForChildren = () => {
    return new Promise(async (resolve, reject) => {
      let getChildren = [];

      for (let i = 0; i < form.productVariants.length; i++) {
        if (!form.productVariants[i].id) {
          const res = await props.client.mutate({
            mutation: ADD_PRODUCT,
            variables: {
              sku: form.sku + "-variant" + (i + 1),
              attributeGroup: form.attributeGroup ?? "",
              categories: form.categories.map((category) =>
                parseInt(getIdFromPath(category.id))
              ),
              createdAt: moment().format("YYYY-MM-DD"),
              updatedAt: moment().format("YYYY-MM-DD"),
              status: form.active === "true" ? true : false,
              suggestions: form.selectedProducts,
            },
          });

          await saveAttributes(
            res.data.createProduct.product.id,
            form.productVariants[i]
          );

          getChildren.push(res.data.createProduct.product.id);
        }
      }

      resolve(getChildren);
    });
  };

  const handleChangeProductVariant = (val, id, key = "inputs") => {
    setForm((prevState) => ({
      ...prevState,
      productVariants: prevState.productVariants.map((productVariant) => {
        if (productVariant.tempoId && productVariant.tempoId === id) {
          return {
            ...productVariant,
            [key]: val,
          };
        }
        return productVariant;
      }),
    }));
  };

  const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.grey.lighter.hue800};
  `;

  const getProductVariantAttributeValueToDisplay = (
    inputValue,
    inputType,
    options = []
  ) => {
    switch (inputType) {
      case "select":
        return options.find((e) => e.value === inputValue)?.label ?? "";
      case "image":
      case "file":
        return inputValue?.name ?? "";
      default:
        return inputValue ?? "";
    }
  };

  const getImageFormatsAccepted = () => {
    return IMAGES.map((format) => format.split("/")[1]).join(", ");
  };

  return (
    <Container>
      <TopPanel
        inForm={true}
        windowWidth={props.windowWidth}
        title={
          product
            ? props.t("products.form.title.editProduct")
            : props.t("products.form.title.createProduct")
        }
        subtitle={
          product
            ? props.t("products.form.helper.helperTitleEdit")
            : props.t("products.form.helper.helperTitleCreate")
        }
      />

      <SelectInput
        label={props.t("inputs.language")}
        value={lang.id}
        options={props.locales.map((locale) => ({
          value: locale.node.id,
          label: locale.node.libelle,
        }))}
        required={true}
        helper={props.t("products.form.helper.helperLang")}
        onChange={(e) =>
          setLang(props.locales.find((lang) => lang.node.id === e)?.node)
        }
      />

      <OurTabs
        tabs={[
          { labelName: props.t("products.form.tabs.general") },
          { labelName: props.t("products.form.tabs.medias") },
          {
            labelName: props.t("products.form.tabs.metas"),
            isDisabled:
              process.env.REACT_APP_PRODUCT_HIDE_SUGGESTIONS == "true",
          },
          {
            labelName: props.t("products.form.tabs.attributes"),
          },
          {
            labelName: props.t("products.form.tabs.variants"),
            isDisabled:
              switchs.attributeGroupConfigurable == "false" ? true : false,
          },
          { labelName: props.t("products.form.tabs.suggestions") },
        ]}
        tabActive={tabActive}
        handleGetCurrentTab={(tab) => setTabActive(tab)}
      >
        {
          // GENERAL TAB
        }
        <CustomTabPanel value={tabActive} index={0}>
          <FormContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={props.t("inputs.sku")}
                  value={form.sku}
                  required={true}
                  delay={450}
                  sx={{ width: "100%" }}
                  error={errors.sku}
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      sku: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectTree
                  label={props.t("inputs.category")}
                  data={categoriesTree}
                  onChange={(value, selectedCategories) =>
                    handleChangeCategories(value, selectedCategories)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={props.t("inputs.ean")}
                  value={form.ean}
                  delay={450}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      ean: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectInput
                  label={props.t("inputs.attributeGroup")}
                  helper={
                    switchs.attributeGroupConfigurable &&
                    form.productVariants.length > 0
                      ? props.t("products.form.helper.helperProductVariantUsed")
                      : ""
                  }
                  value={form.attributeGroup}
                  options={getProductsAttributeGroups(
                    props.attributeGroups
                  ).map((attributeGroup) => ({
                    value: attributeGroup.node.id,
                    label: getTranslationLabel(attributeGroup.node.translation),
                  }))}
                  required={true}
                  disabled={
                    switchs.attributeGroupConfigurable &&
                    form.productVariants.length > 0
                      ? true
                      : false
                  }
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      attributeGroup: e,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={props.t("inputs.title")}
                  value={form.title}
                  error={errors.title}
                  required={true}
                  delay={450}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      title: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RadioGroupInput
                  label={props.t("inputs.configurable")}
                  value={switchs.attributeGroupConfigurable}
                  options={[
                    {
                      value: true,
                      label: props.t("common.yes"),
                    },
                    {
                      value: false,
                      label: props.t("common.yes"),
                    },
                  ]}
                  radioGroupProps={{
                    row: true,
                  }}
                  onChange={(e) =>
                    setSwitchs((prevState) => ({
                      ...prevState,
                      attributeGroupConfigurable: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
            <InputBuilder
              type="wysiwyg"
              input={{
                label: props.t("inputs.description"),
                value: form.description,
                isContent: false,
                translated: false,
                translation: true,
                required: false,
                usedAlone: true,
                stateName: "description",
                modules: {
                  toolbar: [["bold", "italic", "underline", "strike"]],
                },
                helper: {
                  text: props.t("products.form.helper.helperDescription"),
                  link: false,
                },
                richText: product ? true : false,
                checkSpelling: product ? true : false,
              }}
              value={form.description}
              stateCallback={(value) =>
                setForm((prevState) => ({ ...prevState, description: value }))
              }
            />
            <RadioGroupInput
              label={props.t("inputs.active")}
              value={form.active}
              options={[
                {
                  value: true,
                  label: props.t("common.yes"),
                },
                {
                  value: false,
                  label: props.t("common.no"),
                },
              ]}
              radioGroupProps={{
                row: true,
              }}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  active: e.target.value,
                }))
              }
            />
          </FormContainer>
        </CustomTabPanel>

        {
          // MEDIAS TAB
        }
        <CustomTabPanel
          value={tabActive}
          index={1}
          style={{
            backgroundColor: colors.background.white.primary,
          }}
        >
          <FormContainer>
            <TextHelper>
              {props.t("products.form.helper.helperImage")} <br />
              Formats acceptés : {getImageFormatsAccepted()}
            </TextHelper>

            {form.images.length < 6 && (
              <InputBuilder
                type="mediaPicker"
                input={{
                  fileType: MEDIAPICKER_TYPES.IMAGE,
                  allowedTypes: IMAGES,
                  allowedMedia: IMAGES,
                  label:
                    props.t("inputs.addImage") +
                    (form.images.length === 0 ? "*" : ""),
                  translated: false,
                  helper: {
                    text: "Importer une image du produit",
                    link: false,
                  },
                  required: false,
                  stateName: "image",
                  usedAlone: true,
                  handleMediaPicker: (e, stateName) =>
                    setForm((prevState) => ({
                      ...prevState,
                      images: [...prevState.images, e],
                    })),
                }}
                value={{}}
                hasMediaPicker={true}
                stateCallback={(value) =>
                  setForm((prevState) => ({ ...prevState, image: value }))
                }
              />
            )}

            <Separator />

            <Grid container spacing={2}>
              {form.images.map((image, index) => (
                <Grid item xs={12} md={4} key={`image${index}`}>
                  <CardImage
                    key={`image${index}`}
                    path={`${process.env.REACT_APP_MEDIAS}/${image?.filePath}`}
                    title={image?.name}
                    height={200}
                    actions={[
                      {
                        label: props.t("actions.edit"),
                        icon: {
                          name: "edit",
                          color: colors.blue.regular,
                        },
                        onClick: () =>
                          setProductImageEdit({
                            image: image,
                            action: "edit",
                          }),
                      },
                      {
                        label: props.t("actions.delete"),
                        icon: {
                          name: "delete",
                          color: colors.red.regular,
                        },
                        onClick: () => {
                          setProductImageEdit({
                            image: image,
                            action: "delete",
                          });
                        },
                      },
                    ]}
                  />
                  {productImageEdit?.image?.id === image?.id && (
                    <div style={{ visibility: "hidden" }}>
                      <InputBuilder
                        type="mediaPicker"
                        input={{
                          fileType: MEDIAPICKER_TYPES.IMAGE,
                          allowedTypes: IMAGES,
                          allowedMedia: IMAGES,
                          label: "Image " + 1,
                          translated: false,
                          helper: {
                            text: "",
                            link: false,
                          },
                          required: false,
                          stateName: "fakeMediaPicker",
                          usedAlone: true,
                          handleMediaPicker: (e) => {
                            if (e) {
                              setForm((prevState) => ({
                                ...prevState,
                                images: prevState.images.map((img, index) => {
                                  if (img.id === image.id) {
                                    return e;
                                  }
                                  return img;
                                }),
                              }));
                            }
                          },
                        }}
                        value={productImageEdit.image}
                        hasMediaPicker={true}
                        onDelete={(image) => {
                          setProductImageEdit({});
                          setForm((prevState) => ({
                            ...prevState,
                            images: prevState.images.filter(
                              (e) => e.id !== image.id
                            ),
                          }));
                        }}
                      />
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>
          </FormContainer>
        </CustomTabPanel>

        {
          // METAS TAB
        }
        <CustomTabPanel
          value={tabActive}
          index={2}
          style={{
            backgroundColor: colors.background.white.primary,
          }}
        >
          <FormContainer>
            <TextInput
              label={props.t("inputs.metaTitle")}
              value={form.metaTitle}
              delay={450}
              sx={{ width: "100%" }}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  metaTitle: e.target.value,
                }))
              }
            />
            <InputBuilder
              type="wysiwyg"
              input={{
                label: props.t("inputs.metaDescription"),
                value: form.metaDescription,
                isContent: false,
                translated: false,
                translation: true,
                required: false,
                usedAlone: true,
                stateName: "metaDescription",
                modules: {
                  toolbar: [["bold", "italic", "underline", "strike"]],
                },
                helper: {
                  text: props.t("products.form.helper.helperDescription"),
                  link: false,
                },
                richText: product ? true : false,
                checkSpelling: product ? true : false,
              }}
              value={form.metaDescription}
              stateCallback={(value) =>
                setForm((prevState) => ({
                  ...prevState,
                  metaDescription: value,
                }))
              }
            />
          </FormContainer>
        </CustomTabPanel>

        {
          // ATTRIBUTES TAB
        }
        <CustomTabPanel
          value={tabActive}
          index={3}
          style={{
            backgroundColor: colors.background.white.primary,
          }}
        >
          <FormContainer>
            {attributesInputs.map((input, index) => (
              <InputBuilder
                key={`AttributeInput${index}`}
                name={input.node.identifier}
                id={input.node.identifier}
                type={input.node.attributeType.input}
                sx={{ width: "100%" }}
                options={input.node.attributeOptions.edges.map((option) => ({
                  value: option.node.value,
                  label: option.node.label,
                }))}
                label={getTranslationLabel(input.node.translation)}
                onChange={(e) => {
                  handleChangeAttribute(
                    e,
                    input.node.attributeType.input,
                    input.node.identifier
                  );
                }}
                value={form.attributes[input.node.identifier]}
                delay={450}
              />
            ))}
          </FormContainer>
        </CustomTabPanel>

        {
          // VARIANTS TAB
        }
        <CustomTabPanel
          value={tabActive}
          index={4}
          style={{
            backgroundColor: colors.background.white.primary,
          }}
        >
          <FormContainer>
            {loading.productVariants ? (
              <PageLoader />
            ) : (
              <>
                <TextHelper>
                  * {props.t("products.form.helper.helperVariantRule1")} <br />
                  ** {props.t("products.form.helper.helperVariantRule2")} <br />
                </TextHelper>

                <Box margin={1}>
                  <TextAddElement
                    label={props.t("products.addProductVariant")}
                    onClick={() => setShowFormProductVariant(true)}
                  />
                </Box>

                <Separator />

                {form.productVariants.length > 0 ? (
                  form.productVariants.map((productVariant, index) =>
                    productVariantEdit?.tempoId === productVariant?.tempoId &&
                    productVariant.tempoId ? (
                      <RowProductVariantForm
                        inputs={productVariant.inputs}
                        image={
                          productVariant.id
                            ? productVariant.image
                            : productVariant.image
                        }
                        index={productVariant.id ?? productVariant.tempoId}
                        key={`productVariant${index}`}
                        handleChangeVariant={(val, id) =>
                          handleChangeProductVariant(val, id)
                        }
                        handleChangeImage={(val, id) =>
                          handleChangeProductVariant(val, id, "image")
                        }
                        handleDeleteVariant={handleDeleteProductVariant}
                      />
                    ) : (
                      <RowVariantProduct
                        label={`Variante ${index + 1}`}
                        attributes={productVariant.inputs.map((input) => ({
                          label: input.label,
                          value: getProductVariantAttributeValueToDisplay(
                            input.value,
                            input.inputType,
                            input.options
                          ),
                        }))}
                        key={`productVariant${index}`}
                        productVariant={productVariant}
                        index={productVariant.id ?? productVariant.tempoId}
                        imagePath={
                          productVariant.id
                            ? productVariant.image?.filePath
                            : productVariant.image?.filePath
                        }
                        handleSelect={() => {
                          setProductVariantEdit(productVariant);
                        }}
                        handleDelete={handleDeleteProductVariant}
                      />
                    )
                  )
                ) : (
                  <Typography>
                    {props.t("products.form.noProductVariants")}
                  </Typography>
                )}

                <OurDrawer
                  isOpen={showFormProductVariant}
                  setIsOpen={() =>
                    setShowFormProductVariant(!showFormProductVariant)
                  }
                >
                  <ProductVariantForm
                    saveProductVariants={handleSaveProductVariants}
                  />
                </OurDrawer>
              </>
            )}
          </FormContainer>
        </CustomTabPanel>

        {
          // SUGGESTIONS TAB
        }
        <CustomTabPanel
          value={tabActive}
          index={5}
          style={{
            backgroundColor: colors.background.white.primary,
          }}
        >
          <FormContainer>
            <ProductSelector
              allState={{
                currentLang: lang.code,
                selectedProducts: form.selectedProducts,
                nbProductsTotal: 0,
                catalogDatas: [],
              }}
              stateCallback={(name, value) => {
                setForm((prevState) => ({
                  ...prevState,
                  selectedProducts: value,
                }));
              }}
              optionsInputs={{}}
              windowWidth={100}
            />
          </FormContainer>
        </CustomTabPanel>
      </OurTabs>
      <Box
        pt={1}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <OurButton
          text={props.t("actions.validate")}
          onClick={() => handlerMutation()}
        />
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
    attributeGroups: state.attributeGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withApollo(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductForm))
  )
);
