import { gql } from '@apollo/client';

//----- Récupération des retailers

export const GET_RETAILERS_CURSOR = gql`
{
  retailers{
    totalCount
    edges{
      cursor
    }
  }
}
`;

export const GET_RETAILERS_PAGINATION = gql`
query retailers ($nbperpage: Int, $cursor: String, $is_internal: Boolean, $libelle: String, $description: String){
  retailers (first: $nbperpage, after: $cursor, is_internal: $is_internal, libelle: $libelle, description: $description){
    totalCount
    edges{
      cursor
      node{
        id
        identifier
        libelle
        logo
        description
        isInternal
        attributeGroup{
          id
          identifier
        }
        retailerData{
          edges{
            node{
              value
              id
              media{
                id
                filePath
              }
              attributeOption{
                identifier
                translation{
                  translationDatas{
                    edges{
                      node{
                        locale{
                          id
                          code
                        }
                        value
                      }
                    }
                  }
                }
              }
              attribute{
                id
                identifier
                isSystem
                attributeType {
                  id
                  input
                }
              }
              locale{
                id
                code
              }
            }
          }
        }
      }
    }
  }
}
`;
//----- Récupération des retailers / brands
/*
Exemple de variables
Il faut l'attribute_groups correspondant à retailer ou brand
  {
    "attributeGroup": "/api/attribute-groups/4"
  }
*/
export const GET_RETAILERS = gql`
query retailers($is_internal: Boolean,$attributeGroup: String){
  retailers(is_internal: $is_internal, attributeGroup:$attributeGroup){
    edges{
      node{
        id
        identifier
        libelle
        logo
        description
        isInternal
        attributeGroup{
          id
          identifier
        }
        retailerData{
          edges{
            node{
              value
              id
              media{
                id
                filePath
              }
              attributeOption{
                identifier
                translation{
                  translationDatas{
                    edges{
                      node{
                        locale{
                          id
                          code
                        }
                        value
                      }
                    }
                  }
                }
              }
              attribute{
                id
                identifier
                isSystem
                attributeType {
                  id
                  input
                }
              }
              locale{
                id
                code
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Recherche des retailers
export const SEARCH_RETAILERS = gql`
query retailersSearch($libelle: String, $description: String){
  retailers(libelle: $libelle, description: $description){
    edges{
      node{
        id
        identifier
        libelle
        logo
        description
        isInternal
        attributeGroup{
          id
          identifier
        }
        retailerData{
          edges{
            node{
              value
              id
              media{
                id
                filePath
              }
              attributeOption{
                identifier
                translation{
                  translationDatas{
                    edges{
                      node{
                        locale{
                          id
                          code
                        }
                        value
                      }
                    }
                  }
                }
              }
              attribute{
                id
                identifier
                isSystem
                attributeType {
                  id
                  input
                }
              }
              locale{
                id
                code
              }
            }
          }
        }
      }
    }
  }
}
`;


//----- Ajouter un retailer 
/*
 Exemple de variables
  {
    "identifier": "test",
    "libelle": "Retailer de test",
    "attributeGroup": "/api/attribute-groups/6",
    "logo": "logo.png",
    "description": "Ma description de test",
    "isInternal" : false
  }
*/
export const ADD_RETAILER = gql`
mutation AddRetailer($identifier: String!, $libelle: String!, $attributeGroup: String, $logo:String, $description:String, $isInternal:Boolean!){
	createRetailer(input: {identifier:$identifier, libelle:$libelle, attributeGroup:$attributeGroup, logo:$logo, description:$description, isInternal:$isInternal}){
    retailer{
      id
      identifier
    }
  }
}`;



//----- Modifier un retailer 
/*
 Exemple de variables
  {
    "id" : "/api/retailers/1",
    "libelle": "Retailer de test",
    "attributeGroup": "/api/attribute-groups/6",
    "logo": "logo.png",
    "description": "Ma description de test",
    "isInternal" : false
  }
*/
export const UPDATE_RETAILER = gql`
mutation UpdateRetailer($id:ID!, $identifier: String, $libelle: String, $attributeGroup: String, $logo:String, $description:String, $isInternal:Boolean){
	updateRetailer(input: {id:$id, identifier:$identifier, libelle:$libelle, attributeGroup:$attributeGroup, logo:$logo, description:$description, isInternal:$isInternal}){
  	retailer{
      id
      identifier
    }
  }
}`;


//----- Supprimer un retailer
/*
 Exemple de variables
  {
    "id" : "/api/retailers/3"
  }
*/
export const DELETE_RETAILER = gql`
mutation DeleteRetailer($id: ID!){
	deleteRetailer(input: {id:$id}){
    retailer{
      id
    }
  }  
}`;




//----- Ajouter une valeur d'attribut à un retailer

/*
 Exemple de variables
{
  "value": "MON_URL_DE_TEST",
	"retailer": "/api/retailers/1",
  "attribute": "/api/attributes/42", 
	"locale": "/api/locales/3"
}
*/
export const ADD_RETAILER_DATA = gql`
mutation AddRetailerData($value: String, $retailer: String!, $attribute: String!, $locale: String!, $attributeOption: String, $media: String){
	createRetailerData(input: {value:$value, retailer:$retailer, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    retailerData{
      id
      value
      retailer{
        id
      }
      attribute{
        id
      }
      media{
        id
      }
      locale{
        id
      }
      attributeOption{
        id
        identifier
      }
    }
  }  
}`;



//----- Modifier une valeur d'attribut d'un retailer

/*
 Exemple de variables
 {
  "id": "/api/retailer-datas/1",
  "value": "MON_URL_DE_TEST_EDITED",
	"retailer": "/api/retailers/1",
  "attribute": "/api/attributes/42", 
	"locale": "/api/locales/3"
}
*/
export const UPDATE_RETAILER_DATA = gql`
mutation UpdateRetailerData($id:ID!, $value: String, $retailer: String, $attribute: String, $locale: String, $attributeOption: String){
	updateRetailerData(input: {id:$id, value:$value, retailer:$retailer, attribute:$attribute, locale:$locale, attributeOption:$attributeOption}){
    retailerData{
      id
      value
      retailer{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      attributeOption{
        id
        identifier
      }
    }
  }  
}`;


//----- Supprimer une valeur d'attribut d'un retailer
/*
 Exemple de variables
  {
    "id" : "/api/retailer-datas/1"
  }
*/
export const DELETE_RETAILER_DATA = gql`
mutation DeleteRetailerData($id: ID!){
	deleteRetailerData(input: {id:$id}){
    retailerData{
      id
    }
  }  
}`;





//----- Récupération des données retailers products
/*
Exemple de variables
  {
  "retailer":"/api/retailers/1"
  }
*/
export const GET_RETAILER_PRODUCT_DATAS = gql`
query getRetailerProductDatas($retailer:String, $nbperpage: Int, $cursor: String, $cursorLast: String){
  retailerProductDatas(retailer:$retailer, first: $nbperpage, after: $cursor, before: $cursorLast){
    edges{
      node{
        product{
          id
        	sku
        }
        price
        specialPrice
        specialPriceFrom
        specialPriceTo
        currency{code}
        rateGroup{code}
        selection{identifier}
        updatedAt
      }
    }
    pageInfo{
      startCursor
      endCursor
    }
    totalCount
  }
}
`;

