import { Checkbox, FormControlLabel } from "@mui/material";
import styled from "styled-components";
import colors from "../../../../../../config/theme/colors";

export const FormControlLabelCustom = styled(FormControlLabel)`
  margin: 0px;
  padding-left: 55px;

  &:nth-child(odd) {
    background-color: ${colors.grey.lighter.hue980};
  }
`;

export const CheckboxCustom = styled(Checkbox)`
  &.Mui-checked + span {
    color: ${colors.blue.darker.hue300};
    font-weight: bold;
  }
`