import { gql } from '@apollo/client';

//----- Récupération des Magento Top Header Message
export const GET_TOP_HEADER_MESSAGE = gql`
{
  assetMagentoTopHeaders{
    edges{
      node{
        id
        asset{
          id
        }
        message
        link
        startDate
        endDate
        status
      }
    }
  }
}
`;

//----- Récupération des Magento Top Header Message
export const GET_TOP_HEADER_MESSAGE_PAGINATION = gql`
query getMagentoTopHeadersPagination($nbperpage: Int, $cursor: String, $cursorLast: String, $asset: String){
  assetMagentoTopHeaders(first: $nbperpage, after: $cursor, before : $cursorLast asset: $asset){
    pageInfo{
      startCursor
      endCursor
    }
    totalCount
    edges{
      node{
        id
        asset{
          id
        }
        message
        link
        startDate
        endDate
        status
      }
    }
  }
}
`;

//----- Ajouter un Magento Top Header Message
/*
{
  "asset": "/api/assets/1",
  "message": "TEST",
  "link" : "https://www.sinfin.fr",
  "startDate": "2021-07-01",
  "endDate": "2021-07-31",
  "status": false
}
 */
export const ADD_TOP_HEADER_MESSAGE = gql`
mutation createAssetMagentoTopHeader($asset: String!, $message: String, $link: String, $startDate: String, $endDate: String, $status: Boolean!) {
  createAssetMagentoTopHeader(input:{asset: $asset, message: $message, link: $link, startDate: $startDate, endDate: $endDate, status: $status}){
    assetMagentoTopHeader{
        id
        asset{
          id
        }
        message
        link
        startDate
        endDate
        status
    }
  }
}
`;

//----- Modifier un Magento Top Header Message
/*
{
  "id" : "/api/asset-magento-top-headers/1",
  "asset": "/api/assets/1",
  "message": "TEST",
  "link" : "https://www.sinfin.fr",
  "startDate": "2021-07-01",
  "endDate": "2021-07-31",
  "status": true
}
 */

export const UPDATE_TOP_HEADER_MESSAGE = gql`
mutation updateAssetMagentoTopHeader($id: ID!, $asset: String, $message: String, $link: String, $startDate: String, $endDate: String, $status: Boolean!) {
  updateAssetMagentoTopHeader(input:{id: $id, asset: $asset, message: $message, link: $link, startDate: $startDate, endDate: $endDate, status: $status}){
    assetMagentoTopHeader{
        id
        asset{
          id
        }
        message
        link
        startDate
        endDate
        status
    }
  }
}
`;
//----- Supprimer un Magento Top Header Message
/*
{
  "id" : "/api/asset-magento-top-headers/1",
}
 */
export const DELETE_TOP_HEADER_MESSAGE = gql`
mutation deleteAssetMagentoTopHeader($id: ID!) {
  deleteAssetMagentoTopHeader(input:{id: $id}){
    assetMagentoTopHeader{
        id
    }
  }
}
`;