import React, { useEffect }  from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Grid, MenuItem, Divider, CircularProgress } from '@mui/material';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import * as moment from 'moment';
import 'moment-timezone';

import SyncIcon from '@mui/icons-material/Sync';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

import StatusInfo from '../../../ui/status-info/StatusInfo';
import { withTranslation } from 'react-i18next';
import ImgNotFound from '../../../../assets/images/image-fake-card.png';
import TextEllipsis from './TextEllipsis';
import OurMenu from '../../../ui/menu/Menu';


const ContainerImage = styled(Box)`
    border: 1px solid ${colors.grey.lighter.hue700};
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    
`
const ContainerStyled = styled(Grid)`
`
const CatalogDescription = styled(Typography)`
    display: flex;
    flex-direction: row;
    min-height: 34px;
    overflow: hidden;
    font-size: 13px;
    line-height: 17px;
    margin-top: 4px;
    span{
        white-space: nowrap;
    }
`
const Libelle = styled(Typography)`
    font-size: 25px;
    line-height: 1.5;
    font-weight: bold;
    max-height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
`

const WrapperAttr = styled(Grid)`
    flex-flow:nowrap;
    &:not(:last-child){
        margin-bottom: 8px;
    }
    > p:first-child{
        min-width: 150px;
    }
    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
`
const MenuItemCustom = styled(MenuItem)`
    &:hover, &:focus{
        background: ${props => props.colorhover} !important;
        span{
            color: white !important;
        }
        svg{
            fill:white !important;
        }
    }
`

const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`
const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor};
    svg circle{
        stroke: ${props => props.statuscolor};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor};
    }
`;

const useStyles = makeStyles((theme) => ({
    editIcon: {
        position: 'absolute',
        color: colors.black.regular,
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 25,
        top: -10,
        right: 10,
        transition: 'all .5s',
        display: 'none',
    },
    menu: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    paper: {
        border: '1px solid #d3d4d5',
    }
}));

function CardCatalog(props){
    const [nbProducts, setNbProducts] = React.useState(0);
    const [catalogData, setCatalogData] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const open = Boolean(anchorEl);

    const classes = useStyles();

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const goTo = (route, id) => {
        props.history.push({
            pathname : route,
            state: {catalogId : id}
        });
    };

    let calcProduct = 0;

    useEffect(() => {
        if (catalogData !== props.data){
            let toDateStart = moment(props.data.startAt).format('L');
            let toDateEnd = moment(props.data.endAt).format('L');

            setCatalogData(props.data);
            setStartDate(toDateStart);
            setEndDate(toDateEnd);

            if (props.nbProducts){
                setNbProducts(props.nbProducts);
            }else{
                // TODO OPTIMISATION CATEGORIES
                // if(props.data.node.categories?.length === 0){
                //     setNbProducts(0);
                // }
                // else{
                //     for (let item of props.data.node.categories){
                //         if(item.productCategories?.totalCount){
                //             calcProduct = calcProduct + item.productCategories.totalCount;
                //             setNbProducts(calcProduct);
                //         }
                //     }
                // }
            }
        }
    });

    const getMediaPath = (image)=>{
        if (typeof image === "object"){
            return image[props.filePath]
        }
        return image
    }

    return(
        catalogData ?
        (

            <Box width={"100%"} style={{cursor: props.inputCard ? 'inherit' : 'pointer'}} height={'100%'}
                //  onClick={props.inputCard ? null : () => goTo(ROUTE_CATALOGS_DETAIL.replace(':id', catalogData.id.replace('/api/catalogs/', '')))}
                onClick={() => props.redirectDetail(catalogData.id)}
            >

                <Box padding={'16px 16px 22px 16px'} height={'100%'} style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Box style={{flex: '1 1 auto'}}>
                        <ContainerImage height={210} backgroundimage={catalogData[props.image] ? `${process.env.REACT_APP_MEDIAS}/${catalogData[props.image]}`: ImgNotFound} />
                        <Box mt={2} style={{ width: "100%"}}>
                            {/* <ContainerStyled 
                                container 
                                direction="row"
                                justifyContent="space-between"
                                alignItems={props.openForm ? "flex-start" : "center"}
                                style={{marginTop: 19}}
                            >
                                {
                                    process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                        <Grid item xs={props.openForm ? 8 : 10}> 
                                            <Grid container alignItems="center">
                                                <div style={{minHeight: 15, width: '44%'}} />
                                            </Grid>
                                        </Grid>
                                    : null
                                }
                            </ContainerStyled> */}
                            <Grid container justifyContent='space-between' alignItems='flex-start'>
                                <Libelle variant="h4" colortext={colors.black.regular}>
                                    {catalogData[props.title]}
                                </Libelle>
                                <Box mt={'10px'}>
                                    <OurMenu menuOptions={props.dottedMenuOptions} id={props.data.id} />
                                </Box>          
                            </Grid>
                            <CatalogDescription variant="body1" component="div">                            
                                <TextEllipsis title={catalogData[props.description]} text={catalogData[props.description]} maxLine={2} ellipsis={'...'} hasModal={false}/>
                            </CatalogDescription>
                        </Box>
                        {
                            props.attributes?.length > 0?
                            <Divider sx={{marginBottom:2}} />
                            :null
                        }
                        <Box pb={3} style={{width: "100%"}}>
                            <div style={{display:"flex"}}>
                                <div
                                    style={{
                                        width:props.showPercent ? '75%':'100%'
                                    }}
                                >
                                    {
                                        props.attributes?.length > 0?
                                        props.attributes.map((attribute,i)=>{
                                        return (
                                            <WrapperAttr container key={i}>
                                                {
                                                    attribute.type === "status"?
                                                    <>
                                                        <Typography>{attribute.label} :</Typography>
                                                        <Box style={{marginLeft:'3px'}}>
                                                            <StatusInfo status={catalogData[attribute.value]} width={'auto'} fontSize={14}/>
                                                        </Box>
                                                    </>
                                                    :attribute.type === "autoSync"?
                                                        <>
                                                            <Typography>{attribute.label} :</Typography>
                                                            {
                                                                catalogData[attribute.value] ? (
                                                                    <div style={{width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                        <SyncIcon style={{fill: colors.green.regular, fontSize: '1.2rem'}} />
                                                                        <Typography variant="body1" component="div" style={{color: colors.green.regular, paddingLeft: 4, fontSize: 14, lineHeight: '19px', fontWeight: 'bold'}}>
                                                                            {props.t("catalogs.catalogs.synchroProduct")}
                                                                        </Typography>
                                                                    </div>
                                                                ) : (
                                                                    <div style={{width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                        <SyncProblemIcon style={{fill: colors.orange.regular, fontSize: '1.2rem'}} />
                                                                        <Typography variant="body1" component="div" style={{color: colors.orange.regular, paddingLeft: 4, fontSize: 14, lineHeight: '19px', fontWeight: 'bold'}}>
                                                                            {props.t("catalogs.catalogs.noSynchroProduct")}
                                                                        </Typography>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    :<>
                                                        <Typography>{attribute.label} :</Typography>
                                                        <Typography fontWeight={"bold"}>{catalogData[attribute.value]}</Typography>
                                                    </>
                                                }
                                            </WrapperAttr>
                                        )
                                        })
                                        :null
                                    }
                                </div>
                                {
                                    props.showPercent?
                                    <div
                                    style={{
                                        width:"25%"
                                    }}
                                    >
                                        <BoxCustom position="relative" display="flex" justifyContent={"center"} statuscolor={colors.green.regular}>
                                        <CircularProgress
                                            variant="determinate"
                                            value={100}
                                            disableShrink
                                            thickness={2.5}
                                            size={60}
                                        />
                                        <Box
                                            top={0}
                                            left={0}
                                            bottom={0}
                                            right={0}
                                            position="absolute"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography variant="h2" style={{color: colors.green.regular, fontWeight: 'bold',fontSize:15}}>
                                                {catalogData[props.percent] > 0 ? `${Math.round(catalogData[props.percent])}%` : `N/A`}
                                            </Typography>
                                        </Box>
                                    </BoxCustom>
                                    </div>
                                    :null
                                }
                        
                            </div>
                            {
                                props.secondAttributes&&props.secondAttributes?.length > 0?
                                <Divider sx={{marginBottom:2,marginTop:2}}/>
                                :null
                            }
                            {
                                props.secondAttributes&&props.secondAttributes?.length > 0?
                                props.secondAttributes.map((attribute,i)=>{
                                return (
                                    <WrapperAttr container key={i}>
                                        {
                                            attribute.type === "status"?
                                            <>
                                                <Typography>{attribute.label} :</Typography>
                                                <Box style={{marginLeft:'3px'}}>
                                                    <StatusInfo status={catalogData[attribute.value]} width={'auto'} fontSize={14}/>
                                                </Box>
                                            </>
                                            :attribute.type === "autoSync"?
                                                <>
                                                    <Typography>{attribute.label} :</Typography>
                                                    {
                                                        catalogData[attribute.value] ? (
                                                            <div style={{width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                <SyncIcon style={{fill: colors.green.regular, fontSize: '1.2rem'}} />
                                                                <Typography variant="body1" component="div" style={{color: colors.green.regular, paddingLeft: 4, fontSize: 14, lineHeight: '19px', fontWeight: 'bold'}}>
                                                                    {props.t("catalogs.catalogs.synchroProduct")}
                                                                </Typography>
                                                            </div>
                                                        ) : (
                                                            <div style={{width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                <SyncProblemIcon style={{fill: colors.orange.regular, fontSize: '1.2rem'}} />
                                                                <Typography variant="body1" component="div" style={{color: colors.orange.regular, paddingLeft: 4, fontSize: 14, lineHeight: '19px', fontWeight: 'bold'}}>
                                                                    {props.t("catalogs.catalogs.noSynchroProduct")}
                                                                </Typography>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            :attribute.type === "array"?
                                            <>
                                                <Typography>{attribute.label} :</Typography>
                                                <TextEllipsis 
                                                    maxLine={2} 
                                                    width={"100%"} 
                                                    text={catalogData[attribute.value]?.length > 0 ? catalogData[attribute.value].join(" ,") : "-"} 
                                                    ellipsis={'...'} 
                                                    hasModal={false}
                                                    color={colors.black.regular}
                                                    style={{fontWeight:"bold"}}
                                                />
                                                {/* <Typography style={{whiteSpace:"nowrap",textOverflow:"hidden",}} fontWeight={"bold"}>{catalogData[attribute.value]?.length > 0 ?catalogData[attribute.value].map((item)=>item).join(",") : "-"}</Typography> */}
                                            </>
                                            :<>
                                                <Typography>{attribute.label} :</Typography>
                                                <Typography fontWeight={"bold"}>{catalogData[attribute.value]}</Typography>
                                            </>
                                        }
                                    </WrapperAttr>
                                )
                                
                                })
                                :null
                            }
                            {/* <WrapperAttr container>
                                <Typography>{props.t("products.list.cardproduct.status")} :</Typography>
                                <Box style={{marginLeft:'3px'}}>
                                    <StatusInfo status={catalogData[props.description]} width={'auto'} fontSize={14}/>
                                </Box>
                            </WrapperAttr> */}
                            {/* <WrapperAttr container>
                                <Typography>{props.t("catalogs.catalogs.synchro")} :</Typography>
                                {
                                    catalogData.autosync ? (
                                        <div style={{width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <SyncIcon style={{fill: colors.green.regular, fontSize: '1.2rem'}} />
                                            <Typography variant="body1" component="div" style={{color: colors.green.regular, paddingLeft: 4, fontSize: 14, lineHeight: '19px', fontWeight: 'bold'}}>
                                                {props.t("catalogs.catalogs.synchroProduct")}
                                            </Typography>
                                        </div>
                                    ) : (
                                        <div style={{width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <SyncProblemIcon style={{fill: colors.orange.regular, fontSize: '1.2rem'}} />
                                            <Typography variant="body1" component="div" style={{color: colors.orange.regular, paddingLeft: 4, fontSize: 14, lineHeight: '19px', fontWeight: 'bold'}}>
                                                {props.t("catalogs.catalogs.noSynchroProduct")}
                                            </Typography>
                                        </div>
                                    )
                                }
                            </WrapperAttr> */}
                        </Box>
                    </Box>
                    
                    <Grid
                        container 
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                            flex: '0 0 auto'
                        }}
                    >
                        {
                            !props.configCard ? 
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Box width={"100%"}>
                                            <Button 
                                                text={props.textButton} 
                                                style={{padding: "14px 32px", margin: 0, width: "100%"}}
                                                disableElevation={true}  
                                                onClick={() => props.redirectDetail(catalogData.id)}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid> 
                            : null
                        }
                    </Grid>
                </Box>
                {/* Delete modal */}
                {/* <DialogModal
                    open={openDeleteModal}
                    icon={true}
                    type='delete'
                    title={'Êtes-vous sûr de vouloir supprimer ce catalogue ?'}
                    primaryAction={(event) => {
                        event.stopPropagation();
                        props.actionButtonDelete(props.data.node.id);
                        setOpenDeleteModal(false);
                        event.stopPropagation();
                    }}
                    secondaryAction={(event) => {
                        event.stopPropagation();
                        setOpenDeleteModal(false);
                    }}
                    windowWidth={props.windowWidth}
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce catalogue ? <strong>Cette action est irréversible</strong></Typography>
                </DialogModal> */}

                </Box>
        )
        : null
    )
}

export default withTranslation()(withRouter(CardCatalog));
