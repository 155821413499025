import { GridCustom, ToolbarRightSide } from "../../styles/inputStyled";
import { Box, Grid, Button } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import ReactQuill from "react-quill";
import { Image, Twitter } from "@mui/icons-material";
import TypographyCustom from "../../../typography/Typography";
import SearchBarProductDynamic from "../../../inputs/SearchBarProductDynamic";
import { IMAGES } from "../../../../../js/constants/medias-types";
import Translation from "../../../../layouts/Translation/Translation";
import RichDescription from "../../../../layouts/RichDescription/RichDescription";
import CheckSpelling from "../../../../layouts/CheckSpelling/CheckSpelling";
import mediaPickerConfig from "../../config/mediaPicker.config";
import twitterLinkConfig from "../../config/twitterLink.config";
import LayoutBuilder from "../../LayoutFormBuilder";
import { useState } from "react";
import { formats, modules } from "./config/TextArea.config";
import { ReactQuillCustom } from "./styles/styled";
import OurLock from "../../../OurLock/OurLock";
import { InputLabel } from "@mui/material";

const TextArea = ({
  allProps: props,
  seeError,
  errorMessage,
  setSeeError,
  checkError,
  getProductSku,
  setOpenMediaPicker,
  openMediaPicker,
  lock,
}) => {
  const [openTwitterForm, setOpenTwitterForm] = useState(false);
  const [openProductPicker, setOpenProductPicker] = useState(false);
  const [quillIndex, setQuillIndex] = useState(null);
  const [quillInfos, setQuillInfos] = useState(null);

  // function insertProduct() {
  //     let editor = quillInfos.getEditor();
  //     const getIndex = editor.selection.savedRange;
  //     setQuillIndex(getIndex);
  //     setOpenProductPicker(true);
  // }

  function insertImage() {
    let editor = quillInfos.getEditor();
    const getIndex = editor.selection.savedRange;
    setQuillIndex(getIndex);
    setOpenMediaPicker(true);
  }

  function insertTwit() {
    let editor = quillInfos.getEditor();
    const getIndex = editor.selection.savedRange;
    setQuillIndex(getIndex);
    setOpenTwitterForm(true);
  }

  function getProductSku(value) {
    const refQuill = quillInfos.getEditor();
    const cursorPosition = quillIndex.index;
    const textToInsert = `<product sku="${value.sku}" idProduct="${value.id}"/> `;
    refQuill.insertText(cursorPosition, textToInsert);
    refQuill.setSelection(cursorPosition + textToInsert.length);
  }

  if (!window.delays) window.delays = {};

  return (
    <GridCustom item xs={12}>
      {(props.input.label && props.input.usedAlone) && (
        <InputLabel sx={{ fontSize: "14px", marginBottom: "5px" }}>
          {props.input.label}
        </InputLabel>
      )}
      <Drawer
        transitionDuration={400}
        anchor="right"
        open={openProductPicker}
        onClose={() => setOpenProductPicker(false)}
        style={{ minWidth: 300 }}
      >
        <Grid container style={{ width: 300, padding: 20 }}>
          <TypographyCustom variant="body2" colortext="#333333">
            Choisir le produit à importer :
          </TypographyCustom>
          <SearchBarProductDynamic
            key={`ProductSelector`}
            allProducts={true}
            isMulti={false}
            currentLang={"fr_FR"}
            style={{
              position: "relative",
              top: 15,
              marginBottom: 5,
              width: 300,
            }}
            onSelect={(value) => {
              setOpenProductPicker(false);
              getProductSku(value);
            }}
          />
        </Grid>
      </Drawer>
      <div style={{ position: "relative" }}>
        <ToolbarRightSide>
          {props.input.isContent ? (
            <Button
              className="spread-insert-twit"
              style={{ width: "auto", minWidth: "auto" }}
              onClick={() => insertTwit()}
            >
              <Twitter style={{ width: 20, height: 20 }} />
            </Button>
          ) : null}

          {props.input.isContent ? (
            <Button
              className="spread-insert-image"
              style={{ width: "auto", minWidth: "auto" }}
              onClick={() => insertImage()}
            >
              <Image style={{ width: 20, height: 20 }} />
            </Button>
          ) : null}
          {props.input.translation &&
          props.allState?.currentLang !== "fr_FR" &&
          process.env.REACT_APP_HIDE_TRADUCTION !== "true" ? (
            <Translation
              value={props.value}
              stateCallback={props.stateCallback}
              currentLang={props.allState?.currentLang}
            />
          ) : null}

          {/* --- Disabled until we fix / stabilize it */}
          {/* {
                                props.input.isContent ?
                                    <Button className="spread-insert-product" style={{width: "auto", minWidth: 'auto'}} onClick={() => insertProduct()}>
                                        <LocalOffer style={{width: 20, height: 20}}/>
                                    </Button>
                                : null
                            } */}

          {/* --- Disabled until we fix / stabilize it */}
          {/* <SpeechComponent value={props.value || ""} callback={props.stateCallback}/> */}
        </ToolbarRightSide>
      </div>
      <Box position={"relative"} sx={{
        marginBottom: 2,
      }}>
        <ReactQuillCustom
          className={seeError && errorMessage ? "error" : ""}
          value={props.value || ""}
          key={"quill-editor"}
          theme="snow"
          modules={props.input.modules ?? modules}
          formats={formats}
          readOnly={props.input.disabled}
          ref={(el) => {
            setQuillInfos(el);
          }}
          onChange={(content, delta, source, editor) => {
            clearTimeout(window.delays[props.input.stateName]);
            window.delays[props.input.stateName] = setTimeout(() => {
              props.stateCallback(content);
              setSeeError(true);
              checkError(content);
            }, 200);
          }}
          placeholder={props.input.helper.text}
        />
        <Grid
          container
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "auto",
          }}
        >
          <Box position={"relative"} pr={"50px"} height={"42px"}>
            {/* Add relative blocks... */}
            <OurLock
              lock={lock}
              style={{
                position: "absolute",
                top: "-7px",
                right: 0,
              }}
            />
          </Box>
        </Grid>
      </Box>

      <Box
        style={{
          display: "flex",
          justifyContent: "end",
          gap: 20,
        }}
      >
        {props.input.richText &&
        process.env.REACT_APP_HIDE_CHAT_GPT !== "true" ? (
          <RichDescription
            value={props.value}
            stateCallback={props.stateCallback}
            sku={props.allState?.sku}
          />
        ) : null}
        {props.input.checkSpelling ? (
          <CheckSpelling
            value={props.value}
            stateCallback={props.stateCallback}
          />
        ) : null}
      </Box>
      
      <LayoutBuilder
        opened={openMediaPicker}
        forClose={() => setOpenMediaPicker(false)}
        dataLayout={mediaPickerConfig(
          (value) => {
            const image = `${process.env.REACT_APP_MEDIAS}/${value?.filePath}`;

            const refQuill = quillInfos.getEditor();
            const cursorPosition = quillIndex.index;

            refQuill.editor.insertEmbed(cursorPosition, "image", image);

            let content = refQuill.root.innerHTML;

            props.stateCallback(content);
            setOpenMediaPicker(false);
          },
          false,
          false,
          false,
          false,
          IMAGES
        )}
        isBuilder={true}
      />

      {props.allState?.twitterURL && (
        <LayoutBuilder
          opened={openTwitterForm}
          allState={props.allState}
          stateCallback={(stateName, value) => {
            props.stateCallbackOriginal(stateName, value?.target?.value);
          }}
          forClose={() => setOpenTwitterForm(false)}
          handlerMutation={() => {
            const refQuill = quillInfos.getEditor();
            const cursorPosition = quillIndex.index;

            refQuill.editor.insertEmbed(cursorPosition, "twitter", {
              url: props.allState.twitterURL,
            });

            // refQuill.clipboard.dangerouslyPasteHTML(cursorPosition, props.allState.twitterURL);

            let body = refQuill.root.innerHTML;

            props.stateCallback(body);
            props.stateCallbackOriginal("twitterURL", "");
            setOpenTwitterForm(false);
          }}
          validateButton={true}
          dataLayout={twitterLinkConfig(props.allState.twitterURL)}
          isBuilder={true}
        />
      )}

      {!seeError ? null : <span className="error-label">{errorMessage}</span>}
    </GridCustom>
  );
};

export default TextArea;
