import React from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import colors from '../../../config/theme/colors';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import OurTypography from '../typography/Typography';

const CustomSelect = styled(Select)`
    cursor:pointer;
    padding:0;
    fieldset{
        border-width:0 !important;
    }
    .MuiSelect-select {
        padding:0;
        padding-right: 20px !important;
        color:black;
    }
    .MuiSvgIcon-root {
        right: 0px;
    }
    .MuiModal-root .MuiPaper-root{
        margin-top : 5px;
        .MuiList-root{
            padding:0;
        }
    }
`;

const CustomDivider = styled(Divider)`
    margin-top:0px !important;
    margin-bottom:0px !important;
`;

const CustomMenuItemActive = styled(MenuItem)`
    background-color: transparent !important;
    font-weight:bold !important;
`;

const CustomMenuItem = styled(MenuItem)`
    &:hover{
        background-color: ${colors.blue.lighter.hue900};
        color:${colors.blue.darker.hue300};
    }
`;


export default function SelectLang(props){
    const {perPageOptions,changePerPageCallback,currentPerPage,handleViews,views } = props;

    const selectLang = {
        type: 'select',
        isContainerized: true,
        label: 'Langue',
        helper: 'Langue',
        required: false,
        stateName: 'currentLang',
        value: 
            props.locales.map((locale) => {
                return(
                    {
                        value: locale.node.code,
                        label: locale.node.libelle
                    }
                )
            })
    };

    return(
        <FormControl sx={{display:"flex",alignItems:"center",flexDirection:"row",gap:"5px"}}>
            <OurTypography>Langue : </OurTypography>
            <CustomSelect
                autoWidth
                value={props.currentLang ? props.currentLang : selectLang?.value[0].value ?selectLang?.value[0].value: "-1"}
                renderValue={(value)=><OurTypography fontWeight={'bold'}>{selectLang?.value.find(e=>e.value === value).label.substring(0,2)}</OurTypography>}
                onChange={props.handleLang}
                IconComponent={(props)=>{return <ExpandMoreIcon className={props.className} fontSize="small" />}}
                MenuProps={{
                    sx:{
                        marginTop:"5px"
                    },
                    PaperProps:{
                        sx:{
                            boxShadow:"none",
                            border:`0.5px solid ${colors.grey.border}`
                        },  
                    },
                    MenuListProps: { 
                        disablePadding: true ,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: 5,
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
            >
                {selectLang?.value.map(option => (
                    props.currentLang ===option?.value ||selectLang?.value[0].value === option?.value?
                    <CustomMenuItemActive key={option?.value} value={option?.value}>
                        {option?.label}
                    </CustomMenuItemActive>
                    :<CustomMenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                </CustomMenuItem>
                ))}
            </CustomSelect>
        </FormControl>
    );
}