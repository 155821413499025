import React, { useState, useEffect } from "react";
import PG from '../../../../shared/components/CustomParagraph';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';

const Category = ({
    preview = false,
    id = null,
    data = null,
    inputCallback = null,
    children = null,
    spread = false,
    products = [],
    params = null,
    isCroppable = false
}) => {
    const [hover, setHover] = useState(false);
    const [muted, setMuted] = useState(false);
    const [switchVolumeIcon, setSwitchVolumeIcon] = useState(false);

    const styles = {
        backgroundColor: '#212121',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box'
    };

    let image = data.blocks["media"]?.inputs.image?.value;
    let video = data.blocks["media"]?.inputs.video?.value;
    let switchValue = data.blocks["media"]?.inputs.switch.value;
    
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         const video = document.querySelector('.video');
    //     },500)
    //     return () => {
    //         clearTimeout(timer)
    //     }
    // }, [])

    // const handleVolumeOff = () => {
    //     if(switchVolumeIcon === false) {
    //         setSwitchVolumeIcon(true);
    //         // setMuted(video.props.muted);
    //     } else {
    //         setSwitchVolumeIcon(false);
    //     }
    // };
    
    return (
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children }

            <div className="text-image">
                <div className="text-image-content">
                    <div className="col-12 px-0 text-right">
                        {switchValue ?
                            <div className="video">
                                <video id="video" controls loop muted autoPlay width="100%">
                                    <source src={`${process.env.REACT_APP_MEDIAS}/${image?.filePath}`} alt="first" type="video/mp4" />
                                    Désoler, votre navigateur ne supporte pas les vidéos intégrées.
                                </video>
                                {/* <button className="volume" onClick={handleVolumeOff}>
                                    {switchVolumeIcon ? 
                                    <VolumeUpIcon className="volume-svg" />
                                    :
                                    <VolumeMuteIcon className="volume-svg" />
                                    }
                                </button> */}
                            </div>
                            :
                            <div className="video">
                                <iframe 
                                    width="100%" 
                                    height="100%" 
                                    src={video ? `https://www.youtube.com/embed/${video}` : null}
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen
                                />
                            </div>
                        }
                    </div>
                    <div className="col-12 text">
                        <PG data={data} preview={preview} component={id} block={'paragraph_1'} inputCallback={inputCallback} />
                    </div>
                </div>
            </div>
        </div>
    );
    
};

export default Category;