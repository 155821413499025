import React from 'react';
import Grid from '@mui/material/Grid';
import './OfferDisplay.scss';
import imgNotFound from '../../../../../assets/images/not-found.png';
import * as moment from "moment";
import { getTotals } from './Calculator';

class OfferDisplay extends React.Component {
    render() {
        const { phases, listTaxes, isModel, model, offer, listProjects, projectSelected } = this.props;

        let allPhases = phases.filter(e => !e.deleted);

        let { ht, ttc, totals, remises } = getTotals(allPhases, listTaxes);

        let project = null;

        if (!isModel)
            project = listProjects.find(e => e.node.id === projectSelected);

        return (
           <div className="offer-display">
                {
                    isModel ? (
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={9} className="header-item">
                                    <h2>{ model?.name ?? "Nom du modèle" }</h2>
                                    <div dangerouslySetInnerHTML={{ __html: model?.description ?? "Description du modèle" }}></div>
                                </Grid>
                                <Grid item xs={3}>
                                    <img src={model?.imageDevis?.filePath ? `${process.env.REACT_APP_MEDIAS}/${model.imageDevis.filePath}` : imgNotFound} className="modele-image" />
                                </Grid>
                            </Grid>

                            <hr />
                        </div>
                    ) : (
                        <div>
                             <Grid container spacing={1}>
                                <Grid item xs={6} className="offer-item">
                                    <div>DEVIS 4165454</div>
                                    <div className="offer-blue">Offre site internet</div>
                                    <div className="offer-blue">Boutique Levi's</div>
                                </Grid>
                                <Grid item xs={6} className="offer-project">
                                    <div className="offer-address">
                                        <div>{ project?.node.name }</div>
                                        <div>6, rue Petite Fusterie</div>
                                        <div>29200 BREST</div>
                                    </div>
                                  
                                    <img src={project?.node.media?.filePath ? `${process.env.REACT_APP_MEDIAS}/${project.node.media.filePath}` : imgNotFound} className="modele-image" />
                                </Grid>
                            </Grid>

                            <hr />

                            <Grid container spacing={1}>
                                <Grid item xs={6} className="offer-item">
                                    <div className="offer-title">{ offer?.name ?? "Nom de l'offre" }</div>
                                    <span className="offer-date">{ offer?.createdAt ? moment(offer.createdAt).format('DD.MM.YYYY') : "Date de création" }</span>
                                </Grid>
                                <Grid item xs={6} className="offer-po">
                                    <span className="offer-title">{ offer?.poNumber ?? "N°PO" }</span>
                                </Grid>
                                <Grid item xs={12} className="offer-description">
                                    <div dangerouslySetInnerHTML={{ __html: offer?.description ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent luctus fermentum libero a consectetur. Fusce et enim sit amet purus imperdiet porta vitae ac lacus. Maecenas euismod ex nibh. Aliquam dignissim ante ac consectetur consectetur. Donec non sem efficitur, condimentum turpis quis, pharetra nisi." }}></div>
                                </Grid>
                            </Grid>

                            <hr />
                        </div>
                    )
                }

                { 
                    allPhases.map((phase, i) => {
                        let allTaches = phase.taches.filter(e => !e.deleted);

                        return (
                            <div key={`task-${i}`} className="phase">
                                <div className="phase-name">
                                    { phase.name }
                                </div>

                                <Grid container spacing={3} className="phase-headers">
                                    <Grid item xs={4}>
                                        Référence
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={2}>
                                                QTÉ
                                            </Grid>
                                            <Grid item xs={2}>
                                                PU HT
                                            </Grid>
                                            <Grid item xs={2}>
                                                TVA
                                            </Grid>
                                            <Grid item xs={2}>
                                                REM.
                                            </Grid>
                                            <Grid item xs={4}>
                                                T. HT
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {
                                    allTaches.map((tache, j) => {
                                        let tva = listTaxes.find(e => e.node.id === tache.tva)?.node.value ?? "n/a";
                                        let allSubTaches = tache.taches.filter(e => !e.deleted);

                                        return (
                                            <div key={`task-${j}`} className="phase-task">
                                                 <Grid container spacing={3}>
                                                    <Grid item xs={4}>
                                                        { tache.name }
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={2}>
                                                                { tache.qty }
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                { tache.price }
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                { tva }
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                { tache.remise } { tache.typeRemise }
                                                            </Grid>
                                                            <Grid item xs={4} className="total-text">
                                                                { (+tache.total)?.toFixed(2) } 
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {
                                                    allSubTaches.map((subtask, k) => { 
                                                        let tva = listTaxes.find(e => e.node.id === tache.tva)?.node.value ?? "n/a";

                                                        return (
                                                            <Grid container spacing={3} key={`task-${k}`} className="phase-subtask">
                                                                <Grid item xs={4}>
                                                                    <span className="subtask-name">{ subtask.name }</span>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={2}>
                                                                            { subtask.qty }
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            { subtask.price }
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            { tva }
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            { subtask.remise } { subtask.typeRemise }
                                                                        </Grid>
                                                                        <Grid item xs={4} className="total-text">
                                                                            { (+subtask.total)?.toFixed(2) }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )
                    })
                }

                <div className="totals">
                    <div className="totals-container">
                        <div className="total-row">
                            <div className="total-item">
                                Total HT
                            </div>
                            <div className="total-item">
                                { ht.toFixed(2) } €
                            </div>
                        </div>

                        {
                            totals.map((total, i) => (
                                <div className="total-row" key={i}>
                                    <div className="total-item">
                                        { total.tax.name }
                                    </div>
                                    <div className="total-item">
                                        { total.total.toFixed(2) } €
                                    </div>
                                </div>
                            ))
                        }

                        <div className="total-row">
                            <div className="total-item">
                                Remises
                            </div>
                            <div className="total-item">
                                { remises.toFixed(2) } €
                            </div>
                        </div>

                        <div className="total-row">
                            <div className="total-item">
                                Total TTC
                            </div>
                            <div className="total-item">
                                { ttc.toFixed(2) } €
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OfferDisplay;