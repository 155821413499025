import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from '@apollo/client/react/hoc';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';

import CardCampaignDetails from '../../../layouts/Card/cardContent/CardCampaignDetails';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formCampaignAdd from './config/formCampaignAdd.config';
import colors from '../../../../config/theme/colors';
import TopPanel from '../../../layouts/TopPanel/TopPanel';

import * as moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Box } from '@mui/material';
import Button from '../../../ui/button/Button';
import styled from 'styled-components';

import { ROUTE_DIFFUSION_CAMPAIGNS, ROUTE_STATS_CAMPAIGNS, ROUTE_HOME } from '../../../../js/constants/route-names';
import { eventService } from '../../../../js/services/event.service';
import {
    GET_CAMPAIGN,
    GET_CAMPAIGN_BY_ID,
    UPDATE_CAMPAIGN,
    DELETE_CAMPAIGN
} from '../../../../queries/campaigns';
import { GET_CATALOGS_PAGINATION, GET_CATALOGS_CURSOR } from '../../../../queries/catalogs';
import { GET_ASSETS_PAGINATION, GET_ASSETS_CURSOR } from '../../../../queries/assets'
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';
import { GET_RETAILERS_CURSOR, GET_RETAILERS_PAGINATION, } from '../../../../queries/retailers';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { BROADCAST, BROADCAST_CAMPAIGN, UPDATE, VIEW } from '../../../../js/constants/constant-rights';
import DialogModal from '../../../ui/dialog/DialogModal';
import { withTranslation } from 'react-i18next';

const BoxCustom = styled(Box)`
    width: auto;
`
const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

class DiffusionCampaignDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            imageSrc: [],
            campaignId: `/api/campaigns/${this.props.match.params.id}`,
            sku: null,
            title: '',
            categories: [],
            categoriesData: [],
            getAllCatalogs: [],
            getAllAssets: [],
            getAllRetailers: [],
            nbCatalog: 0,
            countPageCatalogs: 0,
            countPageAssets: 0,
            countPageRetailers: 0,
            nbperpageCatalogs: 4,
            nbperpageAssets: 4,
            nbperpageRetailers: 4,
            pageCatalog: 1,
            pageAsset: 1,
            pageRetailer: 1,
            cursorCatalogs: false,
            cursorRetailers: false,
            campaignImage: null,
            campaignName: null,
            campaignIdentifier: null,
            campaignDescription: null,
            campaignGTM: null,
            campaignStartAt: null,
            campaignEndAt: null,
            campaignStatus: true,
            catalogSelected: [],
            assetsSelected: [],
            retailersSelected: [],
            openForm: false,
            openDialog: false,
            allGroups: [],
            ready: false,
            errors: {},
            seeErrors: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.typingSearchTimer = null;
        this.typingTimer = null;
        this.typeTesting = 'campaign';
    }

    componentDidMount() {
        this.handleGetCursorsCatalogs();
        this.handleGetCursorsAssets();
        this.handleGetCursorsRetailers();
        this.initCampaign();
        this.handleGetRetailers();
    }

    handleToggleDrawer = (drawer) => {
        if (drawer === 'form') {
            this.setState({
                openForm: !this.state.openForm??false,
            });
        }
        this.setState({
            seeErrors: false
        });
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog??false
        });
    };

    doneTyping = (stateName) => {
        if (stateName === 'campaignName') {
            this.setState({
                campaignIdentifier: slugify(this.state.campaignName, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g })??null
            })
        }
        if (this.state.campaignIdentifier) {
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.campaignIdentifier}`, 'get')
            .then((data) => {
                if (data.success) {
                    eventService.fire({ stateName: 'campaignIdentifier', errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                }
            }).catch((err)=>{
                console.log(err)
            });
        }
        this.forceUpdate();
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            }, async () => {
                if (stateName === 'groupAttribut') {
                    await this.prepareAttributes();
                    this.prepareAttributeValues();
                }
            });
        }
        if (stateName === "campaignName" || stateName === 'campaignIdentifier') {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
        }
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    resetState() {
        this.handleGetCatalogs();
        this.handleGetAssets();
        this.setState({
            imageSrc: [],
            categories: [],
            errors: {}
        });
    }

    handleCancel = () => {
        this.resetState();
        this.initCampaign();
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    copy(array) {
        let newArray = [];

        for (let elem of array)
            newArray.push(Object.assign({}, elem));

        return newArray;
    }


    handleError = (e) => {
        this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.error"));
        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        await this.initCampaign();

        this.props.snack(ALERT_SUCCESS, this.props.t("spread.campaigns.campaginChanged"));

        this.handleToggleDrawer('form');
        this.resetState();

        this.props.stopLoading();

        // this.goTo(ROUTE_DIFFUSION_CAMPAIGNS);
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors:errors??null });
    };


    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                this.setState({ seeErrors: true });
                return eventService.fire();
            }


            this.props.startLoading();
            const UPDATE_CAMPAIGN_RESULT = await this.props.client.mutate({
                mutation: UPDATE_CAMPAIGN,
                variables: {
                    "id": this.state.campaignId,
                    "identifier": this.state.campaignIdentifier,
                    "media": this.state.campaignImage?.id,
                    "status": this.state.campaignStatus,
                    "libelle": this.state.campaignName,
                    "description": this.state.campaignDescription,
                    "gtm": this.state.campaignGTM,
                    "startAt": moment(this.state.campaignStartAt).format('YYYY-MM-DD'),
                    "endAt": moment(this.state.campaignEndAt).format('YYYY-MM-DD'),
                    "catalogs": this.state.catalogSelected.map((catalog) => catalog.node.id),
                    "assets": this.state.assetsSelected?.map((asset) => asset.node.id),
                    "retailers": this.state.retailersSelected?.map((retailer) => retailer.node.id)
                }
            });
            this.handleSuccess();
        } catch (e) {
            this.handleError(e);
        }
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null);
    }

    handleGetCursorsCatalogs = () => {
        this.props.client.query({
            query: GET_CATALOGS_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursorsCatalogs: result.data.catalogs.edges
            }, () => {
                this.handleGetCatalogs();
            });
        }).catch((err)=>{
            console.log(err)
        });
    }

    handleGetCatalogs = () => {
        return new Promise((resolve, reject) => {
            let variables =
            {
                "nbperpage": this.state.nbperpageCatalogs,
            };
            if (this.state.cursorCatalogs && this.state.listCursorsCatalogs && this.state.cursorCatalogs !== this.state.listCursorsCatalogs[0].cursor) {
                variables.cursor = this.state.cursorCatalogs;
            }
            variables.libelle = this.state.searchValue;
            variables.description = this.state.searchValue;

            this.props.client.query({
                query: GET_CATALOGS_PAGINATION,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    countPageCatalogs: Math.ceil(result.data.catalogs.totalCount / this.state.nbperpageCatalogs)??1,
                    getAllCatalogs: result.data.catalogs.edges,
                    noResult: result.data.catalogs.edges.length > 0 ? false : true,
                    readyCatalogs: true,
                })
                // this.handleAssetAdd();
                this.props.stopLoading();
                resolve();
            }).catch((err)=>{
                console.log(err)
            });
        });
    };

    handleGetCursorsAssets = () => {
        this.props.client.query({
            query: GET_ASSETS_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursorsAssets: result.data.assets.edges
            }, () => {
                this.handleGetAssets();
            });
        }).catch((err)=>{
            console.log(err)
        });
    }

    handleGetAssets = () => {
        return new Promise((resolve, reject) => {
            let variables =
            {
                "nbperpage": this.state.nbperpageAssets,
                // "assetType_list": this.state.assetTypeList, 
            };
            if (this.state.cursorAssets && this.state.listCursorsAssets && this.state.cursorAssets !== this.state.listCursorsAssets[0].cursor) {
                variables.cursor = this.state.cursorAssets;
            }
            variables.libelle = this.state.searchValue;
            variables.description = this.state.searchValue;
            this.props.client.query({
                query: GET_ASSETS_PAGINATION,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    countPageAssets: Math.ceil(result.data.assets.totalCount / this.state.nbperpageAssets)??null,
                    getAllAssets: result.data.assets.edges,
                    readyAssets: true,
                });
                this.props.stopLoading();
                resolve();
            }).catch((err)=>{
                console.log(err)
            });
        });
    }

    handleGetCursorsRetailers = () => {
        this.props.client.query({
            query: GET_RETAILERS_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursorsRetailers: result.data.retailers.edges
            }, () => {
                this.handleGetRetailers();
            });
        }).catch((err)=>{
            console.log(err)
        });
    }

    handleGetRetailers = () => {
        return new Promise((resolve, reject) => {
            let variables =
            {
                "nbperpage": this.state.nbperpageRetailers,
            };
            if (this.state.cursorRetailers && this.state.listCursorsRetailers && this.state.cursorRetailers !== this.state.listCursorsRetailers[0].cursor) {
                variables.cursor = this.state.cursorRetailers;
            }
            variables.libelle = this.state.searchValue;
            variables.description = this.state.searchValue;

            this.props.client.query({
                query: GET_RETAILERS_PAGINATION,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    countPageRetailers: Math.ceil(result.data.retailers.totalCount / this.state.nbperpageRetailers)??null,
                    getAllRetailers: result.data.retailers.edges,
                    readyRetailers: true,
                });
                this.props.stopLoading();
                resolve();
            }).catch((err)=>{
                console.log(err)
            });
        });
    }

    handleSearch = (type) => {
        this.props.startLoading();
        switch (type) {
            case 'catalog':
                this.handleGetCatalogs();
                break;
            case 'retailer':
                this.handleGetRetailers();
                break;
            default:
                return null;
            // case 'asset':
            //     index = ( page * this.state.nbperpageAssets) - this.state.nbperpageAssets -1
            // break;
            // default:
            //     index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
            // break;
        }
    }

    handleChange(e, type) {
        clearTimeout(this.typingSearchTimer);
        this.setState({ searchValue: e.target.value });
        this.typingSearchTimer = setTimeout(() => {
            this.handleSearch(type);
        }, 500);
    }

    deleteMutation = () => {
        this.props.startLoading();

        this.props.client.mutate({
            mutation: DELETE_CAMPAIGN,
            variables: { id: this.state.campaignId },
            refetchQueries: [{
                query: GET_CAMPAIGN,
            }]
        }).then(result => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, this.props.t("spread.campaigns.campaginDeleteSuccess"));
            this.goTo(ROUTE_DIFFUSION_CAMPAIGNS);
        }).catch((err)=>{
            console.log(err)
        });
    };

    changePage = (event, page, type) => {
        this.props.startLoading();
        let index = 0;
        if (page > 1) {
            switch (type) {
                case 'catalog':
                    index = (page * this.state.nbperpageCatalogs) - this.state.nbperpageCatalogs - 1
                    break;
                case 'retailer':
                    index = (page * this.state.nbperpageRetailers) - this.state.nbperpageRetailers - 1
                    break;
                case 'asset':
                    index = (page * this.state.nbperpageAssets) - this.state.nbperpageAssets - 1
                    break;
                default:
                    index = (page * this.state.nbperpage) - this.state.nbperpage - 1
                    break;
            }
        }

        switch (type) {
            case 'catalog':
                this.setState({
                    cursorCatalogs: this.state.listCursorsCatalogs[index].cursor??null,
                    pageCatalog: page??1,
                }, async () => {
                    await this.handleGetCatalogs();
                });
                break;
            case 'retailer':
                this.setState({
                    cursorRetailers: this.state.listCursorsRetailers[index].cursor??null,
                    pageRetailers: page??1,
                }, async () => {
                    await this.handleGetCatalogs();
                });
                break;
            case 'asset':
                this.setState({
                    cursorAssets: this.state.listCursorsAssets[index].cursor??null,
                    pageAsset: page??1
                }, () => {
                    this.handleGetAssets();
                });
                break;
            default:
                return null
        }
    };

    render() {
        const {
            campaignImage,
            campaignName,
            campaignIdentifier,
            campaignDescription,
            campaignGTM,
            campaignStartAt,
            campaignEndAt,
            campaignStatus
        } = this.state;

        let colorImport = {
            shadow: "#346D8D",
            background: colors.blue.darker.hue300,
            arrow: 'right'
        }
        return (
            <div>
                <TopPanel
                    icomoon="icon-campagne"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("spread.campaigns.manageMyCampaign")}
                    subtitle={this.props.t("spread.campaigns.makeChangeToCampaign")}
                    handlerAdd={() => this.handleToggleDrawer('form')}
                    textAdd={this.props.t("products.categories.modify")}
                    handlerImport={() => this.goTo(ROUTE_STATS_CAMPAIGNS)}
                    textImport={this.props.t("spread.campaigns.seeDashboard")}
                    colorImport={colorImport}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    ready={this.state.ready}
                    locales={this.props.locales}
                    hasBorder={true}
                />
                {
                    this.state.ready ? (
                        <>
                            <BoxCustom mt={3} ml={1} mb={1} onClick={this.props.history.goBack}>
                                <ReturnLink variant={'body2'}>&lt; {this.props.t("spread.active_assets.return")}</ReturnLink>
                            </BoxCustom>
                            {
                                <CardCampaignDetails
                                    campaign={{ campaignStatus, campaignImage, campaignName, campaignIdentifier, campaignDescription, campaignGTM, campaignStartAt, campaignEndAt }}
                                    routeCampaign={() => this.goTo(ROUTE_DIFFUSION_CAMPAIGNS)}
                                    openForm={this.state.openForm}
                                    imageAttributes={this.state.imageAttributes}
                                    currentLang={this.state.currentLang}
                                    allState={this.state}
                                    locales={this.props.locales}
                                    drawerWidth={this.props.drawerWidth}
                                />
                            }
                            <LayoutBuilder
                                isSublayout={false}
                                validateButton={true}
                                handleCancel={this.handleCancel}
                                opened={this.state.openForm}
                                forClose={() => { this.handleToggleDrawer('form') }}
                                handlerMutation={this.handlerMutation}
                                icomoon={'ico-modifier-campagne'}
                                paginationInfo={{
                                    changePage: this.changePage,
                                    catalog: {
                                        count: this.state.countPageCatalogs,
                                        page: this.state.pageCatalog
                                    },
                                    asset: {
                                        count: this.state.countPageAssets,
                                        page: this.state.pageAsset
                                    },
                                    retailer: {
                                        count: this.state.countPageRetailers,
                                        page: this.state.pageRetailer
                                    },
                                }}
                                dataLayout={formCampaignAdd(
                                    this.state.currentLang,
                                    this.state.getAllCatalogs,
                                    this.state.getAllAssets,
                                    this.state.getAllRetailers,
                                    this.handleMediaPicker,
                                    true
                                )}
                                stepperButtonAction={[
                                    () => {
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                            this.setState({
                                                seeErrors: true,
                                            });
                                            eventService.fire();
                                            return false;
                                        }
                                        this.setState({
                                            searchValue: ''
                                        });
                                        return true;
                                    },
                                    () => {
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                            this.setState({
                                                seeErrors: true,
                                            });
                                            eventService.fire();
                                            return false;
                                        }
                                        this.setState({
                                            searchValue: ''
                                        });

                                        return true;
                                    },
                                    () => {
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                            this.setState({
                                                seeErrors: true,
                                            });
                                            eventService.fire();
                                            return false;
                                        }
                                        this.setState({
                                            searchValue: ''
                                        });

                                        return true;
                                    },
                                    () => {
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                            this.setState({
                                                seeErrors: true,
                                            });
                                            eventService.fire();
                                            return false;
                                        }
                                        this.setState({
                                            searchValue: ''
                                        });

                                        return true;
                                    }
                                ]}
                                backStepperButtonAction={[
                                    () => {
                                        this.setState({
                                            errors: {},
                                            searchValue: ''
                                        }, () => {
                                            this.props.startLoading();
                                            this.handleGetAssets()
                                        });
                                    },
                                    () => {
                                        this.setState({
                                            errors: {},
                                            searchValue: ''
                                        }, () => {
                                            this.props.startLoading();
                                            this.handleGetRetailers()
                                        });

                                    },
                                    () => {
                                        this.setState({
                                            errors: {},
                                            searchValue: ''
                                        }, () => {
                                            this.props.startLoading();
                                            this.handleGetCatalogs()
                                        });

                                    },
                                    () => {
                                        this.setState({
                                            errors: {},
                                            searchValue: ''
                                        });
                                    }
                                ]}
                                searchHandler={this.handleChange}
                                handleButtonGroupChange={this.handleButtonGroupChange}
                                allState={this.state}
                                stateCallback={this.handleInputChange}
                                errorCallback={this.handleFormError}
                                currentLang={this.state.currentLang}
                                drawerWidth={this.props.drawerWidth}
                                deleteMutation={this.handleToggleDialog}
                                deleteText={this.props.t("spread.campaigns.deleteCampaign")}
                            />

                            <DialogModal
                                icon={true}
                                type={'delete'}
                                windowWidth={this.props.windowWidth}
                                open={this.state.openDialog}
                                title={this.props.t("spread.campaigns.sureToDeleteCampaign")}
                                primaryAction={this.deleteMutation}
                                secondaryAction={() => { this.handleToggleDialog() }}
                            >
                                {this.props.t("spread.campaigns.sureAgainToDeleteCampaign")}
                            </DialogModal>
                        </>
                    ) : <PageLoader />
                }
            </div>
        );
    }

    prepareAttributes() {
        return new Promise(async (resolve, reject) => {
            let group = this.state.allGroups.find(e => e.node.id === this.state.groupAttribut);

            let isSystemAttributes = this.props.attributes.product.attributes.edges.filter(e => e.node.isSystem);
            let metaAttributes = this.props.attributes.product.attributes.edges.filter(e => e.node.isSystem && e.node.identifier.indexOf('meta') > -1);
            let customAttributes = group.node.attributes.edges.filter(e => !e.node.isSystem);
            let imageAttributes = isSystemAttributes.filter(e => e.node.attributeType.input === 'image')
                .concat(customAttributes.filter(e => e.node.attributeType.input === 'image'));

            this.setState({
                isSystemAttributes:isSystemAttributes??null,
                metaAttributes:metaAttributes??null,
                customAttributes:customAttributes??null,
                imageAttributes:imageAttributes??null,
                maxImageNumber: imageAttributes.length??null,
            });

            resolve();
        });
    }

    prepareAttributeValues() {
        for (let locale of this.props.locales) {
            let values = {};

            for (let attribute of this.state.customAttributes) {
                if (attribute.node.attributeType.input === 'select') {
                    if (attribute.node.attributeOptions.edges.length) {
                        values[attribute.node.identifier] = attribute.node.attributeOptions.edges[0].node.id
                    }
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }
    }

    initCampaign() {
        this.props.client.query({
            query: GET_CAMPAIGN_BY_ID,
            fetchPolicy: "no-cache",
            variables: { id: this.state.campaignId }
        }).then(GET_CAMPAIGN_DATA_RESULT => {
            let campaign = GET_CAMPAIGN_DATA_RESULT.data.campaign;
            let toDateStartNotConverted = null;
            let toDateEndNotConverted = null;
            if (campaign.startAt) {
                toDateStartNotConverted = moment(campaign.startAt).format('YYYY-MM-DD');
            }
            if (campaign.endAt) {
                toDateEndNotConverted = moment(campaign.endAt).format('YYYY-MM-DD');
            }
            this.setState({
                campaignIdentifier: campaign.identifier,
                campaignName: campaign.libelle,
                campaignDescription: campaign.description,
                campaignGTM: campaign.gtm,
                campaignStartAt: toDateStartNotConverted,
                campaignEndAt: toDateEndNotConverted,
                campaignStatus: campaign.status,
                campaignImage: campaign.media,
                assetsSelected: campaign.assets.edges,
                catalogSelected: campaign.catalogs.edges,
                retailersSelected: campaign.retailers.edges,
                ready: true,
            })
        }).catch((err)=>{
            console.log(err)
        });
    }

    goTo = route => this.props.history.push(route);
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DiffusionCampaignDetail))));
