import React from 'react';
import { Grid, Tooltip, TextField} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';
import CardCustom from '../../../layouts/Card/CardCustom';
import Typography from '../../../ui/typography/Typography';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import styled from 'styled-components';
import { ROUTE_HOME, ROUTE_ORDERS_DETAIL } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from '@apollo/client/react/hoc';
import { GET_ORDERS_PAGINATION, GET_ORDERS_CURSOR } from '../../../../queries/orders';
import { SEARCH_PRODUCTS_SKU } from '../../../../queries/products';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import request from '../../../../js/utils/fetch';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import Pagination from '@mui/material/Pagination';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import CardOrderList from '../../../layouts/Card/cardContent/CardOrderList';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchBar from '../../../ui/search/SearchBar';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px){
        padding: 16px 8px 8px!important;
    }
`;

const GridItemList = styled(GridCustom)`
    cursor:pointer;
    &:hover{
        .MuiPaper-root{
            background: #e5e5e5;
        }
    }
`;

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;
const ViewModuleIconCustom = styled(ViewModuleIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;
const ViewListIconCustom = styled(ViewListIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;
const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 16px;
        padding: 8px;
    }
    & > p{
        margin: 0;
    }
`;
const TableCellCustom = styled(TableCell)`
    border: none;
    display: inline-block;
    width: calc(100% / 8);
    padding: 3px 16px;
`

const styles = theme => ({
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.darker.hue300,
    },
    tooltip: {
        backgroundColor: colors.blue.darker.hue300,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class ListOrders extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentLang: this.props.locales[0].node.code,
            openForm: false,
            openFormImport: false,
            openFormExport: false,
            catalogFormData : {},
            ready: false,
            page: 1,
            nbperpage: 10,
            countPage: 0,
            cursor: null,
            skulist: [],
            searchValueNumber: '',
            searchValueSource: '',
            searchValueSourceNumber: '',
            searchValueStatus: this.props.noStatus ? this.props.noStatus : '',
            arrayCatFilter: [],
            viewMode: 'card',
            exportName: '',
            exportLang: this.props.locales[0].node.code,
            exportType: 'csv',
            importTypeFile: 'csv',
            importUrl : '',
            errors: {},
        }
        this.errors = {}
        this.handleChange = this.handleChange.bind(this);
        this.typingSearchTimer = null;
    }

    handleSearch = () => {
        this.props.startLoading();
        let value = this.state.searchValue;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_PRODUCTS_SKU,
                variables: {"needle": value},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let stockSku = this.state.skulist;
                this.setState({
                    skulist: [],
                    page: 1,
                    cursor: this.state.listCursors[0].cursor?? null
                }, () => {
                    // if(this.state.activeCategorie.length <= 1){
                        for(let item of result.data.productDatas.edges){
                            if(this.state.skulist.indexOf(item.node.product.sku) === -1){
                                let skulist =[...this.state.skulist,item.node.product.sku]
                                this.setState({
                                    skulist:skulist??null
                                })
                            }
                        }
                    // }
                    // else{
                    //     for(let sku of stockSku){
                    //         let check = false;
                    //         for(let item of result.data.productDatas.edges){
                    //             if(sku === item.node.product.sku && !check){
                    //                 check = true;
                    //             }
                    //         }
                    //         if(check){
                    //             this.state.skulist.push(sku);
                    //         }
                    //     }
                    // }
                });
                if(this.state.skulist.length === 0){
                    this.setState({noResult: true});
                    this.props.stopLoading();
                }
                else{
                    this.setState({noResult: false},() => {
                        this.handleGetOrders();
                    });
                }
            }).catch((err)=>{
                console.log(err)
            });
        } else {
            this.setState({
                skulist: [],
                noResult: false
            },() => {
                if(this.state.activeCategorie.length <= 1){
                    this.handleGetOrders();
                }
                else{
                    this.handleChangeTab(null,null,this.state.idActiveCat);
                }
            });
        }
    }

    handleChange(e, stateName) {
        clearTimeout(this.typingSearchTimer);
        if(e?.target?.value){
            this.setState({[stateName]: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleGetOrders();
            }, 500);
        } 
        else {
            this.setState({
                skulist: [],
                noResult: false,
                [stateName]: ''
            }, () => {
                this.handleGetOrders();
            });
        }
    }

    handleGetCursorsOrders = () => {
        const getCursorsOrders = GET_ORDERS_CURSOR;
        this.props.client.query({
            query: getCursorsOrders,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.orders.edges
            });
        }).catch((err)=>{
            console.log(err)
        });
    }

    handleGetOrders() {
        return new Promise((resolve, reject) => {
            const getOrders = GET_ORDERS_PAGINATION;
            let variables = 
            {
                "nbperpage": this.state.nbperpage, 
            };
            if(this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor){
                variables.cursor = this.state.cursor;
            }
            if(this.state.skulist.length > 0){
                variables.skulist = this.state.skulist;
            }
            // if (this.state.searchValue){
                variables.status = this.state.searchValueStatus;
                variables.number = this.state.searchValueNumber;
                variables.source_number = this.state.searchValueSourceNumber;
                variables.source = this.state.searchValueSource;
            // }
            this.props.client.query({
                query: getOrders,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.listOrders = result.data.orders.edges;
                this.setState({countPage: Math.ceil(result.data.orders.totalCount / this.state.nbperpage)??0})
                // request(`${process.env.REACT_APP_API}/completude/`, 'post', false).then(
                //     (data) => {
                //         if(data.success){
                //             for( var i = 0; i < this.listOrders.length; i++){
                //                 let productSku = this.listOrders[i].node.sku;
                //                 let infoStats = data.products[productSku];
                //                 this.listOrders[i].node.stats = {
                //                     infoStats
                //                 };
                //             }
                //             this.forceUpdate();
                //         }
                //     }
                // );
                this.setState({
                    listOrders: this.listOrders
                });
                this.props.stopLoading();
                resolve();
            }).catch((err)=>{
                console.log(err)
            });
        });
    }

    componentDidMount() {
        if (process.env.REACT_APP_MODE_SPREAD !== "hub"){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.getAll();

        request(`${process.env.REACT_APP_API}/countCatalogsByProduct`, 'post', false)
        .then((data) => {
            if(data.success){
                this.setState({
                    nbCatalog: data
                });
            }
        }).catch((err)=>{
            console.log(err)
        });
        request(`${process.env.REACT_APP_API}/countImagesByAttrGrp`, 'post', false)
        .then((data) => {
            if(data.success){
                this.setState({
                    nbImageTotal: data.details
                });
            }
        }).catch((err)=>{
            console.log(err)
        });
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.noStatus !== prevProps.noStatus){
            if (process.env.REACT_APP_MODE_SPREAD !== "hub"){
                this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
                this.goTo(ROUTE_HOME);
            }
            this.setState({
                ready: false,
                searchValueStatus : this.props.noStatus??null
            }, () => this.getAll())
            
        }
    }

    async getAll() {
        await this.handleGetOrders();
        // await this.handleGetCategories();
        await this.handleGetCursorsOrders();
        this.setState({ ready: true });
    }

    changePage = (event, page) => {
        this.props.startLoading();
        let index = 0;
        if(page > 1){
            index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        }
        this.setState({
            cursor: this.state.listCursors[index].cursor??null,
            page: page
        },()=>{
            this.handleGetOrders();
        });
    };

    handleChangePerPage = (event) => {
        this.setState({
            nbperpage: event.target.value,
            page: 1,
        }, () => this.handleGetOrders())
    }

    render() {
        const headCells = [
            { id: 'numero', image: false, style: null, disablePadding: true, label: 'Numéro' },
            { id: 'date', image: false, style: null, disablePadding: false, label: 'Date de commande' },
            { id: 'shipping', image: false, style: null, disablePadding: false, label: 'Informations livraison' },
            { id: 'warehouse', image: false, style: null, disablePadding: false, label: 'Entrepôt' },
            { id: 'source', image: false, style: null, disablePadding: false, label: 'Source' },
            { id: 'source_number', image: false, style: null, disablePadding: false, label: 'Source N°' },
            { id: 'total', image: false, style: null, disablePadding: false, label: 'Total' },
            { id: 'status', image: false, style: null, disablePadding: false, label: 'Statut' },
        ];
        let productBypage = [];
        productBypage = [10,20,50];
        const { classes } = this.props;

        return (
            <div>
                <TopPanel 
                    icomoon="icon-costs"
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Liste des commandes"
                    subtitle="Liste de vos commandes" 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    hasBorder={true}
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    // locales={this.props.locales}
                />
                {
                    <div>
                        <Grid container justifyContent="flex-end" style={{marginTop: 24}}>
                            <Grid item >
                                <Typography>N° commande: </Typography>
                                <SearchBar onChange={(e) => this.handleChange(e,'searchValueNumber')} />
                            </Grid>
                            <Grid item style={{marginLeft: 16}}>
                                <Typography>Source: </Typography>
                                <SearchBar onChange={(e) => this.handleChange(e,'searchValueSource')} />
                            </Grid>
                            <Grid item style={{marginLeft: 16}}>
                                Source Number:
                                <SearchBar onChange={(e) => this.handleChange(e,'searchValueSourceNumber')} />
                            </Grid>
                            {
                                !this.props.noStatus ?
                                    <Grid item style={{marginLeft: 16}}>
                                        Statut: 
                                        <SearchBar onChange={(e) => this.handleChange(e,'searchValueStatus')} />
                                    </Grid>
                                : null
                            }
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="space-between" style={{marginTop: 16}}>
                            <Grid item>
                            {this.state.listOrders && this.state.listOrders.length > 0 ?
                                <Grid container justifyContent="flex-start" alignItems="center">
                                    <div>Nombre de commandes par page: </div>
                                    {
                                        <FormControl style={{width: 60, marginLeft: 8}}>
                                                <TextFieldCustom
                                                    id='product-by-page'
                                                    variant="outlined"
                                                    color="secondary"
                                                    select
                                                    fullWidth
                                                    value={this.state.nbperpage}
                                                    onChange={this.handleChangePerPage}
                                                    >
                                                    {productBypage.map((pages, index) =>{
                                                        return(
                                                            <MenuItem value={pages} key={`MenuItem${index}`}>{pages}</MenuItem>
                                                        )
                                                    })}
                                                </TextFieldCustom>
                                        </FormControl>
                                    }
                                </Grid>
                                : null
                            }
                            </Grid>
                        </Grid>
                    </div>
                }
                <Grid container direction="column" justifyContent="center" style={{paddingTop: 8}} spacing={0}>
                    <Grid container direction="row" spacing={2} style={{marginTop: 8, marginBottom: 16}}>
                        <GridCustom item xs={12} key={`HeaderList`} style={{padding: '0px 16px', }}>
                            <CardCustom style={{width: "100%", height: "100%",  padding: "0", boxShadow: 'none', border: 'none', borderRadius: '0', background: 'none'}} cardContentStyle={{height: "100%"}} contentpadding={'0'} hovercard={false}>
                                <TableContainer>
                                    <Table aria-label="headerList">
                                        <TableHead style={{padding: '5px 0'}}>
                                            <TableRow>
                                                {headCells.filter(e => e!== null).map((headCell) => (
                                                    <TableCellCustom
                                                        key={headCell.id}
                                                        align={headCell.image ? 'center' : 'left'}
                                                        style={{width: `${headCell.style?.width}`}}
                                                        // sortDirection={orderBy === headCell.id ? order : false}
                                                    >
                                                        {/* <TableSortLabel
                                                            active={orderBy === headCell.id}
                                                            direction={orderBy === headCell.id ? order : 'asc'}
                                                            onClick={createSortHandler(headCell.id)}
                                                        > */}
                                                        {headCell.label}
                                                        {/* {orderBy === headCell.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null} */}
                                                        {/* </TableSortLabel> */}
                                                    </TableCellCustom>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                            </CardCustom>
                        </GridCustom>
                        {
                            !this.state.ready ? (<PageLoader />) : (this.state.listOrders && this.state.listOrders.length > 0 && !this.state.noResult) ? (
                                this.state.listOrders.map((order, index) => {
                                    // let truncOrderId = order.node.id.replace('/api/orders/', '');
                                    return(
                                        <GridItemList item xs={12} key={`ListProduct${index}`} style={{padding: '3px 16px'}} onClick={() => this.goTo(ROUTE_ORDERS_DETAIL.replace(':id', order.node.number),order.node.id)}>
                                            <CardCustom style={{width: "100%", height: "100%",  padding: "0", boxShadow: 'none', borderRadius: 0}} cardContentStyle={{height: "100%"}} contentpadding={'4px 0'} hovercard={false}>
                                                <CardOrderList
                                                    // nbCatalog={this.state.nbCatalog?.products[product.node.sku] ?? 0} 
                                                    // nbImageTotal={this.state.nbImageTotal[truncAttrGroupId]} 
                                                    orders={order.node} 
                                                />
                                            </CardCustom>
                                        </GridItemList>
                                    )
                                })
                            )
                            :
                            <EmptyCard title={"Aucun Résultat"} subtitle={"Recommencer votre recherche"} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                        }
                    </Grid>
                    {
                        this.state.ready && this.state.countPage > 1 && !this.state.noResult && this.state.listCursors?.length > 0 ?
                            <PaginationCustom onChange={(event, page) => {this.changePage(event, page)}} page={this.state.page} count={this.state.countPage} color="primary" />
                            : null
                    }
                </Grid>
            </div>
        );
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { orderId : id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ListOrders))));
