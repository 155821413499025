import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import ArrayHistory from './components/ArrayHistory';
import Typography from '../../../ui/typography/Typography';
import request from '../../../../js/utils/fetch';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { Box } from '@mui/material';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withTranslation } from 'react-i18next';

const ImportHistory = props=>{
    const [used,setUsed]= useState(props.used)
    const [rows,setRows]= useState(null)

    useEffect(()=>{
        checkRouting(props);
        try {
            request(`${process.env.REACT_APP_API}/scheduler/crons/check`, 'get', false).then(
                (data) => {
                    if (data.success) {
                        setRows(data.crons)
                    }
                }
            ).catch((e) => {
                console.log(e);
            })
        } catch (e) {
            console.log(e);
        }
    },[])

    const goTo = route => {
        props.history.push(route);
    };
    return (
        <div style={{ width: "100%", display: 'grid', gridTemplateRows: 'auto 1fr', height: '100%' /* height: 'calc(100% - 64px)' */ }}>
            {
                props.used === "dashboard" ?
                    <Typography>{props.t('drawer.history_imports')}</Typography>
                    :
                    <TopPanel
                        title={props.t('drawer.history_imports')}
                        subtitle={"Liste des imports fait dans la plateforme par les utilisateurs"}
                        locales={props.locales}
                        hasBorder={true}
                    />
            }                
            {rows ?
                rows.length > 0 ?
                (
                    <ArrayHistory rows={rows} style={{ padding: 0, boxShadow: 'none', background: 'transparent', margin: used === "page" ? '16px 32px' : '8px', width: used === "page" ? 'calc(100% - 64px)' : 'calc(100% - 16px)', display: 'grid', gridTemplateRows: '1fr auto' }} />
                )
                : <Typography style={{padding:"16px 32px"}}>Aucun résultat</Typography>
                : (
                    <Box style={{
                        position: 'relative',
                    }}>
                        <PageLoader />
                    </Box>
                )}
        </div>
    );
}


const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, null)(ImportHistory)));
