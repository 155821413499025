import styled from "styled-components";
import { InputFieldCustom } from "../../../styles/styled";
import OurTypography from "../../../../typography/Typography";

export const HiddenUploadFile = styled(InputFieldCustom)`
display: none;
`;

export const OurTypographyCustom = styled(OurTypography)`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;