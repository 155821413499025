import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Grid, Box } from '@mui/material';
import Typography from '../../../ui/typography/Typography';
import { ROUTE_PRODUCTS_ATTRIBUTS, ROUTE_PRODUCTS_ADD } from '../../../../js/constants/route-names';
import { withApollo } from '@apollo/client/react/hoc';
import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import SliderDashboard from "../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import { v4 as uuidv4 } from 'uuid';
import { SNACK } from '../../../../js/constants/action-types';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import moment from 'moment';
import styled from 'styled-components';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';


const WrapperStats = styled(Grid)`
    width: 100%; 
    padding: 0 36px;
    @media screen and (max-width: 1600px){
        padding: 0 16px;
    }
    @media screen and (max-width: 1450px){
        margin: 12px 0; 
    }
`;
const Title = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 16px;
    line-height: 23px;
    font-weight: bold;
    width: 100%;
`;
const TitleBis = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    width: 100%;
`;
const SubTitle = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    font-size: 35px;
    line-height: 46px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 30px;
        line-height: 41px;
    }
    @media screen and (max-width: 1450px){
        font-size: 23px;
        line-height: 30px;
    }
`;
const SubTitleBis = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    font-size: 31px;
    line-height: 39px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 23px;
        line-height: 24px;
    }
    @media screen and (max-width: 1450px){
        font-size: 18px;
        line-height: 19px;
    }
`;
const MoreInfos = styled(Typography)`
    margin-top: 4px;
    color: ${colors.black.regular};
    font-size: 20px;
    line-height: 23px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 16px;
        line-height: 19px;
    }
`;
const MoreInfosBis = styled(Typography)`
    margin-top: 4px;
    color: ${colors.black.regular};
    font-size: 12px;
    line-height: 14px;
    width: 100%;
`;
const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor};
    svg circle{
        stroke: ${props => props.statuscolor};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor};
    }
`;

class DashboardProducts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [],
            loaded: false
        };
    }

    componentDidMount() {
        checkRouting(this.props)
        // this.initDashboard();
    }

    initDashboard = () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/product`, 'get', false)
        .then((data) => {
            if (data.success) {
                this.setState({
                    products: data.datas,
                    loaded: true
                }, () => {
                    let time = this.state.products.nbProducts - ((this.state.products.percentCompleteProducts * this.state.products.nbProducts) / 100);
                    this.setState({ time:time??null });
                });
            }
        }).catch((err)=>{
            console.log(err)
        });
    }

    render() {
        let sliderData = [
            {
                title: this.props.t('products.dashboard.actionslider.producttitle'),
                text: this.props.t('products.dashboard.actionslider.producttext'),
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t('products.dashboard.actionslider.productbutton'),
                        link: `${ROUTE_PRODUCTS_ADD}`
                    },
                ]
            },
            // {
            //     title: this.props.t('products.dashboard.actionslider.categorytitle'),
            //     text: this.props.t('products.dashboard.actionslider.categorytext'),
            //     image: Image2,
            //     id: uuidv4(),
            //     buttons: [
            //         {
            //             text: this.props.t('products.dashboard.actionslider.categorybutton'),
            //             link: `${ROUTE_PRODUCTS_CATEGORIES}?formOpen=true`
            //         },
            //     ]
            // },
            {
                title: this.props.t('products.dashboard.actionslider.attributtitle'),
                text: this.props.t('products.dashboard.actionslider.attributtext'),
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t('products.dashboard.actionslider.attributbutton'),
                        link: `${ROUTE_PRODUCTS_ATTRIBUTS}?formOpen=true`
                    },
                ]
            }
        ];
        sliderData = sliderData.filter(function (el) {
            return el !== null;
        });

        let lastDatas = [
            {
                'data': Math.round(this.state.products.percentCompleteProducts),
                'dataColor': colors.black.regular,
                'title': "Taux de complétude",
                'subtitle': this.props.t('products.dashboard.lastdatas.completedProducts'),
                'moreInfos': `MAJ: ${moment().format('DD/MM/YYYY à HH:mm')}`
            },
            {
                'icon': 'picto-produit',
                'data': this.state.products.nbCategories ? 20 : 0,
                'dataColor': colors.blue.darker.hue300,
                // 'title': this.props.t('products.dashboard.lastdatas.categoriesTotal'),
                'subtitle': `${Number(this.state.products.nbCategories).toLocaleString()} ` + this.props.t('products.dashboard.lastdatas.categories'),
                'moreInfos': `Dernier ajout: ${this.state.products.categoriesLastAddition ? moment(this.state.products.categoriesLastAddition.date).format('DD/MM/YYYY à HH:mm') : 'N/A'}`

            },
            {
                'icon': 'picto-produit',
                'data': this.state.products.nbProducts ? 40 : 0,
                'dataColor': colors.blue.darker.hue300,
                // 'title': this.props.t('products.dashboard.lastdatas.productTotal'),
                'subtitle': `${Number(this.state.products.nbProducts).toLocaleString()} ` + this.props.t(this.state.products.nbProducts > 1 ? 'products.dashboard.lastdatas.products' : 'products.dashboard.lastdatas.product'),
                'moreInfos': `Dernier ajout: ${this.state.products.productLastAddition ? moment(this.state.products.productLastAddition.date).format('DD/MM/YYYY à HH:mm') : 'N/A'}`
            },
            {
                'data': (!Number.isNaN(Math.round((this.state.products.nbProductsEnable / this.state.products.nbProducts) * 100))) ? Math.round((this.state.products.nbProductsEnable / this.state.products.nbProducts) * 100) : 0,
                'dataColor': colors.blue.regularv2,
                // 'title': this.props.t('products.dashboard.lastdatas.nbActiveProduct'),
                'subtitle': this.props.t('products.dashboard.lastdatas.activeProduct'),
                'moreInfos': `Nombre total de produits actifs : `,
                'moreInfosData': `${Number(this.state.products.nbProductsEnable).toLocaleString()}/${Number(this.state.products.nbProducts).toLocaleString()}`

            },
            {
                'data': (!Number.isNaN(Math.round((this.state.products.productsInCatalog / this.state.products.nbProducts) * 100))) ? Math.round((this.state.products.productsInCatalog / this.state.products.nbProducts) * 100) : 0,
                'dataColor': colors.blue.darker.hue300,
                // 'title': this.props.t('products.dashboard.lastdatas.nbCatalogProduct'),
                'subtitle': this.props.t('products.dashboard.lastdatas.catalogProduct'),
                'moreInfos': `Nombre de catalogue produit total : `,
                'moreInfosData': `${Number(this.state.products.productsInCatalog).toLocaleString()}/${Number(this.state.products.nbProducts).toLocaleString()}`

            },
        ];

        return (
            <div style={{ width: "100%" }}>
                <TopPanel
                    icomoon="icon-dashboard"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("products.dashboard.welcome.welcome")}
                    subtitle={this.props.t("products.dashboard.welcome.subtitle")}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    hasBorder={true}
                />
                <Grid container direction="row" justifyContent="center" alignItems='flex-start'>
                    {/* <Grid item xs={12}>
                        <StatsProducts />
                    </Grid> */}
                    {
                        sliderData.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xl={sliderData.length > 1 ? 12 : 6} lg={sliderData.length > 1 ? 12 : 8} md={sliderData.length > 1 ? 12 : 8} xs={12} style={{ overflow: 'hidden' }}>
                                <AccordionCustom forcedExpanded={true} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{this.props.t('products.dashboard.actionslider.actionslider')}</Typography>}>
                                    <SliderDashboard sliderData={sliderData} windowWidth={this.props.windowWidth} />
                                </AccordionCustom>
                            </Grid>
                            : null
                    }
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
    }
}

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardProducts))));