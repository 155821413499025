import React from 'react';
import colors from '../../../config/theme/colors';
import Button from "../../ui/button/Button";
import styled from 'styled-components';
import {Grid, Typography, Box} from '@mui/material';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;
const Title = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 33px;
    line-height: 29px;
    max-width: 23ch;
    margin: 0 auto;
    @media screen and (max-width: 1480px){
        font-size: 18px;
    }
    @media screen and (max-width: 1250px){
        font-size: 17px;
    }
    @media screen and (max-width: 1000px){
        font-size: 16px;
    }
`;
const Subtitle = styled(Typography)`
    color: ${colors.grey.regular};
    margin-top: 8px;
    line-height: 20px;
    @media screen and (max-width: 1480px){
        font-size: 14px;
        margin-top: 0;
    }
    @media screen and (max-width: 1250px){
        font-size: 13px;
    }
    @media screen and (max-width: 1000px){
        font-size: 12px;
    }
`;

const EmptyCard = (props) => {
    const { title, subtitle, textButton, picto, onClick, openForm, mtImg, test } = props;
    return (
        <GridCustom item xs={12}>
            <Grid container alignItems={"center"} justifyContent={"center"}>
                <Grid item xs={12}>
                    <Box textAlign={"center"}>
                        <Title variant={"h2"}>{title}</Title>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box textAlign={"center"}>
                        <Subtitle variant={"h4"}>{subtitle}</Subtitle>
                    </Box>
                </Grid>
                {textButton ?
                    <Box textAlign={"center"} mt={3}>
                        <Button icon="add" style={{padding: "14px 32px"}} bgcolor={colors.green.regular} shadowcolor={colors.green.darker} text={textButton ? `${textButton}` : null} onClick={onClick} className="button" disabled={openForm} />
                    </Box>
                : null
                }
            </Grid>
        </GridCustom>
    );
};

export default EmptyCard;