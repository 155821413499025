import { gql } from '@apollo/client';

export const GET_CATALOG_FILTERS = gql`
{
  catalogFilters{
    edges{
      node{
        id
        operator
        value
        createdAt
        catalog{
          id
        }
        attribute{
          id
          identifier
        }
        attributeOption{
          id
          identifier
        }
      }
    }
  }
}
`;

/*
{
  "id": "/api/catalog-filters/1"
}
 */
export const GET_CATALOG_FILTER = gql`
query getCatalogFilter($id: ID!) {
  catalogFilter(id: $id) {
    id
    operator
    value
    createdAt
    catalog{
      id
    }
    attribute{
      id
      identifier
    }
    attributeOption{
      id
      identifier
    }
  }
}
`;

/*
{
  "operator": "!=",
  "value": "différent",
  "createdAt": "00:00:00",
  "catalog": "/api/catalogs/1",
  "attribute": "/api/attributes/1"
}
 */
export const CREATE_CATALOG_FILTER = gql`
mutation createCatalogFilter($operator: String!, $value: String, $createdAt: String!, $catalog: String!, $attribute: String!, $attributeOption: String) {
  createCatalogFilter(input:{operator: $operator, value: $value, createdAt: $createdAt, catalog: $catalog, attribute: $attribute, attributeOption: $attributeOption}) {
    catalogFilter{
      id
      operator
      value
      createdAt
      catalog{
        id
      }
      attribute{
        id
        identifier
      }
      attributeOption{
        id
        identifier
      }
    }
  }
}
`;

/*
{
  "id": "/api/catalog_filters/1",
  "operator": "!=",
  "value": "différents",
  "catalog": "/api/catalogs/1",
  "attribute": "/api/attributes/1"
}
 */
export const UPDATE_CATALOG_FILTER = gql`
mutation updateCatalogFilter($id: ID!, $operator: String, $value: String, $catalog: String, $attribute: String, $attributeOption: String) {
  updateCatalogFilter(input:{id: $id, operator: $operator, value: $value, catalog: $catalog, attribute: $attribute, attributeOption: $attributeOption}) {
    catalogFilter{
      id
      operator
      value
      createdAt
      catalog{
        id
      }
      attribute{
        id
        identifier
      }
      attributeOption{
        id
        identifier
      }
    }
  }
}
`;

/*
{
  "id": "/api/catalog_filters/1"
}
 */
export const DELETE_CATALOG_FILTER = gql`
mutation deleteCatalogFilter($id: ID!) {
  deleteCatalogFilter(input:{id: $id}) {
    catalogFilter{
      id
    }
  }
}
`;