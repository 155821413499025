import React from "react";
import { Grid } from "@mui/material";
import AccordionCustom from "../../../../../layouts/Accordion/AccordionCustom";
import OurTypography from "../../../../../ui/typography/Typography";
import colors from "../../../../../../config/theme/colors";

const ProductDetailAttributs = (props) => {
  const { product } = props;
  const attributs = product.productDatas.edges.filter(
    (e) => !e.node.attribute.isSystem && !e.node.attribute.isSearchable
  );

  const getValue = (attribut) => {
    let type = attribut.attribute.attributeType.input;
    let value;
    switch (type) {
      case "text":
        value = attribut?.value;
        break;
      case "select":
        value =
          attribut.attributeOption?.translation.translationDatas.edges[0]?.node
            ?.value;
        break;
    }
    return value;
  };

  return (
    <Grid container id={props?.id} columnSpacing={2}>
      <Grid item xs>
        <AccordionCustom
          id={props?.id}
          title={
            <OurTypography fontweighttext={"bold"} style={{ paddingLeft: "15px" }}>
              Attributs
            </OurTypography>
          }
          noborder
          custompadding={"15px 0 !important"}
          topborder
        >
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            <Grid container>
              {attributs.map((e, i) => {
                return (
                  <Grid
                    key={i}
                    item
                    xs={12}
                    container
                    style={{
                      backgroundColor: i % 2 === 0 ? colors.grey.lighter.hue980 : "transparent",
                      padding: "10px 20px"
                    }}
                  >
                    <Grid item xs={6}>
                      {e.node.attribute.translation.translationDatas.edges[0].node.value} :
                    </Grid>
                    <Grid item xs={6}>
                      <OurTypography fontweighttext={"bold"}>{getValue(e.node)}</OurTypography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </AccordionCustom>
      </Grid>

      <Grid item xs>
        <AccordionCustom
          id={props?.id}
          noborder
          title={
            <OurTypography fontweighttext={"bold"} style={{ paddingLeft: "15px" }}>
              Catégorisation automatique des attributs
            </OurTypography>
          }
          custompadding={"15px 0 !important"}
          topborder
        >
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            <Grid container>
              {attributs.map((e, i) => {
                return (
                  <Grid
                    key={i}
                    item
                    xs={12}
                    container
                    style={{
                      backgroundColor: i % 2 === 0 ? colors.grey.lighter.hue980 : "transparent",
                      padding: "10px 20px"
                    }}
                  >
                    <Grid item xs={6}>
                      {e.node.attribute.translation.translationDatas.edges[0].node.value} :
                    </Grid>
                    <Grid item xs={6}>
                      <OurTypography fontweighttext={"bold"}>{getValue(e.node)}</OurTypography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </AccordionCustom>
      </Grid>
    </Grid>
  );
};

export default ProductDetailAttributs;
