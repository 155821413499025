import { GET_SELECTIONS } from "@queries/selections";

export const initCollections = (client, setState, state) => {
  let localStorage_GET_SELECTIONS = localStorage.getItem("GET_SELECTIONS");
  if (!localStorage_GET_SELECTIONS) {
    client
      .query({
        query: GET_SELECTIONS,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        localStorage.setItem("GET_SELECTIONS", JSON.stringify(result));
        setState((prevState) => ({
          ...prevState,
          listCollections: result.data.selections.edges,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    let result = JSON.parse(localStorage_GET_SELECTIONS);
    setState((prevState) => ({
      ...prevState,
      listCollections: result.data.selections.edges,
    }));
  }
};
