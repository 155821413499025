import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function groupRateEdit(action='add', listCurrencies) {
    let generalInputs   = [
        {
            required : true,
            type : 'text',
            label : 'Code',
            stateName : 'groupRateIdentifier',
            helper: {
                text: '',
                link: false,
            },
        },
        {
            type: 'select',
            isContainerized: true,
            label: 'Devise',
            helper: 'Devise du groupe de taxe',
            required: true,
            stateName: 'groupRateCurrency',
            disabled: false,
            value: listCurrencies?.map(currency => {
                return(
                    {
                        value : currency.node.id,
                        label : currency.node.libelle,
                        // disabled : node.node.id === attributeType,
                    }
                )
            })
        }
    ]
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? 'Créer un groupe de taxes':'Modifier le groupe',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre groupe`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de ce groupe' : 'Valider la modification de ce groupe',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}