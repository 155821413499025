import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import { Grid, List, InputLabel } from "@mui/material";
import { DELETE_TYPO } from "@queries/brand_guideline";
import styled from "styled-components";
import colors from "@config/theme/colors";
import TypoPicker from "./TypoPicker";
import AddIcon from "@mui/icons-material/Add";
import { withApollo } from "@apollo/client/react/hoc";
import PropsTypes from "prop-types";
import { SpanAddCustom } from "@ui/form/components/Text/styles/styled";

const AddIconCustom = styled(AddIcon)`
  fill: white;
  background: ${colors.green.regular};
  border-radius: 50%;
  padding: 4px;
  width: calc(0.8em);
  height: calc(0.8em);
  margin-bottom: 10px;
`;
const FormCustom = styled(List)`
  padding: 1rem;
  width: 100%;
  background: rgb(250, 251, 251);
`;
const InputLabelCustom = styled(InputLabel)`
  color: ${colors.black.regular};
  line-height: 20px;
  @media screen and (min-width: 1280px) {
    height: 51px;
  }
  display: flex;
  align-items: center;
  word-break: break-word;
`;

const TypographySelector = ({
  listTypographiesSystems,
  listTypographies,
  listMarkups,
  maxLength,
  onChange,
  ...props
}) => {
  const defaultBaseSize = 16;
  const defaultRatio = 1.25;

  const [temporaryListTypographies, setTemporaryListTypographies] =
    useState(listTypographies);

  const initTypographyData = () => {
    return new Promise((resolve, reject) => {
      let array = [];
      let value = 0;

      const listMarkupsSortByIdDesc = listMarkups.sort(
        (a, b) => b.id.split("/").pop() - a.id.split("/").pop()
      );

      for (let i = 0; i < listMarkupsSortByIdDesc.length; i++) {
        if (value > 0) {
          value = value * defaultRatio;
        } else {
          value = defaultBaseSize / defaultRatio;
        }

        array.push({
          px: Math.trunc(value).toString(),
          markup: listMarkupsSortByIdDesc[i],
        });
      }

      resolve(array);
    });
  };

  const generateTempoId = () => {
    return new Promise((resolve, reject) => {
      let tempoId = Math.random().toString(36).substr(2, 9);
      resolve(tempoId);
    });
  };

  const addTypo = async () => {
    const tempoId = await generateTempoId();
    const typographyDatas = await initTypographyData();
    const typographyMarkups = typographyDatas?.map((typo) => typo.markups);

    let newTypography = {
      tempoId,
      typographyDatas,
      typographyMarkups,
      typographySystem: listTypographiesSystems[0],
      identifier: `typo_${listTypographies.length + 1}`,
      changed: true,
    };

    setTemporaryListTypographies((prevState) => [...prevState, newTypography]);
  };

  useEffect(() => {
    onChange(temporaryListTypographies);
  }, [temporaryListTypographies]);

  const deleteTypo = (typography) => {
    if (typography.id) {
      props.startLoading();
      props.client
        .mutate({
          mutation: DELETE_TYPO,
          variables: { id: typography.id },
        })
        .then(() => {
          const newTypographies = temporaryListTypographies.filter(
            (typo) => typo.id !== typography.id
          );
          setTemporaryListTypographies(newTypographies);
          props.snack("success", "La typographie a bien été supprimée");
        })
        .catch(() => {
          props.snack("error", "Une erreur est survenue");
        })
        .finally(() => {
          props.stopLoading();
        });
    } else if (typography.tempoId) {
      const newTypographies = temporaryListTypographies.filter(
        (typo) => typo.tempoId !== typography.tempoId
      );
      setTemporaryListTypographies(newTypographies);
    }
  };

  const handleChangeComplete = (newVal) => {
    const newTypographiesList = temporaryListTypographies.map((item) => {
      if (
        (item.tempoId && item.tempoId === newVal.tempoId) ||
        (item.id && item.id === newVal.id)
      ) {
        return newVal;
      }
      return item;
    });
    setTemporaryListTypographies(newTypographiesList);
  };

  return (
    <FormCustom>
      {listTypographies.length > 0 ? (
        <>
          {listTypographies.map((typography, index) => (
            <Grid
              container
              direction="row"
              spacing={1}
              key={`index_listTypographies_${index}`}
              id={`index_listTypographies_${index}`}
              sx={{ marginBottom: "1rem", width: "100%" }}
            >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <InputLabelCustom>Typographie {index + 1}</InputLabelCustom>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <TypoPicker
                    typography={typography}
                    typographyDatas={typography.typographyDatas}
                    typographyMarkups={typography.typographyMarkups}
                    typographySystem={typography.typographySystem}
                    index={index}
                    listTypographies={listTypographies}
                    listMarkups={listMarkups}
                    listTypographiesSystem={listTypographiesSystems}
                    onChange={(newTypography) => {
                      handleChangeComplete(newTypography);
                    }}
                    onDelete={() => {
                      deleteTypo(typography);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}

          {listTypographies.length < maxLength && (
            <div
              onClick={() => {
                addTypo();
              }}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                paddingTop: "13px",
              }}
            >
              <AddIconCustom style={{ marginBottom: 0, marginRight: 10 }} />
              <SpanAddCustom>Ajouter une typographie</SpanAddCustom>
            </div>
          )}
        </>
      ) : (
        <div
          onClick={() => {
            addTypo();
          }}
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            minHeight: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            paddingTop: "13px",
          }}
        >
          <AddIconCustom />
          <SpanAddCustom>Ajouter une typographie</SpanAddCustom>
        </div>
      )}
    </FormCustom>
  );
};

TypographySelector.propTypes = {
  listTypographies: PropsTypes.arrayOf(
    PropsTypes.shape({
      id: PropsTypes.string,
      tempoId: PropsTypes.string,
      typographyDatas: PropsTypes.arrayOf(
        PropsTypes.shape({
          id: PropsTypes.string,
          tempoId: PropsTypes.string,
          px: PropsTypes.string,
          markup: PropsTypes.shape({
            id: PropsTypes.string,
            libelle: PropsTypes.string,
          }),
        }).isRequired
      ),
      typographyMarkups: PropsTypes.arrayOf(
        PropsTypes.shape({
          id: PropsTypes.string,
          libelle: PropsTypes.string,
        })
      ).isRequired,
      typographySystem: PropsTypes.shape({
        id: PropsTypes.string,
        identifier: PropsTypes.string,
        libelle: PropsTypes.string,
        value: PropsTypes.string,
      }),
      identifier: PropsTypes.string,
    })
  ),
  listMarkups: PropsTypes.arrayOf(
    PropsTypes.shape({
      id: PropsTypes.string.isRequired,
      libelle: PropsTypes.string.isRequired,
    })
  ),
  listTypographiesSystems: PropsTypes.arrayOf(
    PropsTypes.shape({
      id: PropsTypes.string.isRequired,
      identifier: PropsTypes.string,
      libelle: PropsTypes.string.isRequired,
      value: PropsTypes.string.isRequired,
    })
  ),
  onChange: PropsTypes.func,
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(TypographySelector)
);
