import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import propTypes from "prop-types";

const RadioGroupInput = ({
  id,
  label,
  value,
  options,
  radioProps,
  radioGroupProps,
  required = false,
  onChange,
}) => {
  return (
    <FormControl>
      <FormLabel id={id}>
        {label}
        {required && "*"}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => onChange(e)}
        {...radioGroupProps}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio {...radioProps} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioGroupInput.propTypes = {
  id: propTypes.string,
  label: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  options: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.oneOfType([propTypes.string, propTypes.bool]).isRequired,
      label: propTypes.string.isRequired,
    })
  ).isRequired,
  onChange: propTypes.func,
};

export default RadioGroupInput;
