import { GET_CATEGORIES_LIGHT_2 } from "@queries/categories";

export const initCategories = async (client, setState, state) => {
  try {
    const { data } = await client.query({
      query: GET_CATEGORIES_LIGHT_2,
      variables: {
        exists: [
          {
            catalog: false,
          },
        ],
      },
    });
    setState((prevState) => ({
      ...prevState,
      categoriesData: data.categories,
    }));
  } catch (error) {
    console.log(error);
  }
};
