import React from 'react';
import { Grid } from '@mui/material';
import Typography from '../../../../ui/typography/Typography';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader'
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../../CardCustom';
import * as moment from 'moment';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LanguageIcon from '@mui/icons-material/Language';
import imgNotFound from '../../../../../assets/images/not-found.png';
import CircularProgress from '@mui/material/CircularProgress';

const FeedItem = styled(Grid)`
    margin-bottom:30px;
    img{
        width:100%;
    }
`

const SocialCounter = styled(Grid)`
    color:${colors.blue.lighter.hue150}
`

const PostDate = styled(Typography)`
    color:#0273A5;
`

const MessagePost = styled(Typography)`
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`

const SeeMore = styled(Typography)`
    color:${colors.blue.darker.hue300};
    text-align: center;
    cursor: pointer;
`

const SocialsIcon=styled.div`
    width:25px;
    height:25px;
    border-radius:50%;
    background:red;
    display:flex;
    border:3px solid white;
    transform : scale(1);
    transition:all .5s;
    &:hover{
        transform : scale(1.1); 
    }
`
export default class CardLinkedIn extends React.Component{

    handleClick() {
        this.props.handleLoad()
    }

    render() {
        
        let getImageType = (asset) => {
            const images = require.context('../../../../../assets/images', true);
            let image;
            try{
                image=images('./' + asset);
            } catch(e){
                image=imgNotFound;
            }
            return image;
        }

        let props = this.props;
        let posts=this.props.data;
        let pagination=this.props.pagination;
        let img = getImageType(props.type.logo);
        return (
            <>
                {posts?.length > 0 ? 
                    posts.map((post,index)=>
                    <FeedItem item xs={4} key={`post-${index}`}>
                        <CardCustom contentpadding={"0px 0px 20px 0px"} style={{height: "100%"}} cardContentStyle={{display: "flex", flexDirection:'column'}}>
                                <CardHeader
                                    action={
                                    <IconButton aria-label="settings" onClick={(event)=>{this.props.handleSettings(event,post)}}>
                                        <MoreVertIcon style={{color:'#0273A5'}}/>
                                    </IconButton>
                                    }

                                />
                                <Grid container direction='column' style={{height: "100%"}}>
                                    <Grid item xs={12} style={{maxHeight: 480, overflow: 'hidden', position: 'relative', flex: 1}}>
                                        {
                                            post.content?.contentEntities?.[0]?.thumbnails?.[0].resolvedUrl ?
                                                <img src={post.content.contentEntities[0].thumbnails?.[0].resolvedUrl} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                                                : <img src={typeof img === "string" ? img : img?.default} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                                        }
                                    </Grid >                                   

                                    <Grid item xs={12} style={{padding:20,backgroundColor:colors.grey.lighter.hue980, flex: 0}}>
                                        <MessagePost variant="body2">
                                            {post.text?.text}
                                        </MessagePost>
                                    </Grid>
                                    {post.content?.contentEntities?.[0].entityLocation && post.content?.shareMediaCategory === "ARTICLE" ?
                                        <SocialCounter item xs={12} style={{padding:'20px  20px', flex: 0}}>
                                            <Grid container alignItems="center" direction="row">
                                                <LanguageIcon/> 
                                                <a href={post.content.contentEntities[0].entityLocation} style={{marginLeft:10,color:colors.blue.lighter.hue150}} target="_blank" rel="noreferrer">Lien intégré à la publication</a>
                                            </Grid>

                                        </SocialCounter>
                                        :<div style={{height: 64, width: "100%"}}></div>
                                    }
                                    <Grid item xs={12} style={{padding:'0  20px', display: 'flex', alignItems: "flex-end", flex: 0}}>
                                        <Grid container space={1} alignItems="center" style={{height: 25}}>
                                            <Grid item xs={2} style={{flexGrow:'1'}}>
                                                <PostDate variant="body2">{moment(post.created_time).format('L')}</PostDate>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </CardCustom>
                    </FeedItem>                                    
                )
                : <PageLoader/>}
                {pagination?.total > posts.length ?
                    <Grid container justifyContent="center" style={{paddingBottom: 24}}>
                        {
                            this.props.loader ?
                                <CircularProgress color="inherit" size={'3rem'} 
                                style={{padding: 0}}
                                />
                            :
                                <SeeMore variant="body2" onClick={()=> this.handleClick()}>Voir plus <br/> ...</SeeMore>
                        }
                    </Grid>
                    : null 
                }
            </>
        );
    }
}