import React, { Suspense } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient, InMemoryCache, Observable, ApolloLink, HttpLink } from '@apollo/client';
import store from "./js/redux/store/index";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as moment from 'moment';
import 'moment-duration-format';
import patch from './patch';
import i18n from './i18n';
import './i18n';
import { QueryClient, QueryClientProvider } from 'react-query';
import { deleteLocalStorage } from './js/utils/deleteLocalStorage';
import { ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { StylesProvider } from '@mui/styles';
import { StyledEngineProvider } from '@mui/system';
import axios from 'axios';
const queryClient = new QueryClient();

const cache = new InMemoryCache();

//axios config
const token = localStorage.getItem('AUTH_TOKEN');
axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';

const request = async (operation) => {
  const token = localStorage.getItem('AUTH_TOKEN');
  operation.setContext({
    headers: { Authorization: token ? `Bearer ${token}` : '' }
  });
};

const requestLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        // sendToLoggingService(graphQLErrors);
      }
      if (networkError) {
        // window.location.href = `/login`;
        // window.location.href = `/login?returnTo=${window.location.href}`;
        // deleteLocalStorage()
      }
    }),
    requestLink,
    new HttpLink({
      uri: process.env.REACT_APP_API_GQL
    })
  ]),
  headers: {
    authorization: `Bearer: ${localStorage.getItem('AUTH_TOKEN')}`,
  },
  cache
});

if (localStorage.getItem('DXP_LANG')) {
  let lang = localStorage.getItem('DXP_LANG');
  i18n.changeLanguage(lang);
} else {
  localStorage.setItem('DXP_LANG', 'fr_FR');
  i18n.changeLanguage('fr_FR');
}
patch(client);

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(
  // <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <Router>
              <DndProvider backend={HTML5Backend}>
                <Suspense fallback={null}>
                  <App />
                </Suspense>
              </DndProvider>
            </Router>
          </Provider>
        </ApolloProvider>
      </QueryClientProvider>
    </StyledEngineProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Auto redirect if token is expired

function logout() {
  deleteLocalStorage();

  window.location.href = '/login';

  // client.query({
  //     fetchPolicy: 'no-cache',
  //     query: gql`{ locales { totalCount } }`
  // });
}

setInterval(function () {
  let isLogin = window.location.href.indexOf('login') > -1 || window.location.href.indexOf('password') > -1;

  if (isLogin) return;

  let user = localStorage.getItem('AUTH_USER');
  let expiry = localStorage.getItem('AUTH_TOKEN_EXPIRY');

  if (!user || !expiry) {
    // in case another browser tab already cleared local storage
    window.location.href = '/login';
    return;
  }

  try {
    expiry = moment(expiry);

    let remaingSeconds = expiry.diff(moment(), 'seconds');

    console.log(`Remaining session time : ${remaingSeconds}s (${moment.duration(remaingSeconds, 'seconds').format('h[h]:mm[m]:ss[s]')})`);

    if (remaingSeconds < 0) logout();
  } catch (e) {
    console.log(e);
  }
}, 10000);