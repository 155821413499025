import React, { useEffect, useRef, useState } from "react";
import H2 from '../shared/h2';
import Div from '../shared/div';
import P from '../shared/p';
import styled from 'styled-components';
import Img from '../shared/img';

const DivColumnsTitleParagraph = styled(Div)`
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    padding-top: 14px;
    padding-bottom: 50px;
    max-width: calc(100vw - 32px);
    overflow: hidden;
    .columnTitle {
        width: 100%;
        padding-right: 20px;
    }
    .columnTitle h2 {
        margin-bottom: 18px;
        font-size: 1.4375rem;
        line-height: 1.2;
        font-family: 'DINOffc-Bold',sans-serif;
    }
    .columnTitle p {
        font-size: 1.125rem;
        line-height: 1.2;
        font-family: 'DinRegular', sans-serif;
        width: 100%;
        padding-bottom: 23px;
        margin-bottom: 23px;
        border-bottom: 2px solid rgb(var(--lcs-lightgray));
        max-width: 480px;
    }
    @media screen and (min-width: 768px){
        flex-direction: row;
        .columnTitle{
            width: 40%;
            min-width: 300px;
        }
    }
    @media screen and (min-width: 900px){
        flex-direction: row;
        padding-top: 30px;
        padding-bottom: 50px;
        .columnTitle p {
            max-width: 550px;
            border-bottom: 0;
        }
    }
    @media screen and (min-width: 1024px){
        padding-right: 22px;
        padding-left: 22px;
        max-width: calc(100% - 44px);
        .columnTitle {
            width: 40%;
            min-width: 450px;
            padding-right: 60px;
        }
        .columnTitle h2 {
            margin-bottom: 18px;
            font-size: 1.75rem;
        }
        .columnTitle p {
            font-size: 1.375rem;
        }
    }
    @media screen and (min-width:1450px){
        padding-right: 60px;
        padding-left: 60px;
        max-width: calc(100% - 120px);
    }
    @media screen and (min-width: 1600px){
        .columnTitle {
            padding-right: 150px;
        }
    }
    .blocks{
        display: grid;
        grid-template-columns: 1fr;
        gap: 25px;
        @media screen and (min-width:768px) {
            grid-template-columns: repeat(2,1fr);
        }
        @media screen and (min-width:1024px) {
            grid-template-columns: repeat(3,1fr);
            gap: 56px;
        }

    }
    .title{
        font-family: 'DINOffc-Bold',sans-serif;
        font-size: 1.875rem;
        line-height: 1.2;
        color: rgb(6 14 24);
        text-transform: uppercase;
        -webkit-text-stroke : 1px;
        -webkit-text-stroke-color : rgb(6 14 24);
        padding-right: 15px;
        margin: 0;
        span{
            color: white;
        }
    }
    .heading{
        display: flex;
        align-items: center;
        padding-bottom: 10px;
    }
    .description {
        font-family: 'Univers', sans-serif;
        line-height: 1.4em;
        p{
            margin: 0;
        }
    }
`;

const splitTitle = title =>{
    const firstLetter = title[0];
    const rest = title.slice(1);
    return(
        <>
            {firstLetter}
            <span>{rest}</span>
        </>
    )
}


const SectionManifestBlock = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data,
        inputCallback = null,
        spread = false
    } = props;

    
    return (
        <Div  style={{ position: 'relative', background: data.inputs.backgroundColor.value }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <DivColumnsTitleParagraph className="columnsTitleParagraph">
                <Div className="columnTitle">
                    <H2 className="w100 uppercase">{data.blocks.title.inputs.value.value}</H2>
                    <Div dangerouslySetInnerHTML={{__html: data.blocks.text.inputs.value.value}}/> 
                </Div>
                <Div className="blocks">
                    {
                        data.blocks.description.config.map((bloc, index) => (
                            <div key={index}>
                                <div className="heading">
                                    <h4 className="title">
                                        {splitTitle(bloc.blocks.title.inputs.value.value)}
                                    </h4>
                                    {
                                        bloc.blocks.imagePrincipale?.inputs.value.value ? (
                                            <Img 
                                                src={
                                                    typeof bloc.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                                        bloc.blocks.imagePrincipale?.inputs.value.value 
                                                    : bloc.blocks.imagePrincipale?.inputs.value?.value?.filePath ? 
                                                        `${process.env.REACT_APP_MEDIAS}/${bloc.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                                    : null
                                                }
                                                alt={bloc.blocks.title.inputs.value.value ?? "Illustration"}
                                            />
                                        ) : null
                                    }
                                </div>
                                <div className="description" dangerouslySetInnerHTML={{__html: bloc.blocks.description.inputs.value.value}} />
                            </div>
                        ))
                    }
                </Div>
            </DivColumnsTitleParagraph>
        </Div>
    );
};

export default SectionManifestBlock;