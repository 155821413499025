const userGroupCardsMapper = async (flows) => {
  try {
    let results = flows.map((flow) => {
      return {
        id: flow.node.id,
        code: flow.node.code,
        createdAt: flow.node.createdAt,
        name: flow.node.libelle,
        users: flow.node.users?.edges.map((user) => ({
          id: user.node.id,
          name: user.node.lastname + user.node.firstname,
          firstname: user.node.firstname,
          lastname: user.node.lastname,
          email: user.node.email,
          image: user.node.image,
          phone: user.node.phone,
          job: user.node.job,
          isActive: user.node.isActive
        })),
      };
    });
    return await results;
  } catch (error) {
    console.log(error);
  }
};

export { userGroupCardsMapper };
