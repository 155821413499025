import React from 'react';
import { Box, Divider, Fade, Grid, Menu, MenuItem, Typography } from '@mui/material';

import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';

import Button from '../../../ui/button/Button';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardSetup from '../../../layouts/Card/cardContent/CardSetup';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';

import { withApollo } from '@apollo/client/react/hoc';
import {
    GET_CHANNEL_CATEGORIES,
    GET_CHANNELS_BY_CATEGORY,
    GET_CHANNEL_BY_ID,
    ADD_CHANNEL,
    UPDATE_CHANNEL,
    ADD_CHANNEL_DATA,
    GET_CHANNEL_EXTERNAL_KEYS,
    ADD_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
    GET_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
    GET_CHANNEL_DATA,
    DELETE_CHANNEL,
    DELETE_CHANNEL_DATA,
    DELETE_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
    UPDATE_CHANNEL_DATA,
    UPDATE_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
    GET_ALL_CHANNELS
} from '../../../../queries/channels';
import { GET_ATTRIBUTES } from '../../../../queries/attributes';
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';

import { eventService } from '../../../../js/services/event.service';

import EmptyCMS from '../../../../assets/pictos/empty-picto/empty_cms.png';
import EmptyMails from '../../../../assets/pictos/empty-picto/empty_mails.png';
import EmptyMedias from '../../../../assets/pictos/empty-picto/empty_medias.png';
import EmptyConnectors from '../../../../assets/pictos/empty-picto/empty_connectors.png';
import EmptySocial from '../../../../assets/pictos/empty-picto/empty_rs.png';
import EmptyMarketplace from '../../../../assets/pictos/empty-picto/empty_marketplaces.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import cmsConfig from './config/cms.config';
import cmsAddConfig from './config/cmsAdd.config';

import request from '../../../../js/utils/fetch';
import slugify from 'slugify';
import {
    ROUTE_CHANNELS_CMS,
    ROUTE_CHANNELS_CONNECTORS,
    ROUTE_CHANNELS_CONTENTS,
    ROUTE_CHANNELS_MARKETPLACES,
    ROUTE_CHANNELS_MEDIAS,
    ROUTE_CHANNELS_SOCIALS,
} from '../../../../js/constants/route-names';
import {
    CONNECTORS_CMS,
    CONNECTORS_SOCIAL_NETWORK,
    CONNECTORS_MARKETPLACES,
    CONNECTORS_MEDIAS,
    CONNECTORS_EMAILS,
    CONNECTORS_CONTENTS,
    CONNECTORS_CONNECTORS,
} from '../../../../js/constants/constant-rights';
import { setRedux } from '../../../../js/utils/functions';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import { getParams } from '../../../../js/utils/getParams';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

const MenuCustom = styled(Menu)`
    transform: translateY(30px);
`

const MenuItemCustom = styled(MenuItem)`
    color: ${props => props.isSelected === props.label ? colors.white : colors.blue.darker.hue300};
    background-color: ${props => props.isSelected === props.label && colors.blue.darker.hue300 + ' !important'};
`

const MenuGrid = styled(Box)`
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-weight: 700;
    color: ${colors.blue.darker.hue300};
    cursor: pointer;
`


class ListChannels extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filtered: [],
            typeOf:[],
            filteredCat: [],
            valueSelect: [],
            imageForm: '',
            openForm: false,
            openFormSetupChannel: false,
            editForm: 'add',
            isEmpty: false,
            channelCategorie: null,
            channelByCategorie: null,
            emptyPicto: null,
            dataLayout: null,
            openDialogRemove: false,
            errors: {},
            seeErrors: false,
            getRights: null,
            typeTesting: {
                type: 'channel',
                testingState: ['libelle', 'attributeIdentifier'],
                testingTypingState: 'libelle',
                identifierState: 'attributeIdentifier'
            },
            anchorEl: null,
            isMenuOpened: false,
            seeMore: false,
            selectedMenuItemLabel: undefined,
            cardDrawerTopPanelLayout: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.typingTimer = null;
    }

    setCardDrawerTopPanelLayout = () => {
        switch (this.props.location.pathname) {
            case ROUTE_CHANNELS_MARKETPLACES:
                return {
                    topPanel: {
                        title: '+ ' + this.props.t('connectors.marketplaces.addMarket'),
                        subtitle: 'Veuillez compléter les champs ci-dessous pour configurer votre marketplace'
                    }
                }
            case ROUTE_CHANNELS_SOCIALS:
            case ROUTE_CHANNELS_CMS:
            case ROUTE_CHANNELS_CONTENTS:
            case ROUTE_CHANNELS_MEDIAS:
            case ROUTE_CHANNELS_CONNECTORS:
            default:
                return {
                    topPanel: {}
                }
        }
    }

    setDropdownMenuAllTitle = () => {
        switch (this.props.location.pathname) {
            case ROUTE_CHANNELS_MARKETPLACES:
                return 'Toutes les marketplaces'
            case ROUTE_CHANNELS_SOCIALS:
                return 'Tous les réseaux sociaux'
            case ROUTE_CHANNELS_CMS:
                return 'Tous les CMS'
            case ROUTE_CHANNELS_CONTENTS:
                return 'Tous les contenus'
            case ROUTE_CHANNELS_MEDIAS:
                return 'Tous les médias'
            /* Custom */
            default:
                return 'Tous les connecteurs'
        }
    }

    handleChange(e) {
        let currentList = [];
        let newList = [];
        let newList2 = [];
        let result = [];
        if (e) {
            if (e.target.value !== "") {
                currentList = this.state.filteredCat;
                newList = currentList.filter(item => {
                    const lc = item.node.libelle.toLowerCase();
                    const filter = e.target.value.toLowerCase();
                    return lc.includes(filter);
                });
                newList2 = currentList.filter(item => {
                    const lc = item.node.description.toLowerCase();
                    const filter = e.target.value.toLowerCase();
                    return lc.includes(filter);
                });
                result = newList.concat(newList2);

                let j = 0;
                for (let item of result) {
                    let i = 0;
                    for (let item2 of result) {
                        if (item.node.id === item2.node.id && j < i) {
                            result.splice(i)
                        }
                        i++;
                    }
                    j++;
                }
            } else {
                // If the search bar is empty, set newList to original task list
                result = this.state.filteredCat;
            }
            // Set the filtered state based on what our rules added to newList
            this.setState({
                filtered: result ?? null
            });
        } else {
            this.setState({
                filtered: this.state.filteredCat ?? null
            });
        }
    }

    handleFilterCategories = (event, id) => {
        const currentList = this.state.channelByCategorie;
        let newList = [];
        if (id) {
            newList = currentList.filter(item => {
                // for(let channel of item){
                if (item.node.attributeGroup.id === id) {
                    const lc = id.toLowerCase();
                    // change search term to lowercase
                    const filter = id.toLowerCase();
                    // check to see if the current list item includes the search term
                    // If it does, it will be added to newList. Using lowercase eliminates
                    // issues with capitalization in search terms and search content
                    return lc.includes(filter);
                } else {
                    return null
                }
                // }
            });
        } else {
            newList = this.state.channelByCategorie;
        }
        this.setState({
            filteredCat: newList ?? null
        });
    };

    doneTyping = (stateName) => {
        let typeTesting = this.state.typeTesting;
        if (stateName === typeTesting.testingTypingState) {
            this.setState({
                [typeTesting.identifierState]: slugify(this.state[typeTesting.testingTypingState], { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            });
        }

        if (this.state[typeTesting.identifierState]) {
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${this.state[typeTesting.identifierState]}`, 'get')
            .then((data) => {
                    if (data.success) {
                        eventService.fire({ stateName: typeTesting.identifierState, errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                    }
                }
            ).catch((err)=>{
                console.log(err)
            });
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.props.locales[0].node.id];
            if (!values) {
                values = {};
            }
            values[stateName] = value;
            this.setState({
                [this.props.locales[0].node.id]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            });
        }
        if (this.state.editForm === 'add' && this.state.typeTesting.testingState.includes(stateName))
            this.checkIdentifier(stateName)
    }

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    }

    handleInitData = (typeChannel, firstTime) => {
        let getRights = null
        if (typeChannel === 'cms') {
            getRights = CONNECTORS_CMS;
            this.setState({
                content: {
                    title: this.props.t(process.env.REACT_APP_MODE_SPREAD !== "hub" ?"connectors.marketplaces.manageCms":"connectors.marketplaces.manageSalesChannels")?? null,
                    subtitle: this.props.t(process.env.REACT_APP_MODE_SPREAD !== "hub" ?"connectors.marketplaces.manageCmsInfo":"connectors.marketplaces.manageSalesChannelsInfo")?? null,
                    picto: "icon-cms",
                    txtBtn: process.env.REACT_APP_MODE_SPREAD !== "hub" ? '+ ' + this.props.t("connectors.marketplaces.connectNewCms")??null : null,
                    emptyTitle: this.props.t("connectors.marketplaces.notConfiguredCms")?? null,
                    emptySubtitle: this.props.t("spread.active_assets.clickToAdd")?? null,
                    emptyTxtBtn: this.props.t("connectors.marketplaces.notConfiguredCms")?? null,
                    emptyPicto: EmptyCMS,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_CMS,
            }, () => {
                let finalStep =
                {
                    // picto: EmptyCMS,
                    title: this.props.t("connectors.retailers.andThereYouGo"),
                    subtitle: this.props.t("connectors.retailers.spreadInfo"),
                    textButton: this.props.t("connectors.retailers.connect")
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout ?? null
                });
            });
        }
        else if (typeChannel === 'social') {
            getRights = CONNECTORS_SOCIAL_NETWORK;
            this.setState({
                content: {
                    title: this.props.t("connectors.marketplaces.manageSocial") ?? null,
                    subtitle: this.props.t("connectors.marketplaces.manageSocialInfo")?? null,
                    picto: "picto-rs",
                    txtBtn: '+ ' + this.props.t("connectors.marketplaces.addAccount")?? null,
                    emptyTitle: this.props.t("connectors.marketplaces.notConfiguredSocial")?? null,
                    emptySubtitle: this.props.t("spread.active_assets.clickToAdd")?? null,
                    emptyTxtBtn: this.props.t("spread.campaigns.addSocial")?? null,
                    emptyPicto: EmptySocial,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_SOCIAL_NETWORK,
            }, () => {
                let finalStep =
                {
                    // picto: EmptySocial,
                    title: this.props.t("connectors.retailers.andThereYouGo"),
                    subtitle: this.props.t("connectors.retailers.spreadInfo"),
                    textButton: this.props.t("connectors.retailers.connect")
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout ?? null
                });
            });
        }
        else if (typeChannel === 'marketplace') {
            getRights = CONNECTORS_MARKETPLACES;
            this.setState({
                content: {
                    title: this.props.t("connectors.marketplaces.manageMarket") ?? null,
                    subtitle: this.props.t("connectors.marketplaces.manageMarketInfo") ?? null,
                    picto: "picto-marketplace",
                    txtBtn: '+ ' + this.props.t("connectors.marketplaces.addMarket")?? null,
                    emptyTitle: this.props.t("connectors.marketplaces.notConfiguredMarket")?? null,
                    emptySubtitle: this.props.t("connectors.retailers.clickToAddBrand")?? null,
                    emptyTxtBtn: this.props.t("connectors.marketplaces.addMarket")?? null,
                    emptyPicto: EmptyMarketplace,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_MARKETPLACES,
            }, () => {
                let finalStep =
                {
                    // picto: EmptyMarketplace,
                    title: this.props.t("connectors.retailers.andThereYouGo"),
                    subtitle: this.props.t("connectors.retailers.spreadInfo"),
                    textButton: this.props.t("connectors.retailers.connect")
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout ?? null
                });
            });
        } else if (typeChannel === 'media') {
            getRights = CONNECTORS_MEDIAS;
            this.setState({
                content: {
                    title: this.props.t("connectors.marketplaces.manageConnectorsMedia") ?? null,
                    subtitle: this.props.t("connectors.marketplaces.manageConnectorsMediaInfo")?? null,
                    picto: "picto-media",
                    txtBtn: '+ ' + this.props.t("connectors.marketplaces.connectNewMediaConnector")?? null,
                    emptyTitle: this.props.t("connectors.marketplaces.notConfiguredMediaConnector")?? null,
                    emptySubtitle: this.props.t("spread.active_assets.clickToAdd")?? null,
                    emptyTxtBtn: this.props.t("connectors.marketplaces.addMediaConnector")?? null,
                    emptyPicto: EmptyMedias,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_MEDIAS,
            }, () => {
                let finalStep =
                {
                    // picto: EmptyCMS,
                    title: this.props.t("connectors.retailers.andThereYouGo"),
                    subtitle: this.props.t("connectors.retailers.spreadInfo"),
                    textButton: this.props.t("connectors.retailers.connect")
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout ?? null
                });
            });
        } else if (typeChannel === 'newsletter') {
            getRights = CONNECTORS_EMAILS;
            this.setState({
                content: {
                    title: this.props.t("connectors.marketplaces.manageConnectorsNewsletters")?? null,
                    subtitle: this.props.t("connectors.marketplaces.manageMailer")?? null,
                    picto: "ico_newsletter",
                    txtBtn: '+ ' + this.props.t("connectors.marketplaces.connectNewMailConnector")?? null,
                    emptyTitle: this.props.t("connectors.marketplaces.notConfiguredMailConnector")?? null,
                    emptySubtitle: this.props.t("spread.active_assets.clickToAdd")?? null,
                    emptyTxtBtn: this.props.t("connectors.marketplaces.addMailConnector")?? null,
                    emptyPicto: EmptyMails,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_EMAILS,
            }, () => {
                let finalStep =
                {
                    // picto: EmptyCMS,
                    title: this.props.t("connectors.retailers.andThereYouGo"),
                    subtitle: this.props.t("connectors.retailers.spreadInfo"),
                    textButton: this.props.t("connectors.retailers.connect")
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout ?? null
                });
            });
        }
        else if (typeChannel === 'content') {
            getRights = CONNECTORS_EMAILS;
            this.setState({
                content: {
                    title: this.props.t("connectors.marketplaces.manageContentConnector")?? null,
                    subtitle: this.props.t("connectors.marketplaces.manageContentConnectorInfo")?? null,
                    picto: "picto-connector-content",
                    txtBtn: '+ ' + this.props.t("connectors.marketplaces.connectNewContentConnector")?? null,
                    emptyTitle: this.props.t("connectors.marketplaces.notConfiguredConnector")?? null,
                    emptySubtitle: this.props.t("spread.active_assets.clickToAdd")?? null,
                    emptyTxtBtn: this.props.t("connectors.marketplaces.addConnector")?? null,
                    emptyPicto: EmptyCMS,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_CONTENTS,
            }, () => {
                let finalStep =
                {
                    // picto: EmptyCMS,
                    title: this.props.t("connectors.retailers.andThereYouGo"),
                    subtitle: this.props.t("connectors.retailers.spreadInfo"),
                    textButton: this.props.t("connectors.retailers.connect")
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout?? null
                });
            });
        }
        else if (typeChannel === 'connector') {
            getRights = CONNECTORS_CONNECTORS;
            this.setState({
                content: {
                    title: this.props.t(process.env.REACT_APP_MODE_SPREAD !== "hub" ?"connectors.marketplaces.manageConnector":"connectors.marketplaces.manageLogisticians") ?? null,
                    subtitle: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gérez ici vos connecteurs custom" : "Gérer ici vos logisticiens",
                    picto: "picto-connector",
                    txtBtn: process.env.REACT_APP_MODE_SPREAD !== "hub" ? '+ ' + "Connecter un nouveau connecteur custom" : null,
                    emptyTitle: "Vous n'avez pas encore configuré de connecteur",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter un connecteur",
                    emptyPicto: EmptyConnectors,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_CONNECTORS,
            }, () => {
                let finalStep =
                {
                    // picto: EmptyCMS,
                    title: this.props.t("connectors.retailers.andThereYouGo"),
                    subtitle: this.props.t("connectors.retailers.spreadInfo"),
                    textButton: this.props.t("connectors.retailers.connect")
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout?? null
                });
            });
        }
        if (process.env.REACT_APP_MODE_SPREAD === "hub") {
            request(`${process.env.REACT_APP_API}/warehousePreference`, 'get', false)
            .then((data) => {
                    if (data.success) {
                        this.setState({
                            preferences: data.preferences
                        });
                    }
                }
            ).catch((err)=>{
                console.log(err)
            });
        }

        if (firstTime && (getParams(this.props).includes('formOpen') || this.props.history.location?.state?.formOpen)) {
            this.handleToggleDrawer('form')
        }
    }

    handlerSetup = (id, typeId) => {
        this.props.client.query({
            query: GET_CHANNEL_BY_ID,
            variables: { id: id },
            fetchPolicy: 'no-cache',
        }).then(result => {
            this.setState({
                imageForm: result.data.channel.logo,
                attrGroupId: result.data.channel.attributeGroup.id,
                typeOfId: typeId??[]
            });

            if (this.state.editForm === 'edit') {
                let children = 0;
                for (let item of this.state.dataAddLayout.formConfig.children) {
                    let optionsInputs = 0;
                    for (let input of item.optionsInputs) {
                        if (input.stateName === "attributeIdentifier") {
                            let getLayoutAdd = this.state.dataAddLayout
                            getLayoutAdd.formConfig.children[children].optionsInputs[optionsInputs].disabled = true
                            this.setState({
                                dataAddLayout: getLayoutAdd ?? null
                            });
                        }
                        optionsInputs++;
                    }
                    children++;
                }
                this.setState({
                    libelle: result.data.channel.libelle,
                    attributeIdentifier: result.data.channel.identifier,
                    description: result.data.channel.description
                });
                for (let item of result.data.channel.attributeGroup.attributes.edges) {
                    let identifier = item.node.identifier;
                    for (let value of result.data.channel.channelDatas.edges) {
                        if (value.node.attribute.identifier === identifier) {
                            this.setState({
                                [identifier]: item.node.attributeType.input === 'select' ? value.node.attributeOption.id : value.node.value,
                            });
                        }
                    }
                }
                this.props.client.query({
                    query: GET_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
                    variables: { "limit": 1000 },
                    fetchPolicy: 'no-cache',
                }).then(result => {
                    this.setState({ externalKeyAttribute: result.data.channelExternalKeyAttributes });
                    for (let externalKey of result.data.channelExternalKeyAttributes.edges) {
                        if (externalKey.node.channel.id === id) {
                            this.setState({
                                [`${externalKey.node.externalKey.externalKey}_externalkey`]: externalKey.node.attribute.id,
                            });
                        }
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }

            else if (this.state.editForm === "add") {
                this.setState({
                    libelle: undefined,
                    description: undefined,
                    attributeIdentifier: undefined
                });
                let children = 0;
                for (let item of this.state.dataAddLayout.formConfig.children) {
                    let optionsInputs = 0;
                    for (let input of item.optionsInputs) {
                        if (input.stateName === "attributeIdentifier") {
                            let getLayoutAdd = this.state.dataAddLayout
                            getLayoutAdd.formConfig.children[children].optionsInputs[optionsInputs].disabled = false
                            this.setState({
                                dataAddLayout: getLayoutAdd?? null
                            });
                        }
                        optionsInputs++;
                    }
                    children++;
                }
            }
            let getLayoutAdd = this.state.dataAddLayout
            getLayoutAdd.titleForm = result.data.channel.libelle
            this.setState({
                dataAddLayout: getLayoutAdd?? null
            });
            let dataLayoutSetup = {
                labelName: 'Paramétrage',
                isOptionnal: false,
                optionsInputs: []
            };

            //CONSTRUCTION PARAMETRAGE
            for (let item of result.data.channel.attributeGroup.attributes.edges) {
                let label = '';
                if (item.node.translation.translationDatas) {
                    label = item.node.translation.translationDatas.edges[0].node.value;
                }
                else {
                    label = item.node.identifier
                }

                let helper = '';
                if (item.node.attributeHelper) {
                    helper = item.node.attributeHelper.translation.translationDatas.edges[0].node.value;
                }
                else {
                    helper = item.node.identifier
                }

                let setupInput = {
                    type: item.node.attributeType.input,
                    label: label,
                    helper: {
                        text: helper,
                        link: false,
                    },
                    required: true,
                    stateName: item.node.identifier,
                    value: null
                }
                if (item.node.attributeType.input === 'select') {
                    setupInput.value = item.node.attributeOptions.edges.map(attribute => {
                        const langSelectedValuesDefault = attribute.node.translation.translationDatas.edges[0];

                        const langSelectedValues = attribute.node.translation.translationDatas.edges.find(
                            lang => lang.node.locale.code === this.props.locales[0].node.id
                        );

                        return ({
                            value: attribute.node.id,
                            label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node.value ?? attribute.node.identifier
                        });
                    })
                }

                if (this.state.editForm === "edit") {
                    let idAttr = "";
                    for (let attr of result.data.channel.channelDatas.edges) {
                        if (attr.node.attribute.identifier === item.node.identifier) {
                            idAttr = attr.node.id;
                        }
                    }
                    setupInput.id = idAttr;
                }

                dataLayoutSetup.optionsInputs.push(setupInput)
                if (this.state.editForm === "add") {
                    this.setState({ [item.node.identifier]: undefined });
                }
            }
            let configFormAdd = this.state.dataAddLayout;
            configFormAdd.formConfig.children.splice(1, 1, dataLayoutSetup);
            this.setState({
                dataAddLayout: configFormAdd?? null
            })

            this.handleToggleDrawer('formSetupChannel');
            this.handlerGetAttributs();
        }).catch((err)=>{
            console.log(err)
        });
    }

    handlerGetAttributs = () => {
        this.props.client.query({
            query: GET_ATTRIBUTES,
            fetchPolicy: 'no-cache',
            variables: { "limit": 1000 }
        }).then(result => {
            let valueSelect = [];
            for (let item of result.data.attributes.edges) {
                for (let value of item.node.translation.translationDatas.edges) {
                    if (value.node.locale.id === this.props.locales[0].node.id)
                        valueSelect.push({ value: item.node.id, label: value.node.value, attributeGroup: item.node.attributeGroup.edges.node ? item.node.attributeGroup.edges.node.id : '' });
                }
            }
            this.setState({
                    valueSelect: valueSelect
                }, this.props.typeChannel !== 'connector' ? this.handlerGetExternalKeys() : null);
        }).catch((err)=>{
            console.log(err)
        });
    };

    handlerGetExternalKeys = () => {
        let dataLayoutSetup = {
            labelName: 'Liaisons',
            isOptionnal: false,
            optionsInputs: [],
        };
        this.props.client.query({
            query: GET_CHANNEL_EXTERNAL_KEYS,
            variables: { "limit": 1000 }
        }).then(result => {
            let datas = result.data.channelExternalKeys.edges;
            let externalKeysConfig = []
            for (let item of datas) {
                if (item.node.attributeGroup.id === this.state.attrGroupId) {
                    externalKeysConfig.push(`${item.node.externalKey}_externalkey`)
                    let setLink = {
                        type: 'select',
                        isContainerized: true,
                        label: item.node.translation.translationDatas.edges[0].node.value,
                        helper: {
                            text: item.node.translation.translationDatas.edges[0].node.value,
                            link: false,
                        },
                        required: true,
                        stateName: `${item.node.externalKey}_externalkey`,
                        id: item.node.id,
                        value: this.state.valueSelect
                    };
                    dataLayoutSetup.optionsInputs.push(setLink)
                    if (this.state.editForm === "add") {
                        let label = item.node.translation.translationDatas.edges[0].node.value;
                        let value = undefined;
                        for (let attr of this.state.valueSelect) {
                            if (label === attr.label) {
                                value = attr.value;
                            }
                        }
                        this.setState({ [`${item.node.externalKey}_externalkey`]: value });
                    }
                }
            }

            let configFormAdd = this.state.dataAddLayout;
            configFormAdd.formConfig.children.splice(2, 1, dataLayoutSetup);
            this.setState({
                externalKeysConfig,
                dataAddLayout: configFormAdd ??null
            });
        }).catch((err)=>{
            console.log(err)
        });
    }

    handleGetChannelByCategorie(id) {
        this.setState({
            typeOf: []
        })
        const getChannelByCategorie = GET_CHANNELS_BY_CATEGORY;
        const variables = { id: this.state.channelCategorie }
        this.props.client.query({
            query: getChannelByCategorie,
            variables,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                channelByCategorie: [],
                categories: [],
                filtered: result.data.channelCategory.channels.edges,
                filteredCat: result.data.channelCategory.channels.edges,
            }, () => {
                let notIsSystem = 0;
                for (let channel of result.data.channelCategory.channels.edges) {
                    if (channel.node.isSystem) {
                        channel.node.isLocaleImage = true;
                        let isDisabled = channel.node.identifier === "cloudcommerce-mailer-template"
                        if (isDisabled) {
                            channel.node.disabled = true
                            channel.node.secondaryStyle = true
                        }
                        this.setState({
                            typeOf :[...this.state.typeOf,channel.node]
                        })
                    }
                    else if (!channel.node.isSystem) {
                        notIsSystem++;
                        this.setState({
                            channelByCategorie:[...this.state.channelByCategorie,channel]
                        })
                    }
                }
                if (this.state.typeOf.length === 0 || notIsSystem === 0) {
                    this.setState({
                        isEmpty: true
                    });
                }
                if (this.state.channelByCategorie.length > 0) {
                    this.setState({
                        isEmpty: false
                    });
                }
                this.forceUpdate()
            });
        }).catch((err)=>{
            console.log(err)
        });
    }

    handleGetChannelsCategories() {
        const getChannelsCategories = GET_CHANNEL_CATEGORIES;
        this.props.client.query({
            query: getChannelsCategories,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.listChannelsCategories = result.data.channelCategories.edges;
            for (let item of this.listChannelsCategories) {
                if (item.node.identifier === this.props.typeChannel) {
                    this.setState({
                        channelCategorie: item.node.id,
                    }, () => {
                        this.handleGetChannelByCategorie(this.state.channelCategorie)
                    });
                }
            }
        }).catch((err)=>{
            console.log(err)
        });
    }

    handlerSaveExternalKeyMap = () => {
        for (let item of this.state.dataAddLayout.formConfig.children[2].optionsInputs) {
            if (this.state.editForm === "add") {
                this.props.client.mutate({
                    mutation: ADD_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
                    variables: {
                        "externalKey": item.id,
                        "attribute": this.state[item.stateName],
                        "channel": this.state.idNewChannel
                    },
                });
                this.handleToggleDrawer('form');
            }
            else if (this.state.editForm === "edit") {
                for (let channelExternalKeyAttribute of this.state.externalKeyAttribute.edges) {
                    if (item.id === channelExternalKeyAttribute.node.externalKey.id) {
                        if (channelExternalKeyAttribute.node.channel.id === this.state.idNewChannel) {
                            this.props.client.mutate({
                                mutation: UPDATE_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
                                variables: {
                                    "id": channelExternalKeyAttribute.node.id,
                                    "externalKey": item.id,
                                    "attribute": this.state[item.stateName],
                                    "channel": this.state.idNewChannel
                                },
                            })
                        }
                    }
                }
            }

        }
        this.handleToggleDrawer('formSetupChannel');
        this.handleGetChannelByCategorie(this.state.channelCategorie);
        this.props.stopLoading();
    }

    handlerSaveChannelData = () => {
        for (let channel of this.state.typeOf) {
            if (channel.attributeGroup.id === this.state.typeOfId) {
                for (let item of channel.attributeGroup.attributes.edges) {
                    let value = this.state[item.node.identifier];
                    if (this.state.editForm === "add") {
                        this.props.client.mutate({
                            mutation: ADD_CHANNEL_DATA,
                            variables: {
                                "value": item.node.attributeType.input === 'select' ? null : value,
                                "channel": this.state.idNewChannel,
                                "attribute": item.node.id,
                                "locale": this.props.locales[0].node.id,
                                "attributeOption": item.node.attributeType.input === 'select' ? value : null,
                            },
                        })
                    }
                    else if (this.state.editForm === "edit") {
                        let idAttr = ""
                        for (let input of this.state.dataAddLayout.formConfig.children[1].optionsInputs) {
                            if (item.node.identifier === input.stateName) {
                                idAttr = input.id;
                            }
                        }
                        this.props.client.mutate({
                            mutation: UPDATE_CHANNEL_DATA,
                            variables: {
                                "id": idAttr,
                                "value": item.node.attributeType.input === 'select' ? null : value,
                                "channel": this.state.idNewChannel,
                                "attribute": item.node.id,
                                "locale": this.props.locales[0].node.id,
                                "attributeOption": item.node.attributeType.input === 'select' ? value : null,
                            },
                        })
                    }
                }
            }
        }
    }

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
            this.props.startLoading();
            let query = null;
            let variables = null;
            switch (this.state.editForm) {
                case 'edit':
                    query = UPDATE_CHANNEL;
                    variables =
                    {
                        "id": this.state.editId,
                        "libelle": this.state.libelle,
                        "description": this.state.description
                    }
                    break;
                case 'add':
                    query = ADD_CHANNEL;
                    variables =
                    {
                        "identifier": this.state.attributeIdentifier,
                        "category": this.state.channelCategorie,
                        "attributeGroup": this.state.attrGroupId,
                        "libelle": this.state.libelle,
                        "logo": this.state.imageForm,
                        "description": this.state.description,
                        "isSystem": false
                    }
                    break;
                default:
                    return null;
            }
            this.props.client.mutate({
                mutation: query,
                variables,
            }).then(result => {
                localStorage.removeItem('GET_ALL_CHANNELS');
                let localStorage_GET_ALL_CHANNELS = localStorage.getItem('GET_ALL_CHANNELS');
                if (!localStorage_GET_ALL_CHANNELS) {
                    this.props.client.query({
                        query: GET_ALL_CHANNELS,
                    }).then(result => {
                        localStorage.setItem('GET_ALL_CHANNELS', JSON.stringify(result));
                    }).catch((err)=>{
                        console.log(err)
                    });
                }
                if (this.state.editForm === 'add') {
                    this.setState({
                        idNewChannel: result.data.createChannel.channel.id
                    }, () => {
                        this.handlerSaveChannelData();
                        if (this.props.typeChannel !== 'connector') {
                            this.handlerSaveExternalKeyMap()
                        } else {
                            this.handleToggleDrawer('formSetupChannel');
                            this.handleGetChannelByCategorie(this.state.channelCategorie);
                            this.props.stopLoading();
                        }
                    });
                }
                else if (this.state.editForm === "edit") {
                    this.setState({
                        idNewChannel: this.state.editId??null
                    }, () => {
                        this.handlerSaveChannelData();
                        if (this.props.typeChannel !== 'connector') {
                            this.handlerSaveExternalKeyMap()
                        } else {
                            this.handleToggleDrawer('formSetupChannel');
                            this.handleGetChannelByCategorie(this.state.channelCategorie);
                            this.props.stopLoading();
                        }
                    });
                }
            }).catch((err)=>{
                console.log(err)
            });
            if (this.state.editForm === 'add') {
                this.props.snack(ALERT_SUCCESS, 'Channel ajouté !');
            } else if (this.state.editForm === "edit") {
                this.props.snack(ALERT_SUCCESS, 'Channel modifié !');
            }
            setRedux(false, true, false, false)
            this.props.stopLoading();
        } catch {
            this.props.snack(ALERT_ERROR, 'Une erreur est survenue');
            this.props.stopLoading();
        }
    };

    deleteChannelExternalKeyAttributes = () => {
        return new Promise(async (resolve, reject) => {
            const result = await this.props.client.query({
                variables: { "limit": 100000 },
                query: GET_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
                fetchPolicy: 'no-cache'
            })
            for (let item of result.data.channelExternalKeyAttributes.edges) {
                if (item.node.channel.id === this.state.editId) {
                    await this.props.client.mutate({
                        mutation: DELETE_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
                        variables: { "id": item.node.id }
                    });
                }
            }
            resolve();
        });
    }

    deleteChannelDatas = () => {
        return new Promise(async (resolve, reject) => {
            const result = await this.props.client.query({
                variables: { "limit": 1000 },
                query: GET_CHANNEL_DATA,
                fetchPolicy: 'no-cache'
            })
            for (let item of result.data.channelDatas.edges) {
                if (item.node.channel.id === this.state.editId) {
                    await this.props.client.mutate({
                        mutation: DELETE_CHANNEL_DATA,
                        variables: { "id": item.node.id }
                    })
                }
            }
            resolve();
        });
    }

    deleteChannel = () => {
        this.props.client.mutate({
            mutation: DELETE_CHANNEL,
            variables: { "id": this.state.editId },
        }).then(result => {
            this.handleGetChannelByCategorie(this.state.channelCategorie);
            this.handleToggleDrawer('formSetupChannel');
            this.setState({
                openDialogRemove: false
            });
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Channel supprimé !');
        }).catch((err)=>{
            console.log(err)
        });
    }

    deleteMutation = async () => {
        this.props.startLoading();
        await this.deleteChannelExternalKeyAttributes();
        await this.deleteChannelDatas();
        this.deleteChannel();
    }

    editChannel = (id, typeId) => {
        this.setState({
            editForm: 'edit',
            editId: id,
        }, () => { this.handlerSetup(id, typeId) });
    }

    handleToggleDialog = () => {
        this.setState({
            openDialogRemove: !this.state.openDialogRemove ??null
        });
    }
    handleToggleDrawer = (drawer) => {
        if (drawer === 'form') {
            if (this.state.externalKeysConfig?.length > 0) {
                for (let externalKeyName of this.state.externalKeysConfig) {
                    this.setState({
                        [externalKeyName]: null
                    })
                }
            }
            this.setState({
                openForm: !this.state.openForm ??false,
                editForm: 'add',
                errors: {},
            });
            if (this.props.typeChannel === 'CMS' && !this.state.openForm) {
                this.setState({
                    dataAddLayout: cmsAddConfig(),
                });
            }
        }
        else if (drawer === 'formSetupChannel') {
            this.setState({
                openFormSetupChannel: !this.state.openFormSetupChannel ?? false,
                errors: {},
            }, () => {
                if (this.props.typeChannel === 'CMS' && !this.state.openFormSetupChannel) {
                    this.setState({
                        dataAddLayout: cmsAddConfig(),
                    });
                }
            });
        }
    }

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors:errors ??null });
    };


    componentDidMount() {
        checkRouting(this.props);
        this.handleGetChannelsCategories();
        this.handleInitData(this.props.typeChannel, true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.typeChannel !== prevProps.typeChannel) {
            checkRouting(this.props);
            this.setState({
                // getRights: null,
                identifier: this.props.typeChannel ??null,
                isEmpty: false,
                channelCategorie: null,
                channelByCategorie: null,
                typeOf: [],
                filtered: [],
                filteredCat: [],
                valueSelect: [],
                imageForm: '',
                openForm: false,
                openFormSetupChannel: false,
                editForm: 'add',
                emptyPicto: null,
                dataLayout: null,
                openDialogRemove: false
            }, () => {
                this.handleInitData(this.props.typeChannel);
                this.handleGetChannelsCategories();
            })
        }
        if (this.state.filteredCat !== prevState.filteredCat) {
            this.handleChange();
        }
    }

    handleClickMenu = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            isMenuOpened: true
        });
    };

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null,
            isMenuOpened: false
        });
        this.state.seeMore && (
            this.setState({
                seeMore: false
            })
        )
    };

    handleMenuChange = (event, id) => {
        const { value } = event.currentTarget.dataset;
        this.setState({
            selectedMenuItemLabel: value,
        }, () => {
            this.handleFilterCategories(event, id)
            this.handleCloseMenu()
        });
    };

    render() {
        return (
            <div>
                {this.state.content ?
                    (
                        <TopPanel
                            icomoon={this.state.content.picto}
                            colorIcomoon={colors.blue.darker.hue300}
                            title={this.state.content.title}
                            subtitle={this.state.content.subtitle}
                            gradientColor1={colors.menu.regular}
                            gradientColor2={colors.menu.darker}
                            handlerAdd={() => { this.handleToggleDrawer('form') }}
                            textAdd={(this.state.isEmpty || !this.state.channelByCategorie) ? null : this.state.content.txtBtn}
                            searchHandler={(this.state.isEmpty || !this.state.channelByCategorie) ? null : this.handleChange}
                            openForm={this.state.openForm}
                            buttonAvailable={this.state.channelByCategorie !== null ? true : false}
                            windowWidth={this.props.windowWidth}
                            noResult={this.state.isEmpty}
                            hasBorder={this.state.typeOf && this.state.typeOf.length > 0 && !this.state.isEmpty && process.env.REACT_APP_MODE_SPREAD !== "hub" ? false : true}
                        />
                    ) : ''
                }
                <Box p={20}>
                    <AccordionCustom forcedExpanded={true} square={false} detailsStyles={{
                        minHeight: '350px',
                    }} title={
                        <Box>
                            <Grid container alignItems='center'>
                                <Typography variant="body1" color='primary' style={{ display: 'inline', marginRight: '5px' }}>Visualiser par : </Typography>
                                <MenuGrid container aria-controls="fade-menu" aria-haspopup="true" onClick={this.handleClickMenu}>
                                    {this.state.selectedMenuItemLabel === undefined ? this.setDropdownMenuAllTitle() : this.state.selectedMenuItemLabel} {/* // TODO: HERE */}
                                    {
                                        this.state.isMenuOpened ? <ExpandLessSharpIcon /> : <ExpandMoreSharpIcon />
                                    }
                                </MenuGrid>
                            </Grid>
                            <MenuCustom
                                id="fade-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={this.state.isMenuOpened}
                                onClose={this.handleCloseMenu}
                                TransitionComponent={Fade}
                                disableScrollLock={false}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                {/* See all categories button */}
                                <MenuItemCustom data-value={undefined} label={undefined} isSelected={this.state.selectedMenuItemLabel} onClick={(event) => this.handleMenuChange(event)}>Tout voir</MenuItemCustom>
                                {this.state?.typeOf && this.state?.typeOf.map((i, index) => (
                                    <Box key={index}>
                                        {/* All categories */}
                                        {this.state.seeMore && <MenuItemCustom data-value={i.libelle} label={i.libelle} isSelected={this.state.selectedMenuItemLabel} onClick={(event) => this.handleMenuChange(event, i.attributeGroup.id)}>{i.libelle}</MenuItemCustom>}
                                        {/* A few categories */}
                                        {(index <= 2 && !this.state.seeMore) && <MenuItemCustom data-value={i.libelle} label={i.libelle} isSelected={this.state.selectedMenuItemLabel} onClick={(event) => this.handleMenuChange(event, i.attributeGroup.id)} style={{
                                            marginBottom: index === 2 && '8px'
                                        }}>{i.libelle}</MenuItemCustom>}
                                        {/* See more button */}
                                        {index === 4 && !this.state.seeMore && (
                                            <>
                                                <Divider />
                                                <MenuItemCustom data-value={i.libelle} label={i.libelle} isSelected={this.state.selectedMenuItemLabel} onClick={() => { this.setState({ seeMore: true }) }} style={{ fontWeight: 'bold', marginTop: '8px' }}>Voir plus</MenuItemCustom>
                                            </>
                                        )}
                                    </Box>
                                ))}
                            </MenuCustom>
                        </Box>
                    }>
                        <Grid container direction="column" justifyContent="center" spacing={0} style={{ width: ((this.state.openForm && this.state.isEmpty) || (this.state.filtered.length === 0 && this.state.openForm)) ? '100%' : "100%", padding: 16, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                            <Grid container direction="row" spacing={2} style={{
                                position: 'relative',
                            }}>
                                {this.state.isEmpty ?
                                    <EmptyCard title={this.state.content.emptyTitle} subtitle={this.state.content.emptySubtitle} textButton={this.state.content.emptyTxtBtn} onClick={() => { this.handleToggleDrawer('form') }} openForm={this.state.openForm} />
                                    :
                                    <>
                                        {
                                            this.state.filtered.length > 0 ? (
                                                this.state.filtered.map((type, index) => {
                                                    let setupData = type
                                                    setupData.node.preferences = this.state.preferences?.[type.node.id.replace('/api/channels/', '')]
                                                    return (
                                                        setupData.node.isSystem ? '' :
                                                            <GridCustom item lg={4} md={6} xs={12} key={`ListProduct${index}`}>
                                                                <CardCustom paddingbottom={0} paddingtop={0} style={{ width: "100%" }} contentpadding={'0px'}>
                                                                    <CardSetup noImage={true} type={setupData} textButton={'Modifier'} handlerEdit={process.env.REACT_APP_MODE_SPREAD !== "hub" ? () => this.editChannel(type.node.id, type.node.attributeGroup.id) : null} />
                                                                </CardCustom>
                                                            </GridCustom>
                                                    )
                                                }
                                                )
                                            )
                                                :
                                                this.state.channelByCategorie !== null && (this.state.filtered.length === 0 || this.state.filteredCat.length === 0) ?
                                                    (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.state.content.emptyTxtBtn : null} onClick={() => { this.handleToggleDrawer('form') }} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                                                    :
                                                    (
                                                        <Box style={{
                                                            color: colors.grey.regular,
                                                            display:"flex",
                                                            justifyContent:"center",
                                                            alignItems:"center",
                                                            width:"100%"
                                                        }}>
                                                            <PageLoader />
                                                        </Box>
                                                    )
                                        }
                                    </>
                                }
                            </Grid>
                            </Grid>
                            {
                                this.state.dataLayout && this.state.typeOf?.length > 0 ?
                                    (
                                        <LayoutBuilder isSublayout={false} allState={this.setCardDrawerTopPanelLayout()} opened={this.state.openForm} forClose={() => { this.handleToggleDrawer('form') }} handlerSetup={this.handlerSetup} dataLayout={this.state.dataLayout} dataCard={this.state.typeOf} drawerWidth={this.props.drawerWidth} />
                                    ) : ''
                            }
                            {
                                this.state.dataAddLayout ?
                                    (
                                        <LayoutBuilder
                                            isSublayout={false}
                                            opened={this.state.openFormSetupChannel}
                                            image={this.state.imageForm}
                                            forClose={() => { this.handleToggleDrawer('formSetupChannel') }}
                                            dataLayout={this.state.dataAddLayout}
                                            handlerMutation={this.handlerMutation}
                                            drawerWidth={this.props.drawerWidth}
                                            stateCallback={this.handleInputChange}
                                            backStepperButtonAction={[
                                                () => {
                                                    this.setState({ errors: {} });
                                                },
                                                () => {
                                                    this.setState({ errors: {} });
                                                },
                                                () => {
                                                    this.setState({ errors: {} });
                                                },
                                                null
                                            ]}
                                            stepperButtonAction={[
                                                () => {
                                                    if (this.hasErrors()) {
                                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                                        this.setState({ seeErrors: false });
                                                        eventService.fire();
                                                        return false;
                                                    }
                                                    return true;
                                                },
                                                () => {
                                                    if (this.hasErrors()) {
                                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                                        this.setState({ seeErrors: true });
                                                        eventService.fire();
                                                        return false;
                                                    }
                                                    return true;
                                                },
                                                () => {
                                                    if (this.hasErrors()) {
                                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                                        this.setState({ seeErrors: true });
                                                        eventService.fire();
                                                        return false;
                                                    }
                                                    return true;
                                                }
                                            ]}
                                            errorCallback={this.handleFormError}
                                            validateButton={true}
                                            deleteMutation={this.state.editForm === 'edit' ? this.handleToggleDialog : null}
                                            deleteText={this.state.editForm === 'edit' ? 'Supprimer' : null}
                                            allState={this.state}
                                        />
                                    ) : ''
                            }
                    </AccordionCustom>
                </Box>

                <Dialog
                    open={this.state.openDialogRemove}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer ce channel ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si vous supprimez ce channel celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button text="Annuler" onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                        </Button>
                        <Button types="Delete" text="Supprimer" onClick={this.deleteMutation} autoFocus>
                           
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        typeOf: state.typeOf,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListChannels))));