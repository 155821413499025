import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

const EditUser = 
    {
        titleForm: 'Modifier un utilisateur',
        subTitleForm: "Veuillez compléter les champs ci-dessous pour modifier l'utilisateur",
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations générales',
            },
            children:[
                {
                    optionsInputs: [
                        {
                            type: 'text',
                            label: 'Nom',
                            helper: {
                                text: 'Indiquez le nom ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'name',
                        },
                        {
                            type: 'text',
                            label: 'Prénom',
                            helper: {
                                text: 'Indiquez le prénom ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'firstname',
                        },
                        {
                            type: 'text',
                            email: true,
                            label: 'Adresse email',
                            helper: {
                                text: 'Indiquez l\'adresse email',
                                link: false,
                            },
                            required: true,
                            stateName: 'email',
                        },
                        {
                            type: 'radio',
                            direction: 'row',
                            label: 'Modifier le mot de passe',
                            // helper: {
                            //     text: 'Indiquez l\'état',
                            //     link: false,
                            // },
                            required: true,
                            stateName: 'editPassword',
                            value: [
                                {
                                    value: true,
                                    label: 'Oui'
                                },
                                {
                                    value: false,
                                    label: 'Non'
                                },
                            ]
                        },
                        {
                            type: 'radio',
                            direction: 'row',
                            label: 'Générer le mot de passe',
                            helper: {
                                text: 'Génération',
                                link: false,
                            },
                            required: true,
                            stateName: 'generate',
                            value: [
                                {
                                    value: true,
                                    label: 'Par Sinfin DXP'
                                },
                                {
                                    value: false,
                                    label: 'Par moi'
                                },
                            ]
                        },
                        {
                            type: 'hidden',
                            label: 'Nouveau mot de passe',
                            helper: {
                                text: 'Le mot de passe doit contenir au moins 8 caractères, dont un chiffre, une minuscule, une majuscule et un caractère spécial parmis : !@#$%^&*',
                                link: false,
                            },
                            required: true,
                            stateName: 'plainPassword',
                        },
                        {
                            type: 'hidden',
                            confirm: true,
                            label: 'Confirmer le mot de passe',
                            helper: {
                                text: 'Répétez le mot de passe',
                                link: false,
                            },
                            required: true,
                            stateName: 'plainPasswordRepeat',
                        },
                    ]
                },
            ]
        }
    }
export default EditUser;