import { withApollo } from "@apollo/client/react/hoc";
import colors from "@config/theme/colors";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import {
  ALERT_ERROR,
  ALERT_SUCCESS,
} from "@constants/alert-types";
import * as formHelper from "@helpers/form";
import {
  ROUTE_PRODUCTS_BRANDS_LIST,
  ROUTE_PRODUCTS_LIST_DETAIL,
} from "@constants/route-names";
import AccordionCustom from "@layouts/Accordion/AccordionCustom";
import OurDrawer from "@layouts/Drawer";
import Listing from "@layouts/Listing/Listing";
import TopPanel from "@layouts/TopPanel/TopPanel";
import CallMadeIcon from "@mui/icons-material/CallMade";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  CHECK_IF_CAN_EXPORT_PRODUCTS_NOT_EXPORTED_YET,
  DELETE_BRAND,
  DELETE_BRAND_SOURCE_MAPPING,
  DELETE_BRAND_SOURCE_SCRAP,
  getOneBrand,
} from "@queries/brands";
import { DELETE_CATALOG } from "@queries/catalogs";
import { get_productSourcePriceWatchers_by_brand } from "@queries/price_watcher";
import { eventService } from "@services/event.service";
import DialogModal from "@ui/dialog/DialogModal";
import BrandForm from "@ui/form/BrandForm/BrandForm";
import ReturnLink from "@ui/link/ReturnLink";
import PageLoader from "@ui/loadings/page-loader/PageLoader";
import Status from "@ui/status/Status";
import OurTypography from "@ui/typography/Typography";
import request from "@utils/fetch";
import { saveElement, updateElement } from "@utils/functions";
import { CursorPagination } from "@utils/pagination";
import moment from "moment";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styled from "styled-components";
import {
  filtersOptions,
  listMappers,
  listSettings,
  perPageOptions,
  viewOptions,
} from "../config/listProducts.config";

const ContainerImage = styled(Box)`
  overflow: hidden;
  background: url(${(props) => props.backgroundimage});
  background-size: cover;
  background-position: center;
  border: 1px solid ${colors.blue.lighter.hue600};
`;

const GridBorderedRight = styled(Grid)`
  position: relative;

  @media screen and (min-width: 1250px) {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: ${colors.grey.border};
    }
  }
`;

const WrapperAttr = styled(Grid)`
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  > p:first-child {
    min-width: 120px;
  }
  p {
    font-size: 13px;
    text-align: left;
    color: ${colors.black.regular};
  }
`;
const WrapperAlert = styled(Grid)`
  svg {
    font-size: 16px;
    margin-right: 4px;
    fill: ${(props) =>
      props.isactive ? colors.green.regular : colors.grey.lighter.hue600};
  }
  color: ${(props) =>
    props.isactive ? colors.green.regular : colors.grey.lighter.hue600};
`;

const TableRowDataCustom = styled(TableRow)`
  display: inline-block;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey.lighter.hue980};
  }
  td {
    border-top: 1px solid ${colors.grey.lighter.hue700};
    border-color: ${colors.grey.lighter.hue700};
  }
  td:first-child {
    border-left: 1px solid ${colors.grey.lighter.hue700};
  }
  td:last-child {
    border-right: 1px solid ${colors.grey.lighter.hue700};
  }
`;

const TableRowCustom = styled(TableRow)`
  td,
  th {
    border-color: #edeeee;
    white-space: nowrap;
    line-height: 1rem;
    padding: 8px;
  }
  th {
    color: ${colors.grey.border};
    font-weight: bold;
    background: ${colors.white};
  }
`;
const TableBodyCustom = styled(TableBody)`
  .table-row {
    cursor: pointer;
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
    text-decoration: none;
    td,
    th {
      padding: 8px;
    }
  }
`;

const TableCellCustom = styled(TableCell)`
  font-size: 20px;
  font-weight: bold;
`;

const ValueItem = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  margin: 4px 0;
  text-align: center;
  strong {
    display: flex;
    align-items: center;
  }
  svg {
    font-size: 14px;
    margin-left: 4px;
  }
`;

function BrandsDetail(props) {
  const [currentLang, setCurrentLang] = React.useState(
    props.locales[0].node.code
  );
  const [openForm, setOpenForm] = React.useState(false);
  const [openFormImportType, setOpenFormImportType] = React.useState(false);
  const [openFormImport, setOpenFormImport] = React.useState(false);
  const [importType, setImportType] = React.useState("api");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [reloadBrand, setReloadBrand] = React.useState(false);
  const [searchNameLocal, setSearchNameLocal] = React.useState(null);
  const [searchName, setSearchName] = React.useState(null);

  const [brands, setBrands] = React.useState(null);
  const [oneBrand, setOneBrand] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [loadingCanExport, setLoadingCanExport] = React.useState(false);
  const [customState, setCustomState] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [seeErrors, setSeeError] = React.useState(false);
  const [canExportNotExported, setCanExportNotExported] = React.useState(false);

  const [loadingList, setLoadingList] = React.useState(true);
  const [prices, setPrices] = React.useState([]);

  const [localStorageFilters, setLocalStorageFilters] = React.useState(null);

  const [pagination, setPagination] = React.useState({
    perPage: 10,
    page: 0,
    startCursor: null,
    endCursor: null,
    count: 0,
  });
  const [states, setStates] = React.useState({
    brandId: null,
    brandImage: null,
    brandName: null,
    brandDesc: null,
    brandLink: null,
    brandRows: [],
    brandEcartNegative: null,
    brandEcartPositive: null,
    mapOn: [],
    isActive: true,
    isActiveAlert: true,
  });
  const [state, setState] = React.useState({
    currentLang: props.locales[0].node.code,
    openForm: false,
    openFormImport: false,
    openFormExport: false,
    catalogFormData: {},
    exportName: "",
    exportLang: props.locales[0].node.code,
    exportType: "csv",
    importTypeFile: "csv",
    importUrl: "",
    errors: {},
    // dataImportLayout: importProductsApiConfig,
  });

  const [scrollListing, setScrollListing] = React.useState(false);
  const [offsetListing, setOffsetListing] = React.useState(0);
  const [reloadListing, setReloadListing] = React.useState(false);
  const [dataCount, setDataCount] = React.useState(0);
  const [selectedItems, setSelectedItems] = React.useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  function initBrand() {
    setLoadingCanExport(true);
    props.client
      .query({
        query: getOneBrand,
        fetchPolicy: "no-cache",
        variables: { id: `/api/product-sources/${props.match.params.id}` },
      })
      .then((result) => {
        const brand = result?.data?.productSource;
        const rows = brand?.productSourceScraps?.edges.map((s) => {
          const row = {
            new: false,
            changed: false,
            code: s.node.name,
            values: null,
            operator: null,
            attributeType: null,
            id: s.node.id,
            checkbox: false,
            typingChips: null,
            showButton: false,
          };
          return row;
        });

        let mapOn = [];
        for (const m of Object.keys(brand?.productSourceMappings?.edges)) {
          const element = brand?.productSourceMappings?.edges[m].node;
          mapOn[element.attribute.id] = element?.productSourceScrap?.name;
        }

        const newStates = {
          brandId: brand.id,
          brandImage: brand.media,
          brandName: brand.libelle,
          brandDesc: brand.description,
          brandLink: brand.link,
          brandRows: rows,
          brandEcartNegative: null,
          brandEcartPositive: null,
          mapOn: mapOn,
          isActive: brand.status,
          isActiveAlert: brand.activeAlert,
        };
        setStates(newStates);
        setOneBrand(result.data.productSource);
        props.client
          .query({
            query: CHECK_IF_CAN_EXPORT_PRODUCTS_NOT_EXPORTED_YET,
            fetchPolicy: "no-cache",
            variables: { productSources_id: parseInt(props.match.params.id) },
          })
          .then((result2) => {
            setCanExportNotExported(
              result2?.data.productSourceWorkflows?.totalCount
            );
            setLoadingCanExport(false);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoadingCanExport(false);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  const handlerMutation = async () => {
    if (hasErrors()) {
      props.snack(ALERT_ERROR, props.t("spread.active_assets.toastCheckField"));
      return eventService.fire();
    }
    props.startLoading();
    let variables = null;
    variables = {
      id: oneBrand.id,
      libelle: states.brandName,
      description: states.brandDesc,
      media: states.brandImage?.id,
      status: states.isActive,
      isDisplay: true,
      isBrand: true,
      activeAlert: states.isActiveAlert,
      link: states.brandLink,
      brandEcartPositive: states.brandEcartPositive,
      brandEcartNegative: states.brandEcartNegative,
    };

    try {
      let updateBrand = await updateElement(
        states,
        "productSource",
        variables,
        oneBrand,
        { enableLoad: false, setDates: false }
      );
      let updateSources;
      if (updateBrand) {
        Promise.all(
          states.brandRows.map(async (row) => {
            if (row.new) {
              let scrapVariables = {
                name: row.code,
                link: row.code,
                codeScrap: row.code,
                productSource: oneBrand.id,
              };
              try {
                const createSource = await saveElement(
                  "productSourceScrap",
                  scrapVariables,
                  { enableLoad: false, setDates: false }
                );
                Object.keys(states.mapOn).map(async (m) => {
                  if (row.code === states.mapOn[m]) {
                    let mapVariables = {
                      productSource: oneBrand.id,
                      attribute: m,
                      productSourceScrap: createSource.id,
                    };
                    try {
                      await saveElement("productSourceMapping", mapVariables, {
                        enableLoad: false,
                        setDates: false,
                      });
                    } catch (error) {
                      props.snack(
                        ALERT_ERROR,
                        "Une erreur est survenu lors de la création de la marque"
                      );
                      console.log(error);
                    }
                  }
                });
              } catch (error) {
                props.snack(
                  ALERT_ERROR,
                  "Une erreur est survenu lors de la création de la marque"
                );
                console.log(error);
              }
            }
            if (row.changed) {
              let scrapVariables = {
                name: row.code,
                link: row.code,
                codeScrap: row.code,
                productSource: updateBrand.id,
              };
              try {
                updateSources = await updateElement(
                  states,
                  "productSourceScrap",
                  scrapVariables,
                  oneBrand,
                  { enableLoad: false, setDates: false }
                );
                Object.keys(states.mapOn).map(async (m) => {
                  if (row.code === states.mapOn[m]) {
                    let mapVariables = {
                      productSource: updateBrand.id,
                      attribute: m,
                      productSourceScrap: updateSources.id,
                    };
                    try {
                      await updateElement(
                        states,
                        "productSourceMapping",
                        mapVariables,
                        oneBrand,
                        { enableLoad: false, setDates: false }
                      );
                    } catch (error) {
                      props.snack(
                        ALERT_ERROR,
                        "Une erreur est survenu lors de la modification de la marque"
                      );
                      console.log(error);
                    }
                  }
                });
              } catch (error) {
                props.snack(
                  ALERT_ERROR,
                  "Une erreur est survenu lors de la modification de la marque"
                );
                console.log(error);
              }
            }
            // if (states.mapOn.length !== oneBrand.productSourceMappings.edges.length) {
            //     Object.keys(states.mapOn).map(async(m)=>{
            //         if (row.code === states.mapOn[m]) {
            //             let mapVariables = {
            //                 productSource:updateBrand.id,
            //                 attribute:m,
            //                 productSourceScrap:updateSources.id,
            //             }
            //             try {
            //                 await updateElement(states,'productSourceMapping', mapVariables, oneBrand,{enableLoad:false, setDates:false})
            //             } catch (error) {
            //                 props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification de la marque');
            //                 console.log(error)
            //             }
            //         }
            //     })
            // }
            if (row.deleted) {
              oneBrand.productSourceMappings.edges.map(async (m) => {
                if (row.code === m.node.productSourceScrap.name) {
                  const isValueChange = [];
                  for (const e of Object.keys(states.mapOn)) {
                    if (e === m.node.attribute.id) {
                      isValueChange.push({
                        [e]: states.mapOn[e],
                      });
                    }
                  }
                  // await deleteSourceMap(s.node.id)
                  try {
                  } catch (error) {
                    props.snack(
                      ALERT_ERROR,
                      "Une erreur est survenu lors de la modification de la marque"
                    );
                    console.log(error);
                  }
                }
              });
              // await deleteSource(row.id)
            }
          })
        );
        setReloadBrand(true);
        setOpenForm(!openForm);
        props.snack(ALERT_SUCCESS, "Marque modifiée avec succès");
      }
    } catch (err) {
      props.snack(
        ALERT_ERROR,
        "Une erreur est survenu lors de la modification de la marque"
      );
      console.log(err);
    }

    props.stopLoading();
  };

  //--- call 1 fois dans le cycle de vie du composant
  useEffect(() => {
    initBrand();
    let oneBrandVariables = {
      // value: diff_value,
      date: moment().format("YYYY-MM-DD"),
      nbperpage: pagination?.perPage,
      //---- permets de chercher les produits par source :
      productSourcePriceWatchers_source: `/api/product-sources/${props.match.params.id}`,
      //--- permets de chercher uniquement les produits ayant des écarts :
      productSourcePriceWatchers_hasDiff: true,
    };
    getPricesOneBrand(
      get_productSourcePriceWatchers_by_brand,
      oneBrandVariables,
      false
    );
  }, [reloadListing]);

  const realoadBrand = async () => {
    setLoading(true);
    await initBrand();
  };

  const goTo = (route) => {
    props.history.push({
      pathname: route,
    });
  };

  const handleToggleDrawer = () => {
    setOpenForm(!openForm);
  };

  const initForm = () => {
    handleToggleDrawer();
  };

  const getBrandsCallback = () => {
    setReloadBrand(false);
  };
  const filterBrands = () => {
    setSearchName(searchNameLocal);
  };

  const deleteSourceMap = (id) => {
    return new Promise((resolve, reject) => {
      props.client
        .query({
          query: DELETE_BRAND_SOURCE_MAPPING,
          fetchPolicy: "no-cache",
          variables: {
            id: id,
          },
        })
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const deleteSource = (id) => {
    return new Promise((resolve, reject) => {
      props.client
        .query({
          query: DELETE_BRAND_SOURCE_SCRAP,
          fetchPolicy: "no-cache",
          variables: {
            id: id,
          },
        })
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const deleteBrand = () => {
    if (oneBrand) {
      props.startLoading();
      try {
        Promise.all(
          oneBrand?.productSourceMappings?.edges.map(async (s) => {
            await deleteSourceMap(s.node.id);
          })
        )
          .then(() => {
            Promise.all(
              oneBrand?.productSourceScraps?.edges.map(async (sourceScrap) => {
                await deleteSource(sourceScrap.node.id);
              })
            )
              .then(() => {
                props.client
                  .query({
                    query: DELETE_BRAND,
                    fetchPolicy: "no-cache",
                    variables: {
                      id: `/api/product-sources/${props.match.params.id}`,
                    },
                  })
                  .then(() => {
                    props.history.push({
                      pathname: ROUTE_PRODUCTS_BRANDS_LIST,
                    });
                    props.stopLoading();
                    props.snack(ALERT_SUCCESS, `Marque supprimée avec succès`);
                  })
                  .catch((e) => {
                    props.stopLoading();
                    props.snack(
                      ALERT_ERROR,
                      `Un problème est survenu lors de la suppression!`
                    );
                    console.log(e);
                  });
              })
              .catch((e) => {
                props.stopLoading();
                props.snack(
                  ALERT_ERROR,
                  `Un problème est survenu lors de la suppression!`
                );
                console.log(e);
              });
          })
          .catch((e) => {
            props.stopLoading();
            props.snack(
              ALERT_ERROR,
              `Un problème est survenu lors de la suppression!`
            );
            console.log(e);
          });
      } catch (error) {
        props.stopLoading();
        props.snack(
          ALERT_ERROR,
          `Un problème est survenu lors de la suppression!`
        );
        console.log(error);
      }
    }
  };

  const handleLang = (event) => {
    setCurrentLang(event.target.value);
    eventService.fire();
  };

  const handleExportProducts = () => {
    try {
      props.startLoading();
      let data = new FormData();
      data.append("name", "export-produits-marques-validated");
      data.append("format", "csv");
      data.append("locale", "fr_FR");
      data.append("status", "all");
      data.append("image", "all");
      data.append("onlyNew", false);
      data.append("productSourceWorkflowCompleted", true);
      data.append("productSourceWorkflowExported", true);
      data.append("productSourceIds[]", props.match.params.id);

      let urlencoded = new URLSearchParams(data).toString();

      request(
        `${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`,
        "get",
        null,
        "application/x-www-form-urlencoded"
      )
        .then(async (data) => {
          if (data.success) {
            window.open(
              `${process.env.REACT_APP_API_ROOT}/medias/export/${
                data.mediaObject.filePath + ".csv"
              }`,
              "_blank"
            );
            props.stopLoading();
            props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
          } else {
            props.stopLoading();
            props.snack(
              ALERT_ERROR,
              `Un problème est survenu lors de l'exportation !`
            );
          }
        })
        .catch((e) => {
          props.stopLoading();
          props.snack(
            ALERT_ERROR,
            `Un problème est survenu lors de l'exportation !`
          );
        });
    } catch (e) {
      props.snack(ALERT_ERROR, `L'exportation a échoué !`);
      props.stopLoading();
    }
  };
  const handleFormImport = (type, title) => {
    let separator = {
      type: "select",
      isContainerized: true,
      label: props.t("products.categories.importation.separator"),
      translated: false,
      helper: {
        text: props.t("products.categories.importation.separatorsub"),
        link: false,
      },
      required: false,
      stateName: "importSep",
      value: [
        { value: ",", label: "," },
        { value: ";", label: ";" },
        { value: "|", label: "|" },
        { value: "xml", label: "SimpleXML ( temp )" },
      ],
    };
    let setupUploadFile;
    let setupUploadFileExcel;
    let setupUploadFileZip;
    let setupMapping;

    setupUploadFile = {
      type: "uploadFile",
      label: props.t("products.list.importation.csv"),
      translated: false,
      required: false,
      stateName: `upload_CSV`,
      downloadType: "product",
    };

    setupUploadFileZip = {
      type: "uploadFile",
      label: props.t("products.list.importation.zip"),
      translated: false,
      required: false,
      typeUpload: title === "zip" ? "only-zip" : "zip",
      stateName: `upload_ZIP`,
      multipleStates: title === "zip" ? true : false,
    };

    setupUploadFileExcel = {
      type: "uploadFile",
      label: props.t("products.list.importation.excel"),
      translated: false,
      required: false,
      typeUpload: "xls",
      stateName: `upload_CSV`,
      downloadType: "product",
    };

    setupMapping = {
      type: "mapper",
      label: "",
      translated: false,
      required: false,
      mapOn: `upload_CSV`,
      stateName: `mapper`,
      staticOptions: [
        {
          id: "sku",
          label: "Code article",
        },
        {
          id: "price",
          label: "Price",
        },
        {
          id: "stock",
          label: "Stock",
        },
      ],
    };

    let configFormImport = state.dataImportLayout;
    configFormImport.formConfig.children[0].optionsInputs = [];
    if (configFormImport.formConfig.children[1]) {
      configFormImport.formConfig.children[1].optionsInputs = [];
    }

    if (title === "excel") {
      configFormImport.formConfig.children[0].optionsInputs.push(
        setupUploadFileExcel
      );
    } else if (title === "zip") {
      configFormImport.formConfig.children[0].optionsInputs.push(
        setupUploadFileZip
      );
    } else {
      configFormImport.formConfig.children[0].optionsInputs.push(separator);
      configFormImport.formConfig.children[0].optionsInputs.push(
        setupUploadFile
      );
      configFormImport.formConfig.children[0].optionsInputs.push(
        setupUploadFileZip
      );
    }
    if (configFormImport.formConfig.children[1]) {
      configFormImport.formConfig.children[1].optionsInputs.push(setupMapping);
    }

    setState({
      dataImportLayout: configFormImport,
      catalogFormData: {
        type,
        title,
      },
      mapper: [],
      headers: title === "api" ? "" : null,
      upload_CSV: null,
      upload_ZIP: null,
      importSep: title === "zip" ? ";" : ",",
      importValues: {},
      importLang: props.locales[0].node.id,
      importType: title,
      importUrl: title === "api" ? "" : null,
    });
    setOpenFormImport(!openFormImport);
  };

  const handleExportProductsNotExported = () => {
    try {
      props.startLoading();
      let data = new FormData();
      data.append("name", "export-produits-marques-notExported");
      data.append("format", "csv");
      data.append("locale", "fr_FR");
      data.append("status", "all");
      data.append("image", "all");
      data.append("onlyNew", false);
      data.append("productSourceWorkflowCompleted", true);
      data.append("productSourceWorkflowExported", false);
      data.append("productSourceIds[]", props.match.params.id);

      let urlencoded = new URLSearchParams(data).toString();

      request(
        `${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`,
        "get",
        null,
        "application/x-www-form-urlencoded"
      )
        .then(async (data) => {
          if (data.success) {
            window.open(
              `${process.env.REACT_APP_API_ROOT}/medias/export/${
                data.mediaObject.filePath + ".csv"
              }`,
              "_blank"
            );
            props.stopLoading();
            props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
          } else {
            props.stopLoading();
            props.snack(
              ALERT_ERROR,
              `Un problème est survenu lors de l'exportation !`
            );
          }
        })
        .catch((e) => {
          props.stopLoading();
          props.snack(
            ALERT_ERROR,
            `Un problème est survenu lors de l'exportation !`
          );
        });
    } catch (e) {
      props.snack(ALERT_ERROR, `L'exportation a échoué !`);
      props.stopLoading();
    }
  };

  const resetPagination = () => {
    return {
      perPage: 10,
      page: 0,
      startCursor: null,
      endCursor: null,
      count: 0,
    };
  };

  const getPricesOneBrand = (
    query = get_productSourcePriceWatchers_by_brand,
    variables = { date: moment().format("YYYY-MM-DD") },
    pageInfo
  ) => {
    setLoadingList(true);
    props.client
      .query({
        query,
        fetchPolicy: "no-cache",
        variables,
      })
      .then((result) => {
        let getPrices = result.data.products.edges;

        let page = pageInfo || pagination;

        let newPagination = {
          ...page,
          ...result.data.products.pageInfo,
          count: result.data.products.totalCount,
        };

        if (!pageInfo) pageInfo = resetPagination();

        setPagination(newPagination);
        setPrices(getPrices);
        setLoadingList(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingList(false);
      });
  };

  const getListingValues = (array, type) => {
    return array.map((value2, index2) => {
      switch (type) {
        case "date":
          return (
            <ValueItem key={index2}>
              <strong>
                {moment(value2.node.created_at).format("DD/MM/YYYY")}
              </strong>
            </ValueItem>
          );
        case "retailer":
          return (
            <Grid
              key={index2}
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ maxWidth: 250 }}
            >
              <ValueItem>{value2.node.source.libelle}</ValueItem>
              <ValueItem>
                <strong>{value2.node.product_price.toFixed(2)}EUR</strong>
              </ValueItem>
            </Grid>
          );
        case "diff":
          return (
            <ValueItem key={index2} style={{ justifyContent: "flex-end" }}>
              <strong>{value2.node.source_price.toFixed(2)}EUR &nbsp;</strong>(
              {value2.node.diffPercent <= 0 ? "" : "+"}
              {value2.node.diffPercent.toFixed(2)}%)
              {value2.node.diff_flat === 0 ? (
                <RemoveIcon style={{ fill: "#C9C9C9" }} />
              ) : value2.node.diff_flat < 0 ? (
                <CallMadeIcon
                  style={{
                    fill: colors.green.regular,
                    transform: "rotate(-45deg)",
                  }}
                />
              ) : (
                <CallMadeIcon
                  style={{
                    fill: colors.red.regular,
                    transform: "rotate(135deg)",
                  }}
                />
              )}
            </ValueItem>
          );

        default:
          break;
      }
    });
  };

  const searchPrices = (pageInfo) => {
    let page = pageInfo;

    if (!page) page = resetPagination();

    let variables = {
      productSourcePriceWatchers_source: `/api/product-sources/${props.match.params.id}`,
      productSourcePriceWatchers_hasDiff: true,
      date: moment().format("YYYY-MM-DD"),
      nbperpage: page?.perPage,
      cursor: page?.startCursor,
      cursorLast: page?.endCursor,
    };
    getPricesOneBrand(get_productSourcePriceWatchers_by_brand, variables, page);
  };

  const changePage = (newPage) => {
    let newPagination = {
      ...pagination,
      page: newPage,
      startCursor: newPage > pagination.page ? pagination.endCursor : null,
      endCursor: newPage > pagination.page ? null : pagination.startCursor,
    };

    setPagination(newPagination);
    searchPrices(newPagination);
  };

  const changePerPage = (perPage) => {
    let newPagination = {
      ...resetPagination(),
      perPage,
    };

    setPagination(newPagination);
    searchPrices(newPagination);
  };

  const handleMediaPicker = (selected, stateName) => {
    // let getState = {...states};
    // getState[stateName] =  selected
    // setBrands(getState)
    setStates({ ...states, brandImage: selected });
  };

  const handleFormError = (stateName, error) => {
    let getErrors = errors;
    getErrors[stateName] = error;
    setErrors(getErrors);
  };
  const hasErrors = () => {
    if (errors) {
      for (let error in errors) {
        if (errors[error]) return true;
      }
    }
    return false;
  };

  const handleNextStep = () => {
    let result = formHelper.handleStep("next", errors);
    setSeeError(!result);
    return result;
  };

  const stateCallback = (stateName, value, custom, translated, callback) => {
    let getState = { ...states };
    getState[stateName] = value?.target?.value ?? value;
    setStates(getState, callback);
  };

  const stateCallbackImport = (
    stateName,
    value,
    custom,
    translated,
    callback
  ) => {
    let getState = { ...state };
    getState[stateName] = value?.target?.value ?? value;
    setState(getState, callback);
  };

  const handleButtonGroupChange = (stateName, value) => {
    let getState = { ...states };
    getState[stateName] = value;
    setStates(getState);
  };

  const handlerMutationImport = async () => {
    props.startLoading();
    let importConfig = {
      url: `${
        state.importType === "excel"
          ? `${process.env.REACT_APP_API_ROOT}/medias/${state.upload_CSV?.medias?.mediaObject.filePath}`
          : state.upload_CSV?.medias?.contentUrl ?? null
      }`,
      mapper: state.mapper,
      eavType: props.attributes.eavTypes.find((e) => e.node.code === "product")
        .node.id,
      locale: state.importLang,
      delimiter: state.importSep,
      media: state.upload_ZIP
        ? `/api/media-objects/${state.upload_ZIP.medias.id}`
        : null,
      fileType: state.importSep === "xml" ? "simplexml" : null,
      user: props.user.id,
      productSource: states.brandId,
      scrapStatus: "99",
    };

    try {
      await request(
        `${process.env.REACT_APP_API_ROOT}/api/file-imports`,
        "post",
        importConfig,
        undefined,
        true
      );
      props.snack(
        ALERT_SUCCESS,
        `Votre fichier a été importé avec succès, son intégration dans Sinfin DXP sera exécutée lors du passage du CRON (Tâche automatique).`,
        6000
      );
      setOpenFormImport(!openFormImport);
      setOpenFormImportType(!openFormImportType);
      props.stopLoading();
    } catch (e) {
      props.snack(ALERT_ERROR, `L'import a échoué !`);
      props.stopLoading();
    }
  };

  const errorMapper = () => {
    let string = "";
    let i = 0;
    for (let value of state.requiredValueMapper) {
      if (!value.checked) {
        if (i > 0) {
          string += ", ";
        } else {
          string += " ";
        }
        string += `"${value.label}"`;
        i++;
      }
    }
    if (i === 1) {
      return `Le champ ${string} est requis`;
    } else {
      return `Les champs${string} sont requis`;
    }
  };

  const editMenuOptions = [
    {
      label: "Modifier",
      color: colors.blue.darker.hue300,
      isDisabled: false,
      icon: <EditSharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
      action: (e) => {
        e.stopPropagation();
        // props.actionButtonSecond();
        setOpenForm(true);
        // handleClose(e);
      },
    },
    {
      label: "Supprimer",
      color: colors.red.regular,
      icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
      action: (e) => {
        e.stopPropagation();
        setOpenDialog(true);
        // handleClose(e);
      },
    },
  ];

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + 0.5 >= offsetListing) {
      setScrollListing(true);
    } else {
      setScrollListing(false);
    }
  };

  const goToProductDetail = (id) => {
    props.history.push({
      pathname: ROUTE_PRODUCTS_LIST_DETAIL.replace(
        ":id",
        id.replace("/api/products/", "")
      ),
      state: { productId: id },
    });
  };

  const handleDelete = async (catalog) => {
    props.startLoading();
    props.client
      .mutate({
        mutation: DELETE_CATALOG,
        variables: { id: catalog },
        fetchPolicy: "no-cache",
      })
      .then(async (result) => {
        props.snack(ALERT_SUCCESS, "Catalogue supprimé avec succès");
        props.stopLoading();
        setReloadListing(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <TopPanel
        colorIcomoon={colors.blue.darker.hue300}
        title={props.t("products.brands.detail_brand")}
        subtitle={props.t("products.brands.detail_subtitle")}
        gradientColor1={colors.menu.regular}
        gradientColor2={colors.menu.darker}
        windowWidth={props.windowWidth}
        currentLang={currentLang}
        locales={props.locales}
        hasBorder={true}
        textDelete="Supprimer la marque"
        handlerDelete={deleteBrand}
        actions={{
          edit: {
            handler: () => setOpenForm(true),
            text: "Modifier",
          },
        }}
        buttonAvailable={oneBrand ? true : false}
      />
      <Grid container direction="column" style={{ paddingTop: "unset" }}>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: 14,
            marginTop: 14,
          }}
        >
          <ReturnLink
              goTo={() => {
                  const referrer = document.referrer;
                  const currentPath = props.match.path;
                  const referrerHostname = referrer ? new URL(referrer).hostname : "";
                  
                  if (referrerHostname !== window.location.hostname) {
                      window.location.replace(currentPath.replace('detail/:id', ''));
                  } else if (referrer) {
                      props.history.goBack();
                  } else {
                      window.location.replace(currentPath.replace('detail/:id', ''));
                  }
              }}
              type={"button"}
          />
        </Grid>
        <AccordionCustom
          forcedExpanded={true}
          title={"Information liée"}
          custompadding={"22px 55px !important"}
          style={{ borderRadius: 0 }}
        >
          {!loading ? (
            oneBrand ? (
              <Grid container>
                <GridBorderedRight
                  item
                  xs={12}
                  md={6}
                  style={{ paddingRight: 100 }}
                >
                  <Typography
                    variant="h2"
                    container="h3"
                    style={{
                      fontWeight: "bold",
                      fontSize: "25px",
                      marginBottom: "6px",
                    }}
                  >
                    {oneBrand.libelle}
                  </Typography>
                  <Box mb={5}>
                    <OurTypography
                      colortext={colors.grey.regular}
                      style={{
                        fontSize: 13,
                        // 2 line clamping
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        lineHeight: "1.4em",
                      }}
                    >
                      {oneBrand.description ||
                        props.t("spread_builder.asset_view.notDescription")}
                    </OurTypography>
                  </Box>
                </GridBorderedRight>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    padding:
                      props.windowWidth < 1250 ? "0px" : "25px 0px 25px 100px",
                  }}
                >
                  <Grid container columnSpacing={2}>
                    <Grid item xs={8}>
                      <WrapperAttr container>
                        <Typography>Statut :</Typography>
                        <Status
                          type="isActive"
                          value={oneBrand.status}
                          size={12}
                        />
                      </WrapperAttr>
                      <WrapperAttr container>
                        <Typography>Synchronisation :</Typography>
                        <Status
                          type="isSynchro"
                          value={oneBrand.status}
                          size={12}
                        />
                      </WrapperAttr>
                      <WrapperAttr container>
                        <Typography>Accès direct :</Typography>
                        <a href={oneBrand?.link} target={"__blank"}>
                          <Typography variant="body1">
                            <strong>{oneBrand?.link}</strong>
                          </Typography>
                        </a>
                      </WrapperAttr>
                    </Grid>
                    <Grid item xs={4}>
                      <WrapperAttr container>
                        <Typography>Produits complets :</Typography>
                        <Typography variant="body1">
                          <strong>
                            {oneBrand?.product?.totalCount -
                              oneBrand?.productSourceProductStates?.totalCount}
                          </strong>
                        </Typography>
                      </WrapperAttr>
                      <WrapperAttr container>
                        <Typography>Produit à compléter :</Typography>
                        <Typography variant="body1">
                          <strong>
                            {oneBrand?.productSourceProductStates?.totalCount}
                          </strong>
                        </Typography>
                      </WrapperAttr>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Typography>Pas de datas</Typography>
            )
          ) : (
            <PageLoader />
          )}
        </AccordionCustom>
        <Grid container style={{ marginTop: 16 }}>
          <AccordionCustom
            scrollable={false}
            title={
              <Typography variant={"h4"} style={{ fontSize: 14 }}>
                Liste des produits en erreur
              </Typography>
            }
          >
            {loadingList ? (
              <PageLoader />
            ) : prices.length > 0 ? (
              <Grid container>
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "calc(144px * 5)", boxShadow: "none" }}
                >
                  <Table aria-label="headerList" stickyHeader>
                    <TableHead>
                      <TableRowCustom>
                        <TableCell>Listes des produits</TableCell>
                        <TableCell align="left">Mon prix</TableCell>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center" style={{ width: 250 }}>
                          Information sites référents
                        </TableCell>
                        <TableCell align="right">
                          Ecart de mon prix par <br />
                          rapport au référent
                        </TableCell>
                        {/* <TableCell align="right">Écart(%)</TableCell> */}
                      </TableRowCustom>
                    </TableHead>
                    <TableBodyCustom>
                      {prices?.map((value, index) => {
                        return (
                          <TableRowDataCustom key={index} className="table-row">
                            <TableCell style={{ fontSize: 16 }}>
                              <Box width={320}>
                                <Grid
                                  container
                                  alignItems="center"
                                  direction="row"
                                  wrap="nowrap"
                                >
                                  <Grid item style={{ background: "#F7F7F7" }}>
                                    <img
                                      src={`${process.env.REACT_APP_MEDIAS}/${
                                        value.node.productDatas?.edges.find(
                                          (e) =>
                                            e.node.attribute.identifier ===
                                            "product_image"
                                        )?.node?.media?.filePath
                                      }`}
                                      style={{
                                        objectFit: "cover",
                                        objectPosition: "center",
                                        width: 125,
                                        height: 125,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item style={{ marginLeft: 32 }}>
                                    <Typography
                                      variant="h2"
                                      style={{
                                        lineHeight: "17px",
                                        maxWidth: 218,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <strong>
                                        {
                                          value?.node.productDatas?.edges.find(
                                            (e) =>
                                              e?.node.attribute.identifier ===
                                              "product_name"
                                          )?.node.value
                                        }
                                      </strong>
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      style={{ fontSize: 16 }}
                                    >
                                      REF. {value?.node.sku}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </TableCell>
                            <TableCellCustom align="left">
                              {
                                value?.node.productDatas?.edges.find(
                                  (e) =>
                                    e?.node.attribute.identifier ===
                                    "product_price"
                                )?.node.value
                              }
                              &euro;
                            </TableCellCustom>
                            <TableCellCustom align="center">
                              {getListingValues(
                                value.node?.productSourcePriceWatchers.edges,
                                "date"
                              )}
                            </TableCellCustom>
                            <TableCellCustom align="center">
                              {getListingValues(
                                value.node?.productSourcePriceWatchers.edges,
                                "retailer"
                              )}
                            </TableCellCustom>
                            {/* <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'source_price')}</TableCellCustom> */}
                            <TableCellCustom align="right">
                              {getListingValues(
                                value.node?.productSourcePriceWatchers.edges,
                                "diff"
                              )}
                            </TableCellCustom>
                            {/* <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'diffPercent')}</TableCellCustom> */}
                          </TableRowDataCustom>
                        );
                      })}
                    </TableBodyCustom>
                  </Table>
                </TableContainer>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <CursorPagination
                      rowLabel={`Résultats par page`}
                      pagination={pagination}
                      type="table"
                      changePageCallback={changePage}
                      changePerPageCallback={changePerPage}
                      showPerPage={true}
                      perPageOptions={["10", "30", "100"]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Typography style={{ color: colors.grey.lighter.hue600 }}>
                Vous n’avez pas de produits en erreur
              </Typography>
            )}
          </AccordionCustom>
        </Grid>
        <Grid container style={{ marginTop: 16 }}>
          <Listing
            scroll={scrollListing}
            label="products"
            settings={listSettings}
            perPageOptions={perPageOptions}
            mappers={listMappers}
            typeResult={"itemsPerPage"}
            pagination={true}
            enablePagination={true}
            enableChangeView={true}
            enableChangeSort={true}
            enableSelectionItems={true}
            enableFilters={true}
            identifier="researchProducts"
            viewsOptions={viewOptions}
            tableProps={{
              textButtonSecondary: "red",
              redirectDetail: goToProductDetail,
            }}
            queryVariables={{
              localeId: "/api/locales/1",
              productSources: [props.match.params.id],
            }}
            locales={props.locales}
            setDataCount={setDataCount}
            reload={reloadListing}
            setReload={setReloadListing}
            filtersOptions={filtersOptions}
            itemSelection={{
              set: setSelectedItems,
              items: selectedItems,
            }}
            cardProps={{
              actionButtonDelete: handleDelete,
              redirectDetail: goToProductDetail,
              showPercent: true,
              percent: "completude",
              image: "image",
              title: "libelle",
              imagesNb: "imagesNb",
              imagesMaxNb: "imagesMaxNb",
              sku: "sku",
              attributes: [
                {
                  label: "Statut",
                  value: "status",
                  type: "status",
                },
                {
                  label: "Variantes",
                  value: "childrenNb",
                },
                {
                  label: "Catalogues",
                  value: "catalogsNb",
                },
                {
                  label: "Catégories",
                  value: "categoriesNb",
                },
              ],
            }}
          />
        </Grid>
      </Grid>
      <DialogModal
        open={openDialog}
        title={`Êtes-vous sûr de vouloir supprimer cette marque ?`}
        secondaryAction={() => setOpenDialog(false)}
        primaryAction={() => {
          deleteBrand();
          setOpenDialog(false);
        }}
        windowWidth={props.windowWidth}
      >
        Si vous supprimez cette marque celle-ci ne sera plus accessible. Si vous
        ne l'utilisez plus mais que vous ne souhaitez pas la supprimer, annulez
        la suppression.
      </DialogModal>

      <OurDrawer
        open={openForm}
        setIsOpen={() => setOpenForm(!openForm)}
        title={props.t("products.brands.detail_brand")}
        drawerWidth={props.drawerWidth}
      >
        <BrandForm
          brand={oneBrand}
          onSuccess={() => {
            setOpenForm(false);
            setReloadListing(true);
          }}
        />
      </OurDrawer>

      {/* <LayoutBuilder
                        isSublayout={false}
                        opened={openFormImportType}
                        forClose={() => setOpenFormImportType(!openFormImportType)}
                        dataLayout={importProductsTypesConfig}
                        drawerWidth={props.drawerWidth}
                        dataCard={[
                            {
                                'libelle': props.t('products.categories.importation.file'),
                                'bicoloreText': props.t('products.list.cardproduct.file'),
                                'colorhover': '#0273A5',
                                'picto': csvPicto,
                                'disabled': false,
                                'textButton': props.t('products.categories.import'),
                                'description': props.t('products.categories.importation.fileText'),
                                'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                                'onClick': () => handleFormImport(null, 'fichier')
                            },
                            {
                                'libelle': props.t('products.categories.importation.zip'),
                                'bicoloreText': 'ZIP',
                                'colorhover': '#0273A5',
                                'picto': zipPicto,
                                'disabled': false,
                                'textButton': props.t('products.categories.import'),
                                'description': props.t('products.categories.importation.zipText'),
                                'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                                'onClick': () => handleFormImport(null, 'zip')
                            }
                        ]}
                    /> */}

      {/* <LayoutBuilder
                        isSublayout={true}
                        opened={openFormImport}
                        icomoon={'ico-import-fichier'}
                        forClose={() => setOpenFormImport(!openFormImport)}
                        dataLayout={importType === 'api' ? importProductsApiConfig : state.dataImportLayout}
                        drawerWidth={props.drawerWidth}
                        // handleCancel={handleCancel}
                        handlerMutation={handlerMutationImport}
                        allState={state}
                        stateCallback={stateCallbackImport}
                        stepperButtonDisabled={[() => (
                            importType === 'api' ? state.importUrl === '' : state.upload_CSV === null)
                            , null
                        ]} 
                        stepperButtonAction={[
                            null,
                            () => {
                                let error = 0;
                                let requiredValueMapper;
                                if (state.requiredValueMapper) {
                                    let i = 0;
                                    requiredValueMapper = state.requiredValueMapper;
                                    for (let item of state.requiredValueMapper) {
                                        for (let value of state.mapper) {
                                            if (value && value.length > 0) {
                                                for (let val of value) {
                                                    if (val === item.id) {
                                                        error++;
                                                        requiredValueMapper[i].checked = true;
                                                    }
                                                }
                                            }
                                        }
                                        i++;
                                    }
                                }
                                if (error === state.requiredValueMapper.length) {
                                    return true;
                                } else {
                                    setState({...state,requiredValueMapper})
                                    props.snack(ALERT_ERROR, errorMapper());
                                    return false;
                                }
                            }
                        ]}
                        backStepperButtonAction={[null, null, null]}
                    /> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    locales: state.locales,
    attributes: state.attributes,
    user: state.user,
    attributeGroups: state.attributeGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withApollo(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandsDetail))
  )
);
