import React from "react";

const StepPanel = ({ value, index, ...props }) => {
  return (
    <div
      role="steppanel"
      hidden={value !== index}
      id={`vertical-steppanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...props}
    >
      {value === index && <div>{props.children}</div>}
    </div>
  );
};

export default StepPanel;
