import { withTranslation } from "react-i18next";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../../../js/constants/action-types";
import { withRouter } from "react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import AccordionCustom from "../../../../../layouts/Accordion/AccordionCustom";
import OurTypography from "../../../../../ui/typography/Typography";
import CardProduct from "../../../../../layouts/Card/cardContent/CardProduct";
import CarouselCards from "../../../../../layouts/Carousel/CarouselCards";

const ProductDetailAssociatedProducts = props =>{
    const products = props.product.suggestions.edges

    const params = {
        slidesNb:2.5,
        direction: 'horizontal',
        spaceBetween: 5,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
    }

    return (
        products.length > 0 &&
        <div id={props?.id}>
            <AccordionCustom
                noborder 
                topborder 
                title={<OurTypography fontweighttext={"bold"} style={{paddingLeft: "15px"}}>Produits associés</OurTypography>} 
                custompadding={'15px 30px !important'}
            >
                    <CarouselCards {...params}>
                        {
                            products.map((e,i)=>{
                                return (
                                    <CardProduct 
                                        key={i} 
                                        border
                                        data={e.node.flatProducts.edges[0].node} 
                                        redirectDetail={()=>console.log("GOT TO PRODUCT DETAIL")}
                                        showPercent={true}
                                        percent={'completude'}
                                        image={'image'}
                                        title={"name"}
                                        imagesNb={"imagesNb"}
                                        imagesMaxNb={"imagesMaxNb"}
                                        sku={"sku"}
                                        attributes={[
                                            {
                                                label: "Statut",
                                                value:"status",
                                                type:'status'
                                            },
                                            {
                                                label: "Variantes",
                                                value:"childrenNb",
                                            },
                                            {
                                                label: "Catalogues",
                                                value:"catalogsNb",
                                            },
                                            {
                                                label: "Catégories",
                                                value:"categoriesNb",
                                            }
                                        ]}
                                    />
                                )
                            })
                        }
                    </CarouselCards>
                {/* </div> */}
            </AccordionCustom>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ProductDetailAssociatedProducts))));