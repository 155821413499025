import colors from "../../../../config/theme/colors"

// icons
import EditSharpIcon from '@mui/icons-material/EditSharp';
import SyncIcon from '@mui/icons-material/Sync';

// * Add here your custom configs

export const booleanTypes = {
    isActive: {
        active: {
            text: 'Actif',
            color: colors.green.regular,
        },
        inactive: {
            text: 'Inactif',
            color: colors.grey.regular,
        },
    },
    isSynchro: {
        active: {
            text: 'Automatique',
            color: colors.green.regular,
            icon: <SyncIcon style={{color: colors.green.regular, marginLeft: '-3px', marginRight: '-4px'}} />
        },
        inactive: {
            text: 'Inactif',
            color: colors.grey.regular,
            icon: <SyncIcon style={{color: colors.grey.regular, marginLeft: '-3px', marginRight: '-4px'}} />
        },
    },
}

export const statusTypes = {
    orders: {
        not: {
            text: 'En cours',
            color: colors.red.regular,
            icon: 'ico_produit'
        },
        waiting: {
            text: 'Waiting',
            color: colors.green.regular,
            icon: 'check'
        },
        sent: {
            text: 'Sent',
            color: colors.green.regular,
            icon: <EditSharpIcon />
            // icon: 'ico_produit'
        },
    }
}