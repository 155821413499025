import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid } from '@mui/material';
import { toggleExpandedForAll } from 'react-sortable-tree';
import { withApollo } from '@apollo/client/react/hoc';

import {
    GET_ATTR_GROUPE,
    GET_ATTRIBUTE_TYPES,
    UPDATE_ATTRIBUTE_STATUS,
    UPDATE_ATTRIBUTE_POSITION,
    ADD_ATTRIBUTE_GROUPE,
    DELETE_ATTRIBUTE_GROUPE,
    UPDATE_ATTRIBUTE,
    ADD_ATTRIBUTE,
    ADD_ATTRIBUTE_OPTION,
    DELETE_ATTRIBUTE_OPTION,
    GET_ATTRIBUTES_BY_TYPE,
    GET_ATTRIBUTE,
    ADD_ATTRIBUTE_DICTIONNARY,
    DELETE_ATTRIBUTE_DICTIONNARY,
    UPDATE_ATTRIBUTE_DICTIONNARY,
    ADD_ATTRIBUTE_OPTION_DICTIONNARY,
    UPDATE_ATTRIBUTE_OPTION_DICTIONNARY,
    DELETE_ATTRIBUTE_OPTION_DICTIONNARY,
    GET_EAV_TYPES,
    UPDATE_ATTRIBUTE_GROUPE
} from '../../../../queries/attributes';
import {
    ADD_TRANSLATION,
    ADD_TRANSLATION_DATA,
    UPDATE_TRANSLATION_DATA,
    DELETE_TRANSLATION_DATA
} from '../../../../queries/translations';

import formGroupAttributAdd from './config/formGroupAttributAdd.config';
import formGroupAttributEdit from './config/formGroupAttributEdit.config';
import formAttributEdit from './config/formAttributEdit.config';
import formAttributAdd from './config/formAttributAdd.config';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TreeView from '../../../ui/tree-view/TreeView';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import Button from '../../../ui/button/Button';
import { eventService } from '../../../../js/services/event.service';

import colors from '../../../../config/theme/colors';
import { SNACK, START_LOADING, STOP_LOADING, SET_ATTRIBUTES, SET_ATTRIBUTE_GROUPS } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';

import { withTranslation } from 'react-i18next';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { getParams } from '../../../../js/utils/getParams';
import { setRedux } from '../../../../js/utils/functions';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import Listing from '../../../layouts/Listing/Listing';
import { filtersOptions, listMappers, listSettings, perPageOptions, viewOptions } from './config/listing.config';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditIcon from '@mui/icons-material/Edit';
import { goTo } from '../../../../js/utils/goTo';
import { ROUTE_CONTENT_ATTRIBUT, ROUTE_PRODUCTS_ATTRIBUT } from '../../../../js/constants/route-names';

class AttributesProductsNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openForm: false,
            openDialog: false,
            editForm: "",
            eavGrpType: '',
            attr_id: '',
            grp_id: '',
            parent: '',
            libelle: '',
            isRequired: true,
            isSearchable: true,
            attrGrpIsSystem: false,
            attributeType: '',
            attributUnity: 'none',
            categoriesData: [],
            listGroupAttr: [],
            treeData: null,
            inputAttributes: [],
            currentLang: this.props.locales[0].node.code,
            groupIdentifier: null,
            errors: {},
            status: true,
            internalFilter: true,
            useInCard: false,
            scrollListing: false,
            reloadListing: false,
            offsetListing: 0,
        };
        this.errors = {}
        this.typingTimer = null;
        this.typeTesting = 'attribute';
    }
    
    componentDidMount() {
        this.initData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.typeAttributs !== prevProps.typeAttributs) {
            this.setState({
                openForm: false,
            })
            this.initData();
        }
        if (this.state.treeData !== prevState.treeData) {
            this.setState({
                filteredData: this.state.treeData,
            })
        }
        if (this.props.attributeGroups !== prevProps.attributeGroups) {
            this.prepareLocalListing();
        }
    }

    // [BEGIN] MUTATIONS
    // -- GROUPS
    saveAddGroup = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let identifier = `${this.state.groupIdentifier}`;

                const ADD_TRANSLATION_RESULT = await this.props.client.mutate({
                    mutation: ADD_TRANSLATION,
                    // variables: { 'translationKey': `spread.attributeGroup.${identifier}` }
                    variables: { 'translationKey': `${identifier}` }
                });

                for (let locale of this.props.locales) {
                    if (this.state[locale.node.code]?.['groupName']) {
                        await this.props.client.mutate({
                            mutation: ADD_TRANSLATION_DATA,
                            variables: {
                                'value': this.state[locale.node.code]['groupName'],
                                'locale': locale.node.id,
                                'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
                            }
                        });
                    }
                }

                let RESULT_ADD_ATTRIBUTE_GROUPE = await this.props.client.mutate({
                    mutation: ADD_ATTRIBUTE_GROUPE,
                    variables: {
                        'identifier': identifier,
                        'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id,
                        'isSystem': false,
                        'isForContent': this.props.typeAttributs === 'content'
                    }
                });
                let getAllGroups = [...this.props.attributeGroups];
                let newGroup = {
                    node: RESULT_ADD_ATTRIBUTE_GROUPE.data.createAttributeGroup.attributeGroup
                }
                getAllGroups.push(newGroup)

                this.props.setAttributeGroups(getAllGroups);
                resolve();
            } catch (e) {
                this.handleError(e);
                reject();
            }
        });
    };

    saveEditGroup = () => {
        return new Promise(async (resolve, reject) => {
            try {
                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code]?.['groupName'];
                    let currentTranslation = this.state.currentGroup.locales.find(e => e.id === locale.node.id);
                    if (formValue) {
                        if (currentTranslation) {
                            // UPDATE STEP
                            await this.props.client.mutate({
                                mutation: UPDATE_TRANSLATION_DATA,
                                variables: {
                                    'id': currentTranslation.translationDataId,
                                    'value': formValue
                                }
                            });
                        } else {
                            // CREATE STEP
                            await this.props.client.mutate({
                                mutation: ADD_TRANSLATION_DATA,
                                variables: {
                                    'value': formValue,
                                    'locale': locale.node.id,
                                    'translation': this.state.currentGroup.node.translation.id
                                }
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP
                        await this.props.client.mutate({
                            mutation: DELETE_TRANSLATION_DATA,
                            variables: { 'id': currentTranslation.translationDataId }
                        });
                    }
                }

                const updatedGroup = await this.props.client.mutate({
                    mutation: UPDATE_ATTRIBUTE_GROUPE,
                    variables: {
                        id: this.state.currentGroup.node.id,
                        // It has to be a translation, not a translationData
                        // translation: translationData.data[translateAction].translationData.id,
                        translation: this.state.currentGroup.node.translation.id,
                    }
                });

                // this.props.setAttributeGroups(updatedGroup)

                resolve(updatedGroup);
            } catch (e) {
                this.handleError(e);
                reject();
            }
        });
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.error"));

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }

        this.setState({
            openDialog: false
        });
    };

    handleSuccess = async (item, type) => {
        await this.updateLocalDataInRedux(item, type);
        await this.prepareLocalListing();

        this.props.snack(ALERT_SUCCESS, this.state.editForm === 'editGroup'
            ? 'Groupe modifié'
            : 'Groupe ajouté'
        );

        this.handleToggleDrawer();
        this.resetState();
        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors: errors ?? null });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handlerMutation = () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
            return eventService.fire();
        }

        this.props.startLoading();

        switch (this.state.editForm) {
            case 'addGroup':
                this.saveAddGroup().then(() => this.handleSuccess(null, 'create')).catch((err) => { console.log(err) });
                break;
            case 'editGroup':
                this.saveEditGroup().then((item) => this.handleSuccess({
                    node: item.data.updateAttributeGroup.attributeGroup
                }, 'update')).catch((err) => { console.log(err) });
                break;
            default: return false;
        }
    };

    deleteMutation = (groupId) => {
        const clickedGroup = this.props.attributeGroups.find(e => e.node.id === groupId);

        this.props.startLoading();

        this.props.client.mutate({
            mutation: DELETE_ATTRIBUTE_GROUPE,
            variables: { id: clickedGroup.node.id }
        }).then(async () => {
            this.removeGroupLocally(clickedGroup.node.id);
            await this.updateLocalDataInRedux(clickedGroup, 'delete');

            this.handleToggleDialog();
            this.props.stopLoading();
            this.props.snack(
                ALERT_SUCCESS,
                this.props.t("attributes.products.groupDeleted")
            );
            this.resetState();
        }).catch(e => {
            this.handleError(e)
        });
    };
    // [END] MUTATIONS

    // Remove from treeData
    removeGroupLocally = (id) => {
        let tree = this.state.treeData;

        tree = tree.filter(e => e.node.id !== id);

        for (let data of tree)
            data.children = data.children.filter(e => e.node.id !== id);

        this.setState({
            treeData: this.copyArrayOfObjects(tree) ?? null,
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            for (let attrGroup of this.state.treeData) {
                this.populateGroup(attrGroup, true);

                for (let attr of attrGroup.children)
                    this.populateGroup(attr, false, attrGroup, true);
            }

            for (let unassignedAttribute of this.state.unassignedAttributes)
                this.populateGroup(unassignedAttribute, true, null, true);

            this.forceUpdate();
        });
    };

    handleToggleDrawer = () => {
        this.setState({
            openForm: !this.state.openForm ?? false
        });
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog ?? false
        });
    };

    resetState() {
        this.setState({
            attr_id: '',
            grp_id: '',
            libelle: '',
            parent: '',
            attributeType: '',
            isSystem: false,
            isRequired: true,
            isSearchable: true,
            attrGrpIsSystem: false,
            attributeIdentifier: null,
            groupIdentifier: null,
            errors: {},
            useInCard: false,
            readyForm: false
        });
    }

    addGroupAttribut = () => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {},
            });
        }

        this.setState({
            readyForm: true,
            openForm: !this.state.openForm ?? false,
            editForm: 'addGroup',
        });
    };

    editGroupAttribut = (nodeInfo) => {
        this.resetState();

        for (let locale of this.props.locales) {
            let values = {};

            for (let groupLocale of nodeInfo.locales) {
                if (groupLocale.code === locale.node.code) {
                    values['groupName'] = groupLocale.value;
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }

        this.setState({
            readyForm: true,
            openForm: true,
            editForm: 'editGroup',
            currentGroup: nodeInfo,
            groupIdentifier: nodeInfo.node.identifier.indexOf('_') > -1
                ? nodeInfo.node.identifier.split('_')[1]
                : nodeInfo.node.identifier
        });
    };

    doneTyping = (stateName) => {
        if (stateName === 'groupName') {
            this.setState({
                groupIdentifier: slugify(this.state[this.state.currentLang].groupName, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }) ?? null,
            })
        }

        if (this.state.groupIdentifier) {
            this.typeTesting = 'attribute_group';
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.groupIdentifier}`, 'get')
                .then((data) => {
                    if (data.success) {
                        eventService.fire({ stateName: 'groupIdentifier', errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        if (stateName === 'attributeName' || stateName === 'attributeIdentifier' || stateName === 'groupName' || stateName === 'groupIdentifier') {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
        }
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values
            });
        } else {
            this.setState({
                [stateName]: value
            });
        }

        // Check that it does not exist
        if ((this.state.editForm === "addAttribut" || this.state.editForm === "addGroup") && (stateName === 'attributeName' || stateName === 'attributeIdentifier' || stateName === 'groupName' || stateName === 'groupIdentifier'))
            this.checkIdentifier(stateName);
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        if (stateName === "attributeType") {
            if (this.state.choiceType.node.input === "select") {
                this.handleFormError("dictionnaryValues", false)
            } else {
                this.handleFormError("dictionnaryValues", true)
            }
        }
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    async initData() {
        checkRouting(this.props)
        // Set top panel content 
        await this.handleInitData(this.props.typeAttributs)
        this.prepareLocalListing();
    }

    handleInitData = (typeAttributs) => {
        let content;
        let showTab = false;
        return new Promise(async (resolve, reject) => {
            switch (typeAttributs) {
                case 'product':
                    content = {
                        title: this.props.t("catalogs.catalogs.manageAttributes"),
                        subtitle: this.props.t("attributes.products.manageAttributesInfo"),
                        picto: "picto-attribut",
                        textAdd: this.props.t("attributes.products.addAttributeGroup"),
                    };
                    break;
                case 'content':
                    content = {
                        title: this.props.t("catalogs.catalogs.manageAttributes"),
                        subtitle: this.props.t("attributes.products.manageAttributesInfo"),
                        picto: "picto-attribut",
                        textAdd: this.props.t("attributes.products.addAttributeGroup"),
                    };
                    break;
                case 'category':
                    content = {
                        title: this.props.t("attributes.products.manageCatAttributes"),
                        subtitle: this.props.t("attributes.products.manageAttributesInfo"),
                        picto: "picto-categorie",
                        textAdd: null,
                        textImport: this.props.t("attributes.products.addAttribute"),
                    };
                    break;
                case 'point':
                    content = {
                        title: this.props.t("attributes.products.manageStoreAttributes"),
                        subtitle: this.props.t("attributes.products.manageAttributesInfo"),
                        picto: "picto-retailer",
                        textAdd: null,
                        textImport: this.props.t("attributes.products.addAttribute"),
                    };
                    break;
                default:
                    content = {
                        title: this.props.t("attributes.products.manageAttributesDynamic"),
                        subtitle: this.props.t("attributes.products.manageAttributesInfo"),
                        picto: "picto-attribut",
                        textAdd: null,
                        textImport: this.props.t("attributes.products.addAttribute"),
                    };
            }
            this.setState({
                showTab: showTab ?? null,
                content: content ?? null,
            })
            resolve()
        })
    };

    listingConfig = (page) => {
        switch (page) {
            // Products attributes
            case 'product':
                return {
                    listSettings: listSettings,
                    filtersOptions: filtersOptions,
                    viewOptions: viewOptions,
                    perPageOptions: perPageOptions,
                    listMappers: listMappers
                }
            // Content models
            case 'content':
                return {
                    listSettings: listSettings,
                    filtersOptions: filtersOptions,
                    viewOptions: viewOptions,
                    perPageOptions: perPageOptions,
                    listMappers: listMappers
                }
        }
    }

    isSystemAttribute = (id) => JSON.parse(localStorage.getItem('ATTRIBUTE_GROUPS'))?.find(i => (i.node.id === id) && (i.node.isSystem))

    setDataCount = (dataCount) => {
        this.setState({ dataCount })
    }

    setReloadListing = (reloadListing) => {
        this.setState({ reloadListing })
    }

    handleScroll = (event) => {
        if (event.currentTarget.scrollTop + 0.5 >= this.state.offsetListing) {
            this.setState({ scrollListing: true })
        } else {
            this.setState({ scrollListing: false })
        }
    };

    filterLocalDataCallback = ({ evt, isEmptySearch }) => {
        // Filter data
        if (isEmptySearch) {
            this.setState({ filteredData: this.state.treeData });
        } else {
            let filter = evt.target.value;
            let filteredData = this.state.treeData?.filter((value) => {
                return value.title.toLowerCase().includes(filter.toLowerCase());
            }
            );
            this.setState({ filteredData });
        }
    }

    updateLocalDataInRedux = async (item, type) => {
        let typeToFetch = this.props.typeAttributs;
        let attributes = this.props.attributes;
        let data = null;
        for (let type of attributes.eavTypes) {
            if (type.node.code === typeToFetch) {
                let typeData = await this.props.client.query({ query: GET_ATTRIBUTES_BY_TYPE, variables: { id: type.node.id }, fetchPolicy: 'no-cache' });
                data = typeData.data.eavType;
            }
        }
        attributes[this.props.typeAttributs] = data
        let getAllGroups = this.props.attributeGroups;
        if (this.state.selectedGroups && this.state.selectedGroups.length > 0) {
            for (let group of this.state.selectedGroups) {
                let getGroup = getAllGroups.find(e => e.node.id === group.node.id);
                if (getGroup) {
                    getGroup.node.attributes = attributes[this.props.typeAttributs].attributes
                }
            }
        }

        let finalGroups = null

        // Redux
        switch (type) {
            case 'create':
                this.props.setAttributeGroups(getAllGroups);
                break
            case 'update':
                const copyOfGetAllGroupsToUpdate = [...getAllGroups]
                const groupIdToUpdate = copyOfGetAllGroupsToUpdate.findIndex(e => e.node.id === item.node.id);
                copyOfGetAllGroupsToUpdate[groupIdToUpdate] = item
                finalGroups = copyOfGetAllGroupsToUpdate
                this.props.setAttributeGroups(finalGroups);
                break
            case 'delete':
                const copyOfGetAllGroupsToDelete = [...getAllGroups]
                const groupIdToDelete = copyOfGetAllGroupsToDelete.findIndex(e => e.node.id === item.node.id);
                copyOfGetAllGroupsToDelete.splice(groupIdToDelete, 1)
                finalGroups = copyOfGetAllGroupsToDelete
                this.props.setAttributeGroups(finalGroups);
                break
            default:
                break
        }

        // Local storage
        try {
            localStorage.setItem('ATTRIBUTE_GROUPS', JSON.stringify(finalGroups ?? getAllGroups));
        } catch (error) {
            console.log("Not saved on localstorage because exceeded localstorage limit ")
        }
    }

    //* It is here where you can filter the attributs groups to display (it takes Redux as base, which uses the local storage internally)
    getAttributesGroupsFromRedux = (attributeGroup, typeAttributs) => {
        const choices = {
            'product': (() => {
                // if ((!attributeGroup.node.isSystem && !attributeGroup.node.isForContent) || attributeGroup.node.identifier === 'default') {
                if (!attributeGroup.node.isSystem && !attributeGroup.node.isForContent) {
                    attributeGroup.children = this.copyArrayOfObjects(this.props.attributes[this.props.typeAttributs].attributes.edges.filter(e => {
                        return e.node.attributeGroup.edges.find(e => e.node.id === attributeGroup.node.id);
                    }));

                    this.populateGroup(attributeGroup, true);

                    for (let attr of attributeGroup.children)
                        this.populateGroup(attr, false, attributeGroup, true);

                    return attributeGroup;
                }
            })(),
            'content': (() => {
                // if ((attributeGroup.node.isSystem && attributeGroup.node.isForContent) || attributeGroup.node.identifier === 'default') {
                // if (attributeGroup.node.isSystem && attributeGroup.node.isForContent) {
                if (attributeGroup.node.isForContent) {
                    attributeGroup.children = this.copyArrayOfObjects(this.props.attributes[this.props.typeAttributs].attributes.edges.filter(e => {
                        return e.node.attributeGroup.edges.find(e => e.node.id === attributeGroup.node.id);
                    }));

                    this.populateGroup(attributeGroup, true);

                    for (let attr of attributeGroup.children)
                        this.populateGroup(attr, false, attributeGroup, true);

                    return attributeGroup;
                }
            })(),
        }

        return choices[typeAttributs];
    }

    prepareLocalListing = () => {
        return new Promise(async (resolve, reject) => {
            this.setState({ loadingTree: true })
            let groups = [];


            if (!this.props.noGroup) {
                let attrGroupResult = this.props.attributeGroups;
                for (let attrGroup of attrGroupResult) {
                    const attributeGroup = this.getAttributesGroupsFromRedux(attrGroup, this.props.typeAttributs);
                    if (attributeGroup) {
                        groups.push(attributeGroup);
                    }
                }
            }

            this.setState({
                treeData: toggleExpandedForAll({
                    treeData: this.copyArrayOfObjects(groups),
                    expanded: false,
                }) ?? null,
            });

            if (getParams(this.props).includes('formOpen') || this.props.history.location?.state?.formOpen) {
                this.addAttribut()
            }
            this.setState({ loadingTree: false })
            resolve();
        });
    }

    getIdentifier = identifier => identifier.indexOf('_') > -1
        ? identifier.split('_')[1]
        : identifier;

    populateGroup(data, root, parent, isAttribute) {
        let getTraduction = data.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === this.state.currentLang
        );

        if (root && data.node.identifier === 'default')
            data.noAction = true;

        data.locales = [];

        for (let { node } of data.node.translation.translationDatas.edges) {
            data.locales.push({
                value: node.value,
                id: node.locale.id,
                code: node.locale.code,
                translationDataId: node.id
            });
        }

        data.title = getTraduction?.node?.value ?? data?.node?.translation.translationDatas.edges[0]?.node.value ?? this.getIdentifier(data.node.identifier);
    }

    copyArrayOfObjects = array => array.map(a => ({ ...a })); // be careful, only breaks references at objects level

    goTo = route => this.props.history.push(route);

    render() {
        const topPanelConfig = {
            product: {
                title: 'Groupes d\'attributs',
                subtitle: 'Gestion de vos groupes d\'attributs (création / modification / suppression)',
                textAdd: '+ Ajouter un groupe',
                handlerAdd: this.addGroupAttribut,
            },
            content: {
                title: 'Groupes de modèles',
                subtitle: 'Gestion de vos groupes de modèles (création / modification / suppression)',
                textAdd: '+ Ajouter un groupe',
                handlerAdd: this.addGroupAttribut,
            }
        }

        return (
            <div style={{ overflow: "auto" }} onScroll={this.handleScroll}>
                {this.state.content && (
                    <TopPanel
                        icomoon={this.state.content.picto}
                        colorIcomoon={colors.blue.darker.hue300}
                        title={topPanelConfig[this.props.typeAttributs].title}
                        subtitle={topPanelConfig[this.props.typeAttributs].subtitle}
                        handlerAdd={topPanelConfig[this.props.typeAttributs].handlerAdd}
                        textAdd={topPanelConfig[this.props.typeAttributs].textAdd}
                        searchHandler={false}
                        openForm={this.state.openForm}
                        buttonAvailable={this.state.treeData ? true : false}
                        windowWidth={this.props.windowWidth}
                        hasBorder={true}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        locales={this.props.locales}
                    />
                )
                }
                <Listing
                    // General
                    padding={16}
                    scroll={this.state.scrollListing}
                    pagination={true}
                    enablePagination={true}
                    enableChangeView={false}
                    enableChangeSort={false}
                    enableSelectionItems={false}
                    enableFilters={true}
                    viewsOptions={this.listingConfig(this.props.typeAttributs).viewOptions}
                    settings={this.listingConfig(this.props.typeAttributs).listSettings}
                    perPageOptions={this.listingConfig(this.props.typeAttributs).perPageOptions}
                    cardProps={{
                        button: {
                            text: 'Voir le groupe',
                            type: 'info',
                            importance: 'primary',
                            action: (obj) => {
                                if(this.props.typeAttributs === 'content'){
                                    goTo(
                                        this.props.history,
                                        ROUTE_CONTENT_ATTRIBUT.replace(':id', obj.node.id.replace('/api/completeness-groups/', '')),
                                        obj.node.id.replace('/api/completeness-groups/', ''))
                                } else {
                                    goTo(
                                        this.props.history,
                                        ROUTE_PRODUCTS_ATTRIBUT.replace(':id', obj.node.id.replace('/api/completeness-groups/', '')),
                                        obj.node.id.replace('/api/completeness-groups/', ''))
                                }
                            }
                        },
                        dottedMenu: {
                            toggleCallback: (obj) => this.setState({
                                selectedGroup: obj,
                            }),
                            options: [
                                {
                                    label: 'Modifier',
                                    color: colors.blue.darker.hue300,
                                    icon: <EditIcon />,
                                    action: () => this.editGroupAttribut(this.state.selectedGroup),
                                },
                                {
                                    label: 'Supprimer',
                                    color: colors.red.regular,
                                    icon: <DeleteSharpIcon />,
                                    isDisabled: this.isSystemAttribute(this.state.selectedGroup?.node.id),
                                    modal: true,
                                    modalProps: {
                                        type: "delete",
                                        title: 'Êtes-vous sûr de vouloir supprimer ce groupe de complétude ?',
                                        subtitle: "Cette action est irréversible",
                                        actions: {
                                            primaryAction: this.deleteMutation
                                        }
                                    },
                                }
                            ]
                        }
                    }}

                    // Local data
                    localData={this.state.filteredData?.map((e) => ({
                        obj: e,
                        id: e.node.id,
                        title: e.title,
                        summary: [
                            {
                                label: 'Nb d\'attributs',
                                value: e.node.attributes.edges.length,
                            }
                        ]
                    }))}
                    filterLocalDataCallback={this.filterLocalDataCallback}

                    // Dynamic data
                    label='attributeGroups'
                    identifier='attributeGroups'
                    mappers={this.listingConfig(this.props.typeAttributs).listMappers}
                    setDataCount={this.setDataCount}
                    reload={this.reloadListing}
                    setReload={this.setReloadListing}
                    filtersOptions={this.listingConfig(this.props.typeAttributs).filtersOptions}
                    listingCallback={(data) => this.setState({
                        attributesGroups: data
                    })}
                />

                {this.state.openForm ?
                    <LayoutBuilder
                        isSublayout={false}
                        readyForm={this.state.readyForm}
                        opened={this.state.openForm}
                        forClose={this.handleToggleDrawer}
                        handlerMutation={() => (this.state.status ? this.handlerMutation() : this.deleteMutation(false))}
                        dataLayout={this.state.readyForm
                            ? this.state.editForm === 'addGroup'
                                ? formGroupAttributAdd(this.props.typeAttributs)
                                : formGroupAttributEdit(this.props.typeAttributs)
                            : null
                        }
                        allState={this.state}
                        icomoon={(this.state.editForm === 'editGroup' || this.state.editForm === 'editAttribut') ? 'ico-modifier-attribut' : 'ico-ajouter-attribut'}
                        stateCallback={this.handleInputChange}
                        errorCallback={this.handleFormError}
                        checkError={() => { }}
                        deleteButton={this.state.editForm}
                        validateButton={true}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                    />
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        attributeTypes: state.attributeTypes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        setAttributes: (attributes) => dispatch({ type: SET_ATTRIBUTES, payload: { attributes } }),
        setAttributeGroups: (attributeGroups) => dispatch({ type: SET_ATTRIBUTE_GROUPS, payload: { attributeGroups } }),
    }
}

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(AttributesProductsNew))));