import PropTypes from "prop-types";
import React, {useState} from "react";
import Link from 'next/link';
import TitleTypoPNG from './image/typo_home_title.svg';
import RibbonTopLeft from './image/Masque-ruban-01.png';
import RibbonTopRight from './image/Masque-ruban-02.png';
import SantaPNG from './image/pere-noel-light.png';
import SantaMobilePNG from './image/santa_title_mobile.png';
import SantaWEBP from './image/pere-noel-light.png';
import SantaMobileWEBP from './image/santa_title_mobile.png';
import SapinPNG from './image/image-sapin.png';
import CadeauPNG from './image/image-cadeau.png';
import SetLegoPNG from './image/image-set-lego.png';
import RulesBlock from '../RulesBlock/RulesBlock';
import H1 from "../../../../shared/components/CustomH1";
import Pg from '../../../../shared/components/CustomParagraph';

const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const TopHomepageBook = (props) => {
    let {
        preview = false,
        id = null, 
        data,
        noSticky,
        inputCallback = null,
        spread = false
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const clickRules = () => {
        setIsOpen(true)
        if (document.getElementById('flipBook')){
            document.getElementById('flipBook').style.zIndex = "2";
        }
        if (document.getElementsByClassName('postCardWrapper')){
            document.getElementsByClassName('postCardWrapper')[0].style.zIndex = "3";
        }
    }
    let imageLeft = data?.blocks?.imageLeft?.inputs.value.value;
    let imageLeftMobile = data?.blocks?.imageLeftMobile?.inputs.value.value;
    let imageTitre = data?.blocks?.imageTitre?.inputs.value.value;

    return (
        <>
            {
                isOpen ? 
                    <RulesBlock isOpen={isOpen} onClose={() => setIsOpen(false)} lang={props.lang}/>
                : null
            }
            <div className="topHomepage">
                { !preview && props.children}
                <div className="ribbon_top_left"><img src={RibbonTopLeft} alt="Ruban en haut à gauche"/></div>
                <div className="ribbon_top_right"><img src={RibbonTopRight} alt="Ruban en haut à droite"/></div>
                <div className="title">
                    <div className="santa_lego_desktop">
                        <picture>
                            {/* <source srcSet={SantaWEBP} type="image/webp" />
                            <source srcSet={SantaPNG} type="image/png" /> */}
                            <img src={imageLeft?.filePath ? `${process.env.REACT_APP_MEDIAS}/${imageLeft.filePath}` : SantaPNG} alt="Père noël"/>
                        </picture>
                    </div>
                    <div className="santa_lego_mobile">
                        <picture>
                            {/* <source srcSet={SantaMobileWEBP} type="image/webp" />
                            <source srcSet={SantaMobilePNG} type="image/png" /> */}
                            <img src={imageLeftMobile?.filePath ? `${process.env.REACT_APP_MEDIAS}/${imageLeftMobile.filePath}` : SantaMobilePNG} alt="Père noël"/>
                        </picture>
                    </div>
                    <picture className="title_image">
                        {/* <source srcSet={TitleTypoPNG} type="image/webp" />
                        <source srcSet={TitleTypoPNG} type="image/png" /> */}
                        <img src={imageTitre?.filePath ? `${process.env.REACT_APP_MEDIAS}/${imageTitre.filePath}` : TitleTypoPNG} alt="Une rencontre avec le Père Noël en Laponie à gagner !"/>
                    </picture>
                </div>
                <div className="ribbon">
                    <H1 data={data} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true} />
                </div>
                <div className="wrapper_button">
                    <LinkCustom spread={spread} href="/catalog">
                        <div className="container_button">
                            <a className="button_lego">{data.blocks.button?.inputs.value.value}</a>
                        </div>
                    </LinkCustom>
                </div>
                <div className="rules">
                    <a className="link_reglement" onClick={() => setIsOpen(true)}>{data.blocks.rulesText?.inputs.value.value}</a>
                </div>
                <div className="bottom_section">
                    <div className="firstPart">
                        <img src={SapinPNG} alt="Sapin lego" />
                        <img src={CadeauPNG} alt="Cadeaux lego" />
                    </div>
                    <div className="text">
                        <Pg data={data} preview={preview} component={id} block={'bottomText'} inputCallback={inputCallback} nopadding={true} />
                    </div>
                    <div className="lastPart">
                        <img src={SetLegoPNG} alt="Set Lego" />
                    </div>
                </div>
            </div>
        </>
    );
};

TopHomepageBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default TopHomepageBook;
