import { goTo } from "../../../../../js/utils/goTo";
import AccordionCustom from "../../../../layouts/Accordion/AccordionCustom";
import OurLock from "../../../OurLock/OurLock";
import { HelperText, GridCustom, LinkText } from "../../styles/inputStyled";
import { DropdownTreeSelectCustom } from "./styles/styled";

const FirstSelectTree = ({ allProps: props, seeError, errorMessage, setSeeError, checkError, lock }) => {

    return (
        <GridCustom item xs={12} position={'relative'}>
            <DropdownTreeSelectCustom
                id={props.input.stateName}
                data={props.input.data}
                mode="radioSelect"
                inlineSearchInput={true}
                className={seeError && errorMessage ? 'error singleSelectTree' : 'singleSelectTree'}
                disabled={props.input.disabled ? true : false}
                texts={{ placeholder: props.input.data.length > 0 ? 'Rechercher...' : 'Chargement...', noMatches: 'Aucun résultat' }}
                onChange={(currentNode, selectedNodes) => {
                    setSeeError(true);
                    checkError(currentNode);
                    props.stateCallback(currentNode.value);
                }}
            />
            {
                !seeError ? props.input.helper.link
                    ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.t(props.input.helper.text)}</LinkText>
                    : <HelperText variant="body2">{props.t(props.input.helper.text)}</HelperText>
                    : <span className="error-label">{errorMessage}</span>
            }
            <OurLock lock={lock} isDisabled={props.input.disabled} style={{
                position: 'absolute',
                right: '20px',
                bottom: '40%',
            }} />
        </GridCustom>
    )
}

const SecondSelectTree = ({ allProps: props, seeError, errorMessage, setSeeError, checkError, lock }) => {

    return (
        <GridCustom item xs={12}>
            <DropdownTreeSelectCustom
                className={seeError && errorMessage ? 'error multiSelectTree' : props.input.bottom ? 'multiSelectTreeBottom' : 'multiSelectTree'}
                id={props.input.stateName}
                data={props.input.data}
                mode="hierarchical"
                keepOpenOnSelect={true}
                inlineSearchInput={true}
                disabled={props.input.disabled ? true : false}
                texts={{
                    placeholder: props.input.data.length > 0 ? 'Rechercher...' : 'Chargement...',
                    noMatches: 'Aucun résultat',
                    inlineSearchPlaceholder: props.input.searchPlaceholder ?? 'Rechercher...'
                }}
                onChange={(currentNode, selectedNodes) => {
                    setSeeError(true);
                    checkError(selectedNodes);
                    props.stateCallback(selectedNodes);
                }}
            />
            {
                !seeError ? props.input.helper.link
                    ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText>
                    : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                    : <span className="error-label">{errorMessage}</span>
            }
            <OurLock lock={lock} isDisabled={props.input.disabled} style={{
                position: 'absolute',
                right: '20px',
                bottom: '32%',
            }} />
            {/* { 
                            props.input.showSelected
                                ? props.value 
                                    ? props.value.map((attr,index) =>{
                                        let attrSelected = ''
                                        let firstAttr = false
                                        index > 0 
                                            ? attrSelected = '- ' + attr.label : attrSelected = attr.label; firstAttr = true
                                        return(
                                            <TypographyCustom key={`AttributsSelected${index}`} variant={'body2'} style={{display: 'inline-block'}}>{firstAttr ? <span>{attrSelected}&nbsp;</span> : attrSelected}</TypographyCustom>
                                        )
                                    }) : null
                                : null
                        } */}
        </GridCustom>
    )
}


export { FirstSelectTree, SecondSelectTree }