import React from "react";
import { withRouter } from "react-router";
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from "react-redux";
import { Box, Grid } from '@mui/material';
import * as moment from 'moment';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';

import { ROUTE_CATALOGS_DETAIL } from '../../../../js/constants/route-names';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { GET_CATALOGS_PAGINATION, ADD_CATALOG, GET_CATALOGS, DELETE_CATALOG } from '../../../../queries/catalogs';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardCatalog from '../../../layouts/Card/cardContent/CardCatalog';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import EmptyCatalogue from "../../../../assets/pictos/empty-picto/empty_catalogs.png";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import LayoutBuilder from "../../../ui/form/LayoutFormBuilder";
import catalogAdd from "./config/catalogAdd.config";

import * as Pagination from "../../../../js/utils/pagination";

import { CREATE_CATALOG_FILTER } from "../../../../queries/catalog_filter";
import { withTranslation } from "react-i18next";
import {
    getItemsLocalStorage,
    setItemsLocalStorage,
} from "../../../../js/helpers/filters";
import catalogFormDuplicate from './config/formCatalogDuplicate.config';
import { checkRouting } from '../../../../js/utils/checkRouting';
import axios from '../../../../js/utils/axios';
import { getParams } from "../../../../js/utils/getParams";

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;
const ContainerCatalogs = styled(Box)`
    width: 100%;
    height: calc(100% - 64px);
    display: grid;
    /* grid-template-rows: auto auto 1fr; */
    grid-template-rows: auto 1fr auto;

    .layout-wrapper{
        display: none;
    }
`;

class Catalogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEmpty: false,
            openForm: false,
            openFormAdd: false,
            openFormDuplicate: false,
            catalogFormData: {},
            allCatalogs: [],
            imageCatalog: null,
            catalogId: "",
            catalogName: "",
            catalogIdentifier: "",
            catalogDescription: "",
            catalogIsActive: true,
            catalogAutosync: true,
            catalogFilters: [],
            catalogSelected: [],
            allCatalogsTypes: [],
            typeOfCatalogs: [],
            drawerWidthModified: this.props.drawerWidth,
            libelle: "",
            currentLang: this.props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            ready: false,
            catalogStartAt: "2020-08-01",
            catalogEndAt: "2020-08-05",
            noResult: false,
            pagination: {
                page: 0,
                perPage: 6,
                count: 0,
            },
            skulist: [],
            searchValue: "",
            arrayCatFilter: [],
            typeTesting: {
                type: "catalog",
                testingState: ["catalogName", "catalogIdentifier"],
                testingTypingState: "catalogName",
                identifierState: "catalogIdentifier",
            },
        };
        this.typingTimer = null;
        this.typingSearchTimer = null;
    }

    doneTyping = (stateName) => {
        let typeTesting = this.state.typeTesting;
        if (stateName === typeTesting.testingTypingState) {
            this.setState({
                [typeTesting.identifierState]: slugify(this.state[typeTesting.testingTypingState], { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            });
        }

        if (this.state[typeTesting.identifierState]) {
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${this.state[typeTesting.identifierState]}`, 'get')
            .then((data) => {
                if (data.success) {
                    eventService.fire({ stateName: typeTesting.identifierState, errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                }
            }).catch((err)=>{
                console.log(err)
            });
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            });
        }
        if (this.state.typeTesting.testingState.includes(stateName))
            this.checkIdentifier(stateName);
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();
        });
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({ [stateName]: value });
    };

    handleSearch = () => {
        this.props.startLoading();
        this.handleGetCatalogs();
    };

    handleChange(e, value) {
        if (e && e?.target?.value) {
            clearTimeout(this.typingSearchTimer);
            this.setState({ searchValue: e.target.value });
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        }
        else if (value) {
            this.handleSearch();
        }
        else {
            this.setState({
                searchValue: ''
            }, () => {
                if (this.state.activeCategorie.length <= 1) {
                    this.handleGetCatalogs();
                }
                else {
                    this.handleChangeTab(null, null, this.state.idActiveCat);
                }
            });
        }
    }

    handleChangeTab = async (event, newValue, id, libelle, category) => {
        this.props.startLoading();
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        this.setState({ pagination })
        if (id) {
            this.setState({
                idActiveCat: id,
                arrayCatFilter: [id],
            }, () => {
                this.handleGetCatalogs();
            });
        } else {
            this.setState({
                arrayCatFilter: [],
                activeCategorie: ['Tout voir'],
            }, () => {
                this.handleChange(null, true);
            });
        }
    };

    resetStateCatalog = () => {
        this.setState({
            imageCatalog: null,
            catalogName: "",
            catalogIdentifier: "",
            catalogDescription: "",
            catalogStartAt: "",
            catalogEndAt: "",
            catalogIsActive: true,
            catalogAutosync: true,
            catalogSelected: [],
            secondTreeData: [],
            errors: {},
            seeErrors: false,
        });
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        if (custom && stateName !== 'catalogSelected') {
            this.setState({ ...custom });
        } else {
            const value = evt?.target?.value ?? evt;
            this.setValue(stateName, value, translated);
        }
    };

    handleMediaPicker = (selected, stateName) => {
        if (stateName === 'imageCatalog') {
            this.handleInputChange(stateName, selected, null, false);
        }
        else {
            this.handleInputChange(stateName, selected, null, true);
        }
    }

    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer],
        });

        if (changeWidth) {
            this.handleDrawerWidthChange(300);
        }
    };

    handleDrawerWidthChange = (width) => {
        this.setState({ drawerWidthModified: width });
    };

    handleFormAdd = () => {
        this.resetStateCatalog();
        this.handleToggleDrawer("openFormAdd", false);
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors:errors??{} });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error]) return true;
            }
        }
        return false;
    };

    handlerMutation = async () => {
        if (this.state.editForm === 'duplicate') {
            this.props.startLoading()
            for (let catalog of this.state.catalogSelected) {
                await axios(`${process.env.REACT_APP_API}/catalog/duplicate/${this.state.catalogIdToDuplicate.replace('/api/catalogs/', '')}/${catalog.replace('/api/catalogs/', '')}`, 'GET');
            }
            this.props.snack(ALERT_SUCCESS, this.props.t("catalogs.catalogs.catalogDuplicated"));
            this.handleToggleDrawer('openFormDuplicate', true);
            this.resetStateCatalog();
            this.props.stopLoading();
        } else {
            this.props.startLoading();

            let variables = {
                "type": '/api/catalog-types/1',
                "identifier": this.state.catalogIdentifier,
                "libelle": this.state.catalogName,
                "description": this.state.catalogDescription ? this.state.catalogDescription : null,
                "status": this.state.catalogIsActive,
                "media": (this.state.imageCatalog?.id ?? null) ? this.state.imageCatalog.id : null,
                "autosync": this.state.catalogAutosync,
                "startAt": moment().format('YYYY-MM-DD'),
                "endAt": moment().format('YYYY-MM-DD'),
            };
            this.props.client.mutate({
                mutation: ADD_CATALOG,
                variables,
            }).then(async (result) => {

                this.props.snack(ALERT_SUCCESS, this.props.t("catalogs.catalogs.catalogAddedSuccess"));
                this.props.stopLoading();
                if (result.data.createCatalog.catalog.identifier) {
                    this.goToCatalog(ROUTE_CATALOGS_DETAIL.replace(':id', result.data.createCatalog.catalog.id.replace('/api/catalogs/', '')))
                }
                for (let filter of this.state.catalogFilters) {
                    let value = null;
                    let attributeOption = null;
                    if (filter.attributeType.node.attributeType.input === "select") {
                        if (filter.operator === "IN" || filter.operator === "NOT IN") {
                            let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                            value = JSON.stringify(arrayRegex)
                        } else {
                            attributeOption = filter.values
                        }
                    } else {
                        if (filter.operator === "IN" || filter.operator === "NOT IN") {
                            value = JSON.stringify(filter.values)
                        } else {
                            value = filter.values
                        }
                    }
                    let queryFilter = CREATE_CATALOG_FILTER;
                    let variablesFilter = {
                        operator: filter.operator,
                        createdAt: moment(),
                        catalog: result.data.createCatalog.catalog.id,
                        attribute: filter.code,
                        attributeOption,
                        value
                    };
                    await this.props.client.mutate({
                        mutation: queryFilter,
                        variables: variablesFilter
                    });
                }
            }).catch((err)=>{
                console.log(err)
            });
        }
    };

    handleGetCatalogs = async () => {
        // this.props.startLoading();
        let paginationVars = await Pagination.paginationQueryData(this.state.pagination);

        let variables = { ...paginationVars };
        let variablesSetted = false;

        if (this.state.arrayCatFilter.length > 0) {
            variables.typeList = this.state.arrayCatFilter;
            variablesSetted = true;
        }
        if (this.state.searchValue !== '') {
            variables.libelle = this.state.searchValue;
            variablesSetted = true;
        }

        if (variables.cursor || variables.cursorLast) {
            variablesSetted = true;
        }

        let filters = {
            searchValue: this.state.searchValue,
            arrayCatFilter: this.state.arrayCatFilter,
        }
        let paginationBis = this.state.pagination
        let ItemToStore = {
            filters,
            pagination: paginationBis
        }
        setItemsLocalStorage("CATALOGS_FILTERS", ItemToStore)

        this.props.client.query({
            query: GET_CATALOGS_PAGINATION,
            variables,
            fetchPolicy: 'no-cache'
        }).then(async result => {
            let pagination = await Pagination.updatePaginationData(this.state.pagination, result.data.catalogs);
            this.setState({
                pagination,
                listCatalogs: result.data.catalogs.edges,
                noResult: result.data.catalogs.edges.length > 0 ? false : true,
                ready: true,
            })
            this.props.stopLoading();
        }).catch((err)=>{
            console.log(err)
        });
    };

    handleFilters() {
        return new Promise((resolve, reject) => {
            let getFilters = getItemsLocalStorage("CATALOGS_FILTERS")?.filters;
            let getPagination = getItemsLocalStorage("CATALOGS_FILTERS")?.pagination;

            if (getFilters?.searchValue && getFilters.searchValue !== "") {
                this.setState({ searchValue: getFilters.searchValue });
            }
            if (getFilters?.arrayCatFilter && getFilters.arrayCatFilter.length > 0) {
                this.setState({ arrayCatFilter: getFilters.arrayCatFilter });
            }
            if (getPagination) {
                this.setState({ pagination: getPagination });
            }
            resolve();
        });
    }

    changePage = async (newPage) => {
        let pagination = await Pagination.changePage(this.state.pagination, newPage);
        this.setState({ ready: false, pagination }, () => this.handleGetCatalogs());
    };
    changePerPage = async (perPage) => {
        let pagination = await Pagination.updatePerPage(this.state.pagination, perPage);
        this.setState({ pagination }, () => { this.handleGetCatalogs() });
    };

    handleDuplicate = (catalog) => {
        this.props.startLoading()
        this.props.client.query({
            query: GET_CATALOGS,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCatalogsDuplicate: result.data.catalogs.edges,
                editForm: 'duplicate',
                catalogIdToDuplicate: catalog,
            }, () => this.props.stopLoading())
            this.handleToggleDrawer('openFormDuplicate');
        }).catch((err)=>{
            console.log(err)
        });
    }

    handleDelete = async(catalog) => {
        this.props.startLoading()
        this.props.client.mutate({
            mutation: DELETE_CATALOG,
            variables: { id: catalog },
            fetchPolicy: 'no-cache'
        }).then(async (result) => {
            this.props.snack(ALERT_SUCCESS, 'Catalogue supprimé avec succès');
            this.props.stopLoading()
            await this.handleGetCatalogs();
        }).catch((err)=>{
            console.log(err)
        });
    }

    async componentDidMount() {
        checkRouting(this.props)
        await this.handleFilters();
        this.handleGetCatalogs();
        // this.initCatalogsTypes();
        if (getParams(this.props).includes('formOpen') || this.props.history.location?.state?.formOpen) {
            this.handleToggleDrawer('openForm')
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.ready !== this.state.ready) {
    //         this.initCatalogsTypes();
    //     }
    // }

    render() {
        const { t } = this.props;
        return (
            <ContainerCatalogs>
                <TopPanel
                    icomoon="icon-catalogue"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("catalogs.catalogs.manageCatalogs") : this.props.t("catalogs.catalogs.manageWarehouses")}
                    subtitle={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("catalogs.catalogs.manageProductCatalogs") : this.props.t("catalogs.catalogs.manageProductWarehouse")}
                    handlerAdd={() => this.handleFormAdd()}
                    textAdd={process.env.REACT_APP_MODE_SPREAD !== "hub" ? t('catalog.add') : null}
                    searchHandler={
                        ((this.state.listCatalogs?.length > 0 && this.state.ready) || this.state.searchValue || this.state.arrayCatFilter.length > 0) ? (e, value) => { this.handleChange(e, value) } : null
                    }
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    windowWidth={this.props.windowWidth}
                    hasBorder={((this.state.listCatalogs?.length > 0 && this.state.ready) || this.state.searchValue || this.state.arrayCatFilter.length > 0) && process.env.REACT_APP_MODE_SPREAD !== "hub" ? false : true}
                />
                {/* {
                    ((this.state.listCatalogs?.length > 0 && this.state.ready) || this.state.searchValue || this.state.arrayCatFilter.length > 0) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        (<TabCatalogs catalogs={this.state.allCatalogsTypes} rootid={this.state.rootid} handleChangeTab={this.handleChangeTab} />)
                        :
                        null
                } */}
                <Grid container direction="column" justifyContent="center" spacing={0} style={{ height: '100%', display: 'grid', gridTemplateRows: '1fr auto', gridTemplateColumns: '1fr' }}>
                    <div style={{position: 'relative'}}>
                        <Grid container direction="row" spacing={2}>
                            {this.state.isEmpty ?
                                <EmptyCard
                                    title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("catalogs.catalogs.notConfiguredCatalogs") : this.props.t("catalogs.catalogs.notConfiguredWarehouses")}
                                    subtitle={this.props.t("spread.active_assets.clickToAdd")}
                                    textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("catalogs.dashboard.createCatalog") : this.props.t("catalogs.catalogs.createWarehouse")}
                                    picto={EmptyCatalogue}
                                    onClick={() => this.handleFormAdd()}
                                    openForm={this.state.openForm}
                                />
                                :
                                !this.state.ready ?
                                    (<PageLoader />)
                                    :
                                    this.state.listCatalogs?.length > 0 ?
                                        this.state.listCatalogs.map((catalog, index) =>
                                            <GridCustom item lg={this.props.windowWidth > 1500 ? 4 : 6} md={6} sm={this.props.windowWidth > 768 ? 6 : 12} xs={12} key={`ListCatalogs${index}`}>
                                                <CardCustom paddingbottom={0} paddingtop={0} style={{ width: "100%" }} contentpadding={'0 0px 10px 0px'} >
                                                    <CardCatalog
                                                        data={catalog}
                                                        textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("catalogs.dashboard.seeCatalog") : this.props.t("catalogs.catalogs.seeWarehouse")}
                                                        openForm={this.state.openForm}
                                                        textButtonSecond={this.props.t("spread.active_assets.duplicate")}
                                                        actionButtonSecond={() => this.handleDuplicate(catalog.node.id)}
                                                        actionButtonDelete={() => this.handleDelete(catalog.node.id)}
                                                        windowWidth={this.props.windowWidth}
                                                    />
                                                </CardCustom>
                                            </GridCustom>
                                        )
                                        :
                                        this.state.noResult ?
                                            (<EmptyCard title={this.props.t("catalogs.catalogs.noResults")} textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("catalogs.catalogs.addCatalog") : this.props.t("catalogs.catalogs.addWarehouse")} onClick={() => this.handleFormAdd()} picto={EmptyCatalogue} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                                            :
                                            (<EmptyCard title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("catalogs.catalogs.notConfiguredCatalogs") : this.props.t("catalogs.catalogs.notConfiguredWarehouses")} subtitle={this.props.t("spread.active_assets.clickToAdd")} textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("catalogs.catalogs.addCatalog") : this.props.t("catalogs.catalogs.addWarehouse")} onClick={() => this.handleFormAdd()} picto={EmptyCatalogue} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                            }
                        </Grid>
                    </div>
                    {
                        this.state.listCatalogs?.length > 0 ?
                            <Pagination.CursorPagination
                                label={this.props.t("catalogs.catalogs.catalogsPerPage")}
                                pagination={this.state.pagination}
                                type="table"
                                changePageCallback={this.changePage}
                                perPageOptions={[6, 18, 30]}
                                rowLabel={this.props.t("catalogs.catalogs.catalogsPerPageDot")}
                                changePerPageCallback={this.changePerPage}
                            />
                            : null
                    }

                </Grid>
                {/* Create Catalog */}
                {this.state.catalogFormData ?
                    <LayoutBuilder
                        isSublayout={true}
                        handleCancel={this.resetStateCatalog}
                        opened={this.state.openFormAdd}
                        validateButton={true}
                        handlerMutation={this.handlerMutation}
                        dataLayout={catalogAdd(this.handleMediaPicker, this.props.attributes.product.attributes)}
                        allState={this.state}
                        stateCallback={this.handleInputChange}
                        errorCallback={this.handleFormError}
                        drawerWidth={this.state.drawerWidthModified}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        forClose={() => this.handleToggleDrawer('openFormAdd', true)}
                        dataCard={this.state.typeOfCatalogs}
                        stepperButtonAction={[
                            () => {
                                if (this.hasErrors()) {
                                    this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                    this.setState({ seeErrors: true });
                                    eventService.fire();
                                    return false;
                                }
                                this.setState({
                                styleInnerContainer: {
                                    boxShadow: 'none',
                                    backgroundInner: colors.white,
                                    backgroundActions: colors.white,
                                    padding: '0px',
                                    height: '450px'
                                }
                            })},
                            () => {
                                if (this.hasErrors()) {
                                    this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                    this.setState({ seeErrors: true });
                                    eventService.fire();
                                    return false;
                                }

                                this.handleDrawerWidthChange(300)

                                return true;
                            }
                        ]}
                        backStepperButtonAction={[
                            () => this.handleDrawerWidthChange(300),
                            () => this.setState({
                                backgroundDrawer: colors.grey.lighter.hue980,
                                styleInnerContainer: {
                                    boxShadow: 'none',
                                    backgroundInner: colors.white,
                                    backgroundActions: colors.grey.lighter.hue980,
                                    padding: '0px',
                                    height: '630px'
                                }
                            }),
                            () => this.handleDrawerWidthChange(-800),
                        ]}
                    />
                    : null
                }
                {/* Duplicate */}
                <LayoutBuilder
                    isSublayout={false}
                    icomoon={"icon-catalogue"}
                    opened={this.state.openFormDuplicate}
                    forClose={() => { this.handleToggleDrawer('openFormDuplicate', true); this.resetStateCatalog() }}
                    handlerSetup={() => { }}
                    dataLayout={catalogFormDuplicate(this.state)}
                    drawerWidth={this.props.drawerWidth}
                    allState={this.state}
                    stateCallback={this.handleInputChange}
                    errorCallback={this.handleFormError}
                    handlerMutation={this.handlerMutation}
                    handleButtonGroupChange={this.handleButtonGroupChange}
                    validateButton={true}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                />
            </ContainerCatalogs>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };

    goToCatalog = (route, id) => {
        this.props.history.push({
            pathname: route,
            state: { catalogId: id }
        });
    };

    goTo = (route) => {
        this.props.history.push(route);
    };

    goToCatalog = (route, id) => {
        this.props.history.push({
            pathname: route,
            state: { catalogId: id },
        });
    };
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) =>
        dispatch({ type: SNACK, payload: { type, message } }),
    };
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Catalogs))));
