import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideAbout } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider à propos',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        optimisedImage:{
            label: 'Utiliser des images optimisées',
            type: FormInputTypes.SWITCH,
            value: true,
            id: uuidv4(),
        },
        value: {
            type: FormInputTypes.TEXT,
            label: "Changer le texte",
            value: "À propos de nous"
        },
        textOnImage:{
            type: FormInputTypes.SWITCH,
            label: "Mettre le texte sur l'image",
            value: false,
        } 
    },
    blocks:{
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_ABOUT,
            buttonAdd: 'une slide',
            limit: 10,
            config: [ 
                getDefaultSlideAbout(),
                getDefaultSlideAbout(),
                getDefaultSlideAbout(),
            ]
        }
    }
};