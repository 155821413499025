import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import Button from '../../../../components/ui/button/Button';
import CustomCard from './CustomCard';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import './MultipleBlockConfig.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '&>div': {
            margin: '16px 0',
            boxShadow: 'inherit',
            '&:first-child': {
                marginTop: 0,
            },
            '&:before': {
                content: 'inherit',
            }
        }
    },
    cardHeader: {
        color: '#000'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rulePanel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: colors.blue.lighter.hue900,
    },
    actionBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10
    }
}));

const DeleteButton = withStyles(theme => ({
    root: {
        color: red[500],
        backgroundColor: 'transparent',
        minHeight: 10,
        alignSelf: 'flex-end',
        textTransform: 'inherit',
        borderRadius: 0,
        width: 'auto',
        boxShadow: 'inherit',
        '&:hover': {
            color: red[700],
            backgroundColor: 'transparent',
        }
    },
}))(Fab);

const AccordionSummaryCustom = styled(AccordionSummary)`
    background: ${colors.blue.lighter.hue900};
    &>div{
        justify-content: space-between;
        align-items: center;
        p{
            color: ${colors.blue.lighter.hue300};
        }
        svg{
            fill: ${colors.blue.lighter.hue300};
        }
    }
`;

const DragHandle = SortableHandle(() => <span>:::</span>)

const SortableItem = SortableElement(
    ({ data, number, deleteBlock, buildFormInput, addBlock, updateConfig, classes, allDatas }) => {
        let inputs = [];
        let index = number;

        // List inputs 
        for (let inputName in data.inputs) {
            let input = data.inputs[inputName];
            inputs.push(buildFormInput(input, data.id));
        }

        // List block inputs
        for (let blockName in data.blocks) {
            let block = data.blocks[blockName];
            let cardInputs = [];

            for (let inputName in block.inputs) {
                let input = block.inputs[inputName];
                cardInputs.push(buildFormInput(input, block.id));
            }

            if (block.type === 'multiple-block') {
                inputs.push(<MultipleBlockConfig key={block.id} datas={block} buildFormInput={buildFormInput} addBlock={addBlock} deleteBlock={deleteBlock} updateConfig={updateConfig} />)
            } else {
                inputs.push(<CustomCard block={block} cardInputs={cardInputs} propExpanded={false} />);
            }
        }
        return (
            <Accordion style={{ borderRadius: 0 }} key={index} className="sortable-item">
                <AccordionSummaryCustom
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{data.title} {index + 1}</Typography>
                    <DragHandle />
                </AccordionSummaryCustom>
                <AccordionDetails className={classes.rulePanel} style={{ padding: '0 16px!important' }}>
                    {inputs}
                    <DeleteButton style={{ paddingRight: 8 }} color="secondary" size="small" aria-label="delete" className={classes.button} onClick={() => { deleteBlock(allDatas, index) }}>
                        <DeleteIcon style={{ width: 16 }} />
                        Supprimer
                    </DeleteButton>
                </AccordionDetails>
            </Accordion>
        )
    }
);

const SortableList = SortableContainer(({ items, deleteBlock, buildFormInput, addBlock, updateConfig, classes }) => {
    return (
        <div className="sortableHelper">
            {items.config.map((value, index) => {
                return <SortableItem 
                    key={`item-${index}`} 
                    index={index} 
                    number={index} 
                    data={value}
                    deleteBlock={deleteBlock} 
                    buildFormInput={buildFormInput} 
                    addBlock={addBlock}
                    updateConfig={updateConfig}
                    classes={classes}
                    allDatas={items} 
                />;
            })}
        </div>
    );
});

const MultipleBlockConfig = ({ datas, buildFormInput, addBlock, deleteBlock, updateConfig }) => {
    const classes = useStyles();

    const onSortEnd = ({ oldIndex, newIndex }) => {
        datas.config = arrayMove(datas.config, oldIndex, newIndex);

        if (updateConfig)
            updateConfig(true);
    };

    return (
        <div className={classes.root}>
            <SortableList 
                hideSortableGhost={false} 
                useDragHandle
                items={datas} 
                onSortEnd={onSortEnd} 
                axis={"y"} 
                buildFormInput={buildFormInput} 
                deleteBlock={deleteBlock} 
                addBlock={addBlock}
                updateConfig={updateConfig}
                classes={classes}
            />

            {
                (datas.limit > datas.config.length || !datas.limit) ? (
                    <div className={classes.actionBar}>
                        <Button
                            label="Ajouter"
                            style={{ backgroundColor: colors.menu.regular, color: "white", boxShadow: `0px 3px 0px 0px ${colors.menu.darker}`, textTransform: 'initial', width: '100%' }}
                            onClick={() => addBlock(datas)}
                        >
                            <AddIcon style={{ fontSize: 16, fill: colors.white, marginRight: 7.5 }} /> <span style={{ fontSize: 17 }}>Ajouter {datas.buttonAdd ? ` ${datas.buttonAdd}` : null}</span>
                        </Button>
                    </div>
                ) : null
            }
        </div>
    )
};

export default MultipleBlockConfig;
