import { Box } from '@mui/material';
import { GridCustom, LinkText } from "../../styles/inputStyled";
import { HourFieldCustom } from './styles/styled';

const Hour = ({ allProps: props }) => {
    return (
        <GridCustom item xs={12}>
            <HourFieldCustom
                id={props.input.stateName}
                type="time"
                defaultValue="07:30"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                }}
                helperText={props.input.helper.link ? <Box><LinkText variant="h5">{props.input.helper.text}</LinkText></Box> : props.input.helper.text}
                disabled={props.input.disabled ? true : false}
            />
        </GridCustom>
    )
}

export default Hour;