import { Grid } from "@mui/material"
import TopPanel from "../../../layouts/TopPanel/TopPanel"
import Filters from "./components/Filters/Filters"
import Listing from "../../../layouts/Listing/Listing"
import { connect } from "react-redux"
import { withApollo } from '@apollo/client/react/hoc';
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types"
import { getFilters, getListingProps, getTopPanel, getTradingPartners, getUsers } from "./utils"
import { useEffect, useState } from "react"
import { ALERT_ERROR, ALERT_SUCCESS } from "../../../../js/constants/alert-types"
import { UPDATE_PRODUCT_WORKFLOW } from "../../../../queries/product_workflow"
import { getUserBrands } from "../../../../queries/brands"
import DashboardActivities from "./components/DashboardActivities/DashboardActivities"

const WorkflowLayout = (props) => {
  const [loading,setLoading] = useState(true)
  const [filtersLoading,setFiltersLoading] = useState(true)
  const [users,setUsers] = useState([])
  const [tradingPartners,setTradingPartners] = useState([])
  const [brands,setBrands] = useState([])
  const [state,setState] = useState({
    nbPerPage:12,
    cursor:null,
    cursorLast:null,
    priority:null,
    state:null,
    taskState:null,
    importState:null,
    user:" ",
    sku:null,
    source:" ",
    dateFrom:null,
    dateTo:null,
    retailerState:null,
    channel:" "
  })

  const stateCallback = (newState,type,view)=>{
    if (type === 'date') {
      if (view === "start") {
        setState({
          ...state,
          dateFrom : newState
        })    
      }
      if (view === "end") {
        setState({
          ...state,
          dateTo : newState
        })    
      }
    }else{
      setState({
        ...state,
        ...newState
      })
    }
  }
  
  const assignUser = (id,value,type)=>{
    let variables 
    if (type === "user") {
      variables = {
        id: id,
        user:value,
      }
    }
    if (type === 'priority') {
      variables = {
        id: id,
        priority:value,
      }
    }
    props.client.mutate({
      mutation: UPDATE_PRODUCT_WORKFLOW,
      variables
    }).then(result => {
      reload()
      if (type === 'user') {
        let name = `${result.data.updateProductWorkflow.productWorkflow.user.firstname} ${result.data.updateProductWorkflow.productWorkflow.user.lastname}`
        props.snack(ALERT_SUCCESS, `Produit assigné à ${name}`);
      }
      if (type === 'priority') {
        props.snack(ALERT_SUCCESS, `Priorité modifiée`);
      }
    }).catch((err)=>{
      console.log(err)
      props.snack(ALERT_ERROR, 'Une erreur est survenue');
    })
  }

  const reload=()=>{
    setLoading(!loading)
  }

  const reloadFilters=()=>{
    setFiltersLoading(!filtersLoading)
    setTimeout(() => {
      setFiltersLoading(!filtersLoading)
    }, 100);
  }

  
  useEffect(()=>{
    getUsers({client:props.client,id:props.user?.id}).then((result)=>{
      setUsers(result)
    }).catch((err)=>{
      console.log(err)
    });
    getTradingPartners({client:props.client,id:props.user.id}).then((result)=>{
      setTradingPartners(result)
    }).catch((err)=>{
      console.log(err)
    });
  },[])

  const getBrands = ()=>{
    props.client.query({
      query: getUserBrands,
      variables: {
          user:props.user.id
      }
    }).then(result => {
        setBrands(result.data.productSources.edges)
    }).catch((err)=>{
      console.log(err)
    })
  }

  
  useEffect(()=>{
    getBrands()
  },[])
  
  useEffect(()=>{
    reload()
    reloadFilters()
  },[props.location.pathname])
  return (
    <div>
      <TopPanel
        title={getTopPanel(props).title}
        subtitle={getTopPanel(props).subtitle}
        windowWidth={props.windowWidth}
        locales={props.locales}
        hasBorder={true}
        alignItems={true}
      />
      <Grid container style={{
      paddingTop:20,
      paddingBottom:20
    }}>
      {
        props.identifier === 'tasks' || props.identifier === 'activity'|| props.identifier === 'tasks_contributor'?
        <Grid item xs={12} style={{marginBottom:20}}>
          <DashboardActivities/>
        </Grid>
        :null
      }
        <Grid item xs={12} style={{marginBottom:20}}>
          <Filters
            filters={getFilters({props,users,state,currentUser:props.user,tradingPartners})}
            stateCallback={stateCallback}
            validate={reload}
            state={{users,state}}
            select
            brands={brands}
          />
        </Grid>
        <Grid item xs={12}>
          {
            state ?
          <Listing
              label={getListingProps({identifier:props.identifier,users,currentUser:props.user}).label}
              settings={getListingProps({identifier:props.identifier,users,currentUser:props.user}).settings}
              perPageOptions={getListingProps({identifier:props.identifier,users,currentUser:props.user}).perPageOptions}
              mappers={getListingProps({identifier:props.identifier,users,currentUser:props.user}).mappers}
              // currentLang={currentLang}
              identifier={getListingProps({identifier:props.identifier,users,currentUser:props.user}).identifier}
              propsToPass={{
                  allProps:props,
                  users,
                  currentUser:props.user,
                  stateCallback,
                  assignUser
              }}
              queryVariables={{
                "nbperpage": state.nbPerPage ??12, 
                "cursor": state.cursor, 
                "cursorLast": state.cursorLast, 
                "priority":state?.priority === " " ?null:state?.priority === 3 ? 0 :state?.priority, 
                "state":state?.state === " " ?null:state?.state === 2? 0 :state?.state,
                "taskState":state?.taskState === " " ?null:state?.taskState === 4 ? 0 :state?.taskState, 
                "importState":state?.importState === " " ?null:state?.importState === 2 ? 0 :state?.importState, 
                "user":props.user.id === "/api/users/1" ? state.user : props.user.id, 
                "productSku":state.sku, 
                "source":state.source.length > 1 ?Number(state.source?.replace('/api/product-sources/',' ')): null,
                "dateFrom":state.dateFrom, 
                "dateTo":state.dateTo,
                "channel":state.channel,
                "retailerState": state?.retailerState === " " ?null: state?.retailerState === 3 ? 0 :state?.retailerState, 
              }}
              viewsOptions={{
                  current: 'table_workflow',
              }}
              pagination={true}
              reload={loading}
              // listingCallback={reload}
          />
         :null   
        }
        </Grid>
      </Grid>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
      startLoading: () => dispatch({ type: START_LOADING }),
      stopLoading: () => dispatch({ type: STOP_LOADING }),
      snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
  }
}

const mapStateToProps = state => {
  return {
      loading: state.loading,
      products: state.products,
      attributes: state.attributes,
      attributeGroups: state.attributeGroups,
      locales: state.locales,
      user:state.user
  };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(WorkflowLayout))));