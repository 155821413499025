import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "@config/theme/colors";
import { Input, Typography } from "@mui/material";
import PropsTypes from "prop-types";

const InputCustom = styled(Input)`
  border: 1px solid ${colors.grey.lighter.hue900};
  border-radius: 5px;
  padding-left: 4px;
  &:before {
    content: inherit !important;
  }
  &:after {
    content: inherit !important;
  }
`;

const TypoMarkupInput = ({
  label,
  fontFamily,
  value,
  minHeight,
  onChange,
  onBlur,
  inputProps,
}) => {
  const [fontSize, setFontSize] = useState(12);

  useEffect(() => {
    if (typeof value === "string" && value !== "") {
      setFontSize(parseInt(value));
    } else {
      setFontSize(1);
    }
  }, [value]);

  return (
    <div
      style={{
        position: "relative",
        minHeight: minHeight,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: 18,
          marginRight: 2,
        }}
      >
        {label} :
      </Typography>
      <p
        style={{
          fontFamily: fontFamily,
          fontSize: fontSize,
          margin: 0,
          lineHeight: "1.3",
          height: fontSize * 1.3,
          overflow: "hidden",
          transition: "all ease 0.5s",
        }}
      >
        {fontFamily}
      </p>
      <div
        style={{
          position: "absolute",
          background: "white",
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: fontSize * 1.3,
          padding: "0 0 0 25px",
        }}
      >
        <InputCustom
          id="outlined-basic"
          variant="outlined"
          value={value}
          style={{ width: 50 }}
          size="small"
          onChange={(e) => {
            onChange(e.target.value);
          }}
          inputProps={{
            step: 1,
            type: "number",
            onBlur: () => onBlur(),
            ...inputProps,
          }}
        />
        <span style={{ marginLeft: 8 }}>px</span>
      </div>
    </div>
  );
};

TypoMarkupInput.propTypes = {
  label: PropsTypes.string,
  fontFamily: PropsTypes.string,
  minHeight: PropsTypes.number,
  onChange: PropsTypes.func,
  onBlur: PropsTypes.func,
  inputProps: PropsTypes.object,
};

export default TypoMarkupInput;
