export const getTranslationLabel = (translationObject, lang = "fr_FR") => {
    // return translationObject.translationDatas?.edges?.find(
    //   (e) => e.node?.locale?.code === lang
    // )?.node?.value;

    const trad = translationObject.translationDatas?.edges?.find(
        (e) => e.node?.locale?.code === lang
      )?.node?.value;

      if (!trad) console.log(translationObject)
      else return trad;
  };
