import { gql } from '@apollo/client';

export const GET_RATE_GROUP = gql`
query getRateGroup($id: ID!){
  rateGroup(id: $id) {
    id
    code

    currency{
      id
      code
      libelle
    }
    taxRates{
      edges{
        node{
          id
        }
      }
    }
  }
}
`;

export const GET_RATE_GROUPS = gql`
{
  rateGroups{
    edges{
      node{
        id
        code

        currency{
          id
          code
          libelle
        }
        taxRates{
          edges{
            node{
              id
            }
          }
        }
      }
    }  
  }
}
`;


export const GET_RATE_GROUPS_LIGHT = gql`
{
  rateGroups{
    edges{
      node{
        id
        code
        currency{
          id
          code
          libelle
        }
        taxRates{
          edges{
            node{
              id
            }
          }
        }
      }
    }  
  }
}
`;
export const GET_RATE_GROUPS_PAGINATION = gql`
query getRateGroupPagination($nbperpage: Int, $cursor: String, $cursorLast: String){
  rateGroups(first: $nbperpage, after: $cursor, before: $cursorLast){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        code

        currency{
          id
          code
          libelle
        }
        taxRates{
          edges{
            node{
              id
            }
          }
        }
      }
    }  
  }
}
`;

export const CREATE_RATE_GROUP = gql`
mutation createRateGroup($code: String!, $currency: String!, $retailerProductData: [String], $taxRates: [String]){
  createRateGroup(input:{code: $code, currency: $currency, retailerProductData: $retailerProductData, taxRates: $taxRates}){
    rateGroup{
      id
      code

      currency{
        id
        code
        libelle
      }
      taxRates{
        edges{
          node{
            id
          }
        }
      }
    }
  }
}
`;

export const UPDATE_RATE_GROUP = gql`
mutation updateRateGroup($id: ID!, $code: String, $currency: String, $retailerProductData: [String], $taxRates: [String]){
  updateRateGroup(input:{id: $id, code: $code, currency: $currency, retailerProductData: $retailerProductData, taxRates: $taxRates}){
    rateGroup{
      id
      code

      currency{
        id
        code
        libelle
      }
      taxRates{
        edges{
          node{
            id
          }
        }
      }
    }
  }
}
`;

export const DELETE_RATE_GROUP = gql`
mutation deleteRateGroup($id: ID!){
  deleteRateGroup(input:{id: $id}){
    rateGroup{
      id
    }
  }
}
`;