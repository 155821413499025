import {getTraductionAttributs} from '../utils/functions';
import { ROUTE_CRM_CONTACTS_DETAILS,ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS } from '../constants/route-names';

export const customersListMapper = (customers,lang) =>{
    return new Promise((resolve,reject)=>{
        let results = customers.map((customer,index)=>{
            let attributes = customer.node.customerDatas.edges;
            return{
                name : getTraductionAttributs('customer_firstname',attributes,lang) + getTraductionAttributs('customer_lastname',attributes,lang),
                companies : renderCompany(customer.node.companies.edges,lang),
                email : customer.node.sku,
                telephone : getTraductionAttributs('customer_phone',attributes,lang),
                image : getTraductionAttributs('customer_image', attributes, lang,'image')?.filePath,
                link : {
                    pathname : ROUTE_CRM_CONTACTS_DETAILS.replace(':id',customer.node.sku),
                    state : {contactId : customer.node.id}
                },
            }
        })
        resolve(results);
    })
}

const renderCompany = (companies,lang) =>{
    let newCompanies = companies.map((company)=>{
        let attributes = company.node.companyDatas.edges;
        return {
            letter : company.node.libelle.charAt(0).toUpperCase(),
            tooltip : getTraductionAttributs('company_name',attributes,lang),
            image : getTraductionAttributs('company_image',attributes,lang,'image')?.filePath,
            link : {
                pathname : ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS.replace(':id',company.node.libelle),
                state : {idCompany : company.node.id}
            },
        }
    })
    return newCompanies;
}