import { withTranslation } from "react-i18next";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../../../js/constants/action-types";
import { withRouter } from "react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import AccordionCustom from "../../../../../layouts/Accordion/AccordionCustom";
import OurTypography from "../../../../../ui/typography/Typography";
import { useEffect, useState } from "react";
import { GET_PRODUCT_TAXOS } from "../../../../../../queries/products";
import colors from "../../../../../../config/theme/colors";

const ProductDetailTaxonomie = props =>{
    const [listTaxos, setListTaxos] = useState([])

    useEffect(()=>{
        props.client.query({
            query: GET_PRODUCT_TAXOS,
            fetchPolicy: "no-cache",
            variables: {
                product: props.match.params.id,
            }
        }).then(GET_PRODUCT_TAXOS_RESULT => {
            setListTaxos({
                listTaxos: GET_PRODUCT_TAXOS_RESULT.data.productTaxos.edges
            })
            if(GET_PRODUCT_TAXOS_RESULT.data.productTaxos.edges.length > 0){
                props.setTaxos(true)
            }else{
                props.setTaxos(false)
            }
        })
    },[])
    
    return (
        listTaxos.length > 0 &&
        <div id={props?.id}>
            <AccordionCustom 
                noborder 
                topborder 
                title={<OurTypography fontweighttext={"bold"} style={{paddingLeft: "15px"}}>Taxonomies</OurTypography>} 
                custompadding={'15px 0 !important'}
            >
                {
                    listTaxos.length > 0
                        ? listTaxos.map((taxo, index) => (
                            <OurTypography key={index} style={{ marginBottom: listTaxos.length !== index + 1 ? 4 : 0 }}>Assignation à la catégorie <OurTypography fontweighttext={"bold"} colortext={colors.blue.darker.hue300}>{taxo.node.categoryName}</OurTypography> grâce au terme "<OurTypography fontweighttext={"bold"} colortext={colors.blue.darker.hue300}>{taxo.node.taxoValue}</OurTypography>" trouvée dans l'attribut <OurTypography fontweighttext={"bold"} colortext={colors.blue.darker.hue300}>{taxo.node.attributeName}</OurTypography>.</OurTypography>
                        ))
                        : null
                }
            </AccordionCustom>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ProductDetailTaxonomie))));