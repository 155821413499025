import PropTypes from "prop-types";
import React from "react";
import Cdiscount from './image/cdiscount-2.svg';
import Fnac from './image/fnac_Logo-blanc.png';
import PicWic from './image/picwic-2.png';
import Pg from '../../../../shared/components/CustomParagraph';
import LogoLego from './image/logo_lego.svg';

const HeaderBook = (props) => {
    let {
        preview = false,
        id = null, 
        data,
        noSticky,
        inputCallback = null,
        origin = 'LEGO',
        spread = false,
    } = props;

    const renderMobileBanner = (origin) => {
        let backgroundBanner = null;
        let imageUrl = null;
        let notDisplayed = false;
        switch(origin){
            case 'CDISCOUNT': 
                backgroundBanner = '#293847';
                imageUrl = Cdiscount;
                break;
            case 'FNAC': 
                backgroundBanner = '#ebb300';
                imageUrl = Fnac;
                break;
            case 'PICWICTOYS': 
                backgroundBanner = '#FFD300';
                imageUrl = PicWic;
                break;
            default :
                notDisplayed = true;
                break;

        }
        if (!notDisplayed){
            return(
                <div className="originBanner" style={{background: backgroundBanner}}>
                    <img src={imageUrl} alt="Background banner"/>
                </div> 
            )
        }
    }

    let image = data?.blocks?.logo?.inputs.value.value;
    return (
        <section 
            className="scope-book" 
            onClick={spread ? () => inputCallback(id) : null}
            // style={{
            //     backgroundColor: data.inputs['backgroundColor'].value,
            //     backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
            //     backgroundSize: '100%',
            //     backgroundPosition: "center",
            //     backgroundRepeat: "no-repeat",
            //     position: 'relative',
            // }}
        >
            { !preview && props.children}
            <div className="wrapper_lego">
                <a href={process.env.REACT_APP_HOST_LINK}>
                    <img className="logo" onClick={spread ? () => inputCallback(id, 'logo') : null} 
                        src={image?.filePath ? `${process.env.REACT_APP_MEDIAS}/${image.filePath}` : LogoLego}
                        alt="Logo Flipbook"
                    />
                </a>
            </div>
            {renderMobileBanner(origin)}
            <div className="banner">
                <Pg data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
                {/* <p>Du 20 octobre au 5 décembre 2021</p> */}
            </div>
        </section>
    );
};

HeaderBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default HeaderBook;
