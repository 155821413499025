import { gql } from '@apollo/client';


//----- Récupération des clés de traductions
export const GET_TRANSLATIONS = gql`
{
  translations{
    edges{
      node{
        id
        translationKey
      }
    }
  }
}
`;


//----- Ajouter une translation 
/*
 Exemple de variables
 {
  "translationKey": "spread.test.api"
  }
*/
export const ADD_TRANSLATION = gql`
mutation AddTranslation($translationKey: String!){
	createTranslation(input: {translationKey:$translationKey}){
  	translation{
      id
      translationKey
    }
  }
}`;

//----- Modifier une translation 
/*
 Exemple de variables
 {
  "id": "/api/translations/33",
  "translationKey": "spread.test.api"
  }
*/
export const UPDATE_TRANSLATION = gql`
mutation UpdateTranslation($id: ID!, $translationKey: String){
	updateTranslation(input: {id:$id, translationKey:$translationKey}){
  	translation{
      id
      translationKey
    }
  }
}`;

//----- Supprimer une translation 
/*
 Exemple de variables
 {
  "id": "/api/translations/33",
  }
*/
export const DELETE_TRANSLATION = gql`
mutation DeleteTranslation($id: ID!, $translationKey: String){
	deleteTranslation(input: {id:$id, translationKey:$translationKey}){
  	translation{
      id
    }
  }
}`;

//----- Ajouter une translation data ( valeur / locale ) 
/*
 Exemple de variables
 {
  "value": "Rouge",
  "locale": "/api/locales/2",
  "translation": "/api/translations/33"
  }
*/
export const ADD_TRANSLATION_DATA = gql`
mutation AddTranslationData($value: String!, $locale: String!, $translation: String!){
	createTranslationData(input: {value:$value, locale:$locale, translation:$translation}){
  	translationData{
      id
      value
      locale{
        id
      }
      translation{
        id
      }
    }
  }
}`;

//----- Modifier une translation data ( valeur / locale ) 
/*
 Exemple de variables
 {
  "id" : "/api/translation-datas/28",
  "value": "Rouge edited",
  "locale": "/api/locales/2",
  "translation": "/api/translations/33"
  }
*/
export const UPDATE_TRANSLATION_DATA = gql`
mutation UpdateTranslationData($id: ID!, $value: String, $locale: String, $translation: String){
	updateTranslationData(input: {id: $id, value:$value, locale:$locale, translation:$translation}){
  	translationData{
      id
      value
      locale{
        id
      }
      translation{
        id
      }
    }
  }
}`;

//----- Supprimer une translation data ( valeur / locale ) 
/*
 Exemple de variables
  {
    "id" : "/api/translation-datas/28"
  }
*/
export const DELETE_TRANSLATION_DATA = gql`
mutation DeleteTranslationData($id: ID!){
	deleteTranslationData(input: {id: $id}){
  	translationData{
      id
    }
  }
}`;