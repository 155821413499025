import React, { useState } from "react";
import H2 from '../shared/h2';
import P from '../shared/p';
import Div from '../shared/div';
import styled from 'styled-components';

const SectionSubtitle = styled(Div)`
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 512px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    justify-content: flex-end;
    img{
        max-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        min-width: 100%;
        min-height: 100%;
        position: absolute;
    }
    p {
        position: relative;
        text-align: center;
        display: block;
        color: white;
        text-transform: uppercase;
        font-size: 14px; //DONT CHANGE
        line-height: 1.2;
        font-family: 'DINOffc-Medi', sans-serif;
        margin: 0;
        &.top-text{
            position: absolute;
            top: 18px;
            @media screen and (min-width: 1024px){
                top: 30px;
                font-size: 1.5625rem;
            };
        }
        &.bottom-text{
            font-family: 'DINOffc-Black', sans-serif;
            margin-top: 0;
            font-size: 17px; //DONT CHANGE
            line-height: 46px; //DONT CHANGE
        }
    }
    h2 {
        position: relative;
        text-align: center;
        display: block;
        color: white;
        font-size: 67px;
        line-height: 71px;
        font-family: 'Univers Bold Condensed', sans-serif;
    }
    @media screen and (min-width: 768px){
        p {
            font-size: 1.5625rem;
        }
        img {
            max-height: none !important;
        }
    }
    @media screen and (min-width: 1024px){
        height: 800px;
        p.bottom-text{
            font-size: 42px !important; // DONT CHANGE
            line-height: 106px !important; // DONT CHANGE
        }
        h2 {
            font-size: 163px !important; // DONT CHANGE
            line-height: 170px !important; // DONT CHANGE
        }
    }
`;

const ContainerImage = styled(Div)`
    width: 100%;
    &:after{
        content:'';
        position:absolute;
        left:0px;
        top:0px;
        width:100%;
        height:100%;
        background: rgba(0, 0, 0, 0.2);
    }
`;

const ContainerTitle = styled(Div)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: auto;
    display: block;
    margin: auto;
    max-width: 90%;
    width: 1500px;
    padding-bottom: 20px;
    h2 {
        letter-spacing: -4px;
    }
`;

const TopImageFullscreen = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        data,
    } = props;

    return (
        <Div style={{position: 'relative'}} className="sectionSubtitle" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <SectionSubtitle>
                <ContainerImage>
                    <img src={
                        typeof data.blocks.image.inputs.value.value === 'string' ? 
                            data.blocks.image.inputs.value.value 
                        : data.blocks.image.inputs.value.value.filePath ? 
                            `${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` 
                        : null} 
                        alt={data.blocks.image.inputs.alt?.value ?? "Illustration"}
                    />
                </ContainerImage>
                {
                    data.blocks.subtitleTop.inputs.value.value ? 
                        <P className="top-text">{data.blocks.subtitleTop.inputs.value.value}</P>
                    : null
                }
                <ContainerTitle>
                    <H2>{data.blocks.title.inputs.value.value}</H2>
                    {
                        data.blocks.subtitle.inputs.value.value ? 
                            <P className="bottom-text">{data.blocks.subtitle.inputs.value.value}</P>
                        : null
                    }
                </ContainerTitle>
            </SectionSubtitle>
        </Div>
    );
};

export default TopImageFullscreen;