import DropdownTreeSelect from "react-dropdown-tree-select";
import styled from "styled-components";
import { Grid, InputLabel } from "@mui/material";
import PropTypes from "prop-types";

const DropdownTreeSelectCustom = styled(DropdownTreeSelect)`
  font-weight: bold;
  background-color: white;
  & .node {
    height: 35px;
  }
  & .node-label {
    font-weight: 400;
  }
`;

const SelectTree = ({
  label,
  required,
  error,
  data,
  disabled = false,
  searchPlaceholder,
  onChange,
  ...props
}) => {
  return (
    <Grid item xs={12}>
      {label && (
        <InputLabel
          margin={"dense"}
          variant="standard"
          sx={{ marginBottom: "5px" }}
        >
          {label} {required && "*"}
        </InputLabel>
      )}
      <DropdownTreeSelectCustom
        className={error ? "error multiSelectTree" : "multiSelectTree"}
        data={data}
        mode="hierarchical"
        keepOpenOnSelect={true}
        inlineSearchInput={true}
        disabled={disabled}
        texts={{
          placeholder: data.length > 0 ? "Rechercher..." : "Chargement...",
          noMatches: "Aucun résultat",
          inlineSearchPlaceholder: searchPlaceholder ?? "Rechercher...",
        }}
        onChange={(currentNode, selectedNodes) => {
          onChange(currentNode, selectedNodes);
        }}
      />

      {/* {!error ? (
        props.input.helper.link ? (
          <LinkText
            variant="body2"
            onClick={() => goTo(props.input.helper.route)}
          >
            {props.input.helper.text}
          </LinkText>
        ) : (
          <HelperText variant="body2">{props.input.helper.text}</HelperText>
        )
      ) : (
        <span className="error-label">{errorMessage}</span>
      )} */}
      {/* <OurLock
        lock={lock}
        isDisabled={props.input.disabled}
        style={{
          position: "absolute",
          right: "20px",
          bottom: "32%",
        }}
      /> */}
    </Grid>
  );
};

SelectTree.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  disabled: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectTree;
