import { Box, Grid, Tooltip, Typography } from "@mui/material"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import colors from "../../../../../../config/theme/colors"
import AccordionCustom from "../../../../../layouts/Accordion/AccordionCustom"
import OurTypography from "../../../../../ui/typography/Typography"
import { getTotalTaskByUser } from "../../../../../../queries/product_workflow"
import { useEffect, useState } from "react"
import { withRouter } from "react-router"
import { withApollo } from '@apollo/client/react/hoc';
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../../../js/constants/action-types"
import { connect } from "react-redux"
import { getUserBrands } from "../../../../../../queries/brands"

const DataCard = styled(Box)`
    border: 1px solid #C9CBCC80;
    width: 20%;
    background: ${colors.white.regular};
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    border: ${props => props.isActive ? `1px solid ${colors.blue.darker.hue300}` : `1px solid ${colors.grey.border}`};
    background-color: ${props => props.isActive ? `${colors.blue.lighter.hue900}` : `${colors.white.regular}`} ;
    & > p {
        color: ${props => props.isActive ? `${colors.blue.darker.hue300}` : `${colors.black.regular}`};
    }
    /* &:hover {
        border: 1px solid ${colors.blue.darker.hue300};
        background-color: ${colors.blue.lighter.hue900} ;

        & > p {
            color: ${colors.blue.darker.hue300} !important;
        }
    } */
`;

const DashboardActivities = (props) => {
  const [tasks,setTasks]= useState(null)
  const [brands,setBrands]= useState(null)

  const getBrands = ()=>{
    props.client.query({
      query: getUserBrands,
      variables: {
        "user":props.user.id
      }
    }).then(result => {
        setBrands(result.data.productSources.edges)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const getTotalTask = ()=>{
    props.client.query({
      query: getTotalTaskByUser,
      variables: {
        // user:user.id
      }
    }).then(result => {
        setTasks(result.data.productWorkflows)
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getTotalTask()
    getBrands()
  },[])

  return (
    <div>
      <Box>
        <AccordionCustom defaultExpanded={true} title={
          <OurTypography fontsize={14} colortext={colors.black.regular}>Informations du profil en temps réel : <span style={{fontWeight: 700}}>Vendredi 10 mars 2023 à 14:18:05</span></OurTypography>
        }>
          <Grid container alignItems="center" style={{
            padding: props.windowWidth < 1250 ? '10px' : '16px 0px',
          }}>
            {/* User */}
            <Grid item xs={12} md={4} style={{
              marginBottom: props.windowWidth < 1250 ? '32px' : null,
            }}>
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <Grid container alignItems="center" justifyContent="center" style={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: '50%',
                    width: 'auto'
                  }}>
                    <Grid container justifyContent="center" alignItems="center" style={{
                      backgroundColor: colors.grey.lighter.hue980,
                      border: `1px solid ${colors.grey.lighter.hue700}`,
                      height: 125,
                      width: 125,
                      borderRadius: '50%',
                      margin: 'auto',
                    }}>
                      {!props.user?.image ? (
                        <OurTypography fontweighttext={700} colortext={colors.grey.lighter.hue700} style={{ fontSize: '75px' }}>{(`${props.user?.firstname} ${props.user?.lastname ?? props.user?.name ?? null}`).split(' ').map(w => w[0]?.toUpperCase()).splice(0, 2)}</OurTypography>
                      ) : (
                        <img src={props.user?.image ? `${process.env.REACT_APP_API_ROOT}/${props.user.image}` : 'JohnDoe'} style={{
                          // height: 'auto',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          // paddingRight: '16px',
                          transform: 'translate(-50%, -50%)',
                          // width: '100%',
                          objectFit: 'cover',
                          borderRadius: '50%',
                          width: 147,
                          height: 156,
                        }} />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Box ml={'16px'}>
                    <div style={{ marginBottom: '19px' }}>
                      <OurTypography fontsize={25} fontweighttext={700} style={{ marginBottom: 4 }}>{props.user.firstname} {props.user.lastname}</OurTypography>
                      <OurTypography fontsize={16} fontstyle={'italic'}>{props.user.email}</OurTypography>
                    </div>
                    {/* Datas */}
                    <Box>
                      <Grid container>
                        <Grid item xs={5}>
                          <OurTypography fontsize={13} style={{ marginBottom: 3 }}>Poste occupé :</OurTypography>
                        </Grid>
                        <Grid item xs={7}>
                          <OurTypography fontsize={13} fontweighttext={700}>{props.user?.job}</OurTypography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5}>
                          <OurTypography fontsize={13} style={{ marginBottom: 3 }}>Accès :</OurTypography>
                        </Grid>
                        <Grid item xs={7}>
                          <OurTypography fontsize={13} fontweighttext={700}>{props.user?.userGroup.code}</OurTypography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5}>
                          <OurTypography fontsize={13} style={{ marginBottom: 3 }}> {brands?.length > 1 ?"Maisons associées :" : "Maison associée :" }</OurTypography>
                        </Grid>
                        <Grid item xs={7}>
                          {
                            brands?
                              <Tooltip title={
                                brands?.length === 1 ?
                                brands[0].node.libelle
                                :brands?.length === 0 ?
                                null
                                :brands?.length > 1 ?
                                brands?.map((e,i)=> i !== brands?.length - 1 ? e.node.libelle + " / ":e.node.libelle )
                                :null
                              }
                              placement={"bottom-start"}>
                                <Typography style={{fontSize:13,fontWeight:"700"}}>

                                  {
                                    brands && brands?.length === 0 ?
                                    "-"
                                    :brands && brands?.length === 1  ?
                                    brands[0]?.node.libelle
                                    :brands && brands?.length > 1  ?
                                    `${brands[0]?.node.libelle} , ${brands[1]?.node.libelle} ${brands?.length > 2? " + " + (brands?.length - 2) : ""}`
                                    :null
                                  }
                                  
                                </Typography>
                              </Tooltip>
                            :null
                          }
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* Datas */}
            <Grid item xs={12} md={8} style={{ 
              height: props.windowWidth < 1250 ? null : '100%',
              display: 'flex',
              alignItems: 'flex-end'
            }}>
              <Grid container style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "nowrap",
                gap: 20,
                marginTop: 5
              }}>
                <DataCard isActive={true}>
                  <OurTypography fontweighttext={700} fontsize={12} style={{ marginBottom: '10px' }}>Tâches total</OurTypography>
                  <OurTypography fontweighttext={700} fontsize={36} style={{ marginBottom: '15px' }}>
                    {
                      props.user?.id === '/api/users/1'?
                      tasks ?tasks?.edges.length:null
                      :tasks ?tasks?.edges.filter(e=>e.node.user?.id === props.user.id).length:null

                    }
                  </OurTypography>
                  {/* <OurTypography fontstyle={'italic'} colortext={colors.grey.lighter.hue600} fontsize={12} >Mise à jour à 10:45:58</OurTypography> */}
                </DataCard>
                {
                  props.user?.id === "/api/users/1"?
                    <DataCard>
                      <OurTypography fontweighttext={700} fontsize={12} style={{ marginBottom: '10px' }}>À assigner</OurTypography>
                      <OurTypography fontweighttext={700} fontsize={36} style={{ marginBottom: '15px' }}>{tasks ?tasks?.edges.filter(e=>!e.node.user).length:null}</OurTypography>
                    </DataCard>
                  :null
                }
                {
                  props.user?.id === "/api/users/1"?
                  <DataCard>
                  <OurTypography fontweighttext={700} fontsize={12} style={{ marginBottom: '10px' }}>A faire</OurTypography>
                  <OurTypography fontweighttext={700} fontsize={36} style={{ marginBottom: '15px' }}>{tasks ?tasks?.edges.filter(e=>e.node.taskState === 0 ).length:null}</OurTypography>
                </DataCard>
                  :null
                }
                {
                  props.user?.id !== "/api/users/1"?
                  <DataCard>
                  <OurTypography fontweighttext={700} fontsize={12} style={{ marginBottom: '10px' }}>A faire</OurTypography>
                  <OurTypography fontweighttext={700} fontsize={36} style={{ marginBottom: '15px' }}>{tasks ?tasks?.edges.filter(e=>e.node.taskState === 0 &&e.node.user?.id === props.user.id).length:null}</OurTypography>
                </DataCard>
                  :null
                }
                {
                  props.user?.id === "/api/users/1"?
                  <DataCard>
                  <OurTypography fontweighttext={700} fontsize={12} style={{ marginBottom: '10px' }}>En cours</OurTypography>
                  <OurTypography fontweighttext={700} fontsize={36} style={{ marginBottom: '15px' }}>{tasks ?tasks?.edges.filter(e=>e.node.taskState === 1).length:null}</OurTypography>
                </DataCard>
                  :null
                }
                {
                  props.user?.id !== "/api/users/1"?
                  <DataCard>
                  <OurTypography fontweighttext={700} fontsize={12} style={{ marginBottom: '10px' }}>En cours</OurTypography>
                  <OurTypography fontweighttext={700} fontsize={36} style={{ marginBottom: '15px' }}>{tasks ?tasks?.edges.filter(e=>e.node.taskState === 1&&e.node.user?.id === props.user.id).length:null}</OurTypography>
                </DataCard>
                  :null
                }          
                {
                  props.user?.id === "/api/users/1"?
                  <DataCard>
                  <OurTypography fontweighttext={700} fontsize={12} style={{ marginBottom: '10px' }}>En recette</OurTypography>
                  <OurTypography fontweighttext={700} fontsize={36} style={{ marginBottom: '15px' }}>{tasks ?tasks?.edges.filter(e=>e.node.taskState === 2 ).length:null}</OurTypography>
                </DataCard>
                  :null
                }               
                {
                  props.user?.id !== "/api/users/1"?
                  <DataCard>
                  <OurTypography fontweighttext={700} fontsize={12} style={{ marginBottom: '10px' }}>En recette</OurTypography>
                  <OurTypography fontweighttext={700} fontsize={36} style={{ marginBottom: '15px' }}>{tasks ?tasks?.edges.filter(e=>e.node.taskState === 2&&e.node.user?.id === props.user.id ).length:null}</OurTypography>
                </DataCard>
                  :null
                }               

{
                  props.user?.id === "/api/users/1"?
                  <DataCard>
                  <OurTypography fontweighttext={700} fontsize={12} style={{ marginBottom: '10px' }}>Résolu</OurTypography>
                  <OurTypography fontweighttext={700} fontsize={36} style={{ marginBottom: '15px' }}>
                    {tasks ?tasks?.edges.filter(e=>e.node.taskState === 3 ).length:null}
                    </OurTypography>
                </DataCard>
                  :null
                }            
                   
                   {
                  props.user?.id !== "/api/users/1"?
                  <DataCard>
                  <OurTypography fontweighttext={700} fontsize={12} style={{ marginBottom: '10px' }}>Résolu</OurTypography>
                  <OurTypography fontweighttext={700} fontsize={36} style={{ marginBottom: '15px' }}>
                    {tasks ?tasks?.edges.filter(e=>e.node.taskState === 3&&e.node.user?.id === props.user.id ).length:null}
                    </OurTypography>
                </DataCard>
                  :null
                }          
              </Grid>
            </Grid>
          </Grid>
        </AccordionCustom>
      </Box>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
      startLoading: () => dispatch({ type: START_LOADING }),
      stopLoading: () => dispatch({ type: STOP_LOADING }),
      snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
  }
}

const mapStateToProps = state => {
  return {
      loading: state.loading,
      products: state.products,
      attributes: state.attributes,
      attributeGroups: state.attributeGroups,
      locales: state.locales,
      user:state.user
  };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardActivities))));