import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import * as formHelper from '../../../../js/helpers/form'
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { Box, Grid, MenuItem, Select, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import crosssellEdit from './config/crosssell/crosssellEdit.config';
import { eventService } from '../../../../js/services/event.service';
import { listSettings, listMappers, perPageOptions } from './config/crosssell/listCrosssell.config';
import Listing from '../../../layouts/Listing/Listing';
import DialogContentText from '@mui/material/DialogContentText';

import Button from '../../../ui/button/Button';
import { withApollo } from '@apollo/client/react/hoc';
import { GET_ASSET_BY_ID_NAVIGATION } from '../../../../queries/assets';
import { checkRouting } from '../../../../js/utils/checkRouting';
import {
    CREATE_CROSS_SELL,
    CREATE_CROSS_SELL_PRODUCT,
    CREATE_CROSS_SELL_PRODUCT_FILTER,
    DELETE_CROSS_SELL,
    DELETE_CROSS_SELL_PRODUCT,
    DELETE_CROSS_SELL_PRODUCT_FILTER,
    UPDATE_CROSS_SELL,
    UPDATE_CROSS_SELL_PRODUCT_FILTER,
} from '../../../../queries/crossSell';
import request from '../../../../js/utils/fetch';
import styled from 'styled-components';
import SearchBar from '../../../ui/search/SearchBar';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import EmptyAsset from '../../../../assets/pictos/empty-picto/empty_assets.png';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import DialogModal from '../../../ui/dialog/DialogModal';

const CustomSelect = styled(Select)`
    width: 100%;
    border: 1px solid ${colors.grey.border};
    padding-left: 16px;
    padding-right: 16px;
    font-weight: bold;
    height: 40px;

    .MuiSelect-root{
        padding-top: 13px;
        padding-bottom: 13px;
        &:focus{
            background-color:transparent;
        }
    }
    fieldset{
        border: 0.5px solid ${colors.grey.lighter.hue700};
    }
`;

const ContainerInput = styled(Grid)`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding-top:0!important;
    padding-bottom:0!important;
`;

const LayoutCustom = styled(Box)`
    width: 100%; 
    transition: all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    height: calc(100% - 64px);
    display: grid;
    grid-template-rows: auto 1fr;

    .layout-wrapper {
        display: none;
    }
`

function EngineAssetsDetailsCrosssell(props) {
    const [asset, setAsset] = React.useState(props.match.params?.id ? props.match.params?.id : null);
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [loadedCrosssell, setLoadedCrosssell] = React.useState(false);
    const [loadedProducts, setLoadedProducts] = React.useState(false);
    const [loadedForm, setLoadedForm] = React.useState(false);
    const [editType, setEditType] = React.useState(null);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [seeErrors, setSeeErrors] = React.useState(false);
    const [reloadCrossSell, setReloadCrossSell] = React.useState(false);
    const [initProductFilters, setInitProductFilters] = React.useState([]);
    const [states, setStates] = React.useState({
        id: null,
        crosssellListSku: null,
        crosssellName: null,
        crossSellsFilters: [],
        crossSellsProductsList: [],
        crosssellStatus: true
    });
    /* Accordion filters */
    const [queryVariables, setQueryVariables] = React.useState({ // Query variables set when clicking the Clear / Filter buttons
        name: '',
        status: null
    });
    const [filters, setFilters] = React.useState({ // Local filters that change when changing filters before clicking the Clear / Filter buttons
        name: '',
        /* rootStatus: 'all', */
        status: null,
        statusValue: 'all',
    });

    useEffect(() => {
        checkRouting(props)
        initNavAsset()
    }, [])

    const initNavAsset = () => {
        let crosssellListSku = []
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${asset}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                setLoadedForm(true)
                crosssellListSku.push({
                    new: false,
                    changed: false,
                    code: '',
                    values: [],
                    operator: '',
                    // attributeType: {node: 'select'},
                    id: null,
                    checkbox: false,
                    typingChips: null,
                    showButton: false,
                })
                stateCallback('crosssellListSku', crosssellListSku)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }

    const goTo = route => {
        props.history.push(route);
    };

    const handleToggleDrawer = (stateDrawer, reset) => {
        setOpenForm(!openForm)
        if (reset) {
            resetStates()
        }
    };
    const handleToggleDialog = () => {
        setOpenDialog(!openDialog)
    };

    const handleDelete = (crossSell) => {
        handleToggleDialog()
        cleanUpCrossSell(crossSell)
    }

    const handleButtonGroupChange = (stateName, value) => {
        let getState = { ...states };
        getState[stateName] = value;
        setStates(getState);
    };

    const resetStates = () => {
        let crosssellListSku = []
        crosssellListSku.push({
            new: true,
            changed: false,
            code: '',
            values: [],
            operator: '',
            // attributeType: {node: 'select'},
            id: null,
            checkbox: false,
            typingChips: null,
            showButton: false,
        })
        setStates({
            id: null,
            crosssellListSku,
            crosssellName: null,
            crossSellsFilters: [],
            crossSellsProductsList: [],
            crosssellStatus: true
        })
    }

    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const stateCallback = (stateName, value, custom, translated, callback) => {
        let getState = { ...states };
        getState[stateName] = value?.target?.value ?? value;
        setStates(getState)
    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(errors)
    };

    const cleanUpCrossSell = (crossSell) => {
        let getState = { ...states };
        let values = null;
        let listFiltersSku = [];
        let listFilters = [];
        for (let filter of crossSell?.crossSellProductFilters.edges) {
            if (filter.node.attribute.attributeType.input === "select") {
                if (filter.node.operator === "IN" || filter.node.operator === "NOT IN") {
                    let parsing = JSON.parse(filter.node.value)
                    values = parsing.map(e => `/api/attribute-options/${e}`)
                } else {
                    values = filter.node.attributeOption.id
                }
            } else {
                if (filter.node.operator === "IN" || filter.node.operator === "NOT IN") {
                    let parsing = JSON.parse(filter.node.value)
                    values = parsing
                } else {
                    values = filter.node.value
                }
            }
            if (filter.node.step === "1") {
                listFiltersSku.push({
                    new: false,
                    changed: false,
                    code: filter.node.attribute.id,
                    values,
                    operator: filter.node.operator,
                    attributeType: {
                        node: {
                            attributeType: filter.node.attribute.attributeType,
                            attributeOptions: filter.node.attribute.attributeOptions
                        }
                    },
                    id: filter.node.id,
                    checkbox: false,
                    typingChips: null,
                    showButton: false,
                })
            } else {
                listFilters.push({
                    new: false,
                    changed: false,
                    code: filter.node.attribute.id,
                    values,
                    operator: filter.node.operator,
                    attributeType: {
                        node: {
                            attributeType: filter.node.attribute.attributeType,
                            attributeOptions: filter.node.attribute.attributeOptions
                        }
                    },
                    id: filter.node.id,
                    checkbox: false,
                    typingChips: null,
                    showButton: false,
                })
            }

        }
        getState['id'] = crossSell?.id;
        getState['crosssellName'] = crossSell?.name;
        getState['crosssellListSku'] = listFiltersSku
        getState['crossSellsFilters'] = listFilters
        getState['crosssellStatus'] = crossSell?.status;
        setStates(getState);
    }

    const editHandler = (crossSell) => {
        setEditType('edit')
        setInitProductFilters([...crossSell.crossSellProducts.edges])
        cleanUpCrossSell(crossSell)
        handleToggleDrawer('openForm');
    }
    const initForm = () => {
        setEditType('add')
        handleToggleDrawer('openForm');
    }

    const deleteMutation = async () => {
        props.startLoading();
        props.client.mutate({
            mutation: DELETE_CROSS_SELL,
            variables: { id: states.id }
        }).then(result => {
            props.stopLoading();
            props.snack(ALERT_SUCCESS, 'Cross sell supprimé avec succès');
            setReloadCrossSell(true);
            handleToggleDialog();
            // handleToggleDrawer('openForm', true);
        }).catch(error => {
            props.stopLoading();
            props.snack(ALERT_ERROR, `Impossible de supprimer le cross sell`);
            setReloadCrossSell(true);
            handleToggleDialog();
            // handleToggleDrawer('openForm', true);
        });
    }

    const deleteCrossSellProducts = () => {
        return new Promise(async (resolve, reject) => {
            for (let product of initProductFilters) {
                props.client.query({
                    query: DELETE_CROSS_SELL_PRODUCT,
                    variables: {
                        id: product.node.id,
                    },
                    fetchPolicy: 'no-cache'
                })
            }
            resolve()
        })
    }

    const handlerMutation = async () => {
        await deleteCrossSellProducts()
        let i = 0
        for (let productToAdd of states.crossSellsProductsList.filter(e => e.selected)) {
            await props.client.query({
                query: CREATE_CROSS_SELL_PRODUCT,
                variables: {
                    crossSell: states.id,
                    product: `/api/products/${productToAdd.id}`,
                    position: i
                },
                fetchPolicy: 'no-cache'
            })
            i++
        }
        props.snack(ALERT_SUCCESS, 'Produits ajoutés avec succès');
        handleToggleDrawer('openForm', true);
    }

    const handleNextStep = () => {
        let result = formHelper.handleStep('next', errors);
        setSeeErrors(!result)
        return result;
    }

    const handlerMutationStep = () => {
        const canNext = handleNextStep();

        if (canNext) {
            setEditType('add');

            let variables = null;
            if (editType === "add") {
                variables = {
                    asset: `/api/assets/${asset}`,
                    name: states.crosssellName,
                    status: states.crosssellStatus,
                }
                try {
                    props.client.query({
                        query: CREATE_CROSS_SELL,
                        variables,
                        fetchPolicy: 'no-cache'
                    }).then(async result => {
                        stateCallback('id', result.data.createCrossSell.crossSell.id)
                        props.snack(ALERT_SUCCESS, 'Cross sell ajouté avec succès');

                        let newStatesFiltersSku = { ...states }
                        for (let filter of newStatesFiltersSku.crosssellListSku) {
                            let value = null;
                            let attributeOption = null;
                            if (filter.attributeType.node.attributeType.input === "select") {
                                if (filter.operator === "IN" || filter.operator === "NOT IN") {
                                    let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                                    value = JSON.stringify(arrayRegex)
                                } else {
                                    attributeOption = filter.values
                                }
                            } else {
                                if (filter.operator === "IN" || filter.operator === "NOT IN") {
                                    value = JSON.stringify(filter.values)
                                } else {
                                    value = filter.values
                                }
                            }
                            await props.client.query({
                                query: CREATE_CROSS_SELL_PRODUCT_FILTER,
                                variables: {
                                    crossSell: result.data.createCrossSell.crossSell.id,
                                    operator: filter.operator,
                                    value,
                                    attribute: filter.code,
                                    attributeOption,
                                    step: 1
                                },
                                fetchPolicy: 'no-cache'
                            })
                            filter.new = false;
                            filter.changed = false;
                        }

                        for (let filter of newStatesFiltersSku.crossSellsFilters) {
                            let value = null;
                            let attributeOption = null;
                            if (filter.attributeType.node.attributeType.input === "select") {
                                if (filter.operator === "IN" || filter.operator === "NOT IN") {
                                    let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                                    value = JSON.stringify(arrayRegex)
                                } else {
                                    attributeOption = filter.values
                                }
                            } else {
                                if (filter.operator === "IN" || filter.operator === "NOT IN") {
                                    value = JSON.stringify(filter.values)
                                } else {
                                    value = filter.values
                                }
                            }
                            await props.client.query({
                                query: CREATE_CROSS_SELL_PRODUCT_FILTER,
                                variables: {
                                    crossSell: result.data.createCrossSell.crossSell.id,
                                    operator: filter.operator,
                                    value,
                                    attribute: filter.code,
                                    attributeOption,
                                    step: 2
                                },
                                fetchPolicy: 'no-cache'
                            }).then(
                                request(`${process.env.REACT_APP_API}/cross-sells/${result.data.createCrossSell.crossSell.id.replace('/api/cross-sells/', '')}`, 'get', null, 'multipart/form-data').then(response => {
                                    if (response.success) {
                                        setLoadedProducts(true);
                                        setReloadCrossSell(true);
                                        let getConstructionListProducts = constructProductsListElements(response.data)
                                        stateCallback('crossSellsProductsList', getConstructionListProducts)
                                    } else {
                                        setLoadedProducts(true)
                                    }
                                })
                            ).catch(error => {
                                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du cross sell');
                                console.log(error);
                            })
                            filter.new = false;
                            filter.changed = false;
                        }
                        setStates(newStatesFiltersSku)
                        props.snack(ALERT_SUCCESS, 'Filtres pour cross sell ajoutés avec succès');
                    }).catch(error => {
                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du cross sell');
                        console.log(error);
                    })
                } catch {
                    props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du cross sell');
                }
            } else {
                variables = {
                    id: states.id,
                    asset: `/api/assets/${asset}`,
                    name: states.crosssellName,
                    status: states.crosssellStatus,
                }
                try {
                    props.client.query({
                        query: UPDATE_CROSS_SELL,
                        variables,
                        fetchPolicy: 'no-cache'
                    }).then(result => {
                        props.snack(ALERT_SUCCESS, 'Cross sell modifié avec succès');
                        for (let filter of states.crosssellListSku) {
                            let value = null;
                            let attributeOption = null;
                            if (filter.attributeType.node.attributeType.input === "select") {
                                if (filter.operator === "IN" || filter.operator === "NOT IN") {
                                    let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                                    value = JSON.stringify(arrayRegex)
                                } else {
                                    attributeOption = filter.values
                                }
                            } else {
                                if (filter.operator === "IN" || filter.operator === "NOT IN") {
                                    value = JSON.stringify(filter.values)
                                } else {
                                    value = filter.values
                                }
                            }
                            if (filter.new) {
                                props.client.query({
                                    query: CREATE_CROSS_SELL_PRODUCT_FILTER,
                                    variables: {
                                        crossSell: states.id,
                                        operator: filter.operator,
                                        value,
                                        attribute: filter.code,
                                        attributeOption,
                                        step: 1
                                    },
                                    fetchPolicy: 'no-cache'
                                })
                                    .then(add => console.log('ADD FILTER_UPDATE', add))
                                    .catch(error => {
                                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du cross sell');
                                        console.log(error);
                                    })
                            } else if (filter.changed && !filter.deleted) {
                                props.client.query({
                                    query: UPDATE_CROSS_SELL_PRODUCT_FILTER,
                                    variables: {
                                        id: filter.id,
                                        crossSell: states.id,
                                        operator: filter.operator,
                                        value,
                                        attribute: filter.code,
                                        attributeOption,
                                        step: 1
                                    },
                                    fetchPolicy: 'no-cache'
                                })
                                    .then(update => console.log('UPDATE FILTER_UPDATE', update))
                                    .catch(error => {
                                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du cross sell');
                                        console.log(error);
                                    })
                            } else if (filter.deleted) {
                                props.client.query({
                                    query: DELETE_CROSS_SELL_PRODUCT_FILTER,
                                    variables: {
                                        id: filter.id,
                                    },
                                    fetchPolicy: 'no-cache'
                                })
                                    .then(deleteElement => console.log('DELETE FILTER', deleteElement))
                                    .catch(error => {
                                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du cross sell');
                                        console.log(error);
                                    })
                            }
                        }
                        for (let filter of states.crossSellsFilters) {
                            let value = null;
                            let attributeOption = null;
                            if (filter.attributeType.node.attributeType.input === "select") {
                                if (filter.operator === "IN" || filter.operator === "NOT IN") {
                                    let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                                    value = JSON.stringify(arrayRegex)
                                } else {
                                    attributeOption = filter.values
                                }
                            } else {
                                if (filter.operator === "IN" || filter.operator === "NOT IN") {
                                    value = JSON.stringify(filter.values)
                                } else {
                                    value = filter.values
                                }
                            }
                            if (filter.new) {
                                props.client.query({
                                    query: CREATE_CROSS_SELL_PRODUCT_FILTER,
                                    variables: {
                                        crossSell: states.id,
                                        operator: filter.operator,
                                        value,
                                        attribute: filter.code,
                                        attributeOption,
                                        step: 2
                                    },
                                    fetchPolicy: 'no-cache'
                                })
                                    .then(add => console.log('ADD FILTER_UPDATE', add))
                                    .catch(error => {
                                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du cross sell');
                                        console.log(error);
                                    })
                            } else if (filter.changed && !filter.deleted) {
                                props.client.query({
                                    query: UPDATE_CROSS_SELL_PRODUCT_FILTER,
                                    variables: {
                                        id: filter.id,
                                        crossSell: states.id,
                                        operator: filter.operator,
                                        value,
                                        attribute: filter.code,
                                        attributeOption,
                                        step: 2
                                    },
                                    fetchPolicy: 'no-cache'
                                })
                                    .then(update => console.log('UPDATE FILTER_UPDATE', update))
                                    .catch(error => {
                                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du cross sell');
                                        console.log(error);
                                    })
                            } else if (filter.deleted) {
                                props.client.query({
                                    query: DELETE_CROSS_SELL_PRODUCT_FILTER,
                                    variables: {
                                        id: filter.id,
                                    },
                                    fetchPolicy: 'no-cache'
                                })
                                    .then(deleteElement => console.log('DELETE FILTER', deleteElement))
                                    .catch(error => {
                                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du cross sell');
                                        console.log(error);
                                    })
                            }
                        }
                        request(`${process.env.REACT_APP_API}/cross-sells/${states.id.replace('/api/cross-sells/', '')}`, 'GET').then(response => {
                            if (response.success) {
                                setLoadedProducts(true);
                                setReloadCrossSell(true);
                                let getConstructionListProducts = constructProductsListElements(response.data)
                                stateCallback('crossSellsProductsList', getConstructionListProducts)
                            } else {
                                setLoadedProducts(true)
                            }
                        }).catch(error => {
                            console.log(error);
                        })
                    }).catch(error => {
                        console.log(error);
                    })
                } catch (e) {
                    console.log('e', e)
                    props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification du cross sell');
                }
            }
        } else {
            return canNext;
        }
    }

    const constructProductsListElements = (product) => {
        let productElements = [];
        if (product.length > 0) {
            let i = 0;
            for (let productData of product) {
                productElements.push({
                    selected: false,
                    id: productData.id,
                    name: productData.product_name,
                    sku: productData.sku,
                    position: i,
                    image: productData.product_image
                });
                i++;
            }
        }
        productElements.sort((a, b) => {
            if (a.position < b.position)
                return -1;
            if (a.position > b.position)
                return 1;
            return 0;
        });
        return productElements;
    };

    const getCrossSellCallback = () => {
        setReloadCrossSell(false)
    }

    const handleSearchByName = (e) => {
        const value = e.target.value;
        setFilters(prev => ({
            ...prev,
            name: value
        }))
    }

    const handleSearchByStatus = (e, custom) => {
        const chosenOption = e.target.value;
        setFilters(prev => ({
            ...prev,
            statusValue: chosenOption,
            status: Object.values(statusFilterOptions).find(option => option.value === chosenOption).status
        }))
    }

    const statusFilterOptions = {
        all: { value: 'all', label: 'Tous', status: null },
        active: { value: 'active', label: 'Actif', status: true },
        inactive: { value: 'inactive', label: 'Inactif', status: false },
    }

    const handleResetFilters = () => {
        setQueryVariables(prev => ({
            ...prev,
            name: '',
            status: null
        }))
        setFilters(prev => ({
            ...prev,
            name: '',
            status: null,
            statusValue: 'all'
        }))
    }

    const isFilterActive = () => filters.name !== '' || /* filters.rootStatus !== filters.statusValue */ filters.statusValue !== statusFilterOptions.all.value

    return (
        <LayoutCustom>
            <TopPanel
                icomoon={"ico-merchandising"}
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t("spread.crosssell.title")}
                subtitle={props.t("spread.crosssell.subtitle")}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                openForm={openForm}
                // currentLang     = {currentLang} 
                // handleLang      = {handleLang} 
                // locales         = {props.locales}
                handlerAdd={() => initForm()}
                textAdd={props.t("spread.crosssell.textAdd")}
                hasBorder={true}
                buttonAvailable={loadedForm}
            />
            <>
                <Grid container style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', height: '100%', position: 'relative' }}>
                    {/* Accordion of filters */}
                    {true && (
                        <AccordionCustom
                            defaultExpanded={true}
                            title={'Filtres et recherche'}
                            custompadding={'0px'}
                            style={{
                                marginBottom: '18px'
                            }}
                        >
                            <Grid container style={{ padding: '16px 19px' }} spacing={2}>
                                <Grid item xs={8} md={isFilterActive() ? 7 : 8}>
                                    <Grid container>
                                        <Grid container justifyContent='space-between'>
                                            <Typography variant='body1' style={{
                                                color: colors.black.regular,
                                                fontSize: '12px',
                                                marginBottom: '9px'
                                            }}>Recherche par nom</Typography>
                                            <Box>
                                                {/* Add Info */}
                                            </Box>
                                        </Grid>
                                        <SearchBar noIcon
                                            style={{
                                                margin: '0 auto 8px auto',
                                                width: '100%',
                                                background: 'white',                                                
                                            }}
                                            customstyle={{
                                                width: '100%',
                                                height: '40px',
                                                boxSizing: 'border-box',
                                                padding: '0px 0px 0px 16px',
                                            }}
                                            onChange={handleSearchByName} value={filters.name} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <Grid container>
                                        <Grid container justifyContent='space-between'>
                                            <Typography variant='body1' style={{
                                                color: colors.black.regular,
                                                fontSize: '12px',
                                                marginBottom: '9px',
                                                marginTop: props.windowWidth < 600 && '10px'
                                            }}>Recherche par status</Typography>
                                            <Box>
                                                {/* Add Info */}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <CustomSelect
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={filters.statusValue}
                                        onChange={handleSearchByStatus}
                                        variant="standard"
                                        disableUnderline
                                    >
                                        <MenuItem value={statusFilterOptions.all.value}>{statusFilterOptions.all.label}</MenuItem>
                                        <MenuItem value={statusFilterOptions.active.value}>{statusFilterOptions.active.label}</MenuItem>
                                        <MenuItem value={statusFilterOptions.inactive.value}>{statusFilterOptions.inactive.label}</MenuItem>
                                    </CustomSelect>
                                </Grid>
                                <Grid item container xs={12} md={isFilterActive() ? 2 : 1} justifyContent={'start'} alignItems='center'>
                                    <Box>
                                        {
                                            isFilterActive() ?
                                                <Button types="Delete" onClick={handleResetFilters} style={{ marginBottom: 0, marginRight: 8, borderRadius: 0, marginTop: 20, border: `1px solid ${colors.red.darker}` }}></Button>
                                                : null
                                        }
                                        <Button onClick={(e) => {
                                            setQueryVariables(prev => ({
                                                ...prev,
                                                name: filters.name,
                                                status: filters.status
                                            }))
                                        }}
                                        text="Filtrer" style={{ marginBottom: 0, borderRadius: 0, marginTop: 20 }}></Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionCustom>
                        // <AccordionCustom defaultExpanded>
                        //     <AccordionSummaryCustom
                        //         expandIcon={<ExpandMoreIcon />}
                        //         style={{
                        //             borderBottom: `0.5px solid ${colors.grey.lighter.hue700}`,
                        //             fontWeight: 600,
                        //         }}
                        //     >
                        //         Filtres et recherche
                        //     </AccordionSummaryCustom>
                        //     <AccordionDetailsCustom>
                        //         <Grid container style={{ padding: '16px 19px' }}>
                        //             <Grid item xs={8} md={7}>
                        //                 <Grid container>
                        //                     <Grid container justifyContent='space-between'>
                        //                         <Typography variant='body1' style={{
                        //                             color: colors.black.regular,
                        //                             fontSize: '12px',
                        //                             marginBottom: '9px'
                        //                         }}>Recherche par nom</Typography>
                        //                         <Box>
                        //                             {/* Add Info */}
                        //                         </Box>
                        //                     </Grid>
                        //                     <SearchBar noIcon
                        //                         style={{
                        //                             margin: '0 auto 8px auto',
                        //                             width: '100%',
                        //                             background: 'white',
                        //                             paddingRight: props.windowWidth >= 600 && '16px'
                        //                         }}
                        //                         customstyle={{
                        //                             width: '100%',
                        //                             height: '40px',
                        //                             boxSizing: 'border-box',
                        //                             padding: '0px 0px 0px 16px',
                        //                         }}
                        //                         onChange={handleSearchByName} value={filters.name} />
                        //                 </Grid>
                        //             </Grid>
                        //             <Grid item xs={4} md={3}>
                        //                 <Grid container>
                        //                     <Grid container justifyContent='space-between'>
                        //                         <Typography variant='body1' style={{
                        //                             color: colors.black.regular,
                        //                             fontSize: '12px',
                        //                             marginBottom: '9px',
                        //                             marginTop: props.windowWidth < 600 && '10px'
                        //                         }}>Recherche par status</Typography>
                        //                         <Box>
                        //                             {/* Add Info */}
                        //                         </Box>
                        //                     </Grid>
                        //                 </Grid>
                        //                 <CustomSelect
                        //                     labelId="demo-simple-select-label"
                        //                     id="demo-simple-select"
                        //                     value={filters.statusValue}
                        //                     onChange={handleSearchByStatus}
                        //                     variant="standard"
                        //                     disableUnderline
                        //                 >
                        //                     <MenuItem value={statusFilterOptions.all.value}>{statusFilterOptions.all.label}</MenuItem>
                        //                     <MenuItem value={statusFilterOptions.active.value}>{statusFilterOptions.active.label}</MenuItem>
                        //                     <MenuItem value={statusFilterOptions.inactive.value}>{statusFilterOptions.inactive.label}</MenuItem>
                        //                 </CustomSelect>
                        //             </Grid>
                        //             <Grid container xs={12} md={2} justifyContent={props.windowWidth >= 1250 ? (isFilterActive() ? "center" : 'flex-end') : 'flex-end'} alignItems='center'>
                        //                 <Box>
                        //                     {
                        //                         isFilterActive() ?
                        //                             <Button onClick={handleResetFilters} color={colors.red.darker} bgcolor={colors.white} bgcolorhover={colors.red.lighter} style={{ marginBottom: 0, marginRight: 8, borderRadius: 0, marginTop: 20 }}>Effacer</Button>
                        //                             : null
                        //                     }
                        //                     <Button onClick={(e) => {
                        //                         setQueryVariables(prev => ({
                        //                             ...prev,
                        //                             name: filters.name,
                        //                             status: filters.status
                        //                         }))
                        //                     }} style={{ marginBottom: 0, borderRadius: 0, marginTop: 20 }}>Filtrer</Button>
                        //                 </Box>
                        //             </Grid>
                        //         </Grid>

                        //     </AccordionDetailsCustom>
                        // </AccordionCustom>
                    )}
                    {/* Lists of cross sells */}
                    <Listing
                        label='crossSell'
                        settings={listSettings} // Component to render and its settings
                        cardProps={{
                            openForm: openForm,
                            currentLang: currentLang,
                            textButton: 'Modifier',
                            handlerButton: editHandler,
                            windowWidth: props.windowWidth,
                            toggleDeleteModal: handleDelete,
                            // toggleDuplicateModal: toggleDuplicateModal,
                        }}
                        perPageOptions={perPageOptions}
                        mappers={listMappers}
                        currentLang={currentLang}
                        identifier='crossSells'
                        // queryVariables={{
                        //     asset : this.state.assetId,
                        // }}
                        viewsOptions={{
                            current: 'card',
                            settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        pagination={true}
                        reload={reloadCrossSell}
                        listingCallback={getCrossSellCallback}
                        queryVariables={queryVariables}
                        noResultComponent={() => <EmptyCard title={props.t("spread.active_assets.crossNotConfigured")} subtitle={props.t("spread.active_assets.clickToAdd")} textButton={props.t("spread.active_assets.addCross")} onClick={() => initForm()} picto={EmptyAsset} openForm={openForm} xsImg={openForm ? 4 : 2} />}
                    />
                </Grid>
                <LayoutBuilder
                    isSublayout={false}
                    icomoon="ico-merchandising"
                    opened={openForm}
                    forClose={() => { handleToggleDrawer('openForm', true); setLoadedProducts(false) }}
                    handlerSetup={() => { }}
                    dataLayout={crosssellEdit(editType, props.attributes.product.attributes, states)}
                    drawerWidth={props.drawerWidth}
                    allState={{ ...states, loadedProducts, currentLang }}
                    stateCallback={stateCallback}
                    errorCallback={handleFormError}
                    handleButtonGroupChange={handleButtonGroupChange}
                    validateButton={true}
                    handlerMutation={handlerMutation}
                    // deleteMutation={editType === "edit" ? () => { handleToggleDialog() } : null}
                    // deleteText={'Supprimer le cross sell'}
                    stepperButtonAction={[
                        handleNextStep,
                        handlerMutationStep,
                        handleNextStep,
                        handleNextStep,
                    ]}
                    backStepperButtonAction={[
                        () => setErrors({}),
                        () => { setLoadedProducts(false); setErrors({}) },
                        () => { setLoadedProducts(false); setErrors({}) },
                        () => (setErrors({}))
                    ]}
                    stepperButtonDisabled={[null, () => states.crossSellsFilters.length === 0, null]}
                />

                <DialogModal
                    icon={true}
                    type={'delete'}
                    open={openDialog}
                    onClose={handleToggleDialog}
                    title={'Êtes-vous sûr de vouloir supprimer ce cross sell ?'}
                    primaryAction={deleteMutation}
                    secondaryAction={handleToggleDialog}
                    windowWidth={props.windowWidth}
                >
                    <DialogContentText id="alert-dialog-description">
                        Si vous supprimez ce cross sell, celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                    </DialogContentText>
                </DialogModal>
            </>
        </LayoutCustom>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsCrosssell))));