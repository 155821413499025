import { FORM } from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function userGroupsEdit(props, action = 'add', currentGroupInfos, allGroups, menuEntries, userAccess) {
    let generalInputs = () => {};

    if (action === 'edit') {
        const currentGroup = allGroups?.find(group => group.node.id === currentGroupInfos.id);
        const currentUserAccess = userAccess?.find(user => user.groupId === currentGroup?.node.id);
        // All parent and children menu entries
        const parentGroups = menuEntries?.filter(group => !group.node.parent);
        const childrenGroups = menuEntries?.filter(group => group.node.parent);

        generalInputs = () => {
            let finalInputs = [];

            const access = parentGroups?.filter(group => group.node.code !== 'shells')?.map((parent, index) => {
                // Children menu entries of the current parent
                const childrenList = childrenGroups?.filter(child => child.node.parent.id === parent.node.id);

                const clickedAccess = currentUserAccess?.access?.find(access => access.stateName === `${parent.node.code}-parent`)

                return {
                    required: false,
                    type: 'userAccess',
                    label: index === 0 ? 'Selection des accès' : '',
                    stateName: 'userAccess',
                    helper: {
                        text: 'Identifiant du groupe d\'utilisateurs',
                        link: false,
                    },
                    title: {
                        type: 'checkbox',
                        required: false,
                        // stateName: 'parent',
                        stateName: `${parent.node.code}-parent`,
                        value: [
                            {
                                value: `${parent.node.code}-parent`,
                                // value: 'allAccess',
                                isChecked: clickedAccess?.[`${parent.node.code}-parent`],
                                label: props.t(`drawer.${parent.node.code}`)
                            },
                        ]
                    },
                    children: (() => {
                        const children = childrenList?.map(child => {
                            const childWithRelations = currentGroup?.node.menuEntryRelations.edges?.find(group => group.node.menuEntry.id === child.node.id);

                            const foundAccessChild = clickedAccess?.children?.find(access => access.label === `${child.node.code}`);

                            return {
                                title: props.t(`drawer.${child.node.code}`),
                                label: props.t(`drawer.${child.node.code}`),
                                parent: `${parent.node.code}-parent`,
                                options: [
                                    {
                                        type: 'checkbox',
                                        required: false,
                                        stateName: 'write', // * Do this dynamically (label + value)
                                        parent: `${parent.node.code}-parent`,
                                        label: child.node.code,
                                        value: [
                                            {
                                                value: 'write',
                                                isChecked: foundAccessChild?.writeAccess,
                                                label: '',
                                            },
                                        ]
                                    },
                                    // {
                                    //     type: 'checkbox',
                                    //     required: false,
                                    //     stateName: 'read', // * Do this dynamically (label + value)
                                    //     parent: `${parent.node.code}-parent`,
                                    //     label: child.node.code,
                                    //     value: [
                                    //         {
                                    //             value: 'read',
                                    //             isChecked: foundAccessChild?.readAccess,
                                    //             label: ''
                                    //         },
                                    //     ]
                                    // },
                                ]
                            }
                        })
                        return children;
                    })()
                }
            });


            if (parentGroups) {
                const newAccess = [
                    {
                        required: true,
                        type: 'text',
                        label: 'Identifiant du groupe',
                        stateName: 'code',
                        helper: {
                            text: 'Identifiant du groupe d\'utilisateurs',
                            link: false,
                        },
                    },
                    ...access
                ]

                finalInputs = newAccess;
            }

            return finalInputs;
        }
    } else if (action === 'add') {
        // const currentGroup = allGroups?.find(group => group.node.id === currentGroupInfos.id);
        const currentUserAccess = userAccess?.find(user => user?.isNew);
        // All parent and children menu entries
        const parentGroups = menuEntries?.filter(group => !group.node.parent);
        const childrenGroups = menuEntries?.filter(group => group.node.parent);

        generalInputs = () => {
            let finalInputs = [];

            const access = parentGroups?.filter(group => group.node.code !== 'shells')?.map((parent, index) => {
                // Children menu entries of the current parent
                const childrenList = childrenGroups?.filter(child => child.node.parent.id === parent.node.id);

                const clickedAccess = currentUserAccess?.access?.find(access => access.stateName === `${parent.node.code}-parent`)

                return {
                    required: false,
                    type: 'userAccess',
                    label: index === 0 ? 'Selection des accès' : '',
                    stateName: 'userAccess',
                    helper: {
                        text: 'Identifiant du groupe d\'utilisateurs',
                        link: false,
                    },
                    title: {
                        type: 'checkbox',
                        required: false,
                        // stateName: 'parent',
                        stateName: `${parent.node.code}-parent`,
                        value: [
                            {
                                value: `${parent.node.code}-parent`,
                                // value: 'allAccess',
                                isChecked: clickedAccess?.[`${parent.node.code}-parent`],
                                label: props.t(`drawer.${parent.node.code}`)
                            },
                        ]
                    },
                    children: (() => {
                        const children = childrenList?.map(child => {
                            const foundAccessChild = clickedAccess?.children?.find(access => access.label === `${child.node.code}`);

                            return {
                                title: props.t(`drawer.${child.node.code}`),
                                label: props.t(`drawer.${child.node.code}`),
                                parent: `${parent.node.code}-parent`,
                                options: [
                                    {
                                        type: 'checkbox',
                                        required: false,
                                        stateName: 'write', // * Do this dynamically (label + value)
                                        parent: `${parent.node.code}-parent`,
                                        label: child.node.code,
                                        value: [
                                            {
                                                value: 'write',
                                                isChecked: foundAccessChild?.writeAccess,
                                                label: '',
                                            },
                                        ]
                                    },
                                    // {
                                    //     type: 'checkbox',
                                    //     required: false,
                                    //     stateName: 'read', // * Do this dynamically (label + value)
                                    //     parent: `${parent.node.code}-parent`,
                                    //     label: child.node.code,
                                    //     value: [
                                    //         {
                                    //             value: 'read',
                                    //             isChecked: foundAccessChild?.readAccess,
                                    //             label: ''
                                    //         },
                                    //     ]
                                    // },
                                ]
                            }
                        })
                        return children;
                    })()
                }
            });


            if (parentGroups) {
                const newAccess = [
                    {
                        required: true,
                        type: 'text',
                        label: 'Identifiant du groupe',
                        stateName: 'code',
                        helper: {
                            text: 'Identifiant du groupe d\'utilisateurs',
                            link: false,
                        },
                    },
                    ...access
                ]

                finalInputs = newAccess;
            }

            return finalInputs;
        }
    }



    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add' ? props.t("settings.userGroups.add") : props.t("settings.userGroups.edit"),
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add' ? 'créer' : 'modifier'} votre groupe`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: action === 'add' ? 'Créer ce groupe' : 'Modifier ce groupe',
        formConfig: {
            header:{
                title: props.t("settings.userGroups.editTitle")
            },
            type: FORM,
            children: [
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs: generalInputs()
                },
            ]
        }
    }
    return form;
}