import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { START_LOADING } from "../../../../js/constants/action-types";
import { Box, CircularProgress, Grid, Tooltip } from "@mui/material";
import Button from "../../../ui/button/Button";
import Typography from "../../../ui/typography/Typography";
import colors from "../../../../config/theme/colors";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import JohnDoe from "../../../../assets/images/john-doe.png";
import OurTypography from "../../../ui/typography/Typography";
import OurButton from "../../../ui/button/Button";
import { capitalize } from "lodash";
import styled from "styled-components";
import CircularProgressWithLabel from "../../../ui/data-progress/CircularProgressWithLabel";
import { isImgUrl } from "../../../../js/utils/isValidImage";

const BoxCustom = styled(Box)`
  color: ${(props) => props.statuscolor};
  position: relative;
  svg circle {
    stroke: ${(props) => props.statuscolor};
  }
  div:nth-child(2) svg circle {
    stroke: #edeeee;
  }
  p {
    color: ${(props) => props.statuscolor};
  }
`;

const CircularProgressCustom = styled(CircularProgress)`
  color: ${(props) =>
    props.value > 30
      ? props.value > 65
        ? colors.green.regular
        : "#f6d607"
      : colors.red.regular};
  margin-left: ${(props) => props.marginleft};
`;

function CardUser(props) {
  const user = props.data ? props.data : props.userProperty.node;
  const listRoles = props.listRoles;
  const userGroup = props.userGroup;
  const index = props.index;
  const [userImage, setUserImage] = useState(false)

  const [isFormOpen, setIsFormOpen] = React.useState(false);

  const handleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const getCompletedPercent = () => {
    const userData = {
      lastname: user?.lastname,
      firstname: user?.firstname ?? user?.name,
      email: user?.email,
      image: user?.image,
      phone: user?.telephone ?? user?.phone,
    };

    const values = Object.values(userData);
    const filledValues = values.filter(
      (value) => value !== null && value !== undefined && value !== ""
    );

    return Math.round((filledValues.length / values.length) * 100);
  };

  useEffect(() => {
    isImgUrl(`${process.env.REACT_APP_API_ROOT}/${user.image}`)
        .then((result) => {
            setUserImage(result)
        }).catch((err) => {
            console.log(err)
        });
}, [])

  return (
    <Grid
      key={index}
      container
      style={{
        height: "100%",
        minHeight: "235px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Grid
        item
        xs={12}
        container
        style={{ padding: 16, position: "relative", height: "100%" }}
      >
        <Grid
          item
          xs={6}
          style={{
            position: "relative",
            overflow: "hidden",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              backgroundColor: colors.grey.lighter.hue980,
              border: `1px solid ${colors.grey.lighter.hue700}`,
              height: 156,
              width: 147,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!user?.image ? (
              <OurTypography
                fontweighttext={700}
                colortext={colors.grey.lighter.hue700}
                style={{ fontSize: "75px" }}
              >
                {`${user?.firstname} ${user?.lastname ?? user?.name ?? null}`
                  .split(" ")
                  .map((w) => w[0]?.toUpperCase())
                  .splice(0, 2)}
              </OurTypography>
            ) : (
              <img
                src={
                  userImage
                    ? `${process.env.REACT_APP_API_ROOT}/${user.image}`
                    : JohnDoe
                }
                style={{
                  // height: 'auto',
                  // position: "absolute",
                  // top: "50%",
                  // left: "50%",
                  // paddingRight: '16px',
                  // transform: "translate(-50%, -50%)",
                  // width: '100%',
                  objectFit: "cover",
                  borderRadius: "50%",
                  width: 147,
                  height: 156,
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: "100%" }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{ height: "100%" }}
          >
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              style={{
                flex: "1 1 auto",
                gap: "15px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <OurTypography
                    variant="body1"
                    colortext={colors.black.regular}
                    style={{ fontSize: "20px" }}
                    fontweighttext={"900"}
                    component="p"
                  >
                    <Box
                      fontWeight="bold"
                      style={{
                        width: "100%",
                        display: "inline-block",
                        lineHeight: "25px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Tooltip
                        title={`${user?.firstname} ${
                          user?.lastname ?? user?.name
                        }`}
                      >
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            display: "block",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                          }}
                        >
                          {user?.firstname} {user?.lastname ?? user?.name}
                        </span>
                      </Tooltip>
                    </Box>
                  </OurTypography>

                  {userGroup && (
                    <OurTypography
                      variant="body1"
                      component="p"
                      style={{ fontSize: "16px", fontStyle: "italic" }}
                    >
                      <Box pb={1}>{capitalize(userGroup)}</Box>
                    </OurTypography>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={8}>
                  {
                    user.job &&
                    <OurTypography variant="body1" component="p">
                      <Box pb={1}>
                        <Box mb={!user?.job && "21px"}>Poste</Box>
                        {user.job && (
                          <Tooltip title={user.job}>
                            <span
                              style={{
                                fontWeight: 900,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                display: "block",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                              }}
                            >
                              {user.job}
                            </span>
                          </Tooltip>
                        )}
                      </Box>
                    </OurTypography>
                  }

                  {user?.email && (
                    <OurTypography variant="body1" component="p">
                      <Box pb={1}>
                        <Box>Adresse mail :</Box>
                        <Tooltip title={user.email}>
                          <span
                            style={{
                              fontWeight: 900,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              display: "block",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                            }}
                          >
                            {user.email}
                          </span>
                        </Tooltip>
                      </Box>
                    </OurTypography>
                  )}
                  {
                    user?.phone &&
                      <OurTypography variant="body1" component="p">
                        <Box pb={1}>
                          <Box mb={!user?.phone && "21px"}>
                            Numéro de téléphone :
                          </Box>
                          <Tooltip title={user?.phone && user.phone}>
                            <span
                              style={{
                                fontWeight: 900,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                display: "block",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                              }}
                            >
                              {user?.phone && user.phone}
                            </span>
                          </Tooltip>
                        </Box>
                      </OurTypography>
                  }
                </Grid>
              </Grid>
            </Grid>

            <OurButton
              style={{
                margin: 0,
                padding: "14px 32px",
                width: "100%",
                marginTop: "17px",
              }}
              icon={"edit"}
              text={"Modifier"}
              color={colors.blue.darker.hue300}
              colorHover={colors.white}
              bgcolorhover={colors.blue.lighter.hue600}
              bgcolor={colors.blue.lighter.hue900}
              border={`1px solid ${colors.blue.darker.hue300}`}
              onClick={(event) => props.handleToggleForm(event, user)}
              disabled={false}
            />
          </Grid>
        </Grid>

        <BoxCustom
          statuscolor={colors.green.regular}
          style={{
            right: "10px",
            top: "10px",
            position: "absolute",
          }}
        >
          <CircularProgressWithLabel value={getCompletedPercent()}>
            <Typography
              variant="h5"
              sx={{
                color: colors.green.regular,
                fontWeight: "bold",
              }}
            >
              {getCompletedPercent() > 0
                ? `${Math.round(getCompletedPercent())}%`
                : `N/A`}
            </Typography>
          </CircularProgressWithLabel>
        </BoxCustom>
      </Grid>

      {props.canContact ? (
        <Grid
          container
          style={{ borderTop: `1px solid ${colors.grey.lighter.hue900}` }}
          justifyContent="center"
          align="center"
        >
          <a href={`mailto: ${user?.email}`} style={{ textDecoration: "none" }}>
            <Button
              bgcolor="transparent"
              bgcolorhover="transparent"
              style={{ margin: 5 }}
              color={colors.blue.darker.hue300}
            >
              <MailOutlineIcon style={{ marginRight: 8 }} /> Contact
            </Button>
          </a>
        </Grid>
      ) : null}
    </Grid>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardUser)
);
