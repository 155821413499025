import React from 'react';
import styled from 'styled-components';

import { Box, Grid } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Typography from '../../../ui/typography/Typography';
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import CircularProgress from '@mui/material/CircularProgress';
const MediaIcon=styled(Box)`
    height:100%;
    background: transparent linear-gradient(135deg, #425E7C 0%, #15181C 100%) 0% 0% no-repeat padding-box;
    border-radius:5px;
    display:flex;
    align-items:center;
    justify-content:center;
`
const CardMediaContainer=styled(Grid)`
    cursor:pointer;
    .see-more{
        transform:translateX(0);
        transition:all .5s;
    }
    &:hover{
        .see-more{
            transform:translateX(5px);
    }
`
export default function CardMedia(props){
    const media=props.media;
    if(props.goTo){
        props.media.routeState.index=props.index+1;
        
    }
    return (

        <CardMediaContainer container direction="row" spacing={2} onClick={() => {props.goTo(media.route, media.routeState ? media.routeState : null)}}>
            <Grid item xs={3}>
                <MediaIcon>
                    <IcomoonReact iconSet={iconSet} color="white" size={35} icon={media.icon} />
                </MediaIcon>
                
            </Grid>
            <Grid item xs={6}>
                <Box py={2}>
                    <Typography variant="h3">
                        {media.title}
                    </Typography>
                    <Box variant="body2" style={{marginTop:15, display: 'flex', alignItems: 'center'}}>
                        {media.count || media.count===0 ? <Typography variant="body2">{media.count}</Typography> :
                        <CircularProgress size={'14px'}/>
                        } <Typography variant="body2" style={{marginLeft: 8}}>fichiers</Typography>
                    </Box>                    
                </Box>

            </Grid>
            <Grid item xs={3}>  
                <Box alignItems="center" justifyContent="center" display="flex" height={'100%'}>
                    <Typography variant="h3">
                           <ArrowForwardIosIcon className="see-more"></ArrowForwardIosIcon> 
                    </Typography>                    
                </Box>

            </Grid>
        </CardMediaContainer>
    )

}