import { Grid, Box, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Chip } from '@mui/material';
import request from '../../../../../js/utils/fetch';
import {  ExpandMore } from "@mui/icons-material";
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from "@mui/styles";
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';

import colors from '../../../../../config/theme/colors';
import OurTypography from '../../../../ui/typography/Typography';
import DailyStats from './DailyStats';
import StatsRealTime from './StatsRealTime';
import { spaceOnNumbers } from '../../../../../js/utils/spaceOnNumber';
import AccordionCustom from '../../../../layouts/Accordion/AccordionCustom';

const WrapperGlobalsLeft = styled(Grid)`
    width: 100%;
    max-width: 290px;
    @media screen and (max-width: 1249px){
        max-width: 100%
    }
`;

const typographyStyles = makeStyles({
    typography: {
        fontSize: 33,
        lineHeight: '44px',
        color: colors.black.regular,
        fontWeight: "bold"
    }
})


const comparisonStatsBoxStyles = makeStyles(theme => ({
    root: {
        gap: "50px",
        [theme.breakpoints.down('sm')]: {
            gap: "0px"
        },
    },
}))

const WrapperGlobalsRight = styled(Grid)`
    width: 100%;
    max-width: calc(100% - 290px);
    @media screen and (max-width: 1249px){
        margin-top: 24px;
        max-width: 100%
    }
`;

const TitleGlobals = styled(OurTypography)`
    font-size: 25px; 
    line-height: 32px; 
    margin-bottom:12px;
    @media screen and (max-width: 1249px){
        max-width: 100%;
    }
`;

const SubTitleLeftGlobals = styled(OurTypography)`
    font-size: 16px;
    line-height: 15px;
    color: ${colors.black.regular};
`;

const CustomChip = withStyles({
    root: {
      backgroundColor:props=>props.bgcolor ? "#0273A5" :"#F5F5F580",    
      color:props=>props.bgcolor ? "#fff" :"black",
      marginRight: "8px",
      "&&:hover,&&:focus": {
        backgroundColor: "#0273A5",
        color:"white",
      },     
    }
  })(Chip);


const GlobalView = (props)=>{
    const {assetId,startDate,endDate}=props    
    const [sources,setSources] =useState(null)
    const [selectedSource,setSelectedSource] =useState(null)    
    const [selectedSourceIsLoaded,setSelectedSourceIsLoaded] =useState(false)      
    const [global,setGlobal] =useState(null)
    const [lineOptions, setLineOptions] = useState(null)    
    
    const typographyClass = typographyStyles()    
    const comparisonStatsBoxClass = comparisonStatsBoxStyles()

    const capitalizeFirstLetter = (string)=> {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
        
    const getGlobal=()=>{
        request(`${process.env.REACT_APP_API}/games/stats/${assetId}/global`, 'GET').then(
            (data) => {
                if(data.success){                       
                    setGlobal(data.datas)                    
                }   
            }
        ).catch((err)=>{
            console.log(err)
        });
    }

    const getSources=()=>{
        request(`${process.env.REACT_APP_API}/games/stats/${assetId}/sources`, 'GET')
        .then(
            (data) => {
                if(data.success){                       
                    let newArray =[]
                    for (const k of Object.keys(data.datas)) {
                        let newObject = {}
                        let total = 0
                        newObject["source"] = capitalizeFirstLetter(k)
                        const newData = data.datas[k].map((item)=>{
                            const newObject = {}                            
                            const formattedDate = moment(item.date).format('ddd DD.MM')
                            newObject["date"] = formattedDate                                   
                            newObject["value"] = item.totalParticipation                                                                             
                            total += item.totalParticipation
                            return newObject                                                  
                        })
                        newObject["data"] = newData
                        newObject["total"] = total   
                        newArray.push(newObject)   
                    }                                
                    setSources(newArray)                                      
                }   
            }
        ).catch((err)=>{
            console.log(err)
        });
    }

    const getDataDate = ()=>{
        if (sources) {
            let dates=[]
            for (const s of sources) {
                if (s.source===selectedSource) {
                    for (const d of s.data) {
                        dates.push(d.date)
                    }               
                }
            }          
            return dates            
        }
    }
    const getDataValue = ()=>{
        if (sources) {
            let values=[]
            for (const s of sources) {
                if (s.source===selectedSource) {
                    for (const d of s.data) {
                        values.push(d.value)                                              
                    }              
                } 
            }           
            return values            
        }
    }

    const getDataTotal = ()=>{
        if (sources) {
            let total
            for (const s of sources) {
                if (s.source===selectedSource) {
                    total = s.total                       
                } 
            }
            return total            
        }
    }

    useEffect(()=>{        
        sources&&setSelectedSource(sources[0]?.source)                             
    },[sources])      
    
    useEffect(()=>{                
        getOptions()
    },[selectedSource])  
    

    const getOptions =  ()=>{
        setLineOptions(null)
        const data =getDataDate()        
        const options= {
            title: {
                show: data?.length === 0,
                textStyle: {
                    color: "grey",
                    fontSize: 20
                },
                text: "Aucune information",
                left: "center",
                top: "center"
            },
            xAxis: {
              type: 'category',
              data: data
            },
            yAxis: {
              type: 'value',          
            },
            series: [
              {
                data: getDataValue(),
                type: 'line'
              }
            ]
        };
        setLineOptions(options)
    }


    const handleChange = (value)=>{
      setSelectedSourceIsLoaded(false)
      try {
          setSelectedSource(value)
      } catch (error) {
          console.log(error)
      }
      finally{
          setSelectedSourceIsLoaded(true)
      }
    } 
    
    useEffect(()=>{
        getSources()
        getGlobal()             
    },[])

    return (
        <>
        <StatsRealTime stats={global}/>
        <Box style={{padding:"0px 32px 32px"}}>
            <AccordionCustom defaultExpanded={true} square={true} title={'Statistique globales'}>
                <Grid container direction="column" justifyContent="center" spacing={0}>
                    <Grid container direction="row" style={{
                        padding: '24px'
                    }}>
                        {/* Top */}
                        <Grid container style={{marginBottom:10}}>
                            <WrapperGlobalsLeft item alignItems={'center'} style={{ display: 'flex' }}>
                                <Box style={{ width: '100%' }}>
                                    <TitleGlobals variant={"h2"}>Dashboard statistiques</TitleGlobals>
                                </Box>
                            </WrapperGlobalsLeft>
                        </Grid>
                        {
                            sources
                            ?
                            <>                                                 
                                {
                                    sources.map((d, i)=>{                                                                                                                                                                                           
                                        return <span key={i}><CustomChip bgcolor={d.source === selectedSource} label={d.source} variant="outlined" onClick={()=>{handleChange(d.source)}} /></span>
                                    })
                                }
                            </>                                                                
                            :null
                        }                                                                                               
                    </Grid>
                    <Grid container direction='row'>
                        <WrapperGlobalsLeft item>
                            {
                                global?
                                <Box mt={{ md: 10 }}>
                                    <Grid container alignItems={'center'} className={comparisonStatsBoxClass.root}>
                                        {/* One */}
                                        <Grid item lg={12} md={12} xs={4} style={{ textAlign: 'center', display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
                                            <OurTypography variant="h1" component="div" className={typographyClass.typography}>
                                                {spaceOnNumbers(getDataTotal())}
                                            </OurTypography>
                                            <SubTitleLeftGlobals variant="body1">Nombre de participations</SubTitleLeftGlobals>
                                            <OurTypography variant="body1" component="div">
                                                Sur {spaceOnNumbers(global.playCount)} parties
                                            </OurTypography>
                                            <Box display={'flex'} alignItems="center" justifyContent="center">
                                                {/* {
                                                    stats?.list?.total?.totalAmount < stats?.list?.total?.totalAmountLastWeek ?
                                                        <ArrowDropDown style={{ fill: colors.red.regular }} /> :
                                                        <ArrowDropUpIcon style={{ fill: colors.green.regular }} />
                                                } */}
                                                {/* <OurTypography variant="body1" style={{ color: stats?.list?.total?.totalAmount < stats?.list?.total?.totalAmountLastWeek ? colors.red.regular : colors.green.regular }}>{(stats?.list?.total?.totalAmount - stats?.list?.total?.totalAmountLastWeek).toFixed(2)}€ / semaine</OurTypography> */}
                                            </Box>
                                        </Grid>
                                        {/* Two */}
                                        <Grid item lg={12} md={12} xs={4} style={{ textAlign: 'center', display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
                                            <OurTypography variant="h1" component="div" className={typographyClass.typography}>
                                                {global.playerCount}
                                            </OurTypography>
                                            <SubTitleLeftGlobals variant="body1">Nombre de joueurs</SubTitleLeftGlobals>
                                            <Box display={'flex'} alignItems="center" justifyContent="center">
                                                {/* {
                                                    stats?.list?.total?.orderNumber < stats?.list?.total?.orderNumberLastWeek ?
                                                        <ArrowDropDown style={{ fill: colors.red.regular }} /> :
                                                        <ArrowDropUpIcon style={{ fill: colors.green.regular }} />
                                                } */}
                                                {/* <OurTypography variant="body1" style={{ color: stats?.list?.total?.orderNumber < stats?.list?.total?.orderNumberLastWeek ? colors.red.regular : colors.green.regular }}>{(stats?.list?.total?.orderNumber - stats?.list?.total?.orderNumberLastWeek)} / semaine</OurTypography> */}
                                            </Box>
                                        </Grid>
                                        {/* Three */}
                                        <Grid item lg={12} md={12} xs={4} style={{ textAlign: 'center', display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
                                            <OurTypography variant="h1" component="div" className={typographyClass.typography}>
                                                {global.playCount}
                                            </OurTypography>
                                            <SubTitleLeftGlobals variant="body1">Nombre de parties</SubTitleLeftGlobals>
                                            <Box display={'flex'} alignItems="center" justifyContent="center">
                                                {/* {
                                                    stats?.list?.total?.cartAverage < stats?.list?.total?.cartAverageLastWeek ?
                                                        <ArrowDropDown style={{ fill: colors.red.regular }} /> :
                                                        <ArrowDropUpIcon style={{ fill: colors.green.regular }} />
                                                } */}
                                                {/* <OurTypography variant="body1" style={{ color: stats?.list?.total?.cartAverage < stats?.list?.total?.cartAverageLastWeek ? colors.red.regular : colors.green.regular }}>{(stats?.list?.total?.cartAverage - stats?.list?.total?.cartAverageLastWeek).toFixed(2)}€ / semaine</OurTypography> */}
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} md={12} xs={4} style={{ textAlign: 'center', display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
                                            <OurTypography variant="h1" component="div" className={typographyClass.typography}>
                                                {global.zeroPlayPlayer}
                                            </OurTypography>
                                            <SubTitleLeftGlobals variant="body1">Nombre de joueurs sans partie</SubTitleLeftGlobals>                                                
                                        </Grid>
                                        <Grid item lg={12} md={12} xs={4} style={{ textAlign: 'center', display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
                                            <OurTypography variant="h1" component="div" className={typographyClass.typography}>
                                                {global.onePlayPlayer}
                                            </OurTypography>
                                            <SubTitleLeftGlobals variant="body1">Nombre de joueurs ayant jouer une fois</SubTitleLeftGlobals>                                                
                                        </Grid>
                                    </Grid>
                                </Box>
                                :null
                            }
                        </WrapperGlobalsLeft>
                        <WrapperGlobalsRight item>
                            <Grid container direction="row" justifyContent='space-between'>
                                <Grid item xs={12}>
                                    <Box pt={1}>
                                        {
                                            lineOptions?
                                                <ReactEcharts option={lineOptions} />                                                                                                                                            
                                            :<CircularProgress/>
                                        }
                                    </Box>
                                </Grid>
                                <DailyStats startDate={startDate} endDate={endDate} assetId={assetId}/>                                   
                            </Grid>
                        </WrapperGlobalsRight>
                    </Grid> 
                </Grid>
            </AccordionCustom>                      
        </Box>
        </>
    )
}

export default GlobalView