import {STEPPER} from 'js/constants/form-types';
import Button from 'ui/button/Button';
import CheckboxCards from 'ui/inputs/CheckboxCards';


export default function campaignAdd(currentLang, getAllCatalogs, getAllAssets, getAllRetailers, handleMediaPicker, edit = false){
    let generalInputs  = []
    generalInputs = [
        {
            type: 'mediaPicker',
            label: 'Entête de la campagne',
            helper: {
                // text: 'Indiquez le nom',
                link: false,
            },
            required: false,
            stateName: 'campaignImage',
            handleMediaPicker : handleMediaPicker
        },
        {
            type: 'text',
            label: 'Nom',
            helper: {
                text: 'Indiquez l\'ID de la campagne',
                link: false,
            },
            required: true,
            stateName: 'campaignName',
            disabled: false,
        },
        {
            type: 'text',
            label: 'Identifiant',
            helper: {
                text: 'Indiquez l\'identifiant de la campagne',
                link: false,
            },
            required: true,
            stateName: 'campaignIdentifier',
            disabled: edit ? true : false,
        },
        {
            type: 'textarea',
            label: 'Description',
            helper: {
                text: 'Entrez ici une description',
                link: false,
            },
            required: false,
            stateName: 'campaignDescription',
        },
        {
            type: 'text',
            label: 'Google GTM',
            helper: {
                text: 'Indiquez le Google GTM de la campagne',
                link: false,
            },
            required: true,
            stateName: 'campaignGTM',
            disabled: false,
        },
    ];

    generalInputs.push(
        {
            type: 'date',
            label: 'Date de début',
            helper: {
                text: 'Date de début',
                link: false,
            },
            required: true,
            stateName: 'campaignStartAt',
        },
        {
            type: 'date',
            label: 'Date de fin',
            helper: {
                text: 'Date de fin',
                link: false,
            },
            required: true,
            stateName: 'campaignEndAt',
            minDate: 'campaignStartAt'
        },
        {
            type: 'radio',
            direction: 'row',
            label: 'Actif',
            helper: {
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'campaignStatus',
            value: [
                {
                    value: true,
                    label: 'Oui'
                },
                {
                    value: false,
                    label: 'Non'
                },
            ]
        },
        // {
        //     type: 'buttonGroup',
        //     label: 'Autosync',
        //     helper: {
        //         label: 'État',
        //         text: 'Indiquez l\'état',
        //         link: false,
        //     },
        //     required: false,
        //     stateName: 'campaignAutosync',
        //     value: [
        //         {
        //             value: true,
        //             label: 'Actif',
        //         },
        //         {
        //             value: false,
        //             label: 'Inactif',
        //         },
        //     ]
        // },
    )

    let steps = [];

    steps = steps.concat(
        [
            {
                labelName: 'Principal',
                isOptionnal: false,
                optionsInputs:
                    generalInputs
            },
            {
                labelName: 'Catalogue',
                isOptionnal: false,
                component: CheckboxCards,
                optionsInputs:
                    {
                        stateName: 'catalogSelected',
                        allInfo: true,
                        paginationType: 'catalog', 
                        required: true,
                        isEmpty: getAllCatalogs.length > 0 ? false : true,
                        value: getAllCatalogs.map((values) => {
                            return ({
                                values,
                                smallCard: true
                            });
                        })
                    }
            },
            {
                labelName: 'Retailers',
                isOptionnal: false,
                component: CheckboxCards,
                optionsInputs:
                    {
                        stateName: 'retailersSelected',
                        allInfo: true,
                        paginationType: 'retailer', 
                        required: true,
                        isEmpty: getAllRetailers.length > 0 ? false : true,
                        value: getAllRetailers.map((values) => {
                            return ({
                                values,
                                smallCard: true
                            });
                        })
                    }
            },
            {
                labelName: 'Assets',
                isOptionnal: false,
                component: CheckboxCards,
                optionsInputs:
                    {
                        stateName: 'assetsSelected',
                        allInfo: true,
                        paginationType: 'asset',
                        required: true,
                        isEmpty: getAllAssets.length > 0 ? false : true,
                        value: getAllAssets.map((values) => {
                            return ({
                                values,
                                smallCard: true
                            });
                        })
                    }
            },
        ]
    )
    var obj=
        {
            titleForm: edit ? `Modifier une campagne` : `Ajouter une campagne`,
            subTitleForm: edit ? 'Veuillez compléter les champs ci-dessous pour modifier votre campagne' : 'Veuillez compléter les champs ci-dessous pour créer votre campagne',
            // langSelect: true,
            drawerType: 'drawer',
            noForm: true,
            component: Button,
            formConfig: {
                type: STEPPER,
                finalStep: {
                    title: 'Et voilà !',
                    subtitle: edit ? 'Votre campagne est prête pour la modification' : 'Votre campagne est désormais configurée',
                    textButton: edit ? 'Modifier cette campagne' : 'Créer cette campagne'
                },
                children: steps
            },

        }    
    return obj;
}
