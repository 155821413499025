import { gql } from '@apollo/client';

//----- Récupération des assetTopMessages
/*
 Exemple de variables
  {
    "asset":"/api/assets/1",
    "statut":true,
  }
*/
export const GET_ASSET_TOP_MESSAGES = gql`
query assetTopMessages($asset:String, $statut:Boolean){
  assetTopMessages(asset:$asset, statut:$statut){
    edges{
      node{
          id
          message
          startAt
          endAt
          asset{
              id
          }
          statut
      }
    }
  }
}
`;

//----- Récupération des assets top messages
export const GET_ASSET_TOP_MESSAGES_PAGINATION = gql`
query assetTopMessages ($nbperpage: Int, $cursor: String, $cursorLast: String, $asset: String, $statut:Boolean, $name: String){
  assetTopMessages(first: $nbperpage, after: $cursor, before: $cursorLast, asset: $asset, statut:$statut, name:$name){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        name
        message
        startAt
        endAt
        statut
        asset{
          id
        }
      }
    }
  }
}
`;

//----- Ajouter un assetTopMessage
/*
 Exemple de variables
  {
    "name": "Message #1",
  	"message": "test",
  	"startAt": "2022-11-08 11:35:40",
  	"endAt": "2022-11-08 11:35:40",
    "asset" : "/api/assets/1",
    "statut" : true
  }
*/
export const ADD_ASSET_TOP_MESSAGE = gql`
mutation AddAssetTopMessage($name: String!, $message:String!, $startAt:String!, $endAt:String!, $asset:String!, $statut:Boolean!){
    createAssetTopMessage(input: {name:$name, message:$message, startAt:$startAt, endAt:$endAt, asset:$asset, statut:$statut}){
		assetTopMessage{
        	id
          	message
			      startAt
          	endAt
          	asset{
            	id
            }
          	statut
        }
    }
}
`;

//----- Editer un assetTopMessage
/*
 Exemple de variables
  {
    "id" : "/api/asset-top-messages/1",
    "name": "Message #1",
  	"message": "test",
  	"startAt": "2022-11-08 11:35:40",
  	"endAt": "2022-11-08 11:35:40",
    "statut" : true
  }
*/
export const UPDATE_ASSET_TOP_MESSAGE = gql`
mutation UpdateAssetTopMessage($id: ID!, $name: String!, $message:String, $startAt:String, $endAt:String, $statut:Boolean){
  updateAssetTopMessage(input: {id:$id, name:$name, message:$message, startAt:$startAt, endAt:$endAt, statut:$statut}){
  assetTopMessage{
      id
      message
      startAt
      endAt
      asset{
        id
      }
      statut
    }
  }
}
`;

//----- Supprimer un assetTopMessage
/*
 Exemple de variables
  {
    "id": "api/asset-top-messages/1"
  }
*/
export const DELETE_ASSET_TOP_MESSAGE = gql`
mutation DeleteAssetTopMessage($id: ID!){
	deleteAssetTopMessage(input: {id:$id}){
    assetTopMessage{
      id
    }
  }  
}
`;