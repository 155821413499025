import React from 'react';

import { withRouter } from 'react-router';
import { connect } from "react-redux";
import ReactEcharts from 'echarts-for-react';
import colors from '../../../config/theme/colors';
import { withTranslation } from 'react-i18next';

class LineCharts extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            xAxisData: this.props.xAxisData,
            yAxisData: this.props.yAxisData,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.xAxisData !== prevProps.xAxisData || this.props.yAxisData !== prevProps.yAxisData) {
            this.setState({ 
                xAxisData: this.props.xAxisData??[], 
                yAxisData: this.props.yAxisData??[] 
            });
        }
    }

    getOption() {
        var options = {
            title: {
                textStyle: {
                    fontSize: 20,
                    color:colors.black.regular,
                },
                text: this.props.title,
                subtext: this.props.subtext,
            },
            grid: {
                top: this.props.noLegend ? this.props.fullHeight ? 35 : 50 : 70,
                left: this.props.fullWidth ? 0:10,
                bottom: 0,
                right: this.props.fullWidth ? 0:20,
                containLabel: true,
            },
            toolbox: {
                show: true,
                showTitle: true,
                right:this.props.fullWidth ? 0:20,
                top:0,
                feature: {
                    saveAsImage: {
                        show: this.props.noExport ? false : true,
                        title: this.props.t("dashboard.exportStats"),
                        name:this.props?.titleExport,
                        icon: 'path://M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z',
                        iconStyle: {     
                            color: colors.blue.darker.hue300,
                            borderWidth:0,
                        },
                        emphasis: {
                            iconStyle: {
                                textPosition: "left",
                                // color:"#000"
                            }
                        }
                    }
                },
                // tooltip: { // same as option.tooltip
                //     show: true,
                //     formatter: function (param) {
                //         return '<div>' + param.title + '</div>'; // user-defined DOM structure
                //     },
                //     position: 'left',
                //     backgroundColor: colors.blue.darker.hue300,
                //     textStyle: {
                //         fontSize: 14,
                //         color: colors.white,
                //     },
                //     extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);' // user-defined CSS styles
                // }
            },
            tooltip: {
                trigger: 'axis',
                position: 'top',
            },
            legend: {
                show: this.props.noLegend ? false : true,
                type: 'scroll',
                align: 'left',
                icon: 'line',
                // right: '0',
                padding: [
                    0,  // up
                    0, // right
                    0,  // down
                    0, // left
                ],
                center: 0,
                // top: "12%",
                itemGap: 30,
                textStyle: {
                    fontSize: 16,
                    fontFamily: 'Soleil'
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: this.props.fullWidth ? true:false ,
                data: this.state.xAxisData,                                     
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: colors.grey.lighter.hue900,
                    }
                },
                axisLabel: {
                    formatter: function (value) {
                        return value.split(" ").join("\n");
                    },
                    fontWeight: "bold",
                    lineHeight: 16,
                    color:colors.black.regular,
                }
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: colors.grey.lighter.hue900
                    }
                },
                axisLabel: {
                    // formatter: function (value) {
                    //     return value.split(" ").join("\n");
                    // },
                    // fontWeight: "bold",
                    // lineHeight: 16,
                    color:colors.black.regular,
                }

            },
            // dataZoom: [
            //     {
            //         type: 'inside',
            //         zoomLock: true,
            //         start: 0,
            //         end: 100
            //     },
            //     {
            //         start: 0,
            //         end: 10
            //     }
            // ],
            series: this.state.yAxisData.map(serie => {
                let getConfig = {
                    name: serie.title,
                    type: 'line',
                    smooth: false,
                    sampling: 'average',
                    itemStyle: {
                        color: serie.color
                    },
                    data: serie.data
                }
                if (this.props.showArea) {
                    getConfig.lineStyle = {
                        width: 0,
                    }
                    getConfig.areaStyle = {
                        origin: 'start',
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0.15, color: serie.color // color at 0% position
                            }, {
                                offset: 1, color: '#FFFFFF' // color at 100% position
                            }],
                            global: false // false by default
                        }
                    }
                }
                return getConfig
            })
        };

        return options

    }

    render() {
        return (
            <div>
                <ReactEcharts option={this.getOption()} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
    };
};

export default withRouter(connect(mapStateToProps, null)(withTranslation()(LineCharts)));
