import React from "react";
import { Grid, Typography } from "@mui/material";
import colors from "@/config/theme/colors";
import PropTypes from "prop-types";

const AccordionHeader = ({ title, actionLabel, actionHandler }) => {
  return (
    <Grid container justifyContent="space-between">
      <Typography
        sx={{
          color: colors.black.lighter,
        }}
      >
        {title}
      </Typography>
      {actionHandler && actionLabel && (
        <Typography
          sx={{
            color: colors.blue.darker.hue300,
            fontWeight: 700,
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={(e) => {
            e.stopPropagation();
            actionHandler();
          }}
        >
          {actionLabel}
        </Typography>
      )}
    </Grid>
  );
};

AccordionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actionLabel: PropTypes.string,
  action: PropTypes.func,
};

export default AccordionHeader;
