import React, { useEffect } from 'react';
import { Box, Grid, MenuItem, Select } from '@mui/material';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import { withApollo } from '@apollo/client/react/hoc';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import '../../../navigation/DrawerLeft.scss';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import { listSettings, listMappers, perPageOptions, filtersOptions } from './config/topMessage/listTopMessage.config';
import Listing from '../../../layouts/Listing/Listing';
import * as moment from 'moment';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { DELETE_ASSET_TOP_MESSAGE } from '../../../../queries/asset_top_message';
import topMessageEdit from './config/topMessage/topMessageEdit.config';
import { eventService } from '../../../../js/services/event.service';
import { GET_ASSET_BY_ID_NAVIGATION } from '../../../../queries/assets';
import { saveElement, updateElement } from '../../../../js/utils/functions';
import DialogModal from '../../../ui/dialog/DialogModal';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import SearchBar from '../../../ui/search/SearchBar';
import Button from '../../../ui/button/Button';
import styled from 'styled-components';
import OurTypography from '../../../ui/typography/Typography';
import EmptyAsset from '../../../../assets/pictos/empty-picto/empty_assets.png';
import EmptyCard from '../../../ui/empty-card/EmptyCard';

const SelectCustom = styled(Select)`
    .MuiSelect-outlined{
        padding: 12px 10px;
    }
`

function EngineAssetsDetailsTopMessages(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [searchStatusLocal, setSearchStatusLocal] = React.useState("-1");
    const [searchNameLocal, setSearchNameLocal] = React.useState(null);
    const [searchStatus, setSearchStatus] = React.useState("-1");
    const [searchName, setSearchName] = React.useState(null);
    const [editType, setEditType] = React.useState(null);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [reloadTopMessages, setReloadTopMessages] = React.useState(false);
    const [states, setStates] = React.useState({
        topMessageId: null,
        topMessageName: null,
        topMessageStartAt: null,
        topMessageEndAt: null,
        isActive: true,
        topMessageContent: true,
    });

    const initForm = () => {
        setEditType('add');

        let getState = { ...states };
        getState['topMessageId'] = null;
        getState['topMessageName'] = null;
        getState['topMessageStartAt'] = null;
        getState['topMessageEndAt'] = null;
        getState['isActive'] = true;
        getState['topMessageContent'] = null;

        setStates(getState);

        handleToggleDrawer('openForm');
    }

    useEffect(() => {
        checkRouting(props)
        setLoading(false)
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
            initNavAsset()
        }
    }, []);

    const initNavAsset = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }

    const editHandler = (topMessage) => {
        setEditType('edit')
        let getState = { ...states };
        getState['topMessageId'] = topMessage.id;
        getState['topMessageName'] = topMessage.name;
        getState['topMessageStartAt'] = moment(topMessage.startAt).format('YYYY-MM-DD');
        getState['topMessageEndAt'] = moment(topMessage.endAt).format('YYYY-MM-DD');
        getState['isActive'] = topMessage.status;
        getState['topMessageContent'] = topMessage.message;

        setStates(getState);
        handleToggleDrawer('openForm');
    }

    const getTopMessageCallback = () => {
        setReloadTopMessages(false)
    }

    const stateCallback = (stateName, value, custom, translated, callback) => {
        let getState = { ...states };
        getState[stateName] = value?.target?.value ?? value;
        setStates(getState)
    };

    const handleButtonGroupChange = (stateName, value) => {
        let getState = { ...states };
        getState[stateName] = value;
        setStates(getState);
    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(errors)
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const openRemove = async (footer) => {
        let getState = { ...states };
        getState['topMessageId'] = footer.id;
        setStates(getState);
        setOpenDialog(true);
    };

    const handlerMutation = async () => {
        props.startLoading();
        let variables = null;
        if (editType === "add") {
            variables = {
                name: states.topMessageName,
                startAt: states.topMessageStartAt,
                endAt: states.topMessageEndAt,
                statut: states.isActive,
                message: states.topMessageContent,
                asset: `/api/assets/${props.match.params.id}`,
            }
            try {
                let createElement = await saveElement('assetTopMessage', variables, { enableLoad: false })
                if (createElement) {
                    props.snack(ALERT_SUCCESS, 'Message promotionnel ajouté avec succès');
                    handleToggleDrawer('openForm', false);
                    setReloadTopMessages(true);
                }
            } catch {
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du message promotionnel');
            }
        } else {
            variables = {
                id: states.topMessageId,
                name: states.topMessageName,
                startAt: states.topMessageStartAt,
                endAt: states.topMessageEndAt,
                statut: states.isActive,
                message: states.topMessageContent,
            }
            try {
                let createElement = await updateElement(states, 'assetTopMessage', variables, null, { enableLoad: false });

                if (createElement) {
                    props.snack(ALERT_SUCCESS, 'Message promotionnel modifié avec succès');
                    handleToggleDrawer('openForm', false);
                    setReloadTopMessages(true);
                }
            } catch {
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification du message promotionnel');
            }
        }
        props.stopLoading();
    };

    const deleteMutation = async () => {
        props.startLoading();
        await props.client.mutate({
            mutation: DELETE_ASSET_TOP_MESSAGE,
            variables: { id: states.topMessageId }
        }).then(result => {
            props.snack(ALERT_SUCCESS, 'Message promotionnel supprimé avec succès');
        }).catch(error => {
            props.snack(ALERT_ERROR, `Impossible de supprimer le message promotionnel`);
        });
        setReloadTopMessages(true);
        setOpenDialog(false)
        props.stopLoading();
    };

    const handleToggleDrawer = (stateDrawer, reset) => {
        setOpenForm(!openForm)
    };
    const filterTopMessages = () => {
        setSearchName(searchNameLocal)
        setSearchStatus(searchStatusLocal)
    };

    return (
        <div style={{ height: 'calc(100% - 64px)', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
            <TopPanel
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t('spread.top_messages.title')}
                subtitle={props.t('spread.top_messages.subtitle')}
                handlerAdd={() => initForm()}
                textAdd={"+ Créer un message"}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <Grid container style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr', position: 'relative' }}>
                <Grid container style={{ marginTop: 16 }}>
                    {
                        !loading ? (
                            <Listing
                                label='messages promos'
                                settings={listSettings}
                                cardProps={{
                                    openForm: openForm,
                                    currentLang: currentLang,
                                    textButton: 'Modifier',
                                    handlerButton: editHandler,
                                    textButtonDelete: 'Supprimer',
                                    handlerButtonDelete: openRemove,
                                    windowWidth: props.windowWidth,
                                }}
                                perPageOptions={perPageOptions}
                                pagination={true}
                                enablePagination={true}
                                enableChangeSort={true}
                                enableFilters={true}
                                mappers={listMappers}
                                filtersOptions={filtersOptions}
                                currentLang={currentLang}
                                identifier='assetTopMessages'
                                queryVariables={{
                                    asset: `/api/assets/${props.match.params.id}`,
                                    // statut: searchStatus !== "-1" ? searchStatus === "active" ? true : false : null,
                                    // name: searchName
                                }}
                                propsToPass={props}
                                viewsOptions={{
                                    current: 'tableSimple',
                                    settings: ['tableSimple'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                }}
                                reload={reloadTopMessages}
                                listingCallback={getTopMessageCallback}
                                noResultComponent={() => <Box><EmptyCard title={props.t("spread.active_assets.promNotConfigured")} subtitle={props.t("spread.active_assets.clickToAdd")} textButton={props.t("spread.active_assets.addProm")} onClick={() => initForm()} picto={EmptyAsset} openForm={openForm} xsImg={openForm ? 4 : 2} /></Box>}
                            />
                        ) : <PageLoader />
                    }
                </Grid>
                <LayoutBuilder
                    isSublayout={false}
                    opened={openForm}
                    forClose={() => handleToggleDrawer('openForm', true)}
                    handlerSetup={() => { }}
                    dataLayout={editType === "add" ? topMessageEdit('add') : topMessageEdit('edit')}
                    drawerWidth={"50vw"}
                    allState={states}
                    stateCallback={stateCallback}
                    errorCallback={handleFormError}
                    // // handleButtonGroupChange={this.handleButtonGroupChange}
                    validateButton={true}
                    handlerMutation={handlerMutation}
                    handleButtonGroupChange={handleButtonGroupChange}
                />
                <DialogModal
                    icon={true}
                    type='delete'
                    open={openDialog}
                    title={'Êtes-vous sûr de vouloir supprimer ce message ?'}
                    primaryAction={() => deleteMutation()}
                    secondaryAction={handleClose}
                    windowWidth={props.windowWidth}
                >
                    <OurTypography text={'Si vous supprimez ce message celui-ci ne sera plus accessible. Si vous ne l\'utilisez plus mais que vous ne souhaitez pas le supprimer, annulez la suppression et déclarer ce message comme inactif.'} colortext={colors.grey.lighter.hue600} style={{ fontSize: '16px' }} />
                </DialogModal>
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsTopMessages)))));