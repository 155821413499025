import React from "react";
import Pg from '../../../../../shared/components/CustomParagraph';
import H1 from '../../../../../shared/components/CustomH1';
import Link from '../../../../../shared/components/CustomLink';
import colors from '../../../../../../colors';

const MiddleBoxWin = (props) => {
    let { data, dotation, score, preview, inputCallback, component = null, hover, toolbarBlock, isVirtual } = props;
    let image = data.inputs['backgroundImage'].value;
    return(
        <div
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundPosition: 'bottom center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                paddingBottom: typeof image === 'string' || image?.filePath ? 30 : 10,
            }}
        >
            { !preview && hover && toolbarBlock }
            {
                !score ?
                    <img 
                        className="lot" 
                        onClick={() => inputCallback(component, 'imageDotation')} 
                        src={dotation ? dotation.p1_image : data.blocks.imageDotation.inputs.value?.value?.filePath  ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.imageDotation.inputs.value.value.filePath}` : typeof(data.blocks.imageDotation.inputs.value.value) === "string" ? data.blocks.imageDotation.inputs.value.value : "http://via.placeholder.com/300x250/EFF7FC/53839D?text=Lot"} 
                    />
                : null
            }
            {
                data.blocks.text?.inputs?.value?.value && !isVirtual ? (
                    <Pg data={data} preview={preview} component={component} block={'text'} inputCallback={inputCallback} nopadding={true} />
                ) : null
            }
            {
                data.blocks.textVirtual?.inputs?.value?.value && isVirtual ? (
                    <Pg data={data} preview={preview} component={component} block={'textVirtual'} inputCallback={inputCallback} nopadding={true} />
                ) : null
            }
            {
                data.blocks.subText?.inputs?.value?.value && !isVirtual ? (
                    <Pg data={data} preview={preview} component={component} block={'subText'} inputCallback={inputCallback} nopadding={true} />
                ) : null
            }
            {
                data.blocks.subTextVirtual?.inputs?.value?.value && isVirtual ? (
                    <Pg data={data} preview={preview} component={component} block={'subTextVirtual'} inputCallback={inputCallback} nopadding={true} />
                ) : null
            }
            {
                score?.score ?
                    <h1 
                        style={{ 
                            fontSize: data?.blocks['nameDotation']?.inputs.size?.value,
                            color: data?.blocks['nameDotation']?.inputs.color?.value,
                            textTransform: data?.blocks['nameDotation']?.inputs.textTransform?.value,
                            textAlign: data?.blocks['nameDotation']?.inputs.textAlign?.value,
                            lineHeight: typeof data?.blocks['nameDotation']?.inputs['backgroundImage']?.value === 'string' || data?.blocks['nameDotation']?.inputs['backgroundImage']?.value?.filePath ? '2': '1.2', 
                            border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null,
                        }}
                        className="lot_text"
                        dangerouslySetInnerHTML={{ __html: score?.score }}
                    />
                : 
                    null
            }
            {
                !score?.score ?
                    dotation?.p1_name ? (
                        <h1 
                            style={{ 
                                fontSize: data?.blocks['nameDotation']?.inputs.size?.value,
                                color: data?.blocks['nameDotation']?.inputs.color?.value,
                                backgroundColor: data?.blocks['nameDotation']?.inputs.backgroundColor?.value,
                                backgroundImage: typeof data?.blocks['nameDotation']?.inputs['backgroundImage']?.value === 'string' ? `url(${data?.blocks['nameDotation']?.inputs['backgroundImage']?.value})` : data?.blocks['nameDotation']?.inputs['backgroundImage']?.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${data?.blocks['nameDotation']?.inputs['backgroundImage']?.value.filePath})` : 'none',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                textTransform: data?.blocks['nameDotation']?.inputs.textTransform?.value,
                                textAlign: data?.blocks['nameDotation']?.inputs.textAlign?.value,
                                lineHeight: typeof data?.blocks['nameDotation']?.inputs['backgroundImage']?.value === 'string' || data?.blocks['nameDotation']?.inputs['backgroundImage']?.value?.filePath ? '2': '1.2', 
                                padding: typeof data?.blocks['nameDotation']?.inputs['backgroundImage']?.value === 'string' || data?.blocks['nameDotation']?.inputs['backgroundImage']?.value?.filePath ?  `10px ${data?.blocks['nameDotation']?.inputs.size?.value}px` : null,
                                border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null,
                            }}
                            dangerouslySetInnerHTML={{ __html: dotation.p1_name }}
                        />
                    ) : (
                        <H1 data={data} preview={preview} component={component} block={'nameDotation'} inputCallback={inputCallback} className="lot_text" nopadding={true} />
                    )
                : null
            }
            {
                data.blocks.text2?.inputs?.value?.value && !isVirtual ? (
                    <Pg data={data} preview={preview} component={component} block={'text2'} inputCallback={inputCallback} nopadding={true} />
                ) : null
            }
            {
                /* SUPPRIMER APRES LA FIN DU JEU LEGO EASTER 2022 */
                isVirtual ? (
                    <a
                        href="https://www.cdiscount.com/juniors/op/ha/lego/selection-jeu-concours-lego/l-12005035015.html#_his_"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{background: '#41A4E2', borderRadius: '8px', boxShadow: '0 4px 0 0 #2A77A7', color: 'white', fontSize: 16, padding: '12px 16px', margin:'0 auto 16px', width: 220, textAlign: 'center', textDecoration: 'none', textTransform: 'uppercase', display: 'block'}}
                    >
                        Je craque sans tarder 
                    </a>
                ) : null
            }
            {
                data.blocks.text2Virtual?.inputs?.value?.value && isVirtual ? (
                    <Pg data={data} preview={preview} component={component} block={'text2Virtual'} inputCallback={inputCallback} nopadding={true} />
                ) : null
            }
            {
                data.blocks.button?.inputs?.value?.value ? (
                    <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', margin: '16px 0'}}>
                        <Link data={data} preview={preview} component={component} block={'button'} inputCallback={inputCallback} nopadding={true} style={{textDecoration: 'none'}} />
                    </div>
                ) : null
            }
            {
                data.blocks.reglement.inputs.value.value ? (
                    <div>
                        <Pg data={data} preview={preview} component={component} block={'reglement'} inputCallback={inputCallback} nopadding={true} />
                    </div>
                ) : null
            }
        </div>
    )
};


export default MiddleBoxWin;