import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Grid } from "@mui/material";
import styled from "styled-components";
import colors from "@/config/theme/colors";
import { DatePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import moment from "moment";

const DatePickerCustom = styled(DatePicker)`
  margin-bottom: 16px;

  & .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid ${colors.grey.border};
    }
    &:hover fieldset {
      border: 1px solid ${colors.grey.border};
    }
    &.Mui-focused fieldset {
      border: 1px solid ${colors.grey.border};
    }
  }

  & .MuiFormHelperText-root {
    margin-left: 0px;
  }

  & .MuiInputBase-root {
    height: 45px;
    background-color: white !important;
    font-weight: bold;

    svg {
      fill: black;
    }
  }

  & .MuiInputAdornment-root {
    margin-right: 35px;
  }
`;
const OurDatePicker = ({
  error,
  label = "",
  value = "",
  lock,
  format = "DD/MM/YYYY",
  onChange = () => {},
  ...props
}) => {
  const [inputValue, setInputValue] = useState(
    moment(value).isValid() ? moment(value) : null
  );

  useEffect(() => {
    if (inputValue) onChange(inputValue.format(format));
  }, [inputValue]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePickerCustom
          label={label}
          disableToolbar
          autoOk
          invalidDateMessage={"La date est invalide"}
          variant="inline"
          format={format}
          value={inputValue}
          onKeyDown={(e) => e.preventDefault()}
          InputProps={{
            disableUnderline: true,
          }}
          onChange={(e) => {
            setInputValue(e);
          }}
          {...props}
        />
      </LocalizationProvider>
      {/* <OurLock
          lock={lock}
          style={{
            position: "absolute",
            top: "30px",
            right: 0,
          }}
        /> */}

      {error && <span className="error-label">{error}</span>}
    </>
  );
};

OurDatePicker.propTypes = {
  error: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  lock: PropTypes.bool,
};

export default OurDatePicker;
