import styled from 'styled-components';
import { Grid, Box, Accordion, AccordionSummary, AccordionDetails, Chip, LinearProgress, TextField } from '@mui/material';
import colors from '../../../../../config/theme/colors';
import Typography from '../../../../ui/typography/Typography';
import { makeStyles, withStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export const AccordionCustom = styled(Accordion)`
    // background : ${colors.grey.lighter.hue980};
    width: 100%;
    box-shadow: none;
    border: 0.5px solid #D0D0D1;
    border-radius: 0 !important;
`;
export const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 0 20px;
    border-bottom: 0.5px solid ${colors.grey.lighter.hue700};
    min-height: 40px !important;
    .MuiAccordionSummary-content{
        margin: 8px 0 !important;
        align-items: center;
    }
`;
export const AccordionDetailsCustom = styled(AccordionDetails)`
    margin: 32px !important;
    /* min-height: 200px; */
    max-height: 550px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    color: ${colors.black.regular};
    p{
        color: ${colors.black.regular};
    }
`;
export const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor === "neutral" ? colors.grey.regular : props.statuscolor === "positive" ? colors.green.regular : colors.red.regular};
    svg circle{
        stroke: ${props => props.statuscolor === "neutral" ? colors.grey.regular : props.statuscolor === "positive" ? colors.green.regular : colors.red.regular};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor === "neutral" ? colors.grey.regular : props.statuscolor === "positive" ? colors.green.regular : colors.red.regular};
    }
`;
export const TitleGlobals = styled(Typography)`
    font-size: 25px; 
    line-height: 32px; 
    @media screen and (max-width: 1249px){
        max-width: 100%;
    }
`;
export const WrapperGlobalsLeft = styled(Grid)`
    width: 100%;
    max-width: 290px;
    @media screen and (max-width: 1249px){
        max-width: 100%
    }
`;
export const SubTitleLeftGlobals = styled(Typography)`
    font-size: 16px;
    line-height: 15px;
    color: ${colors.black.regular};
`;
export const WrapperGlobalsRight = styled(Grid)`
    width: 100%;
    max-width: calc(100% - 290px);
    @media screen and (max-width: 1249px){
        margin-top: 24px;
        max-width: 100%
    }
`;
export const WrapperDoubleStats = styled(Grid)`
    width: 100%;
    max-width: calc((100% / 2) - 40px);
    @media screen and (max-width: 1249px){
        max-width: 100%
    }
`;

export const CustomChip = withStyles({
    root: {
      backgroundColor:props=>props.isFocus ? "#0273A5" :"#F5F5F580",
      color:props=>props.isFocus ? "white" :"inherit",
      "&&:hover,&&:focus": {
        backgroundColor: "#0273A5",
        color:"white"
      },     
    }
  })(Chip);

  export const TextFieldCustom = styled(TextField)`
    &.MuiTextField-root{
      height:100%;
      .MuiInput-root{
          border: 0.5px solid ${colors.grey.lighter.hue700};
          height:100%;
          &::before{
              border-bottom:none;
          }
      }
    }
    input{        
        height:100%;
        padding:0 10px;
        font-weight:bold;
    }    
`;

export const DatePickerCustom = styled(DateTimePicker)`
    &.MuiTextField-root{  
        max-width:150px;
        .MuiInputBase-root{
            height:100%;
        }
      .MuiInput-root{
          border: 0.5px solid ${colors.grey.lighter.hue700};
          height:100%;
          &::before{
              border-bottom:none;
          }
          .MuiInputAdornment-root{
            button span svg {
                fill:#000;
            }
          }
        }
        .MuiFormHelperText-root{
          position:absolute;
        }
    }
    input{        
        height:100%;
        padding:0 10px;
        caret-color:transparent;
        font-weight:bold;
    }    
`;

export const inputStyles = makeStyles({
    input: {
        border: `0.5px solid ${colors.grey.lighter.hue700}`,
        minWidth: '200px',
    }
})

export const typographyStyles = makeStyles({
    typography: {
        fontSize: 33,
        lineHeight: '44px',
        color: colors.black.regular,
        fontWeight: "bold"
    }
})

export const comparisonStatsBoxStyles = makeStyles(theme => ({
    root: {
        gap: "50px",
        // [theme.breakpoints.down('sm')]: {
        //     gap: "0px"
        // },
    },
}))

export const StyledLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: colors.blue.lighter.hue600
    },
    barColorPrimary: {
        backgroundColor: colors.blue.darker.hue300
    }
})(LinearProgress);

export 
const topItemNameStyles = makeStyles(theme => ({
    root: {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        overflow: 'hidden',
    }
}))

export const buttonCardStyles = makeStyles({
    enabled: {
        marginTop: "1%",
        width: '100%',
        color: colors.white,
        padding: "10px"
    },
    disabled: {
        marginTop: "1%",
        width: '100%',
        padding: "10px"
    }
})