import React from 'react';

export function CompoTest(props) {
  let data = props.datas;
  let block = data.blocks;
  let products = data.inputs?.products?.value;

  const getProductData = (product) => {
    let name = null;
    let image = null;

    if (!product)
      return {
        name: 'Produit',
        image: null
      };

    let allNames = product.attributes.product_name;
    name = allNames?.['fr_FR'] ?? product.sku;

    let allImages = product.attributes.product_image;
    image = allImages?.['fr_FR'] ?? null;

    return {
      image,
      name
    };
  };

  let product1 = getProductData(products?.[0]);
  let product2 = getProductData(products?.[1]);
  let product3 = getProductData(products?.[2]);
  let product4 = getProductData(products?.[3]);

  return (
    <div>
      <div dangerouslySetInnerHTML={{
        __html: `<style type="text/css">
            h1{
                color: #2B4553;
                line-height: 36px;
                font-size: 28px;
            }
    
            .name_product{
              font-family:Arial, Helvetica, sans-serif;
              font-size: 10px;
              line-height: 12px;
              text-align: center;
              margin-top: 20px;
            }
            @media screen and (max-width: 520px){
              #containerBottom td{
                font-size: 25px !important;
                line-height: 32px !important;
              }
              #containerBottom td a{
                width: 213px !important;
                font-size: 21px !important;
              }
            }
            @media screen and (max-width: 375px){
              .message_felicitation{
                font-size: 18px !important;
                line-height: 22px !important;
              }
              #containerBottom td{
                font-size: 22px !important;
                line-height: 31px !important;
              }
              #containerBottom td a{
                width: 213px !important;
                font-size: 21px !important;
              }
              #footer td{
                font-size: 14px !important;
                line-height: 20px !important;
              }
            }
        </style>`}}></div>
      <center>
        <table width="600" style={{ borderCollapse: 'collapse', width: 600 }} cellpadding="0" cellspacing="0">
          <tr>
            <td
              style={{
                backgroundColor: data?.inputs.backgroundColor.value,
                backgroundImage: typeof data?.inputs.backgroundImage.value === 'string' ? `url(${data?.inputs.backgroundImage.value})` : data?.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${data?.inputs.backgroundImage.value.filePath})` : 'none',
                backgroundSize: 'cover',
              }}
            >
              <table id="container1" width="600">
                <tr>
                  <td style={{ height: 30 }}></td>
                </tr>
                <tr>
                  <td width="8%"></td>
                  <td
                    className="cardWrapper"
                    width="84%"
                    style={{ background: block?.firstBlock.inputs.backgroundColor.value, borderRadius: block?.firstBlock.inputs.border.inputs.borderRadius.value, padding: '20px 9px' }}
                  >
                    <table width="500">
                      <tr width="500">
                        <td width="500" style={{ textAlign: 'center' }}>
                          <img
                            style={{ maxHeight: 100 }}
                            src={
                              typeof block?.logo.inputs.value.value === 'string' ?
                                block?.logo.inputs.value.value
                                :
                                block?.logo.inputs.value?.value?.filePath ?
                                  `${process.env.REACT_APP_MEDIAS}/${block?.logo.inputs.value.value.filePath}`
                                  :
                                  '/img/not-found.png'
                            }
                            alt="Logo"
                          />
                        </td>
                      </tr>
                      <tr width="500">
                        <td width="500" style={{ fontFamily: 'Arial, Helvetica, sans-serif', textAlign: block?.title.inputs.textAlign?.value, color: block?.title.inputs.color?.value, backgroundColor: block?.title.inputs.backgroundColor?.value, fontSize: `${block?.title.inputs.size?.value}px`, lineHeight: '1.2', textTransform: block?.title.inputs.textTransform?.value, fontWeight: 'bold', paddingTop: 40, paddingLeft: 50, paddingRight: 50 }}>
                          {block?.title.inputs.value.value}
                        </td>
                      </tr>
                      <tr width="500">
                        <td width="500" className="message_felicitation" style={{ fontFamily: 'Arial, Helvetica, sans-serif', textAlign: block?.subtitle.inputs.textAlign?.value, color: block?.subtitle.inputs.color?.value, backgroundColor: block?.subtitle.inputs.backgroundColor?.value, fontSize: `${block?.subtitle.inputs.size?.value}px`, lineHeight: '1.2', textTransform: block?.subtitle.inputs.textTransform?.value, fontWeight: 'bold', paddingTop: 45, paddingLeft: 50, paddingRight: 50 }}>
                          {block?.subtitle.inputs.value.value}
                        </td>
                      </tr>
                      <tr width="500">
                        <td className="message_felicitation" width="500" style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: block?.description.inputs.color.value, textAlign: block?.description.inputs.textAlign.value, textTransform: block?.description.inputs.textTransform.value, fontSize: block?.description.inputs.size.value, lineHeight: '1.2', paddingTop: 16, paddingLeft: 50, paddingRight: 50 }}>
                          {block?.description.inputs.value.value}
                        </td>
                      </tr>
                      <tr width="500">
                        <td width="500" style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: block?.titleList.inputs.color.value, textAlign: block?.titleList.inputs.textAlign.value, textTransform: block?.titleList.inputs.textTransform.value, fontSize: block?.titleList.inputs.size.value, lineHeight: '1.2', fontWeight: 'bold', paddingTop: 45, paddingLeft: 50, paddingRight: 50 }}>
                          {block?.titleList.inputs.value.value}
                        </td>
                      </tr>
                      <tr width="500">
                        <td style={{ paddingTop: 16 }}>
                          <table width="500">
                            <tr>
                              <td width="8%">
                              </td>
                              <td width="84%">
                                <table width="100%">
                                  <tr height="130">
                                    <td width="130" style={{ boxShadow: '0px 1px 9px #3333332E', width: 130, marginRight: 16, backgroundColor: 'white' }}>
                                      <table width="100%">
                                        <tr>
                                          <td style={{ textAlign: 'center' }}>
                                            <img
                                              width="130"
                                              style={{ display: "block", margin: '0 auto', maxHeight: 80, width: 'auto' }}
                                              alt="Produit 1"
                                              src={product1.image
                                                ? `${process.env.REACT_APP_MEDIAS}/${product1.image}`
                                                : "/img/not-found.png"}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="name_product" style={{ paddingTop: 16 }}>
                                            {product1.name}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td width="13"></td>
                                    <td width="130" style={{ boxShadow: '0px 1px 9px #3333332E', width: 130, marginLeft: 16, backgroundColor: 'white' }}>
                                      <table width="100%">
                                        <tr>
                                          <td style={{ textAlign: 'center' }}>
                                            <img src={product2.image
                                              ? `${process.env.REACT_APP_MEDIAS}/${product2.image}`
                                              : "/img/not-found.png"} width="130" style={{ display: 'block', margin: '0 auto', maxHeight: 80, width: 'auto' }} alt="Produit 2" />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="name_product" style={{ paddingTop: 16 }}>
                                            {product2.name}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td width="8%">
                              </td>
                            </tr>
                            <tr>
                              <td width="8%" style={{ paddingTop: 12 }}>
                              </td>
                              <td width="84%">
                                <table width="100%">
                                  <tr height="130">
                                    <td width="130" style={{ boxShadow: '0px 1px 9px #3333332E', width: 130, marginRight: 16, backgroundColor: 'white' }}>
                                      <table width="100%">
                                        <tr>
                                          <td style={{ textAlign: 'center' }}>
                                            <img src={product3.image
                                              ? `${process.env.REACT_APP_MEDIAS}/${product3.image}`
                                              : "/img/not-found.png"} width="130" style={{ display: 'block', margin: '0 auto', maxHeight: 80, width: 'auto' }} alt="Produit 3" />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="name_product" style={{ paddingTop: 16 }}>
                                            {product3.name}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td width="13"></td>
                                    <td width="130" style={{ boxShadow: '0px 1px 9px #3333332E', width: 130, marginLeft: 16, backgroundColor: 'white' }}>
                                      <table width="100%">
                                        <tr>
                                          <td style={{ textAlign: 'center' }}>
                                            <img src={product4.image
                                              ? `${process.env.REACT_APP_MEDIAS}/${product4.image}`
                                              : "/img/not-found.png"} width="130" style={{ display: "block", margin: '0 auto', maxHeight: 80, width: 'auto' }} alt="Produit 4" />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="name_product" style={{ paddingTop: 16 }}>
                                            {product4.name}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td width="8%">
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      {/* <tr width="600">
                        <td width="600" style={{ textAlign: 'center', paddingTop: 20 }}>
                          <img style={{ maxHeight: 200 }} src={typeof block?.illustrations.inputs.value.value === 'string' ? block?.illustrations.inputs.value.value : block?.illustrations.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${block?.illustrations.inputs.value.value.filePath}` : '/img/not-found.png'} alt="illustration" />
                        </td>
                      </tr> */}
                      <tr>
                        <td width="600" style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: block?.conditions.inputs.color.value, textAlign: block?.conditions.inputs.textAlign.value, textTransform: block?.conditions.inputs.textTransform.value, fontSize: block?.conditions.inputs.size.value, lineHeight: '1.2', paddingTop: 25 }}>
                          {block?.conditions.inputs.value.value}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td width="8%"></td>
                </tr>
                <tr><td style={{ height: 50 }}></td></tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{
              backgroundColor: block?.secondBlock.inputs.backgroundColor.value,
              backgroundImage: typeof block?.secondBlock.inputs.backgroundImage.value === 'string' ? `url(${block?.secondBlock.inputs.backgroundImage.value})` : block?.secondBlock.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${block?.secondBlock.inputs.backgroundImage.value.filePath})` : 'none',
              backgroundSize: 'cover',
              height: 310
            }}>
              <table id="containerBottom" width="100%" cellpadding="0" cellspacing="0">
                <tr>
                  <td>
                    <table width="100%" cellpadding="0" cellspacing="0">
                      <tr>
                        <td style={{ fontFamily: 'Arial, Helvetica, sans-serif', textAlign: block?.reassurance.inputs.textAlign.value, textTransform: block?.reassurance.inputs.textTransform.value, color: block?.reassurance.inputs.color.value, fontSize: block?.reassurance.inputs.size.value, lineHeight: '1.2', fontWeight: 'bold', height: 130, padding: '0 50px' }}>
                          {block?.reassurance.inputs.value.value}
                        </td>
                      </tr>
                      <tr><td style={{ height: 50 }}></td></tr>
                      <tr>
                        <td>
                          <table width="280" cellpadding="0" cellspacing="0" border="0" align="center" style={{ textAlign: 'center' }}>
                            <tr>
                              <td title={block?.button.inputs.value.value} width="280" align="center" valign="middle" style={{ textAlign: 'center', backgroundColor: block?.button.inputs.backgroundColor.value, textDecoration: 'none', borderRadius: block?.button.inputs.border.inputs.borderRadius.value, fontWeight: 'bold', padding: 16, cursor: 'pointer', width: 150, margin: 'auto' }}>
                                <a href={block?.button.inputs.link.value} className="button" target="__blank"
                                  style={{ color: block?.button.inputs.color.value, textDecoration: 'none', fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: block?.button.inputs.size.value, lineHeight: '1.2', margin: 'auto' }}>
                                  {block?.button.inputs.value.value}
                                </a>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr><td style={{ height: 77 }}></td></tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table id="footer" width="100%" style={{
                backgroundColor: block?.footer.inputs.backgroundColor.value,
                backgroundImage: typeof block?.footer.inputs.backgroundImage.value === 'string' ? `url(${block?.footer.inputs.backgroundImage.value})` : block?.footer.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${block?.footer.inputs.backgroundImage.value.filePath})` : 'none',
                backgroundSize: 'cover',
              }}>
                <tr>
                  <td width="100%" style={{ textAlign: block?.footerText.inputs.textAlign?.value, textTransform: block?.footerText.inputs.textTransform?.value, fontFamily: 'Arial, Helvetica, sans-serif', color: block?.footerText.inputs.color?.value, fontSize: block?.footerText.inputs.size?.value, lineHeight: '1.2', fontWeight: '500', padding: '32px 20px' }}>
                    <div dangerouslySetInnerHTML={{ __html: block?.footerText.inputs.value.value }} />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </center>
    </div>
  )
}

export const htmlBuildSuccessEmail = (datas, spread) => {

  const START = `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html lang="fr" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <!--[if gte mso 9]><xml>
    <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml><![endif]-->
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style type="text/css">
            h1{
                color: #2B4553;
                line-height: 36px;
                font-size: 28px;
            }
    
            .name_product{
              font-family:Arial, Helvetica, sans-serif;
              font-size: 10px;
              line-height: 12px;
              text-align: center;
              margin-top: 20px;
            }
            #footer a{
              color: white;
            }
            @media screen and (max-width: 520px){
              #containerBottom td{
                font-size: 25px !important;
                line-height: 32px !important;
              }
              #containerBottom td a{
                width: 213px !important;
                font-size: 21px !important;
              }
            }
            @media screen and (max-width: 375px){
              .message_felicitation{
                font-size: 18px !important;
                line-height: 22px !important;
              }
              #containerBottom td{
                font-size: 22px !important;
                line-height: 31px !important;
              }
              #containerBottom td a{
                width: 213px !important;
                font-size: 21px !important;
              }
              #footer td{
                font-size: 14px !important;
                line-height: 20px !important;
              }
            }
        </style>
      </head>
    <body>
  `;

  const END = `
      </body>
    </html>
  `;

  return `
        ${!spread ? START : ''}
        <center>
          <table width="600" style="border-collapse: collapse;width: 600px" cellpadding="0" cellspacing="0">
            <tr>
              <td 
              background="${typeof datas?.inputs.backgroundImage.value === 'string' ? `url(${datas?.inputs.backgroundImage.value})` : datas?.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas?.inputs.backgroundImage.value.filePath})` : 'none'}" 
              bgcolor="${datas?.inputs?.backgroundColor.value}" 
              valign="top" 
              style="background-color: ${datas?.inputs.backgroundColor.value}; background-image: ${typeof datas?.inputs.backgroundImage.value === 'string' ? `url(${datas?.inputs.backgroundImage.value})` : datas?.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas?.inputs.backgroundImage.value.filePath})` : 'none'};background-size:cover;">
                <!--[if gte mso 9]>
                <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width: 600px;">
                  <v:fill type="frame" src="${typeof datas?.inputs.backgroundImage.value === 'string' ? `${datas?.inputs.backgroundImage.value}` : datas?.inputs.backgroundImage.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas?.inputs.backgroundImage.value.filePath}` : 'none'}" color="#00A29D" />
                  <v:textbox style="mso-fit-shape-to-text:true" inset="0,0,0,0">
                <![endif]-->
                <table id="container1" width="600">
                <tr><td style="height: 30px"></td></tr>
                  <tr>
                    <td width="8%"></td>
                    <td 
                      className="cardWrapper" 
                      width="84%" 
                      style="
                        background: ${datas?.blocks?.firstBlock.inputs.backgroundColor.value};
                        border-radius: ${datas?.blocks?.firstBlock.inputs.border.inputs.borderRadius.value};
                        padding: 20px 9px;
                      "
                    >
                      <table width="500">
                        <tr width="500">
                          <td width="500" style="text-align: center;">
                            <img src=${typeof datas.blocks?.logo.inputs.value.value === 'string' ? datas.blocks?.logo.inputs.value.value : datas.blocks?.logo.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.logo.inputs.value.value.filePath}` : '/img/not-found.png'} />
                          </td>
                        </tr>
                        <tr width="500">
                          <td width="500" style="font-family: Arial, Helvetica, sans-serif; text-align: ${datas.blocks?.title.inputs.textAlign?.value}; color: ${datas.blocks?.title.inputs.color?.value}; background-color: ${datas.blocks?.title.inputs.backgroundColor?.value}; font-size: ${datas.blocks?.title.inputs.size?.value}px; line-height: 1.2; text-transform: ${datas.blocks?.title.inputs.textTransform?.value}; font-weight: bold; padding-top: 40px; padding-left: 50px; padding-right: 50px;">
                            ${datas?.blocks.title.inputs.value?.value}
                          </td>
                        </tr>
                        <tr width="500">
                          <td 
                            width="500" 
                            className="message_felicitation" 
                            style="font-family: Arial, Helvetica, sans-serif; text-align: ${datas.blocks?.subtitle.inputs.textAlign?.value}; color: ${datas.blocks?.subtitle.inputs.color?.value}; background-color: ${datas.blocks?.subtitle.inputs.backgroundColor?.value}; font-size: ${datas.blocks?.subtitle.inputs.size?.value}px; line-height: 1.2; text-transform: ${datas.blocks?.subtitle.inputs.textTransform?.value}; font-weight: bold; padding-top: 45px; padding-left: 50px; padding-right: 50px;"
                          >
                            ${datas?.blocks.subtitle.inputs.value?.value}
                            </td>
                        </tr>
                        <tr width="500">
                          <td 
                            className="message_felicitation" 
                            width="500" 
                            style="font-family: Arial, Helvetica, sans-serif; color: ${datas.blocks?.description.inputs.color.value}; text-align: ${datas.blocks?.description.inputs.textAlign.value}; text-transform: ${datas.blocks?.description.inputs.textTransform.value}; font-size: ${datas.blocks?.description.inputs.size.value}px; line-height: 1.2; padding-top: 16px; padding-left: 50px; padding-right: 50px;"
                          >
                            ${datas?.blocks.description.inputs.value?.value}
                          </td>
                        </tr>
                        <tr width="500">
                          <td width="500" style="font-family: Arial, Helvetica, sans-serif; color: ${datas.blocks?.titleList.inputs.color.value}; text-align: ${datas.blocks?.titleList.inputs.textAlign.value}; text-transform: ${datas.blocks?.titleList.inputs.textTransform.value}; font-size: ${datas.blocks?.titleList.inputs.size.value}px; line-height: 1.2; font-weight: bold; padding-top: 45px; padding-left: 50px; padding-right: 50px;">
                              ${datas?.blocks.titleList.inputs.value?.value}
                          </td>
                        </tr>
                        <tr width="500">
                          <td style="padding-top: 16px">
                            <table width="500">
                              <tr>
                                <td width="8%">
                                </td>
                                <td width="84%">
                                  <table width="100%">
                                    <tr height="130">
                                      <td width="130" style="box-shadow: 0px 1px 9px rgb(51 51 51 / 18%);width: 130px;margin-right: 16px;background-color: white;">
                                        <a href="%p1_link%">
                                          <table width="100%">
                                            <tr>
                                              <td style="text-align: center;">
                                                <img src="%p1_image%" width="130" style="display: block; margin: 0 auto; max-height: 80px; max-width: 100px; width: auto;">
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="name_product" style="padding-top: 16px;color:black;text-decoration: none;">
                                                  %p1_ref% - %p1_name%
                                              </td>
                                            </tr>
                                          </table>
                                        </a>
                                      </td>
                                      <td width="13"></td>
                                      <td width="130" style="box-shadow: 0px 1px 9px rgb(51 51 51 / 18%);width: 130px;margin-left: 16px;background-color: white;">
                                        <a href="%p2_link%">
                                          <table width="100%">
                                            <tr>
                                              <td style="text-align: center;">
                                                <img src="%p2_image%" width="130" style="display: block; margin: 0 auto; max-height: 80px; max-width: 100px; width: auto;">
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="name_product" style="padding-top: 16px;color:black;text-decoration: none;">
                                                  %p2_ref% - %p2_name%
                                              </td>
                                            </tr>
                                          </table>
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td width="8%">
                                </td>
                              </tr>
                              <tr>
                                <td width="8%" style="padding-top: 12px">
                                </td>
                                <td width="84%">
                                  <table width="100%">
                                    <tr height="130">
                                      <td width="130" style="box-shadow: 0px 1px 9px rgb(51 51 51 / 18%);width: 130px;margin-right: 16px;background-color: white;">
                                        <a href="%p3_link%">
                                          <table width="100%">
                                            <tr>
                                              <td style="text-align: center;">
                                                <img src="%p3_image%" width="130" style="display: block; margin: 0 auto; max-height: 80px; max-width: 100px; width: auto;">
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="name_product" style="padding-top: 16px;color:black;text-decoration: none;">
                                                  %p3_ref% - %p3_name%
                                              </td>
                                            </tr>
                                          </table>
                                        </a>
                                      </td>
                                      <td width="13"></td>
                                      <td width="130" style="box-shadow: 0px 1px 9px rgb(51 51 51 / 18%);width: 130px;margin-left: 16px;background-color: white;">
                                        <a href="%p4_link%">
                                          <table width="100%">
                                            <tr>
                                              <td style="text-align: center;">
                                                <img src="%p4_image%" width="130" style="display: block; margin: 0 auto; max-height: 80px; max-width: 100px; width: auto;">
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="name_product" style="padding-top: 16px; color:black;text-decoration: none;">
                                                  %p4_ref% - %p4_name%
                                              </td>
                                            </tr>
                                          </table>
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td width="8%">
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td 
                            width="600"
                            style="font-family: Arial, Helvetica, sans-serif; color: ${datas.blocks?.conditions.inputs.color.value}; text-align: ${datas.blocks?.conditions.inputs.textAlign.value}; text-transform: ${datas.blocks?.conditions.inputs.textTransform.value}; font-size: ${datas.blocks?.conditions.inputs.size.value}; line-height: 1.2; padding-top: 25px"
                          >
                            ${datas.blocks?.conditions.inputs.value.value}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td width="8%"></td>
                  </tr>
                  <tr><td style="height: 50px"></td></tr>
                </table>
                <!--[if gte mso 9]>
                  </v:textbox>
                </v:rect>
                <![endif]-->
              </td>
            </tr>
            <tr>
              <td
              background="${typeof datas.blocks?.secondBlock.inputs.backgroundImage.value === 'string' ? `url(${datas.blocks?.secondBlock.inputs.backgroundImage.value})` : datas.blocks?.secondBlock.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas.blocks?.secondBlock.inputs.backgroundImage.value.filePath})` : 'none'}" 
              bgcolor="#fdcf02" 
              valign="top" 
              style="background-color: ${datas.blocks?.secondBlock.inputs.backgroundColor.value}; background-image: ${typeof datas.blocks?.secondBlock.inputs.backgroundImage.value === 'string' ? `url(${datas.blocks?.secondBlock.inputs.backgroundImage.value})` : datas.blocks?.secondBlock.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas.blocks?.secondBlock.inputs.backgroundImage.value.filePath})` : 'none'};background-size:cover;height: 310px;">
              <!--[if gte mso 9]>
                <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width: 600px;">
                  <v:fill type="frame" src="${typeof datas.blocks?.secondBlock.inputs.backgroundImage.value === 'string' ? `${datas.blocks?.secondBlock.inputs.backgroundImage.value}` : datas.blocks?.secondBlock.inputs.backgroundImage.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.secondBlock.inputs.backgroundImage.value.filePath}` : 'none'}" color="#fdcf02" />
                  <v:textbox style="mso-fit-shape-to-text:true" inset="0,0,0,0">
                <![endif]-->
                <table id="containerBottom" width="100%" cellpadding="0" cellspacing="0">
                  <tr><td style="height: 50px"></td></tr>
                    <tr>
                      <td>
                        <table width="100%" cellpadding="0" cellspacing="0">
                            <tr>
                                <td style="font-family: Arial, Helvetica, sans-serif; text-align: ${datas.blocks?.reassurance.inputs.textAlign.value}; text-transform: ${datas.blocks?.reassurance.inputs.textTransform.value}; color: ${datas.blocks?.reassurance.inputs.color.value}; font-size: ${datas.blocks?.reassurance.inputs.size.value}px; line-height: 1.2; font-weight: bold; height: 130px; padding: 0 50px">
                                  ${datas.blocks?.reassurance.inputs.value.value}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                  <table width="280" cellpadding="0" cellspacing="0" border="0" align="center" style="text-align:center;">
                                    <tr>
                                        <td title="${datas.blocks?.button.inputs.value.value}" width="280" align="center" valign="middle" 
                                        style="text-align: center; background-color: ${datas.blocks?.button.inputs.backgroundColor.value}; text-decoration: none; border-radius: ${datas.blocks?.button.inputs.border.inputs.borderRadius.value}; font-weight: bold; padding: 16px; cursor: pointer; width: 150px; margin: auto;">
                                          <a href="%link%" className="button" target="__blank" 
                                          style="color: ${datas.blocks?.button.inputs.color.value}; text-decoration: none; font-weight: bold; font-family: Arial, Helvetica, sans-serif; font-size: ${datas.blocks?.button.inputs.size.value}px; line-height: 1.2; margin: auto;">
                                            ${datas.blocks?.button.inputs.value.value}
                                          </a>
                                        </td>
                                      </tr>
                                  </table>
                                </td>
                            </tr>
                        </table>
                      </td>
                    </tr>
                  <tr><td style="height: 77px"></td></tr>
                </table>
                <!--[if gte mso 9]>
                  </v:textbox>
                </v:rect>
                <![endif]-->
              </td>
            </tr>
            <tr>
              <td>
                <table 
                  id="footer" 
                  width="100%" 
                  bgcolor="#333333"
                  style="
                    background-color: ${datas.blocks?.footer.inputs.backgroundColor.value};
                    background-image: ${typeof datas.blocks?.footer.inputs.backgroundImage.value === 'string' ? `url(${datas.blocks?.footer.inputs.backgroundImage.value})` : datas.blocks?.footer.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas.blocks?.footer.inputs.backgroundImage.value.filePath})` : 'none'};
                    background-size: cover;
                  "
                  >
                  <tr>
                    <td width="100%" style="text-align: justify;font-family:Arial, Helvetica, sans-serif; color: white; font-size: 16px; line-height: 23px;font-weight: 500;padding: 32px 20px;">
                      ${datas.blocks?.footerText.inputs.value.value}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </center>
    ${!spread ? END : ''}
  `;
};

function FlipbookSuccessEmail({ data }) {
  return (
    <div dangerouslySetInnerHTML={{ __html: htmlBuildSuccessEmail(data, true) }} />
  )
}

export default FlipbookSuccessEmail;