import { STEPPER } from "@constants/form-types";
import OurButton from "@ui/button/Button";
import CheckboxAttributs from "@ui/inputs/CheckboxAttributs";



export default function exportProducts(allLangs, typeExport, t,allGroups,productNb){
    let allLanguages =[
        {
            value: 'all',
            label: 'Toutes les langues',
            disabled: true
        }
    ]
    for (let lang of allLangs){
        allLanguages.push(
            {
                value: lang.node.code,
                label: lang.node.libelle
            }
        )
    }
    // allLanguages.concat(getLanguages)
    var obj = {
        titleForm: `Export produits ${productNb ? `(${productNb})` : ''}`,
        subTitleForm: "Veuillez compléter les champs ci-dessous pour exportez vos produits",
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: OurButton,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Action réussie !',
                textButton: "Lancer l'exportation"
            },
            children: [
                {
                    labelName: "Configurations d'export",
                    isOptionnal: false,
                    optionsInputs: [
                        {
                            type: 'text',
                            label: "Nom du fichier",
                            helper: {
                                text: 'Nom du fichier',
                                link: false,
                            },
                            required: true,
                            stateName: 'exportName',
                            translated: false,
                        },
                        {
                            type: 'select',
                            label: "Langue",
                            translated: false,
                            helper: {
                                text:'Indiquez la langue des produits exportés',
                                link: false,
                            },
                            required: true,
                            stateName: 'exportLang',
                            value: allLanguages
                        },
                        {
                            type: 'select',
                            label: t('products.categories.exporter.exporttype'),
                            translated: false,
                            helper: {
                                text: t('products.categories.exporter.exporttype'),
                                link: false,
                            },
                            required: true,
                            stateName: 'exportType',
                            value: 
                                [
                                    {
                                        value : 'csv',
                                        label : '.csv', 
                                    },
                                    {
                                        value : 'xls',
                                        label : '.xls', 
                                    }
                                ]
                        },
                        {
                            type: 'select',
                            label: t('products.categories.exporter.status'),
                            translated: false,
                            helper: {
                                text: 'Sélectionnez le status produit',
                                link: false,
                            },
                            required: true,
                            stateName: 'exportStatus',
                            value: 
                                [
                                    {
                                        value : 'all',
                                        label : 'Tout', 
                                    },
                                    {
                                        value : 'active',
                                        label : 'Actif', 
                                    },
                                    {
                                        value : 'inactive',
                                        label : 'Inactif', 
                                    }
                                ]
                        },
                    ]
                },
                {
                    labelName: 'Séléction des attributs',
                    isOptionnal: false,
                    component: CheckboxAttributs,
                    optionsInputs: {
                        stateName: 'attributesSelectedExport',
                        required: true,
                        value: allGroups
                    }
                }
            ]
        }
    }
    return obj;
}