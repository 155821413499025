import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import colors from "@config/theme/colors";
import Button from "@ui/button/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TypoMarkupInput from "./TypoMarkupInput";
import TypographyCard from "./TypographyCard";
import PropsTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const DivColor = styled.div`
  border: 1px solid lightgrey !important;
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;
const DivMarkups = styled.div`
  border: 1px solid lightgrey !important;
  border-top: 0 !important;
  background: white;
  width: 100%;
  padding: 6px 16px;
  & > div:first-child {
    display: flex;
    align-items: center;
    padding: 6px 0px;
  }
`;
const SelectCustom = styled(Select)`
  &:before {
    content: inherit;
  }
  &:after {
    content: inherit !important;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 1em;
`;

const TypoPicker = ({
  typography,
  typographyMarkups,
  typographyDatas,
  typographySystem,
  listMarkups,
  listTypographiesSystem,
  defaultBaseSize = 16,
  defaultRatioSize = 1.25,
  listRatio = [1.125, 1.25, 1.333, 1.414, 1.5],
  onChange,
  onDelete,
}) => {
  const [ratio, setRatio] = useState(defaultRatioSize);
  const [edit, setEdit] = useState(false);
  const [defaultTypo, setDefaultTypo] = useState();

  const initialRender = useRef(true);

  const handleChangeTypographySystem = (event) => {
    const typographySystem = listTypographiesSystem.find(
      (typo) => typo.id === event.target.value
    );
    const newTypography = {
      ...typography,
      typographySystem: {
        id: typographySystem.id,
        identifier: typographySystem.identifier,
        libelle: typographySystem.libelle,
        value: typographySystem.value,
      },
      changed: true,
    };
    onChange(newTypography);
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      setDefaultTypo(typography);
      return;
    }
    handleChangeRatio();
  }, [ratio]);

  const handleChangeRatio = () => {
    let size = defaultBaseSize * defaultRatioSize;

    const listMarkupsSortByIdDesc = listMarkups.sort(
      (a, b) => b.id.split("/").pop() - a.id.split("/").pop()
    );
    const newTypographyDatas = listMarkupsSortByIdDesc.map((markup, index) => {
      const typoData = typographyDatas.find(
        (data) => data?.markup?.id === markup.id
      );

      size = size * ratio;

      if (size > 0 && size < 100) {
        return {
          ...typoData,
          px: Math.trunc(size * ratio),
          changed: true,
        };
      }
      if (size > 100) {
        return {
          ...typoData,
          px: 100 - (listMarkups.length - index),
          changed: true,
        };
      }
      if (size < 0) {
        return {
          ...typoData,
          px: defaultBaseSize / ratio,
          changed: true,
        };
      }
      return typoData;
    });

    const newTypography = {
      ...typography,
      changed: true,
      typographyDatas: newTypographyDatas,
    };
    onChange(newTypography);
  };

  const checkSize = (typographyData) => {
    const inputValue = parseInt(typographyData.px);

    if (typeof inputValue !== "number") inputValue = 0;

    const prevTypo = typographyDatas?.find((item) => {
      return (
        parseInt(item.markup?.id.split("/").pop()) - 1 ===
        parseInt(typographyData.markup?.id.split("/").pop())
      );
    });
    const min = prevTypo ? parseInt(prevTypo.px) + 1 : 10;
    const nextTypo = typographyDatas.find((item) => {
      return (
        parseInt(item.markup?.id.split("/").pop()) + 1 ===
        parseInt(typographyData.markup?.id.split("/").pop())
      );
    });
    const max = nextTypo ? parseInt(nextTypo.px) - 1 : 100;
    let newVal = inputValue;

    if (inputValue < min || inputValue < 10) {
      newVal = min;
    } else if (inputValue > max || inputValue > 100) {
      newVal = max;
    } else if (!inputValue) {
      newVal = min;
    }

    if (newVal !== inputValue) {
      const newTypo = typographyDatas.map((item) => {
        if (item.markup?.id === typographyData.markup?.id) {
          return {
            ...item,
            px: newVal.toString(),
            changed: true,
          };
        }
        return item;
      });

      const newTypography = {
        ...typography,
        typographyDatas: newTypo,
        changed: true,
      };
      onChange(newTypography);
    }
  };

  const handleSizeChange = (newVal, typographyData) => {
    if (typeof newVal !== "string") newVal = "";

    const newTypo = typographyDatas.map((item) => {
      if (item.markup?.id === typographyData.markup?.id) {
        return {
          ...item,
          px: newVal,
          changed: true,
        };
      }
      return item;
    });

    const newTypography = {
      ...typography,
      typographyDatas: newTypo,
      changed: true,
    };
    onChange(newTypography);
  };

  return (
    typography &&
    (edit ? (
      <div style={{ width: "100%", position: "relative" }}>
        <div>
          <DivColor
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <FormControl sx={{ padding: "10px" }}>
              <SelectCustom
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typographySystem.id}
                onChange={(e) => handleChangeTypographySystem(e)}
                disableUnderline={true}
                variant="standard"
              >
                {listTypographiesSystem.map((typoSystem, index) => (
                  <MenuItem
                    value={typoSystem.id}
                    key={`typopicker-select-${index}`}
                  >
                    {typoSystem.libelle}
                  </MenuItem>
                ))}
              </SelectCustom>
            </FormControl>
            {/* TODO:  gestion des ratios, à voir avec Julien

            <FormControl
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <LinkIcon
                style={{
                  marginRight: 10,
                  color: isLink
                    ? colors.blue.darker.hue300
                    : colors.black.regular,
                }}
                onClick={() => setIsLink(!isLink)}
              />
              <SelectCustom
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={ratio}
                onChange={(e) => setRatio(e.target.value)}
              >
                {listRatio.map((ratio, index) => (
                  <MenuItem value={ratio} key={`typopicker-ratio-${index}`}>
                    {ratio}
                  </MenuItem>
                ))}
              </SelectCustom>
            </FormControl> */}
          </DivColor>
          <DivMarkups>
            {listMarkups && (
              <>
                {listMarkups.map((markup, index) => {
                  const typographyData = typographyDatas.find(
                    (data) => data?.markup?.id === markup.id
                  );
                  markup.libelle =
                    markup.libelle.toLowerCase() == "paragraphe"
                      ? "P"
                      : markup.libelle;
                  markup.libelle =
                    markup.libelle.toLowerCase() == "note"
                      ? "N"
                      : markup.libelle;

                  return (
                    <TypoMarkupInput
                      key={`typopicker-markup-${index}`}
                      label={markup.libelle}
                      fontFamily={typographySystem.value}
                      value={typographyData?.px ?? ""}
                      minHeight={
                        typographyMarkups[0] * 1.3 > 50
                          ? typographyMarkups[0] * 1.3
                          : 50
                      }
                      onChange={(newVal) =>
                        handleSizeChange(newVal, typographyData)
                      }
                      onBlur={() => checkSize(typographyData)}
                    />
                  );
                })}

                <ButtonsContainer>
                  <Button
                    onClick={() => {
                      // onDelete(typography);
                      onChange(defaultTypo);
                      setEdit(false);
                    }}
                    bgcolor={colors.white}
                    bgcolorhover={colors.white}
                    color={colors.red.regular}
                    colorhover={colors.red.darker}
                    fontsize={12}
                  >
                    Annuler
                  </Button>
                  <Button
                    onClick={() => setEdit(false)}
                    bgcolor={colors.white}
                    bgcolorhover={colors.blue.darker.hue300}
                    color={colors.blue.darker.hue300}
                    colorhover={colors.white}
                    border={`1px solid ${colors.blue.darker.hue300}`}
                    fontsize={14}
                  >
                    Valider
                  </Button>
                </ButtonsContainer>
              </>
            )}
          </DivMarkups>
        </div>
      </div>
    ) : (
      <CardContainer>
        <div style={{ width: "100%" }} onClick={() => setEdit(true)}>
          <TypographyCard
            typographySystem={{
              label: typographySystem.libelle,
              value: typographySystem.value,
            }}
            typographyDatas={typographyDatas}
            listMarkups={listMarkups}
          />
        </div>
        <IconButton
          sx={{
            backgroundColor: "white",
            border: "1px solid lightgrey",
            borderRadius: "100%",
            height: "35px",
            width: "35px",
          }}
          aria-label="delete"
          onClick={() => onDelete(typography)}
        >
          <CloseIcon />
        </IconButton>
      </CardContainer>
    ))
  );
};

TypoPicker.propTypes = {
  typography: PropsTypes.shape({
    id: PropsTypes.string,
    tempoId: PropsTypes.string,
  }),
  typographyMarkups: PropsTypes.arrayOf(
    PropsTypes.shape({
      id: PropsTypes.string.isRequired,
    })
  ),
  typographyDatas: PropsTypes.arrayOf(
    PropsTypes.shape({
      id: PropsTypes.string,
      markup: PropsTypes.shape({
        id: PropsTypes.string,
      }),
      px: PropsTypes.string.isRequired,
    })
  ),
  typographySystem: PropsTypes.shape({
    id: PropsTypes.string.isRequired,
    identifier: PropsTypes.string,
    libelle: PropsTypes.string.isRequired,
    value: PropsTypes.string.isRequired,
  }),
  listMarkups: PropsTypes.arrayOf(
    PropsTypes.shape({
      id: PropsTypes.string.isRequired,
      libelle: PropsTypes.string.isRequired,
    })
  ),
  listTypographiesSystem: PropsTypes.arrayOf(
    PropsTypes.shape({
      id: PropsTypes.string.isRequired,
      identifier: PropsTypes.string,
      libelle: PropsTypes.string.isRequired,
      value: PropsTypes.string.isRequired,
    })
  ),
  defaultBaseSize: PropsTypes.number,
  defaultRatioSize: PropsTypes.number,
  listRatio: PropsTypes.arrayOf(PropsTypes.number),
  onChange: PropsTypes.func,
  onDelete: PropsTypes.func,
};

export default TypoPicker;
