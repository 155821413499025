import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Button from '../../../../shared/components/CustomButton';
import H1 from '../../../../shared/components/CustomH1';
import Pg from '../../../../shared/components/CustomParagraph';
import Div from '../../../../shared/components/CustomDiv';
import request from "../../../../../tools/Fetch";

const GameRain = (props) => {
    const [hover, setHover] = useState(false);
    const [counterScore, setCounterScore] = useState(0);
    const [timerStatus, setTimerStatus] = useState(false);
    const [timer, setTimer] = useState(0);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [hidePopup, setHidePopup] = useState(false);
    const [listItems, setListItems] = useState([]);
    const [endGame, setEndGame] = useState(false);
    const [isWin, setIsWin] = useState(true);

    const VIDEOS     = ["video/mpeg","video/webm","video/mp4"];

    let { 
        preview = false,
        id = null, 
        data,
        inputCallback = null,
        spread = false
    } = props;

    let image = data.inputs['backgroundImage'].value;
    let image2 = data.blocks.middleBox.inputs['backgroundImage'].value;

    let border;
    if (data.blocks.middleBox.inputs.border) {
        border = data.blocks.middleBox.inputs.border;
    }
    

    function startGame(){
        setHidePopup(true)
        setTimerStatus(true)
        setTimeout(function(){
            var ele = document.getElementsByClassName('image_drop');
            var blockHeight = document.getElementById('game_rain').offsetHeight;
            for (var i = 0; i < ele.length; i++ ) {
                ele[i].style.top = `${blockHeight + 30}px`;
            }
        }, 100);
        setTimeout(function(){
            if (props.spread)
                return;

            let dataForm = new FormData();
            dataForm.append('username', localStorage.getItem('username'));
            dataForm.append('token', process.env.REACT_APP_ASSET_TOKEN);
            try {
                request(`${process.env.REACT_APP_API}/public/asset/games/instant/registration/has_win`, 'post', dataForm, 'multipart/form-data')
                .then(response => {
                    if (response.code === "has_win"){
                        setIsWin(true);
                        localStorage.setItem('dotation', JSON.stringify(response.productData));
                        localStorage.setItem('isVirtual', JSON.stringify(response.isVirtual));

                        setEndGame(true);
                        let video = document.getElementById('result-video');
                        if (video){
                            video.play()
                            video.onended = function(){
                                window.location = '/win';
                            }
                        }else{
                            setTimeout(function(){
                                window.location = '/win';
                            }, 3000);
                        }
                    }else if(response.code === "has_lost"){
                        setIsWin(false);

                        setEndGame(true);
                        let video = document.getElementById('result-video');
                        if (video){
                            video.play()
                            video.onended = function(){
                                window.location = '/loose';
                            }
                        }else{
                            setTimeout(function(){
                                window.location = '/loose';
                            }, 3000);
                        }
                    }else if (response.code === "already_played_today"){
                        window.location = '/already-played';
                    }
                }).catch(e => {
                    console.log(e);
                });
            } catch(e) {
                console.log(e);
            }
        }, data.blocks.time.inputs.value.value * 1000);
    }

    function hideElement(id){
        setCounterScore(prevCount => prevCount + 1)
        var x = document.getElementById(id);
        x.style.display = "none";
    }
    
    function renderItems(maxItems){
        let i = 0; 
        let items = [];
        for (;i <= maxItems;) {
            for (const item of blockListItems.config) {
                i++
                if (i <= maxItems){
                    if (item.blocks.image.inputs.value.value){
                        items.push(<img id={`item_${i}`} className="image_drop" src={`${process.env.REACT_APP_MEDIAS}/${item.blocks.image.inputs.value.value.filePath}`} onMouseOver={(e) => {hideElement(e.target.id)}} onTouchStart={(e) => {hideElement(e.target.id)}} onClick={(e) => {hideElement(e.target.id)}}/>)
                    }else{
                        items.push(<img id={`item_${i}`} className="image_drop" src={"https://via.placeholder.com/90x90"} onMouseOver={(e) => {hideElement(e.target.id)}} onTouchStart={(e) => {hideElement(e.target.id)}} onClick={(e) => {hideElement(e.target.id)}}/>)
                    }
                }
            }
        }
        setListItems(items)
    }
    
    useEffect(() => {
        if (spread)
            return;

        renderItems(data.blocks.number_items.inputs.value.value);
        if(localStorage.getItem('username')){
            let data = new FormData();
            let currentSource = localStorage.getItem('GAME_SOURCE');
            data.append('source', currentSource);
            data.append('username', localStorage.getItem('username'));
            data.append('token', process.env.REACT_APP_ASSET_TOKEN);
            try {
                request(`${process.env.REACT_APP_API}/public/asset/games/${props.assetGameType}/registration/can_play`, 'post', data, 'multipart/form-data').then(response => {
                    if (response.code === "no_registration_for_asset" || response.code === "email_not_validated"){
                        window.location = '/';
                    }else if (response.code === "already_played_today"){
                        window.location = '/already-played';
                    }
                    else if (response.code === "already_win"){
                        window.location = '/already-won';
                    }
                }).catch(e => {
                    console.log(e);
                });
            } catch(e) {
                console.log(e);
            }
        }
        else{
            window.location = '/';
        }
        setTimer(data.blocks.time.inputs.value.value)
    }, []);

    useEffect(() => {
        if (timerStatus && timer !== 0){
            const intervalId = setInterval(() => {
                setTimer(prevCount => prevCount - 1);
            }, 1000);
            
            return () => clearInterval(intervalId);
        }
    }, [timer,timerStatus]);
    
    let blockListItems = data.blocks.list_lots;

    return (
        <div className="game_game" id="game_rain">
            <section 
                className={endGame ? "game ended" : "game"}
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: "cover!important",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative'
                }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                { !preview && props.toolbarSection }
                <div className={hidePopup ? "popup game" : "popup play game"}>
                    <div 
                        className="popup_content" 
                        onMouseEnter={() => {setHoverBlock(true); setHover(false);}} 
                        onMouseLeave={() => {setHoverBlock(false); setHover(true)}}
                        style={{
                            backgroundColor: data.blocks.middleBox.inputs['backgroundColor'].value,
                            backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                            borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                            borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                        }}
                    >
                        { !preview && hoverBlock && props.toolbarBlock }
                        <div>
                            {
                                data.blocks.middleBox?.blocks?.title.inputs.value.value ? 
                                    <H1 data={data.blocks.middleBox} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                                : null
                            }
                            {
                                data.blocks.middleBox?.blocks?.desc.inputs.value.value && data.blocks.middleBox?.blocks?.desc.inputs.value.value !== "<p><br></p>" ? 
                                    <Div data={data.blocks.middleBox} preview={preview} component={id} block={'desc'} inputCallback={inputCallback} />
                                : null
                            }
                            <Button data={data.blocks.middleBox} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="button goToGame" nopadding={true} onClick={() => startGame()}/>
                        </div>
                    </div>
                </div>
                {
                    data.blocks.overlay.inputs.value.value ? 
                        <section className="overlay-image">
                            <img src={`${process.env.REACT_APP_MEDIAS}/${data.blocks.overlay.inputs.value.value.filePath}`} />
                        </section>
                    : null
                }
                <section className="rain-container">
                    <div className="rain">
                        {
                            <div className={hidePopup ? "top-left-infos" : "top-left-infos hide"}>
                                <div className="timer">
                                    <img src={`${process.env.REACT_APP_MEDIAS}/${data.blocks.time_icone.inputs.value.value?.filePath}`} />
                                    <span>{timer}</span>
                                </div>
                                <div className="score">
                                    <img src={`${process.env.REACT_APP_MEDIAS}/${data.blocks.number_items_icone.inputs.value.value?.filePath}`} />
                                    <span>X {counterScore}</span>
                                </div>
                            </div>
                        }
                        
                        {
                            blockListItems.config.length > 0 ?
                                listItems.map(item => {
                                    return item
                                })
                            : null
                        }
                    </div>
                </section>
                {
                    data.blocks.end_game.inputs.value.value ?
                        <section className={VIDEOS.includes(isWin ? data.blocks.end_game.inputs.value.value.type : data.blocks.end_game_loose.inputs.value.value?.type) ? "endGame" : "endGame overlayImage"}>
                            {
                                VIDEOS.includes(isWin ? data.blocks.end_game?.inputs.value.value.type : data.blocks.end_game_loose.inputs.value.value?.type) ?
                                    <video width="100%" id="result-video" playsInline muted>
                                        <source src={`${process.env.REACT_APP_MEDIAS}/${isWin ? data.blocks.end_game.inputs.value.value?.filePath : data.blocks.end_game_loose.inputs.value.value?.filePath}`} type={isWin ? data.blocks.end_game.inputs.value.value?.type : data.blocks.end_game_loose.inputs.value.value?.type} />
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                : 
                                    <img src={`${process.env.REACT_APP_MEDIAS}/${isWin ? data.blocks.end_game.inputs.value.value?.filePath : data.blocks.end_game_loose.inputs.value.value?.filePath}`} />
                            }
                        </section>
                    : null
                }
            </section>
        </div>
    );
};

GameRain.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default GameRain;
