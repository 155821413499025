import { gql } from '@apollo/client';

//----- Récupération des poles
export const GET_AGENCE_POLES = gql`
{
  agencyPoles{
    edges{
      node{
        id
        name
      }
    }
  }
}
`;


//----- Ajouter un pole
/*
 Exemple de variables
  {
  "name": "DEV"
  }
*/
export const ADD_POLE = gql`
mutation AddAgencyPole($name: String!){
	createAgencyPole(input: {name: $name}){
    agencyPole{
      id
      name
    }
  }  
}`;

//----- Modifier un pole
/*
 Exemple de variables
  {
    "id": "/api/agency-poles/1",
    "name": "DEV-BACK"
  }
*/
export const UPDATE_POLE = gql`
mutation UpdateAgencyPole($id: ID!, $name: String!){
  updateAgencyPole(input: {id: $id, name: $name}){
    agencyPole{
      id
      name
    }
  }  
}
`;

//----- Supprimer un pole
/*
 Exemple de variables
  {
    "id" : "/api/agency-poles/1"
  }
*/
export const DELETE_POLE = gql`
mutation DeleteAgencyPole($id: ID!){
	deleteAgencyPole(input: {id:$id}){
    agencyPole{
      id
    }
  }  
}`;

//----- Récupération des rates
export const GET_AGENCE_TARIFS = gql`
{
  agencyRates{
    edges{
      node{
        id
        name
        price
      }
    }
  }
}
`;



//----- Ajouter un rate
/*
 Exemple de variables
  {
    "name": "1 journée",
    "price": "599"
  }
*/
export const ADD_TARIF = gql`
mutation AddAgencyRate($name: String!, $price: String!){
  createAgencyRate(input: {name: $name, price: $price}){
    agencyRate{
      id
      name
      price
    }
  }  
}
`;

//----- Modifier un rate
/*
 Exemple de variables
  {
    "id": "/api/agency-rates/1",
    "name": "1 journée",
    "price": "600"
  }
*/
export const UPDATE_TARIF = gql`
mutation UpdateAgencyRate($id: ID!, $name: String!, $price: String!){
  updateAgencyRate(input: {id: $id, name: $name, price: $price}){
    agencyRate{
      id
      name
      price
    }
  }  
}
`;

//----- Supprimer un rate
/*
 Exemple de variables
  {
    "id" : "/api/agency-rates/1"
  }
*/
export const DELETE_TARIF = gql`
mutation DeleteAgencyRate($id: ID!){
	deleteAgencyRate(input: {id:$id}){
    agencyRate{
      id
    }
  }  
}`;




//----- Récupération des taux de TVA
export const GET_AGENCE_TAX_RATES = gql`
{
  agencyTaxRates{
    edges{
      node{
        id
        name
        value
      }
    }
  }
}
`;



//----- Ajouter un taux de TVA
/*
 Exemple de variables
  {
    "name": "TVA FR 20%",
    "value": 20.00
  }
*/
export const ADD_TAX_RATE = gql`
mutation AddAgencyTaxRate($name: String!, $value: String!){
  createAgencyTaxRate(input: {name: $name, value: $value}){
    agencyTaxRate{
      id
      name
      value
    }
  }  
}
`;

//----- Modifier un taux de TVA
/*
 Exemple de variables
  {
    "id": "/api/agency-tax-rates/1",
    "name": "TVA FR 20.00%",
    "value": 20.00
  }
*/
export const UPDATE_TAX_RATE = gql`
mutation UpdateAgencyTaxRate($id: ID!, $name: String!, $value: String!){
  updateAgencyTaxRate(input: {id: $id, name: $name, value: $value}){
    agencyTaxRate{
      id
      name
      value
    }
  }  
}
`;

//----- Supprimer un taux de TVA
/*
 Exemple de variables
  {
    "id": "/api/agency-tax-rates/1",
  }
*/
export const DELETE_TAX_RATE = gql`
mutation DeleteAgencyTaxRate($id: ID!){
	deleteAgencyTaxRate(input: {id:$id}){
    agencyTaxRate{
      id
    }
  }  
}`;


//----- Récupération des conditions de paiement
export const GET_PAYMENT_TERMS = gql`
{
  agencyPaymentTerms{
    edges{
      node{
        id
        name
      }
    }
  }
}
`;


//----- Ajouter une condition de paiement
/*
 Exemple de variables
  {
  "name": "Paiement à 30 jours"
  }
*/
export const ADD_PAYMENT_TERM = gql`
mutation AddAgencyPaymentTerm($name: String!){
	createAgencyPaymentTerm(input: {name: $name}){
    agencyPaymentTerm{
      id
      name
    }
  }  
}`;

//----- Modifier une condition de paiement
/*
 Exemple de variables
  {
    "id": "/api/agency-payment-terms/1",
    "name": "Paiement à 30 jours svp"
  }
*/
export const UPDATE_PAYMENT_TERM = gql`
mutation UpdateAgencyPaymentTerm($id: ID!, $name: String!){
  updateAgencyPaymentTerm(input: {id: $id, name: $name}){
    agencyPaymentTerm{
      id
      name
    }
  }  
}
`;

//----- Supprimer une condition de paiement
/*
 Exemple de variables
  {
    "id" : "/api/agency-payment-terms/1"
  }
*/
export const DELETE_PAYMENT_TERM = gql`
mutation DeleteAgencyPaymentTerm($id: ID!){
	deleteAgencyPaymentTerm(input: {id:$id}){
    agencyPaymentTerm{
      id
    }
  }  
}`;



//----- Récupération des délais de paiement
export const GET_PAYMENT_DEADLINES = gql`
{
  agencyPaymentDeadlines{
    edges{
      node{
        id
        name
      }
    }
  }
}
`;


//----- Ajouter un délai de paiement
/*
 Exemple de variables
  {
  "name": "Paiement à 30 jours"
  }
*/
export const ADD_PAYMENT_DEADLINE = gql`
mutation AddAgencyPaymentDeadline($name: String!){
	createAgencyPaymentDeadline(input: {name: $name}){
    agencyPaymentDeadline{
      id
      name
    }
  }  
}`;

//----- Modifier un délai de paiement
/*
 Exemple de variables
  {
    "id": "/api/agency-payment-deadlines/1",
    "name": "Paiement à 30 jours svp"
  }
*/
export const UPDATE_PAYMENT_DEADLINE = gql`
mutation UpdateAgencyPaymentDeadline($id: ID!, $name: String!){
  updateAgencyPaymentDeadline(input: {id: $id, name: $name}){
    agencyPaymentDeadline{
      id
      name
    }
  }  
}
`;

//----- Supprimer un délai de paiement
/*
 Exemple de variables
  {
    "id" : "/api/agency-payment-deadlines/1"
  }
*/
export const DELETE_PAYMENT_DEADLINE = gql`
mutation DeleteAgencyPaymentDeadline($id: ID!){
	deleteAgencyPaymentDeadline(input: {id:$id}){
    agencyPaymentDeadline{
      id
    }
  }  
}`;



//----- Récupération des CGVs
export const GET_CGVS = gql`
{
  agencyTermAndConditions{
    edges{
      node{
        id
        name
        content
      }
    }
  }
}
`;

//----- Ajouter une CGVs
/*
 Exemple de variables
  {
  "name": "Condition générale de ventes",
  "content": "<h1>ARTICLE 1</h1><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec sodales magna. Maecenas auctor tempus quam hendrerit laoreet. Vestibulum interdum pulvinar nisl ultricies cursus. Integer pretium ante risus, eu rutrum nisi bibendum ac. Quisque massa diam, ullamcorper sed sem et, scelerisque vulputate tellus. Fusce ipsum leo, vehicula fringilla lectus fringilla, lacinia iaculis elit. Ut dui sapien, pharetra ut nisi euismod, pharetra commodo massa. Integer pulvinar lorem at nulla euismod, non pretium felis consequat. Nulla id ligula dignissim, mollis sapien sit amet, egestas nibh. Donec vitae erat a nisi semper viverra. Cras nec auctor arcu.</p>"
  }
*/
export const ADD_CGV = gql`
mutation createAgencyTermAndCondition($name: String!, $content: String){
	createAgencyTermAndCondition(input: {name: $name, content: $content}){
    agencyTermAndCondition{
      id
      name
    }
  }  
}`;

//----- Editer une CGVs
/*
 Exemple de variables
  {
    "id": "/api/agency_term_and_conditions/1",
    "name": "Condition générale de ventes edited",
    "content": "<h1>ARTICLE 1</h1><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec sodales magna. Maecenas auctor tempus quam hendrerit laoreet. Vestibulum interdum pulvinar nisl ultricies cursus. Integer pretium ante risus, eu rutrum nisi bibendum ac. Quisque massa diam, ullamcorper sed sem et, scelerisque vulputate tellus. Fusce ipsum leo, vehicula fringilla lectus fringilla, lacinia iaculis elit. Ut dui sapien, pharetra ut nisi euismod, pharetra commodo massa. Integer pulvinar lorem at nulla euismod, non pretium felis consequat. Nulla id ligula dignissim, mollis sapien sit amet, egestas nibh. Donec vitae erat a nisi semper viverra. Cras nec auctor arcu.</p>"
  }
*/
export const EDIT_CGV = gql`
mutation updateAgencyTermAndCondition($id: ID!, $name: String!, $content: String){
	updateAgencyTermAndCondition(input: {id: $id, name: $name, content: $content}){
    agencyTermAndCondition{
      id
      name
    }
  }  
}`;


//----- supprimer une CGVs
/*
 Exemple de variables
  {
    "id": "/api/agency_term_and_conditions/1"
  }
*/
export const DELETE_CGV = gql`
mutation deleteAgencyTermAndCondition($id: ID!){
	deleteAgencyTermAndCondition(input: {id: $id}){
    agencyTermAndCondition{
      id
    }
  }  
}`;