import {
  GridCustom,
  LinkText,
  ContainerFileUpload,
  LabelFab,
} from "../../styles/inputStyled";
import { Box, Grid } from "@mui/material";
import colors from "../../../../../config/theme/colors";
import { ALLOWED, DOCUMENTS } from "../../../../../js/constants/medias-types";
import { HiddenUploadFile, OurTypographyCustom } from "./styles/styled";
import OurTypography from "../../../typography/Typography";
import OurButton from "../../../button/Button";
import OurModal from "../../../OurModal/OurModal";
import { useState } from "react";
import { getFileConfig } from "../../../../../js/helpers/files";
import LayoutFormBuilder from "../../LayoutFormBuilder";
import mediaPickerConfig from "./config/mediaPicker.config";
import OurLock from "../../../OurLock/OurLock";
import { InputLabel } from "@mui/material";
import { MEDIAPICKER_TYPES } from "../../../../../js/constants/mediapickerTypes.enum";
import IconInsertFile from "@mui/icons-material/InsertDriveFile";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

// ! If you cannot get the image, try editing the graphql query and add the "name", "id" and "type" fields
const OurImageSelector = ({
  allProps: props,
  error: { seeError, errorMessage },
  checkErrorCallback,
  maxFileSize,
  file,
  hasMediaPicker,
  lock,
  usedAlone = false,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenMediaPicker, setIsOpenMediaPicker] = useState(false);
  const [mediapickerFile, setMediapickerFile] = useState(null);

  const value = file;

  const toggleMediaPickerDrawer = () => {
    setIsOpenMediaPicker(!isOpenMediaPicker);
  };

  const onSelect = (file) => {
    setMediapickerFile(file);
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <GridCustom item xs={props.xs ?? 12}>
      {props.label && props.usedAlone && (
        <InputLabel sx={{ fontSize: "14px", marginBottom: "5px" }}>
          {props.label}
          {props.required && "*"}
        </InputLabel>
      )}
      <HiddenUploadFile
        id={props.input.stateName}
        type="file"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          accept:
            hasMediaPicker && !props.input.allowedMedia
              ? ALLOWED
              : props.input.allowedMedia?.toString() ??
                "image/*, application/*",
        }}
        onChange={
          hasMediaPicker
            ? null
            : (event) => {
                event.preventDefault();

                let file = event.target.files[0];
                let reader = new FileReader();

                reader.readAsDataURL(file);

                event.target.value = null;

                reader.onloadend = () => {
                  let allowedTypes = ALLOWED;
                  let documentsTypes = DOCUMENTS;
                  checkErrorCallback(file.size);
                  if (file.size > maxFileSize) {
                  } else {
                    if (allowedTypes.includes(file.type)) {
                      let value = {
                        ...file,
                        data: documentsTypes.includes(file.type)
                          ? getFileConfig(file.type).image
                          : reader.result,
                        file: file,
                        changed: true,
                      };
                      props.stateCallback(value);
                    }
                  }
                };
              }
        }
        helperText={
          props.input.helper?.link ? (
            <Box>
              <LinkText variant="h5">{props.input.helper.text}</LinkText>
            </Box>
          ) : (
            props.input.helper?.text
          )
        }
        hidden={true}
      />
      <Box position={"relative"}>
        <ContainerFileUpload
          hasError={seeError}
          isDisabled={props.input.disabled}
          hasData={value && value.data}
          style={{
            display: "flex",
          }}
          fullwidth={props.input.fullwidth}
        >
          {/* Modify */}
          {value && value.data ? (
            <Grid
              container
              alignSelf={"center"}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{
                height: "100%",
                width: "100%",
                padding: "15px 30px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <OurTypographyCustom
                  onClick={toggleModal}
                  fontweighttext={"bold"}
                  style={{
                    fontSize: 14,
                    color: props.input.disabled && colors.grey.lighter.hue600,
                  }}
                >
                  Photo
                </OurTypographyCustom>
                <OurTypography
                  style={{
                    fontSize: 14,
                    color: props.input.disabled && colors.grey.lighter.hue600,
                  }}
                >
                  {value?.file?.name ?? "-"}
                </OurTypography>
              </Grid>
              <Grid
                item
                style={{
                  marginRight: "20px",
                }}
              >
                <OurButton
                  id={"delete_image_button_" + props.input.stateName}
                  types={"delete"}
                  importance={"secondary"}
                  disabled={props.input.disabled}
                  onClick={
                    props.input.disabled
                      ? () => {}
                      : hasMediaPicker
                      ? () => {
                          checkErrorCallback(null);
                          props.input.handleMediaPicker(
                            null,
                            props.input.stateName
                          );
                          props.onDelete && props.onDelete(props.value);
                        }
                      : () =>
                          props.stateCallback({
                            changed: true,
                            file: null,
                            data: null,
                          })
                  }
                  style={{
                    marginRight: 10,
                  }}
                >
                  Supprimer
                </OurButton>

                <OurButton
                  id={"edit_image_button_" + props.input.stateName}
                  types={"edit"}
                  importance={"secondary"}
                  disabled={props.input.disabled}
                  onClick={
                    hasMediaPicker
                      ? (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleMediaPickerDrawer();
                        }
                      : () => {}
                  }
                >
                  {props.input.disabled ? (
                    "Modifier"
                  ) : (
                    <LabelFab htmlFor={props.input.stateName}>
                      Modifier
                    </LabelFab>
                  )}
                </OurButton>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              alignSelf={"center"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              {props.input.disabled ? (
                <OurTypography
                  colortext={colors.grey.lighter.hue600}
                  fontweighttext={"bold"}
                  style={{ fontSize: 14 }}
                >
                  {props.input.fileType === MEDIAPICKER_TYPES.FILE && (
                    <Box>
                      {/* <IconInsertFile size={10} /> */}
                      <span style={{ textDecoration: "underline" }}>
                        + Sélectionner un fichier
                      </span>
                    </Box>
                  )}
                  {props.input.fileType === MEDIAPICKER_TYPES.IMAGE && (
                    <span style={{ textDecoration: "underline" }}>
                      + Ajouter une image
                    </span>
                  )}
                </OurTypography>
              ) : (
                <LabelFab
                  htmlFor={props.input.stateName}
                  onClick={
                    hasMediaPicker
                      ? (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleMediaPickerDrawer();
                        }
                      : null
                  }
                >
                  <OurTypography
                    colortext={colors.blue.darker.hue300}
                    fontweighttext={"bold"}
                    style={{ fontSize: 14, fontStyle: "italic" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {props.input.fileType === MEDIAPICKER_TYPES.FILE && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                            padding: "10px",
                          }}
                        >
                          <div>
                            <IconInsertFile fontSize="large" />
                          </div>
                          <span style={{ textDecoration: "underline" }}>
                            + Sélectionner un fichier
                          </span>
                        </Box>
                      )}
                    </Box>
                    {(props.input.fileType === MEDIAPICKER_TYPES.IMAGE ||
                      !props.input.fileType) && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {/* <div>
                          <AddPhotoAlternateIcon fontSize="large" />
                        </div> */}
                        <span style={{ textDecoration: "underline" }}>
                          + Ajouter une image
                        </span>
                      </Box>
                    )}
                    {props.input.showTypesAllowed && (
                      <span>
                        {`(`}
                        {props.input.allowedMedia?.map(
                          (format, index) =>
                            format +
                            (index < props.input.allowedMedia.length - 1
                              ? ", "
                              : "")
                        )}
                        {`)`}
                      </span>
                    )}
                  </OurTypography>
                </LabelFab>
              )}
            </Grid>
          )}
        </ContainerFileUpload>

        <OurLock
          lock={lock}
          style={{
            position: "absolute",
            top: "9px",
            right: 2,
          }}
        />
      </Box>

      <OurModal
        isOpen={isOpenModal}
        handleClose={toggleModal}
        modalData={value}
      />

      {hasMediaPicker && (
        <LayoutFormBuilder
          isSublayout
          opened={isOpenMediaPicker}
          dataLayout={mediaPickerConfig({
            onSelect,
            stateName: props.input.stateName,
            xs: props.input.cardSize ?? 3,
            toggleMediaPickerDrawer,
            allowedTypes: props.input.allowedTypes,
            translated: props.input.translated,
          })}
          showInfoText={false}
          forClose={() => {
            toggleMediaPickerDrawer();
          }}
          buttons={{
            validate: {
              type: "create",
              importance: "primary",
              label: "Valider",
              hasIcon: false,
              isDisabled: !mediapickerFile,
            },
          }}
          isButtonDisabled={!mediapickerFile}
          validateButton
          handlerMutation={() => {
            checkErrorCallback(mediapickerFile);
            props.input.handleMediaPicker(
              mediapickerFile,
              props.input.stateName,
              props.input.translated
            );
            toggleMediaPickerDrawer();
          }}
        />
      )}

      {!seeError ? null : <span className="error-label">{errorMessage}</span>}
    </GridCustom>
  );
};

export default OurImageSelector;
