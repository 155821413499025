import UpdateIcon from "@mui/icons-material/Update";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SyncIcon from "@mui/icons-material/Sync";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import WarningIcon from '@mui/icons-material/Warning';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const muiIconList = {
  stock: UpdateIcon,
  update: UpdateIcon,
  stats: EqualizerIcon,
  basket: ShoppingBasketIcon,
  saveAlt: SaveAltIcon,
  sync: SyncIcon,
  settings: SettingsInputComponentIcon,
  add: AddIcon,
  cancel: CancelIcon,
  check: CheckCircleOutlineIcon,
  edit: EditOutlinedIcon,
  trash: DeleteIcon,
  delete: DeleteIcon,
  openNewTab: OpenInNewIcon,
  visibility: VisibilityIcon,
  visibilityOff: VisibilityOffIcon,
  user: PersonIcon,
  lock: LockIcon,
  warning: WarningIcon,
  upload: FileUploadIcon,
};

export default muiIconList;