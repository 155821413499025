import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING  } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { withApollo } from '@apollo/client/react/hoc';
import { withTranslation } from 'react-i18next';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { GET_ASSET_BY_ID_NAVIGATION } from '../../../../queries/assets';
import { GET_CATALOGS_BY_ID } from '../../../../queries/catalogs';
import { toggleExpandedForAll } from 'react-sortable-tree';
import TreeView from '../../../ui/tree-view/TreeView';
import SearchBar from '../../../ui/search/SearchBar';
import { Box, Grid } from '@mui/material';
import { checkRouting } from '../../../../js/utils/checkRouting';
import styled from 'styled-components';

const PageWrapper = styled(Box)`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
`;

function EngineAssetsCacheCategories(props) {
    const [asset, setAsset] = React.useState(props.match.params?.id ? props.match.params?.id : null);
    const [ready, setReady] = React.useState(false);
    const [arrayCategories, setArrayCategories] = React.useState([]);
    const [currentLang, setCurrentLang] =  React.useState(props.locales[0].node.code);
    const [expanded, setExpanded] =  React.useState({});
    const [searchModal, setSearchModal] =  React.useState(null);

    useEffect(() => {
        checkRouting(props)
        initNavAsset()
    }, [])

    const initNavAsset = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query:GET_ASSET_BY_ID_NAVIGATION,
                variables:{id: `/api/assets/${asset}`},
                fetchPolicy:'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                initCatalog(result.data.asset.catalog.id)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }

    const initCatalog = (idCatalog) => {
        props.client.query({
            query: GET_CATALOGS_BY_ID,
            variables: { id: idCatalog },
            fetchPolicy: 'no-cache',
        }).then(result => {
            prepareSecondTree(result.data.catalog.categories, true);
        }).catch(error => {
            console.log(error)
        })
    }

    const prepareSecondTree = async (categories, init, exe = false) => {
        processTree(categories, e => e.parent === null, init);
    }

    const processTree = (categories, getRoot, init = false) => {
        let data = categories.filter(getRoot);
        for (let parent of data) {
            convertToNode(parent, true, init);
            populateChildren(categories, parent, init);
        }

        setArrayCategories(copyArrayOfObjects(data))
        setReady(true)
    };

    const copyArrayOfObjects = array => array.map(a => ({...a})); 


    const convertToNode = (data, isRoot = false, init = false) => {
        let attributes = [];
        // let filters = [];

        if (init) {
            for (let { node } of data.categoryDatas.edges) {
                let found = false;
                
                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;
    
                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.value,
                            media: {
                                id: node.media ? node.media.id : null,
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id // category data id
                        });
                    }
                }
    
                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        locales: [{
                            value: node.value,
                            media: {
                                id: node.media ? node.media.id : null,
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id
                        }]
                    });
                }
            }
        }
        
        data.attributes = init ? attributes : data.attributes;
        // data.filters = init ? filters : data.filters;
        data.filters = []; // created when opening a cat in tree

        let nameAttribute = data.attributes.find(e => e.identifier === 'category_name');

        let getTraduction = nameAttribute ? nameAttribute.locales.find(
            translation => translation.code === currentLang
        ) : null;

        data.title          = isRoot ? '/' : getTraduction?.value ?? nameAttribute?.locales[0]?.value ?? data.libelle;
        data.isDirectory    = true;
        data.isRoot         = isRoot;
        data.dragDisabled   = true;
        data.expanded       = isRoot ? true : data.expanded;
    }

    const populateChildren = (cats, parent, init) =>{
        parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);
        
        for (let child of parent.children) {
            convertToNode(child, false, init);
            populateChildren(cats, child, init);
        }
    }

    const handleTreeChange = (value) => {
        setArrayCategories(value)
        let all = {...expanded};

        setExpandedValue(all, value[0]);
        setExpanded(all)
    };

    const setExpandedValue = (all, node) => {
        if(node){
            all[node.id] = node.expanded;
    
            for (let child of node.children) {
                setExpandedValue(all, child);
            }
        }
    };

    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const expandNodes = (expanded) => {
        let getArray = [...arrayCategories]
        getArray = toggleExpandedForAll({
            treeData: getArray,
            expanded
        })
        setArrayCategories(getArray)
    };
    const clearCacheCategory = (category) => {
        try{
            request(`${process.env.REACT_APP_API}/category/flush/${props.match.params.id}/${category.id.replace('/api/categories/', '')}`, 'get').catch(function(error){
                props.snack(ALERT_SUCCESS, 'Cache vidé avec succès');
            });
        }catch(e){
            console.log('ERROR CLEAR CACHE', e)
        }
    };

    return (
        <PageWrapper>
            <TopPanel
                icomoon         = {"ico-notifications"} 
                colorIcomoon    = {colors.blue.darker.hue300}
                title           = {props.t("spread_builder.cache.title")}
                subtitle        = {'Gestion de vos régies d’informations (création / modification / suppression)'}
                ready           = {ready}
                gradientColor1  = {colors.menu.regular} 
                gradientColor2  = {colors.menu.darker} 
                currentLang     = {currentLang} 
                handleLang      = {handleLang} 
                locales         = {props.locales}
                hasBorder={true}
            />
            {
                ready ? 
                    <>
                    <Grid container direction="column" style={{padding: 32}}>
                        <SearchBar value={searchModal} placeholder="Rechercher une catégorie" onChange={(e) => setSearchModal(e?.target?.value)} style={{marginBottom: 16}}/> 
                        <TreeView 
                            typeOfTree={'categorie'}
                            reducedTree={true}
                            dataTree={arrayCategories}
                            // onChange={treeData => console.log('treeData', treeData)}
                            searchQuery={searchModal}
                            onChange={treeData => handleTreeChange(treeData)}
                            clearCache={clearCacheCategory}
                            expand={expandNodes}
                        /> 
                    </Grid>
                        
                    </>
                : (
                    <Box style={{
                        position: "relative",
                    }}>
                        <PageLoader />
                    </Box>
                )
            }
        </PageWrapper>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsCacheCategories))));