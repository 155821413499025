import React from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import colors from '../../../config/theme/colors';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import StraightIcon from '@mui/icons-material/Straight';

const CustomSelect = styled(Select)`
    cursor:pointer;
    background-color:#FFF;
    padding:5px 10px;
    fieldset{
        border: 1px solid ${colors.grey.border} !important;
    }
    .MuiSelect-select {
        padding-right:0 !important;
        padding:0;
        display:flex;
        align-items:center;
    }
    .MuiSvgIcon-root {
        right: 5px;
    }
    .MuiModal-root .MuiPaper-root{
        margin-top : 5px;
        .MuiList-root{
            padding:0;
        }
    }
`;

const CustomMenuItemActive = styled(MenuItem)`
    font-weight:bold;
    background-color: transparent !important;
`;

const CustomMenuItem = styled(MenuItem)`
    &:hover{
        background-color: ${colors.blue.lighter.hue900};
        color:${colors.blue.darker.hue300};
        .MuiSvgIcon-root{
            fill: ${colors.blue.darker.hue300} !important;
        }
    }
`;

export default function SelectSort(props){
    const {currentSort,changeSort} = props;

    const handleChange = (e)=>{
        let value = e.target.value
        changeSort({filter:value,orderBy : value})
    }
    return(
        <FormControl>
            <CustomSelect
                autoWidth
                value={currentSort.orderBy}
                renderValue={()=><SwapVertIcon fontSize="small"/>}
                onChange={handleChange}
                IconComponent={""}
                MenuProps={{
                    sx:{
                        marginTop:"5px"
                    },
                    PaperProps:{
                        sx:{
                            boxShadow:"none",
                            border:`0.5px solid ${colors.grey.border} !important`
                        },  
                    },
                    MenuListProps: { 
                        disablePadding: true ,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: 0,
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
            >
                {
                    currentSort.orderBy === "desc" ?
                    <CustomMenuItemActive  value={"desc"}><StraightIcon fontSize="small" /> Plus récent</CustomMenuItemActive>    
                    :<CustomMenuItem value={"desc"}><StraightIcon fontSize="small" /> Plus récent</CustomMenuItem>
                }
                {
                    currentSort.orderBy === "asc"?
                    <CustomMenuItemActive value={"asc"}><StraightIcon  fontSize="small" sx={{transform : "rotate(180deg)"}}/> Moins récent</CustomMenuItemActive>    
                    :<CustomMenuItem value={"asc"}><StraightIcon fontSize="small" sx={{transform : "rotate(180deg)"}}/> Moins récent</CustomMenuItem>
                }
            </CustomSelect>
        </FormControl>
    );
}