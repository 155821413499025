import React, { useState, useEffect } from "react";
import {SocialComplete} from '../../index';
import Pg from '../../../../../shared/components/CustomParagraph';
import H1 from '../../../../../shared/components/CustomH1';
import request from "../../../../../../tools/Fetch";

const ModalEmailShare = (props) => {
    const [listEmail, setListEmail] = useState([]);
    const [firstCheck, setFirstCheck] = useState(false);

    let { 
        isOpen,
        onClose,
        data,
        lang
    } = props;

    useEffect(()=> {
        var arrayEmail = []
        arrayEmail.push({
            email: null,
            checked: false,
            empty: false,
        })
        setListEmail([...arrayEmail])
    }, [])

    function addEmailInput(){
        let arrayEmail = listEmail;
        arrayEmail.push({
            email: null,
            checked: false,
            empty: false,
        })
        setListEmail([...arrayEmail])
    }
    function deleteMail(index){
        let newList = listEmail;
        newList.splice(index, 1)
        setListEmail([...newList])
    }

    function checkMail(mail){
        if (mail.email){
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail.email)) {
                mail.checked = true;
            }else{
                mail.checked = false;
            }
        }else{
            mail.empty = true;
            mail.checked = false;
        }
    }

    function handleChange(e, index) {
        var getList = listEmail
        listEmail[index].email = e.target.value;
        checkMail(listEmail[index])
        if (e.target.value && e.target.value !== ""){
            listEmail[index].empty = false;
        }else{
            listEmail[index].empty = true;
        }
        setListEmail([...getList])
    }

    function checkingAll(){
        var checkArray = [];
        setFirstCheck(true)
        for (let mail of listEmail){
            checkArray.push(mail.checked)
        }
        if (checkArray.every(v => v === true)){
            try {
                let data = new FormData();
                data.append('username', localStorage.getItem('username'));
                data.append('token', process.env.REACT_APP_ASSET_TOKEN);
                request(`${process.env.REACT_APP_API}/public/asset/registration/play`, 'post', data, 'multipart/form-data').then(result => {
                    let allMailAdress = listEmail.map(e => e.email)
                    data.append('customData', allMailAdress);
                    try {
                        request(`${process.env.REACT_APP_API}/public/asset/registration/add/custom-data`, 'post', data, 'multipart/form-data').then(result => {
                            onClose()
                            if (props.sendAllMail()){
                                props.sendAllMail()
                            }
                        }).catch(error => {
                            console.err(error)
                        })
                    }catch(e){
                        console.err(e);   
                    }
                }).catch(error => {
                    console.err(error)
                })
            }catch(e){
                console.err(e);   
            }
        }else{
            console.err("ERROR")
        }
    }
    console.err('LANGHERE', lang)
    return(
        <>
            <div className="overlay_modal_share" style={{display: isOpen ? 'block' : 'none', backgroundColor: "#071242B3"}} onClick={onClose}></div>
            <div className="content_modal_share" style={{display: isOpen ? 'flex' : 'none'}}>
                <h3 className="title">{lang === "fr_FR" ? "Ajouter les adresses mails pour partager le jeu concours" : "Añade direcciones de correo electrónico para compartir el concurso."}</h3>
                <div className="list_email">
                    {
                        listEmail.length > 0 ?
                            listEmail.map((mail, index) => {
                                return (
                                    <div key={index} className="container-input">
                                        <label>Email #{index +1}</label>
                                        <div className="input-block">
                                            <input className={firstCheck && (mail.empty || !mail.checked) ? "error" : null} onChange={(e) => {handleChange(e,index)}} type="email" id="email" pattern=".+@globex\.com" placeholder={lang === "fr_FR" ? "Entrez une adresse mail" : "Introduzca una dirección de correo electrónico"} required />
                                            {
                                                index > 0 ?
                                                    <button className="delete-block" onClick={() => {deleteMail(index)}}>
                                                        {lang === "fr_FR" ? "Supprimer" : "Borrar"}
                                                    </button>
                                                : null
                                            }
                                        </div>
                                        <div className={"verification"} style={{display: firstCheck && (mail.empty || !mail.checked) ? 'block' : 'none'}}>{mail.empty ? lang === "fr_FR" ? "Veuillez remplir ce champ." : "Por favor complete este campo." : lang === "fr_FR" ? "Veuillez utiliser une adresse mail valide (ex: johndoe@example.com)." : "Utilice una dirección de correo electrónico válida (p. ej., johndoe@example.com)."}</div>
                                    </div>
                                )
                            })
                        : null
                    }
                    {
                        listEmail.length < 10 ?
                            <button className="add-button" onClick={() => {addEmailInput()}}>{lang === "fr_FR" ? "+ Ajouter une adresse" : "+ Agregar correo electrónico"}</button>
                        : null
                    }
                </div>
                <div className="container-validation">
                    <button className="validation_button" onClick={() => {checkingAll()}}>{lang === "fr_FR" ? "Valider" : "Validar"}</button>
                </div>
                <div className="button_close">
                    <button className="as_button" onClick={onClose}>{"<"} {lang === "fr_FR" ? "Retour" : "Devolver"}</button>
                </div>
            </div>
        </>
)
};

export default ModalEmailShare;