export const START_LOADING  = "START_LOADING";
export const STOP_LOADING   = "STOP_LOADING";
export const SET_USER       = "SET_USER";
export const SET_FEATURE_ENTRIES       = "SET_FEATURE_ENTRIES";
export const ADD_PRODUCT    = "ADD_PRODUCT";
export const SNACK          = "SNACK";
export const STOP_SNACK     = "STOP_SNACK";
export const SET_LOCALES    = "SET_LOCALES";
export const SET_ATTRIBUTES = "SET_ATTRIBUTES";
export const SET_ATTRIBUTE_GROUPS = "SET_ATTRIBUTE_GROUPS";
export const SET_ATTRIBUTE_TYPES = "SET_ATTRIBUTE_TYPES";
export const SET_GUIDELINE = "SET_GUIDELINE";
export const SET_NAVIGATION = "SET_NAVIGATION";
export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const SET_LOGIN_PROGRESS = "SET_LOGIN_PROGRESS";
export const SET_ATTRIBUTES_LOADED = "SET_ATTRIBUTES_LOADED";
export const SET_THEME = "SET_THEME";
export const SET_EDIT_ITEM = "SET_EDIT_ITEM";
export const SET_DRAWER_STATE = "SET_DRAWER_STATE";
