import React from 'react';
import { useDrag } from 'react-dnd';
import { makeStyles, withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { withApollo } from '@apollo/client/react/hoc';
import notFound from '../../../../assets/images/not-found.png';
import Chip from '@mui/material/Chip';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import styled from 'styled-components';
import { getFileConfig } from '../../../../js/helpers/files';
import { DOCUMENTS, VIDEOS } from '../../../../js/constants/medias-types';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import OurTooltip from '../../../../components/ui/tooltip/Tooltip';

const WrapperName = styled(Box)`
    width: 120px;
    height: 30px;
    overflow: hidden;
    text-align: center;
    overflow-wrap: break-word;
`;

const CustomChip = withStyles((theme) => ({
    label: {
        textOverflow: "ellipsis",
        maxWidth: 116
    }
}))(Chip);

const VideoPlaceholder = styled.video`
    position:absolute;
    top:50%;
    left:50%;
    height:90%;
    width:auto;
    max-width:100%;
    transform : translate(-50%,-50%);
`;

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateRows: '1fr auto',
        gridTemplateColumns: '100%',
        height: ({ height }) => height ? height + 30 : 'auto', // Since the text is inside the image container, just add 28px to the height (text bar height)
        border: '1px solid transparent',
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
        margin: 'auto',
        backgroundSize: 'auto 70%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 50% top 35%;',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #d2edf9',
        backgroundColor: 'white',
    },
    selected: {
        border: '2px solid #4caf50'
    },
    selectedRed: {
        border: '2px solid #e00000'
    },
    check: {
        position: 'absolute',
        top: 5,
        right: 5,
        color: '#4caf50'
    },
    chip: {
        position: 'absolute',
        top: 10,
        left: 10
    }
}));

function ItemCard ({ style, selected, setSelected, item, product, simpleProduct, onRemove, xs, allowedTypes, height }) {
    const classes = useStyles({
        height
    });

    const [collectedProps, drag] = useDrag({
        item: { id: product ? 'product' : 'media', type: product ? 'product' : 'media', media: item }
    });

    let isSelected = product 
        ? selected && selected.id === item.id
        : selected && selected.id === item.node.id;

    let name    = null;
    let image   = null;

    // todo: handle translations / or use new product selector

    if (product) {
        if (simpleProduct) {
            let allNames    = item.attributes.product_name;
            name            = allNames?.['fr_FR'] ?? item.sku;

            let allImages   = item.attributes.product_image;
            image           = allImages?.['fr_FR'] ?? null;
        } else {
            const datas = item.productDatas.edges;

            let allNames    = datas.filter(e => e.node.attribute.identifier === 'product_name');
            name            = allNames[0];
        
            let allImages   = datas.filter(e => e.node.attribute.identifier === 'product_image');
            image           = allImages[0];
        }
    }
    return (
        <Grid 
            item 
            xs={6}
            sm={4}
            md={3}
            className={classes.root}
            key={`media-${product ? item.id : item.node.id}`}
            ref={drag}
        >
            <Paper 
                className={`${classes.paper} ${isSelected ? product ? classes.selectedRed : classes.selected : ''}`} 
                style={{
                    ...style,
                    alignItems: 'flex-end',
                    width: isSelected ? '100%' - 4 : '100%',
                    height: isSelected ? '100%' - 4 : '100%',
                    backgroundImage: 
                        DOCUMENTS.includes(item.node?.type) 
                            ? `url(${getFileConfig(item.node.type).image})`
                            : product 
                                ? simpleProduct
                                    ? `url('${process.env.REACT_APP_MEDIAS}/${image}')`
                                    : image?.node.media?.filePath ? `url('${process.env.REACT_APP_MEDIAS}/${image.node.media.filePath}')` : `url('${notFound}')`
                                : item?.node.filePath ? `url('${process.env.REACT_APP_MEDIAS}/${item.node.filePath}')` : `url('${notFound}')`,
                    backgroundSize: allowedTypes?.includes(item.node?.type) ? '60%' : "100%",
                }} 
                onClick={() => {
                    setSelected(isSelected ? null : product ? item : item.node);
                }}
            >
                {
                            item !== undefined?
                            item.node.type === 'video/mpeg' ||item.node.type === 'video/webm'|| item.node.type === 'video/mp4'?
                                <VideoPlaceholder>
                                    <source 
                                        src={process.env.REACT_APP_API_ROOT + '/medias/' + item.node.filePath} 
                                        type={item.node.type}
                                    />
                                </VideoPlaceholder>
                            :null:null
                }
                { 
                    isSelected 
                        ? product 
                            ? <Tooltip 
                                title="Supprimer" 
                                aria-label="delete"
                            >
                                <CancelIcon 
                                    className={classes.check} 
                                    style={{ 
                                        color: '#e00000' 
                                    }} 
                                    fontSize="large" 
                                    onClick={() => {
                                        if (onRemove)
                                            onRemove(item);
                                    }} 
                                /> 
                            </Tooltip>
                            : <CheckCircleIcon 
                                className={classes.check} 
                                fontSize="large" 
                            />
                        : null
                }

                { 
                    product ? (
                        <CustomChip 
                            size="small" 
                            label={ simpleProduct ? name : name ? name.node.value : item.sku } 
                            className={classes.chip} 
                            style={{ 
                                top: isSelected ? 8 : 10,
                                left: isSelected ? 8 : 10
                            }} 
                        />
                    ) : null
                }
                {
                    product ? null : (
                        <Grid 
                            container 
                            justifyContent="left"
                        >
                            <OurTooltip title={item.node.name ?? item.node.filePath}>
                                <WrapperName 
                                    mt={1} 
                                    style={{
                                        height: '20px',
                                        margin: 'auto',
                                        width:'calc(100% - 16px)',
                                        overflow: 'hidden',
                                        textAlign: 'center',
                                        overflowWrap: 'break-word',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}>
                                    <Typography 
                                        style={{
                                            padding: 8,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            width: '100%',
                                        }}
                                        variant="h6" 
                                    >
                                        {item.node.name ?? item.node.filePath}
                                    </Typography>
                                </WrapperName>
                            </OurTooltip>
                        </Grid>
                    )
                }
            </Paper>
        </Grid>
    );
}

export default withApollo(ItemCard);