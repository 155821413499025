import styled from 'styled-components';
import colors from '@config/theme/colors';

export const Container = styled.div`
  width: 100%;
  height: maxContent;
  overflow-y: hidden;
`;

export const FormContainer = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: ${colors.background.white.primary};
`;

export const Separator = styled.div`
  height: 1px;
  border: 0.5px solid ${colors.grey.border};
  width: 100%;
`;