import store from '../redux/store';
import {client} from '../../index'
import * as moment from 'moment';
import { START_LOADING, STOP_LOADING, SNACK, SET_LOCALES, SET_ATTRIBUTES, SET_GUIDELINE } from '../constants/action-types';
import {addAttributesQueries,addQueries,updateQueries,updateAttributesQueries,getQueries} from '../constants/queries-type';
import {GET_EAV_TYPES, GET_ATTRIBUTES_BY_TYPE} from '../../queries/attributes';
import {GET_LOCALES} from '../../queries/locales';
import {GET_BRAND_GUIDELINE} from '../../queries/brand_guideline';

const props = store.getState();
const startLoading = () => store.dispatch({ type: START_LOADING });
const stopLoading = () => store.dispatch({ type: STOP_LOADING });
const snack = () => store.dispatch({ type: SNACK }) ;
const setLocales = (locales) => store.dispatch({ type: SET_LOCALES, payload: { locales } });
const setGuideline = (guideline) => store.dispatch({ type: SET_GUIDELINE, payload: { guideline } });
const setAttributes = (attributes) => store.dispatch({ type: SET_ATTRIBUTES, payload: { attributes } });

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function isset(el){
    let isSet= typeof el !== 'undefined';
    return isSet;
}

export function getTraductionAttributs(attributeIdentifier, data, lang, type) {
    if (!data?.length) return null;

    let nameAttributes  = data.filter(e => e.node.attribute.identifier === attributeIdentifier);
    let getTraduction   = nameAttributes.find(e => e.node.locale?.code === lang);
    let getOptionTrad   = null
    if(type === 'select'){
        getOptionTrad = getTraduction?.node.attributeOption.translation.translationDatas.edges.find(e => e.node.locale?.code === lang)
    }
    return( 
        type !== 'image'
            ? type !== 'select'
                ? type !== 'file' 
                    ? getTraduction?.node.value ?? nameAttributes[0]?.node.value
                    : getTraduction?.node.media ?? nameAttributes[0]?.node.media
            : getOptionTrad?.node.value ?? getTraduction?.node.attributeOption.translation.translationDatas.edges[0]?.node.value
        : getTraduction?.node.media ?? nameAttributes[0]?.node.media
    )
}

export async function prepareAttributes(type){
    let attributes = props.attributes[type].attributes.edges;
    let isSystemAttributes  = attributes.filter(e => e.node.isSystem);
    let customAttributes = attributes;
    return await {
        'isSystemAttributes' : isSystemAttributes,
        'customAttributes' : customAttributes
    }
}

export const prepareAttributeValues = async (type,isEdit = false,current=null) => {
    let {customAttributes,isSystemAttributes} = await prepareAttributes(type);
    let dataArray = type+'Datas';
    let langArray = {};
    for (let locale of props.locales) {
        let values = {};

        for (let attribute of customAttributes) {
            if (attribute.node.attributeType.input === 'select') {
                if (attribute.node.attributeOptions.edges.length){
                    values[attribute.node.identifier] = attribute.node.attributeOptions.edges[0].node.id
                }
            }
            if(isEdit && current){
                let translated = getTraductionAttributs(attribute.node.identifier,current[dataArray].edges,locale.node.code,attribute.node.attributeType.input)
                if(translated){
                    values[attribute.node.identifier]=translated;
                }
            }
        }
        langArray[locale.node.code]={...values};
    }
    let result = {
        'customAttributes' : customAttributes,
        'isSystemAttributes':isSystemAttributes,
        ...langArray,
    }
    return await result
}

export const saveAttributes = async (states,identifier,id,options,action='create',currentData=null) =>{
    let query = action === 'create' ? addAttributesQueries[identifier] : updateAttributesQueries[identifier];
    let attributes = states.isSystemAttributes;
    for (let attribute of attributes) {
        for (let locale of props.locales) { 
            let formValue   = states[locale.node.code][attribute.node.identifier];
            let isMedia     = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';

            if (formValue) {

                let variables = {
                    "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                    "attribute": attribute.node.id,
                    "locale": locale.node.id
                };
                variables[identifier] = id;


                if (isMedia){
                    variables.media = formValue.id;
                }
                    
                
                if (!isMedia)
                    if (attribute.node.attributeType.input !== 'select')
                            variables.value = formValue;
                    
                if(action==='edit' && currentData){
                    let translation = currentData.find((e)=>{
                        if(e.node.attribute.id === attribute.node.id && e.node.locale.id === locale.node.id){
                            return true;
                        }else{
                            return false
                        }
                    });
                    if(translation){
                        variables.id = translation.node.id;
                    }
                }

                await client.mutate({
                    mutation: query,
                    variables
                })

            }
        }
    }
}

/**
 * Enregistre un élèment en gql
 * @param {Object} states - States du composant
 * @param {string} identifier - Type d'élèment à sauvegarder
 * @param {Object} variables - Variables pour la sauvegarde
 * @param {Object} options - Options supplémentaires : {enableLoad : bool , setAttributes : bool , setDates : bool ,states : {Object}} (optionnel)
 * @returns {Object} - Element ajouté
 */
export const saveElement = async (identifier,variables,options)=>{
    let setDates = isset(options.setDates) ? options.setDates : true ;
    let setAttributes = isset(options.setAttributes) ? options.setAttributes : false ;
    let enableLoad = isset(options.enableLoad) ? options.enableLoad : true;

    if(enableLoad)
        startLoading();

    let mutation = addQueries[identifier];

    if(setDates){
        variables['createdAt'] = moment().format('YYYY-MM-DD');
        variables['updatedAt'] = moment().format('YYYY-MM-DD');        
    }

    let ADD_ITEM = await client.mutate({
        mutation : mutation,
        variables : variables,
        fetchPolicy: 'no-cache'
    }).catch((e) => {
        return;
    })

    let newItem =  ADD_ITEM?.data['create'+capitalize(identifier)][identifier];

    if(setAttributes){
        await saveAttributes(options.states,identifier,newItem.id);

    }

    if(enableLoad)
        stopLoading();
    
    if(options.success)
        snack();

    return newItem
}

/**
 * 
 * @param {Object} states - States du composant
 * @param {string} identifier - Type d'élèment à sauvegarder
 * @param {Object} variables - Variables pour la sauvegarde
 * @param {Object} currentDatas - Datas de l'élèment à mettre à jour (optionnel)
 * @param {Object} options - Options supplémentaires : {enableLoad : bool,setAttributes : bool, setDates : bool} (optionnel)
 * @returns {Object} - Element mis à jour
 */
export const updateElement = async (states,identifier,variables,currentDatas,options)=>{
    let setDates = isset(options.setDates) ? options.setDates : true ;
    let setAttributes = isset(options.setAttributes) ? options.setAttributes : false ;
    let enableLoad = isset(options.enableLoad) ? options.enableLoad : true;

    if(enableLoad)
        startLoading();
    let mutation = updateQueries[identifier];

    if(setDates){
        variables['updatedAt'] = moment().format('YYYY-MM-DD');        
    }

    let UPDATE_ITEM = await client.mutate({
        mutation : mutation,
        variables : variables,
        fetchPolicy: 'no-cache'
    }).catch((e) => {
        return;
    })

    let updatedItem = UPDATE_ITEM.data['update'+capitalize(identifier)][identifier];
    if(setAttributes){
        await saveAttributes(states,identifier,updatedItem.id,options,'edit',currentDatas);
    }

    if(enableLoad)
        stopLoading();
    
    if(options.success)
        snack();

    return updatedItem;
}


/**
 * Récupère des élèments
 * @param {string} identifier - Type d'élèments à récupérer
 * @param {Object} variables - Variables pour la récupération (optionnel)
 * @param {Object} options - Options supplémentaires : {enableLoad : bool} (optionnel)
 * @returns 
 */
export const getElements = async (identifier,variables,options)=>{
    let query = getQueries[identifier];
    let enableLoad = isset(options?.enableLoad) ? options?.enableLoad : false;

    if(enableLoad)
        startLoading();

    let GET_ELEMENTS = await client.query({
        query: query,
        variables: variables,
        fetchPolicy: 'no-cache'
    })        

    if(enableLoad)
        stopLoading();
    return GET_ELEMENTS
}

export const setRedux = async(isCRM, eavTypes, locales, guidelines)=>{
    let categoryTypes = [];
    const defaultEav = {attributes: {edges: []}};

    let attributesDefault = {
        eavTypes: [],
        category: defaultEav,
        product: defaultEav,
        content: defaultEav,
        company: defaultEav,
        customer: defaultEav
    };
    if (eavTypes){
        let dataTypes = await client.query({ query: GET_EAV_TYPES, fetchPolicy: 'no-cache' });
        const allTypes = dataTypes?.data?.eavTypes?.edges ?? [];
        let typesToFetch = allTypes.concat(['product', 'category', 'content']).filter(e => !e.node?.isSystem).map(e => e.node?.code ? e.node.code : e);
        if (isCRM)
            typesToFetch.push('company', 'customer')


        attributesDefault = {
            eavTypes: allTypes,
        };
        for (let type of allTypes) {
            if (~typesToFetch.indexOf(type.node.code)) {
                let typeData = await client.query({ query: GET_ATTRIBUTES_BY_TYPE, variables: { id: type.node.id }, fetchPolicy: 'no-cache' });
                categoryTypes.push(typeData.data.eavType);
                attributesDefault[type.node.code] = typeData.data?.eavType ?? defaultEav;
                setAttributes(attributesDefault);
                localStorage.setItem('ATTRIBUTES', JSON.stringify(attributesDefault));
            }
        }
    }

    if (locales){
        let dataLocales = await client.query({ query: GET_LOCALES, fetchPolicy: 'no-cache' });
        setLocales(dataLocales.data.locales.edges);
        localStorage.setItem('LOCALES', JSON.stringify(dataLocales.data.locales.edges));
    }

    if (guidelines){
        let dataGuideline = await client.query({ query: GET_BRAND_GUIDELINE, fetchPolicy: 'no-cache' });
        if (dataGuideline.data.guidelines.edges.length) {
            setGuideline(dataGuideline.data.guidelines.edges[0].node);
            localStorage.setItem('GUIDELINE', JSON.stringify(dataGuideline.data.guidelines.edges[0].node));
        }
    }
}