import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { BUILDER, BUILDER_ASSETS, VIEW, UPDATE } from '../../../../js/constants/constant-rights';
import { withTranslation } from 'react-i18next';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import userGroupsEdit from './config/userGroupsEdit.config';
import { eventService } from '../../../../js/services/event.service';
import { saveElement, updateElement } from '../../../../js/utils/functions';
import { listSettings, listMappers, perPageOptions, viewOptions } from './config/listUserGroups.config';
import Listing from '../../../layouts/Listing/Listing';
import { Box, Grid } from '@mui/material';
import * as moment from "moment";
import { DELETE_USERGROUP, deleteUserGroupMenuEntry } from '../../../../queries/user_groups';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '../../../ui/button/Button';
import { withApollo } from '@apollo/client/react/hoc';
import { checkRouting } from '../../../../js/utils/checkRouting';
import styled from 'styled-components';
import DialogModal from '../../../ui/dialog/DialogModal';
import { GET_MENU_ENTRIES } from '../../../../queries/menu_entries';

const LayoutWrapper = styled(Box)`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;

    .layout-wrapper{
        display: none;
    }
`;

function SettingsUsersGroups(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [formType, setFormType] = React.useState(null);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [reloadGroupUsers, setReloadGroupUsers] = React.useState(false);
    const [menuEntries, setMenuEntries] = React.useState([]);
    const [errors, setErrors] = React.useState({});
    const [states, setStates] = React.useState({
        id: null,
        code: null,
    });
    const [listGroups, setListGroups] = React.useState(null);
    const [userAccess, setUserAccess] = React.useState([]);
    const [oldUserAccess, setOldUserAccess] = React.useState([]);

    useEffect(() => {
        checkRouting(props);
        getMenuEntries()
    }, [])

    const goTo = route => {
        props.history.push(route);
    };

    const getMenuEntries = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_MENU_ENTRIES,
            }).then(result => {
                setMenuEntries(result.data.menuEntries.edges)
                resolve()
            }).catch(error => {
                resolve([])
            });
        })
    }

    useEffect(() => {
        if(listGroups){
            const list = [...listGroups]
            setOldUserAccess(prev => [...prev, ...setupUserAccess(list)])
            setUserAccess(prev => [...prev, ...setupUserAccess(list)])
        }
    }, [listGroups])

    const getGroupUsersCallback = (list) => {
        setReloadGroupUsers(false)
        setListGroups(list)
    }

    const handleToggleDrawer = (stateDrawer, reset) => {
        setOpenForm(!openForm)
        if (reset) {
            resetStates()
        }
    };
    const handleToggleDialog = (group = null) => {
        setOpenDialog(!openDialog)
        if (group?.id) setStates(prev => ({ 
            ...prev, 
            id: group.id ,
            group: group
        }))
    };

    const resetStates = () => {
        setStates({
            id: null,
            code: null,
        })
    }

    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const setupUserAccess = (listGroups) => {
        // Loop every group to populate its rights
        const userAccessStructure = listGroups.map((userGroup) => {
            const parentGroups = menuEntries?.filter(group => !group.node.parent);
            const childrenGroups = menuEntries?.filter(group => group.node.parent);
            
            // Loop every parent group
            const access = parentGroups?.map((parent, index) => {
                // Get its children list
                const childrenList = childrenGroups?.filter(child => child.node.parent.id === parent.node.id);
                
                // List of menu entries that the current group has rights on
                const hasRightsOnChildren = childrenList?.filter(child => {
                    const currentGroupHasRights = userGroup?.node.menuEntryRelations.edges?.find(group => group.node.menuEntry.id === child.node.id);
                    return currentGroupHasRights?.node.writeRight || currentGroupHasRights?.node.readRight;
                });

                return {
                    [`${parent.node.code}-parent`]: hasRightsOnChildren?.length > 0,
                    stateName: `${parent.node.code}-parent`,
                    id: (() => {
                        const currentGroup = userGroup?.node.menuEntryRelations.edges?.find(group => group.node.menuEntry.id === parent.node.id);
                        return currentGroup?.node.id || null;
                    })(),
                    children: (() => {
                        const children = childrenList?.map(child => {
                            const childWithRelations = userGroup?.node.menuEntryRelations.edges?.find(group => group.node.menuEntry.id === child.node.id);
                            // The user group has rights on this menu entry
                            if(childWithRelations){
                                return {
                                    id: childWithRelations?.node?.id,
                                    label: childWithRelations?.node.menuEntry.code,
                                    parent: `${parent.node.code}-parent`,
                                    writeAccess: childWithRelations?.node.writeRight,
                                    readAccess: childWithRelations?.node.readRight,
                                }
                            // The user group doesn't have rights on this menu entry
                            } else {
                                return {
                                    id: childWithRelations?.node?.id,
                                    label: child.node.code,
                                    parent: `${parent.node.code}-parent`,
                                    writeAccess: false,
                                    readAccess: false,
                                }
                            }
                        })
                        return children;
                    })()
                }
            });

            return {
                access: [...access],
                groupId: userGroup.node.id,
            };
        })

        return userAccessStructure
    }

    // Add an empty user access list for the creation form
    const setupNewUserAccess = () => {
        const parentMenuEntries = menuEntries.filter((menuEntry) => !menuEntry.node.parent);
        const childrenMenuEntries = menuEntries.filter((menuEntry) => menuEntry.node.parent);

        // Config by default of creation user access form
        const newUserAccess = [{
            isNew: true,
            access: parentMenuEntries.map((parentMenuEntry) => {
                const childrenList = childrenMenuEntries.filter(child => child.node.parent.id === parentMenuEntry.node.id);

                return {
                    [`${parentMenuEntry.node.code}-parent`]: false,
                    stateName: `${parentMenuEntry.node.code}-parent`,
                    children: childrenList.map((child) => {
                        return {
                            label: child.node.code,
                            parent: `${parentMenuEntry.node.code}-parent`,
                            writeAccess: false,
                            // readAccess: false,
                        }
                    })
                }
            }
            )
        }]

        setUserAccess(prev => ([...prev, ...newUserAccess]))
    }

    const switchCheckbox = (newUserAccess, group, clickedAccessInput) => {
        // It is a parent menu entry
        if(clickedAccessInput?.title?.stateName){
            const foundMenuEntry = group.find((item) => item.stateName === clickedAccessInput.title.stateName);

            foundMenuEntry[clickedAccessInput.title.stateName] = !foundMenuEntry[clickedAccessInput.title.stateName];

            foundMenuEntry.children = foundMenuEntry.children.map((child) => {
                return {
                    ...child,
                    writeAccess: foundMenuEntry[clickedAccessInput.title.stateName],
                    // readAccess: foundMenuEntry[clickedAccessInput.title.stateName],
                }
            })

            setUserAccess(newUserAccess);
        } else {
            const foundMenuEntry = group.find((item) => item.stateName === clickedAccessInput.parent);
            const foundMenuEntryChild = foundMenuEntry?.children?.find((child) => child.label === clickedAccessInput.label);
            foundMenuEntryChild.writeAccess = !foundMenuEntryChild.writeAccess;

            if(foundMenuEntry.children.filter((child) => child?.writeAccess || child?.readAccess).length === 0) {
                foundMenuEntry[clickedAccessInput.parent] = false;
            } else {
                foundMenuEntry[clickedAccessInput.parent] = true;
            }

            setUserAccess(newUserAccess)
        }
    }

    // StateName and translated are autoimported
    const stateCallback = (stateName, evt, custom, translated) => {
        const getState = { ...states };
        const value = evt?.target?.value ?? evt;

        const clickedAccessInput = custom?.clickedAccessInput

        // When the user access is the parent
        if(clickedAccessInput?.type === 'userAccess' && custom?.isParent){
            if(formType === 'edit'){
                const userAccessCopy = [...userAccess];
                const currentUserAccess = userAccessCopy.find(group => group.groupId === states.id)?.access;
                switchCheckbox(userAccessCopy, currentUserAccess, clickedAccessInput)
            } else if (formType === 'add') {
                const userAccessCopy = [...userAccess];
                const creationAccess = userAccessCopy.find((item) => item.isNew)?.access;
                switchCheckbox(userAccessCopy, creationAccess, clickedAccessInput)
            }
        // When the user access is a child (read/write)
        } else if(clickedAccessInput?.type === 'checkbox' && !custom?.isParent){
            if(formType === 'edit') {
                const userAccessCopy = [...userAccess];
                const creationAccess = userAccessCopy.find((group) => group.groupId === states.id)?.access;
                switchCheckbox(userAccessCopy, creationAccess, clickedAccessInput)
            } else if (formType === 'add') {
                const userAccessCopy = [...userAccess];
                const creationAccess = userAccessCopy.find((item) => item.isNew)?.access;
                switchCheckbox(userAccessCopy, creationAccess, clickedAccessInput)
            }
        } else {
            getState[stateName] = value?.target?.value ?? value;
            setStates(getState)
        }

    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(errors)
    };

    const editHandler = (userGroup) => {
        setFormType('edit')
        let getState = { ...states };
        getState['code'] = userGroup.code;
        getState['id'] = userGroup.id;
        setStates(getState);
        handleToggleDrawer('openForm');

    }
    const initForm = () => {
        setFormType('add')
        handleToggleDrawer('openForm');
    }

    const deleteMutation = async () => {
        if(states?.group?.users.edges.length === 0) {
            try {
                props.startLoading();
    
                // await states?.group?.menuEntryRelations?.edges?.forEach(async (menuEntry) => {
                //     await props.client.mutate({
                //         mutation: deleteUserGroupMenuEntry,
                //         variables: { id: menuEntry.node.id }
                //     })
                // })
    
                const result = await props.client.mutate({
                    mutation: DELETE_USERGROUP,
                    variables: { id: states.id }
                })
    
                props.stopLoading();
                props.snack(ALERT_SUCCESS, 'Groupe supprimé avec succès');
                setReloadGroupUsers(true);
                handleToggleDialog();
            } catch (error) {
                props.stopLoading();
                props.snack(ALERT_ERROR, `Impossible de supprimer le groupe`);
                setReloadGroupUsers(true);
                handleToggleDialog();
            }
        } else {
            handleToggleDialog();
            props.snack(ALERT_ERROR, `Veuiller supprimer les utilisateurs du groupe avant de le supprimer`);
        }
    }

    const formatMenuEntriesAccess = (access, isEdit) => {
        const parentMenuEntries = access.map((item) => ({
            id: item?.id,
            label: item?.stateName.replace('-parent', ''),
            isParent: true,
            hasAccess: item[item?.stateName] ?? false,
        }))
        const childrenMenuEntries = access.map((item) => {
            return item?.children?.map((child) => ({
                id: child?.id,
                label: child?.label,
                isParent: false,
                readRight: child?.readAccess ?? false,
                writeRight: child?.writeAccess ?? false,
            }))
        })

        const menuEntriesCodes = [...parentMenuEntries, ...childrenMenuEntries].flat().filter((item) => item !== undefined);
        if(isEdit){
            return menuEntriesCodes.filter(item => item?.id !== undefined);
        }
        return menuEntriesCodes;
    }
            

    const handlerMutation = async () => {
        props.startLoading()
        let variables = null;
        if (formType === "add") {
            variables = {
                code: states.code,
                createdAt: moment().format(),
                updatedAt: moment().format(),
                menuEntry: [],
            }
            try {
                const createElement = await saveElement('userGroup', variables, { enableLoad: false })
                // Add relations with menu entries createElement?.id
                // Get number of menu entries to create
                const menuEntriesAccess = userAccess.find((item) => item?.isNew)?.access;
                const menuEntriesCodes = formatMenuEntriesAccess(menuEntriesAccess, false);

                // Create menu entries
                await Promise.all(menuEntriesCodes?.map(async (code) => {
                    const menuEntryId = menuEntries.find((menuEntry) => menuEntry?.node?.code === code.label)?.node?.id;
                    // Create relation with user group
                    if(code?.isParent) {
                        await saveElement('userGroupMenuEntry', { userGroup: createElement?.id, menuEntry: menuEntryId, readRight: code?.hasAccess, writeRight: code?.hasAccess }, { enableLoad: false })
                    } else {
                        await saveElement('userGroupMenuEntry', { userGroup: createElement?.id, menuEntry: menuEntryId, readRight: code?.readRight, writeRight: code?.writeRight }, { enableLoad: false })
                    }
                }))

                props.snack(ALERT_SUCCESS, 'Groupe d\'utilisateur ajouté avec succès');
                if (createElement) {
                    props.stopLoading()
                    handleToggleDrawer('openForm', true);
                    setUserAccess(prev => prev.filter(item => !item.isNew))
                    setReloadGroupUsers(true)
                }
            } catch {
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du groupe d\'utilisateur');
            }
        } else {
            variables = {
                id: states.id,
                code: states.code,
                updatedAt: moment().format()
            }
            try {
                const updateElementResult = await updateElement(states, 'userGroup', variables, null, { enableLoad: false })
                const currentMenuEntries = userAccess.find((item) => item?.groupId === states.id)?.access;
                const oldMenuEntries = setupUserAccess(listGroups)?.find((item) => item?.groupId === states.id)?.access;
                
                const oldMenuEntriesCodes = formatMenuEntriesAccess(oldMenuEntries, true);
                const menuEntriesCodes = formatMenuEntriesAccess(currentMenuEntries, true);

                const menuEntriesToUpdate = menuEntriesCodes.filter((item) => {
                    const oldMenuEntry = oldMenuEntriesCodes.find((oldItem) => oldItem?.label === item?.label);
                    return oldMenuEntry?.readRight !== item?.readRight || oldMenuEntry?.writeRight !== item?.writeRight || oldMenuEntry?.hasAccess !== item?.hasAccess;
                })

                await Promise.all(menuEntriesToUpdate?.map(async (code) => {
                    const menuEntryId = menuEntries.find((menuEntry) => menuEntry?.node?.code === code.label)?.node?.id;

                    // Create relation with user group
                    if(code?.isParent) {
                        await updateElement(null, 'userGroupMenuEntry', { id: code?.id, userGroup: updateElementResult?.id, menuEntry: menuEntryId, readRight: code?.hasAccess, writeRight: code?.hasAccess }, null, { enableLoad: false })
                    } else {
                        await updateElement(null, 'userGroupMenuEntry', { id: code?.id, userGroup: updateElementResult?.id, menuEntry: menuEntryId, readRight: code?.readRight, writeRight: code?.writeRight }, null, { enableLoad: false })
                    }
                }))

                props.snack(ALERT_SUCCESS, 'Groupe d\'utilisateur modifié avec succès');
                if (updateElementResult) {
                    props.stopLoading()
                    handleToggleDrawer('openForm', true);
                    setReloadGroupUsers(true)
                }
            } catch (e) {
                console.log('e', e)
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification du groupe d\'utilisateur');
            }
        }
    }

    return (
        <LayoutWrapper>
            <TopPanel
                icomoon={"ico_gestion_acces"}
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t("settings.userGroups.title")}
                subtitle={'Gestion de vos groupe d’utilisateurs et gestion d’accès'}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                openForm={openForm}
                currentLang={currentLang}
                handleLang={handleLang}
                locales={props.locales}
                handlerAdd={() => {
                    initForm()
                    setupNewUserAccess()
                }}
                textAdd={"Ajouter un groupe"}
                hasBorder={true}
                buttonAvailable={true}
            />
            <>
                <Grid container>
                    <Listing
                        label='header'
                        settings={listSettings}
                        cardProps={{
                            openForm: openForm,
                            currentLang: currentLang,
                            textButton: 'Gérer le groupe',
                            handlerButton: editHandler,
                            handlerMenu: {
                                toggleDeleteDialog: handleToggleDialog,
                                // toggleDuplicateDialog: () => {}
                            },
                            windowWidth: props.windowWidth,
                        }}
                        perPageOptions={perPageOptions}
                        enableFilters={false}
                        enablePagination={false}
                        enableChangeView={false}
                        enableChangeSort={false}
                        mappers={listMappers}
                        currentLang={currentLang}
                        identifier='userGroups'
                        // queryVariables={{}}
                        viewsOptions={viewOptions}
                        reload={reloadGroupUsers}
                        setReload={setReloadGroupUsers}
                        listingCallback={getGroupUsersCallback}
                        pagination={true}
                    />
                </Grid>
                <LayoutBuilder
                    isSublayout={false}
                    icomoon="ico_gestion_acces"
                    opened={openForm}
                    forClose={() => {
                        handleToggleDrawer('openForm', true)
                        if(formType === 'add'){
                            setUserAccess(prev => prev.filter(item => !item.isNew))
                        } else if (formType === 'edit') {
                            setUserAccess([...oldUserAccess])
                        }
                    }}
                    handlerSetup={() => { }}
                    dataLayout={userGroupsEdit(props, formType, states, listGroups, menuEntries, userAccess)}
                    drawerWidth={"50vw"}
                    allState={
                        {
                            userAccess: userAccess?.find(item => item.groupId === states.id) ?? userAccess?.find(item => item.isNew),
                            ...states
                        }
                    }
                    stateCallback={stateCallback}
                    errorCallback={handleFormError}
                    validateButton={true}
                    handlerMutation={handlerMutation}
                />
                <DialogModal
                    icon={true}
                    type='delete'
                    open={openDialog}
                    onClose={handleToggleDialog}
                    title={'Êtes-vous sûr de vouloir supprimer ce groupe d\'utilisateur ?'}
                    primaryAction={deleteMutation}
                    secondaryAction={handleToggleDialog}
                    windowWidth={props.windowWidth}
                >
                    <DialogContentText id="alert-dialog-description">
                        Si vous supprimez ce groupe, celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                    </DialogContentText>
                </DialogModal>
            </>
        </LayoutWrapper>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsUsersGroups))));