import React from "react";
import { useDrop } from 'react-dnd';

import Pg from '../../../../../shared/components/CustomParagraph';
import H1 from '../../../../../shared/components/CustomH1';

const MiddleBoxLoose = (props) => {

    let {data, preview, inputCallback, component = null, update = null, toolbarBlock, hover } = props;

    let image = data.inputs['backgroundImage'].value;
    return(
        <div
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundPosition: 'bottom center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                padding: data.blocks.image?.inputs?.value?.value ? '0 70px 10px' : null
            }}
        >
            { !preview && hover && toolbarBlock }
            {
                data.blocks.image?.inputs?.value?.value ? (
                    <img 
                        src={
                            typeof data.blocks.image?.inputs?.value?.value === 'string' ? 
                                data.blocks.image.inputs.value.value : 
                                data.blocks.image?.inputs?.value?.value?.filePath ? 
                                    ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` 
                                    : null
                        }
                        style={{ 
                            margin: 'auto',
                            width: '100%',
                            maxWidth: 360,
                            display: '  block',
                        }}
                        alt="Décoration jeu"
                    />
                ) : null
            }
            <Pg data={data} preview={preview} component={component} block={'text'} inputCallback={inputCallback} nopadding={true} />
            <H1 data={data} preview={preview} component={component} block={'nameDotation'} inputCallback={inputCallback} nopadding={true} />
            {
                data.blocks.reglement.inputs.value.value ? (
                    <a className="reglement">
                        <Pg data={data} preview={preview} component={component} block={'reglement'} inputCallback={inputCallback} nopadding={true} />
                    </a>
                ) : null
            }
        </div>
    )
};

export default MiddleBoxLoose;