import CardCatalog from '../../../../../layouts/Card/cardContent/CardCatalog';
import noImageFlux from '../../../../../../assets/pictos/empty-picto/empty_flux.png';
import { FormInputTypes } from '../../../../../../builder/shareable/types';

export const listSettings = {
    table : {
        style : {
            type : 'striped',
        },
        action:{
            show: true,
            label: ''
        },
        header:{
            show:false
        },
        columns : [
            {
                label   : 'Status',
                field   :  'status',
                type : 'status',
                cellType:"status",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Date de création',
                field   :  'importedAt',
                type:"date",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                    formatDate : 'DD/MM/YYYY à HH:mm',
                    fontWeight: "bold",
                }
            },
            {
                label   : 'Utilisateurs',
                field   :  'user',
                type: "default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                    fontWeight: "bold",
                }
            },
            {
                label   : 'type',
                field   :  'type',
                type: "default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Nom du fichier',
                field   :  'url',
                type: "default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                    fontWeight: "bold",
                }
            },
            {
                label   : 'Nombres items',
                field   :  'totalImported',
                type: "default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
        ]   
    }
}

export const listMappers = [
    {
        view: 'table',
        mapper: "importsTable"
    },
]

export const perPageOptions = {
    'table': [10],
}

export const viewOptions = {
    current : 'table',
    settings: [
        {
            label: "Liste",
            value:"table"
        }
    ]
}

export const filtersOptions = {
    title: 'Filtres et Recherche',
    inputs: [
        {
            xs:3,
            required: false,
            disabled: false,
            topLabel:"Par statut",
            label:'Recherche par SKU, nom, ect …',
            type: FormInputTypes.SELECT,
            stateName: 'status',
            helper: {
                position: 'top',
                text: null,
                link: false,
            },
            value:[
                {
                    label:"Tous les statuts",
                    value:'all',
                },
                {
                    label:"Actif",
                    value:"true",
                },
                {
                    label:"Inactif",
                    value:"false",
                },
            ]
        },
        {
            xs: 9,
            required: false,
            disabled: false,
            topLabel:"Taper votre recherche",
            label:'Recherche par nom',
            type: FormInputTypes.TEXT,
            stateName: 'libelle',
            helper: {
                position: 'top',
                text: null,
                link: false,
            }
        },
    ]
}