import React from 'react';
import Div from '../../../../shared/components/CustomDiv';

const RulesModal = ({
    isOpen,
    onClose,
    data
}) => {
    return(
            <>
                <div className="overlay_reglement" style={{display: isOpen ? 'block' : 'none'}} onClick={onClose}></div>
                <div className="text_reglement" style={{display: isOpen ? 'block' : 'none', backgroundColor: data.rulesColorBackground.value}}>
                    {
                        data.rulesModal.value ?
                        <div 
                            className={"content-reglement"} 
                            dangerouslySetInnerHTML={{ __html: data.rulesModal.value }}
                            style={{color: data.rulesColorText.value}}
                        />
                        : null
                    }
                    <div className="button_close">
                        <button className="as_button" onClick={onClose}>x</button>
                    </div>
                </div>
            </>
    )
}

export default RulesModal;