const userCardsMapper = async (flows) => {
  try {
    let results = flows.map((flow) => {
      return {
        id: flow.node.id,
        name: flow.node.lastname + flow.node.firstname,
        firstname: flow.node.firstname,
        lastname: flow.node.lastname,
        email: flow.node.email,
        image: flow.node.image,
        phone: flow.node.phone,
        job: flow.node.job,
      };
    });
    return await results;
  } catch (error) {
    console.log(error);
  }
};

export { userCardsMapper };

