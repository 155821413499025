const Fetch = (url, method = 'GET', data = null, type = 'application/json', auth = true) => {
    var headers = new Headers();
    const token = localStorage.getItem('AUTH_TOKEN');

    if (auth && token)
        headers.append('Authorization', `Bearer ${token}`);

    if (type === 'application/json')
        headers.append('Content-Type', type);

    var init = { 
        method,
        headers,
        mode: 'cors',
        body: data 
            ? type === 'application/json' 
                ? JSON.stringify(data) 
                : data 
            : null
    };

    return new Promise((resolve, reject) => {
        fetch(url, init)
            .then(response => {
                if (!response.ok)
                    return reject({ status: response.status, message: response.statusText });

                let data = response.json();
                
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    });
};

export default Fetch;