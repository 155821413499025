export const convertToRgb = (hex) => {
  const hexString = hex.replace("#", "");
  const r = parseInt(hexString.substring(0, 2), 16);
  const g = parseInt(hexString.substring(2, 4), 16);
  const b = parseInt(hexString.substring(4, 6), 16);
  return {
    r,
    g,
    b,
  };
};

export const convertToRgba = (hex, opacity) => {
  const hexString = hex.replace("#", "");
  const r = parseInt(hexString.substring(0, 2), 16);
  const g = parseInt(hexString.substring(2, 4), 16);
  const b = parseInt(hexString.substring(4, 6), 16);

  return {
    r,
    g,
    b,
    a: opacity,
  };
};

export const getIdFromPath = (url) => {
  return url.split("/").pop();
};

export const generateTempoId = () => {
  return new Promise((resolve, reject) => {
    let tempoId = Math.random().toString(36).substr(2, 9);
    resolve(tempoId);
  });
};
