import React, { useEffect, useState } from "react";
import { Toolbar } from '../../../../../../tools';
import { useDrop } from 'react-dnd';
import Label from '../../../../../shared/components/CustomLabel';
import Button from '../../../../../shared/components/CustomButton';

export default ({ data, preview, inputCallback, component = null, update = null, toolbar = null }) => {
    const [hover, setHover] = useState(false);

    let border = null;
    if (data.inputs.border) {
        border = data.inputs.border;
    }

    let image = data.inputs['backgroundImage'].value;


    return(
        <form 
            id="form_login" 
            method="POST"
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: 'relative',
                borderStyle: 'solid',
                borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && hover && toolbar }
            <Label data={data} preview={preview} component={component} block={'labelEmail'} inputCallback={inputCallback} nopadding={true} forInput="inputEmail" /><br />
            <input type="email" value="" style={{boxShadow: '-1px -1px 10px 1px #F0F7FA'}} name="email" id="inputEmail" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required autofocus /><br />
            <div>
                <input required id="consentement" type="checkbox" />
                <Label data={data} preview={preview} component={component} block={'labelConsent'} inputCallback={inputCallback} nopadding={true} forInput="consentement" />    
            </div>
            <Button data={data} preview={preview} component={component} block={'button'} inputCallback={inputCallback} className="button" nopadding={true} type="submit" />
        </form>
    )
};

