import React from "react";
import TableCell from '@mui/material/TableCell';
import styled from 'styled-components';

const ImageContainer = styled.div`
    height : ${props=> props.height};
    background-size : 100%;
    background-repeat : no-repeat;
    background-position : center;
    width:100%;
    background-image:url(${props => props.imageUrl});
`
function CellImage(props){
    let media = props.value ? `${process.env.REACT_APP_MEDIAS}/${props.value}` : props.media?.filePath ? `${process.env.REACT_APP_MEDIAS}/${props.media.filePath}` : props.noImage
    return(
        <TableCell 
            style={{
                width : props.width ? props.width :'auto',
                padding : '4px 16px',
                textAlign: 'center'
            }}
        >
            {props.imageHeight ? 
                <ImageContainer height={props.imageHeight} imageUrl={media} /> 
            : <img onClick={props?.column?.download?()=>window.open(media,"download"):null} src={media} style={{width:'auto', maxHeight: 50,cursor:props?.column?.download?'pointer' : "default"}}/>
            }
        </TableCell>
    )
}
export default CellImage;