import React from 'react';
import {Box, Grid} from '@mui/material';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCurrencies from '../../../layouts/Card/cardContent/CardCurrencies';
import ValidateTaxes from './validate/ValidateTaxes';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { withApollo } from '@apollo/client/react/hoc';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { ROUTE_SYSTEM_GROUPRATES } from '../../../../js/constants/route-names';
import { SNACK } from '../../../../js/constants/action-types';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { GET_TAXRATES } from '../../../../queries/tax_rates';
import { GET_RATE_GROUPS_PAGINATION } from '../../../../queries/rate_group';
import {withTranslation} from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import emptyProduct from '../../../../assets/pictos/empty-picto/empty_assets.png';
import styled from 'styled-components';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PageWrapper = styled(Box)`
    position: relative;
    min-height: calc(100% - 64px);
    height: calc(100% - 64px);
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
`;

class SettingsTaxRates extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            NbTaxes: 0,
            NbTaxesTotal: 0,
            NbTaxesInitial: 0,
            changes: [],
            taxes: [],
            existentTaxes: [],
            validate: false,
            noTax: true,
            loading: true,
            textSnack: "La suppression de la taxe s'est déroulée avec succès",
            typeSnack: 'success',
            openSnack: false,
        }
    }
    // handleInputChange = (stateName, evt, id) => {
    //     const value = evt.target.value;
    //     this.setState({
    //         [`${stateName}-${id}`]: value
    //     });
    // };

    handleGetTaxes(){
        this.props.client.query({
            query: GET_TAXRATES,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.setState({
                taxes: []
            })
            this.props.client.query({
                query: GET_RATE_GROUPS_PAGINATION,
                fetchPolicy: 'no-cache'
            }).then(resultGroupRates => {
                this.setState({
                    allGroupRates: resultGroupRates.data.rateGroups.edges
                })
                if(result.data.taxRates.edges.length > 0){
                    this.setState({
                        existentTaxes: result.data.taxRates.edges,
                        NbTaxesInitial: result.data.taxRates.edges.length,
                        NbTaxesTotal: result.data.taxRates.edges.length,
                        noTax: false
                    }, () => {
                        this.setState({loading: false}, () => {
                            this.forceUpdate();
                        });
                    });
                }
                else{
                    this.setState({loading: false}, () => {
                        this.forceUpdate();
                    });
                }
            }).catch((err)=>{
                console.log(err)
            });
        }).catch((err)=>{
            console.log(err)
        });
    }

    handleAddTax = () => {
        this.setState({
            NbTaxesTotal: this.state.NbTaxesTotal + 1??0,
        }, () => {
            this.setState({
                validate: false,
                taxes: [...this.state.taxes,{
                    "code": '',
                    "rate" : '',
                    "countryCode" : '',
                    "rateGroup" : {id: ''},
                    "id": this.state.NbTaxesTotal,
                }]
            });
            this.forceUpdate();
        });
    }

    handleRemoveExistentTax = () => {
        if(this.state.NbTaxesInitial === 1){
            this.setState({
                NbTaxesInitial: 0,
                noTax: true,
            });
        } else {
            this.setState({
                NbTaxesInitial: this.state.NbTaxesInitial - 1??0,
            });
        }
        this.setState({openSnack: true})
        this.handleGetTaxes();
        this.forceUpdate();
    }

    handleRemoveTax = (index) => {
        this.setState({
            NbTaxesTotal: this.state.NbTaxesTotal - 1??0,
        });

        let i = 0;
        for (let currency of this.state.taxes) {
            if(i>index){
                currency.id = currency.id - 1;
            }
            i++;
        }
        this.setState({
            taxes : [...this.state.taxes].splice(index,1)
        })
        this.forceUpdate();
    }

    handleResetData = () => {
        this.handleGetTaxes();
        this.setState({changes: []})
    }

    handleCheckValidate = () => {
        let changes = this.state.changes;
        let taxes = this.state.taxes;
        let success = true;
        for (let item of changes) {
            if (success) {
                if(item.data.code === '' || item.data.rate === '' || item.data.countryCode === ''){
                    success = false;
                }
            }
            
        }
        for (let tax of taxes) {
            if (success) {
                if(tax.code === '' || tax.rate === '' || tax.countryCode !== ''){
                    success = false;
                }
            }
        }
        if(success){
            this.setState({validate: true});
        }
        else{
            this.setState({validate: false});
        }
    }

    componentDidMount = () => {
        checkRouting(this.props);
        this.handleGetTaxes();
    }

    handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openSnack: false});
    };


    render() {
        return (
            <PageWrapper>
                <TopPanel 
                title={this.props.t('settings.tax.title')} 
                subtitle={this.props.t('settings.tax.subTitle')} 
                handlerAdd={!this.state.loading ? this.handleAddTax : null} 
                textAdd={this.props.t('settings.tax.buttonadd')}
                handlerImport={!this.state.loading ? () => this.goTo(ROUTE_SYSTEM_GROUPRATES) : null} 
                textImport={this.props.t('settings.taxGroup.seeMore')}
                buttonAvailable={true}
                hasBorder={true}
                windowWidth={this.props.windowWidth}
                />
                <Box style={{
                    position: 'relative',
                }}>
                    <Grid container direction="column" justifyContent="center" spacing={0} style={{marginTop: 16}}>
                        <Grid container direction="row" spacing={4}>
                            {
                                !this.state.loading ?
                                    (
                                        this.state.noTax ? 
                                            this.state.taxes.length === 0 ? 
                    
                                                (<EmptyCard title={this.props.t("catalogs.catalogs.noResults")} textButton={this.props.t('settings.tax.buttonadd')} onClick={this.handleAddTax} picto={emptyProduct} mtImg={2} />) 
                                            :
                                                null
                                        : 
                                            (
                                                this.state.existentTaxes.map((tax, i) => {
                                                    return(
                                                        <React.Fragment key={`ContainerTax${i}`}>
                                                            <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                                                <Typography>Taxe n°{i+1}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <CardCustom paddingbottom="8px" paddingtop="32px">
                                                                    <CardCurrencies
                                                                        windowWidth={this.props.windowWidth}
                                                                        type="existing"
                                                                        handleInputChange = {(data) => {
                                                                            let changes = [...this.state.changes];
                                                                            let found = false;
                                                                            for (let item of changes) {
                                                                                if (item.id === data.id) {
                                                                                    item.data = data.data;
                                                                                    found = true;
                                                                                }
                                                                            }
                                                                            this.handleCheckValidate();
                                                                            if (!found)
                                                                                changes.push(data);
                                                                            
                                                                            this.setState({ changes: [].concat(changes) });
                                                                        }} 
                                                                        handleRemove={this.handleRemoveExistentTax} 
                                                                        currency={tax.node}
                                                                        disabled={null}
                                                                        typeTax={true}
                                                                        allGroupRates={this.state.allGroupRates}
                                                                    />
                                                                </CardCustom>
                                                            </Grid>
                                                        </React.Fragment>
                                                    )
                                                })
                                            )
                                    ) 
                                    : 
                                    (<PageLoader />)
                            }
                    
                            {
                                this.state.taxes.map((tax, index) =>
                                    <React.Fragment key={`ContainerTax${index}`}>
                                        <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                            <Typography>Taxe n°{tax.id}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardCustom paddingbottom="8px" paddingtop="32px">
                                                <CardCurrencies
                                                    windowWidth={this.props.windowWidth}
                                                    type="new"
                                                    handleInputChange={(data) => {
                                                        for (let tax of this.state.taxes) {
                                                            if (tax.id === data.id) {
                                                                tax.code = data.data.code;
                                                                tax.rate = data.data.rate;
                                                                tax.countryCode = data.data.countryCode;
                                                                tax.rateGroup = data.data.rateGroup;
                                                                this.handleCheckValidate();
                                                            }
                                                        }
                                                    }} 
                                                    handleRemove={() => this.handleRemoveTax(index)} 
                                                    currency={tax}
                                                    typeTax={true}
                                                    allGroupRates={this.state.allGroupRates}
                                                />
                                            </CardCustom>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                        </Grid>
                    </Grid>
                    {
                        this.state.existentTaxes.length > 0 || this.state.taxes.length > 0 ? (
                            <ValidateTaxes newTaxes={this.state.taxes} changesTaxes={this.state.changes} validate={this.state.validate} handleResetData={this.handleResetData} />
                        ) : null
                    }
                    
                    
                    <Snackbar open={this.state.openSnack} autoHideDuration={10000} onClose={this.handleCloseSnack}>
                        <div>
                            <Alert onClose={this.handleCloseSnack} severity={this.state.typeSnack}>
                                {this.state.textSnack}
                            </Alert>
                        </div>
                    </Snackbar>
                </Box>
            </PageWrapper>
        );
    }
    goTo = route => this.props.history.push(route);
}
const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withTranslation()(withRouter(withApollo(connect(null, mapDispatchToProps)(SettingsTaxRates))));