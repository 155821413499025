import { FORM } from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import { CURRENCIES, LOCALES, SYSTEMS } from '../constants/settings';

const SystemInfosConfig = (item, locales, rowsState) => {
  const isAlreadySelected = (searchedId, stateName) => {
    const rowStates = rowsState?.filter((rowState) => !rowState.delete).map((rowState) => rowState.states)
    const mappedIdsByStateName = rowStates?.flat().map((row) => row[stateName]).filter((option) => option)
    
    return mappedIdsByStateName?.includes(searchedId)
  }

  // Inputs by row
  const rowSchema = {
    [SYSTEMS.CURRENCY]: {
      title: 'Devise',
      inputs: [
        {
          xs: 6,
          type: 'select', 
          isContainerized: true,
          required: true,
          stateName: 'type',
          value: CURRENCIES?.map((currency) => ({
            value: currency.code,
            label: currency.libelle,
            disabled: isAlreadySelected(currency.code, 'type')
          }))
        },
        {
          xs: 6,
          type: 'text',
          required: false,
          disabled: true,
          stateName: 'code',
        }
      ]
    },
    [SYSTEMS.LANG]: {
      title: 'Langue',
      inputs: [
        {
          xs: 6,
          type: 'select',
          isContainerized: true,
          required: true,
          stateName: 'type',
          value: LOCALES.map((locale) => ({
            value: locale.code,
            label: locale.libelle,
            disabled: isAlreadySelected(locale.code, 'type')
          }))
        },
        {
          xs: 6,
          type: 'text',
          required: false,
          disabled: true,
          stateName: 'code',
        }
      ]
    },
    [SYSTEMS.TAX]: {
      title: 'Taxe',
      inputs: [
        {
          xs: 4,
          type: 'text',
          required: true,
          stateName: 'name',
        },
        {
          xs: 2,
          type: 'number',
          required: true,
          stateName: 'percent',
        },
        {
          xs: 2,
          type: 'text',
          required: true,
          stateName: 'flag',
        },
        {
          xs: 4,
          type: 'select',
          isContainerized: true,
          required: false,
          stateName: 'type',
          value: []
          // value: taxRates?.edges?.map(({ node }) => ({
          //   value: node.id,
          //   label: node.libelle,
          //   disabled: isAlreadySelected(node.id, 'type')
          // }))
        },
      ]
    },
  }

  return {
    titleForm: 'Gestion système',
    subTitleForm: "Veuillez compléter les champs ci-dessous pour configurer votre système",
    drawerType: 'drawer',
    component: Button,
    formConfig: {
      type: FORM,
      header: {
        title: `Informations sur les ${item?.title.toLowerCase()}`,
      },
      children: [
        {
          isOptionnal: false,
          optionsInputs: [
            {
              type: 'rowsNew',
              required: true,
              stateName: 'rows',
              schema: rowSchema[item?.slug],
            },
          ]
        },
      ]
    }
  }
}
export default SystemInfosConfig;