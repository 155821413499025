import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { withRouter } from "react-router";
import 'react-dropdown-tree-select/dist/styles.css';
import './InputBuilder.scss';
import Mapping from '../inputs/Mapping';
import { eventService } from '../../../js/services/event.service';
import * as moment from "moment";
import { withTranslation } from 'react-i18next';
import OurCheckboxList from './components/OurCheckboxList/OurCheckboxList';
import OurSelect from './components/OurSelect/OurSelect.';
import OurText from './components/Text/OurText';
import OurCheckbox from './components/OurCheckbox/OurCheckbox';
import TreeView from './components/TreeView/TreeView';
import CheckBoxItem from './components/CheckBoxItem/CheckBoxItem';
import Rows from './components/Rows/Rows';
import { FirstSelectTree, SecondSelectTree } from './components/SelectTree/SelectTree';
import TextArea from './components/TextArea/TextArea';
import TextAreaSimple from './components/TextAreaSimple/TextAreaSimple';
import ButtonGroupInput from './components/ButtonGroup/ButtonGroup';
import SwitchInput from './components/Switch/Switch';
import Hour from './components/Hour/Hour';
import ProductSelector from './components/ProductSelector/ProductSelector';
import VariantAdd from './components/VariantAdd/VariantAdd';
import ProductExplorer from './components/ProductExplorer/ProductExplorer';
import UserAccess from './components/UserAccess/UserAccess';
import { useStyles } from './styles/styled';
import OurRadio from './components/OurRadio/OurRadio';
import OurImageSelector from './components/OurImageSelector/OurImageSelector';
import { formatData } from './components/OurImageSelector/config/formatData.config';
import OurFileUploader from '../inputs/OurFileUploader/OurFileUploader';
import OurDatePicker from './components/Date/OurDate';
import RowsVariant from './components/RowsVariant/RowsVariant';
import mediaPickerConfig from './components/OurImageSelector/config/mediaPicker.config';
import RowsNew from './components/RowsNew/RowsNew';

function InputBuilder(props) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [seeError, setSeeError] = useState(false);
    
    // This is used by several inputs to open the media selector drawer
    const [openMediaPicker, setOpenMediaPicker] = useState(false);
    const toggleOpenMediaPicker = () => setOpenMediaPicker(!openMediaPicker);

    const maxFileSize = process.env.REACT_APP_FILE_UPLOAD_MAX ?? 30000000

    const classes = useStyles(props);

    const checkError = (value = null) => {
        let val = value ?? props.value;

        let error = false;
        let message = null;
        let errorType = null
        switch (props.input.type) {
            case 'text':
                const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = props.t('products.categories.creation.requirefield');
                }
                if (props.input.limitedCaracters && props.input.limitedCaracters.test(val)) {
                    error = true;
                    message = props.t('products.categories.creation.noSpecialCaracters');
                }
                if (val && !props.input.noSpecificValidation && props.input.email && !EMAIL_REGEX.test(val)) {
                    // invalid email
                    error = true;
                    message = `Cette adresse email est invalide`;
                }

                break;
            case 'hidden':
                const PASSWORD_REGEX = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/;

                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = props.t('products.categories.creation.requirefield');
                }

                if (val && !props.input.noSpecificValidation && !props.input.confirm && !PASSWORD_REGEX.test(val)) {
                    // invalid password
                    error = true;
                    message = `Le mot de passe doit contenir au moins 8 caractères, dont un chiffre, une minuscule, une majuscule et un caractère spécial parmis : !@#$%^&*`;
                }

                break;
            case 'textarea':
            case 'textareaSimple':
                // if (!val && props.input.required) {
                //     // required
                //     error = true;
                //     message = props.t('products.categories.creation.requirefield');
                // }

                break;
            case 'number':
            case 'decimal':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = props.t('products.categories.creation.requirefield');
                }
                if (val) {
                    setSeeError(false);
                }

                break;
            case 'radio':
                if (!val && props.input.required && typeof val !== 'boolean') {
                    // required
                    error = true;
                    message = props.t('products.categories.creation.requirefield');
                }
                if (val && typeof val === 'boolean') {
                    setSeeError(false);
                }

                break;
            case 'checkbox':
            case 'mediaPicker':
                if (!val && props.input.required) {
                    error = true;
                    message = props.t('products.categories.creation.requirefield');
                } else {
                    setSeeError(false);
                }

                break;
            case 'productExplorer':
                if (val.length === 0 && props.input.required) {
                    error = true;
                    message = props.t('products.categories.creation.requirefield');
                } else {
                    setSeeError(false);
                }

                break;
            case 'select':
                if (props.input.multiselect) {
                    if (val.length === 0 && props.input.required) {
                        // required
                        error = true;
                        message = props.t('products.categories.creation.requirefield');
                    }
                } else {
                    
                    if ((!val || val.length === 0) && props.input.required) {
                        // required
                        error = true;
                        message = props.t('products.categories.creation.requirefield');
                    }
                }
                break;
            case 'selectTree':
                if (!val?.length && props.input.required) {
                    // required
                    error = true;
                    message = props.t('products.categories.creation.requirefield');
                }

                break;
            case 'rowsVariant':
                let arrayOfError = [];
                for (let value of val) {
                    if (props.input.hasPrice && value.price.value === "") {
                        // required
                        arrayOfError.push(true)
                    }
                    if (value.image.value?.filePath === null) {
                        // required
                        arrayOfError.push(true)
                    }
                    for (let attribute of value.allAttr) {
                        if (attribute.values === null || attribute.values === "") {
                            arrayOfError.push(true)
                        }
                    }
                }
                if (arrayOfError.includes(true)) {
                    error = true;
                    message = props.input.hasPrice ? "Vous devez remplir les champs prix et attributs" : "Vous devez remplir les champs attributs"
                } else {
                    setSeeError(false);
                }
                break;
            case 'productSelector':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Vous devez choisir un produit';
                }

                break;
            case 'file':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = props.t('products.categories.creation.requirefield');
                } else if (val?.file) {
                    if (val.file.size > maxFileSize) {
                        error = true;
                        message = 'Fichier trop volumineux (30 Mo max)';
                    }
                } else {
                    setSeeError(false);
                }
            case 'oneImage':
                if (val?.file) {
                    if (val.file.size > maxFileSize) {
                        error = true;
                        message = 'Fichier trop volumineux (30 Mo max)';
                    }
                } else if (!val?.data && props.input.required) {
                    error = true;
                } else if (!val?.file && val?.changed && props.input.required) {
                    error = true;
                    message = props.t('products.categories.creation.requirefield');
                } else {
                    setSeeError(false);
                }

                break;
            case 'date':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = props.t('products.categories.creation.requirefield');
                } else {
                    let errorCustom = false;
                    const isNegative = (num) => Math.sign(num) === -1


                    if (props.input.minDate) {
                        let getDateStatusMin = moment(val).diff(moment(props.allState[props.input.minDate]));
                        if (isNegative(getDateStatusMin)) {
                            errorCustom = true
                        }
                    }
                    if (props.input.maxDate) {
                        let getDateStatusMax = moment(val).diff(moment(props.allState[props.input.maxDate]));
                        if (!isNegative(getDateStatusMax)) {
                            errorCustom = true
                        }
                    }

                    if (errorCustom) {
                        // required
                        error = true;
                        message = ' ';
                    }
                }

                if (val && !moment(val).isValid()) {
                    // invalid
                    error = true;
                    message = 'La date est invalide';
                }

                break;
            case 'rows':
                if (val.length === 0 && props.input.required) {
                    error = true;
                    message = 'Vous devez créer une ligne minimum';
                } else if (val.length > 0 && props.input.required) {
                    for (let value of val) {
                        if (props.input.filter) {
                            if (value.code === "" || value.code?.length === 0 || value.values === "" || value.operator === "" || value.values?.length === 0) {
                                error = true;
                                message = 'Vous devez remplir tous les champs';
                            } else {
                                setSeeError(true)
                            }
                        } else if (props.input?.dictionarySelect) {
                            if (value.code.length === 0) {
                                error = true;
                                message = 'Vous devez remplir le champ attributs fichier';
                            }
                            if (value.values === "") {
                                error = true;
                                message = 'Vous devez remplir le champ attributs Sinfin DXP';
                            }
                        } else {
                            if (value?.code?.length === 0) {
                                error = true;
                                message = 'Vous devez remplir tous les champs';
                            }
                            if (value.values === "") {
                                error = true;
                                message = 'Vous devez remplir tous les champs';
                            }
                        }
                    }
                } else {
                    error = false;
                    // setSeeError(false);
                }
                break;
            default: return;
        }
        setErrorMessage(error ? message : null);
        // setSeeError(Boolean(error));
        props.errorCallback?.(error);
    };

    useEffect(() => {
        checkError();
    }, []);

    // Check every input change
    useEffect(() => {
        let subscription = eventService.get().subscribe((data) => {
            if (data && props.input.stateName === data.stateName) {
                setErrorMessage(data.errorMessage);
                setSeeError(true)
            }
        });

        return () => subscription.unsubscribe();
    }, [props.value]);


    switch (props.input.type) {

        /* ----------------------------- General inputs ----------------------------- */

        case 'text':
        case 'number':
        case 'decimal':
        case 'hidden': // ex: passwords, etc
            return (
                <OurText 
                    allProps={props} 
                    error={{
                        seeError: seeError,
                        errorMessage: errorMessage
                    }}
                    keyCallback={props?.onKeyCallback}
                    updateCallback={(evt) => {
                        setSeeError(true);
                        checkError(evt.target.value);
                        props.stateCallback(evt);
                    }}
                    lock={props.lock}
                    isHidden={props.input.type === 'hidden'}
                />
            );
        case 'select':
            return (
                //* This is a normal select and can be normal or multiselect (not selectTree)
                <OurSelect
                    allProps={props}
                    error={{
                        seeError : seeError,
                        errorMessage: errorMessage
                    }}
                    updateCallback={evt => {
                        setSeeError(true);
                        checkError(evt.target.value);
                        props.stateCallback(evt);
                    }}
                    lock={props.lock}
                />
            )
        case 'radio':
            // It can be a simple text radio (text + radio) or a radio card (card + radio)
            return (
                <OurRadio 
                    allProps={props} 
                    error={{
                        seeError : seeError,
                        errorMessage: errorMessage
                    }}
                    updateCallback={evt => {
                        // This is to convert the value to boolean if it is a radio of true/false
                        const valueToSend = ['true', 'false'].includes(evt.target.value) 
                            ? evt.target.value === 'true' 
                                ? true 
                                : false
                            : evt

                        setSeeError(true);
                        checkError(evt.target.value);
                        props.stateCallback(valueToSend);
                    }}
                />
            );
        
        // * OurCheckboxList and UserAccess implements OurCheckbox, pay attention if you change something here
        case 'checkbox':
            // Simple checkbox
            return (
                <OurCheckbox 
                    allProps={props} 
                    error={{
                        seeError : seeError,
                        errorMessage: errorMessage
                    }}
                    updateCallback={evt => {
                        setSeeError(true);
                        checkError(evt.target.value);
                        props.stateCallback(evt);
                    }}
                />
            )
        // TODO: Remove and mix with checkbox. It is used just in Catalogue details > FORMULAIRE D'AJOUT DE PRODUITS PAR CATEGORIE
        case 'checkboxItem':
            return (
                <CheckBoxItem allProps={props} />
            );
        case 'checkboxList':
            return (
                <OurCheckboxList 
                    allProps={props} 
                    error={{
                        seeError : seeError,
                        errorMessage: errorMessage
                    }}
                    updateCallback={(items) => {
                        // TODO: Set errors on switch
                        setSeeError(true);
                        checkError(items);
                        props.stateCallback(items);
                    }} 
                />
            )

        // TODO: Use this in every TreeView Screen (Configs)
        case 'treeView':
            return (
                <TreeView allProps={props} />
            );

        case 'rows':
            let hasNew = props.allState?.values?.find(e => e.new)
            return (
                <Rows 
                    allProps={props} 
                    seeError={seeError} 
                    setSeeError={setSeeError} 
                    errorMessage={errorMessage} 
                    checkError={checkError} 
                    hasNew={hasNew} 
                    classes={classes} 
                />
            );

        case 'rowsNew':
            return (
                <RowsNew
                    parentState={props.allState?.[props.input.stateName]}
                    input={props.input}
                    errorCallback={props.errorCallback}
                    updateCallback={(items) => {
                        props.stateCallback(items);
                    }}
                />
            );

        // TODO: Remove and mix with Rows
        case 'variantAdd':
            return (
                <VariantAdd allProps={props} seeError={seeError} setSeeError={setSeeError} errorMessage={errorMessage} checkError={checkError} />
            );

        // TODO: Remove and mix with Rows
        case 'rowsVariant':
            return (
                <RowsVariant
                    allProps={props} 
                    seeError={seeError} 
                    setSeeError={setSeeError} 
                    errorMessage={errorMessage} 
                    checkError={checkError} 
                    handleOpenMediaPicker={toggleOpenMediaPicker} 
                    openMediaPicker={openMediaPicker} 
                    mediaPickerConfig={mediaPickerConfig} 
                    updateCallback={(evt) => {
                        setSeeError(true);
                        checkError(evt?.target?.value);
                        props.stateCallback(evt);
                    }}
                />
            );

        // * This is a selectTree (not a normal select) (With search bar and checkboxes)
        case 'selectTree':
            if (!props.input.multiselect) {
                return (
                    <FirstSelectTree allProps={props} lock={props.lock} seeError={seeError} setSeeError={setSeeError} errorMessage={errorMessage} checkError={checkError} />
                );
            } else {
                return (
                    <SecondSelectTree allProps={props} lock={props.lock} seeError={seeError} setSeeError={setSeeError} errorMessage={errorMessage} checkError={checkError} />
                );
            }

            // TODO: Refactor. Mix both in OurSelect
            // return <OurTreeSelect allProps={props} />
        
        // * This is a textarea with React Quill
        case 'textarea':
            return (
                <TextArea 
                    allProps={props} 
                    seeError={seeError} 
                    setSeeError={setSeeError} 
                    errorMessage={errorMessage} 
                    checkError={checkError}
                    setOpenMediaPicker={setOpenMediaPicker}
                    openMediaPicker={openMediaPicker} 
                    lock={props.lock}
                />
            );

        // * This is a normal textarea
        case 'textareaSimple':
            if (!window.delays)
                window.delays = {};

            return (
                <TextAreaSimple allProps={props} seeError={seeError} setSeeError={setSeeError} errorMessage={errorMessage} checkError={checkError} />
            );
        
        case 'date':
            return (
                <OurDatePicker 
                    allProps={props}
                    updateCallback={(date, invalidLabel) => {
                        setSeeError(true);
                        checkError(date);
                        props.stateCallback(date);
                    }}
                    seeError={seeError}
                    errorMessage={errorMessage} 
                    lock={props.lock}
                />
            );

        // TODO: Use TimePicker from MUI X
        case 'hour':
            return (
                <Hour allProps={props} />
            );

        // TODO: Remove this input. Normally it has to be a radio selection
        case 'buttonGroup':
            return (
                <ButtonGroupInput allProps={props} />
            );
        case 'switch':
            return (
                <SwitchInput allProps={props} />
            );

        // TODO: Check builder because it is used just there
        // case 'image':
        //     let countImage = 0;
        //     return (
        //         <ImageInput allProps={props} countImage={countImage} />
        //     );
        case 'oneImage':
            return (
                <OurImageSelector 
                    allProps={props} 
                    error={{
                        seeError : seeError,
                        errorMessage: errorMessage
                    }}
                    checkErrorCallback={(fileSize) => {
                        setSeeError(Boolean(errorMessage)) 
                        checkError(fileSize.size) 
                    }}
                    maxFileSize={maxFileSize} 
                    file={props.value}
                    lock={props.lock}
                    usedAlone={props.usedAlone}
                />
            )
        case 'mediaPicker':
            // Get file from state when it is chosen from mediapicker
            const mediaPickerFile = formatData(
                props?.value?.node ?? props?.allState?.[props?.allState?.currentLang]?.[props?.input?.stateName] ?? props.value
            );

            return (
                <OurImageSelector
                    allProps={props} 
                    error={{
                        seeError : seeError,
                        errorMessage: errorMessage
                    }}
                    checkErrorCallback={(file) => {
                        // Check if the error is visible
                        setSeeError(!Boolean(file?.size)) 
                        // Check the error message to be displayed
                        checkError(Boolean(file?.size)) 
                    }}
                    maxFileSize={maxFileSize} 
                    file={mediaPickerFile}
                    hasMediaPicker
                    lock={props.lock}
                    usedAlone={props.usedAlone}
                    label={props.label}
                />
            )
        case 'uploadFile':
            return (
                <OurFileUploader allState={props.allState} stateCallback={props.stateCallback} input={props.input} />
            );

            
        case 'mapper':
            return (
                <Grid item xs={12}>
                    <Mapping allState={props.allState} stateCallback={props.stateCallback} input={props.input} category={props.input.isCategory} />
                </Grid>
            );
        //* They are in src\components\ui\inputs
        // case 'mapper-multiple':
        //     return (
        //         <Grid item xs={12}>
        //             <MultipleMapping allState={props.allState} stateCallback={props.stateCallback} input={props.input} category={props.input.isCategory} />
        //         </Grid>
        //     );
        // case 'simpleImage':
        //     return (
        //         <Grid item xs={12}>
        //             <SimpleImage src={DOCUMENTS.concat(VIDEOS).includes(props.input.mediaType) ? props.input.imagepath : `${process.env.REACT_APP_API_ROOT}/medias/${props.input.imagepath}`} style={{ maxHeight: DOCUMENTS.concat(VIDEOS).includes(props.input.mediaType) ? 100 : null }} />
        //         </Grid>
        //     );
        // case 'colorPicker':
        //     return (
        //         <Grid item xs={12}>
        //             <ColorPicker
        //                 color={props.allState[props.input.stateName] ? props.allState[props.input.stateName].hex : '#ffffff'}
        //                 onChange={(color) => {
        //                     props.stateCallback(color, 'color')
        //                 }}
        //             />
        //         </Grid>
        //     );

        /* ------------------------------ Custom inputs ----------------------------- */

        //! Exception: This input is custom and just available for user groups rights (userAccess)
        //* This is because there is no need to use this input in other places and it is a very specific input
        //* It is better to not overload the 'OurCheckboxList' component with this for readability
        //* However, it depends on the OurCheckbox component (which is used in OurCheckboxList)
        // TODO: Remove them and use the form config custom component option (component: 'componentName')
        case 'userAccess':
            return (
                <UserAccess allProps={props} />
            );

        case 'productSelector':
            return (
                <ProductSelector allProps={props} seeError={seeError} setSeeError={setSeeError} errorMessage={errorMessage} checkError={checkError} />
            );
        case 'productExplorer':
            return (
                <ProductExplorer allProps={props} seeError={seeError} setSeeError={setSeeError} errorMessage={errorMessage} checkError={checkError} classes={classes} />
            )
        default:
            return null;
    }
}

export default withTranslation()(withRouter(InputBuilder));