import React, { useEffect } from 'react';
import {
    Box,
    CircularProgress,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import { withApollo } from '@apollo/client/react/hoc';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import '../../../navigation/DrawerLeft.scss';
import { withTranslation } from 'react-i18next'
import moment from 'moment';
import imageDashboard from '../../../../assets/images/dashboard/dashboard_prices.png';
import styled from 'styled-components';
import { get_productSourcePriceWatchers, productSourcePriceWatchers_diff_flat_lte, productSourcePriceWatchers_diff_flat_gte, productSourcePriceWatchers_diff_percent_lte, productSourcePriceWatchers_diff_percent_gte, totalProducts, totalProductGT, totalProductLT, getSources, get_productDetails, get_productSourcePriceWatchers_by_brand } from '../../../../queries/price_watcher';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import ReactEcharts from 'echarts-for-react';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import RemoveIcon from '@mui/icons-material/Remove';
import CallMadeIcon from '@mui/icons-material/CallMade';
import OurButton from '../../../ui/button/Button';
import { ROUTE_PRODUCTS_BRANDS_DETAIL, ROUTE_PRODUCTS_LIST_DETAIL, ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL, ROUTE_PRODUCTS_LIST, ROUTE_PRODUCTS_PRICE_COMPARE_LIST } from '../../../../js/constants/route-names';
import { GET_PRODUCTS_BY_SKU_LIGHT } from '../../../../queries/products';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { CursorPagination } from '../../../../js/utils/pagination';
import OurLink from '../../../ui/link/ReturnLink';
import axios from 'axios';

const WrapperStats = styled(Grid)`
    border: 1px solid ${colors.grey.lighter.hue700};
    background: ${colors.white};
`
const ContentStats = styled(Grid)`
    padding: 30px 48px;
`
const TitleDashboard = styled(Typography)`
    font-weight: bold;
    font-size: 20px;
`
const BigNumber = styled(Typography)`
    font-weight: bold;
    font-size: 57px;
    line-height: 72px;
`
const WrapperStat = styled(Grid)`
    display: flex;
    flex-direction: column;
    p{
        color: ${colors.black.regular};
    }
`
const TableRowCustomDashboard = styled(TableRow)`
    td{
        padding: 24px 16px;
    }
    td,th{
        border-color: #EDEEEE;
        white-space: nowrap;
        line-height: 1rem;
    }
    th{
        color: ${colors.grey.border};
        font-weight: bold;
    }
`
const TableRowCustom = styled(TableRow)`
    td,th{
        border-color: #EDEEEE;
        white-space: nowrap;
        line-height: 1rem;
        padding: 8px;
    }
    th{
        color: ${colors.grey.border};
        font-weight: bold;
        border: none;
    }
`
const TableRowBottom = styled(TableRowCustomDashboard)`
    border: none;
    &:nth-child(odd){
        background: #F5F5F5CC;
    }
`
const TableBodyCustom = styled(TableBody)`
    .table-row{
        cursor: pointer;
        color: inherit;
        display: table-row;
        outline: 0;
        vertical-align: middle;
        text-decoration: none;
        td,th{
            padding: 8px;
        }
    }
`

const TableCellCustom = styled(TableCell)`
    font-size: 20px;
    font-weight: bold;
`
const ValueItem = styled.p`
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:14px;
    line-height: 17px;
    font-weight: normal;
    margin: 4px 0;
    text-align: center;
    strong{
        display:flex;
        align-items: center;
    }
    svg{
        font-size:14px;
        margin-left: 4px;
    }
`
const WrapperFilters = styled(Grid)`
    .MuiOutlinedInput-input{
        padding-top: 11.5px;
        padding-bottom: 11.5px;
    }
`

const WrapperAttr = styled(Grid)`
    &:not(:last-child){
        margin-bottom: 8px;
    }
    > p:first-child{
        min-width: 180px;
    }
    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
`;

const NameProduct = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: 272px;
    padding-right: 24px;
`;
const TableRowDataCustom = styled(TableRow)`
    display: inline-block;
    cursor: pointer;
    &:hover{
        background-color: ${colors.grey.lighter.hue980};
    }
    td{
        border-top: 1px solid ${colors.grey.lighter.hue700};
        border-color: ${colors.grey.lighter.hue700};
    }
    td:first-child{
        border-left: 1px solid ${colors.grey.lighter.hue700};
    }
    td:last-child{
        border-right: 1px solid ${colors.grey.lighter.hue700};
    }
`;
const SelectCustom = styled(Select)`
    .MuiSelect-select.MuiSelect-select{
        padding-bottom: 0;
        padding-right: 0;
    }
    svg{
        display: none;
    }
`;
const ContainerLink = styled.a`
    text-decoration: none;
    &:hover{
       p:first-child{
        text-decoration: underline;
       } 
    }
`;

const PriceCompare = props => {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [prices, setPrices] = React.useState(null);
    const [pricesOneBrand, setPricesOneBrand] = React.useState(null);
    
    const [operator, setOperator] = React.useState(JSON.parse(localStorage.getItem('PRODUCTS_LISTING_FILTERS'))?.operator ?? 'default');
    const [diff_value, setDiffValue] = React.useState(JSON.parse(localStorage.getItem('PRODUCTS_LISTING_FILTERS'))?.diffValue || '');
    const [diff_type, setDiffType] = React.useState(JSON.parse(localStorage.getItem('PRODUCTS_LISTING_FILTERS'))?.diffType ?? 'percent');

    const [searchSKU, setSearchSKU] = React.useState(null??'');

    const [stateTotalProducts, setTotalProducts] = React.useState(null);
    const [stateTotalProductsGT, setTotalProductsGT] = React.useState(null);
    const [stateTotalProductsLT, setTotalProductsLT] = React.useState(null);

    const [productInfos, setProductInfos] = React.useState(null);
    const [productDetails, setProductDetails] = React.useState(null);
    const [productDetailsBySite, setProductDetailsBySite] = React.useState(null);
    const [productDetailsBySiteRef, setProductDetailsBySiteRef] = React.useState(null);

    const [sources, setSources] = React.useState(null);
    const [sourcesBrands, setSourcesBrands] = React.useState(null);
    const [loadingDashboard, setLoadingDashboard] = React.useState(true);
    const [loadingDashboardSources, setLoadingDashboardSources] = React.useState(true);
    const [loadingDashboardBrands, setLoadingDashboardBrands] = React.useState(true);
    const [loadingProductDetails, setLoadingProductDetails] = React.useState(true);
    const [loadingList, setLoadingList] = React.useState(true);
    const [loadingProduct, setLoadingProduct] = React.useState(true);
    const [dateHistory, setDateHistory] = React.useState(moment().startOf('months').format('YYYY-MM-DD'));
    const [dateHistoryBefore, setDateHistoryBefore] = React.useState(moment().format('YYYY-MM-DD'));
    const [orderBy, setOrderBy] = React.useState('dateASC');
    const [orderByRef, setOrderByRef] = React.useState('dateASC');

    const [localStorageFilters, setLocalStorageFilters] = React.useState(null);

    const params = new URLSearchParams(window.location.search);
    let getType = params.get("type");

    const [pagination, setPagination] = React.useState({
        perPage: 10,
        page: 0,
        startCursor: null,
        endCursor: null,
        count: 0
    });

    const getPrices = (query = get_productSourcePriceWatchers, variables = { date: moment().format("YYYY-MM-DD") }, init = false, pageInfo) => {
        setLoadingList(true);
        // file deepcode ignore Sqli: <>
        props.client.query({
            query,
            fetchPolicy: "no-cache",
            variables
        }).then(result => {
            let getPrices = result.data.products.edges;

            let page = pageInfo || pagination;

            let newPagination = {
                ...page,
                ...result.data.products.pageInfo,
                count: result.data.products.totalCount
            };

            // if (!pageInfo)
            //     pageInfo = resetPagination();

            setPagination(newPagination);
            setPrices(getPrices);
            setLoadingList(false);
        }).catch((err)=>{
            console.log(err)
        });
    }

    const getPricesOneBrand = (query = get_productSourcePriceWatchers_by_brand, variables = { date: moment().format("YYYY-MM-DD") }, init = false, pageInfo) => {
        setLoadingList(true);
        props.client.query({
            query,
            fetchPolicy: "no-cache",
            variables
        }).then(result => {

            let getPrices = result.data.products.edges;

            let page = pageInfo || pagination;

            let newPagination = {
                ...page,
                ...result.data.products.pageInfo,
                count: result.data.products.totalCount
            };

            if (!pageInfo)
                pageInfo = resetPagination();

            setPagination(newPagination);
            setPrices(getPrices);
            setLoadingList(false);
        }).catch((err)=>{
            console.log(err)
        });
    }

    function setDashboards() {

        axios.get(`${process.env.REACT_APP_API_ROOT}/medias/getTotalProducts.json?v=${moment().unix()}`)
        .then(response => {                
            if (response.status !== 200){                            
                props.client.query({
                    query: totalProducts,
                    fetchPolicy: "no-cache",
                    variables: {}
                }).then(result => {
                    setTotalProducts(result.data.products.totalCount);
                    setLoadingDashboard(false);
                }).catch((err)=>{
                    console.log(err)
                });
            }else{
                setTotalProducts(response.data.data.products.totalCount);
                setLoadingDashboard(false);    
            }
        })  
        .catch((err)=>(
            props.client.query({
                query: totalProducts,
                fetchPolicy: "no-cache",
                variables: {}
            }).then(result => {
                setTotalProducts(result.data.products.totalCount);
                setLoadingDashboard(false);
            })
            .catch((err)=>{
                console.log(err)
            })                
        ));

        axios.get(`${process.env.REACT_APP_API_ROOT}/medias/productSourcePriceWatchersTotalLT.json?v=${moment().unix()}`)
        .then(response => {                
            if (response.status !== 200){                            
                props.client.query({
                    query: totalProductLT,
                    fetchPolicy: "no-cache",
                    variables: {
                        date: moment().format("YYYY-MM-DD"),
                        value: "-1"
                    }
                }).then(result => {
                    setTotalProductsLT(result?.data?.products.totalCount);
                }).catch((err)=>{
                    console.log(err)
                });
            }else{
                setTotalProductsLT(response.data.data.products.totalCount);
            }
        })  
        .catch((err)=>(
            props.client.query({
                query: totalProductLT,
                fetchPolicy: "no-cache",
                variables: {
                    date: moment().format("YYYY-MM-DD"),
                    value: "-1"
                }
            }).then(result => {
                setTotalProductsLT(result?.data?.products.totalCount);
            })
            .catch((err)=>{
                console.log(err)
            })                
        ));

        axios.get(`${process.env.REACT_APP_API_ROOT}/medias/productSourcePriceWatchersTotalGT.json?v=${moment().unix()}`)
        .then(response => {                
            if (response.status !== 200){                            
                props.client.query({
                    query: totalProductGT,
                    fetchPolicy: "no-cache",
                    variables: {
                        date: moment().format("YYYY-MM-DD"),
                        value: "1"
                    }
                }).then(result => {
                    setTotalProductsGT(result?.data?.products.totalCount);
                }).catch((err)=>{
                    console.log(err)
                });
            }else{
                setTotalProductsGT(response.data.data.products.totalCount);
            }
        })  
        .catch((err)=>(
            props.client.query({
                query: totalProductGT,
                fetchPolicy: "no-cache",
                variables: {
                    date: moment().format("YYYY-MM-DD"),
                    value: "1"
                }
            }).then(result => {
                setTotalProductsGT(result?.data?.products.totalCount);
            })
            .catch((err)=>{
                console.log(err)
            })                
        ));
    }

    const getSourcesFile = ()=>{                    
        axios.get(`${process.env.REACT_APP_API_ROOT}/medias/getSources-isBrand.json?v=${moment().unix()}`)
        .then(response => {                
            if (response.status !== 200){                            
                props.client.query({
                    query: getSources,
                    fetchPolicy: "no-cache",
                    variables: {
                        date: moment().format("YYYY-MM-DD"),
                        isBrand: true
                    }
                }).then(result => {
                    setSourcesBrands(result.data.productSources.edges);
                    setLoadingDashboardBrands(false);
                }).catch((err)=>{
                    console.log(err)
                })
            }else{                                        
                setSourcesBrands(response.data.data.productSources.edges);
                setLoadingDashboardBrands(false);
            }

        })  
        .catch((err)=>(
            props.client.query({
                query: getSources,
                fetchPolicy: "no-cache",
                variables: {
                    date: moment().format("YYYY-MM-DD"),
                    isBrand: true
                }
            }).then(result => {                    
                setSourcesBrands(result.data.productSources.edges);
                setLoadingDashboardBrands(false);
            })
            .catch((err)=>{
                console.log(err)
            })                
        ))                      
    } 

    useEffect(()=>{
        getSourcesFile()
    },[])

    const getSourcesIsNotBrandFile = ()=>{                    
        axios.get(`${process.env.REACT_APP_API_ROOT}/medias/getSources-isNotBrand.json?v=${moment().unix()}`)
        .then(response => {                
            if (response.status !== 200){                            
                props.client.query({
                    query: getSources,
                    fetchPolicy: "no-cache",
                    variables: {
                        date: moment().format("YYYY-MM-DD"),
                        isBrand: false
                    }
                }).then(result => {
                    setSources(result.data.productSources.edges);
                    setLoadingDashboardSources(false);
                }).catch((err)=>{
                    console.log(err)
                })
            }else{                                        
                setSources(response.data.data.productSources.edges);
                setLoadingDashboardSources(false);
            }

        })  
        .catch((err)=>(
            props.client.query({
                query: getSources,
                fetchPolicy: "no-cache",
                variables: {
                    date: moment().format("YYYY-MM-DD"),
                    isBrand: false
                }
            }).then(result => {
                setSources(result.data.productSources.edges);
                setLoadingDashboardSources(false);
            })
            .catch((err)=>{
                console.log(err)
            })                
        ))                      
    } 

    useEffect(()=>{
        getSourcesIsNotBrandFile()
    },[])

    const resetPagination = () => {
        return {
            perPage: 10,
            page: 0,
            startCursor: null,
            endCursor: null,
            count: 0
        };
    }

    /* BUTTON */
    const searchPrices = (pageInfo) => {
        let page = pageInfo;

        if (!page)
            page = resetPagination();

        // Read LocalStorage
        const filtersLocalStorage = localStorage.setItem('PRODUCTS_LISTING_FILTERS', JSON.stringify({
            ...localStorageFilters, /* HERE */
            operator: operator,
            diffValue: diff_value,
            diffType: diff_type,
        }))
        setLocalStorageFilters(filtersLocalStorage)

        let variables = {
            value: diff_value,
            date: moment().format("YYYY-MM-DD"),
            nbperpage: page?.perPage,
            cursor: page?.startCursor,
            cursorLast: page?.endCursor,
            productSourcePriceWatchers_hasDiff: true
        };

        if (searchSKU)
            variables.sku = searchSKU;

        if (diff_type === 'flat' && operator === 'lte') {
            getPrices(productSourcePriceWatchers_diff_flat_lte, variables, false, page);
        } else if (diff_type === 'flat' && operator === 'gte') {
            getPrices(productSourcePriceWatchers_diff_flat_gte, variables, false, page);
        } else if (diff_type === 'percent' && operator === 'lte') {
            getPrices(productSourcePriceWatchers_diff_percent_lte, variables, false, page);
        } else if (diff_type === 'percent' && operator === 'gte') {
            getPrices(productSourcePriceWatchers_diff_percent_gte, variables, false, page);
        } else {
            getPrices(get_productSourcePriceWatchers, variables, false, page);
        }
    }

    const searchHistoryProduct = () => {
        getProductDetails()
    }

    function getProductDetails(query = get_productDetails, variables = { sku: props.match.params.sku, order: orderBy, date: dateHistory, dateBefore: dateHistoryBefore}) {
        setLoadingProductDetails(true)
        props.client.query({
            query,
            fetchPolicy: "no-cache",
            variables
        }).then(result => {
            let arrayRetailerStats = [];

            result?.data?.products?.edges?.map((value) => {
                if (value.node.productSourcePriceWatchers.edges?.length === 0) {
                    return null;
                }

                for (const value2 of value.node.productSourcePriceWatchers.edges) {
                    let findIt = arrayRetailerStats.find(e => e.libelle === value2.node.source.libelle);

                    if (!findIt) {
                        arrayRetailerStats.push({
                            libelle: value2.node.source.libelle,
                            isBrand: value2.node.source.isBrand,
                            children: [{
                                'created_at': value2.node.created_at,
                                'source_price': value2.node.source_price,
                                'diff_flat': value2.node.diff_flat,
                                'diffPercent': value2.node.diffPercent,
                            }]
                        });
                    } else {
                        findIt.children.push({
                            'created_at': value2.node.created_at,
                            'source_price': value2.node.source_price,
                            'diff_flat': value2.node.diff_flat,
                            'diffPercent': value2.node.diffPercent
                        });
                    }
                }
            })
            setProductDetails([...arrayRetailerStats]);
            setProductDetailsBySite([...arrayRetailerStats.filter(e => e.isBrand)]);
            setProductDetailsBySiteRef([...arrayRetailerStats.filter(e => !e.isBrand)]);
            setLoadingProductDetails(false)
        }).catch((err)=>{
            console.log(err)
        });
    }

    // const getOptionPie = () => {
    //     let selectedDataPie = [
    //         {
    //             value: stateTotalProductsLT,
    //             name: 'Produits \n - chers',
    //             label: '- chers que notre site'
    //         },
    //         {
    //             value: stateTotalProductsGT,
    //             name: 'Produits \n + chers',
    //             label: '+ chers que notre site'
    //         },
    //     ];
    //     var colorPalette = ['#0273A5', '#78CBEE'];
    //     var options = {
    //         tooltip: {
    //             trigger: 'item',
    //             backgroundColor: colors.white,
    //             padding: 0,
    //             formatter: function (params) {
    //                 var colorSpan = color => `<span style="display: inline-block; margin-right: 5px; border-radius: 10px; width: 12px; height: 12px; background-color: ${color}"></span>`;
    //                 var tooltipText = `
    //                     <div style="background: ${colors.white}; padding:12px;">
    //                         <p style="margin: 0;"><strong>${params.seriesName}</strong></p>
    //                         <p style="margin: 0;display: flex; justify-content: space-between;align-items: center;">${colorSpan(params.color)} <i>${params.data.label}</i> <strong style="font-size: 25px;margin-left:20px;">${params.data.value}</strong></p>
    //                     </div>
    //                 `
    //                 return tooltipText;
    //             }
    //         },
    //         grid: {
    //             containLabel: true,
    //         },
    //         legend: {
    //             orient: 'vertical',
    //             left: 'left',
    //             show: false
    //         },
    //         series: [
    //             {
    //                 name: 'Nombre de produits',
    //                 type: 'pie',
    //                 radius: '80%',
    //                 color: colorPalette,
    //                 label: {
    //                     fontSize: 14,
    //                     fontWeight: 'bold',
    //                 },
    //                 emphasis: {
    //                     itemStyle: {
    //                         shadowBlur: 10,
    //                         shadowOffsetX: 0,
    //                         shadowColor: 'rgba(0, 0, 0, 0.5)'
    //                     }
    //                 },
    //                 data: selectedDataPie
    //             }
    //         ]
    //     };
    //     return options

    // };
    const getListingValues = (array, type) => {
        return (
            array.map((value2, index2) => {
                switch (type) {
                    case 'date':
                        return (
                            <ValueItem key={index2}><strong>{moment(value2.node.created_at).format('DD/MM/YYYY')}</strong></ValueItem>
                        );
                    case 'retailer':
                        return (
                            <Grid key={index2} container justifyContent='space-between' alignItems='center' style={{maxWidth: 250}}>
                                <ValueItem>{value2.node.source.libelle}</ValueItem>
                                <ValueItem>
                                    <strong>
                                        {value2.node.product_price.toFixed(2)}EUR</strong>
                                </ValueItem>
                            </Grid>
                        )
                    case 'diff':
                        return (
                            <ValueItem key={index2} style={{justifyContent:"flex-end"}}>
                                <strong>{value2.node.source_price.toFixed(2)}EUR &nbsp;</strong>
                                ({value2.node.diffPercent <= 0 ? '' : '+'}{value2.node.diffPercent.toFixed(2)}%) 
                                {value2.node.diff_flat === 0 ? <RemoveIcon style={{ fill: "#C9C9C9" }} /> : value2.node.diff_flat < 0 ? <CallMadeIcon style={{ fill: colors.green.regular, transform: 'rotate(-45deg)' }} /> : <CallMadeIcon style={{ fill: colors.red.regular, transform: 'rotate(135deg)' }} />}
                            </ValueItem>
                        )

                    default:
                        break;
                }
            })
        )
    };

    const initProductInfos = async () => {
        props.client.query({
            query: GET_PRODUCTS_BY_SKU_LIGHT,
            variables: {
                'sku': props.match.params.sku,
            }
        }).then(
            result => {
                if (result.data.products.edges.length > 0) {
                    let product = result.data.products.edges[0].node;
                    let getName = getTraductionAttributs('product_name', product.productDatas.edges, currentLang);
                    let getDesc = getTraductionAttributs('product_description', product.productDatas.edges, currentLang);
                    let getEan = getTraductionAttributs('product_ean', product.productDatas.edges, currentLang);
                    let getImage = getTraductionAttributs('product_image', product.productDatas.edges, currentLang, 'image');
                    let getPrice = getTraductionAttributs('product_price', product.productDatas.edges, currentLang);
                    setProductInfos({
                        id: product.id,
                        name: getName,
                        desc: getDesc,
                        ean: getEan,
                        image: getImage,
                        price: getPrice
                    });
                }
                setLoadingProduct(false)
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }


    //--- call 1 fois dans le cycle de vie du composant
    useEffect(() => {

        if (!stateTotalProducts) {
            setDashboards();
        }

        if (props.type === "list") {
            let variables = {
                productSourcePriceWatchers_hasDiff: true,
                value: diff_value,
                date: moment().format("YYYY-MM-DD"),
                nbperpage: pagination?.perPage,
            };

            let hashSku = window.location.hash.replace('#', '');

            if (hashSku || searchSKU) {
                variables.sku = hashSku || searchSKU;
                setSearchSKU(hashSku || searchSKU);
            }

            if (!getType){
                if (diff_type === 'flat' && operator === 'lte') {
                    getPrices(productSourcePriceWatchers_diff_flat_lte, variables, false, false);
                } else if (diff_type === 'flat' && operator === 'gte') {
                    getPrices(productSourcePriceWatchers_diff_flat_gte, variables, false, false);
                } else if (diff_type === 'percent' && operator === 'lte') {
                    getPrices(productSourcePriceWatchers_diff_percent_lte, variables, false, false);
                } else if (diff_type === 'percent' && operator === 'gte') {
                    getPrices(productSourcePriceWatchers_diff_percent_gte, variables, false, false);
                } else {
                    getPrices(get_productSourcePriceWatchers, variables, false, false);
                }
            }

            // let oneBrandVariables = {
            //     // value: diff_value,
            //     date: moment().format("YYYY-MM-DD"),
            //     nbperpage: pagination?.perPage,
            //     //---- pemets de chercher les produits par source :
            //     // productSourcePriceWatchers_source: "/api/product-sources/1",
            //     //--- permets de chercher uniquement les produits ayant des écarts : 
            //     productSourcePriceWatchers_hasDiff: true
            // };
            // getPricesOneBrand(get_productSourcePriceWatchers_by_brand, oneBrandVariables, false, false);
        } else {
            getProductDetails();
            initProductInfos();
        }
    }, [props.type])

    const reorderPricesBySite = (isRef) => {
        if (productDetails && productDetails.length > 0) {
            let getData = null
            if (isRef){
                getData = [...productDetailsBySiteRef]
            }else{
                getData = [...productDetailsBySite]
            }
            if (orderBy === 'dateASC') {
                getData = getData.sort((a, b) => new Date(a.children[0].created_at) - new Date(b.children[0].created_at));
            } else if (orderBy === 'dateDESC') {
                getData = getData.sort((a, b) => new Date(b.children[0].created_at) - new Date(a.children[0].created_at));
            } else if (orderBy === 'ASC') {
                getData = getData.sort((a, b) => a.children[0].diff_flat - b.children[0].diff_flat);
            } else {
                getData = getData.sort((a, b) => b.children[0].diff_flat - a.children[0].diff_flat);
            }
            if (isRef){
                setProductDetailsBySiteRef(getData)
            }else{
                setProductDetailsBySite(getData)
            }
        }
    }

    useEffect(() => {
        reorderPricesBySite();
    }, [orderBy])
    useEffect(() => {
        reorderPricesBySite(true);
    }, [orderByRef])

    useEffect(() => {
        searchHistoryProduct();
    }, [dateHistory, dateHistoryBefore])

    const getNames = () => {
        return productDetails?.map((product) => {
            return product.libelle
        })
    }

    const getXAxis = () => {
        let formatedList = []
        const diff = moment(dateHistoryBefore).diff(dateHistory, 'days')
        formatedList.push(moment(dateHistory).format('DD/MM'))
        for (let i = 0; i < diff; i++) {
            const date = moment(dateHistory).add(i + 1, 'days').format('DD/MM');
            formatedList.push(date)
        }
        return formatedList
    }

    const getValues = () => {
        const dates = getXAxis()

        return productDetails?.map((product) => {
            let list = []
            for (const date of dates) {
                let findChild = product.children.find(e => moment(e.created_at).format('DD/MM') === date)
                if (findChild) {
                    list.push(findChild.source_price)
                } else {
                    list.push('')
                }
            }
            return {
                name: product.libelle,
                type: 'line',
                stack: '',
                data: list
            }
        })
    }

    const multiLineOptions = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: getNames()
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {
                    title: "Télécharger graphique",
                }
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: getXAxis()
        },
        yAxis: {
            type: 'value'
        },
        series: getValues()
    };

    const barOptionsMore = {
        title: {
            show: true,
            text: 'Stock le plus élevé',
            textStyle: {
                color: '#C9CBCC',
                fontSize: 14
            },
            top: 20
        },
        xAxis: {
            type: 'category',
            data: []
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    {
                        value: 358,
                        itemStyle: {
                            color: '#3171A1'
                        }
                    },
                    {
                        value: 289,
                        itemStyle: {
                            color: '#3171A1'
                        }
                    },
                    {
                        value: 263,
                        itemStyle: {
                            color: '#3171A1'
                        }
                    },
                    {
                        value: 263,
                        itemStyle: {
                            color: '#3171A1'
                        }
                    },

                ],
                type: 'bar',
                label: {
                    show: true,
                    position: 'top',
                    textStyle: {
                        fontSize: 16,
                        fontWeight: 'bold'
                    }
                },
            }
        ]
    };

    const barOptionsLess = {
        title: {
            show: true,
            text: 'Stock le plus bas',
            textStyle: {
                color: '#C9CBCC',
                fontSize: 14
            },
            top: 20
        },
        xAxis: {
            type: 'category',
            data: []
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    {
                        value: 143,
                        itemStyle: {
                            color: '#8CC9EA'
                        }
                    },
                    {
                        value: 128,
                        itemStyle: {
                            color: '#8CC9EA'
                        }
                    },
                    {
                        value: -+3,
                        itemStyle: {
                            color: '#8CC9EA'
                        }
                    },
                    {
                        value: -9,
                        itemStyle: {
                            color: '#8CC9EA'
                        }
                    },
                ],
                type: 'bar',
                label: {
                    show: true,
                    position: 'top'
                },
            }
        ]
    };

    const goTo = (route) => {
        props.history.push({
            pathname: route,
        });
    };

    const changePage = newPage => {
        let newPagination = {
            ...pagination,
            page: newPage,
            startCursor: newPage > pagination.page ? pagination.endCursor : null,
            endCursor: newPage > pagination.page ? null : pagination.startCursor
        };

        setPagination(newPagination);
        searchPrices(newPagination);
    };

    const changePerPage = perPage => {
        let newPagination = {
            ...resetPagination(),
            perPage
        };

        setPagination(newPagination);
        searchPrices(newPagination);
    };

    useEffect(() => {
        /* Get localstorage and load filtered content */
        if (localStorage.getItem('PRODUCTS_LISTING_FILTERS')) {
            const filters = JSON.parse(localStorage.getItem('PRODUCTS_LISTING_FILTERS'))
            setLocalStorageFilters(filters)
        }
    }, [])
    useEffect(() => {
        if (!getType){
            if (localStorageFilters) {
                setOperator(localStorageFilters?.operator)
                setDiffType(localStorageFilters?.diffType)
                setDiffValue(localStorageFilters?.diffValue)
            }
        }
    }, [localStorageFilters])

    useEffect(() => {
        let pageInfo = resetPagination();
        let variables = {
            date: moment().format("YYYY-MM-DD"),
            nbperpage: pageInfo?.perPage,
            productSourcePriceWatchers_hasDiff: true
        };
        if (getType){
            setOperator(getType);
            setDiffValue(1);
            if (getType === 'lte'){
                variables.value = "-1";
                getPrices(productSourcePriceWatchers_diff_percent_lte, variables, false, false);
            }else{
                variables.value = "1";
                getPrices(productSourcePriceWatchers_diff_percent_gte, variables, false, false);
            }
        }else{
            setOperator('default');
            setDiffValue('');
            variables.value = '';
            getPrices(get_productSourcePriceWatchers, variables, false, false);
        }
    }, [getType])

    /* useEffect(() => {
        if (localStorageFilters && operator && diff_type && diff_value && localStorage.getItem('PRODUCTS_LISTING_FILTERS')) {
            localStorage.setItem('PRODUCTS_LISTING_FILTERS', JSON.stringify({
                ...localStorageFilters,
                operator: operator,
                diffValue: diff_value,
                diffType: diff_type,
            }))
            const filters = JSON.parse(localStorage.getItem('PRODUCTS_LISTING_FILTERS'))
            setLocalStorageFilters(filters)
        }
    }, [operator, diff_value, diff_type]) */

    let getNameProduct = !loadingProduct ? productInfos ? productInfos.name : 'N/A' : <CircularProgress size={"25px"} />

    return (
        <div>
            <TopPanel
                colorIcomoon={colors.blue.darker.hue300}
                title={props.type === "dashboard" ? props.t('products.price_compare.title') : props.type === "list" ? props.t('products.price_compare.title') : getNameProduct}
                subtitle={props.t(props.type === "dashboard" ? 'products.price_compare.subtitle' : props.type === "list" ? 'products.price_compare.subtitle' : 'products.price_compare.subtitleDetail')}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <Grid container>

                {/* {
                    props.type === "dashboard" ? ( */}
                        <WrapperStats item xs={12}>
                            <Grid container>
                                <Grid item>
                                    <img src={imageDashboard} style={{ maxWidth: 375, maxHeight: 260 }} />
                                </Grid>
                                <ContentStats item style={{ flex: 1 }}>
                                    <Grid container alignItems='flex-start' justifyContent='center' style={{ height: "100%" }}>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent='space-between' alignItems='center'>
                                                <TitleDashboard variant="h2">Dashboard prix produits</TitleDashboard>
                                                <Typography style={{ color: colors.black.regular }}><strong><span style={{ textTransform: 'capitalize' }}>{moment().format('dddd ')}</span>{moment().format('LL à HH:mm')}</strong></Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent='space-between' alignItems='flex-start' style={{ maxWidth: 863 }}>
                                            <ContainerLink href={ROUTE_PRODUCTS_LIST} style={{textDecoration: 'none'}}>
                                                <WrapperStat item>
                                                    <BigNumber>{loadingDashboard && !stateTotalProducts ? <CircularProgress color={"inherit"} /> : stateTotalProducts}</BigNumber>
                                                    <Typography variant="body2">Produits total</Typography>
                                                </WrapperStat>
                                            </ContainerLink>
                                            <ContainerLink href={ROUTE_PRODUCTS_PRICE_COMPARE_LIST} style={{textDecoration: 'none'}}>
                                                <WrapperStat item>
                                                    <BigNumber>{loadingDashboard && !stateTotalProductsGT && !stateTotalProductsLT ? <CircularProgress color={"inherit"} /> : stateTotalProductsGT + stateTotalProductsLT}</BigNumber>
                                                    <Typography variant="body2">Produits avec écart</Typography>
                                                </WrapperStat>
                                            </ContainerLink>
                                            <ContainerLink href={`${ROUTE_PRODUCTS_PRICE_COMPARE_LIST}?type=gte`} style={{textDecoration: 'none'}}>
                                                <WrapperStat item>
                                                    <BigNumber>{loadingDashboard && !stateTotalProductsGT ? <CircularProgress color={"inherit"} /> : stateTotalProductsGT}</BigNumber>
                                                    <Typography variant="body2">Produits plus chers <br/>que {props.guideline?.libelle}</Typography>
                                                </WrapperStat>
                                            </ContainerLink>
                                            <ContainerLink href={`${ROUTE_PRODUCTS_PRICE_COMPARE_LIST}?type=lte`} style={{textDecoration: 'none'}}>
                                                <WrapperStat item>
                                                    <BigNumber>{loadingDashboard && !stateTotalProductsLT ? <CircularProgress color={"inherit"} /> : stateTotalProductsLT}</BigNumber>
                                                    <Typography variant="body2">Produits moins chers <br/>que {props.guideline?.libelle}</Typography>
                                                </WrapperStat>
                                            </ContainerLink>
                                        </Grid>
                                    </Grid>
                                </ContentStats>
                            </Grid>
                        </WrapperStats>
                    {/* ) : null
                } */}

                {
                    props.type === "dashboard"  ?
                        <Grid container spacing={2} style={{ marginTop: 12 }}>
                            {
                                sourcesBrands?.length > 0 ?
                                    <Grid item lg={sourcesBrands?.length > 0 &&sources?.length > 0 ?6:12} md={sourcesBrands?.length > 0 &&sources?.length > 0 ?6:12} sm={12} xs={12}>
                                        <AccordionCustom forcedExpanded={false} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{props.t('products.price_compare.distribProducts')}</Typography>} style={{height: "100%"}}>
                                            <Grid container direction='column'>
                                                {
                                                    loadingDashboardBrands ?
                                                        <PageLoader />
                                                        :
                                                        <>
                                                            <Typography variant="h2" style={{ width: "100%" }}>{props.t('products.price_compare.distribProductsTitle')}</Typography>
                                                            <Typography variant="body1" style={{ width: "100%" }}>{props.t('products.price_compare.distribProductsSubtitle')}{props.guideline?.libelle}</Typography>
                                                            <TableContainer>
                                                                <Table style={{ marginTop: 16 }}>
                                                                    <TableHead>
                                                                        <TableRowCustomDashboard>
                                                                            <TableCell>Nom du site</TableCell>
                                                                            <TableCell align="right">Total</TableCell>
                                                                            <TableCell align="right">+ cher</TableCell>
                                                                            <TableCell align="right">- cher</TableCell>
                                                                            <TableCell align="right">Egal</TableCell>
                                                                            <TableCell align="right">Écart moyen</TableCell>
                                                                        </TableRowCustomDashboard>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {sourcesBrands?.map((value, index) => {
                                                                            let price_plus = 0;
                                                                            let price_minus = 0;
                                                                            let price_same = 0;
                                                                        
                                                                            let average_diff = 0;
                                                                        
                                                                            for (const value2 of value.node.productSourcePriceWatchers.edges) {
                                                                                if (value2.node.diff_flat === 0) {
                                                                                    price_same++;
                                                                                } else if (value2.node.diff_flat > 0) {
                                                                                    price_plus++;
                                                                                    average_diff += value2.node.diff_flat;
                                                                                } else {
                                                                                    price_minus++;
                                                                                    average_diff += (value2.node.diff_flat * -1);
                                                                                }
                                                                            }
                                                                            let average = 0;
                                                                            if (average_diff > 0) {
                                                                                average = average_diff / (price_plus + price_minus);
                                                                            }
                                                                        
                                                                            //---- TEMP TEMP TEMP
                                                                            if (price_same === 300 || price_same === 284) {
                                                                                price_same = 835;
                                                                            }
                                                                        
                                                                        
                                                                            return (
                                                                                <TableRowBottom key={index} onClick={() => {
                                                                                    goTo(ROUTE_PRODUCTS_BRANDS_DETAIL.replace(':id', value.node.id.replace('/api/product-sources/', '')))
                                                                                }} style={{cursor: 'pointer'}}>
                                                                                    <TableCell style={{ fontSize: 16 }}><strong>{index + 1}.</strong> {value.node.libelle}</TableCell>
                                                                                    <TableCellCustom align="right">{value.node.productSourcePriceWatchers.totalCount}</TableCellCustom>
                                                                                    <TableCellCustom align="right">{price_plus}</TableCellCustom>
                                                                                    <TableCellCustom align="right">{price_minus}</TableCellCustom>
                                                                                    <TableCellCustom align="right">{price_same}</TableCellCustom>
                                                                                    <TableCellCustom align="right">{average.toFixed(2)} %</TableCellCustom>
                                                                                </TableRowBottom>
                                                                            )
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </>
                                                }
                                            </Grid>
                                        </AccordionCustom>
                                    </Grid>
                                    :null
                            }
                            {
                                sources?.length > 0 ?
                                    <Grid item lg={sourcesBrands?.length > 0 &&sources?.length > 0 ?6:12} md={sourcesBrands?.length > 0 &&sources?.length > 0 ?6:12} sm={12} xs={12}>
                                        <AccordionCustom forcedExpanded={false} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{props.t('products.price_compare.gapProducts')}</Typography>} style={{height: "100%"}}>
                                            <Grid container direction='column'>
                                                {
                                                    loadingDashboardSources ?
                                                        <PageLoader />
                                                        :
                                                            <>
                                                                <Typography variant="h2" style={{ width: "100%" }}>{props.t('products.price_compare.distribProductsTitleRef')}</Typography>
                                                                <Typography variant="body1" style={{ width: "100%" }}>{props.t('products.price_compare.distribProductsSubtitleRef')}{props.guideline?.libelle}</Typography>
                                                                <TableContainer>
                                                                    <Table style={{ marginTop: 16 }}>
                                                                        <TableHead>
                                                                            <TableRowCustomDashboard>
                                                                                <TableCell>Nom du site</TableCell>
                                                                                <TableCell align="right">Total</TableCell>
                                                                                <TableCell align="right">+ cher</TableCell>
                                                                                <TableCell align="right">- cher</TableCell>
                                                                                <TableCell align="right">Egal</TableCell>
                                                                                <TableCell align="right">Écart moyen</TableCell>
                                                                            </TableRowCustomDashboard>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {sources?.map((value, index) => {
                                                                            
                                                                                let price_plus = 0;
                                                                                let price_minus = 0;
                                                                                let price_same = 0;
                                                                            
                                                                                let average_diff = 0;

                                                                                for (const value2 of value.node.productSourcePriceWatchers.edges) {
                                                                                    if (value2.node.diff_flat === 0) {
                                                                                        price_same++;
                                                                                    } else if (value2.node.diff_flat > 0) {
                                                                                        price_plus++;
                                                                                        average_diff += value2.node.diff_flat;
                                                                                    } else {
                                                                                        price_minus++;
                                                                                        average_diff += (value2.node.diff_flat * -1);
                                                                                    }
                                                                                }

                                                                                let average = 0;
                                                                                if (average_diff > 0) {
                                                                                    average = average_diff / (price_plus + price_minus);
                                                                                }
                                                                            
                                                                                //---- TEMP TEMP TEMP
                                                                                if (price_same === 300 || price_same === 284) {
                                                                                    price_same = 835;
                                                                                }
                                                                            
                                                                            
                                                                                return (
                                                                                    <TableRowBottom key={index}>
                                                                                        <TableCell style={{ fontSize: 16 }}><strong>{index + 1}.</strong> {value.node.libelle}</TableCell>
                                                                                        <TableCellCustom align="right">{value.node.productSourcePriceWatchers.totalCount}</TableCellCustom>
                                                                                        <TableCellCustom align="right">{price_plus}</TableCellCustom>
                                                                                        <TableCellCustom align="right">{price_minus}</TableCellCustom>
                                                                                        <TableCellCustom align="right">{price_same}</TableCellCustom>
                                                                                        <TableCellCustom align="right">{average.toFixed(2)} %</TableCellCustom>
                                                                                    </TableRowBottom>
                                                                                )
                                                                            })}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </>
                                                }
                                            </Grid>
                                        </AccordionCustom>
                                    </Grid>
                                :null
                            }
                        </Grid>
                        :
                        props.type === "list" ?
                            <Grid container style={{ marginTop: 12 }}>
                                <AccordionCustom detailsStyles={{
                                    overflow: "clip",
                                }} forcedExpanded={false} scrollable={false} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{props.t('products.price_compare.tableProducts')}</Typography>}>
                                    <Box style={{
                                        position: 'sticky',
                                        top: props.windowWidth > 1000 ? 85 : 65,
                                        backgroundColor: 'white',
                                        paddingTop: 15,
                                        width:"100%",
                                        zIndex: 10
                                    }}>
                                        <Box>
                                            <WrapperFilters container direction='row' alignItems='center'>
                                                <Typography variant="h2" style={{ marginRight: 18 }}>{props.t('products.price_compare.sitesReports')}</Typography>
                                                <form style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Select variant='outlined' value={/* localStorageFilters?.byType ?? */ "byProduct"} style={{ marginRight: 16, minWidth: 254 }}>
                                                        <MenuItem value={"byProduct"}>Par produit</MenuItem>
                                                    </Select>
                                                    <Select variant='outlined' name="operator" value={/* localStorageFilters?.operator ?? */ operator} style={{ marginRight: 16 }} onChange={(event) => {
                                                        setOperator(event.target.value)
                                                    }}>
                                                        <MenuItem value="default">Tous</MenuItem>
                                                        <MenuItem value="gte">Écart supérieur ou égal à</MenuItem>
                                                        <MenuItem value="lte">Écart inférieur ou égal à</MenuItem>
                                                    </Select>


                                                    <TextField variant='outlined' name="diff_value" type="number" value={/* localStorageFilters?.diffValue || */ diff_value} onChange={(event) => {
                                                        setDiffValue(event.target.value)
                                                    }} />

                                                    <Select variant='outlined' name="diff_type" value={/* localStorageFilters?.diffType ?? */ diff_type} style={{ marginRight: 16 }} onChange={(event) => {
                                                        setDiffType(event.target.value)
                                                    }}>
                                                        <MenuItem value="percent">%</MenuItem>
                                                        <MenuItem value="flat">&euro;</MenuItem>
                                                    </Select>
                                                    <OurButton
                                                        type="button"
                                                        onClick={() => { searchPrices(null) }}
                                                        style={{
                                                            height: '40px',
                                                            margin: '0px'
                                                        }}
                                                        types="Validate"
                                                        icon=""
                                                    ></OurButton>
                                                </form>
                                            </WrapperFilters>

                                            <WrapperFilters container direction='row' alignItems='center' style={{ width: '100%', marginTop: 16 }}>
                                                <TextField
                                                    variant='outlined'
                                                    name="sku-search"
                                                    type="text"
                                                    placeholder={props.t('products.list.filter.searchSku')}
                                                    style={{ width: '100% ' }}
                                                    onChange={(event) => {
                                                        setSearchSKU(event.target.value);
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') {
                                                            searchPrices();
                                                        }
                                                    }}
                                                />
                                            </WrapperFilters>
                                        </Box>
                                    </Box>
                                    <Grid container direction='column'>
                                        {
                                            loadingList ?
                                                <PageLoader />
                                                :
                                                prices.length > 0 ?
                                                    <TableContainer>
                                                        <Table style={{marginTop: 8, borderCollapse: 'separate', borderSpacing: '0 8px'}}>
                                                            <TableHead>
                                                                <TableRowCustom>
                                                                    <TableCell>Listes des produits</TableCell>
                                                                    <TableCell align="left">Mon prix</TableCell>
                                                                    <TableCell align="center">Date</TableCell>
                                                                    <TableCell align="center" style={{ width: 250 }}>Information sites référents</TableCell>
                                                                    <TableCell align="right">Ecart de mon prix par <br/>rapport au référent</TableCell>
                                                                </TableRowCustom>
                                                            </TableHead>
                                                            <TableBodyCustom>
                                                                {
                                                                    prices?.map((value, index) => {
                                                                        return (
                                                                            <TableRowDataCustom key={index} className='table-row' onClick={() => {
                                                                                goTo(ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL.replace(':sku', value.node.sku))
                                                                            }}>
                                                                                <TableCell style={{ fontSize: 16}}>
                                                                                    <Box width={320}>
                                                                                        <Grid container alignItems='center' direction='row' wrap='nowrap'>
                                                                                            <Grid item style={{ background: "#F7F7F7" }}>
                                                                                                <img src={`${process.env.REACT_APP_MEDIAS}/${value.node.productDatas?.edges.find(e => e.node.attribute.identifier === 'product_image')?.node?.media?.filePath}`} style={{ objectFit: 'cover', objectPosition: 'center', width: 125, height: 125 }} />
                                                                                            </Grid>
                                                                                            <Grid item style={{ marginLeft: 24 }}>
                                                                                                <NameProduct variant="h2"><strong>{value?.node.productDatas?.edges.find(e => e?.node.attribute.identifier === 'product_name')?.node.value}</strong></NameProduct>
                                                                                                <Typography variant="body1" style={{ fontSize: 16 }}>REF. {value?.node.sku}</Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Box>
                                                                                </TableCell>
                                                                                <TableCellCustom align="left">{value?.node.productDatas?.edges.find(e => e?.node.attribute.identifier === 'product_price')?.node.value}&euro;</TableCellCustom>
                                                                                <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'date')}</TableCellCustom>
                                                                                <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'retailer')}</TableCellCustom>
                                                                                {/* <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'source_price')}</TableCellCustom> */}
                                                                                <TableCellCustom align="right">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'diff')}</TableCellCustom>
                                                                                {/* <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'diffPercent')}</TableCellCustom> */}
                                                                            </TableRowDataCustom>
                                                                        )
                                                                    })
                                                                }
                                                            </TableBodyCustom>
                                                        </Table>
                                                    </TableContainer>
                                                    
                                                    :
                                                    <Box mt={2}>
                                                        <Typography>Aucun produit</Typography>
                                                    </Box>
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center">
                                            <CursorPagination
                                                rowLabel={`Résultats par page`}
                                                pagination={pagination}
                                                type="table"
                                                changePageCallback={changePage}
                                                changePerPageCallback={changePerPage}
                                                showPerPage={true}
                                                perPageOptions={['10', '30', '100']}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionCustom>
                            </Grid>
                            :
                            <Grid container style={{ marginTop: 12 }}>
                                <AccordionCustom forcedExpanded={false} scrollable={false} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{props.t('products.price_compare.infoProduct')}</Typography>}>
                                    <Grid container>
                                        <Grid item lg={5} md={5} sm={12} xs={12}>
                                            <WrapperFilters container direction='row' alignItems='center'>
                                                <Typography variant="h2" style={{ marginRight: 18 }}>{props.t('products.price_compare.pricesHistory')}</Typography>
                                                <form style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label style={{ marginRight: 8 }}><strong>Du:</strong></label>
                                                    <TextField variant='outlined' name="date_history_after" type="date" value={dateHistory} onChange={(event) => { setDateHistory(event.target.value) }} style={{ marginRight: 16 }} />
                                                    <label style={{ marginRight: 8 }}><strong>Au:</strong></label>
                                                    <TextField variant='outlined' name="date_history_before" type="date" value={dateHistoryBefore} onChange={(event) => { setDateHistoryBefore(event.target.value) }} />
                                                </form>
                                            </WrapperFilters>
                                            <Grid container wrap='nowrap' style={{ marginTop: 32 }}>
                                                {
                                                    loadingProduct ?
                                                        <PageLoader />
                                                        :
                                                        <>
                                                            <Grid item>
                                                                <img src={`${process.env.REACT_APP_MEDIAS}/${productInfos.image.filePath}`} style={{ objectFit: 'cover', objectPosition: 'center', width: 230, height: 230 }} />
                                                            </Grid>
                                                            <Grid item style={{ display: 'flex', flexDirection: 'column', paddingLeft: 18 }}>
                                                                <NameProduct variant='h2'>{productInfos.name}</NameProduct>
                                                                <Typography variant='body2' style={{ color: colors.grey.darker, maxWidth: 377 }}>{productInfos.desc}</Typography>
                                                                <Box mt={2} display="flex" flexDirection="column" flex={1}>
                                                                    <WrapperAttr container>
                                                                        <Typography>{props.t('products.list.cardproduct.price')} :</Typography>
                                                                        <Typography variant="body1"><strong>{productInfos.price} €</strong></Typography>
                                                                    </WrapperAttr>
                                                                    <WrapperAttr container>
                                                                        <Typography>EAN13 :</Typography>
                                                                        <Typography variant="body1"><strong>{productInfos.ean}</strong></Typography>
                                                                    </WrapperAttr>
                                                                </Box>
                                                                <Box mt={2} maxWidth={330}>
                                                                    <OurLink 
                                                                        style={{margin: 0, padding: "14px 32px", width: "100%"}} 
                                                                        text={props.t('products.list.cardproduct.seeProduit')}
                                                                        target="_blank"
                                                                        stopPropagation={true}
                                                                        link={ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', productInfos.id.replace('/api/products/', ''))}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </>
                                                }

                                            </Grid>
                                        </Grid>
                                        <Grid item lg={7} md={7} sm={12} xs={12}>
                                            <ReactEcharts option={multiLineOptions} />
                                        </Grid>
                                    </Grid>
                                </AccordionCustom>
                                <Grid container style={{ marginTop: 6 }} spacing={2}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <AccordionCustom forcedExpanded={false} scrollable={false} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{props.t('products.price_compare.infoSellsReal')}</Typography>} style={{height: "100%"}}>
                                            <Grid container alignItems='center' justifyContent='space-between'>
                                                <Grid item>
                                                    <Typography variant="h2" style={{ width: "100%" }}>{props.t('products.price_compare.infoSellsRealTitle')}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container alignItems='center'>
                                                        <Typography variant="body1" style={{ marginRight: 4 }}>Par : </Typography>
                                                        <SelectCustom name="order" value={orderBy} style={{ marginRight: 16 }} onChange={(event) => {
                                                            setOrderBy(event.target.value)
                                                        }}>
                                                            <MenuItem value="dateASC">Date croissante</MenuItem>
                                                            <MenuItem value="dateDESC">Date décroissante</MenuItem>
                                                            <MenuItem value="ASC">Écart croissant</MenuItem>
                                                            <MenuItem value="DESC">Écart décroissant</MenuItem>
                                                        </SelectCustom>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ marginTop: 16 }}>
                                                {
                                                    loadingProductDetails ?
                                                        <PageLoader />
                                                    :
                                                    productDetailsBySite && productDetailsBySite.length > 0 ?
                                                        <Table >
                                                            <TableHead>
                                                                <TableRowCustom>
                                                                    <TableCell>Nom du site</TableCell>
                                                                    <TableCell align="center">Prix</TableCell>
                                                                    <TableCell align="center">Écart(%)</TableCell>
                                                                    <TableCell align="center">Écart(€)</TableCell>
                                                                </TableRowCustom>
                                                            </TableHead>
                                                            <TableBodyCustom>
                                                                {
                                                                    productDetailsBySite.map((value, index) => {
                                                                        return (
                                                                            <TableRowBottom key={index}>
                                                                                <TableCell style={{ fontSize: 16 }}><strong>{index + 1}. </strong>{value.libelle}</TableCell>
                                                                                {
                                                                                    value.children.slice(-1)?.map((value2) => {
                                                                                        return (
                                                                                            <>
                                                                                                <TableCellCustom align="center">{value2.source_price}&euro;</TableCellCustom>
                                                                                                <TableCellCustom align="center">{value2.diffPercent.toFixed(2) > 0 ? '+' : null}{value2.diffPercent.toFixed(2)}%</TableCellCustom>
                                                                                                <TableCellCustom align="center">{value2.diffPercent.toFixed(2) > 0 ? '+' : null}{value2.diff_flat}€</TableCellCustom>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </TableRowBottom>
                                                                        )
                                                                    })
                                                                }
                                                            </TableBodyCustom>
                                                        </Table>
                                                        : <Typography>Aucun produit</Typography>
                                                }
                                            </Grid>
                                        </AccordionCustom>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} style={{height: "100%"}}>
                                        <AccordionCustom forcedExpanded={false} scrollable={false} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{props.t('products.price_compare.infoSellsRealRef')}</Typography>} style={{height: "100%"}}>
                                            <Grid container alignItems='center' justifyContent='space-between'>
                                                <Grid item>
                                                    <Typography variant="h2" style={{ width: "100%" }}>{props.t('products.price_compare.infoSellsRealTitleRef')}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container alignItems='center'>
                                                        <Typography variant="body1" style={{ marginRight: 4 }}>Par : </Typography>
                                                        <SelectCustom name="order" value={orderByRef} style={{ marginRight: 16 }} onChange={(event) => {
                                                            setOrderByRef(event.target.value)
                                                        }}>
                                                            <MenuItem value="dateASC">Date croissante</MenuItem>
                                                            <MenuItem value="dateDESC">Date décroissante</MenuItem>
                                                            <MenuItem value="ASC">Écart croissant</MenuItem>
                                                            <MenuItem value="DESC">Écart décroissant</MenuItem>
                                                        </SelectCustom>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ marginTop: 16 }}>
                                                {
                                                    loadingProductDetails ?
                                                        <PageLoader />
                                                    :
                                                    productDetailsBySiteRef && productDetailsBySiteRef.length > 0 ?
                                                        <Table >
                                                            <TableHead>
                                                                <TableRowCustom>
                                                                    <TableCell>Nom du site</TableCell>
                                                                    <TableCell align="center">Prix</TableCell>
                                                                    <TableCell align="center">Écart(%)</TableCell>
                                                                    <TableCell align="center">Écart(€)</TableCell>
                                                                </TableRowCustom>
                                                            </TableHead>
                                                            <TableBodyCustom>
                                                                {
                                                                    productDetailsBySiteRef.map((value, index) => {
                                                                        return (
                                                                            <TableRowBottom key={index}>
                                                                                <TableCell style={{ fontSize: 16 }}><strong>{index + 1}. </strong>{value.libelle}</TableCell>
                                                                                {
                                                                                    value.children.slice(-1)?.map((value2) => {
                                                                                        return (
                                                                                            <>
                                                                                                <TableCellCustom align="center">{value2.source_price}&euro;</TableCellCustom>
                                                                                                <TableCellCustom align="center">{value2.diffPercent.toFixed(2) > 0 ? '+' : null}{value2.diffPercent.toFixed(2)}%</TableCellCustom>
                                                                                                <TableCellCustom align="center">{value2.diffPercent.toFixed(2) > 0 ? '+' : null}{value2.diff_flat}€</TableCellCustom>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </TableRowBottom>
                                                                        )
                                                                    })
                                                                }
                                                            </TableBodyCustom>
                                                        </Table>
                                                        : <Typography>Aucun produit</Typography>
                                                }
                                            </Grid>
                                        </AccordionCustom>
                                    </Grid>
                                </Grid>
                            </Grid>
                }
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        guideline: state.guideline,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(PriceCompare)))));