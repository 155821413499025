export const assetCardMapper = (flows,lang) =>{
    return new Promise((resolve,reject)=>{
        let results = flows.map((flow,index)=>{
            return {
                asset: flow,
                id: flow.node.id,
                name: flow.node.name,
                startAt: flow.node.startAt || '-',
                endAt: flow.node.endAt || '-',
                message: flow.node.message,
                status: flow.node.statut,
            }
        })
        resolve(results);
    })
}