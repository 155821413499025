import { LinkText, HelperText } from "../../styles/inputStyled";
import { Grid } from '@mui/material';
import SearchBarProductDynamic from '../../../inputs/SearchBarProductDynamic';
import { goTo } from "../../../../../js/utils/goTo";


const ProductSelector = ({ allProps: props, setSeeError, checkError, seeError, errorMessage }) => {
    return (
        <Grid item xs={12}>
            <SearchBarProductDynamic
                catalog={props.catalogDatas}
                isMulti={false}
                currentLang={'fr_FR'}
                style={{
                    position: 'relative',
                    top: 5,
                    marginBottom: 5
                }}
                onSelect={(value) => {
                    setSeeError(true);
                    checkError(value);
                    props.stateCallback(value);
                }}
                isContent={props.input.contentSelector}
                stateCallback={props.stateCallback}
            />

            {
                seeError && errorMessage ? null :
                    props.input.helper.link
                        ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText>
                        : <HelperText variant="body2">{props.input.helper.text}</HelperText>
            }
        </Grid>
    )
}

export default ProductSelector;