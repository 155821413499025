import { FormInputTypes } from "../../../../../builder/shareable/types"
import CardMinimal from "../../../../layouts/Card/cardContent/CardMinimal"

export const listSettings = {
  grid: {
      components: CardMinimal,
      columns: {
          xs: 1,
          sm: 2,
          laptop: 4,
      },
  }
}

export const perPageOptions = {
  'card': [12, 24],
}

export const viewOptions = {
  current : 'card',
  settings: [
      {
          label: "Card",
          value:"card"
      }
  ]
}

export const filtersOptions = {
  title: 'Filtres et Recherche',
  inputs: [
      {
          xs: 12,
          required: false,
          disabled: false,
          topLabel:"Taper votre recherche",
          label:'Recherche par nom',
          type: FormInputTypes.TEXT,
          stateName: 'libelle',
          helper: {
              position: 'top',
              text: null,
              link: false,
          }
      },
  ]
}

export const listMappers = [
  {
      view: 'card',
      mapper: "attributesConfigMapper"
  },
]