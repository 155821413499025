import React, { useEffect, useState } from 'react';
import {Grid, Box, FormControl, MenuItem, Select, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import Typography from '../../../../ui/typography/Typography';
import CardCustom from '../../../../layouts/Card/CardCustom';
import { SNACK } from '../../../../../js/constants/action-types';
import {withTranslation} from 'react-i18next'
import { checkRouting } from '../../../../../js/utils/checkRouting';
import request from '../../../../../js/utils/fetch';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import { ExpandMore } from '@mui/icons-material';

const AccordionCustom = styled(Accordion)`
    // background : ${colors.grey.lighter.hue980};
    width: 100%;
    box-shadow: none;
    border: 0.5px solid #D0D0D1;
    border-radius: 0;
`;
const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 0 20px;
    border-bottom: 0.5px solid ${colors.grey.lighter.hue700};
    min-height: 40px !important;
    .MuiAccordionSummary-content{
        margin: 8px 0 !important;
        align-items: center;
    }
`;
const AccordionDetailsCustom = styled(AccordionDetails)`
    margin: 32px !important;
    max-height: 550px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    color: ${colors.black.regular};
    p{
        color: ${colors.black.regular};
    }
`;


const StatsRetailers =props=>{
    const [statsLoaded,setStatsLoaded] = useState(false)
    const [daily,setDaily] = useState([])
    const [dailyLoaded,setDailyLoaded] = useState(false)
    const [dailyLoading,setDailyLoading] = useState(true)
    const [global,setGlobal] = useState([])
    const [globalLoaded,setGlobalLoaded] = useState(false)
    const [globalLoading,setGlobalLoading] = useState(true)
    const [sources,setSources] = useState([])
    const [sourcesLoaded,setSourcesLoaded] = useState(false)
    const [sourcesLoading,setSourcesLoading] = useState(true)
    const [assetId,setAssetId] = useState(19)
    const [assets,setAssets] = useState([])
    const [topItemsLoaded,setTopItemsLoaded] = useState(false)
    
    const getStats = ()=> {
        setStatsLoaded(false)
        getDaily()
        getGlobal()
        getSources()
        setStatsLoaded(true)
    }

    const getDaily = (postData = false)=>{
        postData = { "date": "2022-06-14" } //possibilité de passer un jour particulier en param
        request(`${process.env.REACT_APP_API}/games/stats/${assetId}/daily`, 'POST', postData).then(
            (data) => {
                if(data.success){
                    setDaily(data.datas)
                    setDailyLoaded(true)
                    setDailyLoading(false)
                }
            }
        ).catch((err)=>{
            console.log(err)
        });
    }

    const getGlobal = () => {
        request(`${process.env.REACT_APP_API}/games/stats/19/global`, 'GET').then(
            (data) => {
                if(data.success){
                    setGlobal(data.datas)
                    setGlobalLoaded(true)
                }else{
                    setGlobalLoaded(false)
                }
                setGlobalLoading(false)
            }
        ).catch((err)=>{
            console.log(err)
        });
    }

    const getSources = () => {
        request(`${process.env.REACT_APP_API}/games/stats/19/sources`, 'GET').then(
            (data) => {
                if(data.success){
                    setSources(data.datas)
                    setSourcesLoaded(true)
                }else{
                    setSourcesLoaded(false)
                }
                setSourcesLoading(false)
            }
        ).catch((err)=>{
            console.log(err)
        });
    }

    useEffect(()=>{
        checkRouting(props)
        getStats()
    },[])

    return (
        <div>
            {
                statsLoaded ? 
                    <div>
                        <FormControl style={{paddingLeft: '30px'}}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={assets}
                                label="Asset"
                                style={{
                                    border: `0.5px solid ${colors.grey.lighter.hue700}`,
                                    minWidth: '200px',
                                    width: "100%",
                                    padding: "5px 10px"
                                }}
                                disableUnderline
                            >
                                <MenuItem value={"1"}>Asset id 1</MenuItem>
                                <MenuItem value={"2"}>Asset id 2</MenuItem>
                                <MenuItem value={"3"}>Asset id 3</MenuItem>
                            </Select>
                        </FormControl>
                        <Box pb={2}>
                            <AccordionCustom defaultExpanded={true} style={{borderRadius: 0}}>
                                <AccordionSummaryCustom expandIcon={<ExpandMore />}>
                                    <Typography variant={"h4"} style={{fontSize: 14, fontWeight: '600'}}>{props.t('dashboard.realtime')}</Typography>
                                </AccordionSummaryCustom>
                                <AccordionDetailsCustom>
                                    <Grid container direction="row" justifyContent="space-between">
                                        <Grid item lg={4} md={4} xs={4}>
                                            <Box pr={1} height={"100%"}>
                                                <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} contentpadding={"16px 24px"}>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Box mt={1} mb={1}>
                                                                <Typography variant="body2"> <strong><span style={{fontSize: 35, lineHeight: '25px'}}>{ daily.playCount }</span></strong></Typography>
                                                            </Box>
                                                            <Typography variant="body1">
                                                                <strong>Participations</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardCustom>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={4}>
                                            <Box pr={1} height={"100%"}>
                                                <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} contentpadding={"16px 24px"}>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Box mt={1} mb={1}>
                                                                <Typography variant="body2"> <strong><span style={{fontSize: 35, lineHeight: '25px'}}>{ daily.newPlayerCount }</span></strong></Typography>
                                                            </Box>
                                                            <Typography variant="body1">
                                                                <strong>Nouveaux joueurs</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardCustom>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={4}>
                                            <Box pr={1} height={"100%"}>
                                                <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} contentpadding={"16px 24px"}>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Box mt={1} mb={1}>
                                                                <Typography variant="body2"> <strong><span style={{fontSize: 35, lineHeight: '25px'}}>{ daily.zeroPlayPlayer }</span></strong></Typography>
                                                            </Box>
                                                            <Typography variant="body1">
                                                                <strong>Joueurs n'ayant pas terminé</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardCustom>
                                            </Box>
                                        </Grid>
                                        
                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Box>

                        <Box pb={2}>
                            <AccordionCustom defaultExpanded={true} style={{borderRadius: 0}}>
                                <AccordionSummaryCustom expandIcon={<ExpandMore />}>
                                    <Typography variant={"h4"} style={{fontSize: 14, fontWeight: '600'}}>Statistiques globales</Typography>
                                </AccordionSummaryCustom>
                                <AccordionDetailsCustom>
                                    <Grid container direction="row" justifyContent="space-between">
                                        <Grid item lg={4} md={4} xs={4}>
                                            <Box pr={1} height={"100%"}>
                                                <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} contentpadding={"16px 24px"}>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Box mt={1} mb={1}>
                                                                <Typography variant="body2"> <strong><span style={{fontSize: 35, lineHeight: '25px'}}>{ global.playCount }</span></strong></Typography>
                                                            </Box>
                                                            <Typography variant="body1">
                                                                <strong>Participations</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardCustom>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={4}>
                                            <Box pr={1} height={"100%"}>
                                                <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} contentpadding={"16px 24px"}>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Box mt={1} mb={1}>
                                                                <Typography variant="body2"> <strong><span style={{fontSize: 35, lineHeight: '25px'}}>{ global.playerCount }</span></strong></Typography>
                                                            </Box>
                                                            <Typography variant="body1">
                                                                <strong>Joueurs uniques</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardCustom>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={4}>
                                            <Box pr={1} height={"100%"}>
                                                <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} contentpadding={"16px 24px"}>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Box mt={1} mb={1}>
                                                                <Typography variant="body2"> <strong><span style={{fontSize: 35, lineHeight: '25px'}}>{ global.multiPlayPlayer }</span></strong></Typography>
                                                            </Box>
                                                            <Typography variant="body1">
                                                                <strong>Joueurs ayant joué plusieurs fois</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardCustom>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={4}>
                                            <Box pr={1} height={"100%"}>
                                                <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} contentpadding={"16px 24px"}>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Box mt={1} mb={1}>
                                                                <Typography variant="body2"> <strong><span style={{fontSize: 35, lineHeight: '25px'}}>{ global.onePlayPlayer }</span></strong></Typography>
                                                            </Box>
                                                            <Typography variant="body1">
                                                                <strong>Joueurs ayant joué une seule fois</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardCustom>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={4}>
                                            <Box pr={1} height={"100%"}>
                                                <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} contentpadding={"16px 24px"}>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Box mt={1} mb={1}>
                                                                <Typography variant="body2"> <strong><span style={{fontSize: 35, lineHeight: '25px'}}>{ global.zeroPlayPlayer }</span></strong></Typography>
                                                            </Box>
                                                            <Typography variant="body1">
                                                                <strong>Joueurs n'ayant pas terminé</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardCustom>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={4}>
                                            <Box pr={1} height={"100%"}>
                                                <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} contentpadding={"16px 24px"}>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Box mt={1} mb={1}>
                                                                <Typography variant="body2"> <strong><span style={{fontSize: 35, lineHeight: '25px'}}>{global.mostAttractiveDay ? global.mostAttractiveDay.playCount : '' }</span> participations</strong></Typography>
                                                            </Box>
                                                            <Typography variant="body1">
                                                                <strong>Jour le + attractif : {global.mostAttractiveDay ? global.mostAttractiveDay.date : '' }</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardCustom>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Box>

                        <Box pb={2}>
                            <AccordionCustom defaultExpanded={true} style={{borderRadius: 0}}>
                                <AccordionSummaryCustom expandIcon={<ExpandMore />}>
                                    <Typography variant={"h4"} style={{fontSize: 14, fontWeight: '600'}}>Participations par sources</Typography>
                                </AccordionSummaryCustom>
                                <AccordionDetailsCustom>
                                    <Grid container direction="column" justifyContent="center" spacing={0}>
                                        <CardCustom paddingtop={'48px'}>
                                            <Grid container direction="row" spacing={4}>

                                            </Grid>
                                        </CardCustom>
                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Box>                            
                    </div>
                    
                : <PageLoader />
            }
        </div>
    );
}



const mapStateToProps = state => {
    return {
        loading: state.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(StatsRetailers)));
