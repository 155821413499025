import {FORM, STEPPER} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function formPage(action='add', allState, type) {
    let generalInputs   = [
        {
            type : 'text',
            label : 'Libelle',
            stateName : 'pageLibelle',
            required: action === 'add' ? true : false,
            helper: {
                text: 'Libelle de la page',
                link: false,
            },
        },
        {
            type : 'text',
            label : 'Identifiant',
            stateName : 'pageIdentifier',
            required: action === 'add' ? true : false,
            disabled: false,
            helper: {
                text: 'Identifiant de la page',
                link: false,
            },
        },
    ]
    if (allState.assetTypeIdentifier !== "pages"){
        generalInputs.push(
            {
                type : 'text',
                label : 'Méta Title',
                stateName : 'metaTitle',
                required: false,
                helper: {
                    text: 'Méta title de la page',
                    link: false,
                },
            },
            {
                type : 'text',
                label : 'Méta Description',
                stateName : 'metaDesc',
                required: false,
                helper: {
                    text: 'Méta description de la page',
                    link: false,
                },
            },
            {
                type : 'text',
                label : 'Méta Keywords',
                stateName : 'metaKeywords',
                required: false,
                helper: {
                    text: 'Méta keywords de la page',
                    link: false,
                },
            },
            {
                type: 'radio',
                direction: 'row',
                label: 'Existant côté site',
                helper: {
                    text: 'Indiquez l\'état',
                    link: false,
                },
                required: true,
                stateName: 'pageIsExisting',
                value: [
                    {
                        value: true,
                        label: 'Oui'
                    },
                    {
                        value: false,
                        label: 'Non'
                    },
                ]
            })
    }
    if (allState.pageIsExisting){
        generalInputs.push({
            required : true,
            type : 'text',
            label : 'ID page',
            stateName : 'pageIdMagentoPage',
            helper: {
                text: 'ID de la page distante',
                link: false,
            },
        })
    }
    generalInputs.push(
        // {
        //     type: 'select',
        //     disabled: action === 'edit' ? true : false,
        //     label: 'Type de page',
        //     helper: {
        //         text: 'Choississez un type de page',
        //         link: false,
        //     },
        //     required: true,
        //     stateName: 'pageType',
        //     value: allState.listTypesPage?.map(type => {
        //         return ({
        //             value: type.node.id,
        //             label: type.node.libelle || type.node.identifier
        //         });
        //     })
        // },
        {
            type: 'radio',
            direction: 'row',
            label: 'Actif',
            helper: {
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'pageStatus',
            value: [
                {
                    value: true,
                    label: 'Oui'
                },
                {
                    value: false,
                    label: 'Non'
                },
            ]
        },
        {
            type: 'radio',
            direction: 'row',
            label: 'Utilisé comme catégorie',
            helper: {
                text: 'Indiquez si la page peut être utilisé comme une catégorie',
                link: false,
            },
            required: true,
            stateName: 'useInCategory',
            value: [
                {
                    value: true,
                    label: 'Oui'
                },
                {
                    value: false,
                    label: 'Non'
                },
            ]
        }
    )

    var form = {
        titleForm: action === 'add'? `Créer une ${type === "home" ? "home" : "landing"}`:`Modifier une ${type === "home" ? "home" : "landing"}`,
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre ${type === "home" ? "home" : "landing"}`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations générales',
            },
            // finalStep: {
            //     title: 'Et voilà !',
            //     subtitle: action === 'add' ? `Valider la création de cette ${type === "home" ? "home" : "landing"}` : `Valider la modification de cette ${type === "home" ? "home" : "landing"}`,
            //     textButton: action === 'add' ? 'Créer' : 'Modifier'
            // },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}