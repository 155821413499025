import { createRef, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { withRouter } from 'react-router';
import 'moment/locale/fr';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
//import Button from '../../components/ui/button/Button';
import Button from 'ui/button/Button';
import clsx from 'clsx';
import { connect } from "react-redux";
import { Grid, Box, Divider, TextField, FormControlLabel, Switch } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JohnDoe from '../../assets/images/john-doe.png';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import colors from 'colors';
import logoSinfin from '../../assets/images/Logo-sinfin.svg';
import logoSinfinWhite from '../../assets/images/sinfin-logo-white.svg';
import styled from 'styled-components';
import Typography from 'ui/typography/Typography';
import { SET_THEME, START_LOADING } from 'js/constants/action-types';
import { ROUTE_HOME, ROUTE_SETTINGS_DETAIL_USER, ROUTE_CRM_SETTINGS_DETAIL_USER } from 'js/constants/route-names';
import i18n from '../../i18n';
import { withTranslation } from 'react-i18next'
import { deleteLocalStorage } from 'js/utils/deleteLocalStorage';
import OurTypography from 'ui/typography/Typography';
import { isImgUrl } from '../../js/utils/isValidImage';

const isCRM = process.env.REACT_APP_MODE_CRM === 'true';

const SelectLang = styled(TextField)`
    fieldset{
        border: none;
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 0px 16px !important;
        margin-right: 16px;
        color: ${colors.black.regular};
        font-weight: bold;
        border-radius: 0;
        &:focus{
            background: none;
        }
        @media screen and (max-width: 1450px){
            font-size: 14px;
            line-height: 18px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            line-height: 17px;
        }
        @media screen and (max-width: 960px){
            font-size: 12px;
            line-height: 16px;
        }
    }
    svg{
        fill: ${colors.black.regular};
        font-weight: bold;
    }
    & > p{
        margin: 0;
    }
`;

const SpreadLogo = styled.img`
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 90px;
    /* max-width: 110px; */
    max-height: 70px;
    // @media screen and (max-width: 1000px){
    //     max-width: 190px;
    // }
    // @media screen and (min-width: 1001px){
    //     left: 50%;
    //     transform: translate(-50%);
    // }
`
const AppBarCustom = styled(AppBar)`
    &.MuiAppBar-root{
        width: ${props => props.drawerwidth ? `calc(100% - ${props.drawerwidth}px) !important` : '100% !important'};
    }
    @media screen and (max-width: 1000px){
        .MuiToolbar-root{
            padding-left: ${props => props.opened ? '8px' : '52px'}
        }
    }
`

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: 380,
        paddingTop: '12px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));


let demo = true;
var drawerWidth = 300;

if (window.innerWidth <= 1600 && window.innerWidth > 1400) {
    drawerWidth = 240;
}
else if (window.innerWidth <= 1400) {
    drawerWidth = 210;
}

const styles = theme => ({
    root: {
        display: 'flex',
    },
    select: {
        marginTop: 40,
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.06), 0px 8px 10px 1px rgba(0,0,0,0.06), 0px 3px 14px 2px rgba(0,0,0,0.06)",
        "& ul": {
            // backgroundColor: 'r',
            padding: 0,

        },
        "& li": {
            fontSize: 14,
            color: colors.blue.darker.hue300
        },
        "& li.MuiListItem-root.Mui-selected, & li.MuiListItem-root.Mui-selected:hover": {
            backgroundColor: colors.blue.darker.hue300,
            color: colors.white
        },
        "& li.MuiListItem-root:hover": {
            backgroundColor: colors.blue.darker.hue900,
            // color: colors.white
        },
    },
    appBar: {
        // zIndex: theme.zIndex.drawer + 1,
        right: 0,
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: 'none',
        borderBottom: `0.5px solid ${colors.grey.lighter.hue700}`,
        borderLeft: 'none',
        background: colors.white,
    },
    appBarShift: {
        marginLeft: 0,
        right: 0,
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingRight: '0 !important',
        paddingLeft: '0px',
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: 0,
        marginLeft: 0,
        width: '33%',
    },
    searchIcon: {
        width: 'auto',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: colors.grey.lighter.hue600,
    },
    inputRoot: {
        color: colors.grey.lighter.hue600,
        width: '100%',
        fontSize: 16,
    },
    inputInput: {
        padding: theme.spacing(1, 4, 1, 4),
        transition: theme.transitions.create('width'),
        width: '100%',
    },
    toolBar: {
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        paddingRight: 8,
        justifyContent: 'space-between',
    },
    logout: {
        color: '#000',
        position: 'absolute',
        right: 20,
        cursor: 'pointer'
    }
});

const Appbar = props => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null)
    const [userImage, setUserImage] = useState(false)
    const [currentLang, setCurrentLang] = useState(props.locales?.[0]?.node?.code)
    const ref = createRef()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const goTo = route => {
        props.history.push(route);
        handleClose();
    };

    const goToWithId = (route, id) => {
        props.history.push({
            pathname: route,
            state: { userId: id }
        });
    };

    const handleChangeLang = (lang) => {
        if (lang === "fr_FR") {
            localStorage.setItem('DXP_LANG', 'en_US');
            i18n.changeLanguage('en_US');
        } else {
            localStorage.setItem('DXP_LANG', 'fr_FR');
            i18n.changeLanguage('fr_FR');
        }
    }

    const updateWindowDimensions = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        isImgUrl(`${process.env.REACT_APP_API_ROOT}/${props.user.image}`)
            .then((result) => {
                setUserImage(result)
            }).catch((err) => {
                console.log(err)
            });
    }, [])

    useEffect(() => {
        props.setAppBarHeight(ref.current.clientHeight)
    }, [ref])

    const changeTheme = () => {
        props.toggleTheme(props.theme === "light" ? "dark" : "light")
        localStorage.setItem('DXP_THEME', JSON.stringify(props.theme === "light" ? "dark" : "light"))
    }

    return (
        <AppBarCustom
            ref={ref}
            position="fixed"
            className={clsx(props.classes.appBar, {
                [props.classes.appBarShift]: props.opened,
            })}
            elevation={0}
            color={"inherit"}
            drawerwidth={props.drawerWidth}
            opened={props.opened}
        >
            <Toolbar className={clsx(props.classes.toolBar, {
            })}>

                {/* <IconButtonCustom
                        color="inherit"
                        className={props.opened ? 'open' : 'close'}
                        aria-label="open drawer"
                        onClick={props.openCallback}
                        edge="start"
                        drawerwidth={props.drawerWidth}
                    >
                        {props.opened ? <CloseRounded /> : <MenuIcon />}
                    </IconButtonCustom> */}

                {/* {
                    isCRM ?
                        null
                    :
                        <EncartTopLeft className={props.opened ? 'open' : 'close'} imagefound={(getGuidelineImg() !== null && isCRM === false) ? "true" : "false"} drawerwidth={props.opened ? props.drawerWidth : drawerWidth} onClick={() => goTo(ROUTE_HOME)} style={{cursor: 'pointer'}}>
                            {
                                getGuidelineImg() !== null && isCRM === false ? (
                                    <img src={getGuidelineImg()} alt={getGuidelineName()} />
                                ) : getGuidelineName() !== null && isCRM === false ? (
                                        <Typography variant="h2">{getGuidelineName()}</Typography>
                                    ) : null
                            }
                        </EncartTopLeft>
                } */}
                <SpreadLogo className={clsx(props.classes.logoDXP)} src={props.theme === 'light' ? logoSinfin : logoSinfinWhite} onClick={() => goTo(ROUTE_HOME)} style={{ cursor: 'pointer' }} height={isCRM ? "32px" : "auto"} width={isCRM ? '' : '285px'} alt="logo-dxp" />
                {demo !== true && isCRM !== true ?
                    <div className={props.classes.search}>
                        <div>
                            <div className={props.classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder={props.t("menu.lookInto")}
                                classes={{
                                    root: props.classes.inputRoot,
                                    input: props.classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    </div>
                    : null}
                <div className={props.classes.grow} />
                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }} >
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        disableElevation
                        style={{ background: 'transparent', color: colors.black.regular, textTransform: 'inherit', paddingLeft: 0, paddingRight: 0 }}
                    >
                        {
                            props.windowWidth > 1200 ?
                                (
                                    <Grid item style={{
                                        width: 38,
                                        height: 38,
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: '50%'
                                    }}>
                                        {
                                            userImage ?
                                                <img src={`${process.env.REACT_APP_API_ROOT}/${props.user.image}`} style={{
                                                    height: '100%',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)'
                                                }} />
                                                :
                                                <div style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    height: '72px',
                                                    width: '72px',
                                                    borderRadius: '50%',
                                                    fontWeight: 'bold',
                                                    fontSize: '18px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: colors.grey.lighter.hue900,
                                                }}><span style={{
                                                    color: colors.grey.lighter.hue600,
                                                }}>{props.user.firstname.charAt(0).toUpperCase()}{props.user.lastname.charAt(0).toUpperCase()}</span></div>
                                        }
                                    </Grid>
                                ) : null
                        }
                        <Typography
                            variant="body1"
                            style={{
                                background: 'transparent',
                                color: colors.black.regular,
                                textTransform: 'inherit',
                                // minWidth: props.windowWidth > 1200 ? 160 : null, 
                                maxWidth: 200,
                                marginLeft: 8,
                                paddingLeft: props.windowWidth > 1200 ? 0 : 8,
                                textAlign: 'end',
                                fontSize: 14,
                                paddingRight: 3,
                                fontWeight: '500',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {props.user.firstname} {props.user.lastname}
                        </Typography>
                        <ExpandMoreIcon />
                    </Button>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        autoFocus={false}
                    >
                        <Grid container>
                            {/* Profile */}
                            {
                                props.windowWidth > 1200 ?
                                    (
                                        <Grid item xs={4} style={{
                                            width: 72,
                                            height: 72,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            borderRadius: '50%'
                                        }}>
                                            {props.user.image ? (
                                                <img src={userImage ? `${process.env.REACT_APP_API_ROOT}/${props.user.image}` : JohnDoe} style={{
                                                    height: '100%',
                                                    borderRadius: '50%',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)'
                                                }} />
                                            ) : (
                                                <div style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    height: '72px',
                                                    width: '72px',
                                                    borderRadius: '50%',
                                                    fontWeight: 'bold',
                                                    fontSize: '34px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: colors.grey.lighter.hue900,
                                                }}><span style={{
                                                    color: colors.grey.lighter.hue600,
                                                }}>{props.user.firstname.charAt(0).toUpperCase()}{props.user.lastname.charAt(0).toUpperCase()}</span></div>
                                            )}
                                        </Grid>
                                    ) : null
                            }
                            <Grid item xs={8} style={{ marginBottom: '16.5px' }}>
                                <OurTypography fontweighttext={700} colortext={colors.black.regular} style={{
                                    fontSize: '20px',
                                    marginTop: '5px',
                                }} >{props.user.firstname} {props.user.lastname}</OurTypography>
                                {/* BRAND */}
                                {/* {
                                        getGuidelineName() !== null ? (
                                            <OurTypography colortext={colors.black.regular} style={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                            }} text={getGuidelineName()} />
                                        ) : null
                                    }
                                    {
                                        getLinkBrand() !== null ? (
                                            <OurTypography colortext={colors.black.regular} style={{
                                                fontSize: '12px',
                                                fontWeight: '500'
                                            }} text={getLinkBrand()} />
                                        ) : null
                                    } */}
                                <OurTypography colortext={colors.grey.lighter.hue600} style={{
                                    fontSize: '12px',
                                }}>{props.user.email}</OurTypography>
                                <OurTypography onClick={() => {
                                    goToWithId(isCRM ? ROUTE_CRM_SETTINGS_DETAIL_USER : ROUTE_SETTINGS_DETAIL_USER.replace(':id', props.user.id.replace('/api/users/', '')))
                                    handleClose()
                                }} colortext={colors.grey.darker} style={{
                                    fontSize: '14px',
                                    marginTop: '10px',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}>{props.t("homepage.appbar.profileManagement")}</OurTypography>
                            </Grid>
                        </Grid>
                        <Divider style={{ backgroundColor: colors.grey.border }} />
                        <Box py={'20px'} mx={'17px'} style={{ /* borderBottom: `1px solid ${colors.grey.border}` */ }}>
                            <OurTypography text={props.t("homepage.appbar.yourPlan")} fontweighttext={600} colortext={colors.black.regular} style={{ fontSize: '14px' }} />
                            <OurTypography text={process.env.REACT_APP_FORMULE ?? '-'} colortext={colors.black.regular} style={{ marginTop: '3.5px', fontSize: '14px' }} />
                        </Box>
                        {/* STOCKAGE */}
                        {/* <Box py={'20px'} mx={'17px'}>
                                <OurTypography text={'Stockage'} fontweighttext={600} colortext={colors.black.regular} style={{ fontSize: '14px', marginBottom: '4px' }} />
                                <Grid container justifyContent='space-between'>
                                    <OurTypography text={'546,46 Mo/1 To'} colortext={colors.grey.regular} style={{ fontSize: '10px' }} />
                                    <OurTypography text={'24 %'} colortext={colors.grey.regular} style={{ fontSize: '10px' }} />
                                </Grid>
                                <Bar>
                                    <Tooltip
                                        title={'Stockage'}
                                        aria-label="add"
                                        placement="top"
                                        arrow
                                    >
                                        <span style={{ backgroundColor: colors.blue.darker.hue300, width: `${24}%` }} />
                                    </Tooltip>
                                </Bar>
                            </Box> */}
                            <Divider style={{ backgroundColor: colors.grey.border }} />
                            <Grid container justifyContent='space-between' alignItems='baseline' style={{
                                padding: '10px 3px 10px 17px',
                            }}>
                                <Grid item>
                                    <OurTypography text={props.t("menu.disconnect")} fontweighttext={600} colortext={colors.black.regular} style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => {
                                        props.startLoading();

                                    setTimeout(() => {
                                        deleteLocalStorage();
                                    }, 1000);
                                }} />
                            </Grid>
                            <Grid container alignItems='baseline' style={{
                                width: 'auto'
                            }}>
                                {/* <span style={{ fontWeight: i18n.language === "fr_FR" ? "bold" : null, textDecoration: i18n.language === "fr_FR" ? "underline" : null }}>FR</span> | <span style={{ fontWeight: i18n.language === "fr_FR" ? null : "bold", textDecoration: i18n.language === "fr_FR" ? null : "underline" }}>EN</span> */}
                                <div style={{ color: colors.black.regular }}>{props.t("content.content.choiceLanguage")}</div>
                                <SelectLang
                                    variant="outlined"
                                    color="secondary"
                                    select
                                    // fullWidth
                                    value={localStorage.getItem('DXP_LANG')}
                                    onChange={evt => {
                                        handleChangeLang(localStorage.getItem('DXP_LANG'))
                                    }}
                                    SelectProps={{ MenuProps: { classes: { paper: props.classes.select } } }}
                                >
                                    <MenuItem key={'FR'} value={'fr_FR'}>
                                        FR
                                    </MenuItem>
                                    <MenuItem key={'EN'} value={'en_US'}>
                                        EN
                                    </MenuItem>
                                </SelectLang>
                            </Grid>
                        </Grid>
                        {
                            false ?
                                <>
                                    <Divider style={{ backgroundColor: colors.grey.border }} />
                                    <Grid container justifyContent='space-between' alignItems='baseline' style={{
                                        padding: '25px 3px 5.5px 17px',
                                    }}>
                                        <FormControlLabel control={<Switch checked={props.theme === 'light' ? true : false} onChange={changeTheme} />} label="Thème" />
                                    </Grid>
                                </>
                                : null
                        }
                    </StyledMenu>
                </div>
            </Toolbar>
        </AppBarCustom>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        toggleTheme: (theme) => dispatch({ type: SET_THEME, payload: { theme } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        guideline: state.guideline,
        locales: state.locales,
        theme: state.theme
    };
};

export default withTranslation()(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Appbar))));