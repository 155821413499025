import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../../../js/constants/alert-types';
import PictoCSV from '../../../../../../assets/pictos/csv.svg';
import axios from '../../../../../../js/utils/axios';
import request from '../../../../../../js/utils/fetch';
import FormBuilder from '../../../../../../components/ui/form/FormBuilder';
import styled from 'styled-components';
import colors from '../../../../../../config/theme/colors';

const WrapperButton = styled.div`
    background: ${colors.blue.darker.hue300};
    border-radius: 4px;
    color: white;
    margin-top: -8px;
    padding: 8px 16px;
    width: 190px;
    text-align: center;
    margin-bottom: 16px;
    margin-left: 38px; 
    cursor: pointer;
    &:hover{
        background: ${colors.black.regular}
    }
`;

class UploadFile extends React.Component {  
    state = {
        hover: false,
        file: null,
        uploading: false
    };

    componentDidMount() {
        this.setState({ 
            inputs: [
                {
                    type: 'select',
                    isContainerized: true,
                    label: 'Séparateur',
                    translated: false,
                    helper: {
                        text: `Choisir le séparateur`,
                        link: false,
                    },
                    required: true,
                    stateName: 'importSep',
                    value: [
                        { value: ';', label: ';' },
                        { value: ',', label: ',' },
                        { value: '|', label: '|' }
                    ]
                }
            ]
        });
    }

    error = (error) => {
        this.props.snack(ALERT_ERROR, error);
        this.setState({ hover: false });
    };

    onDrop = (acceptedFiles) => {
        const { stateCallback } = this.props;

        let file = acceptedFiles[0];

        if (!file)
            return this.error('Fichier non supporté');

        if (file.name.indexOf('.csv') < 0)
            return this.error('Le fichier doit être de type CSV');

        stateCallback('importFile', file, null, null, () => {
            this.setState({ hover: false, uploading: true }, async () => {
                try {
                    let formData = new FormData();
    
                    formData.append('file', file);
                    formData.append('type', 'csv');
    
                    let resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);

                    let headersFormData = new FormData();
    
                    headersFormData.append('csv', `${process.env.REACT_APP_API_ROOT}${resultMedia.contentUrl}`);
                    headersFormData.append('separator', this.props.allState['importSep']);
                    
                    let resultHeaders = await axios(`${process.env.REACT_APP_API_ROOT}/api/export/csv/structure`, 'post', headersFormData);

                    this.props.stateCallback('headers', resultHeaders.message);
                    this.props.stateCallback('media', resultMedia);
    
                    this.setState({
                        uploading: false
                    });
                } catch(e) {
                    console.log(e);
                }
            });
        });
    };

    onDragEnter = () => {
        this.setState({ hover: true });
    };

    onDragLeave = () => {
        this.setState({ hover: false });
    };

    handlerMutationExport = async () => {
        let downloadType = null;
        if (this.props.category){
            downloadType = 'excel/header/contents/categories';
        }else{
            downloadType = 'excel/header/contents';
        }
        try {
            this.props.startLoading();
            request(`${process.env.REACT_APP_API}/export/${downloadType}`, 'get', false).then(
                (data) => {
                    if(data.success){
                        window.open(data.file, '_blank');
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, `Téléchargement réussi !`, 10000);
                    }else{
                        this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
                        this.props.stopLoading();
                    }
                }
            ).catch((e) => {
                this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
                this.props.stopLoading();
            });
        } catch(e) {
            this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
            this.props.stopLoading();
        }
    };

    render() {
        const { hover, uploading, inputs } = this.state;
        const { allState, stateCallback } = this.props;

        return (
            <div style={{ paddingTop: 15, paddingBottom: 15, backgroundColor: 'rgb(250, 251, 251)' }}>
                <FormBuilder 
                    optionsInputs={inputs} 
                    stateCallback={stateCallback} 
                    allState={allState}
                />
                <WrapperButton onClick={() => this.handlerMutationExport()}>Télécharger un example</WrapperButton>
                <Dropzone 
                    onDrop={this.onDrop} 
                    onDragEnter={this.onDragEnter} 
                    onDragLeave={this.onDragLeave} 
                    multiple={false}
                >
                    {({getRootProps, getInputProps}) => (
                        <section style={{
                            border: 'dashed 1px #C5DEEC',
                            backgroundColor: hover ? '#eee' : '#fff',
                            borderWidth: 2,
                            borderRadius: 4,
                            height: 300,
                            cursor: 'pointer',
                            width: '90%',
                            margin: '0 auto'
                        }}>
                            <div {...getRootProps()} style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <input {...getInputProps()} disabled={uploading} />

                                {
                                    allState['importFile'] 
                                        ? (
                                            <>
                                                <img src={PictoCSV} style={{ width: 75, marginBottom: 15 }} />
                                                <div>{ allState['importFile'].name } { uploading ? '(chargement...)' : null }</div>
                                                <div style={{ textDecoration: 'underline', color: '#0273A5', marginTop: 5 }}>
                                                    Changer de fichier
                                                </div>      
                                            </>
                                        )
                                        : (
                                            <>
                                                <div>+ Ajouter un fichier (.csv)</div>
                                                <div style={{ textDecoration: 'underline', color: '#0273A5', marginTop: 5 }}>
                                                    Sélectionner un fichier
                                                </div>                         
                                            </>
                                        )
                                }
                            
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default connect(null, mapDispatchToProps)(UploadFile);