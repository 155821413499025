import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import H2 from '../shared/h2';
import Div from '../shared/div';
import Img from '../shared/img';

const SliderImage = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data,
        inputCallback = null,
        spread = false
    } = props;

    const initSliders = () => {
        new Swiper('.swiper-container-image-carre', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: '.swiper-button-next-image',
                prevEl: '.swiper-button-prev-image',
            },
            spaceBetween: 10,
            breakpoints: {
                300:{
                    slidesPerView: 1,
                },
                700:{
                    slidesPerView: 2,
                },
                1100:{
                    slidesPerView: 3.2,
                },
            }
        });
        
        new Swiper('.swiper-container-image-horizontal', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: '.swiper-button-next-image',
                prevEl: '.swiper-button-prev-image',
            },
            spaceBetween: 10,
            slidesPerView: 1.3,
        });           
        
        new Swiper('.swiper-container-image-fullscreen', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: '.swiper-button-next-image',
                prevEl: '.swiper-button-prev-image',
            },
            spaceBetween: 10,
            slidesPerView: 1,
        });
    };
    
    useEffect(() => {
        initSliders();
    }, []);

    useEffect(() => {
        initSliders();
    }, [props]);


    return (
        <Div style={{ position: 'relative', background: data.blocks.background.inputs.backgroundColor.value }} className="slider-image" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            {
                data.blocks.title.inputs.value.value || (data.blocks.link.inputs.link.value && data.blocks.link.inputs.value.value) ? (
                    <Div className="title-box" style={{justifyContent: !data.blocks.title.inputs.value.value && (data.blocks.link.inputs.link.value && data.blocks.link.inputs.value.value) ? 'flex-end' : null,width:data.inputs.noPaddingHorizontal.value? "100%":null}}>
                        {
                            data.blocks.title.inputs.value.value ? (
                                <H2 className="w100 uppercase">{data.blocks.title.inputs.value.value}</H2>
                            ) : null
                        }
                        {
                            data.blocks.link.inputs.link.value && data.blocks.link.inputs.value.value ? (
                                <a 
                                href={data.blocks.link.inputs.link.value} 
                                style={{
                                    borderWidth:data.blocks.link.inputs.border?.inputs.borderWidth.value?data.blocks.link.inputs.border.inputs.borderWidth.value:null,
                                    borderColor:data.blocks.link.inputs.border?.inputs.borderWidth.value?data.blocks.link.inputs.border.inputs.borderColor.value:null,
                                    borderRadius:data.blocks.link.inputs.border?.inputs.borderRadius.value?data.blocks.link.inputs.border.inputs.borderRadius.value:null,
                                    borderStyle:data.blocks.link.inputs.border?.inputs.borderWidth.value?"solid":null,
                                    padding:data.blocks.link.inputs.border?.inputs.borderWidth.value?"5px 10px":null,
                                }}>{data.blocks.link?.inputs.value.value}</a>
                            ) : null
                        }
                    </Div>
                ) : null
            }
            <Div className={`swiper-container swiper-container-image-${data.inputs.formatSlider.value}`} style={{width: data.inputs.formatSlider.value === 'fullscreen'||data.inputs.noPaddingHorizontal.value ? '100%' : null, marginLeft: data.inputs.formatSlider.value === 'fullscreen'|| data.inputs.noPaddingHorizontal.value ? 0 : null, paddingTop: data.blocks.title.inputs.value.value || (data.blocks.link.inputs.link.value && data.blocks.link.inputs.value.value) ? null : 0}}>
                <div className="swiper-wrapper">
                    {
                        data.blocks.slides.config.map((slide, index) => (
                            <Div className="swiper-slide w100 flexColumn" key={index}>
                                {
                                    slide.blocks.link?.inputs.link.value ? (
                                        <a href={slide.blocks.link?.inputs.link.value}>
                                            <Img 
                                                src={
                                                    typeof slide.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                                        slide.blocks.imagePrincipale?.inputs.value.value 
                                                    : slide.blocks.imagePrincipale?.inputs.value.value.filePath ? 
                                                        `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                                    : null
                                                }
                                                alt={slide.blocks.imagePrincipale?.inputs?.alt?.value ?? "Illustration"}
                                            />
                                        </a>
                                    ) : (
                                        <Img 
                                            src={
                                                typeof slide.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                                    slide.blocks.imagePrincipale?.inputs.value.value 
                                                : slide.blocks.imagePrincipale?.inputs.value.value.filePath ? 
                                                    `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                                : null
                                            }
                                            alt={slide.blocks.imagePrincipale?.inputs?.alt?.value ?? "Illustration"}
                                        />
                                    ) 
                                }
                                {
                                    slide.blocks.link?.inputs.value.value && slide.blocks.link?.inputs.link.value ? (
                                        <a className="linkText" href={slide.blocks.link?.inputs.link.value}>{slide.blocks.link?.inputs.value.value}</a>
                                    ) : null
                                }
                            </Div>
                        ))
                    }
                </div>
                <div className="swiper-scrollbar swiper-scrollbar-image"></div>
                <div className="swiper-button-prev swiper-button-prev-image"></div>
                <div className="swiper-button-next swiper-button-next-image"></div>
            </Div>
        </Div>
    );
};

export default SliderImage;