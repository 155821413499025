import * as pagination from './pagination';
import * as sortBy from './sortingBy';

/**
 * @param {Object} states - Les states du composant
 * @param {string} view - View choisie : table,cards
 * @returns {Object} - Views mise à jour
 */
export function changeViews(states,view){
    return new Promise((resolve,reject)=>{
        let views = states.views;
        views['current'] = view;
        resolve(views)
    })
}

export {pagination,sortBy};

/**
 * @param {Object} result - Resultats de la Query
 * @param {string} listNaming - Nom de la future liste d'items
 * @param {Object} state - States du composant
 * @param {Object} naming - Tableau des noms de states à changer 
 * @returns {Object} - Resultats de la query formatés
 */
export const handleResult = async (result,listNaming,state,naming={},type = "edges",identifier,firstRender)=>{
    let obj={};

    let newPagination = await pagination.updatePaginationData(state.pagination,result,type,identifier,firstRender);

    obj[naming.pagination ? naming.pagination : 'pagination'] = newPagination;
    obj["totalCount"] = result?.totalCount ?? null;
    if (type === "edges"||type === 'itemsPerPage'){
        obj[listNaming] = state.searchActive  && state.noResult ? null : result?.edges;
        obj[naming.noResult ? naming.noResult : 'noResult'] =state.searchActive  && state.noResult ? true :  result?.edges.length > 0 ? false : true;        
    }else{
        obj[listNaming] = state.searchActive  && state.noResult ? null : result.collection;
        obj[naming.noResult ? naming.noResult : 'noResult'] =state.searchActive  && state.noResult ? true :  result.collection?.length > 0 ? false : true;
    }
    obj[naming.ready ? naming.ready : 'ready'] = true;
    return await obj
}

export const initQuery = async (state,customVar,type,typeResult = "edges",naming) => {
    let paginationData = await pagination.paginationQueryData(state.pagination, typeResult);
    let obj = {
        queryData : {},
        states : {}
    };
    obj.queryData = {...customVar,...paginationData};
    obj.states[naming?.ready ? naming?.ready : 'ready'] = false;

    let result = obj[type];
    return await result 
}

