import React from 'react';
import { ButtonGroup, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import colors from '../../../config/theme/colors';
import styled from 'styled-components';

const ButtonGroupCustom = styled(ButtonGroup)`
    margin-bottom: 20px;
    max-width: 100%;
    display: block;
    button{
        background-color: ${colors.white};
        color: ${colors.grey.regular};
        text-transform: inherit;
        padding: 6px 10px;
        margin-top: 2px;
        &.selected{
            background-color: ${colors.blue.lighter.hue300};
            color: ${colors.white};            
        }
    }
`;

export default function ButtonGroupInput(props) {
    const handleInputChange = (event, value) => {
        props.onChange(value);
    };
    return(
        <>
            <Typography gutterBottom>
                { props.label }
            </Typography>
            <ButtonGroupCustom color="primary" aria-label="outlined primary button group">
                {props.params.map((option, index) => 
                    (
                        <Button 
                            key={index} 
                            value={option.value} 
                            selected={option.value === props.value} 
                            className={option.value === props.value ? 'selected' : ''} 
                            onClick={event => handleInputChange(event, option.value)}
                        >
                            {option.label}
                        </Button>
                    )
                )}
            </ButtonGroupCustom>
        </>
    );
}
