import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@ui/button/Button";
import colors from "@config/theme/colors";
import Icon from "@ui/icons/Icon";
import PropTypes from "prop-types";

const FinalStep = ({
  icon = "check",
  title = "Bientôt prêt !",
  text,
  action,
}) => {
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ padding: "30px" }}
    >
      <Grid item xs={3}>
        <Box textAlign={"center"}>
          <Icon
            icon={icon}
            style={{
              fill: colors.green.regular,
              width: "100%",
              height: "100%",
              maxHeight: "54px",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign={"center"} mt={2}>
          <Typography variant={"h2"}>{title}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign={"center"} mt={1}>
          <Typography variant={"h4"}>{text}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign={"center"} mt={4}>
          <Button
            bgcolor={colors.green.regular}
            shadowcolor={colors.green.darker}
            text={action.text}
            onClick={() => action.onClick()}
            className="button"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

FinalStep.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
};

export default FinalStep;
