import { TABS } from "../../../../../js/constants/form-types";
import Button from "../../../../ui/button/Button";
import ProductsSelector from "../components/ProductsSelector";

// DEPRECATED, use formEditProduct.config.js instead
export default function formProductAdd(
  currentLang,
  categories,
  customAttributes,
  metaAttributes,
  imageAttributes,
  categoriesSelected,
  allGroups,
  errors,
  seeErrors,
  handleMediaPicker,
  variantGroup,
  handleToggleDrawer,
  variantsValidated,
  isVariant,
  rightButtonCallback,
  allowedTypes,
  hasPrice
) {
  let typeInput = "";

  let attributeSetup = (attribute) => {
    let isEmail = false;
    const defaultLang = attribute.node.translation.translationDatas.edges[0];

    const langSelected = attribute.node.translation.translationDatas.edges.find(
      (lang) => lang.node.locale.code === currentLang
    );

    switch (attribute.node.attributeType.input) {
      case "textarea":
        typeInput = "textarea";
        break;
      case "number":
      case "decimal":
        typeInput = "decimal";
        break;
      case "text":
      case "link":
        typeInput = "text";
        break;
      case "mail":
        typeInput = "text";
        isEmail = true;
        break;
      case "select":
        typeInput = "select";
        break;
      case "image":
        typeInput = "mediaPicker";
        break;
      case "file":
        typeInput = "file";
        break;
      case "date":
        typeInput = "date";
        break;
      default:
        typeInput = null;
    }
    return {
      type: typeInput,
      label: langSelected?.node.value ?? defaultLang.node.value,
      translated: true,
      handleMediaPicker:
        typeInput === "mediaPicker" || typeInput === "file"
          ? handleMediaPicker
          : null,
      allowedTypes: typeInput === "file" ? allowedTypes : null,
      helper: {
        text: "",
        link: false,
      },
      isSystem: false,
      currentLang,
      required:
        typeInput === "mediaPicker" && isVariant
          ? false
          : attribute.node.isRequired,
      stateName: attribute.node.identifier,
      email: isEmail,
      valueWithFlags: true,
      value: attribute.node.attributeOptions.edges.map((values) => {
        const langSelectedValuesDefault =
          values.node.translation.translationDatas.edges[0];

        const langSelectedValues =
          values.node.translation.translationDatas.edges.find(
            (lang) => lang.node.locale.code === currentLang
          );

        return {
          value: values.node.id,
          label:
            langSelectedValues?.node.value ??
            langSelectedValuesDefault?.node?.value,
        };
      }),
    };
  };

  let generalInputs = [
    {
      type: "select",
      isContainerized: true,
      label: `Groupe d'attributs`,
      helper: {
        text:
          variantsValidated && isVariant
            ? `Une variante produit est déjà utlisée vous ne pouvez changer de groupe d'attributs`
            : `Indiquez le groupe d'attributs`,
        link: false,
      },
      translated: false,
      required: true,
      disabled: variantsValidated && isVariant ? true : false,
      stateName: "groupAttribut",
      value: allGroups.map((group) => {
        const langSelectedValuesDefault =
          group.node.translation.translationDatas.edges[0];

        const langSelectedValues =
          group.node.translation.translationDatas.edges.find(
            (lang) => lang.node.locale.code === currentLang
          );

        return {
          value: group.node.id,
          label:
            langSelectedValues?.node.value ??
            langSelectedValuesDefault?.node.value ??
            group.node.identifier,
        };
      }),
    },
    {
      type: "radio",
      direction: "row",
      label: "Groupe d'attributs configurable",
      helper: {
        text: "Indiquez si le groupe d'attributs est configurable",
        link: false,
      },
      required: true,
      stateName: "isVariant",
      value: [
        {
          value: true,
          label: "Oui",
        },
        {
          value: false,
          label: "Non",
        },
      ],
    },
    {
      type: "text",
      label: "Code article",
      helper: {
        text: "Code article du produit",
        link: false,
      },
      required: true,
      stateName: "sku",
      disabled: false,
    },
    {
      type: "text",
      label: "EAN",
      helper: {
        text: "Code EAN",
        link: false,
      },
      rightButton: {
        textTooltip: "Cliquer pour utiliser l'autocomplétion",
        textTooltipDisabled: "Entrer un code EAN",
        icon: "icon-search",
        callback: rightButtonCallback,
      },
      translated: true,
      required: false,
      stateName: "product_ean",
      disabled: false,
      valueWithFlags: true,
    },
  ];

  if (hasPrice) {
    generalInputs.push({
      type: "decimal",
      label: "Prix",
      helper: {
        text: "Prix du produit",
        link: false,
      },
      translated: true,
      required: false,
      stateName: "product_price",
      disabled: false,
      valueWithFlags: true,
    });
  }

  generalInputs.push(
    {
      type: "text",
      label: "Titre du produit",
      helper: {
        text: "Indiquez le nom du produit ici",
        link: false,
      },
      translated: true,
      required: true,
      stateName: "product_name",
      valueWithFlags: true,
      checkSpelling: false,
      translation: true,
    },
    {
      type: "selectTree",
      label: "Catégories",
      translated: false,
      helper: {
        text: "Modifier les catégories du produit",
        link: false,
      },
      required: true,
      stateName: "categories",
      multiselect: true,
      data: (() => {
        let data = categories.filter(
          (e) => e.parent === null && e.catalog === null
        );
        let populateChildren = (cats, parent) => {
          let catSelected = categoriesSelected.find((e) => e.id === parent.id);

          let allNames = parent.categoryDatas.edges.filter(
            (e) => e.node.attribute.identifier === "category_name"
          );
          const defaultValue = allNames.find(
            (e) => e.node.locale.code === currentLang
          );
          const value = allNames.find(
            (e) => e.node.locale.code === currentLang
          );
          let finalValue =
            value?.node.value ?? defaultValue?.node.value ?? parent.libelle;

          parent.value = parent.id;
          parent.label = finalValue;
          parent.checked = catSelected ? true : false;
          parent.expanded = parent.id === "/api/categories/1" ? true : false;
          parent.children = cats.filter(
            (e) => e.parent !== null && e.parent.id === parent.id
          );

          for (let child of parent.children) populateChildren(cats, child);
        };

        for (let parent of data) populateChildren(categories, parent);

        return data;
      })(),
    },
    {
      type: "textarea",
      label: "Description",
      helper: {
        text: "Décrivez votre produit",
        link: false,
      },
      translated: true,
      required: false,
      stateName: "product_description",
      valueWithFlags: true,
      richText: false,
      checkSpelling: false,
      translation: true,
    },
    {
      type: "radio",
      direction: "row",
      label: "Actif",
      helper: {
        text: "Indiquez l'état",
        link: false,
      },
      required: true,
      stateName: "status",
      value: [
        {
          value: true,
          label: "Oui",
        },
        {
          value: false,
          label: "Non",
        },
      ],
    }
  );

  let imageInputs = imageAttributes.map(attributeSetup);
  let metaInputs = metaAttributes.map(attributeSetup);
  let customInputs = customAttributes
    .filter((e) => e.node.attributeType.input !== "image" && e.node.status)
    .map(attributeSetup);

  let variantProduct = [
    {
      type: "variantAdd",
      required: isVariant ? true : false,
      stateName: "variantsValidated",
      disabled: false,
      handleClick: handleToggleDrawer,
      hasPrice: hasPrice,
    },
  ];

  let hasErrors = (inputs) => {
    for (let input of inputs) {
      if (errors[input.stateName]) return true;
    }

    return false;
  };

  let children = [
    {
      labelName: "Général",
      error: hasErrors(generalInputs),
      optionsInputs: generalInputs,
    },
    {
      labelName: "Médias",
      isOptionnal: false,
      error: hasErrors(imageInputs),
      optionsInputs: imageInputs,
    },
  ];

  if (metaInputs.length) {
    children.push({
      labelName: "Métas",
      isOptionnal: false,
      error: hasErrors(metaInputs),
      optionsInputs: metaInputs,
    });
  }
  if (customInputs.length) {
    children.push({
      labelName: "Attributs",
      isOptionnal: false,
      error: hasErrors(customInputs),
      optionsInputs: customInputs,
    });
  }

  children.push({
    labelName: "Variantes",
    isOptionnal: false,
    disabled: !variantGroup,
    error: hasErrors(variantProduct),
    optionsInputs: variantProduct,
  });

  children.push({
    labelName: "Suggestions",
    isOptionnal: true,
    component: ProductsSelector,
  });

  var obj = {
    titleForm: "Ajouter un produit",
    subTitleForm:
      "Veuillez compléter les champs ci-dessous pour créer votre produit",
    langSelect: true,
    drawerType: "drawer",
    collectionSelect: false,
    noForm: true,
    component: Button,
    formConfig: {
      type: TABS,
      seeErrors,
      children,
    },
  };

  return obj;
}
