import React from 'react';
import { makeStyles } from '@mui/styles';
import '../../../shareable/components/assets/game/scss/style.scss';
import '../../../shareable/components/assets/game/scss/style-estac.scss';
import '../../../shareable/components/assets/game/scss/style-sony.scss';
import '../../../shareable/components/assets/game/scss/style-ubisoft.scss';
import '../../../shareable/components/assets/game/scss/style-lego-mario.scss';
import '../../../shareable/components/assets/game/scss/style-lego-mario2022.scss';
import '../../../shareable/components/assets/game/scss/style-lego-toulouse.scss';
import '../../../shareable/components/assets/game/scss/style-lego-easter.scss';
import '../../../shareable/components/assets/game/scss/style-lego-90.scss';
import '../../../shareable/components/assets/game/scss/style-lego-city-friends.scss';
import '../../../shareable/components/assets/game/scss/style-lego-wdn-2022.scss';
import styled from 'styled-components';

const useStyles = makeStyles({
    root: {
        width: '100%',
    }
});

const Div = styled.div`
    section, div.typeSection{
        background-size: ${props => props.widthWindow < 1600 && !props.noPanel ? 'cover' : null};
    }
`;

export default ({ config, params, noPanel, mapper, asset, page }) => {
    const classes = useStyles();
    const widthWindow = window.innerWidth;
    return (
        <Div 
            widthWindow={widthWindow * 75 / 100} 
            className={`${asset.identifier} ${classes.root} ${page}`} 
            noPanel={noPanel} 
            id="builder-template-scope-game" 
            style={{
                backgroundImage: params?.bg?.value ? (typeof(params.bg?.value) === 'string' ? `url(${params.bg?.value})` : `url(${process.env.REACT_APP_MEDIAS}/${params.bg.value.filePath})` ) : null,
                maxWidth: params?.maxWidth?.value ? `${params?.maxWidth?.value}px` : '100%',
                backgroundSize: 'cover'
            }}
        >
                { config.elements.map(mapper) }
        </Div>
    );
};