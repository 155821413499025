import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid } from '@mui/material';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { withApollo } from '@apollo/client/react/hoc';
import { GET_PRODUCTS_BY_SKU } from '../../../../queries/products';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import CircularProgress from '@mui/material/CircularProgress';

class CardIframe extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            sku: this.props.match.params.sku,
            lang: this.props.match.params.lang,
            id: null,
            name: null,
            ean: null,
            image: null,
            ready: false,
        };
    }

    componentDidMount(){
        this.props.client.query({
            query: GET_PRODUCTS_BY_SKU,
            variables: {
                'sku': this.state.sku,
            }
        }).then(result =>{
            let product = result.data.products.edges[0].node;
            let getName = getTraductionAttributs('product_name', product.productDatas.edges, this.state.lang);
            let getEan = getTraductionAttributs('product_ean', product.productDatas.edges, this.state.lang);
            let getImage = getTraductionAttributs('product_image', product.productDatas.edges, this.state.lang, 'image');
            this.setState({
                id: product.id??null,
                name: getName??null,
                ean: getEan??null,
                image: getImage??null,
                ready: true,
            })
        }).catch((err)=>{
            console.log(err)
        });
    }
    
    render() {
        return (
            this.state.ready ?
                <Grid container alignItems="center" style={{height: "100vh", padding: 20, cursor: 'pointer'}}>
                    <Grid item>
                        {
                            this.state.image ?
                                <img src={`${process.env.REACT_APP_MEDIAS}/${this.state.image.filePath}`}  style={{maxHeight: 60, maxWidth: 60}}/>
                            : null
                        }
                    </Grid>
                    <Grid item style={{marginLeft: 24, flex: 1}}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="body2" colortext={colors.black.regular}>
                                    {this.state.name} 
                                </Typography>
                                <Typography variant="body2">
                                    {this.state.ean} 
                                </Typography>
                                <Typography variant="body2">
                                    Code article: {this.state.sku}
                                </Typography>
                            </Grid>
                            <Grid item>
                                Voir
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
            : <Grid container alignItems="center" justifyContent="center" style={{height: "100vh", padding: 20, textAlign: 'center'}}><CircularProgress color="inherit" size={'2rem'} style={{padding: 0}} /></Grid>
        );
    }
    
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withApollo(withRouter(connect(mapStateToProps, null)(CardIframe)));
