import { gql } from '@apollo/client';

//----- Récupération des types des pages
export const GET_PAGE_TYPES = gql`
{
  assetMinisitePageTypes{
    edges{
      node{
        id
	     	identifier
        image
      }
    }
  }
}
`;

//----- Récupération des pages du minisite
/*
 Exemple de variables
  {
    "id":"/api/assets/1"
  }
*/
export const GET_MINISITE_PAGES = gql`
query assetMinisitePages($asset: String){
  assetMinisitePages(asset:$asset){
    edges{
      node{
        id
        libelle
        status
        content
        createdAt
        updatedAt
        assetMinisitePageType{
          id
          identifier
          image
        }
        asset{
          id
          identifier
        }
      }
    }
  }
}
`;


//----- Ajouter une page d'un minisite 
/*
 Exemple de variables
  {
    "identifier": "test",
    "libelle": "Test",
    "status": true,
    "content": "Content",
    "asset": "api/assets/3",
    "assetMinisitePageType":"api/asset-minisite-page-types/1",
    "createdAt": "2020-06-18"
  }
*/


export const ADD_MINISITE_PAGE = gql`
mutation AddAssetMinisitePage($identifier: String!, $libelle: String!, $status: Boolean!, $content: String, $createdAt: String!, $updatedAt: String, $asset: String!, $assetMinisitePageType: String!){
	createAssetMinisitePage(input: {identifier:$identifier, libelle: $libelle, status: $status, content: $content, createdAt: $createdAt, updatedAt: $updatedAt, asset: $asset, assetMinisitePageType: $assetMinisitePageType}){
  	assetMinisitePage{
      id
      identifier
    }
  }
}`;


//----- Editer une page d'un minisite 
/*
 Exemple de variables
  {
    "id": "api/asset-minisite-pages/1",
    "content": "Content edited"
  }
*/


export const UPDATE_MINISITE_PAGE = gql`
mutation UpdateAssetMinisitePage($id: ID!, $identifier: String, $libelle: String, $status: String, $content: String, $createdAt: String, $updatedAt: String, $asset: String, $assetMinisitePageType: String){
	updateAssetMinisitePage(input: {id: $id, identifier:$identifier, libelle: $libelle, status: $status, content: $content, createdAt: $createdAt, updatedAt: $updatedAt, asset: $asset, assetMinisitePageType: $assetMinisitePageType}){
  	assetMinisitePage{
      id
      identifier
    }
  }
}`;

//----- Supprimer une page d'un minisite
/*
 Exemple de variables
  {
    "id": "api/asset-minisite-pages/1"
  }
*/
export const DELETE_MINISITE_PAGE = gql`
mutation DeleteAssetMinisitePage($id: ID!){
	deleteAssetMinisitePage(input: {id:$id}){
    assetMinisitePage{
      id
    }
  }  
}`;