import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Icon from "@ui/icons/Icon";
import styled from "styled-components";
import Tooltip from "@ui/tooltip/BootstrapTooltip";
import PropTypes from "prop-types";
import colors from "@config/theme/colors.js";
import OurMenu from "@ui/menu/Menu";

const ActionIconsContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.3rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  transition: all 0.1s ease-in-out;
  visibility: ${(props) => (props.hover ? "visible" : "hidden")};
  background-color: ${(props) =>
    props.hover ? "rgba(255,255,255,0.7)" : "transparent"};
`;

const CustomCard = styled(Card)`
  position: relative;
  border: ${(props) =>
    props.hover
      ? `solid 1px ${colors.blue.regular}`
      : `solid 1px ${colors.grey.border}`};
`;

const CardImage = ({ path, title, actions, sx, ...props }) => {
  const [hover, setHover] = useState(false);

  return (
    <CustomCard
      sx={{ maxWidth: 345, position: "relative", ...sx }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      hover={hover ? 1 : 0}
    >
      <CardMedia component="img" height="300" image={path} {...props} />
      <ActionIconsContainer hover={hover ? 1 : 0}>
        {actions.length <= 2 &&
          actions.map((action, index) => (
            <Tooltip
              title={action.label}
              placement="right"
              key={`action-${index}`}
            >
              <IconButton onClick={() => action.onClick()}>
                <Icon
                  icon={action.icon?.name}
                  color={action.icon?.color ?? "black"}
                />
              </IconButton>
            </Tooltip>
          ))}

        {/**
         * TODO : Mieux gérer le cas où il y a plus de 2 actions
         */}
        {actions.length > 2 && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <OurMenu
              menuOptions={actions.map((action) => ({
                label: action.label,
                icon: (
                  <Icon
                    icon={action.icon.name}
                    color={action.icon.color ?? "black"}
                    style={{ fill: colors.red.regular }}
                  />
                ),
                modal: action.label == "Supprimer" ? true : false,
                modalProps: {
                  identifier: "delete",
                  type: "delete",
                  title: "Êtes-vous sûr de vouloir supprimer cet élement ?",
                  subtitle: "Cette action est irréversible",
                  actions: {
                    primaryAction: (id) => action.onClick(id),
                  },
                },
              }))}
            />
          </Box>
        )}
      </ActionIconsContainer>

      {title && (
        <CardContent>
          <Typography gutterBottom fontWeight="bold" color="black">
            {title}
          </Typography>
        </CardContent>
      )}
    </CustomCard>
  );
};

CardImage.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.shape({
        name: PropTypes.string,
        color: PropTypes.string,
      }),
      onClick: PropTypes.func.isRequired,
    })
  ),
  sx: PropTypes.object,
};

export default CardImage;
