import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import SelectInput from "@ui/form/inputs/SelectInput";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";
import colors from "@config/theme/colors.js";
import Tooltip from "@ui/tooltip";
import PropTypes from "prop-types";

const Separator = styled.div`
  height: 1px;
  border: 0.5px solid ${colors.black.regular};
  transform: rotate(90deg);
  width: 2rem;
`;

const RowMatching = ({
  label,
  value,
  options,
  sx,
  selectMultiple = true,
  onChange,
  onDelete,
}) => {
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    const labels = options
      .filter((option) => value?.includes(option.identifier))
      .map((option) => option.label);
    if (labels) setSelectedLabels(labels);
  }, [value]);

  return (
    <Grid
      container
      sx={{
        alignItems: "center",
        ...sx,
      }}
      columnSpacing={1}
    >
      <Grid item xs={12} md={4}>
        <Typography fontWeight="bold">{label}</Typography>
      </Grid>

      <Separator />

      <Grid item xs={12} md={6}>
        <Tooltip
          title={selectedLabels?.map((selectedLabel, index) => (
            <div key={index}> {selectedLabel} </div>
          ))}
          disableHoverListener={
            tooltipOpen && selectedLabels?.length > 0 ? false : true
          }
          disableFocusListener={true}
          disableTouchListener={true}
          disableTriggerFocus={true}
          open={tooltipOpen && selectedLabels?.length > 0 ? true : false}
          aria-label="add-attributes"
        >
          <div
            onMouseLeave={() => setTooltipOpen(false)}
            onMouseEnter={() => setTooltipOpen(true)}
            onBlur={() => setTooltipOpen(false)}
          >
            <SelectInput
              options={options.map((option) => ({
                label: option.label,
                value: option.identifier,
              }))}
              variant="outlined"
              multiple={selectMultiple}
              value={value}
              onOpen={() => setTooltipOpen(false)}
              onClose={() => setTooltipOpen(true)}
              onChange={(val) => onChange(val)}
              sx={{ width: "100%", backgroundColor: "black" }}
            />
          </div>
        </Tooltip>
      </Grid>
      {onDelete && (
        <Grid item xs={12} md={1}>
          <Tooltip title="Supprimer">
            <IconButton onClick={() => onDelete()}>
              <CancelOutlinedIcon color="#000000" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

RowMatching.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      identifier: PropTypes.string,
    })
  ),
  sx: PropTypes.object,
  selectMultiple: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default RowMatching;
