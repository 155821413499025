import colors from '../theme/colors';
import { createTheme } from '@mui/material/styles';

export const THEME_SPREAD_LIGHT = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor:colors.white,
            borderRadius:0,
            color:colors.black.regular
          },
        },
        defaultProps:{
          elevation: 0
        }
      },
      MuiOutlinedInput:{
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: 'none',
              // WebkitTextFillColor: '#fff',
            },
          },
        },
      },
    },
    typography: {
      fontFamily: "soleil, sans-serif",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      h1: {
        fontSize: 30,
        fontWeight: "normal",
        lineHeight: "39px",
        color: colors.grey.lighter.hue600,
      },
      h2: {
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: "25px",
        color: colors.black.regular,
      },
      h3: {
        fontSize: 18,
        fontWeight: "bold",
        lineHeight: "23px",
        color: colors.black.regular,
      },
      h4: {
        fontSize: 16,
        fontWeight: "normal",
        lineHeight: "26px",
        color: colors.black.regular,
      },
      h5: {
        fontSize: 12,
        fontWeight: "normal",
        lineHeight: "15px",
        color: colors.blue.darker.hue300,
      },
      h6: {
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: "15px",
        color: colors.black.regular,
      },
      body1: {
        fontSize: 14,
        fontWeight: "normal",
        lineHeight: "19px",
        color: colors.black.regular,
      },
      body2: {
        fontSize: 13,
        fontWeight: "normal",
        lineHeight: "15px",
        color: colors.black.regular,
      },
      p:{
        color: colors.white,
      }
    },
    palette: {
      mode:'light',
      background:{
        default:'#FAFAFA',
        paper:"#FFF"
      },
      primary: {
        main: colors.blue.hue500,
      },
      secondary: {
        main: colors.grey.regular,
      },
      terciary: {
        main: colors.blue.darker.hue300,
      },
      ...colors,
    },
    // breakpoints: {
    //   values: {
    //     xs: 0,
    //     sm: 600,
    //     md: 900,
    //     lg: 1200,
    //     xl: 1536,
    //   },
    // },
  });

  export const THEME_SPREAD_DARK = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor:colors.black,
            borderRadius:0,
            color:colors.white
          },
        },
        defaultProps:{
          elevation: 0
        }
      },
      MuiFilledInput:{
        styleOverrides: {
          input: {
            boxShadow :"none !important",
            backgroundColor:"transparent",
            '&:-webkit-autofill': {
              WebkitBoxShadow: 'none !important',
              WebkitTextFillColor: colors.black.regular,
            },
          }
        },
      },
      MuiOutlinedInput:{
        styleOverrides: {
          input: {
            boxShadow: "none",
            '&:-webkit-autofill': {
              WebkitBoxShadow: 'none !important',
              WebkitTextFillColor: colors.black.regular,
            },
            '&:-internal-autofill-selected':{
              backgroundColor:"none"
            }
          }
        },
      }
    },
    typography: {
      fontFamily: "soleil, sans-serif",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      color:"red",
      h1: {
        fontSize: 30,
        fontWeight: "normal",
        lineHeight: "39px",
        color: colors.white,
      },
      h2: {
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: "25px",
        color: colors.white,
      },
      h3: {
        fontSize: 18,
        fontWeight: "bold",
        lineHeight: "23px",
        color: colors.white,
      },
      h4: {
        fontSize: 16,
        fontWeight: "normal",
        lineHeight: "26px",
        color: colors.white,
      },
      h5: {
        fontSize: 12,
        fontWeight: "normal",
        lineHeight: "15px",
        color: colors.white,
      },
      body1: {
        fontSize: 14,
        fontWeight: "normal",
        lineHeight: "19px",
        color: colors.white,
      },
      body2: {
        fontSize: 13,
        fontWeight: "normal",
        lineHeight: "15px",
        color: colors.white,
      },
    },
    palette: {
      mode:'dark',
      background:{
        default:'rgba(0, 0, 0, 0.85)',
        paper:"rgb(18, 18, 18)"
      },
      primary: {
        main: colors.black.regular,
        ...colors.blue,
      },
      secondary: {
        main: colors.grey.regular,
      },
      terciary: {
        main: colors.blue.darker.hue300,
      },
      ...colors,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });