import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from '@apollo/client/react/hoc';

import mapDispatchToProps from '../../../../js/helpers/mapDispatchToProps';
import mapStateToProps from '../../../../js/helpers/mapStateToProps';

import { Box } from '@mui/material';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import Empty from '../../../../assets/pictos/empty-picto/empty_products.png';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import colors from '../../../../config/theme/colors';

import { eventService } from '../../../../js/services/event.service';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';

import { GET_ATTR_GROUPE_WITH_ATTRIBUTES_LIGHT } from '../../../../queries/attributes';
import {
    CREATE_COMPLETENESSGROUP,
    UPDATE_COMPLETENESSGROUP,
    DELETE_COMPLETENESSGROUP,
} from '../../../../queries/completeness_group';

import formCompletenessGroup from './config/formCompletenessGroup.config';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import Listing from '../../../layouts/Listing/Listing';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { filtersOptions, listMappers, listSettings, perPageOptions, viewOptions } from './config/productsCompletude.config'

const PageWrapper = styled(Box)`
    width: 100%;   
    /* width: ${props => props.openForm ? `calc(100% - ((50% - ${props.drawerWidth}px / 2) + (${props.drawerWidth}px / 2)))` : "100%"};    */
    transition: all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms; 
    padding-bottom: 32;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
`;

const ProductsCompletudeNew = (props) => {
    const [state, setState] = useState({
        currentLang: props.locales[0].node.code,
        openForm: false,
        openDialog: false,
        errors: {},
        editForm: 'add',
        libelle: null,
        attributesSelected: [],
        ready: false,
        listCompletenessGroup: [],
    })
    const [scrollListing, setScrollListing] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [reloadListing, setReloadListing] = useState(false);

    useEffect(() => {
        checkRouting(props);
        initAttributs();
    }, [])

    useEffect(() => {
        if (!state.openForm) {
            handleReinitState();
        }
    }, [state.openForm])

    const handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;

        if (stateName === 'groupsWithAllSelectedAttr') {
            const { listingId, checkedValues } = evt;
            const copyOfGroupsWithSelectedAttr = structuredClone(state.groupsWithAllSelectedAttr);

            const group = copyOfGroupsWithSelectedAttr.find(group => group.node.id === listingId);

            if (group) {
                group.selectedAttributes = checkedValues
            }

            setState(prev => ({
                ...prev,
                [stateName]: copyOfGroupsWithSelectedAttr
            }));
        } else {
            setState(prev => ({
                ...prev,
                [stateName]: value,
            }));
        }
    };

    const handleFormError = (stateName, error) => {
        let errors = { ...state.errors };

        errors[stateName] = error;

        setState(prev => ({ ...prev, errors }));
    };

    const handleToggleDrawer = () => {
        setState(prev => ({
            ...prev,
            openForm: !state.openForm ?? false
        }));
    };

    const handleReinitState = () => {
        setState(prev => ({ ...prev, libelle: null, attributesSelected: [], editForm: 'add', selectedGroup: null }));
    }

    const handlerMutation = () => {
        // TODO: Create/update/delete selected attributes. It is done in a recursive way backend side so it breaks for network errors (+ 50 requests at same time)
        if (hasErrors()) {
            props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            return eventService.fire();
        }
        props.startLoading();
        let query = state.editForm === 'add' ? CREATE_COMPLETENESSGROUP : UPDATE_COMPLETENESSGROUP;
        let variables = { libelle: state.libelle, attributes: state.attributesSelected };
        if (state.editForm === 'edit') {
            variables.id = state.selectedGroup;
        }
        props.client.query({
            query,
            variables,
            fetchPolicy: "no-cache",
        }).then(result => {
            handleToggleDrawer();
            handleReinitState();
            props.stopLoading();
        }).catch((err) => {
            console.log(err)
        });
    };

    const deleteMutation = (id) => {
        props.startLoading();
        props.client.mutate({
            mutation: DELETE_COMPLETENESSGROUP,
            variables: { id: id }
        }).then(result => {
            props.stopLoading();
            props.snack(ALERT_SUCCESS, 'Groupe de complétude supprimé avec succès');
        }).catch(error => {
            props.stopLoading();
            props.snack(ALERT_ERROR, `Impossible de supprimer le groupe`);
        });
    };

    const getGroupSelectedAttributes = (group, attr) => {
        return attr?.filter((attribute) => {
            return attribute.attributeGroup.edges.findIndex((attrGroup) => {
                return attrGroup.node.id === group.node.id
            })
        })
    }

    const groupWithAttributes = (gr, attr) => gr?.map((group) => {
        return ({
            ...group,
            selectedAttributes: getGroupSelectedAttributes(group, attr)?.map((attribute) => ({
                id: attribute.id,
                name: attribute.identifier,
                groupId: group.node.id,
            }))
        })
    })

    const initAttributs = () => {
        props.client.query({
            query: GET_ATTR_GROUPE_WITH_ATTRIBUTES_LIGHT,
            variables: {
                "boolIsForContent": false,
                // "isSystem": false
            },
            fetchPolicy: 'no-cache'
        }).then(result => {
            setState(prev => {
                const groupsWithAllSelectedAttr = result.data.attributeGroups.edges.filter(e => (!e.node.isSystem) || e.node.identifier === 'default');
                

                return { ...prev, groupsWithAllSelectedAttr: groupsWithAllSelectedAttr, ready: true }
            });
        }).catch((err) => {
            console.log(err)
        });
    }

    const hasErrors = () => {
        if (state.errors) {
            for (let error in state.errors) {
                if (state.errors[error])
                    return true;
            }
        }
        return false;
    };

    const editGroup = (completenessGroup) => {
        const attributesSelected = completenessGroup.node.completenessGroupAttributes.edges.map(gr => gr.node.attribute)

        setState(prev => ({
            ...prev,
            selectedGroup: completenessGroup.node.id,
            libelle: completenessGroup.node.libelle,
            attributesSelected,
            editForm: 'edit',
        }));

        setState(prev => {
            const groupsWithAllSelectedAttrCopy = structuredClone(prev.groupsWithAllSelectedAttr);
            const groupsWithSelectedAttr = groupWithAttributes(groupsWithAllSelectedAttrCopy, prev.attributesSelected);

            return {
                ...prev,
                groupsWithAllSelectedAttr: groupsWithSelectedAttr,
            }
        })

        handleToggleDrawer();
    };

    return (
        <PageWrapper openForm={state.openForm} drawerWidth={props.drawerWidth}>
            <TopPanel
                icomoon="picto-produit"
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t("products.completeness.title")}
                subtitle={props.t("products.completeness.subtitle")}
                handlerAdd={handleToggleDrawer}
                textAdd={props.t("products.completeness.addgroup")}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                windowWidth={props.windowWidth}
                buttonAvailable={state.openForm || !state.ready ? false : true}
                openForm={state.openForm}
                hasBorder={true}
            />
            <Listing
                padding={16}
                scroll={state.scrollListing}
                label='completenessGroups'
                settings={listSettings}
                perPageOptions={perPageOptions}
                mappers={listMappers}
                pagination={true}
                enablePagination={true}
                enableChangeView={false}
                enableChangeSort={false}
                enableSelectionItems={false}
                enableFilters={true}
                identifier='completenessGroups'
                viewsOptions={viewOptions}
                locales={props.locales}
                setDataCount={setDataCount}
                reload={reloadListing}
                setReload={setReloadListing}
                filtersOptions={filtersOptions}
                listingCallback={(props) => setState(prev => ({
                    ...prev,
                    listCompletenessGroup: props
                }))}
                cardProps={{
                    button: {
                        text: 'Modifier',
                        type: 'edit',
                        importance: 'secondary',
                        isDisabled: state.openForm || !state.ready,
                        action: (group) => editGroup(group),
                    },
                    dottedMenu: {
                        options: [
                            {
                                label: 'Supprimer',
                                color: colors.red.regular,
                                icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                                modal: true,
                                modalProps: {
                                    type: "delete",
                                    title: 'Êtes-vous sûr de vouloir supprimer ce groupe de complétude ?',
                                    subtitle: "Cette action est irréversible",
                                    actions: {
                                        primaryAction: (id) => deleteMutation(id),
                                    }
                                },
                            }
                        ]
                    }
                }}
            />
            {state.openForm ?
                <LayoutBuilder
                    isSublayout={false}
                    opened={state.openForm}
                    forClose={handleToggleDrawer}
                    handlerMutation={handlerMutation}
                    allState={state}
                    dataLayout={formCompletenessGroup(state.groupsWithAllSelectedAttr, state.editForm, state.currentLang, props.t)}
                    icomoon={'picto-produit'}
                    stateCallback={handleInputChange}
                    errorCallback={handleFormError}
                    checkError={() => { }}
                    validateButton={true}
                    currentLang={state.currentLang}
                    // handleLang={handleLang}
                    stepperButtonAction={[
                        () => {
                            if (hasErrors()) {
                                props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                setState(prev => ({ ...prev, seeErrors: true }));
                                eventService.fire();
                                return false;
                            }
                            return true;
                        }
                    ]}
                />
                : null
            }
        </PageWrapper>
    )
}

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsCompletudeNew))));
