import React, { useState, useEffect } from "react";
import OurStepper from "@ui/stepper/OurStepper";
import StepPanel from "@ui/stepper/components/StepPanel";
import TopPanel from "@layouts/TopPanel/TopPanel";
import InputBuilder from "@ui/form/InputBuilder";
import { MEDIAPICKER_TYPES } from "@constants/mediapickerTypes.enum.js";
import { IMAGES } from "@constants/medias-types.js";
import TextInput from "@ui/form/inputs/TextInput";
import TextareaInput from "@ui/form/inputs/TextareaInput";
import colors from "@config/theme/colors.js";
import RadioGroupInput from "@ui/form/inputs/RadioGroupInput";
import { Box } from "@mui/system";
import TextAddElement from "@ui/typography/TextAddElement.jsx";
import { Grid, IconButton } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Tooltip from "@ui/tooltip/BootstrapTooltip";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import RowMatching from "@ui/form/components/RowMatching";
import FinalStep from "@ui/stepper/components/FinalStep";
import { client } from "@/index.js";
import {
  ADD_BRAND,
  ADD_BRAND_SOURCE_SCRAP,
  ADD_BRAND_SOURCE_MAPPING,
  ADD_BRAND_SOURCE_ALERT,
  UPDATE_BRAND_SOURCE_SCRAP,
  UPDATE_BRAND_SOURCE_MAPPING,
  UPDATE_BRAND,
  UPDATE_BRAND_SOURCE_ALERT,
  DELETE_BRAND_SOURCE_SCRAP,
  DELETE_BRAND_SOURCE_MAPPING,
} from "@queries/brands.js";
import { ALERT_ERROR, ALERT_SUCCESS } from "@constants/alert-types";
import { generateTempoId } from "@utils/string.utils.js";
import { Container, Separator, FormContainer } from "./style/BrandForm.styled.js";

const BrandForm = ({ brand, onSuccess, onFail, ...props }) => {
  const [form, setForm] = useState({
    image: brand ? brand.media : null,
    name: brand ? brand.libelle : "",
    description: brand ? brand.description : "",
    website: brand ? brand.link : "",
    active: brand ? brand.status : false,
    referenceSites:
      brand?.productSourceScraps?.edges?.length > 0
        ? brand.productSourceScraps.edges.map((scrap) => ({
            id: scrap.node.id,
            link: scrap.node.link,
          }))
        : [{ id: null, tempoId: "new_scrap_1", link: "" }],
    positifGap: brand?.productSourceAlerts?.edges[0]?.node.max ?? "",
    negatifGap: brand?.productSourceAlerts?.edges[0]?.node.min ?? "",
    alertActive: brand?.activeAlert ? "true" : "false",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [mapping, setMapping] = useState([]);

  useEffect(() => {
    initMapping();
  }, []);

  const initMapping = () => {
    const mapping = props.attributes?.product?.attributes?.edges?.map(
      (attribute) => {
        const value =
          brand?.productSourceMappings.edges?.find(
            (map) => map.node.attribute.id === attribute.node.id
          )?.node.productSourceScrap?.id ?? "none";

        return {
          id: attribute.node.id,
          identifier: attribute.node.identifier,
          label:
            attribute.node.translation?.translationDatas?.edges[0]?.node.value,
          value: value,
          exist: brand?.productSourceMappings.edges?.find(
            (map) => map.node.attribute.id === attribute.node.id
          )
            ? true
            : false,
        };
      }
    );
    setMapping(mapping);
  };

  const stepOneCompleted = () => {
    return form.name !== "" && form.active !== undefined && form.website !== "";
  };

  const stepTwoCompleted = () => {
    return (
      form.alertActive !== undefined &&
      form.referenceSites.every((site) => site.link !== "")
    );
  };

  const stepThreeCompleted = () => {
    return true;
  };

  const handleChangeStep = async (newStep) => {
    setActiveStep(newStep);
  };

  const getOptionsMapping = () => {
    let options = [
      {
        identifier: "none",
        label: "Aucun",
      },
    ];
    options = options.concat(
      form.referenceSites.map((site) => ({
        identifier: site.id ?? site.tempoId,
        label: site.link,
      }))
    );
    return options;
  };

  const handleSubmitBrand = async () => {
    return new Promise(async (resolve, reject) => {
      let variables = {
        libelle: form.name,
        description: form.description,
        media: form.image ? form.image.id : null,
        status: form.active == "true" ? true : false,
        isDisplay: true,
        isBrand: true,
        activeAlert: form.alertActive == "true" ? true : false,
        link: form.website,
      };

      if (brand) {
        variables = {
          ...variables,
          id: brand.id,
          users: brand.users?.edges?.map((user) => user.node.id),
        };
      }

      try {
        const res = await client.mutate({
          mutation: brand ? UPDATE_BRAND : ADD_BRAND,
          variables,
        });
        const data = brand
          ? res.data.updateProductSource.productSource
          : res.data.createProductSource.productSource;
        resolve(data);
      } catch (error) {
        props.snack(
          ALERT_ERROR,
          "Une erreur est survenu lors de la création de la marque"
        );
        resolve(false);
      }
    });
  };

  const handleSubmitAlert = async (currentBrand) => {
    return new Promise(async (resolve, reject) => {
      let variables = {
        productSource: currentBrand.id,
        min: form.negatifGap !== "" ? form.negatifGap : 0,
        max: form.positifGap !== "" ? form.positifGap : 0,
      };

      if (brand) {
        const id = brand.productSourceAlerts?.edges[0]?.node.id;

        if (id) {
          variables = {
            ...variables,
            id: brand.productSourceAlert?.edges[0].node.id,
          };
        }
      }

      try {
        const res = await client.mutate({
          mutation: variables.id
            ? UPDATE_BRAND_SOURCE_ALERT
            : ADD_BRAND_SOURCE_ALERT,
          variables,
        });
        const data = variables.id
          ? res.data.updateProductSourceAlert.productSourceAlert
          : res.data.createProductSourceAlert.productSourceAlert;
        resolve(data);
      } catch {
        resolve(false);
      }
    });
  };

  const handleSubmitMapping = async (
    attribute,
    currentBrand,
    productSourceScrapId
  ) => {
    return new Promise(async (resolve, reject) => {
      if (!attribute.exist && attribute.value !== "none") {
        try {
          const res = await props.client.mutate({
            mutation: ADD_BRAND_SOURCE_MAPPING,
            variables: {
              productSource: currentBrand.id,
              attribute: attribute.id,
              productSourceScrap: productSourceScrapId,
            },
            fetchPolicy: "no-cache",
          });
          resolve(res);
        } catch (error) {
          resolve(false);
        }
      } else if (attribute.exist && attribute.value !== "none") {
        try {
          const res = await props.client.mutate({
            mutation: UPDATE_BRAND_SOURCE_MAPPING,
            variables: {
              id: brand.productSourceMappings?.edges?.find(
                (map) => map.node.attribute.id === attribute.id
              )?.node.id,
              productSource: currentBrand.id,
              attribute: attribute.id,
              productSourceScrap: productSourceScrapId,
            },
            fetchPolicy: "no-cache",
          });
          resolve(res);
        } catch (error) {
          resolve(false);
        }
      }
    });
  };

  const handleSubmitScrap = async (currentBrand, siteRef) => {
    return new Promise(async (resolve, reject) => {
      let variables = {
        name: siteRef.link,
        link: siteRef.link,
        codeScrap: siteRef.link,
        productSource: currentBrand.id,
      };

      if (siteRef.id) {
        variables = {
          ...variables,
          id: siteRef.id,
        };
      }

      try {
        const res = await client.mutate({
          mutation: variables.id
            ? UPDATE_BRAND_SOURCE_SCRAP
            : ADD_BRAND_SOURCE_SCRAP,
          variables: variables,
        });
        const scrap = variables.id
          ? res.data.updateProductSourceScrap.productSourceScrap
          : res.data.createProductSourceScrap.productSourceScrap;
        resolve(scrap);
      } catch (error) {
        resolve(false);
      }
    });
  };

  const onSubmit = async (form, mapping) => {
    props.startLoading();

    try {
      const currentBrand = await handleSubmitBrand();

      if (currentBrand) {
        await handleSubmitAlert(currentBrand);

        // Suppression des maps qui ne sont plus utilisé
        await Promise.all(
          mapping.map(async (attribute) => {
            if (attribute.exist && attribute.value === "none") {
              const id = brand.productSourceMappings?.edges?.find(
                (map) => map.node.attribute.id === attribute.id
              )?.node.id;

              await handleDeleteMapping(id);
            }
          })
        );

        // Création des scrappings
        await Promise.all(
          form.referenceSites.map(async (site) => {
            const scrap = await handleSubmitScrap(currentBrand, site);

            // Création des maps
            await Promise.all(
              mapping.map(async (attribute) => {
                if (
                  attribute.value === site.id ||
                  attribute.value === site.tempoId
                ) {
                  await handleSubmitMapping(attribute, currentBrand, scrap.id);
                }
              })
            );
          })
        );
      }
      props.stopLoading();
      props.snack(
        ALERT_SUCCESS,
        brand ? "Marque modifié avec succès" : "Marque ajouté avec succès"
      );
      if (onSuccess) onSuccess();
    } catch (error) {
      props.snack(
        ALERT_ERROR,
        brand
          ? "Erreur lors de la modification de la marque"
          : "Erreur lors de l'ajout de la marque"
      );
      if (onFail) onFail();
    }
  };

  const handleDeleteMapping = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await props.client.mutate({
          mutation: DELETE_BRAND_SOURCE_MAPPING,
          variables: { id },
          fetchPolicy: "no-cache",
        });
        resolve(res);
      } catch (err) {
        props.snack(
          ALERT_ERROR,
          "Une erreur est survenu lors de la suppression des scrappings"
        );
        resolve(false);
      }
    });
  };

  const handleDeleteSite = async (site) => {
    if (site.id) {
      props.startLoading();

      // Suppression des maps lié au site qui va être supprimé
      await Promise.all(
        mapping.map(async (attribute) => {
          const id = brand.productSourceMappings?.edges?.find(
            (map) => map.node.attribute.id === attribute.id
          )?.node.id;

          if (id) {
            await handleDeleteMapping(id);

            const newMapping = mapping.map((map) => {
              if (map.value === attribute.value) {
                return {
                  ...map,
                  value: "none",
                  exist: false,
                };
              } else {
                return map;
              }
            });
            setMapping(newMapping);
          }
        })
      );

      // Suppression du site
      try {
        await props.client.mutate({
          mutation: DELETE_BRAND_SOURCE_SCRAP,
          variables: { id: site.id },
          fetchPolicy: "no-cache",
        });
        setForm((prevState) => ({
          ...prevState,
          referenceSites: prevState.referenceSites.filter(
            (referenceSite) =>
              referenceSite.id !== site.id ||
              referenceSite.tempoId !== site.tempoId
          ),
        }));
      } catch (err) {
        props.snack(
          ALERT_ERROR,
          "Une erreur est survenu lors de la suppression du site référent"
        );
      }
    } else {
      const newMapping = mapping.map((map) => {
        if (map.value === site.id || map.value === site.tempoId) {
          return {
            ...map,
            value: "none",
            exist: false,
          };
        } else {
          return map;
        }
      });
      setMapping(newMapping);

      setForm((prevState) => ({
        ...prevState,
        referenceSites: prevState.referenceSites.filter(
          (referenceSite) =>
            referenceSite.id !== site.id ||
            referenceSite.tempoId !== site.tempoId
        ),
      }));
    }
    props.stopLoading();
  };

  return (
    <Container>
      <TopPanel
        inForm={true}
        windowWidth={props.windowWidth}
        title={brand ? "Modifier une marque" : "Ajouter une marque"}
        subtitle={
          "Veuillez compléter les champs ci-dessous pour configurer votre image de marque"
        }
      />
      <OurStepper
        activeStep={activeStep}
        steps={[
          {
            label: "Informations",
            show: true,
            completed: stepOneCompleted(),
            required: true,
          },
          {
            label: "Écarts",
            show: true,
            completed: stepTwoCompleted(),
            required: true,
          },
          {
            label: "Scrapping",
            show: true,
            completed: stepThreeCompleted(),
            required: true,
          },
          {
            label: "Validation",
            show: false,
            completed: false,
            required: true,
          },
        ]}
        helper="* Champs obligatoires"
        onChange={(step) => handleChangeStep(step)}
      >
        <StepPanel
          title="Informations"
          style={{
            height: "calc(100vh - 275px)",
            overflowY: "auto",
            border: `1px solid ${colors.grey.lighter.hue700}`,
          }}
          value={activeStep}
          index={0}
        >
          <FormContainer>
            <InputBuilder
              input={{
                type: "mediaPicker",
                fileType: MEDIAPICKER_TYPES.FILE,
                allowedTypes: IMAGES,
                allowedMedia: IMAGES,
                label: "Importez votre image",
                translated: false,
                helper: {
                  text: "",
                  link: false,
                },
                required: false,
                stateName: "listDocument",
                usedAlone: true,
                value: form.image,
                handleMediaPicker: (e, stateName) =>
                  setForm((prevState) => ({
                    ...prevState,
                    image: e,
                  })),
              }}
              value={form.image}
            />

            <Separator />

            <TextInput
              label="Nom de la marque"
              value={form.name}
              onChange={(e) =>
                setForm((prevState) => ({ ...prevState, name: e.target.value }))
              }
              helper={<span>Indiquer le nom de votre marque</span>}
              required={true}
              placeholder="Nom de la marque"
              sx={{ width: "100%" }}
            />
            <TextareaInput
              label="Description"
              value={form.description}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
              helper={<span>Indiquer la description de votre marque</span>}
              placeholder="Description"
              sx={{ width: "100%" }}
            />
            <TextInput
              label="URL du site de la marque"
              value={form.website}
              required={true}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  website: e.target.value,
                }))
              }
              helper={<span>Indiquer l'URL du site de votre marque</span>}
              placeholder="https://www.example.com"
              sx={{ width: "100%" }}
            />
            <RadioGroupInput
              label="Actif"
              value={form.active}
              options={[
                { value: true, label: "Oui" },
                { value: false, label: "Non" },
              ]}
              required={true}
              radioGroupProps={{
                row: true,
              }}
              sx={{ width: "100%" }}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  active: e.target.value,
                }))
              }
            />
          </FormContainer>
        </StepPanel>

        <StepPanel
          title="Écarts"
          index={1}
          value={activeStep}
          style={{
            height: "calc(100vh - 275px)",
            overflowY: "auto",
            border: `1px solid ${colors.grey.lighter.hue700}`,
            backgroundColor: colors.background.white.primary,
          }}
        >
          <FormContainer>
            {form.referenceSites.map((site, index) => {
              return (
                <Grid
                  container
                  key={index}
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  spacing={1}
                >
                  <Grid item xs={11}>
                    <TextInput
                      key={index}
                      label={`Site référent ${index + 1}`}
                      value={site.link}
                      onChange={(e) =>
                        setForm((prevState) => ({
                          ...prevState,
                          referenceSites: [
                            ...prevState.referenceSites.slice(0, index),
                            {
                              id: site.id ?? null,
                              tempoId: site.tempoId ?? null,
                              link: e.target.value,
                            },
                            ...prevState.referenceSites.slice(index + 1),
                          ],
                        }))
                      }
                      required={true}
                      helper={<span>Indiquer l'URL du site réferent</span>}
                      placeholder="https://www.example.com"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {form.referenceSites.length > 1 && (
                      <Tooltip title="Supprimer">
                        <IconButton onClick={() => handleDeleteSite(site)}>
                          <CancelOutlinedIcon color="#000000" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              );
            })}

            <Box sx={{ display: "flex", gap: 10, justifyContent: "end" }}>
              <TextAddElement
                label="Ajouter un site référent"
                onClick={async () => {
                  const tempoId = await generateTempoId();
                  setForm((prevState) => ({
                    ...prevState,
                    referenceSites: [
                      ...prevState.referenceSites,
                      { tempoId, link: "" },
                    ],
                  }));
                }}
              />
            </Box>

            <Separator />

            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item xs={6}>
                <TextInput
                  label="Écart négatif"
                  value={form.negatifGap}
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      negatifGap: e.target.value,
                    }))
                  }
                  helper={<span>Mon produit est - cher</span>}
                  placeholder="Écart négatif"
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  label="Écart positif"
                  value={form.positifGap}
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      positifGap: e.target.value,
                    }))
                  }
                  helper={<span>Mon produit est + cher</span>}
                  placeholder="Écart positif"
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>

            <RadioGroupInput
              label="Alerte activé"
              value={form.alertActive}
              options={[
                { value: "true", label: "Oui" },
                { value: "false", label: "Non" },
              ]}
              required={true}
              radioGroupProps={{
                row: true,
              }}
              sx={{ width: "100%" }}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  alertActive: e.target.value,
                }))
              }
            />
          </FormContainer>
        </StepPanel>

        <StepPanel
          title="Scrapping"
          index={2}
          value={activeStep}
          style={{
            height: "calc(100vh - 275px)",
            overflowY: "auto",
            border: `1px solid ${colors.grey.lighter.hue700}`,
            backgroundColor: colors.background.white.primary,
          }}
        >
          <FormContainer>
            {mapping.map((attribute, index) => {
              return (
                <RowMatching
                  key={index}
                  label={attribute.label}
                  value={attribute.value}
                  options={getOptionsMapping()}
                  sx={{
                    width: "100%",
                    backgroundColor:
                      attribute.value !== "none"
                        ? colors.blue.lighter.hue600
                        : colors.white,
                    alignItems: "center",
                    padding: "10px",
                  }}
                  selectMultiple={false}
                  onChange={(newVal) => {
                    setMapping((prevState) => {
                      const newMapping = mapping.map((map) => {
                        if (map.id === attribute.id) {
                          return {
                            ...map,
                            value: newVal,
                          };
                        } else {
                          return map;
                        }
                      });
                      return newMapping;
                    });
                  }}
                />
              );
            })}
          </FormContainer>
        </StepPanel>
        <StepPanel title="Validation" index={3} value={activeStep}>
          <FinalStep
            icon="check"
            title="Votre marque est prête !"
            text="Vous pouvez enregistrer votre marque :)"
            action={{
              text: "Enregistrer la marque",
              onClick: () => onSubmit(form, mapping),
            }}
          />
        </StepPanel>
      </OurStepper>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withApollo(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandForm))
  )
);
