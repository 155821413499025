import { Grid, Box } from "@mui/material";
import Typography from "@ui/typography/Typography";
import { buttonCardStyles, topItemNameStyles } from "../styledComponents";
import PageLoader from "@ui/loadings/page-loader/PageLoader";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { SNACK } from "@constants/action-types";
import colors from "@config/theme/colors";
import OurButton from "@ui/button/Button";
import CardCustom from "@components/layouts/Card/CardCustom";
import { ROUTE_PRODUCTS_LIST_DETAIL } from "@constants/route-names";
import { useEffect, useRef, useState } from "react";
import request from "@utils/fetch";
import Slider from "react-slick/lib/slider";
import styled from "styled-components";
import AccordionCustom from "@components/layouts/Accordion/AccordionCustom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { isImgUrl } from "@utils/isValidImage";
import Button from "@ui/button/OurButton";

const ContainerSlider = styled.div`
  max-width: 99%;
  *:focus {
    outline: none !important;
  }
  .slick-track {
  }
  .slick-initialized .slick-slide {
    width: auto;
    padding: 0 5px;
    > div {
      width: 100%;
    }
  }
`;

const Arrow = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.5px solid black;
  background-color: white;
  padding: 15px;
  z-index: 1;
  left: ${(props) => (props.left ? props.left : "unset")};
  right: ${(props) => (props.right ? props.right : "unset")};
  &:before {
    content: "";
  }
  &:hover {
    background-color: white;
  }
`;
/* // TODO: The back needs to be prepared to send top items for the filters (month and year, etc) */
const TopItems = (props) => {
  const [topItems, setTopItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSwiping, setIsSwiping] = useState(false);
  const [error, setError] = useState(null);
  const firstCarouselRef = useRef();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handlerDrag = (next) => {
    setCurrentSlideIndex(next);
    setIsSwiping(true);
  };

  const getTopItem = () => {
    setIsLoaded(false);
    request(
      `${process.env.REACT_APP_API}/statistics/sales-order/top-items`,
      "POST",
      false
    )
      .then(async (data) => {
        if (data.success) {
          const array = await Promise.all(
            data.topItems.map(async (e) => {
              try {
                let isValidImg = await isImgUrl(
                  `${process.env.REACT_APP_MEDIAS}/${e.file}`
                );
                return {
                  ...e,
                  file: isValidImg
                    ? `${process.env.REACT_APP_MEDIAS}/${e.file}`
                    : "/img/not-found.png",
                };
              } catch (error) {
                console.log(error);
              }
            })
          );
          setIsLoaded(true);
          setTopItems(array);
        } else {
          setIsLoaded(true);
          setTopItems([]);
        }
      })
      .catch((err) => {
        setError(err);
        setIsLoaded(true);
      });
  };

  const topItemNameClass = topItemNameStyles();
  const buttonCardClass = buttonCardStyles();

  const goTo = (route) => {
    props.history.push(route);
  };

  useEffect(() => {
    getTopItem();
  }, []);

  const settings = {
    infinite: false,
    dots: false,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    nextArrow: (
      <Arrow right={"-35px"}>
        <ChevronRightIcon style={{ fontSize: 30, color: "black" }} />
      </Arrow>
    ),
    prevArrow: (
      <Arrow left={"-15px"}>
        <ChevronLeftIcon style={{ fontSize: 30, color: "black" }} />
      </Arrow>
    ),
    beforeChange: (current, next) => handlerDrag(next),
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };

  return (
    <Box pb={2}>
      <AccordionCustom
        defaultExpanded={true}
        square={true}
        title={props.t("dashboard.topitems")}
      >
        {!isLoaded ? (
          <Box
            style={{
              position: "relative",
              height: "300px",
              width: "100%",
              color: colors.grey.regular,
            }}
          >
            <PageLoader />
          </Box>
        ) : topItems?.length !== 0 ? (
          <Box width={"100%"}>
            <Grid
              container
              direction="column"
              style={{ gap: "5px", marginBottom: "20px" }}
            >
              <Typography variant={"h3"} style={{ fontWeight: "bold" }}>
                Top 5 des meilleures ventes
              </Typography>
              <Typography
                variant={"h5"}
                style={{
                  fontWeight: "bold",
                  color: colors.grey.lighter.hue600,
                }}
              >
                Ce Top est actualisé pour cette semaine
              </Typography>
            </Grid>
            {/* Items */}
            <ContainerSlider>
              <Slider ref={firstCarouselRef} {...settings}>
                {topItems?.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      container
                      style={{ paddingBottom: "0px" }}
                    >
                      <CardCustom
                        style={{ height: "100%" }}
                        cardContentStyle={{
                          paddingBottom: "16px",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                          }}
                        >
                          <div>
                            <Grid container style={{ position: "relative" }}>
                              <span
                                style={{
                                  backgroundColor: colors.green.regularv2,
                                  padding: "3px 15px",
                                  color: colors.white,
                                  fontWeight: "bold",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                }}
                              >
                                {" "}
                                TOP {index + 1}
                              </span>
                              <img
                                src={item.file}
                                style={{
                                  width: "100%",
                                  height: "338px",
                                  objectFit: "cover",
                                }}
                              />
                            </Grid>
                            <Box pt={1}>
                              <Typography
                                variant="body1"
                                component="div"
                                style={{ color: colors.grey.lighter.hue800 }}
                              >
                                réf: {item.sku}
                              </Typography>
                            </Box>
                            <Box py={1} className={topItemNameClass.root}>
                              <Typography variant="h3" component="div">
                                {item.name}
                              </Typography>
                            </Box>
                            <Box pb={2}>
                              <Typography variant="body1" component="div">
                                Quantité commandée:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: colors.black.regular,
                                  }}
                                >
                                  {item?.orderedQuantity}
                                </span>
                              </Typography>
                            </Box>
                          </div>
                          {item.id !== null ? (
                            <Button
                              label={props.t("connectors.dashboard.seeDetails")}
                              handleClick={() => {
                                goTo(
                                  ROUTE_PRODUCTS_LIST_DETAIL.replace(
                                    ":id",
                                    item.id
                                  )
                                );
                              }}
                            />
                          ) : (
                            <Button
                              label={props.t(
                                "connectors.dashboard.detailsUnavailble"
                              )}
                              disabled={true}
                            />
                          )}
                        </Box>
                      </CardCustom>
                    </Grid>
                  );
                })}
              </Slider>
            </ContainerSlider>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{ padding: "24px 0px", color: colors.grey.regular }}
            >
              Aucun produit pour le moment
            </Typography>
          </Box>
        )}
      </AccordionCustom>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TopItems))
);
