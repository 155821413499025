import { Box, Grid } from "@mui/material"
import OurTooltip from "../tooltip/Tooltip"
import colors from "../../../config/theme/colors"
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { truncate } from "lodash";
import { truncateSync } from "fs";

const OurLock = ({lock, isDisabled = false, style}) => {

  return (
    <Box style={{
      width: 'auto',
      display: 'inline-block',
      ...style
    }}>
      {lock?.show ? (
        <Grid item xs={1} style={{ paddingTop: 16, display: 'flex', justifyContent: 'center' }}>
          {
            lock?.isLocked
              ? (
                <OurTooltip title={"Valeur non écrasée lors des imports"}>
                  <Box mb={'3px'}>
                    {isDisabled
                      ? <LockIcon onClick={lock?.callback} style={{ fill: colors.grey.lighter.hue600 }} />
                      : <LockIcon onClick={lock?.callback} style={{ cursor: 'pointer' }} />
                    }
                  </Box>
                </OurTooltip>
              )
              : (
                <OurTooltip title={"Valeur écrasée lors des imports"}>
                  <Box mb={'3px'}>
                    {isDisabled
                      ? <LockOpenIcon onClick={lock?.callback} style={{ fill: colors.grey.lighter.hue600 }} />
                      : <LockOpenIcon onClick={lock?.callback} style={{ cursor: 'pointer' }} />
                    }
                  </Box>
                </OurTooltip>
              )
          }
        </Grid>
      ) : null}
    </Box>
  )
}

export default OurLock