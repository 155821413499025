import { withRouter } from "react-router";
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types";
import AccordionCustom from "../../Accordion/AccordionCustom";
import colors from "../../../../config/theme/colors";
import {  Grid, Typography } from "@mui/material";
import InputBuilder from "../../../ui/form/InputBuilder";
import { useState } from "react";
import { useEffect } from "react";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import Swiper from "react-id-swiper"
import { GET_MEDIAS_IMAGES } from "../../../../queries/medias";
import styled from "styled-components";
import CarouselCustom from "../../Carousel/CarouselCustom";

const ThumbnailCarousel = styled.img`
    height:300px;
    object-fit:cover;
`

const CardContentDetails = props =>{
    const [imageOptions,setImagesOptions] =useState({})
    const [otherOptions,setOtherOptions] =useState([])
    const [mediasCarousel,setMediasCarousel] =useState([])

    useEffect(()=>{        
        setImagesOptions(props.dataLayout.formConfig.children.find(e=>e.labelName === "Médias").optionsInputs.find(e=>e.stateName === "content_image"))
        setOtherOptions(props.dataLayout.formConfig.children.find(e=>e.labelName === "Général").optionsInputs.filter(e=>e.showInCard))
    },[props])

    useEffect(()=>{
        getMedias()        
    },[props.allState.selectedMediaCategories])

    const getMedias = ()=>{
        setMediasCarousel([])
        props.allState.selectedMediaCategories.forEach(element => {
            props.client.query({
                query: GET_MEDIAS_IMAGES,
                variables: { 
                    categories: element.id
                }
              }).then(result => {                           
                let obj={
                    label:element.label,
                    value:result.data.mediaObjects.edges
                }         
                setMediasCarousel(previous=>[...previous,obj])
            }).catch(error => {
                console.err(error);
                props.dispatch({ type: SNACK, data: { message: error.message, type: "error" } });
            });           
        });       
    }

    const settings={
        slidesPerView: 3.5,
        spaceBetween: 10,
        pagination: false,
        grabCursor: true,
    }

    const getMediaType = (image)=>{
        if (image.node.type === "video/mp4") {
            return 'video'
        }else{
            return 'image'
        }
    }
    
    return (
        <AccordionCustom custompadding={"20px 20px 0 20px !important"} overflow={"auto"} style={{height:"100%",overflow:"hidden"}} forcedExpanded title={'Contenu'}>  
        {
            !props.allState.ready ?
            <PageLoader/>
            :<>
            <Grid container style={{marginBottom:20}}>
                <Grid item xs={12}>
                    <Typography style={{fontSize:16,color:colors.black.regular,marginBottom:5}}>
                        {imageOptions?.label}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {imageOptions?
                    <InputBuilder
                        value={
                            imageOptions.translated
                                ? imageOptions.valueWithFlags
                                    ? props.allState[props.allState.currentLang][imageOptions.stateName]?.value
                                    : props.allState[props.allState.currentLang][imageOptions.stateName]
                                : imageOptions.valueWithFlags
                                    ? props.allState[imageOptions.stateName]?.value
                                    : props.allState[imageOptions.stateName]
                        }                                 
                        allState={props.allState}
                        input={imageOptions}
                        border={true}
                        handleTreeChange={props.handleTreeChange}
                        expandNodes={props.expandNodes}
                        treeEditCat={props.treeEditCat}
                        copyCategories={props.copyCategories}
                        treeAddCat={props.treeAddCat}
                        errorCallback={props.errorCallback ? (error) => props.errorCallback(imageOptions.stateName, error) : () => { }}
                        stateCallback={(evt, custom) => props.stateCallback(imageOptions.stateName, evt, custom, imageOptions.translated)}
                        stateCallbackOriginal={props.stateCallback}
                        handleButtonGroupChange={props.handleButtonGroupChange
                            ? props.handleButtonGroupChange
                            : null}
                            gridItems={props.gridItems}
                            loadGrid={props.loadGrid}
                            addProducts={props.addProducts}
                            merchModal={props.merchModal}
                            resetReloadListing={props.resetReloadListing}
                            catalogDatas={props.catalogDatas}
                    />
                    :null}
                </Grid>
            </Grid>
            {
                otherOptions?.map((w,i)=>{                    
                    return (
                        <Grid key={i} container style={{marginBottom:20}}>
                            <Grid item xs={12}>
                                <Typography style={{fontSize:16,color:colors.black.regular,marginBottom:5}} >
                                    {w.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="card-content">
                                <InputBuilder
                                    value={
                                        w.translated
                                            ? w.valueWithFlags
                                                ? props.allState[props.allState.currentLang][w.stateName]?.value
                                                : props.allState[props.allState.currentLang][w.stateName]
                                            : w.valueWithFlags
                                                ? props.allState[w.stateName]?.value
                                                : props.allState[w.stateName]
                                    }
                                    allState={props.allState}
                                    input={w}
                                    handleTreeChange={props.handleTreeChange}
                                    expandNodes={props.expandNodes}
                                    treeEditCat={props.treeEditCat}
                                    copyCategories={props.copyCategories}
                                    treeAddCat={props.treeAddCat}
                                    errorCallback={props.errorCallback ? (error) => props.errorCallback(w.stateName, error) : () => { }}
                                    stateCallback={(evt, custom) => props.stateCallback(w.stateName, evt, custom, w.translated)}
                                    stateCallbackOriginal={props.stateCallback}
                                    handleButtonGroupChange={props.handleButtonGroupChange
                                        ? props.handleButtonGroupChange
                                        : null}
                                    gridItems={props.gridItems}
                                    loadGrid={props.loadGrid}
                                    addProducts={props.addProducts}
                                    merchModal={props.merchModal}
                                    resetReloadListing={props.resetReloadListing}
                                    catalogDatas={props.catalogDatas}
                                />
                            </Grid>
                        </Grid>
                    )
                })
            }
            {
                mediasCarousel.length > 0 ?
                    <Grid container style={{marginBottom:20}}>
                        <Grid item xs={12} style={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center"
                        }}>
                            <Typography style={{fontSize:16,color:colors.black.regular,marginBottom:5}} >
                                Carousel d'images
                            </Typography>
                            {/* <Typography style={{fontSize:16,color:colors.blue.darker.hue300,marginBottom:5,cursor:'pointer'}} >
                                + Ajouter des images
                            </Typography> */}
                        </Grid>                        
                            {
                                mediasCarousel.map((c,i)=>{                                        
                                    return (
                                        <Grid key={i} container item xs={12}>
                                            <Grid item xs={12} style={{paddingTop:"10px"}}>
                                                <Typography style={{color:colors.grey.lighter.hue600}}>{c.label}</Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{paddingTop:"10px"}}>
                                                <CarouselCustom slidesNb={2.5} images={c.value.map((v,i)=>`${process.env.REACT_APP_MEDIAS}/${v.node.filePath}`)} imagesWithoutPlaceholder={c.value.map((v,i)=>`${process.env.REACT_APP_MEDIAS}/${v.node.filePath}`)} />
                                            </Grid>
                                        </Grid>
                                    )
                                })                        
                            }                    
                        </Grid>                    
                :null
            }            
            </>
        }            
        </AccordionCustom>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
        attributeGroups: state.attributeGroups
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(CardContentDetails)));
