import { gql } from '@apollo/client';

//----- Récupération des models
export const GET_MODELES = gql`
{
  models{
    edges{
      node{
        id
      	name
        description
        media{
          id
          filePath
        }
        modelPhases{
          edges{
            node{
              id
              name
              position
              modelTasks{
                edges{
                  node{
                    id
                    name
                    reference
                    description
                    price
                    position
                    task {
                      id
                      name
                    }
                    pole{
                      edges{
                        node{
                          id
                     	   name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;


export const GET_MODELE_BY_ID = gql`
query model($id: ID!) {
  model(id: $id) {
    id
    name
    description
    media{
      id
      filePath
    }
    modelPhases{
      edges{
        node{
          id
          name
          position
          modelTasks{
            edges{
              node{
                id
                name
                reference
                description
                price
                position
                quantity
                total
                discountFixed
                discountPercent
                tax {
                  id
                  name
                }
                task {
                  id
                  name
                }
                pole{
                  edges{
                    node{
                      id
                     name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;


//----- Ajouter un model
/*
 Exemple de variables
  {
  "name": "Magento 2",
  "description": "Mise en place Boutique en ligne Magento"
  }
*/
export const ADD_MODELE = gql`
mutation AddModel($name: String!, $description: String, $media: String, $recurring: Boolean, $recurringStartDate: String, $recurringInvoiceDay: Int, $recurringDelay: Int){
	createModel(input: {name: $name, description: $description, media: $media, recurring: $recurring, recurringStartDate: $recurringStartDate, recurringInvoiceDay: $recurringInvoiceDay, recurringDelay: $recurringDelay}){
    model{
      id
      name
      description
      media{
        id
        filePath
      }
    }
  }  
}`;

//----- Modifier un modèle
/*
 Exemple de variables
  {
  "id" : "/api/models/1",
  "name": "Magento 2",
  "description": "Mise en place Boutique en ligne Magento 2"
  }
*/
export const UPDATE_MODELE = gql`
mutation UpdateModel($id: ID!, $name: String!, $description: String, $media: String, $recurring: Boolean, $recurringStartDate: String, $recurringInvoiceDay: Int, $recurringDelay: Int){
  updateModel(input: {id: $id, name: $name, description: $description, media: $media, recurring: $recurring, recurringStartDate: $recurringStartDate, recurringInvoiceDay: $recurringInvoiceDay, recurringDelay: $recurringDelay}){
    model{
      id
      name
      description
      media{
        id
        filePath
      }
    }
  }  
}
`;

//----- Supprimer un modèle
/*
 Exemple de variables
  {
    "id" : "/api/models/1"
  }
*/
export const DELETE_MODELE = gql`
mutation DeleteModel($id: ID!){
	deleteModel(input: {id:$id}){
    model{
      id
    }
  }  
}`;





//----- Ajouter une phase d'un model
/*
 Exemple de variables
  {
  "name": "Définition du projet",
  "position": 1,
  "model" : "/api/models/1"
  }
*/
export const ADD_PHASE_MODELE = gql`
mutation AddPhaseModel($name: String!, $position: Int, $model: String){
	createModelPhase(input: {name: $name, position: $position, model: $model}){
    modelPhase{
      id
      name
    }
  }  
}`;

//----- Modifier une phase d'un model
/*
 Exemple de variables
  {
  "id": "/api/model-phases/1",
  "name": "Définition",
  "position": 1,
  "model" : "/api/models/1"
  }
*/
export const UPDATE_PHASE_MODELE = gql`
mutation UpdatePhaseModel($id: ID!, $name: String, $position: Int, $model: String){
	updateModelPhase(input: {id: $id, name: $name, position: $position, model: $model}){
    modelPhase{
      id
      name
    }
  }  
}`;


//----- Supprimer une phase d'un model
/*
 Exemple de variables
  {
    "id" : "/api/model-phases/1"
  }
*/
export const DELETE_PHASE_MODELE = gql`
mutation DeletePhaseModel($id: ID!){
	deleteModelPhase(input: {id:$id}){
    modelPhase{
      id
    }
  }  
}`;


//----- Ajouter une task à une phase d'un model
/*
 Exemple de variables
  {
  "name": "Définition",
  "reference": "REF0001",
  "description": "Définition du projet",
  "price": "99.90",
  "quantity": "1",
  "total": "19.90",
  "position": 1,
  "phase" : "/api/model-phases/1",
  "task": "/api/model-tasks/1",
  "poles" : []
  }
*/

export const ADD_TACHE_PHASE_MODELE = gql`
mutation AddModelTask($name: String!, $reference: String, $description: String, $price: String, $quantity: String, $total: String, $delay: String, $position: Int, $phase: String, $task: String,  $poles: [String], $tax: String, $discountFixed: String, $discountPercent: String){
  createModelTask(input: {name: $name, reference: $reference, description: $description, price: $price, quantity: $quantity, total: $total, delay: $delay, position: $position, phase: $phase, task: $task, pole: $poles, tax: $tax, discountFixed: $discountFixed, discountPercent: $discountPercent}){
    modelTask{
      id
      name
      reference
      description
      price
      position
      pole{
        edges{
          node{
            id
            name
          }
        }
      }
    }
  }  
}`;



//----- Modifier une task à une phase d'un model
/*
 Exemple de variables
  {
  "id": "/api/model-tasks/3",
  "name": "Définition de test",
  "reference": "REF0003",
  "description": "Définition du projet",
  "price": "19.90",
  "quantity": "1",
  "total": "19.90",
  "position": 3,
  "phase" : "/api/model-phases/1",
  "task": "/api/model-tasks/1",
  "poles" : []
  }
*/
export const UPDATE_TACHE_PHASE_MODELE = gql`
mutation UpdateModelTask($id: ID!, $name: String, $reference: String, $description: String, $price: String, $quantity: String, $total: String, $delay: String, $position: Int, $phase: String, $task: String,  $poles: [String], $tax: String, $discountFixed: String, $discountPercent: String){
  updateModelTask(input: {id:$id, name: $name, reference: $reference, description: $description, price: $price, quantity: $quantity, total: $total, delay: $delay, position: $position, phase: $phase, task: $task, pole: $poles, tax: $tax, discountFixed: $discountFixed, discountPercent: $discountPercent}){
    modelTask{
      id
      name
      reference
      description
      price
      position
      pole{
        edges{
          node{
            id
            name
          }
        }
      }
    }
  }  
}`;


//----- Supprimer une task
/*
 Exemple de variables
  {
    "id" : "/api/model-tasks/1"
  }
*/
export const DELETE_TACHE_PHASE_MODELE = gql`
mutation DeleteModelTask($id: ID!){
	deleteModelTask(input: {id:$id}){
    modelTask{
      id
    }
  }  
}`;


export const GET_MODEL_COMPONENTS = gql`
query modelComponents ($nbperpage: Int, $cursor: String, $cursorLast: String) {
  modelComponents (first: $nbperpage, after: $cursor, before: $cursorLast) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        identifier
        libelle
        content
        createdAt
      }
    }
  }
}
`;

//----- Ajouter des modèles de components
/*
 Exemple de variables
{
  "identifier": "demo",
  "libelle": "demo",
  "content": "Test content",
  "createdAt": "2021-04-19",
  "model": "/api/models/1"
}
*/


export const ADD_MODEL_COMPONENT = gql`
mutation AddModelComponent($identifier: String!, $libelle: String!, $content: String, $createdAt: String!, $updatedAt: String, $model: String){
  createModelComponent(input: {identifier: $identifier, libelle: $libelle, content: $content, createdAt: $createdAt, updatedAt: $updatedAt, model: $model}){
    modelComponent{
      id
      identifier
      libelle
      content
      model{
        id
      }
    }
  }  
}`;

//----- Modifier des modèles de components
/*
 Exemple de variables
{
  "id" : "/api/model-components/2",
  "identifier": "demo",
  "libelle": "demo",
  "content": "Test content edited",
  "createdAt": "2021-04-19",
  "model": "/api/models/1"
}
*/


export const UPDATE_MODEL_COMPONENT = gql`
mutation UpdateModelComponent($id: ID!, $identifier: String, $libelle: String, $content: String, $createdAt: String, $updatedAt: String, $model: String){
  updateModelComponent(input: {id: $id, identifier: $identifier, libelle: $libelle, content: $content, createdAt: $createdAt, updatedAt: $updatedAt, model: $model}){
    modelComponent{
      id
      identifier
      libelle
      content
      model{
        id
      }
    }
  }  
}`;

//----- Supprimer un model de composant
/*
 Exemple de variables
  {
    "id" : "/api/model-components/2"
  }
*/
export const DELETE_MODEL_COMPONENT = gql`
mutation DeleteModelComponent($id: ID!){
	deleteModelComponent(input: {id:$id}){
    modelComponent{
      id
    }
  }  
}`;