import React from "react";
import { Tooltip, tooltipClasses } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import colors from "../../../config/theme/colors";
import styled from "styled-components";

const TooltipCustom = styled(Tooltip)`
    ${'.' + tooltipClasses.tooltip} {
        background-color: red !important;
        color: ${colors.white};
    }
`

function OurTooltip(props) {
    return <TooltipCustom arrow {...props} />;
}

export default OurTooltip