import { Badge, Box, Divider } from "@mui/material"
import colors from "../../../../../../config/theme/colors"
import OurButton from "../../../../../ui/button/Button"
import WarningIcon from '@mui/icons-material/Warning';
import OurTypography from "../../../../../ui/typography/Typography";
import ErrorIcon from '@mui/icons-material/Error';
import SelectLang from "../../../../../ui/pagination/SelectLang";

const ProductDetailSummary = props => {
    const {anchors,currentAnchor,setCurrentAnchor,setScrollChange,locales} = props
    return ( 
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
            <Box display={"flex"} alignItems={"center"} gap={1}>
                <Box display={"flex"} alignItems={"center"} gap={1}>
                    <WarningIcon fontSize={"small"}/>
                    <OurTypography text={"Détections"} fontweightbold={'bold'} />
                    {/* <div style={{
                        backgroundColor:colors.red.regular,
                        color: colors.white,
                        borderRadius:100,
                        padding:5
                    }}>
                        1
                    </div> */}
                    <Badge badgeContent={1} color="error" style={{
                        margin:'0 10px'
                    }}/>
                </Box>
                <Divider flexItem orientation="vertical"/>
                <Box>
                    {
                        anchors&&
                        anchors.map((anchor, i) => {
                            return (
                                <OurButton
                                    key={i}
                                    style={{
                                        margin:0,
                                        height:20,
                                        padding:"14px 22px"
                                    }}
                                    bgcolor={currentAnchor === anchor ?colors.blue.lighter.hue900: "transparent"}
                                    color={currentAnchor === anchor?colors.blue.darker.hue300:colors.black.regular}
                                    bgcolorhover={colors.blue.lighter.hue900}
                                    colorhover={colors.blue.darker.hue300}
                                    onClick={() => {setCurrentAnchor(anchor);setScrollChange(false)}}
                                >
                                    {anchor.label}
                                </OurButton>
                            )
                        })
                    }
                </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
                <Divider flexItem orientation="vertical"/>
                <Box>
                    <SelectLang locales={locales} minimal/>
                </Box>
                <Divider flexItem orientation="vertical"/>
                <ErrorIcon onClick={()=>props.setOpenLogs(!props.openLogs)} sx={{ color: colors.grey.lighter.hue800 }} style={{
                    transform: 'rotate(180deg)',
                    cursor:'pointer'
                }}/>
            </Box>
        </Box>
    )
}

export default ProductDetailSummary;