import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { register } from "react-scroll/modules/mixins/scroller";
import Button from '../../../../shared/components/CustomButton';
import H1 from '../../../../shared/components/CustomH1';
import Pg from '../../../../shared/components/CustomParagraph';
import request from "../../../../../tools/Fetch";
import Swiper from 'swiper';
import { ReactPaginate } from "react-paginate";
import { Pagination } from "@mui/material";
import LoadingSVG from "./images/loading-animated.svg";
import BandeauText from "./images/wishlist/Bandeau-catalogue-desktop.png";
import BandeauTextTablet from "./images/wishlist/Bandeau-catalogue-tablette.png";
import BandeauTextMobile from "./images/wishlist/Bandeau-catalogue-mobile.png";
import BandeauTextES from "./images/wishlist/bandeau-catalogue-desktop-es.png";
import BandeauTextTabletES from "./images/wishlist/bandeau-catalogue-tablette-es.png";
import BandeauTextMobileES from "./images/wishlist/bandeau-catalogue-mobile-es.png";
import TopLeftImage from "./images/wishlist/bandeau-1.png";
import BottomRightImage from "./images/wishlist/bandeau-2.png";
import LEGOCity from "./images/wishlist/lego_city.png";
import LEGOFriends from "./images/wishlist/lego_friends.png";


const GameWishlist = (props) => {
    const [hover, setHover] = useState(false);
    const [maxItem, setMaxItem] = useState(3);
    const [listSelectedProduct, setListSelectedProduct] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [showLimit, setShowLimit] = useState(false)
    const [activeProduct, setActiveProduct] = useState(null);
    const [offsetTop, setOffsetTop] = useState(0);
    const [offsetBottom, setOffsetBottom] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [listProducts, setListProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ageFilter, setAgeFilter] = useState("-1");
    const [resetPagination, setResetPagination] = useState(false);
    const [currentCat, setCurrentCat] = useState("-1");
    const [currentPassion, setCurrentPassion] = useState("-1");

    let idCity= props.categories.find(e => e.libelle === "City")
    let idFriends= props.categories.find(e => e.libelle === "Friends")

    const VIDEOS     = ["video/mpeg","video/webm","video/mp4"];

    let { 
        preview = false,
        id = null, 
        data,
        inputCallback = null,
        products,
        spread = false
    } = props;

    const setWishlistDisplay = (display) => {
        document.getElementById("game_wishlist").style.display = display;
    }
    window.setWishlistDisplay = setWishlistDisplay

    const handleAddProduct = (product) => {
        if(listSelectedProduct.length < maxItem && !listSelectedProduct.includes(product)){
            listSelectedProduct.push(product);
            if (listSelectedProduct.length >= maxItem){
                setShowLimit(true)
            }
            setListSelectedProduct([...listSelectedProduct]);
        }else{
            for(let j = 0; j < listSelectedProduct.length; j++){
                if(listSelectedProduct[j] === null && !listSelectedProduct.includes(product)){
                    listSelectedProduct[j] = product;
                    setListSelectedProduct([...listSelectedProduct]);
                }
            }
        }
    }
    const handleRemoveProduct = (index) => {
        listSelectedProduct.splice(index,1);
        if (listSelectedProduct.length < maxItem){
            setShowLimit(false)
        }
        setListSelectedProduct([...listSelectedProduct]);
    }

    const handleTogglePopup = (product, id) => {
        if(product){
            setOpenPopup(true);
            setActiveProduct(product);
        }else{
            setOpenPopup(false);
            setActiveProduct(null);
        }
        
        if(id){
            var ele = document.getElementById(id);
            var top = 0;
            var height = 0;
            var eleContainer =  document.getElementById('wishlist-container');

            top = ele.offsetTop;
            height = eleContainer.offsetHeight;


            if(top > height - height / 2){
                setOffsetBottom(height - top);
                setOffsetTop(null);
            }
            else{
                setOffsetTop(top);
                setOffsetBottom(null);
            }
        }
    }

    const recapContent = () => {
        let recap = [];
        for(let i = 0; i < maxItem; i++){
            if(listSelectedProduct[i]){
                recap.push(<div className="active"><p className="remove" onClick={() => handleRemoveProduct(i)}>x</p><img src={`${process.env.REACT_APP_MEDIAS}/${listSelectedProduct[i].attributes.product_image.fr_FR}`} /></div>)
            }else{
                let emptyImage = data.inputs['emptyItem'].value;
                recap.push(<div>{typeof emptyImage === 'string' ? (<img src={emptyImage} />) : emptyImage?.filePath ? (<img src={`${process.env.REACT_APP_MEDIAS}/${emptyImage.filePath}`} />) : null}</div>)
            }
        }
        return recap;
    }

    const registerWishlist = () => {
        localStorage.setItem('products', JSON.stringify(listSelectedProduct));
        let data = new FormData();
        let getUsername = localStorage.getItem('username')
        data.append('username', getUsername);
        data.append('token', process.env.REACT_APP_ASSET_TOKEN);
        try {
            let wishlistObjects = listSelectedProduct.map(e => e.sku)
            data.append('products', wishlistObjects);
            request(`${process.env.REACT_APP_API}/public/asset/registration/add/products`, 'post', data, 'multipart/form-data').then(result => {
                onGameEnd()
            }).catch(e => {
                console.log('Erreur enregistrement wishlist',e);
            })
        }catch(e){
            console.log('Erreur fin du jeu',e);   
        }
    }

    const vidRef1 = useRef(null);
    const vidRef2 = useRef(null);

    const handlePlayVideo = () => {
        if(vidRef1.current){
            vidRef1.current.play();
        }
        if(vidRef2.current){
            vidRef2.current.play();
        }
    }

    useEffect(() => {
        handlePlayVideo();
    }, [openPopup]);

    useEffect(() => {
        handlePlayVideo();
        setMaxItem(data.inputs.nbItemMax.value);
    }, [props]);

    useEffect(() => {
        localStorage.setItem('products', JSON.stringify(listSelectedProduct));
    }, [listSelectedProduct]);

    useEffect(() => {
        let getValidation = localStorage.getItem('wishlist_validated')
        if (getValidation && JSON.parse(getValidation) === true){
            window.location = "/"
        }
        handlePlayVideo();
        let list = localStorage.getItem('products');
        if (list)
            list = JSON.parse(list);
            setListSelectedProduct([...list]);
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            spaceBetween: 10,
            slidesPerView: 1,
        });
    }, []);
    useEffect(() => {
        const swiper = new Swiper('.swiper-container', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            spaceBetween: 10,
            slidesPerView: 1,
        });
    }, [props, openPopup]);

    useEffect(() => {
        setLoading(true)
        document.getElementById('builder-template-scope-game').scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTimeout(() => {
            let newList = [];
            if (ageFilter !== "-1"){
                // let newListCategories = props.categories.slice(1).filter(e => e.attributes[ageFilter]?.[props.lang] === 1 || e.attributes[ageFilter]?.[props.lang] === '1');
                // let listCategoriesFlat = newListCategories.map(e => e.id);
                newList = products.filter(e => e.attributes.age.fr_FR === ageFilter)
                if (currentPassion !== "-1"){
                    newList = newList.filter(e => e.attributes.passion.fr_FR === currentPassion)
                }
                if (currentCat !== "-1"){
                    newList = newList.filter(e => e.categories.includes(parseInt(currentCat)))
                }
                let getCity = newList.filter(e => e.categories.includes(idCity.id));
                let getFriends = newList.filter(e => e.categories.includes(idFriends.id));
                newList = newList.filter(e => !e.categories.includes(idCity.id) && !e.categories.includes(idFriends.id));
                newList = getCity.concat(getFriends,newList);
                setTotalPages(Math.round(newList.length / 24));
                setListProducts(newList.slice(((currentPage - 1) * 24), (currentPage * 24)))
            }else if (currentCat !== "-1"){
                if (currentPassion !== "-1"){
                    products = products.filter(e => e.attributes.passion.fr_FR === currentPassion)
                }
                for (let product of products){
                    if (product.categories.includes(parseInt(currentCat))){
                        newList.push(product)
                    }
                }
                let getCity = newList.filter(e => e.categories.includes(idCity.id));
                let getFriends = newList.filter(e => e.categories.includes(idFriends.id));
                newList = newList.filter(e => !e.categories.includes(idCity.id) && !e.categories.includes(idFriends.id));
                newList = getCity.concat(getFriends,newList);
                setTotalPages(Math.round(newList.length / 24));
                setListProducts(newList.slice(((currentPage - 1) * 24), (currentPage * 24)));
            }else{
                if (currentPassion !== "-1"){
                    products = products.filter(e => e.attributes.passion.fr_FR === currentPassion)
                }
                let getCity = products.filter(e => e.categories.includes(idCity.id));
                let getFriends = products.filter(e => e.categories.includes(idFriends.id));
                products = products.filter(e => !e.categories.includes(idCity.id) && !e.categories.includes(idFriends.id));
                products = getCity.concat(getFriends,products);
                setTotalPages(Math.round(products.length / 24))
                setListProducts(products.slice(((currentPage - 1) * 24), (currentPage * 24)))
            }
            
            setLoading(false)
        }, 1000);
        setResetPagination(false)
    }, [currentPage, resetPagination, currentPassion]);

    useEffect(() => {
        if (currentPage === 1){
            setResetPagination(true)
        }else{
            setCurrentPage(1)
        }
    }, [ageFilter, currentCat]);

    const onGameEnd = async() => {
		try{
			let data = new FormData();
			data.append('username', localStorage.getItem('username'));
			data.append('token', process.env.REACT_APP_ASSET_TOKEN);
			data.append('link1', window.link1);
			data.append('link2', window.link2);
			data.append('link3', window.link3);
			data.append('link4', window.link4);
			const res = await fetch(`${process.env.REACT_APP_API}/public/asset/registration/play`, {
				method: 'post',
				body: data
			});
			const response = await res.json();
			if (response.success){
                window.location = "/win"
			}
		}catch(e){
			console.log('error', e)
		}
	};

    let image = data.inputs['backgroundImage'].value;
    let newCategories = props.categories?.slice(1);
    newCategories = newCategories.sort((a,b) => (a.libelle > b.libelle) ? 1 : ((b.libelle > a.libelle) ? -1 : 0));
    let filter_age = null;
    if (props.lang === "fr_FR"){
        filter_age = [{attribute: "age.0-3-ans", libelle: "0 - 3 ans"},  {attribute: "age.3-5-ans", libelle: "3 - 5 ans"}, {attribute: "age.6-8-ans", libelle: "6 - 8 ans"}, {attribute: "age.9-12-ans", libelle: "9 - 12 ans"}, {attribute: "age.Adulte", libelle: "16 ans et +"}]
    }else{
        filter_age = [{attribute: "age.0-3-ans", libelle: "0 - 3 años"},  {attribute: "age.3-5-ans", libelle: "3 - 5 años"}, {attribute: "age.6-8-ans", libelle: "6 - 8 años"}, {attribute: "age.9-12-ans", libelle: "9 - 12 años"}, {attribute: "age.Adulte", libelle: "16 años y más"}]
    }
    let filter_passion = null;
    if (props.lang === "fr_FR"){
        filter_passion = [{attribute: "passion.Animaux", libelle: "Animaux"},  {attribute: "passion.Art", libelle: "Art"}, {attribute: "passion.Fantastique", libelle: "Fantastique"}, {attribute: "passion.Jeux", libelle: "Jeux"}, {attribute: "passion.Sport", libelle: "Sport et Métier"}, {attribute: "passion.STEM", libelle: "STEM"}, {attribute: "passion.Vhicules", libelle: "Véhicules"}, {attribute: "passion.Voyage", libelle: "Voyage"}]
    }else{
        filter_passion = [{attribute: "passion.Animaux", libelle: "Animales"},  {attribute: "passion.Art", libelle: "Arte"}, {attribute: "passion.Fantastique", libelle: "Fantástico"}, {attribute: "passion.Jeux", libelle: "Juegos"}, {attribute: "passion.Sport", libelle: "Deportes y profesiones"}, {attribute: "passion.STEM", libelle: "STEM"}, {attribute: "passion.Vhicules", libelle: "Vehículos"}, {attribute: "passion.Voyage", libelle: "Viaje"}]
    }
    return (
        <div className="game_game">
            <section 
                className="game wishlist"
                id="game_wishlist"
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: "1920px",
                    backgroundPosition: "top center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative',
                    display: spread ? 'block' : 'none'
                }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                { !preview && hover && props.toolbarSection }
                <div id="recap-container">
                    {
                        data.blocks.title.inputs.value.value || data.blocks.text.inputs.value.value ?
                            <div className="wrapper-text">
                                <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
                                <Pg data={data} preview={preview} component={id} block={'text'} inputCallback={inputCallback} nopadding={true} />
                            </div>
                        : 
                        <div className="wrapper-img">
                            {
                                props.lang === "fr_FR" ?
                                    <picture>
                                        <source media="(min-width: 767px)" srcset={BandeauText} />
                                        <source media="(min-width: 600px)" srcset={BandeauTextTablet} />
                                        <img src={BandeauTextMobile} alt={data.blocks.title.inputs.value.value} />
                                    </picture>
                                :
                                    <picture>
                                        <source media="(min-width: 767px)" srcset={BandeauTextES} />
                                        <source media="(min-width: 600px)" srcset={BandeauTextTabletES} />
                                        <img src={BandeauTextMobileES} alt={data.blocks.title.inputs.value.value} />
                                    </picture>

                            }
                        </div>
                    }
                    <div className="wrapper-recap">
                        <img className="absolute top-left" src={TopLeftImage} />
                        <img className="absolute bottom-right" src={BottomRightImage} />
                        <div className="wrapper-elements">
                            <p style={{color:'#013432',textAlign:"center",fontSize: 16}}>{props.lang === "fr_FR" ? "Produits sélectionnés dans ma liste" : "Productos seleccionados en mi lista"}</p>
                            <div className="recap" style={{marginBottom: 16}}>
                                { recapContent() }
                            </div>
                            <Button onClick={() => registerWishlist()} data={data} preview={preview} component={id} block={'button'} className="button" inputCallback={inputCallback} nopadding={true} disabled={listSelectedProduct.length < maxItem ? true : false}/>
                        </div>
                    </div>
                    {/* {
                        showLimit ?
                            <p style={{color:'#E6CB60',textAlign:"center"}}>Votre liste est complète</p>
                        : null
                    } */}
                </div>
                <div className="wrapper-wishlist">
                    <div className="filters">
                        <div className="filter-wrapper">
                            <label>{props.lang === "fr_FR" ? "Thèmes" : "Temas"} :</label>
                            <select value={currentCat} onChange={(event) => setCurrentCat(event.target.value)}>
                                <option value={"-1"}>{props.lang === "fr_FR" ? "Tous les thèmes" : "Todos los temas"}</option>
                                {
                                    newCategories.map((categorie, index) => {
                                        return(
                                            <option key={index} value={categorie.id}>{categorie.attributes.category_name[props.lang]}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="filter-wrapper">
                            <label>{props.lang === "fr_FR" ? "Âge" : "Edad"} :</label>
                            <select value={ageFilter} onChange={(event) => setAgeFilter(event.target.value)}>
                                <option value={"-1"}>{props.lang === "fr_FR" ? "Tous les âges" : "Todas las edades"}</option>
                                {
                                    filter_age.map((filter, index) => {
                                        return(
                                            <option key={index} value={filter.attribute}>{filter.libelle}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="filter-wrapper">
                            <label>{props.lang === "fr_FR" ? "Intérêt" : "Interés"} :</label>
                            <select value={currentPassion} onChange={(event) => setCurrentPassion(event.target.value)}>
                                <option value={"-1"}>{props.lang === "fr_FR" ? "Toutes les passions" : "Todas las pasiones"}</option>
                                {
                                    filter_passion.map((filter, index) => {
                                        return(
                                            <option key={index} value={filter.attribute}>{filter.libelle}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className={listProducts.length > 0 ? "wishlist-container" : "wishlist-container no-product"} id="wishlist-container">
                        { 
                            loading ? 
                                <div className="wrapper-loading" style={{maxWidth: "100%", flex:1, display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <img src={LoadingSVG} style={{maxWidth: "250px"}}/>
                                </div>
                            :  
                                listProducts.length > 0 ?
                                    listProducts.map((product, index) => (
                                        <div key={index} className="item-product">
                                            <div 
                                                // onClick={() => handleTogglePopup(product, `product-${index}`)} 
                                                onClick={() => handleAddProduct(product)}
                                                className="products" 
                                                id={`product-${index}`}
                                            >
                                                <div className="container_image">
                                                    {
                                                        product.categories.find(e => e === idCity.id) ? 
                                                            <img className="absolute-corner-image" src={LEGOCity} />
                                                        :
                                                        null
                                                    }
                                                    {
                                                        product.categories.find(e => e === idFriends.id) ? 
                                                            <img className="absolute-corner-image" src={LEGOFriends} />
                                                        :
                                                        null
                                                    }
                                                    <img src={`${process.env.REACT_APP_MEDIAS}/${product.attributes.product_image.fr_FR}`} />
                                                </div>
                                                <p className="name-product">{product.attributes.product_name[props.lang]}</p>
                                                <span className="ref-product">REF: {product.sku}</span>
                                            </div>
                                            <button className={product.categories.find(e => e === idCity.id) ? "blue" : product.categories.find(e => e === idFriends.id) ? "purple" : null} onClick={() => handleAddProduct(product)} disabled={showLimit || listSelectedProduct.some(e => e.id === product.id) ? "disabled" : false}>{listSelectedProduct.some(e => e.id === product.id) ? props.lang === "fr_FR" ? "Ajouté" : "Adicional" : props.lang === "fr_FR" ? "Ajouter" : "Agregar"}</button>
                                        </div>
                                    ))
                                : 
                                <div className="wrapper-no-product">
                                    <p>{props.lang === "fr_FR" ? "Aucun produit ne correspond à vos filtres" : "Ningún producto coincide con sus filtros"}</p>
                                    <button className="button buttonReset" onClick={() => {setCurrentCat("-1");setAgeFilter("-1");setCurrentPassion("-1")}}>{props.lang === "fr_FR" ? "Retirer les filtres" : "Eliminar filtros"}</button>
                                </div>
                        }
                    </div>
                    {
                        totalPages > 0 ?  
                            <div className="wrapper-pagination">
                                <Pagination 
                                    count={totalPages} 
                                    page={currentPage} 
                                    // defaultPage={currentPage}
                                    onChange={(event, page) => {
                                        setCurrentPage(page);
                                    }} 
                                />
                            </div>
                      : null
                    }
                    
                </div>
                {
                    openPopup && activeProduct ? (
                        <div className="popup descriptionProduct" id="descriptionPopup">
                            <div className="overlay"></div>
                            <div className="popup_content" style={{top: offsetTop ? `${offsetTop}px` : null, bottom: offsetBottom ? `${offsetBottom > 150 ? offsetBottom : 150}px` : null}}>
                                <div className="return" onClick={() => handleTogglePopup()}>&lt; Retour</div>
                                <img src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.product_image[props.lang]}`} />
                                <img src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.image_tittle[props.lang]}`} />
                                <div className="swiper-container">
                                    <div className="swiper-wrapper">
                                        {
                                            activeProduct.attributes.image_1?.[props.lang] ? <div className="swiper-slide"><img src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.image_1[props.lang]}`}/></div> : null
                                        }
                                        {
                                            activeProduct.attributes.image_2?.[props.lang] ? <div className="swiper-slide"><img src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.image_2[props.lang]}`}/></div> : null
                                        }
                                        {
                                            activeProduct.attributes.image_3?.[props.lang] ? <div className="swiper-slide"><img src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.image_3[props.lang]}`}/></div> : null
                                        }
                                        {
                                            activeProduct.attributes.image_4?.[props.lang] ? <div className="swiper-slide"><img src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.image_4[props.lang]}`}/></div> : null
                                        }
                                        {
                                            activeProduct.attributes.image_5?.[props.lang] ? <div className="swiper-slide"><img src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.image_5[props.lang]}`}/></div> : null
                                        }
                                        {
                                            activeProduct.attributes.image_6?.[props.lang] ? <div className="swiper-slide"><img src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.image_6[props.lang]}`}/></div> : null
                                        }
                                        {
                                            activeProduct.attributes.image_7?.[props.lang] ? <div className="swiper-slide"><img src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.image_7[props.lang]}`}/></div> : null
                                        }
                                        {
                                            activeProduct.attributes.image_8?.[props.lang] ? <div className="swiper-slide"><img src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.image_8[props.lang]}`}/></div> : null
                                        }
                                        {
                                            activeProduct.attributes.video_1?.[props.lang] ? (
                                                <div className="swiper-slide">
                                                    <video autoPlay={true} muted={true} loop={true} ref={vidRef1}>
                                                        <source src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.video_1[props.lang]}`} type="video/mp4"/>
                                                    </video>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            activeProduct.attributes.video_2?.[props.lang] ? (
                                                <div className="swiper-slide">
                                                    <video autoPlay={true} muted={true} loop={true} ref={vidRef2}>
                                                        <source src={`${process.env.REACT_APP_MEDIAS}/${activeProduct.attributes.video_2[props.lang]}`} type="video/mp4"/>
                                                    </video>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                    <div className="swiper-scrollbar"></div>
                                    <div className="swiper-button-prev"></div>
                                    <div className="swiper-button-next"></div>
                                </div>
                                <div dangerouslySetInnerHTML={{__html: activeProduct.attributes.product_description[props.lang]}} />
                                <button onClick={() => {handleAddProduct(activeProduct); handleTogglePopup();}} disabled={showLimit || listSelectedProduct.some(e => e.id === activeProduct.id) ? "disabled" : false}>{listSelectedProduct.some(e => e.id === activeProduct.id) ? "Ajouté" : "Ajouter à ma liste"}</button>
                            </div>
                        </div>
                    ) : null
                }
            </section>
        </div>
    );
};

GameWishlist.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default GameWishlist;