import {
    withStyles
} from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from "react";

const Custom = withStyles((theme) => ({
    root: {
        '&': {
            width: '100%',
            color: '#fff',
            // marginBottom: theme.spacing(2),
            backgroundColor: '#fff',
            marginBottom: 20,
            borderRadius: 0,
            '& > div':{
                borderRadius: 0
            }
        },
    },
}))(TextField);

export default (props) => {
    const [loading, setLoading] = useState(false);

    const input = (
        <>
            <Typography gutterBottom style={{fontSize: 14}}>
                { props.label }
            </Typography>
            <Custom {...props} label={false} variant="outlined" />
        </>
    );

    if (!props.translate || process.env.REACT_APP_ENABLE_TRANSLATION !== 'true')
        return input;

    return (
        <div>
            <div className={"translate-btn over"} onClick={async () => {
                setLoading(true);

                const key = process.env.REACT_APP_TRANSLATION_KEY;
                const target = 'en';
                const q = encodeURI(props.value);

                try {
                    let response = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${key}&target=${target}&q=${q}`);

                    response = await response.json();
        
                    let translation = response?.data?.translations?.[0]?.translatedText;
        
                    if (translation)
                        props.onChange(translation);
                } catch(e) {
                    console.err(e?.message ?? e);
                } finally {
                    setLoading(false);
                }
            }}>
                <img src="/img/flag-en.png" className={`${loading ? 'rotate' : ''}`} />
            </div>

           { input }
        </div>
    );
};