import React from 'react';
import { withRouter } from 'react-router';
import { Box, Grid } from '@mui/material'
import { connect } from "react-redux";
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import TopPanel from '../../../layouts/TopPanel/TopPanel'
import Typography from '../../../ui/typography/Typography';
import { toggleExpandedForAll } from 'react-sortable-tree';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TreeView from '../../../ui/tree-view/TreeView'
import { withApollo } from '@apollo/client/react/hoc';
import { GET_MEDIA_CATEGORIES, ADD_MEDIA_CATEGORY, UPDATE_MEDIA_CATEGORY, DELETE_MEDIA_CATEGORY } from '../../../../queries/mediaCategories';
import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import mediasCategoriesConfig from './config/mediasCategories.config'
import DialogModal from '../../../ui/dialog/DialogModal';
import { ALLOWED, VIDEOS, IMAGES } from '../../../../js/constants/medias-types';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import { getParams } from '../../../../js/utils/getParams';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import SearchBar from '../../../ui/search/SearchBar';

const MediaContainer = styled(Grid)`
    background-color:white;
    display: flex;
    flex-wrap: wrap;
    &>.MuiGrid-item{
        height: 65vh;
        overflow: auto;
    }
`;

const PageWrapper = styled(Box)`
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
`;

class MediasCategories extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            openForm: this.props.history.location.state?.openForm ? this.props.history.location.state.openForm : false,
            openMediaForm: this.props.history.location.state?.openMediaForm ? this.props.history.location.state.openMediaForm : false,
            dataLayout: null,
            identifier: '',
            parent: [],
            parentCat: '',
            formImage: null,
            fileName: null,
            fileAlt: 'Image',
            treeCats: [],
            medias: null,
            /*allMedias       : null,*/
            filterByType: this.props.history.location.state?.types ? this.props.history.location.state.types : ALLOWED,
            openModal: false,
            buttonAvailable: this.props.history.location.state?.buttonAvailable ? false : true,
            toDelete: null,
            openDeleteModal: false,
            selectedNodes: [],
            formAction: 'add',
            currentId: '',
            openDeleteCatModal: false,
            loading: false,
            page: 1,
            nbperpage: 8,
            countPage: 0,
            cursor: null,

        };
        this.cats = [
            {
                libelle: 'All',
                type: 'all',
            },
            {
                libelle: 'Images',
                type: IMAGES,
            },
            {
                libelle: 'Vidéos',
                type: VIDEOS,
            },
            /*{
                libelle:'360°',
                type:[],
            },
            {
                libelle:'Audio',
                type:[],
            }*/
        ]

    }


    /*UTILS*/

    copyArrayOfObjects = array => array.map(a => ({ ...a }));

    resetState() {
        this.setState({
            cat_id: '',
            identifier: '',
            parentCat: '',
            parent: [],
            action: '',
            formAction: '',
            toDelete: null,
            fileName: '',
            fileAlt: ''
        });
    }

    handleDeleteCatModal = (node = null) => {
        if (node) {
            this.setState({
                currentId: node.id ?? node
            })
        }
        this.setState({
            openDeleteCatModal: !this.state.openDeleteCatModal ?? false
        })
    }

    /*GET CATEGORIES + TREE*/

    prepareTree() {
        this.props.client.query({
            query: GET_MEDIA_CATEGORIES,
            fetchPolicy: 'no-cache'
        }).then(result => {
            let cats = result.data.mediaObjectCategories.edges;
            // let tree  = [];
            let data = cats.filter(e => e.node.parent === null);

            this.setState({ categoriesData: cats, defaultRoot: data });

            if (getParams(this.props).includes('formOpen') || this.props.history.location?.state?.formOpen) {
                this.addCategory()
            }

            for (let parent of data) {
                this.convertToNode(parent, true);
                this.populateChildren(cats, parent);
            }

            this.setState({
                treeData: toggleExpandedForAll({
                    treeData: this.copyArrayOfObjects(data)[0].children,
                    expanded: false
                }),
                treeCats: this.copyArrayOfObjects(data)
            });
        }).catch((err) => {
            console.log(err)
        });
    }

    convertToNode(data, isRoot = false) {
        data.title = data.node.libelle;
        data.isDirectory = true;
        data.isRoot = isRoot;
        data.dragDisabled = true;
        data.expanded = true;
        data.id = data.node.id;
        data.libelle = data.node.libelle;
        data.parent = data.node.parent;
    }

    populateChildren(cats, parent) {
        parent.children = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);

        for (let child of parent.children) {
            this.convertToNode(child);
            this.populateChildren(cats, child);
        }
    }



    /*LAYOUT FORM*/

    handleToggleDrawer(form) {
        if (form === 'addMediaCatForm') {
            this.setState({
                openForm: !this.state.openForm ?? false,
                buttonAvailable: !this.state.buttonAvailable ?? null
            });
        }

    }

    handleInputChange = (stateName, evt) => {
        const value = evt?.target?.value ?? evt;

        this.setState({
            ...this.state,
            [stateName]: value
        });
    };



    addCategory = () => {
        this.resetState();
        let root = this.state.categoriesData.find(cat => cat.node.parent === null)

        this.setState({
            parentCat: root.node.id ?? null
        }, () => this.handleToggleDrawer('addMediaCatForm'));
    }

    editCategory = (nodeInfo) => {

        this.resetState();

        this.setState({
            formAction: 'edit',
            parentCat: nodeInfo.node.parent?.id,
            identifier: nodeInfo.node.libelle,
            currentId: nodeInfo.node.id

        }, () => {
            this.handleToggleDrawer('addMediaCatForm');
        })
    }

    addSubcategory = (nodeInfo) => {
        this.resetState();

        this.handleToggleDrawer('addMediaCatForm');
        this.setState({
            parentCat: nodeInfo.id ?? null,
            dataLayout: mediasCategoriesConfig(this.state.categoriesData, nodeInfo.id) ?? null
        });


    };



    /*MUTATION*/
    handleError = (e) => {
        this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.error"));

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async (action) => {
        await this.prepareTree();
        if (action === 'edit')
            this.props.snack(ALERT_SUCCESS, this.props.t("medias.categories.categoryUpdated"));

        if (action === 'add')
            this.props.snack(ALERT_SUCCESS, this.props.t("medias.categories.categoryAdded"));

        if (action === 'delete') {
            this.handleDeleteCatModal();
            this.props.snack(ALERT_SUCCESS, this.props.t("medias.categories.categoryDeleted"));
        } else {
            this.handleToggleDrawer('addMediaCatForm');
        }
        this.resetState();
        this.props.stopLoading();
    };

    handlerMutation = async (action = 'add') => {
        try {
            let query = null;
            let variables = null;

            this.props.startLoading();

            if (action === 'edit') {
                query = UPDATE_MEDIA_CATEGORY;

                variables = {
                    libelle: this.state.identifier,
                    parent: this.state.parentCat,
                    id: this.state.currentId
                };
            }
            else if (action === 'delete') {
                query = DELETE_MEDIA_CATEGORY;
                variables = {
                    id: this.state.currentId
                };
            }
            else {
                query = ADD_MEDIA_CATEGORY;

                variables = {
                    libelle: this.state.identifier,
                    parent: this.state.parentCat !== 'root' && this.state.parentCat !== '' ? this.state.parentCat : null
                };
            }

            await this.props.client.mutate({
                mutation: query,
                variables,
            });

            await this.prepareTree();

            this.handleSuccess(action);
        } catch (e) {
            this.handleError(e);
        }
    };
    expand = (expanded) => {
        this.setState({
            treeData: toggleExpandedForAll({
                treeData: this.state.treeData,
                expanded,
            }) ?? null,
        });
    };

    async preparePage() {
        this.prepareTree();
        this.setState({ ready: true });
    }
    /*COMPONENTS*/

    componentDidMount() {
        checkRouting(this.props);
        this.preparePage();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.formImage !== this.state.formImage) {

            let name = this.state.formImage?.file?.name.replace(/ /g, "_").toLowerCase();
            this.setState({
                fileName: name ?? null
            })
        }
    }

    render() {
        return (
            <PageWrapper>
                <TopPanel
                    icomoon="picto-media"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={'Gérer les catégories'}
                    subtitle={'Gestion de vos catégories (Créer, modifier, supprimer)'}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    handlerAdd={this.addCategory}
                    textAdd={'+ Créer'}
                    buttonAvailable={this.state.treeData ? this.state.buttonAvailable : false}
                    hasBorder={true}
                />

                <Box style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    display: 'grid',
                gridTemplateRows: 'auto 1fr',
                gridTemplateColumns: '1fr',
                }}>
                    <AccordionCustom title={'Filtres et Recherche'} forcedExpanded={true} detailsStyles={{
                        padding: 16,
                    }}>
                        <SearchBar
                            noIcon={true}
                            value={this.state.searchString}
                            placeholder={'Recherche'}
                            onChange={(e) => this.handleInputChange("searchString", e)}
                        />
                    </AccordionCustom>
                    {this.state.treeData
                        ? (
                            <Grid container direction={'column'} xs={12} style={{
                                marginTop: 18,
                                height: 'auto',
                            }}>
                                <TreeView
                                    typeOfTree={'categorie'}
                                    dataTree={this.state.treeData}
                                    onChange={treeData => this.setState({ treeData })}
                                    canDrag={false}
                                    canDrop={false}
                                    addSubcategory={this.addSubcategory}
                                    expand={this.expand}
                                    searchQuery={this.state.searchString}
                                    handleChangeCategorie={this.handleChangeCategorie}
                                    editCat={this.editCategory}
                                    deleteCat={this.handleDeleteCatModal}
                                    handleDelete={this.handleDeleteCatModal}
                                    marginTop={true}
                                    // allButton={
                                    //     () => {
                                    //         this.setState({
                                    //             filterByCategory: null,
                                    //             selectedNodeId: null
                                    //         });
                                    //         this.filterByType(this.state.filterByType)
                                    //     }
                                    // }
                                    canModify={true}
                                    canAdd={true}
                                    canDelete={true}
                                />

                            </Grid>
                        )
                        : (
                            <PageLoader />
                        )}
                </Box>

                {this.state.openForm ?
                    <LayoutFormBuilder
                        isSublayout={false}
                        icomoon="ico-ajouter-categorie"
                        opened={this.state.openForm}
                        forClose={() => { this.handleToggleDrawer('addMediaCatForm') }}
                        dataLayout={mediasCategoriesConfig(this.copyArrayOfObjects(this.state.categoriesData), this.state.parentCat, this.state.formAction)}
                        allState={this.state}
                        stateCallback={this.handleInputChange}
                        handlerMutation={this.state.formAction === 'edit' 
                            ? () => { this.handlerMutation('edit') } 
                            : () => { this.handlerMutation('add') }
                        }
                        validateButton={true}
                    // deleteMutation={this.state.formAction === 'edit' ? this.handleDeleteCatModal : null}
                    // deleteText={this.state.formAction === 'edit' ? this.props.t("medias.categories.deleteCategory") : null}
                    />
                    : null}

                <DialogModal
                    icon={true}
                    type='delete'
                    windowWidth={this.props.windowWidth}
                    open={this.state.openDeleteCatModal}
                    secondaryAction={() => { this.handleDeleteCatModal() }}
                    primaryAction={() => { this.handlerMutation('delete') }}
                    title={this.props.t("medias.categories.deleteThisCategory")}
                >
                    <Typography variant="body2">
                        {this.props.t("medias.categories.sureToDelete")} <strong>{this.props.t("medias.categories.irreversibleAction")}</strong>
                    </Typography>
                </DialogModal>
            </PageWrapper>
        );
    }

    copyArrayOfObjects = array => array.map(a => ({ ...a })); // be careful, only breaks references at objects level

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};
export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(MediasCategories))));
