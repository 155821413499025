import React from 'react';
import { Grid } from '@mui/material';
import CardMinisite from './CardMinisite';
import CardFeeds from './CardFeed';

export default class CardByAssetType extends React.Component{

    renderSwitch(param){
        switch(param.assetType.identifier){
            case 'flipbook':
            case 'flipbook_with_cart':
            case 'newsletter':
            case 'minisite':
            case 'jeu':
                return <CardMinisite data={this.props.asset} isFlipbook={this.props.isFlipbook}/>
            // case 'magento':
            // case 'shopify':
            //     return null;
            case 'facebook':
            case 'instagram':
            case 'linkedin':
                return <CardFeeds data={this.props.asset} pagination={this.props.pagination} loader={this.props.loader} handleLoad={this.props.handleLoad} handleAdd={this.props.handleAdd} posts={this.props.posts ? this.props.posts : null} handleDelete={this.props.handleDelete} handleEdit={this.props.handleEdit}/>
            case 'twitter':
                return <CardFeeds data={this.props.asset} handleAdd={this.props.handleAdd} posts={this.props.posts ? this.props.posts : null} textAdd={'Ajouter un Tweet'} handleDelete={this.props.handleDelete} handleEdit={this.props.handleEdit}/>
            case 'wordpress':
                return <CardFeeds data={this.props.asset} handleAdd={this.props.handleAdd} posts={this.props.posts ? this.props.posts : null} textAdd={'Ajouter un post'} handleDelete={this.props.handleDelete} handleEdit={this.props.handleEdit}/>
            default:
                return null;
        }
    }
    render() {
        return (
            <Grid container>
                <Grid container direction="row" justifyContent="center">
                    {this.renderSwitch(this.props.asset)}
 
                </Grid>                
            </Grid>

        );
    }
}