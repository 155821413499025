import React, {Component} from 'react';
import {SortableContainer, SortableElement, sortableHandle} from 'react-ordering';
import arrayMove from 'array-move';
import { TableBody,TableRow, TableCell, Typography, Grid } from '@mui/material';
import styled from 'styled-components';
import { getTraductionAttributs } from '../../../../../js/utils/functions';
import NoImage from '../../../../../assets/images/not-found.png';
import colors from '../../../../../config/theme/colors';
 

const GridCustom = styled(Grid)`
    border: none;
    padding: 6px;
`;

const TableRowCustom = styled.div`
    z-index: 9999;
    background: white;
    display: flex;
    margin: 10px;
    width: calc(100%/3 - 20px);
    align-items: center;
    // border: 1px solid ${colors.grey.regular};
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    img{
      width: 100%;
    }
`;

const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export class SortableComponent extends Component {
  state = {
    items: this.props.listItems,
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }),() => {
      this.props.stateCallback('listItems', this.state.items)
      this.props.stateCallback('changeInMerch', true)
    })
  }
  render () {

    const SortableItem = SortableElement(
      ({value, index}) =>{
        let getName = getTraductionAttributs('product_name', value.product.productDatas.edges, this.props.currentLang);
        let getImage = getTraductionAttributs('product_image', value.product.productDatas.edges, this.props.currentLang, 'image');
        return (
          <TableRowCustom item xs={3} key={`row-product-${index}`} className="sortable-item">
            <GridCustom item align="center">
                {
                    getImage && getImage !== '' ? 
                        (<img src={`${process.env.REACT_APP_MEDIAS}/${getImage.filePath}`} alt={`image-${getName}-${index}`} style={{maxWidth: '100%', maxHeight: 40, display: 'inline-block',verticalAlign: 'middle'}}/>) 
                    : 
                        (<img src={NoImage} alt={`image-${getName}-${index}`} style={{maxWidth: '100%', maxHeight: 40, display: 'inline-block',verticalAlign: 'middle'}}/>)
                }
            </GridCustom>
            <GridCustom >
                <Typography variant="body2">{value.product.sku}</Typography>
            </GridCustom>
            <GridCustom style={{minWidth: 150, flex:1}}>
                <Title variant="body1">{getName || 'N/A'}</Title>
            </GridCustom>
          </TableRowCustom>
        )
      }
    )
 
    const SortableList = SortableContainer(({items}) => {
      return (
        <Grid container className="sortableHelper">
          {items.map((value, index) => {
            return(
              <SortableItem key={`item-${value.node.product.sku}`} index={index} value={value.node} />
            )
          }
          )}
        </Grid>
      )
    })
    return (<SortableList items={this.state.items} onSortEnd={this.onSortEnd} axis={"xy"}/>)
  }
}