export const SYSTEMS = {
  CURRENCY: 'currency',
  LANG: 'lang',
  TAX: 'tax',
}

export const CURRENCIES = [
  {
    code: "EURO",
    libelle: 'Euro'
  },
  {
    code: "FRANC_SUISSE",
    libelle: 'Franc Suisse'
  },
  {
    code: "POUND",
    libelle: 'Livre'
  },
  {
    code: "YEN",
    libelle: 'Yen'
  },
  {
    code: "DOLLAR",
    libelle: 'Dollar'
  },
]

export const LOCALES = [
  {
    code: "fr_FR",
    libelle: 'Français'
  },
  {
    code: "en_US",
    libelle: 'Anglais'
  },
  {
    code: "es_ES",
    libelle: 'Espagnol'
  },
  {
    code: "de_DE",
    libelle: 'Allemand'
  },
  {
    code: "it_IT",
    libelle: 'Italien'
  },
  {
    code: "pt_PT",
    libelle: 'Portugais'
  }
]