import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import AccordionCustom from "../../../layouts/Accordion/AccordionCustom"
import OurTypography from "../../../ui/typography/Typography"
import ReactEcharts from "echarts-for-react"
import BarCharts from "../../../layouts/Stats/BarCharts"
import styled from "styled-components"
import { CustomChip } from "../stats/components/styledComponents"
import colors from "../../../../config/theme/colors"
import TopPanel from "../../../layouts/TopPanel/TopPanel"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from "react-redux"
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types"
import InputBuilder from "../../../ui/form/InputBuilder"
import { useEffect, useState } from "react"
import { getUserBrands } from "../../../../queries/brands"
import { FormInputTypes } from "../../../../builder/shareable/types"
import moment from "moment"
import { getTradingPartners } from "./utils"
import { getProductWorkflows, getTotalTaskByUser } from "../../../../queries/product_workflow"

const DataCard = styled(Box)`
    border: 1px solid #C9CBCC80;
    width: 20%;
    background: ${colors.white.regular};
    padding: 16px;
    display: flex;
    flex-direction: column;

    border: ${props => props.isActive ? `1px solid ${colors.blue.darker.hue300}` : `1px solid ${colors.grey.border}`};
    background-color: ${props => props.isActive ? `${colors.blue.lighter.hue900}` : `${colors.white.regular}`} ;
    & > p {
        color: ${props => props.isActive ? `${colors.blue.darker.hue300}` : `${colors.black.regular}`};
    }
    /* &:hover {
        border: 1px solid ${colors.blue.darker.hue300};
        background-color: ${colors.blue.lighter.hue900} ;

        & > p {
            color: ${colors.blue.darker.hue300} !important;
        }
    } */
`;


const CustomLegend = styled(Box)`
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: ${props => props.backgroundColor};
`;


const WorkflowDashboard = props=>{
    const [brands,setBrands]= useState([])
    const [tradingPartners,setTradingPartners]= useState([])
    const [tasks,setTasks]= useState(null)
    const [productCount,setProductCount]= useState(null)
    const [loading,setLoading]= useState(false)
    const [products,setProducts]= useState([])
    const [state,setState]= useState({
        currentBrand:" ",
        currentChannel:" ",
    })

    const getBrands = ()=>{
        props.client.query({
          query: getUserBrands,
          variables: {
              user:props.user.id
          }
        }).then(result => {
            setBrands(result.data.productSources.edges)
        }).catch((err)=>{
          console.log(err)
        })
      }

      const getTotalTask = ()=>{
        props.client.query({
          query: getTotalTaskByUser,
          variables: {
          }
        }).then(result => {
            setTasks(result.data.productWorkflows)
        }).catch((err)=>{
          console.log(err)
        })
      }

      const getProducts = ()=>{
        setLoading(true)
        props.client.query({
          query: getProductWorkflows,
          variables: {
            "source": state.currentBrand === " " ?null :Number(state.currentBrand?.replace('/api/product-sources/',' ')),
            "channel":state.currentChannel === " " ? null:state.currentChannel,
          }
        }).then(result => {
            setProducts(result.data.productWorkflows.edges)
            setProductCount(result.data.productWorkflows.totalCount)
            setLoading(false)
        }).catch((err)=>{
          console.log(err)
        })
      }

      useEffect(()=>{
        getBrands()
        getTotalTask()
        getProducts()
        getTradingPartners({client:props.client,id:props.user.id}).then((result)=>{
            setTradingPartners(result)
        }).catch((err)=>{
            console.log(err)
        })
      },[])

      useEffect(()=>{
        getProducts()
      },[state])

    const config = {
        charts: {
            first: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    show: false
                },
                series: [
                    {
                    // name: 'Access From',
                    type: 'pie',
                    color:  [colors.blue.regularv2, colors.blue.darker.hue300],
                    radius: ['100%', '65%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: products.filter(e=>e.node.user !==null).length, name: 'Assignés' },
                        { value: products.filter(e=>e.node.productWorkflowLogs.edges.length > 0).length, name: 'En erreur' },
                    ]
                    }
                ]
            },
            second: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    show: false
                },
                series: [
                    {
                    type: 'pie',
                    color:  [colors.blue.regularv2, colors.blue.darker.hue300],
                    radius: ['100%', '65%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: products.filter(e=>e.node.importState === 1).length, name: 'Accepté' },
                        { value: products.filter(e=>e.node.importState === 0).length, name: 'Rejeté' },
                    ]
                    }
                ]
            },
            third: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    show: false
                },
                series: [
                    {
                    type: 'pie',
                    color:  [colors.blue.regularv2, colors.blue.darker.hue300, colors.blue.lighter.hue600, colors.blue.lighter.hue900],
                    radius: ['100%', '65%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: products.filter(e=>e.node.retailerState === 2).length, name: 'Envoyé' },
                        { value: products.filter(e=>e.node.retailerState === 1).length, name: 'Reviewed' },
                        { value: products.filter(e=>e.node.retailerState === 0).length, name: 'Synchonized' },
                    ]
                    }
                ]
            },
            bars: {
                y: [{
                    data:[
                        {
                            value: tasks?.edges?.filter(e=>e.node.user).length,
                            itemStyle: {
                                color: colors.blue.regularv2
                            }
                        },
                        {
                            value: tasks?.edges.filter(e=>!e.node.user).length,
                            itemStyle: {
                                color: colors.blue.darker.hue300
                            },
                        },
                    ],
                    noLabelName :true
                }],
                x: ["Assignées", "Non assignées"]
            }
        }
    }

    const goTo = route => props.history.push(route);

    return (
        <div>
            <TopPanel
                title={props.t("drawer.workflow_dashboard")}
                subtitle={"Liste de vos produit a assigner"}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                windowWidth={props.windowWidth}
                locales={props.locales}
                hasBorder={true}
                // buttonAvailable={state.readyForCat && state.ready}
                alignItems={true}                    
            />    
            <Grid container>
                <AccordionCustom 
                    defaultExpanded={true} 
                    forcedExpanded={true} 
                    isMenubar={false} 
                    noIcon={true}  
                    title={
                        <Box display={"flex"}>
                        <InputBuilder 
                            xs={2} 
                            value={state["currentBrand"]} 
                            padding={"0px"} 
                            style={{margin:0}} 
                            input={
                                {
                                    xs: 2,
                                    required: false,
                                    disabled: false,
                                    defaultValue:" ",
                                    noBorder:true,
                                    type: FormInputTypes.SELECT_WITHOUT_BORDER,
                                    stateName: 'currentBrand',
                                    special: 'noborder',
                                    helper: {
                                        position: 'top',
                                        text:'Visualiser par :',
                                        link: false,
                                    },
                                    value: (()=>{
                                        let allBrands =!brands? []: brands?.map((brand,i)=>{
                                            return {
                                                value: brand.node.id,
                                                label: brand.node.libelle 
                                            }
                                        })
                                        allBrands.unshift({
                                            value: " ",
                                            label: 'Toutes les maisons'
                                        })
                                        return allBrands
                                    })() 
                                }
                            } 
                            stateCallback={(evt,type) => {
                                evt.stopPropagation()
                                setState({
                                    ...state,
                                    currentBrand: evt.target.value
                                })
                            }} 
                        />
                        {
                            loading?
                            <CircularProgress style={{marginLeft:5,color:colors.blue.darker.hue300}} size={20}/>
                            :null
                        }
                        </Box>
                    }
                >
                    {/* Chips */}
                    <Grid container alignItems='center' justifyContent={
                        props.windowWidth < 1250 ? 'space-between' : 'flex-start'
                    }>
                        <OurTypography fontweighttext={700} style={{
                            marginRight: props.windowWidth >= 1250 ? '90px' : 0,
                            fontSize:25
                        }}>{moment().format("dddd D MMMM YYYY")}</OurTypography>
                        <Grid container style={{gap: '10px', width: 'auto'}}>
                            <CustomChip  label={'Toutes les TD'} variant="outlined" isFocus={state.currentChannel === " "} onClick={(e) => { setState({...state,currentChannel:" "}) }} />
                            {
                                tradingPartners.length > 0 ?
                                    tradingPartners.map((tp,i)=>{
                                        return <CustomChip key={i} label={tp.node.libelle} variant="outlined" isFocus={state.currentChannel === tp.node.id} onClick={() => { setState({...state,currentChannel:tp.node.id}) }} />
                                    })
                                :null
                            }
                        </Grid>
                    </Grid>

                    {/* Data */}
                    <Grid container style={{
                        display:"flex",
                        justifyContent:"space-between",
                        flexWrap:"nowrap",
                        gap:20,
                        marginTop:30
                    }}>
                        <DataCard>
                            <OurTypography fontweighttext={700} fontsize={14} style={{marginBottom: '10px'}}>Total de produits</OurTypography>
                            <OurTypography fontweighttext={700} fontsize={40} style={{marginBottom: '15px'}}>130</OurTypography>
                            <OurTypography fontstyle={'italic'} colortext={colors.grey.lighter.hue600} fontsize={12} >Mise à jour à {moment().format("HH:mm:ss")}</OurTypography>
                        </DataCard>
                        <DataCard>
                            <OurTypography fontweighttext={700} fontsize={14} style={{marginBottom: '10px'}}>Produits PIM</OurTypography>
                            <OurTypography fontweighttext={700} fontsize={40} style={{marginBottom: '15px'}}>130</OurTypography>
                            <OurTypography fontstyle={'italic'} colortext={colors.grey.lighter.hue600} fontsize={12} >Mise à jour à {moment().format("HH:mm:ss")}</OurTypography>
                        </DataCard>
                        <DataCard>
                            <OurTypography fontweighttext={700} fontsize={14} style={{marginBottom: '10px'}}>Produits GAIA</OurTypography>
                            <OurTypography fontweighttext={700} fontsize={40} style={{marginBottom: '15px'}}>130</OurTypography>
                            <OurTypography fontstyle={'italic'} colortext={colors.grey.lighter.hue600} fontsize={12} >Mise à jour à {moment().format("HH:mm:ss")}</OurTypography>
                        </DataCard>
                        <DataCard>
                            <OurTypography fontweighttext={700} fontsize={14} style={{marginBottom: '10px'}}>Produits TP</OurTypography>
                            <OurTypography fontweighttext={700} fontsize={40} style={{marginBottom: '15px'}}>130</OurTypography>
                            <OurTypography fontstyle={'italic'} colortext={colors.grey.lighter.hue600} fontsize={12} >Mise à jour à {moment().format("HH:mm:ss")}</OurTypography>
                        </DataCard>
                        <DataCard isActive={true}>
                            <OurTypography fontweighttext={700} fontsize={14} style={{marginBottom: '10px'}}>Tâches a faire</OurTypography>
                            <OurTypography fontweighttext={700} fontsize={40} style={{marginBottom: '15px'}}>{tasks?.edges?.filter(e=>e.node.user).length}/{tasks?.totalCount}</OurTypography>
                            <OurTypography fontstyle={'italic'} colortext={colors.blue.darker.hue300} fontsize={12} >Mise à jour à {moment().format("HH:mm:ss")}</OurTypography>
                        </DataCard>
                    </Grid>

                    {/* Stats */}
                    <Grid container style={{
                        marginTop: '30px',
                        display:"grid",
                        gridTemplateColumns:"repeat(4,1fr)",
                        gap:50
                    }}>
                        <Grid item xs={12}>
                            <OurTypography fontweighttext={700} fontsize={25} style={{marginBottom: '10px'}}>Flux PIM</OurTypography>
                            <Box style={{minHeight: '65px'}}>
                                <Grid container style={{gap: '10px 20px',marginTop:10}}>
                                    <Box style={{ display: "inline-flex", gap: 8, alignItems: "center"}}>
                                        <CustomLegend backgroundColor={colors.blue.regularv2} />
                                        <Typography variant={"body1"} style={{ color: colors.black.regular }}>Assignées</Typography>
                                    </Box>
                                    <Box style={{ display: "inline-flex", gap: 8, alignItems: "center" }}>
                                        <CustomLegend backgroundColor={colors.blue.darker.hue300} />
                                        <Typography variant={"body1"} style={{ color: colors.black.regular }}>En erreur</Typography>
                                    </Box>
                                </Grid>
                            </Box>
                            {/* Stat */}
                            <Grid container style={{position: 'relative'}} direction={'column'}>
                                <ReactEcharts style={{
                                    height: '300px',
                                }} option={config.charts.first}/>

                                <OurTypography style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: colors.blue.darker.hue300,
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }} fontweighttext={700}
                                onClick={()=>goTo('/products/workflow/pim')}
                                >Voir plus</OurTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <OurTypography fontweighttext={700} fontsize={25} style={{marginBottom: '10px'}}>Flux GAIA</OurTypography>
                            <Box style={{minHeight: '65px'}}>
                                <Grid container style={{gap: '10px 20px',marginTop:10}}>
                                    <Box style={{ display: "inline-flex", gap: 8, alignItems: "center"}}>
                                        <CustomLegend backgroundColor={colors.blue.regularv2} />
                                        <Typography variant={"body1"} style={{ color: colors.black.regular }}>Accepté</Typography>
                                    </Box>
                                    <Box style={{ display: "inline-flex", gap: 8, alignItems: "center" }}>
                                        <CustomLegend backgroundColor={colors.blue.darker.hue300} />
                                        <Typography variant={"body1"} style={{ color: colors.black.regular }}>Rejeté</Typography>
                                    </Box>
                                </Grid>
                            </Box>
                            {/* Stat */}
                            <Grid container style={{position: 'relative'}} direction={'column'}>
                                <ReactEcharts style={{
                                    height: '300px',
                                }} option={config.charts.second}/>

                                <OurTypography style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: colors.blue.darker.hue300,
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }} fontweighttext={700}
                                onClick={()=>goTo('/products/workflow/gaia')}
                                >Voir plus</OurTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <OurTypography fontweighttext={700} fontsize={25} style={{marginBottom: '15px'}}>Flux TP</OurTypography>
                            <Box style={{minHeight: '65px'}}>
                                <Grid container style={{gap: '10px 30px',display:"grid",gridTemplateColumns:"repeat(2,1fr)",width:"fit-content",marginTop:10}}>
                                    <Box style={{ display: "inline-flex", gap: 8, alignItems: "center" }}>
                                        <CustomLegend backgroundColor={colors.blue.regularv2} />
                                        <Typography variant={"body1"} style={{ color: colors.black.regular }}>Envoyé</Typography>
                                    </Box>
                                    <Box style={{ display: "inline-flex", gap: 8, alignItems: "center" }}>
                                        <CustomLegend backgroundColor={colors.blue.darker.hue300} />
                                        <Typography variant={"body1"} style={{ color: colors.black.regular }}>Reviewed</Typography>
                                    </Box>
                                    <Box style={{ display: "inline-flex", gap: 8, alignItems: "center" }}>
                                        <CustomLegend backgroundColor={colors.blue.lighter.hue600} />
                                        <Typography variant={"body1"} style={{ color: colors.black.regular }}>Synchronized</Typography>
                                    </Box>
                                </Grid>
                            </Box>
                            {/* Stat */}
                            <Grid container style={{position: 'relative'}} direction={'column'}>
                                <ReactEcharts style={{
                                    height: '300px',
                                }} option={config.charts.third}/>

                                <OurTypography style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: colors.blue.darker.hue300,
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }} fontweighttext={700}
                                onClick={()=>goTo('/products/workflow/tp')}
                                >Voir plus</OurTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent='space-between'>
                                <OurTypography fontweighttext={700} fontsize={25} style={{marginBottom: '10px'}}>Activités</OurTypography>
                                <OurTypography style={{
                                    color: colors.blue.darker.hue300,
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }} fontsize={14} fontweighttext={700}>Tout voir</OurTypography>
                            </Grid>
                            <Box>
                                <Grid container style={{gap: '10px 20px'}}>
                                    <Box style={{ display: "inline-flex", gap: 8, alignItems: "center" }}>
                                        <CustomLegend backgroundColor={colors.blue.regularv2} />
                                        <Typography variant={"body1"} style={{ color: colors.black.regular }}>Assignées</Typography>
                                    </Box>
                                    <Box style={{ display: "inline-flex", gap: 8, alignItems: "center" }}>
                                        <CustomLegend backgroundColor={colors.blue.darker.hue300} />
                                        <Typography variant={"body1"} style={{ color: colors.black.regular }}>Non assignées</Typography>
                                    </Box>
                                </Grid>
                            </Box>
                            {/* Stat */} 
                            {
                                tasks?.edges ?
                                <BarCharts height={'300px'} xAxisData={config.charts.bars.x} yAxisData={config.charts.bars.y} hideLabel={true} fullHeight={true} fullWidth={true}/>
                                :null
                            }
                        </Grid>
                    </Grid>
                </AccordionCustom>
            </Grid>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales,
        user:state.user
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(WorkflowDashboard))));