import { useEffect, useState } from "react";
import { GridCustom, ButtonGroupCustom } from "../../styles/inputStyled";
import { Button } from '@mui/material';

const ButtonGroup = ({ allProps: props }) => {
    const [buttonGroupValue, setButtonGroupValue] = useState(props.input.type === 'buttonGroup' ? props.value : null);

    useEffect(() => {
        if (buttonGroupValue !== props.value) {
            props.handleButtonGroupChange(props.input.stateName, buttonGroupValue ?? props.value);
        }
    }, [buttonGroupValue]);

    const handleMenuItemClick = (event, value) => setButtonGroupValue(value);

    return (
        <GridCustom item xs={12}>
            <ButtonGroupCustom color="primary" aria-label="outlined primary button group">
                {props.input.value.map((option, index) =>
                (
                    <Button
                        key={index}
                        value={option.value}
                        selected={option.value === props.value}
                        className={option.value === props.value ? 'selected' : ''}
                        onClick={event => handleMenuItemClick(event, option.value)}
                        disabled={props.input.disabled ? true : false}
                    >
                        {option.label}
                    </Button>
                )
                )}
            </ButtonGroupCustom>
        </GridCustom>
    )
}

export default ButtonGroup;