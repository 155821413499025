export const productsTableMapper = async (flows,lang) =>{
    try {
        let results = flows.map((flow,index)=>{
            return {
                sku:flow.node.sku,
                id:flow.node.id,
                libelle: flow.node?.flatProducts?.edges[0]?.node.name,
                status: flow.node.status,
                catalogsNb:flow.node?.flatProducts.edges[0]?.node.catalogsNb,
                categoriesNb:flow.node?.flatProducts.edges[0]?.node.categoriesNb,
                childrenNb:flow.node?.flatProducts.edges[0]?.node.childrenNb,
                image:flow.node?.flatProducts.edges[0]?.node.image,
                imagesMaxNb:flow.node?.flatProducts.edges[0]?.node.imagesMaxNb,
                imagesNb:flow.node?.flatProducts.edges[0]?.node.imagesNb,
                completude:Math.round(flow.node?.flatProducts.edges[0]?.node.completude),
            }
        })
        return await results
    } catch (error) {
        console.log(error)   
    }
}

export const productsCardMapper = async (flows,lang) =>{
    try {
        let results = flows.map((flow,index)=>{
            
            return {
                sku:flow.node.sku,
                id:flow.node.id,
                libelle: flow.node?.flatProducts.edges[0]?.node.name,
                status: flow.node.status,
                catalogsNb:flow.node?.flatProducts.edges[0]?.node.catalogsNb,
                categoriesNb:flow.node?.flatProducts.edges[0]?.node.categoriesNb,
                childrenNb:flow.node?.flatProducts.edges[0]?.node.childrenNb,
                image:flow.node?.flatProducts.edges[0]?.node.image,
                imagesMaxNb:flow.node?.flatProducts.edges[0]?.node.imagesMaxNb,
                imagesNb:flow.node?.flatProducts.edges[0]?.node.imagesNb,
                completude:flow.node?.flatProducts.edges[0]?.node.completude,
            }
        })
        return await results
    } catch (error) {
        console.log(error)   
    }
}