import React, { useEffect, useState } from 'react';
import {Grid, Box} from '@mui/material';
import Stats from '../../../../layouts/Stats/Stats';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import Typography from '../../../../ui/typography/Typography';
import colors from '../../../../../config/theme/colors';
import CardCustom from '../../../../layouts/Card/CardCustom';
import PieCharts from '../../../../layouts/Stats/PieCharts';
import LineCharts from '../../../../layouts/Stats/LineCharts';
import ListStats from '../../../../layouts/Stats/ListStats';
import DataBar from '../../medias/components/DataBar';
import { eventService } from '../../../../../js/services/event.service';
import Auchan from '../../../../../assets/images/retailers/Auchan.png';
import { SNACK } from '../../../../../js/constants/action-types';

const selectDataPie = {
    type: 'select',
    isContainerized: true,
    required: false,
    stateName: 'selectedDataPie',
    label: '',
    helper: '',
    value: 
        [
            {
                value : [
                    {value: 213, name: 'Catégorie de produit 1'},
                    {value: 376, name: 'Catégorie de produit 2'},
                    {value: 230, name: 'Catégorie de produit 3'},
                    {value: 230, name: 'Catégorie de produit 4'},
                ],
                label : 'Zone'
            },
            {
                value : [
                    {value: 600, name: 'Test 1'},
                    {value: 945, name: 'Test 2'},
                    {value: 198, name: 'Test 3'},
                    {value: 310, name: 'Test 4'},
                ],
                label : 'Test1'
            }
        ]
};
var date = ['Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre'];
var allDatas = [
    {
        data: [
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000)],
        color: '#147FAB',
        title: 'Test1',
    },
    {
        data: [
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 3000)],
        color: '#E51C52',
        title: 'Test2',
    }
]

var productsStats = [
    {
        name: 'Produits-03',
        view: 320,
        price: 2116,
        dataColor: '#318FB5'
    },
    {
        name: 'Produits-04',
        view: 320,
        price: 2116,
        dataColor: '#878FAB'
    },
    {
        name: 'Produits-05',
        view: 320,
        price: 2116,
        dataColor: '#A1CCDD'
    },
    {
        name: 'Produits-06',
        view: 320,
        price: 2116,
        dataColor: '#D8D8E3'
    },
]
var retailersStats = [
    {
        name: 'Auchan',
        view: 320,
        price: 2116,
        dataColor: '#318FB5',
        img: Auchan
    },
    {
        name: 'Auchan',
        view: 320,
        price: 2116,
        dataColor: '#878FAB',
        img: Auchan
    },
    {
        name: 'Target',
        view: 320,
        price: 2116,
        dataColor: '#A1CCDD'
    },
    {
        name: 'Veepee',
        view: 320,
        price: 2116,
        dataColor: '#D8D8E3'
    },
]

const StatsCampaigns = props=>{
    const [filtered,setFiltered]= useState([])
    const [selectedDataPie,setSelectedDataPie]= useState(selectDataPie.value[0].value)
    const [stockageSize,setStockageSize]= useState(4)
    const [remainginSize,setRemainginSize]= useState(4)
    const [stockageCat,setStockageCat]= useState([
        {
            size:0.3,
            graphSize:0,
            name:'Fichiers Vidéos',
            graphColor:'#1A2543'
        },
        {
            size:1,
            graphSize:0,
            name:'Image',
            graphColor:'#58AFFD'
        },
        {
            size:2,
            graphSize:0,
            name:'Fichiers 360',
            graphColor:'#37DBE0'
        },

    ])
    const [stats,setStats]= useState([
        {
            'icon': 'picto-media',
            'data': 24,
            'dataColor': colors.pink.regular,
            'subtitle': 'Images uploadés',
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'notification':{
                'notificationColor': colors.green.regular
            }
        },
        {
            'icon': 'picto-3d',
            'data': 0,
            'dataColor': colors.pink.regular,
            'subtitle': 'Fichiers 3D uploadés',
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'notification':{
                'notificationColor': colors.red.darker
            }
        },
        {
            'icon': 'picto-video',
            'data': 1,
            'dataColor': colors.pink.regular,
            'subtitle': 'Vidéos uploadés',
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'notification':{
                'notificationColor': colors.orange.regular
            }
        },
        {
            'icon': 'picto-media',
            'data': '19/09/2022',
            'dataColor': colors.pink.regular,
            'subtitle': 'Dernier upload',
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'notification':{
                'notificationColor': colors.orange.regular
            }
        },
        {
            'icon': 'picto-produit',
            'data': 25,
            'dataColor': colors.pink.regular,
            'subtitle': 'Produits actifs',
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'notification':{
                'notificationColor': colors.green.regular
            }
        },
    ])

     const handleGetStorageSize = ()=>{
        let globalStockage=stockageSize;
        let files=stockageCat;
        let newList=[];
        let remainginSize=stockageSize;
        for (let file of files){
            let usedStockage=(100*file.size)/globalStockage;
            file['graphSize']=usedStockage;
            newList.push(file);
            remainginSize=Math.round((remainginSize-file.size)*100+Number.EPSILON)/100;
        }
        setStockageCat(newList)
        setRemainginSize(remainginSize)
    }

    useEffect(()=>{
        setFiltered(props.products)
        handleGetStorageSize();
    },[])

    const handleSelectPie = (event) => {
        setSelectedDataPie(event.target.value)
        eventService.fire();
    };

    const goTo = route => {
        props.history.push(route);
    };

    return (
        <div>
            <Typography variant="h1">
                    Mes statistiques - Par Campagne
            </Typography>
            <Typography variant="body1" component="div">
                <Box pt={3} pb={2}>
                    Dernières datas
                </Box>
            </Typography>
            <Grid container direction="row">
                <Grid container>
                    {this.stats.map((stat, index) =>
                        (
                            <Grid item lg={2} key={`statsGrid${index}`}>
                                <Stats stat={stat} />
                            </Grid>
                        )
                    )}
                </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="center" spacing={0}>
                <Grid container direction="row" spacing={4}>
                    <Grid item lg={7} md={7} xs={7}>
                        <Typography variant="body1" component="div">
                            <Box pt={3} pb={2}>
                                Activation par retailers
                            </Box>
                        </Typography>
                        <Box>
                            <CardCustom paddingtop={'48px'} contentpadding={'32px'}>
                                <LineCharts xAxisData={date} yAxisData={allDatas} handleSelectPie={this.handleSelectPie}/>
                            </CardCustom>
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={5} xs={5}>
                        <Typography variant="body1" component="div">
                            <Box pt={3} pb={2}>
                                Répartition par catégorie de produit
                            </Box>
                        </Typography>
                        <Box style={{position:'relative'}}>
                            <PieCharts selectedDataPie={this.state.selectedDataPie} selectDataPie={selectDataPie} handleSelectPie={this.handleSelectPie}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <DataBar datas={this.state.stockageCat} remaining={this.state.remainginSize}></DataBar>
            <Grid container direction="row" spacing={4}>
                <Grid item lg={6} md={6} xs={6}>
                    <Box>
                        <Typography variant="body1" component="div">
                            <Box pt={3} pb={2}>
                                Top produit
                            </Box>
                        </Typography>
                        <Box>
                            <CardCustom paddingtop={'32px'} contentpadding={'32px'}>
                                <ListStats listStats={productsStats}/>
                            </CardCustom>
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                    <Box>
                        <Typography variant="body1" component="div">
                            <Box pt={3} pb={2}>
                                Activation par retailer
                            </Box>
                        </Typography>
                        <Box>
                            <CardCustom paddingtop={'32px'} paddingbottom={'32px'} contentpadding={'32px'}>
                                <ListStats listStats={retailersStats}/>
                            </CardCustom>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatsCampaigns));
