import React from 'react';
import { makeStyles } from '@mui/styles';
import { withApollo } from '@apollo/client/react/hoc';
import SearchBarProductDynamic from '../../../components/ui/inputs/SearchBarProductDynamic';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 15
    },
}));

function ProductSelector ({ catalog, products, value, onChange }) {
    const classes = useStyles();

    const onSelect = (value) => {
        onChange(value);
    };

    return (
        <div className={classes.root}>
            <SearchBarProductDynamic 
                currentLang={'fr_FR'}
                onSelect={onSelect}
                catalog={catalog}
                products={products}
                isMulti={false}
                static={false}
                value={value}
            />
        </div>
    );
}

export default withApollo(ProductSelector);