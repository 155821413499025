import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import AccordionCustom from "../../../../layouts/Accordion/AccordionCustom"
import { Grid } from "swiper"
import OurTypography from "../../../typography/Typography"
import colors from "../../../../../config/theme/colors"
import InputBuilder from "../../InputBuilder"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { checkbox, searchOptions } from "./config/input.config"
import { CheckboxCustom, FormControlLabelCustom } from "./styles/styled"
import icoset from '../../../../../assets/selection.json'
import IcomoonReact from "icomoon-react"

// TODO: Instead of use <Checkbox>, implement OurCheckbox from InputBuilder
const OurCheckboxList = ({
  allProps: props,
  error,
  updateCallback
}) => {
  const [search, setSearch] = useState('');
  const [allItems, setAllItems] = useState(props.input.listing.values);
  const [checkedItems, setCheckedItems] = useState(props.input.listing.checkedValuesByDefault ?? []);
  const [isSelectAll, setIsSelectAll] = useState(true);

  useEffect(() => {
    const filteredItems = props.input.listing.values.filter((item) => item.name.toLowerCase().startsWith(search.toLowerCase()));

    setAllItems(filteredItems);
  }, [search]);

  useEffect(() => {
    updateCallback({
      listingId: props.input.listing?.id,
      checkedValues: checkedItems
    });
  }, [checkedItems]);

  const toggleSelectAll = (evt) => {
    const newCheckedItems = isSelectAll
      ? props.input.listing.values
      : [];

    evt.stopPropagation()
    setCheckedItems(newCheckedItems);
    setIsSelectAll(!isSelectAll);
  }

  const handleItemsToSubmit = (evt, isChecked, item) => {
    const index = checkedItems.findIndex((i) => i.id === item.id);

    if (index === -1) {
      setCheckedItems([...checkedItems, item]);
    } else {
      setCheckedItems(checkedItems.filter((_, i) => i !== index));
    }
  }

  return (
    <Box height={'100%'} style={props.input.custom.style}>
      <AccordionCustom defaultExpanded={props.input.custom.isExpanded} forcedExpanded={!props.input.custom.isExpandable} custompadding={'0px !important'} title={
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <OurTypography fontweighttext={'bold'} style={{ fontSize: 16 }}>{props.input.listing.title}</OurTypography>
          <OurTypography onClick={toggleSelectAll} fontweighttext={'bold'} color={colors.blue.darker.hue300} style={{ cursor: 'pointer' }}>
            {isSelectAll ? 'Tout Sélectionner' : 'Tout Désélectionner'}
          </OurTypography>
        </Box>
      }>
        {/* Search Bar */}
        <Box padding={'20px'}>
          <InputBuilder value={search} input={searchOptions} stateCallback={(evt) => {
            setSearch(evt.target.value);
          }} />
        </Box>

        {/* Listing */}
        <Box>
          {/* //* Custom checkbox */}
          <FormGroup>
            {allItems.map((item, index) => (
              <FormControlLabelCustom
                key={index}
                control={
                  <CheckboxCustom
                    checkedIcon={<IcomoonReact iconSet={icoset} size={25} icon="ico_checkbox_checked" style={{marginTop: 2}} />}
                    icon={<IcomoonReact iconSet={icoset} size={25} icon="ico_checkbox_unchecked" style={{marginTop: 2}} />}
                    checked={checkedItems.findIndex((i) => i.id === item.id) !== -1}
                    onChange={(evt, isChecked) => handleItemsToSubmit(evt, isChecked, item)}
                  />
                } label={item.name} />
            ))}
          </FormGroup>

          {/* //* Reusable OurCheckbox */}
          {/* // TODO: Use InputBuilder to load all checkboxes */}
        </Box>
      </AccordionCustom>
      {
          !error.seeError ? null :
              <span className="error-label">{error.errorMessage}</span>
      }
    </Box>
  )
}

export default OurCheckboxList