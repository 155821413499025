import { Box, Fade, Typography } from "@mui/material";
import { useState } from "react";
import colors from "../../../config/theme/colors";
import DialogModal from "../dialog/DialogModal";
import React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuCustom, MenuItemCustom, useStyles } from "./styles/styled";

const OurMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const handleClick = (event) => {
    if (props.toggleCallback) {
      props.toggleCallback(props.obj)
    } else {
      event.stopPropagation();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box>
      {
        props.hasSquare ? (
          <Box aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick} style={{
            backgroundColor: colors.white,
            zIndex: 100,
            border: '1px solid ' + colors.grey.border,
            position: 'absolute',
            top: '0',
            right: '0',
            ...props.customSquareStyle
          }}>
            <Box style={{
              display: "flex",
              padding: '4px 0',
            }}>
              {/* <Typography variant="h4" style={{ 
                color: colors.black.regular, 
                fontSize: 25, 
                fontWeight: 'bold', 
                lineHeight: 0, 
                cursor: 'pointer',
                // transform: !open ? 'translate(8px, -8px)' : null,
                // rotate: !open ? '90deg' : null,
                rotate: '90deg',
                transition: 'all .5s',
                zIndex: '100',
                position: 'absolute',
                top: '50%',
                right: '-50%',
                ...props.customStyle
              }}>...</Typography> */}
              <MoreVertIcon />
            </Box>
          </Box>
        ) : (
          <Box style={{
            display: "flex",
            cursor: 'pointer',
          }} {...props.style} onClick={handleClick}>
            {/* <Typography variant="h4" style={{ 
              color: colors.black.regular, 
              fontSize: 25, 
              fontWeight: 'bold', 
              lineHeight: 0, 
              cursor: 'pointer',
              // transform: !open ? 'translate(8px, -8px)' : null,
              // rotate: !open ? '90deg' : null,
              transform: 'translate(8px, -8px)',
              rotate: '90deg',
              transition: 'all .5s',
              zIndex: '100',
              ...props.customStyle
            }}>...</Typography> */}
            <MoreVertIcon />
          </Box>
        )
      }
      <MenuCustom
        disableAutoFocusItem
        style={{
          marginTop: props.hasSquare ? '30px' : '20px',
          marginLeft: props.hasSquare ? '10px' : null,
        }}
        value={'all'}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: props.hasSquare ? 'center' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock={false}
        PopoverClasses={{
          paper: classes.paper
        }}
        {...props}
      >
        {props?.menuOptions?.map((option, index, arr) => (
          <Box key={index}>
            <MenuItemCustom key={index} divider={props.menuOptions.length - 1 === index ? false : true} colorhover={option.color} isDisabled={option.isDisabled} onClick={(e) => {
              if (option.isDisabled) {
                e.stopPropagation();
                return
              } else if (option.modal) {
                e.stopPropagation();
                setOpenModal(option.modalProps.identifier)
              } else if (option.action) {
                // option.action(props.id)
                props.handleClick(e, option)
              } else if (option.onClick) {
                option.onClick(props.id)
              }
              // props.handleClick(e, option)
              handleClose(e)
            }
            }>
              <Box mr={1} fontSize={12} display="flex" alignItems={"center"}>
                {option.icon}
              </Box>
              <Typography variant="inherit" noWrap style={{ fontWeight: 'bold', fontSize: '12px', color: option.color }}>
                {option.label}
              </Typography>
            </MenuItemCustom>
            {/* {arr.length !== index + 1 ? (<Divider />) : null} */}
            {
              option.modal ?
                <DialogModal
                  open={openModal === option.modalProps.identifier}
                  icon={true}
                  type={option.modalProps.type}
                  title={option.modalProps.title}
                  subtitle={option.modalProps.subtitle}
                  primaryAction={(event) => {
                    event.stopPropagation()
                    option.modalProps.actions.primaryAction(props.id)
                    setOpenModal(null)
                  }}
                  secondaryAction={(event) => {
                    event.stopPropagation()
                    if (option.modalProps.actions.cancelAction) {
                      option.modalProps.actions.cancelAction()
                    }
                    setOpenModal(null)
                  }}
                  windowWidth={props.windowWidth}
                />
                : null
            }
          </Box>
        ))}
      </MenuCustom>

    </Box>
  )

}

export default OurMenu