import { Grid, TextField, Typography, FilledInput, MenuItem, FormControlLabel, Pagination, ToggleButton, Chip, Switch, ButtonGroup } from '@mui/material';
import styled from "styled-components";
import colors from "../../../../config/theme/colors";
import ChipInput from 'material-ui-chip-input-v5';
import Fab from '@mui/material/Fab';
import { TextFieldCustom } from '../components/Text/styles/styled';
import { InputFieldCustom } from './styled';

export const GridCustom = styled(Grid)`
// TODO: Set to 0 for filters forms
margin-bottom: ${props=> props.margin || props.margin === 0 ? props.margin + "px" : "16px"};
`;

export const LinkText = styled(Typography)`
    color: ${colors.blue.darker.hue300} !important;
    cursor: pointer;
    margin-top: 0.5rem;
    text-decoration: underline;
    &:hover{
        color: ${colors.black.regular} !important;
    }
`;

export const HelperText = styled(Typography)`
color: rgba(0, 0, 0, 0.54);
margin: 0;
font-size: 0.75rem;
margin-top: 3px;
text-align: left;
font-family: soleil, sans-serif;
font-weight: 400;
line-height: 1.66;
`;

export const FilledInputCustom = styled(FilledInput)`
background: white!important;
border: 0.5px solid ${colors.grey.lighter.hue700};
border-radius: 0;
&.Mui-focused{
    border: 1px solid rgba(0, 0, 0, 0.87);
}
&:before, &:after{
    border: 0!important;
}
&:hover{
    background: white;
}
input{
    padding: 16px;
}
`;

export const SelectLang = styled(TextField)`
fieldset{
    border: none;
}
input, .MuiSelect-select{
    font-size: 14px;
    padding: 0px 16px !important;
    margin-right: 16px;
    color: ${colors.blue.darker.hue300};
    border-radius: 0;
    &:focus{
        background: none;
    }
    @media screen and (max-width: 1450px){
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (max-width: 1280px){
        font-size: 13px;
        line-height: 17px;
    }
    @media screen and (max-width: 960px){
        font-size: 12px;
        line-height: 16px;
    }
}
svg{
    fill: ${colors.blue.darker.hue300};
}
& > p{
    margin: 0;
}
`;

export const MenuItemCustom = styled(MenuItem)`
color: ${colors.black.regular};
&.Mui-selected{
    background-color: ${colors.blue.darker.hue300} !important;
    color: white;
}
`;

export const FormControlLabelCustom = styled(FormControlLabel)`
    margin: 0;
    > span.MuiRadio-root, > span.MuiCheckbox-root{
        padding: 3px 0;
    }
    span{
        color: ${colors.black.regular};
    }
    span.MuiTypography-root{
        margin-left: 8px;
    }
    &.active{
        span{
            color: ${colors.blue.darker.hue300};
            font-weight: bold;
        }
    }
    
`;

export const GridRadio = styled(Grid)`
    &:not(:last-child){
        margin-bottom: 0.5rem;
    }
`;

export const ClickableLabel = styled.label`
    > div {
        border: 1px solid transparent;
        &:hover{
            border: 1px solid #0273A5;
            cursor: pointer;
        }
    }
    &.active{
        > div {
            border: 1px solid #0273A5;
        }
    }
    &.error{
        > div {
            border: 1px solid #f44336;
        }
    }
`;

export const DescriptionRadio = styled(Typography)`
p{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
}
`;

export const GridCatalog = styled(Grid)`
background: ${colors.white};
height: 100%;
`;

export const PaginationCustom = styled(Pagination)`
ul{
    justify-content: center; 
    margin-top: 24px;
}
`;

export const ToggleButtonCustom = styled(ToggleButton)`
    width: 50%;
    padding: 0;
    height: 100%;
    border: none;
    text-align: inherit;
    padding: 8px;
    background: none !important;
`;

export const CheckBoxCustom = styled.span`
border: 1px solid ${colors.grey.regular};
width: 20px;
height: 20px;
display: block;
margin: auto;
position: relative;
&.active{
    &:after{
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        top: 2px;
        left: 2px;
        background: ${colors.blue.darker.hue300}
    }
}
`;

export const GridCustomCheckbox = styled(GridCustom)`
padding: 0 1rem 0 1rem;
`;

export const Suggestion = styled(Typography)`
height:auto;
margin-top: 15px;
width : 100%;
display : flex;
align-items:center;
font-size:16px;
color : ${colors.grey.lighter.hue600};
// padding-left:37.82px;
span.tag{
    z-index:15;
    cursor:pointer;
    border : dotted 1px ${colors.grey.lighter.hue600};
    padding: 2px 5px;
    margin-left:8px;
    transition : all .1s;
    &:hover{
        color : ${colors.blue.darker.hue300};
        border-color:${colors.blue.darker.hue300};
    }
}
`;

export const ChipInputCustom = styled(ChipInput)`
    border: 1px solid ${colors.grey.border} !important;

    .MuiInputBase-root{
        padding: 0px !important;
        padding-left: 15px !important;
        gap: 5px;
        font-weight: bold;
        background-color: white;
        flex-wrap: nowrap !important;
        overflow-x: auto;
        overflow-y: hidden;

        & .MuiButtonBase-root {
            margin: 0px;
            font-weight: 400;
        }

        & fieldset, & fieldset[aria-expanded="true"] {
            display: none;
        }
    }

    input {
        min-width: 45px !important;
    }

    .MuiInputBase-input{
        height: 45px;
        min-height: 45px;
        min-width: auto;
        padding: 0px;
        margin: 0px;
    }
    > div{
        margin:0;
    }
`;

export const ChipCustom = styled(Chip)`
height: 20px;
margin-right: 4px;
margin-bottom: 4px;
span{
    font-size: 12px;
}
`;

export const HiddenUploadFile = styled(TextFieldCustom)`
display: none;
`;

export const ContainerFileUpload = styled.div`
    width: 100%;
    min-height: 70px;
    background-color: ${props => props.isDisabled 
        ? colors.grey.lighter.hue200 
        : props.hasError
            ? colors.red.lighterv2
            : props.hasData
                ? colors.blue.lighter.hue900
                : colors.white
    };
    border: ${props => props.hasError
        ? `2px dashed ${colors.red.regular}`
        : !props.hasData && `2px dashed ${colors.grey.border}`
    };

    & p {
        color: ${props => props.isDisabled
            ? colors.grey.lighter.hue600
            : props.hasError
                ? colors.red.regular
                : props.hasData
                    && colors.blue.darker.hue300
        } !important;
    }
`;

export const ClickToUpload = styled.label`
width: 100%;
height: 100%;
display: flex;
position: relative;
justify-content: center;
align-items: center;
&:after{
    content: '${props => props.textcontent}';
    position: absolute;
    margin: auto;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    color: white;
}
`;

export const CustomFab = styled(Fab)`
box-shadow: none;
`;

export const LabelFab = styled.label`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
`;

export const StyledFab = styled(Fab)`
background-color: ${props => props.checkerror ? 'red' : colors.black.regular};
box-shadow: none;
`;

export const ToolbarRightSide = styled(Grid)`
position: absolute;
right: 5px;
width: auto;
top: 5px;
display: flex;
flex-direction: row;
align-items:center;
justify-content:end;
@media screen and (max-width: 1450px){
    right: 4px;
    top: 1px;
}
`;

export  const SwitchCustom = styled(Switch)`
    .Mui-checked{
        color: ${colors.blue.lighter.hue150};
        & + .MuiSwitch-track{
            background-color: ${colors.blue.darker.hue300};
        }
    }
`;

export  const ButtonGroupCustom = styled(ButtonGroup)`
button{
    background-color: ${colors.white};
    color: ${colors.grey.regular};
    text-transform: inherit;
    padding: 4px 20px;
    &.selected{
        background-color: ${colors.blue.darker.hue300};
        color: ${colors.white};            
    }
}
`;

export const VideoPlaceholder = styled.video`
    position:absolute;
    top:50%;
    left:50%;
    height:90%;
    width:auto;
    transform : translate(-50%,-50%);
`;