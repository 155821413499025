import React, { useEffect, useState } from "react";
import { Grid, Tooltip, Checkbox, Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import CardCustom from "../../../../layouts/Card/CardCustom";
import CardProduct from "../../../../layouts/Card/cardContent/CardProduct";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  ROUTE_PRODUCTS_LIST_DETAIL,
  ROUTE_PRODUCTS_ADD,
} from "../../../../../js/constants/route-names";
import colors from "../../../../../config/theme/colors";
import { withApollo } from "@apollo/client/react/hoc";
import { GET_PRODUCTS_SEARCH_PAGINATION_LIGHT } from "../../../../../queries/products";
import LayoutBuilder from "../../../../ui/form/LayoutFormBuilder";
import exportProductsConfig from "../config/exportProducts.config";
import EmptyProduct from "../../../../../assets/pictos/empty-picto/empty_products.png";
import EmptyCard from "../../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../../ui/loadings/page-loader/PageLoader";
import request from "../../../../../js/utils/fetch";
import {
  START_LOADING,
  STOP_LOADING,
  SNACK,
} from "../../../../../js/constants/action-types";
import {
  ALERT_SUCCESS,
  ALERT_ERROR,
} from "../../../../../js/constants/alert-types";
import { eventService } from "../../../../../js/services/event.service";
import TablePagination from "@mui/material/TablePagination";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import CardProductList from "../../../../layouts/Card/cardContent/CardProductList";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "../../../../ui/typography/Typography";
import "../../../../navigation/DrawerLeft.scss";
import { GET_CATEGORIES_LIGHT_2 } from "../../../../../queries/categories";
import MasterSearch from "../../../../ui/search/MasterSearch";
import Button from "../../../../ui/button/Button";
import { UPDATE_PRODUCT_CATEGORY } from "../../../../../queries/product_position";
import { withTranslation } from "react-i18next";
import TraductionSelect from "../../../../layouts/TopPanel/TraductionSelect";
import { GetApp } from "@mui/icons-material";
import { Link } from "react-router-dom/cjs/react-router-dom";
import AccordionCustom from "../../../../layouts/Accordion/AccordionCustom";
import { productsCardMapper } from "../../../../../js/mappers/products";

const GridCustom = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
`;
const ViewModuleIconCustom = styled(ViewModuleIcon)`
  fill: ${colors.grey.regular};
  cursor: pointer;
  &.active {
    fill: ${colors.blue.darker.hue300};
    cursor: inherit;
  }
`;
const ViewListIconCustom = styled(ViewListIcon)`
  fill: ${colors.grey.regular};
  cursor: pointer;
  &.active {
    fill: ${colors.blue.darker.hue300};
    cursor: inherit;
  }
`;

const TableCellCustom = styled(TableCell)`
  border: none;
  // display: inline-block;
  // width: calc(100% / 9);
  padding: 3px 16px 14px 16px;
  background-color: ${(props) =>
    !props.minimalForm ? colors.grey.lighter.hue980 : "white"};
  color: ${colors.grey.lighter.hue600};
`;
const TablePaginationStyle = styled(TablePagination)`
  .MuiToolbar-root {
    padding: 0;
  }
  .MuiTablePagination-spacer {
    display: none;
  }
`;

const CheckboxCustom = styled(Checkbox)`
  justify-content: flex-start;
  position: absolute;
  background: white;
  padding: 2px;
  margin-top: 10px;
  z-index: 10;
  border-radius: 0;
  svg {
    fill: ${colors.blue.darker.hue300};
  }
  &:hover {
    background: white !important;
    svg {
      fill: ${colors.blue.lighter.hue150};
    }
  }
  input {
    width: 40px;
  }
`;

const CheckboxAll = styled(Checkbox)`
  background: transparent;
  z-index: 10;
  padding: 0;
  margin: 0;
`;

const styles = (theme) => ({});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: colors.blue.darker.hue300,
  },
  tooltip: {
    backgroundColor: colors.blue.darker.hue300,
    fontSize: 14,
  },
}));

const ListingWrapper = styled(Box)`
  min-height: 300px;
  overflow: auto;

  & > div {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
  }
`;

const TableHeadCustom = styled(TableHead)`
  background: ${(props) =>
    props.minimalForm ? colors.white : colors.grey.lighter.hue100};
`;

const TableRowCustom = styled(TableRow)`
  background-color: transparent;
`;

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const ListingProducts = (props) => {
  const [state, setState] = useState({
    openFormExport: false,
    catalogFormData: {},
    ready: false,
    page: 0,
    nbperpage: props.nbperpage,
    countPage: 0,
    categories: [],
    skulist: props.idlist ? props.idlist : [],
    searchValue: "",
    searchValueSku: "",
    arrayCatFilter: [],
    viewMode: "card",
    exportName: "",
    exportLang: props.locales[0].node.code,
    exportType: "csv",
    exportStatus: "all",
    exportImage: "all",
    errors: {},
    searchStatus: "all",
    attributesInternalFilter: [],
    customSearchAttributes: {
      product_image: "all",
    },
    categoriesSelected: props.activeCategorie ? [props.activeCategorie] : [],
    catalogId: props.catalogId || null,
    readyFilters: false,
    listCollections: [],
    listProducts: [],
    listProductsFormatted: [],
    callback: null,
  });

  useEffect(() => {
    if (props.listCategories?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        categories: props.listCategories ?? null,
      }));
    }
    
    getAll();

    request(`${process.env.REACT_APP_API}/products-count/media`, "get", false)
      .then((data) => {
        if (data.success) {
          setState((prevState) => ({
            ...prevState,
            nbImageTotal: data.details,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (state.listProducts) {
      productsCardMapper(state.listProducts).then((data) => {
        setState((prevState) => ({
          ...prevState,
          listProductsFormatted: data,
        }));
      });
    }
  }, [state.listProducts]);

  useEffect(() => {
    resetFilters();
  }, [props.currentLang]);

  useEffect(() => {
    if (props.reload) {
      props.onReload();
      resetFilters();
    }
  }, [props.reload]);

  useEffect(() => {
    if (props.listCategories?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        categories: props.listCategories ?? null,
      }));
    }
  }, [props.listCategories]);

  const getAll = async () => {
    await handleFilters();
    await handleGetProducts();

    if (!props.listCategories) {
      await handleGetCategories();
    }

    setState((prevState) => ({
      ...prevState,
      ready: true,
    }));
  };

  const handleFilters = () => {
    return new Promise((resolve, reject) => {
      let getFilters = props.previousFilters?.filters;
      let getPagination = props.previousFilters?.pagination;

      if (getFilters?.searchValue && getFilters.searchValue !== "") {
        setState((prevState) => ({
          ...prevState,
          searchValue: getFilters.searchValue ?? null,
        }));
      }
      if (getFilters?.listCategories && getFilters.listCategories.length > 0) {
        setState((prevState) => ({
          ...prevState,
          categoriesSelected: getFilters.listCategories ?? null,
        }));
      }
      if (getFilters?.customSearchAttributes) {
        setState((prevState) => ({
          ...prevState,
          customSearchAttributes: getFilters.customSearchAttributes ?? null,
        }));
      }

      if (getFilters?.searchValueSku && getFilters.searchValueSku !== "") {
        setState((prevState) => ({
          ...prevState,
          searchValueSku: getFilters.searchValueSku ?? null,
        }));
      }
      if (
        getFilters?.categoriesSelected &&
        getFilters.categoriesSelected !== ""
      ) {
        setState((prevState) => ({
          ...prevState,
          categoriesSelected: getFilters.categoriesSelected ?? null,
        }));
      }
      if (getFilters?.searchStatus && getFilters.searchStatus !== "") {
        setState((prevState) => ({
          ...prevState,
          searchStatus: getFilters.searchStatus ?? null,
        }));
      }
      if (getFilters?.arrayCatFilter && getFilters.arrayCatFilter.length > 0) {
        setState((prevState) => ({
          ...prevState,
          arrayCatFilter: getFilters.arrayCatFilter ?? null,
        }));
      }
      if (getPagination?.currentPage) {
        setState((prevState) => ({
          ...prevState,
          page: getPagination.currentPage ?? null,
        }));
      }
      if (getPagination?.perPage) {
        setState((prevState) => ({
          ...prevState,
          nbperpage: getPagination.perPage ?? null,
        }));
      }
      resolve();
    });
  };

  const handleSearchStatus = (e, value) => {
    setState((prevState) => ({
      ...prevState,
      searchStatus: e.target.value,
    }));
  };

  const handleSearchSku = (e, value) => {
    setState((prevState) => ({
      ...prevState,
      searchValueSku: e.target.value,
    }));
  };

  const handleChange = (e, value) => {
    setState((prevState) => ({
      ...prevState,
      searchValue: e.target.value,
    }));
  };

  const resetFilters = () => {
    setState((prevState) => ({
      ...prevState,
      ready: false,
      page: 0,
      searchValue: "",
      searchValueSku: "",
      searchStatus: "all",
      customSearchAttributes: {
        product_image: "all",
      },
      categoriesSelected: props.activeCategorie ? [props.activeCategorie] : [],
    }));
  };

  const validateFilters = () => {
    setState((prevState) => ({
      ...prevState,
      ready: false,
      page: 0,
    }));
  };

  const goTo = (route, id, list) => {
    props.history.push({
      pathname: route,
      state: { productId: id, productList: list },
    });
  };

  const handleToggleDrawer = (stateDrawer) => {
    if (stateDrawer === "openFormExport") {
      setState((prevState) => ({
        ...prevState,
        [stateDrawer]: !state[stateDrawer] ?? null,
        exportName: "",
        exportLang: props.currentLang ?? null,
        exportType: "csv",
        exportStatus: "all",
        exportImage: "all",
      }));
    }
    setState((prevState) => ({
      ...prevState,
      [stateDrawer]: !state[stateDrawer],
    }));
  };

  const handlerMutationExport = async () => {
    props.startLoading();
    if (hasErrors()) {
      props.stopLoading();
      props.snack(ALERT_ERROR, "Veuillez vérifier les champs invalides");
      return eventService.fire();
    }
    try {
      let data = new FormData();
      data.append("name", state.exportName);
      data.append("locale", state.exportLang);
      data.append("format", state.exportType);
      data.append("status", state.exportStatus);
      data.append("image", state.exportImage);

      let urlencoded = new URLSearchParams(data).toString();

      request(
        `${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`,
        "get",
        null,
        "application/x-www-form-urlencoded"
      )
        .then(async (data) => {
          if (data.success) {
            window.open(
              `${process.env.REACT_APP_API_ROOT}/medias/export/${
                data.mediaObject.filePath +
                "." +
                (state.exportType === "xls"
                  ? state.exportType + "x"
                  : state.exportType)
              }`,
              "_blank"
            );
            props.stopLoading();
            props.snack(ALERT_SUCCESS, `L'export a réussi !`);
          } else {
            props.stopLoading();
            props.snack(
              ALERT_ERROR,
              `Un problème est survenu lors de l'exportation !`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      props.snack(ALERT_ERROR, `L'export a échoué !`);
      props.stopLoading();
    }
    handleToggleDrawer("openFormExport");
  };

  const handleGetProducts = () => {
    return new Promise((resolve, reject) => {
      const getProducts = GET_PRODUCTS_SEARCH_PAGINATION_LIGHT;

      let currentLocale = props.locales.find(
        (e) => e.node.code === props.currentLang
      );
      let currentLocaleId = currentLocale?.node.id;

      let productsDataFilters = [];
      let variables = {
        itemsPerPage: state.nbperpage,
        page: state.page + 1,
        localeId: currentLocaleId,
        scrapStatus: props?.variables?.scrapStatus,
        productSources: props?.variables?.productSources,
        fileImports: props?.variables?.fileImports,
      };

      if (state.searchValueSku) {
        variables.sku = state.searchValueSku;
      }
      if (state.catalogId) {
        variables.catalog = state.catalogId;
      }

      if (state.searchValue !== "" && state.searchValue) {
        productsDataFilters.push(
          JSON.stringify({
            identifier: "product_name",
            value: state.searchValue,
            locale: props.currentLang,
            option: null,
          })
        );
      }

      if (state.searchStatus && state.searchStatus !== "all") {
        if (state.searchStatus === "active") {
          variables.status = true;
        } else {
          variables.status = false;
        }
      }

      if (state.customSearchAttributes) {
        for (const [key, value] of Object.entries(
          state.customSearchAttributes
        )) {
          if (value !== "") {
            let option = null;
            let newValue = null;
            if (key !== "product_image") {
              option = value?.includes("/api/attribute-options");
            } else {
              if (value === "active") {
                newValue = true;
              } else if (value === "inactive") {
                newValue = false;
              }
            }
            if (value !== "all") {
              productsDataFilters.push(
                JSON.stringify({
                  identifier: key,
                  value: option ? null : newValue !== null ? newValue : value,
                  locale: option ? null : props.currentLang,
                  option: option
                    ? value.replaceAll("/api/attribute-options/", "")
                    : null,
                })
              );
            }
          }
        }
      }
      if (props.variables) {
        for (const [key, value] of Object.entries(props.variables)) {
          if (key === "productDatas") {
            if (productsDataFilters.length > 0) {
              for (const e of value) {
                productsDataFilters.push(e);
              }
            } else {
              productsDataFilters = value;
            }
          }
        }
      }

      if (productsDataFilters.length > 0) {
        variables.productDatas = productsDataFilters;
      }

      if (state.categoriesSelected.length > 0 || props.activeCategorie) {
        if (props.activeCategorie) {
          variables.categories = state.categoriesSelected
            .concat([props.activeCategorie])
            .map((e) => parseInt(e.id.replace("/api/categories/", "")));
        } else {
          variables.categories = state.categoriesSelected.map((e) =>
            parseInt(e.id.replace("/api/categories/", ""))
          );
        }
      }

      if (props.callbackFilters) {
        let getFilters = {
          sku: state.searchValueSku,
          searchValue: state.searchValue,
          customSearchAttributes: state.customSearchAttributes,
          categoriesSelected: state.categoriesSelected,
          searchStatus: state.searchStatus,
          searchValueSku: state.searchValueSku,
        };
        let getPagination = {
          perPage: state.nbperpage,
          currentPage: state.page,
        };
        props.callbackFilters(getFilters, getPagination);
      }
      props.client
        .query({
          query: getProducts,
          variables,
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          let listProducts = result.data.researchProducts.edges;

          setState((prevState) => ({
            ...prevState,
            listProducts,
            countPage: result.data.researchProducts.totalCount,
          }));

          let dataSku = new FormData();
          let list_sku = listProducts.map((e) => e.node.sku);

          list_sku.forEach((element) => {
            dataSku.append("sku_list[]", element);
          });

          if (props.totalCountCallback)
            props.totalCountCallback(
              result?.data?.researchProducts?.totalCount ?? 0
            );
          if (process.env.REACT_APP_MODE_SPREAD === "hub") {
            request(
              `${process.env.REACT_APP_API}/stockCompletude`,
              "get",
              false
            )
              .then((data) => {
                if (data.success) {
                  for (var i = 0; i < listProducts.length; i++) {
                    let productSku = listProducts[i].node.sku;
                    let infoStocks = data.products[productSku].stock;
                    let allInfoStocks = data.products[productSku];

                    listProducts[i].node.stocks = {
                      infoStocks,
                      allInfoStocks,
                    };
                  }

                  setState((prevState) => ({
                    ...prevState,
                    listProducts,
                  }));
                  // forceUpdate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (process.env.REACT_APP_MODE_SPREAD === "hub") {
            request(
              `${
                process.env.REACT_APP_API
              }/stockCompletudeByWarehouse/${state.catalogId.replace(
                "/api/catalogs/",
                ""
              )} `,
              "post",
              false
            )
              .then((data) => {
                if (data.success) {
                  for (var i = 0; i < listProducts.length; i++) {
                    let productSku = listProducts[i].node.sku;
                    let infoStocks = data.products[productSku].stock;
                    listProducts[i].node.stocks = {
                      infoStocks,
                    };
                  }

                  setState((prevState) => ({
                    ...prevState,
                    listProducts,
                  }));
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (listProducts.length === 0) {
            setState((prevState) => ({ ...prevState, noResult: true }));
          } else {
            setState((prevState) => ({ ...prevState, noResult: false }));
          }

          setState((prevState) => ({
            ...prevState,
            ready: true,
            readyFilters: true,
            listProducts,
          }));
          props.stopLoading();
          resolve();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const processChildren = async (categories, parent, allIdentifiers) => {
    parent.children = categories.filter((e) => e.parent?.id === parent?.id);
    for (let child of parent.children) {
      await processChildren(categories, child);
    }
  };

  const handleGetCategories = () => {
    return new Promise((resolve, reject) => {
      let variables = {
        exists: [{ catalog: props.isCatalog }],
        status:
          state.searchStatus && state.searchStatus !== "all"
            ? state.searchStatus === "active"
              ? true
              : false
            : null,
      };
      if (state.catalogId) {
        variables.catalog = `/api/catalogs/${state.catalogId}`;
      }
      props.client
        .query({
          query: GET_CATEGORIES_LIGHT_2,
          variables,
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          let allCategories = result.data.categories;
          let root = Array.isArray(allCategories)
            ? allCategories?.find((e) => e.parent === null)
            : null;
          if (!props.isCatalog) {
            allCategories = allCategories.filter((e) => e.catalog === null);
          }
          if (allCategories.length > 0) {
            processChildren(allCategories, root);
          }
          setState((prevState) => ({
            ...prevState,
            categories: allCategories,
            activeCategorie: root,
            ready: true,
            root,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
      resolve();
    });
  };

  const handleChangePositionMutation = (data, idProduct) => {
    props.startLoading();
    let variables = {
      id: data.id,
      category: data.category.id,
      product: idProduct,
      position: data.position
        ? data.position !== ""
          ? data.position
          : null
        : null,
    };
    props.client
      .mutate({
        mutation: UPDATE_PRODUCT_CATEGORY,
        variables,
      })
      .then((result) => {
        props.snack(
          ALERT_SUCCESS,
          data.selection && data.position
            ? "Changement de la position et de la collection bien effectué"
            : data.selection && !data.position
            ? "Changement de la collection bien effectué"
            : "Changement de la position bien effectué"
        );
        props.stopLoading();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const stateCallback = (stateName, value, custom, translated, callback) => {
    if (custom === true) {
      let customSearch = state.customSearchAttributes;

      if (!customSearch) {
        customSearch = {};
      }

      customSearch[stateName] = value?.target?.value ?? value;

      setState((prevState) => ({
        ...prevState,
        customSearchAttributes: customSearch ?? null,
        callback,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [stateName]: value?.target?.value ?? value,
        callback,
      }));
    }
  };

  useEffect(() => {
    if (state.callback) {
      state.callback();
    }
  }, [state.callback]);

  const changePage = (event, page) => {
    props.startLoading();
    setState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const handleChangePerPage = (event) => {
    setState((prevState) => ({
      ...prevState,
      nbperpage: event.target.value,
    }));
  };

  useEffect(() => {
    handleGetProducts();
  }, [state.page, state.nbperpage, state.categoriesSelected, state.ready]);

  const handleFormError = (stateName, error) => {
    let errors = state.errors;

    errors[stateName] = error;

    setState((prevState) => ({ ...prevState, errors: errors ?? null }));
  };

  const hasErrors = () => {
    if (state.errors) {
      for (let error in state.errors) {
        if (state.errors[error]) return true;
      }
    }

    return false;
  };

  const changeViewMode = (mode) => {
    if (mode === "list") {
      let nbperpage = !props.productBypage["list"].includes(state.nbperpage)
        ? props.productBypage["list"][0]
        : state.nbperpage;
      setState((prevState) => ({
        ...prevState,
        nbperpage: nbperpage ?? null,
        viewMode: "list",
        page: 0,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        nbperpage: props.productBypage["card"][0] ?? null,
        viewMode: "card",
        page: 0,
      }));
    }
  };

  const handleBlankGoTo = (productId) => {
    window.open(
      ROUTE_PRODUCTS_LIST_DETAIL.replace(
        ":id",
        productId.replace("/api/products/", "")
      )
    );
  };

  const changePosition = (stateName, value, idProduct) => {
    //todo
    let getProducts = state.listProducts;
    let productData = getProducts.find((e) => e.node.id === idProduct);
    let getData = productData.node.productCategories.edges.find(
      (e) => e.node.category.id === props.activeCategorie.id
    );
    if (stateName === "position") {
      getData.node.position = value?.target?.value ?? value;
    } else {
      if (getData.node.selection) {
        getData.node.selection.id = value?.target?.value ?? value;
      } else {
        getData.node.selection = { id: value?.target?.value ?? value };
      }
    }
    setState((prevState) => ({
      ...prevState,
      listProducts: getProducts ?? null,
    }));
  };

  const _renderListing = (cardSize, productBypage, headCells) => {
    return (
      <>
        <Box
          width={"100%"}
          style={{
            position: "relative",
          }}
        >
          <Grid
            container
            direction="row"
            spacing={2}
            style={{
              minHeight: "150px",
              marginTop: state.viewMode === "list" ? 8 : 0,
              marginBottom: state.viewMode === "list" ? 16 : 0,
            }}
          >
            {state.viewMode === "list" ? (
              <GridCustom
                item
                xs={12}
                key={`HeaderList`}
                style={{
                  padding: props.handleToggle ? "20px 0px 3px" : "3px 16px",
                  zIndex: 12,
                }}
              >
                <CardCustom
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "0",
                    borderBottom: `1px solid ${colors.grey.border}`,
                  }}
                  cardContentStyle={{ height: "100%" }}
                  contentpadding={"0"}
                  hovercard={false}
                  collapse={true}
                >
                  <TableContainer
                    component={Paper}
                    style={{ maxHeight: props.inForm ? 600 : 700 }}
                  >
                    <Table aria-label="headerList" stickyHeader>
                      <TableHeadCustom minimalForm={props.minimalForm}>
                        <TableRowCustom>
                          {headCells
                            .filter((e) => e !== null)
                            .map((headCell) => (
                              <TableCellCustom
                                minimalForm={props.minimalForm}
                                key={headCell.id}
                                align={headCell.align ? headCell.align : "left"}
                                style={{
                                  width: `${headCell.style?.width}`,
                                  zIndex: 5,
                                  borderBottom: `1px solid ${colors.grey.border}`,
                                }}
                              >
                                {headCell.label}
                              </TableCellCustom>
                            ))}
                        </TableRowCustom>
                      </TableHeadCustom>
                      <TableBody>
                        {state.listProducts.map((product, index) => {
                          let productCategory = null;
                          if (props.minimalForm) {
                            productCategory =
                              product.node.productCategories?.edges?.find(
                                (e) =>
                                  e.node.category.id ===
                                  props.activeCategorie.id
                              );
                          }
                          return (
                            <CardProductList
                              key={index}
                              currentLang={props.currentLang}
                              nbCatalog={
                                state.nbCatalog?.products[product.node.sku] ?? 0
                              }
                              nbImageTotal="0"
                              product={product}
                              index={index}
                              countProducts={state.listProducts?.length}
                              routeProduct={() =>
                                props.inForm
                                  ? handleBlankGoTo(product.node.id)
                                  : goTo(
                                      ROUTE_PRODUCTS_LIST_DETAIL.replace(
                                        ":id",
                                        product.node.id.replace(
                                          "/api/products/",
                                          ""
                                        )
                                      )
                                    )
                              }
                              windowWidth={props.windowWidth}
                              inForm={props.inForm}
                              minimalForm={props.minimalForm}
                              changePosition={changePosition}
                              productCategory={productCategory}
                              reverseCheck={props.reverseCheck}
                              selectedProducts={props.selectedProducts}
                              handleChangePositionMutation={
                                handleChangePositionMutation
                              }
                              handleToggle={props.handleToggle}
                              listCollections={state.listCollections}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardCustom>
              </GridCustom>
            ) : null}
            {!state.ready ? (
              <Box
                style={{
                  width: "100%",
                }}
              >
                <PageLoader />
              </Box>
            ) : state.listProducts &&
              state.listProducts.length > 0 &&
              !state.noResult ? (
              state.listProductsFormatted.map((product, index) => {
                //let truncAttrGroupId = product.node.attributeGroup.id.replace('/api/attribute-groups/', '');
                const labelId = `checkbox-list-secondary-label-${product}`;
                if (state.viewMode === "card") {
                  return (
                    <GridCustom
                      item
                      lg={cardSize?.["lg"] ? cardSize?.["lg"] : 4}
                      md={cardSize?.["md"] ? cardSize?.["md"] : 6}
                      sm={cardSize?.["sm"] ? cardSize?.["sm"] : 6}
                      xs={cardSize?.["xs"] ? cardSize?.["xs"] : 12}
                      key={`ListProduct${index}`}
                      onClick={
                        props.handleToggle
                          ? () => props.handleToggle(product)
                          : null
                      }
                    >
                      <Link
                        onClick={
                          props.inForm || props.handleToggle
                            ? (event) => event.preventDefault()
                            : null
                        }
                        style={{ width: "100%", textDecoration: "none" }}
                        // to={ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', product.node.id.replace('/api/products/', ''))}
                        to={{
                          pathname: ROUTE_PRODUCTS_LIST_DETAIL.replace(
                            ":id",
                            product.id.replace("/api/products/", "")
                          ),
                          state: props.navButton
                            ? { productList: state.listProducts }
                            : null,
                        }}
                      >
                        <CardCustom
                          cardContentStyle={{
                            height: "100%",
                            width: "100%",
                            boxSizing: "border-box",
                            paddingBottom: 16,
                            cursor: "pointer",
                          }}
                          hovercard={props.hoverCard ? props.hoverCard : true}
                          style={{
                            width: "100%",
                            height: "100%",
                            cursor: props.inForm ? "pointer" : "initial",
                          }}
                          onClick={() =>
                            props.inForm && !props.handleToggle
                              ? handleBlankGoTo(product.id)
                              : null
                          }
                        >
                          {props.inForm ? (
                            <CheckboxCustom
                              edge="start"
                              checked={
                                props.reverseCheck
                                  ? props.selectedProducts?.indexOf(
                                      product.id
                                    ) !== -1
                                    ? false
                                    : true
                                  : props.selectedProducts?.indexOf(
                                      product.id
                                    ) === -1
                                  ? false
                                  : true
                              }
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          ) : null}
                          <CardProduct
                            currentLang={props.currentLang}
                            dataFormatted={product}
                            locale={props.locales.find(
                              (e) => e.node.code === props.currentLang
                            )}
                            index={index}
                            routeProduct={() =>
                              props.inForm
                                ? props.handleToggle
                                  ? null
                                  : handleBlankGoTo(product.node.id)
                                : goTo(
                                    ROUTE_PRODUCTS_LIST_DETAIL.replace(
                                      ":id",
                                      product.node.id.replace(
                                        "/api/products/",
                                        ""
                                      )
                                    ),
                                    null,
                                    props.navButton ? state.listProducts : null
                                  )
                            }
                            windowWidth={props.windowWidth}
                            inForm={props.inForm}
                          />
                        </CardCustom>
                      </Link>
                    </GridCustom>
                  );
                }
              })
            ) : state.noResult ? (
              <EmptyCard
                title={props.t("products.list.filter.emptyCard.noResult")}
                textButton={props.t(
                  "products.list.filter.emptyCard.addProduct"
                )}
                onClick={() => goTo(ROUTE_PRODUCTS_ADD)}
                picto={EmptyProduct}
                openForm={props.openForm}
                xsImg={props.openForm ? 4 : 2}
                mtImg={2}
              />
            ) : (
              <EmptyCard
                title={props.t("products.list.filter.emptyCard.noSetup")}
                subtitle={props.t(
                  "products.list.filter.emptyCard.addProductByClicking"
                )}
                textButton={props.t(
                  "products.list.filter.emptyCard.addProduct"
                )}
                onClick={() => goTo(ROUTE_PRODUCTS_ADD)}
                picto={EmptyProduct}
                openForm={props.openForm}
                xsImg={props.openForm ? 4 : 2}
              />
            )}
          </Grid>
        </Box>
        {state.ready && !state.noResult ? (
          <TablePaginationStyle
            count={state.countPage}
            page={state.page}
            onPageChange={(event, page) => {
              changePage(event, page);
            }}
            color="primary"
            component="div"
            rowsPerPage={state.nbperpage}
            rowsPerPageOptions={productBypage}
            labelDisplayedRows={(from = state.pagePresentations) =>
              `${from.from}-${from.to === -1 ? from.count : from.to} sur ${
                from.count
              }`
            }
            onRowsPerPageChange={(event) => handleChangePerPage(event)}
            labelRowsPerPage={props.t("products.list.bypage")}
          />
        ) : null}
      </>
    );
  };

  let productBypage = [];
  productBypage = props.productBypage[state.viewMode];
  const { classes, cardSize } = props;
  let checkSelectAll = props.reverseCheck ? false : true;
  let checker = (arr, target) => target.every((v) => arr.includes(v));
  if (
    state.listProducts &&
    state.listProducts?.length > 0 &&
    props.selectedProducts?.length > 0
  ) {
    let productsIdArray = state.listProducts.map((e) => e.node.id);
    if (checker(props.selectedProducts, productsIdArray)) {
      checkSelectAll = false;
    }
  }

  let headCells = [
    props.handleSelectAll
      ? {
          id: "check",
          align: "center",
          style: null,
          disablePadding: true,
          label: (
            <CheckboxAll
              edge="start"
              checked={checkSelectAll ? true : false}
              indeterminate={
                checkSelectAll
                  ? false
                  : props.selectedProducts?.length > 0
                  ? true
                  : false
              }
              onClick={() => {
                props.handleSelectAll(state.listProducts, checkSelectAll);
              }}
            />
          ),
        }
      : null,
    {
      id: "status",
      align: "center",
      style: { width: "calc(100%/12)" },
      disablePadding: false,
      label: props.t("products.list.cardproduct.status"),
    },
    {
      id: "sku",
      align: "center",
      style: { width: "calc(100%/12)" },
      disablePadding: false,
      label: props.t("products.list.cardproduct.article"),
    },
    {
      id: "nom",
      style:
        process.env.REACT_APP_MODE_SPREAD !== "hub"
          ? null
          : { width: "calc(100%/3.5)" },
      disablePadding: false,
      label: props.t("products.list.cardproduct.name"),
    },
    {
      id: "variant",
      align: "center",
      style:
        process.env.REACT_APP_MODE_SPREAD !== "hub"
          ? null
          : { width: "calc(100%/12)" },
      disablePadding: false,
      label: "Variantes",
    },
    {
      id: "categories",
      style:
        process.env.REACT_APP_MODE_SPREAD !== "hub"
          ? null
          : { width: "calc(100%/12)" },
      disablePadding: false,
      label: props.t("products.list.cardproduct.categorie") + "(s)",
    },
    {
      id: "nbCatalogs",
      align: "center",
      style: { width: "calc(100%/12)" },
      disablePadding: false,
      label:
        process.env.REACT_APP_MODE_SPREAD !== "hub"
          ? props.t("products.list.cardproduct.catalogs") + "(s)"
          : "Présence dans les entrepôts",
    },
    process.env.REACT_APP_MODE_SPREAD !== "hub"
      ? {
          id: "nbImages",
          align: "center",
          style: { width: "calc(100%/9)" },
          disablePadding: false,
          label: props.t("products.list.cardproduct.nbimage"),
        }
      : null,
    process.env.REACT_APP_MODE_SPREAD !== "hub"
      ? {
          id: "completion",
          style: { width: "calc(100%/9) / 1.25" },
          disablePadding: false,
          label: props.t("products.list.cardproduct.completeness"),
        }
      : null,
    process.env.REACT_APP_MODE_SPREAD !== "hub"
      ? {
          id: "button",
          style: { width: "calc(100%/7)" },
          disablePadding: false,
          label: "",
        }
      : null,
  ];

  if (props.minimalForm) {
    headCells = [
      {
        id: "checkbox",
        align: "center",
        style: null,
        disablePadding: true,
        label: "",
      },
      {
        id: "status",
        align: "center",
        style: null,
        disablePadding: false,
        label: "Statut",
      },
      { id: "sku", style: null, disablePadding: false, label: "Code article" },
      { id: "nom", style: null, disablePadding: false, label: "Nom" },
      process.env.REACT_APP_MODE_SPREAD !== "hub"
        ? {
            id: "nbImages",
            align: "center",
            style: { width: "calc(100%/9)" },
            disablePadding: false,
            label: props.t("products.list.cardproduct.nbimage"),
          }
        : null,
      { id: "button", style: null, disablePadding: false, label: "" },
    ];
  }

  return (
    <Grid
      container
      style={{
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        gridTemplateColumns: "100%",
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 12 }}
      >
        <Grid container>
          {state.readyFilters ? (
            <AccordionCustom
              isFilter={true}
              defaultExpanded={false}
              title={props.t("products.list.filter.filterSearch")}
            >
              <Grid container justifyContent="center" alignItems="center">
                <MasterSearch
                  currentStatus={state.searchStatus}
                  searchStatusHandler={handleSearchStatus}
                  handleNameDesc={handleChange}
                  handleSearchSku={handleSearchSku}
                  validateSearch={validateFilters}
                  resetFilters={resetFilters}
                  categories={state.categories}
                  attributesInternalFilter={props.attributes.product.attributes.edges.filter(
                    (e) => e.node.internalFilter
                  )}
                  stateCallback={stateCallback}
                  currentLang={props.currentLang}
                  allState={state}
                  categorieActivated={props.activeCategorie ? false : true}
                  noCategories={props.noCategories}
                />
              </Grid>
            </AccordionCustom>
          ) : null}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: 12 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid
                  item
                  style={{
                    marginRight: 16,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    colortext={colors.black.regular}
                    style={{ marginBottom: 4 }}
                  >
                    {props.t("products.list.view")} :{" "}
                  </Typography>
                  <BootstrapTooltip
                    title="Vue carte"
                    className={clsx(classes.tooltip)}
                  >
                    <ViewModuleIconCustom
                      style={{ fontSize: 30, marginLeft: 8 }}
                      className={state.viewMode === "card" ? "active" : null}
                      onClick={
                        state.viewMode === "card"
                          ? null
                          : () => changeViewMode("card")
                      }
                    />
                  </BootstrapTooltip>
                  <BootstrapTooltip
                    title="Vue liste"
                    className={clsx(classes.tooltip)}
                  >
                    <ViewListIconCustom
                      style={{ fontSize: 30, marginLeft: 8 }}
                      className={state.viewMode === "list" ? "active" : null}
                      onClick={
                        state.viewMode === "list"
                          ? null
                          : () => changeViewMode("list")
                      }
                    />
                  </BootstrapTooltip>
                </Grid>
                <Grid item style={{ height: "100%", display: "block" }}>
                  <span
                    style={{
                      width: 1,
                      height: "100%",
                      border: `solid 1px ${colors.grey.lighter.hue900}`,
                    }}
                  ></span>
                </Grid>
                {process.env.REACT_APP_MODE_SPREAD !== "hub" ? (
                  // <BootstrapTooltip title="Cliquez pour exporter les produits" className={clsx(classes.tooltip)}>
                  <Grid item style={{ marginLeft: 16 }}>
                    <Grid
                      container
                      alignItems="center"
                      onClick={() =>
                        props.handlerExport
                          ? props.handlerExport()
                          : handleToggleDrawer("openFormExport")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Typography
                        colortext={colors.blue.darker.hue300}
                        style={{ marginBottom: 4 }}
                      >
                        {props.handlerExportText
                          ? props.handlerExportText
                          : props.t("products.list.export.title")}
                      </Typography>
                      <GetApp
                        style={{
                          fontSize: 26,
                          marginLeft: 4,
                          fill: colors.blue.darker.hue300,
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : // </BootstrapTooltip>
                null}
                {props.loadingSecondExport ? (
                  <CircularProgress
                    size={"20px"}
                    style={{ marginLeft: 16, fill: colors.blue.darker.hue300 }}
                  />
                ) : props.handlerSecondExportText ? (
                  <Grid item style={{ marginLeft: 16 }}>
                    <Grid
                      container
                      alignItems="center"
                      onClick={() => props.handlerSecondExport()}
                      style={{ cursor: "pointer" }}
                    >
                      <Typography
                        colortext={colors.blue.darker.hue300}
                        style={{ marginBottom: 4 }}
                      >
                        {props.handlerSecondExportText
                          ? props.handlerSecondExportText
                          : props.t("products.list.export.title")}
                      </Typography>
                      <GetApp
                        style={{
                          fontSize: 26,
                          marginLeft: 4,
                          fill: colors.blue.darker.hue300,
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item>
              {props.handleLang ? (
                <Grid item xs={12} style={{ fontSize: 14 }}>
                  <TraductionSelect
                    currentLang={props.currentLang}
                    handleLang={props.handleLang}
                    locales={props.locales}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {props.handleSelectAll &&
      state.viewMode === "card" &&
      state.listProducts?.length > 0 ? (
        <Grid container justifyContent="space-between" alignItems="center">
          <Button
            onClick={() => {
              props.handleSelectAll(state.listProducts, checkSelectAll);
            }}
          >
            {checkSelectAll
              ? props.t("products.list.filter.selectAll")
              : props.t("products.list.filter.deselectAll")}
          </Button>
        </Grid>
      ) : null}
      <ListingWrapper>
        <Box>
          {props.inAccordion ? (
            <Grid container>
              <AccordionCustom forcedExpanded={true} title={props.inAccordion}>
                {_renderListing(cardSize, productBypage, headCells)}
              </AccordionCustom>
            </Grid>
          ) : (
            _renderListing(cardSize, productBypage, headCells)
          )}
        </Box>
      </ListingWrapper>
      <LayoutBuilder
        isSublayout={false}
        opened={state.openFormExport}
        icomoon={"ico-import-fichier"}
        forClose={() => handleToggleDrawer("openFormExport")}
        dataLayout={exportProductsConfig(props.locales, "products", props.t)}
        drawerWidth={state.drawerWidth}
        // handleCancel={handleCancel}
        handlerMutation={handlerMutationExport}
        allState={state}
        stateCallback={stateCallback}
        validateButton={true}
        errorCallback={handleFormError}
        checkError={() => {}}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    locales: state.locales,
    attributes: state.attributes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withApollo(
    withRouter(
      withStyles(styles)(
        connect(mapStateToProps, mapDispatchToProps)(ListingProducts)
      )
    )
  )
);
