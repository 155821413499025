import {FORM} from '../../../../../js/constants/form-types';
import { getTraductionAttributs } from '../../../../../js/utils/functions';
import Button from '../../../../ui/button/Button';

export default function formLookBookDuplicate(allState) {
    let generalInputs   = [
        {
            type: 'select',
            isContainerized: true,
            label: 'Asset cible',
            helper: {
                text: 'Choisissez un asset pour la duplication',
                link: false,
            },
            required: true,
            multiselect: false,
            stateName: 'idAssetSelected',
            value: allState.listCatalogsDuplicate?.filter(e => e.node.id !== allState.idAssetToDuplicate).map(asset => {
                let defaultName = getTraductionAttributs('asset_store_name', asset.node.assetDatas.edges, allState.currentLang);
                return ({
                    value: asset.node.id,
                    label: defaultName || asset.node.identifier
                });
            })
        }
    ]
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: 'Dupliquer un catalogue',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour dupliquer votre catalogue`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations générales',
            },
            // finalStep: {
            //     title: 'Et voilà !',
            //     subtitle: 'Valider la duplication de ce catalogue',
            //     textButton: 'Valider'
            // },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}