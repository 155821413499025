import { FormInputTypes } from "../../../../../../builder/shareable/types"

export const listSettings = {
    table : {
        style : {
            type : 'classic',
        },
        columns : [
            // {
            //     label   : 'Nom du message',
            //     field   :  'name',
            //     cellType : 'text',
            //     cellProps : {
            //         width : 'auto',
            //     }
            // },
            {
                label   : 'Statut',
                field   :  'status',
                cellType : 'status',
                cellProps : {
                    width : 'auto',
                }
            },
            {
                label   : 'Name',
                field   :  'name',
                hasTooltip : true,
                limitText : 1,
                cellType : 'text',
                cellProps : {
                    width : '20%',
                    fontWeight : 'bold'
                }
            },
            {
                label   : 'Date de début:',
                field   :  'startAt',
                cellType : 'date',
                cellProps : {
                    width : 'auto',
                }
            },
            {
                label   : 'Date de fin:',
                field   :  'endAt',
                cellType : 'date',
                cellProps : {
                    width : 'auto',
                }
            },
            
        ]   
    }
}

export const listMappers = [
    {
        view : 'tableSimple',
        mapper : 'topMessagesMapper'
    }
]

export const perPageOptions = {
    'tableSimple' : [30,50,100]
}


export const filtersOptions = {
    title: 'Filtres et Recherche',
    inputs: [
        {
            xs:2,
            required: false,
            disabled: false,
            topLabel:"Par statut",
            type: FormInputTypes.SELECT,
            stateName: 'status',
            helper: {
                position: 'top',
                text: null,
                link: false,
            },
            value:[
                {
                    label:"Tous les statuts",
                    value:"all",
                },
                {
                    label:"Actif",
                    value:true,
                },
                {
                    label:"Inactif",
                    value:false,
                },
            ]
        },
        {
            xs: 10,
            required: false,
            disabled: false,
            topLabel:"Taper votre recherche",
            label:'Recherche par nom',
            type: FormInputTypes.TEXT,
            stateName: 'libelle',
            helper: {
                position: 'top',
                text: null,
                link: false,
            }
        },
    ]
}