import React, { useState, useRef } from "react";
import H1 from '../../../../shared/components/CustomH1';
import Button from '../../../../shared/components/CustomButton';
import Swiper from "react-id-swiper";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LinkCustom } from "../_next/navigation";
import * as ga from '../../../../../tools/GA';

const SliderProduct = ({
    preview = false,
    id = null,
    data = null,
    inputCallback = null,
    children = null,
    products = [],
    spread = false,
    params = null
}) => { 
    const [hover, setHover] = useState(false);
    const [update, setUpdate] = useState(0);
    const ref = useRef(null);

    let swiperParams = {
        loop: false,
        speed: 750,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            480: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,
            },
            1000: {
                slidesPerView: 3,
            }
        },
        spaceBetween: 20,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        shouldSwiperUpdate: true,
        pagination: false,
    };

    const styles = {
        backgroundColor: '#eee',
        width: '100%',
        boxSizing: 'border-box'
    };

    let slides = data.blocks['slides'].config;

    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();

            if (typeof Math !== "undefined")
                setUpdate(Math.random());
        }
    };
     
    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();

            if (typeof Math !== "undefined")
                setUpdate(Math.random());
        }
    };

    let color = data.blocks['slides'].inputs?.color?.value ?? '#212121';
    let category = data.inputs?.category;

    return(
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children}

            <div className="container slider-product">
                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                        </div>

                        <Swiper {...swiperParams} ref={ref}>
                            {slides && slides.map((single, key) => {
                                let productId = +single.inputs.product.value?.item.id;
               
                                let titleCat = null;
                                let imgCat = null;
                                let refCat = null;
                                let priceCat = null;

                                let product = products.find(product => product.id === productId);

                                if (product) {
                                    titleCat = product?.attributes?.product_name?.fr_FR;
                                    refCat = product?.sku;
                                    priceCat = product?.attributes?.product_price?.fr_FR;
                                    imgCat = product?.attributes?.product_image?.fr_FR 
                                        ? `${process.env.REACT_APP_MEDIAS}/${product?.attributes?.product_image?.fr_FR}`
                                        : null;
                                }

                                return (
                                    <div className="card" key={`slide-${key}`}>
                                        <div className="slider-image">
                                            <img src={imgCat ? imgCat : "https://via.placeholder.com/300"} alt="produits" />
                                        </div>
                                        <div className="card-text">
                                            { refCat ? <p className="ref-product">{refCat}</p> : <p className="ref-product"></p> }
                                            { titleCat ? <p className="title-product">{titleCat}</p> :  <p className="title-product"></p>}
                                            { priceCat ? <p className="price">{`${priceCat} €`}</p> : <p className="price"></p>}
                                            
                                            <LinkCustom 
                                                spread={spread}
                                                href="/product/[slug]" 
                                                as={`/product/${productId}`}
                                                external={params?.isExternal?.value}
                                            >
                                                <Button 
                                                    data={data.blocks.slides.config[key]}
                                                    preview={preview} 
                                                    component={id} 
                                                    block={'card_button'} 
                                                    // inputCallback={inputCallback} 
                                                    onClick={() => {
                                                        ga.pageview(product?.sku, product?.retailerUrl);
                                                        
                                                        // ga.product(product?.sku, titleCat);

                                                        // ga.event({
                                                        //     action: 'view_item',
                                                        //     params: {
                                                        //         items: [{
                                                        //             id: product?.sku,
                                                        //             name: titleCat
                                                        //         }]
                                                        //     }
                                                        // });

                                                        if (!spread && params?.isExternal?.value) {
                                                            try { window.parent.location.href = product?.retailerUrl; } 
                                                            catch (e) { console.log(e); }
                                                        }
                                                    }}
                                                />
                                            </LinkCustom>
                                        </div>
                                    </div>
                                );
                            })}
                        </Swiper>
                        <div className="button">
                            <LinkCustom 
                                spread={spread}
                                href="/category/[slug]" 
                                as={`/category/${category?.value?.replace('/api/categories/', '')}`}
                            >
                                <a>
                                    <Button 
                                        data={data} 
                                        preview={preview} 
                                        component={id} 
                                        block={'button'} 
                                        inputCallback={inputCallback} 
                                    />
                                </a>
                            </LinkCustom>
                        </div>
                    </div>

                    <div className="pagination">
                        {!ref.current?.swiper.isBeginning && (
                            <div className="prev" onClick={goPrev}>
                                <ChevronLeftIcon style={{ fontSize: 40, color: color }} />
                            </div>
                        )}
                        {!ref.current?.swiper.isEnd && (
                            <div className="next" onClick={goNext}>
                                <ChevronRightIcon style={{ fontSize: 40, color: color }} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SliderProduct;