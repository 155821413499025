export const importsTableMapper = async (flows,lang) =>{
    try {
        let results = flows.map((flow,index)=>{
            return {
                id:flow.node.id,
                importedAt: flow.node.importedAt,
                productSource: flow.node.productSource,
                products:flow.node.products,
                scrapStatus:flow.node.scrapStatus,
                totalImported:flow.node.totalImported,
                totalRow:flow.node.totalRow,
                url:flow.node.url,
                user:flow.node.user?flow.node.user?.firstname + " " + flow.node.user?.lastname:null,
                type:flow.node.eavType?.code,
            }
        })
        return await results
    } catch (error) {
        console.log(error)   
    }
}