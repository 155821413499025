import React from "react";
import styled from "styled-components";

import { Box, Grid, Typography } from "@mui/material";
import OurMenu from "@ui/menu/Menu";
import { convertToRgba } from "../../../../js/utils/string.utils";
import PropTypes from "prop-types";

const BigImage = styled(Box)`
  position: relative;
  width: 100%;
  padding: 14px;
`;
const ChipCustom = styled(Box)`
  border-radius: 0px;
  background-color: ${(props) => (props.color ? props.color : "#FFFFFF")};
  display: block;
  height: 120px;
`;

export default function CardColor({ actionButtons, color, markups }) {
  const rgba = color.rgba ? color.rgba : convertToRgba(color.hex);

  return (
    color && (
      <BigImage>
        <Box>
          <ChipCustom
            style={{ transition: "all ease 500ms" }}
            color={color.hex}
          />
        </Box>
        <Box style={{ padding: "16px 0px 16px 5px" }}>
          <Grid container justifyContent={true ? "space-between" : "flex-end"}>
            <Typography
              fontweighttext={700}
              style={{
                fontSize: "25px",
              }}
              sx={{
                fontWeight: 700,
                fontSize: "25px",
              }}
            >
              {color.hex}
            </Typography>

            {actionButtons && (
              <OurMenu
                menuOptions={actionButtons}
                handleClick={(e, item) => item.action(e)}
              />
            )}
          </Grid>
          <Box mt={"20px"}>
            <Grid
              container
              style={{
                marginBottom: "10px",
              }}
            >
              <Grid item xs={6}>
                <Typography style={{ fontSize: "15px" }}>RVB :</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                >
                  {rgba.r}, {rgba.g}, {rgba.b}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                marginBottom: "10px",
              }}
            >
              <Grid item xs={6}>
                <Typography style={{ fontSize: "15px" }}>HEX :</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                >
                  {color.hex}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography style={{ fontSize: "15px" }}>
                  Assigné à :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                >
                  {markups?.length > 0 &&
                    markups.map((markup, index) => {
                      return (
                        <span key={index}>
                          {markup.libelle}
                          {index !== markups.length - 1 ? ", " : ""}
                        </span>
                      );
                    })}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </BigImage>
    )
  );
}

CardColor.propTypes = {
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      color: PropTypes.string,
      icon: PropTypes.element,
    })
  ),
  color: PropTypes.shape({
    hex: PropTypes.string.isRequired,
  }),
  markups: PropTypes.arrayOf(
    PropTypes.shape({
      libelle: PropTypes.string,
    })
  ),
};