import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlidePresentationMedia, getDefaultTextCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider présentation médias',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.TEXT,
            value: '#FFF'
        },
        seeMore: {
            label: 'Texte voir plus',
            type: FormInputTypes.TEXT,
            value: "Voir plus",
        },
        seeLess: {
            label: 'Texte voir moins',
            type: FormInputTypes.TEXT,
            value: "Voir moins",
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Histoire", null),
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA,
            buttonAdd: 'une slide',
            limit: 100,
            config: [ 
                getDefaultSlidePresentationMedia(),
                getDefaultSlidePresentationMedia(),
                getDefaultSlidePresentationMedia(),
            ]
        }
    }
};