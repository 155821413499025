import { MenuItem, Select } from "@mui/material";
import styled from "styled-components";
import colors from "../../../../../../config/theme/colors";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    select: {
        marginTop: 10,
        "& ul": {
            // backgroundColor: 'r',
            padding: 0,

        },
        "& li": {
            fontSize: 14,
            color: colors.blue.darker.hue300
        },
        "& li.MuiListItem-root.Mui-selected, & li.MuiListItem-root.Mui-selected:hover": {
            backgroundColor: colors.blue.darker.hue300,
            color: colors.white
        },
        "& li.MuiListItem-root:hover": {
            backgroundColor: colors.blue.darker.hue900,
            // color: colors.white
        },
    },
});

export const NotContainerizedSelectWrapper = styled(Select)`
fieldset{
    border: 1px solid black !important;
}
input, .MuiSelect-select{
    font-size: 14px;
    padding: 4px 16px !important;
    margin-right: 16px;
    color: ${colors.black.regular};
    font-weight: bold;
    border-radius: 0;
    &:focus{
        background: none;
    }
    @media screen and (max-width: 1450px){
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (max-width: 1280px){
        font-size: 13px;
        line-height: 17px;
    }
    @media screen and (max-width: 960px){
        font-size: 12px;
        line-height: 16px;
    }
}
svg{
    fill: ${colors.black.regular};
}
& > p{
    margin: 0;
}
`;

export const SelectFieldCustom = styled(Select)`
    justify-self: flex-start;
    position: relative;

    & > label {
        font-weight: bold;
        transform: translate(13px, 14px);
        color: ${colors.black.regular} !important;
    }
    input, .MuiSelect-select{
        box-sizing: border-box;
        height: ${props => props.custom?.height ?? '45px'};
        /* height: 45px; */
        font-weight: bold;
        font-size: 14px;
        padding: ${props => props.custom?.padding ?? '14px'} !important;
        border-radius: 0;
        border-color: ${colors.grey.border};
        background-color: white;
        & ~ fieldset {
            border: 1px solid ${colors.grey.border} !important;
            top: 0px;

            & legend {
                display: none;
            }
        }
        
        & ~ fieldset[aria-expanded="true"] {
            border: 10px solid ${colors.grey.border} !important;

            & legend {
                display: none;
            }
        }

        @media screen and (max-width: 1450px){
            font-size: 14px;
            padding: 14px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            padding: 13px;
        }
        @media screen and (max-width: 1000px){
            font-size: 13px;
            padding: 13px;
        }
        padding: ${props => props.custom?.padding};
        
    }
    & > p{
        margin: 0;
    }
`;

export const MenuItemCustom = styled(MenuItem)`
    color: ${colors.black.regular};
    height: 35px;
    
    &:hover {
        background-color: ${colors.blue.lighter.hue900} !important;
        color: ${colors.blue.darker.hue300};
        font-weight: bold;
    }

    &.Mui-selected{
        background-color: unset !important;
        color: black !important;
        font-weight: bold;
        opacity: 1;

        &:hover {
            color: black;
            font-weight: bold;
        }
    }


    &:nth-child(even){
        background-color: ${props => 
            props.total
                ? (props.total > 2 ? colors.grey.lighter.hue980 : 'unset') + ' !important'
                : colors.grey.lighter.hue980} !important;
    }
`;

export const SelectInline = styled(Select)`
    &:before,&:after{
        display: none;
    }
    .MuiSelect-select{
        padding-bottom: 0px !important;
        color: ${colors.blue.darker.hue300};
        font-weight: bold;
        &:focus{
            background: none;
        }
    }
    svg{
        top: calc(50% - 0.4em) !important;
    }
`

export const MenuItemInline = styled(MenuItem)`
    /* color: colors.blue.darker.hue300; */
    color:${colors.blue.darker.hue900} !important;
    &:hover{
        background-color: ${colors.blue.lighter.hue900} !important;
        color: ${colors.blue.darker.hue300};
    }
    &.Mui-selected{
        background-color: ${colors.blue.lighter.hue900} !important;
        color: ${colors.blue.darker.hue300};
    }
    .MuiPaper-root{
        .MuiList-root{
            .MuiButtonBase-root.MuiMenuItem-root{
                color: #0273A5 !important;
                /* background-color: ${colors.white} !important; */
            }
            .MuiButtonBase-root.MuiMenuItem-root.Mui-selected{
                color: #0273A5 !important;
                background-color: ${colors.blue.lighter.hue600} !important;
            }
        }
    }
`