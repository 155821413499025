import styled from "styled-components";
import colors from "../../../../config/theme/colors";
import pngBackground from '../../../../assets/images/medias/pixelBackground.jpg'
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

export const useStyles = makeStyles((theme) => ({
  asset: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
      outline: 'none',
  },
}));

export const ModalWrapperCustom = styled(Box)`
    display: flex;
    flex-direction: column;
    border: none;
    outline: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    max-width: 1150px;
    max-height: 700px;
    background-color: white;
    z-index: 999;
    overflow: auto;
`;