import React, { useState } from "react";
import styled from "styled-components";

const DivPlaceholder = styled.div`
    height: 500px;
    background-color: #eee;
`;

const PlaceholderGame = (props) => {
    const [hover, setHover] = useState(false);
    
    return (
        <DivPlaceholder 
            id="placeholder-game"
            style={{position: 'relative'}}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {hover && props.toolbarSection}
        </DivPlaceholder>
    );
};

export default PlaceholderGame;
