import { Menu, MenuItem } from "@mui/material";
import styled from "styled-components";
import colors from "../../../../config/theme/colors";
import { makeStyles } from "@mui/styles";

export const MenuCustom = styled(Menu)`
    & .MuiMenu-paper:before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      right: 29px;
      width: 20px;
      height: 20px;
      transform: translateY(-50%) rotate(45deg);
      background-color: white;
      border-top: ${props => '1px solid ' + colors.grey.border};
      border-left: ${props => '1px solid ' + colors.grey.border};
    }
    
    & .MuiMenu-paper {
      overflow: visible;
      margin-top: 15px;
      margin-left: 30px;
      border-radius:0;
      box-shadow:none;

      & .MuiList-root {
        margin: 0px;
        padding: 0px;
      }
    }
`

export const MenuItemCustom = styled(MenuItem)`
    & {
      min-width: 230px;
      background: ${props => props.isDisabled ? '#e0e0e0' : 'white'} !important;
      cursor: ${props => props.isDisabled ? 'initial' : 'pointer'};

      span, p{
        color: ${props => props.isDisabled ? colors.grey.lighter.hue600 : props.colorhover} !important;
        /* color: ${props => props.isDisabled ? `${colors.grey.lighter.hue600}` : colors.black.regular} !important; */
      }
      svg{
        fill: ${props => props.isDisabled ? colors.grey.lighter.hue600 : props.colorhover} !important;
      }
    }

    &:hover, &:focus{
        background-color: ${props => props.isDisabled ? '#e0e0e0' : `${props.colorhover}`} !important;
        span, p{
          color: ${props => props.isDisabled ? colors.grey.lighter.hue600 : colors.white} !important;
        }
        svg{
          fill: ${props => props.isDisabled ? colors.grey.lighter.hue600 : colors.white} !important;
        }
    }

    & > ul {
      margin: 0px;
      padding: 0px;
    }
`

export const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid #d3d4d5',
    boxShadow: 'none',
    borderRadius: '0px',
  }
}));