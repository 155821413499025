import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from '../../../../../shared/components/CustomLink';

const RedirectOn = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data,
        inputCallback = null,
    } = props;

    let image = data?.inputs['backgroundImage'].value;

    return (
        <div className="results" style={{position: "relative"}} >
            { !preview && props.children }
            <section 
                className="bandeau2"
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: 'cover!important',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                }}
                
            >
                <div className="block">
                    <img className="img_center" onClick={() => inputCallback(id, 'logo')} src={data.blocks.logo.inputs.value?.value?.filePath  ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` : typeof(data.blocks.logo.inputs.value?.value) === "string" ? data.blocks.logo.inputs.value.value : "http://via.placeholder.com/500x300/CCCCCC/AAAAAA?text=Logo"} />
                </div>
                <div className="block">
                    <img className="img_center" onClick={() => inputCallback(id, 'textImg')} src={data.blocks.textImg.inputs.value?.value?.filePath  ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.textImg.inputs.value.value.filePath}` : typeof(data.blocks.textImg.inputs.value?.value) === "string" ? data.blocks.textImg.inputs.value.value : "http://via.placeholder.com/500x300/CCCCCC/AAAAAA?text=Logo"} />
                </div>
                <div className="block">
                    <img className="img_center" onClick={() => inputCallback(id, 'image')} src={data.blocks.image.inputs.value?.value?.filePath  ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` : typeof(data.blocks.image.inputs.value?.value) === "string" ? data.blocks.image.inputs.value.value : "http://via.placeholder.com/500x300/CCCCCC/AAAAAA?text=Logo"} />
                </div>
                <div className="block">
                    <Button data={data} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="as_button" nopadding={true} />
                </div>
                <div className="block_absolute">
                    <img className="img_center" onClick={() => inputCallback(id, 'logo2')} src={data.blocks.logo2.inputs.value?.value?.filePath  ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo2.inputs.value.value.filePath}` : typeof(data.blocks.logo2.inputs.value?.value) === "string" ? data.blocks.logo2.inputs.value.value : "http://via.placeholder.com/500x300/CCCCCC/AAAAAA?text=Logo"} />
                </div>
            </section>
        </div>
    );
};

RedirectOn.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default RedirectOn;
