import { withTranslation } from "react-i18next";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../js/constants/action-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from '@apollo/client/react/hoc';
import AccordionCustom from "../../layouts/Accordion/AccordionCustom";
import InputBuilder from "../form/InputBuilder";
import Grid from "@mui/material/Grid"
import styled from "styled-components";
import { ChipCustom } from "../form/styles/inputStyled";
import CloseIcon from '@mui/icons-material/Clear';
import colors from "../../../config/theme/colors";

const GridCustom = styled(Grid)`
    .input-with-border {
        margin-bottom: unset;
    }
    & > div {
        margin-bottom: 0px;
    }
`;

const Filters = props => {
    const { options, state, handleChange, dynamicFilters, handleRemoveDynamicFilter, activeFilter } = props;
    return (
        <AccordionCustom
            title={options?.title ?? "Filtres et Recherche"}
            defaultExpanded={false}
        >
            <GridCustom container spacing={1}>
                {
                    options?.inputs?.map((input, i) => {
                        return <InputBuilder margin={"0px !important"} key={i} xs={input.xs} value={
                            // state[input.stateName] === undefined ? 'all' : state[input.stateName]
                            state[input.stateName]
                        } submit={"onKeyChange"} padding={"12px"} style={{ marginBottom: "0px !important" }} input={input} stateCallback={(evt, type) => {
                            handleChange(evt, input, input.type, input)
                        }} />
                    })
                }
                {
                    dynamicFilters &&
                        <>
                            {
                                activeFilter && dynamicFilters.length > 0 &&
                                options.inputs?.map((input, i) => {
                                    if (i !== options.inputs.length - 1) {
                                        return <Grid item xs={input.xs} key={i} />
                                    }
                                    return null
                                })
                            }
                            {
                                activeFilter && dynamicFilters.length > 0 &&
                                    <Grid item xs={"auto"}>
                                        {
                                            dynamicFilters.map((filter, i) => {
                                                return filter.label ? (
                                                    <ChipCustom
                                                        style={{
                                                            fontWeight: " bold",
                                                        }}
                                                        key={i}
                                                        label={filter.label}
                                                        onDelete={(e) => handleRemoveDynamicFilter(filter)}
                                                        deleteIcon={<CloseIcon sx={{ fontSize: 12, color: colors.black.regular }} />}
                                                    />
                                                ) : null
                                            })
                                        }
                                    </Grid>
                            }
                        </>
                }
            </GridCustom>
        </AccordionCustom>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) =>
            dispatch({ type: SNACK, payload: { type, message } }),
    };
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Filters))));