import React from 'react';
import { withRouter } from "react-router-dom";
import { Box, Grid } from '@mui/material';
import Typography from '../../../ui/typography/Typography';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCatalog from '../../../layouts/Card/cardContent/CardCatalog';
import imgNotFound from '../../../../assets/images/not-found.png';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as moment from 'moment';
import { EPHEMERAL_ASSET } from '../../../../js/constants/assets-types';
import LinearProgress from '@mui/material/LinearProgress';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../assets/selection.json";
import CardAssets from './CardAssets';
import CardSetup from './CardSetup';
import { withTranslation } from 'react-i18next'

const GridCatalog = styled(Grid) `
    height: 100%;
`;
const ContainerStyled = styled(Grid)`
    padding-bottom: 0.5rem;
`;

const CampaignTitle = styled(Typography)`
    color: ${colors.black.regular};
`;
const CampaignDate = styled(Typography)`
    color: ${colors.grey.regular};
    text-transform: uppercase;
    margin-left: ${props => props.marginleft};
`;

const Subtitle = styled(Typography)`
    line-height: 19px;
    margin-bottom: 0.5rem;
    font-size: 14px;
`;

const Description = styled(Typography)`
    color: ${colors.grey.regular};
    p{
        margin: 0;
        &:first-child{
            margin-top: 0;
        }
        margin-top: 8px;
    }
`;

const StatusBox = styled(Box)`
    background: ${props => props.status ? colors.green.regular : '#CF3657'};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
`;
const ChipAssets = styled(Box)`
    border-radius: 16px;
    background: ${props => props.typeasset === 'permanent' ? colors.blue.darker.hue300 : colors.purple.regular};
    padding: 6px 24px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    p{
        font-size: 14px;
        font-weight: bold;
    }
`;

const ProgressButton = styled(Box)`
    height: 18px;
    width: 18px;
    top: -3px;
    background: ${props => props.colorprogress};
    border: 3px solid ${colors.white};
    position: absolute;
    border-radius: 50%;
`;
const LinearProgressCustom = styled(LinearProgress)`
    background-color: ${colors.grey.lighter.hue900};
    height: 12px;
    border-radius: 34px;
    .MuiLinearProgress-bar1Determinate{
        background-color: ${props => props.colorprogress};
        border-radius: 34px;
    }
`;
const InfoList = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &:not(:last-child){
        border-bottom: 1px solid ${colors.grey.lighter.hue800};
        padding-bottom: 16px;
    }
`;

const NoImageWrapper = styled(Box)`
    background: ${colors.grey.regular};
    color: white;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    text-align: center;
    font-size: 20px;
    line-height: 46px;
`;
const WrapperImage = styled(Box)`
    border-radius: 50%;
    height: 48px;
    width: 48px;
    margin-right: 8px;
    overflow: hidden;
    position: relative;
    img{
        height: 100%;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }
`;

const CampaignItemImage = styled(Box)`
    background: url(${props => props.backgroundimage});
    height: 110px;
    width: 100%;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    transition:all .5s;
`;

const LineColor = styled(Box)`
    background: ${colors.blue.darker.hue300};
`

const GridCustom = styled(Grid)`
    // display: flex;
    // flex-wrap: wrap;
    // padding: 0.5rem;
`;

const AccordionCustom = styled(Accordion)`
    box-shadow: none;
`;
const AccordionDetailsCustom = styled(AccordionDetails)`
    background: ${colors.grey.lighter.hue980};
`;


class CardCampaignDetails extends React.Component{
    render() {
        const isTablet = window.innerWidth <1280;
        const campaign             = this.props.campaign;
        let image= '';
        let previewImage = campaign.campaignImage?.filePath

        image   =  previewImage 
                        ? `${process.env.REACT_APP_MEDIAS}/${previewImage}`
                        : imgNotFound

        let local = moment.tz.guess();
        let toDateStart = null;
        let toDateEnd = null;
        if (campaign.campaignStartAt){
            toDateStart = moment.tz(campaign.campaignStartAt, local).format('L');
        }
        if (campaign.campaignEndAt){
            toDateEnd = moment.tz(campaign.campaignEndAt, local).format('L');
        }
        let diffDate = moment.tz(campaign.campaignEndAt, local).diff(moment.tz(campaign.campaignStartAt, local), 'days');
        let diffDateActual = moment().diff(moment.tz(campaign.campaignStartAt, local), 'days');
        let diffDateEnd = moment.tz(campaign.campaignEndAt, local).diff(moment.tz(), 'days');
        let progressActual = Math.round((100*diffDateActual)/ diffDate);
        let actualDate = moment().format('L');
        if (progressActual > 100){
            progressActual = 100
        }
        // if (progressActual === 'Infinity'){
        //     progressActual = 100
        // }
        return (
            <div style={{width: '100%'}}>
                <CardCustom contentpadding={'0'} >
                    <Grid container direction="column" justifyContent="center" spacing={0}>
                    <GridCustom item lg={12} md={12} xs={12}>
                        <Box width={'100%'}>
                                <CampaignItemImage
                                backgroundimage={image}
                                />
                        </Box>
                        <LineColor height={8} width={"100%"}/>
                        <Box pl={4} pr={4} pb={6} pt={4} width={"100%"}>
                            <Grid container>
                                <Grid item xs={this.props.openForm || isTablet ? 12 : 6} style={{paddingRight: this.props.openForm ? 0 : 24}}>
                                    <ContainerStyled
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems={'flex-start'}
                                    >
                                        <Grid item xs={12}>
                                            <Grid container alignItems="flex-start" direction="column">
                                                <Grid container justifyContent={'space-between'} alignItems='flex-start'>
                                                    <Box style={{display: 'flex', alignItems: 'center'}}>
                                                        <IcomoonReact iconSet={iconSet} color={colors.blue.darker.hue300} size={33} icon={'icon-campagne'} />
                                                        <CampaignTitle variant="h1" fontweighttext={'bold'} style={{marginLeft: 8}}>
                                                            {campaign.campaignName}
                                                        </CampaignTitle>
                                                    </Box>
                                                    <StatusInfo status={campaign.campaignStatus} width={'auto'}/>
                                                </Grid>
                                                <InfoList mt={3} style={{marginTop: 24}}>
                                                    <Description variant="body1" component="div" dangerouslySetInnerHTML={{ __html: campaign.campaignDescription }}/>
                                                </InfoList>
                                                <InfoList mt={3} style={{borderBottom: 'none'}}>
                                                    <Box>
                                                        <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem'}}>
                                                            {this.props.t("spread.campaigns.startDate")}
                                                        </Subtitle>
                                                        <CampaignDate variant="body1" colortext={colors.grey.regular} component="div">
                                                            {toDateStart}
                                                        </CampaignDate>
                                                    </Box>
                                                    <Box>
                                                        <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem'}}>
                                                            {this.props.t("spread.campaigns.endDate")}
                                                        </Subtitle>
                                                        <CampaignDate variant="body1" colortext={colors.grey.regular} component="div">
                                                            {toDateEnd}
                                                        </CampaignDate>
                                                    </Box>
                                                </InfoList>
                                                <Box style={{width: '100%', position: 'relative'}}>
                                                    <LinearProgressCustom variant="determinate" value={diffDateEnd < 0 ? 100 : progressActual < 0 ? 0 : progressActual > 100 ? 100 : progressActual} colorprogress={diffDateEnd < 0 ? colors.red.regular : progressActual < 0 ? colors.green.regular : progressActual > 100 ? colors.red.regular : colors.green.regular}/>
                                                    <ProgressButton colorprogress={diffDateEnd < 0 ? colors.red.regular : progressActual < 0 ? colors.green.regular : progressActual > 100 ? colors.red.regular : colors.green.regular} style={{left: `${diffDateEnd < 0 ? 98 : progressActual < 0 ? 0 : progressActual > 100 ? 98 : progressActual >= 2 ? progressActual - 2 : 0 }%`}} />
                                                </Box>
                                                <Box mt={1} pb={2} style={{width: '100%', borderBottom: `1px solid ${colors.grey.lighter.hue800}`}}>
                                                    <Box style={{ color: diffDateEnd < 0 ? colors.red.regular : progressActual < 0 ? colors.green.regular : progressActual > 100 ? colors.red.regular : colors.green.regular, width: '100%', transform: `translateX(${diffDateEnd < 0 ? 82 : progressActual < 0 ? 0 : progressActual > 100 ? 75 : progressActual >= 6 ? (progressActual - 6) : 0}%)`}}>{diffDateEnd < 0 ? 'Date de fin dépassé' : progressActual < 0 ? '(Pas encore commencée)' : progressActual > 100 ? actualDate + ' (Délai dépassé)' : actualDate}</Box>
                                                </Box>
                                                <InfoList mt={3}>
                                                    <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'}>
                                                        I.D. :
                                                    </Subtitle>
                                                    <Typography variant="h4" colortext={colors.black.regular}>{campaign.campaignIdentifier}</Typography>
                                                </InfoList>
                                                <InfoList mt={3}>
                                                    <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'}>
                                                        Google GTM :
                                                    </Subtitle>
                                                    <Typography colortext={colors.black.regular}>{campaign.campaignGTM}</Typography>
                                                </InfoList>
                                                {/* <InfoList mt={3}>
                                                    <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'}>
                                                        Autosync :
                                                    </Subtitle>
                                                    <Typography colortext={colors.black.regular}>{campaign.campaignAutosync ? 'Actif' : 'Inactif'}</Typography>
                                                </InfoList>  */}
                                            </Grid>
                                        </Grid>
                                    </ContainerStyled>
                                </Grid>
                                <Grid item xs={this.props.openForm || isTablet ? 12 : 6} style={{paddingLeft: this.props.openForm ? 0 : 24, background: colors.grey.lighter.hue980, marginTop: this.props.openForm ? 24 : 0}}>
                                    <ContainerStyled
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems={'flex-start'}
                                    >
                                        <Box width={'100%'} style={{padding: '32px 32px 10px 8px'}}>
                                            <Grid container style={{paddingBottom: 40}}>
                                                <Box width={'100%'} style={{display: 'flex', borderBottom: `1px solid ${colors.grey.lighter.hue800}`}}>
                                                    <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={20} icon={'icon-catalogue'} />
                                                    <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem', marginLeft: 8}}>
                                                        {this.props.allState.catalogSelected.length > 1 ? 'Catalogues' : 'Catalogue'}
                                                    </Subtitle>
                                                </Box>
                                                <Grid container style={{marginTop: 8}}>
                                                    {
                                                        this.props.allState.catalogSelected.length > 0 ?
                                                        this.props.allState.catalogSelected.map((catalog, index) => {
                                                            let image = null;
                                                            if(catalog.node.media){
                                                                image = `${process.env.REACT_APP_API_ROOT}/medias/${catalog.node.media.filePath}`;
                                                            }
                                                            return(
                                                                <Grid container style={{marginTop: 8}} key={`CatalogsSelected${index}`}>
                                                                    <Box style={{display: 'flex', alignItems: 'center'}}>
                                                                        {image ?
                                                                            <WrapperImage>
                                                                                <img src={image} />
                                                                            </WrapperImage>
                                                                            : <NoImageWrapper style={{marginRight: 8}}>C</NoImageWrapper>
                                                                        }
                                                                        <Typography colortext={colors.blue.darker.hue300}>{catalog.node.libelle}</Typography>
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        })
                                                        : <Typography>Pas encore de catalogue selectionné</Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{paddingBottom: 40}}>
                                                <Box width={'100%'} style={{display: 'flex', borderBottom: `1px solid ${colors.grey.lighter.hue800}`}}>
                                                    <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={20} icon={'picto-retailer'} />
                                                    <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem', marginLeft: 8}}>
                                                        {this.props.allState.retailersSelected.length > 1 ? 'Retailers' : 'Retailer'}
                                                    </Subtitle>
                                                </Box>
                                                <Grid container style={{marginTop: 8}}>
                                                    {
                                                        this.props.allState.retailersSelected.length > 0 ?
                                                        this.props.allState.retailersSelected.map((retailer, index) => {
                                                            if(retailer.node.logo){
                                                                image = `${process.env.REACT_APP_API_ROOT}/${retailer.node.logo}`;
                                                            }else{
                                                                image = imgNotFound;
                                                            }
                                                            return(
                                                                <Grid style={{marginTop: 8, marginRight: 8}} key={`RetailersSelected${index}`}>
                                                                    <CardCustom cardContentStyle={{display: 'flex', alignItems: 'center'}} contentpadding={'16px'}>
                                                                        <img style={{maxHeight: '48px', marginRight: 8}} src={image} />
                                                                        <Typography colortext={colors.black.regular}>{retailer.node.libelle}</Typography>
                                                                    </CardCustom>
                                                                </Grid>
                                                            )
                                                        })
                                                        : <Typography>Pas encore de retailer selectionné</Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{paddingBottom: 40}}>
                                                <Box width={'100%'} style={{display: 'flex', borderBottom: `1px solid ${colors.grey.lighter.hue800}`}}>
                                                    <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={20} icon={'icon-asset'} />
                                                    <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem', marginLeft: 8}}>
                                                        {this.props.allState.assetsSelected.length > 1 ? 'Assets' : 'Asset'}
                                                    </Subtitle>
                                                </Box>
                                                <Grid container style={{marginTop: 8}}>
                                                    {
                                                        this.props.allState.assetsSelected.length > 0 ?
                                                        this.props.allState.assetsSelected.map((asset, index) => {
                                                            return(
                                                                <ChipAssets mt={1} typeasset={EPHEMERAL_ASSET.indexOf(asset.node.assetType.identifier) > -1 ? 'ephemeral' : 'permanent'} key={`AssetsSelected${index}`}>
                                                                    <StatusBox status={asset.node.status}/>
                                                                    <Typography colortext={colors.white}>{asset.node.assetType.libelle} {asset.node.assetGameType ? `- ${asset.node.assetGameType.libelle}` : null}</Typography>
                                                                </ChipAssets>
                                                            )
                                                        })
                                                        : <Typography>Pas encore d'asset selectionné</Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </ContainerStyled>
                                </Grid>
                            </Grid>
                        </Box>
                    </GridCustom>
                </Grid>
            </CardCustom>
            <Grid container style={{marginTop: 16}} spacing={2}>
                <Grid item xs={this.props.openForm || isTablet ? 12 : 6} >
                    <CardCustom contentpadding={'24px 32px 32px 32px'} style={{width: `100%`}}>
                        <Box width={'100%'}>
                            <AccordionCustom>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{padding: 0}}
                                >
                                    <Box style={{display: 'flex'}}>
                                        <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={20} icon={'icon-catalogue'} />
                                        <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem', marginLeft: 8}}>
                                            {this.props.allState.catalogSelected.length > 1 ? 'Catalogues' : 'Catalogue'}
                                        </Subtitle>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetailsCustom>
                                    <Grid container>
                                        {
                                            this.props.allState.catalogSelected.length > 0 ? 
                                                this.props.allState.catalogSelected.map((catalog, index) => {
                                                    return(
                                                        <Grid item xs={12} xl={6} key={`CatalogSelectedCards${index}`}> 
                                                            <Grid container alignItems="flex-start" direction="column">
                                                                <Box style={{width: '100%'}}>
                                                                    <Grid container justifyContent={'center'} style={{background: colors.grey.lighter.hue980, padding: '18px 6px'}}>
                                                                        <GridCatalog container alignItems={'flex-start'} justifyContent={'flex-start'} direction="column">
                                                                            <Box style={{width: "100%", background: colors.white}}>
                                                                                <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0'}>
                                                                                    <CardCatalog 
                                                                                    data={catalog} 
                                                                                    textButton={"Voir le catalogue"}
                                                                                    configCard={true} 
                                                                                    inputCard={true}
                                                                                    windowWidth={this.props.windowWidth} 
                                                                                    openForm={true}
                                                                                    />
                                                                                    {/* <div>{catalog}</div> */}
                                                                                </CardCustom>
                                                                            </Box>
                                                                        </GridCatalog>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            : <Typography>Pas encore de catalogue selectionné</Typography>
                                        }    
                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Box>
                    </CardCustom>
                </Grid>
                <Grid item xs={this.props.openForm || isTablet ? 12 : 6}>
                    <CardCustom contentpadding={'24px 32px 32px 32px'} style={{width: `100%`}}>
                        <Box width={'100%'}>
                            <AccordionCustom>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{padding: 0}}
                                >
                                    <Box style={{display: 'flex'}}>
                                        <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={20} icon={'picto-retailer'} />
                                        <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem', marginLeft: 8}}>
                                            {this.props.allState.retailersSelected.length > 1 ? 'Retailers' : 'Retailer'}
                                        </Subtitle>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetailsCustom>
                                    <Grid container>
                                        {
                                            this.props.allState.retailersSelected.length > 0 ? 
                                                this.props.allState.retailersSelected.map((retailer, index) => {
                                                    return(
                                                        <Grid item xs={12} xl={6} key={`RetailersSelectedCards${index}`}> 
                                                            <Grid container alignItems="flex-start" direction="column">
                                                                <Box style={{width: '100%'}}>
                                                                    <Grid container justifyContent={'center'} style={{background: colors.grey.lighter.hue980, padding: '18px 6px'}}>
                                                                        <GridCatalog container alignItems={'flex-start'} justifyContent={'flex-start'} direction="column">
                                                                            <Box style={{width: "100%", background: colors.white}}>
                                                                                <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0'}>
                                                                                    <CardSetup
                                                                                    type={retailer} 
                                                                                    configCard={true} 
                                                                                    />
                                                                                </CardCustom>
                                                                            </Box>
                                                                        </GridCatalog>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            : <Typography>Pas encore de retailer selectionné</Typography>
                                        }    
                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Box>
                    </CardCustom>
                </Grid>
                <Grid item xs={this.props.openForm || isTablet ? 12 : 6}>
                    <CardCustom contentpadding={'24px 32px 32px 32px'} style={{width: `100%`}}>
                        <Box width={'100%'}>
                            <AccordionCustom>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{padding: 0}}
                                >
                                    <Box style={{display: 'flex'}}>
                                        <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={20} icon={'icon-asset'} />
                                        <Subtitle variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem', marginLeft: 8}}>
                                            {this.props.allState.assetsSelected.length > 1 ? 'Assets' : 'Asset'}
                                        </Subtitle>
                                    </Box>
                                    
                                </AccordionSummary>
                                <AccordionDetailsCustom>
                                    <Grid container>
                                        {
                                            this.props.allState.assetsSelected.length > 0 ? 
                                                this.props.allState.assetsSelected.map((asset, index) => {
                                                    return(
                                                        <Grid item xs={12} xl={6} key={`AssetsSelectedCards${index}`}> 
                                                            <Grid container alignItems="flex-start" direction="column">
                                                                <Box style={{width: '100%'}}>
                                                                    <Grid container justifyContent={'center'} style={{background: colors.grey.lighter.hue980, padding: '18px 6px'}}>
                                                                        <GridCatalog container alignItems={'flex-start'} justifyContent={'flex-start'} direction="column">
                                                                            <Box style={{width: "100%", background: colors.white}}>
                                                                                <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0'}>
                                                                                    <CardAssets
                                                                                    asset={asset} 
                                                                                    currentLang={this.props.currentLang}
                                                                                    configCard={true} 
                                                                                    inputCard={true}
                                                                                    />
                                                                                </CardCustom>
                                                                            </Box>
                                                                        </GridCatalog>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            : <Typography>Pas encore d'asset selectionné</Typography>
                                        }    
                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Box>
                    </CardCustom>
                </Grid>
            </Grid>
        </div>
        );
    }
}
export default withTranslation()(withRouter(CardCampaignDetails));
