import CardContent from '../../../../../layouts/Card/cardContent/CardContent';

export const listSettings = {
    grid: {
        components: CardContent,
        columns: {
            xs: 1,
            sm: 2,
            laptop: 3,
        }
    },
}

export const listMappers = [
    {
        view: 'card',
        mapper: null
    },
    // {
    //     view : 'table',
    //     mapper : 'companiesListMapper'
    // }
]

export const perPageOptions = {
    'card': [12, 24],
}

