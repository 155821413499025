import React, { useEffect, useState } from "react";
import Div from '../shared/div';
import H2 from '../shared/h2';
import H3 from '../shared/h3';
import P from '../shared/p';
import Img from '../shared/img';
import { PictureTag } from "../../../../../utils/image";

const CategoriesHome = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        data,
        spread = false
    } = props;
    
    return (
        <Div style={{position: 'relative'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="bloc-list">
                <H2 className="w100 uppercase">{data.inputs.value.value}</H2>
                <Div className="list-categories">
                    {
                        data.blocks.categories.config.map((categorie, index) => {
                            let image = categorie.blocks.image.inputs.value.value;
                           
                            return (
                                <Div className="relative" key={index}>
                                    <PictureTag
                                        media={image}
                                        alt={categorie.blocks.image.inputs.alt?.value ?? "Illustration"}
                                        spread={spread}
                                        classes="w100"
                                        lazy={true}
                                        isOptimised={data.inputs.optimisedImage?.value}
                                    />
                                    <Div className="absolute gradient-overlay" />
                                    <a href={categorie.blocks.link.inputs.value.value}>
                                        <Div className="absolute">
                                            <H3 className="white uppercase">{categorie.blocks.title.inputs.value?.value}</H3>
                                            <P className="center white">{data.inputs.linkText?.value}</P>
                                        </Div>
                                    </a>
                                </Div>
                            )
                        })
                    }
                </Div>
            </Div>
        </Div>
    );
};

export default CategoriesHome;