import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import RadioCards from '../../../../ui/inputs/RadioCards';
import OfferManager from '../components/OfferManager';
import { ROUTE_CRM_OFFERS_PROJECTS } from '../../../../../js/constants/route-names';



export default function addOffer(currentLang,handleMediaPicker,allCompanies, handleSelectCompany,currentCompanies,formSettings,getAllProjects,typeOfModel, action) {
    let generalInputs = [
        {
            type: 'text',
            label: 'Nom',
            helper: {
                text: 'Indiquez le nom',
                link: false,
            },
            required: true,
            stateName: 'name',
        },
        {
            type: 'text',
            label: 'N°PO',
            helper: {
                text: 'Indiquez le n°PO',
                link: false,
            },
            required: false,
            stateName: 'poNumber',
        },
        {
            type: 'date',
            getTime: true,
            label: 'Date de création',
            helper: {
                text: 'Date de création',
                link: false,
            },
            required: true,
            stateName: 'createdAt',
            // minDate: 'paramStartAt',
        },
        {
            type: 'date',
            getTime: true,
            label: 'Date de validité',
            helper: {
                text: 'Date de validité',
                link: false,
            },
            required: true,
            stateName: 'validityDate',
            minDate: 'createdAt',
        },
        {
            type: 'radio',
            direction: 'row',
            label: 'Actif',
            helper: {
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'status',
            value: [
                {
                    value: true,
                    label: 'Oui'
                },
                {
                    value: false,
                    label: 'Non'
                },
            ]
        },
        {
            type: 'textarea',
            label: 'Description',
            helper: {
                text: 'Indiquez la description de l\'offre',
                link: false,
            },
            required: false,
            stateName: 'description',
        },
        // {
        //     type: 'mediaPicker',
        //     label: 'Image offer',
        //     helper: {
        //         text: 'Indiquez le nom',
        //         link: false,
        //     },
        //     handleMediaPicker : handleMediaPicker,
        //     required: true,
        //     stateName: 'imageDevis',
        // },
        
    ]
    let children = [
        {
            labelName: 'Projet',
            isOptionnal: false,
            component: RadioCards,
            optionsInputs:
            {
                stateName: 'projectSelected',
                // allInfo: true,
                paginationType: 'project',
                buttonSearch : true,
                buttonText: 'Nouveau projet',
                buttonRoute: ROUTE_CRM_OFFERS_PROJECTS,
                inline: true, 
                required: true,
                isEmpty: getAllProjects.length > 0 ? false : true,
                value: getAllProjects.map((values) => {
                    return ({
                        values,
                        smallCard: true
                    });
                })
            }
        },
        {
            labelName: 'Informations',
            isOptionnal: false,
            optionsInputs: generalInputs
        },
        
        {
            labelName: 'Phase du projet',
            isOptionnal: false,
            component : OfferManager,
            componentProps: {
                stateName: 'offerSetup',
            }
        },
    ];

    var obj = {
        titleForm: action === 'add'? 'Créer un devis' : 'Modifier un devis',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add' ? 'créer' : 'modifier'} votre devis`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de ce devis' : 'Valider la modification de ce devis',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children
        }
    };
    return obj;
}