import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import { SET_GUIDELINE } from "@constants/action-types";

import {
  GET_GUIDELINE_BY_ID,
  DELETE_COLOR,
  DELETE_TYPO,
  DELETE_GUIDELINE,
} from "@queries/brand_guideline";

import colors from "@config/theme/colors";

import { Box, Grid } from "@mui/material";
import CardCustom from "@layouts/Card/CardCustom";
import CardMedia from "@layouts/Card/cardContent/CardMedia";
import CardColor from "@layouts/Card/cardContent/CardColor";
import Typography from "@ui/typography/Typography";

import Empty from "@assets/pictos/empty-picto/empty_guidelines.png";

import styled from "styled-components";
import PageLoader from "@ui/loadings/page-loader/PageLoader";
import TopPanel from "@layouts/TopPanel/TopPanel";
import AccordionCustom from "@layouts/Accordion/AccordionCustom";
import OurTypography from "@ui/typography/Typography";
import { withTranslation } from "react-i18next";
import CarouselCards from "@layouts/Carousel/CarouselCards";
import CardTypographies from "@layouts/Card/cardContent/CardTypographies";
import ColorsList from "./components/ColorsList/ColorsList";

import GuidelineForm from "@ui/form/GuidelineForm";
import CustomDrawer from "@layouts/Drawer";
import AccordionHeader from "./components/accordion/AccordionHeader";
import Modal from "@ui/OurModal/OurModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import ReturnLink from "@ui/link/ReturnLink";
import OurMenu from "@ui/menu/Menu";
import { goTo } from "@utils/goTo";
import { ROUTE_SETTINGS_GUIDELINE } from "@constants/route-names";

const Logo = styled(Box)`
  background-color: white;
  background-image: url(${(props) =>
    props.imagechanged
      ? (props) => props.backgroundimage
      : props.backgroundimage});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 170px;
  width: 170px;
  margin: auto;
`;
const BoxPart = styled(Box)`
  background: ${colors.grey.lighter.hue980};
  margin-bottom: 32px;
`;

const GuidelineWrapper = styled(Grid)`
  & + .layout-wrapper {
    display: none;
  }
`;

const carouselCardsParams = {
  slidesNb: 4,
  direction: "horizontal",
  spaceBetween: 5,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
};

const GuidelineDetails = (props) => {
  const [state, setState] = useState({
    currentLang: props.locales[0].node.code,
    openForm: false,
    openDialog: false,
    editForm: "edit",
    listTypographySystems: null,
    listMarkups: [],
    listColors: [],
    tabState: 2,
    nbperpage: 4,
    page: 1,
    countPage: 0,
    pageDocument: 1,
    pageTypos: 1,
    countPageDocument: 0,
    countPageTypos: 0,
    searchValueColor: "",
    ready: false,
  });

  const [guideline, setGuideline] = useState(null);
  const [loading, setLoading] = useState(true);
  const [typographies, setTypographies] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [formTabActive, setFormTabActive] = useState(0);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalStates, setModalStates] = useState({
    media: false,
    delete: false,
  });

  const fetchGuideline = async () => {
    setLoading(true);
    const res = await props.client.query({
      query: GET_GUIDELINE_BY_ID,
      variables: {
        id: `/api/guidelines/${props.match.params.id}`,
      },
      fetchPolicy: "no-cache",
    });
    setGuideline(res.data.guideline);

    setLoading(false);
  };

  const deleteColor = async (id) => {
    props.startLoading();
    props.client
      .mutate({
        mutation: DELETE_COLOR,
        variables: { id },
      })
      .then(() => {
        props.snack("success", "La couleur a bien été supprimée");
        fetchGuideline();
      })
      .catch((error) => {
        props.snack("error", "Une erreur est survenue");
      })
      .finally(() => {
        props.stopLoading();
      });
  };

  const deleteTypo = async (id) => {
    props.startLoading();
    props.client
      .mutate({
        mutation: DELETE_TYPO,
        variables: { id },
      })
      .then(() => {
        props.snack("success", "La couleur a bien été supprimée");
        fetchGuideline();
      })
      .catch((error) => {
        props.snack("error", "Une erreur est survenue");
      })
      .finally(() => {
        props.stopLoading();
      });
  };

  useEffect(() => {
    fetchGuideline();
  }, []);

  const handleDelete = () => {
    props.startLoading();
    props.client
      .mutate({
        mutation: DELETE_GUIDELINE,
        variables: { id: guideline.id },
      })
      .then(async (result) => {
        props.snack("success", "La charte graphique a bien été supprimée.");
        goTo(props.history, ROUTE_SETTINGS_GUIDELINE);
        props.stopLoading();
      })
      .catch((err) => {
        props.stopLoading();
      });
  };

  return (
    <div
      style={{
        transition: "all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        overflow: "scroll",
      }}
    >
      <TopPanel
        title={"Détails de la marque"}
        subtitle={"Vue d’ensemble de la gestion de votre image de marque"}
        gradientColor1={colors.menu.regular}
        gradientColor2={colors.menu.darker}
        hasBorder={true}
        buttonAvailable={!state.openForm}
        actions={{
          edit: {
            handler: () => {
              setOpenForm(true);
              setFormTabActive(0);
            },
          },
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <OurMenu
            menuOptions={[
              {
                label: "Supprimer",
                color: colors.red.regular,
                icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                modal: true,
                modalProps: {
                  identifier: "deleteGuideline",
                  type: "delete",
                  title:
                    "Êtes-vous sûr de vouloir supprimer cette image de marque ?",
                  subtitle: "Cette action est irréversible",
                  actions: {
                    primaryAction: (id) => handleDelete(),
                  },
                },
              },
            ]}
          />
        </div>
      </TopPanel>
      <Box>
        <GuidelineWrapper container direction="column" justifyContent="center">
          {guideline && !loading ? (
            <Grid
              container
              style={{
                gap: "10px",
              }}
            >
              <ReturnLink goTo={props.history.goBack} type={"button"} />
              <AccordionCustom
                title={
                  <OurTypography colortext={colors.black.lighter}>
                    Information lié à la marque
                  </OurTypography>
                }
              >
                <Grid
                  container
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Grid container spacing={7}>
                    <Grid item xs={3}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          height: "100%",
                          border: `1px solid ${colors.grey.border}`,
                        }}
                      >
                        <Logo
                          backgroundimage={
                            `${process.env.REACT_APP_MEDIAS}/${
                              guideline.guidelineData.edges.find(
                                (data) =>
                                  data?.node?.attribute?.identifier ===
                                  "guideline_logo"
                              )?.node?.media?.filePath
                            }` ?? Empty
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={9}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        style={{
                          padding: "10px 0px",
                          height: "100%",
                        }}
                      >
                        <OurTypography
                          fontweighttext={700}
                          style={{
                            fontSize: 36,
                            marginBottom: 10,
                          }}
                        >
                          {guideline.libelle}
                        </OurTypography>
                        <OurTypography
                          style={{
                            fontSize: 16,
                            marginBottom: 35,
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              guideline.guidelineData.edges.find(
                                (e) =>
                                  e.node.attribute.identifier ===
                                  "guideline_description"
                              )?.node?.value ?? "",
                          }}
                        />

                        <Grid
                          container
                          style={{
                            gap: "8px",
                            marginBottom: 40,
                          }}
                        >
                          {
                            // Color
                          }
                          <Grid container>
                            <Grid item xs={3} sx={{ width: "50%" }}>
                              <Typography>
                                {guideline.colors.length === 1 ||
                                guideline.colors.length === 0
                                  ? "Couleur :"
                                  : "Couleurs :"}
                              </Typography>
                            </Grid>

                            <Grid item xs={9} sx={{ fontWeight: 700 }}>
                              <ColorsList
                                colorsList={guideline.colors.edges.map(
                                  (color) => color.node
                                )}
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid item xs={3}>
                              <Typography>
                                {guideline.colors.length === 1 ||
                                guideline.colors.length === 0
                                  ? "Typographie :"
                                  : "Typographies :"}
                              </Typography>
                            </Grid>

                            <Grid item xs={9} sx={{ fontWeight: 700 }}>
                              {typographies?.length > 0 ? (
                                typographies.map((typo, index) => {
                                  return (
                                    <>
                                      <OurTypography
                                        fontweighttext={700}
                                        style={{ display: "inline" }}
                                      >
                                        {typo.libelle}
                                      </OurTypography>
                                      {index ===
                                      typographies.length - 1 ? null : (
                                        <OurTypography
                                          fontweighttext={700}
                                          style={{ display: "inline" }}
                                        >
                                          ,{" "}
                                        </OurTypography>
                                      )}
                                    </>
                                  );
                                })
                              ) : (
                                <OurTypography
                                  fontweighttext={700}
                                  style={{ display: "inline" }}
                                >
                                  Aucune typographie associée
                                </OurTypography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionCustom>

              {
                // colors section
              }
              <AccordionCustom
                defaultExpanded={true}
                title={
                  <AccordionHeader
                    title={`Couleurs (${guideline.colors?.edges.length})`}
                    actionLabel={"+ Ajouter une couleur"}
                    actionHandler={() => {
                      setFormTabActive(1);
                      setOpenForm(true);
                    }}
                  />
                }
              >
                <Box width={"100%"} paddingLeft={"10px"}>
                  <CarouselCards {...carouselCardsParams}>
                    {guideline.colors?.edges.length === 0 ? (
                      <Typography>Aucune couleur</Typography>
                    ) : (
                      guideline.colors.edges.map((color, index) => (
                        <CardCustom
                          style={{ margin: 10, display: "inline-block" }}
                          cardContentStyle={{ padding: 0 }}
                          key={`card-color-${index}`}
                        >
                          <CardColor
                            color={{
                              hex: color.node.codeHexa,
                              rgba: {
                                r: color.node.codeR,
                                g: color.node.codeG,
                                b: color.node.codeB,
                                a: color.node.codeA,
                              },
                            }}
                            markups={color.node.markups.edges.map(
                              (markup) => markup.node
                            )}
                            actionButtons={[
                              {
                                label: "Modifier",
                                color: colors.blue.darker.hue300,
                                icon: (
                                  <EditIcon
                                    style={{
                                      fill: colors.blue.darker.hue300,
                                    }}
                                  />
                                ),
                                action: (e) => {
                                  e.stopPropagation();
                                  setFormTabActive(1);
                                  setOpenForm(true);
                                },
                              },
                              {
                                label: "Supprimer",
                                color: colors.red.regular,
                                icon: (
                                  <DeleteSharpIcon
                                    style={{ fill: colors.red.regular }}
                                  />
                                ),
                                action: (e) => {
                                  e.stopPropagation();
                                  deleteColor(color.node.id);
                                },
                              },
                            ]}
                          />
                        </CardCustom>
                      ))
                    )}
                  </CarouselCards>
                </Box>
              </AccordionCustom>

              {
                // TYPOGRAPHIES SECTION
              }
              <AccordionCustom
                defaultExpanded={true}
                title={
                  <AccordionHeader
                    title={`Typographies (${guideline.typographies?.edges.length})`}
                    actionLabel={"+ Ajouter une typographie"}
                    actionHandler={() => {
                      setFormTabActive(2);
                      setOpenForm(true);
                    }}
                  />
                }
              >
                <Box width={"100%"} paddingLeft={"10px"}>
                  <CarouselCards {...carouselCardsParams}>
                    {guideline.typographies?.edges.length === 0 ? (
                      <Typography>Aucune typographie</Typography>
                    ) : (
                      guideline.typographies.edges.map((typo, index) => (
                        <CardCustom
                          style={{
                            margin: "10px",
                            display: "inline-block",
                          }}
                          cardContentStyle={{ padding: 0 }}
                          key={`card-typo-${index}`}
                        >
                          <CardTypographies
                            typography={typo.node}
                            title={typo.node.typographySystem.libelle}
                            actionButtons={[
                              {
                                label: "Modifier",
                                color: colors.blue.darker.hue300,
                                icon: (
                                  <EditIcon
                                    style={{
                                      fill: colors.blue.darker.hue300,
                                    }}
                                  />
                                ),
                                action: (e) => {
                                  e.stopPropagation();
                                  setFormTabActive(2);
                                  setOpenForm(true);
                                },
                              },
                              {
                                label: "Supprimer",
                                color: colors.red.regular,
                                icon: (
                                  <DeleteSharpIcon
                                    style={{ fill: colors.red.regular }}
                                  />
                                ),
                                action: (e) => {
                                  e.stopPropagation();
                                  deleteTypo(typo.node.id);
                                },
                              },
                            ]}
                          />
                        </CardCustom>
                      ))
                    )}
                  </CarouselCards>
                </Box>
              </AccordionCustom>

              {/**
               * Documents section
               */}
              <AccordionCustom
                defaultExpanded={true}
                title={
                  <AccordionHeader
                    title={`Documents (${guideline.documents?.edges.length})`}
                    actionLabel={"+ Ajouter un document"}
                    actionHandler={() => {
                      setFormTabActive(3);
                      setOpenForm(true);
                    }}
                  />
                }
              >
                <Box width={"100%"} paddingLeft={"10px"}>
                  <CarouselCards {...carouselCardsParams}>
                    {guideline.documents?.edges.length === 0 ? (
                      <Typography>Aucun document</Typography>
                    ) : (
                      guideline.documents?.edges.map((document, index) => (
                        <CardCustom
                          style={{
                            margin: "10px",
                            display: "inline-block",
                            overflow: "unset !important",
                          }}
                          cardContentStyle={{ padding: 8 }}
                          key={`card-documents-${index}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          <CardMedia
                            data={document.node}
                            actionButtons={[
                              {
                                label: "Modifier",
                                color: colors.blue.darker.hue300,
                                icon: (
                                  <EditIcon
                                    style={{
                                      fill: colors.black.regular,
                                    }}
                                  />
                                ),
                                action: () => {
                                  setFormTabActive(3);
                                  setOpenForm(true);
                                },
                              },
                            ]}
                            onDelete={() => {
                              fetchGuideline();
                            }}
                          />
                        </CardCustom>
                      ))
                    )}
                  </CarouselCards>
                </Box>
              </AccordionCustom>
            </Grid>
          ) : (
            <PageLoader />
          )}
        </GuidelineWrapper>
      </Box>

      {/* <DialogModal
        icon={true}
        type="delete"
        open={state.openDialog}
        onClose={handleToggleDialog}
        title={"Êtes-vous sûr de vouloir supprimer cette charte graphique ?"}
        primaryAction={modalStates.delete.action}
        secondaryAction={() => setModalStates({ delete: false })}
        windowWidth={props.windowWidth}
      >
        <DialogContentText id="alert-dialog-description">
          Si vous supprimez cette charte graphique celle-ci ne sera plus
          accessible. Si vous ne souhaitez pas la supprimer, annulez la
          suppression en cliquant sur annuler.
        </DialogContentText>
      </DialogModal> */}
      <Modal
        isOpen={modalStates.media}
        handleClose={() =>
          setModalStates((prevState) => ({ ...prevState, media: false }))
        }
        modalData={modalData}
      />

      {guideline && (
        <CustomDrawer
          isOpen={openForm}
          setIsOpen={setOpenForm}
          hideBackdrop={false}
        >
          <GuidelineForm
            guideline={guideline}
            tabActive={formTabActive}
            onSuccess={() => {
              fetchGuideline();
              setOpenForm(false);
            }}
          />
        </CustomDrawer>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    locales: state.locales,
    attributeGroups: state.attributeGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGuideline: (guideline) =>
      dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withRouter(
    withApollo(connect(mapStateToProps, mapDispatchToProps)(GuidelineDetails))
  )
);
