import styled from 'styled-components';
import colors from '@config/theme/colors';
import TabPanel from "@ui/TabPanel";

export const Container = styled.div`
  width: 100%;
  height: maxContent;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const FormContainer = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: ${colors.background.white.primary};
`;
export const CustomTabPanel = styled(TabPanel)`
  height: calc(100vh - 350px);
  overflow-y: auto;
`;