import React, { useCallback } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import colors from "@/config/theme/colors";
import Icon from "@/components/ui/icons/Icon.js";
import { Grid } from "@mui/material";
import styled from "styled-components";
import Tooltip from "@/components/ui/tooltip/BootstrapTooltip";
import { ROUTE_PRODUCTS_LIST_DETAIL } from "@/js/constants/route-names.js";
import { getIdFromPath } from "@/js/utils/string.utils.js";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const RowContainer = styled(Grid)`
  width: 100%;
  background-color: white;
  padding: 10px;
  border: 1px solid ${colors.grey.border};
  &:hover {
    border: solid 1px ${colors.blue.regular};
  }
  transition: all 0.1s ease-in-out;
  max-height: 150px;
`;
const Image = styled(Box)`
  overflow: hidden;
  background-color: white};
  background-image: url(${(props) => props.backgroundimage});
  background-size: 80%};
  background-repeat: no-repeat;
  background-position: center;
  border: solid 1px ${colors.grey.lighter.hue900};
`;

const RowProductVariant = ({
  imagePath,
  attributes,
  index,
  productVariant,
  handleSelect,
  handleDelete,
  ...props
}) => {
  const redirectToVariant = useCallback(() => {
    window.open(
      ROUTE_PRODUCTS_LIST_DETAIL.replace(
        ":id",
        getIdFromPath(productVariant.id)
      ),
      "_blank"
    );
  }, []);

  return (
    <RowContainer
      container
      spacing={1}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Grid item display={"flex"} justifyContent={"center"} gap={3}>
        <Grid item>
          <Image
            backgroundimage={`${process.env.REACT_APP_MEDIAS}/${imagePath}`}
            height={100}
            width={100}
          />
        </Grid>
        <Grid item>
          <Box
            sx={{
              height: "100px",
              overflow: "auto",
            }}
          >
            {attributes.map((attribute, index) => (
              <Box
                sx={{ display: "flex", gap: "0.3rem" }}
                key={`attribute${index}`}
              >
                <Typography fontWeight="bold">{attribute.label}:</Typography>
                <Typography>{attribute.value}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>

      <Grid item>
        {!productVariant.id ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip title={props.t("actions.edit")}>
              <IconButton onClick={handleSelect}>
                <Icon icon="edit" color={colors.grey.regular} />
              </IconButton>
            </Tooltip>
            <Tooltip title={props.t("actions.delete")}>
              <IconButton onClick={() => handleDelete(index)}>
                <Icon icon="delete" color={colors.red.regular} />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Box>
            <Tooltip title={props.t("products.form.showProductVariant")}>
              <IconButton onClick={() => redirectToVariant()}>
                <Icon icon="openNewTab" color={colors.blue.regular} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Grid>
    </RowContainer>
  );
};

RowProductVariant.propTypes = {
  imagePath: PropTypes.string,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  productVariant: PropTypes.shape({
    id: PropTypes.string,
  }),
  handleSelect: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default withTranslation()(RowProductVariant);
