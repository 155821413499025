import {FORM, STEPPER} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';
import FooterColumns from '../../components/FooterColumns';

export default function topMessageEdit(action='add') {
    let generalInputs = []
    generalInputs.push(
        {
            required : true,
            type : 'text',
            label : 'Nom du message promotionnel',
            stateName : 'topMessageName',
            helper: {
                text: 'Nom du message promotionnel',
                link: false,
            },
        }, 
        {
            type: 'date',
            label: 'Date de début',
            helper: {
                text: 'Date de début',
                link: false,
            },
            required: true,
            stateName: 'topMessageStartAt',
        },
        {
            type: 'date',
            label: 'Date de fin',
            helper: {
                text: 'Date de fin',
                link: false,
            },
            required: true,
            stateName: 'topMessageEndAt',
        },
        {
            type: 'radio',
            direction: 'row',
            label: 'Actif',
            helper: {
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'isActive',
            value: [
                {
                    value: true,
                    label: 'Oui'
                },
                {
                    value: false,
                    label: 'Non'
                },
            ]
        },
        {
            type: 'textareaSimple',
            label: 'Message',
            helper: {
                text: 'Message',
                link: false,
            },
            required: true,
            stateName: 'topMessageContent',
        },
    )
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? '+ Ajouter un message promotionnel':'Modifier le message promotionnel',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer votre message':'modifier votre message'}`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: action === 'add' ? 'Créer ce message' : 'Modifier le message',
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations générales',
            },
            // finalStep: {
            //     picto: null,
            //     title: 'Et voilà !',
            //     subtitle: action === 'add' ? 'Votre nouveau message est prêt pour sa création' : 'Votre message est prêt à être mis à jour',
            //     textButton: action === 'add' ? 'Créer' : 'Modifier le message'
            // },
            children:[
                {
                    optionsInputs:generalInputs
                }
            ]
        }
    }
    return form;
}