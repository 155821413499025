import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from '@apollo/client/react/hoc';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import { Grid, Checkbox, Select, Box, MenuItem, TextField } from '@mui/material';
import Button from '../../../ui/button/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import _ from 'lodash';
import { SETTINGS, SETTINGS_USERS, CREATE } from '../../../../js/constants/constant-rights';
import { v4 as uuidv4 } from 'uuid';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import Typography from '../../../ui/typography/Typography';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { GET_USERGROUPS, UPDATE_USERGROUP } from '../../../../queries/user_groups';
import { GET_MENU_ENTRIES } from '../../../../queries/menu_entries';
import {withTranslation} from 'react-i18next';
import { checkRouting } from '../../../../js/utils/checkRouting';


const AccordionSummaryCustom = styled(AccordionSummary)`
    background: ${colors.blue.lighter.hue900};
    &>div{
        justify-content: space-between;
        align-items: center;
        p{
            color: ${colors.blue.darker.hue300};
        }
        svg{
            fill: ${colors.blue.darker.hue300};
        }
    }
`;

const AccordionDetailsCustom = styled(AccordionDetails)`
    padding: 20px 40px;
`;
const ContainerSub = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RowContainer = styled(Grid)`
    padding: 10px;
`;
const RowContainerGeneral = styled(Grid)`
    padding: 20px;
    border-bottom: 1px solid ${colors.grey.lighter.hue800};
`;
const CheckboxCustom = styled(Checkbox)`
    &.Mui-checked{
        color: ${colors.blue.darker.hue300};
    }
`;

const ContainerError = styled(Grid)`
    width: auto;
    padding: 20px;
    background-color: #fecfd0;
`

const ErrorText = styled(Typography)`
    color: ${colors.red.darker};
`

const PageWrapper = styled(Box)`
    width: 100%;
    height: calc(100% - 64px);
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
`;

const SettingsAccess = (props) => {
    // State
    const [currLang, setCurrLang] = useState(props.locales[0].node.code)
    const [isReady, setIsReady] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [roles, setRoles] = useState({
        all: [],
        current: null
    })
    const [selectedGroupUser, setSelectedGroupUser] = useState(null)
    const [access, setAccess] = useState({
        initial: [],
        current: [],
        list: []
    })

    // Lifecycle hooks
    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        setSelectedGroupUser(roles?.all[0])
        setAccess(prev => ({
            ...prev, 
            initial: roles?.all[0]?.menuEntry.edges, 
            current: _.cloneDeep(roles?.all[0]?.menuEntry?.edges) 
        }))
    }, [roles])

    useEffect(() => {
        setIsModified(false)
        let getCurrentAccess = access?.current?.map(e => e.node.id);
        let getInitialAccess = access?.initial?.map(e => e.node.id);
        if(getCurrentAccess && getInitialAccess) {
            if (!_.isEqual(getCurrentAccess.sort(), getInitialAccess.sort())){
                setIsModified(true)
            }
        }
    }, [access])

    // Functions
    const init = async () => {
        checkRouting(props);
        await initRoles();
        await initAccess();
        setIsReady(true)
    }

    const initRoles = async () => {
        const result = await props.client.query({
            query: GET_USERGROUPS,
            fetchPolicy: 'no-cache'
        })
        const data = result.data.userGroups.edges.map(e => e.node)
        setRoles(prev => ({ ...prev, all: data }));
    }

    // TODO: Check
    const list_to_tree = (list) => {
        let map = {}, node, roots = [], i;
        
        for (i = 0; i < list.length; i += 1) {
          map[list[i].id] = i; // initialize the map
          list[i].children = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
          node = list[i];
          if (node.parentId) {
            list[map[node.parentId]].children.push(node);
          } else {
            roots.push(node);
          }
        }

        // Change the last position to the first one
        const lastItem = roots.pop();
        roots.unshift(lastItem)
        return roots;
    }

    const initAccess = async () => {
        const result = await props.client.query({
            query: GET_MENU_ENTRIES,
            fetchPolicy: 'no-cache'
        })
        let newArray = []
        let reworkArray = result.data.menuEntries.edges.map(e => e.node)
        for (let element of reworkArray){
            newArray.push({
                code: element.code,
                createdAt: element.createdAt,
                id: element.id.replace('/api/menu-entries/', ''),
                parentId: element.parent?.id.replace('/api/menu-entries/', ''),
                updatedAt: element.updatedAt
            })
        }
        setAccess(prev => ({...prev, list: list_to_tree(newArray)}))
    }

    const checkAllChild = (parentId, list) => {
        let getParentData = access?.list.find(e => e.id === parentId);
        let getAllChild = access?.list.find(e => e.id === parentId).children;
        let checkerArray = [];
        for (let child of getAllChild){
            let findElement = list.find(e => e.node.id.replace('/api/menu-entries/', '') === child.id)
            if (findElement){
                checkerArray.push(true)
            }else{
                checkerArray.push(false)
            }
        }
        let resultOnlyFalse = checkerArray.every(elem => elem === false)
        if (resultOnlyFalse){
            list = list.filter(e => e.node.id.replace('/api/menu-entries/', '') !== parentId)
        }else{
            list.push({
                node:{
                    ...getParentData,
                    id:`/api/menu-entries/${parentId}`,
                    parent: null,
                }
            })
        }
        return list
    }

    const handleChange = (currAccess) => {
        let getState = access?.current;
        let findElement = getState.find(e => e.node.id.replace('/api/menu-entries/', '') === currAccess.id)
        if (currAccess.parentId){
            if (findElement){
                getState = getState.filter(e => e.node.id.replace('/api/menu-entries/', '') !== currAccess.id)
                getState = checkAllChild(currAccess.parentId, getState)
            }else{
                getState.push({
                    node:{
                        ...currAccess,
                        id:`/api/menu-entries/${currAccess.id}`,
                        parent:`/api/menu-entries/${currAccess.parentId}`,
                    }
                })
                getState = checkAllChild(currAccess.parentId, getState)
            }
        }else{
            if (findElement){
                getState = getState.filter(e => e.node.id.replace('/api/menu-entries/', '') !== currAccess.id)
                for (let child of currAccess.children){
                    getState = getState.filter(e => e.node.id.replace('/api/menu-entries/', '') !== child.id)
                }
            }else{
                getState.push({
                    node:{
                        ...currAccess,
                        id:`/api/menu-entries/${currAccess.id}`,
                        parent:`/api/menu-entries/${currAccess.parentId}`,
                    }
                })
                for (let child of currAccess.children){
                    getState.push({
                        node:{
                            ...currAccess,
                            id:`/api/menu-entries/${child.id}`,
                            parent:`/api/menu-entries/${child.parentId}`,
                        }
                    })
                }
            }
        }
        setAccess(prev => ({...prev, current: getState}))
    }

    const filterNew = (obj) => {
        if (!obj.new){
            return true
        }else{
            return false
        }
    }

    const handleChangeRole = (id, newRole = false) => {
        let findRole = roles?.all.find(e => e.id === id)
        setSelectedGroupUser(findRole)
        setAccess(prev => ({
            ...prev, 
            initial: findRole?.menuEntry.edges,
            current: _.cloneDeep(findRole?.menuEntry.edges)
        }))
    };

    const handleSave = async () => {
        try {
            let mutation = null;
            let variables = null;
            mutation = UPDATE_USERGROUP;
            variables={
                "id": selectedGroupUser.id,
                "menuEntry": access?.current.map(e => e.node.id)
                // "identifier": state.selectedGroupUser.identifier,
                // "libelle": state.selectedGroupUserName,
            }
            props.startLoading();
            let RESULT_MUTATION = await props.client.mutate({
                mutation: mutation,
                variables: variables
            });
            // props.stopLoading();
            handleSuccess(RESULT_MUTATION.data.updateUserGroup.userGroup);
        } catch(e) {
            handleError(e);
        }
    }

    const handleSuccess = async (data) => {
        await initRoles();
        await initAccess();
        props.snack(ALERT_SUCCESS, `Groupe utilisateur ${data.code} modifié`);
        props.stopLoading();
    };

    const handleError = (e) => {
        props.snack(ALERT_ERROR, 'Une erreur est survenue');
        props.stopLoading();
        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    return (
        <PageWrapper>
            <TopPanel 
                icomoon="ico_access_manager" 
                colorIcomoon={colors.blue.lighter.hue300} 
                title="Gestion des accès" 
                subtitle="Gestion de vos accès (création / modification / suppression)" 
                // searchHandler={handleChange}  
                gradientColor1={colors.menu.regular} 
                gradientColor2={colors.menu.darker}
                windowWidth={props.windowWidth}
                buttonAvailable={isReady}
                // openForm={state.openForm}
                hasBorder={true}
            />
            <Grid container direction='column' style={{
                position: 'relative',
            }}>
                {
                isReady ?
                    <>
                        <Grid container justifyContent={"space-between"} alignItems={"center"} style={{marginTop: 16,paddingBottom: 16}}>
                            <Box style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                                <Typography>Sélection du groupe : </Typography>
                                <Select
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    value={selectedGroupUser?.id}
                                    style={{width: 400, marginLeft: 16}}
                                    onChange={(e) => handleChangeRole(e.target.value)}
                                >
                                    {
                                        roles?.all.length > 0 ? 
                                            roles?.all.map((role, index) => {
                                                return(
                                                    <MenuItem value={role.id} key={`role-${index}`}>{role.code.charAt(0).toUpperCase() + role.code.slice(1)}</MenuItem>
                                                )
                                            })
                                        : null
                                    }
                                </Select>
                            </Box>
                            <Button disabled={isModified ? false  : true} disableElevation={true} text="Valider la modification" types="Validate" onClick={handleSave}/>
                        </Grid>
                        {
                            isModified ?
                                <Grid container>
                                    <ContainerError container alignItems="center">
                                        <ReportProblemOutlinedIcon style={{fill: colors.red.darker, width: 20, height: 20, marginRight: 8}}/>
                                        <ErrorText>Attention si vous changez de groupe vos modifications actuelles seront perdues</ErrorText>
                                    </ContainerError>
                                </Grid>
                            : null
                        }
                    </>
                    : null
                }
                {
                    isReady && access?.list.length > 0 ? 
                    <Box style={{width: "100%"}}>
                        { 
                            access?.list.map((currAccess,index) => {
                                let getChecked = access?.current?.find(e => e.node.id.replace('/api/menu-entries/', '') === currAccess.id)
                                let arrayChild = currAccess.children.map(e => e.id);
                                let arraySelected = access?.current?.map(e => e.node.id.replace('/api/menu-entries/', ''))
                                let checker = (arr, target) => target.every(v => arr.includes(v));
                                return(
                                    <Grid key={currAccess.id} item lg={6} md={6} xs={12}>
                                        <Accordion key={`Accordion${currAccess.code + index}`} style={{borderRadius: 0, width: "100%", marginTop: index === 0 ? '18px' : null}}>
                                            <AccordionSummaryCustom
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography style={{textTransform: "capitalize"}}>{props.t(`drawer.${currAccess.code}`)}</Typography>
                                                
                                            </AccordionSummaryCustom>
                                            <AccordionDetailsCustom>
                                                <Grid container direction="column">
                                                    <RowContainerGeneral container justifyContent={"space-between"}>
                                                        <Grid item xs={4}>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Grid container justifyContent={"center"}>
                                                                <ContainerSub item xs={10}>
                                                                    {/* Ici? */}
                                                                </ContainerSub>
                                                                <ContainerSub item xs={2}>
                                                                    <Typography>Activation</Typography>
                                                                </ContainerSub>
                                                            </Grid>
                                                        </Grid>
                                                    </RowContainerGeneral>
                                                    <RowContainer container justifyContent={"space-between"} alignItems={"center"}>
                                                        <Grid item xs={8}>
                                                            All
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Grid container justifyContent={"center"}>
                                                                <ContainerSub item xs={10}>
                                                                </ContainerSub>
                                                                <ContainerSub item xs={2}>
                                                                    <CheckboxCustom 
                                                                        checked={getChecked ? true : false} 
                                                                        indeterminate={getChecked && !checker(arraySelected, arrayChild) ? true : false} 
                                                                        onChange={(event) => handleChange(currAccess)}
                                                                    />
                                                                </ContainerSub>
                                                            </Grid>
                                                        </Grid>
                                                    </RowContainer>
                                                    {
                                                        currAccess.children.length > 0 ? 
                                                            currAccess.children.map((sub,indexSub) => {
                                                                let getCheckedSub = access?.current.find(e => e.node.id.replace('/api/menu-entries/', '') === sub.id)
                                                                return(
                                                                    <RowContainer container justifyContent={"space-between"} key={`RowContainer${sub.code + index}`} alignItems={"center"} style={{background: indexSub %2 === 0 ? colors.grey.lighter.hue980 : null}}>
                                                                        <Grid item xs={8}>
                                                                            {props.t(`drawer.${sub.code}`)}
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <Grid container justifyContent={"center"}>
                                                                                <ContainerSub item xs={10}>
                                                                                </ContainerSub>
                                                                                <ContainerSub item xs={2}>
                                                                                    <CheckboxCustom checked={getCheckedSub ? true : false} onChange={(event) => handleChange(sub)}/>
                                                                                </ContainerSub>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </RowContainer>
                                                                )
                                                            })
                                                        : null
                                                    }
                                                </Grid>
                                            </AccordionDetailsCustom>
                                        </Accordion>
                                    </Grid>
                                )
                            })
                        }
                    </Box>
                    : <PageLoader />
                }
            </Grid>
        </PageWrapper>
    );

}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsAccess))));
