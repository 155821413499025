import React, { useEffect, useState } from 'react';

import { Box, Divider, Grid } from '@mui/material';
import Typography from '../../../ui/typography/Typography';
import styled from 'styled-components'

import JohnDoe from '../../../../assets/images/john-doe.png';
import Stats from '../../Stats/Stats';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import colors from '../../../../config/theme/colors';
import { PowerSettingsNew } from '@mui/icons-material';
import OurTypography from '../../../ui/typography/Typography';
import { isImgUrl } from '../../../../js/utils/isValidImage';

const ContainerImage = styled(Grid)`
    max-width: 90px;
    max-height: 90px;
    width: 90px;
    height: 90px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    flex-basis:auto;
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 100%;
        max-height: 60px;
        max-width: 60px;
    }
`;
const DisconnectText = styled(Typography)`
    color: #BEBEBF;
    font-weight: lighter;
    cursor: pointer;
    display: inline-block;
    align-items: center;
    &:hover{
        color:${colors.grey.lighter.hue600};
    };
    svg{
        width: 16px;
        height: 16px;
    }
    span{
        margin-left: 4px;
    }
`;

function CardHomepageStats(props) {
    const stats = props.statsHomepage;
    const [userImage, setUserImage] = useState(false)

    useEffect(() => {
        isImgUrl(`${process.env.REACT_APP_API_ROOT}/${props.user.image}`)
            .then((result) => {
                setUserImage(result)
            }).catch((err) => {
                console.log(err)
            });
    }, [])

    return (
        <Box style={{position: 'relative'}}>
            <Grid container justifyContent={'space-between'} wrap={props.windowWidth > 1100 ? "nowrap" : "wrap"}>
                <Grid item style={{ flexGrow: 1 }} xs={5}>
                    <Grid container direction={"row"} justifyContent={"flex-start"} alignItems="center" style={{
                        padding: '20px 0px 20px 25px',
                    }}>
                        <ContainerImage item style={{
                            backgroundColor: colors.blue.lighter.hue900,
                            marginRight: "35px"
                        }}>
                            {userImage ? (
                                <img src={`${process.env.REACT_APP_API_ROOT}/${props.user.image}`} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }} />
                            ) : (
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: colors.blue.lighter.hue600,
                                    fontWeight: 'bold',
                                    fontSize: '45px'
                                }}>{props.user.firstname.charAt(0).toUpperCase()}{props.user.lastname.charAt(0).toUpperCase()}</div>
                            )}
                        </ContainerImage>
                        <Grid item xs>
                            {/* <Typography variant={"body2"} style={{fontWeight: "bold"}}>Bienvenue</Typography> */}
                            <Box>
                                <Typography variant={"h2"} style={{fontSize: 25, fontWeight: 'extrabold'}}>{props.user.firstname} {props.user.lastname}</Typography>
                                <Typography variant={"body"} style={{fontSize: 16, fontStyle: 'italic'}}>{props.user.firstname} {props.user.lastname}</Typography>
                            </Box>
                            <Box>
                                <Box>
                                    <DisconnectText
                                        style={{
                                            textDecoration: 'underline',
                                        }}>Gestion du profil</DisconnectText>
                                    {/* <DisconnectText
                                        onClick={() => {
                                            props.startLoading();

                                            setTimeout(() => {
                                                localStorage.removeItem('AUTH_USER');
                                                localStorage.removeItem('AUTH_TOKEN');
                                                localStorage.removeItem('AUTH_TOKEN_EXPIRY');
                                                // localStorage.removeItem('ATTRIBUTES');
                                                // localStorage.removeItem('ATTRIBUTE_GROUPS');
                                                localStorage.removeItem('LOCALES');
                                                localStorage.removeItem('GUIDELINE');
                                                localStorage.removeItem('ATTRIBUTES_LOADED');
                                            }, 1000);
                                        }}
                                        style={{
                                            textDecoration: 'underline',
                                        }}>{props.t("menu.disconnect")}</DisconnectText> */}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item xs={7}>
                    <Grid container alignItems={'center'} style={{height: '100%', paddingLeft: '50px'}}>
                        <Grid item>
                            <Grid container>
                                <Grid item style={{minWidth: 130}}>Poste occupé:</Grid>
                                <Grid item style={{fontWeight: 'bold'}}>{props.user.job}</Grid>
                            </Grid>
                            <Grid container>
                                <Grid item style={{minWidth: 130}}>Accès:</Grid>
                                <Grid item style={{fontWeight: 'bold'}}>{props.user.userGroup.code}</Grid>
                            </Grid>
                            <Grid container>
                                <Grid item style={{minWidth: 130}}>langue:</Grid>
                                <Grid item style={{fontWeight: 'bold'}}>{props.user.preferredLangCode ?? '-'}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <OurTypography fontweighttext={'bold'} style={{
                    fontSize: 14,
                    position: 'absolute',
                    right: 20,
                    bottom: 20,
                    cursor: 'pointer'
                }} onClick={() => {
                    props.startLoading();

                    setTimeout(() => {
                        localStorage.removeItem('AUTH_USER');
                        localStorage.removeItem('AUTH_TOKEN');
                        localStorage.removeItem('AUTH_TOKEN_EXPIRY');
                        // localStorage.removeItem('ATTRIBUTES');
                        // localStorage.removeItem('ATTRIBUTE_GROUPS');
                        localStorage.removeItem('LOCALES');
                        localStorage.removeItem('GUIDELINE');
                        localStorage.removeItem('ATTRIBUTES_LOADED');
                    }, 1000);
                }}>Se déconnecter</OurTypography>
                {/* <Grid item style={{ flexGrow: 1 }}>
                    <Grid container alignItems="flex-end" justifyContent={"flex-end"} wrap="wrap" style={{
                        height: '100%',
                        marginTop: props.windowWidth > 1100 ? 0 : 16,
                        paddingRight: props.windowWidth > 1100 ? '18.28px' : '20px',
                        gap: '18px'
                    }}>
                        {stats.map((stat, index) => {
                            if (stat) {
                                return (
                                    <Grid item key={`StatHomepage${index}`} style={{
                                        marginBottom: props.windowWidth > 1100 ? '18.85px' : '30px'
                                    }}>
                                        <Stats stat={stat} windowWidth={props.windowWidth} />
                                    </Grid>
                                )
                            }
                        })}
                    </Grid>
                </Grid> */}
            </Grid>
        </Box >
    );
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CardHomepageStats));