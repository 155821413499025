import { gql } from '@apollo/client';



//----- Mise à jour d'un product Workflow
/*
 Exemple de variables
  {
    "id" : "/api/product-workflows/7"
  }

priority : 0 = Basse / 1 = Moyenne / 2 = Haute
state : 0 = Contributeur / 1 = Correction
taskState : 0 = A faire / 1 = En cours / 2 = En recette / 3 = Résolu
retailerState : 0 = En erreur / 1 = OK
importState : 0 = En erreur / 1 = OK
*/
export const UPDATE_PRODUCT_WORKFLOW = gql`
mutation updateProductWorkflow($id: ID!, $user: String, $priority: Int, $state: Int, $taskState: Int, $retailerState: Int, $importState: Int, $description:String){
  updateProductWorkflow(input:{id: $id, user: $user, priority: $priority, state: $state, taskState: $taskState, retailerState: $retailerState, importState: $importState, description: $description}){
    productWorkflow{
      id 
      user{
        id
        lastname
        firstname
        image
      }
    }
  }
}
`;

/**
Détails des statuts =

  priority : 0 = Basse / 1 = Moyenne / 2 = Haute
  state : 0 = Contributeur / 1 = Correction
  taskState : 0 = A faire / 1 = En cours / 2 = En recette / 3 = Résolu tache
  retailerState : 0 = synchronised / 1 = reviewied / 2 = envoyer tp
  importState : 0 = En erreur / 1 = OK status import / gaia 
 */
export const getProductWorkflows = gql`
query productWorkflows($nbperpage: Int, $cursor: String, $cursorLast: String, $priority:Int, $state:Int, $taskState:Int, $importState:Int, $user:String, $productSku:String, $source:Int, $dateFrom:String, $dateTo:String, $channel:String,$retailerState:[Int]) {
  productWorkflows(first: $nbperpage, after: $cursor, before: $cursorLast, priority:$priority,state:$state,taskState:$taskState,importState:$importState,user:$user, channels:$channel ,product_sku:$productSku, retailerState_list:$retailerState, product_fileImports_productSource_id:$source,createdAt:{after:$dateFrom,before:$dateTo}) {
		totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges {
      node {
        id
        priority
        state
        taskState
        importState
        retailerState
        createdAt
        product{
          id
          sku
          productDatas(attribute_list : ["/api/attributes/1","/api/attributes/172"]){
            edges{
              node{
                value
              }
            }
          }
          productSources{
            edges{
              node{
                id
              }
            }
          }
        }
        user{
          id
          lastname
          firstname
          image
        }
        channels{
          edges{
            node{
              libelle
              logo
            }
          }
        }
        productWorkflowLogs{
          edges{
            node{
              title
              attribute
              description
              type
              createdAt
            }
          }
        }
      }
    }
  }
}
`;



export const getTeamByUserId = gql`
query getTeamByUserId($id: ID!) {
  user(id: $id) {
    subordinate {
      edges {
        node {
          id
          lastname
          firstname
          image
        }
      }
    }
  }
}
`;

//---- GET TOTAL BY TASKSTATE

/**
Détails des statuts =

  taskState : 0 = A faire / 1 = En cours / 2 = En recette / 3 = Résolu
 */
export const getTotalTaskByUser = gql`
query productWorkflows($taskState:Int, $user:String) {
  productWorkflows(taskState:$taskState,user:$user) {
    totalCount
    edges{
      node{
        taskState
        user{
          id
          lastname
          firstname
          image
        }
      }
    }
  }
}
`;


export const getTradingPartnersList = gql`
query channels {
  channels(isSystem: false) {
    edges {
      node {
        identifier
        libelle
        logo
        id
      }
    }
  }
}
`;
