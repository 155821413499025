import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';
import Typography from '../../../ui/typography/Typography';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next'

const GridCustom = styled(Grid)`
    height: 300px;
    
    
`;

const PageLoader = (props) => {
    return (
        // <Box style={{
        //     position: 'relative',
        // }}>
            <GridCustom container justifyContent='center' direction='column' alignItems='center' style={props.style ? props.style : null}>
                <CircularProgress color="inherit" size={'5rem'} 
                style={{padding: 0}}
                />
                <Typography variant="h3" style={{marginTop:32,textAlign:'center',width:'100%'}}>{props.messageLoading ? props.messageLoading : props.t("medias.tags.loading")}</Typography>
            </GridCustom>
        // </Box>
    );
};
export default withTranslation()(PageLoader);