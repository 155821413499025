import { gql } from '@apollo/client';


//---- Récupération des pages d'un flipbook
export const GET_ASSET_FLIPBOOK_PAGES = gql`
query assetFlipbookPages($asset: String){
  assetFlipbookPages(asset: $asset){
    edges{
      node{
        id
        identifier
        libelle
        position
        htmlContent
      }
    }
  }
}
`;
//----- Ajouter une page 
/*

*/
export const ADD_FLIPBOOK_PAGE = gql`
mutation createAssetFlipbookPage($media:String, $identifier:String!, $asset:String!, $position:Int!, $htmlContent:String){
	createAssetFlipbookPage(input: {media:$media, identifier:$identifier, asset:$asset, position:$position, htmlContent:$htmlContent}){
  	assetFlipbookPage{
      id
    }
  }
}`;
//----- Editer une page 
/*

*/
export const UPDATE_FLIPBOOK_PAGE = gql`
mutation updateAssetFlipbookPage($id:ID!, $media:String, $identifier:String, $asset:String, $position:Int, $htmlContent:String){
	updateAssetFlipbookPage(input: {id:$id, media:$media, identifier:$identifier, asset:$asset, position:$position, htmlContent:$htmlContent}){
  	assetFlipbookPage{
      id
    }
  }
}`;

//----- Supprimer un page 
/*
*/
export const DELETE_FLIPBOOK_PAGE = gql`
mutation deleteAssetFlipbookPage($id:ID){
	deleteAssetFlipbookPage(input: {id:$id}){
  	assetFlipbookPage{
      id
    }
  }
}`;