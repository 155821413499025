import { useEffect, useState } from "react";
import colors from "../../../../../config/theme/colors";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "../../../../../js/constants/action-types";
import { withTranslation } from "react-i18next";
import { withApollo } from "@apollo/client/react/hoc";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { ROUTE_SETTINGS_DETAIL_USER } from "../../../../../js/constants/route-names";
import { getElements } from "../../../../../js/utils/functions";
import * as listingHelper from "../../../../../js/utils/listing";
import GridView from "../../../../layouts/Listing/components/GridView/GridView";
import { userGroupCardsMapper } from "../../../../../js/mappers/usergroups";
import { eventService } from "../../../../../js/services/event.service";
import {
  GET_USERS,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
} from "../../../../../queries/users";
import request from "../../../../../js/utils/fetch";
import editedAccountTemplate from "../../../../../email/editedAccount";
import newAccountTemplate from "../../../../../email/newAccount";
import TopPanel from "../../../../layouts/TopPanel/TopPanel";
import {
  listSettings,
  perPageOptions,
  viewOptions,
} from "./listUsers.config";
import AccordionCustom from "../../../../layouts/Accordion/AccordionCustom";
import LayoutBuilder from "../../../../ui/form/LayoutFormBuilder";
import moment from "moment";
import editUserForm from "./editUser.config";
import addUserForm from "./addUser.config";
import {
  ALERT_SUCCESS,
  ALERT_ERROR,
} from "../../../../../js/constants/alert-types";
import DialogModal from "../../../../ui/dialog/DialogModal";
import DialogContentText from "@mui/material/DialogContentText";
import PageLoader from "../../../../ui/loadings/page-loader/PageLoader";

const SettingsUsers = ({ windowWidth, ...props }) => {
  const initialFormState = {
    user_id: "",
    user_token_id: "",
    email: "",
    plainPassword: "",
    plainPasswordRepeat: "",
    firstname: "",
    lastname: "",
    phone: "",
    image: "",
    preferredLangcode: "fr",
    isActive: true,
    generate: false,
    isBlocked: false,
    showPassword: false,
    createdAt: moment().format(),
    updatedAt: moment().format(),
    inputHidden: [],
    filtered: [],
    errors: {},
    currentLang: props.locales[0].node.id,
  };

  const [state, setState] = useState({
    scrollListing: false,
    dataCount: 0,
    reloadListing: false,
    items: [],
    selectedItems: null,
    userGroups: null,
    pagination: {
      page: 1,
      perPage: perPageOptions[viewOptions.current][0],
    },
    openForm: false,
    form: initialFormState,
    formType: "add",
    editPassword: false,
    notif: false,
    user_id: "",
    user_token_id: "",
    email: "",
    plainPassword: "",
    plainPasswordRepeat: "",
    firstname: "",
    lastname: "",
    phone: "",
    image: "",
    preferredLangcode: "fr",
    isActive: true,
    generate: false,
    isBlocked: false,
    showPassword: false,
    createdAt: moment().format(),
    updatedAt: moment().format(),
    inputHidden: [],
    filtered: [],
    errors: {},
    currentLang: props.locales[0].node.id,
    userGroup: null,
  });
  const [offsetListing, setOffsetListing] = useState(0);

  const handleChangeState = (stateName, event) => {
    const value = event?.target?.value ?? event;

    setState((prevState) => ({
      ...prevState,
      [stateName]: value,
    }));
  };

  const handleButtonGroupChange = (stateName, value) => {
    setState((prevState) => ({
      ...prevState,
      [stateName]: value,
    }));

    if (stateName === "generate") {
      handleFormError("plainPassword", false);
      handleFormError("plainPasswordRepeat", false);
    }
  };

  const handleFormError = (stateName, error) => {
    let errors = state.errors;
    errors[stateName] = error;
    setState((prevState) => ({
      ...prevState,
      [state.errors]: errors ?? {},
    }));
  };

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + 0.5 >= offsetListing) {
      setState((prevState) => ({ ...prevState, scrollListing: true }));
    } else {
      setState((prevState) => ({ ...prevState, scrollListing: false }));
    }
  };

  useEffect(() => {
    getUserGroups();
  }, []);

  const getUserGroups = async () => {
    let variables = {};

    variables = await listingHelper.initQuery(
      {
        pagination: state.pagination,
      },
      -"queryData",
      props.typeResult
    );
    let queryStates = await listingHelper.initQuery(
      { pagination: state.pagination },
      "states",
      props.typeResult
    );
    const result = await getElements("userGroups", variables);
    let userGroups = await userGroupCardsMapper(result.data?.userGroups?.edges);

    setState((prevState) => ({ ...prevState, userGroups }));
  };

  const handleForm = (type) => {
    setState((prevState) => ({
      ...prevState,
      isActive: false,
      errors: {},
    }));
    setState((prevState) => ({ ...prevState, formType: type }));
    setState((prevState) => ({ ...prevState, openForm: !prevState.openForm }));
  };

  const handleChange = () => {};

  const handleToggleDrawer = () => {
    const openForm = !state.openForm;

    setState((prevState) => ({
      ...prevState,
      openForm,
    }));

    if (!openForm) {
      setState((prevState) => ({
        ...prevState,
        ...initialFormState,
      }));
    }
  };

  const goToUser = (id) => {
    props.history.push({
      pathname: ROUTE_SETTINGS_DETAIL_USER.replace(
        ":id",
        id.replace("/api/product-sources/", "")
      ),
      state: { catalogId: id },
    });
  };

  const handleToggleForm = (event, user) => {
    const userData = {
      user_id: user.id,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone,
      image: {
        changed: false,
        data: user.image?.replace("medias/", ""),
      },
      preferredLangcode: user.preferredLangcode,
      isActive: user.isActive,
      userGroup: user.userGroup?.id,
    };

    setState((prevState) => ({
      ...prevState,
      ...userData,
      formType: "edit",
    }));
    handleToggleDrawer();
  };

  const hasErrors = () => {
    if (state.form?.errors) {
      for (let error in state.errors) {
        console.log(state.errors[error])
        console.log(state)
        if (state.errors[error]) return true;
      }
    }

    return false;
  };

  const handlerEditMutation = () => {
    if (hasErrors()) {
      props.snack(ALERT_ERROR, "Veuillez vérifier les champs invalides");
      return eventService.fire();
    }

    if (state.plainPassword !== state.plainPasswordRepeat) {
      return props.snack(
        ALERT_ERROR,
        "Les mots de passe ne sont pas identiques"
      );
    }

    props.startLoading();
    let variables = {
      id: state.user_id,
      email: state.email,
      userGroup: state.userGroup,
      job:state.job,
      firstname: state.firstname,
      lastname: state.lastname,
      phone: state.phone,
      preferredLangcode: state.preferredLangcode,
      updatedAt: state.updatedAt,
    };

    if (state.editPassword) variables.password = state.plainPassword;
    if (typeof state.isActive !== "undefined")
      variables.isActive = state.isActive;
    if (typeof state.isBlocked !== "undefined")
      variables.isBlocked = state.isBlocked;
    if (state.image.changed) variables.image = state.image.data;

    props.client
      .mutate({
        mutation: UPDATE_USER,
        variables,
      })
      .then(() => {
        if (state.editPassword) {
          request(`${process.env.REACT_APP_API}/sender-emails`, "post", {
            sender: "no-reply@sinfin.fr",
            receiver: state.email,
            subject: "Votre compte Sinfin DXP a été modifié",
            content: editedAccountTemplate(
              state.email,
              state.plainPassword,
              `${process.env.REACT_APP_PUBLIC}/login`,
              `${process.env.REACT_APP_PUBLIC}/img/logo.png`,
              state.notif
            ),
          });
        } else if (state.editPassword) {
          handleToggleDrawer();
        }
        props.snack(ALERT_SUCCESS, "Le compte est modifié");
        getUserGroups();
        props.stopLoading();
        handleToggleDrawer();
      })
      .catch((err) => {
        console.log(err);
        handleToggleDrawer();
      });
  };

  const handlerMutation = () => {
    if (hasErrors()) {
      props.snack(ALERT_ERROR, "Veuillez vérifier les champs invalides");
      return eventService.fire();
    }

    if (state.plainPassword !== state.plainPasswordRepeat && !state.generate) {
      return props.snack(
        ALERT_ERROR,
        "Les mots de passe ne sont pas identiques"
      );
    }

    props.startLoading();

    let variables = {
      email: state.email,
      password: state.plainPassword,
      firstname: state.firstname,
      lastname: state.lastname,
      job:state.job,
      phone: state.phone,
      preferredLangcode: state.preferredLangcode,
      isActive: state.isActive,
      isBlocked: state.isBlocked,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt,
      userGroup: state.userGroup,
    };
    if (state.image?.data) {
      variables.image = state.image.data;
    }
    props.client
      .mutate({
        mutation: ADD_USER,
        variables,
        refetchQueries: [
          {
            query: GET_USERS,
          },
        ],
      })
      .then(() => {
        if (state.generate) {
          request(`${process.env.REACT_APP_API}/sender-emails`, "post", {
            sender: "no-reply@sinfin.fr",
            receiver: state.email,
            subject: "Nouveau compte Sinfin DXP",
            content: newAccountTemplate(
              state.email,
              state.plainPassword,
              `${process.env.REACT_APP_PUBLIC}/login`,
              `${process.env.REACT_APP_PUBLIC}/logo192.png`,
              state.notif
            ),
          });
        }
        getUserGroups();
        props.stopLoading();
        props.snack(ALERT_SUCCESS, "Le compte est ajouté");
        handleToggleDrawer();
      })
      .catch((err) => {
        console.log(err);
        handleToggleDrawer();
      });
  };

  const deleteMutation = () => {
    props.startLoading();
    props.client
      .mutate({
        mutation: DELETE_USER,
        fetchPolicy: "no-cache",
        variables: {
          id: state.user_id,
        },
      })
      .then(() => {
        props.snack(ALERT_SUCCESS, "Le compte est supprimé");
      })
      .catch((err) => {
        console.log(err);
        props.snack(
          ALERT_ERROR,
          "Un problème est survenue lors de la suppression"
        );
      });
    setState((prevState) => ({ ...prevState, openDialogDelete: false }));
    getUserGroups();
    handleToggleDrawer();
    props.stopLoading();
  };

  return (
    <div style={{ overflow: "auto" }} onScroll={handleScroll}>
      <TopPanel
        icomoon="icon-catalogue"
        getRef
        setRef={setOffsetListing}
        colorIcomoon={colors.blue.lighter.hue300}
        title={props.t("drawer.user_management")}
        subtitle={props.t("drawer.user_management_subtitle")}
        dataCount={state.dataCount}
        textAdd={props.t("users.manage.add")}
        handlerAdd={() => handleForm("add")}
        gradientColor1={colors.menu.regular}
        gradientColor2={colors.menu.darker}
        buttonAvailable={true}
        windowWidth={windowWidth}
        searchHandler={handleChange}
        openForm={state.openForm}
        hasBorder={true}
      />

      <div>
        {state.userGroups ?
          state.userGroups.map((userGroup) => (
            <AccordionCustom
              title={
                userGroup.name &&
                `${userGroup.name} (${userGroup.users?.length})`
              }
            >
              <GridView
                scroll={state.scrollListing}
                settings={listSettings.grid}
                propsToPass={null}
                items={userGroup.users.map((user) => ({
                  ...user,
                  userGroup: userGroup,
                }))}
                label={"test"}
                currentLang={null}
                cardProps={{
                  editUser: goToUser,
                  redirectDetail: goToUser,
                  showPercent: true,
                  percent: "percentCompletude",
                  image: "media",
                  filePath: "filePath",
                  title: "name",
                  description: "description",
                  handleToggleForm: handleToggleForm,
                  secondAttributes: [
                    {
                      label: "Sources",
                      value: "scrapSources",
                      type: "array",
                    },
                  ],
                }}
              />
            </AccordionCustom>
          ))
          :<PageLoader/>
        }
        {state.userGroups && (
          <LayoutBuilder
            isSublayout={false}
            opened={state.openForm}
            icomoon={"ico-ajouter-utilisateur"}
            forClose={handleToggleDrawer}
            dataLayout={
              state.formType === "edit"
                ? editUserForm(state.userGroups,state?.generate)
                : state.formType === "add" && addUserForm(state.userGroups,state.generate)
            }
            allState={state}
            stateCallback={handleChangeState}
            errorCallback={handleFormError}
            handleButtonGroupChange={handleButtonGroupChange}
            handlerMutation={
              state.formType === "edit"
                ? handlerEditMutation
                : state.formType === "add" && handlerMutation
            }
            validateButton={true}
            deleteMutation={
              state.formType === "edit"
                ? () => {
                    setState((prevState) => ({
                      ...prevState,
                      openDialogDelete: true,
                    }));
                  }
                : null
            }
            deleteText={state.formType === "edit" ? "Supprimer" : null}
          />
        )}
      </div>

      {/* Modal warning delete */}
      <DialogModal
        icon={true}
        type="delete"
        open={state.openDialogDelete}
        onClose={() =>
          setState((prevState) => ({ ...prevState, openDialogDelete: false }))
        }
        title={"Êtes-vous sûr de vouloir supprimer cet utilisateur ?"}
        primaryAction={() => deleteMutation()}
        secondaryAction={() =>
          setState((prevState) => ({ ...prevState, openDialogDelete: false }))
        }
        windowWidth={props.windowWidth}
      >
        <DialogContentText id="alert-dialog-description">
          Si vous supprimez cet utilisateur, celui-ci ne sera plus accessible.
          Si vous ne souhaitez pas le supprimer, annulez la suppression en
          cliquant sur annuler.
        </DialogContentText>
      </DialogModal>
    </div>
  );
};

SettingsUsers.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withApollo(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsUsers))
  )
);
