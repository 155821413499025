import { gql } from "@apollo/client";

export const getBrands = gql`
  query getBrands($user: String) {
    productSources(isBrand: true, users: $user) {
      totalCount
      edges {
        node {
          id
          libelle
          media {
            id
            filePath
            type
            size
          }
          description
          status
          link
          isBrand
          isDisplay
          product {
            totalCount
          }
          productSourceProductStates(state: 0) {
            totalCount
          }
          productSourceScraps {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_BRANDS_PAGINATION = gql`
  query getBrandsPagination(
    $nbperpage: Int
    $cursor: String
    $cursorLast: String
    $libelle: String
    $user: String
  ) {
    productSources(
      first: $nbperpage
      after: $cursor
      before: $cursorLast
      libelle: $libelle
      isBrand: true
      users: $user
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          libelle
          media {
            id
            filePath
            type
            size
          }
          description
          status
          activeAlert
          link
          isBrand
          isDisplay
          product {
            totalCount
          }
          productSourceProductStates(state: 0) {
            totalCount
            edges {
              node {
                id
              }
            }
          }
          productSourceMappings {
            totalCount
            edges {
              node {
                id
                productSourceScrap {
                  id
                }
                attribute {
                  id
                }
              }
            }
          }
          productSourceAlerts {
            totalCount
            edges {
              node {
                id
                min
                max
              }
            }
          }
          productSourceScraps {
            totalCount
            edges {
              node {
                name
                link
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const getOneBrand = gql`
  query getOneBrand($id: ID!) {
    productSource(id: $id) {
      id
      libelle
      media {
        filePath
      }
      description
      status
      link
      isBrand
      isDisplay
      activeAlert
      product {
        totalCount
      }
      productSourceScraps {
        edges {
          node {
            name
            id
            link
          }
        }
      }
      productSourceProductStates(state: 0) {
        totalCount
      }
      productSourceMappings {
        totalCount
        edges {
          node {
            id
            productSourceScrap {
              id
            }
            attribute {
              id
            }
          }
        }
      }
      productSourceAlerts {
        totalCount
        edges {
          node {
            id
            min
            max
          }
        }
      }
      productSourceScraps {
        totalCount
        edges {
          node {
            name
            link
            id
          }
        }
      }
    }
  }
`;

//--- BRAND
export const ADD_BRAND = gql`
  mutation createProductSource(
    $libelle: String
    $isBrand: Boolean!
    $isDisplay: Boolean!
    $media: String
    $description: String
    $status: Boolean!
    $link: String
    $activeAlert: Boolean!
  ) {
    createProductSource(
      input: {
        libelle: $libelle
        isBrand: $isBrand
        isDisplay: $isDisplay
        media: $media
        description: $description
        status: $status
        link: $link
        activeAlert: $activeAlert
      }
    ) {
      productSource {
        id
      }
    }
  }
`;

/**
/!\ - il est nécessaire de bien pousser l'ensemble des users dans le cas ou on update ce champs
*/
export const UPDATE_BRAND = gql`
  mutation updateProductSource(
    $id: ID!
    $libelle: String
    $isBrand: Boolean
    $isDisplay: Boolean
    $media: String
    $description: String
    $status: Boolean
    $link: String
    $activeAlert: Boolean
    $users: [String]
  ) {
    updateProductSource(
      input: {
        id: $id
        libelle: $libelle
        isBrand: $isBrand
        isDisplay: $isDisplay
        media: $media
        description: $description
        status: $status
        link: $link
        activeAlert: $activeAlert
        users: $users
      }
    ) {
      productSource {
        id
      }
    }
  }
`;

export const DELETE_BRAND = gql`
  mutation deleteProductSource($id: ID!) {
    deleteProductSource(input: { id: $id }) {
      productSource {
        id
      }
    }
  }
`;

//--- BRAND SOURCE SCRAP
export const ADD_BRAND_SOURCE_SCRAP = gql`
  mutation createProductSourceScrap(
    $name: String!
    $link: String
    $codeScrap: String!
    $productSource: String
  ) {
    createProductSourceScrap(
      input: {
        name: $name
        link: $link
        codeScrap: $codeScrap
        productSource: $productSource
      }
    ) {
      productSourceScrap {
        id
      }
    }
  }
`;

export const UPDATE_BRAND_SOURCE_SCRAP = gql`
  mutation updateProductSourceScrap(
    $id: ID!
    $name: String
    $link: String
    $productSource: String
  ) {
    updateProductSourceScrap(
      input: {
        id: $id
        name: $name
        link: $link
        productSource: $productSource
      }
    ) {
      productSourceScrap {
        id
      }
    }
  }
`;

export const DELETE_BRAND_SOURCE_SCRAP = gql`
  mutation deleteProductSourceScrap($id: ID!) {
    deleteProductSourceScrap(input: { id: $id }) {
      productSourceScrap {
        id
      }
    }
  }
`;

//--- BRAND SOURCE MAPPING
export const ADD_BRAND_SOURCE_MAPPING = gql`
  mutation createProductSourceMapping(
    $productSource: String
    $attribute: String
    $productSourceScrap: String
  ) {
    createProductSourceMapping(
      input: {
        productSource: $productSource
        attribute: $attribute
        productSourceScrap: $productSourceScrap
      }
    ) {
      productSourceMapping {
        id
      }
    }
  }
`;

export const UPDATE_BRAND_SOURCE_MAPPING = gql`
  mutation updateProductSourceMapping(
    $id: ID!
    $productSource: String
    $attribute: String
    $productSourceScrap: String
  ) {
    updateProductSourceMapping(
      input: {
        id: $id
        productSource: $productSource
        attribute: $attribute
        productSourceScrap: $productSourceScrap
      }
    ) {
      productSourceMapping {
        id
      }
    }
  }
`;

export const DELETE_BRAND_SOURCE_MAPPING = gql`
  mutation deleteProductSourceMapping($id: ID!) {
    deleteProductSourceMapping(input: { id: $id }) {
      productSourceMapping {
        id
      }
    }
  }
`;

//--- BRAND SOURCE MAPPING
export const ADD_BRAND_SOURCE_ALERT = gql`
  mutation createProductSourceAlert(
    $productSource: String
    $ProductSource: String
    $min: Float!
    $max: Float!
  ) {
    createProductSourceAlert(
      input: {
        ProductSource: $ProductSource
        productSource: $productSource
        min: $min
        max: $max
      }
    ) {
      productSourceAlert {
        id
      }
    }
  }
`;

export const UPDATE_BRAND_SOURCE_ALERT = gql`
  mutation updateProductSourceAlert(
    $id: ID!
    $productSource: String
    $min: Float
    $max: Float
  ) {
    updateProductSourceAlert(
      input: { id: $id, productSource: $productSource, min: $min, max: $max }
    ) {
      productSourceAlert {
        id
      }
    }
  }
`;

export const DELETE_BRAND_SOURCE_ALERT = gql`
  mutation deleteProductSourceAlert($id: ID!) {
    deleteProductSourceAlert(input: { id: $id }) {
      productSourceAlert {
        id
      }
    }
  }
`;

export const GET_PRODUCTS_BY_SOURCE = gql`
  query getProductsByBrand($productSources_id: Int) {
    products(productSources_id: $productSources_id) {
      totalCount
      edges {
        node {
          flatProducts {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CHECK_IF_CAN_EXPORT_PRODUCTS_NOT_EXPORTED_YET = gql`
  query getCheckIfCanExportProductsNotExportedYet($productSources_id: Int) {
    productSourceWorkflows(
      product_productSources_id: $productSources_id
      exists: [{ exportedAt: false }]
    ) {
      totalCount
    }
  }
`;

export const getUserBrands = gql`
  query getBrands($user: String) {
    productSources(isBrand: true, users: $user) {
      edges {
        node {
          id
          libelle
        }
      }
    }
  }
`;

export const getUsersOneBrand = gql`
  query getUsersOneBrand($id: ID!) {
    productSource(id: $id) {
      id
      libelle
      users {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;
