import { Component } from 'react';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import './TreeView.scss';

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import FolderIcon from '@mui/icons-material/Folder';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import LocalOfferSharpIcon from '@mui/icons-material/LocalOfferSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import Badge from '@mui/material/Badge';

import { Box, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import PageLoader from '../loadings/page-loader/PageLoader';
import {withTranslation} from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { Info } from '@mui/icons-material';
import LogsActions from '../logs-actions/LogsActions';
import IcomoonReact from 'icomoon-react';
import iconSet from "../../../assets/selection.json";
import AccordionCustom from '../../layouts/Accordion/AccordionCustom';
import OurTypography from '../typography/Typography';
import { startTransition } from 'react';


const BadgeCustom = styled(Badge)`
  display: block;
  margin-right: 15px; 
  &>span{
      border: 1px solid ${colors.blue.darker.hue300};
      font-weight: bold;
      background-color: ${props => props.bgcolor ? props.bgcolor : colors.white};
      color: ${props => props.bgcolor ? colors.blue.darker.hue300 : colors.black.regular};
  }
`;

class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
        treeData: this.props.dataTree,
        selectedNodeId: this.props.selectedCategorie,
        selected: this.props.selectedCategory || null,
        infoLogs: false,
        currentCategory: null
    };
    this.updateTreeData = this.updateTreeData.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
  }

  nodeClicked = (event, rowInfo) => {
    const clickedElement = event.target;

    this.setState({
      clickedElement: clickedElement,
      clickedNode: rowInfo.node,
    }, () => {
      if (event.target.className.includes && (event.target.className.includes('collapseButton') || event.target.className.includes('expandButton'))) {
        // ignore the event
      } else {
        this.setState({selectedNodeId: rowInfo.node.id});
      }
      if(this.props.handleChangeCategorie && !this.props.preventItemClick){
        this.props.handleChangeCategorie(rowInfo.node.id,rowInfo.node.children,rowInfo.node.parent,rowInfo.node);      
      }
      if (this.props.handleClickNode){
        this.props.handleClickNode(rowInfo)
      }
    })
  };

  updateTreeData(treeData) {
    this.setState({ treeData });
  }

  expandAll() {
    this.props.expand(true, this.props.stateName);

    const activeNodes = document.querySelectorAll('.rstcustom__node.currentNodeChildren');
    Array.from(activeNodes).forEach(node => {
      node.classList.remove('currentNodeChildren')
    })
  }

  collapseAll() {
    this.props.expand(false, this.props.stateName);

    const activeNodes = document.querySelectorAll('.rstcustom__node');
    Array.from(activeNodes).forEach(node => {
      node.classList.add('currentNodeChildren')
    })
  }

  closeChildrenFolders = (node) => {
    if (node.children) {
      node.children.forEach(child => {
        child.expanded = false;
        this.closeChildrenFolders(child);
      });
    }
  }

  getChildrenNodes = (selectedNode) => {
    if(selectedNode.nextElementSibling){
      const blocksOfLevel = selectedNode.nextElementSibling.querySelectorAll('.rstcustom__lineBlock').length;
        
      const clickedNode = selectedNode.parentNode.parentNode

      const allNodes = document.querySelectorAll('.rstcustom__node');
      const clickedNodeIndex = Array.from(allNodes).indexOf(clickedNode);

      const nextNodes = Array.from(allNodes).slice(clickedNodeIndex + 1)
      const nextSiblingNodeTemporary = nextNodes.findIndex((node, index) => {
        return node.querySelectorAll('.rstcustom__lineBlock').length <= blocksOfLevel
      })

      let childrenNodes = [];
      // Root
      if(nextSiblingNodeTemporary === -1){
        childrenNodes = Array.from(allNodes).slice(clickedNodeIndex + 1);
      } else {
        const nextSiblingNodeIndex = Array.from(allNodes).indexOf(nextNodes[nextSiblingNodeTemporary]);
        childrenNodes = Array.from(allNodes).slice(clickedNodeIndex + 1, nextSiblingNodeIndex);
      }

      return childrenNodes;
    }
    return null
  }

  componentDidMount() {
    // let root = document.querySelector('.rstcustom__node .rstcustom__collapseButton');

    // if(root){
    //   const childrenNodes = this.getChildrenNodes(root);

    //   childrenNodes.forEach(node => {
    //     node.classList.add('currentNodeChildren')
    //   })
    // }

    const root = document.querySelectorAll('.rstcustom__node');

    if(root){
      Array.from(root).forEach(node => {
        node.classList.add('currentNodeChildren')
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataTree !== this.props.dataTree) {
      // * ADD LINES EVERYWHERE (Used before to add all lines for treeviews with just 1 level)
      const root = document.querySelectorAll('.rstcustom__node');

      if(root){
        Array.from(root).forEach(node => {
          node.classList.add('currentNodeChildren')
        })
      }


      // * CALCULATE CHILDREN NODES TO SHOW LINES JUST IN CURRENT LEVEL (Used when treeview has more than 1 level)
      if(this.state.clickedElement && (this.state.clickedElement.classList.contains('rstcustom__collapseButton') || this.state.clickedElement.classList.contains('rstcustom__expandButton'))){
        this.closeChildrenFolders(this.state.clickedNode);
        
        // Remove lines
        document.querySelectorAll('.rstcustom__node.currentNodeChildren').forEach(node => {
          node.classList.remove('currentNodeChildren')
        })

        let childrenNodes = [];

        childrenNodes = this.getChildrenNodes(this.state.clickedElement);
        if(childrenNodes){
          if(childrenNodes.length === 0){
            let nextNodes = [];
            let previousNodes = [];
  
            const allNodes = document.querySelectorAll('.rstcustom__node');
            const clickedNodeIndex = Array.from(allNodes).indexOf(this.state.clickedElement.parentNode.parentNode);
            const nbBlocksOfClickedElement = this.state.clickedElement.parentNode.parentNode.querySelectorAll('.rstcustom__lineBlock').length
  
            // Get previous siblings
            const previousChildrenNodes = Array.from(allNodes).slice(0, clickedNodeIndex).reverse()
            const previousSiblingNodeTemporary = previousChildrenNodes.findIndex((node, index) => {
              return node.querySelectorAll('.rstcustom__lineBlock').length < nbBlocksOfClickedElement
            })
            if(previousSiblingNodeTemporary === -1){
              previousNodes = Array.from(allNodes).slice(0, clickedNodeIndex);
            } else {
              const previousSiblingNodeIndex = Array.from(allNodes).indexOf(previousChildrenNodes[previousSiblingNodeTemporary]);
              previousNodes = Array.from(allNodes).slice(previousSiblingNodeIndex, clickedNodeIndex + 1);
            }
  
            // Get next siblings
            const nextChildrenNodes = Array.from(allNodes).slice(clickedNodeIndex + 1)
            const nextSiblingNodeTemporary = nextChildrenNodes.findIndex((node, index) => {
              return node.querySelectorAll('.rstcustom__lineBlock').length < nbBlocksOfClickedElement
            })
            if(nextSiblingNodeTemporary === -1){
              nextNodes = Array.from(allNodes).slice(clickedNodeIndex + 1);
            } else {
              const nextSiblingNodeIndex = Array.from(allNodes).indexOf(nextChildrenNodes[nextSiblingNodeTemporary]);
              nextNodes = Array.from(allNodes).slice(clickedNodeIndex + 1, nextSiblingNodeIndex);
            }
  
            // Mix all siblings with same blocks as clicked element
            childrenNodes = previousNodes.concat(nextNodes).filter(node => node.querySelectorAll('.rstcustom__lineBlock').length === nbBlocksOfClickedElement);
          }

          // Create lines
          childrenNodes.forEach(node => {
            node.classList.add('currentNodeChildren')
          })
        }
      }
    }
  }

  customSearchMethod = ({ node, searchQuery }) =>
        searchQuery && node?.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

  renderSwitch(param) {
    switch(param){      
      case 'categorie':
        return(
          <div className={`treeContent ${this.props.reducedTree ? 'reducedTree' : ''} ${this.props.overflowed ? 'overflowed' : ''}`}>
              <SortableTree
                theme={FileExplorerTheme}
                treeData={this.props.dataTree}
                isVirtualized={false}
                onChange={(treeData) => {
                  this.props.onChange(treeData);
                }}
                canDrag={({ node }) => this.props.canDrag !== undefined ? this.props.canDrag : !node.dragDisabled}
                canDrop={({ nextParent, node }) => {
                  return nextParent === null ? false : this.props.canDrop !== undefined ? this.props.canDrop ? node.parent.id === nextParent?.id : null : !nextParent || nextParent.isDirectory;
                }}
                onMoveNode={({treeData, node, nextTreeIndex}) => this.props.onMoveNode?.(treeData, node, nextTreeIndex)}
                searchQuery={this.props.searchQuery}
                searchMethod={this.customSearchMethod}
                dndType={'dndTree'}
                shouldCopyOnOutsideDrop={this.props.shouldCopy}
                generateNodeProps={rowInfo => ({
                  onClick: event => this.nodeClicked(event, rowInfo),
                  icons: rowInfo.node.isDirectory || rowInfo.node.mainCat
                    ? [
                      rowInfo.node.expanded 
                        ? <FolderOpenRoundedIcon className={"icons"}/>
                        : <FolderIcon className={"icons"}/>
                      ]
                    : [
                        <div>
                        </div>,
                      ],
                  buttons:
                    rowInfo.node.isDirectory ? [
                      this.props.canModify ?
                        rowInfo.node.isRoot ? null : <div //rowInfo.node.isRoot ? null : 
                          className={"treeOptions smallSpace"}
                          style={{marginRight: 12}}
                          onClick={() => this.props.editCat(rowInfo.node)}
                        >
                          <Grid container alignItems='center'>
                            <Tooltip title={this.props.t('products.categories.modify')}>
                              <Grid container>
                                <EditRoundedIcon/> 
                                {window.innerWidth >= 1920 ?
                                  <OurTypography fontsize={'12px'} fontweighttext={'bold'} colortext={colors.blue.darker.hue300}>Modifier</OurTypography>
                                : null }
                              </Grid>
                            </Tooltip>
                          </Grid>
                        </div>
                      : null,
                      this.props.canAdd ?
                        <div 
                          className={"treeOptions smallSpace"}
                          style={{marginRight: 12}}
                          onClick={() => this.props.addSubcategory(rowInfo.node)}
                        >
                          <Grid container alignItems='center'>
                            <Tooltip title={this.props.t('products.categories.subcategory')}>
                              <Grid container>
                                <AddCircleRoundedIcon/>
                                {window.innerWidth >= 1920 ?
                                  <OurTypography fontsize={'12px'} fontweighttext={'bold'} colortext={colors.blue.darker.hue300}>Créer</OurTypography>
                                : null }
                              </Grid>
                            </Tooltip>
                          </Grid>
                          {/* <Typography variant={"h5"}>{this.props.t('products.categories.subcategory')}</Typography> */}
                        </div>
                      : null,
                      this.props.canDelete && !rowInfo.node.isRoot ?
                      <div 
                        className={"treeOptions smallSpace"}
                        style={{marginRight: 12}}
                        onClick={() => this.props.deleteCat(rowInfo.node.id)}
                      >
                        <Grid container alignItems='center'>
                          <Tooltip title={this.props.t('products.completeness.deletebutton')}>   
                            <Grid container>
                              <DeleteSharpIcon/>
                              {window.innerWidth >= 1920 ?
                                <OurTypography fontsize={'12px'} fontweighttext={'bold'} colortext={colors.blue.darker.hue300}>Supprimer</OurTypography>
                              : null}
                            </Grid>                           
                          </Tooltip>
                        </Grid>
                      </div>
                    : null,
                      this.props.canLog && !rowInfo.node.isRoot ?
                        <div 
                          className={"treeOptions smallSpace"}
                          style={{marginRight: 12}}
                          onClick={() => this.handleToggleDrawer('infoLogs',rowInfo.node)}
                        >
                          <Grid container alignItems='center'>
                            <Tooltip title={this.props.t('spread.treeview.logs')}>
                              <Grid container>
                                <Info/>
                                {window.innerWidth >= 1920 ?
                                  <OurTypography fontsize={'12px'} fontweighttext={'bold'} colortext={colors.blue.darker.hue300}>Informations</OurTypography>
                                : null }
                              </Grid>  
                            </Tooltip>
                          </Grid>
                        </div> 
                      : null,
                      this.props.clearCache && !rowInfo.node.isRoot ?
                        <div 
                          className={"treeOptions smallSpace"}
                          onClick={() => this.props.clearCache(rowInfo.node)}
                        >
                          <Typography variant={"h5"}>{this.props.t('products.categories.clearCache')}</Typography>
                        </div>
                      : null,
                    ]
                    : null,
                })}
              />
          </div>
        );
      case 'selectCategorieFull':
      case 'selectCategorie':
        return(
          <div className={`treeContent ${this.props.reducedTree ? 'reducedTree' : ''} ${this.props.overflowed ? 'overflowed' : ''}`}>
              <SortableTree
                theme={FileExplorerTheme}
                treeData={this.props.dataTree}
                isVirtualized={false}
                onChange={(treeData) => {
                  this.props.onChange(treeData);
                }}
                canDrag={false}
                canDrop={false}
                dndType={'dndTree'}
                searchQuery={this.props.searchQuery}
                searchMethod={this.customSearchMethod}
                generateNodeProps={rowInfo => ({
                  onClick: event => this.nodeClicked(event, rowInfo),
                  icons: 
                    rowInfo.node.isDirectory || rowInfo.node.mainCat ? 
                      [ rowInfo.node.expanded ? <FolderOpenRoundedIcon className={"icons"}/> : <FolderIcon className={"icons"}/>]
                    : [<div></div>],
                  className: this.state.selected === rowInfo.node.id ? 'rst__selected-node' : null,
                  buttons:[
                    <div className={"treeOptions"}
                      onClick={() => {this.props.selectCategorie(rowInfo); this.setState({selected: rowInfo.node.id})}}
                    >
                      <Typography variant={"h5"}>Sélectionner cette catégorie</Typography>
                    </div>
                  ],
                })}
              />
          </div>
        );
      case 'categorieFilter':
        return(
          <div className={`treeContent ${this.props.reducedTree ? 'reducedTree' : ''} ${this.props.overflowed ? 'overflowed' : ''}`}>
              <SortableTree
                theme={FileExplorerTheme}
                treeData={this.props.dataTree}
                isVirtualized={false}
                onChange={(treeData) => {
                  this.props.onChange(treeData);
                }}
                canDrag={({ node }) => this.props.canDrag !== undefined ? this.props.canDrag : !node.dragDisabled}
                canDrop={({ nextParent, node }) => nextParent === null ? false : this.props.canDrop !== undefined ? this.props.canDrop : !nextParent || nextParent.isDirectory}
                dndType={'dndTree'}
                shouldCopyOnOutsideDrop={this.props.shouldCopy}
                generateNodeProps={rowInfo => {
                  let nodeProps =  {
                    onClick: event => this.nodeClicked(event, rowInfo),
                    icons: 
                      rowInfo.node.isDirectory || rowInfo.node.mainCat ? 
                        [ rowInfo.node.expanded ? <FolderOpenRoundedIcon className={"icons"}/> : <FolderIcon className={"icons"}/>]
                      : [ <div></div> ],
                  };
                  if (this.state.selectedNodeId === rowInfo.node.id) {
                    nodeProps.className = 'rst__selected-node';
                  }
                  return nodeProps;
                }}
                style={{overflowX: 'hidden!important'}}
              />
          </div>
        );
      case 'attribut':
        return(
          <div className={`treeContent ${this.props.reducedTree ? 'reducedTree' : ''} ${this.props.overflowed ? 'overflowed' : ''}`}>
              {
                this.props.dataTree?.length ? 
                  null 
                  : this.props.none
              }
              <SortableTree
                theme={FileExplorerTheme}
                treeData={this.props.dataTree}
                isVirtualized={false}
                onChange={this.props.onChange}
                onMoveNode={({treeData, node, nextTreeIndex}) => this.props.onMoveNode?.(treeData, node, nextTreeIndex)}
                canDrag={({ node }) => !node.dragDisabled}
                // canDrop={({ nextParent, node }) => !nextParent || nextParent.isDirectory}
                canDrop={({ nextParent, node }) => {
                  return nextParent === null ? false : node.parent === nextParent.node.id ? true : false;
                }}
                generateNodeProps={
                  rowInfo => ({
                    onClick: event => this.nodeClicked(event, rowInfo),
                    // icons: rowInfo.node.isDirectory || rowInfo.node.mainCat ? [rowInfo.node.expanded ? <LocalOfferSharpIcon className={"icons"}/> : <LocalOfferSharpIcon className={"icons"}/>] : [<div></div>],
                    buttons: rowInfo.node.noAction ? [] 
                    : rowInfo.node.isItem ? 
                      [
                        <div className={"treeOptions type-info"}>
                          <BadgeCustom bgcolor={colors.blue.lighter.hue900} badgeContent={rowInfo.node.node.attributeType.input} />
                        </div>,
                        this.props.canModify ?
                          <div className={"treeOptions"} onClick={() => {this.props.editItem(rowInfo.node)}} style={{transform: 'translateY(-2px)'}}>
                            <EditRoundedIcon/>
                            <Typography variant={"h5"} style={{fontWeight: 'bold', marginRight: 10}}>{this.props.t('products.completeness.edit')}</Typography>
                          </div>
                        : null,
                        this.props.canDisable && (
                          <div 
                            className={"treeOptions"}
                            style={{marginRight: 12}}
                            onClick={() => this.props.disableCat(rowInfo.node.node.id)}
                          >
                            <Grid container alignItems={'center'} style={{transform: 'translateY(2px)'}}>
                              {rowInfo.node.node.status 
                                ? <DeleteSharpIcon style={{color: colors.red.regular}} />
                                : <CheckCircleSharpIcon style={{color: colors.green.regular}} />
                              }
                              <OurTypography variant={"h5"} fontsize={'12px'} fontweighttext={'bold'} colortext={rowInfo.node.node.status ? colors.red.regular : colors.green.regular}>
                                {rowInfo.node.node.status ? 'Désactiver' : 'Réactiver'}
                              </OurTypography>
                            </Grid>
                          </div> 
                        ),
                        !rowInfo.node.node.status ? 
                          <div className={"treeOptions type-info"}>
                            <Typography variant={"h5"} style={{color: colors.red.regular, textDecoration: 'none', fontWeight: 'bold', transform: 'translateY(-2px)'}}>Attribut désactivé</Typography>
                          </div>
                        : null
                          
                      ] 
                    : rowInfo.node.isDirectory ? 
                        [
                          this.props.canModify ?
                              <div className={"treeOptions"} onClick={() => this.props.editCat(rowInfo.node)}>
                                <EditRoundedIcon/>
                                <Typography variant={"h5"}>{this.props.t('products.completeness.edit')}</Typography>
                              </div>
                            : null,
                          this.props.canAdd ?
                            <div 
                              className={"treeOptions"}
                              onClick={() => this.props.addSubcategory(rowInfo.node.node)}
                            >
                              <AddCircleRoundedIcon/>
                              <Typography variant={"h5"}>{this.props.content ? 'Ajouter une variable de modèle' :'Ajouter un attribut'}</Typography>
                            </div>
                          : null,
                        ] 
                      : [], 
                    style: {color: !rowInfo.node.node.status && rowInfo.node.isItem ? colors.grey.lighter.hue900 : null}
                })}
              />
          </div>
        );
      case 'classic':
        return(
          <div className={`treeContent ${this.props.reducedTree ? 'reducedTree' : ''} ${this.props.overflowed ? 'overflowed' : ''}`}>
              <SortableTree
                theme={FileExplorerTheme}
                treeData={this.props.dataTree}
                isVirtualized={false}
                onChange={this.props.onChange}
                canDrag={({ node }) => {
                  return this.props.canDrag !== undefined ? this.props.canDrag : !node.dragDisabled;
                }}
                canDrop={({ nextParent, node }) => {
                  return this.props.canDrop !== undefined ? this.props.canDrop : !nextParent || nextParent.isDirectory;
                }}
                dndType={'dndTree'}
                shouldCopyOnOutsideDrop={this.props.shouldCopy}
                searchQuery={this.props.searchQuery}
                searchMethod={this.customSearchMethod}
                generateNodeProps={rowInfo => ({
                  onClick: event => this.nodeClicked(event, rowInfo),
                  icons: 
                    rowInfo.node.isDirectory || rowInfo.node.mainCat ? 
                      [ rowInfo.node.expanded ? <FolderOpenRoundedIcon className={"icons"}/> : <FolderIcon className={"icons"}/>]
                    : [<div></div>],
                  buttons: this.props.shouldCopy ? [
                    <div className={"treeOptions"}
                      onClick={() => this.props.copyCategories(rowInfo)}
                    >
                      <AddCircleRoundedIcon/>
                      <Typography variant={"h5"}>Ajouter au catalogue</Typography>
                    </div>
                  ] : null,
                })}
              />
          </div>
        );
      default: return null;
    }
  }

  handleToggleDrawer = (stateDrawer, category) => {
    this.setState({ 
      [stateDrawer]: !this.state[stateDrawer],
      currentCategory: category ? category.id : null
    });
  };

  render() {
    return (
      this.props.loading ? (
        <div className={"blockContainer"}>
          <div className={this.props.marginTop ? "containerTree topSpace" : "containerTree"}>
            <PageLoader style={{height: '100%'}} />
          </div>
        </div>
      ) : (
        // <div className={`blockContainer ${this.props.typeOfTree}`} style={{height: '100%'}}>
        <div className={`blockContainer ${this.props.typeOfTree}`}>
          <div className={this.props.marginTop ? "containerTree topSpace" : "containerTree"}>
            <AccordionCustom overflow={'auto'} style={{
              height: '100%',
              // overflow: 'auto',
              display: 'grid',
              gridTemplateRows: 'auto 1fr',
              gridTemplateColumns: '100%',
            }} forcedExpanded={true} title={(
              <Grid container alignItems='center' justifyContent='space-between'>
                <Grid container alignItems='center' style={{width: 'auto'}}>
                  {this.props.allButton ? 
                    <button className={"buttonAll"} onClick={this.props.allButton}><Typography variant={"h4"}>Tout voir</Typography></button> 
                  : null}
                  {this.props.noExpand 
                    ? null 
                    : (
                      <div className={"containerButtons"}>
                        <button onClick={(e) => {
                          e.preventDefault(); 
                          this.expandAll();
                        }}>
                          <Typography variant={"h4"} style={{
                            fontWeight: 'bold',
                          }}>{this.props.t('products.categories.open')}</Typography>
                        </button>
                        <button onClick={(e) => {
                          e.preventDefault(); 
                          this.collapseAll();
                        }}>
                          <Typography variant={"h4"}>{this.props.t('products.categories.reduce')}</Typography>
                        </button>
                      </div>
                    )}
                  {this.props.title && (
                    <Typography variant={"h4"} style={{
                      fontWeight: 'bold',
                    }}>{this.props.title}</Typography>
                  )}
                </Grid>
                {
                  this.props.secondaryAction ? (
                    <Box>
                      {this.props.secondaryAction}
                    </Box>
                  ) : null
                }
              </Grid>
            )}>
              { this.renderSwitch(this.props.typeOfTree) }
            </AccordionCustom>
          </div>
          <LogsActions 
            typeToLoad={this.state.currentCategory} 
            open={this.state.infoLogs} 
            toggleDrawer={() => this.handleToggleDrawer('infoLogs')}
          />
        </div>
      )
    );
  }
}
export default withTranslation()(Tree)