import React from 'react';
import { withRouter } from 'react-router';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from "react-redux";
import { Grid, Typography } from '@mui/material';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import { eventService } from '../../../../../js/services/event.service';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../../js/constants/alert-types';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import { GET_DEMANDS, CREATE_DEMAND, UPDATE_DEMAND, DELETE_DEMAND } from '../../../../../queries/demands';
import { CREATE_DEMANDDATA, UPDATE_DEMANDDATA } from '../../../../../queries/demanddatas';

import Button from '../../../../ui/button/Button';
import DialogModal from '../../../../ui/dialog/DialogModal';
import CardCustom from '../../../../layouts/Card/CardCustom';
import TopPanel from '../../../../layouts/TopPanel/TopPanel';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';

import LayoutBuilder from '../../../../ui/form/LayoutFormBuilder';
import addDemandsForm from './config/addDemandsForm.config';
import editDemandsForm from './config/editDemandsForm.config';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

class ListDemands extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isEmpty: false,
            openForm: false,
            currentLang: this.props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            ready: false,
            noResult: false,
            listDemands: [],
            image: null,
            code: null,
            name: null,
            editForm: false,
        };
    }

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            });
        }
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();
        });
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({ [stateName]: value });
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        let value = null
        if (custom && !evt) {
            value = custom;
        }
        else {
            value = evt?.target?.value ?? evt;
        }
        this.setValue(stateName, value, translated);
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, null, selected, null);
    };

    handleToggleDrawer = (stateDrawer) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        }, () => {
            if (stateDrawer === 'openForm' && !this.state[stateDrawer]) {
                this.setState({ name: null, code: null, image: null, editForm: false });
            }
        });
    };

    handleToggleDialog = (name) => {
        this.setState({
            [name]: !this.state[name]
        });
    };

    handleFormError = (stateName, error) => {
        let errors = {...this.state.errors};
        errors[stateName] = error;
        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    handlerMutation = async () => {
        this.props.startLoading();
        if (this.hasErrors('firstForm')) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            this.props.stopLoading();
            return eventService.fire();
        }
        if (this.state.editForm) {
            let variables = { id: this.state.editId, code: this.state.code };
            if (this.state.image.filePath) {
                variables.imageUrl = `${process.env.REACT_APP_MEDIAS}/${this.state.image.filePath}`;
            }
            this.props.client.mutate({
                mutation: UPDATE_DEMAND,
                variables,
            }).then(result => {
                this.setState({
                    newDemand: result.data.updateDemand.demand
                }, () => {
                    let variables = { id: this.state.newDemand.demandData.edges[0].node.id, demand: this.state.newDemand.id, value: this.state.name, locale: this.props.locales[0].node.id }
                    this.props.client.mutate({
                        mutation: UPDATE_DEMANDDATA,
                        variables,
                    }).then(result => {
                        this.handleGetDemands();
                        this.handleToggleDrawer('openForm');
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, 'Demande modifiée avec succès');
                    }).catch((err)=>{
                        console.log(err)
                    });
                })
            }).catch((err)=>{
                console.log(err)
            });
        }
        else {
            let imageUrl = `${process.env.REACT_APP_MEDIAS}/${this.state.image.filePath}`;
            let variables = { imageUrl, code: this.state.code };
            this.props.client.mutate({
                mutation: CREATE_DEMAND,
                variables,
            }).then(result => {
                this.setState({
                    newDemand: result.data.createDemand.demand
                }, () => {
                    let variables = { demand: this.state.newDemand.id, value: this.state.name, locale: this.props.locales[0].node.id }
                    this.props.client.mutate({
                        mutation: CREATE_DEMANDDATA,
                        variables,
                    }).then(result => {
                        this.handleGetDemands();
                        this.handleToggleDrawer('openForm');
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, 'Demande ajoutée avec succès');
                    }).catch((err)=>{
                        console.log(err)
                    });
                })
            }).catch((err)=>{
                console.log(err)
            });
        }
    };

    handleGetDemands = () => {
        this.props.client.query({
            query: GET_DEMANDS,
            fetchPolicy: 'no-cache'
        }).then(async result => {
            this.setState({ listDemands: result.data.demands.edges, ready: true })
        }).catch((err)=>{
            console.log(err)
        });
    }

    deleteMutation = (id) => {
        this.props.startLoading();
        let variables = { id: this.state.idToDelete };

        this.props.client.mutate({
            mutation: DELETE_DEMAND,
            variables,
        }).then(result => {
            this.props.snack(ALERT_SUCCESS, 'Demande supprimée avec succès');
            this.handleGetDemands();
            this.handleToggleDialog('openDialog');
            this.props.stopLoading();
        }).catch((err)=>{
            console.log(err)
        });
    };

    componentDidMount() {
        this.handleGetDemands();
    }

    render() {
        return (
            <div style={{ width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', paddingBottom: 32 }}>
                <TopPanel
                    icomoon="icon-costs"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={"Gérer les demandes"}
                    subtitle={"Gestion des demandes (création / modification / suppression)"}
                    handlerAdd={() => this.handleToggleDrawer('openForm')}
                    textAdd={"Créer une demande"}
                    handlerImport={() => this.handleToggleDrawer('')} // set a custom form when data in back-end will be available
                    textImport={"Exporter les demandes"}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    windowWidth={this.props.windowWidth}
                    hasBorder={true}
                />
                <Grid container direction="row" spacing={2} style={{ paddingTop: 16, paddingBottom: 16 }}>
                    {
                        this.state.listDemands.length > 0 ? (

                            this.state.listDemands.map((demand, index) => (
                                <GridCustom item lg={this.state.openForm ? 4 : 2} md={this.state.openForm ? 6 : 3} xs={this.state.openForm ? 6 : 4} key={`listDemands${index}`}>
                                    <CardCustom style={{ width: '100%' }} cardContentStyle={{ padding: 0 }}>
                                        <div style={{ height: 150, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8 }}>
                                            <img style={{ maxWidth: '90%', maxHeight: '90%' }} src={demand.node.imageUrl} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: 'auto' }}>
                                            <Typography variant="h2">{demand.node.demandData.edges[0]?.node.value}</Typography>
                                            <Typography variant="body1">{demand.node.code}</Typography>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <Button types="Delete" text="Supprimer" onClick={() => { this.handleToggleDialog('openDialog'); this.setState({ idToDelete: demand.node.id }) }}><DeleteSharpIcon /></Button>
                                            <Button types="Edit" color={colors.green.regular} colorhover={colors.green.darker} bgcolor="transparent" bgcolorhover="transparent" onClick={() => { this.setState({ name: demand.node.demandData.edges[0].node.value, code: demand.node.code, image: demand.node.imageUrl, editForm: true, editId: demand.node.id }); this.handleToggleDrawer('openForm') }}><EditIcon /></Button>
                                        </div>
                                    </CardCustom>
                                </GridCustom>
                            ))
                        ) : !this.state.ready ? <PageLoader /> : 'Aucune demande créée'
                    }
                </Grid>

                <LayoutBuilder
                    isSublayout={false}
                    opened={this.state.openForm}
                    validateButton={true}
                    handlerMutation={this.handlerMutation}
                    icomoon="icon-costs"
                    dataLayout={this.state.editForm ? editDemandsForm(this.handleMediaPicker) : addDemandsForm(this.handleMediaPicker)}
                    allState={this.state}
                    stateCallback={this.handleInputChange}
                    errorCallback={this.handleFormError}
                    handleButtonGroupChange={this.handleButtonGroupChange}
                    forClose={() => this.handleToggleDrawer('openForm', true)}
                />

                <DialogModal
                    icon={true}
                    type='delete'
                    open={this.state.openDialog}
                    title={`Êtes-vous sûr de vouloir supprimer la demande ?`}
                    secondaryAction={() => this.handleToggleDialog('openDialog')}
                    primaryAction={this.deleteMutation}
                    windowWidth={this.props.windowWidth}
                >
                    Si vous supprimez cette demande, plus aucun restaurant ne pourra l'utiliser. Si vous ne souhaitez pas la supprimer, annulez la suppression en cliquant sur annuler.
                </DialogModal>
            </div>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };


}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    };
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListDemands)));