import React from "react";
import Div from "../../../../shared/components/CustomDiv";

export default ({
  data,
  preview,
  component,
  block,
  inputCallback,
  className,
}) => {
  var gallery = data.blocks[block].config;
  return (
    <div className="gallery">
      <div className="list_gallery" style={{gap: 16}}>
        {gallery.map((galerie, index) => {
          return (
            <div
              className="image-gallery"
              onClick={() => inputCallback(component, block)}
              id={`Image-${index}`}
              key={`Image-${index}`}
              style={{
                width: `calc(100% / ${gallery.length > 4 ? 4 : gallery.length} - 16px)`,
                maxWidth: `${galerie.blocks.image?.inputs?.maxWidth?.value}px`,
              }}
            >
              <a href={`${galerie.blocks.text.inputs.value.value}`}>
                <img
                  className="img_center"
                  src={
                    typeof galerie.blocks.image.inputs.value.value ===
                      "string" && galerie.blocks.image.inputs.value.value !== ""
                      ? gallery.blocks.image.inputs.value.value
                      : galerie.blocks.image.inputs.value?.value?.filePath
                      ? ` ${process.env.REACT_APP_MEDIAS}/${galerie.blocks.image.inputs.value.value.filePath}`
                      : `http://via.placeholder.com/700x500/FFFFFF/53839D?text=${
                          index + 1
                        }`
                  }
                  style={{
                    width: `${galerie.blocks.image?.inputs?.maxWidth?.value}px`,
                  }}
                  alt={`image-gallery ${index + 1}`}
                />
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
