import { useEffect, useState } from "react"
import colors from "../../../../config/theme/colors"
import TopPanel from "../../../layouts/TopPanel/TopPanel"
import { useToggle } from "@uidotdev/usehooks"
import AccordionCustom from "../../../layouts/Accordion/AccordionCustom"
import { Box, Grid } from "@mui/material"
import OurTypography from "../../../ui/typography/Typography"
import OurButton from "../../../ui/button/Button"
import Status from "../../../ui/status/Status"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import { connect } from "react-redux"
import LayoutFormBuilder from "../../../ui/form/LayoutFormBuilder"
import formAttributsRights from "./config/formAttributsRights.config"
import { ALERT_ERROR, ALERT_SUCCESS } from "../../../../js/constants/alert-types"
import { eventService } from "../../../../js/services/event.service"
import TreeView from "../../../ui/tree-view/TreeView"
import formAttributEdit from "./config/formAttributEdit.config"
import formAttributAdd from "./config/formAttributAdd.config"
import { ADD_TRANSLATION, ADD_TRANSLATION_DATA, DELETE_TRANSLATION_DATA, UPDATE_TRANSLATION_DATA } from "../../../../queries/translations"
import { ADD_ATTRIBUTE, ADD_ATTRIBUTE_DICTIONNARY, ADD_ATTRIBUTE_OPTION, ADD_ATTRIBUTE_OPTION_DICTIONNARY, DELETE_ATTRIBUTE_DICTIONNARY, DELETE_ATTRIBUTE_GROUPE, DELETE_ATTRIBUTE_OPTION, DELETE_ATTRIBUTE_OPTION_DICTIONNARY, GET_ATTRIBUTE, GET_ATTRIBUTES_BY_TYPE, UPDATE_ATTRIBUTE, UPDATE_ATTRIBUTE_DICTIONNARY, UPDATE_ATTRIBUTE_OPTION_DICTIONNARY, UPDATE_ATTRIBUTE_POSITION, UPDATE_ATTRIBUTE_STATUS } from "../../../../queries/attributes"
import { SET_ATTRIBUTES, SET_ATTRIBUTE_GROUPS, SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types"
import { withApollo } from "@apollo/client/react/hoc"
import DialogModal from "../../../ui/dialog/DialogModal"
import { withTranslation } from "react-i18next"
import { GET_PRODUCTS } from "../../../../queries/products"
import styled from "styled-components"

const AppWrapper = styled(Grid)`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  /* height: calc(100% - 64px); */
`

const AttributesDetails = (props) => {
  const location = useLocation()

  const currentLang = props.locales[0].node.code
  const groupId = location.state?.id ?? location.pathname.split('/').at(-1)
  const allGroups = JSON.parse(localStorage.getItem('ATTRIBUTE_GROUPS')).filter(i => {
    // * If you don't want to show system attributes, uncomment the following lines
    // if (props.typeAttributs === 'content'){
    //   return !i.node.isSystem && i.node.isForContent
    // } else {
    //   return !i.node.isForContent && !i.node.isSystem
    // }

    return i.node.isForContent === (props.typeAttributs === 'content')
  })

  const [isDialogOpened, toggleDialog] = useToggle(false)
  const [isAttributeDrawerOpened, toggleAttributeDrawer] = useToggle(false)
  const [isEditRightsDrawerOpened, toggleEditRightsDrawer] = useToggle(false)
  const [formType, setFormType] = useState(null)
  const [group, setGroup] = useState(JSON.parse(localStorage.getItem('ATTRIBUTE_GROUPS'))?.find(item => item.node.id === `/api/attribute-groups/${groupId}`))
  const [treeData, setTreeData] = useState(null)
  const [unassignedAttributes, setUnassignedAttributes] = useState(null)
  const [state, setState] = useState({
    ready: false,
    errors: {},
    currentLang: currentLang,
    selectedGroups: [],
  })

  useEffect(() => {
    const newObj = {
      ...group,
      selectedAttributes: group?.node?.attributes?.edges?.map((attr) => ({
        id: attr.node.id,
        name: attr.node.identifier ?? '-',
        groupId: attr.node.id,
      })),
    }

    setState(prev => ({
      ...prev,
      groupWithAttributes: newObj
    }))
  }, [group])

  const attributeGroupInfos = {
    title: group.node.translation.translationDatas.edges.find(i => i.node.locale.code === currentLang).node.value,
    status: group.node.isActive,
    sync: group.node.isActive,
    products: group.node.attributes.edges.find(i => i.node.identifier === ''), // TODO
    categories: group.node.attributes.edges.find(i => i.node.identifier === ''), // TODO
    assets: group.node.attributes.edges.find(i => i.node.identifier === ''), // TODO
  }

  /* --------------------------------- Drawer --------------------------------- */
  const resetState = () => {
    setState({
      ready: true,
      errors: {},
      currentLang: currentLang,
      selectedGroups: [],
    })
    toggleAttributeDrawer()
  }

  const deleteOption = (id) => {
    return new Promise(async (resolve, reject) => {
      await props.client.mutate({
        mutation: DELETE_ATTRIBUTE_OPTION,
        variables: { 'id': id }
      });

      resolve();
    });
  };

  const createOption = (attribute, option) => {
    return new Promise(async (resolve, reject) => {
      const identifier = `${option.code}`;

      const ADD_TRANSLATION_RESULT = await props.client.mutate({
        mutation: ADD_TRANSLATION,
        variables: { 'translationKey': `spread.attributeOption.${attribute.identifier}.${identifier}` }
      });

      await props.client.mutate({
        mutation: ADD_ATTRIBUTE_OPTION,
        variables: {
          'identifier': identifier,
          'attribute': attribute.id,
          'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
        }
      });

      for (let locale of props.locales) {
        let value = option.values[locale.node.code];

        if (value) {
          await props.client.mutate({
            mutation: ADD_TRANSLATION_DATA,
            variables: {
              'value': value,
              'locale': locale.node.id,
              'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
            }
          });
        }
      }

      resolve();
    });
  };

  const updateOption = (option) => {
    return new Promise(async (resolve, reject) => {
      for (let locale of props.locales) {
        let formValue = option.values[locale.node.code];
        let currentTranslation = state.clickedAttribute.node.attributeOptions.edges.find(e => e.node.id === option.id);
        let currentTranslationData = currentTranslation.node.translation.translationDatas.edges.find(e => e.node.locale.id === locale.node.id);
        if (formValue) {
          if (currentTranslation) {
            // UPDATE STEP

            await props.client.mutate({
              mutation: UPDATE_TRANSLATION_DATA,
              variables: {
                'id': currentTranslationData.node.id,
                'value': formValue
              }
            });
          } else {
            // CREATE STEP

            await props.client.mutate({
              mutation: ADD_TRANSLATION_DATA,
              variables: {
                'value': formValue,
                'locale': locale.node.id,
                'translation': group.node.translation.id
              }
            });
          }
        } else if (currentTranslation && currentTranslationData) {
          // DELETE STEP

          await props.client.mutate({
            mutation: DELETE_TRANSLATION_DATA,
            variables: { 'id': currentTranslationData.node.id }
          });
        }
      }

      resolve();
    });
  };

  const saveOptions = (attribute) => {
    return new Promise(async (resolve, reject) => {
      for (let option of state.values) {
        if (option.deleted)
          await deleteOption(option.id);
        else if (option.new)
          await createOption(attribute, option);
        else if (option.changed)
          await updateOption(option);
      }

      resolve();
    });
  };

  const deleteEntry = (id, isSelect) => {
    let mutationName = null
    if (isSelect) {
      mutationName = DELETE_ATTRIBUTE_OPTION_DICTIONNARY
    } else {
      mutationName = DELETE_ATTRIBUTE_DICTIONNARY
    }
    return new Promise(async (resolve, reject) => {
      await props.client.mutate({
        mutation: mutationName,
        variables: { 'id': id }
      });

      resolve();
    });
  };

  const createEntry = (attribute, entry, isSelect) => {
    return new Promise(async (resolve, reject) => {
      let input = entry.code
      /* if (entry.checkbox){
          input = `|%|${input}|%|`
      } */
      if (isSelect) {
        await props.client.mutate({
          mutation: ADD_ATTRIBUTE_OPTION_DICTIONNARY,
          variables: {
            'input': input[0],
            'attributeOption': entry.values,
            'attribute': attribute.id
          }
        });
      } else {
        await props.client.mutate({
          mutation: ADD_ATTRIBUTE_DICTIONNARY,
          variables: {
            'input': input,
            'output': entry.values,
            'attribute': attribute.id
          }
        });
      }
      resolve();
    });
  };

  const updateEntry = (attribute, entry, isSelect) => {
    let input = entry.code
    return new Promise(async (resolve, reject) => {
      if (isSelect) {
        await props.client.mutate({
          mutation: UPDATE_ATTRIBUTE_OPTION_DICTIONNARY,
          variables: {
            'id': entry.id,
            'attributeOption': entry.values,
            "input": input[0],
            'attribute': attribute.id
          }
        });
      } else {
        await props.client.mutate({
          mutation: UPDATE_ATTRIBUTE_DICTIONNARY,
          variables: {
            'id': entry.id,
            'output': entry.values,
            "input": input,
            'attribute': attribute.id
          }
        });
      }
      resolve();
    });
  };

  const saveDictionnary = (attribute, isSelect) => {
    return new Promise(async (resolve, reject) => {
      for (let entry of state.dictionnaryValues) {
        if (entry.deleted)
          await deleteEntry(entry.id, isSelect);
        else if (entry.new && entry.code && entry.values)
          await createEntry(attribute, entry, isSelect);
        else if (entry.changed && entry.code && entry.values)
          await updateEntry(attribute, entry, isSelect);
      }

      resolve();
    });
  };

  const handleError = (e) => {
    props.snack(ALERT_ERROR, 'Veuillez corriger les erreurs avant de continuer');

    props.stopLoading();

    if (e.graphQLErrors) {
      for (let error of e.graphQLErrors) {
        console.error('ERROR', `${error.message} =>`, error.debugMessage);
      }
    }

    setState(prev => ({
      ...prev,
      openDialog: false
    }));
  };

  const saveAddAttr = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let identifier = `attribute_${state.attributeIdentifier}`;
        const ADD_TRANSLATION_RESULT = await props.client.mutate({
          mutation: ADD_TRANSLATION,
          // variables: { 'translationKey': `spread.attribute.${identifier}` }
          variables: { 'translationKey': `${identifier}` }
        });

        for (let locale of props.locales) {
          if (state[locale.node.code]?.['attributeName']) {
            await props.client.mutate({
              mutation: ADD_TRANSLATION_DATA,
              variables: {
                value: state[locale.node.code]['attributeName'],
                locale: locale.node.id,
                translation: ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
              }
            });
          }
        }

        const ADD_ATTRIBUT_RESULT = await props.client.mutate({
          mutation: ADD_ATTRIBUTE,
          variables: {
            identifier: state.attributeIdentifier,
            attributeGroup: state.selectedGroups.map(e => e.node.id),
            eavType: props.attributes.eavTypes.find(e => e.node.code === props.typeAttributs).node.id,
            attributeType: state.attributeType,
            isRequired: state.isRequired,
            isSearchable: state.isSearchable,
            useInCard: state.useInCard,
            isSystem: false,
            status: true,
            translation: ADD_TRANSLATION_RESULT.data.createTranslation.translation.id,
            internalFilter: state.internalFilter || false,
          }
        });

        if (state.attributeType === state.choiceType?.node.id)
          await saveOptions(ADD_ATTRIBUT_RESULT.data.createAttribute.attribute);

        // ADD DICTIONNARY
        if (state.attributeType === state.choiceType?.node.id) {
          await saveDictionnary(ADD_ATTRIBUT_RESULT.data.createAttribute.attribute, true)
        } else {
          await saveDictionnary(ADD_ATTRIBUT_RESULT.data.createAttribute.attribute);
        }

        // ADD ATTRIBUTE TO STORE AND LOCAL STORAGE

        const GET_ATTRIBUT_RESULT = await props.client.mutate({
          mutation: GET_ATTRIBUTE,
          variables: {
            'id': ADD_ATTRIBUT_RESULT.data.createAttribute.attribute.id
          }
        });

        props.client.mutate({
          mutation: UPDATE_ATTRIBUTE_POSITION,
          variables: {
            'id': ADD_ATTRIBUT_RESULT.data.createAttribute.attribute.id,
            'position': parseInt(ADD_ATTRIBUT_RESULT.data.createAttribute.attribute.id.replace("/api/attributes/", '')),
          }
        })

        resolve(GET_ATTRIBUT_RESULT.data.attribute);
      } catch (e) {
        handleError(e);
        reject();
      }
    });
  };

  const addAttribut = () => {
    resetState();

    for (let locale of props.locales) {
      setState(prev => ({
        ...prev,
        [locale.node.code]: {}
      }));
    }

    setState(prev => ({
      ...prev,
      ready: true,
      choiceType: props.attributeTypes.find(e => e.node.input === 'select') ?? null,
      attributeType: props.attributeTypes[0].node.id ?? null,
      values: [],
      dictionnaryValues: [],

      attributUnity: 'none',
      isRequired: true,
      isSearchable: true,
      internalFilter: true,
      useInCard: false,
      selectedGroups: [{
        ...allGroups.find(e => e.node.id === `/api/attribute-groups/${groupId}`),
        checked: true,
        children: [],
        expanded: true,
        label: allGroups.find(e => e.node?.id === `/api/attribute-groups/${groupId}`)?.node?.translation.translationDatas.edges.find(i => i.node?.locale.code === currentLang).node?.value,
      }]
    }));

    toggleAttributeDrawer();
  };

  const saveEditAttr = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const UPDATE_ATTRIBUTE_RESULT = await props.client.mutate({
          mutation: UPDATE_ATTRIBUTE,
          variables: {
            id: state.clickedAttribute.node.id,
            attributeGroup: state.selectedGroups?.map(e => e.node.id),
            attributeType: state.attributeType,
            isRequired: state.isRequired,
            isSearchable: state.isSearchable,
            internalFilter: state.internalFilter || false,
            useInCard: state.useInCard || false,
          }
        });

        for (let locale of props.locales) {
          let formValue = state[locale.node.code]?.['attributeName'];
          let currentTranslation = state.clickedAttribute.node.translation.translationDatas.edges.find(e => e.node.locale.id === locale.node.id);
          if (formValue) {
            if (currentTranslation) {
              // UPDATE STEP

              await props.client.mutate({
                mutation: UPDATE_TRANSLATION_DATA,
                variables: {
                  'id': currentTranslation.node.id,
                  'value': formValue
                }
              });
            } else {
              // CREATE STEP

              await props.client.mutate({
                mutation: ADD_TRANSLATION_DATA,
                variables: {
                  'value': formValue,
                  'locale': locale.node.id,
                  'translation': state.clickedAttribute.node.translation.id
                }
              });
            }
          } else if (currentTranslation) {
            // DELETE STEP

            await props.client.mutate({
              mutation: DELETE_TRANSLATION_DATA,
              variables: { 'id': currentTranslation.node.id }
            });
          }
        }

        if (state.attributeType === state.choiceType.node.id)
          await saveOptions(UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute);


        // UPDATE DICTIONNARY    
        if (state.attributeType === state.choiceType.node.id) {
          await saveDictionnary(UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute, true)
        } else {
          await saveDictionnary(UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute);
        }

        // UPDATE ATTRIBUTE IN STORE AND LOCAL STORAGE

        const GET_ATTRIBUT_RESULT = await props.client.mutate({
          mutation: GET_ATTRIBUTE,
          variables: {
            'id': UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute.id
          }
        });

        resolve(GET_ATTRIBUT_RESULT.data.attribute);
      } catch (e) {
        console.error('error', e)
        handleError(e);
        reject();
      }
    });
  };

  const updateRedux = async (item, type) => {

    const allAttributes = props.attributes;
    const attributesOfType = props.attributes[props.typeAttributs];
    const allGroups = props.attributeGroups;

    const copyOfAllAttributes = structuredClone(allAttributes)
    const copyOfAttributesOfType = structuredClone(attributesOfType)
    const copyOfAllAttributesGroups = [...allGroups]

    const groupsIdOfAttribute = item.node.attributeGroup.edges.map(e => e.node.id);
    const groupsOfAttribute = copyOfAllAttributesGroups.filter(e => groupsIdOfAttribute.includes(e.node.id));

    switch (type) {
      case 'create':
        /* -------------------------------- ATTRIBUTE ------------------------------- */
        // Add the new attribute to the list of attributes of specific type
        copyOfAttributesOfType.attributes.edges.push(item)

        // Add that updated list to the general one, which will be stored in redux and local storage
        copyOfAllAttributes[props.typeAttributs] = copyOfAttributesOfType

        /* ---------------------------------- GROUP --------------------------------- */
        groupsOfAttribute.forEach(e => e.node.attributes.edges.push(item)) // Add to all groups of this attribute

        break
      case 'update':
        /* -------------------------------- ATTRIBUTE ------------------------------- */
        // Update the attributes of specific type
        const attributeIdToUpdate = copyOfAttributesOfType.attributes.edges.findIndex(e => e.node.id === item.node.id);

        if(attributeIdToUpdate !== -1) {
          copyOfAttributesOfType.attributes.edges[attributeIdToUpdate] = item

          // Add that updated list to the general one, which will be stored in redux and local storage
          copyOfAllAttributes[props.typeAttributs] = copyOfAttributesOfType
        }

        /* ---------------------------------- GROUP --------------------------------- */
        // Remove attribute from all groups
        copyOfAllAttributesGroups.forEach(e => {
          const attributeIdToRemove = e.node.attributes.edges.findIndex(e => e.node.id === item.node.id);
          if(attributeIdToRemove !== -1) {
            e.node.attributes.edges.splice(attributeIdToRemove, 1)
          }
        })

        // Add attribute to the new selected groups
        groupsOfAttribute.forEach(e => e.node.attributes.edges.push(item))
        break
      default:
        break
    }

    // Redux
    props.setAttributes(copyOfAllAttributes);
    props.setAttributeGroups(copyOfAllAttributesGroups);

    // Local storage
    try {
      localStorage.setItem('ATTRIBUTES', JSON.stringify(copyOfAllAttributes));
      localStorage.setItem('ATTRIBUTE_GROUPS', JSON.stringify(copyOfAllAttributesGroups));
    } catch (error) {
      console.log("Not saved on localstorage because exceeded localstorage limit ")
    }
  }

  const handleSuccess = async (item, type) => {
    await updateRedux(item, type);
    await prepareTree();

    props.snack(ALERT_SUCCESS, formType === 'editAttribut'
      ? 'Attribut modifié'
      : formType === 'addAttribut'
      && 'Attribut ajouté')

    toggleAttributeDrawer();
    toggleAttributeDrawer();
    resetState();
    props.stopLoading();
  };

  const handlerMutation = () => {
    if (hasErrors()) {
      props.snack(ALERT_ERROR, 'Veuillez corriger les erreurs avant de continuer');
      return eventService.fire();
    }

    props.startLoading();

    switch (formType) {
      case 'addAttribut':
        saveAddAttr().then((item) => handleSuccess({
          node: {
            ...item,
          }
        }, 'create')).catch((err) => { console.error(err) }); //* OK
        break;
      case 'editAttribut':
        saveEditAttr().then((item) => handleSuccess({
          node: {
            ...item,
          }
        }, 'update')).catch((err) => { console.error(err) }); //! FAIL
        break;
      default: return false;
    }
  };

  const handleInputChange = (stateName, evt, custom, translated) => {
    const value = evt?.target?.value ?? evt;

    if (translated) {
      setState(prev => ({
        ...prev,
        [state.currentLang]: {
          ...prev[state.currentLang],
          [stateName]: value
        }
      }));
    } else {
      setState(prev => ({
        ...prev,
        [stateName]: value
      }));
    }

  };

  const getIdentifier = identifier => identifier.indexOf('_') > -1
    ? identifier.split('_')[1]
    : identifier;

  const convertToNode = (data, root, parent, isAttribute) => {
    let getTraduction = data.node.translation.translationDatas.edges.find(
      lang => lang.node.locale.code === state.currentLang
    );

    if (root && data.node.identifier === 'default')
      data.noAction = true;

    data.locales = [];

    for (let { node } of data.node.translation.translationDatas.edges) {
      data.locales.push({
        value: node.value,
        id: node.locale.id,
        code: node.locale.code,
        translationDataId: node.id
      });
    }

    if (!root) {
      data.title = getTraduction?.node?.value ?? data?.node?.translation.translationDatas.edges[0]?.node.value ?? getIdentifier(data.node.identifier);
      data.parent = parent.node.id;
      data.isDirectory = false;
      data.expanded = true;
      data.isItem = true;
      data.canDrag = false;
    } else {
      data.title = getTraduction?.node?.value ?? data?.node?.translation.translationDatas.edges[0]?.node.value ?? getIdentifier(data.node.identifier);
      data.isDirectory = !isAttribute;
      // data.isDirectory = true;
      data.expanded = true;
      data.isItem = isAttribute;
      data.canDrag = false;
    }
  }

  const handleLang = (event) => {
    setState(prev => ({ ...prev, currentLang: event.target.value }));

    for (let attr of treeData) {
      convertToNode(attr, true, null, true);
    }

    // for (let unassignedAttribute of state.unassignedAttributes)
    //   convertToNode(unassignedAttribute, true, null, true);
  };

  const handleFormError = (stateName, error) => {
    let errors = state.errors;

    errors[stateName] = error;

    setState(prev => ({ ...prev, errors: errors }));
  };

  const hasErrors = () => {
    if (state.errors) {
      for (let error in state.errors) {
        if (state.errors[error])
          return true;
      }
    }

    return false;
  };

  /* -------------------------------- TreeData -------------------------------- */
  const prepareTree = () => {
    const tree = props.attributeGroups.find(group => group.node.id === `/api/attribute-groups/${groupId}`).node.attributes.edges.map((attr) => ({
      title: attr.node.translation.translationDatas.edges.find(i => i.node.locale.code === currentLang).node.value,
      canDrag: false,
      expanded: false,
      isDirectory: false,
      isItem: true,
      locales: props.locales,
      options: [],
      ...attr,
    }))

    const unassignedAttributes = props.attributes[props.typeAttributs].attributes.edges.filter(attr => attr.node.attributeGroup.edges.length === 0).map(attr => ({
      title: attr.node.translation.translationDatas.edges.find(i => i.node.locale.code === currentLang).node.value,
      canDrag: false,
      expanded: false,
      isDirectory: false,
      isItem: true,
      locales: props.locales,
      options: [],
      ...attr,
    }))

    setTreeData(tree)
    setUnassignedAttributes(unassignedAttributes)
  };

  const getMainDatas = () => {
    // TODO: The back might prepare the necessary datas so we can get the list of products, etc from the attribute group object
    // * In case we call an individual call for products, categories and assets, it will be slow and not efficient (there are many products, etc)
  }

  useEffect(() => {
    prepareTree()
    getMainDatas()

    setState(prev => ({
      ...prev,
      ready: true,
    }))
  }, [])

  useEffect(() => {
    if(formType === 'addAttribut'){
      setState(prev => ({
        ...prev,
        attributeIdentifier: prev[currentLang]?.attributeName
      }))
    }
  }, [state[currentLang]?.attributeName])

  const handleButtonGroupChange = (stateName, value) => {
    setState(prev => ({
      ...prev,
      [stateName]: value
    }));
  };

  const editAttribute = (i) => {
    setState(prev => ({
      ...prev,
      clickedAttribute: i,
      attributeIdentifier: i.node.identifier,
      attributeType: i.node.attributeType.id,

      // TODO: Check what type of values we have to put here for the input Rows (in older versions it gets an error)
      values: [],
      dictionnaryValues: [],

      choiceType: props.attributeTypes.find(e => e.node.input === 'select') ?? null,
      attributUnity: 'none',
      isRequired: i.node.isRequired,
      isSearchable: i.node.isSearchable,
      internalFilter: i.node.internalFilter,
      useInCard: i.node.useInCard,
      selectedGroups: i.node.attributeGroup.edges,
      [currentLang]: {
        ...prev[currentLang],
        attributeName: i.node.translation.translationDatas.edges.find(i => i.node.locale.code === currentLang).node.value,
      }
    }))
    setFormType('editAttribut')
    toggleAttributeDrawer()
  }

  useEffect(() => {
    prepareTree()
    setGroup(props.attributeGroups.find(group => group.node.id === `/api/attribute-groups/${groupId}`))
  }, [props.attributes, props.attributeGroups])

  const updateAttributesStatus = async (attributeId) => {
    const copyOfReduxAttributes = structuredClone(props.attributes)
    const attributeToUpdate = copyOfReduxAttributes.product.attributes.edges.find(attr => attr.node.id === attributeId)
    attributeToUpdate.node.status = !attributeToUpdate.node.status
    props.setAttributes(copyOfReduxAttributes);
    localStorage.setItem('ATTRIBUTES', JSON.stringify(copyOfReduxAttributes));
  }

  const disableAttributeMutation = (attributeId) => {
    const attribute = unassignedAttributes.find(i => i.node.id === attributeId)
    let query = null;
    let variables = null;

    props.startLoading();

    props.client.mutate({
      mutation: UPDATE_ATTRIBUTE_STATUS,
      variables: { id: attributeId, status: !attribute.node.status }
    }).then(async (result) => {
      await updateAttributesStatus(attributeId)

      toggleDialog();
      props.stopLoading();
      props.snack(
        ALERT_SUCCESS,
        attribute.node.status ? props.t("attributes.products.contactAdmin") : props.t("attributes.products.attributeReactived")
      );
    }).catch(e => {
      handleError(e)
    });
  };

  const topPanelConfig = {
    product: {
      title: 'Attributs produits',
      subtitle: 'Gestion de vos attributs produits (création / modification / suppression)',
      textAdd: '+ Ajouter un attributs',
      handlerAdd: () => {
        addAttribut()
        setFormType('addAttribut');
        toggleAttributeDrawer();
      }
    },
    content: {
      title: 'Modèles de contenu',
      subtitle: 'Gestion de vos modèles de contenu (création / modification / suppression)',
      textAdd: '+ Ajouter un modèle',
      handlerAdd: () => {
        addAttribut()
        setFormType('addAttribut');
        toggleAttributeDrawer();
      }
    }
  }

  return (
    <AppWrapper>
      <TopPanel
        colorIcomoon={colors.blue.darker.hue300}
        title={topPanelConfig[props.typeAttributs].title}
        subtitle={topPanelConfig[props.typeAttributs].subtitle}
        handlerAdd={topPanelConfig[props.typeAttributs]?.handlerAdd}
        textAdd={topPanelConfig[props.typeAttributs]?.textAdd}
        openForm={isAttributeDrawerOpened}
        buttonAvailable={state.ready && !isAttributeDrawerOpened}
        windowWidth={props.windowWidth}
        hasBorder={true}
        locales={props.locales}
      />

      <Box style={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: '100%',
      }}>
        <AccordionCustom title={'Information lié'} defaultExpanded custompadding={'40px !important'}>
          <OurTypography fontsize={'25px'} fontweighttext={'bold'} style={{ marginBottom: 14 }}>{attributeGroupInfos.title}</OurTypography>

          <Grid container justifyContent={'space-between'} alignItems={'end'}>
            <Grid container columnGap={14} style={{ width: 'auto' }}>
              <Grid item>
                <Grid container style={{ marginBottom: 8 }} alignItems={'center'}>
                  <Grid item style={{ minWidth: 200 }}><OurTypography>Statut :</OurTypography></Grid>
                  <Grid item><Status type="isActive" value={attributeGroupInfos.status} size={10} /></Grid>
                </Grid>
                <Grid container style={{ marginBottom: 8 }} alignItems={'center'}>
                  <Grid item style={{ minWidth: 200 }}><OurTypography>Synchronisation :</OurTypography></Grid>
                  <Grid item><Status type="isSynchro" value={attributeGroupInfos.status} /></Grid>
                </Grid>
                <Grid container alignItems={'center'}>
                  <Grid item style={{ minWidth: 200 }}><OurTypography>Accès directe :</OurTypography></Grid>
                  <Grid item><OurTypography>Accéder aux synchronisations</OurTypography></Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container style={{ marginBottom: 8 }} alignItems={'center'}>
                  <Grid item style={{ minWidth: 200 }}><OurTypography>Total de produit :</OurTypography></Grid>
                  <Grid item><OurTypography>-</OurTypography></Grid>
                </Grid>
                <Grid container style={{ marginBottom: 8 }} alignItems={'center'}>
                  <Grid item style={{ minWidth: 200 }}><OurTypography>Total de catégorie :</OurTypography></Grid>
                  <Grid item><OurTypography>-</OurTypography></Grid>
                </Grid>
                <Grid container alignItems={'center'}>
                  <Grid item style={{ minWidth: 200 }}><OurTypography>Assignation assets :</OurTypography></Grid>
                  <Grid item><OurTypography>-</OurTypography></Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* <OurButton types={'edit'} importance={'secondary'} hasIcon={false} style={{
              minWidth: 235,
            }} onClick={() => {
              // * We don't have this functinality for the moment
              // toggleEditRightsDrawer()
            }}>Gérer les droits</OurButton> */}
          </Grid>
        </AccordionCustom>


        <Grid container columnSpacing={2} style={{ marginTop: 16 }}>
          <Grid item xs={6} style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <TreeView
              typeOfTree={'attribut'}
              dataTree={treeData}
              addSubcategory={() => { }}
              editCat={() => { }}
              noExpand
              title={'Liste d’attributs'}
              editItem={(i) => editAttribute(i)}
              expand={() => { }}
              onChange={treeData => setTreeData(treeData)}
              content={true}
              canModify={true}
              overflowed={true}
            />
          </Grid>
          <Grid item xs={6} style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <TreeView
              typeOfTree={'attribut'}
              dataTree={unassignedAttributes}
              addSubcategory={() => { }}
              editCat={() => { }}
              disableCat={(id) => {
                setState(prev => ({
                  ...prev,
                  clickedAttribute: props.attributes.product.attributes.edges.find(attr => attr.node.id === id),
                }))
                toggleDialog()
              }}
              noExpand
              title={'Attributs non assignés'}
              editItem={(i) => editAttribute(i)}
              expand={() => { }}
              onChange={treeData => setUnassignedAttributes(treeData)}
              content={true}
              canModify={true}
              canDisable={true}
              overflowed={true}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Edit rights */}
      <LayoutFormBuilder
        isSublayout={false}
        styleInnerContainer={state.styleInnerContainer}
        backgroundColor={state.backgroundDrawer}
        handleCancel={resetState}
        opened={isEditRightsDrawerOpened}
        handlerMutation={() => { handlerMutation() }}
        dataLayout={formAttributsRights(state.groupWithAttributes, currentLang)}
        allState={state}
        stateCallback={handleInputChange}
        errorCallback={handleFormError}
        forClose={toggleAttributeDrawer}
        // handlerSetup={handlerSetup}
        validateButton={true}
        stepperButtonAction={[
          () => {
            if (hasErrors()) {
              props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
              setState(prev => ({ ...prev, seeErrors: true }));
              eventService.fire();
              return false;
            }
            return true;
          }
        ]}
      />

      <DialogModal
        icon
        type={unassignedAttributes?.find(i => i.node.id === state.clickedAttribute?.node.id)?.node.status ? 'delete' : 'add'}
        button={{
          text: unassignedAttributes?.find(i => i.node.id === state.clickedAttribute?.node.id)?.node.status ? 'Désactiver' : 'Réactiver',
        }}
        open={isDialogOpened}
        title={
          unassignedAttributes?.find(i => i.node.id === state.clickedAttribute?.node.id)?.node.status
            ? 'Êtes-vous sûr de vouloir désactiver cet attribut ?'
            : 'Êtes-vous sûr de vouloir réactiver cet attribut ?'
        }
        primaryAction={() => disableAttributeMutation(state.clickedAttribute?.node.id)}
        secondaryAction={toggleDialog}
        windowWidth={props.windowWidth}
      >
        <OurTypography text={
          unassignedAttributes?.find(i => i.node.id === state.clickedAttribute?.node.id)?.node.status
            ? 'Si vous désactivez cet attribut celui-ci ne sera plus accessible. Si vous ne souhaitez pas le désactiver, annulez la désactivation en cliquant sur annuler.'
            : 'Si vous réactivez cet attribut celui-ci sera à nouveau accessible. Si vous ne souhaitez pas le réactiver, annulez la réactivation en cliquant sur annuler.'
        } colortext={colors.grey.lighter.hue600} style={{ fontSize: '16px' }} />
      </DialogModal>

      {/* Edit attribut */}
      {isAttributeDrawerOpened && (
        <LayoutFormBuilder
          isSublayout={false}
          readyForm={true}
          opened={isAttributeDrawerOpened}
          forClose={resetState}
          handlerMutation={handlerMutation}
          dataLayout={
            formType === 'editAttribut'
              ? formAttributEdit(
                allGroups, // * I filtered the group attributs to show for unassigned attributs. In case that you want to show all groups just for unassigned attributs, create a new var with all groups and pass it to the function
                state.clickedAttribute.node.attributeType.id,
                currentLang,
                props.attributeTypes,
                props.locales,
                state.selectedGroups,
                state.clickedAttribute.node.isSystem,
                !state.clickedAttribute.node.status,
                false
              )
              : formType === 'addAttribut'
              && formAttributAdd(
                allGroups,
                state.attributeType,
                currentLang,
                props.attributeTypes,
                props.locales,
                state.selectedGroups,
                false
              )
          }
          allState={state}
          stateCallback={handleInputChange}
          errorCallback={handleFormError}
          checkError={() => { }}
          validateButton={true}
          currentLang={state.currentLang}
          handleLang={handleLang}
          handleButtonGroupChange={handleButtonGroupChange}
        />
      )}
    </AppWrapper>
  )
}

const mapStateToProps = state => {
  return {
    locales: state.locales,
    attributeTypes: state.attributeTypes,
    attributes: state.attributes,
    attributeGroups: state.attributeGroups,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
    setAttributes: (attributes) => dispatch({ type: SET_ATTRIBUTES, payload: { attributes } }),
    setAttributeGroups: (attributeGroups) => dispatch({ type: SET_ATTRIBUTE_GROUPS, payload: { attributeGroups } }),
  }
}

export default withTranslation()(withApollo(connect(mapStateToProps, mapDispatchToProps)(AttributesDetails)));