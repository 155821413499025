import React from 'react';
import { Box, Grid, Typography, Tooltip, Divider } from '@mui/material';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import NoImage from '../../../../assets/images/not-found.png';

const TypoWeight = styled(Typography)`
    font-weight:bold;
    color:${colors.black.regular}
`

const GridCustom = styled(Grid)`
    margin-bottom:10px;
`

export default function CardInfoManagement(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.black.regular,
        },
        tooltip: {
            backgroundColor: colors.black.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }
    const classes = useStylesBootstrap();

    return(
        // <ContainerCard>
        //     <Grid container style={{position: 'relative', height: '100%'}}>
        //         <Box style={{width: "100%"}}>
        //             <ContainerImage height={160} backgroundimage={props.data.node.media ? `${process.env.REACT_APP_MEDIAS}/${props.data.node.media?.filePath}` : NoImage} />
        //             {/* <Grid item xs={4}>
        //                 <img src={props.data.node.media ? `${process.env.REACT_APP_MEDIAS}/${props.data.node.media?.filePath}` : NoImage} style={{width: "100%"}} alt={`image-ShopTheLook-${props.data.node.libelle}`}/>
        //             </Grid> */}
        //             <LineColor height={8}/>
        //             <Grid item xs={12} style={{padding: 16}}>
        //                 <Grid container direction="row" style={{marginBottom: 8}} justifyContent="space-between" alignItems="center">
        //                     <Grid item>
        //                         <Typography variant="h3" style={{marginBottom: 8}}>{props.data.node.libelle}</Typography>
        //                     </Grid>
        //                     <Grid item>
        //                         <StatusInfo status={props.data.node.status} />
        //                     </Grid>
        //                 </Grid>
        //                 <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
        //                     <Typography variant="body2" style={{marginRight: 8}}>- Couleur libelle: </Typography>
        //                     <BootstrapTooltip title={props.data.node.libelleColor} className={clsx(classes.tooltip)}>
        //                         <Color color={props.data.node.libelleColor} />
        //                     </BootstrapTooltip>
        //                 </Grid>
        //                 {
        //                     props.data.node.linkButton ? 
        //                         <>
        //                             <Typography variant="h4">Bouton: </Typography>
        //                             <Grid container direction="column">
        //                                 <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
        //                                     <Typography variant="body2" style={{marginRight: 8}}>- Texte: </Typography>
        //                                     <Typography variant="body2">{props.data.node.textButton}</Typography>
        //                                 </Grid>
        //                                 <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
        //                                     <Typography variant="body2" style={{marginRight: 8}}>- Couleur texte: </Typography>
        //                                     <BootstrapTooltip title={props.data.node.textColorButton} className={clsx(classes.tooltip)}>
        //                                         <Color color={props.data.node.textColorButton} />
        //                                     </BootstrapTooltip>
        //                                 </Grid>
        //                                 <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
        //                                     <Typography variant="body2" style={{marginRight: 8}}>- Couleur bouton: </Typography>
        //                                     <BootstrapTooltip title={props.data.node.colorButton} className={clsx(classes.tooltip)}>
        //                                         <Color color={props.data.node.colorButton} />
        //                                     </BootstrapTooltip>
        //                                 </Grid>
        //                                 <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
        //                                     <Typography variant="body2" style={{marginRight: 8}}>- Lien: </Typography>
        //                                     <a href={props.data.node.linkButton} target="__blank">{props.data.node.linkButton}</a>
        //                                 </Grid>
        //                                 <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
        //                                     <a href={props.data.node.linkButton} target="__blank" style={{textDecoration: 'none'}}><ButtonCustom bgcolor={props.data.node.colorButton} color={props.data.node.textColorButton} text={props.data.node.textButton} onClick={() => {}}/></a>
        //                                 </Grid>
        //                             </Grid>
        //                         </>
        //                     : null
        //                 }
        //                 {
        //                     props.data.node.product.edges.length > 0 ?
        //                         <Grid container alignItems="center" style={{marginTop: 8, marginBottom: 4}}>
        //                             <Grid item style={{marginRight: 8}}>
        //                                 <Typography variant="body1">Nombre de produits : </Typography>
        //                             </Grid>
        //                             <Grid item>
        //                                 <Grid container alignItems="center">
        //                                     <IcomoonReact iconSet={iconSet} color={colors.blue.darker.hue300} size={25} icon={"picto-produit"} />
        //                                     <Typography variant="body1" style={{marginLeft: 8, color:colors.blue.darker.hue300, fontWeight: "bold"}}>{props.data.node.product.edges.length}</Typography>
        //                                 </Grid>
        //                             </Grid>
        //                         </Grid>
        //                     : 
        //                         <Grid container alignItems="center">
        //                             <Grid item style={{marginRight: 8}}>
        //                                 <Typography variant="body1" style={{marginTop: 8, marginBottom: 4}}>Catégories : </Typography>
        //                             </Grid>
        //                             <Grid item>
        //                                 <Grid container alignItems="center">
        //                                     <IcomoonReact iconSet={iconSet} color={colors.blue.darker.hue300} size={25} icon={"picto-categorie"} />
        //                                     <Typography variant="body1" style={{marginLeft: 8, color:colors.blue.darker.hue300, fontWeight: "bold"}}>{props.data.node.category.length}</Typography>
        //                                 </Grid>
        //                             </Grid>
        //                         </Grid>
        //                 }
        //                 <Grid container justifyContent="flex-end">
        //                     <Button onClick={() => props.handlerButton(props.data.node)} text={props.textButton}></Button>
        //                 </Grid>
        //             </Grid>
        //         </Box>
        //     </Grid>
        // </ContainerCard>
        <Grid container style={{padding:"16px"}} spacing={2}>
            <Grid item xs={6}>
                <img src={props.data.node.media ? `${process.env.REACT_APP_MEDIAS}/${props.data.node.media?.filePath}` : NoImage} style={{width: "100%",height:"100%",objectFit:"cover"}} alt={`image-ShopTheLook-${props.data.node.libelle}`}/>
            </Grid>
            <Grid item container xs={6}>
                <Grid item xs={12} style={{marginBottom:10}}>                    
                    <Typography 
                        variant="h3" 
                        style={{
                            marginBottom: 8,                            
                            maxWidth:"100%",
                            textOverflow:"ellipsis",
                            overflow:"hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            fontSize:22
                        }}
                    >
                        {props.data.node.libelle}
                    </Typography>
                </Grid>
                <GridCustom container item xs={12}>
                    <Grid item xs={6}>
                        <Typography>Statut de la règle:</Typography>
                    </Grid>
                    <Grid item xs>
                        <StatusInfo status={props.data.node.status} />
                    </Grid>
                </GridCustom>
                <GridCustom container item xs={12}>
                    <Grid item xs={6}>
                        <Typography>Nombre de produits :</Typography>
                    </Grid>
                    <Grid item xs>
                    <TypoWeight>{props.data.node.product.edges.length}</TypoWeight>                    
                    </Grid>
                </GridCustom>
                <GridCustom container item xs={12}>
                    <Grid item xs={6}>
                        <Typography>Couleur du libellé :</Typography>
                    </Grid>
                    <Grid item xs>
                        <TypoWeight>{props.data.node.libelleColor}</TypoWeight>                    
                    </Grid>
                </GridCustom> 
                <Grid container item xs={12} style={{paddingBottom:"10px"}}>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>                    
                </Grid> 
                <GridCustom container item xs={12}>
                    <Grid item xs={6}>
                        <TypoWeight>Bouton</TypoWeight>
                    </Grid>                    
                </GridCustom>       
                <GridCustom container item xs={12}>
                    <Grid item xs={6}>
                        <Typography>Texte :</Typography>
                    </Grid>
                    <Grid item xs>
                        <TypoWeight>{props.data.node.textButton}</TypoWeight>                    
                    </Grid>
                </GridCustom>       
                <GridCustom container item xs={12}>
                    <Grid item xs={6}>
                        <Typography>Couleur du texte :</Typography>
                    </Grid>
                    <Grid item xs>
                        <TypoWeight>{props.data.node.textColorButton}</TypoWeight>                    
                    </Grid>
                </GridCustom>       
                <GridCustom container item xs={12}>
                    <Grid item xs={6}>
                        <Typography>Couleur du bouton :</Typography>
                    </Grid>
                    <Grid item xs>
                        <TypoWeight>{props.data.node.colorButton}</TypoWeight>                    
                    </Grid>
                </GridCustom>          
                <GridCustom container item xs={12}>
                    <Grid item xs={6}>
                        <Typography>Lien :</Typography>
                    </Grid>
                    <Grid item xs>
                        <TypoWeight>{props.data.node.linkButton}</TypoWeight>                    
                    </Grid>
                </GridCustom>    
                <Grid container item xs={12}>
                    <Button 
                        types="Edit"
                        text="Modifier la règle"
                        icon={"edit"} 
                        style={{
                            width:"100%"
                        }}
                        onClick={() => props.handlerButton(props.data.node)}
                    >
                    </Button>
                </Grid>    
            </Grid>
        </Grid>
    );
}