import React from "react";
import { Box, Typography } from "@mui/material";
import colors from "@/config/theme/colors";
import Icon from "@/components/ui/icons/Icon.js";
import styled from "styled-components";
import { useState } from "react";
import PropTypes from "prop-types";

const Text = styled(Typography)`
  color: ${(props) =>
    props.hover ? colors.green.darker : colors.green.regular};
  text-decoration: underline;
`;

const TextAddElement = ({ label, onClick, ...props }) => {
  const [hover, setHover] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        cursor: "pointer",
      }}
      onClick={onClick}
      onMouseEnter={(e) => {
        setHover(true);
      }}
      onMouseLeave={(e) => {
        setHover(false);
      }}
    >
      <Icon
        icon="add"
        color={hover ? colors.green.darker : colors.green.regular}
      />
      <Text $hover>{label}</Text>
    </Box>
  );
};

TextAddElement.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default TextAddElement;
