import * as muiColors from "@mui/material/colors"

const colors = ({
    ...muiColors,
    background:{
        black:{
            primary:"rgb(18, 18, 18)",
            secondary:'#000'
        },
        white:{
            primary:"#fafafa",
            secondary:'#FFF'
        }
    },
    blue:{
        regular : "#0273A5",
        regularv2 : "#78CBEE",
        darker : {
            hue300 : "#0273A5",
        },        
        lighter:{
            hue150: "#53839D",
            hue200: "#5BC0EB",
            hue300 : "#6EAED1",
            hue600 : "#D2EDF9",
            hue900 : "#F0F9FD",
        },
        turquoise : '#5E829A',

        hue50: "#e6f1f7",
        hue100: "#cce3ed",
        hue200: "#b3d5e4",
        hue400: "#358fb7",
        hue300: "#67abc9",
        hue500: "#0273a5",
        hue600: "#026895",
        hue700: "#015173",
        hue800: "#013a53",
        hue900: "#012231",
    },
    grey:{
        regular: "#808F98",
        darker: "#727779",
        lighter:{
            hue600 : "#AAB4BA",
            hue700 : "rgba(201,203,204,0.5)",
            hue800 : "#D4D9DC",
            hue900 : "#EAECEE",
            hue980 : "#FAFBFB",
        },
        border: "rgba(201,203,204,0.5)",
        disabled: '#ababab',
        ...muiColors.grey
    },
    green:{
        lighterv2 : '#82D3931A',
        lighterv2Hover : '#82D3934D',
        regular: "#4CAA5F",
        lighter: "#EBFFEF",
        darker: "#388648",
        regularv2 : '#79C579',
        hue50: "#E3F2E6",
        hue100: "#B9DFC1",
        hue200: "#8ECC9B",
        hue300: "#72C082",
        hue400: "#56B369",
        hue500: "#4CAA5F",
        hue600: "#469B57",
        hue700: "#3F8D4F",
        hue800: "#397F47",
        hue900: "#2F6B3D",
    },
    red:{
        hue100: "#FCE8E9",
        hue200: "#F7C6C8",
        hue300: "#EE8285",
        hue400: "#E85459",
        hue500: "#E53D43",
        hue600: "#E33238",
        hue700: "#E2262D",
        hue800: "#D51A20",
        hue900: "#B3161B",

        regular: "#E01B22",
        lighter: "#F7777B",
        lighterv2: "#FFEBEC",
        lighterv2Hover : '#ffd6d8',
        darker: "#BB161B"
    },
    orange:{
        regular: "#F67817",
        lighter: "#fff8eb",
        darker: "#D16614",
        ...muiColors.orange
    },
    yellow:{
        regular: "#FFE53B",
        ...muiColors.yellow
    },
    purple:{
        regular: "#BC8DF3",
        ...muiColors.purple
    },
    pink:{
        regular: "#E21C55",
        lighter: "#CD2683",
        ...muiColors.pink
    },
    cyan:{
        regular: "#58AFFD",
        lighter: "#37DBE0",
        ...muiColors.cyan   
    },
    sinfinNewMenu:{
        normalAccordion: "#98999A",
        normal : "#4D4E50",
        active: "#36738D",
        backgroundHover: "#F0F9FD",
        backgroundSubHover: "#D2EDF9",
        selected : "#78CBEE",
    },
    menu:{
        regular: "#425E7C",
        darker: "#15181C"
    },
    white: "#FFFFFF",
    black: {
        regular: "#151619",
        lighter: "#151619",
        grey:"#EDEEEE"
    },
    crmNavBackground : '#000000'
})

export default colors;