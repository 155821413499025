import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Pg from '../../../../shared/components/CustomParagraph';

const HeaderGame = (props) => {
    const [hover, setHover] = useState(false);
    let {
        preview = false,
        id = null, 
        data,
        inputCallback = null,
    } = props;

    let image = data.inputs['backgroundImage'].value;
    return (
        <div className="preheader typeSection" onClick={() => inputCallback(id)}
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: '100%',
                backgroundPosition: "bottom center",
                backgroundRepeat: "no-repeat",
                position: 'relative',
                padding: data.inputs['padding']?.value ? data.inputs['padding'].value : null,
                maxWidth: data.inputs['maxWidth']?.value ? data.inputs['maxWidth'].value : null,
                margin: data.inputs['maxWidth']?.value ? 'auto' : null,
                justifyContent: data.blocks.redirectionButtonText.inputs['link']?.value ? 'space-between' : 'center'
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && hover && props.children }
            <div className="div">
                {
                    typeof data.blocks.image.inputs.value?.value === 'string' || data.blocks.image.inputs.value?.value?.filePath ? (
                        <img className="logo" onClick={() => inputCallback(id, 'logo')} 
                            src={
                                typeof data.blocks.image.inputs.value.value === 'string' ? 
                                    data.blocks.image.inputs.value.value : 
                                    data.blocks.image.inputs.value?.value?.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` 
                                        : "http://via.placeholder.com/300x115/CCCCCC/AAAAAA?text=Image"
                                }
                            alt="Logo jeu"
                        />
                    ) : null
                }
            </div>
            <div className="div">
                {
                    typeof data.blocks.logo.inputs.value?.value === 'string' || data.blocks.logo.inputs.value?.value?.filePath ? (
                        <img className="logo" onClick={() => inputCallback(id, 'logo')} 
                            src={
                                typeof data.blocks.logo.inputs.value.value === 'string' ? 
                                    data.blocks.logo.inputs.value.value : 
                                    data.blocks.logo.inputs.value?.value.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` 
                                        : "http://via.placeholder.com/300x115/CCCCCC/AAAAAA?text=Image"
                                }
                            alt="Logo jeu"
                        />
                    ) : null
                }
            </div>
            {
                data.blocks.redirectionButtonText.inputs['link']?.value ? 
                <div 
                    style={{
                        backgroundColor: data.blocks.redirectionButtonText.inputs['backgroundColor'].value,
                    }}
                    className="div"
                >
                    <a href={data.blocks.redirectionButtonText.inputs['link'].value} target="_blank" rel="noopener noreferrer">
                        <Pg data={data} preview={preview} className="center" component={id} block={'redirectionButtonText'} inputCallback={inputCallback} nopadding={true} />
                    </a>
                </div>
                : null
            }
        </div>
    );
};

HeaderGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default HeaderGame;
