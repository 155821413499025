import {CARD} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

const ModelesType = 
    {
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: CARD,

        },

    }
export default ModelesType;