import React from "react";
import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import colors from '../../../../../../config/theme/colors';
import StatusInfo from "../../../../../ui/status-info/StatusInfo";

function CellStatus(props){
    let value = props.attribute?.attribute.attributeType.input === "textarea"
        ? props.value
        : props.attribute?.attribute.attributeType.input === "select" ?
            props.attribute.attributeOption.translation.translationDatas?.edges?.[0].node.value ?? props.attribute.attributeOption.identifier
            : props.value === 'false' 
                ? 'Non' 
                : props.value === 'true' 
                    ? 'Oui' 
                    : props.value;
                
    return(
        <TableCell 
            key={props.key}
            style={{
                width : props.width ? props.width :'auto',
                fontWeight : props.fontWeight ? props.fontWeight : 'normal',
                padding : '4px 16px'
            }}
        >
            <StatusInfo status={value} width={'auto'} />
        </TableCell>
    )
}
export default CellStatus;