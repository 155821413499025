import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {SocialComplete} from '../../index';
import Pg from '../../../../../shared/components/CustomParagraph';
import H1 from '../../../../../shared/components/CustomH1';
import ModalEmailShare from './ModalEmailShare';
import request from "../../../../../../tools/Fetch";
import { getBackgroundImage } from "../../../../../../utils/getBackgroundImage";
import TopLeftImage from "../../mini-games/images/wishlist/bandeau-1.png";
import BottomRightImage from "../../mini-games/images/wishlist/bandeau-2.png";
import { getImage } from "../../../../../../utils/getImage";

const RecapWishlist = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [hoverBlockSocial, setHoverBlockSocial] = useState(false);
    const [listProducts, setListProducts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [endPage, setEndPage] = useState(false);

    let { 
        preview = false,
        id = null, 
        data,
        inputCallback = null,
        update = null,
        lang
    } = props;

    let image = data.inputs['backgroundImage'].value;
    let imageTablet = data.inputs['backgroundImageTablet']?.value;
    let imageMobile = data.inputs['backgroundImageMobile']?.value;
    let blockSocial = data.blocks.socialBlock;
    
    function toggleModal(){
        setOpenModal(!openModal)
    }


    useEffect(() => {
        let list = localStorage.getItem('products');
        if (list)
            list = JSON.parse(list);
            setListProducts(list);
    }, []);

    function callBackValidated(){
        setValidated(true)
        localStorage.setItem('wishlist_validated', true);
    }

    function sendAllMail(){
        let data = new FormData();
        data.append('username', localStorage.getItem('username'));
        data.append('token', process.env.REACT_APP_ASSET_TOKEN);
        data.append('send', true);
        try {
            request(`${process.env.REACT_APP_API}/public/asset/registration/send/share-emails`, 'post', data, 'multipart/form-data')
            .then(result => {
                setEndPage(true);
                localStorage.removeItem('wishlist_validated');
                localStorage.removeItem('products');
                localStorage.removeItem('username');
                localStorage.removeItem('userToken');
            }).catch(e => {
                console.err('Erreur envoi mail',e);
            })
        }catch(e){
            console.rer('Erreur fin du jeu',e);   
        }
        setEndPage(true);
    }

    let imageBack = blockSocial.inputs['backgroundImage'].value;

    let imageDesktop = data.blocks.logoDesktop?.inputs.value?.value
    let imageTopTablet = data.blocks.logoTablet?.inputs.value?.value
    let imageTopMobile = data.blocks.logoMobile?.inputs.value?.value    
    return (
        <div className="results recap-wishlist" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>        
            <section 
                class="win"
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: getBackgroundImage(image,imageTablet,imageMobile),
                    backgroundPosition: 'top center',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                { !preview && props.toolbarSection }
                <div className="containerImage">
                    {
                        data.blocks.logoDesktop?.inputs.value?.value?.filePath || typeof(data.blocks.logoDesktop?.inputs.value?.value) === "string" ?
                        (
                            <img className="imageAbsolute" onClick={() => inputCallback(id, 'logo')} src={getImage(imageDesktop,imageTopTablet,imageTopMobile)} />
                        ) : null
                    }
                </div>
                {
                    !endPage ?
                    <>
                        <div>
                            {
                                data.blocks.title.inputs.value?.value ?
                                    <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true}/>
                                : null
                            }
                            <Pg data={data} preview={preview} component={id} block={'description'} inputCallback={inputCallback} nopadding={true}/>
                        </div>
                        {
                            blockSocial.blocks.shareText.inputs?.value?.value || blockSocial.blocks.social.config.length > 0  ?
                            (
                                <div 
                                    style={{position: "relative"}} 
                                    onMouseEnter={() => {setHoverBlockSocial(true); setHoverBlock(false); setHover(false);}} 
                                    onMouseLeave={() => {setHoverBlockSocial(false); setHover(true);}}
                                >
                                    { !preview && hoverBlockSocial && props.toolbarBlockSocial }
                                    <div 
                                        className="block_social_complete"
                                        style={{
                                            backgroundColor: blockSocial.inputs['backgroundColor'].value,
                                            // backgroundImage: imageBack,
                                            backgroundImage: typeof imageBack === 'string' ? `url(${imageBack})` : imageBack?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${imageBack.filePath})` : 'none',
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            position: 'relative',
                                        }}
                                    >
                                        {
                                            blockSocial.blocks.shareText.inputs.value.value ? (
                                                <div className="social" style={{background: blockSocial.blocks.shareText.inputs.backgroundColor ? blockSocial.blocks.shareText.inputs.backgroundColor.value : null }}>
                                                    <div>
                                                        <Pg data={blockSocial} preview={preview} component={id} block={'shareText'} inputCallback={inputCallback} nopadding={true} />
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    
                                        <SocialComplete data={blockSocial} preview={preview} component={id} block={'social'} inputCallback={inputCallback} update={update} actionClick={toggleModal} callBackValidated={callBackValidated}/>
                                        {
                                            openModal ?
                                                <ModalEmailShare onClose={toggleModal} isOpen={openModal} callBackValidated={callBackValidated} lang={lang}/>
                                            : null
                                        }
                                    </div>
                                </div>
                            ) : null
                        }
                        {
                            listProducts.length > 0 ? (
                                <div className="recap">
                                    <img className="absolute top-left" src={TopLeftImage} />
                                    <img className="absolute bottom-right" src={BottomRightImage} />
                                    {
                                        data.blocks.titleRecap.inputs.value?.value ?
                                            <H1 data={data} preview={preview} component={id} block={'titleRecap'} inputCallback={inputCallback} nopadding={true} className={'title-recap'}/>
                                        : null
                                    }
                                    {
                                        !validated ?
                                            <div className="button" onClick={() => window.location = "/game"}>
                                                <button>Modifier ma liste</button>
                                            </div>
                                        : 
                                        <div className="button" onClick={() => sendAllMail()}>
                                            <button className="finished">Valider mon inscription</button>
                                        </div>
                                    }
                                    <div className="products">
                                        {
                                            listProducts.map((product, index) => (
                                                <div key={index}>
                                                    <img src={`${process.env.REACT_APP_MEDIAS}/${product.attributes.product_image.fr_FR}`} />
                                                    <p>{product.attributes.product_name[lang]}</p>
                                                    <p className="ref">Ref: {product.sku}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : null
                        }
                    </>
                    :
                    <div className="endGame">
                        <img src="https://sony-api.spread.sinfin.fr/medias/619ca286c6d89286318984.png"></img>
                        <h3 className="center" style={{fontSize: "39px", color: "white", textAlign: "center",lineHeight: "1.2"}}>VOUS ÊTES BIEN INSCRIT<br /> AU GRAND TIRAGE AU SORT SONY PLAYSTATION</h3>
                        <p className="center" style={{fontSize: "16px", color: "white", textAlign: "center",lineHeight: "1.2"}}>Nous reviendrons vers vous par mail si vous êtes tiré au sort.</p>
                    </div>
                }
            </section>
        </div>
    );
};

RecapWishlist.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default RecapWishlist;
