import React from 'react';
import { Box, Grid } from '@mui/material';
import Typography from '../../ui/typography/Typography';
import styled from 'styled-components';
import iconSet from "../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import CircularProgress from '@mui/material/CircularProgress';
import { withTranslation } from 'react-i18next';

const BoxPicto = styled(Box)`
    border-radius: 4px;
    width: max-content;
    background: ${props => `transparent linear-gradient(118deg, ${props.gradientcolor1} 0%, ${props.gradientcolor2} 100%) 0% 0% no-repeat padding-box`};
`;
const BoxIcon = styled(Box)`
    border-radius: 4px;
    width: max-content;
    // background: ${props => `transparent linear-gradient(118deg, ${props.gradientcolor1} 0%, ${props.gradientcolor2} 100%) 0% 0% no-repeat padding-box`};
`;
const Notification = styled.span`
    background-color: ${props => props.color ? props.color : ''};
    width:15px;
    height:15px;
    border-radius:50%;
    display:inline-block;
    margin-left:10px;
`;

function Stats(props) {
    const stat = props.stat;

    let getName = props.isRecognizer ? stat.productInfo.name : stat.productInfo?.productDatas.edges.find(e => e.node.attribute.identifier === "product_name")
    let getImage = props.isRecognizer ? null : stat.productInfo?.productDatas.edges.find(e => e.node.attribute.identifier === "product_image")
    
    return (

        <Grid container direction="row" alignItems='center'>
            {
                stat.productInfo ?
                    <Grid container direction="column">
                        <Typography variant="body2" component="div" responsivebreakpoint={1600} responsivecss={`font-size:12px;`} style={{ color: stat.subtitleColor, fontSize: stat.subtitleCustomFont, fontWeight: stat.subtitleCustomFont ? 'bold' : 'normal' }}>
                            <Box pt={1}>
                                {props.t(stat.subtitle)}

                            </Box>
                        </Typography>
                        <Grid container direction="row" style={{ marginTop: 8 }}>
                            {
                                getImage ?
                                    <Grid>
                                        <BoxPicto p={1}>
                                            <img src={`${process.env.REACT_APP_MEDIAS}/${getImage.node.media.filePath}`} style={{ maxWidth: 100 }} />
                                        </BoxPicto>
                                    </Grid>
                                    : null
                            }
                            <Grid display="flex" style={{ paddingLeft: '8px' }}>
                                <Typography component="div" style={{ color: stat.dataColor, fontSize: stat.dataCustomFont }} variant={stat.dataSize ? stat.dataSize : "body1"}>
                                    <Box fontWeight="bold" style={{ display: 'flex', alignItems: 'center' }}>
                                        {stat.data || stat.data === 0 ? stat.data : <CircularProgress size={"18px"} color={"inherit"} />}
                                        {stat.notification ? (
                                            <Notification color={stat.notification.notificationColor} />
                                        ) : null}

                                    </Box>
                                </Typography>
                                {
                                    stat.productInfo ?
                                        <Typography variant="body2" component="div" responsivebreakpoint={1600} responsivecss={`font-size:12px;`} style={{ color: stat.subtitleColor, fontSize: stat.subtitleCustomFont, fontWeight: stat.subtitleCustomFont ? 'bold' : 'normal' }}>
                                            <Box pt={props.windowWidth < 1400 ? 0 : 1}>
                                                {stat.productInfo.sku}
                                            </Box>
                                            {
                                                stat.productInfo.categories.length > 0 ?
                                                    <Box
                                                        pt={props.windowWidth < 1400 ? 0 : 1}
                                                        style={{ width: props.isRecognizer ? 'auto' : 140 }}>
                                                        {stat.productInfo.categories.length > 1 ? 'Catégories: ' : 'Catégorie: '}
                                                        {stat.productInfo.categories.map((cat, index) => {
                                                            return (
                                                                <span key={`categorie-${cat}-${index}`}>{index > 0 ? ` / ${cat}` : cat}</span>
                                                            )
                                                        })}
                                                    </Box>
                                                    : null
                                            }
                                            <Box
                                                pt={props.windowWidth < 1400 ? 0 : 1}
                                                style={{
                                                    width: props.isRecognizer ? 'auto' : 140,
                                                    color: props.isRecognizer ? stat.dataColor : null,
                                                }}
                                            >
                                                {props.isRecognizer ? getName : getName.node.value}
                                            </Box>
                                        </Typography>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Grid style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <BoxIcon gradientcolor1={stat.gradientColor1} gradientcolor2={stat.gradientColor2} style={{
                                display: "flex",
                                alignItems: 'center',
                            }} >
                                {typeof stat.icon === "string"
                                ? <IcomoonReact iconSet={iconSet} color={stat.dataColor} size={props.windowWidth <= 1500 ? 19 : 19} icon={stat.icon} />
                                : stat.icon
                                }
                            </BoxIcon>
                        </Grid>
                        <Grid style={{ display: 'flex', paddingLeft: '8px', alignItems: 'center', fontSize: props.windowWidth <= 1500 ? 12 : 12 }}>
                            <Typography component="div" style={{ color: stat.dataColor, fontSize: stat.dataCustomFont }} variant={stat.dataSize ? stat.dataSize : "body1"}>
                                <Box style={{ display: 'inline-block', alignItems: 'center', fontWeight: props.subtitlebold && "bold", lineHeight: '20px', fontSize: props.windowWidth <= 1500 ? 12 : 12 }}>
                                    <span>{props.t(stat.subtitle)} : &nbsp;</span>
                                </Box>
                                <Box fontWeight="bold" style={{ display: 'inline-block', alignItems: 'center', fontSize: '12px' }}>
                                    {stat.data || stat.data === 0 ? Number(stat.data).toLocaleString() : <CircularProgress size={"12px"} color={"inherit"} />}
                                    {stat.notification ? (
                                        <Notification color={stat.notification.notificationColor} />
                                    ) : null}
                                </Box>
                            </Typography>
                            {/* <Typography variant="body2" component="div" responsivebreakpoint={1600} responsivecss={`font-size:12px;`} style={{color: stat.subtitleColor, fontSize: stat.subtitleCustomFont, fontWeight: stat.subtitleCustomFont ? 'bold' : 'normal'}}>
                                <Box pt={props.windowWidth < 1400 ? 0 : 1}>
                                    {stat.subtitle}
                                </Box>
                            </Typography> */}
                        </Grid>
                    </>
            }
        </Grid>
    );
}

export default withTranslation()(Stats);
