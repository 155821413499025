import { Box } from "@mui/material";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import colors from "../../../../../config/theme/colors";
import OurTypography from "../../../../ui/typography/Typography";
import moment from "moment";
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';
import { capitalize } from "lodash";

const ContainerNotification = styled(Box)`
    background: ${props => props.isHighlighted ? colors.blue.lighter.hue900 : colors.grey.lighter.hue980};
    :nth-child(even){
        background: ${props => props.isHighlighted ? colors.blue.lighter.hue900 : colors.grey.lighter.hue900};
    }
    border-radius: 2px;
    position: relative;
`;

const Newscard = (props) => {
  const formatDate = (date) => {
    return moment(date).format("[Le] DD MMMM YYYY");
  }

  const isMoreThanOneDay = (date) => {
    return moment().diff(date, 'days') > 1;
  }

  return (
    <ContainerNotification p={2} pb={3} isHighlighted={props.isHighlighted}>
      <OurTypography
        style={{ fontSize: 14, lineHeight: 'inherit' }}
        colortext={props.isHighlighted ? colors.blue.darker.hue300 : colors.black.regular}
      >
        {props.isHighlighted && <FiberManualRecordSharpIcon style={{ fontSize: 10, marginRight: 5 }} />}
        {/* {props?.notification.title} */}
        {isMoreThanOneDay(props.notification.date) 
          // If yes, show 'il y a x heuers', if not, show the date
          ? formatDate(props.notification.date)
          : capitalize(moment(props.notification.date).fromNow())
        }
      </OurTypography>
      <Box mt={1}>
        <OurTypography 
          variant={"body2"} 
          colortext={props.isHighlighted ? colors.blue.darker.hue300 : colors.black.regular}
          style={{ fontWeight: 'bold', fontSize: props.windowWidth < 1400 ? 12 : 13, lineHeight: '20px' }}
        >
          {t(props?.notification.text)}
        </OurTypography>
      </Box>
      {/* <Box mt={1}>
        <OurTypography 
          variant={"body2"} 
          colortext={props.notification?.isHighlighted ? colors.blue.darker.hue300 : "#AAB4BA"}
          style={{ fontWeight: 'normal', fontSize: props.windowWidth < 1400 ? 12 : 13, fontStyle: 'italic' }}
        >
          {formatDate(props.notification.date)}
        </OurTypography>
      </Box> */}
      {/* <RemoveNotification
          bgcolor={"transparent"}
          bgcolorhover={"transparent"}
          color={colors.black.regular}
          fontsize={16}
          style={{
              padding: '0px 10px',
              position: 'absolute',
              right: '1rem',
              minWidth: 'auto',
          }}
          onClick={() => removeNotification(notification.id)}
      >x</RemoveNotification> */}
    </ContainerNotification>
  )
}

export default withTranslation()(Newscard);