import React, { useState, useEffect, useRef } from "react";
import Swiper from "react-id-swiper";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import H1 from '../../../../shared/components/CustomH1';

const SliderMultiple = ({
    preview = false,
    id = null,
    data = null,
    inputCallback = null,
    children = null
}) => {
    const [hover, setHover] = useState(false);
    const ref = useRef(null);

    let slides = data.blocks['slides'].config;

    let params = {
        loop: false,
        speed: 1000,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true
        },
        slidesPerView: 5,
        spaceBetween: 10,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        shouldSwiperUpdate: true,
        pagination: false
    };

    const styles = {
        backgroundColor: !preview ? '#eee' : '#fff',
        marginBottom: 20,
        width: '100%',
        boxSizing: 'border-box'
    };

    let col = data.blocks['slides'].inputs?.color?.value ?? '#f48256';

    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();
        }
    };
     
    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();
        }
    };

    return (
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children}

            <div className="container slider-multiple">
                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                        </div>
                        <div className="slider-area">
                            <div className="slider-active-2 nav-style-2">
                                <Swiper {...params} ref={ref}>
                                    {slides && slides.map((single, key) => {
                                        let image = single.inputs['image'].value;

                                        return (
                                            <div
                                                key={`slide-${key}`}
                                                className={`slider-height-4 d-flex align-items-center bg-img swiper-slide`}
                                                style={{
                                                    backgroundImage: `url(${typeof image === 'string' ? image : `${process.env.REACT_APP_MEDIAS}/${image.filePath}`})`,
                                                    position: 'relative'
                                                }}
                                            >
                                                <div className="container" style={{ zIndex: 15 }}>
                                                    <div className="row">
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                            <div className="slider-content-5 slider-animated-1 text-center">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Swiper>
                            </div>

                            <div className="pagination">
                                <div className="prev" onClick={goPrev} style={{ backgroundColor: col }}>
                                    <ChevronLeftIcon style={{ fontSize: 30, color: '#fff' }} />
                                </div>
                                <div className="next" onClick={goNext} style={{ backgroundColor: col }}>
                                    <ChevronRightIcon style={{ fontSize: 30, color: '#fff' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderMultiple;
