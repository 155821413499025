import React, { useEffect, useState } from 'react';
import {Grid, Box} from '@mui/material';
import Stats from '../../../../layouts/Stats/Stats';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import Typography from '../../../../ui/typography/Typography';
import colors from '../../../../../config/theme/colors';
import CardCustom from '../../../../layouts/Card/CardCustom';
import CardActions from '../../../../layouts/Card/cardContent/CardActions';
import PieCharts from '../../../../layouts/Stats/PieCharts';
import LineCharts from '../../../../layouts/Stats/LineCharts';
import { eventService } from '../../../../../js/services/event.service';
import { ROUTE_PRODUCTS_CATEGORIES, ROUTE_PRODUCTS_LIST, ROUTE_SALES_ESHOP } from '../../../../../js/constants/route-names';
import { SNACK } from '../../../../../js/constants/action-types';
import { checkRouting } from '../../../../../js/utils/checkRouting';

var date = ['Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre'];

var allDatas = [
    {
        data: [
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 4000),
            Math.round(Math.random() * 5000),
            Math.round(Math.random() * 6000),
            Math.round(Math.random() * 7000),
            Math.round(Math.random() * 8000),
            Math.round(Math.random() * 9000),
            Math.round(Math.random() * 10000)],
        color: colors.menu.regular,
        title: 'Nombre d\'utilisateurs',
    },
];

const selectDataPie = {
    type: 'select',
    isContainerized: true,
    required: false,
    stateName: 'selectedDataPie',
    label: '',
    helper: '',
    value: 
        [
            {
                value : [
                    {value: 26011, name: 'Asset 1'},
                    {value: 13089, name: 'Asset 2'},
                    {value: 7542, name: 'Asset 3'},
                    {value: 5458, name: 'Asset 4'},
                ],
                label : 'Utilisateur par assets'
            },
        ]
};

const StatsAssets = props =>{
    const [selectedDataPie,setSelectedDataPie] = useState(selectDataPie.value[0].value)
    const [stats,setStats] = useState([
        {
            'icon': 'picto-media',
            'data': 24,
            'dataColor': colors.pink.regular,
            'subtitle': 'Images uploadés',
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'notification':{
                'notificationColor': colors.green.regular
            }
        },
        {
            'icon': 'picto-3d',
            'data': 0,
            'dataColor': colors.pink.regular,
            'subtitle': 'Fichiers 3D uploadés',
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'notification':{
                'notificationColor': colors.red.darker
            }
        },
        {
            'icon': 'picto-video',
            'data': 1,
            'dataColor': colors.pink.regular,
            'subtitle': 'Vidéos uploadés',
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'notification':{
                'notificationColor': colors.orange.regular
            }
        },
        {
            'icon': 'picto-media',
            'data': '19/09/2022',
            'dataColor': colors.pink.regular,
            'subtitle': 'Dernier upload',
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'notification':{
                'notificationColor': colors.orange.regular
            }
        },
        {
            'icon': 'picto-produit',
            'data': 25,
            'dataColor': colors.pink.regular,
            'subtitle': 'Produits actifs',
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'notification':{
                'notificationColor': colors.green.regular
            }
        },
    ])
    const [actionsCard,setActionsCard] = useState([
        {
            'icon': 'picto-attribut',
            'title': 'Gérer les assets',
            'subtitle': 'Gérez l’ensemble de vos assets (création / modification / suppression)',
            'marginTop': 16,
            'gradientColor1': colors.pink.regular,
            'gradientColor2': colors.pink.lighter,
            'route': ROUTE_SALES_ESHOP
        },
        {
            'icon': 'picto-categorie',
            'title': 'Accéder aux catégories',
            'subtitle': 'Gérez l’ensemble de vos catégories (création / modification / suppression)', 
            'marginTop': 16,
            'gradientColor1': colors.menu.regular,
            'gradientColor2': colors.menu.darker,
            'route': ROUTE_PRODUCTS_CATEGORIES
        },
        {
            'icon': 'picto-produit',
            'title': 'Gérer mes produits',
            'subtitle': 'Gérez l’ensemble de vos produits (création / modification / suppression)',
            'marginTop': 0,
            'gradientColor1': colors.cyan.regular,
            'gradientColor2': colors.cyan.lighter,
            'route': ROUTE_PRODUCTS_LIST
        },
    ])

    useEffect(() =>{
        checkRouting(props);
    },[])

    const handleSelectPie = (event) => {
        setSelectedDataPie(event.target.value)
        eventService.fire();
    };

    const goTo = route => {
        props.history.push(route);
    };
    
    const goToAction = (route) => {
        props.history.push(route);
    };
    
    return (
        <div>
            <Typography variant="h1">
                Analytics
            </Typography>
            <Typography variant="body1" component="div">
                <Box pt={3} pb={2}>
                    Dernières datas
                </Box>
            </Typography>
            <Grid container direction="row">
                <Grid container>
                    {stats.map((stat, index) =>
                        (
                            <Grid item lg={2} key={`statsGrid${index}`}>
                                <Stats stat={stat} />
                            </Grid>
                        )
                    )}
                </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="center" spacing={0}>
                <Grid container direction="row" spacing={4}>
                    <Grid item lg={7} md={7} xs={7}>
                        <Typography variant="body1" component="div">
                            <Box pt={3} pb={2}>
                                Nombre d'utilisateurs
                            </Box>
                        </Typography>
                        <Box>
                            <CardCustom paddingtop={'48px'} contentpadding={'32px'}>
                                <LineCharts xAxisData={date} yAxisData={allDatas} handleSelectPie={handleSelectPie}/>
                            </CardCustom>
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={5} xs={5}>
                        <Typography variant="body1" component="div">
                            <Box pt={3} pb={2}>
                                Répartition des utilisateurs par asset
                            </Box>
                        </Typography>
                        <Box>
                            <PieCharts selectedDataPie={selectedDataPie} selectDataPie={selectDataPie} handleSelectPie={handleSelectPie}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" component="div">
                        <Box pt={3} pb={2}>
                            Que voulez vous faire ?
                        </Box>
                    </Typography>
                <Grid container spacing={4}>
                    {actionsCard.map((action, index) =>
                        (
                            <Grid item lg={3} md={6} xs={12} key={`CardActions${index}`} style={{marginRight: props.windowWidth < 1400 ? '8px' : '0px', width: '100%'}}>
                                <CardCustom style={{background: `transparent linear-gradient(118deg, ${action.gradientColor1} 0%, ${action.gradientColor2} 100%) 0% 0% no-repeat padding-box`}}>
                                    <CardActions action={action} goTo={goToAction} />
                                </CardCustom>
                            </Grid>
                        )
                    )}
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatsAssets));
