import { FormControlLabel, Grid } from "@mui/material";
import styled from "styled-components";
import colors from "../../../../../../config/theme/colors";

export const GridCustom = styled(Grid)`
margin-bottom: 16px;
`;

export const FormControlLabelCustom = styled(FormControlLabel)`
    margin: 0;
    > span.MuiRadio-root, > span.MuiCheckbox-root{
        padding: 3px 0;
    }
    span{
        color: ${colors.black.regular};
    }
    span.MuiTypography-root{
        margin-left: 8px;
    }
    &.active{
        span{
            color: ${colors.blue.darker.hue300};
            font-weight: bold;
        }
    }
    
`;