import CardCatalog from '../../../../../layouts/Card/cardContent/CardCatalog';
import noImageFlux from '../../../../../../assets/pictos/empty-picto/empty_flux.png';
import { FormInputTypes } from '../../../../../../builder/shareable/types';
import CardProduct from '../../../../../layouts/Card/cardContent/CardProduct';

export const listSettings = {
    grid: {
        components: CardProduct,
        columns: {
            xs: 1,
            sm: 2,
            laptop: 3,
        },
        // layout:{
        //     spacing:2,
        //     padding: "40px 0 0 0"
        // }
    },
    table : {
        style : {
            type : 'striped',
        },
        action:{
            show: true,
            label: 'Voir le produit'
        },
        header:{
            show:true
        },
        columns : [
            {
                label   : 'Status',
                field   :  'status',
                type : 'status',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                    align:"left"
                }
            },
            {
                label   : 'Sku',
                field   :  'sku',
                type : 'default',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Nom',
                field   :  'libelle',
                type:"default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                    align:"left"
                }
            },
            {
                label   : 'Complétude',
                field   :  'completude',
                type: "default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Catalogues',
                field   :  'catalogsNb',
                type: "default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Catégories',
                field   :  'categoriesNb',
                type: "default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Variantes',
                field   :  'childrenNb',
                type: "default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Images',
                field   :  'imagesNb',
                type: "default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Max images',
                field   :  'imagesMaxNb',
                type: "default",
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
        ]   
    }
}

export const listMappers = [
    {
        view: 'card',
        mapper: "productsCard"
    },
    {
        view: 'table',
        mapper: "productsTable"
    },
]

export const perPageOptions = {
    'card': [12, 24],
    'table': [20],
}

export const viewOptions = {
    current : 'card',
    settings: [
        {
            label: "Card",
            value:"card"
        },
        {
            label: "Liste",
            value:"table"
        }
    ]
}

export const filtersOptions = {
    title: 'Filtres et Recherche',
    inputs: [
        {
            xs:3,
            required: false,
            disabled: false,
            topLabel:"Par statut",
            type: FormInputTypes.SELECT,
            stateName: 'status',
            helper: {
                position: 'top',
                text: null,
                link: false,
            },
            value:[
                {
                    label:"Tous les statuts",
                    value:'all',
                },
                {
                    label:"Actif",
                    value:"true",
                },
                {
                    label:"Inactif",
                    value:"false",
                },
            ]
        },
        {
            xs: 9,
            required: false,
            disabled: false,
            dynamicFilter: true,
            topLabel:"Taper votre recherche",
            label:'Recherche par nom',
            type: FormInputTypes.TEXT,
            stateName: 'product_name',
            helper: {
                position: 'top',
                text: null,
                link: false,
            }
        },
    ]
}