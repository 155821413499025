import React, { useState, useCallback, useEffect } from "react";
import { Box, FormControl, InputLabel } from "@mui/material";
import { MenuItemInline } from "@/components/ui/form/components/OurSelect/styles/styled.js";
import colors from "../../../../config/theme/colors";
import { FormHelperText } from "@mui/material";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import Checkbox from "@mui/material/Checkbox";
import { debounce } from "lodash";
import styled from "styled-components";
import Select from "@mui/material/Select";

const SelectFieldCustom = styled(Select)`
  justify-self: flex-start;
  position: relative;

  & > label {
    font-weight: bold;
    transform: translate(13px, 14px);
    color: ${colors.black.regular} !important;
  }
  input,
  .MuiSelect-select {
    box-sizing: border-box;
    font-weight: bold;
    font-size: 14px;
    border-radius: 0;
    border-color: ${colors.grey.border};
    background-color: white;
    & ~ fieldset {
      border: 1px solid ${colors.grey.border} !important;
      top: 0px;

      & legend {
        display: none;
      }
    }

    & ~ fieldset[aria-expanded="true"] {
      border: 10px solid ${colors.grey.border} !important;

      & legend {
        display: none;
      }
    }

    @media screen and (max-width: 1450px) {
      font-size: 14px;
      padding: 14px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 13px;
      padding: 13px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 13px;
      padding: 13px;
    }
    padding: ${(props) => props.custom?.padding};
  }
  & > p {
    margin: 0;
  }
`;

export const SelectInput = ({
  label,
  error,
  value,
  options,
  helper,
  rules,
  delay = 0,
  onChange = () => {},
  onDelete = () => {},
  ...props
}) => {
  const [val, setVal] = useState(value);

  useEffect(() => {
    if (value !== val) setVal(value);
  }, [value]);

  const handleChange = (e) => {
    setVal(e.target.value);
    emitValue(e.target.value);
  };

  const handleDelete = (e) => {
    setVal(val.filter((v) => v !== e));
    onDelete(e);
  };

  const emitValue = useCallback(
    debounce((val) => {
      onChange(val);
    }, delay),
    []
  );

  return (
    <Box sx={{ width: "100%" }}>
      {label && (
        <InputLabel
          margin="dense"
          variant="standard"
          sx={{ marginBottom: "5px" }}
        >
          {label}
          {rules?.required && "*"}
        </InputLabel>
      )}
      <FormControl size="small" fullWidth>
        <SelectFieldCustom
          variant="outlined"
          value={val}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            },
            getContentAnchorEl: null,
            elevation: 0,
            PaperProps: {
              square: true,
              style: {
                border: `1px solid ${colors.grey.border}`,
                marginTop: "4px",
                boxShadow: "none",
                color: colors.blue.darker.hue300,
                "&:hover": {
                  backgroundColor: colors.blue.darker.hue900,
                },
              },
            },
          }}
          size="small"
          renderValue={
            props.multiple
              ? (selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        color="info"
                        label={
                          options.find((option) => option.value === value).label
                        }
                        clickable
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={() => handleDelete(value)}
                      />
                    ))}
                  </Box>
                )
              : null
          }
          {...props}
        >
          {options?.map((option, index) => {
            return (
              <MenuItemInline
                key={`OptionSelect${index}`}
                value={option.value}
                total={options.length}
                dense={true}
                disableGutters={props.multiple}
                {...option.props}
              >
                {props.multiple && (
                  <Checkbox checked={value.includes(option.value)} />
                )}
                {option.label}
              </MenuItemInline>
            );
          })}
        </SelectFieldCustom>
      </FormControl>
      {helper && <FormHelperText>{helper}</FormHelperText>}
      {error && <FormHelperText error={true}>{error}</FormHelperText>}
    </Box>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  helper: PropTypes.string,
  onChange: PropTypes.func,
  props: PropTypes.object,
};

export default SelectInput;
