import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './Snack.scss';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Snack = (props) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
    
        props.onClose();
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            open={props.open}
            autoHideDuration={props.duration ? props.duration : 3000}
            onClose={handleClose}
        >
            <div>
                <Alert onClose={handleClose} severity={props.type}>
                    { props.message }
                </Alert>
            </div>
        </Snackbar>
    );
};
export default Snack;