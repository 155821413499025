import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function formGroupAttributEdit(typeAttribute){
    const drawerInfoConfig = {
        product: {
            title: "Modifier un groupe d'attributs",
            subtitle: "Veuillez compléter les champs ci-dessous pour modifier votre groupe d'attributs",
        },
        content: {
            title: "Modifier un groupe de modèles",
            subtitle: "Veuillez compléter les champs ci-dessous pour modifier votre groupe de modèles",
        }
    }

    var obj = {
        titleForm: drawerInfoConfig[typeAttribute].title,
        subTitleForm: drawerInfoConfig[typeAttribute].subtitle,
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            header: {
                title: "Informations du groupe",
            },
            children: [
                {
                    optionsInputs: [
                        {
                            type: 'text',
                            label: 'Identifiant',
                            helper: {
                                text: 'Identifiant du groupe d\'attribut',
                                link: false,
                            },
                            required: false,
                            stateName: 'groupIdentifier',
                            translated: false,
                            disabled: true
                        },
                        {
                            type: 'text',
                            label: 'Libellé',
                            helper: {
                                text: 'Libellé du groupe d\'attribut',
                                link: false,
                            },
                            required: false,
                            stateName: 'groupName',
                            translated: true
                        }
                    ]
                },
            ]
        }
    }
    return obj;
}