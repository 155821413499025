import React from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

const TypographyCustom = styled(Typography)`
    color: ${props => props.colortext ? props.colortext+' !important' : ''};
    background-color: ${props => props.bgcolor ? props.bgcolor : ''};
    font-weight:${props => props.fontweighttext ? props.fontweighttext : ''};
    font-style:${props => props.fontstyle ? props.fontstyle : ''};
    font-size:${props => props.fontsize ? props.fontsize : ''};
    @media screen and (max-width:${props=>props.responsivebreakpoint}px){
        ${props=>props.responsivecss}
    }
    &:hover{
        color: ${props => props.colortexthover ? props.colortexthover : ''};
    }
`


const OurTypography = (props) => {
    const {variant, bgColor, colortext, colortexthover, text, style, fontweighttext,fontsize } = props;
    return (
        <TypographyCustom 
            variant={variant}
            {...props}
            className={props.className ? `${props.className}` : null}
            bgcolor={bgColor}
            colortext={colortext}
            colortexthover={colortexthover}
            style={style ? style : null}
            fontweighttext={fontweighttext}
            fontsize={fontsize}
        >
            { text ? text : props.children}
        </TypographyCustom>
    );
};

export default OurTypography