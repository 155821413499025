import React from 'react';
import { Box, Grid, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import { withTranslation } from 'react-i18next'

const ContainerSearchBar = styled(Grid)`
    position: relative;
    .search-icon{
        position:absolute;
        margin: 14px 16px;
        fill: ${colors.grey.lighter.hue600};
        @media screen and (max-width: 1400px){
            margin: 7px 12px;
            width: 0.9em;
            height: 0.9em;
        }
    }
    .enter-icon{
        right: 0;
        position:absolute;
        margin: 9px 16px;
        @media screen and (max-width: 1400px){
            margin: 7px 12px;
            width: 0.9em;
            height: 0.9em;
        }
    }
    input{
        padding: 11px 12px 11px 54px;
        @media screen and (max-width: 1400px){
            font-size: 14px;
            padding: 9px 9px 8px 44px;
        }
    }
    .MuiInput-underline:before, .MuiInput-underline:after{
        display: none;
    }
`

const TextFieldCustom = styled(TextField)`
    fieldset{
        border-color: ${colors.grey.lighter.hue700};
    }
    .MuiOutlinedInput-root {
        fieldset {
            border: 1px solid ${colors.grey.border};
        }
        &:hover fieldset {
            border: 1px solid ${colors.grey.border};
        }
        &.Mui-focused fieldset {
            border: 1px solid ${colors.grey.border};
        }
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding: ${props => props.noIcon ? '11px 16px' : '11px 16px 11px 54px'};
        border: 1px solid ${colors.grey.border};
        ${props => props.customstyle};
        @media screen and (max-width: 1450px){
            font-size: 14px;
            padding: ${props => props.noIcon ? '11px 16px' : '11px 16px 11px 54px'};
            ${props => props.customstyle};
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            padding: ${props => props.noIcon ? '11px 16px' : '11px 16px 11px 54px'};
            ${props => props.customstyle};
        }
    }
    & > p{
        margin: 0;
    }
`;


function SearchBar(props) {
    return (
        <Box component="div" width={'100%'} style={props.style ? props.style : null}>
            <Grid container>
                <Grid item xs={12}>
                    <ContainerSearchBar container direction="row" alignItems="center">
                        {
                            props.label ? <label>{props.label}</label>
                                : null
                        }
                        {
                            props.noIcon ? null
                                : <SearchIcon className="search-icon" />
                        }
                        <TextFieldCustom
                            id={props.id ? props.id : 'searchBar'}
                            variant={props.variant ? props.variant : 'outlined'}
                            InputProps={props.underlined ? { disableUnderline: false } : null}
                            color="secondary"
                            noIcon={props.noIcon}
                            fullWidth
                            onChange={(event) => props.onChange(event, props.type)}
                            name={'searchBar'}
                            type={'text'}
                            value={props.value}
                            placeholder={props.placeholder ? props.placeholder : props.t("content.content.research")}
                            onFocus={props.onFocus ? (event) => props.onFocus(event) : null}
                            customstyle={props.customstyle}
                        />
                        {/* <KeyboardReturnIcon className="enter-icon"/> */}
                    </ContainerSearchBar>
                </ Grid>
            </Grid>
        </Box>
    );
}

export default withTranslation()(SearchBar);