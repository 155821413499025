import React, { useEffect }  from 'react';

import { Box, Grid } from '@mui/material';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { withRouter } from 'react-router';
import { ROUTE_RESTAURANTS_DETAILS } from '../../../../js/constants/route-names';
import styled from 'styled-components';
import 'moment-timezone';
import emptyCatalog from '../../../../assets/images/emptyCatalog.jpg';


const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    
`;
const LineColor = styled(Box)`
    background: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
`;

const CatalogNumbers = styled(Typography)`
    color: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    font-weight: bold;
`;
const CatalogNbType = styled(Typography)`
    margin-top: -0.5rem;
    font-weight: bold;
    font-size: 14px;
    color: ${colors.black.regular};
`;

function CardRestaurant(props){
    const [numberTable, setNumberTable] = React.useState(null);

    const goTo = (route, id) => {
        props.history.push({
            pathname : route,
            state: {restaurantId : id}
        });
    };

    const getNumberTable = (rooms) => {
        let number = 0;
        for(let room of rooms){
            number += room.node.roomTables.edges.length;
        }
        setNumberTable(number)
    }
    
    useEffect(() => {
        if(props.data && !numberTable){
            getNumberTable(props.data.node.rooms.edges);
        }
    }, [props.data]);

    let getImageBackground = props.data.node.restaurantData.edges.find(item => item.node.attribute.identifier === 'restaurant_logo')?.node.media
    return(
        <Box width={'100%'} style={{cursor: props.inputCard ? 'inherit' : 'pointer'}} onClick={() => goTo(ROUTE_RESTAURANTS_DETAILS.replace(':id', props.data.node.restaurantData.edges.find(item => item.node.attribute.identifier === 'restaurant_identifier').node.value), props.data.node.id)}>
            <ContainerImage height={160} backgroundimage={getImageBackground?.filePath ? `${process.env.REACT_APP_MEDIAS}/${getImageBackground.filePath}` : emptyCatalog} />
            <LineColor height={8} />
            <Box padding={'1rem 1.5rem 0.5rem 1.5rem'}>
                <Typography variant="h4" colortext={colors.black.regular} component="div">
                    <Box fontWeight="bold">
                        {props.data.node.restaurantData.edges.find(item => item.node.attribute.identifier === 'restaurant_name')?.node.value}
                    </Box>
                </Typography>
                <Typography variant="body1" colortext={colors.black.regular} component="div">
                    <Box fontWeight="bold">
                        {props.data.node.restaurantData.edges.find(item => item.node.attribute.identifier === 'restaurant_phone')?.node.value}
                    </Box>
                </Typography>
                <Grid
                    container 
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <CatalogNumbers variant="h1" >
                                    {props.data.node.rooms.edges.length}
                                </CatalogNumbers>
                                <CatalogNbType variant="h4">
                                    {props.data.node.rooms.edges.length <= 1 ? 'salle' : 'salles'}
                                </CatalogNbType>
                            </Grid>
                            <Grid item style={{marginLeft: 32}}>
                                <CatalogNumbers variant="h1">
                                    {numberTable}
                                </CatalogNumbers>
                                <CatalogNbType variant="h4">
                                    {numberTable <= 1 ? 'table' : 'tables'}
                                </CatalogNbType>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item  xs={12}>
                        <Grid container justifyContent="flex-end">
                            <Box>
                                <Button 
                                text={props.textButton} 
                                color={colors.blue.darker.hue300} 
                                border={`1px solid ${colors.blue.darker.hue300}`}
                                colorhover={colors.white}
                                bgcolor={colors.white} 
                                bgcolorHover={colors.blue.darker.hue300}
                                disableElevation={true} 
                                arrow="right" 
                                onClick={() => goTo(ROUTE_RESTAURANTS_DETAILS.replace(':id', props.data.node.identifier), props.data.node.id)} />
                            </Box>
                        </Grid>
                    </Grid> 
                </Grid>
            </Box>
        </Box>
    )
}

export default withRouter(CardRestaurant);
