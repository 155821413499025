import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import H1 from "../../../../shared/components/CustomH1";
import H2 from "../../../../shared/components/CustomH2";
import Link from "../../../../shared/components/CustomLink";
import Label from "../../../../shared/components/CustomLabel";
import Button from "../../../../shared/components/CustomButton";
import colors from "../../../../../colors";
import request from "../../../../../tools/Fetch";

const HomeCompleteFormGameToulouse = (props) => {
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorConsent, setErrorConsent] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [consentInput, setConsentInput] = useState(false);
  const [optionnalInput, setOptionnalInput] = useState(false);
  const [states, setStates] = useState({});

  const [stillCodes, setStillCodes] = useState(true);
  const [alreadyPlayed, setAlreadyPlayed] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  function handleChange(value, type = false) {
    let getState = { ...states };
    getState[type] = value?.target?.value ?? value;
    setStates(getState);
    if (type === 'labelInput3'){
      setErrorMail(false);
    }
    if (type === 'labelInput4'){
      setErrorPhone(false);
    }
  }

  const {
    preview = false,
    id = null,
    data = null??{},
    inputCallback = null,
    assetGameType,
  } = props;


  const asGift = async () => {
    var formData = new FormData();
    formData.append("token", process.env.REACT_APP_ASSET_TOKEN);
    let response = await fetch(
      "https://api.lego.spreadsuite.io/api/public/asset/games/gift-form/gift-remaining",
      {
        method: "POST",
        mode: "cors",
        body: formData,
      }
    );
    
    if (response.status === 200 || response.status === 400) {
      let data = await response.json();
      setStillCodes(data.success && data.message === "gift remaining" ? true : false)
    }
  };

  useEffect(() => {
    asGift();
  }, []);



  const handleClickButton = (e) => {
    setLoading(true);
    let data = new FormData();
    let errorEmail = false;
    let errorTel = false;
    data.append("token", process.env.REACT_APP_ASSET_TOKEN);
    data.append("username", states.labelInput3);
    data.append("firstName", states.labelInput2);
    data.append("lastName", states.labelInput1);
    data.append("phone", states.labelInput4);
    data.append("consent", 1);
    if (/^[0-9]{10}$/.test(states.labelInput4)){
      setErrorPhone(false)
    }else{
      errorTel = true
      setErrorPhone(true)
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(states.labelInput3)){
      setErrorMail(false);
    }
    else{
      errorEmail = true
      setErrorMail(true);
    }
    if (
      !errorEmail && !errorTel
    ) {
      e.preventDefault();

      if (consentInput) {
        try {
          request(
            `${process.env.REACT_APP_API}/public/asset/games/gift-form`,
            "post",
            data,
            "multipart/form-data"
          ).then(resultat => {
            if (resultat.success) {
                window.location = "/win";
            } else {
                // Gérer erreur
                if(resultat.message === "already played") {
                  setStillCodes(false)
                  setAlreadyPlayed(true)
                }
                else
                  asGift()
            }
            setLoading(false);
          }).catch(error => {
            console.log(error);
            setLoading(false);
          });
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      } else {
        setLoading(false);
        setErrorConsent(true);
      }
    } else {
      e.preventDefault();
      if (!consentInput) {
        setErrorConsent(true);
      }
      setLoading(false);
    }
  };

  console.log(errorPhone);

  let image = data.inputs["backgroundImage"].value;

  useEffect(() => {
    let element = {};
    for (const [key, value] of Object.entries(data.blocks)) {
      element[key] = "";
    }
    setStates(element);
  }, []);

  return (
    <div
      style={{
        backgroundColor: data.inputs["backgroundColor"].value,
        backgroundImage:
          typeof image === "string"
            ? `url(${image})`
            : image?.filePath
            ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})`
            : "none",
        backgroundSize: "100%",
        backgroundPosition: "bottom center",
        backgroundRepeat: "no-repeat",
        position: "relative",
      }}
      className={"typeSection"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!preview && props.children}

      <Link
        className="reglement home_reglement"
        href="#reglement_link"
        data={data}
        preview={preview}
        component={id}
        block={"linkReglementText"}
        inputCallback={inputCallback}
      />
      <section
        className="first overflow_hidden"
        style={{
          minHeight:
          stillCodes &&
            ((data.blocks["title"].inputs.value?.value &&
              data.blocks["title"].inputs.value?.value !== "<p><br></p>") ||
            (data.blocks["subtitle"].inputs.value?.value &&
              data.blocks["subtitle"].inputs.value?.value !== "<p><br></p>"))
              ? "500px"
              : "auto",
          padding: "0 33px 40px 33px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.blocks["title"].inputs.value?.value &&
        data.blocks["title"].inputs.value?.value !== "<p><br></p>" &&
        stillCodes ? (
          <H1
            data={data}
            preview={preview}
            component={id}
            block={"title"}
            inputCallback={inputCallback}
            nopadding={true}
          />
        ) : null}
        {data.blocks["subtitle"].inputs.value?.value &&
        data.blocks["subtitle"].inputs.value?.value !== "<p><br></p>" &&
        stillCodes ? (
          <H2
            data={data}
            preview={preview}
            component={id}
            block={"subtitle"}
            inputCallback={inputCallback}
            nopadding={true}
          />
        ) : null}

        {stillCodes ? (
          <form id="form_login" className="complete_form" method="POST">
            <input
              style={{
                fontSize: data.blocks["labelInput1"]?.inputs?.size?.value,
                lineHeight: "2",
                borderStyle: "solid",
                borderWidth: data.blocks.button.inputs?.border?.inputs
                  ?.borderWidth?.value
                  ? data.blocks.button.inputs.border.inputs.borderWidth.value
                  : "0px",
                borderColor: data.blocks.button.inputs?.border?.inputs
                  ?.borderColor?.value
                  ? data.blocks.button.inputs.border.inputs.borderColor.value
                  : null,
                borderRadius: data.blocks.button.inputs?.border?.inputs
                  ?.borderRadius?.value
                  ? data.blocks.button.inputs.border.inputs.borderRadius.value
                  : null,
              }}
              type="text"
              value={states.labelInput1}
              name="lastName"
              id="labelInput1"
              placeholder={
                data.blocks["labelInput1"]?.inputs?.placeholder?.value
              }
              required
              autofocus
              onChange={(e) => {
                handleChange(e.target?.value, "labelInput1");
              }}
            />
            <input
              style={{
                fontSize: data.blocks["labelInput2"]?.inputs?.size?.value,
                lineHeight: "2",
                borderStyle: "solid",
                borderWidth: data.blocks.button.inputs?.border?.inputs
                  ?.borderWidth?.value
                  ? data.blocks.button.inputs.border.inputs.borderWidth.value
                  : "0px",
                borderColor: data.blocks.button.inputs?.border?.inputs
                  ?.borderColor?.value
                  ? data.blocks.button.inputs.border.inputs.borderColor.value
                  : null,
                borderRadius: data.blocks.button.inputs?.border?.inputs
                  ?.borderRadius?.value
                  ? data.blocks.button.inputs.border.inputs.borderRadius.value
                  : null,
              }}
              type="text"
              value={states.labelInput2}
              name="firstName"
              id="labelInput2"
              placeholder={
                data.blocks["labelInput2"]?.inputs?.placeholder?.value
              }
              required
              autofocus
              onChange={(e) => {
                handleChange(e.target?.value, "labelInput2");
              }}
            />
            <input
              style={{
                fontSize: data.blocks["labelInput3"]?.inputs?.size?.value,
                lineHeight: "2",
                borderStyle: "solid",
                borderWidth: data.blocks.button.inputs?.border?.inputs
                  ?.borderWidth?.value
                  ? data.blocks.button.inputs.border.inputs.borderWidth.value
                  : "0px",
                borderColor: errorMail ? "rgb(220, 31, 43)" : data.blocks.button.inputs?.border?.inputs
                  ?.borderColor?.value
                  ? data.blocks.button.inputs.border.inputs.borderColor.value
                  : null,
                borderRadius: data.blocks.button.inputs?.border?.inputs
                  ?.borderRadius?.value
                  ? data.blocks.button.inputs.border.inputs.borderRadius.value
                  : null,
              }}
              type="email"
              value={states.labelInput3}
              name="username"
              id="labelInput3"
              placeholder={
                data.blocks["labelInput3"]?.inputs?.placeholder?.value
              }
              required
              autofocus
              onChange={(e) => {
                handleChange(e.target?.value, "labelInput3");
              }}
            />
             {
              errorMail && (<span style={{
                color: "rgb(220, 31, 43)",
                fontSize: "14px"
              }}>Email incorrect</span>)
            }


            <input
              style={{
                fontSize: data.blocks["labelInput4"]?.inputs?.size?.value,
                lineHeight: "2",
                borderStyle: "solid",
                borderWidth: data.blocks.button.inputs?.border?.inputs
                  ?.borderWidth?.value
                  ? data.blocks.button.inputs.border.inputs.borderWidth.value
                  : "0px",
                borderColor: errorPhone ? "rgb(220, 31, 43)" : data.blocks.button.inputs?.border?.inputs
                  ?.borderColor?.value
                  ? data.blocks.button.inputs.border.inputs.borderColor.value
                  : null,
                borderRadius: data.blocks.button.inputs?.border?.inputs
                  ?.borderRadius?.value
                  ? data.blocks.button.inputs.border.inputs.borderRadius.value
                  : null,
              }}
              type="tel"
              value={states.labelInput4}
              name="phone"
              id="labelInput4"
              placeholder={
                data.blocks["labelInput4"]?.inputs?.placeholder?.value
              }
              required
              autofocus
              onChange={(e) => {
                handleChange(e.target?.value, "labelInput4");
              }}
            />
            {
              errorPhone && (<span style={{
                color: "rgb(220, 31, 43)",
                fontSize: "14px"
              }}>Numéro de téléphone incorrect</span>)
            }


            <div className="consentContainer">
              <div className={errorConsent ? "consent error" : "consent"}>
                <input
                  required
                  id="consentement"
                  type="checkbox"
                  name="consent"
                  value={consentInput}
                  onClick={() => {
                    setConsentInput(!consentInput);
                    setErrorConsent(false);
                  }}
                />
                <Label
                  data={data}
                  preview={preview}
                  component={id}
                  block={"labelConsent"}
                  inputCallback={inputCallback}
                  nopadding={true}
                  forInput="consentement"
                />
              </div>
            </div>
            <Button
              data={data}
              preview={preview}
              component={id}
              block={"button"}
              className="button"
              inputCallback={inputCallback}
              nopadding={true}
              type="submit"
              onClick={(e) => handleClickButton(e)}
            />
          </form>
        ) : alreadyPlayed ? (

          <>
          <h1 className="cera-italic-bold" style={{ color: "#222", marginBottom: 0, fontSize:"20px", textTransform: "uppercase" }}>
            Vous avez déjà joué !
          </h1>
          <p>Tenez vous au courant des dernières actualités LEGO® pour ne pas manquer nos prochains événements !</p>
          <img className="image-erreur" src={`${process.env.REACT_APP_MEDIAS}/${data.inputs.displayImageDejaParticipe.value.filePath}`} alt={data.inputs.displayImageDejaParticipe.value.alt} />
        </>
        ) : 

        <>
             <h1 className="cera-italic-bold" style={{ color: "#222", marginBottom: 0, fontSize:"20px", textTransform: "uppercase" }}>
              Tous les lots ont été obtenus !
            </h1>
            <p style={{fontSize: "13px"}}>Tenez vous au courant des dernières actualités LEGO® pour ne pas manquer nos prochains événements !</p>
              <img className="image-erreur" src={`${process.env.REACT_APP_MEDIAS}/${data.inputs.displayImageHorsLots.value.filePath}`} alt={data.inputs.displayImageHorsLots.value.alt} />
            </>
       
      }
      </section>
    </div>
  );
};

HomeCompleteFormGameToulouse.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingclassName: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
  noSticky: PropTypes.bool,
};

export default HomeCompleteFormGameToulouse;
