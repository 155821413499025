import React from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Typography, Grid, Drawer, Box, InputLabel } from "@mui/material";
import styled from "styled-components";
import colors from "../../../config/theme/colors";
import FormBuilder from "./FormBuilder";
import TopPanel from "../../layouts/TopPanel/TopPanel";

import { TABS, STEPPER, FORM, CARD, COMPONENT, MEDIAPICKER } from '../../../js/constants/form-types';
import StepperFormBuilder from './typeForm/StepperFormBuilder';
import TabsFormBuilder from './typeForm/TabsFormBuilder';
import CardDrawerBuilder from './typeForm/CardDrawerBuilder';
import Button from '../../ui/button/Button';
import InputBuilder from './InputBuilder';
import Picto from '../../../assets/pictos/bg-ampoule.svg';
import imgNotFound from '../../../assets/images/not-found.png';
import { withTranslation } from 'react-i18next'
import { ArrowBack } from '@mui/icons-material';
import PageLoader from '../loadings/page-loader/PageLoader';
import Synonymous from '../../layouts/Synonymous/Synonymous';
import OurTypography from '../typography/Typography';

const SwippeableCustom = styled(Drawer)`
    left: unset !important;
    right: 0 !important;
    
    &> .MuiDrawer-paper{
        z-index: 1300 !important;
        
        width: ${props => props.drawerwidth ?props.drawerwidth: "50%"};
        /* width: ${props => props.drawerwidth ? typeof props.drawerwidth === 'string' ? props.drawerwidth : `calc(50% - ${props.drawerwidth}px / 2)` : 'calc(797px)'}; */
        @media screen and (max-width: 1500px){
            /* width: ${props => props.drawerwidth ? typeof props.drawerwidth === 'string' ? props.drawerwidth : props.scrollable ? `calc(50% - ${props.drawerwidth}px / 2)` : `calc(60% - ${props.drawerwidth}px / 2)` : 'calc(797px)'}; */
        }
        @media screen and (max-width: 1000px){
            /* width: ${props => props.drawerwidth ? typeof props.drawerwidth === 'string' ? props.drawerwidth : `calc(100% - ${props.drawerwidth}px / 2)` : 'calc(797px - 30% )'}; */
        }
        @media screen and (max-width: 765px){
            width: 100vw;
        }
        @media screen and (max-width: 1280px){
            padding-left: 40px;
            padding-right: 40px;
            width: 80vw;
        }
        @media screen and (max-width: 1000px){
            // padding-left: 18px;
            // padding-right: 18px;
            padding-top: 30px;
        }
        padding: 60px;
        padding-bottom: ${props => props.hasBottomBar ? '0px' : '40px'};
        box-shadow: none;
        border: 0.5px solid ${colors.grey.lighter.hue700};
        padding-top: ${props => props.paddingtop ? '20px' : '40px'};
        margin-top: 0; // 80px to get as before
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 100%;
    }
`;
const RequiredMessage = styled(Typography)`
    position: absolute;
    transform: translateY(-10px);
    top: 0;
    left: 0;
    color: ${colors.grey.lighter.hue800};
    font-weight: lighter;
    font-style: italic;
`;
const LangWrapper = styled(Grid)`
    & > div {
        margin-bottom: 0px;
    }
`
const DrawerCustom = styled(Drawer)`
    
    left: unset !important;
    right: 0 !important;
    
    &> .MuiDrawer-paper{
        z-index: 1300 !important;
        
        width: ${props => props.drawerwidth ?props.drawerwidth: "50%"};
        /* width: ${props => props.drawerwidth ? typeof props.drawerwidth === 'string' ? props.drawerwidth : `calc(50% - ${props.drawerwidth}px / 2)` : 'calc(797px)'}; */
        @media screen and (max-width: 1500px){
            /* width: ${props => props.drawerwidth ? typeof props.drawerwidth === 'string' ? props.drawerwidth : props.scrollable ? `calc(50% - ${props.drawerwidth}px / 2)` : `calc(60% - ${props.drawerwidth}px / 2)` : 'calc(797px)'}; */
        }
        @media screen and (max-width: 1000px){
            /* width: ${props => props.drawerwidth ? typeof props.drawerwidth === 'string' ? props.drawerwidth : `calc(100% - ${props.drawerwidth}px / 2)` : 'calc(797px - 30% )'}; */
        }
        @media screen and (max-width: 765px){
            width: 100vw;
        }
        @media screen and (max-width: 1280px){
            padding-left: 40px;
            padding-right: 40px;
            width: 80vw;
        }
        @media screen and (max-width: 1000px){
            // padding-left: 18px;
            // padding-right: 18px;
            padding-top: 30px;
        }
        padding: 60px;
        padding-bottom: ${props => props.hasBottomBar ? '0px' : '40px'};
        box-shadow: none;
        border: 0.5px solid ${colors.grey.lighter.hue700};
        padding-top: ${props => props.paddingtop ? '20px' : '40px'};
        margin-top: 0; // 80px to get as before
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 100%;
    }
`;
const BoxClose = styled(Box)`
  width: 80px;
  padding-bottom: 0;
  color: ${colors.black.regular};
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
  span {
    margin-left: 4px;
  }
`;
const ButtonsContainerCustom = styled(Grid)`
  /* & > div > div:only-child{
        justify-content: flex-end;
    } */
    position: relative;
    width: 100%;
    margin: 16px 0px;
    &:has(> div:only-child){
        justify-content: flex-end;
    }
    /* &:has(div > div:only-child){
        justify-content: flex-end;
    } */
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    margin-top: ${props => props.margintop || 0};
    font-size: 16px;
    margin-bottom: 4px;
    @media screen and (max-width: 1450px){
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (max-width: 1280px){
        font-size: 13px;
        line-height: 17px;
    }
    @media screen and (max-width: 960px){
        font-size: 12px;
        line-height: 16px;
    }
`;

class LayoutBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabState: "",
      langState: "",
      image: "",
    };
  }

  handleChange = (event, value) => {
    this.setState({ tabState: value });
  };

    componentDidMount() {
        this.setState({
            tabState: 0,
            langState: this.props.currentLang ?? null,
        });

    if (this.props.image) {
      const imagesFolder = require.context("../../../assets/images", true);

      try {
        let img = imagesFolder("./" + this.props.image);
        this.setState({ image: typeof img === "string" ? img : img?.default });
      } catch (e) {
        this.setState({ image: imgNotFound });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.image !== prevProps.image) {
      if (this.props.image) {
        const imagesFolder = require.context("../../../assets/images", true);
        try {
          let img = imagesFolder("./" + this.props.image);
          this.setState({
            image: typeof img === "string" ? img : img?.default,
          });
        } catch (e) {
          this.setState({ image: imgNotFound });
        }
      }
    }
  }

    typeNavForm(
        hasLangSelect,
        navFormConfig,
        stateCallback,
        useLocking,
        errorCallback,
        handleButtonGroupChange,
        handlerSetup,
        hideInput,
        allState,
        dataCard,
        currentLang,
        stepperButtonAction,
        handlerMutation,
        backStepperButtonAction,
        stepperButtonDisabled,
        handleTreeChange,
        expandNodes,
        component,
        treeEditCat,
        treeDeleteCat,
        copyCategories,
        treeAddCat,
        handleChangeCategorie,
        resetReloadListing,
        selectedCategorie,
        productByCategorie,
        selectedProductByCategorie,
        setSelectedProducts,
        allCategories,
        catalogDatas,
        paginationInfo,
        searchHandler,
        isBuilder,
        noPaddingTop,
    ) {
        switch (navFormConfig.type) {
            case TABS:
                return (
                    <TabsFormBuilder
                        navFormConfig={navFormConfig}
                        stateCallback={stateCallback}
                        useLocking={useLocking}
                        errorCallback={errorCallback}
                        allState={allState}
                        handleButtonGroupChange={handleButtonGroupChange ? handleButtonGroupChange : null}
                        catalogDatas={catalogDatas ? catalogDatas : null}
                        paginationInfo={paginationInfo}
                        searchHandler={searchHandler}
                        hasLangSelect={hasLangSelect}
                    />
                );
            case STEPPER:
                return (
                    <StepperFormBuilder
                        setSelectedProducts={setSelectedProducts}
                        allCategories={allCategories}
                        styleInnerContainer={this.props.styleInnerContainer}
                        backgroundColor={this.props.backgroundColor}
                        deleteMutation={this.props.deleteMutation}
                        forClose={this.props.forClose}
                        deleteText={this.props.deleteText}
                        navFormConfig={navFormConfig}
                        stateCallback={stateCallback}
                        useLocking={useLocking}
                        errorCallback={errorCallback}
                        allState={allState}
                        stepperButtonAction={stepperButtonAction}
                        backStepperButtonAction={backStepperButtonAction}
                        handlerMutation={handlerMutation}
                        handleButtonGroupChange={handleButtonGroupChange ? handleButtonGroupChange : null}
                        handleTreeChange={handleTreeChange}
                        expandNodes={expandNodes}
                        treeEditCat={treeEditCat}
                        treeDeleteCat={treeDeleteCat}
                        copyCategories={copyCategories}
                        treeAddCat={treeAddCat}
                        component={component}
                        handleChangeCategorie={handleChangeCategorie}
                        resetReloadListing={resetReloadListing}
                        selectedCategorie={selectedCategorie}
                        productByCategorie={productByCategorie}
                        selectedProductByCategorie={selectedProductByCategorie}
                        stepperButtonDisabled={stepperButtonDisabled}
                        stepperSkip={this.props.stepperSkip}
                        gridItems={this.props.gridItems}
                        loadGrid={this.props.loadGrid}
                        addProducts={this.props.addProducts}
                        merchModal={this.props.merchModal}
                        hideInput={hideInput !== null ? hideInput : null}
                        catalogDatas={catalogDatas ? catalogDatas : null}
                        paginationInfo={paginationInfo}
                        searchHandler={searchHandler}
                        windowWidth={this.props.windowWidth}
                        hasLangSelect={hasLangSelect}
                    />
                );
            case FORM:
                return (
                    <Grid container direction={'column'}>
                        <Box style={{
                            borderTop: `1px solid ${colors.grey.border}`,
                            borderLeft: `1px solid ${colors.grey.border}`,
                            borderRight: `1px solid ${colors.grey.border}`,
                            padding: 12,
                            backgroundColor: colors.white,
                            flex: '0 0 auto'
                        }}>
                            {this.props.dataLayout.formConfig.header?.title}
                        </Box>
                        <Box style={{ 
                            //* Flex and height are important to make the form scrollable and flexible
                            flex: '1 1 auto',
                            height: '0px',

                            overflow: "auto", 
                            backgroundColor: colors.grey.lighter.hue980, 
                            border: `1px solid ${colors.grey.border}` 
                        }}>
                            {navFormConfig.children.map((child, index) => (
                                <div value={this.state.tabState} hidden={this.state.tabState !== index} key={`FormBuilder${index}`} style={{ height: '100%' }}>
                                    <FormBuilder
                                        setSelectedProducts={setSelectedProducts}
                                        child={child}
                                        optionsInputs={child.optionsInputs}
                                        stateCallback={stateCallback}
                                        useLocking={useLocking}
                                        errorCallback={errorCallback}
                                        handleButtonGroupChange={handleButtonGroupChange ? handleButtonGroupChange : null}
                                        hideInput={hideInput !== null ? hideInput : null}
                                        allState={allState}
                                        catalogDatas={catalogDatas ? catalogDatas : null}
                                        paginationInfo={paginationInfo}
                                        searchHandler={searchHandler}
                                        gridItems={this.props.gridItems}
                                        loadGrid={this.props.loadGrid}
                                        selectedCategorie={selectedCategorie}
                                        productByCategorie={productByCategorie}
                                        selectedProductByCategorie={selectedProductByCategorie}
                                        resetReloadListing={resetReloadListing}
                                        hasLangSelect={hasLangSelect}
                                    />
                                </div>
                            ))}
                        </Box>
                    </Grid>
                );
            case CARD:
                return (
                    <CardDrawerBuilder
                        navFormConfig={navFormConfig}
                        stateCallback={stateCallback}
                        useLocking={useLocking}
                        errorCallback={errorCallback}
                        handlerSetup={handlerSetup}
                        dataCard={dataCard}
                        handleButtonGroupChange={handleButtonGroupChange ? handleButtonGroupChange : null}
                        catalogDatas={catalogDatas ? catalogDatas : null}
                        paginationInfo={paginationInfo}
                        searchHandler={searchHandler}
                        allState={allState}
                        hasLangSelect={hasLangSelect}
                    />
                );
            case MEDIAPICKER:
                return (
                    <navFormConfig.component onSelect={navFormConfig.onSelect} onAccept={navFormConfig.onAccept} xs={navFormConfig.xs} stateName={navFormConfig.stateName} toggleMediaPickerDrawer={navFormConfig.toggleMediaPickerDrawer} translated={navFormConfig.translated} isBuilder={isBuilder} allowedTypes={navFormConfig.allowedTypes} />
                );

      case COMPONENT:
        return (
          <navFormConfig.component
            allState={allState}
            allProps={this.props}
            stateCallback={stateCallback}
          />
        );
      default:
        return null;
    }
  }

    topLayoutForm(props) {
        const selectLang = {
            type: 'select',
            label: 'Langue',
            helper: 'Langue',
            required: false,
            stateName: 'langState',
            isContainerized: true,
            value:
                props.locales.map((locale) => {
                    return ({ value: locale.node.code, label: locale.node.libelle })
                })
        };
        let selectCollection = {}
        if (props.listCollections && props.listCollections.length > 0) {
            selectCollection = {
                type: 'select',
                isContainerized: true,
                label: 'Collection',
                helper: 'Collection',
                required: false,
                stateName: 'currentCollection',
                value:
                    [].concat({
                        node: {
                            id: '-1',
                            identifier: 'Aucune collection'
                        }
                    }).concat(props.listCollections)
                        .filter(e => props.allState?.allGroups[0]?.node.identifier === 'mobilier' ? e.node.id === "/api/selections/4" || e.node.id === "-1" : e.node.id !== "/api/selections/4")
                        .map((collection) => {
                            return ({ value: collection.node.id, label: collection.node.identifier })
                        })
            };
        }
        return (
            <Grid container direction="column" justifyContent="center" spacing={0} style={{
                display: 'grid',
                gridTemplateRows: props.dataLayout.langSelect ? 'auto auto 1fr' : 'auto 1fr',
                gridTemplateColumns: '100%',
            }}>
                {
                    props.dataLayout.titleForm ? (
                        props.icomoon ?
                            (<TopPanel inForm={true} windowWidth={props.windowWidth} icomoon={props.icomoon} colorIcomoon={props.keepColorIcomoon ? 'none' : colors.blue.darker.hue300} title={this.props.t(props.dataLayout.titleForm)} subtitle={this.props.t(props.dataLayout.subTitleForm)} gradientColor1={props.gradientColor1} gradientColor2={props.gradientColor2} >
                                {
                                    props.dico && process.env.REACT_APP_HIDE_SYNONYME !== "true" ?
                                        <Synonymous />
                                        : null
                                }
                            </TopPanel>)
                            : (<TopPanel inForm={true} windowWidth={props.windowWidth} picto={props.picto ? props.picto : props.image ? (this.state.image ? this.state.image : imgNotFound) : Picto} title={this.props.t(props.dataLayout.titleForm)} subtitle={this.props.t(props.dataLayout.subTitleForm)} ></TopPanel>)
                    ) : null
                }
                {
                    props.dataLayout.langSelect ? (
                        <Grid container direction="column" justifyContent="center" spacing={0}>
                            <LangWrapper container direction="row" alignItems="center" justifyContent='space-between'>
                                <OurTypography fontweighttext={400} style={{fontSize: 16, color: colors.grey.lighter.hue600, marginBottom: 8 }}>Choisir une langue</OurTypography>
                                <InputBuilder value={this.props.currentLang} input={selectLang} stateCallback={this.props.handleLang} />
                                {props.dataLayout.collectionSelect && <InputBuilder value={props.currentCollection} input={selectCollection} stateCallback={this.props.handleChangeCollection} />}
                            </LangWrapper>
                        </Grid>
                    ) : null
                }
                {
                    /* TODO: Add loader */
                    this.typeNavForm(
                        props.dataLayout.langSelect,
                        props.dataLayout.formConfig,
                        props.stateCallback,
                        props.useLocking,
                        props.errorCallback,
                        props.handleButtonGroupChange,
                        props.handlerSetup,
                        props.hideInput,
                        props.allState,
                        props.dataCard,
                        props.currentLang,
                        props.stepperButtonAction,
                        props.handlerMutation,
                        props.backStepperButtonAction,
                        props.stepperButtonDisabled,
                        props.handleTreeChange,
                        props.expandNodes,
                        props.component,
                        props.treeEditCat,
                        props.treeDeleteCat,
                        props.copyCategories,
                        props.treeAddCat,
                        props.handleChangeCategorie,
                        props.resetReloadListing,
                        props.selectedCategorie,
                        props.productByCategorie,
                        props.selectedProductByCategorie,
                        props.setSelectedProducts,
                        props.allCategories,
                        props.catalogDatas,
                        props.paginationInfo,
                        props.searchHandler,
                        props.isBuilder,
                        props.noPaddingTop,
                        props.allowedTypes,
                    )
                }
            </Grid>
        )
    }

    renderSwitch(props) {
        if (props.dataLayout) {
            switch (props.dataLayout.drawerType) {
                case 'swipeable':
                    return (
                        <SwippeableCustom hasBottomBar={props.validateButton} BackdropProps={{ invisible: props?.isSublayout }} hideBackdrop={!props?.isProtected} anchor="right" open={props.opened}>
                            <Box style={{
                                display: 'grid',
                                gridTemplateRows: 'auto 1fr',
                                /* gridTemplateRows: 'auto auto 1fr', */
                                height: '100%',
                            }}>
                                {props.noCancel ? null :
                                    <Typography variant="h5" onClick={() => {
                                        props.forClose();
                                        if (props.handleCancel)
                                            props.handleCancel();
                                    }} component="div">
                                        <BoxClose pb={2} style={{ cursor: 'pointer' }}>
                                            <ArrowBack /> <span>{this.props.t("spread.active_assets.return")}</span>
                                        </BoxClose>
                                    </Typography>
                                }
                                {this.topLayoutForm(props)}
                                {props.validateButton &&
                                    <Grid container direction="row" justifyContent="flex-end" style={{margin: '16px 0px'}}>
                                        {props.deleteMutation &&
                                            <Box pt={3} mr={2}>
                                                <Button
                                                    onClick={props.deleteMutation}
                                                    forClose={props.forClose}
                                                    allState={props.allState}
                                                    text={props.deleteText ? props.deleteText : null}
                                                    bgcolor={'transparent'}
                                                    bgcolorhover={'transparent'}
                                                    color={colors.red.regular}
                                                    deleteIcon={true}
                                                    textTransform={'underline'}
                                                    fontsize={12}
                                                    fontWeight="normal"
                                                    style={{ padding: '14px 32px' }}
                                                />
                                            </Box>
                                        }
                                        {
                                            props.dataLayout.formConfig.type !== STEPPER &&
                                                (
                                                    props.handlerMutation ?
                                                        (
                                                            <Box pt={3}>
                                                                <Button types="Validate" onClick={props.handlerMutation} forClose={props.forClose} allState={props.allState} text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} shadowcolor={colors.green.darker} style={{ padding: '14px 32px' }}>
                                                                </Button>
                                                            </Box>
                                                        )
                                                        :
                                                        (
                                                            <Box pt={3}>
                                                                <Button types="Validate" onClick={props.forClose} forClose={props.forClose} allState={props.allState} text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} shadowcolor={colors.green.darker} style={{ padding: '14px 32px' }}>
                                                                </Button>
                                                            </Box>
                                                        )
                                                ) 
                                        }
                                        {/* <Grid container direction="row" justifyContent="flex-start">
                                            <Box pt={1}>
                                                <RequiredMessage variant="h4">*{this.props.t('products.obligate')}</RequiredMessage>
                                            </Box>
                                        </Grid> */}
                                    </Grid>
                                }
                            </Box>
                        </ SwippeableCustom>
                    );
                case 'drawer':
                    return (
                        <DrawerCustom hasBottomBar={props.validateButton} BackdropProps={{ invisible: props?.isSublayout }} scrollable={props.scrollable} variant={props.scrollable ? 'persistent' : 'temporary'} hideBackdrop={!props?.isProtected} anchor="right" open={props.opened} drawerwidth={props.drawerWidth ? props.drawerWidth : null} paddingtop={props.noPaddingTop ? props.noPaddingTop : props.isBuilder}>
                            {props.noCancel ? null :
                                <Typography variant="h5" onClick={() => {
                                    props.forClose();
                                    if (props.handleCancel)
                                        props.handleCancel();
                                }} component="div">
                                    <BoxClose pb={2} style={{ cursor: 'pointer' }}>
                                        <ArrowBack /> <span>{this.props.t("spread.active_assets.return")}</span>
                                    </BoxClose>
                                </Typography>
                            }
                            {this.topLayoutForm(props)}

                            {/* Hide from STEPPER since it has its own one and also, CARD */}
                            {props.validateButton & 
                                ![STEPPER, CARD].includes(props.dataLayout.formConfig.type) ?
                                <Grid container direction="row" justifyContent="flex-end">

                                    <ButtonsContainerCustom container direction="column">
                                        {props.showInfoText && <RequiredMessage variant="h4">*{this.props.t('products.obligate')}</RequiredMessage>}
                                        <Box marginLeft={'auto'}>
                                            {
                                                props.dataLayout.formConfig.type !== STEPPER ?
                                                    (
                                                        props.handlerMutation ?
                                                            (<Box>
                                                                <Button 
                                                                    types={props.buttons.validate.type} 
                                                                    importance={props.buttons.validate.importance} 
                                                                    icon={props.buttons.validate.hasIcon} 
                                                                    onClick={props.handlerMutation} 
                                                                    disabled={props.buttons.validate.isDisabled} 
                                                                    forClose={props.forClose} 
                                                                    allState={props.allState} 
                                                                    text={props.buttons.validate.text ?? props.dataLayout.textButtonValidate ?? this.props.t('products.categories.exporter.validate')} 
                                                                    shadowcolor={colors.green.darker} 
                                                                    style={{ padding: '14px 32px' }} 
                                                                />
                                                            </Box>)
                                                            :
                                                            (<Box>
                                                                <Button 
                                                                    types={props.buttons.validate.type} 
                                                                    importance={props.buttons.validate.importance} 
                                                                    icon={props.buttons.validate.hasIcon} 
                                                                    onClick={props.forClose} 
                                                                    disabled={props.isButtonDisabled} 
                                                                    forClose={props.forClose} 
                                                                    allState={props.allState} 
                                                                    text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} 
                                                                    shadowcolor={colors.green.darker} 
                                                                    style={{ padding: '14px 32px' }} 
                                                                />
                                                            </Box>)
                                                    )
                                                    : ''
                                            }
                                        </Box>
                                    </ButtonsContainerCustom>
                                </Grid>
                                : null}
                        </DrawerCustom>
                    );
                case 'none':
                    return (
                        <div>
                            <Typography variant="h5" onClick={() => {
                                props.forClose();
                                if (props.handleCancel)
                                    props.handleCancel();
                            }} component="div">
                            </Typography>
                            {this.topLayoutForm(props)}
                            <Grid container direction="row" justifyContent="flex-end">
                                {
                                    props.dataLayout.formConfig.type !== STEPPER ?
                                        (
                                            props.handlerMutation ?
                                                (
                                                    <Box pt={3}>
                                                        <Button types="Validate" onClick={props.handlerMutation} forClose={props.forClose} allState={props.allState} text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} shadowcolor={colors.green.darker} style={{ padding: '14px 32px' }}>
                                                        </Button>
                                                    </Box>
                                                )
                                                :
                                                (
                                                    <Box pt={3}>
                                                        <Button types="Validate" onClick={props.forClose} forClose={props.forClose} allState={props.allState} text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} shadowcolor={colors.green.darker} style={{ padding: '14px 32px' }}>
                                                        </Button>
                                                    </Box>
                                                )
                                        ) : ''
                                }
                            </Grid>
                        </div>
                    );
                default:
                    return null;
            }
        } else {
            return (
                <DrawerCustom BackdropProps={{ invisible: props?.isSublayout }} hideBackdrop={!props?.isProtected} anchor="right" open={true} drawerwidth={props.drawerWidth ? props.drawerWidth : null} paddingtop={props.noPaddingTop ? props.noPaddingTop : props.isBuilder}>
                    <Grid container justifyContent='center' alignItems='center' style={{ height: "100%" }}>
                        <PageLoader />
                    </Grid>
                </DrawerCustom>
            )
        }
    }

    render() {
        return (
            <div className='layout-wrapper' style={{ padding: 0 }}>
                {this.renderSwitch(this.props)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
  };
};

LayoutBuilder.defaultProps = {
  isSublayout: false,
  isProtected: true,
  showInfoText: true,
  validateButton: true,
  buttons: {
    validate: {
        type: 'info',
        importance: 'primary',
        text: 'Valider',
        isDisabled: false,
    }
  }
};

export default withTranslation()(
  withApollo(connect(mapStateToProps, null)(LayoutBuilder))
);
