import React, { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  HeaderGame,
  PreHeaderGame,
  FooterGame,
  HomeGame,
  HomeCompleteFormGame,
  HomeCompleteFormGameToulouse,
  LoginGame,
  IndexGame,
  ResultsWinGame,
  ResultsLooseGame,
  AlreadyPlayedGame,
  AlreadyPlayedGameSimple,
  EndedGame,
  NotStartedGame,
  ExplanationsGame,
  ExplanationsACVGame,
  ExplanationsBFGame,
  ExplanationsMoreDetailsGame,
  RedirectOn,
  RedirectOn2,
  RedirectOn3,
  RedirectOn4,
  BannerImage,
  BlocBasicGame,
  BlocBasic2Game,
  AmazingBlocGame,
  GameRain,
  GameQuizPersonality,
  GameCodeFinder,
  GameWishlist,
  GameScratchImage,
  RecapWishlist,
  CustomComponent,
  GameClicker,
} from "../../../../shareable/components/assets/game/components";

import { Toolbar } from "../../../../shareable/tools";
import { ItemTypes } from "../../../../shareable/types";

// DND
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { recapWishlist } from "../../../../utils/config/game";

import PlaceholderGame from "../../../../shareable/components/assets/game/components/mini-games/PlaceholderGame";
import GameEmail from "../../../../../email/game/mailBase";

export const getComponent = (
  element,
  index,
  preview,
  Droppable,
  updateConfig,
  deleteComponent,
  inputCallback,
  paramsGeneral,
  apiConfig,
  asset
) => {
  let lang = asset.locales.edges[0]?.node.code;

  switch (element.type) {
    case ItemTypes.HEADER_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.HEADER_GAME}
              id={element.key - 1}
              index={index - 1}
            />
            <HeaderGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </HeaderGame>
            <Droppable
              type={ItemTypes.HEADER_GAME}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.PRE_HEADER_GAME:
      return (
        <Fragment key={element.key}>
          <PreHeaderGame
            id={element.key}
            noSticky={preview}
            preview={preview}
            data={element.data}
            inputCallback={inputCallback}
          >
            <Toolbar
              name={element.data.name}
              onEdit={() => inputCallback(element.key)}
              onDelete={() => deleteComponent(element.key)}
            />
          </PreHeaderGame>
        </Fragment>
      );
    case ItemTypes.FOOTER_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.FOOTER_GAME}
              id={element.key - 1}
              index={index - 1}
            />
            <FooterGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              paramsGeneral={paramsGeneral}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </FooterGame>
            <Droppable
              type={ItemTypes.FOOTER_GAME}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.HOME_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <HomeGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              assetNeedRegistration={apiConfig.assetNeedRegistration}
              isMultiplePlayGame={apiConfig.isMultiplePlayGame}
              assetGameType={apiConfig.assetGameType}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.contentTop?.name}
                  onEdit={() => inputCallback(element.key, "contentTop")}
                />
              }
              toolbarPopup={
                <Toolbar
                  name={element.data.blocks.popupEmail?.name}
                  onEdit={() => inputCallback(element.key, "popupEmail")}
                />
              }
            />
            <Droppable
              type={ItemTypes.HOME_GAME}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.HOME_COMPLETE_FORM_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.HOME_COMPLETE_FORM_GAME}
              id={element.key - 1}
              index={index - 1}
            />
            <HomeCompleteFormGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              assetNeedRegistration={apiConfig.assetNeedRegistration}
              isMultiplePlayGame={apiConfig.isMultiplePlayGame}
              assetGameType={apiConfig.assetGameType}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </HomeCompleteFormGame>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE}
              id={element.key - 1}
              index={index - 1}
            />
            <HomeCompleteFormGameToulouse
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              assetNeedRegistration={apiConfig.assetNeedRegistration}
              isMultiplePlayGame={apiConfig.isMultiplePlayGame}
              assetGameType={apiConfig.assetGameType}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </HomeCompleteFormGameToulouse>
            <Droppable
              type={ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.LOGIN_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <LoginGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.INDEX_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <IndexGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
            >
              {/* <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} /> */}
            </IndexGame>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.RESULTS_WIN_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <ResultsWinGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
              toolbarBlockSocial={
                <Toolbar
                  name={element.data.blocks.socialBlock.name}
                  onEdit={() => inputCallback(element.key, "socialBlock")}
                />
              }
            />
            <Droppable
              type={ItemTypes.RESULTS_WIN_GAME}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.RECAP_WISHLIST:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <RecapWishlist
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              lang={lang ? lang : "fr_FR"}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlockSocial={
                <Toolbar
                  name={element.data.blocks.socialBlock.name}
                  onEdit={() => inputCallback(element.key, "socialBlock")}
                />
              }
            />
            <Droppable
              type={ItemTypes.RECAP_WISHLIST}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.RESULTS_LOOSE_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <ResultsLooseGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
              toolbarBlockSocial={
                <Toolbar
                  name={element.data.blocks.socialBlock.name}
                  onEdit={() => inputCallback(element.key, "socialBlock")}
                />
              }
            />
            <Droppable
              type={ItemTypes.RESULTS_LOOSE_GAME}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.ALREADYPLAYED_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.ALREADYPLAYED_GAME}
              id={element.key - 1}
              index={index - 1}
            />
            <AlreadyPlayedGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
            />
            <Droppable
              type={ItemTypes.ALREADYPLAYED_GAME}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.ALREADYPLAYED_GAME_SIMPLE:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <AlreadyPlayedGameSimple
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
              toolbarBlockSocial={
                <Toolbar
                  name={element.data.blocks.socialBlock.name}
                  onEdit={() => inputCallback(element.key, "socialBlock")}
                />
              }
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.ENDED_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <EndedGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.NOT_STARTED_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <NotStartedGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.REDIRECT_ON_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.REDIRECT_ON_GAME}
              id={element.key - 1}
              index={index - 1}
            />
            <RedirectOn
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </RedirectOn>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.REDIRECT_ON_GAME2:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.REDIRECT_ON_GAME2}
              id={element.key - 1}
              index={index - 1}
            />
            <RedirectOn2
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </RedirectOn2>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.REDIRECT_ON_GAME3:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.REDIRECT_ON_GAME3}
              id={element.key - 1}
              index={index - 1}
            />
            <RedirectOn3
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </RedirectOn3>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.REDIRECT_ON_GAME4:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.REDIRECT_ON_GAME4}
              id={element.key - 1}
              index={index - 1}
            />
            <RedirectOn4
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </RedirectOn4>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.BANNER_IMAGE:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.BANNER_IMAGE}
              id={element.key - 1}
              index={index - 1}
            />
            <BannerImage
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </BannerImage>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.EXPLANATIONS_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <ExplanationsGame
              id={element.key}
              noSticky={preview}
              update={updateConfig}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.explanation.name}
                  onEdit={() => inputCallback(element.key, "explanation")}
                />
              }
            ></ExplanationsGame>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.EXPLANATIONS_ACV_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <ExplanationsACVGame
              id={element.key}
              noSticky={preview}
              update={updateConfig}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.explanation.name}
                  onEdit={() => inputCallback(element.key, "explanation")}
                />
              }
            ></ExplanationsACVGame>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.EXPLANATIONS_BF_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <ExplanationsBFGame
              id={element.key}
              noSticky={preview}
              update={updateConfig}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.explanation.name}
                  onEdit={() => inputCallback(element.key, "explanation")}
                />
              }
            ></ExplanationsBFGame>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <ExplanationsMoreDetailsGame
              id={element.key}
              noSticky={preview}
              update={updateConfig}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.explanation.name}
                  onEdit={() => inputCallback(element.key, "explanation")}
                />
              }
            ></ExplanationsMoreDetailsGame>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.AMAZING_GAME:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
          <Droppable
                type={ItemTypes.AMAZING_GAME}
                id={element.key - 1}
                index={index - 1}
              />
            <AmazingBlocGame
              id={element.key}
              noSticky={preview}
              update={updateConfig}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              lang={lang ? lang : "fr_FR"}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.insideBlock.name}
                  onEdit={() => inputCallback(element.key, "insideBlock")}
                />
              }
              //Droppable={Droppable} idInside={element.data.blocks.insideBlock.id} indexInside={index}
            >
              <Droppable
                type={ItemTypes.AMAZING_GAME_INSIDE}
                id={element.data.blocks.insideBlock.id}
                index={index}
                inside={true}
              />
            </AmazingBlocGame>
            <Droppable
              type={ItemTypes.AMAZING_GAME}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.BASIC_BLOC:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <BlocBasicGame
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </BlocBasicGame>
            <Droppable
              type={ItemTypes.BASIC_BLOC}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.BASIC_BLOC2:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <BlocBasic2Game
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </BlocBasic2Game>
            <Droppable
              type={ItemTypes.BASIC_BLOC}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.GAME_RAIN:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <GameRain
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              spread={true}
              assetGameType={apiConfig.assetGameType}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
            ></GameRain>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.GAME_CLICKER:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <GameClicker
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              spread={true}
              assetGameType={apiConfig.assetGameType}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.popup.name}
                  onEdit={() => inputCallback(element.key, "popup")}
                />
              }
            ></GameClicker>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.GAME_QUIZ_PERSONALITY:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <GameQuizPersonality
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              spread={true}
              assetGameType={apiConfig.assetGameType}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlockStart={
                <Toolbar
                  name={element.data.blocks.popupStart.name}
                  onEdit={() => inputCallback(element.key, "popupStart")}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
            ></GameQuizPersonality>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.GAME_CODE_FINDER:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <GameCodeFinder
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlock={
                <Toolbar
                  name={element.data.blocks.middleBox.name}
                  onEdit={() => inputCallback(element.key, "middleBox")}
                />
              }
            ></GameCodeFinder>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.GAME_SCRATCH_IMAGE:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <GameScratchImage
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onEdit2={() => inputCallback(element.key, "game")}
                  onEdit3={() => inputCallback(element.key, "endGame")}
                  data={element.data}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarPopup={
                <Toolbar
                  name={element.data.blocks.startPopup.name}
                  onEdit={() => inputCallback(element.key, "startPopup")}
                />
              }
            ></GameScratchImage>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.GAME_WISHLIST:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <GameWishlist
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              products={apiConfig.products}
              categories={apiConfig.categories}
              lang={lang ? lang : "fr_FR"}
              spread={true}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
            ></GameWishlist>
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.EMAIL_SHARE_IG_GAME:
    case ItemTypes.EMAIL_CONFIRMATION_IG_GAME:
    case ItemTypes.EMAIL_CONFIRMATION_RANDOM_GAME:
    case ItemTypes.EMAIL_WIN_IG_GAME:
    case ItemTypes.EMAIL_WIN_RANDOM_GAME:
      return <GameEmail data={element.data} id={uuidv4()} />;
    case ItemTypes.CMS_CUSTOM:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <CustomComponent
              id={element.key}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              spread={true}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </CustomComponent>
            <Droppable
              type={ItemTypes.CMS_CUSTOM}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.GAME_PLACEHOLDER:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <PlaceholderGame
              key={element.key}
              toolbarSection={
                <Toolbar
                  name={"Placeholder jeu"}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
            />
            <Droppable
              type={ItemTypes.PLACEHOLDER}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
  }
};
