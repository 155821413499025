import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Grid, Box, CircularProgress } from '@mui/material';
import Typography from '../../../ui/typography/Typography';
import { ROUTE_MEDIAS_CATEGORIES, ROUTE_MEDIAS_LIST, ROUTE_MEDIAS_TAGS } from '../../../../js/constants/route-names';
import { withApollo } from '@apollo/client/react/hoc';
import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../assets/selection.json";
import SliderDashboard from "../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import Image2 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-marketplace.png';
import { v4 as uuidv4 } from 'uuid';
import DataBar from './components/DataBar';

import { GET_MEDIAS_IMAGES } from '../../../../queries/medias';

import { ALLOWED, VIDEOS, IMAGES, CSV, DOCUMENTS, OTHERS } from '../../../../js/constants/medias-types';

import { SNACK } from '../../../../js/constants/action-types';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import styled from 'styled-components';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import Stats from '../../../layouts/Stats/Stats';
import moment from 'moment';


const WrapperStats = styled(Grid)`
    width: 100%; 
    min-height: 105px;
    @media screen and (max-width: 1450px){
        margin: 16px 0; 
    }
`;

const TitleBis = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    width: 100%;
`;
const SubTitleBis = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    font-size: 35px;
    line-height: 39px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 28px;
        line-height: 30px;
    }
    @media screen and (max-width: 1450px){
        font-size: 18px;
        line-height: 19px;
    }
`;
const MoreInfosBis = styled(Typography)`
    margin-top: 4px;
    color: ${colors.black.regular};
    font-size: 12px;
    line-height: 14px;
    width: 100%;
`;
const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor};
    svg circle{
        stroke: ${props => props.statuscolor};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor};
    }
`;
class DashboardMedias extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stockageSize: null,
            warningStorage: false,
            readyMediaList: false,
            medias: [],
            stockageCat: [
                {
                    graphSize: 0,
                    name: 'Fichiers Vidéos',
                    graphColor: colors.blue.darker.hue300,
                    show: false,
                    mimetypes: VIDEOS,
                },
                {
                    graphSize: 0,
                    name: 'Image',
                    graphColor: "#0099DD",
                    show: false,
                    mimetypes: IMAGES,

                },
                {
                    graphSize: 0,
                    name: 'Fichiers CSV',
                    graphColor: "#5FCEFF",
                    show: false,
                    mimetypes: CSV,
                },
                {
                    graphSize: 0,
                    name: 'Autres',
                    graphColor: colors.blue.lighter.hue600,
                    show: false,
                    mimetypes: OTHERS,
                },
            ],
        };
        this.availablesMedias = [
            {
                id: 'image',
                mime: IMAGES,
            },
            {
                id: 'video',
                mime: VIDEOS,
            }
        ]

    }

    getLastUpdate = async () => {
        return new Promise(async (resolve, reject) => {
            await this.props.client.query({
                query: GET_MEDIAS_IMAGES,
                fetchPolicy: 'no-cache',
                variables: {
                    first: 1,
                    nbperpage: 8,
                    types: ALLOWED
                }
            }).then(result => {
                this.setState({ lastMediaList: result.data.mediaObjects.edges, readyMediaList: true });
            }).catch((err)=>{
                console.log(err)
            });
        });
    }

    getStorage = async () => {
        request(`${process.env.REACT_APP_API}/media-objects-size`)
        .then(async (data) => {
            let result = data.response;
            let storages = this.state.stockageCat;
            let storageStats = [];
            let totalStorage = 0
            for (let storage of storages) {
                let size = 0;
                for (let mimetype of storage.mimetypes) {
                    if (typeof result[mimetype] !== 'undefined') {
                        size = size + result[mimetype];
                        totalStorage = totalStorage + result[mimetype]
                    }
                }

                storageStats.push({
                    ...storage,
                    size: size,
                    show: true
                })

            }
            this.setState({
                stockageSize: totalStorage + result.freeSpace,
                stockageCat: storageStats
            }, () => {
                this.handleGetStorageSize();
            })
        }).catch((err)=>{
            console.log(err)
        });
    }

    getNbByType(type) {
        let totalType = 0
        if (this.state.medias?.nbMediasByType) {
            for (const [key, value] of Object.entries(this.state.medias?.nbMediasByType)) {
                switch (type) {
                    case 'image':
                        if (IMAGES.includes(key)) {
                            totalType += value
                        }
                        break;
                    case 'video':
                        if (VIDEOS.includes(key)) {
                            totalType += value
                        }
                        break;
                    case 'document':
                        if (DOCUMENTS.concat(OTHERS).includes(key)) {
                            totalType += value
                        }
                        break;

                    default:
                        break;
                }
            }
            return totalType
        } else {
            return null
        }
    }

    handleGetStorageSize() {
        let globalStockage = this.state.stockageSize;
        let files = this.state.stockageCat;
        let newList = [];
        let remainginSize = this.state.stockageSize;
        for (let file of files) {
            let usedStockage = (100 * file.size) / globalStockage;
            file['graphSize'] = usedStockage;
            newList.push(file);
            remainginSize = remainginSize - file.size;
        }
        let warning = ((remainginSize / globalStockage) <= 0.1) ? true : false;
        remainginSize = Math.round(remainginSize * 100 + Number.EPSILON) / 100;
        this.setState({
            stockageCat: newList,
            remainginSize: remainginSize??null,
            warningStorage: warning
        });
    }

    handleGetInfos = async () => {
        this.getStorage();
    }

    initDashboard = () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/media`, 'get', false)
        .then((data) => {
            if (data.success) {
                this.setState({
                    medias: data.datas
                });
            }
        }).catch((err)=>{
            console.log(err)
        });
    }

    componentDidMount() {
        checkRouting(this.props);
        this.getLastUpdate();
        this.initDashboard();
        this.handleGetInfos();
    }

    render() {
        const sliderData = [
            {
                title: this.props.t("medias.dashboard.whyCreateMedia"),
                text: this.props.t("medias.dashboard.mediaInfos"),
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t("medias.dashboard.createMedia"),
                        link: `${ROUTE_MEDIAS_LIST}?formOpen=true`
                    },
                ]
            },
            {
                title: this.props.t("products.dashboard.actionslider.categorytitle"),
                text: this.props.t("medias.dashboard.categoriesInfos"),
                image: Image2,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t("products.dashboard.actionslider.categorybutton"),
                        link: `${ROUTE_MEDIAS_CATEGORIES}?formOpen=true`
                    },
                ]
            },
            {
                title: this.props.t("medias.dashboard.whyCreateTag"),
                text: this.props.t("medias.dashboard.tagInfos"),
                image: Image2,
                id: uuidv4(),
                buttons: [
                    {
                        text: this.props.t("medias.dashboard.createTag"),
                        link: `${ROUTE_MEDIAS_TAGS}?formOpen=true`
                    },
                ]
            }
        ];

        let stats = [
            {
                'icon': 'ico_picto_img',
                'data': this.getNbByType('image'),
                'dataColor': colors.blue.darker.hue300,
                'subtitle': this.props.t("medias.dashboard.lastdatas.images"),
            },
            {
                'icon': 'ico_videoplayer',
                'data': this.getNbByType('video'),
                'dataColor': colors.blue.darker.hue300,
                'subtitle': this.props.t("medias.dashboard.lastdatas.videos"),
            },
            {
                'icon': 'picto-connector-content',
                'data': this.getNbByType('document'),
                'dataColor': colors.blue.darker.hue300,
                'subtitle': this.props.t("medias.dashboard.lastdatas.files"),
            },
        ];
        let lastDatas = [
            {
                'icon': 'picto-media',
                'data': this.state.medias.nbContents,
                'dataColor': colors.blue.darker.hue300,
                'subtitle': `${this.state.medias.nbContents ? this.state.medias.nbContents.toLocaleString() : 0} ` + (this.state.medias.nbContents > 1 ? this.props.t("medias.dashboard.lastdatas.medias") : this.props.t("medias.dashboard.lastdatas.media")),
                'moreInfos': `Dernier ajout: ${this.state.medias.mediaLastAddition ? moment(this.state.medias.mediaLastAddition.date).format('DD/MM/YYYY à HH:mm') : 'N/A'}`
            },
            {
                'icon': 'picto-produit',
                'subtitle': `${this.state.medias.nbCategories ? this.state.medias.nbCategories.toLocaleString() : 0} ` + this.props.t("medias.dashboard.lastdatas.categories"),
                'data': this.state.medias.nbCategories,
                'dataColor': colors.blue.darker.hue300,
                'moreInfos': `Dernier ajout: ${this.state.medias.categoryLastAddition ? moment(this.state.medias.categoryLastAddition.date).format('DD/MM/YYYY à HH:mm') : 'N/A'}`
            },
        ]
        return (
            <div>
                <TopPanel
                    icomoon="icon-dashboard"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("spread_builder.dashboard.welcomeOnDashboardMedias")}
                    subtitle="Gestion de vos médias (création / modification / suppression)"
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    hasBorder={true}
                />
                <Grid container direction="row" justifyContent="center" spacing={0}>
                    <Grid item xs={12}>
                        <WrapperStats container direction="row" alignItems="center" justifyContent='space-between'>
                            {/* Circle stats */}
                            <Grid item md={7} sm={8}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    {
                                        lastDatas.map((data, index) => {
                                            return (
                                                <Grid item xs={6} key={`LastDatas${index}`} style={{ padding: "0px 10px 32px 10px" }}>
                                                    {
                                                        data.data || data.data === 0 ?
                                                            <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                                                                <Grid item style={{ maxWidth: 80, width: "100%" }}>
                                                                    {
                                                                        data.data || data.data === 0 ?
                                                                            <BoxCustom position="relative" display="flex" justifyContent={"center"} statuscolor={data.dataColor} width="100%">
                                                                                <CircularProgress variant="determinate" thickness={2.5} value={data.data} style={{ width: "100%", height: "auto", position: "absolute", left: 0, top: 0, zIndex: 2 }} />
                                                                                <CircularProgress
                                                                                    variant="determinate"
                                                                                    value={100}
                                                                                    disableShrink
                                                                                    style={{ width: "100%", height: "auto" }}
                                                                                    thickness={2.5}
                                                                                />
                                                                                <Box
                                                                                    top={0}
                                                                                    left={0}
                                                                                    bottom={0}
                                                                                    right={0}
                                                                                    position="absolute"
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center"
                                                                                >
                                                                                    {
                                                                                        data.icon ?
                                                                                            <IcomoonReact iconSet={iconSet} color={data.dataColor} size={this.props.windowWidth > 1200 ? 35 : 25} icon={data.icon} />
                                                                                            :
                                                                                            <Typography variant="body1" color="textSecondary" style={{ fontWeight: "bold", fontSize: 25 }}>
                                                                                                {data.data}%
                                                                                            </Typography>
                                                                                    }
                                                                                </Box>
                                                                            </BoxCustom>
                                                                            // <Typography variant="h2" colortext={colors.blue.lighter.hue150} style={{fontSize: this.props.windowWidth > 1200 ? 23 : 16}}>{lastDatas[0].data}</Typography>
                                                                            : <CircularProgress size={"18px"} color={"inherit"} />
                                                                    }
                                                                </Grid>
                                                                <Grid item style={{ flex: 1, paddingLeft: 22 }}>
                                                                    <Grid container direction="column" alignItems="center">
                                                                        <TitleBis variant="body1">{this.props.t(data.title)}</TitleBis>
                                                                        <SubTitleBis variant="h5">{this.props.t(data.subtitle)}</SubTitleBis>
                                                                        <MoreInfosBis variant="body2">{this.props.t(data.moreInfos)} <strong>{data.moreInfosData}</strong></MoreInfosBis>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            :
                                                            <Grid container alignItems='center' justifyContent="center">
                                                                <CircularProgress style={{ width: "100%", maxWidth: 80, height: "auto" }} color={"inherit"} />
                                                            </Grid>
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                            {/* Mini texts */}
                            <Grid item md={5} sm={4} style={{
                                display: 'flex',
                                alignSelf: 'flex-start'
                            }}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item style={{ display: 'flex', alignItems: 'center', width: "100%" }}>
                                        <Grid container direction='column'>
                                            <Box mb={'10px'}>
                                                <Grid item>
                                                    <Typography variant="h4" style={{ lineHeight: '18px', marginBottom: '3px' }}><strong>Nombre de médias par type</strong></Typography>
                                                    <Typography colortext={colors.black.regular} variant="body2">Dernière mise à jour : {moment().format('DD/MM/YYYY à HH:mm')}</Typography>
                                                </Grid>
                                            </Box>
                                            <Grid item style={{ marginTop: 16 }}>
                                                <Grid container direction="row" style={{ gap: this.props.windowWidth >= 1250 ? '27px' : '5px' }}>
                                                    {stats.map((stat, index) =>
                                                    (
                                                        <Grid container xs={12} md={'auto'} style={{ width: 'auto' }} key={`Stat${index}`}>
                                                            <Stats windowWidth={this.props.windowWidth} stat={stat} subtitlebold={true} />
                                                        </Grid>
                                                    )
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </WrapperStats>
                    </Grid>
                    <Grid item xl={sliderData.length > 1 ? 12 : 6} lg={sliderData.length > 1 ? 12 : 8} md={sliderData.length > 1 ? 12 : 8} xs={12} style={{ overflow: 'hidden' }}>
                        <AccordionCustom forcedExpanded={true} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{this.props.t('products.dashboard.actionslider.actionslider')}</Typography>}>
                            <SliderDashboard sliderData={sliderData} windowWidth={this.props.windowWidth} />
                        </AccordionCustom>
                    </Grid>
                    <Grid item xs={12} style={{ overflow: 'hidden', marginTop: 32 }}>
                        <AccordionCustom forcedExpanded={false} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{this.props.t("medias.dashboard.storage")}</Typography>}>
                            <DataBar datas={this.state.stockageCat} remaining={this.state.remainginSize} warning={this.state.warningStorage}></DataBar>
                        </AccordionCustom>
                    </Grid>

                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // startLoading: () => dispatch({ type: START_LOADING }),
        // stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardMedias))));
