import React from "react";
import { Tooltip, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import colors from '../../../../../../config/theme/colors';
import styled from 'styled-components'

const TypographyCustom = styled(Typography)`
    font-weight: ${props => props.fontweight ? props.fontweight : 'normal'};
    color: ${colors.black.regular};
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: ${props => props?.limitText ? "380px" : "none"};
`

function CellText(props){
    let value = props.attribute?.attribute.attributeType.input === "textarea"
        ? props.value
        : props.attribute?.attribute.attributeType.input === "select" ?
            props.attribute.attributeOption.translation.translationDatas?.edges?.[0].node.value ?? props.attribute.attributeOption.identifier
            : props.value === 'false' 
                ? 'Non' 
                : props.value === 'true' 
                    ? 'Oui' 
                    : props.value; 

    const _renderContent = () => {
        return(
            props.attribute?.attribute.attributeType.input === "textarea" ?
                <TypographyCustom variant='body1' fontweight={props.fontWeight} limitText={props.column?.limitText} dangerouslySetInnerHTML={{ __html: value }}/>
            : 
                <TypographyCustom variant='body1' fontweight={props.fontWeight} limitText={props.column?.limitText}>{value || "N/A"}</TypographyCustom>
        )
    }
    return(
        <TableCell 
            style={{
                maxWidth : props?.maxWidth ? props?.maxWidth :'auto',
                width : props.width ? props.width :'auto',
                fontWeight : props.fontWeight ? props.fontWeight : 'normal',
                padding : '4px 16px'
            }}
        >
            {
                props.column?.hasTooltip ?
                    <Tooltip title={value} arrow>
                        {_renderContent()}
                    </Tooltip>
                :
                    _renderContent()
            }

        </TableCell>
    )
}
export default CellText;