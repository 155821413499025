import React, { useEffect } from 'react';
import { Grid, Box, RadioGroup, Radio } from '@mui/material';
import TypographyCustom from '../../ui/typography/Typography';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import { eventService } from '../../../js/services/event.service';
import CardCatalog from '../../layouts/Card/cardContent/CardCatalog';
import ButtonCustom from '../../ui/button/Button';
import { ROUTE_CATALOGS_LIST, ROUTE_SALES_ESHOP, ROUTE_SALES_RETAILERS, ROUTE_CRM_OFFERS_PROJECTS } from '../../../js/constants/route-names';
import CardAssets from '../../layouts/Card/cardContent/CardAssets';
import CardSetup from '../../layouts/Card/cardContent/CardSetup';
import SearchBar from '../../ui/search/SearchBar';
import { withRouter } from 'react-router';
import Typography from '../../ui/typography/Typography';
import imgNotFound from '../../../assets/images/not-found.png';
import { CursorPagination } from '../../../js/utils/pagination';

const ClickableLabel = styled.label `
    > div {
        border: 2px solid transparent;
        &:hover{
            border: 2px solid #0273A5;
            cursor: pointer;
        }
    }
    &.active{
        > div {
            border: 2px solid #0273A5;
        }
    }
    &.error{
        > div {
            border: 2px solid #f44336;
        }
    }
`;

const GridCustom = styled(Grid) `
    padding-bottom: 16px;
    background: rgb(250, 251, 251);
`;
const GridCatalog = styled(Grid) `
    background: ${colors.white};
    height: 100%;
    border-radius: 4px;
`;

const RadioCustom = styled.span `
    border: 1px solid ${colors.grey.regular};
    width: 20px;
    height: 20px;
    display: block;
    margin: auto;
    position: relative;
    &.active{
        &:after{
            content: '';
            width: 14px;
            height: 14px;
            position: absolute;
            top: 2px;
            left: 2px;
            background: ${colors.blue.darker.hue300}
        }
    }
`;
const DescriptionRadio = styled(Typography) `
    p{
        margin: 0;
        margin-top: 4px;
    }
`;

function RadioCards(props) {
    let elementSelected = props.allState[props.optionsInputs.stateName];

    const [seeError, setSeeError]                   = React.useState(false);
    const [errorMessage, setErrorMessage]           = React.useState(null);
    const goTo = route => props.history.push(route);
    const checkError = (value = null) => {
        let val     = value === null ? elementSelected : value;
        let error   = false;
        let message = null;
        let type = null;
        if (!val && props.optionsInputs.required) {
            switch (props.optionsInputs.paginationType){
                case 'catalog':
                    type = 'un catalogue'
                break;
                case 'asset':
                    type = 'un asset'
                break
                case 'retailer':
                    type = 'un retailer'
                break
                case 'project':
                    type = 'un projet'
                break
                default:
                    return null
            }
            // required
            error   = true;
            message = `Veuillez sélectionner ${type}`;
        }
        if (val){
            setSeeError(false);
        }

        setErrorMessage(error ? message : null);  
        if (props.errorCallback){  
            props.errorCallback(props.optionsInputs.stateName,error);
        }
    };

    useEffect(() => {
        if (!props.stepsInfo.isOptionnal){
            checkError(); 

            let subscription = eventService.get().subscribe((data) => {
                setSeeError(true);
                if (data && props.optionsInputs.stateName === data.stateName) {
                    setErrorMessage(data.errorMessage);
                    setSeeError(true)
                }
            });

            return () => subscription.unsubscribe();
        }
    }, [elementSelected, props.optionsInputs.stateName,props.optionsInputs.paginationType, props.stepsInfo.isOptionnal]); 

    const renderTypeCard = (type, option, elementSelected) => {
        switch (type){
            case 'catalog':
                return <CardCatalog data={option.values} configCard={option.smallCard} openForm={true} inputCard={true}/>
            case 'asset':
                return <CardAssets asset={option.values} inputCard={true} configCard={option.smallCard}/>
            case 'retailer':
                return <CardSetup type={option.values} inputCard={true}/>
            case 'project':{
                let image = "";
                try{
                    image = `${process.env.REACT_APP_MEDIAS}/${option.values.node.media.filePath}`;
                }catch(e){
                    image = imgNotFound;
                }
                return(
                    <Grid container direction={'row'} alignItems="center" style={{padding: 12}}>
                        <Box width={72} height={72} style={{display: 'flex', alignItems:'center'}}>
                            <img src={image} width={"100%"} alt=''/>
                        </Box>
                        <Box pl={2}>
                            <Typography variant={'h4'} colortext={elementSelected === option.values.node.id ? colors.blue.darker.hue300 : colors.grey.lighter.hue600}>{option.values.node.name}</Typography>
                            <DescriptionRadio variant={'body2'} colortext={elementSelected === option.values.node.id ? colors.blue.darker.hue300 : colors.black.regular} dangerouslySetInnerHTML={{ __html: option.values.node.description}} />
                        </Box>
                    </Grid>
                )
            }
            default:
                return null 
        }
    }
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const renderNoData = (type) => {
        let text = null;
        let routeButton = null;
        let textButton = null;
        switch (type){
            case 'catalog':
                text = 'Pas de catalogue disponible, cliquez sur ce bouton pour être redirigé vers la création de catalogue.'
                routeButton = ROUTE_CATALOGS_LIST
                textButton = 'Créer un catalogue'
            break;
            case 'asset':
                text = 'Pas d\'asset disponible, cliquez sur ce bouton pour être redirigé vers la création d\'asset.'
                routeButton = ROUTE_SALES_ESHOP
                textButton = 'Créer un asset'
            break;
            case 'retailer':
                text = 'Pas de retailer disponible, cliquez sur ce bouton pour être redirigé vers la création de retailer.'
                routeButton = ROUTE_SALES_RETAILERS
                textButton = 'Créer un retailer'
            break;
            case 'project':
                text = 'Pas de projets disponible, cliquez sur ce bouton pour être redirigé vers la création de projets.'
                routeButton = ROUTE_CRM_OFFERS_PROJECTS
                textButton = 'Créer un projet'
            break;
            default:
                return null
        }
        return(
            <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
                <TypographyCustom variant={'h4'} colortext={colors.red.regular} style={{width: '60%', textAlign: 'center', marginBottom: 8, marginTop: 16}}>
                    {text}
                </TypographyCustom>
                <ButtonCustom bgcolor={colors.blue.darker.hue300} shadowcolor={colors.blue.darker.hue300} text={textButton} onClick={() => goTo(routeButton)} className="button"/>
            </Grid>
        )
    }
    return (
        <GridCustom item xs={12}>
            {
                !props.optionsInputs.isEmpty ?
                <div>
                    {
                        props.optionsInputs.allInfo ? null :
                            props.optionsInputs.paginationType === 'asset' ?
                                null 
                            :   props.optionsInputs.buttonSearch ?  
                                    <Grid container direction="row" alignItems="center" style={{padding: '16px 20px'}}>
                                        <SearchBar value={props.allState.searchValue} onChange={props.searchHandler} type={props.optionsInputs.paginationType} style={{padding: '0', width:'auto', flex: 1, marginRight: 16}}/>
                                        <ButtonCustom text={props.optionsInputs.buttonText} onClick={() => goTo(props.optionsInputs.buttonRoute)} contentStyle={{padding: '4px 0 !important'}}/>
                                    </Grid>
                                :   
                                    <SearchBar value={props.allState.searchValue} onChange={props.searchHandler} type={props.optionsInputs.paginationType} style={{padding: '16px 8px'}}/>
                    }
                    <RadioGroup
                        id={props.optionsInputs.stateName}
                        aria-label={props.optionsInputs.stateName}
                        variant="outlined"
                        color="secondary"
                        value={props.value ? props.value : ''}
                        onChange={evt => {
                            if (!props.stepsInfo.isOptionnal){
                                setSeeError(true)
                                checkError(evt.target.value)
                            }
                            return(props.stateCallback(props.optionsInputs.stateName, evt));
                        }}
                        style={{paddingTop: props.optionsInputs.inline ? 0 : 25}}
                        disabled={props.optionsInputs.disabled ? true : false}
                    >
                        <Grid container direction="row" justifyContent={'center'} style={{gap: 16}}>
                            {props.optionsInputs.value.map((option,index) => {
                                return(
                                    <Grid key={`RadioCatalog${option.values.node.id}${index}`} item style={{marginTop: props.optionsInputs.inline ? 16 : 0, width: `calc(${props.optionsInputs.inline ? '100%' : '(100% / 2) - 32px'})`}}>
                                        <ClickableLabel htmlFor={`RadioCatalog${option.values.node.id}-${index}`} className={!seeError ? elementSelected === option.values.node.id ? 'active' : null :'error'}>
                                            <GridCatalog container alignItems={'center'} justifyContent={'flex-start'} direction={props.optionsInputs.inline ? "row-reverse" : "column"} style={{margin: props.optionsInputs.inline ? '0 20px' : null, width: props.optionsInputs.inline ? 'auto' : null, boxShadow: props.optionsInputs.inline ? "0px 1px 10px #1622421A" : "none"}}>
                                                <Grid container direction={'row'}>
                                                    <Box width={'100%'}>
                                                        {renderTypeCard(props.optionsInputs.paginationType, option, elementSelected)}
                                                    </Box>
                                                </Grid>
                                                {
                                                    props.optionsInputs.inline ? 
                                                    null :
                                                    <Box pt={2} pb={2} width={props.optionsInputs.inline ? '20%' : '100%'} style={{textAlign: 'center', display: props.optionsInputs.inline ? 'inline-block' : 'block'}}>
                                                        <RadioCustom className={elementSelected === option.values.node.id  ? 'active' : null } />
                                                    </Box>
                                                }
                                                
                                                {
                                                    !props.optionsInputs.disabled ?
                                                    <Box mt={2} textAlign={'center'}>
                                                        <Radio color="primary" value={option.values.node.id} id={`RadioCatalog${option.values.node.id}-${index}`} disabled={props.optionsInputs.disabled ? true : false} style={{display: 'none'}}/>
                                                    </Box> : ''
                                                }
                                            </GridCatalog>
                                        </ClickableLabel>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </RadioGroup>
                    {
                        !seeError ? null : 
                        <span className="error-label" style={{marginLeft: 10}}>{ errorMessage }</span>
                    }
                    {
                        props.paginationInfo ?
                            props.paginationInfo[props.optionsInputs.paginationType]?.count > 1 ? 
                            (
                                // <PaginationCustom onChange={(event, page) => {props.paginationInfo.changePage(page, props.optionsInputs.paginationType)}} page={props.paginationInfo[props.optionsInputs.paginationType].page} count={props.paginationInfo[props.optionsInputs.paginationType].count} color="primary" />
                                <div style={{display: 'flex', justifyContent:'center', padding: '0 26px'}}>
                                    <CursorPagination
                                        rowLabel = {`${capitalize(props.optionsInputs.paginationType)} par page`}
                                        pagination = {props.paginationInfo[props.optionsInputs.paginationType]}
                                        type = "table"
                                        inFormType={props.optionsInputs.paginationType}
                                        changePageCallback = {props.paginationInfo.changePage}
                                        // changePerPageCallback = {this.changePerPage}
                                        // showPerPage = {perPageOptions[views.current]?.length > 0}
                                        // perPageOptions = {perPageOptions[views.current]}
                                        typeResult = {'edges'}
                                    />
                                </div>
                            )
                            :
                            null
                        :null
                    }
                </div>
                : 
                    renderNoData(props.optionsInputs.paginationType)
            }
        </GridCustom>
    );
}
export default withRouter(RadioCards)