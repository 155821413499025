import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import TopPanel from "@layouts/TopPanel/TopPanel";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import colors from "@config/theme/colors.js";
import OurStepper from "@ui/stepper/OurStepper";
import StepPanel from "@ui/stepper/components/StepPanel";
import SelectInput from "@ui/form/inputs/SelectInput";
import { getCustomAttributes } from "@utils/products/product.utils.js";
import { getTranslationLabel } from "@utils/translation.utils.js";
import TextAddElement from "@ui/typography/TextAddElement";
import FinalStep from "@ui/stepper/components/FinalStep";
import RowProductVariantForm from "../components/RowProductVariantForm";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";

const Container = styled.div`
  width: 100%;
  height: maxContent;
  overflowy: hidden;
`;

const FormContainer = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: ${colors.background.white.primary};
`;

const ProductVariantForm = ({ saveProductVariants, ...props }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState({
    attributes: [],
    productVariants: [],
  });

  const getAttributes = () => {
    const customAttributes = getCustomAttributes(
      props.attributes.product.attributes
    );
    const defaultAttributeGroup = props.attributeGroups?.find(
      (e) => e.node.identifier === "default"
    )?.node;
    let inputs = [];

    inputs = defaultAttributeGroup.attributes.edges
      .filter((e) => e.node.status)
      .concat(customAttributes.filter((e) => e.node.status));

    return inputs;
  };

  const getInputs = () => {
    const attributesSelected = form.attributes.map((id) =>
      getAttributes().find((e) => e.node.id === id)
    );

    const inputs = attributesSelected.map((attribute) => ({
      options: attribute.node.attributeOptions.edges.map((option) => ({
        label: getTranslationLabel(option.node.translation),
        value: option.node.id,
      })),
      identifier: attribute.node.identifier,
      label: getTranslationLabel(attribute.node.translation),
      inputType: attribute.node.attributeType.input,
      value: "",
    }));

    return inputs;
  };

  const handleChangeProductVariant = (val, id, key = "inputs") => {
    setForm((prevState) => ({
      ...prevState,
      productVariants: prevState.productVariants.map((productVariant) => {
        if (productVariant.tempoId && productVariant.tempoId === id) {
          return {
            ...productVariant,
            [key]: val,
          };
        }
        return productVariant;
      }),
    }));
  };

  useEffect(() => {
    const tabElement = document.querySelector("#vertical-steppanel-1");
    tabElement.scrollTop = tabElement.scrollHeight;
  }, [form.productVariants]);

  return (
    <Container>
      <TopPanel
        inForm={true}
        windowWidth={props.windowWidth}
        title={props.t('productVariants.form.title')}
        subtitle={
          props.t('productVariants.form.helper.helperTitleCreate')
        }
      />
      <OurStepper
        steps={[
          {
            label: props.t('productVariants.form.steps.attributesChoice'),
            show: true,
            completed: form.attributes.length > 0,
            required: true,
          },
          {
            label: props.t('productVariants.form.steps.variantes'),
            show: true,
            completed: form.productVariants.length > 0,
            required: true,
          },
          {
            label: "Validation",
            show: false,
            completed: true,
            required: true,
          },
        ]}
        helper={props.t('productVariants.form.helper.requiredFields')}
        activeStep={activeStep}
        onChange={(newStep) => setActiveStep(newStep)}
      >
        <StepPanel
          style={{
            height: "calc(100vh - 275px)",
            overflowY: "auto",
            border: `1px solid ${colors.grey.lighter.hue700}`,
          }}
          value={activeStep}
          index={0}
        >
          <FormContainer>
            <SelectInput
              label={props.t('inputs.attributes')}
              value={form.attributes}
              options={getAttributes().map((attribute) => ({
                label: getTranslationLabel(attribute.node.translation),
                value: attribute.node.id,
              }))}
              multiple
              helper={props.t('productVariants.form.helper.attributesChoice')}
              rules={{ required: true }}
              sx={{ width: "100%" }}
              onChange={(e) => {
                setForm((prevState) => ({
                  ...prevState,
                  attributes: e,
                }));
              }}
              onDelete={(e) => {
                setForm((prevState) => ({
                  ...prevState,
                  attributes: prevState.attributes.filter(
                    (attribute) => attribute !== e
                  ),
                }));
              }}
            />
          </FormContainer>
        </StepPanel>
        <StepPanel
          style={{
            height: "calc(100vh - 275px)",
            overflowY: "auto",
            border: `1px solid ${colors.grey.lighter.hue700}`,
          }}
          value={activeStep}
          index={1}
        >
          <FormContainer>
            <TextAddElement
              label={props.t('productVariants.form.addVariant')}
              onClick={() => {
                setForm((prevState) => ({
                  ...prevState,
                  productVariants: [
                    ...prevState.productVariants,
                    { tempoId: uuidv4() },
                  ],
                }));
              }}
            />

            {form.productVariants.map((productVariant, index) => {
              return (
                <RowProductVariantForm
                  key={`RowVariant${index}`}
                  inputs={getInputs()}
                  index={productVariant.tempoId}
                  handleChangeVariant={(val, id) =>
                    handleChangeProductVariant(val, id)
                  }
                  handleChangeImage={(val, id) => {
                    handleChangeProductVariant(val, id, "image");
                  }}
                  handleDeleteVariant={(id) => {
                    setForm((prevState) => ({
                      ...prevState,
                      productVariants: prevState.productVariants.filter(
                        (productVariant) =>
                          productVariant.tempoId !== id &&
                          productVariant.tempoId
                      ),
                    }));
                  }}
                />
              );
            })}
          </FormContainer>
        </StepPanel>
        <StepPanel
          style={{
            height: "calc(100vh - 275px)",
            overflowY: "auto",
            border: `1px solid ${colors.grey.lighter.hue700}`,
          }}
          value={activeStep}
          index={2}
        >
          <FormContainer>
            <FinalStep
              icon="check"
              title={props.t('productVariants.form.variantReady')}
              text={props.t('productVariants.form.canSave')}
              action={{
                text: props.t('actions.save'),
                onClick: () =>
                  saveProductVariants(form.productVariants, form.attributes),
              }}
            />
          </FormContainer>
        </StepPanel>
      </OurStepper>
    </Container>
  );
};

ProductVariantForm.propTypes = {
  saveProductVariants: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
    attributeGroups: state.attributeGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withApollo(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductVariantForm))
  )
);
