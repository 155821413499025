import React, { useState } from "react";
import { useDrag } from "react-dnd";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import LayersIcon from "@mui/icons-material/Layers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import colors from "../../../../../config/theme/colors";
import {getTypeName} from './getTypeName';
import {getTypeIcon} from './getTypeIcon';
import './Draggable.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 10,
    width: "100%",
    height: "auto",
    cursor: "move",
    flexDirection: "row",
    paddingTop: 10,
    borderRadius: 4,
    boxShadow: "0px 0px 14px #00000012",
  },
}));

export default function Draggable(props) {
  let { type, icon: ComponentIcon, block } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [{ isDragging }, drag] = useDrag({
    item: { type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  if (block) {
    return (
      <div
        ref={drag}
        className="panel-drag"
        style={{
          border: isDragging
            ? `1px dashed ${colors.black.regular}`
            : "1px solid white",
        }}
      >
        <ComponentIcon />
        {getTypeName(type)}
      </div>
    );
  } else {
    return (
      <Paper
        elevation={3}
        className={classes.root}
        ref={drag}
        style={{
          opacity: 1,
          border: isDragging
            ? `1px dashed ${colors.black.regular}`
            : "1px solid white",
          position: "relative",
          paddingBottom: props.children && open ? 0 : 10,
        }}
      >
        {props.children && !isDragging ? (
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => setOpen(!open)}
          >
            <LayersIcon style={{ fill: colors.blue.lighter.hue150 }} />{" "}
            <span
              style={{ color: colors.blue.lighter.hue150, fontWeight: "bold" }}
            >
              {props.children.length ? props.children.length : 1}
            </span>
          </div>
        ) : (
          <div
            style={{ position: "absolute", top: 10, right: 10, display: "flex" }}
          >
            <MoreVertIcon style={{ fill: colors.blue.lighter.hue600 }} />
          </div>
        )}
  
        {isDragging ? null : getTypeIcon(type, 24)}
  
        <span
          style={{
            fontSize: 14,
            fontWeight: "bold",
            opacity: isDragging ? 0 : 1,
            padding: "0 10px",
            maxWidth: "80%",
          }}
        >
          {getTypeName(type)}
        </span>
  
        {props.children && open ? (
          <div
            style={{
              padding: open ? "10px 0 0" : "0 20px",
              width: "calc(100% + 80px)",
              display: "block",
              overflow: "hidden",
              height: open ? "auto" : 0,
            }}
          >
            <div
              style={{
                // width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                overflow: "auto",
                padding: 10,
                width: "calc(100% - 20px)",
              }}
            >
              {props.children}
            </div>
          </div>
        ) : null}
      </Paper>
    );
  }
}
