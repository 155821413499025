import React from "react";
import PropTypes from "prop-types";

import TextInput from "@/components/ui/form/inputs/TextInput";
import TextareaInput from "@/components/ui/form/inputs/TextareaInput";
import SelectInput from "@/components/ui/form/inputs/SelectInput";
// import SwitchInput from "@/components/ui/form/inputs/SwitchInput";
import RadioGroupInput from "@/components/ui/form/inputs/RadioGroupInput";
import OldInputBuilder from "@/components/ui/form/InputBuilder.js";
import OurDatePicker from "@/components/ui/form/inputs/OurDatePicker";
import NumberInput from "@/components/ui/form/inputs/NumberInput";

const InputBuilder = ({ type, ...props }) => {
  switch (type) {
    case "text":
    case "link":
    case "email":
      return <TextInput {...props} />;
    case "textarea":
      return <TextareaInput {...props} />;
    case "select":
      return <SelectInput {...props} />;
    // case "switch":
    //   return <SwitchInput {...props} />;
    case "radio":
      return <RadioGroupInput {...props} />;
    case "wysiwyg":
      return (
        <OldInputBuilder
          {...props}
          input={{ type: "textarea", ...props.input }}
        />
      );
    case "mediaPicker":
    case "image":
    case "file":
      return (
        <OldInputBuilder
          {...props}
          input={{
            type: "mediaPicker",
            handleMediaPicker: props.onChange,
            ...props.input,
          }}
        />
      );
    case "date":
      return (
        <OurDatePicker
          {...props}
        />
      );
    case "decimal":
    case "number":
      return <NumberInput {...props} />;
    default:
      return <TextInput {...props} />;
  }
};

InputBuilder.propTypes = {
  type: PropTypes.string.isRequired,
};

export default InputBuilder;
