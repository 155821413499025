import OurButton from "@ui/button/Button.js";
import {
  StepLabelCustom,
  StepperCustom,
} from "@ui/stepper/StepperCustom";
import colors from "@config/theme/colors";
import DoneIcon from "@mui/icons-material/Done";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "auto 1fr",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actions_section: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const OurStepper = ({ children, steps, helper, onChange, ...props }) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (props.activeStep !== undefined) {
      setActiveStep(props.activeStep);
    }
  }, [props.activeStep]);

  useEffect(() => {
    if (onChange) {
      onChange(activeStep);
    }
  }, [activeStep]);

  const classes = useStyles();

  const handleNext = () => {
    if (steps[activeStep].completed) {
      onChange(activeStep + 1);
    }
  };

  const handleBack = () => {
    onChange(activeStep - 1);
  };

  return (
    <div className={classes.root}>
      <StepperCustom
        className={
          steps.length <= 1
            ? "onlyStep"
            : activeStep === steps.length - 1
            ? "endSteps"
            : null
        }
        activeStep={activeStep}
      >
        {steps.map(
          (step, index) =>
            step.show && (
              <Step
                className={activeStep === index && "active"}
                key={step.label}
              >
                <StepLabelCustom nbSteps={steps.length}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: 4, fontSize: 17 }}>
                      {step.completed && activeStep > index ? (
                        <DoneIcon fontSize="small" />
                      ) : (
                        index + 1
                      )}
                      -
                    </span>
                    {step.label}
                  </span>
                </StepLabelCustom>
              </Step>
            )
        )}
      </StepperCustom>
      <div>
        {children ?? steps[activeStep]?.component}

        {activeStep < steps.length - 1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: helper ? "space-between" : "flex-end",
              pt: 2,
            }}
          >
            {helper && (
              <Typography
                variant="body2"
                sx={{
                  color: colors.grey.regular,
                  fontSize: "14px",
                  lineHeight: "18px",
                  fontStyle: "italic",
                  fontWeight: "normal",
                  textAlign: "left",
                }}
              >
                {helper}
              </Typography>
            )}

            <Box>
              {activeStep > 0 && (
                <OurButton
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  bgcolor={"transparent"}
                  color={colors.grey.regular}
                  bgcolorhover={"transparent"}
                  arrow="left"
                >
                  Étape précédente
                </OurButton>
              )}
              <OurButton
                disabled={
                  steps[activeStep].required && !steps[activeStep].completed
                }
                onClick={handleNext}
                arrow="right"
              >
                Étape suivante
              </OurButton>
            </Box>
          </Box>
        )}
      </div>
    </div>
  );
};

OurStepper.propTypes = {
  children: PropTypes.node,
  helper: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      component: PropTypes.node,
      show: PropTypes.bool,
      required: PropTypes.bool,
      completed: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func,
};

export default OurStepper;
