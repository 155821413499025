import React from "react";
import Link from 'next/link';

const LinkCustom = (props) => {
  if (props.spread) {
      return <>
          { props.children }
      </>
  } else {
      return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
  }
}

const MobileNavMenu = ({ menu, categories, spread = false }) => {
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        { menu.map((item, i) => {
          if (item.type === 'link') {
            return item.value === '/' || item.value === '/contact' ? (
              <li key={i}>
                <LinkCustom spread={spread} href={item.value}>
                  <a href={item.value} onClick={(e) => {
                    if (spread)
                      e.preventDefault();
                  }}>
                    { item.name }
                  </a>
                </LinkCustom>
              </li>
            ) : (
              <li key={i}>
                <a href={item.value} target="_blank" rel="noreferrer">
                  { item.name }
                </a>
              </li>
            );
          } else {
              if (!item.value) 
                return null;

              const getName = (category) => {
                  return category.attributes['category_name']?.['fr_FR'] ?? category.libelle;
              };

              let id = item.value.replace('/api/categories/', '');
              let category = categories.find(e => e.id.toString() === id);

              if (!category)
                  return null;

              let children = categories
                  .filter(e => e.parent && e.parent === category.id)
                  .map(e => ({
                      ...e,
                      name: getName(e)
                  }));

              for (let child of children) {
                  child.children = categories
                  .filter(e => e.parent && e.parent === child.id)
                  .map(e => ({
                      ...e,
                      name: getName(e)
                  }));
              }
              
              return (
                  <li key={i} className={ children && children.length ? "menu-item-has-children" : "" }>
                      <LinkCustom spread={spread} href="/category/[slug]" as={`/category/${item.value.replace('/api/categories/', '')}`}>
                          <a>
                              { item.name }
                              { children && children.length ? <i className="fa fa-angle-down" /> : null }
                          </a>
                      </LinkCustom>
                      
                      {
                          children && children.length ? (
                              <ul className="sub-menu">
                                  { 
                                      children.map((e, i) => {
                                          return (
                                              <li key={i} className={ e.children.length > 0 ? "menu-item-has-children" : "" }>
                                                  <ul className="sub-menu">
                                                    {
                                                      e.children.length > 0 ? (
                                                      <>
                                                          <li className="menu-item-has-children">
                                                              <LinkCustom spread={spread} href="/category/[slug]" as={`/category/${e.id}`}>
                                                                  <a>
                                                                      { e.name.length > 15 ? e.name.substring(0, 15) + '...' : e.name }
                                                                  </a>
                                                              </LinkCustom>
                                                              <ul className="sub-menu">
                                                                {
                                                                  e.children.map((f, j) => { 
                                                                      return (
                                                                          <li key={j}>
                                                                              <LinkCustom spread={spread} href="/category/[slug]" as={`/category/${f.id}`}>
                                                                                  <a>
                                                                                      { f.name.length > 15 ? f.name.substring(0, 15) + '...' : f.name }
                                                                                  </a>
                                                                              </LinkCustom>
                                                                          </li>
                                                                      );
                                                                  })
                                                                }
                                                              </ul>
                                                          </li>
                                                      </>
                                                      ) : (
                                                          <li>
                                                              <LinkCustom spread={spread} href="/category/[slug]" as={`/category/${e.id}`}>
                                                                  <a>
                                                                      { e.name.length > 20 ? e.name.substring(0, 20) + '...' : e.name }
                                                                  </a>
                                                              </LinkCustom>
                                                          </li>
                                                      )
                                                  }
                                                  </ul>
                                              </li>
                                          );
                                      })
                                  }
                              </ul>
                          ) : null
                      }
                  </li>
              );
            }
          })}
        {/* <li className="menu-item-has-children">
          <a href="/" to={process.env.PUBLIC_URL + "/"}>Home</a>
          <ul className="sub-menu">
            <li className="menu-item-has-children">
              <a href="/" to={process.env.PUBLIC_URL + "/"}>
                Home 1
              </a>
              <ul className="sub-menu">
                <li>
                  <a href="/" to={process.env.PUBLIC_URL + "/home-fashion"}>
                    Home 1
                  </a>
                </li>
                <li>
                  <a href="/" to={process.env.PUBLIC_URL + "/home-fashion-two"}>
                    Home 2
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href="/" to={process.env.PUBLIC_URL + "/"}>
                Home 2
              </a>
              <ul className="sub-menu">
                <li>
                  <a href="/" to={process.env.PUBLIC_URL + "/home-furniture-two"}>
                    Home 1
                  </a>
                </li>
                <li>
                  <a href="/" to={process.env.PUBLIC_URL + "/home-furniture-three"}>
                    Home 2
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a href="/" to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
            Contact us
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
