import { Grid, Box, CircularProgress } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import styled from 'styled-components';
import request from '../../../../../js/utils/fetch';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import colors from '../../../../../config/theme/colors';
import OurTypography from '../../../../ui/typography/Typography';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/fr';

const DatePickerCustom = styled(DatePicker)`
    &.MuiTextField-root{  
        max-width:150px;
      .MuiInput-root{
          border: 0.5px solid ${colors.grey.lighter.hue700};
          height:100%;
          &::before{
              border-bottom:none;
          }
        }
        .MuiFormHelperText-root{
          position:absolute;
        }
    }
    input{        
        height:100%;
        padding:0 10px;
        caret-color:transparent;
        font-weight:bold;
    }    
`;

const WrapperGlobalsRight = styled(Grid)`
    width: 100%;
    max-width: calc(100% - 290px);
    @media screen and (max-width: 1249px){
        margin-top: 24px;
        max-width: 100%
    }
`;

const DailyStats = props=>{
    const {startDate,endDate,assetId}= props
    const [date, setDate] = useState(moment(startDate).format("yyyy-MM-DD"))
    const [daily,setDaily] =useState(null)    

    const barOptions = { 
        grid: {
            left: 10,
            bottom:20,
            right:20,
            top:20,            
            containLabel: true,
        },
        tooltip: {
            show:false,
            trigger: 'axis',
            position: 'top',
        },
        toolbox: {
            feature: {
                saveAsImage: {
                    title: "Télécharger graphique",
                    show:false
                }
            }
        },        
        xAxis: {
            type: 'category',
            data: [
                {
                    value:"Nombre de parties",
                    textStyle:{
                        fontWeight:'bold',
                        fontSize:12
                    }
                },
                {
                    value:"Nombre de joueurs",
                    textStyle:{
                        fontWeight:'bold',
                        fontSize:12
                    }
                },
                {
                    value:"Joueurs unique",
                    textStyle:{
                        fontWeight:'bold',
                        fontSize:12
                    }
                }
            ]
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data:daily,
              type: 'bar',
              label: {
                show: true,
                position: 'top',
                fontWeight:'bold',
                fontSize:20
              },
              showBackground: false,              
            }
          ]
    };

    const getDaily = (postData = false)=>{
        setDaily(null)
        postData = { "date": date } 
        request(`${process.env.REACT_APP_API}/games/stats/${assetId}/daily`, 'POST', postData).then(
            (data) => {
                if(data.success){                                       
                    let newArray =[]   
                    for (const d of Object.keys(data.datas)) {
                        let color
                        switch (d) {
                            case "playCount":
                                color ="#0273A5"
                                break;
                            case "newPlayerCount":
                                color ="#78CBEE"
                                break;
                            case "zeroPlayPlayer":
                                color ="#D2EDF9"
                                break;
                            default:
                                break;
                        }                                           
                        let item = {
                            value:Number(data.datas[d]),
                            itemStyle: {
                                color: color
                              }
                        }
                        newArray.push(
                            item
                        )
                    }                                  
                    setDaily(newArray)                              
                }                
            }
        ).catch((error) => {
            console.log(error)
        })
    }            
    
    useEffect(() => {
      getDaily()
    }, [date])
    
    return (
        <Grid item xs={12}>
            <Box pt={1}>
                <WrapperGlobalsRight item alignItems={'center'}>
                    <Box pt={3} pb={3} style={{display:'flex',alignItems:'center',gap:20}}>
                        <OurTypography variant={"h4"} style={{ fontSize: 20, fontWeight: '600' }}>Statistiques par jour</OurTypography>
                        <LocalizationProvider dateAdapter={AdapterMoment} style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:"center"}}>                                                                                                                        
                                <DatePickerCustom
                                  disableToolbar
                                  autoOk
                                  invalidDateMessage={''}
                                  maxDateMessage={''}
                                  labelFunc={(date,invalidLabel)=> moment(date).format("DD/MM/YYYY")}
                                  variant="inline"
                                  format="yyyy-MM-DD"                                                                                                     
                                  value={date}
                                  maxDate={endDate}
                                  minDate={startDate}
                                  onChange={(date,value)=>setDate(value)}                                                                                                          
                                  onKeyDown={(e)=>e.preventDefault()}
                                  InputProps={{
                                    disableUnderline: true
                                  }}
                                  style={{height:"40px"}}
                                />                                    
                        </LocalizationProvider>                        
                    </Box>
                </WrapperGlobalsRight>
                {
                    daily?
                    <ReactEcharts option={barOptions} />                                            
                    :<Box style={{display:"flex",alignItems:"center",justifyContent:"center"}}><CircularProgress/></Box>
                }
            </Box>
        </Grid> 
    )
}

export default DailyStats