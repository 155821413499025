import React from "react";
import Div from '../../../../shared/components/CustomDiv';
import H2 from '../../../../shared/components/CustomH2';
import H3 from '../../../../shared/components/CustomH3';

export default ({ data, preview, component, block, inputCallback, className }) => {
    var rules = data.blocks[block].config;
    return(
        <div className="regles">
            {
                rules.length > 0 ? (
                    <>
                        <div>
                            {
                                !data.blocks.rulesTitleImage?.inputs?.value?.value  ? (
                                    <>
                                        {
                                            data.blocks.rulesTitle ? (
                                                <H2 data={data} preview={preview} component={component} block={'rulesTitle'} inputCallback={inputCallback} />
                                            ) : null
                                        }
                                        {
                                            data.blocks.rulesSubtitle ? (
                                                <H3 className="center" data={data} preview={preview} component={component} block={'rulesSubtitle'} inputCallback={inputCallback} />
                                            ) : null
                                        }
                                    </>
                                ) : (
                                    <img className="img_center"
                                        src={
                                            typeof data.blocks.rulesTitleImage?.inputs?.value?.value === 'string' &&data.blocks.rulesTitleImage?.inputs?.value?.value !== '' ? 
                                                data.blocks.rulesTitleImage.inputs.value.value : 
                                                data.blocks.rulesTitleImage?.inputs?.value?.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.rulesTitleImage.inputs.value.value.filePath}` 
                                                    : `http://via.placeholder.com/300x80/FFFFFF/53839D?text=Titre`
                                            }
                                        alt="Règles"
                                    />
                                )
                            }
                        </div>
                        <div className="list_regles">
                            {
                                rules.map((rule, index) =>{
                                    return(
                                        <div className="regle" onClick={() => inputCallback(component, block)} id={`rule-${index}`} key={`rule-${index}`}>
                                            <img className="img_center"
                                                src={
                                                    typeof rule.blocks.image.inputs.value.value === 'string' && rule.blocks.image.inputs.value.value !== '' ? 
                                                        rule.blocks.image.inputs.value.value : 
                                                        rule.blocks.image.inputs.value?.value?.filePath ? 
                                                            ` ${process.env.REACT_APP_MEDIAS}/${rule.blocks.image.inputs.value.value.filePath}` 
                                                            : `http://via.placeholder.com/120x220/FFFFFF/53839D?text=${index+1}`
                                                    }
                                                alt={`Règle ${index+1}`}
                                            />
                                            <div className="description">
                                                <Div data={rule} preview={preview} component={rule.blocks.text.id} block={'text'} inputCallback={() => inputCallback(component, block)} nopadding={true} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                ) : null
            }
        </div>
    )
};