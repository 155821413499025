import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Link from 'next/link';
import { useRouter } from 'next/router';
import request from "../../../../../tools/Fetch";
import { v4 as uuidv4 } from "uuid";
import RulesBlock from '../RulesBlock/RulesBlock';
import H1 from '../../../../shared/components/CustomH1';
import H2 from '../../../../shared/components/CustomH2';
import Pg from '../../../../shared/components/CustomParagraph';
import Div from '../../../../shared/components/CustomDiv';

const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const RecapBlockBook = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [hoverBlockRecap, setHoverBlockRecap] = useState(false);
    const [selected, setSelected] = useState([]);
    const [email, setEmail] = useState('');
    const router = useRouter();

    const [isOpen, setIsOpen] = useState(false);
    
    let {
        preview = false,
        id = null, 
        data,
        inputCallback = null,
        spread = false,
        emailTemplate = ''
    } = props;

    useEffect(() => {
        let list = localStorage.getItem('products');

        if (list)
            list = JSON.parse(list);

        setSelected(list ? list : []);
        document.getElementById('builder-template-scope-book').scrollIntoView({ behavior: 'instant', block: 'start' });
    }, []);

    const clickRules = () => {
        setIsOpen(true)
    }

    const getMediaOrigin = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return process.env.REACT_APP_MEDIAS;
        } else {
            return `${process.env.REACT_APP_SPREAD_CDN}/medias` 
        }
    };
    
    let image = data.inputs['backgroundImage'].value;
    let dataExplanation = data.blocks.explanation;
    
    let image2 = dataExplanation.inputs['backgroundImage'].value;
    let border;
    if (dataExplanation.inputs.border) {
        border = dataExplanation.inputs.border;
    }

    let dataRecap = data.blocks.recap;
    let image3 = dataRecap.inputs['backgroundImage'].value;

    return (
        <div 
            className="recap-wrapper"
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: 'relative',
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && props.toolbarSection }
            <div className="wrapper_card">
                <div 
                    className="card_container"
                    style={{
                        backgroundColor: dataExplanation.inputs['backgroundColor'].value,
                        backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        position: 'relative',
                        borderStyle: 'solid',
                        borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                        borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                        borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                    }}
                    onMouseEnter={() => {setTimeout(() => {setHover(false); setHoverBlock(true); setHoverBlockRecap(false);}, 10)}} onMouseLeave={() => {setHover(true); setHoverBlock(false);}}
                >
                    { !preview && hoverBlock && props.toolbarBlock }
                    <div className="top_card">
                        <H1 data={dataExplanation} className="title_card" preview={preview} component={id} block={'title'} inputCallback={spread ? inputCallback : null} nopadding={true} />
                        <H2 data={dataExplanation} preview={preview} component={id} block={'subtitle'} inputCallback={spread ? inputCallback : null} nopadding={true} />
                        <div className="form_validation">
                            <form onSubmit={(e) => {
                                e.preventDefault();

                                if (spread)
                                    return;

                                let data = new FormData();
                                let uuid = localStorage.getItem('uuid');
                                let html = emailTemplate.replace('%link%', process.env.REACT_APP_REDIRECT);
                                
                                html = html.replace('%p1_image%', `${process.env.REACT_APP_MEDIAS}/${selected[0]?.product?.image}`);
                                html = html.replace('%p2_image%', `${process.env.REACT_APP_MEDIAS}/${selected[1]?.product?.image}`);
                                html = html.replace('%p3_image%', `${process.env.REACT_APP_MEDIAS}/${selected[2]?.product?.image}`);
                                html = html.replace('%p4_image%', `${process.env.REACT_APP_MEDIAS}/${selected[3]?.product?.image}`);
            
                                html = html.replace('%p1_ref%', `${selected[0]?.product?.ref}`);
                                html = html.replace('%p2_ref%', `${selected[1]?.product?.ref}`);
                                html = html.replace('%p3_ref%', `${selected[2]?.product?.ref}`);
                                html = html.replace('%p4_ref%', `${selected[3]?.product?.ref}`);
            
                                html = html.replace('%p1_name%', `${selected[0]?.product?.name}`);
                                html = html.replace('%p2_name%', `${selected[1]?.product?.name}`);
                                html = html.replace('%p3_name%', `${selected[2]?.product?.name}`);
                                html = html.replace('%p4_name%', `${selected[3]?.product?.name}`);

                                if (!uuid)
                                    uuid = uuidv4();

                                data.append('username', email);
                                data.append('uuid', spread ? '123-456' : uuid);
                                data.append('token', process.env.REACT_APP_ASSET_TOKEN);
                                data.append('origin', process.env.REACT_APP_ORIGIN);
                                data.append('products[]', selected[0]?.product?.ref);
                                data.append('products[]', selected[1]?.product?.ref);
                                data.append('products[]', selected[2]?.product?.ref);
                                data.append('products[]', selected[3]?.product?.ref);
                                data.append('emailHtml', html);

                                try {
                                    request(`${process.env.REACT_APP_API}/assets/wishlist/${process.env.REACT_APP_ASSET_IDENTIFIER}`, 'post', data, 'multipart/form-data').then(response => {
                                        // set new session uuid
                                        localStorage.setItem('uuid', uuidv4());
    
                                        // reset the wishlist
                                        localStorage.removeItem('products');
    
                                        // save email
                                        localStorage.setItem('email', email);
                                        
                                        // go to the final page
                                        // router.push('/registered');
                                        window.location = '/registered';
                                    }).catch(e => {
                                        console.err(e);
                                    });
                                } catch(e) {
                                    console.err(e);
                                }
                            }}>
                                <input 
                                    id="mail" 
                                    name="mail" 
                                    type="email" 
                                    placeholder="E-mail" 
                                    value={email} 
                                    required 
                                    onChange={e => {setEmail(e.target.value);}}
                                    style={{
                                        borderStyle: 'solid',
                                        borderWidth: dataExplanation.blocks.button.inputs.border?.inputs?.borderWidth?.value ? dataExplanation.blocks.button.inputs.border.inputs.borderWidth.value : '0px',
                                        borderColor: dataExplanation.blocks.button.inputs.border?.inputs?.borderColor?.value ? dataExplanation.blocks.button.inputs.border.inputs.borderColor.value : null,
                                        borderRadius: dataExplanation.blocks.button.inputs.border?.inputs?.borderRadius?.value ? dataExplanation.blocks.button.inputs.border.inputs.borderRadius.value : null,
                                    }}
                                />
                                <div className="wrapper_checkbox">
                                    <input id="acceptRules" type="checkbox" required />
                                    <label 
                                        htmlFor="acceptRules" 
                                        className="labelAccept" 
                                        onClick={spread ? () => inputCallback(id, 'labelConsent') : null}
                                        style={{
                                            color: dataExplanation.blocks.labelConsent.inputs.color.value
                                        }}
                                    >
                                        {dataExplanation.blocks.labelConsent.inputs.value.value}
                                    </label>
                                </div>
                                <input 
                                    id="submit" 
                                    type="submit" 
                                    value={dataExplanation.blocks.button.inputs.value.value} 
                                    style={{
                                        backgroundColor: dataExplanation.blocks.button.inputs.backgroundColor.value,
                                        /* backgroundImage: typeof dataExplanation.blocks.image.inputs['backgroundImage']?.value === 'string' ? `url(${dataExplanation.blocks.image.inputs['backgroundImage']?.value.value})` : dataExplanation.blocks.image.inputs['backgroundImage']?.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${dataExplanation.blocks.image.inputs['backgroundImage']?.value.filePath})` : 'none', */
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        /* padding: typeof image === 'string' || image?.filePath ? `10px ${dataExplanation.blocks.button.inputs.size?.value + 10}px` : '10px', */
                                        fontSize: dataExplanation.blocks.button.inputs.size.value,
                                        color: dataExplanation.blocks.button.inputs.color.value,
                                        borderStyle: 'solid',
                                        borderWidth: dataExplanation.blocks.button.inputs.border?.inputs?.borderWidth?.value ? dataExplanation.blocks.button.inputs.border.inputs.borderWidth.value : '0px',
                                        borderColor: dataExplanation.blocks.button.inputs.border?.inputs?.borderColor?.value ? dataExplanation.blocks.button.inputs.border.inputs.borderColor.value : null,
                                        borderRadius: dataExplanation.blocks.button.inputs.border?.inputs?.borderRadius?.value ? dataExplanation.blocks.button.inputs.border.inputs.borderRadius.value : null,
                                    }}
                                    onClick={spread ? () => inputCallback(id, 'button') : null}
                                />
                            </form>
                        </div>
                        <div className="text_juri">
                            <Pg data={dataExplanation} preview={preview} component={id} block={'conditions'} inputCallback={spread ? inputCallback : null} nopadding={true} />
                        </div>
                    </div>
                    <div 
                        className="bottom_card"
                        style={{
                            backgroundColor: dataRecap.inputs['backgroundColor'].value,
                            backgroundImage: typeof image3 === 'string' ? `url(${image3})` : image3?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image3.filePath})` : 'none',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            position: 'relative',
                        }}
                        onMouseEnter={() => {setTimeout(() => {setHover(false); setHoverBlock(false); setHoverBlockRecap(true);}, 10)}} onMouseLeave={() => {setHover(true); setHoverBlockRecap(false);}}
                    >
                        { !preview && hoverBlockRecap && props.toolbarBlockRecap }
                        <LinkCustom spread={spread} href="/catalogue">
                            <a className="return_button">&lt; Changer ma liste</a>
                        </LinkCustom>
                        <Div data={dataRecap} className="title" preview={preview} component={id} block={'titleRecap'} inputCallback={spread ? inputCallback : null} nopadding={true} />
                        <div className="wrapper_list">
                            {
                                selected.length > 0 ?
                                selected.map((item, index) => {
                                    return (
                                        <div className="selected_product" key={`SelectedProduct${index}`}>
                                            <div className="image_product">
                                                { item.product && item.product.image && <img src={`${getMediaOrigin()}/${item.product?.image}`} alt={`Produit${item.product?.name}`}/>}
                                            </div>
                                            <div className="infos_product">
                                                {item.product ? item.product?.ref + ' - ' + item.product?.name : 'Aucun produit'}
                                            </div>
                                        </div>
                                    )
                                }) : null
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            {
                isOpen ? 
                    <RulesBlock isOpen={isOpen} onClose={() => setIsOpen(false)}/>
                : null
            }
        </div>
    );
};

RecapBlockBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default RecapBlockBook;
