import { Grid, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import {connect} from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import CardCustom from '../../../../layouts/Card/CardCustom';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import { ALERT_SUCCESS } from '../../../../../js/constants/alert-types';
import { GET_PRODUCT_CATEGORIES_BY_CATEGORY, UPDATE_POSITION_PRODUCTS } from '../../../../../queries/product_position';
import { SortableComponent } from "./SortableComponent";
import './SortableComponent.scss';
import DialogModal from '../../../../ui/dialog/DialogModal';

const DialogModalCustom = styled(DialogModal)`
    .MuiDialog-container{
        width: 80% !important;
    }
`
class ProductMerchandising extends React.Component { 
    constructor(props){
        super(props)
        this.state = {
            ready: false,
            switchValue: false,
            changeInMerch: false,
            categoriesSelected: this.props.activeCategorie ? [this.props.activeCategorie] : [],
            catalogId: this.props.catalogId || null,
            readyFilters: false,
        }
    }

    async componentDidMount(){
        await this.handleGetItems();
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.openMerchModal !== this.props.openMerchModal && this.props.openMerchModal === true){
            this.handleGetItems();
        }
    }

    handleGetItems() {
        return new Promise(async(resolve, reject) => {
            this.setState({
                ready:false,
                changeInMerch: false
            })
            const GET_PRODUCT_CATEGORIES_BY_CATEGORY_RESULT = await this.props.client.query({ 
                query: GET_PRODUCT_CATEGORIES_BY_CATEGORY, 
                fetchPolicy: "no-cache",
                variables: { "category": this.props.categorySelected} 
            });
            let setListProduct = []
            for (let product of GET_PRODUCT_CATEGORIES_BY_CATEGORY_RESULT.data.productCategories.edges){
                let getByCatalog = product.node.product.productCatalogs.edges.find(e => e.node.catalog.id === this.state.catalogId)
                if (getByCatalog?.node.status){
                    setListProduct.push(product)
                }
            }
            this.setState({
                listItems: setListProduct,
                ready: true,
            })
            // if(this.props.listingCallback)
            //     this.props.listingCallback(handleResults.listItems)
            resolve();
        });
    }

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    handleChangeMerch = () => {
        this.props.startLoading();
        return new Promise(async(resolve, reject) => {
            await this.props.client.query({ 
                query: UPDATE_POSITION_PRODUCTS, 
                fetchPolicy: "no-cache",
                variables: { "category": this.props.categorySelected, "listId": this.state.listItems.map(e => e.node.id.replace('/api/product-categories/', ''))} 
            });
            this.props.snack(ALERT_SUCCESS, 'Organisation des produits enregistré avec succès');
            this.props.handleToggleDialog('openMerchModal')
            this.props.stopLoading();
            // if(this.props.listingCallback)
            //     this.props.listingCallback(handleResults.listItems)
            resolve();
        });
    }

    render(){
        return(
            <DialogModalCustom
                open={this.props.openMerchModal}
                title={`Organisation des produits :`}
                notText={true}
                maxWidth="md"
                fullWidth={true}
                primaryAction={this.handleChangeMerch} primarybgcolor={colors.green.regular} primarybgcolorhover={colors.green.darker} primaryText="Valider" primaryDisabled={this.state.changeInMerch ? false : true}
                secondaryAction={() => {this.props.handleToggleDialog('openMerchModal')}} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
            >
                <Grid container style={{background: colors.grey.lighter.hue980, margin: 0, padding:"16px"}}>
                    {
                        this.state.ready ?
                            this.state.listItems.length > 0 ?
                                <CardCustom style={{width: "100%", height: "auto",  padding: "0"}} cardContentStyle={{height: "auto"}} contentpadding={'0'} hovercard={false} collapse={true}>
                                    {/* <TableContainer component={Paper} style={{maxHeight: 500}}> */}
                                        <Grid container direction="row" aria-label="headerList">
                                            <SortableComponent stateCallback={this.stateCallback} listItems={this.state.listItems}/>
                                        </Grid>
                                    {/* </TableContainer> */}
                                </CardCustom>
                            : <Typography style={{padding: 20}}>Aucun produit</Typography>
                        : <PageLoader />
                    }
                </Grid>
            </DialogModalCustom>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo((connect(mapStateToProps,mapDispatchToProps)(ProductMerchandising)));