import React from 'react';

import { Box, Grid, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components'
import { withTranslation } from 'react-i18next';
import Button from '../../../ui/button/Button';
import { ROUTE_SALES_ESHOP_MERCHANDISING_BUILDER } from '../../../../js/constants/route-names';
import * as moment from "moment";
import StatusInfo from '../../../ui/status-info/StatusInfo';
import { MoreVert } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import OurMenu from '../../../ui/menu/Menu';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';

const TypographyDate=styled(Typography)`
    color: ${colors.sinfinNewMenu.normal};
`
const MenuCustom=styled(Menu)`
    .MuiMenu-paper{
        box-shadow: 0 0 10px #1622421A;
    }
`
const MenuItemCustom=styled(MenuItem)`
    color: ${colors.sinfinNewMenu.normal};
    svg{
        fill: ${colors.sinfinNewMenu.normal};
    }
`

function CardMerchandisingVersions(props){
    const editMenuOptions = [
        // {
        //     label: 'Dupliquer',
        //     isDisabled: true,
        //     color: colors.blue.darker.hue300,
        //     icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
        //     action: (e) => {
        //         e.stopPropagation();
        //         // props.toggleDuplicateDialog(props.data.node.id)
        //     }
        // },
        {
            label: props.t("spread.active_assets.delete"),
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                props.handleDelete()
            }
        }
    ]

    return(
        <Box width={props.width? props.width:'100%'} height={'100%'} display={"flex"} flexDirection={"column"}>
            <Box padding={'16px'} display={"flex"} flexDirection={"column"} flex={1}>
                <Box padding={"0 5px"} display={"flex"} flexDirection={"column"} flex={1}>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"} justifyContent={"space-between"} flex={1}>
                        <Typography variant={"h2"} style={{paddingRight: 8, fontSize: 19}}>{props.data.node.title}</Typography>
                        <Box display={"flex"} alignSelf={"flex-start"}>
                            <StatusInfo status={props.data.node.status}/>
                        </Box>
                    </Box>
                    <Box mb={2} mt={0.5}>
                        <Box maxWidth={214} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                            <TypographyDate>Date de début: </TypographyDate>
                            <span style={{fontWeight: "bold", color: colors.black.lighter}}>{moment(props.data.node.startAt).utc().format('DD/MM/YYYY')}</span>
                        </Box>
                        <Box maxWidth={214} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                            <TypographyDate>Date de fin: </TypographyDate>
                            <span style={{fontWeight: "bold", color: colors.black.lighter}}>{moment(props.data.node.endAt).utc().format('DD/MM/YYYY')}</span>
                        </Box>
                    </Box>
                </Box>
                <Grid container>
                    <Grid item xs={10}>
                        <Button types="Info" text="Gérer" icon='exitApp' style={{width: "100%", margin: 0, paddingTop: 12, paddingBottom: 12}} onClick={() => {window.open(ROUTE_SALES_ESHOP_MERCHANDISING_BUILDER.replace(':id', props.match.params.id).replace(':idCat', props.data.node.category.id.replace('/api/categories/', '')).replace(':idMerch', props.data.node.id.replace('/api/merchandisings/', '')))}}></Button>
                    </Grid>
                    <Grid item xs={2} style={{position: 'relative', display: 'flex', justifyContent: 'center', marginTop: '12px', paddingLeft: '14px'}}>
                        <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default withTranslation()(CardMerchandisingVersions);