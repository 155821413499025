/**
 * Renvoi la liste des métas attributs des produits
 * @param {array} attributes la liste des attributs des produits stocker dans redux (props.attributes.product.attributes)
 * @returns object
 */
export const getProductsMetaAttributes = (productAttributes) => {
  const metaAttributes = productAttributes.edges.filter(
    (e) => e.node.isSystem && e.node.identifier.indexOf("meta") > -1
  );
  return metaAttributes;
};

/**
 * Renvoi la liste des groupes d'attributs des produits
 * @param {array} attributeGroups la liste des groupes d'attributs stocker dans redux (props.attributeGroups)
 * @returns array
 */
export const getProductsAttributeGroups = (attributeGroups) => {
  const eavTypeProductID = JSON.parse(
    localStorage.getItem("GET_EAV_TYPES")
  )?.data?.eavTypes?.edges.find((eavType) => eavType.node.code === "product")
    ?.node?.id;

  return (
    attributeGroups?.filter(
      (e) => e.node.attributes.edges[0]?.node.eavType.id === eavTypeProductID
    ) ?? []
  );
};

/**
 * @param {array} attributeGroups la liste des attributs des produits stocker dans redux (props.attributes.product.attributes)
 * @returns array
 */
export const getCustomAttributes = (productAttributes) => {
  return productAttributes.edges.filter((e) => !e.node.isSystem);
};

/**
 * @param {array} attributeGroups la liste des attributs des produits stocker dans redux (props.attributes.product.attributes)
 * @returns array
 */
export const getProductSystemAttributes = (productAttributes) => {
  return productAttributes.edges.filter((e) => e.node.isSystem);
};

/**
 *
 * @param {object} product le produit
 * @param {string} dataName le nom de la donnée
 * @returns {object} la donnée du produit
 */
export const getProductData = (product, dataName, lang = "fr_FR") => {
  if (!product || !dataName) return null;

  return product.productDatas.edges.find(
    (e) =>
      e.node.attribute.identifier === dataName && e.node.locale.code === lang
  );
};

/**
 *
 * @param {object} product le produit
 * @returns {array} la liste des images du produit
 */
export const getProductImages = (product, lang = "fr_FR") => {
  if (!product) return null;

  return product.productDatas.edges.filter(
    (e) =>
      (e.node.attribute.identifier.match(/product_image/) &&
        e.node.locale.code === lang) ||
      (e.node.attribute.identifier.match(/product_image_\d/) &&
        e.node.locale.code === lang)
  );
};

export const getMainProductImage = (product, lang = "fr_FR") => {
  const mainImage = getProductImages(product, lang).find((e) =>
    e.node.attribute.identifier.match(/product_image/)
  );
  if (!mainImage) return null;
  return mainImage;
};

export const getProductAttributes = (product, lang = "fr_FR") => {
  return product.productDatas?.edges
    .filter((e) => e.node.locale.code === lang)
    .map((productData) => ({
      [productData.node.attribute.identifier]: productData.node.value,
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});
};
