import React, { useState } from "react";
import H1 from '../../../../shared/components/CustomH1';
import H2 from '../../../../shared/components/CustomH2';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const InformationBanner = ({
    preview = false,
    id = null, 
    data = null,
    inputCallback = null,
    children = null,
    spread = false
}) => {
    const [hover, setHover] = useState(false);

    const styles = {
        backgroundColor: '#212121',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box'
    };

    function goBack() {
        if (typeof window !== "undefined")
            window.history.back();
    }

    let canGoBack = false;

    if (typeof window !== "undefined")
        canGoBack = !spread && window?.location.pathname !== '/';

    return (
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children }

            <div className="container information-banner">
                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            {
                                canGoBack && (
                                    <button 
                                        onClick={spread === false ? goBack : null}
                                        className="button"
                                    >
                                        <ArrowBackIosIcon style={{fill: "white"}} />
                                        <p>Retour</p>
                                    </button>
                                )
                            }
                          
                            <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                            <H2 data={data} preview={preview} component={id} block={'titlelight'} inputCallback={inputCallback} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default InformationBanner;