import { Checkbox, FormControl, FormGroup } from '@mui/material';
import { FormControlLabelCustom, GridCustom } from './styles/styled';


const OurCheckbox = ({ allProps: props, error, updateCallback }) => {
    return (
        <GridCustom item xs={12}>
            <FormControl required component="fieldset">
                <FormGroup>
                    {props.input.value.map((option, index) => {
                        return (
                            <FormControlLabelCustom
                                key={index}
                                className={props.value?.[option.value] ? 'active' : null}
                                control={
                                    <Checkbox onChange={updateCallback} checked={props.value?.[option.value]} name={option.label} />
                                }
                                label={option.label}
                            />
                        )
                    })}
                </FormGroup>
                {
                    !error.seeError ? null :
                        <span className="error-label">{error.errorMessage}</span>
                }
            </FormControl>
        </GridCustom>
    )
}

export default OurCheckbox;