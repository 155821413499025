import React, { useState } from 'react';
import Button from '@mui/material/Button';
import colors from '../../../config/theme/colors';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import GetAppIcon from '@mui/icons-material/GetApp';
import styled from 'styled-components';
import IcomoonReact from "icomoon-react";
import iconSet from '../../../assets/selection.json'
import { CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconeSynonyme from "../../../assets/pictos/ico-synonyme.svg"
import FolderIcon from '@mui/icons-material/Folder';
import { fontWeight } from '@mui/system';
import { buttonTypes } from './config/types.config';

const defaultBg = colors.blue.darker.hue300;
const defaultBgHover = colors.blue.darker.hue300;
const defaultColor = "#fff";
const defaultFontSize = "14px";

const ButtonCustom = styled(Button)`
    margin: ${props => props.margin ? props.margin : '0'};
    text-transform: initial;
    text-transform: ${(props) =>
      props.texttransform ? props.texttransform + "!important" : "none"};
    font-weight: ${(props) => props.fontweight ?? "bold"};
    color: ${(props) => (props.colortext ? props.colortext : defaultColor)};
    background-color: ${(props) => (props.bgcolor ? props.bgcolor : defaultBg)};
    box-shadow: none;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: relative;
    top: 0;
    border: ${(props) => (!props.disabled && props.border ? props.border : "")};
    border-radius: 0;
    padding: ${(props) => props.padding ?? "14px 32px"};
    box-sizing: border-box;
    height: ${(props) => (props.height ? props.height : "40px")};

    &:hover {
      background-color: ${(props) =>
        props.disabled
          ? "#e0e0e0"
          : props.bgcolorhover
          ? props.bgcolorhover
          : defaultBgHover};
      background-color: ${(props) =>
        props.shadowcolor && props.bgcolor
          ? props.bgcolor
          : props.shadowcolor
          ? defaultBg
          : ""};
      background-color: ${(props) =>
        props.border && !props.bgcolorhover ? props.colortext : ""};
      box-shadow: none;
      color: ${(props) => (props.colorhover ? props.colorhover : "")};
      // border-color: ${(props) => (props.border ? props.bgcolor : "")};
      span {
        opacity: ${(props) =>
          props.bgcolorhover && props.bgcolorhover !== colors.green.regular
            ? 1
            : 0.5};
      }
    }
    span {
      font-size: ${(props) =>
        props.fontSize ? `${props.fontSize}px` : defaultFontSize};
      line-height: 1;
      @media screen and (max-width: 1280px) {
        font-size: 13px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 12px;
      }
    }
    .MuiButton-label {
      ${(props) => (props.contentstyle ? props.contentstyle : "")};
    }
    @media screen and (max-width: 1000px) {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      background: ${(props) =>
        props.bgcolor !== "transparent"
          ? "rgba(0, 0, 0, 0.12)"
          : props.bgcolor};
      // box-shadow: ${(props) =>
        props.bgcolor !== "transparent"
          ? "0px 3px 0px 0px #00000045"
          : `0px 3px 0px 0px ${props.bgcolor}`};
    }
`

const OurButton = (props) => {
    const { id, icon, hasIcon, types, importance, bgcolor, fontweight, bgcolorhover, color, colorhover, text, disabled, onClick, disableElevation, margin, disableRipple, border, shadowcolor, fontsize, style, textTransform, contentStyle, className, stopPropagation, loading } = props;

    const selectedButton = disabled 
        ? buttonTypes(icon, hasIcon)?.[types]?.[importance].disabled
        : buttonTypes(icon, hasIcon)?.[types]?.[importance];

    return (
        <>
            {selectedButton ? (
                <ButtonCustom
                    id={id}
                    variant="contained"
                    onClick={
                      disabled
                        ? () => {}
                        : stopPropagation 
                          ? (e) => { e.stopPropagation(); onClick(e); console.log('click') } 
                          : (e) => { onClick(e);}
                    }
                    disableElevation={disableElevation ?? true}
                    disableRipple={disableRipple}
                    bgcolor={selectedButton.bgcolor}
                    bgcolorhover={selectedButton.bgcolorhover}
                    shadowcolor={selectedButton.shadowcolor}
                    colortext={selectedButton.color}
                    colorhover={selectedButton.colorhover}
                    border={selectedButton.border}
                    fontSize={selectedButton.fontSize ?? fontsize}
                    style={{
                        ...style,
                        cursor: disabled ? 'default' : 'pointer',
                    }}
                    texttransform={selectedButton.textTransform ?? textTransform}
                    contentstyle={selectedButton.contentStyle ?? contentStyle}
                    className={className}
                    fontWeight={fontweight ?? 400}
                    margin={margin}
                >
                    {selectedButton.icon?.position === 'start' && selectedButton.icon.icon }
                    {text ?? ''}
                    {props.children ?? ''}
                    {selectedButton.icon?.position === 'end' && selectedButton.icon.icon }
                </ButtonCustom>
            ) : (
                <ButtonCustom
                    variant="contained"
                    disabled={disabled ? disabled : loading ? loading : false}
                    onClick={stopPropagation ? (e) => { e.stopPropagation(); onClick(e) } : (e) => { onClick(e) }}
                    disableElevation={disableElevation ?? true}
                    disableRipple={disableRipple}
                    bgcolor={bgcolor}
                    bgcolorhover={bgcolorhover}
                    shadowcolor={shadowcolor}
                    colortext={color}
                    colorhover={colorhover}
                    border={border}
                    fontSize={fontsize}
                    style={style}
                    texttransform={textTransform}
                    contentstyle={contentStyle}
                    className={className}
                    fontWeight={fontweight}
                    margin={margin}
                >
                    {props.loading ? <CircularProgress size={10} style={{ marginRight: 5 }} /> : ''}
                    {props.deleteIcon ? (<DeleteSharpIcon style={{ paddingRight: 5, width: '1em', height: '1em' }} />) : null}
                    {props.arrow === 'left' ? (<ArrowBackIosRoundedIcon style={{ paddingRight: 5, width: '0.8em', height: '0.5em' }} />) : ''}
                    {(props.icon) === "add" ? <AddIcon style={{ marginLeft: 5, width: '15px', height: 'auto' }} /> : null}
                    {props.icon === "exitApp" ? <ExitToAppIcon style={{ fontSize: 12, marginRight: 4 }} /> : null}
                    {(props.icon) === "edit" ? <EditSharpIcon style={{ marginRight: 10, fontSize: '16px' }} /> : typeof (props.icon) !== "string" ? (props.icon) : null}
                    {(props.icon) === "check" ? <CheckCircleIcon style={{ marginRight: 10, fontSize: '16px' }} /> : typeof (props.icon) !== "string" ? (props.icon) : null}
                    {(props.icon) === "delete" && (<DeleteSharpIcon style={{ paddingRight: 5, width: '1em', height: '1em' }} /> )}
                    {props.icon === "folder" ? <FolderIcon style={{ marginRight: 10, fontSize: '16px' }} /> : typeof props.icon !== "string" ? props.icon : null}
                    {props.downloadIcon ? (<GetAppIcon style={{ marginRight: 10 }} />) : ''}
                    {props.icon === "dicoIcon" ? (<img src={IconeSynonyme} alt='Icone synonyme' style={{ marginRight: 5 }} />) : ''}
                    {(text) ? (text) : ''}
                    {props.children ? props.children : ''}
                    {props.arrow === 'right' ? (<ArrowForwardIosRoundedIcon style={{ paddingLeft: 5, width: '0.8em', height: '0.5em' }} />) : ''}
                    {props.icomoon ? <IcomoonReact iconSet={iconSet} color={color} size={props.fontsize ? props.fontsize + 5 : 19} icon={props.icomoon} style={{ marginLeft: 5 }} /> : null}
                </ButtonCustom>
            )}
        </>
    );
};

OurButton.defaultProps = {
  hasIcon: true
}

export default OurButton;
