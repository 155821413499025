import React, { useEffect, useRef, useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import colors from "@config/theme/colors";
import Button from "@ui/button/Button";
import PropTypes from "prop-types";
import { HelperText } from "@ui/form/components/Text/styles/styled";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const SketchPickerCustom = styled(SketchPicker)`
  box-shadow: inherit !important;
  border-radius: 5px 5px 0 0 !important;
  border: 1px solid lightgrey !important;
  padding: 10px !important;
  width: calc(100% - 22px) !important;
  position: relative;
  > div:first-of-type {
    width: calc(100% - 100px) !important;
    height: 100px !important;
    padding-bottom: 0 !important;
    border-radius: 0 5px 5px 0;
    left: 100px;
  }
  .flexbox-fix {
    &:nth-child(2) {
      margin: 10px 0;
      & > div {
        &:first-child {
          & > div {
            border-radius: 5px;
          }
        }
        &:last-child {
          width: 100px !important;
          height: 100px !important;
          position: absolute !important;
          top: 10px;
          left: 10px;
          margin: 0 !important;
          & > div {
            border-radius: 5px 0 0 5px !important;
            box-shadow: inherit !important;
          }
        }
      }
    }
    &:nth-child(3) {
      & > div {
        padding-left: 10px;
        &:first-child {
          padding-left: 0;
        }
        &:nth-child(2) {
          margin-left: 10px;
          border-left: 1px solid lightgrey;
          padding-left: 10px !important;
        }
        & > div {
          display: flex;
          flex-direction: row-reverse;
          input {
            font-size: 12px !important;
            border-radius: 4px;
            font-weight: bold;
          }
          label {
            font-size: 12px !important;
            padding-right: 5px;
            text-transform: uppercase !important;
            color: #2b4553 !important;
            font-weight: bold;
          }
        }
      }
    }
  }
`;

const MarkupPickerCustom = styled.div`
  box-shadow: inherit !important;
  border-radius: 0 0 5px 5px !important;
  border: 1px solid lightgrey !important;
  border-top: 0 !important;
  padding: 10px !important;
  width: calc(100% - 22px) !important;
  position: relative;
  box-sizing: initial;
  background: rgb(255, 255, 255);
`;

const DivColor = styled.div`
  border: 1px solid lightgrey !important;
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 16px;
  position: relative;
`;

const CheckboxCustom = styled(Checkbox)`
  svg {
    path {
      color: rgb(110, 174, 209);
    }
  }
`;

const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

const ColorMarkupPicker = ({
  color,
  listMarkups,
  edit = false,
  helper,
  onChangeComplete,
  onCheckedMarkup,
  deleteColor,
}) => {
  const [isShowing, setIsShowing] = useState(edit ? true : false);
  const [currentColor, setCurrentColor] = useState(color);
  const [defaultColor, setDefaultColor] = useState();

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender) {
      initialRender.current = false;
      setDefaultColor(color);
    }
  }, [initialRender]);

  const styles = reactCSS({
    default: {
      color: {
        width: 30,
        height: 30,
        background:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==") left center',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
      },
      bgcolor: {
        width: 30,
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        backgroundColor:
          color?.rgb?.r && color?.rgb?.g && color?.rgb?.b && color?.rgb?.a
            ? `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
            : color.hex ?? null,
      },
      swatch: {
        padding: "5px 0 0",
        borderRadius: 4,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: 0,
        width: "100%",
        gap: 10,
      },
      popover: {
        zIndex: "2",
        marginBottom: 20,
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
      label: {
        paddingLeft: 10,
        fontSize: 14,
      },
      deleteBtn: {
        backgroundColor: "white",
        border: "1px solid lightgrey",
        borderRadius: "100%",
        height: "35px",
        width: "35px",
      },
    },
  });

  return (
    <div style={{ width: "100%", position: "relative" }}>
      {isShowing ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={() => setIsShowing(false)} />
          <SketchPickerCustom
            disableAlpha={true}
            onChangeComplete={(newVal) => {
              setCurrentColor(newVal);
              onChangeComplete(newVal, color);
            }}
            color={color.hex}
            presetColors={[]}
          />
          <MarkupPickerCustom>
            <div>
              {listMarkups.map((markup, index) => (
                <div
                  style={{ display: "inline-flex", alignItems: "center" }}
                  onClick={() => {
                    onCheckedMarkup(
                      markup,
                      !color.markups?.map((m) => m.id).includes(markup.id),
                      color,
                      index
                    );
                  }}
                  key={`color-markup-${index}`}
                >
                  <CheckboxCustom
                    checked={
                      color.markups?.map((m) => m.id).includes(markup.id) ??
                      false
                    }
                    style={{ padding: "0px 5px 0 10px" }}
                  />
                  <label>{markup.libelle}</label>
                </div>
              ))}
            </div>

            <ButtonsContainer>
              <div>
                <Button
                  onClick={() => {
                    setCurrentColor(defaultColor);
                    onChangeComplete(defaultColor, color);
                    setIsShowing(false);
                  }}
                  bgcolor={colors.white}
                  bgcolorhover={colors.white}
                  color={colors.red.regular}
                  colorhover={colors.red.darker}
                  fontsize={12}
                >
                  Annuler
                </Button>
                <Button
                  onClick={() => setIsShowing(false)}
                  bgcolor={colors.white}
                  bgcolorhover={colors.blue.darker.hue300}
                  color={colors.blue.darker.hue300}
                  colorhover={colors.white}
                  border={`1px solid ${colors.blue.darker.hue300}`}
                  fontsize={14}
                >
                  Valider
                </Button>
              </div>
            </ButtonsContainer>
          </MarkupPickerCustom>
        </div>
      ) : (
        <>
          <div style={styles.swatch} onClick={() => setIsShowing(true)}>
            <DivColor>
              <div style={styles.color}>
                <div style={styles.bgcolor}></div>
              </div>
              <p
                style={{
                  textTransform: "uppercase",
                  padding: "0 16px",
                  fontWeight: "bold",
                }}
              >
                {color.hex}
              </p>
              <div
                style={{
                  width: "30px",
                  height: "1px",
                  transform: "rotate(90deg)",
                  backgroundColor: "lightgrey",
                }}
              ></div>
              {color && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontStyle: "italic",
                  }}
                >
                  <span>R : {color.rgb?.r}</span>
                  <span>G : {color.rgb?.g}</span>
                  <span>B : {color.rgb?.b}</span>
                  <span>A : {color.rgb?.a}%</span>
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  right: 20,
                }}
              >
                <p
                  style={{
                    color: colors.blue.darker.hue300,
                    textDecoration: "underline",
                  }}
                >
                  Modifier
                </p>
              </div>
            </DivColor>
            <IconButton
              aria-label="delete"
              sx={styles.deleteBtn}
              onClick={() => deleteColor(color)}
            >
              <CloseIcon />
            </IconButton>
          </div>

          {helper?.text && (
            <HelperText variant="body2"> {helper.text} </HelperText>
          )}
        </>
      )}
    </div>
  );
};

ColorMarkupPicker.propTypes = {
  id: PropTypes.string,
  tempoId: PropTypes.string,
  color: PropTypes.shape({
    hex: PropTypes.string,
    rgb: PropTypes.shape({
      r: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      g: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      b: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      a: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  listMarkups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      libelle: PropTypes.string,
    })
  ),
  edit: PropTypes.bool,
  onChangeComplete: PropTypes.func,
  onCheckedMarkup: PropTypes.func,
  deleteColor: PropTypes.func,
};

export default ColorMarkupPicker;
