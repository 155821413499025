import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function formGroupAttributEdit(){

    var obj = {
        titleForm: "Modifier un modèle",
        subTitleForm: "Veuillez compléter les champs ci-dessous pour modifier votre modèle",
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            header: {
                title: 'Informations générales',
            },
            children: [
                {
                    optionsInputs: [
                        {
                            type: 'text',
                            label: 'Identifiant',
                            helper: {
                                text: 'Identifiant du modèle',
                                link: false,
                            },
                            required: false,
                            stateName: 'groupIdentifier',
                            translated: false,
                            disabled: true
                        },
                        {
                            type: 'text',
                            label: 'Libellé',
                            helper: {
                                text: 'Libellé du modèle',
                                link: false,
                            },
                            required: false,
                            stateName: 'groupName',
                            translated: true
                        }
                    ]
                },
            ]
        }
    }
    return obj;
}