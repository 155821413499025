import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { withApollo } from '@apollo/client/react/hoc';
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../../js/constants/action-types";
import { connect } from "react-redux";
import TopPanel from "../../../../layouts/TopPanel/TopPanel";
import AccordionCustom from "../../../../layouts/Accordion/AccordionCustom";
import Listing from "../../../../layouts/Listing/Listing";
import { listMappers, listSettings, perPageOptions, viewOptions } from "./config/listImports.config";
import colors from "../../../../../config/theme/colors";
import InputBuilder from "../../../../ui/form/InputBuilder";
import CheckIcon from '@mui/icons-material/Check';
import OurTypography from "../../../../ui/typography/Typography";
import a from "../../../../../builder/shareable/components/assets/cms/components/shared/a";
import { GET_FILE_IMPORTS_TOTAL } from "../../../../../queries/file_import";
import styled from "styled-components";

const ImportsLayout = styled.div`
    height: 100%;

    & .pagination{
        flex: 0 0 auto;
    }
    & .listing{
        flex: 1 1 auto;
        height: 0px;
    }
`

const Imports = props => {
    const currentLang = props.locales[0].node.code;
    const [panelHeight, setPanelHeight] = useState(null);
    const [eavType, setEavType] = useState("all");
    const [totalValidateImports, setTotalValidateImports] = useState(0);

    const getTotalValidatedImports = async () => {
        try {
            const data = await props.client.query({
                query: GET_FILE_IMPORTS_TOTAL,
                variables: {}
            })
            setTotalValidateImports(data.data.fileImports.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getTotalValidatedImports()
    }, [])

    return (
        <div style={{ overflow: "auto" }}>
            <TopPanel
                title={props.t('products.workflow.title')}
                subtitle={props.t('products.workflow.subtitle')}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
                getRef
                setRef={setPanelHeight}
            />
            <div
                style={{
                    // height: `calc(100% - ${panelHeight}px)`,
                    height: "100%",
                    display: "grid",
                    gridTemplateRows: "1fr 1rf",
                    gap: 16,
                    paddingBottom: 16
                }}
            >
                <AccordionCustom
                    forcedExpanded
                    hasIcon
                    scrollable
                    custompadding={"16px !important"}
                    height={"100%"}
                    overflow={"auto"}
                    detailsHeight={'calc(100% - 40px)'}
                    title={
                        <InputBuilder
                            value={eavType}
                            input={{
                                inlineMode: true,
                                isContainerized: true,
                                type: "select",
                                stateName: "eavType",
                                multiselect: false,
                                disabled: false,
                                value: [
                                    { value: "all", label: "Tous types d'import" },
                                    { value: "product", label: "Produits" },
                                    { value: "media", label: "Médias" },
                                    { value: "category", label: "Catégories" },
                                ],
                                helper: {
                                    link: false,
                                    text: ""
                                },
                                custom: {
                                    padding: "10px",
                                    // height: "40px",
                                }
                            }}
                            stateCallback={(e) => { setEavType(e.target.value) }}
                        />
                    }
                >
                    <ImportsLayout>
                        <Listing
                            padding={0}
                            label='Imports'
                            settings={listSettings}
                            perPageOptions={perPageOptions}
                            mappers={listMappers}
                            pagination={true}
                            enablePagination={true}
                            enableChangeView={false}
                            enableChangeSort={true}
                            enabeleSelectionItems={false}
                            queryVariables={{
                                "first": 12,
                                "eavType": eavType === "all" ? null : eavType
                            }}
                            enableFilters={false}
                            identifier='fileImports'
                            viewsOptions={viewOptions}
                            locales={props.locales}
                            tableProps={{
                                falseUser: "Beverly Cocoyer",
                                falseStatus: "En cours",
                                falseStatusColor: colors.blue.regular,
                            }}
                        />
                    </ImportsLayout>
                </AccordionCustom>
                <AccordionCustom
                    forcedExpanded
                    hasIcon
                    title={
                        <>
                            <CheckIcon fontSize="small" sx={{ color: colors.green.regular, marginRight: "5px" }} />
                            <OurTypography colortext={colors.green.regular}>Liste des imports terminés ({totalValidateImports})</OurTypography>
                        </>}
                    scrollable
                    custompadding={"16px !important"}
                    height={"100%"}
                    detailsHeight={'calc(100% - 40px)'}
                    overflow={"auto"}
                >
                    <ImportsLayout>
                        <Listing
                            padding={0}
                            label='Imports '
                            settings={listSettings}
                            perPageOptions={perPageOptions}
                            mappers={listMappers}
                            pagination={true}
                            enablePagination={true}
                            enableChangeView={false}
                            enableChangeSort={true}
                            enableSelectionItems={false}
                            enableFilters={false}
                            queryVariables={{
                                "first": 12
                            }}
                            identifier='fileImports'
                            viewsOptions={viewOptions}
                            tableProps={{
                                backgroundColor: colors.green.lighter,
                                color: colors.green.regular,
                                borderColor: colors.green.regular,
                                falseUser: "Beverly Cocoyer",
                                falseStatus: "Terminé",
                                falseStatusColor: colors.green.regular,
                            }}
                            locales={props.locales}
                        />
                    </ImportsLayout>
                </AccordionCustom>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(Imports)))));