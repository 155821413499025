import React from 'react';
import { Chip, Grid, TextField, InputAdornment, Typography } from '@mui/material/';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SearchIcon from '@mui/icons-material/Search';
import colors from '../../../config/theme/colors'
import { SEARCH_TAGS } from '../../../queries/medias'

const TextFieldCustom = styled(TextField)`
    height:40px;
    z-index:10;
    background:transparent;

    input, .MuiSelect-select{
        font-size: 14px;
        padding: 12px 5px;
        @media screen and (max-width: 850px){
            font-size: 14px;
        }
        &:-webkit-autofill:focus{
            background:none!important;
        }
        &:-internal-autofill-selected{
            background:none!important;
        }
        
    }
    .MuiSelect-select{
        // text-transform: capitalize;
    }
    & > p{
        margin: 0;
    }
    .MuiInputAdornment-positionStart{
        p,svg{
            color : ${colors.grey.lighter.hue800};
        }
        ${props => props.isfilter === false
        ?
        props.searchvalue.length > 0 ?
            `
                    svg{
                        display: block;
                        cursor:pointer;
                        transition:all .2s;
                        color:${colors.blue.darker.hue300}
                    }       
                `
            :
            `
                    svg{
                        display:none;
                        // &:hover{
                        //     color:${colors.blue.darker.hue300}
                        // }
                    } 
                `
        :
        `
                
            `
    }

        
    }

`;

const InputContainer = styled(Grid)`
    position :relative;
    display: flex;
    /* height :40px; */
`

const Suggestion = styled(Typography)`
    position : absolute;
    top: 0px;
    height:40px;
    width : 100%;
    display : flex;
    align-items:center;
    font-size:16px;
    color : ${colors.grey.lighter.hue600};
    padding-left:37.82px;
    @media screen and (max-width: 850px){
        font-size: 14px;
    }
    span{
        z-index:15;
        cursor:pointer;
        border : dotted 1px ${colors.grey.border};
        padding: 2px 5px;
        margin-left:8px;
        transition : all .1s;
        &:hover{
            color : ${colors.blue.darker.hue300};
            border-color:${colors.blue.darker.hue300};
        }
    }
`

const ChipCustom = styled(Chip)`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 5px;
    color: ${colors.black.regular};
    font-size: 12px;
    font-weight: 500;
    height: 21px;
    border-radius: 15px;
    padding: 0 20px 0 15px;
    transition: all .2s;
    svg{
        color: ${colors.black.regular};
        transform: scale(0.65);
        margin: 0px;
        cursor: pointer;
    }
    span{
        padding: 0px;
        padding-bottom: 1px;
        margin: 0px;
    }
`

class SearchTags extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

            isFilter: this.props.searchOptions?.isFilter ?? false,

            placeholder: this.props.searchOptions?.placeholder ?? 'Rechercher ou ajouter un tag',
            suggestions: [],
            tags: [],
            searchValue: this.props.searchOptions?.defaultValue ?? '',
        }
    }

    addTag = (e, isNew) => {
        let value = isNew ? e : e.value;
        if (value?.length > 0) {
            let tags = [...this.state.tags];
            let suggestions = [...this.state.suggestions];
            if (!isNew) {
                suggestions = suggestions.filter(suggestion => suggestion.value !== e.value)
            }
            tags.push({
                tag: value,
                id: isNew ? null : e.id,
                mediaObjects: isNew ? null : e.mediaObjects,
            });
            this.setState({
                tags: tags,
                searchValue: isNew ? '' : suggestions?.length > 0 ? this.state.searchValue??'' : '',
                suggestions: isNew ? {} : suggestions,
            })
            this.props.stateCallback(this.state.isFilter ? tags : 'fileTags', tags); // callback to parent - filter medias
            this.setState({
                searchValue: '',
                suggestions: {},
            })
        }
    }

    deleteTag = (index) => {
        let tags = [...this.state.tags];
        tags.splice(index, 1);
        this.setState({
            tags: tags
        })
        /* if (this.state.tags.length === 0) this.setState({ isFilter: false }) */
        this.props.stateCallback(this.state.isFilter ? tags : 'fileTags', tags);
    }

    handleChange = (event) => {
        this.setState({ searchValue: event.target.value });
        let suggestions;
        let search = event.target.value

        if (search.length >= 3) {
            this.props.client.query({
                query: SEARCH_TAGS,
                variables: {
                    tag: search,
                    first: 5,
                }
            }).then(result => {
                let currentTags = this.state.tags.map(tag => tag.tag);
                let tags = result.data.mediaObjectTags.edges.map(tag => {
                    return {
                        value: tag.node.tag,
                        id: tag.node.id,
                        mediaObjects: tag.node.mediaObjects.edges.map(media => media.node.id)
                    }

                }).filter(tag => !currentTags.includes(tag.value));
                this.setState({ suggestions: tags })
            }).catch((err)=>{
                console.log(err)
            });
        }

        else {
            suggestions = {
                id: null,
                value: null,
            };
            this.setState({ suggestions: suggestions })

        }

    }
    handleKeyDown = event => {
        if (event.keyCode === 13 && !this.state.isFilter) {
            event.preventDefault();
            this.addTag(event.target.value, true);
        }
        else if (event.keyCode === 9) {
            event.preventDefault();
            this.addTag(this.state.suggestions[0], false);

        }
    }

    componentDidMount() {
        let isFilter = this.state.isFilter;
        let tags = this.props.allState?.fileTags?.length > 0 ? this.props.allState.fileTags : []
        this.setState({
            tags: isFilter ? [] : tags??null
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.allState.isCleared !== prevProps.allState.isCleared && this.props.allState.isCleared) {
            this.setState({
                tags: [],
                searchValue: '',
                suggestions: {},
            }, () => {
                this.props.clearCallback(this.state.tags);
            })
        }
    }

    render() {
        return (
            <Grid style={{
                padding: this.state.isFilter ? 0 : 32,
                position: 'relative',
                display: 'flex',
            }} container>
                {
                    !this.state.isFilter ?
                        <Typography variant={"h4"} style={{ marginBottom: 16 }}>
                            Afin d'ajouter un tag cliquez sur l'icône +, ou veuillez appuyer sur la touche entrée de votre clavier.
                        </Typography>
                        : null
                }
                <InputContainer item xs={12} style={{
                    border: '1px solid ' + colors.grey.border,
                    alignItems: 'center',
                    gap: 10,
                    paddingLeft: '5px'
                }}>
                    <Grid item>
                        {!this.state.isFilter ? (
                            <Grid container style={{ minHeight: 0, margin: '10px 0px' }}>
                                {this.state.tags?.length > 0 ?
                                    <div>
                                        <div>
                                            Tags de l'image :
                                        </div>
                                        {
                                            this.state.tags.map((tag, index) => (
                                                <ChipCustom
                                                    deleteIcon={<CloseSharpIcon />}
                                                    label={tag.tag}
                                                    onDelete={() => this.deleteTag(index)}
                                                    key={`tag-${index}`}
                                                />
                                            ))
                                        }
                                    </div>
                                    : <span>Pas encore de tag selectionné pour cette image.</span>
                                }
                            </Grid>
                        ) : (
                            <Grid container style={{
                                minHeight: 0,

                            }}>
                                {this.state.tags?.length > 0 &&
                                    <Grid container style={{
                                        position: 'relative',
                                        gap: '2px',
                                        flexWrap: 'nowrap',
                                    }}>
                                        {
                                            this.state.tags.map((tag, index) => (
                                                <ChipCustom
                                                    deleteIcon={<CloseSharpIcon />}
                                                    label={tag.tag}
                                                    onDelete={() => this.deleteTag(index)}
                                                    key={`tag-${index}`}
                                                />
                                            ))
                                        }
                                    </Grid>
                                }
                            </Grid>
                        )}
                    </Grid>
                    <Grid container>
                        <TextFieldCustom
                            id="SearchTag"
                            variant="standard"
                            fullWidth={true}
                            name="tag_search"
                            placeholder={this.state.placeholder}
                            paddingLeft={this.state.paddingLeft}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        #
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="start">
                                        {
                                            this.state.isFilter ?
                                                <SearchIcon />
                                                :
                                                <AddBoxIcon style={{ fontSize: 35 }} onClick={() => this.addTag(this.state.searchValue, true)} />
                                        }

                                    </InputAdornment>
                                ),

                            }}
                            onChange={event => this.handleChange(event)}
                            onKeyDown={event => this.handleKeyDown(event)}
                            value={this.state.searchValue}
                            isfilter={this.state.isFilter ? 1 : 0}
                            searchvalue={this.state.searchValue}
                            autoComplete="off"
                        />
                        <Suggestion>
                            <Typography ref={this.suggestionText} style={{ opacity: '0' }}>{this.state.searchValue}</Typography>
                            {this.state.suggestions?.length > 0 ?
                                this.state.suggestions.map((tag, index) => (
                                    <span onClick={() => this.addTag(tag, false)} key={`tag-suggestion-${index}`} style={{ fontSize: '14px' }} >
                                        {tag.value}
                                    </span>
                                ))
                                : null}
                        </Suggestion>
                    </Grid>
                </InputContainer>

                {/* <Grid item xs={12}>
                    {!this.state.isFilter ? (
                        <Grid container style={{ minHeight: 0, margin: '10px 0px' }}>
                            {this.state.tags?.length > 0 ?
                                <div>
                                    <div>
                                        Tags de l'image :
                                    </div>
                                    {
                                        this.state.tags.map((tag, index) => (
                                            <ChipCustom
                                                deleteIcon={<CloseSharpIcon />}
                                                label={tag.tag}
                                                onDelete={() => this.deleteTag(index)}
                                                key={`tag-${index}`}
                                            />
                                        ))
                                    }
                                </div>
                                : <span>Pas encore de tag selectionné pour cette image.</span>
                            }
                        </Grid>
                    ) : (
                        <Grid container style={{
                            minHeight: 0,
                            margin: '10px 0px 20px 0px',
                        }}>
                            {this.state.tags?.length > 0 &&
                                <Grid container style={{

                                    gap: '2px'
                                }}>
                                    {
                                        this.state.tags.map((tag, index) => (
                                            <ChipCustom
                                                deleteIcon={<CloseSharpIcon />}
                                                label={tag.tag}
                                                onDelete={() => this.deleteTag(index)}
                                                key={`tag-${index}`}
                                            />
                                        ))
                                    }
                                </Grid>
                            }
                        </Grid>
                    )}
                </Grid> */}
            </Grid >

        )
    }
}
export default withApollo((SearchTags));