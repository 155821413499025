import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING  } from '../../../../js/constants/action-types';
import { Box, Grid, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import Button from '../../../ui/button/Button';
import { withApollo } from '@apollo/client/react/hoc';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import styled from 'styled-components';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import request from '../../../../js/utils/fetch';
import { GET_CRONLOGS_BY_PRODUCT } from '../../../../queries/cron_log';
import HistoryCrons from './components/HistoryCrons';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import OurTypography from '../../../ui/typography/Typography';

const CardAsset = styled(Grid)`
    background: ${colors.white};
    border: 1px solid #EDEEEE;
    padding: 16px 32px 16px 0px;
`;

function ProductsCoverRateDetail(props) {
    const [currentLang, setCurrentLang] =  React.useState(props.locales[0].node.code);
    const [loaded, setLoaded] =  React.useState(false);
    const [loadedCrons, setLoadedCrons] =  React.useState(false);
    const [cronLogs, setCronLogs] =  React.useState(null);
    const [productInfo, setProductInfo] =  React.useState(null);

    useEffect(() => {
        initCoverRate()
    })


    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const initCoverRate = () => {
        setLoaded(false)
        request(`${process.env.REACT_APP_API}/product/coverage/${props.match.params.sku}`, 'get', false).then(
            (data) => {
                setProductInfo(data);
                setLoaded(true);
                // deepcode ignore Sqli: <please specify a reason of ignoring this>
                props.client.query({
                    query: GET_CRONLOGS_BY_PRODUCT,
                    variables: {
                        "product": `/api/products/${data.id}`
                    },
                    fetchPolicy: 'no-cache'
                }).then(result =>{
                    setCronLogs(result.data.cronLogs.edges)
                    setLoadedCrons(true)
                }).catch((err)=>{
                    console.log(err)
                });
            }
        ).catch((err)=>{
            console.log(err)
        });
    }

    return (
        <div style={{overflow: "hidden"}}>
            {
                loaded && productInfo ?
                <>
                    <TopPanel
                        icomoon         = {"ico-cover-rate"} 
                        colorIcomoon    = {colors.blue.darker.hue300}
                        title           = {'Taux de couverture'}
                        subtitle        = {props.t("spread.cover_rate.detail.subtitle")}
                        gradientColor1  = {colors.menu.regular} 
                        gradientColor2  = {colors.menu.darker} 
                        // openForm        = {openForm}
                        currentLang     = {currentLang} 
                        handleLang      = {handleLang} 
                        locales         = {props.locales}
                        // handlerAdd      = {()=> initForm()} 
                        // textAdd         = {props.t("spread.merchandising.textAdd")}
                        hasBorder={true}
                    />
                    <Grid container style={{marginTop: 16, paddingBottom: 0}}>
                        <AccordionCustom defaultExpanded={true} title={<Typography variant={"h4"} style={{fontSize: 14, fontWeight: '600'}}>{props.t('spread.cover_rate.detail.general')}</Typography>}>
                            <Grid container>
                                <Grid xs={3} item style={{paddingRight: 24}}>
                                    <Box height={325} maxHeight={325} style={{backgroundImage: `url(${productInfo[currentLang].product_image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center', border: `0.5px solid ${colors.grey.lighter.hue700}`}}></Box>
                                </Grid>
                                <Grid xs={6} item style={{paddingLeft: 24}}>
                                    {/* <Typography variant="body2" style={{fontWeight: "bold", marginBottom: 4, color: colors.black.lighter, marginTop: 4}}>{props.t('spread.cover_rate.detail.description')} :</Typography> */}
                                    {/* <Typography variant="body2" style={{fontSize: 16, lineHeight: "24px"}} dangerouslySetInnerHTML={{ __html: productInfo[currentLang].product_description}}></Typography> */}
                                    <Typography variant="body2" style={{fontSize: 24, fontWeight: 700}}>
                                        {productInfo[currentLang].product_name}
                                    </Typography>
                                    <Grid container style={{marginTop: 24}}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" style={{fontWeight: "bold", marginBottom: 4, marginTop: 4, color: colors.grey.regular}}>Statuts PIM :</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <StatusInfo status={productInfo.status}/>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container style={{marginTop: 16}}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" style={{fontWeight: "bold", marginBottom: 4, marginTop: 4}}>Taux de complétude :</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" style={{fontWeight: "bold", marginBottom: 4, color: colors.black.lighter, marginTop: 4}}>{props.t('spread.cover_rate.detail.sku')} :</Typography>
                                        </Grid>
                                    </Grid> */}
                                    <Grid container style={{marginTop: 16}}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" style={{fontWeight: "bold", marginBottom: 4, marginTop: 4, color: colors.grey.regular}}>Type de blocage :</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" style={{fontWeight: "bold", marginBottom: 4, color: colors.black.lighter, marginTop: 4}}>{productInfo[currentLang].articleBloqueQualite}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{marginTop: 16}}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" style={{fontWeight: "bold", marginBottom: 4, marginTop: 4, color: colors.grey.regular}}>Date introduction :</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" style={{fontWeight: "bold", marginBottom: 4, color: colors.black.lighter, marginTop: 4}}>{productInfo[currentLang].marketIntroductionDate}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionCustom>
                    </Grid>
                    {
                        Object.keys(productInfo.catalogs).length > 0 ?
                            Object.keys(productInfo.catalogs).map((keyName, i) => {
                                let listStatus = []
                                for (let item in productInfo.catalogs[keyName].assets){
                                    if (productInfo.catalogs[keyName].assets[item]){
                                        if (productInfo.catalogs[keyName].assets[item].completude === 100){
                                            listStatus.push(true)
                                        }else{
                                            listStatus.push(false)
                                        }
                                    }
                                }
                                let checker = arr => arr.every(v => v === true);
                                return(
                                    <Grid key={i} container style={{marginTop: 8, paddingBottom: 0}}>
                                        <Grid item xs={12}>
                                            <AccordionCustom defaultExpanded={i > 0 ? false : true} title={(
                                                <Box display="flex">
                                                    {listStatus.length > 0 && checker(listStatus) ? 
                                                        <CheckCircleIcon style={{fill: "#388648"}}/> 
                                                        : <WarningIcon style={{fill: "#E01B22"}}/>
                                                    } 
                                                    <Typography variant={"h4"} style={{fontSize: 14, fontWeight: 'bold', marginLeft: 4, color: listStatus.length > 0 && checker(listStatus) ? "#388648" : "#E01B22"}}>
                                                        Catalogue numéro {(i+1).toLocaleString('en-US', {
                                                            minimumIntegerDigits: 3,
                                                            useGrouping: false
                                                        })}
                                                    </Typography>
                                                </Box>
                                            )}>
                                                {
                                                    productInfo.catalogs[keyName].assets.length !== 0 ?
                                                        <>
                                                            <Typography variant='body2' style={{fontWeight: "bold", color: colors.black.lighter}}>{props.t('spread.cover_rate.list_assets.title')}</Typography>
                                                            <Grid container style={{background: "#FAFAFA", marginTop: 16, padding: "16px 16px", maxHeight: 435, overflow:"auto"}}>
                                                                {
                                                                    Object.keys(productInfo.catalogs[keyName].assets).map((asset, i) => {
                                                                        let getProducts = Object.keys(productInfo.catalogs[keyName].assets[asset]).filter((key) => key.includes(props.match.params.sku));
                                                                        return(
                                                                            // {productInfo.catalogs[keyName].assets[asset].completude ? productInfo.catalogs[keyName].assets[asset].completude > 0 ? `${Math.round(productInfo.catalogs[keyName].assets[asset].completude)}%` : `${productInfo.catalogs[keyName].assets[asset].completude}%` : 'N/A'}

                                                                            <CardAsset key={i} item xs={12}>
                                                                                <Grid container>
                                                                                    <Grid item xs={1} style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                    }}>
                                                                                        <OurTypography colortext={colors.green.regular} fontweighttext={700}>{productInfo.catalogs[keyName].assets[asset].completude ? productInfo.catalogs[keyName].assets[asset].completude > 0 ? `${Math.round(productInfo.catalogs[keyName].assets[asset].completude)}%` : `${productInfo.catalogs[keyName].assets[asset].completude}%` : 'N/A'}</OurTypography>
                                                                                    </Grid>
                                                                                    <Grid item xs={2} style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}>
                                                                                        <OurTypography fontweighttext={700}>{productInfo.catalogs[keyName].assets[asset].asset_store_name || asset}</OurTypography>
                                                                                    </Grid>
                                                                                    <Grid item xs={1} style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "flex-end",
                                                                                        alignItems: "center",
                                                                                        gap: 15,
                                                                                    }}>
                                                                                        <span style={{
                                                                                            color: colors.grey.regular
                                                                                        }}>Prix: </span> <OurTypography fontweighttext={700}>{productInfo.catalogs[keyName].assets[asset][getProducts[0]]?.price ? `${productInfo.catalogs[keyName].assets[asset][getProducts[0]]?.price} €` : "N/A"}</OurTypography>
                                                                                    </Grid>
                                                                                    <Grid item xs={2} style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        gap: 15,
                                                                                    }}>
                                                                                        <span style={{
                                                                                            color: colors.grey.regular
                                                                                        }}>Présent e-shop: </span> <OurTypography fontweighttext={700}>{productInfo.catalogs[keyName].assets[asset].online === 1 ? "Oui" : "Non"}</OurTypography>
                                                                                    </Grid>
                                                                                    <Grid item xs={1} style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        gap: 15,
                                                                                    }}>
                                                                                        <span style={{
                                                                                            color: colors.grey.regular
                                                                                        }}>Type: </span> <OurTypography fontweighttext={700}>{productInfo.catalogs[keyName].assets[asset].type || "N/A"}</OurTypography>
                                                                                    </Grid>
                                                                                    <Grid item xs={1} style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "flex-end",
                                                                                        alignItems: "center",
                                                                                        gap: 15,
                                                                                    }}>
                                                                                        <span style={{
                                                                                            color: colors.grey.regular
                                                                                        }}>Quantité: </span> <OurTypography fontweighttext={700}>{productInfo.catalogs[keyName].assets[asset][getProducts[0]]?.stock || "N/A"}</OurTypography>
                                                                                    </Grid>
                                                                                    <Grid item xs={2} style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                        gap: 15,
                                                                                    }}>
                                                                                        <StatusInfo status={productInfo.status} statusText={productInfo.status ? props.t('spread.cover_rate.list_assets.inStock') : props.t('spread.sover_rate.list_assets.outOfStock')} fontSize={14} size={10} width='auto' />
                                                                                    </Grid>
                                                                                    <Grid item xs={2}>
                                                                                        <Button types="Info" text="Voir le produit" bgcolor={"#0273A5"} bgcolorhover={"#015d86"} style={{width: "100%", margin: 0}} onClick={() => {}}></Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </CardAsset>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </>
                                                    : <Typography style={{lineHeight: "inherit"}}>Aucun asset</Typography>
                                                }
                                            </AccordionCustom>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        : 
                        <Grid container style={{marginTop: 8, paddingBottom: 0}}>
                            <Grid item xs={12}>
                                <AccordionCustom title={<Typography variant={"h4"} style={{fontSize: 14, fontWeight: '600'}}>Aucun catalogue</Typography>}></AccordionCustom>
                            </Grid>
                        </Grid>
                        
                    }
                    <Grid container style={{marginTop: 8}}>
                        <Grid item xs={12}>
                            <AccordionCustom defaultExpanded={true} title={<Typography variant={"h4"} style={{fontSize: 14, fontWeight: '600'}}>{props.t('spread.cover_rate.detail.history')}</Typography>}>
                                {
                                    loadedCrons ?
                                        cronLogs && cronLogs.length > 0 ?
                                            <HistoryCrons rows={cronLogs} />
                                        : <Typography variant={"body2"} style={{lineHeight: 'inherit'}}>{props.t('spread.cover_rate.detail.noHistory')}</Typography>
                                    : <PageLoader />
                                }
                            </AccordionCustom>
                        </Grid>
                    </Grid>
                </>
                : <PageLoader />   
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsCoverRateDetail))));