import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "@constants/action-types";
import { Grid, List, InputLabel } from "@mui/material";
import { DELETE_COLOR } from "@queries/brand_guideline";
import styled from "styled-components";
import themeColors from "@config/theme/colors";
import ColorMarkupPicker from "./ColorMarkupPicker";
import AddIcon from "@mui/icons-material/Add";
import { withApollo } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";
import { convertToRgba } from "@utils/string.utils";
import { SpanAddCustom } from "@ui/form/components/Text/styles/styled";

const AddIconCustom = styled(AddIcon)`
  fill: white;
  background: ${themeColors.green.regular};
  border-radius: 50%;
  padding: 4px;
  width: calc(0.8em);
  height: calc(0.8em);
  margin-bottom: 10px;
`;
const FormCustom = styled(List)`
  padding: 1rem;
  width: 100%;
  background: rgb(250, 251, 251);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
const InputLabelCustom = styled(InputLabel)`
  color: ${themeColors.black.regular};
  line-height: 20px;
  display: flex;
  align-items: center;
  word-break: break-word;
`;

const ColorSelector = ({ colors, markups, onChange, ...props }) => {
  const [formColors, setFormColors] = useState(colors);

  useEffect(() => {
    onChange(formColors, "colors");
  }, [formColors]);

  const addColor = async () => {
    const tempoId = await generateTempoId();
    const newColor = {
      hex: themeColors.blue.lighter.hue150,
      tempoId,
      rgb: convertToRgba(themeColors.blue.lighter.hue150),
      changed: true,
    };
    setFormColors((prevState) => [...prevState, newColor]);
  };

  const deleteColor = (colorSelected) => {
    if (colorSelected.tempoId) {
      const newFormColorsState = formColors.filter(
        (color) => color.tempoId !== colorSelected.tempoId
      );
      setFormColors(newFormColorsState);
    } else if (colorSelected.id) {
      props.startLoading();
      props.client
        .mutate({
          mutation: DELETE_COLOR,
          variables: { id: colorSelected.id },
        })
        .then(() => {
          props.stopLoading();
          const newFormColorsState = formColors.filter(
            (color) => color.id !== colorSelected.id
          );
          setFormColors(newFormColorsState);
          props.snack("success", "La couleur a bien été supprimée");
        })
        .catch((error) => {
          props.stopLoading();
          props.snack("error", "Une erreur est survenue");
        });
    }
  };

  const generateTempoId = () => {
    return new Promise((resolve, reject) => {
      let tempoId = Math.random().toString(36).substr(2, 9);
      resolve(tempoId);
    });
  };

  const handleChangeComplete = async (newVal, oldVal) => {
    const newColor = {
      ...oldVal,
      ...newVal,
      changed: true,
    };

    const newFormColors = formColors.map((color) => {
      if (
        (newColor.id && color.id === newColor.id) ||
        (newColor.tempoId && color.tempoId === newColor.tempoId)
      ) {
        return newColor;
      }
      return color;
    });

    setFormColors(newFormColors);
  };

  const handleChangeMarkups = (markup, checked, color, index) => {
    let newColor = { ...color, changed: true };

    if (!color.markups) {
      newColor.markups = [];
    }

    if (checked) {
      newColor.markups.push(markup);
    } else {
      let i = 0;
      for (let m of newColor.markups) {
        if (m.id === markup.id) {
          newColor.markups.splice(i, 1);
        }
        i++;
      }
    }

    newColor.markups.sort(
      (a, b) => a.id.split("/").pop() - b.id.split("/").pop()
    );

    const newFormColors = formColors.map((color) => {
      if (
        (newColor.id && color.id === newColor.id) ||
        (newColor.tempoId && color.tempoId === newColor.tempoId)
      ) {
        return newColor;
      }
      return color;
    });

    setFormColors(newFormColors);
  };

  return (
    <FormCustom>
      {colors.length > 0 ? (
        <>
          {colors.map((color, index) => (
            <Grid
              container
              direction="row"
              spacing={1}
              id={index}
              key={`color-picker${index}`}
              data-id={index}
            >
              <Grid item xs={12}>
                <Grid item lg={3} xs={12}>
                  <InputLabelCustom>Couleur {index + 1}</InputLabelCustom>
                </Grid>
                <ColorMarkupPicker
                  color={color}
                  index={index}
                  listMarkups={markups}
                  listColors={formColors}
                  helper="Indiquer la couleur du libellé ici"
                  onCheckedMarkup={(markup, checked, color, index) => {
                    handleChangeMarkups(markup, checked, color, index);
                  }}
                  onChangeComplete={(newVal, oldVal) => {
                    handleChangeComplete(newVal, oldVal);
                  }}
                  deleteColor={(color) => {
                    deleteColor(color);
                  }}
                />
              </Grid>
            </Grid>
          ))}
          <div
            onClick={() => {
              addColor();
            }}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              paddingTop: "13px",
            }}
          >
            <AddIconCustom style={{ marginBottom: 0, marginRight: 10 }} />
            <SpanAddCustom>Ajouter une couleur</SpanAddCustom>
          </div>
        </>
      ) : (
        <div
          onClick={() => {
            addColor();
          }}
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            minHeight: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            paddingTop: "13px",
          }}
        >
          <AddIconCustom />
          <SpanAddCustom>Ajouter une couleur</SpanAddCustom>
        </div>
      )}
    </FormCustom>
  );
};

ColorSelector.propTypes = {
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tempoId: PropTypes.string,
      hex: PropTypes.string,
      markups: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          libelle: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  markups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      libelle: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(ColorSelector)
);
