import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import notFound from '../../../../src/assets/images/not-found.png';
import { Box, Grid } from "@mui/material";
import colors from '../../../config/theme/colors';
import styled from 'styled-components';
import Button from '../../ui/button/Button';

import './SliderDashboard.scss';
import PageLoader from "../../ui/loadings/page-loader/PageLoader";
import CardCustom from "../Card/CardCustom";
import OurLink from "../../ui/link/ReturnLink";

// import sliderData from "../../data/hero-sliders/hero-slider-twelve.json";
// import '../../assets/scss/style.scss';

const ContainerImage = styled.div`
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-image: url('${props => props.imgSrc}');
    background-repeat: no-repeat;
    background-size: 100%;
`
const Title = styled.h1`
    line-height: 32px;
    color: ${colors.black.regular} !important;
    font-weight: bold !important;
    margin: 0 !important;
`

const TextActu = styled.h5`
    line-height: 18px !important;
    color: ${colors.black.regular} !important;
    font-weight: lighter !important;
    margin: 0 !important;
    margin-top: 12px;
    white-space: pre-line;
`

const ImageArticle = styled.img`
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
`
export default function SliderActu(props) {
  const [sliderParams, setSliderParams] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let params = {
      // effect: "fade",
      slidesPerView: props.sliderData.length > 1 ? props.windowWidth > 1200 ? 2 : 1 : 1,
      loop: false,
      // speed: 250,
      // autoHeight: true,
      grabCursor: props.sliderData.length > 0 ? true : false,
      spaceBetween: 17,
      // autoplay: {
      //   delay: 7000,
      //   disableOnInteraction: true
      // },
      watchSlidesVisibility: true,
      shouldSwiperUpdate: true,
      rebuildOnUpdate: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true
      },
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev"
      // },
      // renderPrevButton: () => (
      //   <button className="swiper-button-prev ht-swiper-button-nav">
      //     <i className="pe-7s-angle-left" />
      //   </button>
      // ),
      // renderNextButton: () => (
      //   <button className="swiper-button-next ht-swiper-button-nav">
      //     <i className="pe-7s-angle-right" />
      //   </button>
      // )
    };

    if (props.sliderData.length <= 1) {
      params.pagination = false
    }

    setSliderParams(params);
    setReady(true);
  }, [props.windowWidth]);

  return (
    ready && sliderParams ?
      <div id="swiper-dashboard" style={{ paddingRight: 16, paddingLeft: 16 }}>
        <div className="slider-area">
          <div className="slider-active-2 nav-style-2">
            <Swiper {...sliderParams}>
              {props.sliderData &&
                props.sliderData.map((single, key) => {
                  return (
                    <Grid
                      className={`swiper-slide`}
                      style={{
                        background: 'white',
                        overflow: 'hidden',
                      }}
                      key={key}
                      container
                      alignItems="stretch"
                    >
                      <CardCustom contentpadding="0" style={{ overflow: 'hidden' }}>
                        <Grid container style={{
                          height: props.windowWidth > 1200 ? '100%' : '500px',
                          minHeight: "auto"
                        }} direction={"row"} alignItems="center" justifyContent="space-between">
                          <Grid item xs={props.windowWidth > 1200 ? 7 : 12} style={{ minHeight: props.windowWidth > 1200 ? '100%' : 'auto', order: props.windowWidth > 1200 ? '1' : '2' }}>
                            <Grid container alignContent="flex-start" direction={props.windowWidth > 1200 ? "row" : "column"} style={{ height: props.windowWidth > 1200 ? '100%' : '60%', marginTop: props.windowWidth > 1200 ? '0' : 16, padding: props.windowWidth > 1200 ? '24px 0px 24px 24px' : '24px' }}>
                              <Box style={{ display: 'flex', flexDirection: 'column', flex: props.windowWidth > 1200 ? '0 1 auto' : '1' }}>
                                <Box>
                                  <Title className="animated" style={{
                                    fontSize: props.sliderData.length === 1 ? '25px' : '1.5em',
                                  }}>{single.title}</Title>
                                </Box>
                                <Box mt={1} style={{
                                  marginBottom: props.sliderData.length > 1 ? '20px' : '40px'
                                }}>
                                  <TextActu className="animated" style={{
                                    fontSize: props.sliderData.length === 1 ? '16px' : '1.0em',
                                  }}>{single.text}</TextActu>
                                </Box>
                              </Box>
                              <Box>
                                {
                                  single.buttons.map((button, index) => {
                                    return (
                                      <OurLink
                                        key={index}
                                        link={button.link}
                                        text={button.text}
                                        style={{
                                          marginTop: 0,
                                          padding: "12px 40px",
                                          fontSize: props.sliderData.length === 1 ? '16px' : '12px',
                                        }}
                                      />
                                    )
                                  })
                                }
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid item xs={props.windowWidth > 1200 ? 5 : 12} style={{
                            order: props.windowWidth > 1200 ? '2' : '1',
                            height: props.windowWidth > 1200 ? '100%' : '50%'
                          }}>
                            <ContainerImage className="animated" style={{
                              // minHeight: props.windowWidth > 1200 ? '100%' : '208px',
                              height: '100%',
                              backgroundImage: `url(${single.image})`,
                              backgroundSize: props.sliderData.length === 1 ? "cover" : 'contain',
                              backgroundPosition: 'center',
                            }}>
                            </ContainerImage>
                          </Grid>
                        </Grid>
                      </CardCustom>
                    </Grid>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </div>
      : <PageLoader />
  );
}