import { Box, Button,Modal } from "@mui/material"
import { useState } from "react"
import styled from "styled-components"
import colors from "../../../config/theme/colors"
import OurButton from "../../ui/button/Button"
import Slider from "react-slick";
import { useRef } from "react"
import PageLoader from "../../ui/loadings/page-loader/PageLoader"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from '@mui/styles';
import fakeImage from '../../../assets/images/image-fake.png';

const Wrapper = styled(Box)`
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:${props=> props.images?.length === 1 ? "1fr":"1fr 1fr"};
    gap:5px;
    position:relative;
`

const MediaWrapper = styled(Box)`
    width:300px;
    height:300px;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    max-height:${props=> props.images?.length === 1 ? "400px":"290px"};
`
const ButtonWrapper = styled(Box)`
    position:absolute;
    bottom:0;
    left:50%;
    transform:translate(-50%,50%);
`

const ContainerSliderModal = styled.div`
  max-width: 50%;
  &:focus-visible {
    outline: none !important;
  }
  *:focus,*:focus-visible {
    outline: none !important;
  }
`

const CustomDiv = styled.div`
    height:100%;
    overflow:hidden;
    &> div >div{
      background-size:contain !important;
    }
`

const ThumbnailZoom = styled.img`
    width:100%;
    height:600px;
    cursor:pointer;
    object-fit:contain;
`

const useStyles = makeStyles((theme) => ({
    arrow: {
      display:'block',    
      zIndex:2,
      fill:'black',
      position:"absolute",
      backgroundColor:'white',
      border:"0.5px solid black",
      borderRadius:"50%",
      width:'30px',
      height:'30px',
      opacity:'1', 
      "&:hover":{
        opacity:'1 !important',
        fill:'black',
        backgroundColor:'white',
      }   
    },
    right:{
      right:'-20px'
    },
    left:{
      left:'-20px'
    }
  }));
  

const CarouselProductDetail = (props) => {
    const {images,imagesWithoutPlaceholder}=props
    const [openModal,setOpenModal]= useState(false)
    const [initialModalSlide,setInitialModalSlide]= useState(null)
    const ref = useRef()
    const classes = useStyles();

    const numberOfPlaceholders = 4 - images.length
    const placeholders = Array.from({ length: numberOfPlaceholders }, (_, index) => index)

    const checkMediaType = (image)=>{
      const suffix =image.substring(image.lastIndexOf('.') +1,image.length) 
      let type
      if (suffix === 'mp4') {
        type = "video"
      }else{
        type = 'image'
      }
      return type
    }

    function Arrow(props) {
        const { className, style, onClick } = props;
        const handle = ()=>{
          if (props.value === 'next') {
            ref.current.slickGoTo(initialModalSlide + 1,true)
          }else if (props.value === 'prev') {
            ref.current.slickGoTo(initialModalSlide - 1,true)
          }
        }
        return (
          props.value === 'next'?
          <ChevronRightIcon 
            onClick={handle} 
            className={[classes.arrow,classes.right,className]} 
            style={{...style,display:"block"}}
          />
          :<ChevronLeftIcon 
              onClick={handle} 
              className={[classes.arrow,classes.left,className]}  
              style={{...style,display:"block"}}
          />
        );
      } 

    const handleOpen = (e)=>{    
        setOpenModal(true)    
      }
  
      const handleClose = ()=>{
        setOpenModal(false)   
        setInitialModalSlide(null)
      }

    const handleSlide = (e,i)=>{     
      if (imagesWithoutPlaceholder &&i < imagesWithoutPlaceholder.length) {
        setInitialModalSlide(i)
        handleOpen()
      }
    }

    const settings = {
        infinite: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,            
        autoplay: false,    
        arrows:true ,
        swipe:true,     
        initialSlide:initialModalSlide,
        nextArrow: <Arrow value={'next'}/>,
        prevArrow: <Arrow value={'prev'}/>,
        beforeChange:(oldIndex,newIndex)=>{setInitialModalSlide(newIndex)},      
      };

    return (
        <Wrapper images={imagesWithoutPlaceholder}>
            {
                images.map((image,index)=>{
                    const type = checkMediaType(image)
                    if (index< 4) {
                    return (                                     
                        <MediaWrapper images={imagesWithoutPlaceholder}>
                          {
                            type === "video" ?
                              <video 
                                onClick={(e)=>handleSlide(e,index)} 
                                style={{
                                  height:"100%",
                                  width:"100%",
                                  objectFit:"cover",
                                  cursor:"pointer"
                                }} 
                                muted 
                                autoPlay 
                                repeat>
                                    <source src={image} type="video/mp4"/>                                                                
                              </video>
                            :<img                                                          
                              onClick={(e)=>handleSlide(e,index)}
                              src={image} 
                              alt=''
                              style={{
                                width:"100%",
                                height:"100%",
                                objectFit:"cover",
                                margin:"auto",
                                border:`1px solid ${colors.grey.border}`,
                                backgroundColor:'#F6F6F6'
                              }}
                            />
                          }
                        </MediaWrapper>
                    )
                    }
                })
            }
            {
                placeholders.map((_,index)=>(
                  <MediaWrapper 
                    key={`placeholder - ${index}`} 
                    images={imagesWithoutPlaceholder}
                  >
                    <img
                      onClick={(e)=>handleSlide(e,index + imagesWithoutPlaceholder.length)}
                      src={fakeImage}
                      alt='Placeholder'
                      style={{
                        width:"100%",
                        height:"100%",
                        objectFit:"cover",
                        margin:"auto",
                        cursor:"default",
                        border:`1px solid ${colors.grey.border}`,
                        backgroundColor:'#F6F6F6'
                      }}
                    />
                  </MediaWrapper>
                ))
            }
            {
              imagesWithoutPlaceholder?.length > 4?
                <ButtonWrapper>
                    <OurButton
                        style={{
                            margin:0,
                            height:"auto",
                            padding:"5px 10px",
                            borderRadius:"20px"
                        }}
                        bgcolor={"#FFF"}
                        bgcolorhover={"#FFF"}
                        color={"#000"}
                        colorhover={"#000"}
                        border={`1px solid ${colors.black.regular}`}
                        onClick={handleOpen}
                    >
                        Voir plus
                    </OurButton>
                </ButtonWrapper>
              :null
            }
            <Modal              
              disableEnforceFocus={true}
              open={openModal}     
              onClose={handleClose}                                     
              style={{
                  display:"flex",
                  alignItems:'center',
                  justifyContent:"center"
                }}
                >            
                <ContainerSliderModal>                                    
                    <Slider                     
                    {...settings}       
                    ref={ref}                                                            
                    >
                    {     
                      !imagesWithoutPlaceholder ? (
                      <Box>
                          <PageLoader/>
                      </Box>
                      ) : 
                      imagesWithoutPlaceholder.map((image,i)=>{
                        const type = checkMediaType(image)
                        return (  
                          <CustomDiv>
                            {
                            type === "video" ?
                              <video 
                                onClick={(e)=>handleSlide(e,i)} 
                                style={{
                                  height:"600px",
                                  width:"600px",
                                  objectFit:"cover",
                                  cursor:"pointer"
                                }} 
                                muted 
                                autoPlay 
                                repeat
                              >
                                    <source src={image} type="video/mp4"/>                                                                
                              </video>
                            :<ThumbnailZoom 
                            key={i} 
                            src={image} 
                            alt=''
                          />   
                          }                                                     
                          </CustomDiv>
                        )
                      })
                    }
                    </Slider>     
                </ContainerSliderModal>
            </Modal>              
        </Wrapper>
    )
}

export default CarouselProductDetail