export const brandsTableMapper = async (flows,lang) =>{
    try {
        let results = flows.map((flow,index)=>{
            return {
                id:flow.node.id,
                libelle: flow.node.libelle,
                description: flow.node.description,
                status: flow.node.status,
                totalProductCount:flow.node.product.totalCount,
                scrapSources : flow.node.productSourceScraps.edges.map(e=>e.node.name),
                media: flow.node.media?.filePath,
                percentCompletude : 100 - (flow.node.productSourceProductStates?.totalCount*100/flow.node.product?.totalCount),
            }
        })
        return await results
    } catch (error) {
        console.log(error)   
    }
}

export const brandsCardMapper = async (flows,lang) =>{
    try {
        let results = flows.map((flow,index)=>{
            return {
                id:flow.node.id,
                libelle: flow.node.libelle,
                description: flow.node.description,
                status: flow.node.status,
                totalProductCount:flow.node.product.totalCount,
                scrapSources : flow.node.productSourceScraps.edges.map(e=>e.node.name),
                media: flow.node.media?.filePath,
                percentCompletude : 100 - (flow.node.productSourceProductStates?.totalCount*100/flow.node.product?.totalCount),
            }
        })
        return await results
    } catch (error) {
        console.log(error)   
    }
}