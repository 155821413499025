import React, { useEffect } from 'react';
import { Box, CircularProgress, Grid, Select, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import { withApollo } from '@apollo/client/react/hoc';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import '../../../navigation/DrawerLeft.scss';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { eventService } from '../../../../js/services/event.service';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import request from '../../../../js/utils/fetch';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import { GET_FILE_IMPORT_BY_SCRAP_STATUS, UPDATE_FILE_IMPORT } from '../../../../queries/file_import';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import moment from 'moment';
import OurButton from '../../../ui/button/Button';
import DialogModal from '../../../ui/dialog/DialogModal';
import CheckIcon from '@mui/icons-material/Check';
import RichIcon from '../../../../assets/pictos/scrap.svg';

const SelectCustom = styled(Select)`
    border-radius: 0; 
    // width: 100%; 
    max-width: 170px; 
    margin-right: 16;
    fieldset{
        border: none;
    }
    :before, &:after{
        display: none !important;
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 16px;
        padding-left: 8px;
        margin-right: 16px;
        color: ${colors.blue.darker.hue300};
        &:focus{
            background: none;
        }
        @media screen and (max-width: 1450px){
            font-size: 14px;
            line-height: 18px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            line-height: 17px;
        }
        @media screen and (max-width: 960px){
            font-size: 12px;
            line-height: 16px;
        }
    }
    svg{
        fill: ${colors.blue.darker.hue300};
    }
    & > p{
        margin: 0;
    }
`;

const Cell = styled(TableCell)`
    border-bottom:none;
    color : ${props=> props.green?colors.green.regular:"inherit"};
`

function ListProductsImport(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [reloadBrands, setReloadBrands] =  React.useState(false);
    const [displaySeasons, setDisplaySeasons] =  React.useState('none');
    const [toRichImports, setToRichImports] =  React.useState(null);
    const [validateImports, setValidateImports] =  React.useState(null);
    const [openModal, setOpenModal] =  React.useState(null);
    const [idToModal, setIdToModal] =  React.useState(null);

    //--- call 1 fois dans le cycle de vie du composant
    useEffect(() => {
    }, [])

    const handleLang = (event) => {
        setCurrentLang(event.target.value);
        eventService.fire();
    };

    const handleExportProducts = () => {
        try {
            props.startLoading();
            let data = new FormData();
            data.append('name', 'export-nouveautes');
            data.append('format', 'csv');
            data.append('locale', 'fr_FR');
            data.append('status', 'all');
            data.append('image', 'all');
            data.append('onlyNew', "true");

            let urlencoded = new URLSearchParams(data).toString();

            request(`${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`, 'get', null, 'application/x-www-form-urlencoded')
                .then(async (data) => {
                    if (data.success) {
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath + '.csv'}`, '_blank');
                        props.stopLoading();
                        props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                    } else {
                        props.stopLoading();
                        props.snack(ALERT_ERROR, `Un problème est survenu lors de l'exportation !`);
                    }
                }).catch((error) => {
                    props.stopLoading();
                    props.snack(ALERT_ERROR, `Un problème est survenu lors de l'exportation !`);
                });
        } catch (e) {
            props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            props.stopLoading();
        }
    };

    const getImports =()=>{
        props.client.query({
            query: GET_FILE_IMPORT_BY_SCRAP_STATUS,
            fetchPolicy: "no-cache",
            variables: {
                scrapStatus:[0,1,2,99]
            }
        })
        .then((result)=>{       
            let sortedData = result.data.customFileImports?.edges.sort((a,b)=>new Date(b.node.importedAt) - new Date(a.node.importedAt))
            setToRichImports(sortedData)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getImportsValidate =()=>{
        props.client.query({
            query: GET_FILE_IMPORT_BY_SCRAP_STATUS,
            fetchPolicy: "no-cache",
            variables: {
                scrapStatus:[3]
            }
        }).then((result)=>{
            let sortedData = result.data.customFileImports?.edges.sort((a,b)=>new Date(b.node.importedAt) - new Date(a.node.importedAt))
            setValidateImports(sortedData)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getStatus = (status)=>{
        let value
        let color
        switch (status) {
            case "0":
                value =  <span>À enrichir</span>
                color= colors.blue.darker.hue300
                break;
            case "1":
            case "2":
            case "99":
                value =<span>En cours</span>
                color= colors.blue.darker.hue300
                break;
            case "3":
                value = <span>Validé</span>
                color= colors.green.regular
                break;
        
            default:
                break;
        }
        return {
            value,color
        }
    }


    useEffect(()=>{
        if(!validateImports||!toRichImports){
            getImports()
            getImportsValidate()
        }
    },[validateImports,toRichImports])

    const reset = ()=>{
        setValidateImports(null)
        setToRichImports(null)
    }

    const updateFileImport = (id)=>{
        props.client.query({
            query: UPDATE_FILE_IMPORT,
            fetchPolicy: "no-cache",
            variables: {
                id:id,
                scrapStatus:1
            }
        }).then((result)=>{
            reset()
        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleModal = (id)=>{
        setIdToModal(id)
        setOpenModal(!openModal)
    }

    const getDelay = (nb,type)=>{
        const productDelay = type === 'import' ?15 : 30
        const seconds = nb * productDelay
        const result = moment().add(seconds,"s").fromNow(true)
        return result
    }

    return (
        <div>
            <TopPanel
                title={props.t('products.workflow.title')}
                subtitle={props.t('products.workflow.subtitle')}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <Grid container direction='column'>
                <Grid item xs={12} style={{marginBottom:20}}>
                    <AccordionCustom title={"Imports en cours"}>
                        {
                            toRichImports?
                            toRichImports?.length > 0?
                            <TableContainer style={{borderRight:"1px solid rgba(224, 224, 224, 1)",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}>
                                <Table>
                                    <TableBody>
                                      {toRichImports.map((row) => (
                                        <TableRow style={{borderTop:"1px solid rgba(224, 224, 224, 1)",borderBottom:"1px solid rgba(224, 224, 224, 1)"}} key={row.node.id}>
                                            <Cell component="th" scope="row">
                                                <div style={{display:"flex",alignItems:"center",gap:5,justifyContent:"center"}}>
                                                    <div
                                                        style={{
                                                            backgroundColor:getStatus(row.node.scrapStatus).color,
                                                            height:"10px",
                                                            width:"10px",
                                                            borderRadius:"100%"
                                                        }}
                                                    />
                                                    <span>{getStatus(row.node.scrapStatus).value}</span>
                                                </div>
                                            </Cell>
                                            <Cell align="left">
                                                <Typography style={{fontWeight:"bold"}} >
                                                    {moment(row.node.importedAt).format("DD/MM/YYYY")}
                                                    <span style={{fontWeight:"normal"}} >{moment(row.node.importedAt).format(" à HH:mm:ss")}</span>
                                                </Typography>                                                
                                            </Cell>
                                            <Cell align="left">
                                                <Typography style={{fontWeight:"bold"}} >
                                                    {row.node.url}
                                                </Typography>           
                                            </Cell>
                                            <Cell align="right">
                                                <Typography style={{fontWeight:"bold"}} >
                                                    <span style={{fontWeight:"normal"}}>Par </span>
                                                    {row.node.user.lastname} {row.node.user.firstname}
                                                </Typography>                        
                                            </Cell>
                                            <Cell align="right">{row.node.productSource.libelle}</Cell>
                                            <Cell align="right">{row.node.totalImported}</Cell>
                                            <Cell align="right" style={{ width: "280px" }}>
                                                    {/* <OurButton 
                                                        edit={"edit"}
                                                        margin={"0"} 
                                                        bgcolor={colors.blue.lighter.hue900}
                                                        bgcolorhover={colors.blue.lighter.hue900}
                                                        color={colors.blue.darker.hue300}
                                                        border={`1px solid ${colors.blue.darker.hue300}`}
                                                        onClick={()=>handleModal(row.node.id)}
                                                    /> */}
                                                {
                                                    row.node.scrapStatus === "0"?
                                                    <OurButton 
                                                    text="Lancer l'enrichissement"
                                                        margin={"0"} 
                                                        bgcolor={colors.blue.lighter.hue900}
                                                        bgcolorhover={colors.blue.lighter.hue900}
                                                        color={colors.blue.darker.hue300}
                                                        border={`1px solid ${colors.blue.darker.hue300}`}
                                                        onClick={()=>handleModal(row.node.id)}
                                                    >
                                                        <img src={RichIcon} alt={'icone enrichissement'} style={{marginRight:5}}/>
                                                      
                                                    </OurButton>
                                                    :row.node.scrapStatus === "2"|| row.node.scrapStatus === "1"?
                                                    <Box style={{
                                                        display:"flex",
                                                        alignItems:"center",
                                                        width:"100%",
                                                        gap:5
                                                    }}>
                                                        <CircularProgress style={{marginRight:5,color:colors.blue.darker.hue300}} size={20}/>
                                                        <Box>
                                                            <Typography style={{color:colors.black.darker,textAlign:"start",fontWeight:"bold"}}>
                                                                Enrichissement en cours…
                                                            </Typography>
                                                            <Typography variant='body1' style={{textAlign:"start",fontSize:10,color:colors.grey.lighter.hue600}}>Temps estimé : environ {getDelay(row.node.totalImported)}</Typography>
                                                        </Box>
                                                    </Box>
                                                    : row.node.scrapStatus === "99"?       
                                                    <Box style={{
                                                        display:"flex",
                                                        alignItems:"center",
                                                        width:"100%",
                                                        gap:5
                                                    }}>
                                                        <CircularProgress style={{marginRight:5,color:colors.blue.darker.hue300}} size={20}/>
                                                        <Box>
                                                            <Typography style={{color:colors.black.regular,textAlign:"start",fontWeight:"bold"}}>Import en cours…</Typography>
                                                            <Typography variant='body1' style={{textAlign:"start",fontSize:10,color:colors.grey.lighter.hue600}}>Temps estimé : environ {getDelay(row.node.totalRow,'import')}</Typography>
                                                        </Box>
                                                    </Box>                                             
                                                    :null
                                                }
                                            </Cell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                                :<p style={{color:colors.grey.lighter.hue800}}>Aucun import en cours</p>
                            :<PageLoader/>
                        }
                    </AccordionCustom>
                </Grid>
                <Grid item xs={12}>
                    <AccordionCustom title={
                        <Typography style={{color:colors.green.regular,display:"flex",alignItems:"center",gap:5}}>
                            Liste des imports terminés <CheckIcon/>
                        </Typography>
                        }>
                        {
                            validateImports?
                            validateImports?.length > 0?
                            <TableContainer style={{borderRight:`1px solid ${colors.green.regular}`,borderLeft:`1px solid ${colors.green.regular}`,background:"#4CAA5F1A"}}>
                                <Table>
                                    <TableBody>
                                      {validateImports.map((row) => (
                                        <TableRow style={{borderTop:`1px solid ${colors.green.regular}`,borderBottom:`1px solid ${colors.green.regular}`}} key={row.node.id}>
                                            <Cell green component="th" scope="row">
                                                <div style={{display:"flex",alignItems:"center",gap:5,justifyContent:"center"}}>
                                                    <div
                                                        style={{
                                                            backgroundColor:getStatus(row.node.scrapStatus).color,
                                                            height:"10px",
                                                            width:"10px",
                                                            borderRadius:"100%"
                                                        }}
                                                    />
                                                    <span>{getStatus(row.node.scrapStatus).value}</span>
                                                </div>
                                            </Cell>
                                            <Cell green align="left">
                                                <Typography style={{fontWeight:"bold",color:colors.green.regular}} >
                                                    {moment(row.node.importedAt).format("DD/MM/YYYY")}
                                                    <span style={{fontWeight:"normal"}} >{moment(row.node.importedAt).format(" à HH:mm:ss")}</span>
                                                </Typography>           
                                            </Cell>
                                            <Cell green align="left">
                                                <Typography style={{fontWeight:"bold",color:"inherit"}}>
                                                    {row.node.url}
                                                </Typography>           
                                            </Cell>
                                            <Cell green align="right">
                                                <Typography style={{fontWeight:"bold",color:colors.green.regular}} ><span style={{fontWeight:"normal"}}>Par</span> {row.node.user.lastname} {row.node.user.firstname}</Typography>
                                            </Cell>
                                            <Cell green align="right">{row.node.productSource.libelle}</Cell>
                                            <Cell green align="right">{row.node.totalImported}</Cell>
                                            <Cell green align="right" style={{ width: "180px" }}>
                                                <Box style={{
                                                    display:"flex",
                                                    alignItems:"center",
                                                    gap:5,
                                                    justifyContent:"end"
                                                }}>
                                                    <CheckIcon/>
                                                    Import terminé
                                                </Box>
                                            </Cell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                                :<p>Aucun résultat</p>
                            :<PageLoader/>
                        }
                    </AccordionCustom>
                </Grid>
            </Grid>
            <DialogModal
            open={openModal}
            title="Êtes-vous sûr de vouloir lancer l’enrichissement"
            color={colors.blue.darker.hue300}
            primaryText={"Lancer l'enrichissement"}
            primarycolorhover={"#FFF"}
            secondaryText={"Annuler l'action"}
            primaryAction={()=>{updateFileImport(idToModal);setOpenModal(!openModal)}}
            secondaryAction={()=>setOpenModal(!openModal)}
            primarybgcolor={colors.blue.darker.hue300}
            primarybgcolorhover={colors.blue.darker.hue300}
            maxWidth={"50%"}
            onClose={()=>setOpenModal(!openModal)}
            >
                <div>Si vous lancer le scrapping il vous sera impossible de travailler sur les produits en meme temps. Si vous ne souhaitez pas lancer l’enrichissement, annuler en cliquant sur annuler</div>
            </DialogModal>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),        
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListProductsImport)))));