import React, { useEffect } from 'react';
import OurTypography from 'ui/typography/Typography';
import OurButton from 'ui/button/Button';
import colors from 'colors';
import { ROUTE_OPTIMISATION_CONFIGURATION, ROUTE_OPTIMISATION_CONFIGURATION_CATEGORISATION_AUTO, ROUTE_OPTIMISATION_CONFIGURATION_RULES, ROUTE_OPTIMISATION_CONFIGURATION_SCRAPPING, ROUTE_OPTIMISATION_NORMALISATION, ROUTE_OPTIMISATION_NORMALISATION_ACTIVITIES, ROUTE_OPTIMISATION_NORMALISATION_FLUX, ROUTE_OPTIMISATION_NORMALISATION_TASKS } from 'js/constants/route-names';
import TopPanel from '../TopPanel/TopPanel';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { withApollo } from '@apollo/client/react/hoc';
import { SET_BREADCRUMB, SNACK, START_LOADING, STOP_LOADING } from 'js/constants/action-types';
import { connect } from 'react-redux';
import { ROUTE_DIFFUSION_NEW_CONFIGURATION, ROUTE_DIFFUSION_NEW_ESHOP_SALES, ROUTE_PRODUCTS_BUNDLES, ROUTE_STATS_ORDERS, ROUTE_SUPERVISION, ROUTE_SUPERVISION_COMPARE, ROUTE_SUPERVISION_COMPARE_DASHBOARD, ROUTE_SUPERVISION_COMPARE_LIST, ROUTE_SUPERVISION_CONFIGURATION, ROUTE_SUPERVISION_CONFIGURATION_COMPLETNESS, ROUTE_SUPERVISION_CONFIGURATION_RULES, ROUTE_SUPERVISION_CONFIGURATION_SITE, ROUTE_SUPERVISION_VIEW } from '../../../js/constants/route-names';

const PlaceholderView = (props) => {
    const [currentPage, setCurrentPage] = React.useState(null);
    const [panelRef, setPanelRef] = React.useState(null);
    const [currentSection, setCurrentSection] = React.useState(null);

    useEffect(()=>{
        const current = values.find((value)=>value.route === props.history.location.pathname)
        props.setBreadcrumb(current?.breadcrumb??[])
        setCurrentPage(current)
        if (props.history.location.pathname.includes("centralisation")) {
            setCurrentSection("centralisation")
        }
        if (props.history.location.pathname.includes("diffusion")) {
            setCurrentSection("diffusion")
        }
        if (props.history.location.pathname.includes("optimisation")) {
            setCurrentSection("optimisation")
        }
        if (props.history.location.pathname.includes("supervision")) {
            setCurrentSection("supervision")
        }
        if (props.history.location.pathname === "/centralisation/products/bundles") {
            setCurrentSection("bundle")
        }
    },[props.history.location.pathname])


    const values = [
        {
            route: ROUTE_OPTIMISATION_NORMALISATION,
            title: "Vue d'ensemble",
            subtitle:'Gestion de la normalisation de la données',
            breadcrumb : [{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'drawer.optimisation' }, { url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'Normalisation' },]
        },
        {
            route: ROUTE_OPTIMISATION_NORMALISATION_FLUX,
            title: 'Workflow flux',
            subtitle:'Liste des produits à travailler pour la normalisation',
            breadcrumb : [{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'drawer.optimisation' }, { url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'Normalisation' },{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'Flux' }]
        },
        {
            route: ROUTE_OPTIMISATION_NORMALISATION_TASKS,
            title: 'Tâches',
            subtitle:'Liste des produits à assigner aux agents',
            breadcrumb : [{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'drawer.optimisation' }, { url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'Normalisation' },{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'Tâches' }]
        },
        {
            route: ROUTE_OPTIMISATION_NORMALISATION_ACTIVITIES,
            title: "Suivi d'activités",
            subtitle:'Liste des produits assignés aux agents',
            breadcrumb : [{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'drawer.optimisation' }, { url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'Normalisation' },{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'Activités' }]
        },
        {
            route: ROUTE_OPTIMISATION_CONFIGURATION,
            title: 'Configuration',
            subtitle:'Gestion de vos configurations (règles de gestion, sites à scrapper, catégorisation automatique)',
            breadcrumb : [{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'drawer.optimisation' }, { url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'Configurations' }]
        },
        {
            route: ROUTE_OPTIMISATION_CONFIGURATION_RULES,
            title: 'Règles de gestion',
            subtitle:'Liste des règles de gestion',
            breadcrumb : [{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'drawer.optimisation' },{ url: ROUTE_OPTIMISATION_CONFIGURATION, identifier: 'Configurations' }, { url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'Règles de gestion' }]
        },
        {
            route: ROUTE_OPTIMISATION_CONFIGURATION_SCRAPPING,
            title: 'Sites à scrapper',
            subtitle:'Liste des sites à scrapper',
            breadcrumb : [{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'drawer.optimisation' },{ url: ROUTE_OPTIMISATION_CONFIGURATION, identifier: 'Configurations' }, { url: ROUTE_OPTIMISATION_CONFIGURATION_SCRAPPING, identifier: 'Sites à scrapper' }]
        },
        {
            route: ROUTE_OPTIMISATION_CONFIGURATION_CATEGORISATION_AUTO,
            title: 'Catégorisation automatique',
            subtitle:'Gestion de la catégorisation automatique',
            breadcrumb : [{ url: ROUTE_OPTIMISATION_NORMALISATION, identifier: 'drawer.optimisation' },{ url: ROUTE_OPTIMISATION_CONFIGURATION, identifier: 'Configurations' }, { url: ROUTE_OPTIMISATION_CONFIGURATION_SCRAPPING, identifier: 'Catégorisation automatique' }]
        },
        {
            route: ROUTE_DIFFUSION_NEW_CONFIGURATION,
            title: 'Configuration',
            subtitle:'Gestion de vos configurations',
            breadcrumb : [{ url: ROUTE_DIFFUSION_NEW_ESHOP_SALES, identifier: 'drawer.diffusion' },{ url: ROUTE_OPTIMISATION_CONFIGURATION, identifier: 'Configurations' }]
        },
        {
            route: ROUTE_PRODUCTS_BUNDLES,
            title: 'Bundles',
            subtitle:'Liste de vos bundles (création / modification/ suppression)',
            breadcrumb : [{ url: ROUTE_STATS_ORDERS, identifier: 'drawer.centralisation' },{ url: ROUTE_PRODUCTS_BUNDLES, identifier: 'Bundles' }],
        },
        {
            route: ROUTE_SUPERVISION_VIEW,
            title: 'Vue',
            subtitle:"Vue d'ensemble pour gérer vos vues de supervision dans la plateforme",
            breadcrumb : [{ url: ROUTE_SUPERVISION_VIEW, identifier: 'drawer.supervision' },{ url: ROUTE_SUPERVISION_VIEW, identifier: 'Vue' }]
        },
        {
            route: ROUTE_SUPERVISION_COMPARE,
            title: 'Comparateur de prix',
            subtitle:"Gestion du comparateur de prix",
            breadcrumb : [{ url: ROUTE_SUPERVISION_VIEW, identifier: 'drawer.supervision' },{ url: ROUTE_SUPERVISION_COMPARE, identifier: 'Comparateur de prix' }]
        },
        {
            route: ROUTE_SUPERVISION_COMPARE_DASHBOARD,
            title: 'Dashboard',
            subtitle:"Dashboard supervision",
            breadcrumb : [{ url: ROUTE_SUPERVISION_VIEW, identifier: 'drawer.supervision' },{ url: ROUTE_SUPERVISION_COMPARE_DASHBOARD, identifier: 'Dashboard' }]
        },
        {
            route: ROUTE_SUPERVISION_COMPARE_LIST,
            title: 'Comparateur de prix',
            subtitle:"Liste des produits",
            breadcrumb : [{ url: ROUTE_SUPERVISION_VIEW, identifier: 'drawer.supervision' },{ url: ROUTE_SUPERVISION_COMPARE_LIST, identifier: 'Comparateur de prix' }]
        },
        {
            route: ROUTE_SUPERVISION_CONFIGURATION,
            title: 'Configuration',
            subtitle:"Gestion de vos configurations (règles / sites à analyser ... )",
            breadcrumb : [{ url: ROUTE_SUPERVISION_VIEW, identifier: 'drawer.supervision' },{ url: ROUTE_SUPERVISION_CONFIGURATION, identifier: 'Configuration' }]
        },
        {
            route: ROUTE_SUPERVISION_CONFIGURATION_RULES,
            title: 'Règles',
            subtitle:"Gestion de vos règles",
            breadcrumb : [{ url: ROUTE_SUPERVISION_VIEW, identifier: 'drawer.supervision' },{ url: ROUTE_SUPERVISION_CONFIGURATION_RULES, identifier: 'Règles' }]
        },
        {
            route: ROUTE_SUPERVISION_CONFIGURATION_SITE,
            title: 'Site à analyser',
            subtitle:"Gestion des sites à analyser",
            breadcrumb : [{ url: ROUTE_SUPERVISION_VIEW, identifier: 'drawer.supervision' },{ url: ROUTE_SUPERVISION_CONFIGURATION_SITE, identifier: 'Site à analyser' }]
        },
        {
            route: ROUTE_SUPERVISION_CONFIGURATION_COMPLETNESS,
            title: 'Complétude',
            subtitle:"Gestion de la complétude",
            breadcrumb : [{ url: ROUTE_SUPERVISION_VIEW, identifier: 'drawer.supervision' },{ url: ROUTE_SUPERVISION_CONFIGURATION_COMPLETNESS, identifier: 'Complétude' }]
        }
    ]

    return (
        <div style={{
            position:"relative"
        }}>
            <TopPanel
                setRef={setPanelRef}
                getRef
                title={currentPage?.title}
                subtitle={currentPage?.subtitle}
            />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: `calc(100% - ${panelRef}px)`,
                gap: '10px',
            }}>
                <OurTypography colortext={colors.black.regular} fontSize="25px" fontWeight="bolder" style={{ textAlign: 'center' }}>
                    Vous n'avez pas encore <br/> <br/> configuré la partie {currentSection}.
                </OurTypography>
                <OurTypography colortext={colors.sinfinNewMenu.normalAccordion} fontSize="13px" style={{ textAlign: 'center' }}>
                    Cliquez sur le bouton ci-dessous pour une demande d'information.
                </OurTypography>
                <OurButton bgcolor={colors.blue.darker.hue300} style={{ marginTop: '10px' }} onClick={()=>{}}>
                    Contactez-nous
                </OurButton>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) =>dispatch({ type: SNACK, payload: { type, message } }),
        setBreadcrumb: (breadcrumb) => dispatch({ type: SET_BREADCRUMB, payload: { breadcrumb }}),
    };
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlaceholderView))));