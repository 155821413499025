import PropTypes from "prop-types";
import React from "react";
import RibbonBottomRight from './image/ruban-3.png';
import StarBlue from './image/asterix-bleu.svg';
import StarRed from './image/asterix-rouge.svg';
import IlluPNG from './image/illu.jpg';
import VipPNG from './image/vip.png';
import Link from 'next/link';
import ArrowBlueSVG from './image/ico-drop-droite.svg';
import ArrowSVG from './image/ico-drop-droite-black.svg';
import HeadPNG from './image/head_lego.png';
import PG from "../../../../shared/components/CustomParagraph";
import H2 from "../../../../shared/components/CustomH2";

const BannerLink = (props) => {
    let {
        preview = false,
        id = null, 
        data,
        noSticky,
        inputCallback = null,
        link = '',
        spread = false,
    } = props;
    
    return (
        <div className="banner_link_lego">
            {!preview && props.children}
            <div className="wrapper_text">
                <div className="container">
                    <div className={`discoverSection ${process.env.REACT_APP_RESTRICTED === "true" ? 'only' : null}`}>
                        <a className="fullLink" href={process.env.REACT_APP_REDIRECT} target={"__blank"} onClick={(e) => {
                                if (spread) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }  
                        }}></a>
                        <img src={IlluPNG} alt="Découvrir les produits LEGO®" />
                        <PG data={data} preview={preview} component={id} block={'title_left'} inputCallback={inputCallback} nopadding={true}/>
                        <div className="container_button">
                            <a className="button_lego" href={process.env.REACT_APP_REDIRECT} target={"_blank"} onClick={(e) => {
                                if (spread) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }  
                            }} rel="noreferrer">
                                <img src={HeadPNG} />
                                {data.blocks.button_left?.inputs.value.value}
                                <img src={ArrowSVG} />
                            </a>
                        </div>
                    </div>
                    {
                        process.env.REACT_APP_RESTRICTED !== "true" ? (
                            <div className="vipSection">
                                <div>
                                    <img src={VipPNG} alt="Devenir VIP LEGO®" />
                                    <H2 data={data} preview={preview} component={id} block={'title_right'} inputCallback={inputCallback} nopadding={true}/>
                                </div>
                                <div>
                                    <PG data={data} preview={preview} component={id} block={'text_left'} inputCallback={inputCallback} nopadding={true}/>
                                    <div className="container_button">
                                        <a className="button_lego" href={process.env.REACT_APP_REDIRECT2 || `${process.env.REACT_APP_REDIRECT}/vip`} target={"_blank"} onClick={(e) => {
                                            if (spread) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }  
                                        }} rel="noreferrer">
                                            {data.blocks.button_right?.inputs.value.value}
                                            <img src={ArrowBlueSVG} />
                                        </a>
                                    </div>
                                </div>
                                <a className="fullLink" href={process.env.REACT_APP_REDIRECT2 || `${process.env.REACT_APP_REDIRECT}/vip`} target={"__blank"} onClick={(e) => {
                                    if (spread) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }  
                                }}>
                                </a>
                            </div>
                        ) : null
                    }
                </div>
                <div className="starsSection">
                    <img src={StarBlue} alt="Etoile bleu" />
                    <img src={StarRed} alt="Etoile rouge" />
                    <img src={StarBlue} alt="Etoile bleu" />
                </div>
                <div className="copyright">{data.blocks.copyright?.inputs.value.value}</div>
            </div>
            <div className="ribbon_bottom_right">
                <img src={RibbonBottomRight} alt="Ruban décoratif en bas à droite"/>
            </div>
        </div>
    );
};

BannerLink.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default BannerLink;
