import React from 'react';

import { Grid, Box, Typography, Tooltip } from '@mui/material';
import styled from 'styled-components';

import DataInfos from './DataInfos'
import colors from '../../../../../config/theme/colors';
import CardCustom from '../../../../layouts/Card/CardCustom';
import { withTranslation } from 'react-i18next'

const DataContainer= styled (Grid)`
    background-color:white;
    padding:30px;
`
const Bar = styled(Box)`
    background-color:${colors.grey.lighter.hue900};
    overflow:hidden;
    border-radius:20px;
    height:20px;

    span{
        height:100%;
        display:inline-block;
        position:relative;
        transition:all .5s;
        
    }
`
const ToolTipInfo= styled.div`
    text-align:center;
    p{
        margin: 2px ;
    }
`

const tooltipTitle = (title, size,unit) => {
    return (
        <ToolTipInfo>
            <p>{title}</p>
            <p>{size} {unit}</p>
        </ToolTipInfo>
    );
};

const convertBytes=(size)=>{
    let convertSize;
    let unit;
    let round = 0;

    if(size>=1073741824){
        convertSize = size/1073741824;
        unit        = 'Go';
        round       = 1;
    }
    else if(size >= 1048576){
        convertSize = size/1048576;
        unit        = 'Mo';
        round       = 1;
    }
    else if(size < 1048576){
        convertSize = size/1024;
        unit        = 'Ko';
    }


    convertSize=convertSize.toFixed(round);

    return([convertSize,unit])
}

function DataBar(props){
    const datas=props.datas;
    const remaining=props.remaining;

    return(
        <Grid container>
            <CardCustom style={{width:"100%"}}>
                
                <DataContainer item xs={12}>
                    <Bar>
                        {
                            datas.map((data,index)=>
                                
                                <Tooltip 
                                    key         = {`TooltipDataBar${index}`} 
                                    title       = {tooltipTitle(data.name,convertBytes(data.size?data.size:0)[0],convertBytes(data.size?data.size:0)[1])} 
                                    aria-label  = "add" 
                                    placement   = "top" 
                                    arrow
                                >
                                    <span style={{backgroundColor:data.graphColor,width:`${data.size>0?data.graphSize>1?data.graphSize:1:0}%`}}/>                        
                                </Tooltip>
                            )
                        }
                    </Bar>

                    <Grid container spacing={2} style={{marginTop:15}}>

                            <DataInfos 
                                color   = {colors.grey.lighter.hue900} 
                                name    = {props.t("medias.dashboard.availableSpace")} 
                                size    = {convertBytes(remaining?remaining:0)[0]} 
                                warning = {props.warning} 
                                unit    = {convertBytes(remaining?remaining:0)[1]}
                            />
                        {
                            datas.map((data,index) =>
                                <DataInfos 
                                key     = {`DataInfos${index}`} 
                                color   = {data.graphColor} 
                                name    = {data.name} 
                                size    = {convertBytes(data.size?data.size:0)[0]} 
                                unit    = {convertBytes(data.size?data.size:0)[1]}></DataInfos>
                            )
                        }
                    </Grid>
                </DataContainer>
            </CardCustom> 
        </Grid>    
    )

}

export default withTranslation()(DataBar);