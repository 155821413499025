import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import H5 from '../../../../shared/components/CustomH5';
import H1 from '../../../../shared/components/CustomH1';
import Pg from '../../../../shared/components/CustomParagraph';
import { useDrop } from 'react-dnd';

// Who Are We

const SectionTitle = (props) => {
  const [hover, setHover] = useState(false);

  let { 
    preview = false,  
    id = null, 
    data,
    inputCallback = null,
    update = null
  } = props;

  const [collectedProps, drop] = useDrop({
    accept: ['media'],
    drop: (item) => {
      data.inputs['backgroundImage'].value = item.media.node;
      update();
    }
  });

  const style = {
    boxShadow: 'none',
    backgroundColor: !preview ? '#eee' : '#fff',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%'
  };

  let image = data.inputs['backgroundImage'].value;

  return (
    <div
      className={`builder-template-scope welcome-area`}  
      ref={drop} 
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)}
      style={{
        ...style,
        paddingTop: data.paddingTop,
        paddingBottom: data.paddingBottom,
        backgroundColor: data.inputs['backgroundColor'].value,
        backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none'
      }}
    >
      { !preview && hover && props.children }

      <div className="container">
        <div className="welcome-content text-center">
          <H5 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} /> 
          <H1 data={data} preview={preview} component={id} block={'subTitle'} inputCallback={inputCallback} />
          <Pg data={data} preview={preview} component={id} block={'desc'} inputCallback={inputCallback} />
        </div>
      </div>
    </div>
  );
};

SectionTitle.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default SectionTitle;
