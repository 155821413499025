import React from 'react';
import { Grid } from '@mui/material';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { ROUTE_PRODUCTS_ADD } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from '@apollo/client/react/hoc';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import importProductsTypesConfig from './config/importProductsTypes.config';
import importProductsConfig from './config/importProducts.config';
import importProductsApiConfig from './config/importProductsAPI.config';
import exportProductsConfig from './config/exportProducts.config';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importZIP from '../../../../assets/pictos/zip.svg';
import request from '../../../../js/utils/fetch';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import '../../../navigation/DrawerLeft.scss';
import ListingProducts from './components/ListingProducts';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import { getItemsLocalStorage, setItemsLocalStorage } from '../../../../js/helpers/filters';

class ListProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLang: this.props.locales[0].node.code,
            openForm: false,
            openFormImport: false,
            openFormExport: false,
            catalogFormData: {},
            exportName: '',
            exportLang: this.props.locales[0].node.code,
            exportType: 'csv',
            importTypeFile: 'csv',
            importUrl: '',
            errors: {},
            dataImportLayout: importProductsConfig,
        }
        this.errors = {}
    }

    componentDidMount() {
        checkRouting(this.props);
    }

    handleToggleDrawer = (stateDrawer) => {
        if (stateDrawer === "openFormExport") {
            this.setState({
                [stateDrawer]: !this.state[stateDrawer]??false,
                exportName: '',
                exportLang: this.state.currentLang??null,
                exportType: 'csv',
            })
        }
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });
    };

    handleFormImport = (type, title) => {

        let separator = {
            type: 'select',
            isContainerized: true,
            label: this.props.t('products.categories.importation.separator'),
            translated: false,
            helper: {
                text: this.props.t('products.categories.importation.separatorsub'),
                link: false,
            },
            required: false,
            stateName: 'importSep',
            value: [
                { value: ',', label: ',' },
                { value: ';', label: ';' },
                { value: '|', label: '|' },
                { value: 'xml', label: 'SimpleXML ( temp )' },
            ]
        }
        let setupUploadFile;
        let setupUploadFileExcel;
        let setupUploadFileZip;
        let setupMapping;

        setupUploadFile = {
            type: 'uploadFile',
            label: this.props.t('products.list.importation.csv'),
            translated: false,
            required: false,
            stateName: `upload_CSV`,
            downloadType: 'product',
        };

        setupUploadFileZip = {
            type: 'uploadFile',
            label: this.props.t('products.list.importation.zip'),
            translated: false,
            required: false,
            typeUpload: title === 'zip' ? "only-zip" : "zip",
            stateName: `upload_ZIP`,
            multipleStates: title === "zip" ? true : false,
        };

        setupUploadFileExcel = {
            type: 'uploadFile',
            label: this.props.t('products.list.importation.excel'),
            translated: false,
            required: false,
            typeUpload: 'xls',
            stateName: `upload_CSV`,
            downloadType: 'product',
        };

        setupMapping = {
            type: 'mapper',
            label: '',
            translated: false,
            required: false,
            mapOn: `upload_CSV`,
            stateName: `mapper`,
            staticOptions: [
                {
                    id: 'sku',
                    label: 'Code article'
                }, {
                    id: 'price',
                    label: 'Price'
                },
                {
                    id: 'stock',
                    label: 'Stock'
                }
            ]
        };

        let configFormImport = {...this.state.dataImportLayout};
        configFormImport.formConfig.children[0].optionsInputs = [];
        configFormImport.formConfig.children[1].optionsInputs = [];

        if (title === "excel") {
            configFormImport.formConfig.children[0].optionsInputs.push(setupUploadFileExcel);
        } else if (title === "zip") {
            configFormImport.formConfig.children[0].optionsInputs.push(setupUploadFileZip);
        } else {
            configFormImport.formConfig.children[0].optionsInputs.push(separator);
            configFormImport.formConfig.children[0].optionsInputs.push(setupUploadFile);
            configFormImport.formConfig.children[0].optionsInputs.push(setupUploadFileZip);
        }
        configFormImport.formConfig.children[1].optionsInputs.push(setupMapping);

        this.setState({
            dataImportLayout: configFormImport,
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            headers: title === 'api' ? '' : null,
            upload_CSV: null,
            upload_ZIP: null,
            importSep: title === 'zip' ? ';' : ',',
            importValues: {},
            importLang: this.props.locales[0].node.id??null,
            importType: title,
            importUrl: title === 'api' ? '' : null,
        }, () => this.handleToggleDrawer('openFormImport'));
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();

            //---- TODO run new GQL with lang change

            this.forceUpdate();
        });
    };

    handlerMutation = async () => {
        this.props.startLoading();
        let importConfig = {
            "url": `${this.state.importType === "excel" ? `${process.env.REACT_APP_API_ROOT}/medias/${this.state.upload_CSV?.medias?.mediaObject.filePath}` : this.state.upload_CSV?.medias?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'product').node.id,
            "locale": this.state.importLang,
            "delimiter": this.state.importSep,
            "media": this.state.upload_ZIP ? `/api/media-objects/${this.state.upload_ZIP.medias.id}` : null,
            "fileType": this.state.importSep === "xml" ? "simplexml" : null,
        };

        try {
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès, son intégration dans Sinfin DXP sera exécutée lors du passage du CRON (Tâche automatique).`, 6000);
            this.handleToggleDrawer('openForm');
            this.handleToggleDrawer('openFormImport');
            this.props.stopLoading();
        } catch (e) {
            this.props.snack(ALERT_ERROR, `L'import a échoué !`);
            this.props.stopLoading();
        }
    };

    handlerMutationExport = async () => {
        this.props.startLoading();
        if (this.hasErrors()) {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            return eventService.fire();
        }
        try {
            let data = new FormData();
            data.append('name', this.state.exportName);
            data.append('locale', this.state.exportLang);
            data.append('format', this.state.exportType);

            let urlencoded = new URLSearchParams(data).toString();

            request(`${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`, 'get', null, 'application/x-www-form-urlencoded')
                .then(async (data) => {
                    if (data.success) {
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath + '.' + (this.state.exportType === 'xls' ? this.state.exportType + 'x' : this.state.exportType)}`, '_blank');
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                    } else {
                        this.props.stopLoading();
                        this.props.snack(ALERT_ERROR, `Un problème est survenu lors de l'exportation !`);
                    }

                }).catch((err)=>{
                    console.log(err)
                });
        } catch (e) {
            this.props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            this.props.stopLoading();
        }
        this.handleToggleDrawer('openFormExport')
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        if (stateName === 'importSep') {
            this.setState({
                upload_CSV: null,
                upload_ZIP: null
            })
        }
        if (custom === 'upload_CSV') {
            this.setState({
                [custom]: value?.target?.value ?? value
            }, callback)
        } else {
            this.setState({
                [stateName]: value?.target?.value ?? value
            }, callback);
        }
    };


    handleFormError = (stateName, error) => {
        let errors = {...this.state.errors};

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    callbackFilters = (filters, pagination) => {
        let itemToStore = {
            filters,
            pagination
        }
        setItemsLocalStorage("PIM_FILTERS", itemToStore)
    }
    render() {
        return (
            <div style={{ display: 'grid', height: 'calc(100% - 64px)', gridTemplateRows: 'auto 1fr' }}>
                <TopPanel
                    icomoon="picto-produit"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t('products.list.topbar.manage') : "Liste de vos Produits"}
                    subtitle={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t('products.list.topbar.subtitle') : "Liste de vos produits ainsi que leurs stocks"}
                    handlerAdd={process.env.REACT_APP_DISABLE_PRICE_WATCHER === "true" ?() => this.handleToggleDrawer('openForm'):null}
                    textAdd={process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_DISABLE_PRICE_WATCHER === "true" ? '+ ' + this.props.t('products.list.topbar.import') : null}
                    handlerImport={process.env.REACT_APP_DISABLE_PRICE_WATCHER === "true"?() => this.goTo(ROUTE_PRODUCTS_ADD):null}
                    textImport={process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_DISABLE_PRICE_WATCHER === "true"? '+ ' + this.props.t('products.list.topbar.add') : null}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    currentLang={this.state.currentLang}
                    locales={this.props.locales}
                    hasBorder={true}
                    buttonAvailable={true}
                />

                <Grid container direction="row" justifyContent="center">
                    {/* <Grid item xs={12}>
                        <StatsProducts />
                    </Grid> */}
                    <ListingProducts
                        productBypage={{
                            card: [12, 24, 48, 72],
                            list: [30, 40, 50]
                        }}
                        isCatalog={false}
                        nbperpage={12}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        windowWidth={this.props.windowWidth}
                        openForm={this.state.openForm}
                        callbackFilters={this.callbackFilters}
                        previousFilters={getItemsLocalStorage("PIM_FILTERS")}
                    />
                </Grid>
                <div className='hidden'>
                    <LayoutBuilder
                        isSublayout={false}
                        opened={this.state.openForm}
                        forClose={() => this.handleToggleDrawer('openForm')}
                        dataLayout={importProductsTypesConfig}
                        drawerWidth={this.props.drawerWidth}
                        dataCard={[
                            // {
                            //     'libelle': this.props.t('products.categories.importation.flux'),
                            //     'bicoloreText': 'flux',
                            //     'colorhover': '#0273A5',
                            //     'picto': importFlux,
                            //     'disabled': true,
                            //     'textButton': this.props.t('products.categories.import'),
                            //     'description': this.props.t('products.categories.importation.fluxText'), 
                            //     'catalogDescription' : 'Veuillez compléter les champs ci-dessous',
                            //     'onClick': () => this.handleFormImport(null, 'flux')
                            // },
                            // {
                            //     'libelle': this.props.t('products.categories.importation.api'),
                            //     'bicoloreText': 'API',
                            //     'colorhover': '#0273A5',
                            //     'picto': importAPI,
                            //     'disabled': false,
                            //     'textButton': this.props.t('products.categories.import'),
                            //     'description': this.props.t('products.categories.importation.apiText'), 
                            //     'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            //     'onClick': () => this.handleFormImport(null, 'api')
                            // },
                            {
                                'libelle': this.props.t('products.categories.importation.file'),
                                'bicoloreText': this.props.t('products.list.cardproduct.file'),
                                'colorhover': '#0273A5',
                                'picto': importFichier,
                                'disabled': false,
                                'textButton': this.props.t('products.categories.import'),
                                'description': this.props.t('products.categories.importation.fileText'),
                                'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                                'onClick': () => this.handleFormImport(null, 'fichier')
                            },
                            // {
                            //     'libelle': this.props.t('products.categories.importation.file'),
                            //     'bicoloreText': this.props.t('products.categories.importation.excel'),
                            //     'colorhover': '#0273A5',
                            //     'picto': importFichier,
                            //     'disabled': false,
                            //     'textButton': this.props.t('products.categories.import'),
                            //     'description': this.props.t('products.categories.importation.excelText'), 
                            //     'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            //     'onClick': () => this.handleFormImport(null, 'excel')
                            // },
                            {
                                'libelle': this.props.t('products.categories.importation.zip'),
                                'bicoloreText': 'ZIP',
                                'colorhover': '#0273A5',
                                'picto': importZIP,
                                'disabled': false,
                                'textButton': this.props.t('products.categories.import'),
                                'description': this.props.t('products.categories.importation.zipText'),
                                'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                                'onClick': () => this.handleFormImport(null, 'zip')
                            }
                        ]}
                    />

                    <LayoutBuilder
                        isSublayout={true}
                        opened={this.state.openFormImport}
                        icomoon={'ico-import-fichier'}
                        forClose={() => this.handleToggleDrawer('openFormImport')}
                        dataLayout={this.state.importType === 'api' ? importProductsApiConfig : this.state.dataImportLayout}
                        drawerWidth={this.state.drawerWidth}
                        handleCancel={this.handleCancel}
                        handlerMutation={this.handlerMutation}
                        allState={this.state}
                        stateCallback={this.stateCallback}
                        stepperButtonDisabled={[() => (
                            this.state.importType === 'api' ? this.state.importUrl === '' : this.state.upload_CSV === null)
                            , null
                        ]}
                        stepperButtonAction={[
                            null,
                            () => {
                                let error = 0;
                                let requiredValueMapper;
                                if (this.state.requiredValueMapper) {
                                    let i = 0;
                                    requiredValueMapper = this.state.requiredValueMapper;
                                    for (let item of this.state.requiredValueMapper) {
                                        for (let value of this.state.mapper) {
                                            if (value && value.length > 0) {
                                                for (let val of value) {
                                                    if (val === item.id) {
                                                        error++;
                                                        requiredValueMapper[i].checked = true;
                                                    }
                                                }
                                            }
                                        }
                                        i++;
                                    }
                                }
                                if (error === this.state.requiredValueMapper.length) {
                                    return true;
                                } else {
                                    this.setState({ requiredValueMapper:requiredValueMapper??null }, () => {
                                        this.props.snack(ALERT_ERROR, this.errorMapper());
                                    })
                                    return false;
                                }
                            }
                        ]}
                        backStepperButtonAction={[null, null, null]}
                    />

                    <LayoutBuilder
                        isSublayout={false}
                        opened={this.state.openFormExport}
                        icomoon={'ico-import-fichier'}
                        forClose={() => this.handleToggleDrawer('openFormExport')}
                        dataLayout={exportProductsConfig(this.props.locales, 'products', this.props.t)}
                        drawerWidth={this.state.drawerWidth}
                        handleCancel={this.handleCancel}
                        handlerMutation={this.handlerMutationExport}
                        allState={this.state}
                        stateCallback={this.stateCallback}
                        validateButton={true}
                        errorCallback={this.handleFormError}
                        checkError={() => { }}
                    />
                </div>

            </div>
        );
    }

    errorMapper = () => {
        let string = '';
        let i = 0;
        for (let value of this.state.requiredValueMapper) {
            if (!value.checked) {
                if (i > 0) {
                    string += ', ';
                }
                else {
                    string += ' ';
                }
                string += `"${value.label}"`;
                i++;
            }
        }
        if (i === 1) {
            return `Le champ ${string} est requis`;
        }
        else {
            return `Les champs${string} sont requis`;
        }
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname: route,
            state: { productId: id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListProducts)))));